import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    searchCustomers,
    selectQuery,
    selectIncludesMinors,
    selectTotalFound,
    selectTotalShown,
    selectTotalPages
} from './customerSlice'

import { debug } from '@/lib/Debug'

export function _CustomerPagination({ selectedCustomersCount=0 }) {

    const dispatch       = useDispatch()
    const query          = useSelector(selectQuery)
    const includesMinors = useSelector(selectIncludesMinors)
    const totalFound     = useSelector(selectTotalFound)
    const totalShown     = useSelector(selectTotalShown)
    const totalPages     = useSelector(selectTotalPages)

    const [page, setPage] = useState(1)

    const handleDirection = (direction) => {
        const newPage = page + direction

        if (newPage < 1) {
            if (debug && console) { console.log('At min...') }
            return
        }

        if (page >= totalPages && direction === 1) {
            if (debug && console) { console.log('No more results...') }
            return
        }

        setPage(newPage)
        dispatch(searchCustomers(newPage))
    }

    const handlePrevious = () => {
        handleDirection(-1)
    }

    const handleNext = () => {
        handleDirection(1)
    }

    useEffect(() => {
        if (query !== null) {
            setPage(1)
        }
    }, [query])

    return (
        <div className="pagination row">
            {
                totalPages > 1 && (
                    <div className={`col-2 pl-0 dir previous ${page === 1 && 'disabled'}`} onClick={handlePrevious}>
                        <i className="far fa-chevron-left" />
                        &nbsp;
                        Previous
                    </div>
                )
            }

            <div className='col text-center text-muted'>
                {
                    selectedCustomersCount > 0 && <>
                        <span>{ selectedCustomersCount } Selected</span>
                        <span className='px-2'>|</span>
                    </>
                }

                <span>{ totalShown } Shown</span>
                <span className='px-2'>|</span>
                <span>{ totalFound } Found</span>
                { !includesMinors && <sup className='ml-1'><small>(except minors)</small></sup> }
            </div>

            {
                totalPages > 1 && (
                    <div className={`col-2 pr-0 text-right dir next ${page >= totalPages && 'disabled'}`} onClick={handleNext}>
                        Next
                        &nbsp;
                        <i className="far fa-chevron-right" />
                    </div>
                )
            }
        </div>
    )
}

export const CustomerPagination = React.memo(_CustomerPagination)
