import React from 'react'
import { useSelector } from 'react-redux'
import { selectStep } from '../bookingSlice'
import { selectName } from '../../Packages/packageSlice'
import BookingCountdown from './BookingCountdown'

export default function BookingModalHeader() {

    const name = useSelector(selectName)
    const step = useSelector(selectStep)

    return !name ? '' : (
        <div className="xbm-header">
            <h1 className="d-flex flex-column flex-md-row align-items-center justify-content-between text-center text-md-left">
              <span className="d-block mb-3 mb-md-0">
                  {name}
              </span>

              <span className="">
                  {
                      /confirmation/i.test(step)
                          ? 'Booked!'
                          : <BookingCountdown />
                  }
              </span>
            </h1>
        </div>
    )
}
