import { debug } from '@/lib/Debug'

export function gaSendPageView(pageTitle='', pagePath='', use='gtag') {
    try {
        if (pageTitle === '' || pagePath === '') {
            return false
        }

        if (use === 'dataLayer') {
            gaDataLayerPush({
                event: 'virtualPageView',
                virtualPageTitle: pageTitle + ' | ' + document.title,
                virtualPagePath: window.location.pathname + pagePath
            })
        }

        if (use === 'gtag') {
            if (typeof window.gtag === 'function') {
                gtag('event', 'virtualPageView', {
                    virtualPageTitle: pageTitle + ' | ' + document.title,
                    virtualPagePath: window.location.pathname + pagePath
                })
                if (debug) {
                    console.log('event', 'virtualPageView', {
                        virtualPageTitle: pageTitle + ' | ' + document.title,
                        virtualPagePath: window.location.pathname + pagePath
                    })
                }
            } else {
                throw('gtag - Unavailable')
            }
        }
    } catch (error) {
        if (console) { console.error(error) }
    }
}

export function gaDataLayerPush(data) {
    if (!(window.dataLayer && typeof window.dataLayer === 'object')) {
        if (console) { console.warn('dataLayer — Unavailable') }
        return false
    }

    if (!data || data.length === 0) {
        if (console) { console.warn('dataLayer — empty data') }
        return false
    }

    try {
        if (debug) { console.log(data) }
        window.dataLayer.push(data)
        if (console) { console.log(`dataLayer updated`) }
    } catch (error) {
        if (console) { console.error(error) }
    }
}
