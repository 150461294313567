import React from 'react'
import moment from 'moment-timezone'
import { v4 as uuidv4 } from 'uuid'
import { useFormContext, Controller } from 'react-hook-form'
import InputWrapper from './InputWrapper'
import TimePicker from 'rc-time-picker'
import titleize from '../../lib/String'
import errorClass from '../../lib/Errors'

// This time picker can handle times specified in timezones
// because it uses Moment instead of the vanilla Date JS
//
// It's interface is as close to the other react-datepicker-based
// TimePicker.jsx component as possible to make our lives easier
//
// https://github.com/react-component/time-picker
//
//
// ██╗   ██╗███████╗ █████╗  █████╗ ██╗  ██╗    ██████╗  ██████╗  ██████╗  ██████╗ ██╗
// ╚██╗ ██╔╝██╔════╝██╔══██╗██╔══██╗██║  ██║    ██╔══██╗██╔═══██╗██╔═══██╗██╔═══██╗██║
//  ╚████╔╝ █████╗  ███████║███████║███████║    ██████╔╝██║   ██║██║   ██║██║   ██║██║
//   ╚██╔╝  ██╔══╝  ██╔══██║██╔══██║██╔══██║    ██╔══██╗██║   ██║██║   ██║██║   ██║██║
//    ██║   ███████╗██║  ██║██║  ██║██║  ██║    ██████╔╝╚██████╔╝╚██████╔╝╚██████╔╝██║
//    ╚═╝   ╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═╝    ╚═════╝  ╚═════╝  ╚═════╝  ╚═════╝ ╚═╝
//
// ███████╗██╗      █████╗ ██╗   ██╗ █████╗     ███████╗██╗      █████╗ ██╗   ██╗    ██╗
// ██╔════╝██║     ██╔══██╗██║   ██║██╔══██╗    ██╔════╝██║     ██╔══██╗██║   ██║    ██║
// █████╗  ██║     ███████║██║   ██║███████║    █████╗  ██║     ███████║██║   ██║    ██║
// ██╔══╝  ██║     ██╔══██║╚██╗ ██╔╝██╔══██║    ██╔══╝  ██║     ██╔══██║╚██╗ ██╔╝    ╚═╝
// ██║     ███████╗██║  ██║ ╚████╔╝ ██║  ██║    ██║     ███████╗██║  ██║ ╚████╔╝     ██╗
// ╚═╝     ╚══════╝╚═╝  ╚═╝  ╚═══╝  ╚═╝  ╚═╝    ╚═╝     ╚══════╝╚═╝  ╚═╝  ╚═══╝      ╚═╝
//
//                              .##%%%%*
//                         #%%%%%%%%%%&%%,
//                       #%%%%%%%%%%%%%%&%
//                 ,(    (%%%%%%%%%%%%%&&&(
//              ( *(   *#  %%%%%#%&%%&&&&&&&%%
//              /(/# *#     %%#&&&&&&&&&&&&&&&&%
//              */##%/////%%&&&&@&#/%%%%%&&&&%%%
//             /*(%#(/#,//(&&&@&,,&(.%&&&%#%%%%
//             (/(//*(##    /%#%((*#%###%%%%%
//        /%*((###%#          (((/((###%%%%%%
//     %%%& ,*%&&&             ((&**/%%%&&&&&&%%#%####((,
//     #%%&&%&&&%    (%%%%%%&%&&#(#%#%&&&&&&&&%%%%%%%%%%%%%%%
//    #%%%&&%&&&    #&&%%%%%%&&&%%##%%%&*/%&%%%%%%%%%%%%%%%%%%*
//   (##%%%&&%&%   %%&%%%%%%&&&%%%& .*/%#((/%%%%%%%%%%%&%%%%%%%%
//  ##%%%%%&&&&@&&&%%%%#%%&%&&%%##/(#(***((%%%%%%&&&%%%%&%%&&%%&%%,
// ##%%%&&%%@&&%&&&%%%%%%&&&&&#%%%&#.     ,* %%%&&%&&%%%&&&&&&&&%%&%%
//  %%%%%&%&&&%%&#&&&%%%%&&&&&&&&&# (     *   %&%&&%&&%&&&&&%%%%%%&%%%%#
//      #%%%%%%%&&&&%%%%%&&&&&&&&&,  ,    /.,  &&&%%&&&&%&&&%%%%%&%%%%%%%(
//                   #%%%&&&&&&&&&/  (   , .,  ,&&&%%&&&&&&&&&&%&%&&%%%%%%#
//                   #%%%&&&&&&&&###///**/##/. ..&&&&&&&&&&&&&&%%&%%%%%%%%%#(
//                   /%%%&&&&&&#/,............#%..&&&&&&&&&&&&&&&&&&%%&%%%%%##
//                   %&%%&&&&&(,......./........#&.&&&&&&&&&&&&&&&&&&%%%%#%%%#
//                    %%%&&&&(.........%.........#&*,&&&%&&&&&&&&&&%%%%#%%%%%#
//                    #%%&&&&/.........(..........&,,#&&%&&&&&&&&&%%%#%%%%%%##
//                    %%%%&&%(....................&%%%%%%%&&&&&&&&&%%%%%%%%%%#
//                    #%%%%%%%,..................#&%%%#%%%%&&&&&&&&%#%#%%%%%%#
//                    #%%%%%%&&(................#%%%%##%#&&%&&&&&&&%%%%%%%%%%#
//                    #%%%%&&&&&&&( .........#%&&&&&&&&&&&&&%&&&&&&%%%%%%%%%%%#
//                    %%%%#%&&&&&&&/&&&&&&%&&&&&%&&&&&&&&&&&&&&&%&&%%%#((##%#%%%
//                    #%%#%%&&&&&&&#&&&&&%%/   %&&&&&&&&&&&%&&&&&&&%%#####%%%%%%
//                    #%#%%%%%&&&&&&#.         #&&&&&&&&&&&&&&%&%%&&%%##%&*(%%%%
//                    (%%#%#%&&&&&%&/    *     /%%&&&&&&&&&&&&&%%%&%%%%%#%&%%%%%
//                    .%%%%%&%%%%   *    ,      %%%&%&%&&%&&&&%&%%%%%%%%%#&%%%%%
//                     %%%%              /      #%%%%&%&%%%&%%%%%%%%%%%%%%%%%%%%
//                     ##            /           %%%%%%%%%&&%%%%%%%%%%%%%%%%%%%%
//                     #             ,  *        #%%%%%&&%%%%&%%%     #%%%#%%%%%
//                                    * ,         %%%%%%%&&&%%%*         ##%%%%%
//                                     ,         /%%%%%&&%%%%%%           /#

export default function MomentTimePicker({
    name,
    value,
    label,
    hideLabel,
    cols,
    disabled,
    placeholder,
    tooltip='',
    req=null,
    errors=[],
    validation=null,
    placement,
    interval,
    format,
    showSecond,
    use12Hours,
    allowEmpty,
    defaultOpenValue,
    tz=null,
    preventTyping=false,
    debug=false,
    handleUpdate=() => {},
}) {
    const formMethods = useFormContext()

    const dateTimeFormat      = 'YYYY-MM-DDTHH:mmZZ'
    const defaultPickerFormat = 'h:mm A'
    const separator           = ':'

    const _name = validation
        ? Object.keys(validation)[0]
        : name + separator + uuidv4()

    /**
     * Example of an expected validation
     * schema object to register
     *
     * {
     *   'name': {
     *     required: 'A name is required',
     *   }
     * }
     */

    const handleChange = (field, date) => {
        const newValue = !!date && moment.isMoment(date)
                ? date.tz(tz || 'UTC', true).format(dateTimeFormat)
                : null

        if (debug && console) {
            console.log({
                date,
                localized: date?.toString(),
                iso: date?.toISOString(),
                valueO: value,
                valueN: newValue
            })
        }

        // update react hook form validation
        if ('onChange' in field) { field.onChange(date) }

        // update our form object
        handleUpdate({target: { name: field.name, value: newValue }})
    }

    // If `preventTyping` is passed in (LocationHoursTab), don't allow users to type in the text field
    // This uses the `onChangeRaw` param from DatePicker
    // Src: https://github.com/Hacker0x01/react-datepicker/issues/942#issuecomment-424939438
    const handleDateChangeRaw = (e) => {
      e.preventDefault();
    }

    return (
        <InputWrapper
            name={_name.split(separator)[0]}
            req={(validation && req === null) || Boolean(req)}
            tooltip={tooltip}
            cols={cols}
            errors={errors}
            hideLabel={hideLabel}
            label={label}
        >
            <div className={errorClass(_name, errors)}>
                { formMethods ? (
                    <Controller
                        name={_name}
                        control={formMethods.control}
                        defaultValue={value || null}
                        rules={validation ? validation[Object.keys(validation)[0]] : {}}
                        render={({ field }) =>
                            <TimePicker
                                ref={field.ref}
                                inputClassName="form-control"
                                className={`w-100 ${errorClass(_name, errors)}`}
                                value={field.value || null}
                                defaultOpenValue={defaultOpenValue || moment().tz(tz || 'UTC')}
                                allowEmpty={allowEmpty || true}
                                placeholder={placeholder || titleize(_name)}
                                disabled={disabled}
                                minuteStep={interval || 15}
                                placement={placement || 'bottomLeft'}
                                format={format || defaultPickerFormat}
                                use12Hours={use12Hours || true}
                                showSecond={showSecond || false}
                                onChange={(date) => handleChange(field, date)}
                                onChangeRaw={preventTyping ? handleDateChangeRaw : ''}
                            />
                        }
                    />
                ) : (
                    <TimePicker
                        name={_name}
                        inputClassName="form-control"
                        className={`w-100 ${errorClass(_name, errors)}`}
                        value={value || null}
                        defaultOpenValue={defaultOpenValue || moment().tz(tz || 'UTC')}
                        allowEmpty={allowEmpty || true}
                        placeholder={placeholder || titleize(_name)}
                        disabled={disabled}
                        minuteStep={interval || 15}
                        placement={placement || 'bottomLeft'}
                        format={format || defaultPickerFormat}
                        use12Hours={use12Hours || true}
                        showSecond={showSecond || false}
                        onChange={(date) => handleUpdate({ name: _name }, date)}
                        onChangeRaw={preventTyping ? handleDateChangeRaw(this) : ''}
                    />
                )}
            </div>
        </InputWrapper>
    )
}
