import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { selectBookingId } from '@/features/EditBooking/editBookingSlice'
import { setOpenResendReceiptModal, setResendReceiptRefund, revertGiftCardRefund } from './bookingHistorySlice'
import { selectTimeZone } from "../Locations/locationSlice"
import StatusBadge from './StatusBadge'
import { useConfirm } from '@/lib/useConfirmHook'
import { numberToCurrency } from '@/lib/Number'
import titleize from '@/lib/String'
import { debug } from '@/lib/Debug'

export default function RefundRow({ refund }) {

    const { confirm }      = useConfirm()
    const dispatch         = useDispatch()
    const bookingId        = useSelector(selectBookingId)
    const selectedTimeZone = useSelector(selectTimeZone)

    const [showNotes, setShowNotes] = useState(false)

    const classes = (klasses) => `${klasses} ${refund.has_been_reverted ? 'text-strikethrough' : ''}`

    const handleRevertGiftCardRefund = async () => {
        if (await confirm('Are you sure you wish to undo this refund?')) {
            if (debug && console) { console.log('reverting gift card refund...', refund) }
            dispatch(revertGiftCardRefund(refund.id, bookingId))
        }
    }

    const handleResendReceipt = () => {
        dispatch(setOpenResendReceiptModal(true))
        dispatch(setResendReceiptRefund(refund))
    }

    const handleToggleRefundNotes = () => {
        setShowNotes(!showNotes)
    }

    return <>
        <tr className='refund-row'>
            { debug && <td className='text-muted pl-0'><strong>{refund.id}</strong></td> }
            <td className={classes('text-danger pl-0')}>
                <strong>
                    {moment.tz(refund.created_at, selectedTimeZone).format('M/D/YYYY')}<br />
                    {moment.tz(refund.created_at, selectedTimeZone).format('h:mm:ss A z')}
                </strong>
            </td>
            <td className={classes()}></td>
            <td className={classes('text-danger pl-0')}><strong>{titleize(refund.refund_method)}</strong></td>
            <td className={classes('text-danger pl-0')}><strong>{numberToCurrency(refund.amount_cents / 100 * -1)}</strong></td>
            <td className={classes()}></td>

            <td className={classes('pl-0')}>
              { refund.refund_method === 'credit_card' && !!refund.refund_response &&
                  <StatusBadge status={refund.refund_response.status} />
              }
              {
                  refund.includes_tip && (
                      <span class='badge badge-danger'>
                        {numberToCurrency(refund.tip_cents / 100 * -1)} TIP
                      </span>
                  )
              }
            </td>

            <td className={classes()}>
                { refund.refund_method === "gift_card" && refund.has_been_reverted &&
                    <span className="badge badge-pill badge-secondary text-muted">Reverted Refund</span>
                }
            </td>

            <td className={classes('text-right')} colSpan='2'>
              { refund.refund_method === "gift_card" && !refund.has_been_reverted &&
                  <button
                      title="Undo Refund"
                      children={<i className="fas fa-undo-alt" />}
                      className="btn btn-primary btn-sm"
                      onClick={handleRevertGiftCardRefund}
                  />
              }

              { refund.email && refund.refund_method !== "gift_card" &&
                  <button
                      children={<i className="far fa-receipt" />}
                      title="Resend Refund Receipt"
                      className="btn btn-primary btn-sm"
                      onClick={handleResendReceipt}
                  />
              }

              {
                  !!refund.notes &&
                      <button
                          title={`${showNotes ? 'Hide' : 'Show'} Refund Notes`}
                          children={<i className={`fas fa-caret-${showNotes ? 'up' : 'down'} lead`} />}
                          className="btn btn-primary btn-sm ml-1"
                          onClick={handleToggleRefundNotes}
                      />
              }
            </td>
        </tr>

        {
            refund.notes && showNotes && (
                <tr className="refund-note-row">
                    <td />
                    <td colSpan={debug ? 8 : 7} className="pl-5 pt-2 pb-4">
                        <p className='mb-0 pb-0'>
                            <strong className="d-block pb-2">REFUND NOTE:</strong>
                            <span className="text-gray3">"{refund.notes}"</span>
                        </p>
                    </td>
                </tr>
            )
        }
    </>
}
