import React, { useEffect } from "react"
import moment from "moment"
import FormRow from "@/components/Form/FormRow"
import Input from "@/components/Form/Input"
import TextArea from "@/components/Form/TextArea"
import Checkbox from "@/components/Form/Checkbox"
import DatePicker from "@/components/Form/DatePicker"
import RichTextEditor from "@/components/Form/RichTextEditor"
import Help from '@/features/Help/Help'
import GuestDiscounts from "./GuestDiscounts"
import MembershipTerms from "./MembershipTerms"
import ConfirmationChecks from "./ConfirmationChecks"
import { numberToCurrency } from '@/lib/Number'
import { momentToLocalDate } from '@/lib/CalendarTimes'

export default function MembershipTypeForm({
    handleUpdate,
    handleManualUpdate,
    handleCheckboxUpdate,
    form,
    errors,
    location,
    customerTypes,
    disabledIfMembers,
    lastExpiration,
    mostRecentRegistration,
    monthlyRevenue,
}) {

    useEffect(() => {
        if (!form.active && form.show_in_group_modal) {
            handleManualUpdate('show_in_group_modal', false)
        }
    }, [form.active])

    const renderQuotaSettings = () => {
        return (
            <>
                <h3>Hours Allowance Settings<Help articleId='154000159017-hours-allowance-settings' /></h3>

                {/* membership-TODO: make this required, fix weird input */}
                <FormRow className="mt-3">
                    <div className="col-auto">
                        <div className="form-group">
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    id="limited_check_box"
                                    type="radio"
                                    name="limited"
                                    value={true}
                                    checked={form.limited}
                                    className="custom-control-input"
                                    onChange={() => handleManualUpdate("limited", true)}
                                    disabled={disabledIfMembers}
                                />

                                <label className="custom-control-label" htmlFor="limited_check_box">
                                    Limited
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="col-auto">
                        <div className="form-group">
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    id="unlimited_check_box"
                                    type="radio"
                                    name="limited"
                                    value={false}
                                    checked={!form.limited} // This is dumb, but !form.limited picks up undefined
                                    className="custom-control-input"
                                    onChange={() => handleManualUpdate("limited", false)}
                                    disabled={disabledIfMembers}
                                />

                                <label className="custom-control-label" htmlFor="unlimited_check_box">
                                    Unlimited
                                </label>
                            </div>
                        </div>
                    </div>
                </FormRow>

                {
                    form.limited && <>
                        <FormRow>
                            <Input
                                cols="3"
                                type="number"
                                min='1'
                                value={form.monthly_quota}
                                errors={errors}
                                label="Base Monthly Allowance"
                                validation={{ monthly_quota: { required: form.limited && !disabledIfMembers } }}
                                req={!!form.limited}
                                handleChange={handleUpdate}
                                disabled={disabledIfMembers}
                            />

                            {
                                /^(allow|force)_renewal/i.test(form.renewal_option) && <>
                                    {
                                        form.allow_rollover && (
                                            <Input
                                                name="rollover_limit"
                                                value={form.rollover_limit}
                                                cols="3"
                                                type="number"
                                                min='1'
                                                errors={errors}
                                                req={!!form.allow_rollover}
                                                validation={{ rollover_limit: { required: form.allow_rollover && !disabledIfMembers } }}
                                                handleChange={handleUpdate}
                                                disabled={!form.allow_rollover || disabledIfMembers}
                                            />
                                        )
                                    }

                                    <Checkbox
                                        cols="6 mt-4 pt-1"
                                        name="allow_rollover"
                                        label="Allow Monthly Rollover of Unused Allowance"
                                        value={form.allow_rollover}
                                        handleChange={handleCheckboxUpdate}
                                        disabled={disabledIfMembers}
                                    />
                                </>
                            }
                        </FormRow>
                    </>
                }
            </>
        )
    }

    const renderDiscountSettings = () => {
        return (
            <>
                <h3>Reservation Discount Settings</h3>
                <FormRow>
                    <Input
                        name="reservation_discount"
                        label="Reservation Discount Percentage"
                        value={form.reservation_discount}
                        cols="4"
                        type="number"
                        min='0.0'
                        errors={errors}
                        validation={{ reservation_discount: { required: form.benefit_type === "discount" } }}
                        handleChange={handleUpdate}
                        disabled={disabledIfMembers}
                        append
                    >
                        <div className="input-group-append">
                            <span className="input-group-text">%</span>
                        </div>
                    </Input>
                </FormRow>
            </>
        )
    }

    const renderBenefitSettings = () => {
        if (form.benefit_type === "hours_quota" || form.benefit_type == null) {
            return renderQuotaSettings()
        } else if (form.benefit_type === "discount") {
            return renderDiscountSettings()
        }
    }

    const renderDisableSettings = () => {
        if (disabledIfMembers && !form.active) {

            return (
                <div className="active-memberships">
                    <h5 className="text-center mb-0 font-weight-bold">
                        THIS MEMBERSHIP CONTAINS ACTIVE MEMBERS
                    </h5>

                    <hr className='my-4' />

                    <p className="membership-info-text d-flex align-items-center justify-content-between">
                        <span>
                            <strong>Most Recent Registration:</strong><br />
                            { moment.tz(mostRecentRegistration, location.time_zone).format("MM/DD/YYYY hh:mm A z") }
                        </span>

                        <span>
                            <strong>Last Expiration:</strong><br />
                            { moment.tz(lastExpiration, location.time_zone).endOf('day').format("MM/DD/YYYY hh:mm A z") }
                        </span>

                        <span>
                            <strong>Total Monthly Revenue:</strong><br />
                            { numberToCurrency(monthlyRevenue) }
                        </span>
                    </p>

                    <hr className='my-4' />

                    <FormRow>
                        <Checkbox
                            cols="12"
                            name="disable_registrations"
                            label="Immediately disable future registrations"
                            value={form.disable_registrations}
                            handleChange={handleCheckboxUpdate}
                        />
                    </FormRow>

                    <p className='pl-3 pb-2 h6 font-weight-bold'>OR</p>

                    <FormRow>
                        <DatePicker
                            label="Disable future registrations on this date"
                            cols={6}
                            value={form.disable_registrations ? null : form.disable_registrations_on_date}
                            validation={{ disable_registrations_on_date: { required: !form.disable_registrations } }}
                            minDate={momentToLocalDate(moment.tz(location.time_zone).add(1, 'day'))}
                            disabled={form.disable_registrations}
                            handleChange={(_, date) =>
                                handleManualUpdate("disable_registrations_on_date", moment(date).format("YYYY-MM-DD"))
                            }
                        />
                    </FormRow>

                    {
                        errors["disable_registrations_on_date"] && (
                            <div className="invalid-feedback d-block mx-2">
                                Please either select the toggle or fill out the future date field.
                            </div>
                        )
                    }

                    <hr className='my-4' />

                    <FormRow>
                        <Checkbox
                            cols="12"
                            name="disable_renewals"
                            label="Immediately disable future renewals"
                            value={form.disable_renewals}
                            handleChange={handleCheckboxUpdate}
                        />
                    </FormRow>

                    <p className='pl-3 pb-2 h6 font-weight-bold'>OR</p>

                    <FormRow>
                        <DatePicker
                            label="Disable future renewals on this date"
                            cols={6}
                            value={form.disable_renewals ? null : form.disable_renewals_on_date}
                            validation={{ disable_renewals_on_date: { required: !form.disable_renewals } }}
                            minDate={momentToLocalDate(moment.tz(location.time_zone).add(1, 'day'))}
                            disabled={form.disable_renewals}
                            handleChange={(_, date) =>
                                handleManualUpdate("disable_renewals_on_date", moment(date).format("YYYY-MM-DD"))
                            }
                        />
                    </FormRow>

                    {
                        errors["disable_renewals_on_date"] && (
                            <div className="invalid-feedback d-block mx-2">
                                Please either select the toggle or fill out the future date field.
                            </div>
                        )
                    }

                    <hr className='my-4' />

                    <FormRow className='mb-n2'>
                        <Checkbox
                            cols="12"
                            name="send_relevant_emails"
                            label="Send relevant emails to currently active members (will only be sent if this is enabled before changes are made)"
                            value={form.send_relevant_emails}
                            handleChange={handleCheckboxUpdate}
                        />
                    </FormRow>
                </div>
            )
        }
    }

    return (
        <div>
            <FormRow className="mt-1">
                <Checkbox
                    cols="6"
                    name="active"
                    label="Active"
                    value={form.active}
                    handleChange={handleCheckboxUpdate}
                />

                <div className="col-6 text-right">
                    <div className='d-flex'>
                        <Checkbox
                            name="show_in_group_modal"
                            label="Show In Group Modal"
                            className="pb-0"
                            value={form.show_in_group_modal}
                            disabled={!form.active}
                            handleChange={handleCheckboxUpdate}
                        />
                        <Help articleId='154000159008-how-to-edit-the-membership-group-modal' />
                    </div>
                </div>
            </FormRow>

            {renderDisableSettings()}

            <FormRow className="mt-3">
                <div className='col-6'>
                    <div className='form-group'>
                        <label className="req d-inline">Membership Type Name</label>
                        <Help articleId='154000159011-membership-type-name' />
                        <Input
                            value={form.name}
                            errors={errors}
                            hideLabel={true}
                            withWrapper={false}
                            validation={{ name: { required: true } }}
                            handleChange={handleUpdate}
                        />
                    </div>
                </div>

                <div className='col-6'>
                    <div className='form-group'>
                        <label className="d-inline">Modal Header</label>
                        <Help articleId='154000159012-membership-type-modal-header' />
                        <Input
                            value={form.modal_header}
                            req={false}
                            hideLabel={true}
                            withWrapper={false}
                            placeholder="Become a Member"
                            errors={errors}
                            validation={{ modal_header: { required: false } }}
                            handleChange={handleUpdate}
                        />
                    </div>
                </div>
            </FormRow>

            <FormRow>
            <div className='col-12'>
            <div className='form-group'>

                <label className="req d-inline">Public Description</label>
                <Help articleId='154000159013-membership-type-public-description' />
                <TextArea
                    rows="3"
                    hideLabel={true}
                    withWrapper={false}
                    value={form.public_description}
                    errors={errors}
                    validation={{ public_description: { required: true } }}
                    handleChange={handleUpdate}
                />
                </div>
                </div>
            </FormRow>

            <FormRow>
                <RichTextEditor
                    name="terms"
                    label="Membership Type Terms and Conditions"
                    cols="12"
                    handleChange={handleManualUpdate}
                    value={form.terms}
                    hideLabel={false}
                    errors={errors}
                    validation={{ terms: { required: true } }}
                />
            </FormRow>

            <FormRow>
                <TextArea
                    name="internal_notes"
                    cols="12"
                    rows="3"
                    label="Internal Notes"
                    value={form.internal_notes}
                    errors={errors}
                    handleChange={handleUpdate}
                />
            </FormRow>

            <FormRow>
                <div className="col-12">
                    <div className='d-flex'>
                        <Checkbox
                            name="limit_members"
                            label="Limit Number of Members"
                            value={form.limit_members}
                            handleChange={handleCheckboxUpdate}
                        />
                        <Help articleId='154000159015-membership-limits' />
                    </div>
                </div>
            </FormRow>

            {
                form.limit_members && (
                    <FormRow>
                        <Input
                            name="member_limit"
                            value={form.member_limit}
                            cols="2"
                            min='1'
                            type="number"
                            errors={errors}
                            validation={{ member_limit: { required: form.limit_members } }}
                            handleChange={handleUpdate}
                            req={!!form.limit_members}
                            disabled={!form.limit_members}
                        />
                    </FormRow>
                )
            }

            <h3>Membership Benefit Type<Help articleId='154000159016-membership-benefit-types' /></h3>

            <FormRow className="mt-3">
                <div className="col-auto">
                    <div className="form-group">
                        <div className="custom-control custom-radio custom-control-inline">
                            <input
                                id="hours_quota_check_box"
                                type="radio"
                                name="benefit_type"
                                value="hours_quota"
                                label="Hours Allowance"
                                checked={form.benefit_type === "hours_quota" || form.benefit_type == null}
                                className="custom-control-input"
                                onChange={handleUpdate}
                                disabled={disabledIfMembers}
                            />

                            <label className="custom-control-label" htmlFor="hours_quota_check_box">
                                Hours Allowance
                            </label>
                        </div>
                    </div>
                </div>

                <div className="col-auto">
                    <div className="form-group">
                        <div className="custom-control custom-radio custom-control-inline">
                            <input
                                id="discount_check_box"
                                type="radio"
                                name="benefit_type"
                                value="discount"
                                label="Resevation Discount"
                                checked={form.benefit_type === "discount"}
                                className="custom-control-input"
                                onChange={handleUpdate}
                                disabled={disabledIfMembers}
                            />

                            <label className="custom-control-label" htmlFor="discount_check_box">
                                Reservation Discount Only
                            </label>
                        </div>
                    </div>
                </div>
            </FormRow>

            {renderBenefitSettings()}

            <h3>Additional Member Discounts<Help articleId='154000159018-additional-member-discounts' /></h3>
            <FormRow>
                <Input
                    name="merchandise_discount"
                    value={form.merchandise_discount}
                    label="Merchandise Discount"
                    cols="3"
                    type="number"
                    min='0.0'
                    errors={errors}
                    validation={{ merchandise_discount: { required: false } }}
                    req={false}
                    handleChange={handleUpdate}
                    disabled={disabledIfMembers}
                    append
                >
                    <div className="input-group-append">
                        <span className="input-group-text">%</span>
                    </div>
                </Input>

                {form.benefit_type === "hours_quota" && (
                    <Input
                        name="reservation_discount_after_quota"
                        value={form.reservation_discount_after_quota}
                        label="Reservation Discount After Allowance is Depleted"
                        cols="5"
                        type="number"
                        min='0.0'
                        errors={errors}
                        validation={{ reservation_discount_after_quota: { required: false } }}
                        req={false}
                        handleChange={handleUpdate}
                        disabled={disabledIfMembers}
                        append
                    >
                        <div className="input-group-append">
                            <span className="input-group-text">%</span>
                        </div>
                    </Input>
                )}
            </FormRow>

            <GuestDiscounts
                form={form}
                errors={errors}
                guestDiscounts={form.guest_discounts}
                handleUpdate={handleManualUpdate}
                handleManualUpdate={handleManualUpdate}
                customerTypes={customerTypes}
                disabledIfMembers={disabledIfMembers}
            />

            <ConfirmationChecks
                errors={errors}
                confirmationChecks={form.confirmation_checks}
                disabledIfMembers={disabledIfMembers}
                handleUpdate={handleManualUpdate}
            />

            <h3>Renewal Options<Help articleId='154000159021-renewal-options' /></h3>
            <FormRow className="mt-3">
                <div className="col-12">
                    <div className="form-group">
                        <div className="custom-control custom-radio custom-control-inline">
                            <input
                                id="allow_auto_renew_check_box"
                                type="radio"
                                name="renewal_option"
                                value="allow_renewal"
                                checked={form.renewal_option === "allow_renewal"}
                                className="custom-control-input"
                                onChange={handleUpdate}
                                disabled={disabledIfMembers}
                            />

                            <label className="custom-control-label" htmlFor="allow_auto_renew_check_box">
                                <strong>Customer Renewal Choice</strong>
                                <small className='d-block text-muted'>
                                    Customers have the option to choose between auto-renewal and non-renewing during the membership purchase process.
                                </small>
                            </label>
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <div className="form-group">
                        <div className="custom-control custom-radio custom-control-inline">
                            <input
                                id="force_auto_renew_check_box"
                                type="radio"
                                name="renewal_option"
                                value="force_renewal"
                                checked={form.renewal_option === "force_renewal"}
                                className="custom-control-input"
                                onChange={handleUpdate}
                                disabled={disabledIfMembers}
                            />

                            <label className="custom-control-label" htmlFor="force_auto_renew_check_box">
                                <strong>Always Auto-Renew</strong>
                                <small className='d-block text-muted'>
                                    Memberships will always auto-renew until canceled. Non-renewing is not an option during the membership purchase process.
                                </small>
                            </label>
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <div className="form-group">
                        <div className="custom-control custom-radio custom-control-inline">
                            <input
                                id="no_renewal_check_box"
                                type="radio"
                                name="renewal_option"
                                value="no_renewal"
                                checked={form.renewal_option === "no_renewal"}
                                className="custom-control-input"
                                onChange={handleUpdate}
                                disabled={disabledIfMembers}
                            />

                            <label className="custom-control-label" htmlFor="no_renewal_check_box">
                                <strong>No Renewal</strong>
                                <small className='d-block text-muted'>
                                    Memberships will not auto-renew and are not allowed to be manually renewed.
                                </small>
                            </label>
                        </div>
                    </div>
                </div>
            </FormRow>

            <MembershipTerms
                form={form}
                errors={errors}
                membershipTerms={form.membership_terms}
                handleUpdate={handleManualUpdate}
                handleManualUpdate={handleManualUpdate}
                handleCheckboxUpdate={handleCheckboxUpdate}
                disabledIfMembers={disabledIfMembers}
            />
        </div>
    )
}
