import React from 'react'
import { useSelector, useDispatch } from "react-redux"
import { selectBooking, setBookingAttribute, selectCustomers } from '../../EditBooking/editBookingSlice'
import { setSearchOpen, removeCustomerFromBooking } from '../../Customers/customerSlice'
import CustomerBadges from '@/features/Customers/CustomerBadges'
import { useConfirm } from '@/lib/useConfirmHook'
import { isBookingCancelled, isBookingComplete } from '@/lib/Booking'

export default function BookingWaiversTable() {

    const dispatch    = useDispatch()
    const { confirm } = useConfirm()
    const booking     = useSelector(selectBooking)
    const customers   = useSelector(selectCustomers)

    const handleAddParticipant = () => {
        dispatch(setSearchOpen(true))
    }

    const handleRemove = async (customerId) => {
        if (!(await confirm('Are you sure you want to remove<br />this customer from the booking?'))) {
            return
        }

        dispatch(removeCustomerFromBooking(customerId))
    }

    const toggleScoring = (customerId, checked) => {
        const exists = booking.customer_settings.map(cs => cs.customer_id).includes(customerId)

        if (exists) {
            dispatch(setBookingAttribute({
                name: 'customer_settings',
                value: booking.customer_settings.map(cs => {
                    if (Number.parseInt(cs.customer_id) === Number.parseInt(customerId)) {
                        return {
                            ...cs,
                            show_in_scoring_app: checked
                        }
                    } else {
                        return cs
                    }
                })
            }))
        } else {
            dispatch(setBookingAttribute({
                name: 'customer_settings',
                value: booking.customer_settings.concat({ customer_id: Number.parseInt(customerId), show_in_scoring_app: checked })
            }))
        }
    }

    const isScoringChecked = (customerId) => {
        return booking.customer_settings.filter(cs => {
            return Number.parseInt(cs.customer_id) === Number.parseInt(customerId) && cs.show_in_scoring_app
        }).length > 0
    }

    const toggleCheckedIn = (customerId, checked) => {
        dispatch(setBookingAttribute({
            name: 'checked_in',
            value: checked ? booking.checked_in.concat(customerId) : booking.checked_in.filter(id => id !== customerId)
        }))
    }

    const toggleCheckedInSelectAll = () => {
        let newSet = []

        booking.customers.forEach(c => newSet.push(c.id))

        dispatch(setBookingAttribute({
            name: 'checked_in',
            value: newSet
        }))
    }

    const calculateWaiversSigned = () => (
        booking.customers?.filter(customer => (
            customer.signed_waivers?.some(signedWaiver => signedWaiver.waiver_id === booking.active_waiver && signedWaiver.is_valid && signedWaiver.is_active)
        ))?.length || 0
    )

    return booking?.resource_type?.waivers_required && (
        <div className="booking-wavers-table mt-5">
            <div className='alert alert-warning mb-3 text-left rounded' role='alert'>
                <strong className="pr-3">Warning</strong>
                Do not add any participants if you have unsaved changes to the reservation.
                <i className="fas fa-exclamation-triangle float-right mt-1" />
            </div>
            <div className="section-header-actions mb-1">
                <h3>Participants</h3>
                <div className="section-counter">
                    <span className={`badge badge-${booking.checked_in.length >= booking.participants ? 'success' : 'warning'} mr-1`}>
                        {booking.checked_in.length} of {!!booking.group_min ? `${booking.group_min}-${booking.group_max}` : booking.participants } Checked In
                    </span>
                    <span className={`badge badge-${calculateWaiversSigned() >= booking.participants ? 'success' : 'warning'}`}>
                        {calculateWaiversSigned()} of {!!booking.group_min ? `${booking.group_min}-${booking.group_max}` : booking.participants } Valid Waivers
                    </span>
                </div>
                <div>
                    <button
                        children={<i className='fas fa-user-plus h6 mb-n1' />}
                        className="btn btn-link px-2"
                        disabled={isBookingCancelled(booking) || isBookingComplete(booking)}
                        title='Click to add participant(s)'
                        onClick={handleAddParticipant}
                    />

                    <a
                        className={`btn btn-link px-2 ${customers.length == 0 ? 'disabled' : ''}`.trim()}
                        href={`/bookings/${booking.id}/participants`}
                        title='Download Participant CSV File'
                        children={<i className="fal fa-download h6 mb-n1" />}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <table className="table table-sm">
                        <colgroup>
                            <col width='50' />
                            <col width='auto' />
                            <col width='auto' />
                            <col width='80' />
                            <col width='50' />
                        </colgroup>
                        { customers.length > 0 &&
                            <thead>
                                <tr>
                                    <th className="pt-0 pl-0" colSpan="2">
                                        <button
                                            children='Check-In All'
                                            className="btn btn-link p-0 m-0"
                                            disabled={isBookingCancelled(booking) || isBookingComplete(booking)}
                                            onClick={toggleCheckedInSelectAll}
                                        />
                                    </th>
                                    <th className="text-right pt-0" colSpan="2">
                                        <span className='d-block py-2'>Thrower</span>
                                    </th>
                                    <th />
                                </tr>
                            </thead>
                        }
                        <tbody>
                            {customers.map(c => (
                              <tr key={c.id} className={booking.checked_in.includes(c.id) ? 'checked-row' : ''}>
                                  <td className="pr-0">
                                      <div className="custom-control custom-checkbox">
                                          <input
                                              id={`participant-${c.id}`}
                                              className='custom-control-input mt-2'
                                              type='checkbox'
                                              name='internal'
                                              checked={booking.checked_in.includes(c.id)}
                                              disabled={isBookingCancelled(booking) || isBookingComplete(booking)}
                                              onChange={e => toggleCheckedIn(c.id, e.target.checked)}
                                          />

                                          {/* This empty label has to be here to make the custom checkbox work.
                                            Maybe we can use it for aria later. */}
                                          <label htmlFor={`participant-${c.id}`} className='custom-control-label'/>
                                      </div>
                                  </td>
                                  <td className="pr-4 text-wrap">{c.name}</td>
                                  <td className="px-0 text-wrap">
                                      <CustomerBadges
                                          customer={c}
                                          waiversRequired={booking.waivers_required}
                                          bookingActiveWaiver={booking.active_waiver}
                                      />
                                  </td>
                                  <td className="pl-2 pr-0">
                                      <div className="custom-control custom-checkbox">
                                          <input
                                              id={`spectator-${c.id}`}
                                              className='custom-control-input mt-2'
                                              type='checkbox'
                                              name='show_on_scoring_app'
                                              checked={isScoringChecked(c.id)}
                                              disabled={isBookingCancelled(booking) || isBookingComplete(booking)}
                                              onChange={(e) => toggleScoring(c.id, e.target.checked)}
                                          />

                                          {/* This empty label has to be here to make the custom checkbox work.
                                            Maybe we can use it for aria later. */}
                                          <label htmlFor={`spectator-${c.id}`} className='custom-control-label'/>
                                      </div>
                                  </td>
                                  <td className="px-0 text-right">
                                      <button
                                        children={<i className='fas fa-trash-alt' />}
                                        className="btn btn-danger text-white px-2 py-1 my-1 min-h-auto d-inline"
                                        title='Click to remove this participant'
                                        disabled={isBookingCancelled(booking) || isBookingComplete(booking)}
                                        onClick={() => handleRemove(c.id)}
                                      />
                                  </td>
                              </tr>
                            ))}
                            {/*<tr className="checked-row">*/}
                            {/*    <td className="pl-0 text-center">*/}
                            {/*        <div className="custom-control custom-checkbox">*/}
                            {/*            <input type='checkbox' name='internal' className='custom-control-input mt-2' id="participant1" defaultChecked />*/}
                            {/*            /!* This empty label has to be here to make the custom checkbox work. */}
                            {/*                Maybe we can use it for aria later. *!/*/}
                            {/*            <label htmlFor="participant1" className='custom-control-label'></label>*/}
                            {/*        </div>*/}
                            {/*    </td>*/}
                            {/*    <td>Name Lastname</td>*/}
                            {/*    <td>21+</td>*/}
                            {/*    <td><span className="badge badge-pill badge-info">Return</span></td>*/}
                            {/*    <td><span className="badge badge-pill badge-success">Valid</span></td>*/}
                            {/*    <td className="pl-0 text-center">*/}
                            {/*        <div className="custom-control custom-checkbox">*/}
                            {/*            <input type='checkbox' name='internal' className='custom-control-input mt-2' id="spectator1" />*/}
                            {/*            /!* This empty label has to be here to make the custom checkbox work. */}
                            {/*                Maybe we can use it for aria later. *!/*/}
                            {/*            <label htmlFor="spectator1" className='custom-control-label'></label>*/}
                            {/*        </div>*/}
                            {/*    </td>*/}
                            {/*    <td className="text-right"><button className="btn btn-link btn-link-danger p-0 d-inline">Remove</button></td>*/}
                            {/*</tr>*/}

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
