import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactJson from 'react-json-view'
import { BookingStatus } from '../BookingStatus'
import { BookingActions } from '../BookingActions'
import { BookingLedgerActions } from '../../../EditBooking/ledger/BookingActions'
import { QuoteLedgerActions } from '../../../EditBooking/ledger/QuoteActions'
import EditBookingLedger from '../../../EditBooking/EditBookingLedger'
import Tabs from '@/features/Schedule/components/steps/Tabs'
import { fetchBookingPriceBreakdown } from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'
import { selectBooking } from '../../../EditBooking/editBookingSlice'
import { selectDarkMode } from '@/features/Utilities/utilitiesSlice'
import { isBookingQuote, isQuoteExpiredOrRejected } from '@/lib/Booking'

export default function BookingSetupStepDebug({
    userName,
    handleClose,
    handleViewParent,
    children
}) {

    const dispatch  = useDispatch()
    const darkMode  = useSelector(selectDarkMode)
    const booking   = useSelector(selectBooking)

    const [isChild, setIsChild]                   = useState(false)
    const [pricingBreakdown, setPricingBreakdown] = useState({})

    useEffect(async () => {
        if (booking) {
            if (booking.parent_booking) {
                setIsChild(true)
            }

            dispatch(fetchBookingPriceBreakdown(booking.id, {
              include_payments: true,
              include_price_summary: true,
            }))
            .then(({ payload }) => setPricingBreakdown(payload))
        }
    }, [])

    return (
        <div className="drawer-scaffold">
            <div className="drawer-main">
                <div className="drawer-header-container">
                    <div className="drawer-header align-items-center pb-1">
                        <h2>
                            { booking.is_quote_expired ? 'EXPIRED' : /rejected/i.test(booking.quote_status) ? 'REJECTED' : 'Edit' }
                            <span className='d-none d-md-inline'> { isBookingQuote(booking) ? 'Quote' : 'Booking' }</span>
                        </h2>
                        {
                            !isBookingQuote(booking) && (
                                <BookingStatus isChild={isChild} />
                            )
                        }
                    </div>
                    <Tabs editMode />
                </div>
                <div className="drawer-main-content">
                    <div className="drawer-scroller">
                        <h2>DEBUGGING</h2>
                        <hr />
                        <ReactJson
                            src={pricingBreakdown}
                            style={{ fontSize: '12px' }}
                            theme={darkMode ? 'solarized' : 'rjv-default'}
                        />
                    </div>
                </div>

                <BookingActions handleClose={handleClose} />
            </div>

            <div className="drawer-aside">
                <EditBookingLedger userName={userName} handleViewParent={handleViewParent} />

                {
                    isBookingQuote(booking)
                        ? !isQuoteExpiredOrRejected(booking) ? <QuoteLedgerActions onError={(formErrors) => onError(formErrors)} /> : null
                        : <BookingLedgerActions />
                }
            </div>

            { children }
        </div>
    )
}
