import { createSlice } from "@reduxjs/toolkit"
import axios from 'axios'
import { debug } from '@/lib/Debug'
import { accessToken } from '@/lib/Csrf'
import { addAlert } from '@/features/Notifications/notificationSlice'

export const locationSlice = createSlice({
    name: 'location',
    initialState: {
        location: {},
        paymentProcessor: null,
    },
    reducers: {
        setLocation: (state, action) => {
            state.location = action.payload
        },
        setPaymentProcessor: (state, action) => {
            state.paymentProcessor = action.payload
        },
    }
})

export const {
    setLocation,
    setPaymentProcessor,
} = locationSlice.actions

export const selectLocation            = state => state?.location?.location
export const selectLocationId          = state => state.location.location?.id
export const selectContactPage         = state => state.location.location.contact_page_link
export const selectTimeZone            = state => state.location.location.time_zone
export const selectReservationsTaxable = state => state.location.location.reservations_taxable
export const selectPaymentProcessor    = state => state.location.location?.payment_processor || state.location.paymentProcessor
export const selectMembershipsEnabled  = state => state.location.location.memberships_enabled
export const generateGoogleReviewLink  = state => state.location?.location?.google_review_url
export const generateMemberLoginLink   = state => state.location?.location?.member_login_url

export function fetchLocation(url) {
    return async (dispatch) => {
        axios.get(url).then(({ data }) => {
            dispatch(setLocation(data))
        })
    }
}

export function sendTestEmail(url, to, email) {
    return async (dispatch, getState) => {
        if (debug && console) { console.log(url, to, email) }

        if (!url || !to || !email) {
            dispatch(addAlert({ type: 'error', text: 'Could not send test email.' }))
            return
        }

        axios.post(url, {
            authenticity_token: accessToken, to, email
        })
        .then(({ data }) => {
            dispatch(addAlert({ type: 'success', text: 'Test Email Sent!' }))
        })
        .catch((error) => {
            if (debug && console) { console.warn(error) }
            dispatch(addAlert({ type: 'error', text: error.response.data.error }))
        })
    }
}

export default locationSlice.reducer
