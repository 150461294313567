import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { addAlert } from '@/features/Notifications/notificationSlice'
import { debug } from '@/lib/Debug'

export const customerSlice = createSlice({
    name: 'customers',
    initialState: {
        query: null,
        customers: [],
        includesMinors: null,
        searchOpen: false,
        searched: false,
        totalFound: 0,
        totalShown: 0,
        totalPages: 0,
    },
    reducers: {
        setQuery: (state, action) => {
            state.query      = action.payload
            state.customers  = []
            state.searched   = false
            state.totalPages = 0
        },
        setCustomers: (state, action) => {
            state.customers = action.payload
        },
        setIncludesMinors: (state, action) => {
            state.includesMinors = action.payload
        },
        setSearchOpen: (state, action) => {
            state.searchOpen = action.payload

            if (!action.payload) {
                state.searched = false
            }
        },
        setSearched: (state, action) => {
            state.searched = action.payload
        },
        setTotalFound: (state, action) => {
            state.totalFound = action.payload
        },
        setTotalShown: (state, action) => {
            state.totalShown = action.payload
        },
        setTotalPages: (state, action) => {
            state.totalPages = action.payload
        },
    }
})

export const {
    setQuery,
    setCustomers,
    setSearchOpen,
    setSearched,
    setIncludesMinors,
    setTotalFound,
    setTotalShown,
    setTotalPages,
} = customerSlice.actions

export const selectQuery          = state => state.customers.query
export const selectCustomers      = state => state.customers.customers
export const selectSearchOpen     = state => state.customers.searchOpen
export const selectSearched       = state => state.customers.searched
export const selectIncludesMinors = state => state.customers.includesMinors
export const selectTotalFound     = state => state.customers.totalFound
export const selectTotalShown     = state => state.customers.totalShown
export const selectTotalPages     = state => state.customers.totalPages

export function searchCustomers(page=1) {
    return async (dispatch, getState) => {
        const query                    = encodeURIComponent(getState().customers.query)
        const companyId                = getState().location.location.company_id
        const resourceTypeId           = getState().resourceType.resourceType
        const bookingReservationNumber = getState().editBooking.booking.reservation_number

        if (!companyId) {
            if (debug && console) { console.warn('Cannot search -- cannot find company id') }
            return
        }

        if (!resourceTypeId) {
            if (debug && console) { console.warn('Cannot search -- cannot find resource type id') }
            return
        }

        if (!bookingReservationNumber) {
            if (debug && console) { console.warn('Cannot search -- cannot find booking reservation number') }
            return
        }

        if (!query) {
            if (debug && console) { console.warn('Cannot search -- a query is required') }
            return
        }

        axios.get(`/companies/${companyId}/customers/search?q=${query}&rt=${resourceTypeId}&brn=${bookingReservationNumber}&page=${page}`)
        .then(({ data }) => {
            dispatch(setSearched(true))
            dispatch(setCustomers(data.customers))
            dispatch(setIncludesMinors(data.includes_minors))
            dispatch(setTotalFound(data.total_found))
            dispatch(setTotalShown(data.total_shown))
            dispatch(setTotalPages(data.total_pages))
        })
        .catch((e) => {
            if (console) { console.warn('Search error: ', e) }
        })
    }
}

export function addCustomerToBooking(customerId, showInScoringApp=true) {
    return async (dispatch, getState) => {
        const token     = getState().session.formToken
        const bookingId = getState().editBooking.booking.id

        axios.post(`/bookings/${bookingId}/customers/${customerId}/add-to-booking`, {
            authenticity_token: token,
            show_in_scoring_app: showInScoringApp,
        }).then(({ data }) => {
            if (!data.success) {
                dispatch(addAlert({ type: 'error', text: data.message }))
                return
            }

            dispatch(setSearchOpen(false))
        }).catch((e) => {
            console.warn('Could not add customer to booking: ', e)
        })
    }
}

export function addCustomersToBooking(customerIds=[]) {
    return async (dispatch, getState) => {
        const token     = getState().session.formToken
        const bookingId = getState().editBooking.booking.id

        axios.post(`/bookings/${bookingId}/customers/add-to-booking`, {
            authenticity_token: token,
            customers: customerIds,
        }).then(({ data }) => {
            if (!data.success) {
                dispatch(addAlert({ type: 'error', text: data.message }))
                return
            }

            dispatch(setSearchOpen(false))
        }).catch((e) => {
            console.warn('Could not add customers to booking: ', e)
        })
    }
}

export function removeCustomerFromBooking(customerId, specificBookingId=null) {
    return async (dispatch, getState) => {
        const token     = getState().session.formToken
        const bookingId = specificBookingId || getState().editBooking.booking.id

        axios.delete(`/bookings/${bookingId}/customers/${customerId}/remove-from-booking`, {
            data: { authenticity_token: token }
        })
        .then(({ data }) => {
            if (!data.success) {
                dispatch(addAlert({ type: 'error', text: data.message }))
                return
            }
        })
    }
}

export default customerSlice.reducer
