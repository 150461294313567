import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectLocation } from '@/features/Locations/locationSlice'

import {
    selectAmountTowardsBalance,
    selectTipAmount,
    selectIsAmountValid,
    selectShouldOverProvision,
    setShouldOverProvision,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import AdyenTerminalTransaction from '@/features/Terminal/AdyenTerminalTransaction'
import Checkbox from '@/components/Form/Checkbox'
import PaymentForm from '../PaymentForm'
import { numberToCurrency } from '@/lib/Number'

export default function Terminal({
    self,
    totalDue,
    tabs,
    currentTab,
    onTabChange,
    onLoad,
    onSubmit,
}) {

    const dispatch             = useDispatch()
    const location             = useSelector(selectLocation)
    const amountTowardsBalance = useSelector(selectAmountTowardsBalance)
    const shouldOverProvision  = useSelector(selectShouldOverProvision)
    const isAmountValid        = useSelector(selectIsAmountValid)
    const tipAmount            = useSelector(selectTipAmount)

    const amountToProcess = useMemo(() => (
        parseFloat(tipAmount || 0) + parseFloat(amountTowardsBalance || 0)
    ), [tipAmount, amountTowardsBalance])

    const processButton = useMemo(() => <>
        <Checkbox
            cols=''
            name='should_over_provision'
            label={`Over Authorize? (${location.adyen_pre_authorized_transaction_over_provision_percentage}%)`}
            value={shouldOverProvision}
            className='mr-4'
            labelClassName='text-uppercase font-weight-bold'
            asFormGroup={false}
            handleChange={() => dispatch(setShouldOverProvision(!shouldOverProvision))}
        />

        <AdyenTerminalTransaction
            id='adyen-terminal-transaction'
            buttonText={`Process ${numberToCurrency(amountToProcess)}`}
            buttonTextProcessing='Please Wait...'
            buttonClassName='process-button float-right text-nowrap'
            menuClassName='border border-color-gray4'
            locationId={location.id}
            isAmountValid={isAmountValid}
            displayAsInline
            transactionCallback={() => {
                onSubmit({
                    totalReceivedAmount: amountToProcess,
                    tipAmount: tipAmount || 0,
                })
            }}
        />
    </>, [isAmountValid, tipAmount, amountToProcess, location, shouldOverProvision])

    useEffect(() => {
        onLoad(processButton)
    }, [processButton])

    return (
        <PaymentForm
            self={self}
            totalDue={totalDue}
            creditTabs={tabs}
            creditCurrentTab={currentTab}
            onCreditTabChange={onTabChange}
        />
    )
}
