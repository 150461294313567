import React, { useEffect, useState } from 'react'
import Wizard from '@/components/Form/Wizard'
import FormRow from '@/components/Form/FormRow'
import Select from '@/components/Form/Select'

export default function ChooseDestination({
    company_id=null,
    all_locations=[],
    adv_pos_locations=[],
}) {

    const hasAdvPosLocationsAvailable   = adv_pos_locations.length > 0
    const initialSteps                  = ['choose_destination']
    const [wizard, setWizardState]      = useState(null)
    const [steps, setSteps]             = useState(initialSteps)
    const [destination, setDestination] = useState(null)
    const [locations, setLocations]     = useState([])
    const [locationId, setLocationId]   = useState(null)

    const handleComplete = () => {
        const paths = {
            admin:          `/companies/${company_id}/locations`,
            admin_location: `/companies/${company_id}/locations/${locationId}/resource_types`,
            calendar:       `/companies/${company_id}/locations/${locationId}/calendar`,
            terminal:       `/companies/${company_id}/locations/${locationId}/pos/terminal`,
        }

        if (destination == 'admin' && !!locationId) {
            window.location.href = paths['admin_location']
        } else {
            window.location.href = paths[destination]
        }
    }

    useEffect(() => {
        switch(destination) {
            case 'admin' :
                if (all_locations.length > 1) {
                    setLocations(null)
                    setSteps(initialSteps)
                } else {
                    setLocationId(all_locations[0].id)
                    setSteps(initialSteps)
                }
                break

            case 'calendar' :
                if (all_locations.length > 1) {
                    setLocations(all_locations)
                    setSteps([...initialSteps, 'choose_location'])
                } else {
                    setLocationId(all_locations[0].id)
                    setSteps(initialSteps)
                }
                break

            case 'terminal' :
                if (adv_pos_locations.length > 1) {
                    setLocations(adv_pos_locations)
                    setSteps([...initialSteps, 'choose_location'])
                } else {
                    setLocationId(adv_pos_locations[0].id)
                    setSteps(initialSteps)
                }
                break
        }
    }, [destination])

    return (
        <div className='d-flex w-100 align-items-center justify-content-center' style={{ minHeight: 'calc(100vh - 60px)' }}>
            <Wizard
                className='card border rounded-xl p-5 m-4 w-100'
                style={{ maxWidth: '650px' }}
                steps={steps}
                prevButtonClassName='btn btn-link text-bold'
                completeButtonText={destination ? "Let's Go!" : 'Please Choose'}
                isNextDisabled={
                    (!destination && wizard?.isFinalStep)
                    || (destination && (!wizard?.isFirstStep && !locationId))
                }
                onPrevious={() => setLocations(null)}
                onChange={(state) => setWizardState(state)}
                onComplete={handleComplete}
            >

                <div>
                    <h4 className='my-4'>Where can we take you next?</h4>
                    <div className='btn-group w-100 d-flex justify-content-between' role='group'>
                        <button
                            type='button'
                            className={`btn btn-${destination === 'admin' ? '' : 'outline-'}primary mr-2 py-3 rounded`}
                            children='Admin'
                            onClick={() => setDestination('admin')}
                        />

                        <button
                            type='button'
                            className={`btn btn-${destination === 'calendar' ? '' : 'outline-'}primary ml-2 py-3 rounded`}
                            children='Calendar'
                            onClick={() => setDestination('calendar')}
                        />

                        {
                            hasAdvPosLocationsAvailable && (
                                <button
                                    type='button'
                                    className={`btn btn-${destination === 'terminal' ? '' : 'outline-'}primary ml-3 py-3 rounded`}
                                    children='POS Terminal'
                                    onClick={() => setDestination('terminal')}
                                />
                            )
                        }
                    </div>
                </div>

                {
                    steps.includes('choose_location') && locations?.length > 1 && (
                        <div>
                            <h4 className='my-4'>Which location?</h4>
                            <FormRow>
                                <Select
                                    cols='12'
                                    hideLabel={true}
                                    options={locations}
                                    value={locationId}
                                    includeBlank
                                    handleChange={(e) => setLocationId(e.target.value)}
                                />
                            </FormRow>
                        </div>
                    )
                }
            </Wizard>
        </div>
    )
}
