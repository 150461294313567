import React from 'react'
import { Provider } from 'react-redux'
import scheduleStore from '../../stores/scheduleStore'
import ScheduleDrawer from '../../features/Schedule/components/ScheduleDrawer'
import Calendar from "../../features/Calendar/Calendar";
import ConfirmationDialog from '@/features/Notifications/ConfirmationDialog'
import NotificationList from '@/features/Notifications/NotificationList'

export default function BookingModal({ occasionTypes, referralTypes, sourceTypes, packagesUrl, resourceTypeUrl }) {
    return (
        <Provider store={scheduleStore}>
            <ScheduleDrawer
                occasionTypes={occasionTypes}
                referralTypes={referralTypes}
                sourceTypes={sourceTypes}
                packagesUrl={packagesUrl}
                resourceTypeUrl={resourceTypeUrl} />

            <ConfirmationDialog />
            <NotificationList />
        </Provider>
    )
}
