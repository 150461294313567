import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectResourceType } from '@/features/ResourceType/resourceTypeSlice'
import { fetchCustomerTypes } from '@/features/CustomerTypes/customerTypeSlice'
import { selectPackageGroup, setPackageGroup, fetchPackagesForGroup } from '@/features/Packages/packageSlice'
import { setFormAttribute } from '@/features/Schedule/scheduleSlice'

export default function BookingPackageGroupOption({ group }) {

    const dispatch     = useDispatch()
    const packageGroup = useSelector(selectPackageGroup)
    const resourceType = useSelector(selectResourceType)

    const handleSelect = () => {
        dispatch(setPackageGroup(group))
        dispatch(fetchPackagesForGroup(`/resource_types/${resourceType}/fetch_group_packages?group=${group.id}`))
    }

    return (
        <div className="col-12 col-md-6">
            <label className="radio-as-card">

                <input
                    name='group_id'
                    type="radio"
                    value={group.id}
                    checked={packageGroup?.id === group.id}
                    onChange={handleSelect}
                />

                <div className="radio-card">
                    {group.name}
                </div>

            </label>
        </div>
    )
}
