import moment from 'moment-timezone'
import { debug } from '@/lib/Debug'

export default null

export function isBookingPaid(booking) {
    // paul specifically wants a booking's paid status icon to be red if
    // it's underpaid OR overpaid and to not rely on payment_status at all
    return parseInt(booking.balance_cents, 10) === 0
}

export function isBookingWaiverComplete(booking) {
    return booking.all_waiver_complete
}

export function isBookingCheckedIn(booking) {
    return booking.all_checked_in
}

export function isBookingTrainingStarted(booking) {
    return !!booking.training_started_at
}

export function isBookingTrainingComplete(booking) {
    return booking.training_complete && !!booking.trained_at
}

export function isBookingInProgress(booking) {
    return !!booking?.started_at
}

export function isBookingWarned(booking) {
    return !!booking.warned_at
}

export function isBookingComplete(booking) {
    return !!booking?.ended_at
}

export function isBookingCancelled(booking) {
    return /^cancelled$/i.test(booking?.status)
}

export function isBookingHeld(booking) {
    return /^hold$/i.test(booking.status)
}

export function isBookingQuote(booking) {
    return booking?.is_quote
}

export function isBookingReserved(booking) {
    return /^reserved$/i.test(booking.status)
}

export function isQuoteExpiredOrRejected(booking) {
    return /rejected|expired/i.test(booking?.quote_status) || booking?.is_quote_expired
}

export function textStatusForBooking(booking) {
    switch(true) {
        case isBookingCancelled(booking):
            return 'cancelled'

        case isBookingComplete(booking):
            return 'complete'

        case isBookingInProgress(booking):
        case isBookingWarned(booking):
              return 'in progress'

        default:
            return booking.status
    }
}

export function textStatusForQuote(booking) {
    return (booking?.quote_status || '').replace(/^quote_/i, '')
}

export function statusOf(booking) {
    if (booking.type === 'buffer') {
        return 'buffer'
    }

    // CSS CLASSES ---------------------------------------------------
    // overlap (used when two or more events are overlapping on the calendar. This class should be added after the status class eg: <div className="event upcoming overlap">)
    // reserved
    // upcoming
    // in-progress
    // warned
    // complete
    // temp (this class is used in the period of time between the event's creation and its being committed to the schedule, at which point it would change to .reserved)
    //
    // RUBY CLASSES --------------------------------------------------
    // open - expired booking
    // reserved - completed booking, set initial payment_status
    // (upcoming) - not a status, just a state of being
    // in_progress - actually started (manually triggered)
    // warned - 15 min left (manually triggered)
    // complete - it’s over (manually triggered)

    switch (true) {
        case isBookingQuote(booking) :
            return 'quote' // neon purple with striped border

        case startsWithin15Minutes(booking)
                && !isBookingHeld(booking)
                && !isBookingInProgress(booking)
                && !isBookingWarned(booking)
                && !isBookingComplete(booking) :
            return 'upcoming' // yellow

        case endsWithin15Minutes(booking)
                && !isBookingHeld(booking)
                && isBookingInProgress(booking)
                && !isBookingWarned(booking)
                && !isBookingComplete(booking) :
            return 'ending-soon' // orange

        case isBookingHeld(booking) :
            return 'temp' // ghosted/white with striped border

        case isBookingComplete(booking) :
            return 'complete' // solid white

        case isBookingWarned(booking) :
            return 'warned' // red

        case isBookingInProgress(booking) :
            return 'in-progress' // green

        case isBookingReserved(booking) :
            return 'reserved' // blue

        default :
            return 'has_problems' // dark purple
    }
}

export function startsWithin15Minutes(booking) {
    const start = moment(booking.start_time)
    const now   = moment()
    const diff  = start.diff(now, 'minutes')
    return (diff <= 15)
}

export function endsWithin15Minutes(booking) {
    if (!booking?.calculated_end_time) { return false }
    const now  = moment()
    const end  = moment(booking?.calculated_end_time)
    const diff = end.diff(now, 'minutes')
    return (diff >= 0 && diff <= 15)
}

// if participants is a single number (ex: 1) or a range (ex: 100-200),
// return either the single number (1) or the last number of the range (200)
export function parsedParticipants(participants) {
    return Number(
        /-/.test(participants)
            ? String(participants).split('-')[1]
            : participants
    )
}

export function participantMaxCount(pkg=null, price_group=null) {
    if (!pkg || !price_group) { return 0 }

    const current_participants   = Number.parseInt(pkg.current_participants, 10)   || 0
    const special_event_capacity = Number.parseInt(pkg.special_event_capacity, 10) || 0
    const price_group_max        = Number.parseInt(price_group.group_max, 10) || Number.parseInt(price_group.max, 10) || 10

    if (debug && console) {
        console.log(
            [
                'PARTICIPANT MAX COUNT',
                pkg.recurring_type.replace(/_/, ' '),
                (pkg.is_special_event ? 'SPECIAL EVENT' : ''),
                (pkg.is_a_league ? 'LEAGUE' : ''),
                price_group.price_type.replace(/_/, ' ')
            ].filter(s => s !== '').join(' | ').toUpperCase(),
            {
                current_participants: current_participants,
                special_event_capacity: special_event_capacity,
                is_special_event: pkg.is_special_event,
                is_a_league: pkg.is_a_league,
                recurring_type: pkg.recurring_type,
                price_group_max: price_group_max
            },
            price_group
        )
    }

    // special event packages
    if (pkg.is_special_event) {
        if (pkg.is_a_league || /single/i.test(pkg.recurring_type)) {
            // @note: current_participants is only a relevant number when the special event is a single event or
            // a recurring one that uses league scheduling (because those only allow first event reservations).
            //
            // per person pricing
            // ex: 20 slots | 5 already booked  | 15 remaining slots | 1-4 price group max | max of 4 returned
            // ex: 20 slots | 17 already booked | 3 remaining slots  | 1-4 price group max | max of 3 returned
            //
            // group ranged pricing
            // ex: 20 slots | 5 already booked  | 15 remaining slots | 1-4 price group max | max of 4 returned
            // ex: 20 slots | 17 already booked | 3 remaining slots  | 1-4 price group max | max of 0 returned (hides option as 3 < group max)
            //
            const remaining_slots = special_event_capacity - current_participants
            const group_price_max = (price_group_max <= remaining_slots) ? price_group_max : 0
            const per_person_max  = (price_group_max <= remaining_slots) ? price_group_max : remaining_slots
            const resulting_max   = /group_price/i.test(price_group.price_type) ? group_price_max : per_person_max

            if (debug && console) {
                console.log({
                    special_event_capacity,
                    current_participants,
                    remaining_slots,
                    price_group_max,
                    group_price_max,
                    per_person_max,
                    resulting_max
                })
            }

            return isNaN(resulting_max) || resulting_max < 0 ? 0 : resulting_max
        } else {
            // @note: for recurring special events that have spawned multiple parent bookings, this number is
            // irrelevant because it acts like a total sum of ALL participants across ALL event instances but
            // the special_event_capacity is a PER EVENT amount. In this case, the price_group_max is returned
            // and times_available_for will figure out if a time slot / special event is bookable.
            //
            // group ranged pricing and per person pricing
            // ex: 20 slots | 5 already booked  | 15 remaining slots | 1-4 price group max | max of 4 returned
            // ex: 20 slots | 17 already booked | 3 remaining slots  | 1-4 price group max | max of 4 returned
            //
            return price_group_max
        }
    // normal packages
    } else {
        return price_group_max
    }
}

// @NOTE:
// ---------------------------------------------------------------------------------------
//  filteredDiscounts.discount_record.XYZ ==> the object structure for EXISTING bookings
//  filteredDiscounts.XYZ                 ==> the object structure for NEW bookings
// ---------------------------------------------------------------------------------------
export function ledgerDiscountDependenciesFor(customerTypeId=null, customerTypes=null, filteredDiscount=null, isGroup=false) {
    try {
        let shouldDiscount = false

        // find the discount
        const discount = !isNaN(parseFloat(filteredDiscount?.discount_record?.amount || filteredDiscount?.amount))
            ? parseFloat(filteredDiscount?.discount_record?.amount || filteredDiscount?.amount) * -1
            : 0

        let dollar = /dollar/i.test(filteredDiscount?.discount_record?.amount_type || filteredDiscount?.amount_type)
            ? discount
            : null

        // determine if the multiplier is a percentage (10.2% becomes 0.102) or simple cash (10.2 is assumed to be $10.2)
        let percentage = /percent/i.test(filteredDiscount?.discount_record?.amount_type || filteredDiscount?.amount_type)
            ? discount / 100
            : null

        // if the booking uses group pricing OR uniform pricing without defined customer types
        if (isGroup || (!isGroup && customerTypeId === null)) {
            shouldDiscount = true

        // if the booking uses customer type pricing
        } else {
            const discountCustomerTypes = filteredDiscount?.discount_record?.customer_types || filteredDiscount?.customer_types || []

            // do not discount customer types that are not to be discounted
            shouldDiscount = (customerTypes.length > 0 && discountCustomerTypes.length === 0)
                ? customerTypes.includes(customerTypeId)
                : discountCustomerTypes.includes(customerTypeId)
        }

        return [dollar, percentage, shouldDiscount]
    } catch(e) {
        console.error(e)
        return [null, null, null]
    }
}
