import { createSlice } from "@reduxjs/toolkit"
import axios from 'axios'

export const managerCodeSlice = createSlice({
    name: 'managerCode',
    initialState: {
        modal: {
            isModalOpen: false,
            options: {},
        },
        valid: false,
        error: null,
        code: null,
    },
    reducers: {
        showManagerOverride: (state) => {
            state.modal.show = true
        },
        hideManagerOverride: (state) => {
            state.modal.show = false
        },
        setManagerOverrideOptions: (state, action) => {
            state.modal.options = action.payload
        },
        setValid: (state, action) => {
            state.valid = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        },
        setCode: (state, action) => {
            state.code = action.payload
        },
        clear: (state) => {
            state.valid = false
            state.error = null
            state.code  = null
        }
    }
})

export const {
    showManagerOverride,
    hideManagerOverride,
    setManagerOverrideOptions,
    setValid,
    setError,
    setCode,
    clear
} = managerCodeSlice.actions

export const selectIsModalOpen = state => state.managerCode.modal.show
export const selectModal       = state => state.managerCode.modal
export const selectOptions     = state => state.managerCode.modal.options
export const selectCode        = state => state.managerCode.code
export const selectValid       = state => state.managerCode.valid
export const selectError       = state => state.managerCode.error

export function validateManagerCode(locationId, code, throwErrorIfUnauthorized=false) {
    return async (dispatch, getState) => {
        if (!locationId) {
            dispatch(setValid(false))
            dispatch(setError('Missing parameter'))
            return false
        }

        if (!code) {
            dispatch(setValid(false))
            dispatch(setError('You must enter a code'))
            return false
        }

        return axios.post(`/locations/${locationId}/validate_manager_code`, {
            authenticity_token: getState().session.formToken,
            code: code
        })
        .then(({ data }) => {
            dispatch(setValid(data.answer))
            dispatch(setError(data.answer ? null : 'Invalid code'))

            if (data.answer) {
                dispatch(setCode(code))
            } else {
                if (throwErrorIfUnauthorized) { throw 'Invalid code' }
            }
        })
    }
}

export default managerCodeSlice.reducer
