// base source: https://stackoverflow.com/a/14428340
// NOTE can be replaced with lib/Currency.js which uses Intl.NumberFormat built-in
export function numberToCurrency(value, options) {
    try {
        const amount = Number(value)

        if (isNaN(amount)) {
            return value
        }

        const { unit, separator, precision, ...rest } = {
            unit: options?.unit || '$',
            separator: options?.separator || ',',
            precision: options?.precision === 0 ? 0 : options?.precision || 2,
        }

        return `${unit}${amount.toFixed(precision)}`                                // create string of unit + amount
                               .replace(RegExp('^\\' + unit + '\\-'), `-${unit}`)   // swap negative sign and unit if negative amount
                               .replace(/\d(?=(\d{3})+\.)/g, `$&${separator}`)      // convert to (-)$1,000,000,000.00 format
    } catch(e) {
        if (console) {
            console.error(`Could not convert ${value} to currency format`)
            console.error(e)
        }
        return value
    }
}

export function roundUp(value, scale=100) {
    return (Math.round(scale * Number.parseFloat(value)) / scale)
}
