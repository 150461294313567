import React, { useState } from 'react'
import DrawerTabs from '@/components/Drawer/Tabs'
import CategoryDetails from './CategoryDetails'
import CategoryItems from './CategoryItems'
import CategorySubCategories from './CategorySubCategories'

const tabs = ['details', 'items', 'sub_categories']

export default function CategoryForm(props) {

    const [tab, setTab] = useState(tabs[0])

    const renderTab = () => {
        switch (tab) {
            case tabs[0] :
                return <CategoryDetails {...props} />

            case tabs[1] :
                return <CategoryItems {...props} />

            case tabs[2] :
                return <CategorySubCategories {...props} />
        }
    }

    return (
        <div>
            <DrawerTabs
                activeTab={tab}
                tabs={tabs}
                handleClick={setTab}
            />
            {renderTab()}
        </div>
    )
}
