import React from 'react'
import { useSelector } from 'react-redux'

import {
    selectSelectedType,
    selectSelectedTerm,
    selectMemberInformation,
    selectBuyerInformation,
    selectAutoRenew,
    selectMembership,
    selectPurchaseFor,
    selectReturnUrl,
} from '../memberSlice'

import { termLength, termLengthLabel } from '@/lib/Member'

export default function Confirmation() {

    const membershipType    = useSelector(selectSelectedType)
    const membershipTerm    = useSelector(selectSelectedTerm)
    const memberInformation = useSelector(selectMemberInformation)
    const buyerInformation  = useSelector(selectBuyerInformation)
    const autoRenew         = useSelector(selectAutoRenew)
    const membership        = useSelector(selectMembership)
    const purchaseFor       = useSelector(selectPurchaseFor)
    const returnUrl         = useSelector(selectReturnUrl)
    const queryParameters   = new URLSearchParams(window.location.search)

    return (
        <div className="xbm-step-content xbm-confirmation">
            <div className="xbm-step-content-main">
                <div className="xbm-step-counter">Membership</div>

                <div className="xbm-step-question">
                    <h3 className="mb-4 text-center">{membershipType.name}</h3>
                    <h4 className="mb-4 text-center">
                        { termLength(membershipTerm) } { termLengthLabel(membershipTerm) } - {autoRenew ? `Recurring` : 'Non-Recurring'}
                    </h4>
                </div>

                <hr />

                <div className="xbm-step-counter">Membership ID</div>

                <div className="xbm-step-question">
                    <h3 className="mb-4 text-center">{membership?.membership_id}</h3>
                </div>

                <hr />

                <div className="xbm-step-counter">Thank you for purchasing a membership to</div>

                <div className="xbm-step-question">
                    <h3 className="mb-4 text-center">{queryParameters.get("location_name")}</h3>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-10 text-center">
                                <p>{memberInformation.firstName} {memberInformation.lastName} <br /> {memberInformation.email}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <hr />

                <div className="xbm-step-question">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-10 text-center">
                                <p>
                                    A confirmation email has been sent to <strong>{!!buyerInformation.email ? buyerInformation.email : memberInformation.email}</strong> with the
                                    membership details. Please keep this information for your records.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="xbm-running-ledger" style={{ padding: "1em" }}>
                <h2 className="mb-4">Next Steps</h2>
                <ol>
                    <li>Check your email to confirm your account details and order receipt.</li>
                    {
                        /^myself$/i.test(purchaseFor) && !!returnUrl ? (
                            <li>
                                <a href={returnUrl} onClick={() => { window.setTimeout(() => window.close(), 50); return true; }} target='_blank' rel='noopener nofollow'>Return to your membership portal</a>
                                &nbsp;to manage your account, update your payment information, and renew your membership.
                            </li>
                        ) : /^myself$/i.test(purchaseFor) ? (
                            <li>Log in to your membership portal to manage your account, update your payment information, and renew your membership.</li>
                        ) : <>
                            <li>Start the account password creation process.</li>
                            <li>Log in to your membership portal to manage your account, update your payment information, and renew your membership.</li>
                        </>
                    }
                </ol>
            </div>
        </div>
    )
}
