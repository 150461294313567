import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectPaymentTypes } from '@/features/Packages/packageSlice'
import { selectBasePrice, selectTotal, selectTaxesAndFees, selectDeposit, selectAutoGratuity } from '@/features/Pricing/pricingSlice'
import { selectBooking, selectStep, selectPaymentType, selectGiftCards } from '@/features/Bookings/bookingSlice'
import { numberToCurrency } from '@/lib/Number'

export default function LedgerPrices() {

    const booking         = useSelector(selectBooking)
    const step            = useSelector(selectStep)
    const basePrice       = useSelector(selectBasePrice)
    const autoGratuity    = useSelector(selectAutoGratuity)
    const taxesAndFees    = useSelector(selectTaxesAndFees)
    const calculatedTotal = useSelector(selectTotal)
    const deposit         = useSelector(selectDeposit)
    const paymentTypes    = useSelector(selectPaymentTypes)
    const paymentType     = useSelector(selectPaymentType)
    const giftCards       = useSelector(selectGiftCards)
    const total           = paymentTypes.authorize_card_only ? 0 : calculatedTotal

    const [totalAfterGiftCard, setTotalAfterGiftCard] = useState(0)

    if (!/5|6/.test(step)) {
        return null
    }

    useEffect(() => {
        const paymentDue = paymentType === 'deposit_only' ? deposit : total

        if (giftCards.length === 0) {
            setTotalAfterGiftCard(paymentDue)
        } else {
            const appliedTotal = giftCards.reduce((sum, giftCard) => {
                return sum + giftCard.appliedAmount
            }, 0)
            const remainder = paymentDue - appliedTotal / 100.0
            setTotalAfterGiftCard(remainder)
        }
    }, [total, deposit, giftCards])

    const balanceDueOnArrival = () => {
        if (paymentTypes.authorize_card_only) {
            return calculatedTotal
        } else if (paymentType === 'deposit_only') {
            return (calculatedTotal - deposit).toFixed(2)
        } else {
            return  '0.00'
        }
    }

    return !!booking.taxes_and_fees_verbiage && (
        <div className="xbm-ledger-section-costs mt-3 my-md-3">
            <div className="xbm-ledger-section-type">
                <div className="xbm-ledger-section-action-label-label">
                    Subtotal
                </div>
                <div className="dots" />
                <div className="xbm-ledger-section-action-label-action">
                    {numberToCurrency(basePrice)}
                </div>
            </div>
            <div className="xbm-ledger-section-type">
                <div className="xbm-ledger-section-action-label-label">
                    {booking.taxes_and_fees_verbiage}
                </div>
                <div className="dots" />
                <div className="xbm-ledger-section-action-label-action">
                    {numberToCurrency(taxesAndFees)}
                </div>
            </div>
            {
                Boolean(autoGratuity) && (
                    <div className="xbm-ledger-section-type">
                        <div className="xbm-ledger-section-action-label-label">
                            Gratuity
                        </div>
                        <div className="dots" />
                        <div className="xbm-ledger-section-action-label-action">
                            {numberToCurrency(autoGratuity)}
                        </div>
                    </div>
                )
            }
            <div className="xbm-ledger-section-type">
                <div className="xbm-ledger-section-action-label-label">
                    Total
                </div>
                <div className="dots" />
                <div className="xbm-ledger-section-action-label-action">
                    <strong>{numberToCurrency(total)}</strong>
                </div>
            </div>
            {
                paymentType === 'deposit_only' && (
                    <div className="xbm-ledger-section-type">
                        <div className="xbm-ledger-section-action-label-label">
                            Deposit
                        </div>
                        <div className="dots" />
                        <div className="xbm-ledger-section-action-label-action">
                            {numberToCurrency(deposit)}
                        </div>
                    </div>
                )
            }
            {
                giftCards.map((giftCard, index) => {
                    return (
                        <div key={`gift-card-applied-${index}`} className="xbm-ledger-section-type">
                            <div className="xbm-ledger-section-action-label-label">
                                Gift Card Ending in { giftCard.cardNumber.slice(-4) }
                            </div>
                            <div className="dots" />
                            <div className="xbm-ledger-section-action-label-action">
                                - {numberToCurrency(giftCard.appliedAmount / 100)}
                            </div>
                        </div>
                    )
                })
            }
            <div className="xbm-ledger-section-type">
                <div className="xbm-ledger-section-action-label-label">
                    Payment Due
                </div>
                <div className="dots" />
                <div className="xbm-ledger-section-action-label-action">
                    <strong>{numberToCurrency(totalAfterGiftCard)}</strong>
                </div>
            </div>
            <div className="xbm-ledger-section-type">
                <div className="xbm-ledger-section-action-label-label">
                    Remaining Balance
                </div>
                <div className="dots" />
                <div className="xbm-ledger-section-action-label-action">
                    {/* When we are only paying a deposit, subtract the total with that amount, for full payments just show 0 remaining */}
                    {numberToCurrency(balanceDueOnArrival())}
                </div>
            </div>
        </div>
    )
}
