import React from 'react'
import { useSelector } from 'react-redux'
import { selectCards } from './memberSlice'
import SelectCardCheckbox from './SelectCardCheckbox'

export default function CardsOnFile({ withoutTitle=false }) {
    const cards = useSelector(selectCards)

    return cards?.length > 0 && (
        <div className="container form-container payment-container my-5">
            {!withoutTitle && <h5>{ cards.length === 1 ? 'Card' : 'Cards' } On File</h5>}
            { cards.map(card => <SelectCardCheckbox key={card.id} card={card} />) }
        </div>
    )
}
