import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectModals } from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'
import { calculateSubtotalAmountCents } from '@/features/AdvancedPointOfSale/lib/Items'
import { selectLocation } from '@/features/Locations/locationSlice'
import { numberToCurrency } from '@/lib/Number'
import { addAlert } from '@/features/Notifications/notificationSlice'
import { debug } from '@/lib/Debug'

export default function DiscountModal({
    items=[],
    onClose=() => {},
    onSave=() => {}
}) {

    const dispatch                  = useDispatch()
    const { manageCheckItem:modal } = useSelector(selectModals)
    const location                  = useSelector(selectLocation)

    const [isDiscounted, setIsDiscounted]             = useState(false)
    const [shouldRemindToSave, setShouldRemindToSave] = useState(false)
    const [amount, setAmount]                         = useState(0)
    const [amountType, setAmountType]                 = useState('percent')
    const [notes, setNotes]                           = useState(null)
    const [selectedDiscountId, setSelectedDiscountId] = useState('')

    // dynamically calculate the price and discount
    // for either multiple items or a single item
    const { priceCents, discountCents } = useMemo(() => {
        const itemPrices = items.map((item) => (
            calculateSubtotalAmountCents(
                item?.item,
                item?.config,
                { amount, amountType }
            )
        ))

        const result = {
            priceCents: itemPrices.reduce((sum, itemPrice) => (
                sum + (!!itemPrice.priceCents    ? Number.parseFloat(itemPrice.priceCents) : 0)
            ), 0),
            discountCents: itemPrices.reduce((sum, itemPrice) => (
                sum + (!!itemPrice.discountCents ? Number.parseFloat(itemPrice.discountCents) : 0)
            ), 0),
        }

        if (debug && console) { console.log(result) }

        return result
    }, [items, amount, amountType])

    const handleAmountTypeChange = (amountType) => {
        if (amountType === 'dollar' && parseFloat(amount) > (priceCents / 100)) {
            setAmount(0)
        }

        setAmountType(amountType)
    }

    const handleAmountChange = (amount) => {
        switch (true) {
            case amountType === 'dollar'  && parseFloat(amount) > (priceCents / 100) :
            case amountType === 'percent' && parseFloat(amount) > 100 :
                return

            default :
                setAmount(amount
                    ? Number(parseFloat(amount).toFixed(2)).toString()
                    : 0
                )
        }
    }

    const handleSelectDiscount = (discountId) => {
        setSelectedDiscountId(discountId)

        if (!!discountId) {
            const discount = location.item_discounts.find(discount => discount.id === parseInt(discountId))

            setAmountType(discount.amount_type)
            setAmount(discount.amount)
        } else {
            setAmount(0)
        }
    }

    const handleRemove = () => {
        setAmount(0)
        setNotes('')

        if (isDiscounted) {
            setShouldRemindToSave(true)
        }
    }

    const handleSave = () => {
        if (priceCents < discountCents) {
            dispatch(addAlert({ type: 'error', text: 'Discount amount cannot be larger than subtotal.' }))
        } else {
            const discount = {
                amount: amountType === 'percent' ? amount : amount * 100,
                amount_type: amountType,
                notes: notes,
            }

            if (!!selectedDiscountId) {
                discount.item_discount_id = selectedDiscountId
            }

            onSave(discount)
        }
    }

    // populate existing item discount
    // info into the form on load
    useEffect(() => {
        if (items.length === 1) {
            const item = items[0]

            if (item?.discount && item.discount.amount > 0) {
                setAmount(item.discount.amount_type === 'percent' ? item.discount.amount : item.discount.amount / 100)
                setAmountType(item.discount.amount_type)
                setSelectedDiscountId(item.discount.item_discount_id)
                setNotes(item.discount.notes)
                setIsDiscounted(true)
            }
        }
    }, [])

    return !!modal && <>
        {location.item_discounts.length > 0 && <>
            <div className="form-group mb-3">
                <label>Available discounts</label>
                <div>
                    <select
                        name="printer_id"
                        className="custom-select"
                        value={selectedDiscountId}
                        onChange={e => handleSelectDiscount(e.target.value)}
                    >
                        <option value="">Custom</option>
                        {location.item_discounts.map(item_discount => (
                            <option value={item_discount.id} key={item_discount.id}>
                                {item_discount.name} - {item_discount.amount_type === 'percent' ? item_discount.amount + '%' : numberToCurrency(item_discount.amount)}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </>}
        <div className="d-flex justify-content-between">
            <div className="w-50 d-flex align-items-start">
                <div className="radio-as-button pr-2">
                    <input
                        id="customAmountDollar"
                        name="customAmountType"
                        type="radio"
                        value="dollar"
                        checked={amountType === 'dollar'}
                        className={`radio-as-button-input ${!!selectedDiscountId ? 'disabled' : ''}`}
                        disabled={!!selectedDiscountId}
                        onChange={e => handleAmountTypeChange(e.target.value)}
                    />

                    <label
                        children={<i className='far fa-dollar-sign text-white fa-lg' />}
                        htmlFor="customAmountDollar"
                        className={`radio-as-button-label d-flex mb-0 px-3 border-0 align-items-center ${amountType === 'dollar' ? 'bg-green' : 'bg-gray3'} ${!!selectedDiscountId ? 'disabled' : ''}`}
                    />
                </div>

                <div className="radio-as-button pr-2">
                    <input
                        id="customAmountPercent"
                        name="customAmountType"
                        type="radio"
                        value="percent"
                        checked={amountType === 'percent'}
                        className={`radio-as-button-input ${!!selectedDiscountId ? 'disabled' : ''}`}
                        disabled={!!selectedDiscountId}
                        onChange={e => handleAmountTypeChange(e.target.value)}
                    />

                    <label
                        children={<i className='far fa-percent text-white fa-lg' />}
                        htmlFor="customAmountPercent"
                        className={`radio-as-button-label d-flex mb-0 px-3 border-0 align-items-center ${amountType === 'percent' ? 'bg-green' : 'bg-gray3'} ${!!selectedDiscountId ? 'disabled' : ''}`}
                    />
                </div>

                <input
                    className={`form-control text-left ${!!selectedDiscountId ? 'text-gray4 bg-gray2' : ''}`}
                    type="number"
                    min='0'
                    step={amountType === 'percent' ? '0.1' : '0.01'}
                    max={amountType === 'percent' ? 100 : (priceCents / 100)}
                    placeholder="Enter Amount"
                    value={amount}
                    disabled={!!selectedDiscountId}
                    onChange={e => handleAmountChange(e.target.value)}
                />
            </div>

            <div className="w-50 ml-3">
                <div className="d-flex justify-content-between">
                    <div>Subtotal:</div>
                    {
                        priceCents !== null && priceCents !== undefined && (
                            <strong>{numberToCurrency(priceCents / 100)}</strong>
                        )
                    }
                </div>
                <div className="d-flex justify-content-between">
                    <div className={discountCents === 0 ? 'text-gray4' : 'text-white'}>
                        Discount:
                    </div>

                    {
                        discountCents !== null && discountCents !== undefined && (
                            <div className={discountCents === 0 ? 'text-gray4' : 'text-success'}>
                                {numberToCurrency(discountCents === 0 ? 0 : (discountCents / 100 * -1))}
                            </div>
                        )
                    }
                </div>
                <div className="d-flex justify-content-between">
                    <div>Adjusted Subtotal:</div>
                    {
                        priceCents !== null && priceCents !== undefined && (
                            <strong>{numberToCurrency((priceCents - (!!discountCents ? discountCents : 0)) / 100)}</strong>
                        )
                    }
                </div>
            </div>
        </div>

        <textarea
            name='notes'
            placeholder='Notes'
            className='form-control mt-3 py-2'
            value={notes || ''}
            onChange={e => setNotes(e.target.value)}
        />

        <div className="modal-footer mx-n4 mt-4 px-4 pb-0 border-top border-thick border-top-gray0">
            <button
                children='Close'
                className='btn btn-outline-secondary text-white mr-auto'
                onClick={onClose}
            />
            {
                isDiscounted && (
                    <button
                        children='Remove'
                        className='btn btn-outline-primary'
                        disabled={discountCents === 0}
                        onClick={handleRemove}
                    />
                )
            }
            <button
                children='Save'
                className={`btn btn-primary ml-2 ${shouldRemindToSave ? 'fa-fade' : ''}`}
                disabled={
                    (isDiscounted && (isNaN(discountCents) || discountCents < 0))
                    || (!isDiscounted && (isNaN(discountCents) || discountCents <= 0))
                }
                onClick={handleSave}
            />
        </div>
    </>
}
