import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { selectMemberInformation, setMemberInformationField } from '../memberSlice'
import { errorsFor } from '@/components/Form/ErrorsHelper'
import Input from '../../../components/Form/Input'
import Select from '../../../components/Form/Select'
import MemberEmail from '../MemberEmail'
import DateSelectFields from '../../../components/Form/DateSelectFields'
import MemberStep from './MemberStep'
import MemberSteps from '@/lib/Member'
import { validationPatterns } from '@/lib/Errors'

export default function GiftedMemberInformation({ occasionOptions=[], hideMobileProgressHeader }) {

    const dispatch = useDispatch()
    const info     = useSelector(selectMemberInformation)

    const [errors, setErrors] = useState({})

    const handleChange = (name, value) => {
        dispatch(setMemberInformationField({ name, value }))
    }

    const onError = (formErrors) => {
        setErrors(errorsFor(formErrors))
    }

    const disabled = () => {
        return isEmpty(info.firstName) ||
               isEmpty(info.lastName)  ||
               isEmpty(info.email)
    }

    return (
        <MemberStep
            disabled={disabled()}
            backStep={MemberSteps.MEMBERSHIP_TERM}
            nextStep={MemberSteps.GIFTED_BUYER_INFORMATION}
            hideMobileProgressHeader={hideMobileProgressHeader}
            onError={onError}
        >

            <h2 className="text-center pt-md-4 pb-4">Member Information</h2>

            <h5 className='text-center font-weight-light pb-4'>
                Enter the information below for the lucky recipient. <br className='d-none d-md-block' />
                We will not contact the recipient in any way; we'll leave that fun up to you. <br className='d-none d-md-block' />
                Your information will be entered later.
            </h5>

            <div className="row">
                <Input
                    req={true}
                    type='text'
                    className='form-control'
                    label='FIRST NAME'
                    name='firstName'
                    placeholder='First Name'
                    value={info.firstName}
                    cols='12 col-md-6'
                    handleChange={e => handleChange('firstName', e.target.value)}
                />

                <Input
                    req={true}
                    type='text'
                    className='form-control'
                    label='LAST NAME'
                    name='lastName'
                    placeholder='Last Name'
                    value={info.lastName}
                    cols='12 col-md-6'
                    handleChange={e => handleChange('lastName', e.target.value)}
                />
            </div>

            <div className="row">
                <MemberEmail ignoreConfirm={true} />

                <Input
                    name='phone'
                    label='PHONE'
                    value={info.phone}
                    mask='999-999-9999'
                    cols='12 col-md-6'
                    handleChange={e => handleChange('phone', e.target.value)}
                />
            </div>

            <div className="row">
                <div className="col-12 col-md-6">
                    <DateSelectFields
                        month={Number((info.birthdate?.split('-'))?.[0])}
                        day={Number((info.birthdate?.split('-'))?.[1])}
                        year={Number((info.birthdate?.split('-'))?.[2])}
                        errors={errors}
                        onChange={(_, value) => handleChange('birthdate', moment(value).format('M-D-YYYY'))}
                    />
                </div>
                <Input
                    req={true}
                    name='zip_code'
                    label='ZIPCODE'
                    value={info.zip_code}
                    cols='12 col-md-6'
                    validation={{ 'zip_code': {
                        required: true,
                        pattern: validationPatterns.zip,
                    }}}
                    errors={errors}
                    handleChange={e => handleChange('zip_code', e.target.value)}
                />
            </div>

            <div className="row">
                {occasionOptions.length > 0 &&
                    <Select
                        name='occasion'
                        label="WHAT'S THE OCCASION?"
                        value={info.occasion}
                        options={occasionOptions}
                        cols='12 col-md-6'
                        handleChange={e => handleChange('occasion', e.target.value)}
                    />
                }
            </div>
        </MemberStep>
    )
}
