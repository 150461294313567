import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import {
    selectAutoRenew,
    selectRecipientType,
    selectSelectedTerm,
    selectTerms,
    selectPurchaseFor,
    setAutoRenew,
    selectMembership,
    selectSelectedType,
} from '../memberSlice'

import MemberStep from './MemberStep'
import Checkbox from '../../../components/Form/Checkbox'
import TermRadio from '../TermRadio'
import MemberSteps, { RecipientTypes } from '@/lib/Member'

export default function MembershipTerm({ hideMobileProgressHeader }) {

    const dispatch       = useDispatch()
    const willAutoRenew  = useSelector(selectAutoRenew)
    const terms          = useSelector(selectTerms)
    const recipient      = useSelector(selectRecipientType)
    const term           = useSelector(selectSelectedTerm)
    const membership     = useSelector(selectMembership)
    const membershipType = useSelector(selectSelectedType)
    const purchaseFor    = useSelector(selectPurchaseFor)

    const termEndDate      = moment().add(term?.term_length || 0, 'months').format('MM/DD/YYYY')
    const autoRenewalText  = `This membership will auto-renew on ${termEndDate}`
    const alwaysRenewsText = `This membership will auto-renew on ${termEndDate}, and continue to auto-renew every ${term?.term_length} ${term?.term_length == 1 ? 'month' : 'months'} until it is canceled.`
    const nonRenewalText   = `This membership will not auto-renew and will end on ${termEndDate}. Unused membership benefits will be lost upon expiration.`

    /**
     * Determine where to go when clicking back based on if there
     * is a membership and which recipient type they selected.
     */
    const backStep = () => {
        switch(true) {
            case !!membership :
                return MemberSteps.SELECT_MEMBER_TYPE

            case !!purchaseFor :
                return MemberSteps.SELECT_MEMBER_TYPE

            default :
                return MemberSteps.SELECT_RECIPIENT
        }
    }

    const nextStep = () => {
        return recipient === RecipientTypes.MYSELF
            ? MemberSteps.MEMBER_INFORMATION
            : MemberSteps.GIFTED_MEMBER_INFORMATION
    }

    return (
        <MemberStep
            disabled={!term}
            nextStep={nextStep()}
            backStep={backStep()}
            hideMobileProgressHeader={hideMobileProgressHeader}
        >
            <h2 className="text-center pt-4 pb-4">Membership Term</h2>

            <div className="row">
                { terms.map((t) => <TermRadio key={t.id} term={t} />) }
            </div>

            {
                !!term && <>
                    {
                        /^allow_renewal$/i.test(membershipType.renewal_option) && recipient === RecipientTypes.MYSELF && (
                            <div className="row text-center mt-4">
                                <Checkbox
                                    cols="12"
                                    value={willAutoRenew}
                                    label="Would you like to enable automatic renewals?"
                                    handleChange={() => dispatch(setAutoRenew(!willAutoRenew))}
                                />
                            </div>
                        )
                    }

                    <small className="mt-md-5 pt-5 d-block text-center font-italic font-weight-bold">
                        { /^allow_renewal$/i.test(membershipType.renewal_option) && (willAutoRenew ? autoRenewalText : nonRenewalText) }
                        { /^force_renewal$/i.test(membershipType.renewal_option) && alwaysRenewsText }
                        { /^no_renewal$/i.test(membershipType.renewal_option) && nonRenewalText }
                    </small>
                </>
            }
        </MemberStep>
    )
}
