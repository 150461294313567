import React, { Fragment, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { selectTimeZone } from '@/features/Locations/locationSlice'
import { closeModal, selectModals, selectTransactions, setTransactions } from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'
import CenteredText from '@/features/AdvancedPointOfSale/components/CenteredText'
import Modal from '@/features/AdvancedPointOfSale/components/Modal'
import Transaction from '@/features/AdvancedPointOfSale/components/Transaction'

import {
    isTransactionCredit,
    isTransactionCancelled,
    isTransactionReadyToCapture,
    isTransactionPendingPreAuth,
} from '@/features/AdvancedPointOfSale/lib/Transactions'

import { sortByKey } from '@/lib/Array'

const headerLabelStyle = { fontSize: '90%', verticalAlign: 'bottom' }

export default function TransactionsModal() {

    const dispatch               = useDispatch()
    const timezone               = useSelector(selectTimeZone)
    const { transactions:modal } = useSelector(selectModals)
    const transactions           = useSelector(selectTransactions)
    const bookingTransactions    = useMemo(() => transactions?.booking_payments || [], [transactions])
    const checkTransactions      = useMemo(() => transactions?.check_payments || [], [transactions])
    const tabTransactions        = useMemo(() => transactions?.tab_payments || [], [transactions])
    const tabs                   = useMemo(() => modal.check?.tabs?.filter((tab) => !tab.is_booking) || [], [modal])
    const columns                = 9

    const hasCreditTransactions = useMemo(() => (
        [ ...bookingTransactions, ...checkTransactions, ...tabTransactions ]
        .filter((t) => (
            isTransactionCredit(t)
            && !isTransactionCancelled(t)
        )).length > 0
    ), [transactions])

    const transactionsNeedingAttentionCount = useMemo(() => (
        [ ...bookingTransactions, ...checkTransactions, ...tabTransactions ]
        .filter((t) => (
            isTransactionPendingPreAuth(t)
            && !isTransactionReadyToCapture(t)
        )).length
    ), [transactions])

    const pendingTransactionsCount = useMemo(() => (
        [ ...bookingTransactions, ...checkTransactions, ...tabTransactions ]
        .filter((t) => isTransactionPendingPreAuth(t))
        .length
    ), [transactions])

    const EmptyRow = <tr><td colSpan={columns} className='font-weight-bolder font-italic text-gray4 text-center'>No Transactions</td></tr>

    const determineModalSize = () => {
        switch(true) {
            case modal.check.has_transactions :
                return pendingTransactionsCount > 0 ? 'modal-full' : 'modal-xxxxl'

            default :
                return 'modal-md'
        }
    }

    const handleClose = () => {
        dispatch(closeModal('transactions'))
        dispatch(setTransactions('clear'))
    }

    const handleKeyDown = (e) => {
        e.stopPropagation()
        if (e.key === 'Escape') { handleClose() }
    }

    useEffect(() => {
        if (modal.isOpen) {
            document.addEventListener('keydown', handleKeyDown, false)
        } else {
            document.removeEventListener('keydown', handleKeyDown, false)
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown, false)
        }
    }, [modal.isOpen])

    return modal.isOpen && !!modal.check && !!transactions && (
        <Modal
            className='transactions-modal'
            size={determineModalSize()}
            isOpen={modal.isOpen}
            htmlTitle
            title={
                <div className='modal-title px-0 w-100 d-flex align-items-end justify-content-between'>
                    <span className='d-flex align-items-center font-weight-bolder'>
                        <span>Transactions</span>

                        {
                            hasCreditTransactions && pendingTransactionsCount > 0 && transactionsNeedingAttentionCount > 0 && (
                                <span
                                    className='badge badge-warning badge--count ml-2'
                                    children={transactionsNeedingAttentionCount}
                                />
                            )
                        }

                        {
                            hasCreditTransactions && pendingTransactionsCount === 0 && transactionsNeedingAttentionCount == 0 && (
                                <i className='fas fa-circle-check text-success ml-2' />
                            )
                        }
                    </span>

                    <h6 className='mb-0 font-weight-light text-gray6 d-flex flex-column align-items-end'>
                        <strong className='pb-1'>
                            Check #{modal.check.check_number}
                        </strong>
                        <small className='font-italic'>
                            { moment.tz(modal.check.created_at, timezone).format('MM/DD/YYYY h:mm A') }
                        </small>
                    </h6>
                </div>
            }
            footerButtons={
                <button
                    children='Close'
                    className='btn btn-link px-5'
                    onClick={handleClose}
                />
            }
            onClose={handleClose}
        >
            {
                modal.check.has_transactions ? (
                    <div className='py-4 px-3'>
                        <table className='table table-sm w-100 m-0 text-white'>
                            <colgroup>
                                <col width='100px' />
                                <col width='180px' />
                                <col width='120px' />
                                <col width='0px' />
                                <col width='0px' />
                                <col width='0px' />
                                <col width='0px' />
                                <col width='0px' />
                                <col width='auto' />
                            </colgroup>
                            <tbody>
                                {
                                    bookingTransactions.length > 0 && <>
                                        {
                                            bookingTransactions.length > 0 ? (
                                                <tr>
                                                    <th colSpan='4' className='bg-gray2 border-top-0 rounded-left px-3 border-bottom-thick border-bottom border-color-gray4'>
                                                        Pre-Payments & Deposits
                                                    </th>
                                                    <th className='bg-gray2 border-top-0 px-3 border-bottom-thick border-bottom border-color-gray4 text-right text-gray6' style={headerLabelStyle}>
                                                        Subtotal
                                                    </th>
                                                    <th className='bg-gray2 border-top-0 px-0 border-bottom-thick border-bottom border-color-gray4 text-left text-gray6' style={headerLabelStyle}>
                                                        Tips
                                                    </th>
                                                    <th className='bg-gray2 border-top-0 rounded-right px-3 border-bottom-thick border-bottom border-color-gray4 text-right text-gray6' style={headerLabelStyle}>
                                                        Total
                                                    </th>
                                                    <th colSpan='2' className='bg-gray2 border-top-0 rounded-right px-3 border-bottom-thick border-bottom border-color-gray4 text-right text-gray6' style={headerLabelStyle}>
                                                        Authorized
                                                    </th>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <th colSpan={columns} className='bg-gray2 border-top-0 rounded-left px-3 border-bottom-thick border-bottom border-color-gray4'>
                                                        Pre-Payments & Deposits
                                                    </th>
                                                </tr>
                                            )
                                        }


                                        {
                                            sortByKey([...bookingTransactions], 'created_at', 'desc').map((transaction, index) => (
                                                <Transaction
                                                    key={`booking-transaction-${index}`}
                                                    children='booking'
                                                    check={modal.check}
                                                    data={transaction}
                                                />
                                            ))
                                        }

                                        { bookingTransactions.length === 0 && EmptyRow }
                                    </>
                                }

                                {
                                    checkTransactions.length > 0 && <>
                                        {
                                            bookingTransactions.length > 0 && (
                                                <tr><td colSpan={columns} className='border-top-0 p-0 pt-4' /></tr>
                                            )
                                        }

                                        {
                                            checkTransactions.length > 0 ? (
                                                <tr>
                                                    <th colSpan='4' className='bg-gray2 border-top-0 rounded-left px-3 border-bottom-thick border-bottom border-color-gray4'>
                                                        Full Check Payments
                                                    </th>
                                                    <th className='bg-gray2 border-top-0 px-3 border-bottom-thick border-bottom border-color-gray4 text-right text-gray6' style={headerLabelStyle}>
                                                        Subtotal
                                                    </th>
                                                    <th className='bg-gray2 border-top-0 px-0 border-bottom-thick border-bottom border-color-gray4 text-left text-gray6' style={headerLabelStyle}>
                                                        Tips
                                                    </th>
                                                    <th className='bg-gray2 border-top-0 rounded-right px-3 border-bottom-thick border-bottom border-color-gray4 text-right text-gray6' style={headerLabelStyle}>
                                                        Total
                                                    </th>
                                                    <th colSpan='2' className='bg-gray2 border-top-0 rounded-right px-3 border-bottom-thick border-bottom border-color-gray4 text-right text-gray6' style={headerLabelStyle}>
                                                        Authorized
                                                    </th>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <th colSpan={columns} className='bg-gray2 border-top-0 rounded-left px-3 border-bottom-thick border-bottom border-color-gray4'>
                                                        Full Check Payments
                                                    </th>
                                                </tr>
                                            )
                                        }

                                        {
                                            sortByKey([...checkTransactions], 'created_at', 'desc').map((transaction, index) => (
                                                <Transaction
                                                    key={`check-transaction-${index}`}
                                                    children='check'
                                                    check={modal.check}
                                                    data={transaction}
                                                />
                                            ))
                                        }

                                        { checkTransactions.length === 0 && EmptyRow }
                                    </>
                                }

                                {
                                    tabs.map((tab, index) => {
                                        const tabTransactions = transactions?.tab_payments
                                                                            ?.filter(({ tab_id }) => tab_id === tab.id) || []

                                        return <Fragment key={`tab-${tab.id}`}>
                                            {
                                                ((checkTransactions.length > 0 && index === 0) || index > 0) && (
                                                    <tr><td colSpan={columns} className='border-top-0 p-0 pt-4' /></tr>
                                                )
                                            }

                                            {
                                                tabTransactions.length > 0 ? (
                                                    <tr>
                                                        <th colSpan='4' className='bg-gray2 border-top-0 rounded-left px-3 border-bottom-thick border-bottom border-color-gray4'>
                                                            {tab.name}
                                                        </th>
                                                        <th className='bg-gray2 border-top-0 px-3 border-bottom-thick border-bottom border-color-gray4 text-right text-gray6' style={headerLabelStyle}>
                                                            Subtotal
                                                        </th>
                                                        <th className='bg-gray2 border-top-0 px-0 border-bottom-thick border-bottom border-color-gray4 text-left text-gray6' style={headerLabelStyle}>
                                                            Tips
                                                        </th>
                                                        <th className='bg-gray2 border-top-0 px-3 border-bottom-thick border-bottom border-color-gray4 text-right text-gray6' style={headerLabelStyle}>
                                                            Total
                                                        </th>
                                                        <th className='bg-gray2 border-top-0 px-3 border-bottom-thick border-bottom border-color-gray4 text-right text-gray6' style={headerLabelStyle}>
                                                            Authorized
                                                        </th>
                                                        <th className='bg-gray2 border-top-0 rounded-right px-3 border-bottom-thick border-bottom border-color-gray4 text-gray4 text-right'>
                                                            <strong className='float-right'>TAB</strong>
                                                        </th>
                                                    </tr>
                                                ) : (
                                                    <tr>
                                                        <th colSpan={columns} className='bg-gray2 border-top-0 rounded-left px-3 border-bottom-thick border-bottom border-color-gray4'>
                                                            {tab.name}
                                                            <strong className='float-right text-gray4'>TAB</strong>
                                                        </th>
                                                    </tr>
                                                )
                                            }

                                            {
                                                sortByKey([...tabTransactions], 'created_at', 'desc').map((transaction, index) => (
                                                    <Transaction
                                                        key={`tab-transaction-${index}`}
                                                        children='tab'
                                                        check={modal.check}
                                                        tab={tab}
                                                        data={transaction}
                                                    />
                                                ))
                                            }

                                            { tabTransactions.length === 0 && EmptyRow }
                                        </Fragment>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <CenteredText
                        children='None Found'
                        textClassName='text-gray2 mt-5 mb-4'
                    />
                )
            }
        </Modal>
    )
}
