import React, { useState } from 'react'
import { useSelector, useDispatch } from "react-redux"
import { clearBookingCalendars, selectStep } from "@/features/Bookings/bookingSlice"
import LedgerLocation from './LedgerLocation'
import LedgerPartySize from './LedgerPartySize'
import LedgerDuration from './LedgerDuration'
import LedgerDateTime from './LedgerDateTime'

export default function MobileProgressheader() {
    const step     = useSelector(selectStep)
    const dispatch = useDispatch()

    const [open, setOpen] = useState(false)

    const handleClearCalendars = () => {
      if (/4|5|6/.test(step)) {
        dispatch(clearBookingCalendars())
      }
    }

    return (
        <div className="xbm-mobile-progress xbm-checkout-ledger d-block d-md-none">
            <div
              className={`xbm-mobile-progress-trigger ${open ? 'open' : ''}`}
              onClick={() => setOpen(!open)}
            />

            <div className={`xbm-ledger-${open ? 'open' : 'collapse'}`}>
                <div className="xbm-ledger-section-type">
                    <LedgerLocation />
                    <LedgerPartySize clearCalendars={handleClearCalendars} />
                    <LedgerDuration clearCalendars={handleClearCalendars} />
                    <LedgerDateTime clearCalendars={handleClearCalendars} />
                </div>
            </div>
        </div>
    )
}
