import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCustomersToPayFor, selectCustomersToPayFor } from '@/features/BookingPayment/bookingPaymentSlice'

export default function ParticipantNumberSelect({
  booking,
  setStep,
}) {
  const dispatch = useDispatch()

  const customersToPayFor = useSelector(selectCustomersToPayFor)

  const [errorMessage, setErrorMessage] = useState('')

  const maxCustomerCount = booking.participants - booking.customers_paid_for

  return (
    <div className="col-12 col-md-6">
      <div className="form-group">
        <h3 className="mb-3">How many people are you paying for?</h3>

        <div className="xbm-incrementer-container">
          <div className="input-group">
            <div
              className="input-group-prepend"
              onClick={() => {
                if (customersToPayFor > 0) {
                  dispatch(setCustomersToPayFor(customersToPayFor - 1))
                }
              }}
            >
              <div className="input-group-text">
                <i className="far fa-minus"></i>
              </div>
            </div>

            <input
              type="number"
              min={0}
              className={'form-control text-center'}
              value={Number(customersToPayFor).toString() || 0}
              onChange={e => {
                const newCustomersToPayFor = e.target.value ? parseInt(e.target.value) : 0

                if (maxCustomerCount < newCustomersToPayFor) { return }

                dispatch(setCustomersToPayFor(newCustomersToPayFor))
              }}
            />

            <div
              className="input-group-append"
              onClick={() => {
                if (maxCustomerCount > customersToPayFor) {
                  dispatch(setCustomersToPayFor(customersToPayFor + 1))
                }
              }}
            >
              <div className="input-group-text">
                <i className="far fa-plus"></i>
              </div>
            </div>
          </div>
        </div>

        <div className="invalid-feedback d-block">{errorMessage}</div>

        <button
          className="btn btn-primary btn-block mt-4"
          onClick={() => {
            if (customersToPayFor > 0) {
              setStep('3')
            } else {
              setErrorMessage('Please select the number of customers you are paying for.')
            }
          }}
        >
          Continue
        </button>
      </div>
    </div>
  )
}
