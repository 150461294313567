import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectForm, setFormAttribute } from '@/features/Schedule/scheduleSlice'
import { selectPackage } from '@/features/Packages/packageSlice'
import Checkbox from '@/components/Form/Checkbox'
import { selectMemberCount } from '../scheduleSlice'

export default function QuoteToggle() {
    const dispatch      = useDispatch()
    const form          = useSelector(selectForm)
    const chosenPackage = useSelector(selectPackage)
    const memberCount   = useSelector(selectMemberCount)

    const isNewQuote = !form.require_email_address

    return <>
        <h2>New { isNewQuote ? 'Quote' : 'Reservation' }</h2>

        {
            !chosenPackage.is_special_event &&
            <div className='ml-auto'>
                <Checkbox
                    cols={null}
                    className='mb-0 pb-0'
                    name='require_email_address'
                    label='Create Quote?'
                    value={!form.require_email_address}
                    disabled={memberCount > 0}
                    handleChange={(e) => {
                        dispatch(setFormAttribute({ name: e.target.name, value: !e.target.checked }))
                    }}
                />
                {memberCount > 0 && <small className='text-muted'><em>* disabled when members exist</em></small>}
            </div>
        }
    </>
}
