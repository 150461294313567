import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectResourceType } from '@/features/ResourceType/resourceTypeSlice'
import {
  selectShowReminder,
  selectReminderAcknowledged,
  setShowReminder,
  setReminderAcknowledged,
} from '@/features/Bookings/bookingSlice'
import { selectPackage } from '@/features/Packages/packageSlice'

export default function ReminderModal() {

    const dispatch             = useDispatch()
    const resourceType         = useSelector(selectResourceType)
    const showReminder         = useSelector(selectShowReminder)
    const reminderAcknowledged = useSelector(selectReminderAcknowledged)
    const pakage               = useSelector(selectPackage)

    const [initialLoad, setInitialLoad] = useState(true)

    const handleClose = () => {
        dispatch(setShowReminder(false))
    }

    const handleAgree = () => {
        dispatch(setReminderAcknowledged(true))
    }

    /**
     * Respond to either button being clicked
     */
    useEffect(() => {
        if (!initialLoad) {
            if (reminderAcknowledged) {
                handleAgree()
            }
            handleClose()
        }
    }, [dispatch, reminderAcknowledged])

    useEffect(() => {
        setInitialLoad(false)
    }, [])

    return showReminder && (
        <div className="modal modal-backdrop modal--sm">
            <div className="modal-container" style={{ height: 'auto' }}>

                <div className="modal-header">
                    <div className="modal-title">Reminder</div>

                    <button className="modal-close" onClick={handleClose}>
                        <span>Close</span>
                        <i className="far fa-times"></i>
                    </button>
                </div>

                <div className="modal-body">
                    <div className="modal-body-main">
                        <div className="modal-body-main-section">
                            <p className="text-center" style={{whiteSpace: 'break-spaces'}}>
                                {pakage.override_resource_type_reminder_text ? pakage.reminder_modal_text : resourceType.reminder_modal_text}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="modal-footer d-flex">
                    <button
                        type="button"
                        className="btn btn-link mr-auto"
                        onClick={handleClose}
                    >
                        Cancel
                    </button>

                    <button
                        type="button"
                        className="btn btn-primary ml-auto"
                        onClick={handleAgree}
                    >
                        Agree
                    </button>
                </div>

            </div>
        </div>
    )
}
