import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCustomerTypeCounts, setCustomerTypeCounts } from './bookingPaymentSlice'
import NumberIncrementer from '../../components/Form/NumberIncrementer'

export default function PaymentParticipantToggle({ type, total }) {

    const dispatch           = useDispatch()
    const customerTypeCounts = useSelector(selectCustomerTypeCounts)
    const typeAmount         = customerTypeCounts[type.id] || 0

    const handleChange = (value) => {
        dispatch(setCustomerTypeCounts({
            ...customerTypeCounts,
            [type.id]: Number.parseInt(typeof value === 'object' ? value.target.value : value)
        }))
    }

    return (
        <div className="col d-flex align-items-end" style={{ minWidth: '30%' }}>
            <div className="form-group">
                <label className="d-flex justify-content-between">
                    {type.name}
                </label>

                <NumberIncrementer
                    name='amount'
                    value={typeAmount}
                    incrementAmount={1}
                    min={0}
                    disabled={typeAmount >= total}
                    preventTyping
                    handleChange={handleChange}
                />
            </div>
        </div>
    )
}
