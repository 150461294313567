import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectCustomerTypes } from '../CustomerTypes/customerTypeSlice'
import { selectCustomerTypeCounts } from '../EditBooking/editBookingSlice'
import { selectPackage } from '../Packages/packageSlice'
import NumberIncrementer from '../../components/Form/NumberIncrementer'

export default function AddonTypeToggles({ typeCounts, setTypeCounts, prices, onError }) {
    const bookingCustomerTypeCounts = useSelector(selectCustomerTypeCounts)
    const customerTypes             = useSelector(selectCustomerTypes)
    const pakage                    = useSelector(selectPackage)

    const typesWithPrices = prices.filter(p => !!p.addon_price).map(p => p.customerType)

    const availableTypes = customerTypes.filter(ct => (
        pakage.customer_types.includes(ct.id) && typesWithPrices.includes(ct.id) && !ct.non_participant
    ))

    const handleChange = (value, typeId) => {
        setTypeCounts({
            ...typeCounts,
            [`${typeId}`]: value
        })
    }

    useEffect(() => {
        if (availableTypes.length === 0) {
            onError('Could not find any available addon pricing.')
        }
    }, [availableTypes])

    return (
        <div className="row">
            {
                availableTypes.map(t => (
                    <div key={t.id} className="col-12">
                        <div className="form-group">
                            <label>{t.name}</label>
                            <NumberIncrementer
                                name='customer_type_counts'
                                value={typeCounts[`${t.id}`] || 0}
                                incrementAmount={1}
                                min={0}
                                max={bookingCustomerTypeCounts[t.id]}
                                handleChange={e => handleChange(e, t.id)} />
                        </div>
                    </div>
                ))
            }

            { availableTypes.length === 0 &&
                <p className="text-danger text-center py-1 px-2 w-100">Could not find any available addon pricing</p>
            }
        </div>
    )
}
