import React from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '@/features/Session/sessionSlice'

export default function UserIcon({ size=null, className='d-flex' }) {

    const currentUser  = useSelector(selectCurrentUser)
    const initials     = currentUser.name.split(' ')
    const firstInitial = initials[0].charAt(0)
    const lastInitial  = initials.length > 1 ? initials[initials.length - 1].charAt(0) : ''

    return (
        <div className={`justify-content-center align-items-center rounded-circle m-1 user-icon ${!!size ? `user-icon--${size}` : ''} ${className}`.trim()}>
            {
                /super@switchboxinc.com/i.test(currentUser.email)
                    ? <span><i className='fas fa-helicopter' /></span>
                    : <span>{ firstInitial } { lastInitial }</span>
            }
        </div>
    )
}
