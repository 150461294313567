import React from "react"
// import { useFormContext } from "react-hook-form"
import GuestDiscountFields from "./GuestDiscountFields"
import Help from '@/features/Help/Help'

export default function GuestDiscounts({
    form,
    errors,
    guestDiscounts = [],
    handleManualUpdate,
    customerTypes,
    disabledIfMembers,
}) {
    // const { register } = useFormContext()

    const name = "guest_discounts"

    // const field = register(name, {
    //     validate: () => {
    //         const temp = guestDiscounts.map((item) => item.customer_type).sort()
    //         const duplicates = []
    //
    //         temp.map((customer_type, index) => {
    //             if (temp[index + 1] && customer_type == temp[index + 1]) {
    //                 duplicates.push(code)
    //             }
    //         })
    //
    //         return duplicates.length === 0 || "Sorry, duplicate customer_types are not allowed"
    //     },
    //
    //     validate: () => {
    //         const empties = []
    //         guestDiscounts.map((item) => {
    //             if (!item.customer_type_id || !item.discount_type || !item.discount_amount) {
    //                 empties.push(item)
    //             }
    //         })
    //         // membership-TODO: fix this
    //         // return empties.length === 0 || "All guest discount fields must be filled out"
    //         return true
    //     },
    // })

    const handleAdd = () => {
        handleManualUpdate(
            name,
            guestDiscounts.concat({
                customer_type_id: null,
                customer_type_name: null,
                discount_type: null,
                discount_amount: null,
            })
        )
    }

    const handleRemove = (index) => {
        handleManualUpdate(
            name,
            guestDiscounts.filter((_p, i) => i !== index)
        )
    }

    const handleGuestDiscountChange = (e, index) => {
        handleManualUpdate(
            name,
            guestDiscounts.map((discount, i) => {
                if (i === index) {
                    const name = e.target.name.split(":")[0]
                    return {
                        ...discount,
                        [name]: e.target.value,
                    }
                } else {
                    return discount
                }
            })
        )
    }

    const findCustomerTypeName = (customerTypesList, customerTypeId) => {
        return customerTypesList.find((customerType) => {
            return String(customerType.id) === customerTypeId
        })
    }

    const handleCustomerTypeChange = (e, index) => {
        handleManualUpdate(
            name,
            guestDiscounts.map((discount, i) => {
                if (i === index) {
                    return {
                        ...discount,
                        customer_type_id: e.target.value,
                        customer_type_name: findCustomerTypeName(customerTypes, e.target.value)?.name,
                    }
                } else {
                    return discount
                }
            })
        )
    }

    return (
        <div className="confirmation-checks">
            <h3>Guests of Member Discounts<Help articleId='154000159019-guests-of-member-discounts' /></h3>

            <button
                children='+ Add Guest Discount'
                className="drawer-side-action btn btn-link px-0 mt-n1"
                disabled={guestDiscounts.length === customerTypes.length || disabledIfMembers}
                onClick={handleAdd}
            />

            {guestDiscounts.length > 0 ? (
                guestDiscounts.map((discount, index) => (
                    <GuestDiscountFields
                        key={`guest-discount-${index}`}
                        form={form}
                        customerTypes={customerTypes}
                        guestDiscount={discount}
                        selectedGuestDiscounts={guestDiscounts}
                        index={index}
                        location={location}
                        handleGuestDiscountChange={handleGuestDiscountChange}
                        handleCustomerTypeChange={handleCustomerTypeChange}
                        handleRemove={handleRemove}
                        disabledIfMembers={disabledIfMembers}
                    />
                ))
            ) : (
                <div className="row text-center">
                    <div className="col-12 text-muted">None right now — why don't you add some?</div>
                </div>
            )}

            {errors && errors[name] && <div className="invalid-feedback d-block">{errors[name].join(", ")}</div>}
        </div>
    )
}
