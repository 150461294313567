import React, { useEffect, useState, useRef } from 'react'
import { createPortal } from 'react-dom'
import { useSelector } from 'react-redux'
import FuzzySearch from 'react-fuzzy'
import { selectDate } from '@/features/Calendar/calendarSlice'
import { LOAD_SPECIFIC_DATE } from '@/lib/Storage'
import { debug } from '@/lib/Debug'

export default function ContextSwitcher({ type=null, context=null, collection=[], showReturnToLocationLink=false, retainViewedCalendarDate=true }) {

    const viewedDate           = retainViewedCalendarDate ? useSelector(selectDate) : null
    const toggleButtonRef      = useRef()
    const paneRef              = useRef()
    const searchInputRef       = useRef()
    const contextPaneContainer = document.getElementById('context-pane-root')

    const [open, setOpen] = useState(false)

    const handleTogglePane = () => {
        setOpen(!open)
    }

    const handleKeyDown = (e) => {
        e.stopPropagation()

        switch(true) {
          case e.key === 'Escape' :
            setOpen(false)
            break

          case e.key === '/' && e.ctrlKey :
            setOpen(true)
            break
        }
    }

    const handleClickOutside = (e) => {
        if (toggleButtonRef.current.contains(e.target)) {
            return false
        }

        if (!paneRef.current?.contains(e.target)) {
            setOpen(false)
        }
    }

    const handleSwitchContext = (selectedLocation) => {
        // retain the selected date currently being viewed (only) when switching location contexts
        if (!!viewedDate) {
            window.localStorage.setItem(LOAD_SPECIFIC_DATE, viewedDate)
        }

        if (!!selectedLocation?.company_id && !!selectedLocation?.id) {
            handleTogglePane()

            // replace the company ID and location ID in the path for the given record
            // example: /companies/7/locations/10/embed/waivers > /companies/14/locations/2/embed/waivers
            window.location.href = window.location.pathname.replace(
                /^(\D*)(\d+)(\D*)(\d+)(\D*)/,
                `$1${selectedLocation.company_id}$3${selectedLocation.id}$5`
            )
        }
    }

    useEffect(() => {
        // auto-focus on the search input in order to force-display the FuzzySearch result list
        searchInputRef.current[open ? 'focus' : 'blur']()

        // auto-scroll to the current location in the switcher list if present
        // after a small unnoticable delay (to allow FuzzySearch to render the list)
        window.setTimeout(() => {
            document.getElementById('context-pane--current-location')?.scrollIntoView({ block: 'center', inline: 'center' })
        }, 100)
    }, [open])

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true)
        document.addEventListener('keyup', handleKeyDown, true)

        // hacky way to prevent the context switcher from making the
        // entire webapp "break" its position and move up/left adding
        // it here because I think the layout / #app-scaffold is used
        // in the public modal as well and I don't want to break those
        document.getElementById('app-scaffold').style.position = 'fixed'

        return () => {
            document.removeEventListener('click', handleClickOutside, true)
            document.removeEventListener('keyup', handleKeyDown, true)
        }
    }, [])

    return <>
        <div
            ref={toggleButtonRef}
            className="action"
            title={`Change ${type}`}
            onClick={handleTogglePane}
        >
            <div className="micro-label">{ type }</div>
            <span className="action-value">{ context.name }</span>
        </div>

        {
            createPortal((
                <nav ref={paneRef} role="navigation" id="context-pane" className={open ? 'open' : ''}>
                    <FuzzySearch
                        className='inner'
                        list={collection}
                        maxResults={20}
                        autoFocus={true}
                        keys={['name']}
                        keyForDisplayName='name'
                        width='auto'
                        shouldShowDropdownAtStart={true}
                        isDropdown={false}
                        verbose={debug}
                        placeholder='Search Location Name'
                        threshold={0.5}
                        onSelect={handleSwitchContext}
                        inputWrapperStyle={{ background: 'transparent', padding: 0, boxShadow: 'none' }}
                        inputStyle={{ border: 'none' }}
                        inputProps={{ ref: searchInputRef, className: 'form-control' }}
                        resultsTemplate={(props, state, styles, clickHandler) => (
                            <ul>
                                {
                                    state.results.map((location, i) => {
                                        const isCurrentPage = location.id === context.id

                                        return (
                                            <li
                                                key={`location-${location.id}`}
                                                id={isCurrentPage ? 'context-pane--current-location' : `context-pange--location-${location.id}`}
                                                className={isCurrentPage ? 'current' : ''}
                                            >
                                                <button
                                                    type='button'
                                                    className="btn w-100"
                                                    onClick={() => clickHandler(i)}
                                                    onTouchStart={() => clickHandler(i)}
                                                >
                                                    <i className={`far fa-check ${isCurrentPage ? '' : 'fa-blank'}`} />
                                                    { location.name }
                                                </button>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        )}
                    />

                    {
                        Boolean(context) && showReturnToLocationLink &&
                            <div className="context-change">
                                <a href={`/companies/${context.company_id}/locations/${context.id}/resource_types`}>
                                    <i className="far fa-chevron-left" />
                                    Back to {context.name}
                                </a>
                            </div>
                    }
                </nav>
            ), contextPaneContainer)
        }
    </>
}
