import { configureStore } from "@reduxjs/toolkit";
import bookingSliceReducer from '../features/Bookings/bookingSlice'
import bookingPaymentSliceReducer from "../features/BookingPayment/bookingPaymentSlice"
import packageSliceReducer from "../features/Packages/packageSlice"
import locationSliceReducer from "../features/Locations/locationSlice"
import customerTypeSliceReducer from "../features/CustomerTypes/customerTypeSlice"
import availabilitySliceReducer from "../features/Availability/availabilitySlice"
import sessionSliceReducer from "../features/Session/sessionSlice"
import referralsSliceReducer from "../features/Referrals/referralsSlice"
import occasionsSliceReducer from "../features/Occasions/occasionsSlice"
import resourceTypeSliceReducer from "../features/ResourceType/resourceTypeSlice"
import pricingSliceReducer from '../features/Pricing/pricingSlice'
import notificationSliceReducer from '../features/Notifications/notificationSlice'
import adyenSliceReducer from '../features/Adyen/adyenSlice'

export default configureStore({
    reducer: {
        booking:      bookingSliceReducer,
        bookingPayment: bookingPaymentSliceReducer,
        package:      packageSliceReducer,
        location:     locationSliceReducer,
        customerType: customerTypeSliceReducer,
        availability: availabilitySliceReducer,
        session:      sessionSliceReducer,
        referrals:    referralsSliceReducer,
        occasions:    occasionsSliceReducer,
        resourceType: resourceTypeSliceReducer,
        pricing:      pricingSliceReducer,
        notifications: notificationSliceReducer,
        adyen: adyenSliceReducer
    }
})
