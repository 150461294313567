import React from 'react'
import { useSelector } from 'react-redux'
import { selectLabelWidth, selectSizeHeight } from './calendarSlice'
import { LaneTimesContainer } from './LaneTimesContainer'
import { debug } from '@/lib/Debug'

const boxShadow = 'inset 0px -65px 60px -45px rgba(0,0,0,0.25)'

export function _Lane({ resource }) {

    const sizeHeight = useSelector(selectSizeHeight)
    const labelWidth = useSelector(selectLabelWidth)
    const labelColor = resource.primary_hex_color
    const labelText  = resource.text_hex_color

    const styles = {
        width: `${labelWidth}px`, 
        ...(labelColor && {background: `${labelColor}`, boxShadow: boxShadow}), 
        ...(labelText && {color: `${labelText}`})
    };

    return (
        <div
            className={`resource ${resource?.non_adjacent ? 'non-adjacent' : ''}`}
            style={{ height: `${sizeHeight}px` }}
            data-resource-id={`${resource.id}`}
        >
            <div className="time-block resource-label" style={styles}>
                { debug && <small style={{ position: 'absolute', top: '5px', left: '5px' }}>{ resource.id }</small> }
                <span>
                    {resource.name}
                </span>
            </div>

            <LaneTimesContainer lane={resource.id} />
        </div>
    )
}

export const Lane = React.memo(_Lane)
