import { createSlice } from "@reduxjs/toolkit"
import axios from 'axios'
import moment from 'moment'
import { assignBookingsToTimeColumns } from "../../lib/CalendarTimes"
import {
    setLoading,
    setLoadedDates,
    setLastLoadedDatetime,
    setMarkerOffset,
    setSelectedBookingLeftOffset,
    setInputLocked,
    triggerTimeMarkerUpdate,
} from "../Calendar/calendarSlice"
import { setOpen as setScheduleOpen } from '../Schedule/scheduleSlice'
import { resetBooking } from '../EditBooking/editBookingSlice'
import { addAlert } from '@/features/Notifications/notificationSlice'
import { debug } from '@/lib/Debug'

export const calendarBookingsSlice = createSlice({
    name: 'calendarBookings',
    initialState: {
        bookings: [],
        activeDrag: null,
        isGrabbed: false
    },
    reducers: {
        resetBookings: (state, action) => {
            state.bookings = []
        },
        setBookings: (state, action) => {
            // when we assign bookings we always want to also assign their time columns
            // state.bookings = assignBookingsToTimeColumns(action.payload)
            state.bookings = action.payload
        },
        concatBookings: (state, action) => {
            state.bookings = state.bookings.concat(action.payload)
        },
        updateBooking: (state, action) => {
            let updatedBookings      = null
            const currentBookingIds  = state.bookings.map((b) => b.booking.id)
            const updatedBooking     = action.payload.bundle
            const loadedDates        = action.payload.loadedDates
            const lastLoadedDatetime = action.payload.lastLoadedDatetime
            const timezone           = action.payload.timezone

            if (!updatedBooking) { return }

            if (currentBookingIds.includes(updatedBooking?.booking?.id)) {
                // either update the booking by replacing it
                updatedBookings = state.bookings.map((storedBooking) => storedBooking.booking.id === updatedBooking.booking.id ? updatedBooking : storedBooking)
            } else {
                // or add the new booking to the existing bookings
                updatedBookings = state.bookings.concat(updatedBooking)
            }

            if (updatedBookings) {
                state.bookings = assignBookingsToTimeColumns(updatedBookings, loadedDates, lastLoadedDatetime, timezone)
            }
        },
        setActiveDrag: (state, action) => {
            state.activeDrag = action.payload
        },
        removeBooking: (state, action) => {
            state.bookings = state.bookings.filter(bundle => {
                return bundle.booking.id !== action.payload
            })
        },
        setIsGrabbed: (state, action) => {
            state.isGrabbed = action.payload
        }
    }
})

export const {
    resetBookings,
    setBookings,
    concatBookings,
    updateBooking,
    setActiveDrag,
    removeBooking,
    setIsGrabbed
} = calendarBookingsSlice.actions

export const selectBookings    = state => state.calendarBookings.bookings
export const selectActiveDrag  = state => state.calendarBookings.activeDrag
export const selectIsGrabbed   = state => state.calendarBookings.isGrabbed

export const selectConcurrentBookingStartTimes = (state) => {
    const maxConcurrentReservationsForResource = state.resourceType?.resourceTypes?.[0]?.max_concurrent_reservations || 0
    const concurrentTimes = []

    if (maxConcurrentReservationsForResource > 1) {
        const bookingTimes = state.calendarBookings.bookings
            .filter((b) => /^booking$/i.test(b.booking.type))
            .filter((b) => !b.booking?.is_parent && !b.booking?.parent_booking)
            .map((b) => b.booking.start_time)

        bookingTimes.forEach((t1) => {
            if (
                !concurrentTimes.includes(t1)
                &&
                bookingTimes.filter((t2) => t1 === t2).length > (maxConcurrentReservationsForResource - 1)
            ) {
                concurrentTimes.push(t1)
            }
        })
    }

    return concurrentTimes
}

export function bookingIsPresent(bookingId) {
    return (dispatch, getState) => {
        const calendarBookings              = getState().calendarBookings.bookings
        const childBookings                 = getState().parentBooking.childBookings
        const isBookingOnCalendar           = Array.isArray(calendarBookings) && calendarBookings.map((b) => b.booking.id).includes(bookingId)
        const isChildOfCurrentParentBooking = Array.isArray(childBookings) && childBookings.map((cb) => cb.id).includes(bookingId)

        if (debug && console) {
            console.log(`Checking if booking (${bookingId}) is present before updating...`, isBookingOnCalendar, isChildOfCurrentParentBooking)
        }

        return isBookingOnCalendar || isChildOfCurrentParentBooking
    }
}

export function fetchCalendarBookings(resourceTypeId, date, concatDates, initial, resetMarker) {
    return async (dispatch, getState) => {
        if (!(resourceTypeId && date)) { return }

        const selectedBookingLeftOffset = getState().calendar.selectedBookingLeftOffset
        const timezone                  = getState().location?.location?.time_zone

        dispatch(setLoading(true))

        if (debug && console) { console.log(`fetching bookings for ${date}`) }

        axios.get(`/resource_types/${resourceTypeId}/bookings?selected_date=${date}&initial=${initial || false}&compact=1`)
        .then(({ data }) => {
            if (debug && console) { console.log('CALENDAR QUERY INFO >>>', data.debug) }

            let newDates = [...getState().calendar.loadedDates]

            if (concatDates) {
                if (!newDates.includes(date)) {
                    newDates = newDates.concat(date)
                }
            } else {
                newDates = [date]
            }

            const bookings = assignBookingsToTimeColumns(
                data.bookings,
                newDates,
                data.last_loaded_datetime,
                timezone
            )

            dispatch(setLastLoadedDatetime(data.last_loaded_datetime))
            dispatch(setLoadedDates({ dates: newDates, resetMarker: resetMarker }))

            /**
             * If we are in 'concat' mode (scrolling mode) we have to make sure we concat
             * the bookings instead of completely overwriting them, otherwise as they scroll
             * back and forth the bookings will be erased and only 1 day at a time will be available.
             */
            if (concatDates) {
                dispatch(concatBookings(bookings))
            } else {
                // WEIRD FIX
                // Resetting the bookings state first as an entirely separate dispatch call is vital
                // because (due to timezones maybe?) it's sometimes possible for the server to return
                // the same events and for Redux to refuse to update the...state which results in the
                // same bookings from the day prior being rendered incorrectly on the requested day.
                // By resetting state first, this ensures that the state *always* updates.
                dispatch(resetBookings())

                // Yeet the returned bookings onto the calendar. This SLAPS.
                //                                                      ,,.,,...
                //                                                    /*,.,,,,,,,...,
                //                                                   *,,,,,**,,,...,........
                //                                                  ./,,,,,,,,,.,....,............
                //                                                    ,*/*,,,,.......,......... ..(///(/
                //                                                        **,,.......,,...  . .../******/
                //                                                           .,,,...,...........*(/*(/*/
                //                                       ./(((,                        ,,....
                //                               .,,..  /((/**((
                //                         */((/(###%%%#/*/**//*.
                //                   */((((#(((*****#%%#(//*//*/#,
                //                .///####((###**,,,***##(#///%%%##*.
                //              .(((#####(########*,,,,,,*//,*,,***/////,
                //      ..,,***/(((#####%##(##%#######*.  .,**,*.    ,**/**.  ,,
                //   #%*////***((#####(/,   ,######((/*                 .**/////*.
                //  .%&@#                      %*****//,
                //  (&&&.                         .*****
                //                                .***/,      *#      (( .###(((#  /(#(##(/ (###(((##  (
                //                                */*//.       .@,   &(  .@,       #&           &(     (
                //                                *///*          &#*@.   .@(/////  %&/////      &(     (
                //                                ///*            #&     .@,       %&           &(
                //                               ./**             #&     .&(/////  #&/////      &(     #
                dispatch(setBookings(bookings))
            }

            if (selectedBookingLeftOffset !== null) {
                dispatch(setMarkerOffset(selectedBookingLeftOffset))
            } else {
                dispatch(triggerTimeMarkerUpdate())
            }
        })
        .finally(() => {
            dispatch(setLoading(false))

            if (selectedBookingLeftOffset !== null) {
                dispatch(setSelectedBookingLeftOffset(null))
            }
        })
    }
}

export function moveBooking(bookingId, newLane, newTime) {
    return async (dispatch, getState) => {
        // display an invisible layer that prevents all clicks above
        // the entire calendar to prevent any changes/moves to the booking
        // while the moving of the booking is "in-flight"
        dispatch(setInputLocked(true))

        return axios.patch(`/bookings/${bookingId}/move_booking`, {
            authenticity_token: getState().session.formToken,
            new_lane:           newLane,
            new_time:           newTime
        }).then(({ data }) => {
            if (debug && console) { console.log('move booking server response: ', data) }

            if (!data.success) {
                dispatch(addAlert({ type: 'error', text: data.message }))
                if (debug && console) { console.log(data.message) }
            }

            return data
        }).catch((error) => {
            dispatch(addAlert({ type: 'error', text: 'Could not move the booking' }))
            if (debug && console) { console.log(error) }
        }).finally(() => {
            dispatch(setInputLocked(false))
        })
    }
}

export function createBuffers(resourceIds, startTime, duration) {
    return async (dispatch, getState) => {
        const token = getState().session.formToken

        if (!resourceIds || !Array.isArray(resourceIds) || !startTime) {
            dispatch(addAlert({ type: 'error', text: 'Unable to create buffer(s)' }))
            return
        }

        axios.post(`/resources/create_buffers`, {
            authenticity_token: token,
            start_time:         startTime,
            resource_ids:       resourceIds,
            duration:           duration
        }).then(({ data }) => {
            if (data.success) {
                dispatch(addAlert({ type: 'success', text: data.message }))
                return
            }
            dispatch(addAlert({ type: 'error', text: data.message }))
        }).catch((error) => {
            dispatch(addAlert({ type: 'error', text: 'Unable to create buffer(s)' }))
            if (debug & console) { console.error(error.response) }
        })
    }
}

export function deleteBuffer(buffer) {
    return async (dispatch, getState) => {
        const token = getState().session.formToken

        const bufferId = Number.parseInt(buffer.id.split('-')[1])

        axios.delete(`/calendars/${bufferId}/delete_buffer?authenticity_token=${token}`).then(({ data }) => {
            if (!data.success) {
                dispatch(addAlert({ type: 'error', text: data.message }))
                if (debug && console) { console.log('Delete buffer error: ', data.message) }
            }

            // remove booking from state -- this should only be happening if we get a successful response from the server
            dispatch(removeBooking(buffer.id))
        }).catch((error) => {
            if (debug && console) { console.log('ERROR: could not delete buffer: ', error) }
        })
    }
}

/**
 * This is a simple wrapper around `updateBooking` that will pass in the calendar slice's loadedDates array.
 * We have to do this in order to get the calendar slice's state, since `state` in the reducers means the
 * state of the individual slice -- not the state of the entire calendar store.
 */
export function initUpdateBooking(bundle) {
    return async (dispatch, getState) => {
        dispatch(updateBooking({
            bundle:             bundle,
            loadedDates:        getState().calendar.loadedDates,
            lastLoadedDatetime: getState().calendar.lastLoadedDatetime,
            timezone:           getState().location?.location?.time_zone,
        }))
    }
}

export function initRemoveBooking(id) {
    return async (dispatch, getState) => {
        dispatch(removeBooking(id))

        const editId = getState().editBooking.booking?.id

        if (editId === id) {
            dispatch(setScheduleOpen(false))
            // dispatch(resetBooking())
        }
    }
}

export function initRemoveBuffer(id) {
    return async (dispatch, getState) => {
        dispatch(removeBooking(id))
    }
}

export default calendarBookingsSlice.reducer
