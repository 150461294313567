import { configureStore } from "@reduxjs/toolkit"
import adyenSliceReducer from '../features/Adyen/adyenSlice'
import helpSliceReducer from '../features/Help/helpSlice'
import memberSliceReducer from '../features/Members/memberSlice'
import notificationSliceReducer from '../features/Notifications/notificationSlice'
import sessionSliceReducer from '@/features/Session/sessionSlice'
import locationSliceReducer from '@/features/Locations/locationSlice'
import productSliceReducer from '@/features/Products/productSlice'
import managerCodeSliceReducer from '@/features/ManagerCodes/managerCodeSlice'

export default configureStore({
    reducer: {
        adyen: adyenSliceReducer,
        help: helpSliceReducer,
        member: memberSliceReducer,
        notifications: notificationSliceReducer,
        session: sessionSliceReducer,
        location: locationSliceReducer,
        products: productSliceReducer,
        managerCode: managerCodeSliceReducer,
    }
})
