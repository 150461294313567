import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { parameterize } from 'inflected'

import {
    fetchMenuContent,
    selectAllMenus,
    selectMenuContent,
    selectBreadcrumbs,
    selectLoadedMenuPath,

    addBreadcrumb,
    resetMenuBreadcrumbs,
    resetMenuContent,
    resetMenuLoadedPath,
    setCurrentComponent,
    setLoadedItemId,
    setLoadedMenuPath,
    truncateBreadcrumbs,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import Breadcrumbs from '@/features/AdvancedPointOfSale/components/Breadcrumbs'
import CenteredText from '@/features/AdvancedPointOfSale/components/CenteredText'
import MenuButton from '@/features/AdvancedPointOfSale/components/buttons/MenuButton'
import { addAlert } from '@/features/Notifications/notificationSlice'
import scrollHinting from '@/lib/ScrollHinting'
import { debug } from '@/lib/Debug'

export default function Menus({
    self='Menus',
    shouldLoadDefault=false,
    onChange=() => {},
}) {

    const dispatch      = useDispatch()
    const path          = useSelector(selectLoadedMenuPath)
    const breadcrumbs   = useSelector(selectBreadcrumbs)
    const menus         = useSelector(selectAllMenus)
    const menu          = useSelector(selectMenuContent)
    const isLoadingItem = useRef(false)

    const [isLoaded, setIsLoaded] = useState(false)

    const menuContent = useMemo(() => {
        try {
            if (!!menu?.content) {
                if (debug && console) { console.log(`menu['content']${path}`) }
                return eval(`menu['content']${path}`) || []
            } else {
                throw('Unable to evaluate menu content')
            }
        } catch(e) {
            return []
        }
    }, [menu, path])

    const categories = useMemo(() => {
        try {
            return (Array.isArray(menuContent) ? menuContent : menuContent?.categories || []).filter((category) => category?.is_present)
        } catch(e) {
            if (console) { console.error(e) }
            return []
        }
    }, [menuContent])

    // sorted alpha ascending since there is no known sort order for pinned
    // items that are hoisted to the category level above where they are assigned
    const pinnedItems = useMemo(() => {
        if (!categories || !Array.isArray(categories)) { return [] }

        try {
            return categories.map((category) => (category.items || []).filter((item) => category.default_item_ids.includes(item.id)))
                             .flat()
                             .filter((item) => item.is_present)
                             .sort((a,b) => (a.name > b.name) ? 1 : -1)
        } catch(e) {
            if (console) { console.error(e) }
            return []
        }
    }, [categories])

    const items = useMemo(() => {
        try {
            return (Array.isArray(menuContent) ? [] : menuContent?.items || []).filter((item) => item.is_present)
        } catch(e) {
            if (console) { console.error(e) }
            return []
        }
    }, [menuContent])

    const handleLoadContentFor = (object, index) => {
        // requesting to load a specific menu, category, or item
        if (object?.id || object?.menu_uuid)
        {
            switch(String(object.type).toLowerCase()) {
                case 'menu' :
                    dispatch(fetchMenuContent(object)).then(() => {
                        dispatch(resetMenuLoadedPath())
                        dispatch(addBreadcrumb({
                            id: object.id,
                            name: object.name,
                            type: object.type,
                            button_color: object.button_color,
                        }))
                        dispatch(truncateBreadcrumbs(object))
                        setIsLoaded(true)
                    })
                    break

                case 'category' :
                    dispatch(fetchMenuContent(object?.menu_id ? { id: object.menu_id } : menu)).then(() => {
                        if (!!object?.path) {
                            dispatch(setLoadedMenuPath({ action: 'replace', payload: object.path }))
                            dispatch(truncateBreadcrumbs(object))
                            dispatch(setCurrentComponent('Menus'))
                        } else {
                            dispatch(setLoadedMenuPath({ action: 'push', payload: !!path ? `['categories'][${index}]` : `[${index}]` }))

                            dispatch(addBreadcrumb({
                                menu_uuid: object.menu_uuid,
                                name: object.name,
                                type: object.type,
                                button_color: object.button_color,
                                path: (
                                    !!path ? `menu['content']${path}['categories'][${index}]`
                                           : `menu['content']${path}[${index}]`
                                )
                            }))
                        }
                        scrollHinting.search()
                        setIsLoaded(true)
                    })
                    break

                case 'item' :
                    isLoadingItem.current = true
                    dispatch(setLoadedMenuPath({ action: 'push', payload: `['items'][${index}]` }))
                    dispatch(setLoadedItemId(object.id))
                    dispatch(setCurrentComponent('MenuItem'))
                    break

                default :
                    dispatch(addAlert({ type: 'error', text: `Could not load content for "${object.type}" type!` }))
            }
        } else {
            // requesting to load "all menus"
            dispatch(fetchMenuContent()).then(() => {
                dispatch(resetMenuBreadcrumbs())
                dispatch(resetMenuLoadedPath())
                scrollHinting.search()
                setIsLoaded(true)
            })
        }
    }

    useEffect(() => {
        if (!breadcrumbs) { return }

        onChange(
            <Breadcrumbs
                id='advanced-pos--terminal-navigation--breadcrumbs'
                data={breadcrumbs}
                onClick={handleLoadContentFor}
            />
        )
    }, [breadcrumbs])

    // load data/dependencies if necessary
    useEffect(() => {
        // if we already have breadcrumbs set, then we're
        // already on a breadcrumb item and are going back up the chain
        // so there's no need to do any of our default setup actions
        if (breadcrumbs.length > 1) { return }

        dispatch(resetMenuBreadcrumbs())
        dispatch(resetMenuLoadedPath())
        dispatch(resetMenuContent())

        // load all menus
        dispatch(fetchMenuContent(null, { loadDefault: shouldLoadDefault })).then(() => {
            scrollHinting.search()
            setIsLoaded(true)
        })

        return () => {
            if (isLoadingItem.current === false) {
                dispatch(resetMenuBreadcrumbs())
                dispatch(resetMenuLoadedPath())
                dispatch(resetMenuContent())
            }
        }
    }, [])

    return (
        <div id={`advanced-pos-terminal--${parameterize(self)}`} className='scrollhint'>
            <div className='scrollhint--inner'>
                {
                    (menus?.length > 0 || categories?.length > 0 || pinnedItems?.length > 0 || items?.length > 0) ? <>
                        <div className='row row-cols-1 row-cols-sm-2 row-cols-lg-3 align-items-stretch no-gutters'>
                            {
                                menus?.map((menu, index) => (
                                    <MenuButton
                                        key={index}
                                        index={index}
                                        data={menu}
                                        onClick={handleLoadContentFor}
                                    />
                                ))
                            }

                            {
                                categories?.map((category, index) => (
                                    <MenuButton
                                        key={index}
                                        index={index}
                                        data={category}
                                        onClick={handleLoadContentFor}
                                    />
                                ))
                            }
                        </div>

                        {
                            ((menus?.length > 0 || categories?.length > 0) && pinnedItems?.length > 0) && (
                                <hr className='menu--divider' />
                            )
                        }

                        <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3 align-items-stretch no-gutters'>
                            {
                                pinnedItems?.map((item, index) => (
                                    <MenuButton
                                        key={index}
                                        index={index}
                                        data={item}
                                        isUnavailable={!item.is_available}
                                        onClick={handleLoadContentFor}
                                    />
                                ))
                            }
                        </div>

                        {
                            ((menus?.length > 0 || categories?.length > 0) && items?.length > 0) && (
                                <hr className='menu--divider' />
                            )
                        }

                        <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3 align-items-stretch no-gutters'>
                            {
                                items?.map((item, index) => (
                                    <MenuButton
                                        key={index}
                                        index={index}
                                        data={item}
                                        isUnavailable={!item.is_available}
                                        onClick={handleLoadContentFor}
                                    />
                                ))
                            }
                        </div>
                    </> : isLoaded && (
                        <CenteredText textClassName='text-gray2'>None Found</CenteredText>
                    )
                }
            </div>
        </div>
    )
}
