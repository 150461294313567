import React from 'react'
import { useSelector } from 'react-redux'
import { selectPricing, selectDurations } from '../Packages/packageSlice'

export default function AddonDuration({ duration, setDuration }) {
    const pricing = useSelector(selectPricing)
    const durations = useSelector(selectDurations)

    const pricingDurations = pricing.map(pricing => pricing.prices.flat())
                                    .flat()
                                    .filter(price => Boolean(price.addon_price))
                                    .map(price => price.duration)

    const packageDurations = durations.map(duration => duration.duration)

    const genOptions = () => {
        const durationOptions = packageDurations.filter(duration => pricingDurations.includes(duration))
                                                .sort((a, b) => a - b)

        return durationOptions.map((duration, index) => {
            return <option value={duration} key={index}>{duration}</option>
        })
    }

    return pricing.length > 0 && (
        <div className="form-group">
            <label>Duration</label>
            <select
                className="form-control"
                name="duration"
                value={duration || ''}
                onChange={e => setDuration(Number.parseInt(e.target.value))}>

                <option value="" disabled>Select a Duration</option>
                { genOptions() }
            </select>
        </div>
    )
}
