import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  holdBooking,
  updateBooking,
  selectReminderAcknowledged,
  selectDuration,
  selectDate,
  selectTime,
  selectHeld,
  selectPreloaded,
  selectParticipants,
  setErrors,
  setStep,
  setTime,
  setShowReminder,
  setReminderAcknowledged,
  trackAbandonedBooking,
} from '../../bookingSlice'
import BookingModalFooter from '../BookingModalFooter'
import BookingModalLedger from '../Ledger/BookingModalLedger'
import { selectGroups, selectPricingType, selectAllowMemberBenefits } from '../../../Packages/packageSlice'
import { evaluateCustomerTypeSelectionStep } from '../../../../lib/Steps'
import { selectCustomerTypes } from '../../../CustomerTypes/customerTypeSlice'
import { fetchAvailableTimesForDate, selectTimes } from '../../../Availability/availabilitySlice'
import { selectResourceType } from '../../../ResourceType/resourceTypeSlice'
import TimesContainer from './TimesContainer'
import DayNavigation from './DayNavigation'
import { gaSendPageView } from '@/lib/GoogleAnalytics'

export default function Step3({ modalStyles, immediateFinalize=false, onNext=() => {} }) {

    const dispatch             = useDispatch()
    const preloaded            = useSelector(selectPreloaded)
    const participants         = useSelector(selectParticipants)
    const groups               = useSelector(selectGroups)
    const customerTypes        = useSelector(selectCustomerTypes)
    const pricingType          = useSelector(selectPricingType)
    const duration             = useSelector(selectDuration)
    const date                 = useSelector(selectDate)
    const time                 = useSelector(selectTime)
    const availableTimes       = useSelector(selectTimes)
    const bookingHeld          = useSelector(selectHeld)
    const resourceType         = useSelector(selectResourceType)
    const reminderAcknowledged = useSelector(selectReminderAcknowledged)
    const allowMemberBenefits  = useSelector(selectAllowMemberBenefits)

    const [initialLoad, setInitialLoad] = useState(true)

    const handleNext = () => {
        dispatch(setErrors({ type: 'clear' }))

        /**
         * If a reminder acknowledgement is required, it will be TRUE or FALSE.
         * Otherwise, we can consider NULL to also be "truthy"
         */
        if (reminderAcknowledged === false) {
            dispatch(setShowReminder(true))
        }

        if (reminderAcknowledged === true || reminderAcknowledged === null) {
            finalizeStep()
        }
    }

    const finalizeStep = () => {
        let errors = []

        if (!time) { errors.push('Please select a time slot.') }

        if (errors.length !== 0) {
            dispatch(setErrors({ type: 'add', errors }))
            return
        }

        dispatch((bookingHeld ? updateBooking : holdBooking)())
        dispatch(trackAbandonedBooking())

        dispatch(setStep(
            evaluateCustomerTypeSelectionStep(
                participants,
                groups,
                customerTypes,
                pricingType,
                false, // going backwards
                allowMemberBenefits
            )
        ))

        onNext()
    }

    /**
     * Initial component mount
     */
    useEffect(() => {
        setInitialLoad(false)

        if (reminderAcknowledged === null && resourceType?.show_reminder_modal) {
            dispatch(setReminderAcknowledged(false))
        }

        gaSendPageView('Select Start Time', '/step/3')
    }, [])

    useEffect(() => {
        if (!initialLoad && immediateFinalize && participants && groups && customerTypes && pricingType) {
            finalizeStep()
        }
    }, [initialLoad, participants, groups, customerTypes, pricingType])

    /**
     * Whenever the duration or date changes, fetch the available times... this really
     * only applies to date since the date navigation can change the date while we're on
     * step3. The way these dependencies work is that any time duration or date is changed and
     * this component is still mounted, it will refetch the available times for the new date/duration value.
     */
    useEffect(() => {
        if (!immediateFinalize) {
            dispatch(fetchAvailableTimesForDate(duration, date, participants, null, false))
        }
    }, [dispatch, duration, date])

    /**
     * Submit a new booking hold/reservation if it hasn't already happened and
     * if either the reminderAcknowledged is null (when an answer IS NOT required)
     * or true (when an answer IS required)
     */
    useEffect(() => {
        if (initialLoad) { return }

        /**
         * If a reminder acknowledgement is required, it will be TRUE or FALSE.
         * Otherwise, we can consider NULL to also be "truthy"
         */
        if (reminderAcknowledged === null || reminderAcknowledged === true) {
            finalizeStep()
        }
    }, [dispatch, reminderAcknowledged])

    return <>
        <div className="xbm-step-content">
            <div className="xbm-step-content-main">
                {/*<div className="xbm-step-counter">Step 3</div>*/}
                <div className="xbm-step-question">
                    <h2 className="mb-4">Start Time</h2>
                    <DayNavigation />
                    <TimesContainer />
                </div>
            </div>

            <BookingModalLedger />
        </div>

        <BookingModalFooter
            modalStyles={modalStyles}
            disableNext={
                Object.keys(availableTimes).length === 0
                || !time
            }
            onBack={() => {
                if (preloaded) {
                    dispatch(setStep('1'))
                } else {
                    dispatch(setTime(null))
                    dispatch(setReminderAcknowledged(null))
                    dispatch(setStep('2'))
                }
            }}
            onNext={handleNext}
        />
    </>
}
