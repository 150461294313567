import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm, FormProvider } from 'react-hook-form'

import {
    generateInvoiceLink,
    generatePaymentLink,
    generateWaiverLink,
    selectBooking,
    selectCustomers,
    selectOpenSendLinkToCustomerModal,
    setOpenSendLinkToCustomerModal,
} from '@/features/EditBooking/editBookingSlice'

import { generateGoogleReviewLink, generateMemberLoginLink, selectLocation } from '@/features/Locations/locationSlice'
import { sendPayloadToDestination } from '@/features/Utilities/utilitiesSlice'

import FormRow from '@/components/Form/FormRow'
import Input from '@/components/Form/Input'
import Select from '@/components/Form/Select'
import { errorsFor } from '@/components/Form/ErrorsHelper'
import { validationPatterns } from '@/lib/Errors'
import { sortedByArray } from '@/lib/Array'
import { UI } from '@/lib/Constants'

export default function SendLinkToCustomerModal() {

    const formMethods      = useForm()
    const dispatch         = useDispatch()
    const isOpen           = useSelector(selectOpenSendLinkToCustomerModal)
    const location         = useSelector(selectLocation)
    const booking          = useSelector(selectBooking)
    const participants     = useSelector(selectCustomers)?.filter((c) => !c.minor)
    const googleReviewLink = useSelector(generateGoogleReviewLink)
    const memberLoginLink  = useSelector(generateMemberLoginLink)
    const invoiceLink      = useSelector(generateInvoiceLink)
    const paymentLink      = useSelector(generatePaymentLink)
    const waiverLink       = useSelector(generateWaiverLink)

    const payload = formMethods.watch('payload', null)
    const email   = formMethods.watch('email', '')
    const phone   = formMethods.watch('phone', '')
    const errors  = errorsFor(formMethods.formState.errors)

    const [emailSending, setEmailSending] = useState(null)
    const [emailSent,    setEmailSent]    = useState(null)
    const [textSending,  setTextSending]  = useState(null)
    const [textSent,     setTextSent]     = useState(null)

    const customers = [
        { name: booking?.name, phone: booking?.phone, email: booking?.email },
        ...participants
    ]

    const options = useMemo(() => {
        if (!!!location || !!!booking) { return false }

        let choices = []

        if (!!invoiceLink) {
            choices.push({
                id: 'download-invoice',
                display: 'Download Invoice',
                email_subject: `${location.name} - Invoice Download Link for Reservation ${booking.reservation_number}`,
                email_prepend_content: `Below is an invoice download link for Reservation ${booking.reservation_number}`,
                text_content: `Please follow the link to download an invoice for reservation ${booking.reservation_number}.`,
                type: 'link',
                url: invoiceLink,
            })
        }

        if (!!googleReviewLink) {
            choices.push({
                id: 'google-review',
                display: 'Leave a Google Review',
                email_subject: `${location.name} - Google Review Link for Reservation ${booking.reservation_number}`,
                email_prepend_content: `Below is a review link for Reservation ${booking.reservation_number}`,
                text_content: `Please follow the link to leave us a review! Thank you!`,
                type: 'link',
                url: googleReviewLink,
            })
        }

        if (location?.memberships_enabled && !!memberLoginLink) {
            choices.push({
                id: 'member-login',
                display: 'Member Login',
                email_subject: `${location.name} - Member Login Link`,
                email_prepend_content: `Below is a member login link for a member of ${location.name}`,
                text_content: `Please follow the link to login to your member portal.`,
                type: 'link',
                url: memberLoginLink,
            })
        }

        if (!!!booking?.check && !!paymentLink) {
            choices.push({
                id: 'payment-link',
                display: 'Make a Payment',
                email_subject: `${location.name} - Payment Link for Reservation ${booking.reservation_number}`,
                email_prepend_content: `Below is a payment link for Reservation ${booking.reservation_number}`,
                text_content: `Please follow the link to make payment towards reservation ${booking.reservation_number}.`,
                type: 'link',
                url: paymentLink,
            })
        }

        if (booking?.resource_type?.waivers_required && !!waiverLink) {
            choices.push({
                id: 'waiver-link',
                display: 'Sign Waivers',
                email_subject: `${location.name} - Waiver Link for Reservation ${booking.reservation_number}`,
                email_prepend_content: `Below is a waiver link for Reservation ${booking.reservation_number}`,
                text_content: `Please follow the link to complete a waiver for reservation ${booking.reservation_number}.`,
                type: 'link',
                url: waiverLink,
            })
        }

        return sortedByArray(choices, 'asc', 'display')
    }, [booking])

    const handleClose = () => {
        dispatch(setOpenSendLinkToCustomerModal(false))
    }

    const handleKeyDown = (e) => {
        if (
            e.key === 'Escape'
            && !document.body.classList.contains(UI.classes.CONFIRMATION_OPEN)
            && !document.body.classList.contains(UI.classes.HELPJUICE_OPEN)
        ) {
            handleClose()
        }
    }

    const onSubmit = ({ destination, email, phone }) => {
        const fullPayload = options.find(({ id }) => id === payload)

        switch(destination) {
            case 'email' : {
                setEmailSending(true)

                dispatch(sendPayloadToDestination({
                    options: {
                        location_id: location.id,
                        destination,
                    },
                    payload: {
                        to: email,
                        ...fullPayload,
                    }
                }))
                .then(({ success }) => { if (success) { setEmailSent(true) }})
                .finally(() => setEmailSending(false))

                break
            }

            case 'text' : {
                setTextSending(true)

                dispatch(sendPayloadToDestination({
                    options: {
                        location_id: location.id,
                        destination,
                    },
                    payload: {
                        to: phone,
                        ...fullPayload,
                    }
                }))
                .then(({ success }) => { if (success) { setTextSent(true) }})
                .finally(() => setTextSending(false))

                break
            }

            default :
                if (console) { console.error('An unknown error occurred while trying to send a link to a customer!' )}
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown, false)

        if (!isOpen) {
            formMethods.reset()
        }

        // cleanup/reset on unmount
        return () => {
            document.removeEventListener('keydown', handleKeyDown, false)
        }
    }, [isOpen])

    // auto flip the button text back 3 seconds after sending successfully
    useEffect(() => {
        if (emailSent) { window.setTimeout(() => setEmailSent(false), 3000) }
        if (textSent)  { window.setTimeout(() => setTextSent(false), 3000) }
    }, [emailSent, textSent])

    return isOpen && (
        <FormProvider {...formMethods}>
            <div className='modal modal-backdrop modal--sm send-link-to-customer-modal'>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-container modal-content">
                        <div className="modal-header">
                            <div className='modal-title'>
                                Send Link To Customer
                            </div>

                            <button className="modal-close mb-auto" onClick={handleClose}>
                                <span>Close</span>
                                <i className="far fa-times" />
                            </button>
                        </div>

                        <div className="modal-body">
                            <div className="modal-body-main">
                                <div className="modal-body-main-section p-4">
                                    <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                                        <input
                                            type='hidden'
                                            {...formMethods.register('destination', { required: true })}
                                        />

                                        <FormRow>
                                            <Select
                                                cols='12'
                                                label='What Would You Like To Send?'
                                                value={formMethods.watch('payload')}
                                                options={options}
                                                validation={{ 'payload': { required: true }}}
                                                errors={errors}
                                                includeBlank
                                            />
                                        </FormRow>

                                        {
                                            !!payload && <>
                                                <hr className='mb-4' />

                                                <FormRow>
                                                    <Input
                                                        cols='12'
                                                        label='Email'
                                                        placeholder="sample@email.com"
                                                        value={email}
                                                        errors={errors}
                                                        validation={{ 'email': {
                                                            required: false,
                                                            pattern: validationPatterns.email,
                                                        }}}
                                                        req={false}
                                                        prepend={!!booking.email}
                                                        append
                                                    >
                                                        {
                                                            !!booking.email && participants.length === 0 && (
                                                                <div className="input-group-prepend">
                                                                    <button
                                                                        type='submit'
                                                                        children='Customer Email Address'
                                                                        className='btn btn-outline-secondary'
                                                                        title='Pre-fill with the email address used when making the reservation.'
                                                                        onClick={() => formMethods.setValue('email', booking.email)}
                                                                    />
                                                                </div>
                                                            )
                                                        }

                                                        {
                                                            participants.length > 0 && (
                                                                <div className="input-group-prepend">
                                                                    <Select
                                                                        cols='12'
                                                                        value={formMethods.watch('customer_email') || null}
                                                                        options={customers.map((c) => (
                                                                            { value: c.email, display: `${c.name} — ${c.email}` }
                                                                        ))}
                                                                        validation={{ 'customer_email': { required: false }}}
                                                                        inputClassName='border-secondary'
                                                                        inputStyle={{
                                                                            borderTopRightRadius: 0,
                                                                            borderBottomRightRadius: 0,
                                                                            width: '250px',
                                                                            display: !!formMethods.watch('email') ? 'none' : 'block',
                                                                        }}
                                                                        withWrapper={false}
                                                                        includeBlank='Customer Email Addresses'
                                                                        hideLabel
                                                                        handleChange={(e) => formMethods.setValue('email', e.target.value)}
                                                                    />
                                                                </div>
                                                            )
                                                        }

                                                        <div className="input-group-append">
                                                            {
                                                                !!email && email.length > 0 && (
                                                                    <button
                                                                        type='button'
                                                                        children={<i className='fas fa-circle-xmark fa-lg' />}
                                                                        className='btn btn-secondary text-white'
                                                                        onClick={() => {
                                                                            formMethods.setValue('customer_email', '')
                                                                            formMethods.setValue('email', '')
                                                                        }}
                                                                    />
                                                                )
                                                            }
                                                            <button
                                                                type='submit'
                                                                children={emailSending ? 'PLEASE WAIT...' : (emailSent ? 'SENT!' : 'SEND')}
                                                                className={`btn ${emailSent ? 'btn-success' : 'btn-primary'} px-4`}
                                                                disabled={emailSending || email.length === 0 || !validationPatterns.email.value.test(email) }
                                                                onClick={() => {
                                                                    formMethods.setValue('destination', 'email')
                                                                    return true
                                                                }}
                                                            />
                                                        </div>
                                                    </Input>
                                                </FormRow>

                                                <FormRow>
                                                    <Input
                                                        cols='12'
                                                        type='tel'
                                                        label='Phone'
                                                        placeholder="555-867-5309"
                                                        mask='999-999-9999'
                                                        value={phone}
                                                        errors={errors}
                                                        validation={{ 'phone': {
                                                            required: false,
                                                            pattern: validationPatterns.phone_basic,
                                                        }}}
                                                        req={false}
                                                        prepend={!!booking.phone}
                                                        append
                                                    >
                                                        {
                                                            !!booking.phone && participants.length === 0 && (
                                                                <div className="input-group-prepend">
                                                                    <button
                                                                        type='submit'
                                                                        children='Customer Phone Number'
                                                                        className='btn btn-outline-secondary'
                                                                        title='Pre-fill with the phone number used when making the reservation.'
                                                                        onClick={() => formMethods.setValue('phone', booking.phone)}
                                                                    />
                                                                </div>
                                                            )
                                                        }

                                                        {
                                                            participants.length > 0 && (
                                                                <div className="input-group-prepend">
                                                                    <Select
                                                                        cols='12'
                                                                        value={formMethods.watch('customer_phone') || null}
                                                                        options={customers.map((c) => (
                                                                            { value: c.phone, display: `${c.name} — ${c.phone}` }
                                                                        ))}
                                                                        validation={{ 'customer_phone': { required: false }}}
                                                                        inputClassName='border-secondary'
                                                                        inputStyle={{
                                                                            borderTopRightRadius: 0,
                                                                            borderBottomRightRadius: 0,
                                                                            width: '250px',
                                                                            display: !!formMethods.watch('phone') ? 'none' : 'block',
                                                                        }}
                                                                        withWrapper={false}
                                                                        includeBlank='Customer Phone Numbers'
                                                                        hideLabel
                                                                        handleChange={(e) => formMethods.setValue('phone', e.target.value)}
                                                                    />
                                                                </div>
                                                            )
                                                        }

                                                        <div className="input-group-append">
                                                            {
                                                                !!phone && phone.replace(/[_-]/g, '').length > 0 && (
                                                                    <button
                                                                        type='button'
                                                                        children={<i className='fas fa-circle-xmark fa-lg' />}
                                                                        className='btn btn-secondary text-white'
                                                                        onClick={() => {
                                                                            formMethods.setValue('customer_phone', '')
                                                                            formMethods.setValue('phone', '')
                                                                        }}
                                                                    />
                                                                )
                                                            }
                                                            <button
                                                                type='submit'
                                                                children={textSending ? 'PLEASE WAIT...' : (textSent ? 'SENT!' : 'SEND')}
                                                                className={`btn ${textSent ? 'btn-success' : 'btn-primary'} px-4`}
                                                                disabled={textSending || !validationPatterns.phone_basic.value.test(phone)}
                                                                onClick={() => {
                                                                    formMethods.setValue('destination', 'text')
                                                                    return true
                                                                }}
                                                            />
                                                        </div>
                                                    </Input>
                                                </FormRow>
                                            </>
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button
                                type='button'
                                className='btn btn-outline-primary px-5'
                                children='Close'
                                onClick={handleClose}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </FormProvider>
    )
}
