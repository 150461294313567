import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectPreloaded,
  selectDuration,
  selectNumberOfResources,
  selectStep,
  setReminderAcknowledged,
  resetCustomerTypes,
  setStep,
  setDuration,
  setDate,
  setTime,
  setGiftCards,
  setPaymentReady,
  setGiftCardNumber,
  removePromoCode
} from '@/features/Bookings/bookingSlice'
import { selectResourceType } from '@/features/ResourceType/resourceTypeSlice'
import { resourceVerbiage } from '@/features/Bookings/utils'

export default function LedgerDuration({ clearCalendars }) {

    const dispatch          = useDispatch()
    const preloaded         = useSelector(selectPreloaded)
    const duration          = useSelector(selectDuration)
    const numberOfResources = useSelector(selectNumberOfResources)
    const resourceType      = useSelector(selectResourceType)
    const step              = useSelector(selectStep)

    return /[1-2]/.test(step) ? null : (
        <div className="xbm-ledger-section">
            <div className="xbm-ledger-section-action-label">
                <div className="xbm-ledger-section-action-label-label">
                    <span>{duration} Minutes</span>

                    { numberOfResources !== null && numberOfResources > 0 &&
                        <span> on {`${numberOfResources} ${resourceVerbiage(numberOfResources, resourceType)}`}</span>
                    }
                </div>
                {
                    !preloaded && (
                        <div className="xbm-ledger-section-action-label-action">
                            <button
                                children='Change'
                                className="as-link"
                                onClick={() => {
                                    clearCalendars()
                                    dispatch(setDuration(null))
                                    dispatch(setDate(null))
                                    dispatch(setTime(null))
                                    dispatch(setGiftCards({ type: 'clear' }))
                                    dispatch(setGiftCardNumber(""))
                                    dispatch(setPaymentReady(false))
                                    dispatch(removePromoCode())
                                    dispatch(resetCustomerTypes())
                                    dispatch(setReminderAcknowledged(null))
                                    dispatch(setStep('2'))
                                }}
                            />
                        </div>
                    )
                }
            </div>
        </div>
    )
}
