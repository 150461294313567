import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useFormContext, Controller } from 'react-hook-form'
import InputWrapper from './InputWrapper'
import DatePicker from 'react-datepicker'
import titleize from '../../lib/String'
import errorClass from '../../lib/Errors'
import { preventTyping } from '@/lib/Form'

export default function TimePicker({
  name,
  value,
  placeholder,
  label,
  interval,
  format,
  cols,
  disabled,
  tooltip='',
  req=null,
  errors=[],
  validation=null,
  handleUpdate=() => {},
  preventTyping=false
}) {
    const formMethods = useFormContext()

    const separator = ':'

    const _name = validation
        ? Object.keys(validation)[0]
        : name + separator + uuidv4()

    /**
     * Example of an expected validation
     * schema object to register
     *
     * {
     *   'name': {
     *     required: 'A name is required',
     *   }
     * }
     */

    const handleChange = (field, date) => {
        // update react hook form validation
        if ('onChange' in field) { field.onChange(date) }
        // update our form object
        handleUpdate({ target: { name: field.name, value: date } })
    }

    // If `preventTyping` is passed in (LocationHoursTab), don't allow users to type in the text field
    // This uses the `onChangeRaw` param from DatePicker
    // Src: https://github.com/Hacker0x01/react-datepicker/issues/942#issuecomment-424939438
    const handleDateChangeRaw = (e) => {
      e.preventDefault();
    }

    return (
        <InputWrapper
            name={_name.split(separator)[0]}
            req={(validation && req === null) || Boolean(req)}
            tooltip={tooltip}
            cols={cols}
            errors={errors}
            label={label}
        >
            <div className={errorClass(_name, errors)}>
                { formMethods ? (
                    <Controller
                        name={_name}
                        control={formMethods.control}
                        defaultValue={value}
                        rules={validation ? validation[Object.keys(validation)[0]] : {}}
                        render={({ field }) =>
                            <DatePicker
                                ref={field.ref}
                                className={`form-control ${errorClass(_name, errors)}`}
                                placeholderText={placeholder || titleize(_name)}
                                disabled={disabled}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={interval || 15}
                                timeCaption='Time'
                                dateFormat={format || 'h:mm aa'}
                                selected={field.value ? new Date(field.value) : ''}
                                onChange={(date) => handleChange(field, date)}
                                onChangeRaw={preventTyping ? handleDateChangeRaw : ''}
                            />
                        }
                    />
                ) : (
                    <DatePicker
                        name={_name}
                        className={`form-control ${errorClass(_name, errors)}`}
                        placeholderText={placeholder || titleize(_name)}
                        disabled={disabled}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={interval || 15}
                        timeCaption='Time'
                        dateFormat={format || 'h:mm aa'}
                        selected={value ? new Date(value) : ''}
                        onChange={(date) => handleUpdate({ name: _name }, date)}
                        onChangeRaw={preventTyping ? handleDateChangeRaw(this) : ''}
                    />
                )}
            </div>
        </InputWrapper>
    )
}
