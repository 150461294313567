import React from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { selectTimeZone } from '@/features/Locations/locationSlice'
import { selectParentBooking, selectChildBookings } from '@/features/ParentBooking/parentBookingSlice'
import { isBookingComplete } from '@/lib/Booking'

export default function ParentBookingGroupDetailsBasicInfo() {

    const parentBooking    = useSelector(selectParentBooking)
    const childBookings    = useSelector(selectChildBookings)
    const selectedTimeZone = useSelector(selectTimeZone)
    const startDateTime    = moment.tz(parentBooking.start_time, selectedTimeZone)
    const endDateTime      = moment.tz(parentBooking.end_time, selectedTimeZone)
    const participantCount = childBookings?.reduce((sum, cb) => sum + parseInt((cb.group_max || cb.participants), 10), 0)

    return !parentBooking || !selectedTimeZone ? null : (
        <div className="modal-body-main-section">
            <div className="row modal-micro-details pb-2">
                <div className="col-12 col-lg-6 modal-micro-detail">
                    <span className='pr-2'>Scheduled Date:</span>
                    <span className='float-right float-lg-none'>
                        { startDateTime.format('MM/DD/YYYY') }
                    </span>
                </div>

                <div className="col-12 col-lg-6 modal-micro-detail">
                    <span className='pr-2'>Package Name:</span>
                    <span className='float-right float-lg-none'>
                        { parentBooking.package.name }
                    </span>
                </div>

                <div className="col-12 col-lg-6 modal-micro-detail">
                    <span className='pr-2'>Scheduled Time:</span>
                    <span className='float-right float-lg-none'>
                        { `${startDateTime.format('LT')} - ${endDateTime.format('LT')}` }
                    </span>
                </div>

                <div className="col-12 col-lg-6 modal-micro-detail">
                    <span className='pr-2'>Duration:</span>
                    <span className='float-right float-lg-none'>
                        {parentBooking.duration} Minutes
                    </span>
                </div>

                <div className="col-12 col-lg-6 modal-micro-detail">
                    <span className='pr-2'>Actual Time:</span>
                    <span className='float-right float-lg-none'>
                        {
                            parentBooking?.started_at ? <>
                                { moment.tz(parentBooking.started_at, selectedTimeZone).format('LT') }
                                &nbsp;-&nbsp;
                                { parentBooking.ended_at ? moment.tz(parentBooking.ended_at, selectedTimeZone).format('LT') : 'In Progress' }
                            </> : (
                                'Not started'
                            )
                        }
                    </span>
                </div>

                {
                    !(isBookingComplete(parentBooking) && parentBooking.package.is_a_league) && (
                        <div className="col-12 col-lg-6 modal-micro-detail">
                            <span className='pr-2'>Capacity:</span>
                            <span className='float-right float-lg-none'>
                                {participantCount} - {parentBooking.package.special_event_capacity || '??'} Slots
                            </span>
                        </div>
                    )
                }
            </div>
        </div>
    )
}
