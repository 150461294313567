import { useDispatch } from 'react-redux'
import { showConfirm, hideConfirm, setConfirmationOptions } from '@/features/Notifications/notificationSlice'
import { UI } from '@/lib/Constants'

let resolveCallback

export function useConfirm() {
    const dispatch = useDispatch()

    const onConfirmation = () => {
        document.body.classList.remove(UI.classes.MULTIPLE_MODALS_OPEN)
        document.body.classList.remove(UI.classes.CONFIRMATION_OPEN)
        dispatch(hideConfirm())

        if (typeof resolveCallback === 'function') {
            resolveCallback(true)
        }
    }

    const onCancellation = () => {
        document.body.classList.remove(UI.classes.MULTIPLE_MODALS_OPEN)
        document.body.classList.remove(UI.classes.CONFIRMATION_OPEN)
        dispatch(hideConfirm())

        if (typeof resolveCallback === 'function') {
            resolveCallback(false)
        }
    }

    const confirm = (text, options={}) => {
        document.body.classList.add(UI.classes.MULTIPLE_MODALS_OPEN)

        dispatch(setConfirmationOptions(options))
        dispatch(showConfirm(text))

        return new Promise((resolve, reject) => {
            resolveCallback = resolve
        })
    }

    return { confirm, onConfirmation, onCancellation }
}
