import { createSlice } from "@reduxjs/toolkit"
import uuid from 'react-uuid'
import { UI } from '@/lib/Constants'

/*
 * EXAMPLES OF ALERT TYPES:
 * { id: GENERATED_UUID, order: 3, type: 'error',   text: 'BIG BADA BOOM!'              },
 * { id: GENERATED_UUID, order: 2, type: 'warning', text: 'Uh oh, watch out!'           },
 * { id: GENERATED_UUID, order: 1, type: 'info',    text: 'Oh hai, something happened.' },
 * { id: GENERATED_UUID, order: 0, type: 'success', text: 'Woohoo!'                     },
 */

const initialOptions = {
    hideHeader: false,
}

export const notificationSlice = createSlice({
    name: 'notifications',
    initialState: {
      alerts: [],
      confirmation: {
        show: false,
        text: '',
        options: initialOptions,
      },
      reload: {
        trigger: false,
        duration: (5 * 60_000), // 5 minutes
      }
    },
    reducers: {
        addAlert: (state, action) => {
            const count = state.alerts.length

            state.alerts.unshift({
              ...{
                id: uuid(),
                order: (count + 1),
                // this is the default type, a type passed
                // in via the payload will override this
                type: 'info',
              },
              ...action.payload,
            })
        },
        removeAlert: (state, action) => {
            const targetId = action.payload
            state.alerts = [...state.alerts].filter((alert) => targetId !== alert.id)
        },
        showConfirm: (state, action) => {
            state.confirmation = { ...state.confirmation, show: true, text: action.payload }
            document.body.classList.add(UI.classes.CONFIRMATION_OPEN)
        },
        hideConfirm: (state) => {
            state.confirmation         = { ...state.confirmation, show: false, text: '' }
            state.confirmation.options = initialOptions
            document.body.classList.remove(UI.classes.CONFIRMATION_OPEN)
        },
        setConfirmationOptions: (state, action) => {
            state.confirmation.options = { ...state.confirmation.options, ...action.payload }
        },
        forceClientReload: (state, action) => {
            state.reload.trigger = true

            if (!!action.payload?.duration) {
                state.reload.duration = action.payload.duration
            }
        },
    }
})

export const {
    addAlert,
    removeAlert,
    showConfirm,
    hideConfirm,
    setConfirmationOptions,
    forceClientReload,
} = notificationSlice.actions

export const selectAlerts              = state => state.notifications.alerts
export const selectConfirmation        = state => state.notifications.confirmation
export const selectConfirmationOptions = state => state.notifications.confirmation.options
export const selectReloadDuration      = state => state.notifications.reload.duration
export const selectReloadTrigger       = state => state.notifications.reload.trigger

export default notificationSlice.reducer
