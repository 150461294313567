import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import deepEqual from 'deep-equal'

import {
    closeModal,
    configureModal,
    openModal,
    resetMenuBreadcrumbs,
    selectLoadedMenuPath,
    selectCurrentCheck,
    selectInItemEditMode,
    selectItemConfig,
    selectMenuItem,
    selectTemporary,
    setCurrentComponent,
    setItemConfig,
    setTemporary,
    updateItemOnChitTab,
    selectPreviousComponent,
    updateItemOnCheckTab,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import TextArea from '@/components/Form/TextArea'
import ButtonGroup from '@/features/AdvancedPointOfSale/components/buttons/ButtonGroup'
import NewTabButton from '@/features/AdvancedPointOfSale/components/buttons/NewTabButton'
import { useConfirm } from '@/lib/useConfirmHook'

export default function MenuItemFooterActions({ children }) {

    const dispatch          = useDispatch()
    const currentMenuPath   = useSelector(selectLoadedMenuPath)
    const itemEditMode      = useSelector(selectInItemEditMode)
    const item              = useSelector(selectMenuItem)
    const itemConfig        = useSelector(selectItemConfig)
    const currentCheck      = useSelector(selectCurrentCheck)
    const temp              = useSelector(selectTemporary)
    const previousComponent = useSelector(selectPreviousComponent)
    const origItemConfigRef = useRef()
    const { confirm }       = useConfirm()

    const [changesMade, setChangesMade] = useState(false)

    const handleCreateNewCheck = () => {
        dispatch(closeModal('createCheck'))

        dispatch(configureModal({
            modal: 'createCheck',
            config: {
                action: 'create_unknown_resource_type_check',
                resourceType: null,
                returnTo: {
                    menuPath: currentMenuPath,
                    itemId: item.id,
                    itemConfig,
                }
            }
        }))

        dispatch(openModal('createCheck'))
    }

    const handleUpdateNotes = (e) => {
        dispatch(setItemConfig({ ...itemConfig, ...{ notes: e.target.value } }))
    }

    const handleAddItem = () => {
        dispatch(openModal('addToChitTab'))
    }

    const handleEditItem = async () => {
        const updatedItemConfig = {...itemConfig}

        if (/^CHECK-ITEM$/i.test(itemEditMode)) {
            const confirmMessage = (
                'Please let the printer location know that the change has happened.\
                A new chit will NOT be printed out after the item is edited.'
            )

            if (await confirm(confirmMessage)) {
                dispatch(updateItemOnCheckTab({
                    item,
                    itemConfig: updatedItemConfig,
                    itemUuid: temp.itemUuid,
                    tabId: temp.tabId
                })).then((data) => {
                    if (data.success) {
                        origItemConfigRef.current = {...updatedItemConfig}
                        setChangesMade(false)
                        dispatch(setCurrentComponent(previousComponent))
                    }
                })
            }
        }

        if (/^CHIT-ITEM$/i.test(itemEditMode)) {
            dispatch(updateItemOnChitTab({
                item,
                itemUuid: temp.itemUuid,
                itemConfig: updatedItemConfig,
                tabId: temp.tabId
            })).then((data) => {
                if (data.success) {
                    origItemConfigRef.current = {...updatedItemConfig}
                    setChangesMade(false)
                    dispatch(setCurrentComponent('DefaultMenu'))
                }
            })
        }
    }

    const handleLeaveEditMode = () => {
        dispatch(resetMenuBreadcrumbs())
        dispatch(setCurrentComponent(previousComponent))
    }

    useEffect(() => {
        if (!itemConfig) { return }
        if (temp.isEdited) { setChangesMade(true); return }

        // set the original reference point
        if (!origItemConfigRef.current) {
            origItemConfigRef.current = {...itemConfig}

        // detect if any changes have been made or not and
        // then update button disabled states accordingly
        } else {
            setChangesMade(deepEqual(
                origItemConfigRef.current,
                {...itemConfig},
                { strict: true }
            ) === false)
        }
    }, [itemConfig])

    useEffect(() => {
        return () => {
            dispatch(setTemporary({}))
        }
    }, [])

    return <>
        <TextArea
            name='notes'
            hideLabel={true}
            withWrapper={false}
            placeholder='Notes'
            value={itemConfig?.notes}
            handleChange={handleUpdateNotes}
        />

        <ButtonGroup
            id='advanced-pos-terminal--menuitem--footeractions--buttons'
            className='theme--outline-solid'
        >
            {
                !!itemEditMode ? <>
                    <button
                        type='button'
                        children='Cancel'
                        onClick={handleLeaveEditMode}
                    />
                    <button
                        type='button'
                        children='Save Changes'
                        disabled={!changesMade}
                        onClick={handleEditItem}
                    />
                </> : <>
                    {
                        !currentCheck && (
                            <button
                                type='button'
                                children='Create New Check'
                                onClick={handleCreateNewCheck}
                            />
                        )
                    }

                    { !!currentCheck && <NewTabButton /> }

                    {
                        !!currentCheck && currentCheck?.tabs?.length >= 1 && (
                            <button
                                type='button'
                                children='Add Item'
                                onClick={handleAddItem}
                            />
                        )
                    }
                </>
            }
        </ButtonGroup>

        { children }
    </>
}
