import React from 'react'

export default function PurchaseModalStyles({ modalStyles }) {
    if (!modalStyles) { return '' }

    const determineFormatOf = (font) => {
        switch(true) {
            case /.otf/i.test(font) :
                return "format('opentype')"

            case /.ttf$/i.test(font) :
                return "format('truetype')"

            case /.woff2/i.test(font) :
                return "format('woff2')"

            case /.woff/i.test(font) :
                return "format('woff')"

            default :
                return false
        }
    }

    return (
        <style type="text/css">
            {`
                @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,900&display=swap'); //Site Default
                @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;0,900;1,400;1,900&display=swap');
                @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;0,800;1,400;1,800&display=swap');
                @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;0,900;1,400;1,900&display=swap');
                @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,400;0,700;1,400;1,700&display=swap');
                @import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700;900&display=swap');
                @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;0,900;1,400;1,900&display=swap');
                @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,700;0,900;1,400;1,900&display=swap');

                .font-roboto            {font-family: 'Roboto', 'Lato', sans-serif;}
                .font-open-sans         {font-family: 'Open Sans', 'Lato', sans-serif;}
                .font-roboto-slab       {font-family: 'Roboto Slab', 'Lato', serif;}
                .font-lato              {font-family: 'Lato', sans-serif;}
                .font-montserrat        {font-family: 'Montserrat', 'Lato', sans-serif;}
                .font-source-sans       {font-family: 'Source Sans Pro', 'Lato', sans-serif;}
                .font-roboto-condensed  {font-family: 'Roboto Condensed', 'Lato', sans-serif;}
                .font-poppins           {font-family: 'Poppins', 'Lato', sans-serif;}
            `}


            { modalStyles.modal_header_font && determineFormatOf(modalStyles.modal_header_font) && `
                @font-face {
                    font-family: 'CustomHeaderFont';
                    src: url("${modalStyles.modal_header_font}") ${determineFormatOf(modalStyles.modal_header_font)};
                }
                .xbm-container h1,
                .xbm-container h2,
                .xbm-container h3,
                .xbm-container h4,
                .xbm-container h5,
                .xbm-container h6,
                .member-modal-container h1,
                .member-modal-container h2,
                .member-modal-container h3,
                .member-modal-container h4,
                .member-modal-container h5,
                .member-modal-container h6 {
                    font-family: 'CustomHeaderFont', sans-serif;
                }
            `}

            { modalStyles.modal_header_font && determineFormatOf(modalStyles.modal_font) && `
                @font-face {
                    font-family: 'CustomFont';
                    src: url("${modalStyles.modal_font}") ${determineFormatOf(modalStyles.modal_font)};
                }
                .xbm-container,
                .member-modal-container {
                    font-family: 'CustomFont', sans-serif;
                }
            `}

            {`
                /* MEMBER MODAL OVERFLOW */
                .member-modal-container {
                    overflow-y: auto !important;
                }

                /* MODAL BACKGROUND IMAGE */
                .xbm-container,
                .xbm-container .modal-container,
                .member-modal-container,
                .member-modal-container .modal-container {
                    background-image: url("${modalStyles.modal_background_image}");
                    background-repeat: no-repeat;
                    background-size: cover;
                    color: ${modalStyles.text_hex_color};
                }

                /* DIVIDERS */
                .xbm-container hr,
                .xbm-container .modal-container hr,
                .member-modal-container hr,
                .member-modal-container .modal-container hr {
                    border-color: ${modalStyles.text_hex_color};
                }

                /* FORM LABELS */
                .xbm-container label,
                .xbm-container .modal-container label,
                .member-modal-container label,
                .member-modal-container .modal-container label {
                    color: ${modalStyles.primary_hex_color} !important;
                }

                /* CARD TEXT */
                .xbm-container .card,
                .xbm-container .modal-container .card,
                .member-modal-container .card,
                .member-modal-container .modal-container .card {
                    color: ${modalStyles.primary_hex_color} !important;
                }

                /* FORM LABELS INSIDE CARDS */
                .xbm-container .card label,
                .xbm-container .modal-container .card label,
                .member-modal-container .card label,
                .member-modal-container .modal-container .card label {
                    color: initial !important;
                }

                /* MEMBERSHIP TYPE CARDS STYLING */
                .membership-type-card {
                    color: ${modalStyles.text_hex_color} !important;
                    border: 1px solid !important;
                }

                .membership-type-card,
                .membership-type-card .card-footer { 
                    backdrop-filter: blur(10px) !important;
                    background: transparent !important;
                }

                /* DEFAULT LINK BUTTON */
                .xbm-container .btn,
                .xbm-container .btn:active,
                .member-modal-container .btn,
                .member-modal-container .btn:active {
                    color: ${modalStyles.text_hex_color};
                }

                /* PRIMARY BUTTON */
                .xbm-container .btn-primary,
                .xbm-container .btn-primary:active,
                .member-modal-container .btn-primary,
                .member-modal-container .btn-primary:active {
                    background-color: ${modalStyles.primary_hex_color} !important;
                    border-color: ${modalStyles.primary_hex_color} !important;
                }

                /* BASIC TEXT BUTTONS */
                .xbm-close,
                .xbm-inner .xbm-step-content .xbm-step-counter,
                .xbm-inner .xbm-running-ledger button.as-link,
                .xbm-inner .xbm-checkout-ledger button.as-link,
                .xbm-inner .xbm-step-content .xbm-time-select-change-day .xbm-time-day .btn {
                    color: ${modalStyles.primary_hex_color};
                }

                /* BUTTONS */
                .xbm-inner .xbm-step-content .radio-as-card input + .radio-card, .xbm-inner a.btn.btn-outline {
                    background-color: transparent;
                    border-color: ${modalStyles.primary_hex_color} !important;
                    color: ${modalStyles.text_hex_color} !important;
                }
                .xbm-inner .xbm-step-content .radio-as-card input:checked + .radio-card {
                    background-color: ${modalStyles.primary_hex_color} !important;
                    border-color: ${modalStyles.primary_hex_color} !important;
                    color: ${modalStyles.text_hex_color} !important;
                }

                /* LOADER */
                #global-loading--content {
                    background-color: ${modalStyles.primary_hex_color} !important;
                    color: ${modalStyles.text_hex_color} !important;
                }
            `}
        </style>
    )
}
