import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectLogoutPath } from '@/features/Session/sessionSlice'
import LogoutButton from '@/components/Sessions/LogoutButton'
import NumberKeyPad from '@/components/NumberKeyPad'

export default function PassCodeKeyPad({
    id=null,
    className='',
    limit=4,
    onChange=() => {}
}) {

    const codeRef         = useRef()
    const [code, setCode] = useState(null)
    const logoutPath      = useSelector(selectLogoutPath)

    const handleDelete = () => {
        if (codeRef.current !== undefined && codeRef.current !== null && codeRef.current.length > 0) {
            codeRef.current = codeRef.current.toString().substring(0, codeRef.current.toString().length-1)
            setCode(codeRef.current)
        }
    }

    const handleChange = (value) => {
        // prevent too many characters from being entered
        if ((codeRef.current?.length || 0) === Number(limit)) { return }

        // respond to only numbers
        if (/\d/.test(value)) {
            codeRef.current = `${!!codeRef.current ? codeRef.current : ''}${value}`.trim()
            setCode(codeRef.current)
        }

        // once we've hit the limit, kick off the change event
        if (codeRef.current.length === Number(limit)) {
            window.setTimeout(() => {
                setCode(null)
            }, 400)

            window.setTimeout(() => {
                onChange(codeRef.current)
                codeRef.current = null
            }, 600)
        }
    }

    return (
        <div id={id} className={`passcode-keypad ${className}`.trim()}>
            <div className='row no-gutters'>
                <div className='col-12 px-1'>
                    <div className='passcode rounded bg-black text-white mb-2 w-100'>
                        { code?.replace(/./g, '*') || '' }
                    </div>
                </div>
            </div>

            <NumberKeyPad
                buttons={[1, 2, 3, 4, 5, 6, 7, 8, 9, 'logout', 0, 'backspace']}
                logoutButton={
                    <LogoutButton
                        className='btn-logout'
                        logout_path={logoutPath}
                        iconOnly={true}
                        icon={<i className='fal fa-sign-out fa-rotate-180' />}
                    />
                }
                isKeyDisabled={{ 'backspace': (code === null || code.length === 0) }}
                onChange={handleChange}
                onDelete={handleDelete}
            />
        </div>
    )
}
