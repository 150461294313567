import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, FormProvider } from 'react-hook-form'

import {
    selectLoading,
    submitMember,
    selectAutoRenew,
    selectMembership,
    selectMembershipCard,
    selectRecipientType,
    updateMembership,
    selectAllowCreditCardPayments,
    setGiftCardNumber,
    selectGiftCards,
    setCreditZip,
    selectCreditZip,
    deleteError,
} from '../memberSlice'

import { errorsFor } from '@/components/Form/ErrorsHelper'
import AdyenCreditCard from '../../Adyen/AdyenCreditCard'
import GiftCardFields from '@/features/Bookings/components/Step6/GiftCardFields'
import MemberStep from './MemberStep'
import CardsOnFile from '../CardsOnFile'
import MemberSteps, { RecipientTypes } from '@/lib/Member'
import { numberToCurrency } from '@/lib/Number'

export default function MemberPayment({ environment, locationId, locationTimeZone }) {

    const formMethods             = useForm()
    const dispatch                = useDispatch()
    const loading                 = useSelector(selectLoading)
    const membership              = useSelector(selectMembership)
    const recipient               = useSelector(selectRecipientType)
    const giftCards               = useSelector(selectGiftCards)
    const zipCode                 = useSelector(selectCreditZip)
    const autoRenew               = useSelector(selectAutoRenew)
    const allowCreditCardPayments = useSelector(selectAllowCreditCardPayments)
    const selectedCard            = useSelector(selectMembershipCard)

    const [giftCardErrors, setGiftCardErrors] = useState({})
    const [adyenCheckout, setAdyenCheckout]   = useState(null)

    const onGiftCardError = (formErrors) => {
        setGiftCardErrors(errorsFor(formErrors))
    }

    const handleSubmit = () => {
        if (membership) {
            dispatch(updateMembership())
        } else {
            dispatch(submitMember())
        }
    }

    useEffect(() => {
        return () => {
            dispatch(deleteError([MemberSteps.PAYMENT_INFORMATION, 'submit']))
        }
    }, [])

    return (
        <FormProvider {...formMethods}>
            <MemberStep
                disabled={loading}
                // NOTE might need to go to? MemberSteps.MEMBERSHIP_TERM
                backStep={recipient === RecipientTypes.MYSELF ? MemberSteps.MEMBER_INFORMATION : MemberSteps.GIFTED_BUYER_INFORMATION}
                adyenCheckout={adyenCheckout}
                handleSubmit={handleSubmit}
            >
                <h2 className="text-center pt-md-4 pb-4">Payment Information</h2>

                <div className="container form-container payment-container">
                    <h5>Gift Card</h5>

                    <GiftCardFields
                        errors={giftCardErrors}
                        handleChange={e => dispatch(setGiftCardNumber(e.target.value))}
                        handleManualChange={() => dispatch(setGiftCardNumber(''))}
                        handleOnError={onGiftCardError}
                    />

                    {
                        giftCards.map(giftCard => {
                            return (
                                <div key={giftCard.cardNumber} className="mb-4 mb-md-2">
                                    <i className="fa fa-check mr-2" />

                                    <span className="text-monospace text-uppercase pr-md-4 d-inline-block mb-1">
                                        {giftCard.cardNumber}
                                    </span>

                                    <small className="d-inline-block mb-1 bg-success text-white py-1 px-3 rounded-pill text-bold">
                                        {numberToCurrency(giftCard.appliedAmount / 100.0)} APPLIED
                                    </small>

                                    <small className="d-inline-block mb-1 pl-2 pl-md-3 text-bold">
                                        Remaining <span className="d-none d-md-inline">Balance</span>:&nbsp;
                                        {numberToCurrency((giftCard.balance - giftCard.appliedAmount) / 100.0)}
                                    </small>
                                </div>
                            )
                        })
                    }
                </div>

                {
                    allowCreditCardPayments && <>
                        <CardsOnFile />

                        {
                            !selectedCard && (
                                <div className="container form-container payment-container my-3">
                                    <h5>Credit Card</h5>

                                    <AdyenCreditCard
                                        locationId={locationId}
                                        locationTimeZone={locationTimeZone}
                                        adyenCheckout={adyenCheckout}
                                        zipcode={zipCode}
                                        environment={environment}
                                        setAdyenCheckout={setAdyenCheckout}
                                        setZipCode={(e) => dispatch(setCreditZip(e.target.value))}
                                        onSubmit={handleSubmit}
                                    />
                                </div>
                            )
                        }
                    </>
                }

                {
                    autoRenew && !allowCreditCardPayments && (
                        <div className="alert alert-warning shadow rounded mt-5" role="alert">
                            <h4>Important!</h4>
                            <p>
                               Because you've elected to auto-renew your membership but are purchasing using a gift card,
                               please remember to add a default credit card payment method to your account or auto-renewal will fail.
                            </p>
                        </div>
                    )
                }
            </MemberStep>
        </FormProvider>
    )
}
