import React from 'react'
import Checkbox from '../Form/Checkbox'
import Input from '../Form/Input'
import Select from '../Form/Select'
import FormRow from '../Form/FormRow'
import titleize from "../../lib/String"
import DatePicker from '../Form/DatePicker'
import { validationPatterns } from '../../lib/Errors'

const formattedOptions = (genders) => {
    return Object.keys(genders).map((gender) => {
        return { value: gender, display: titleize(gender) }
    })
}

export default function CustomerForm({
    genders,
    form,
    errors,
    handleUpdate,
    handleManualUpdate,
    handleCheckboxUpdate,
}) {
    return (
        <div>
            <FormRow>
                <Input
                    name='name'
                    handleChange={handleUpdate}
                    value={form.name}
                    validation={{ 'name': { required: true }}}
                    cols='6'
                    errors={errors} />
                <Input
                    name='email'
                    handleChange={handleUpdate}
                    value={form.email}
                    validation={{ 'email': {
                        required: !form?.is_minor,
                        pattern: validationPatterns.email,
                      }}}
                    req={!form?.is_minor}
                    cols='6'
                    errors={errors} />
            </FormRow>
            <FormRow>
                <Select
                    name='gender'
                    handleChange={handleUpdate}
                    options={formattedOptions(genders)}
                    validation={{ 'gender': { required: true }}}
                    value={form.gender}
                    cols='6'
                    errors={errors} />
                <Input
                    name='zip_code'
                    handleChange={handleUpdate}
                    value={form.zip_code}
                    validation={{ 'zip_code': {
                        required: true,
                        pattern: validationPatterns.zip,
                      }}}
                    cols='6'
                    errors={errors} />
            </FormRow>
            <FormRow>
                <Input
                    name='phone'
                    handleChange={handleUpdate}
                    mask='999-999-9999'
                    value={form.phone}
                    validation={{ 'phone': {
                        required: !form?.is_minor,
                        pattern: validationPatterns.phone,
                      }}}
                    req={!form?.is_minor}
                    cols='6'
                    errors={errors} />
                <DatePicker
                    name='dob'
                    cols='4'
                    minDate={new Date(Date.parse(`01/01/${(new Date()).getFullYear() - 100}`))}
                    maxDate={new Date()}
                    showMonthDropdown={true}
                    showYearDropdown={true}
                    value={form.dob}
                    errors={errors}
                    validation={{ 'dob': { required: true }}}
                    handleChange={handleManualUpdate}
                />
                <Checkbox
                  cols='2'
                  className="mt-4 pt-1 pl-3"
                  name='is_minor'
                  label='Is Minor?'
                  value={form.is_minor}
                  handleChange={handleCheckboxUpdate}
                />
            </FormRow>
        </div>
    )
}
