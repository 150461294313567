import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm, FormProvider } from 'react-hook-form'
import { selectLocation } from '@/features/Locations/locationSlice'

import {
    cancelTransaction,
    configureModal,
    closeModal,
    createTabForCheck,
    openModal,
    selectCurrentCheck,
    selectComponent,
    selectHasVirtualKeyboardApi,
    selectModals,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import {
    preAuthorizeCardViaTerminal,
    tokenizeCardViaTerminal,
    clearAdyenState,
    selectTerminalResponse,
    cancelTerminalTransaction
} from '@/features/Adyen/adyenSlice'

import ButtonTabNav from '@/features/AdvancedPointOfSale/components/tabs/ButtonTabNav'
import Modal from '@/features/AdvancedPointOfSale/components/Modal'
import ModalListButton from '@/features/AdvancedPointOfSale/components/buttons/ModalListButton'
import NameInput from '@/features/AdvancedPointOfSale/components/forms/NameInput'
import Checkbox from '@/components/Form/Checkbox'
import AdyenTerminalTransaction from '@/features/Terminal/AdyenTerminalTransaction'
import ExistingTokenizedCardsOnFile from '@/features/AdvancedPointOfSale/components/ExistingTokenizedCardsOnFile'
import PreAuthorizationAmount from '@/features/AdvancedPointOfSale/components/PreAuthorizationAmount'
import { PREVENT_LOADER } from '@/lib/Storage'

const defaultModes = [ { slug: 'custom_name', label: 'Custom Name' }]

export default function CreateNewTabModal({
    method='PRE_AUTHORIZE', // or TOKENIZE
}) {

    const dispatch               = useDispatch()
    const formMethods            = useForm({ mode: 'all' })
    const name                   = formMethods.watch('name')
    const terminalResponse       = useSelector(selectTerminalResponse)
    const hasVirtualKeyboardApi  = useSelector(selectHasVirtualKeyboardApi)
    const currentComponent       = useSelector(selectComponent)
    const currentCheck           = useSelector(selectCurrentCheck)
    const { createNewTab:modal } = useSelector(selectModals)
    const location               = useSelector(selectLocation)
    const submitButtonRef        = useRef()

    const [modes, setModes]                                       = useState(defaultModes)
    const [mode, setMode]                                         = useState(defaultModes[0])
    const [participant, setParticipant]                           = useState(null)
    const [selectedProfile, setSelectedProfile]                   = useState(null)
    const [hasMembership, setHasMembership]                       = useState(false)
    const [membershipId, setMembershipId]                         = useState(null)
    const [disableMembershipIdInput, setDisableMembershipIdInput] = useState(null)
    const [isCreating, setIsCreating]                             = useState(null)
    const [captureCard, setCaptureCard]                           = useState(false)
    const [amountToPreAuthorize, setAmountToPreAuthorize]         = useState(null)
    const [preAuthorizedPaymentId, setPreAuthorizedPaymentId]     = useState(null)

    const tabEmails = useMemo(() => (
        currentCheck?.tabs?.map(tab => tab.email)
    ), [currentCheck])

    const participants = useMemo(() => (
        currentCheck?.booking?.customers || []
    ), [currentCheck])

    const capturingDisabled = useMemo(() => (
        method === 'PRE_AUTHORIZE' && (
            amountToPreAuthorize === null
            || amountToPreAuthorize < location.minimum_pre_authorized_amount
            || amountToPreAuthorize <= 0
            || !!preAuthorizedPaymentId
        )
    ), [amountToPreAuthorize, preAuthorizedPaymentId])

    const customer = useMemo(() => ({
        name: name || participant?.name,
        email: participant?.email || ''
    }), [name, participant])

    const handleClose = (skipReset=false, cancelPreAuth=false) => {
        if (skipReset) {
            if (!!preAuthorizedPaymentId && cancelPreAuth) {
                dispatch(cancelTransaction(currentCheck.id, preAuthorizedPaymentId))
            }

            dispatch(cancelTerminalTransaction())
            dispatch(closeModal('createNewTab'))
        } else {
            formMethods.reset()
            setParticipant(null)
            setSelectedProfile(null)
            setHasMembership(false)
            setMembershipId(null)
            setModes(defaultModes)
            setMode(defaultModes[0])
            setParticipant(null)
            setSelectedProfile(null)
            setHasMembership(null)
            setMembershipId(null)
            setCaptureCard(null)
            setAmountToPreAuthorize(null)
            setPreAuthorizedPaymentId(null)
            setIsCreating(null)

            handleClose(true, cancelPreAuth)
        }
    }

    const handleModeChange = (mode) => {
        formMethods.reset()
        setParticipant(null)
        setMode(mode)
    }

    // either set the customer or deselect the customer if
    // clicking on the same name in the list a second time
    const handleParticipantSelection = (customer) => {
        if (customer.id === participant?.id) {
            setParticipant(null)
        } else {
            setDisableMembershipIdInput(!!customer?.member?.active_membership?.membership_id)
            setHasMembership(!!customer?.member?.active_membership?.membership_id)
            setMembershipId(customer.member?.active_membership?.membership_id)
            setParticipant(customer)
        }
    }

    const handleCreate = () => {
        setIsCreating(true)

        dispatch(createTabForCheck(
            customer,
            selectedProfile,
            membershipId,
            preAuthorizedPaymentId
        ))
        .then((data) => {
            if (data.success) {
                dispatch(clearAdyenState())
                handleClose(false, false)

                if (/^MenuItem$/i.test(currentComponent)) {
                    dispatch(openModal('addToChitTab'))
                }
            }
        })
        .catch(() => {
            if (!!preAuthorizedPaymentId) {
                dispatch(cancelTransaction(currentCheck.id, preAuthorizedPaymentId))
            }
        })
        .finally(() => {
            setIsCreating(false)
        })
    }

    const handleTokenizeCard = () => {
        setSelectedProfile(null)
        dispatch(tokenizeCardViaTerminal(location.id))
    }

    const handlePreAuthorizeCard = () => {
        dispatch(preAuthorizeCardViaTerminal(
            amountToPreAuthorize,
            location.id,
            'undefined',
            'tab'
        ))
        .then((data) => {
            if (data.success) {
                setPreAuthorizedPaymentId(data.id)

                dispatch(configureModal({
                    modal: 'popModal',
                    config: { isOpen: true, text: data.message, icon: 'fa-floppy-disk bg-green' }
                }))
            }
        })
    }

    useEffect(() => {
        if (modal.isOpen) {
            formMethods.reset()
            window.sessionStorage.setItem(PREVENT_LOADER, true)

            if (
                !modes.map((obj) => obj.slug).includes('participants')
                && !!currentCheck.booking
                && participants.length > 0
            ) {
                const updatedModes = [...modes]
                updatedModes.splice(1,0, { slug: 'participants', label: 'Participants' })
                setModes(updatedModes)
            }
        } else {
            formMethods.reset()
            dispatch(clearAdyenState())
            window.sessionStorage.removeItem(PREVENT_LOADER)
        }
    }, [modal.isOpen])

    return modal.isOpen && (
        <FormProvider {...formMethods}>
            <Modal
                className='create-new-tab-modal'
                title='Create New Tab'
                isOpen={modal.isOpen}
                footerButtons={<>
                    <button
                        type='button'
                        className='btn btn-outline-secondary text-white text-bold mr-auto py-2'
                        children='Close'
                        onClick={() => handleClose(false, true)}
                    />
                    <button
                        type='button'
                        ref={submitButtonRef}
                        children={isCreating ? 'Please Wait' : 'Create'}
                        className="btn btn-primary ml-auto py-2"
                        disabled={
                            isCreating
                            || (mode.slug === 'custom_name' && !customer.name)
                            || (mode.slug === 'participants' && !customer.name)
                            || (hasMembership && !membershipId)
                            || (captureCard && method === 'PRE_AUTHORIZE' && !preAuthorizedPaymentId)
                            || (captureCard && method === 'TOKENIZE' && !terminalResponse)
                        }
                        onClick={handleCreate}
                    />
                </>}
                onClose={() => handleClose(false, true)}
            >
                {
                    modal.isOpen && (
                        <div className="pt-4 pb-0 mt-2 w-100">
                            {
                                modes.length > 1 && (
                                    <ButtonTabNav
                                        tabs={modes}
                                        currentTab={mode}
                                        className='d-flex justify-content-between pb-4 mx-4 mb-2'
                                        onChange={handleModeChange}
                                    />
                                )
                            }

                            {
                                // custom name input
                                mode.slug === modes[0]?.slug && (
                                    <div className='px-4'>
                                        <NameInput
                                            field='name'
                                            className='my-n2'
                                            inputContainerClassName='col-12 px-1'
                                            autoFocus={false}
                                            withWrapper={false}
                                            onEnterCallback={() => { submitButtonRef.current.click() }}
                                        />
                                    </div>
                                )
                            }

                            {
                                // waivers/participants
                                mode.slug === modes[1]?.slug && modes.map((obj) => obj.slug).includes('participants') && (
                                    <div className='participants-list scrollhint px-4'>
                                        <div className='border-bottom border-color-gray3 pb-4 mx-n1 scrollhint--inner'>
                                            {
                                                participants.map((customer) => (
                                                    <ModalListButton
                                                        key={customer.id}
                                                        children={<>
                                                            <span>{customer.name}</span>
                                                            <span className='ml-auto'>
                                                                {customer.is_checked_in && <span className='ml-2 text-success'><i className="fa-solid fa-badge-check"></i></span>}
                                                                {customer.is_minor && <span className='ml-2 badge badge-pill badge-warning p-1'>M</span>}
                                                            </span>
                                                            {customer.member?.active_membership &&
                                                                <span className='badge badge-info ml-2'>
                                                                    <i className="fa-solid fa-id-badge"></i>
                                                                    <span className='ml-1'>{customer.member.active_membership.membership_id}</span>
                                                                </span>
                                                            }
                                                        </>}
                                                        disabled={tabEmails.includes(customer.email) || !customer.is_checked_in}
                                                        selected={customer.id === participant?.id}
                                                        onClick={() => handleParticipantSelection(customer)}
                                                    />
                                                ))
                                            }
                                        </div>
                                    </div>
                                )
                            }

                            <div className='d-flex align-items-center p-4'>
                                <Checkbox
                                    cols=''
                                    name='has_membership'
                                    label="Has Membership?"
                                    value={hasMembership}
                                    className='pb-0'
                                    handleChange={() => setHasMembership(hasMembership ? null : !hasMembership)}
                                />

                                <Checkbox
                                    cols=''
                                    name='capture_card'
                                    label="Capture Card?"
                                    value={captureCard}
                                    className='pb-0 ml-auto'
                                    handleChange={() => setCaptureCard(captureCard ? null : !captureCard)}
                                />
                            </div>

                            {
                                hasMembership && <>
                                    <hr className='mt-2 mx-4 mb-1' />

                                    <div className='form-group px-4 mt-3 mb-2'>
                                        <label className='req'>
                                            Membership Id
                                        </label>

                                        <input
                                            className='form-control'
                                            placeholder='ABC-123456'
                                            value={membershipId || ''}
                                            readOnly={disableMembershipIdInput}
                                            type="text"
                                            onChange={(e) => setMembershipId(e.target.value)}
                                        />
                                    </div>
                                </>
                            }

                            {
                                method === 'TOKENIZE' && currentCheck?.booking?.payment_profiles?.length > 0 && (
                                    <div className="alert alert-warning rounded m-4 text-center font-weight-bold font-italic" role="alert">
                                        <p className='mb-0'>Optional: Attach a credit card to the new tab.</p>
                                        <p className='mb-0'>Either capture a new credit card or choose an existing stored card.</p>
                                    </div>
                                )
                            }

                            {
                                captureCard && <>
                                    <hr className='mt-2 mx-4 mb-1' />

                                    <div className='card-capture'>
                                        {
                                            (method === 'PRE_AUTHORIZE') && (
                                                <PreAuthorizationAmount
                                                    amount={amountToPreAuthorize}
                                                    minimumAmount={location.minimum_pre_authorized_amount}
                                                    formula='BASIC'
                                                    onChange={(amount) => setAmountToPreAuthorize(amount)}
                                                />
                                            )
                                        }

                                        <div className="p-4 w-100 form-group">
                                            <label className='req'>
                                                Select a terminal
                                            </label>

                                            <AdyenTerminalTransaction
                                                locationId={location.id}
                                                buttonText={!!preAuthorizedPaymentId ? 'Captured' : 'Capture'}
                                                buttonTextProcessing='Capturing...'
                                                buttonTextProcessed='Captured!'
                                                menuClassName='border border-color-gray4'
                                                disabled={capturingDisabled}
                                                transactionCallback={() => {
                                                    if (method === 'TOKENIZE') {
                                                        handleTokenizeCard()
                                                    }
                                                    if (method === 'PRE_AUTHORIZE') {
                                                        handlePreAuthorizeCard()
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </>
                            }

                            {
                                (method === 'TOKENIZE') && (
                                    <ExistingTokenizedCardsOnFile
                                        className='px-4 pb-4'
                                        profiles={currentCheck?.booking?.payment_profiles}
                                        selected={selectedProfile}
                                        isOptional
                                        onChange={(profile) => {
                                            dispatch(cancelTerminalTransaction())
                                            setSelectedProfile(profile)
                                        }}
                                    />
                                )
                            }
                        </div>
                    )
                }
            </Modal>
        </FormProvider>
    )
}
