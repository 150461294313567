import React from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { selectMemberInformation, selectStep, selectMembership } from '../memberSlice'
import MemberSteps from '../../../lib/Member'

export default function MemberInformationLedger() {
    const info       = useSelector(selectMemberInformation)
    const step       = useSelector(selectStep)
    const membership = useSelector(selectMembership)

    const black = [
        MemberSteps.SELECT_MEMBER_TYPE,
        MemberSteps.SELECT_RECIPIENT,
        MemberSteps.MEMBER_INFORMATION,
        MemberSteps.GIFTED_MEMBER_INFORMATION,
        MemberSteps.MEMBERSHIP_TERM
    ]

    if (black.includes(step) || membership) {
        return null
    }

    return (
        <div className="xbm-ledger-section">
            <h5>Member Information</h5>
            {info.firstName} {info.lastName} <br />
            {info.email} <br />
            {info.phone} <br />

            {
                !!info.birthdate && <>
                    DOB: {moment(info.birthdate).format('MM/DD/YYYY')} <br />
                </>
            }
        </div>
    )
}
