import React from 'react'
import { useSelector } from 'react-redux'
import MemberStep from './Steps/MemberStep'
import { useDispatch } from 'react-redux'
import { setTermsOpen, selectDetailsAndTerms } from './memberSlice'

export default function TermsContent() {

    const dispatch        = useDispatch()
    const detailsAndTerms = useSelector(selectDetailsAndTerms)

    const footer = () => {
        return (
            <button
                children='Back'
                className="btn btn-primary"
                type="button"
                onClick={() => dispatch(setTermsOpen(false))}
            />
        )
    }

    return (
        <MemberStep hideLedger={true} footerButtons={footer()}>
            <h2 className="text-center pt-4 pb-4">Details & Terms</h2>
            <div dangerouslySetInnerHTML={{ __html: detailsAndTerms }} />
        </MemberStep>
    )
}
