import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { selectTipType, setTipType, selectTipAmount, setTipAmount } from './bookingPaymentSlice'
import { errorMessageFor } from '@/components/Form/ErrorsHelper'

export default function PaymentTip() {

    const { register, watch, trigger, setValue, formState: { errors } } = useFormContext()

    const dispatch  = useDispatch()
    const tipType   = useSelector(selectTipType)
    const tipAmount = useSelector(selectTipAmount)
    const amount    = watch('tipAmount')

    const tipAmountField = register('tipAmount', {
        validate: (rawValue) => {
            const amount = isNaN(parseFloat(rawValue)) ? 0 : parseFloat(rawValue)

            switch(true) {
                case amount > 0 && !tipType:
                    return 'You must select a tip type when including a tip amount.'

                case tipType && amount === 0 :
                    return 'The tip amount must be greater than 0.'

                case tipType && rawValue === '' :
                    return 'A tip amount is required.'

                default :
                    return true
            }
        }
    })

    const handleClearTip = () => {
        setValue('tipAmount', null)
        setValue('tipType', null)
        dispatch(setTipType(null))
        dispatch(setTipAmount(null))
    }

    const handleSetTipType = (e) => {
        dispatch(setTipType(e.target.value))
    }

    useEffect(() => {
        if (!!tipType && !!amount && amount > 0) {
            trigger(['tipAmount', 'tipType'])
            dispatch(setTipAmount(amount))
        }
    }, [tipType, amount])

    return (
        <div className="modal-body-main-section py-3">
            <div className="d-flex align-items-stretch">
                <div className="form-group p-0 pr-3 d-flex align-items-center">
                    <label className="m-0">Add&nbsp;Tip</label>
                </div>

                <div className="radio-as-button pr-2 d-flex align-stretch">
                    <input
                        id="tipDollar"
                        name="tipType"
                        type="radio"
                        value='dollar'
                        checked={tipType === 'dollar'}
                        className='radio-as-button-input'
                        onChange={handleSetTipType}
                    />

                    <label
                        children={<i className="far fa-dollar-sign" />}
                        htmlFor="tipDollar"
                        className="radio-as-button-label d-flex mb-0 px-3 align-items-center"
                        aria-label="Tip in dollars"
                    />
                </div>

                <div className='input-group'>
                    <input
                        {...tipAmountField}
                        className={`flex-1 form-control ${errors['tipAmount'] ? 'is-invalid' : ''}`}
                        type="text"
                        placeholder={!!tipType ? tipType == 'dollar' ? '0.00' : '0.0' : 'Tip Amount'}
                    />

                    {
                        !!tipType && !!tipAmount && tipAmount > 0 && (
                            <div className='input-group-append'>
                                <button
                                    children={<i className="far fa-times" />}
                                    type='button'
                                    className='btn btn-secondary text-white'
                                    onClick={handleClearTip}
                                />
                            </div>
                        )
                    }
                </div>

                <div className="radio-as-button pl-2 d-flex align-stretch">
                    <input
                        id="tipPercent"
                        name="tipType"
                        type="radio"
                        value='percent'
                        checked={tipType === 'percent'}
                        className='radio-as-button-input'
                        onChange={handleSetTipType}
                    />

                    <label
                        children={<i className="far fa-percent" />}
                        htmlFor="tipPercent"
                        className="radio-as-button-label d-flex mb-0 px-3 align-items-center"
                        aria-label="Tip as a percentage"
                    />
                </div>
            </div>

            {
                !!errorMessageFor('tipAmount', errors) && (
                    <div className="pr-5 text-right w-100">
                        <div className='pr-3'>
                            { errorMessageFor('tipAmount', errors) }
                        </div>
                    </div>
                )
            }
        </div>
    )
}
