import React, { useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { selectViewedDate, selectManualBufferOpen, setManualBufferOpen } from './calendarSlice'
import { selectTimeZone } from '@/features/Locations/locationSlice'
import { createBuffers } from '@/features/CalendarBookings/calendarBookingsSlice'
import { selectResources } from '@/features/Resources/resourcesSlice'
import DateTimePicker from '@/components/Form/DateTimePicker'
import Help from '@/features/Help/Help'
import { roundedToNearestMinute, localDateToMoment, momentToLocalDate } from '@/lib/CalendarTimes'
import { debug } from '@/lib/Debug'

export function _ManualBufferModal() {

    const dispatch   = useDispatch()
    const timezone   = useSelector(selectTimeZone)
    const viewedDate = useSelector(selectViewedDate)
    const open       = useSelector(selectManualBufferOpen)
    const resources  = useSelector(selectResources)

    const durations = [15, 30, 45, 60]

    const [startTime, setStartTime]     = useState('')
    const [resourceIds, setResourceIds] = useState([])
    const [duration, setDuration]       = useState(durations[0]) // 15 is default

    const creatingMultipleBuffers = useMemo(() => (
        duration > durations[0] || resourceIds.length > 1
    ), [duration, resourceIds])

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            handleClose()
        }
    }

    const handleClose = () => {
        dispatch(setManualBufferOpen(false))
    }

    const updateStartTime = (e) => {
        try {
            if (typeof e.target.value === 'object') {
              setStartTime(localDateToMoment(e.target.value, timezone))
            } else {
              setStartTime(moment.tz(e.target.value, 'MMM Do YYYY hh:mmA', timezone))
            }
        } catch (e) {
            if (debug & console) { console.warn('Could not set start_time value', e) }
            return
        }
    }

    const handleDurationSelection = (e) => {
        setDuration(Number.parseInt(e.target.value, 10))
    }

    const handleResourceSelection = (e) => {
        const id = Number.parseInt(e.target.value, 10)

        if (resourceIds.includes(id)) {
            setResourceIds(resourceIds.filter((v) => v !== id))
        } else {
            setResourceIds([...resourceIds, id])
        }
    }

    const handleSelectAll = () => {
        if (resources.length == resourceIds.length) {
            setResourceIds([])
        } else {
            setResourceIds(resources.map((r) => r.id))
        }
    }

    const handleSubmit = () => {
        dispatch(createBuffers(resourceIds, startTime.toString(), duration))
        handleClose()
    }

    /**
     * Handle the initial mount and
     * unmount of the component
     */
    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown, false)

        return () => {
            document.removeEventListener('keydown', handleKeyDown, false)
            setStartTime('')
            setResourceIds([])
            setDuration(15)
        }
    }, [open])

    useEffect(() => {
        if (open && viewedDate && !Boolean(startTime)) {
            const now = moment.tz(timezone)

            const st = moment.tz(viewedDate, 'YYYY-MM-DD', timezone)
                        .set({
                          'hours': now.hours(),
                          'minutes': now.minutes(),
                          'seconds': 0,
                          'milliseconds': 0,
                        })

            setStartTime( roundedToNearestMinute(st, 15) )
        }
    }, [open, viewedDate])

    return !open ? null : (
        <div className="modal modal-backdrop modal--xs manual-buffer-modal">
            <div className="modal-container">
                <div className="modal-header">
                    <div className="row">
                        <div className="col-12">
                          <div className="modal-title">
                              Create {creatingMultipleBuffers ? 'Buffers' : 'Buffer'}
                              <Help articleId='154000158993-adding-manual-buffers' />
                          </div>
                        </div>
                    </div>
                    <button className="modal-close mb-auto" onClick={handleClose}>
                        <span>Close</span>
                        <i className="far fa-times" />
                    </button>
                </div>
                <div className="modal-body" style={{ minHeight: '400px' }}>
                    <div className="modal-body-main">
                        <div className="modal-body-main-section">
                            <div className="row">
                                <DateTimePicker
                                    name='start_time'
                                    cols='12'
                                    popperPlacement="bottom"
                                    value={startTime !== '' ? momentToLocalDate(startTime) : startTime}
                                    handleChange={updateStartTime}
                                />
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Duration</label>
                                        <select
                                            className="custom-select mb-0"
                                            value={duration}
                                            onChange={handleDurationSelection}
                                        >
                                            { durations.map(d => <option key={`duration-${d}`} value={d}>{d} Minutes</option>) }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group pb-0">
                                        <label>Resources</label>
                                        {
                                            resources.length > 0 && <>
                                                <div className='form-group col-12 border-bottom pb-2 mb-2'>
                                                    <div className='custom-control custom-checkbox pl-3 py-1'>
                                                        <input
                                                            id='all-resources'
                                                            name='all-resources'
                                                            className='custom-control-input'
                                                            type='checkbox'
                                                            checked={resources.length == resourceIds.length}
                                                            onChange={handleSelectAll}
                                                        />
                                                        <label className='custom-control-label' htmlFor='all-resources'>
                                                            Select All Resources
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className='form-group col-12 pb-0'>
                                                    {
                                                        resources.map((r) => (
                                                            <div
                                                                key={`resource-${r.id}`}
                                                                className='custom-control custom-checkbox pl-3 py-1'
                                                            >
                                                                <input
                                                                    id={`resource-${r.id}`}
                                                                    className='custom-control-input'
                                                                    name='resources[]'
                                                                    type='checkbox'
                                                                    value={r.id}
                                                                    checked={resourceIds.includes(r.id)}
                                                                    onChange={handleResourceSelection}
                                                                />
                                                                <label className='custom-control-label' htmlFor={`resource-${r.id}`}>
                                                                    { debug ? `(${r.id}) ${r.name}` : r.name }
                                                                </label>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                Boolean(startTime) && Boolean(duration) && resourceIds.length > 0 &&
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group m-0 p-0">
                                                <button
                                                    className="btn btn-primary w-100"
                                                    onClick={handleSubmit}
                                                >
                                                    Create {creatingMultipleBuffers ? 'Buffers' : 'Buffer'}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const ManualBufferModal = React.memo(_ManualBufferModal)
