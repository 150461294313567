import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectRecipientType, setRecipientType } from './memberSlice'

export default function RecipientRadio({ type, label, disabled=false }) {

    const dispatch      = useDispatch()
    const recipientType = useSelector(selectRecipientType)

    const setType = () => {
        dispatch(setRecipientType(type))
    }

    return !disabled && (
        <div className="radio-as-card mt-4" onClick={setType}>
            <input
                name='recipient_type'
                type="radio"
                value={type}
                checked={recipientType === type}
                onChange={setType}
            />

            <div className="radio-card">
                {label}
            </div>
        </div>
    )
}
