import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UI } from '@/lib/Constants'
import { setValid } from '../ManagerCodes/managerCodeSlice'
import { selectModalOpen, setModalOpen, clearFields } from './productSlice'
import ProductTransaction from './ProductTransaction'

export default function ProductTransactionModal({
    locationId,
    locationTaxRate,
    locationTimeZone,
    locationPaymentProcessor,
    productsPath,
    purchasePath,
    hardwareKey,
    hidePartialAlert,
    environment,
    inBooking
}) {

    const dispatch = useDispatch()
    const open     = useSelector(selectModalOpen)

    const handleKeyDown = (e) => {
        if (
            e.key === 'Escape'
            && !document.body.classList.contains(UI.classes.CONFIRMATION_OPEN)
            && !document.body.classList.contains(UI.classes.HELPJUICE_OPEN)
        ) {
            handleClose()
        }
    }

    const handleClose = () => {
        dispatch(clearFields())
        dispatch(setValid(false))
        dispatch(setModalOpen(false))
    }

    /**
     * Handle the initial mount and
     * unmount of the component
     */
    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown, false)

        if (open) {
            document.body.classList.add(UI.classes.MULTIPLE_MODALS_OPEN)
        }

        // cleanup/reset on unmount
        return () => {
            document.body.classList.remove(UI.classes.MULTIPLE_MODALS_OPEN)
            document.removeEventListener('keydown', handleKeyDown, false)
        }
    }, [open])

    return !open ? null : (
        <div className="modal modal-backdrop modal--xxxxxl pos-modal">
            <div className="modal-container">
                <div className="modal-header">
                    <div className="modal-title">New POS Transaction</div>
                    <button className="modal-close" onClick={handleClose}>
                        <span>Close</span>
                        <i className="far fa-times" />
                    </button>
                </div>
                <div className="modal-body">
                    <div className="modal-body-main">
                        <div className="modal-body-main-section">
                            {/* this is the shared component between the standalone page and the modal version */}
                            {/* in the transaction container it's just wrapped in a Provider for its own toolkit context */}
                            <ProductTransaction
                                productsPath={productsPath}
                                purchasePath={purchasePath}
                                locationId={locationId}
                                locationTaxRate={locationTaxRate}
                                locationTimeZone={locationTimeZone}
                                locationPaymentProcessor={locationPaymentProcessor}
                                hardwareKey={hardwareKey}
                                environment={environment}
                                hidePartialAlert={hidePartialAlert}
                                inBooking={inBooking}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
