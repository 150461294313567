import React, { useState } from 'react'
import axios from 'axios'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { debug } from '@/lib/Debug'
import { accessToken } from '@/lib/Csrf'

export default function ProductsSortOrder({ products=[], sortOrder=[], updatePath }) {

    const initialButtonLabel = 'Save Changes'

    const [error, setError]                       = useState(null)
    const [updatedSortOrder, setUpdatedSortOrder] = useState(null)
    const [sortedProducts, setSortedProducts]     = useState(products)
    const [buttonLabel, setButtonLabel]           = useState(initialButtonLabel)

    const handleOnDragEnd = (result) => {
        if (!result.destination) { return }

        const items = [...sortedProducts]
        const [reorderedItem] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, reorderedItem)

        setSortedProducts(items)
        setUpdatedSortOrder(items.map((i) => i.id))
    }

    const handleSave = () => {
        setButtonLabel('Saving...')

        axios.post(updatePath, {
            authenticity_token: accessToken,
            pos_products_sort_order: updatedSortOrder
        })
        .then(({ data }) => {
            if (data.success) {
                window.location.reload()
                return
            }
        })
        .catch((error) => {
            if (debug && console) { console.error(error?.response?.data || error) }
            setError('Could not update the sort order.')
            setButtonLabel('ERROR')
        })
        .finally(() => {
            window.setTimeout(() => setError(null), 3000)
            setButtonLabel(initialButtonLabel)
        })
    }

    const renderProducts = () => (
        sortedProducts.map((product, index) => (
            <Draggable
                key={`product-${product.id}`}
                draggableId={`${product.id}`}
                index={index}
            >
                {(provided) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                            <div className={`draggable cursor-pointer px-2 ${index === 0 ? 'pb-2' : 'border-top mt-2 pt-2'}`}>
                            <strong>{product.name}</strong>
                            <i className="fas fa-bars text-muted mt-1 ml-3 float-right" />
                        </div>
                    </div>
                )}
            </Draggable>
        ))
    )

    return <>
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="draggable-options">
                {(provided) => (
                    <div
                        ref={provided.innerRef}
                        className="draggable-options"
                        {...provided.droppableProps}
                    >
                        { renderProducts() }
                        { provided.placeholder }
                    </div>
                )}
            </Droppable>
        </DragDropContext>

        <div className="row mt-4">
            <div className="col text-right">
                <button
                    className={`btn btn-${error !== null ? 'danger' : 'primary'} text-white`}
                    disabled={updatedSortOrder === null}
                    onClick={handleSave}
                >
                    { buttonLabel }
                </button>
            </div>
        </div>

        {
            error !== null && (
                <div className="mt-3 pt-3 border-top text-center text-danger w-100">
                    { error }
                </div>
            )
        }
    </>
}
