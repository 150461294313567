import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { setBooking, setMembers, setMemberCount } from '@/features/EditBooking/editBookingSlice'
import { addAlert } from '@/features/Notifications/notificationSlice'
import { selectBooking, setInputLocked } from '@/features/Calendar/calendarSlice'
import { IS_BOOKING_OPEN } from '@/lib/Storage'
import { debug } from '@/lib/Debug'
import { isEmpty } from 'lodash'

const initialForm = {
    require_email_address: true,
    customer_type_counts: {},
    participants: null,
    date: null,
    time: null,
    members: [],
    memberCount: 0,
}

export const scheduleSlice = createSlice({
    name: 'schedule',
    initialState: {
        open: false,
        loading: false,
        step: '1',
        form: initialForm,
    },
    reducers: {
        setNewSchedule: (state, action) => {
            state.form = initialForm
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setOpen: (state, action) => {
            window.sessionStorage.setItem(IS_BOOKING_OPEN, action.payload)
            state.open = action.payload
        },
        setStep: (state, action) => {
            state.step = action.payload
        },
        setForm: (state, action) => {
            state.form = action.form
        },
        setFormAttribute: (state, action) => {
            state.form[action.payload.name] = action.payload.value
        },
        setDate: (state, action) => {
            state.form.date = action.payload
        },
        setTime: (state, action) => {
            state.form.time = action.payload
        },
        setMember: (state, action) => {
            state.form.members[action.payload.index].memberId = action.payload.value
        }
    }
})

export const {
    setLoading,
    setOpen,
    setStep,
    setForm,
    setFormAttribute,
    setDate,
    setTime,
    setNewSchedule,
    setMember
} = scheduleSlice.actions

export const selectLoading            = state => state.schedule.loading
export const selectOpen               = state => state.schedule.open
export const selectStep               = state => state.schedule.step
export const selectForm               = state => state.schedule.form
export const selectDate               = state => state.schedule.form.date
export const selectParticipants       = state => state.schedule.form.participants
export const selectDuration           = state => state.schedule.form.duration
export const selectTime               = state => state.schedule.form.time
export const selectCustomerTypeCounts = state => state.schedule.form.customer_type_counts
export const selectMembers            = state => state.schedule.form.members
export const selectMemberCount        = state => state.schedule.form.memberCount

export function holdCalendarBooking(packageId) {
    return async (dispatch, getState) => {
        const booking = getState().schedule.form

        if (debug && console) { console.log('>>> (schedule) Holding the booking...', booking, packageId) }

        if (!booking || !packageId) {
            if (debug && console) { console.log('!!! Dependencies not met while trying to hold the booking', booking, packageId) }
            dispatch(addAlert({ type: 'error', text: 'An error occurred!' }))
            return
        }

        dispatch(setLoading(true))
        dispatch(setInputLocked(true))

        axios.post(`/packages/${packageId}/bookings/hold_reservation`, {
            authenticity_token: getState().session.formToken,
            ...booking,
            set_to_reserved: true,
            calculate_price: true
        })
        .then(({ data }) => {
            if (data.success) {
                if (debug && console) { console.log(data.message, data) }
                dispatch(selectBooking(data.booking))
                dispatch(setBooking({ booking: data.booking, resources: data.resources }))
                dispatch(setMemberCount(data.membership_ids.length))
                dispatch(setMembers(data.membership_ids.map(id => {
                    return {
                        customerId: id[0],
                        memberId: id[1],
                        errors: []
                    }
                })))
                dispatch(setStep('3'))
                dispatch(addAlert({ type: 'success', text: data.message }))
                return
            }
            dispatch(addAlert({ type: 'error', text: data.message }))
        })
        .finally(() => {
            dispatch(setLoading(false))
            dispatch(setInputLocked(false))
        })
        .catch((error) => {
            if (debug && console) { console.error(error?.response?.data || error) }
            dispatch(addAlert({ type: 'error', text: (error?.response?.data?.message || 'An error occurred!') }))
        })
    }
}

export function checkMemberIds() {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        const locationId = getState().calendar.locationId
        const form = getState().schedule.form
        const members = form.members
        const date = form.date
        const time = form.time
        const duration = form.duration

        return axios.post(`/locations/${locationId}/members/check_membership_ids`, {
            members: members,
            duration: duration,
            date: date,
            time: time
        }).then(({ data }) => {
            const newMembers = members.map(member => {
                const errors = data.members.find(m => m.memberId === member.memberId.toUpperCase())?.errors

                return { memberId: member.memberId, errors: errors || []}
            })

            dispatch(setFormAttribute({ name: 'members', value: newMembers }))

            const errors = isEmpty(data.members.filter(m => !isEmpty(m.errors)))

            return { isValid: errors }
        }).finally(() => {
            dispatch(setLoading(false))
        })
    }
}

export default scheduleSlice.reducer
