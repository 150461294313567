import React from 'react'
import CustomerBadges from '@/features/Customers/CustomerBadges'
import { selectParentBooking } from '@/features/ParentBooking/parentBookingSlice'
import { useSelector } from 'react-redux'

export default function LeagueParticipantHistory() {

    const parentBooking = useSelector(selectParentBooking)

    const participants = Object.keys(parentBooking.special_event_league_participants).length > 0
                            ? parentBooking.special_event_league_participants
                            : []

    return <>
        <h5 className='mt-2 mb-4'>League Event Participants</h5>

        <table className='table w-100'>
            <colgroup>
                <col width='100' />
                <col width='auto' />
                <col width='auto' />
                <col width='auto' />
            </colgroup>
            <thead>
                <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Email</th>
                    <th className='text-right'>
                        {
                            participants.length > 0 && (
                                <a
                                    className="btn btn-link min-h-auto py-1 px-2 ml-3"
                                    href={`/bookings/${parentBooking.id}/participants`}
                                    title="Click to download a list of all participants as a CSV file"
                                >
                                    <i className="fas fa-download" />
                                </a>
                            )
                        }
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    participants.map((participant) => (
                        <tr key={participant.id}>
                            <td>
                                <span className={`badge badge-${participant.was_checked_in ? 'success' : 'danger' } w-100 text-center`}>
                                    { participant.was_checked_in ? 'Present' : 'Not Present' }
                                </span>
                            </td>
                            <td>{participant.name}</td>
                            <td>
                                {
                                    !!participant.email ? (
                                        <a href={`mailto: ${participant.email}`}>
                                            {participant.email}
                                        </a>
                                    ) : 'N/A'
                                }
                            </td>
                            <td>
                                <CustomerBadges
                                   customer={participant}
                                   simpleWaiverBadge={true}
                                   compact={true}
                                />
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </table>

        <h6 className='w-100 text-center mt-5'>
            <em>This league event has concluded and league reservations have been transferred.<br />
            The above list is a record of those who were there.</em>
        </h6>
    </>
}
