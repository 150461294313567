import React, { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import LedgerCustomerTypes from './LedgerCustomerTypes'
import LedgerDateTime from './LedgerDateTime'
import LedgerDuration from './LedgerDuration'
import LedgerLocation from './LedgerLocation'
import LedgerPartySize from './LedgerPartySize'
import LedgerPrices from './LedgerPrices'
import LedgerDiscounts from './LedgerDiscounts'
import LedgerReservationInformation from './LedgerReservationInformation'
import { selectLocation } from '@/features/Locations/locationSlice'
import { selectResourceType } from '@/features/ResourceType/resourceTypeSlice'
import { selectId, selectPackage, selectMembershipCustomerTypeId } from '@/features/Packages/packageSlice'
import { selectCustomerTypes } from '@/features/CustomerTypes/customerTypeSlice'
import { fetchPrices } from '@/features/Pricing/pricingSlice'
import { evaluateParticipantPricingByGroupType, taxRateAndFeesFor, calculateMemberPrice } from '@/lib/Pricing'

import {
    clearBookingCalendars,
    selectBooking,
    selectStep,
    selectParticipants,
    selectMemberships,
    selectCustomerTypeCounts,
    selectDuration,
    selectDate,
    selectTime,
    selectPromoCode,
    selectMemberCount,
} from '@/features/Bookings/bookingSlice'

export default function BookingModalLedger({ simple=false }) {

    const dispatch           = useDispatch()
    const location           = useSelector(selectLocation)
    const resourceType       = useSelector(selectResourceType)
    const booking            = useSelector(selectBooking)
    const packageId          = useSelector(selectId)
    const pakage             = useSelector(selectPackage)
    const step               = useSelector(selectStep)
    const participants       = useSelector(selectParticipants)
    const memberships        = useSelector(selectMemberships)
    const customerTypeCounts = useSelector(selectCustomerTypeCounts)
    const customerTypes      = useSelector(selectCustomerTypes)
    const duration           = useSelector(selectDuration)
    const date               = useSelector(selectDate)
    const time               = useSelector(selectTime)
    const promoCode          = useSelector(selectPromoCode)
    const memberCustomerId   = useSelector(selectMembershipCustomerTypeId)
    const memberCount        = useSelector(selectMemberCount)

    const handleClearCalendars = () => {
      if (/4|5|6/.test(step)) {
        dispatch(clearBookingCalendars())
      }
    }

    const taxRateAndFees = useMemo(() => (
        taxRateAndFeesFor(location, pakage, booking)
    ), [location, pakage, booking])

    const parsedParticipantCounts = useMemo(() => {
        return evaluateParticipantPricingByGroupType(
            pakage,
            {
              ...booking,
              start_time: moment.tz(booking.time, location.time_zone),
              weekday: moment.tz(booking.date, location.time_zone).format('dddd').toLowerCase()
            },
            customerTypeCounts,
            customerTypes,
            taxRateAndFees.tax_rate,
            taxRateAndFees.rkd_reservation_fee,
            taxRateAndFees.merchant_reservation_fee,
            taxRateAndFees.third_party_reservation_fee,
            location,
            participants,
            resourceType
        )
    }, [pakage, booking, customerTypeCounts, customerTypes, taxRateAndFees, location, participants, resourceType])

    const memberPrice = useMemo(() => (
        calculateMemberPrice(
            pakage,
            {
                ...booking,
                start_time: moment.tz(booking.time, location.time_zone),
                weekday: moment.tz(booking.date, location.time_zone).format('dddd')
            },
            customerTypeCounts,
            location,
            resourceType
        )
    ), [pakage, booking, customerTypeCounts, location, resourceType])

    // calculate the price if we're on the last step
    useEffect(() => {
        if (/5|6/.test(step) && packageId && participants && customerTypeCounts && duration && date && time) {
            dispatch(fetchPrices(packageId, participants, customerTypeCounts, duration, date, time, memberships || null, promoCode || null))
        }
    }, [dispatch, participants, customerTypeCounts, duration, date, time, promoCode, memberships])

    return (
        <div className="xbm-running-ledger">
            <LedgerLocation />
            {
                !simple && <>
                    <LedgerPartySize clearCalendars={handleClearCalendars} />
                    <LedgerDuration clearCalendars={handleClearCalendars} />
                    <LedgerDateTime clearCalendars={handleClearCalendars} />
                    <LedgerCustomerTypes customerTypeTotals={parsedParticipantCounts} memberPrice={memberPrice} />
                    <LedgerDiscounts customerTypeTotals={parsedParticipantCounts} />
                    <LedgerPrices />
                    <LedgerReservationInformation />
                </>
            }
        </div>
    )
}
