import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import TerminalOverlay from './TerminalOverlay'
import { addAlert } from '@/features/Notifications/notificationSlice'
import { SELECTED_TERMINAL, SELECTED_LOCATION } from '@/lib/Storage'

import {
    selectDevices,
    setSelected,
    selectSelected,
    selectConnected,
    selectConnectedDevice,
    fetchDevices,
    connectToDevice,
} from './terminalSlice'

export default function TerminalTransaction({ hardwareKey, transactionCallback }) {
    const dispatch = useDispatch()

    const devices         = useSelector(selectDevices)
    const selectedDevice  = useSelector(selectSelected)
    const connected       = useSelector(selectConnected)
    const connectedDevice = useSelector(selectConnectedDevice)

    const [init, setInit]                           = useState(false)
    const [currentConnection, setCurrentConnection] = useState(false)

    const determineInitiallySelectedTerminal = () => {
        const selectedLocation = window.localStorage.getItem(SELECTED_LOCATION)
        const selectedTerminal = window.localStorage.getItem(SELECTED_TERMINAL)
        if (!selectedLocation || !selectedTerminal) { return null }
        if (selectedTerminal.split('|')[1] == selectedLocation) { return selectedTerminal.split('|')[0] }
        return null
    }

    const handleDeviceSelect = (e) => {
        dispatch(setSelected(e.target.value))
    }

    const handleEstablishTerminalConnection = () => {
        if (!selectedDevice) {
            dispatch(addAlert({ type: 'error', text: 'You must select a device first' }))
            return
        }

        dispatch(connectToDevice(selectedDevice))
    }

    useEffect(() => {
        if (init) { return }
        setInit(true)
        dispatch(fetchDevices(hardwareKey, transactionCallback))
        dispatch(setSelected(determineInitiallySelectedTerminal()))
    }, [init])

    useEffect(() => {
        setCurrentConnection(connected && (connectedDevice === selectedDevice))
    }, [selectedDevice, connected, connectedDevice])

    return (
        <div>
            <div className="row">
                <div className="col-12">
                    <div className="form-group">
                        <label>Terminal</label>

                        <div className="input-group">
                            <select className='custom-select' value={selectedDevice || ""} onChange={handleDeviceSelect}>
                                <option value="" disabled>Select a Terminal</option>
                                {
                                    devices.map((device, index) => (
                                        <option key={index} value={device.device_name}>
                                            {device.device_name}
                                        </option>
                                    ))
                                }
                            </select>

                            <div className="input-group-append">
                                <button
                                    children={ currentConnection ? 'Connected' : 'Connect' }
                                    className={`btn w-100 text-white ${currentConnection ? 'btn-success' : 'btn-primary'}`}
                                    disabled={currentConnection}
                                    onClick={handleEstablishTerminalConnection}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
