import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setOpen } from '@/features/Schedule/scheduleSlice'
import {
    selectParentBookingModalOpen,
    setParentBookingModalOpen,
    selectParentBooking,
    selectParentBookingResources,
} from '@/features/ParentBooking/parentBookingSlice'
import ParentBookingModalTabs from '@/features/ParentBooking/ParentBookingModalTabs'
import ParentBookingGroupDetailsTab from '@/features/ParentBooking/ParentBookingGroupDetailsTab'
import ParentBookingReservationDetailsTab from '@/features/ParentBooking/ParentBookingReservationDetailsTab'
import BookingStatusIcons from '@/features/Calendar/BookingStatusIcons'
import { UI } from '@/lib/Constants'
import { CURRENT_PARENT_BOOKING_ID } from '@/lib/Storage'

export default function ParentBookingModal({ defaultActiveTab='group' }) {

    const dispatch               = useDispatch()
    const open                   = useSelector(selectParentBookingModalOpen)
    const parentBooking          = useSelector(selectParentBooking)
    const parentBookingResources = useSelector(selectParentBookingResources)

    const [activeTab, setActiveTab]                       = useState(defaultActiveTab)
    const [expandedParticipants, setExpandedParticipants] = useState(false)

    const immutableEvent = useMemo(() => (
        /^(cancelled|complete)$/i.test(parentBooking?.status) || !parentBooking?.package?.active
    ), [parentBooking])

    const renderTab = () => {
        switch (activeTab) {
            case 'group':
                return (
                    <ParentBookingGroupDetailsTab
                        immutableEvent={immutableEvent}
                        expandedParticipants={expandedParticipants}
                        onToggleExpandedParticipants={() => setExpandedParticipants(!expandedParticipants)}
                        onClose={handleClose}
                    />
                )

            case 'reservation':
                return (
                    <ParentBookingReservationDetailsTab
                        immutableEvent={immutableEvent}
                        onClose={handleClose}
                    />
                )

            default :
                return null
        }
    }

    const handleClose = () => {
        setActiveTab(defaultActiveTab)
        setExpandedParticipants(false)
        dispatch(setParentBookingModalOpen(false))
    }

    const handleEscape = (e) => {
        if (
            e.key === 'Escape'
            && !document.body.classList.contains(UI.classes.CONFIRMATION_OPEN)
            && !document.body.classList.contains(UI.classes.HELPJUICE_OPEN)
        ) {
            handleClose()
        }
    }

    /**
     * Handle the initial mount and
     * unmount of the component
     */
    useEffect(() => {
        document.addEventListener('keydown', handleEscape, false)

        // cleanup/reset on unmount
        return () => {
            document.removeEventListener('keydown', handleEscape, false)
        }
    }, [])

    useEffect(() => {
        if (open) {
            if (parentBooking) {
                window.sessionStorage.setItem(CURRENT_PARENT_BOOKING_ID, parentBooking?.id)
            }

            // always try to close the schedule drawer upon opening
            // of this modal because if it a drawer is open it will
            // prevent the updating of the booking(s) in response
            // to a booking broadcast and the info within the parent
            // booking modal will become stale
            dispatch(setOpen(false))
        } else {
            window.sessionStorage.removeItem(CURRENT_PARENT_BOOKING_ID)
        }
    }, [open, parentBooking])

    return !open ? '' : (
        <div className="modal parent-booking-modal modal-backdrop modal--xxxxxl" id="parent-booking-modal">
            <div className={`modal-container active-tab--${activeTab}`}>
                <div className="modal-header px-4">
                    <div className="modal-title">
                        Grouped Reservation
                    </div>

                    <ParentBookingModalTabs
                        activeTab={activeTab}
                        handleClick={(tab) => setActiveTab(tab)}
                    />

                    <BookingStatusIcons
                        booking={parentBooking}
                        pkg={parentBooking.package}
                        resources={parentBookingResources}
                        completeConfirmationOptions={
                            parentBooking.package.is_a_league
                                ? { dangerous: true, size: 'lg' }
                                : {}
                        }
                        additionalCompleteWarningText={
                            parentBooking.package.is_a_league
                                ? "<p class='my-5'>Because this special event is a league, reservations will be transferred from this instance to the next once completed.</p>" +
                                  "<p class='text-danger'><u>This is cannot be undone!</u></p>"
                                : ''
                        }
                    />
                </div>

                { renderTab() }
            </div>
        </div>
    )
}
