import React, { useMemo } from 'react'
import { calculateSubtotalAmountCents } from '@/features/AdvancedPointOfSale/lib/Items'
import { numberToCurrency } from '@/lib/Number'

export default function MenuButton({
    data:button=null,
    index=null,
    className='',
    extraLabel='',
    isSelected=false,
    isUnavailable=false,
    toggleable=false,
    content=null,
    onClick=() => {}
}) {

    className = [
        'col',
        'menu-button',
        `menu-button--type-${button?.type || 'unknown'}`,
        'd-flex',
        'align-items-center',
        'justify-content-between',
        button?.is_default ? 'is-default' : '',
        !isUnavailable && isSelected ? 'is-selected' : '',
        isUnavailable ? 'is-unavailable' : '',
        className
    ].join(' ').trim()

    extraLabel = [
        button?.is_default ? 'DEFAULT' : '',
        extraLabel
    ].join(' ').trim()

    const priceCents = useMemo(() => calculateSubtotalAmountCents(button), [button])

    return !button ? null : (
        <div className={className}>
            <button
                type='button'
                style={{ borderLeftColor: button.button_color }}
                disabled={!toggleable && isUnavailable}
                onClick={() => onClick(button, index)}
            >
                <span className='info'>
                    <span className='extra-label' children={extraLabel} />

                    <span className='name font-weight-bold text-truncate mb-1' children={button.name} />

                    {
                        ((button?.subtotal_cents !== undefined) || (button?.amount_cents !== undefined)) && (
                            <span className='price'>
                                +{ numberToCurrency(priceCents / 100) }
                            </span>
                        )
                    }
                </span>

                {
                    content ? content : (
                        <span className='icon'>
                            { !isUnavailable && isSelected && <i className='fa-solid fa-check is-selected' /> }
                            { isUnavailable && <i className='fa-regular fa-xmark is-unavailable' /> }
                        </span>
                    )
                }
            </button>
        </div>
    )
}
