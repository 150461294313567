import React from 'react'
import Select from '@/components/Form/Select'

const tiers = {
    cardPresent: {
        percentage: [
            '1.9',
            '2.0',
            '2.1',
            '2.2',
            '2.3',
            '2.4',
            '2.5',
            '2.6',
            '2.7', // Default
        ],
        feeCents: [
            '15',
            '17',
            '19',
            '21',
            '23',
            '25', // Default
        ],
    },
    cardNotPresent: {
        eCommerce: {
            percentage: [
                '2.2',
                '2.3',
                '2.4',
                '2.5',
                '2.7', // Default
                '2.8',
                '2.9',
            ],
            feeCents: [
                '23',
                '24',
                '25', // Default
                '26',
                '27',
                '28',
                '29',
                '30 ',
            ],
        },
        motoAndContAuth: {
            percentage: [
                '2.7', // Default
                '2.8',
                '2.9',
                '3.0',
                '3.1',
                '3.2',
                '3.3',
                '3.4',
                '3.5',
            ],
            feeCents: [
                '23',
                '24',
                '25', // Default
                '26',
                '27',
                '28',
                '29',
                '30',
            ],
        }
    }
}

export default function PaymentProcessingFees({ form, errors, handleManualUpdate }) {

    const percentSymbol = (
        <div className='input-group-append'>
            <div className='input-group-text'>%</div>
        </div>
    )

    const centsSymbol = (
        <div className='input-group-append'>
            <div className='input-group-text'>¢</div>
        </div>
    )

    return <>
        <h4 className='mb-0'>Payment Processing Fees</h4>

        <div className='table-responsive shadow-none rounded-0 mb-4'>
            <table className='table-sm table-hover w-100'>
                <colgroup>
                    <col width='auto' />
                    <col width='240px' />
                    <col width='240px' />
                    <col width='240px' />
                </colgroup>
                <thead>
                    <tr>
                        <th className='py-2 px-1' />
                        <th className='py-2 px-1' valign='bottom'>Card Present</th>
                        <th className='py-2 px-1' valign='bottom'>Card Not Present<br />(Ecommerce)</th>
                        <th className='py-2 px-1' valign='bottom'>Card Not Present<br />(MOTO & ContAuth)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className='font-weight-bold pt-3 px-1' valign='top'>
                            Processing
                            &nbsp;<br className='d-block d-lg-none' />
                            Rate
                        </td>
                        <td className='pt-2' valign='top'>
                            <Select
                                children={percentSymbol}
                                options={tiers.cardPresent.percentage}
                                value={
                                    !!form.adyen_processing_fees?.card_present?.percentage
                                        ? Number(form.adyen_processing_fees.card_present.percentage).toFixed(1)
                                        : ''
                                }
                                validation={{ 'adyen_processing_fees_card_present_percentage': { required: true } }}
                                errors={errors}
                                withWrapper={false}
                                append
                                req
                                hideLabel
                                includeBlank
                                handleChange={(e) => handleManualUpdate(
                                    'adyen_processing_fees',
                                    {
                                        ...form.adyen_processing_fees,
                                        card_present: {
                                            ...(form.adyen_processing_fees?.card_present || {}),
                                            percentage: e.target.value
                                        }
                                    }
                                )}
                            />
                        </td>
                        <td className='pt-2' valign='top'>
                            <Select
                                children={percentSymbol}
                                options={tiers.cardNotPresent.eCommerce.percentage}
                                value={
                                    !!form.adyen_processing_fees?.card_not_present?.ecommerce?.percentage
                                        ? Number(form.adyen_processing_fees.card_not_present.ecommerce.percentage).toFixed(1)
                                        : ''
                                }
                                validation={{ 'adyen_processing_fees_card_not_present_ecommerce_percentage': { required: true } }}
                                errors={errors}
                                withWrapper={false}
                                append
                                req
                                hideLabel
                                includeBlank
                                handleChange={(e) => handleManualUpdate(
                                    'adyen_processing_fees',
                                    {
                                        ...form.adyen_processing_fees,
                                        card_not_present: {
                                            ...(form.adyen_processing_fees?.card_not_present || {}),
                                            ecommerce: {
                                                ...(form.adyen_processing_fees?.card_not_present?.ecommerce || {}),
                                                percentage: e.target.value
                                            }
                                        }
                                    }
                                )}
                            />
                        </td>
                        <td className='pt-2 pr-1' valign='top'>
                            <Select
                                children={percentSymbol}
                                options={tiers.cardNotPresent.motoAndContAuth.percentage}
                                value={
                                    !! form.adyen_processing_fees?.card_not_present?.moto_and_cont_auth?.percentage
                                        ? Number(form.adyen_processing_fees.card_not_present.moto_and_cont_auth.percentage).toFixed(1)
                                        : ''
                                }
                                validation={{ 'adyen_processing_fees_card_not_present_moto_and_cont_auth_percentage': { required: true } }}
                                errors={errors}
                                withWrapper={false}
                                append
                                req
                                hideLabel
                                includeBlank
                                handleChange={(e) => handleManualUpdate(
                                    'adyen_processing_fees',
                                    {
                                        ...form.adyen_processing_fees,
                                        card_not_present: {
                                            ...(form.adyen_processing_fees?.card_not_present || {}),
                                            moto_and_cont_auth: {
                                                ...(form.adyen_processing_fees?.card_not_present?.moto_and_cont_auth || {}),
                                                percentage: e.target.value
                                            }
                                        }
                                    }
                                )}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className='border-top-0 font-weight-bold px-1' valign='top'>
                            <span className='d-block pt-2'>
                                Fee Per
                                &nbsp;<br className='d-block d-lg-none' />
                                Transaction
                            </span>
                        </td>
                        <td className='border-top-0' valign='top'>
                            <Select
                                children={centsSymbol}
                                options={tiers.cardPresent.feeCents}
                                value={
                                    !!form.adyen_processing_fees?.card_present?.fee_cents
                                        ? Number(form.adyen_processing_fees.card_present.fee_cents).toFixed(0)
                                        : ''
                                }
                                validation={{ 'adyen_processing_fees_card_present_fee_cents': { required: true } }}
                                errors={errors}
                                withWrapper={false}
                                req
                                append
                                hideLabel
                                includeBlank
                                handleChange={(e) => handleManualUpdate(
                                    'adyen_processing_fees',
                                    {
                                        ...form.adyen_processing_fees,
                                        card_present: {
                                            ...(form.adyen_processing_fees?.card_present || {}),
                                            fee_cents: e.target.value
                                        }
                                    }
                                )}
                            />
                        </td>
                        <td className='border-top-0' valign='top'>
                            <Select
                                children={centsSymbol}
                                options={tiers.cardNotPresent.eCommerce.feeCents}
                                value={
                                    !!form.adyen_processing_fees?.card_not_present?.ecommerce?.fee_cents
                                        ? Number(form.adyen_processing_fees.card_not_present.ecommerce.fee_cents).toFixed(0)
                                        : ''
                                }
                                validation={{ 'adyen_processing_fees_card_not_present_ecommerce_fee_cents': { required: true } }}
                                errors={errors}
                                withWrapper={false}
                                req
                                append
                                hideLabel
                                includeBlank
                                handleChange={(e) => handleManualUpdate(
                                    'adyen_processing_fees',
                                    {
                                        ...form.adyen_processing_fees,
                                        card_not_present: {
                                            ...(form.adyen_processing_fees?.card_not_present || {}),
                                            ecommerce: {
                                                ...(form.adyen_processing_fees?.card_not_present?.ecommerce || {}),
                                                fee_cents: e.target.value
                                            }
                                        }
                                    }
                                )}
                            />
                        </td>
                        <td className='border-top-0 pr-1' valign='top'>
                            <Select
                                children={centsSymbol}
                                options={tiers.cardNotPresent.motoAndContAuth.feeCents}
                                value={
                                    !!form.adyen_processing_fees?.card_not_present?.moto_and_cont_auth?.fee_cents
                                        ? Number(form.adyen_processing_fees.card_not_present.moto_and_cont_auth.fee_cents).toFixed(0)
                                        : ''
                                }
                                validation={{ 'adyen_processing_fees_card_not_present_moto_and_cont_auth_fee_cents': { required: true } }}
                                errors={errors}
                                withWrapper={false}
                                req
                                append
                                hideLabel
                                includeBlank
                                handleChange={(e) => handleManualUpdate(
                                    'adyen_processing_fees',
                                    {
                                        ...form.adyen_processing_fees,
                                        card_not_present: {
                                            ...(form.adyen_processing_fees?.card_not_present || {}),
                                            moto_and_cont_auth: {
                                                ...(form.adyen_processing_fees?.card_not_present?.moto_and_cont_auth || {}),
                                                fee_cents: e.target.value
                                            }
                                        }
                                    }
                                )}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </>
}
