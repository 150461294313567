import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectPackage, selectGroups } from '../../../Packages/packageSlice'
import { selectParticipants, setParticipants } from '../../bookingSlice'
import { participantMaxCount } from '@/lib/Booking'

export default function PartySizeInput({ handlePartySizeChange=() => {} }) {

    const dispatch = useDispatch()

    const pkg          = useSelector(selectPackage)
    const groups       = useSelector(selectGroups)
    const participants = useSelector(selectParticipants)

    const handleChange = (e) => {
        const amount = e.target.value
        dispatch(setParticipants(amount))
        handlePartySizeChange(amount)
    }

    /**
     * Re-center/scroll-to the selected option in
     * the scroll wheel if one has already been
     * selected and the user has clicked "back"
     * to change their participant count selection
     */
    useEffect(() => {
        if (!participants) { return }

        const option = document.getElementById(`option-${participants}`)

        if (option) {
            option.scrollIntoView({ behavior: 'auto', block: 'center' })
        }
    }, [])

    const filteredGroups = groups => groups.filter((group) => !group.internal)

    const numOfOptions = groups => {
        let counter = 0

        groups.forEach(group => {
            if (group.price_type === 'per_person') {
                counter += Number.parseInt(group.max, 10)
            } else {
                counter += 1
            }
        })

        return counter
    }

    return  (
        <div className="xbm-party-select-scroller">
            <div className={`xbm-party-select-scroller-inner d-flex align-items-center flex-column ${numOfOptions(groups) > 4 ? '' : 'justify-content-center'}`}>
                {
                    filteredGroups(groups).map((group, index) => {
                        const min = Number.parseInt(group.min, 10)
                        const max = participantMaxCount(pkg, group)

                        // per person pricing (ex: "1" participants)
                        if (group.price_type === 'per_person') {
                            let options = []

                            for (let i = min; i < max + 1; ++i) {
                                options.push(
                                    <label id={`option-${i}`} className="radio-as-card w-100" key={i}>
                                        <input
                                            type="radio"
                                            name="party_size"
                                            value={i}
                                            checked={participants == i}
                                            onChange={handleChange}
                                        />

                                        <div className="radio-card">
                                            {i}
                                        </div>
                                    </label>
                                )
                            }

                            return options
                        } else {
                            // group pricing for ranges (ex: "15-30" participants)
                            const labelText = min === max ? min : `${min}-${max}`
                            return max < min ? null : (
                                <label id={`option-${min}-${max}`} className="radio-as-card w-100" key={index}>
                                    <input
                                        type="radio"
                                        name="party_size"
                                        value={`${min}-${max}`}
                                        checked={participants == `${min}-${max}`}
                                        onChange={handleChange}
                                    />

                                    <div className="radio-card">
                                        {labelText}
                                    </div>
                                </label>
                            )
                        }
                    })
                }
            </div>
        </div>
    )
}
