import React from "react"
import { useSelector } from "react-redux"
import { selectStep, selectMembership, selectSelectedType } from "./memberSlice"
import MemberSteps from '@/lib/Member'

export default function MemberModalHeader() {

    const step           = useSelector(selectStep)
    const membership     = useSelector(selectMembership)
    const membershipType = useSelector(selectSelectedType)

    const name = () => {
        switch (step) {
            case MemberSteps.SELECT_RECIPIENT:
            case MemberSteps.MEMBER_INFORMATION:
            case MemberSteps.MEMBERSHIP_TERM:
            case MemberSteps.PAYMENT_INFORMATION:
                return membershipType?.modal_header || 'Become a Member'
            case MemberSteps.GIFTED_BUYER_INFORMATION:
            case MemberSteps.GIFTED_MEMBER_INFORMATION:
                return "Gift a Membership"
            case MemberSteps.CONFIRMATION:
                return "Membership Confirmation"
            default:
                return "Unknown step..."
        }
    }

    return (
        <div className="xbm-header">
            <h1 className="text-center text-md-left">
                <span className="d-block d-md-inline mb-3 mb-md-0">
                    {name()}

                    {membership && step !== MemberSteps.CONFIRMATION && (
                        <a className="btn btn-link-primary float-right pr-0" style={{ fontSize: '16px' }} href="/member/memberships">
                            <i className="fal fa-chevron-left mr-2"></i> Return to Membership Dashboard
                        </a>
                    )}
                </span>
            </h1>
        </div>
    )
}
