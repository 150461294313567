import React from "react"
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from 'react-hook-form'
import { selectCheckAmount, selectCheckNumber, setCheckAmount, setCheckNumber, selectAmountToPay } from "./bookingPaymentSlice"
import { errorMessageFor } from '@/components/Form/ErrorsHelper'

export default function PaymentCheck() {

    const dispatch = useDispatch()

    const { register, setValue, formState: { errors } } = useFormContext()

    const checkNumber = useSelector(selectCheckNumber)
    const checkAmount = useSelector(selectCheckAmount)
    const amountToPay = useSelector(selectAmountToPay)

    const checkAmountField = register("check_amount_received", {
        validate: v => parseFloat(v) === parseFloat(amountToPay) || "should be the same as the payment amount"
    })

    const handleClear = () => {
        setValue('check_amount_received', null)
        dispatch(setCheckAmount(null))
    }

    return (
        <div className="modal-body-main-section">
            <div className="pay-by-check">
                <div className="form-row">
                    <div className="col-6">
                        <div className="form-group">
                            <label>Check Number</label>
                            <input
                                value={checkNumber || ''}
                                type="text"
                                className="form-control"
                                placeholder="Check Number"
                                onChange={e => dispatch(setCheckNumber(e.target.value))}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <label>Amount Received</label>
                            <div className="input-group mb-2">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">$</div>
                                </div>
                                <input
                                    {...checkAmountField}
                                    value={checkAmount || ''}
                                    type="number"
                                    min="0"
                                    step="0.1"
                                    className={`form-control ${errors?.check_amount_received ? 'is-invalid' : ''}`}
                                    placeholder="0.00"
                                    onChange={e => {
                                        checkAmountField.onChange(e)
                                        dispatch(setCheckAmount(e.target.value))}
                                    }
                                />

                                {
                                    !!checkAmount && checkAmount > 0 && (
                                        <div className='input-group-append'>
                                            <button
                                                children={<i className="far fa-times" />}
                                                type='button'
                                                className='btn btn-secondary text-white'
                                                onClick={handleClear}
                                            />
                                        </div>
                                    )
                                }

                                { errorMessageFor('check_amount_received', errors) }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
