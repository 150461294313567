import React, {useEffect, useState} from 'react'
import Checkbox from '../Form/Checkbox'
import FormRow from '../Form/FormRow'
import Input from '../Form/Input'
import Image from "../Form/Image";
import axios from "axios";
import Select from "../Form/Select";
import Help from '@/features/Help/Help'

export default function LocationBrandingForm({
  form,
  handleUpdate,
  handleCheckboxUpdate,
  handleManualUpdate,
  handleFormUpdate,
  directUploadUrl,
  imagesUrl,
  fontsUrl,
  fonts,
  errors,
  loaded,
  newLocation,
  handleTabLoaded
}) {
  const [previewImages, setPreviewImages] = useState({
    currentLogo:                 null,
    currentDarkLogo:             null,
    currentAppLogo:              null,
    currentTargetImage:          null,
    currentModalBackgroundImage: null
  })

  const [previewFonts, setPreviewFonts] = useState({
    currentHeaderFontName: null,
    currentFontName:       null,
  })

  const [imagesFetched, setImagesFetched] = useState(false)
  const [imagesLoading, setImagesLoading] = useState(false)
  const [fontsFetched, setFontsFetched]   = useState(false)
  const [fontsLoading, setFontsLoading]   = useState(false)

  const formattedOptions = (fonts) => {
    return Object.keys(fonts).map((font) => {
      return { value: font, display: font }
    })
  }

  useEffect(() => {
    if (!loaded && newLocation) {
      handleFormUpdate({
        ...form,
        primary_hex_color: '#000000',
        secondary_hex_color: '#000000',
        text_hex_color: '#000000'
      })
    }
  }, [])

  useEffect(() => {
    if (!loaded) { handleTabLoaded() }
  }, [])

  useEffect(() => {
    // we don't do anything if we've already fetched the images or if we have no url to fetch from (new record)
    if (imagesFetched || !imagesUrl) {
      return
    }

    // turn on spinners
    setImagesLoading(true)

    axios.get(imagesUrl).then(({ data }) => {
      setPreviewImages({
        currentLogo:                 data.override_logo,
        currentAppLogo:              data.override_app_logo,
        currentDarkLogo:             data.override_dark_logo,
        currentTargetImage:          data.override_target_image,
        currentModalBackgroundImage: data.modal_background_image
      })
    }).catch((error) => {
      console.warn('Unable to load images...', error)
    }).finally(() => {
      setImagesLoading(false)
      setImagesFetched(true)
    })
  }, [imagesFetched])

  useEffect(() => {
    // we don't do anything if we've already fetched the fonts or if we have no url to fetch from (new record)
    if (fontsFetched || !fontsUrl) {
      return
    }

    // turn on spinners
    setFontsLoading(true)

    axios.get(fontsUrl).then(({ data }) => {
      setPreviewFonts({
        currentHeaderFontName: data.modal_header_font,
        currentFontName:       data.modal_font,
      })
    }).catch((error) => {
      console.warn('Unable to load fonts...')
    }).finally(() => {
      setFontsLoading(false)
      setFontsFetched(true)
    })
  }, [fontsFetched])

  return (
    <div>
      <h3 className="mt-0">Location Modal Branding<Help articleId='154000158927-branding-tab-location-modal-branding' /></h3>

      <FormRow>
        <Input
          name='primary_hex_color'
          type='color'
          handleChange={handleUpdate}
          req={false}
          value={form.primary_hex_color}
          cols='4'
          errors={errors} />

        <Input
          name='secondary_hex_color'
          type='color'
          handleChange={handleUpdate}
          req={false}
          value={form.secondary_hex_color}
          cols='4'
          errors={errors} />

        <Input
          name='text_hex_color'
          type='color'
          handleChange={handleUpdate}
          req={false}
          value={form.text_hex_color}
          cols='4'
          errors={errors} />
      </FormRow>

      <Checkbox
        handleChange={handleCheckboxUpdate}
        name='use_dark_text_image'
        label="Dark logo on light background?"
        value={form.use_dark_text_image} />

      <Image
        loading={imagesLoading}
        name='modal_background_image'
        handleChange={handleManualUpdate}
        previewUrl={previewImages.currentModalBackgroundImage}
        directUploadUrl={directUploadUrl}
        tooltip='Minimum 800 x 600 pixels, recommended 1200 x 700 pixels' />

      <FormRow>
        <Select
          name='font'
          label='Modal Font'
          handleChange={handleUpdate}
          req={true}
          options={formattedOptions(fonts)}
          value={form.font}
          cols='6'
          errors={errors}
        />
      </FormRow>

      <h3 className="mt-5">
          Override Company Branding<Help articleId='154000158928-branding-tab-override-company-branding' />
      </h3>

      <Image
          loading={imagesLoading}
          name='override_logo'
          label='Light Mode Logo'
          handleChange={handleManualUpdate}
          previewUrl={previewImages.currentLogo}
          directUploadUrl={directUploadUrl}
          tooltip='Minimum 512 x 512 pixels, recommended 800 x 800 pixels'/>

      <Image
          loading={imagesLoading}
          name='override_dark_logo'
          label='Dark Mode Logo'
          handleChange={handleManualUpdate}
          previewUrl={previewImages.currentDarkLogo}
          directUploadUrl={directUploadUrl}
          tooltip='Minimum 512 x 512 pixels, recommended 800 x 800 pixels. For use when using dark mode'/>

      <Image
          loading={imagesLoading}
          name='override_app_logo'
          label='App Logo'
          handleChange={handleManualUpdate}
          previewUrl={previewImages.currentAppLogo}
          directUploadUrl={directUploadUrl} />

      <Image
          loading={imagesLoading}
          name='override_target_image'
          label='Target Image'
          handleChange={handleManualUpdate}
          previewUrl={previewImages.currentTargetImage}
          directUploadUrl={directUploadUrl} />

      <a href="/edit_target.png" download="target.png">Download and modify this image, then upload</a>
    </div>
  )
}
