import React, { useEffect, useRef, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCustomerTypes } from '@/features/CustomerTypes/customerTypeSlice'
import { setPackage } from '@/features/Packages/packageSlice'
import { selectForm, setFormAttribute } from '@/features/Schedule/scheduleSlice'

export default function BookingPackageOption({ pkg, setTriggerGroupLoad, tempPackageId }) {
    const pkgRef = useRef()
    const dispatch = useDispatch()
    const form     = useSelector(selectForm)

    const { register } = useFormContext()
    const field        = register('package_id', { required: true })

    const specialEvent = useMemo(() => {
        let classes = []
        let tooltip = []

        if (pkg.is_special_event) {
            tooltip.push('Special Event')
            classes.push('special-event')
        }

        if (pkg.is_a_league) {
            tooltip.push('with League Scheduling')
            classes.push('league-scheduling')
        }

        return { classes: classes.join(' '), tooltip: tooltip.join(' ') }
    }, [pkg])

    /**
     * if there's a pkg id previously saved to the store
     * from the group reservations modal and that id matches
     * this pkg option then we want to simulate a click so that
     * the option is preselected when the schedule drawer opens
     */
    useEffect(() => {
        if (pkg.id === tempPackageId) {
            pkgRef.current.click()
        }
    }, [field])

    const handleSelect = (e) => {
        field.onChange(e)

        dispatch(setFormAttribute({ name: 'package_id', value: pkg.id }))
        dispatch(setPackage(pkg))
        setTriggerGroupLoad(true)
        dispatch(fetchCustomerTypes(`/packages/${pkg.id}/customer_types`))
    }

    return (
        <div className="col-12 col-md-6">
            <label className="radio-as-card" ref={pkgRef}>

                <input
                    ref={field.ref}
                    name={field.name}
                    type="radio"
                    value={pkg.id}
                    checked={form.package_id === pkg.id}
                    onChange={handleSelect}
                />

                <div className={`radio-card ${specialEvent.classes}`} title={specialEvent.tooltip}>
                    {pkg.name}
                </div>

            </label>
        </div>
    )
}
