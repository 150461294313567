import { createSlice } from "@reduxjs/toolkit"
import axios from 'axios'
import { addAlert } from '@/features/Notifications/notificationSlice'

export const bookingHistorySlice = createSlice({
    name: 'bookingHistory',
    initialState: {
        loading: false,
        openModal: false,
        changeLog: [],
        emails: [],
        addonTime: [],
        refunds: [],
        payments: [],
        openResendReceiptModal: false,
        resendReceiptPayment: null,
        resendReceiptRefund: null,
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setBookingHistory: (state, action) => {
            state.changeLog = action.payload.change_log
            // state.emails    = action.payload.emails
            // state.addonTime = action.payload.addonTime
            // state.refunds   = action.payload.refunds
        },
        openBookingHistoryModal: (state, action) => {
            state.openModal = true
        },
        closeBookingHistoryModal: (state) => {
            state.openModal = false
            state.changeLog = []
            state.emails    = []
            state.addonTime = []
            state.refunds   = []
            state.payments  = []
        },
        setPayments: (state, action) => {
            state.payments = action.payload
        },
        setRefunds: (state, action) => {
            state.refunds = action.payload
        },
        setOpenResendReceiptModal: (state, action) => {
            state.openResendReceiptModal = action.payload
        },
        setResendReceiptPayment: (state, action) => {
            state.resendReceiptPayment = action.payload
        },
        setResendReceiptRefund: (state, action) => {
            state.resendReceiptRefund = action.payload
        }
    }
})

export const {
    setLoading,
    setBookingHistory,
    openBookingHistoryModal,
    closeBookingHistoryModal,
    setPayments,
    setRefunds,
    setOpenResendReceiptModal,
    setResendReceiptPayment,
    setResendReceiptRefund,
} = bookingHistorySlice.actions

export const selectLoading                = state => state.bookingHistory.loading
export const selectOpenModal              = state => state.bookingHistory.openModal
export const selectChangeLog              = state => state.bookingHistory.changeLog
export const selectEmails                 = state => state.bookingHistory.emails
export const selectAddonTime              = state => state.bookingHistory.addonTime
export const selectRefunds                = state => state.bookingHistory.refunds
export const selectPayments               = state => state.bookingHistory.payments
export const selectOpenResendReceiptModal = state => state.bookingHistory.openResendReceiptModal
export const selectResendReceiptPayment   = state => state.bookingHistory.resendReceiptPayment
export const selectResendReceiptRefund    = state => state.bookingHistory.resendReceiptRefund

export function fetchBookingHistory(id) {
    return async (dispatch) => {
        dispatch(setLoading(true))

        axios.get(`/bookings/${id}/history`).then(({ data }) => {
            dispatch(setBookingHistory(data))
        }).catch(e => {
            console.warn(e)
        }).finally(() => {
            dispatch(setLoading(false))
        })
    }
}

export function fetchBookingPayments(bookingId=null) {
    return async (dispatch) => {
        if (!bookingId) { return }

        dispatch(setLoading(true))

        axios.get(`/bookings/${bookingId}/payments`).then(({ data }) => {
            return dispatch(setPayments(data))
        }).catch(e => {
            console.warn(e)
        })
    }
}

export function fetchBookingRefunds() {
    return async (dispatch, getState) => {
        const bookingId = getState().editBooking.booking.id

        axios.get(`/bookings/${bookingId}/refunds`).then(({ data }) => {
            dispatch(setRefunds(data))
        }).catch(e => {
            console.warn(e)
        }).finally(() => {
            dispatch(setLoading(false))
        })
    }
}

export function createRefund(paymentId, amount, email, notes, refundMethod) {
    return async (dispatch, getState) => {
        const token      = getState().session.formToken
        const locationId = getState().location.location.id

        return await axios.post(`/payments/${paymentId}/refunds.json`, {
            authenticity_token: token,
            location_id:        locationId,
            refund: {
                amount_cents:  amount,
                email:         email,
                notes:         notes,
                payment_id:    paymentId,
                refund_method: refundMethod,
            }
        })
        .catch((e) => {
            dispatch(addAlert({ type: 'error', text: 'Refund Failed!' }))
            if (console) { console.warn('Refund error: ', e) }
        })
    }
}

export function revertGiftCardRefund(refundId, bookingId) {
    return async (dispatch, getState) => {
        const token = getState().session.formToken

        if (!refundId || !bookingId || !token) { return }

        await axios.post(`/refunds/${refundId}/revert`, {
            authenticity_token: token
        }).then(({ data }) => {
            if (data.success) {
                dispatch(addAlert({ type: 'success', text: 'Undo Refund Complete!' }))
                dispatch(fetchBookingPayments(bookingId))
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        }).catch(e => {
            console.warn('Could not revert the gift card refund!', e)
        })
    }
}

export function sendPaymentReceipt(paymentId) {
    return async (dispatch, getState) => {
        const token = getState().session.formToken

        axios.post(`/payments/${paymentId}/send_receipt.json`, {
            authenticity_token: token
        })
        .then(({ data }) => {
            if (!data.success) {
                dispatch(addAlert({ type: 'error', text: data.message }))
                console.warn(data.message)
                return
            }

            dispatch(addAlert({ type: 'success', text: 'Receipt Sent!' }))
        }).catch((e) => {
            console.warn('Send receipt error: ', e)
        })
    }
}

export function sendRefundReceipt(refundId) {
    return async (dispatch, getState) => {
        const token = getState().session.formToken

        axios.post(`/refunds/${refundId}/send_receipt`, {
            authenticity_token: token
        })
        .then(({ data }) => {
            if (!data.success) {
                dispatch(addAlert({ type: 'error', text: data.message }))
                console.warn(data.message)
                return
            }

            dispatch(addAlert({ type: 'success', text: 'Receipt Sent!' }))
        }).catch((e) => {
            console.warn('Send receipt error: ', e)
        })
    }
}

export function sendConfirmation() {
    return async (dispatch, getState) => {
        const booking = getState().editBooking.booking
        const token = getState().session.formToken

        axios.post(`/bookings/${booking.id}/resend_confirmation_email`, {
            authenticity_token: token
        }).then(({ data }) => {
            if (data.success) {
                dispatch(addAlert({ type: 'success', text: 'Confirmation Sent!' }))
            } else {
                dispatch(addAlert({ type: 'error', text: data.error }))
            }
        }).catch(e => {
            console.warn('Send confirmation error: ', e)
        })
    }
}

export function notifyChange(versionId) {
    return async (dispatch, getState) => {
        const booking = getState().editBooking.booking

        axios.get(`/bookings/${booking.id}/notify_changed`, { params: { version_id: versionId } })
        .then(({ data }) => {
            if (!data.success) {
                dispatch(addAlert({ type: 'error', text: data.message }))
                console.warn(data.message)
                return
            }
            dispatch(addAlert({ type: 'success', text: 'Notification Sent!' }))
        }).catch(e => {
            console.warn('Notify change error: ', e)
        })
    }
}

export function notifyCancellation() {
    return async (dispatch, getState) => {
        const booking = getState().editBooking.booking

        axios.get(`/bookings/${booking.id}/notify_cancellation`).then(({ data }) => {
            if (!data.success) {
                dispatch(addAlert({ type: 'error', text: data.message }))
                console.warn(data.message)
                return
            }

            dispatch(addAlert({ type: 'success', text: 'Notification Sent!' }))
        }).catch((e) => {
            console.warn('Notify cancellation error: ', e)
        })
    }
}

export function notify24Hour() {
    return async (dispatch, getState) => {
        const booking = getState().editBooking.booking

        axios.get(`/bookings/${booking.id}/notify_24_hour`).then(({ data }) => {
            if (!data.success) {
                dispatch(addAlert({ type: 'error', text: data.message }))
                console.warn(data.message)
                return
            }

            dispatch(addAlert({ type: 'success', text: 'Notification Sent!' }))
        }).catch((e) => {
            console.warn('Notify cancellation error: ', e)
        })
    }
}

export default bookingHistorySlice.reducer
