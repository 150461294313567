import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectModals, closeModal, popModalDefaults, configureModal } from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'
import Icon from '@/components/FontAwesomeIcon'

/**
  * Usage Examples:
  * -----------------------------------------------
  *
  * Basic / Defaults
  *   dispatch(openModal('popModal'))
  *
  * Configured options prior to opening
  *   dispatch(configureModal({
  *      modal: 'popModal',
  *      config: { text: 'Oh HAI!', icon: 'fa-people-pulling bg-green' }
  *   }))
  *   dispatch(openModal('popModal'))
  */
export default function PopModal({ id=null }) {

    const dispatch     = useDispatch()
    const { popModal:modal } = useSelector(selectModals)

    useEffect(() => {
        if (!modal.isOpen) { return }

        window.setTimeout(() => {
            dispatch(closeModal('popModal'))
            dispatch(configureModal({ modal: 'popModal', config: popModalDefaults }))
        }, modal.delay)
    }, [modal.isOpen])

    return modal.isOpen && (
        <div
            className={`modal ${modal.isOpen ? 'd-block' : 'd-none'} ${modal?.className || ''}`.trim()}
            style={{ zIndex: 9999999999999 }}
            tabIndex='-1'
        >
            <div className={`modal-dialog modal-dialog-centered ${modal?.size || ''}`.trim()}>
                <div className='modal-content mx-auto pop-modal' id={id}>
                    <div className='modal-body p-5 d-flex flex-column align-items-center'>
                        {
                            !!modal?.icon && (
                                <Icon
                                    className='icon'
                                    icon={modal.icon}
                                    packs={['fas']}
                                />
                            )
                        }

                        <h2
                            className='pt-4 mb-0 w-100 text-center'
                            dangerouslySetInnerHTML={{ __html: modal?.text || '' }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
