import React from 'react'
import DurationPricing from './DurationPricing'

export default function CustomerTypePricing({ customerTypePrices=null, customerTypes=null, handleUpdate }) {

    const customerTypeName = () => {
        try {
            if (!Array.isArray(customerTypes) || !Array.isArray(customerTypePrices)) {
                throw('Customer types or customer type prices unavailable!')
            }
            return customerTypes.filter(type => {
                return type.id === customerTypePrices[0].customerType
            })[0].name
        } catch (e) {
            console.warn('Could not parse customer type name: ', e)
        }
    }

    return (
        <div className="price-group-customer-type">
            <h4>{customerTypeName()}</h4>
            {
                Array.isArray(customerTypePrices) && customerTypePrices.map((duration, index) => (
                    <DurationPricing
                        key={`ctp-${index}`}
                        duration={duration}
                        handleUpdate={handleUpdate}
                    />
                ))
            }
        </div>
    )
}
