import { createSlice } from "@reduxjs/toolkit";
import axios from 'axios'
import { addAlert } from '@/features/Notifications/notificationSlice'
import { SecureHash } from '@/lib/Crypto'
import { accessToken } from "@/lib/Csrf"
import { debug } from '@/lib/Debug'

import {
    SELECTED_TERMINAL_ADYEN,
    SELECTED_LOCATION,
    ADYEN_TERMINAL_SALE_ID,
    ADYEN_TERMINAL_SERVICE_ID
} from '@/lib/Storage'

export const adyenSlice = createSlice({
    name: 'adyen',
    initialState: {
        sessionId: null,
        sessionData: null,
        terminals: [],
        terminalResponse: null,
        selectedTerminal: null,
        adyenErrors: [],
        adyenPayment: null,
        adyenClient: null,
        processing: false,
        creditToken: null,
    },
    reducers: {
        setSessionId: (state, action) => {
            state.sessionId = action.payload
        },
        setSessionData: (state, action) => {
            state.sessionData = action.payload
        },
        setAdyenErrors: (state, action) => {
            state.adyenErrors = action.payload
        },
        setTerminals: (state, action) => {
            state.terminals = action.payload
        },
        setSelectedTerminal: (state, action) => {
            state.selectedTerminal = action.payload

            const location_id = window.localStorage.getItem(SELECTED_LOCATION)

            if (action.payload !== null && location_id !== null) {
                // when a terminal is selected we also want to set the terminal id/device id combination
                // stored in local storage this should also auto-select the slice when present
                window.localStorage.setItem(SELECTED_TERMINAL_ADYEN, `${action.payload}|${location_id}`)
            } else {
                window.localStorage.removeItem(SELECTED_TERMINAL_ADYEN)
            }
        },
        setAdyenPayment: (state, action) => {
            state.adyenPayment = action.payload
        },
        setAdyenClient: (state, action) => {
            state.adyenClient = action.payload
        },
        setCreditToken: (state, action) => {
            state.creditToken = action.payload
        },
        clearAdyenState: (state) => {
            // whenever the user closes a drawer or submits a payment
            // we should cancel the current state of adyen payment.
            state.sessionId        = null
            state.sessionData      = null
            state.terminals        = []
            state.terminalResponse = null
            state.selectedTerminal = null
            state.adyenErrors      = []
            state.adyenPayment     = null
            state.adyenClient      = null
            state.processing       = false
            state.creditToken      = null
        },
        setTerminalResponse: (state, action) => {
            state.terminalResponse = action.payload
        },
        setProcessing: (state, action) => {
            state.processing = action.payload
        }
    }
})

export const {
    setSessionId,
    setSessionData,
    setAdyenErrors,
    setTerminals,
    setSelectedTerminal,
    setAdyenPayment,
    setAdyenClient,
    setCreditToken,
    clearAdyenState,
    setTerminalResponse,
    setProcessing,
} = adyenSlice.actions

export const selectSessionId        = state => state.adyen.sessionId
export const selectSessionData      = state => state.adyen.sessionData
export const selectAdyenErrors      = state => state.adyen.adyenErrors
export const selectTerminals        = state => state.adyen.terminals
export const selectSelectedTerminal = state => state.adyen.selectedTerminal
export const selectAdyenPayment     = state => state.adyen.adyenPayment
export const selectAdyenClient      = state => state.adyen.adyenClient
export const selectTerminalResponse = state => state.adyen.terminalResponse
export const selectProcessing       = state => state.adyen.processing
export const selectCreditToken      = state => state.adyen.creditToken

export function createSession(bookingId, locationId) {
    return async (dispatch, getState) => {
        locationId = locationId || getState().location.location?.id

        if (!locationId) {
            return dispatch(addAlert({ type: 'error', text: 'A location ID is required!' }))
        }

        axios.post('/adyen/sessions', {
            authenticity_token: accessToken,
            booking_id: bookingId,
            location_id: locationId
        }).then(({ data }) => {
            dispatch(setSessionId(data.id))
            dispatch(setSessionData(data.sessionData))
            dispatch(setAdyenClient(data.adyenClient))
            dispatch(setProcessing(false))
        }).catch((error) => {
            if (debug && console) { console.warn(error) }
            dispatch(addAlert({ type: 'error', text: error.response.data.error }))
        })
    }
}

export function cancelTerminalTransaction() {
    return async (dispatch, getState) => {

        const processing  = getState().adyen.processing
        const terminalId  = getState().adyen.selectedTerminal
        const saleId      = window.sessionStorage.getItem(ADYEN_TERMINAL_SALE_ID)
        const serviceId   = window.sessionStorage.getItem(ADYEN_TERMINAL_SERVICE_ID)

        if (!processing) { return }

        if (!!window.axiosTransactionSource) {
            window.axiosTransactionSource.cancel('OPERATION CANCELLED BY THE USER')
        }

        if (!terminalId) {
            dispatch(addAlert({ type: 'error', text: 'The terminal ID is required to cancel!' }))
            return
        }

        if (!saleId) {
            dispatch(addAlert({ type: 'error', text: 'The sale ID is required to cancel!' }))
            return
        }

        if (!serviceId) {
            dispatch(addAlert({ type: 'error', text: 'The service ID is required to cancel!' }))
            return
        }

        axios.request({
            url: '/adyen/terminals/transactions/cancel',
            method: 'PATCH',
            data: {
                authenticity_token: accessToken,
                terminal_id: terminalId,
                sale_id: saleId,
                service_id: serviceId,
            },
            headers: {
                timeout: 20
            }
        }).then(({ data }) => {
            /*
             * NOTE
             * because we cancel using another thread in the controller,
             * we immediately return a success unless an actual error
             * happened. Therefore, we have no message to display to
             * the user and are waiting on the abort message to come
             * back from original terminal request itself.
             *
             * "Nowadays, everybody wanna talk
             * Like they got somethin' to say
             * But nothin' comes out when they move their lips
             * Just a bunch of gibberish
             * And moth%#$%ers act like they forgot about Dre"
            */
            if (data.success) { return }

            if (console) {
                console.error('An unexpected error occurred while attempting to cancel the transaction!', data)
            }
        }).catch((error) => {
            if (console) { console.error(error) }
            dispatch(addAlert({ type: 'error', text: error.response.data.error }))
        })
    }
}

export function fetchTerminals(locationId=null) {
    return async (dispatch, getState) => {
        locationId = locationId || getState().location.location?.id

        const url = `/adyen/terminals${!!locationId ? `/?l=${locationId}` : ''}`

        axios.get(url).then(({ data }) => {
            dispatch(setTerminals(data))
        }).catch((error) => {
            if (debug && console) { console.error(error) }
            dispatch(addAlert({ type: 'error', text: error?.response?.data?.message || 'Unable to fetch terminals!' }))
        })
    }
}

export function tokenizeCardViaTerminal(locationId) {
    return async (dispatch, getState) => {
        window.sessionStorage.setItem(ADYEN_TERMINAL_SALE_ID, SecureHash(10))
        window.sessionStorage.setItem(ADYEN_TERMINAL_SERVICE_ID, SecureHash(5))

        const terminalId = getState().adyen.selectedTerminal
        const saleId     = window.sessionStorage.getItem(ADYEN_TERMINAL_SALE_ID)
        const serviceId  = window.sessionStorage.getItem(ADYEN_TERMINAL_SERVICE_ID)

        if (!locationId) {
            return dispatch(addAlert({ type: 'error', text: 'A location ID is required!' }))
        }

        if (!terminalId) {
            dispatch(addAlert({ type: 'error', text: 'The terminal ID is required!' }))
            return
        }

        if (!saleId) {
            dispatch(addAlert({ type: 'error', text: 'The sale ID is required!' }))
            return
        }

        if (!serviceId) {
            dispatch(addAlert({ type: 'error', text: 'The service ID is required!' }))
            return
        }

        window.axiosTransactionSource = axios.CancelToken.source()

        dispatch(setProcessing(true))

        axios.request({
            url: '/adyen/terminals/tokenize-card',
            cancelToken: window.axiosTransactionSource.token,
            method: 'POST',
            data: {
                authenticity_token: accessToken,
                location_id: locationId,
                terminal_id: terminalId,
                sale_id: saleId,
                service_id: serviceId,
            },
            headers: {
                timeout: 90
            }
        }).then(({ data }) => {
            dispatch(setProcessing(false))

            if (!data.success) {
                dispatch(addAlert({ type: 'error', text: data.message }))
                return data
            }

            dispatch(setTerminalResponse(data.profile))
        }).catch((e) => {
            if (axios.isCancel(e)) {
                console.log(e.message.toUpperCase())
            } else {
                if (console) { console.warn(e) }

                if (e.response.status === 504) {
                    dispatch(addAlert({ type: 'error', text: 'CONNECTION TIMED OUT' }))
                    dispatch(setProcessing(false))
                } else {
                    dispatch(addAlert({ type: 'error', text: e?.response?.data?.message || 'TRANSACTION FAILED!' }))
                }
            }
        }).finally(() => {
            window.sessionStorage.removeItem(ADYEN_TERMINAL_SALE_ID)
            window.sessionStorage.removeItem(ADYEN_TERMINAL_SERVICE_ID)
            delete window.axiosTransactionSource
        })
    }
}

export function preAuthorizeCardViaTerminal(amount=null, locationId=null, recordId=null, recordType=null) {
    return async (dispatch, getState) => {
        window.sessionStorage.setItem(ADYEN_TERMINAL_SALE_ID, SecureHash(10))
        window.sessionStorage.setItem(ADYEN_TERMINAL_SERVICE_ID, SecureHash(5))

        const terminalId = getState().adyen.selectedTerminal
        const saleId     = window.sessionStorage.getItem(ADYEN_TERMINAL_SALE_ID)
        const serviceId  = window.sessionStorage.getItem(ADYEN_TERMINAL_SERVICE_ID)

        if (!amount) {
            return dispatch(addAlert({ type: 'error', text: 'An amount is required!' }))
        }

        if (!locationId) {
            return dispatch(addAlert({ type: 'error', text: 'A location ID is required!' }))
        }

        if (!terminalId) {
            dispatch(addAlert({ type: 'error', text: 'The terminal ID is required!' }))
            return
        }

        if (!saleId) {
            dispatch(addAlert({ type: 'error', text: 'The sale ID is required!' }))
            return
        }

        if (!serviceId) {
            dispatch(addAlert({ type: 'error', text: 'The service ID is required!' }))
            return
        }

        if (!recordId || !recordType) {
            return dispatch(addAlert({ type: 'error', text: 'Unknown record for transaction attachment' }))
        }

        window.axiosTransactionSource = axios.CancelToken.source()

        dispatch(setProcessing(true))

        return axios.request({
            url: '/adyen/terminals/pre-authorize-card',
            cancelToken: window.axiosTransactionSource.token,
            method: 'POST',
            data: {
                authenticity_token: accessToken,
                location_id: locationId,
                terminal_id: terminalId,
                sale_id: saleId,
                service_id: serviceId,
                amount: amount,
                record_to_attach_to: {
                    id: recordId,
                    type: recordType,
                }
            },
            headers: {
                timeout: 90
            }
        }).then(({ data }) => {
            dispatch(setProcessing(false))

            if (!data.success) {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }

            return data
        }).catch((e) => {
            if (axios.isCancel(e)) {
                console.log(e.message.toUpperCase())
            } else {
                if (console) { console.warn(e) }

                if (e.response.status === 504) {
                    dispatch(addAlert({ type: 'error', text: 'CONNECTION TIMED OUT' }))
                    dispatch(setProcessing(false))
                } else {
                    dispatch(addAlert({ type: 'error', text: e?.response?.data?.message || 'TRANSACTION FAILED!' }))
                }
            }
        }).finally(() => {
            window.sessionStorage.removeItem(ADYEN_TERMINAL_SALE_ID)
            window.sessionStorage.removeItem(ADYEN_TERMINAL_SERVICE_ID)
            delete window.axiosTransactionSource
        })
    }
}

export default adyenSlice.reducer
