import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, FormProvider } from 'react-hook-form'
import PurchaseModalCards from '@/features/GiftCards/components/PurchaseModalCards'
import PurchaseModalPayment from '@/features/GiftCards/components/PurchaseModalPayment'
import {
    setAmounts,
    selectCreditToken,
    selectLoading,
    selectIsComplete,
    selectIsCompleteFailure,
    selectIsUnableToContinue,
    selectCart,
    submitPayment,
    setDefaultDiscount,
    setHasPromotionWithCode,
} from '@/features/GiftCards/giftCardSlice'

import { selectAdyenPayment } from '@/features/Adyen/adyenSlice'

export default function PurchaseModal({
    url=null,
    amounts=[],
    locationPhoneNumber=null,
    companyId,
    locationId,
    locationTimeZone,
    environment,
    valid_promotion_without_code,
    has_promotion_with_code,
    paymentProcessor,
}) {

    const formMethods        = useForm()
    const dispatch           = useDispatch()
    const creditToken        = useSelector(selectCreditToken)
    const isLoading          = useSelector(selectLoading)
    const isUnableToContinue = useSelector(selectIsUnableToContinue)
    const isComplete         = useSelector(selectIsComplete)
    const isCompleteFailure  = useSelector(selectIsCompleteFailure)
    const cart               = useSelector(selectCart)
    const adyenPayment       = useSelector(selectAdyenPayment)

    const [loaded, setLoaded]             = useState(false)
    const [isFormValid, setFormIsValid]   = useState(false)
    const [allSystemsGo, setAllSystemsGo] = useState(false)
    const [adyenCheckout, setAdyenCheckout] = useState(null)

    const handleError = () => {
        setFormIsValid(false)
    }

    const handleSubmit = () => {
        setFormIsValid(true)

        if (paymentProcessor === 'adyen') {
            adyenCheckout.submit()
        } else {
            document.getElementById('finish-button').dispatchEvent(new Event('payment-ready'))
        }
    }

    useEffect(() => {
        dispatch(setAmounts(amounts))
        dispatch(setHasPromotionWithCode(has_promotion_with_code))
        setLoaded(true)
    }, [])

    useEffect(() => {
        if (isFormValid && creditToken) {
            setAllSystemsGo(true)
        }
    }, [isFormValid, creditToken])

    useEffect(() => {
        if (isFormValid && adyenPayment) {
            setAllSystemsGo(true)
        }
    }, [isFormValid, adyenPayment])

    useEffect(() => {
        if (!loaded) { return }

        if (allSystemsGo) {
            dispatch(submitPayment(url))
        } else {
            setAllSystemsGo(false)
        }
    }, [allSystemsGo])

    useEffect(() => {
        if (isComplete) {
            window.setTimeout(() => window.location.reload(), 5000)
        }
    }, [isComplete])

    useEffect(() => {
        if (valid_promotion_without_code) {
            dispatch(setDefaultDiscount(valid_promotion_without_code))
        }
    }, [valid_promotion_without_code])

    return (
        <FormProvider {...formMethods}>
            <div className="purchase-modal-container gc-modal-container d-flex flex-column">
                <div className="container-xl p-4">
                    <div className="row mb-4 border-bottom">
                        <div className="col-12">
                            <h1 className="h4">Purchase Gift Cards</h1>
                        </div>
                    </div>
                </div>
                <div className="container-xl flex-grow scroll-it">
                    {
                        (isCompleteFailure || isUnableToContinue || isComplete) ? <>
                            { isUnableToContinue && (
                                <div className="w-100 text-center mt-5 pt-5">
                                    <h2 className="mb-4">We're sorry...</h2>
                                    <p className="lead">Due to connection issues, we're unable<br />to handle gift card purchases at this time.</p>
                                    <p className="lead">Please try again later.</p>
                                </div>
                            )}

                            { isCompleteFailure && (
                                <div className="w-100 text-center mt-5 pt-5">
                                    <h2 className="mb-4">We're sorry...</h2>
                                    <p className="lead">We had issues while creating your gift {cart.length === 1 ? 'card' : 'cards'}.</p>
                                    { locationPhoneNumber &&
                                        <p className="lead">Please reach out to our location for further assistance by calling us at <a href={`tel:${locationPhoneNumber}`}>{locationPhoneNumber}</a>.</p>
                                    }
                                </div>
                            )}

                            { isComplete &&
                                <h2 className="w-100 text-center mt-5 pt-5">Thank You For Your Purchase!</h2>
                            }
                        </> : (
                            <form className="row" onSubmit={formMethods.handleSubmit(handleSubmit, handleError)}>
                                <PurchaseModalCards maxPurchaseable="10" locationTimeZone={locationTimeZone} />

                                <PurchaseModalPayment
                                    companyId={companyId}
                                    locationId={locationId}
                                    locationTimeZone={locationTimeZone}
                                    environment={environment}
                                    paymentProcessor={paymentProcessor}
                                    adyenCheckout={adyenCheckout}
                                    setAdyenCheckout={setAdyenCheckout} />
                            </form>
                        )
                    }

                    { isLoading &&
                        <div id="global-loading">
                            <div id="global-loading--content">
                                <div className="spinner">
                                    <div className="rect1" />
                                    <div className="rect2" />
                                    <div className="rect3" />
                                    <div className="rect4" />
                                    <div className="rect5" />
                                    <div className="rect6" />
                                </div>
                                Purchasing your { cart.length === 1 ? 'card' : 'cards' }...
                            </div>
                        </div>
                    }
                </div>
            </div>
        </FormProvider>
    )
}
