import React from 'react'
import titleize from '../../lib/String'

export default function LocationTabs({
    superUser,
    companyAdmin,
    activeTab,
    membershipsEnabled,
    handleClick
}) {
    let tabs = ['setup', 'hours', 'branding', 'scoring', 'emails', 'reso_occ.', 'referrals']

    if (superUser) {
        tabs.splice(2, 0, 'payment')
    }

    if (membershipsEnabled) {
        tabs.splice(-1, 0, 'member_occ.')
    }

    return (
        <div className="drawer-tabs">
            {
                tabs.map((tabName) => (
                    <div
                      key={tabName}
                      className={`tab ${tabName === activeTab ? 'active' : ''}`}
                      onClick={() => handleClick(tabName)}
                    >
                        { titleize(tabName) }
                    </div>
                ))
            }
        </div>
    )
}
