import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { selectTimeZone } from '@/features/Locations/locationSlice'
import { setFormAttribute } from '@/features/Schedule/scheduleSlice'
import { momentToLocalDate, localDateToMoment } from '@/lib/CalendarTimes'
import { preventTyping } from '@/lib/Form'

export default function QuoteExpirationDate({ form, onChange }) {

    const dispatch         = useDispatch()
    const selectedTimeZone = useSelector(selectTimeZone)

    const adjustedDate = (date) => {
        return date.set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
    }

    const handleDateChange = (date) => {
        onChange(adjustedDate(localDateToMoment(date, selectedTimeZone)).toISOString())
    }

    // set the default expiration date to be the same as the quote's initial datetime
    useEffect(() => {
        if (!!form?.date && !form?.quote_expires_at) {
            const quoteDate = moment.tz(form.date, selectedTimeZone)

            dispatch(setFormAttribute({
                name: 'quote_expires_at',
                value: adjustedDate(quoteDate).toISOString()
            }))
        }
    }, [form])

    // do not let the quote expiration date ever be
    // earlier or later than the quote's start date
    useEffect(() => {
        if (!!form?.date && !!form?.quote_expires_at) {
            const quoteDate      = moment.tz(form.date, selectedTimeZone)
            const quoteExpiresAt = moment.tz(form.quote_expires_at, selectedTimeZone)

            if (quoteExpiresAt.isAfter(quoteDate)) {
                dispatch(setFormAttribute({
                    name: 'quote_expires_at',
                    value: adjustedDate(quoteDate).toISOString()
                }))
            }
        }
    }, [form.date])

    return (
        <div className={`form-group ${form?.quote_expires_at ? '' : 'd-none'}`}>
            <label>Quote Expiration Date</label>

            <DatePicker
                minDate={momentToLocalDate(moment().tz(selectedTimeZone))}
                maxDate={momentToLocalDate(moment.tz(form.date, selectedTimeZone))}
                selected={
                    !!form?.quote_expires_at
                        ? momentToLocalDate(moment.tz(form?.quote_expires_at, selectedTimeZone))
                        : null
                }
                onChange={handleDateChange}
                onKeyDown={preventTyping}
            />
        </div>
    )
}
