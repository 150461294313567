import React from 'react'
import FormRow from '../Form/FormRow'
import LaneField from './LaneField'

/**
 * Individual lane field row. This will render inputs for each input option (name, capacity) for 1 individual lane.
 *
 * @param handleChange event handler for when an input is changed
 * @param names resource_names object
 * @param capacities resource_capacities object
 * @param number the number lane we're currently on
 */
export default function LaneFieldsRow({ handleChange, names, number }) {
    return (
        <FormRow>
            <LaneField
                cols='12'
                handleChange={handleChange}
                collection={names}
                prefix='name'
                column='resource_names'
                number={number} />
        </FormRow>
    )
}
