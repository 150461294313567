import React, { useState, useRef, useEffect } from 'react'
import { useConfirm } from '@/lib/useConfirmHook'

export default function ConfirmationLink({
  className='btn btn-link btn-link-danger',
  confirmation_text='Are you sure?',
  link_text='Delete',
  method='delete',
  confirmOptions={},
  url=null,
  target=null,
  rel='nofollow',
  disabled=false,
}) {
    const linkRef           = useRef()
    const { confirm }       = useConfirm()
    const [ready, setReady] = useState(false)

    const handleClick = async (e) => {
        if (!ready) {
            e.stopPropagation()
            e.preventDefault()

            if (await confirm(confirmation_text, confirmOptions)) {
                setReady(true)
                window.setTimeout(() => setReady(false), 100)
            }
        }
    }

    useEffect(() => {
        if (ready) {
            linkRef.current.click()
        }
    }, [ready])

    return (
        <a
            children={link_text}
            ref={linkRef}
            className={className}
            href={url}
            rel={rel}
            data-method={method}
            disabled={disabled}
            target={target}
            onClick={handleClick}
        />
    )
}
