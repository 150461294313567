import React, { useState } from 'react'
import DrawerTabs from '@/components/Drawer/Tabs'
import ItemFormDetails from './ItemFormDetails'
import ItemFormModifiers from './ItemFormModifiers'

export default function ItemForm(props) {
  const [tab, setTab]                           = useState('details')
  const [defaultModifiers, setDefaultModifiers] = useState(null)

  const tabs = ['details', 'modifiers']

  const renderTab = () => {
    switch (tab) {
      case tabs[0] : return (
        <ItemFormDetails
          defaultModifiers={defaultModifiers}
          newRecord={props.new}
          {...props}
        />
      )

      case tabs[1] : return (
          <ItemFormModifiers
            defaultModifiersHasChanged={(collection) => setDefaultModifiers(collection)}
            {...props}
          />
        )
    }
  }

  return (
    <div>
      <DrawerTabs
        activeTab={tab}
        tabs={tabs}
        handleClick={setTab}
      />

      {renderTab()}
    </div>
  )
}
