import React from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    checkGiftCardBalance,
    selectGiftCardNumber,
    setGiftCardNumber,
    selectGiftCardBalance,
    setGiftCardBalance,
    selectIsValidAmount
} from "./bookingPaymentSlice"
import Input from '@/components/Form/Input'
import { numberToCurrency } from '@/lib/Number'

export default function PaymentGiftCard() {

    const dispatch        = useDispatch()
    const giftCardNumber  = useSelector(selectGiftCardNumber)
    const giftCardBalance = useSelector(selectGiftCardBalance)
    const isValidAmount   = useSelector(selectIsValidAmount)

    return (
        <div className="modal-body-main-section">
            <div className="pay-by-gift-card">
                <div className="form-row">
                    <div className="col">
                        <div className="form-group pb-0">
                            <label>Gift Card Number</label>
                            <div className="input-group mb-2">
                                <Input
                                    mask="*****-*****-*****-*****"
                                    placeholder="XXXXX-XXXXX-XXXXX-XXXXX"
                                    value={giftCardNumber || ''}
                                    type="text"
                                    className="form-control text-uppercase font-monospace"
                                    append={true}
                                    withWrapper={false}
                                    handleChange={e => {
                                        dispatch(setGiftCardNumber(e.target.value))
                                        dispatch(setGiftCardBalance(null))
                                    }}
                                >
                                    <div className="input-group-append">
                                        <button className="btn btn-primary" onClick={() => dispatch(checkGiftCardBalance())}>
                                            Check Balance
                                        </button>
                                    </div>
                                </Input>
                            </div>
                        </div>

                        {
                            !!giftCardNumber && typeof giftCardBalance === 'number' && (
                                <div className="text-right">
                                    <strong className="note pr-2">Card Balance Remaining:</strong>

                                    <strong className={giftCardBalance > 0 ? 'text-success' : 'text-danger'}>
                                        {numberToCurrency(giftCardBalance / 100)}
                                    </strong>

                                    { isValidAmount === false &&
                                        <strong className="invalid-feedback d-block">Insufficient Balance</strong>
                                    }
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
