import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logger as ActionCableLogger, createConsumer as ActionCableCreateConsumer } from '@rails/actioncable'
import { addAlert } from '@/features/Notifications/notificationSlice'
import { fetchResources } from '@/features/Resources/resourcesSlice'
import { selectResourceType } from '@/features/ResourceType/resourceTypeSlice'
import { initUpdateBooking, initRemoveBooking, initRemoveBuffer, bookingIsPresent } from '@/features/CalendarBookings/calendarBookingsSlice'
import { setBooking, setBookingInUseBy } from '@/features/EditBooking/editBookingSlice'
import { setParentBooking, setChildBookings } from '@/features/ParentBooking/parentBookingSlice'
import { debug } from '@/lib/Debug'

import {
  SOCKET_CONNECTED,
  IS_BOOKING_OPEN,
  IS_PARENT_BOOKING_OPEN,
  IS_SOCKET_CONNECTED,
  CURRENT_BOOKING_ID,
  CURRENT_PARENT_BOOKING_ID
} from '@/lib/Storage'

export default function CalendarSubscription() {

    const socket       = useRef(null)
    const dispatch     = useDispatch()
    const resourceType = useSelector(selectResourceType)

    const updateBooking = (data) => {
        if (!data) { return }
        dispatch(setBooking(data))
    }

    const updateParentAndChildBookings = (data) => {
        if (!data) { return }
        dispatch(setBooking(data))
        dispatch(setParentBooking({booking: data.booking}))
        dispatch(setChildBookings({childBookings: data.child_bookings}))
    }

    const update = (data) => {
        if (!data || !dispatch(bookingIsPresent(data?.booking?.id))) { return }

        if ('child_bookings' in data && data.child_bookings) {
            updateParentAndChildBookings(data)
        } else {
            updateBooking(data)
        }
    }

    /**
     * Whenever the selected resource type changes, we need to make sure we fetch for that resource type's resources.
     * This should not happen above when date changes, since the resources themselves will be the same.
     *
     * @TODO This probably doesn't need to be here but having useEffects everywhere can also get messy if things are firing
     * off, especially since above we have an effect on resourceType... having them all in one place isn't a bad idea,
     * especially if they're shared... putting them for individual components is fine as long as they're not duplicated
     * elsewhere for the same selector, either way these are easily portable to other components.
     */
    useEffect(() => {
        if (dispatch && resourceType) {
            dispatch(fetchResources(resourceType))

            // if we have a socket in state, unsubscribe from it since we need to switch to a different one here
            if (socket.current) {
                try {
                    socket.current.unsubscribe()
                } catch (e) {
                    console.log('Unable to unsubscribe from cable: ', e)
                }
            }

            if (debug) {
                ActionCableLogger.enabled = true
            }

            // create a consumer for the resource type we're currently on & dispatch to calendar booking slice when we get updates
            const subscription = ActionCableCreateConsumer().subscriptions.create({ channel: 'BookingChannel', resource_type_id: resourceType }, {
                connected: () => {
                    if (window.localStorage.getItem(IS_SOCKET_CONNECTED) == 'false') {
                        window.localStorage.setItem(IS_SOCKET_CONNECTED, 'true')

                        if (console) { console.log('%c WEBSOCKET: RECONNECTED','background:#48C936;color:#ffffff;') }
                    }
                },
                disconnected: () => {
                    if (window.localStorage.getItem(IS_SOCKET_CONNECTED) == 'true') {
                        window.localStorage.setItem(IS_SOCKET_CONNECTED, 'false')

                        if (console) { console.log('%c WEBSOCKET: DISCONNECTED','background:#FF2B23;color:#ffffff;') }
                    }
                },
                received: ({ data, type }) => {
                    if (debug && console) {
                        console.log('%c WEBSOCKET: DATA RECEIVED','background:#0488CC;color:#ffffff;')
                        console.log([type, data])
                    }

                    // types: [update, delete]
                    switch(type) {
                        case 'update_package' : {
                            socket.current.unsubscribe()
                            window.location.reload()
                        }

                        case 'insert_buffer' : {
                            /**
                             * update the calendar
                             * data: { :booking, :resources }
                             */
                            dispatch(initUpdateBooking(data))
                            break
                        }

                        case 'delete_buffer' : {
                            /**
                             * update the calendar
                             * data: { :id }
                             */
                            dispatch(initRemoveBuffer(data.id))
                            break
                        }

                        case 'delete' : {
                            /**
                             * update the calendar
                             * data: { :id }
                             */
                            dispatch(initRemoveBooking(data.id))
                            break
                        }

                        case 'in_use' : {
                            dispatch(setBookingInUseBy(data))
                            break
                        }

                        case 'update' : {
                            /**
                             * update the calendar
                             * data: { :booking, :resources, :child_bookings, :package }
                             */
                            dispatch(initUpdateBooking(data))

                            /**
                             * update the booking that is currently in the schedule drawer
                             *
                             * note: we first need to check to make sure that we're only
                             * responding to an update for the data in the drawer that we
                             * are currently viewing/editing
                             *
                             * note: we need to check against localStorage here because inside of
                             * this callback method we do NOT have access to the current redux state
                             */
                            switch(true) {
                                case window.sessionStorage.getItem(IS_BOOKING_OPEN) === 'true'
                                    && window.sessionStorage.getItem(CURRENT_BOOKING_ID) == data?.booking?.id :
                                // ---------------------------------------------------------------------------------
                                    update(data)
                                    break

                                case window.sessionStorage.getItem(IS_PARENT_BOOKING_OPEN) === 'true'
                                    && window.sessionStorage.getItem(CURRENT_PARENT_BOOKING_ID) == data?.booking?.id :
                                // ---------------------------------------------------------------------------------
                                    update(data)
                                    break
                            }

                            break
                        }

                        default: {
                            if (debug && console) { console.error('Something went kaboom during the broadcast. Type must not be set correctly. This should never be hit') }
                        }
                    }
                }
            })

            // store the subscription in state so we can disconnect from it if the user switches resource types.
            window.localStorage.setItem(IS_SOCKET_CONNECTED, 'true')
            socket.current = subscription
        }
    }, [resourceType])

    useEffect(() => {
        return () => { socket.current = null }
    }, [])

    if (debug && console) {
        console.log('>>> CALENDAR SUBSCRIPTION JUST RE-RENDERED <<<')
    }

    return null
}
