import React from 'react'
import { useSelector } from 'react-redux'
import { selectContactPage } from '../../../Locations/locationSlice'

export default function SelectContact() {

    const contact_page = useSelector(selectContactPage)

    return (
        <div className="step-note text-center">
            <h3>Not seeing what you’re looking for?</h3>
            <p><a href={contact_page} target="_blank">Contact Us</a> and we’ll work with you to customize your reservation.</p>
        </div>
    )
}
