import { createSlice } from "@reduxjs/toolkit"
import axios from 'axios'
import { addAlert } from '@/features/Notifications/notificationSlice'

import {
    setProcessing as setAdyenProcessing,
    cancelTerminalTransaction as cancelAdyenTransaction 
} from '@/features/Adyen/adyenSlice'

import {
    setProcessing as setPaysafeProcessing,
    cancelTransaction as cancelPaysafeTransaction
} from '@/features/Terminal/terminalSlice'

import { PREVENT_LOADER, ADYEN_TERMINAL_SALE_ID, ADYEN_TERMINAL_SERVICE_ID } from '@/lib/Storage'
import { SecureHash } from '@/lib/Crypto'
import { roundUp } from '@/lib/Number'
import { debug } from '@/lib/Debug'

export const productSlice = createSlice({
    name: 'products',
    initialState: {
        products: [],
        selectedProducts: [],
        pricing: {
            subtotal: 0.0,
            taxes: 0.0,
            total: 0.0
        },
        locationId: null,
        locationTaxRate: null,
        locationPaymentProcessor: null,
        discount: 0.0,
        memberDiscount: 0.0,
        memberDiscountInfo: "",
        discountNotes: null,
        modalOpen: false,
        paymentType: 'credit',
        paymentAmount: null,
        paymentReceived: 0,
        changeDue: null,
        order: null,
        checkNumber: null,
        giftCardNumber: null,
        giftCardBalance: null,
        giftCardError: null,
        creditToken: null,
        zip: null,
        processingPayment: false,
        error: null,
        debug: {},
    },
    reducers: {
        setError: (state, action) => {
            state.error = action.payload
        },
        setProcessingPayment: (state, action) => {
            state.processingPayment = action.payload
        },
        setProducts: (state, action) => {
            state.products = action.payload
        },
        selectProduct: (state, action) => {
            const product = action.payload.product
            const quantity = action.payload.amount

            if (state.selectedProducts.filter(p => p.id === product.id).length) {
                state.selectedProducts = state.selectedProducts.map((sp) => {
                    if (sp.id === product.id) {
                        return {
                            ...sp,
                            quantity: quantity
                        }
                    } else {
                        return sp
                    }
                }).filter(sp => sp.quantity !== 0)
            } else {
                state.selectedProducts.push({
                    ...product,
                    quantity: quantity
                })
            }
        },
        setPricing: (state, action) => {
            state.pricing            = action.payload
            state.memberDiscountInfo = action.payload.memberDiscountInfo
            state.paymentAmount      = action.payload.total.toFixed(2)
        },
        setLocationId: (state, action) => {
            state.locationId = action.payload
        },
        setLocationTaxRate: (state, action) => {
            state.locationTaxRate = action.payload
        },
        setLocationPaymentProcessor: (state, action) => {
            state.locationPaymentProcessor = action.payload
        },
        setDiscountFields: (state, action) => {
            state.discount       = action.payload.discount
            state.memberDiscount = action.payload.member_discount || 0.0
            state.discountNotes  = action.payload.notes
        },
        setDiscountNotes: (state, action) => {
            state.discountNotes = action.payload;
        },
        setMemberDiscount: (state, action) => {
            state.memberDiscount = action.payload;
        },
        setModalOpen: (state, action) => {
            state.modalOpen = action.payload
        },
        setSelectedProducts: (state, action) => {
            state.selectedProducts = action.payload
        },
        clearFields: (state, _action) => {
            state.selectedProducts = []
            state.pricing = {
                subtotal: 0.0,
                taxes: 0.0,
                total: 0.0
            }
            state.discount = 0.0
            state.memberDiscount = 0.0
            state.discountNotes = null
            state.paymentType = 'credit'
            state.paymentAmount = null
            state.order = null
            state.checkNumber = null
            state.giftCardNumber = null
            state.giftCardBalance = null
            state.giftCardError = null
        },
        setPaymentType: (state, action) => {
            state.paymentType = action.payload
        },
        setPaymentAmount: (state, action) => {
            state.paymentAmount = action.payload
        },
        setPaymentReceived: (state, action) => {
            state.paymentReceived = action.payload
        },
        setChangeDue: (state, action) => {
            state.changeDue = action.payload
        },
        setOrder: (state, action) => {
            state.order = action.payload
        },
        setCheckNumber: (state, action) => {
            state.checkNumber = action.payload
        },
        setGiftCardNumber: (state, action) => {
            state.giftCardNumber = action.payload
        },
        setGiftCardBalance: (state, action) => {
            state.giftCardBalance = action.payload
        },
        setGiftCardError: (state, action) => {
            state.giftCardError = action.payload
        },
        setCreditToken: (state, action) => {
            state.creditToken = action.payload
        },
        setZip: (state, action) => {
            state.zip = action.payload
        },
        setDebug: (state, action) => {
            state.debug = action.payload
        }
    }
})

export const {
    setError,
    setProcessingPayment,
    setProducts,
    selectProduct,
    setPricing,
    setLocationId,
    setLocationTaxRate,
    setLocationPaymentProcessor,
    setDiscountFields,
    setDiscountNotes,
    setModalOpen,
    setSelectedProducts,
    clearFields,
    setPaymentType,
    setPaymentAmount,
    setPaymentReceived,
    setChangeDue,
    setOrder,
    setCheckNumber,
    setGiftCardNumber,
    setGiftCardBalance,
    setGiftCardError,
    setCreditToken,
    setZip,
    setDebug,
    setMemberDiscount,
} = productSlice.actions

export const selectProcessingPayment  = state => state.products.processingPayment
export const selectProducts           = state => state.products.products
export const selectSelectedProducts   = state => state.products.selectedProducts
export const selectPricing            = state => state.products.pricing
export const selectLocationId         = state => state.products.locationId
export const selectLocationTaxRate    = state => state.products.locationTaxRate
export const selectLocationPaymentProcessor = state => state.products.locationPaymentProcessor
export const selectDiscount           = state => state.products.discount
export const selectDiscountNotes      = state => state.products.discountNotes
export const selectMemberDiscount     = state => state.products.memberDiscount
export const selectMemberDiscountInfo = state => state.products.memberDiscountInfo
export const selectModalOpen          = state => state.products.modalOpen
export const selectPaymentType        = state => state.products.paymentType
export const selectPaymentAmount      = state => state.products?.paymentAmount
export const selectPaymentReceived    = state => state.products.paymentReceived
export const selectChangeDue          = state => state.products.changeDue
export const selectOrder              = state => state.products.order
export const selectCheckNumber        = state => state.products.checkNumber
export const selectGiftCardNumber     = state => state.products?.giftCardNumber
export const selectGiftCardBalance    = state => state.products?.giftCardBalance
export const selectGiftCardError      = state => state.products?.giftCardError
export const selectCreditToken        = state => state.products.creditToken
export const selectZipCode            = state => state.products.zip
export const selectError              = state => state.products.error
export const selectDebug              = state => state.products.debug

export function fetchProducts(path) {
    return async (dispatch) => {
        axios.get(path).then(({ data }) => {
            dispatch(setProducts(data))
        }).catch((e) => {
            console.warn(e)
        })
    }
}

/**
 * Calculate the pricing object for the selected products.
 *
 * Right now this is done locally so we don't have to worry about pinging
 * the backend just to get totals which are just price * quantity and then
 * tax rate. No real need to fire more requests and queries just to sum those.
 */
export function calculatePrice() {
    return async (dispatch, getState) => {
        const locationTaxRate = getState().products.locationTaxRate || 'missing!'
        const products        = getState().products.selectedProducts
        const wholeDiscount   = getState().products.discount
        const memberDiscount  = getState().products.memberDiscount

        let pricing = {
            discount: 0.0,
            memberDiscount: 0.0,
            memberDiscountInfo: '',
            subtotal: 0.0,
            taxes: 0.0,
            total: 0.0,
        }

        // Discounts are determined in the product loop as a percentage.
        // The rate is determined by "weighting" the item in the cart.
        //
        // For example:
        // A $5 flat discount applied across a cart with 4 items in it
        // ($10, $10, $1, $1) where the discount is weighted across each
        // item as a percentage so that, when added up, all of the small
        // discounts per item equal the whole flat $5 discount in the end.
        //
        // (flat_discount * (product_price / base_cart_total) ...etc
        // (5*(10/22) + (5*(10/22)) + (5*(1/22)) + (5*(1/22))
        //

        const baseCartTotal = products.length > 0
            ? products.map((p) => (Number.parseFloat(p.price) * Number.parseInt(p.quantity, 10)))
                      .reduce((a,b) => a + b)
            : 0

        // loop over each selected, and update total to price * quantity
        products.forEach((p) => {
            const quantity = Number.parseInt(p.quantity, 10)
            // If the memberDiscount is present, we need to adjust the actualy price of
            // each product in order to apply the admin discounts, taxes, etc... properly
            const priceWithMemberDiscount = Number.parseFloat(p.price) * ((100 - memberDiscount) / 100)

            const discount = (wholeDiscount * (Number.parseFloat(priceWithMemberDiscount) / baseCartTotal))
            let price      = Number.parseFloat(priceWithMemberDiscount) - discount
            let taxRate    = Number.parseFloat(p.use_product_tax_rate ? p.tax_rate : locationTaxRate) / 100
            let salesTax   = 0
            let subTotal   = 0

            price   = isNaN(price)   ? 0 : price
            taxRate = isNaN(taxRate) ? 0 : taxRate

            if (p.price_includes_sales_tax) {
                // Subtractive Sales Tax Formula
                // for 7.5% sales tax
                // $20 - ($20 / (1 + (7.5/100))) = $1.3953488372 (sales tax only)
                // $20 - $1.3953488372 = $18.6046511628 (product price only)
                // -----------------------------------------------
                // $20 = $18.6046511628 + $1.3953488372 (price with sales tax included)
                salesTax = price - (price / (1 + taxRate))
                subTotal = price - salesTax
            } else {
                // Additive Sales Tax Formula
                // for 7.5% sales tax
                // $20 * (7.5/100) = $1.5 (sales tax only)
                // $20 + $1.5 = $21.50 (price plus sales tax)
                salesTax = price * taxRate
                subTotal = price
            }

            // NOTE: we round up to the nearest 100th
            // ex: 1.075 becomes 1.08
            pricing.memberDiscount += roundUp(Number.parseFloat(p.price) * memberDiscount / 100)
            pricing.discount       += roundUp(discount * quantity)
            pricing.subtotal       += roundUp(subTotal * quantity)
            pricing.taxes          += roundUp(salesTax * quantity)
        })

        // NOTE: we round up to the nearest 100th
        // ex: 1.075 becomes 1.08
        pricing.total = roundUp(pricing.subtotal + pricing.taxes)

        pricing.memberDiscountInfo = `${memberDiscount}% (\$${pricing.memberDiscount})`

        if (debug) {
            dispatch(setDebug({
                locationTaxRate: locationTaxRate.toString(),
                baseCartTotal: baseCartTotal.toString(),
                memberDiscount: memberDiscount.toString(),
                calculatedMemberDiscount: pricing.memberDiscount.toString(),
                memberDiscountInfo: pricing.memberDiscountInfo,
                requestedDiscount: wholeDiscount.toString(),
                calculatedDiscount: pricing.discount.toString(),
                subTotal: pricing.subtotal.toString(),
                taxes: pricing.taxes.toString(),
                total: pricing.total.toString(),
            }))
        }

        // set pricing object with subtotal, taxes, and total
        dispatch(setPricing(pricing))
    }
}

export function submitPurchase(purchasePath, name, email, creditToken, paymentResponse, partialAmountCharged, managerCode=null) {
    return async (dispatch, getState) => {
        const state            = getState().products
        const token            = getState().session.formToken
        const paymentType      = getState().products.paymentType
        const paymentProcessor = getState().products?.locationPaymentProcessor || getState()?.location?.paymentProcessor
        const adyenPayment     = getState().adyen.adyenPayment
        const adyenTerminal    = getState().adyen.selectedTerminal

        const discount = !!state.pricing.memberDiscount
                                ? state.pricing.memberDiscount
                                : !!state.pricing.discount
                                        ? state.pricing.discount 
                                        : 0

        window.axiosTransactionSource = axios.CancelToken.source()

        dispatch(setError(null))

        window.sessionStorage.setItem(PREVENT_LOADER, true)
        window.sessionStorage.setItem(ADYEN_TERMINAL_SALE_ID, SecureHash(10))
        window.sessionStorage.setItem(ADYEN_TERMINAL_SERVICE_ID, SecureHash(5))

        if (/^(credit_card|credit|terminal)$/i.test(paymentType)) {
            if (paymentProcessor === 'adyen')   { dispatch(setAdyenProcessing(true))   }
            if (paymentProcessor === 'paysafe') { dispatch(setPaysafeProcessing(true)) }
        }

        axios.request({
            url: purchasePath,
            cancelToken: window.axiosTransactionSource.token,
            method: 'POST',
            data: {
                authenticity_token: token,
                products:           state.selectedProducts,
                // payment_amount:     state.paymentAmount,
                payment_amount:     partialAmountCharged || state.paymentAmount,
                is_partial_payment: !!partialAmountCharged,
                payment_type:       state.paymentType,
                order_id:           state.order?.id,
                check_number:       state.checkNumber,
                gift_card_number:   state.giftCardNumber,
                discount:           discount,
                discount_notes:     state.discountNotes,
                credit_token:       creditToken || state.creditToken,
                zip:                state.zip,
                name:               name,
                email:              email,
                payment_response:   paymentResponse,
                manager_code_used:  managerCode,
                payment:            adyenPayment,
                terminal_id:        adyenTerminal,
                sale_id:            window.sessionStorage.getItem(ADYEN_TERMINAL_SALE_ID),
                service_id:         window.sessionStorage.getItem(ADYEN_TERMINAL_SERVICE_ID),
            },
            headers: {
                timeout: !!adyenTerminal ? 90 : 20 // 0 indicates no timeout
            }
        }).then(({ data }) => {
            if (data.success && !data.error) {

                // set the order, which is used if they make partial transactions
                dispatch(setOrder(data.order))

                // update the amount to pay to be the remaining balance
                dispatch(setPaymentAmount((data.order.balance_cents / 100).toFixed(2)))

                dispatch(setError(null))
                dispatch(addAlert({ type: 'success', text: data?.message?.toUpperCase() || 'TRANSACTION SUCCESSFUL' }))
                return
            }

            if (data.success && data.error) {
                dispatch(setOrder(data.order))
                dispatch(setError(data.message))
                dispatch(addAlert({ type: 'warning', text: data.message.toUpperCase() }))
                return
            }

            dispatch(setOrder(data.order))
            dispatch(setError(data.message))
            dispatch(addAlert({ type: 'error', text: data.message || 'ERROR: Could not complete transaction'}))
        }).catch((e) => {
            if (axios.isCancel(e)) {
                console.log(e.message.toUpperCase())
            } else {
                if (console) { console.warn(e) }

                if (e.response.status === 504) {
                    dispatch(addAlert({ type: 'error', text: 'TRANSACTION TIMED OUT' }))

                    if (/^(credit_card|credit|terminal)$/i.test(paymentType)) {
                        if (paymentProcessor === 'adyen')   { dispatch(cancelAdyenTransaction())   }
                        if (paymentProcessor === 'paysafe') { dispatch(cancelPaysafeTransaction()) }
                    }
                } else {
                    dispatch(addAlert({ type: 'error', text: e?.response?.data?.message || 'TRANSACTION FAILED!' }))
                }
            }
        }).finally(() => {
            window.sessionStorage.removeItem(PREVENT_LOADER)
            window.sessionStorage.removeItem(ADYEN_TERMINAL_SALE_ID)
            window.sessionStorage.removeItem(ADYEN_TERMINAL_SERVICE_ID)
            delete window.axiosTransactionSource

            if (/^(credit_card|credit|terminal)$/i.test(paymentType)) {
                if (paymentProcessor === 'adyen')   { dispatch(setAdyenProcessing(false))   }
                if (paymentProcessor === 'paysafe') { dispatch(setPaysafeProcessing(false)) }
            }
        })
    }
}

export function checkGiftCard() {
    return async (dispatch, getState) => {
        const token  = getState().session.formToken
        const number = getState().products.giftCardNumber

        axios.post(`/gift-cards/${number}/balance`, {
            authenticity_token: token,
            card_number:        number
        }).then(({ data }) => {
            dispatch(setGiftCardBalance(data.balance))

            if (data.success) {
                dispatch(setGiftCardError(null))
            } else {
                dispatch(setGiftCardError(data.message))
            }
        }).catch((e) => {
            console.warn(e)
        })
    }
}

export default productSlice.reducer
