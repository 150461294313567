import React from 'react'
import MemberMobileProgressHeader from '../MemberMobileProgressHeader'
import MemberFooter from '../MemberFooter'
import MemberErrors from '../MemberErrors'
import MemberLedger from '../Ledger/MemberLedger'
import { useForm, FormProvider } from "react-hook-form"

export default function MemberStep({
    hideMobileProgressHeader,
    hideNavigation,
    hideLedger,
    hideBackStep,
    footerButtons,
    nextStep,
    backStep,
    disabled,
    adyenCheckout,
    onError=()=>{},
    handleSubmit=()=>{},
    children,
}) {
    const formMethods = useForm()

    return (
        <FormProvider {...formMethods}>
            { !hideMobileProgressHeader && <MemberMobileProgressHeader /> }

            <div className="xbm-step-content" style={{ minHeight: '555px' }}>
                <div className="xbm-step-content-main">
                    {children}
                    <MemberErrors />
                </div>

                { !hideLedger && <MemberLedger /> }
            </div>

            {
                !hideNavigation && (
                    <MemberFooter
                        disabled={disabled}
                        footerButtons={footerButtons}
                        adyenCheckout={adyenCheckout}
                        nextStep={nextStep}
                        backStep={backStep}
                        hideBackStep={hideBackStep}
                        onError={onError}
                        handleSubmit={handleSubmit}
                    />
                )
            }
        </FormProvider>
    )
}
