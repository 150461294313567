import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { selectCart, updateCart } from '@/features/GiftCards/giftCardSlice'

export default function PurchaserName({ index }) {
    const dispatch = useDispatch()
    const { register, resetField, formState: { errors } } = useFormContext()
    const giftCards = useSelector(selectCart)
    const [showPurchaserName, purchaserName] = useMemo(() => [
        giftCards[index].showPurchaserName,
        giftCards[index].purchaserName
    ], [giftCards[index]])

    const nameField = register(`cards.${index}.purchaserName`, {
        required: showPurchaserName ? 'Please Enter Your Name.' : false,
    })

    const handleChange = () => {
        if (showPurchaserName) {
            dispatch(updateCart({ index, name: "showPurchaserName", value: false }))
            resetField(`cards.${index}.purchaserName`)
            dispatch(updateCart({ index, name: "purchaserName", value: "" }))
        } else {
            dispatch(updateCart({ index, name: "showPurchaserName", value: true }))
        }

    }

    return <>
        <div className="custom-control custom-checkbox my-3">
            <input
                id={`show-purchaser-name-${index}`}
                type="checkbox"
                className="custom-control-input"
                checked={showPurchaserName}
                onChange={handleChange}
            />
            <label
                htmlFor={`show-purchaser-name-${index}`}
                className="custom-control-label"
            >Tell the recipient who it's from?
            </label>
        </div>

        {showPurchaserName && <>
            <input
                {...nameField}
                id={`purchaser-name-${index}`}
                className={`form-control ${errors?.cards?.[index]?.purchaserName ? 'is-invalid' : ''}`}
                placeholder="Your Name Here"
                value={purchaserName}
                onChange={(e) => {
                    nameField.onChange(e)
                    dispatch(updateCart({ index, name: "purchaserName", value: e.target.value }))
                }}
            />
            {
                errors?.cards?.[index]?.purchaserName &&
                <div className="invalid-feedback d-block">{errors.cards[index].purchaserName.message}</div>
            }
        </>}
    </>
}
