import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  preloadBookingData,
  selectStep,
  selectParticipants,
  selectDuration,
  selectDate,
  selectTime,
  selectShowReminder,
  selectReminderAcknowledged,
  setFinalizeBookingUrl,
  setTrackAbandonedUrl,
  setHoldBookingUrl,
  setStep,
  setShowReminder,
  setReminderAcknowledged,
  setHoldTimeDurationMinutes,
  setPaymentType,
} from '../bookingSlice'

import { fetchPackage, selectPackage, selectPaymentTypes } from '../../Packages/packageSlice'
import { fetchLocation } from '../../Locations/locationSlice'
import { fetchResourceType, selectResourceType } from '../../ResourceType/resourceTypeSlice'
import { fetchCustomerTypes } from '../../CustomerTypes/customerTypeSlice'
import { setAvailableDatesUrl, setAvailableTimesUrl } from '../../Availability/availabilitySlice'
import { setReferralTypes } from "../../Referrals/referralsSlice"
import { setOccasionTypes } from "../../Occasions/occasionsSlice"

import BookingModalHeader from './BookingModalHeader'
import BookingModalStyles from './BookingModalStyles'
import MobileProgressHeader from './Ledger/MobileProgressHeader'
import Step0 from "./Step0/Step0"
import Step1 from './Step1/Step1'
import Step2 from './Step2/Step2'
import Step3 from './Step3/Step3'
import Step4 from './Step4/Step4'
import Step5 from './Step5/Step5'
import Step6 from './Step6/Step6'
import Confirmation from './Confirmation/Confirmation'
import UnbookableError from './UnbookableError'
import ReminderModal from '@/features/Bookings/components/Reminder/ReminderModal'
import Members from './Members/Members'

export default function BookingModalBody({
  packageUrl,
  locationUrl,
  resourceTypeUrl,
  availableDatesUrl,
  availableTimesUrl,
  holdBookingUrl,
  trackAbandonedUrl,
  finalizeBookingUrl,
  customerTypesUrl,
  referralTypes,
  occasionTypes,
  modalStyles,
  step0,
  packageGroup,
  packages,
  preloadedData,
  environment,
  holdTimeDurationMinutes,
}) {

    const dispatch             = useDispatch()
    const pkg                  = useSelector(selectPackage)
    const resourceType         = useSelector(selectResourceType)
    const reminderAcknowledged = useSelector(selectReminderAcknowledged)
    const showReminder         = useSelector(selectShowReminder)
    const participants         = useSelector(selectParticipants)
    const duration             = useSelector(selectDuration)
    const date                 = useSelector(selectDate)
    const time                 = useSelector(selectTime)
    const paymentTypes         = useSelector(selectPaymentTypes)

    let step = useSelector(selectStep)
    if (step0 === true) { step = '0' }

    const [loaded, setLoaded]                       = useState(false)
    const [immediateFinalize, setImmediateFinalize] = useState(false)

    /**
     * Whenever the modal first loads, fetch the needed data to build out the modal
     * i.e. package, resource information etc.
     */
    useEffect(() => {
        if (!loaded) {
            setLoaded(true)

            // if the package has any data to
            // preload, this is where it does it
            dispatch(preloadBookingData(preloadedData))

            if (packageUrl) { dispatch(fetchPackage(packageUrl)) }
            dispatch(fetchResourceType(resourceTypeUrl))
            dispatch(fetchLocation(locationUrl))
            // this is just so we can put this url in the slice that uses it
            // instead of passing it around as props... the other initial URL's
            // are loaded when the app loads, however available dates are determined
            // by later input, not on app load...
            // we also have to do the same thing with available times URL
            dispatch(setAvailableDatesUrl(availableDatesUrl))
            dispatch(setAvailableTimesUrl(availableTimesUrl))
            dispatch(setHoldBookingUrl(holdBookingUrl))
            dispatch(setHoldTimeDurationMinutes(holdTimeDurationMinutes))
            dispatch(setTrackAbandonedUrl(trackAbandonedUrl))
            dispatch(setFinalizeBookingUrl(finalizeBookingUrl))
            dispatch(setReferralTypes(referralTypes))
            dispatch(setOccasionTypes(occasionTypes))
        }
    }, [dispatch, loaded])

    // wait for package id to be loaded before trying to load things that rely
    // on the package id e.g. customer types on the package
    useEffect(() => {
        if (pkg?.id) {
            dispatch(fetchCustomerTypes(customerTypesUrl))

            // if authorize-only is not enabled and full amount is not enabled,
            // then automatically select desposit_only on load so that the customer
            // sees the right price calculated for them. Otherwise, select full_amount
            // because either it's the only option available or we want to default to it
            // when the customer can choose between full and deposit only.
            if (!paymentTypes.authorize_card_only) {
                if (!paymentTypes.full_amount && paymentTypes.deposit_amount) {
                    dispatch(setPaymentType('deposit_only'))
                } else {
                    dispatch(setPaymentType('full_amount'))
                }
            }
        }
    }, [dispatch, pkg])

    /**
     * Show reminder if necessary on step 1
     */
    useEffect(() => {
        if (
            loaded
            && pkg?.is_special_event
            && (!pkg?.special_event_is_publicly_bookable || pkg.special_event_is_sold_out)
        ) {
            dispatch(setStep('unbookable'))
        }

        if (
            loaded
            && /[1]/.test(step)
            && participants
            && (duration && date && time)
            && reminderAcknowledged === null
            && resourceType?.show_reminder_modal
        ) {
            dispatch(setReminderAcknowledged(false))
        }
    }, [dispatch, pkg, loaded, step, resourceType, participants, duration, date, time, reminderAcknowledged])

    const handleNext = () => {
        /**
         * Show reminder if necessary on steps earlier than step 3
         */
        if (
            participants
            && (duration || date || time)
            && reminderAcknowledged === false
        ) {
            dispatch(setShowReminder(true))
        }

        /**
         * Skip from step 1 > (4 or 5)
         * if we have enough preloaded data
         */
        if (
            loaded
            && /[1]/.test(step)
            && (reminderAcknowledged === null || reminderAcknowledged === true)
            && participants
            && duration
            && date
            && time
        ) {
            setImmediateFinalize(true)
            dispatch(setStep('3'))
        }
    }

    const renderStep = () => {
        switch(step) {
            case 'confirmation':
                return <Confirmation />

            case 'unbookable':
                if (pkg.special_event_is_sold_out) {
                    return <UnbookableError modalStyles={modalStyles} text={`This ${pkg.is_a_league ? 'league' : 'package'} has been sold out.`} />
                } else {
                    return <UnbookableError modalStyles={modalStyles} />
                }

            case '6':
                return <Step6 modalStyles={modalStyles} environment={environment} />

            case '5':
                return <Step5 modalStyles={modalStyles} />

            case 'members':
                return <Members modalStyles={modalStyles} />

            case '4':
                return <Step4 modalStyles={modalStyles} />

            case '3':
                return <Step3 modalStyles={modalStyles} immediateFinalize={immediateFinalize} />

            case '2':
                return <Step2 modalStyles={modalStyles} immediateFinalize={immediateFinalize} onNext={handleNext} />

            case '1':
                return <Step1 modalStyles={modalStyles} onNext={handleNext} />

            case '0':
                return (
                  <Step0
                    locationUrl={locationUrl}
                    resourceTypeUrl={resourceTypeUrl}
                    referralTypes={referralTypes}
                    occasionTypes={occasionTypes}
                    modalStyles={modalStyles}
                    packageGroup={packageGroup}
                    packages={packages}
                  />
                )
        }
    }

    return <>
        <div id="booking-modal-body" className={`xbm-inner font-${modalStyles.font}`}>
            <BookingModalHeader />
            { !/0/.test(step) && <MobileProgressHeader /> }
            { !/^(0|confirmation)?$/i.test(step) && <ReminderModal /> }
            { renderStep() }
        </div>

        <BookingModalStyles modalStyles={modalStyles} />
    </>
}
