import React from 'react'
import { SelectResource } from './SelectResource'
import ToggleDay from './ToggleDay'
import ManualBuffer from './ManualBuffer'
import RecentlyViewed from './RecentlyViewed'

export default function Toolbar({ location }) {
    return (
        <div id="calendar-toolbar">
            <SelectResource />
            <ToggleDay />

            <div className="interface-customization">
                <div>
                    <ManualBuffer />
                    <RecentlyViewed location={location}/>
                </div>
            </div>

        </div>
    )
}
