import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ToolbarSearch from '../CalendarSearch/ToolbarSearch'
import { selectLocation } from '../Locations/locationSlice'
import { selectResourceType, selectResourceTypes, setResourceType } from '../ResourceType/resourceTypeSlice'
import { selectOpen as selectScheduleDrawerOpen } from '../Schedule/scheduleSlice'
import { selectOpen as selectSearchOpen } from '../CalendarSearch/calendarSearchSlice'
import { setPackages } from '@/features/Packages/packageSlice'
import { selectInitialLoadComplete } from '@/features/Calendar/calendarSlice'
import { debug } from '@/lib/Debug'

export function _SelectResource() {

    const dispatch             = useDispatch()
    const calendarLoaded       = useDispatch(selectInitialLoadComplete)
    const location             = useSelector(selectLocation)
    const resourceTypes        = useSelector(selectResourceTypes)
    const selectedResourceType = useSelector(selectResourceType)
    const scheduleDrawerOpen   = useSelector(selectScheduleDrawerOpen)
    const searchDrawerOpen     = useSelector(selectSearchOpen)

    const [resourceTypesWithResources, setResourceTypesWithResources] = useState([])

    const sessionStorageKey   = `da-location-${location?.id}-resource-type`
    const sessionResourceType = window.localStorage.getItem(sessionStorageKey)

    const handleChange = e => {
        const value = Number.parseInt(e.target.value)
        window.localStorage.setItem(sessionStorageKey, value)
        dispatch(setResourceType(value))
        dispatch(setPackages([]))
    }

    /**
     * Select a resource type on initial load
     */
    useEffect(() => {
      if (calendarLoaded && !selectedResourceType && location && resourceTypesWithResources && resourceTypesWithResources.length > 0) {
          if (sessionResourceType) {
              // select the previously known/selected resource type of the session
              dispatch(setResourceType(Number.parseInt(sessionResourceType)))
          } else {
              // or default to selecting the first resource type in the list of resource types
              dispatch(setResourceType(resourceTypesWithResources[0].id))
              window.localStorage.setItem(sessionStorageKey, resourceTypesWithResources[0].id)
          }
      }
    }, [calendarLoaded, resourceTypes, location, resourceTypesWithResources])

    useEffect(() => {
        setResourceTypesWithResources(resourceTypes.filter(resourceType => resourceType.resources_count > 0))
    }, [resourceTypes])

    return resourceTypesWithResources && resourceTypesWithResources.length === 0 ? null : (
        <div className="resource-selection">
            <div>
                {
                    resourceTypesWithResources.length > 1 ? (
                        <select
                            className="custom-select"
                            value={selectedResourceType || ''}
                            disabled={scheduleDrawerOpen || searchDrawerOpen}
                            style={{ fontSize: '93%' }}
                            onChange={handleChange}
                        >
                            <option value="" disabled>Select One...</option>
                            {
                                resourceTypesWithResources.map(type => (
                                    <option key={type.id} value={type.id}>
                                        { debug && `(${type.id}) ` }
                                        { type.title }
                                    </option>
                                ))
                            }
                        </select>
                    ) : (
                        <strong
                            className="py-1 d-inline-block w-100"
                            style={{ fontSize: '93%', lineHeight: '1.2', overflowWrap: "break-word" }}
                            children={resourceTypesWithResources[0].title }
                        />
                    )
                }

                <ToolbarSearch />
            </div>
        </div>
    )
}

export const SelectResource = React.memo(_SelectResource)
