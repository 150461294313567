import React from 'react'
import { numberToCurrency } from '@/lib/Number'

export default function ExpectedPricing({
    sectionTitle='Expected Pricing',
    sectionSubTitle='',
    basePriceCents=null,
    price:_price=0,
    taxRate:_taxRate=0,
    additionalItems=[],
    priceIncludesSalesTax=false,
    className='',
}) {

    let price    = Number.parseFloat(_price)
    let taxRate  = Number.parseFloat(_taxRate) / 100
    let salesTax = 0
    let subTotal = 0

    price   = isNaN(price)   ? 0 : price
    taxRate = isNaN(taxRate) ? 0 : taxRate

    if (priceIncludesSalesTax) {
        // Subtractive Sales Tax Formula
        // for 7.5% sales tax
        // $20 - ($20 / (1 + (7.5/100))) = $1.3953488372 (sales tax only)
        // $20 - $1.3953488372 = $18.6046511628 (product price only)
        // -----------------------------------------------
        // $20 = $18.6046511628 + $1.3953488372 (price with sales tax included)
        salesTax = price - (price / (1 + taxRate))
        subTotal = price - salesTax
    } else {
        // Additive Sales Tax Formula
        // for 7.5% sales tax
        // $20 * (7.5/100) = $1.5 (sales tax only)
        // $20 + $1.5 = $21.50 (price plus sales tax)
        salesTax = price * taxRate
        subTotal = price
    }

    const total = subTotal + salesTax

    return <div className={`expected-pricing ${className}`}>
        <h6 className={sectionSubTitle ? 'mb-0' : ''}>{sectionTitle}</h6>
        { sectionSubTitle && <small className='m-0 pb-2 text-secondary font-italic'>{ sectionSubTitle }</small> }

        <table className="table table-sm border-top mt-2 pt-2">
          <colgroup>
              <col width="auto" />
              <col width="auto" />
          </colgroup>
          <tbody>
              {
                  !!basePriceCents && (
                      <tr>
                          <td className="border-0">Base {priceIncludesSalesTax ? 'Target' : ''} Price:</td>
                          <td className="border-0 text-right">{numberToCurrency((!isNaN(basePriceCents) ? basePriceCents : 0) / 100)}</td>
                      </tr>
                  )
              }
              {
                  !!additionalItems && Array.isArray(additionalItems) && additionalItems.map((item, index) => (
                      <tr key={`additional-item-${index}`}>
                          <td className="border-0">
                              — {item?.name || item?.modifierName || '???'}:
                          </td>
                          <td className="border-0 text-right">
                              {numberToCurrency((item?.amount_cents || item?.modifierAmountCents || 0) / 100)}
                          </td>
                      </tr>
                  ))
              }
              <tr>
                  <td className="border-0"><strong>Subtotal:</strong></td>
                  <td className="border-0 text-right"><strong>{numberToCurrency(!isNaN(subTotal) ? subTotal : 0)}</strong></td>
              </tr>
              <tr>
                  <td><em>Sales Tax:</em></td>
                  <td className='text-right'><em>{numberToCurrency(!isNaN(salesTax) ? salesTax : 0)}</em></td>
              </tr>
              <tr>
                  <td><strong>TOTAL:</strong></td>
                  <td className='text-right'><strong>{numberToCurrency(!isNaN(total) ? total : 0)}</strong></td>
              </tr>
          </tbody>
        </table>
    </div>
}
