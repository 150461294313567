import React from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { selectBooking } from "@/features/EditBooking/editBookingSlice"
import { selectPackage } from '@/features/Packages/packageSlice'
import { selectTimeZone } from "@/features/Locations/locationSlice"
import { isBookingQuote, isQuoteExpiredOrRejected, isBookingComplete, isBookingCancelled } from '@/lib/Booking'
import { debug } from '@/lib/Debug'

export default function BasicInfo({ newBooking=false, handleViewParent, handleCancel, handleReactivate}) {

    const booking          = useSelector(selectBooking)
    const pkg              = useSelector(selectPackage)
    const selectedTimeZone = useSelector(selectTimeZone)

    return <>
        {
            (
                (booking?.reservation_number && !isBookingComplete(booking) && !pkg.is_special_event)
                    ||
                (booking?.reservation_number && pkg.is_special_event && !isBookingCancelled(booking) && !isBookingComplete(booking))
            ) && (
                <div className="drawer-booking-ledger-section">
                    <div className="d-flex">
                        {
                            isBookingQuote(booking) ? (
                                !isQuoteExpiredOrRejected(booking) ? (
                                    <button
                                        type="button"
                                        className="btn btn-link btn-link-danger py-0 px-2 ml-auto"
                                        onClick={handleCancel}
                                    >
                                        Reject Quote
                                    </button>
                                ) : <div className='py-2'>&nbsp;</div>
                            ) : (
                                <button
                                    type="button"
                                    className="btn btn-link btn-link-danger py-0 px-2 ml-auto"
                                    onClick={isBookingCancelled(booking) ? handleReactivate : handleCancel}
                                >
                                    { isBookingCancelled(booking) ? 'Reactivate' : 'Cancel'} Reservation
                                </button>
                            )
                        }
                    </div>
                </div>
            )
        }

        {
            !newBooking && booking?.is_child && booking?.parent?.id && booking?.parent?.reservation_number && (
                <div className="drawer-booking-ledger-section">
                    <table>
                        <tbody>
                            {
                                debug && (
                                    <tr>
                                        <th>ID:</th>
                                        <td>{booking.parent.id}</td>
                                    </tr>
                                )
                            }
                            <tr>
                                <th>Special Event:</th>
                                <td>
                                    <button className='btn btn-link p-0 bold' style={{ minHeight: 0 }} onClick={handleViewParent}>
                                        { booking.parent.reservation_number }
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )
        }

        {
            !newBooking && (
                <div className="drawer-booking-ledger-section">
                    <table>
                        <tbody>
                            {
                                debug && (
                                    <tr>
                                        <th>ID:</th>
                                        <td>{booking?.id || '---'}</td>
                                    </tr>
                                )
                            }
                            <tr>
                                <th>{isBookingQuote(booking) ? 'QUOTE' : 'RESERVATION'}:</th>
                                <td>{booking?.reservation_number || '---'}</td>
                            </tr>
                            <tr>
                                <th>{isBookingQuote(booking) ? 'Quoted' : 'Booked'} On:</th>
                                <td>{(booking && moment(booking.created_at).tz(selectedTimeZone).format('M/D/YYYY h:mm A z')) || '---'}</td>
                            </tr>
                            {
                                booking?.quote_expires_at && (
                                    <tr>
                                        <th className="text-danger">
                                            Expires On:
                                        </th>
                                        <td className="text-danger">
                                            {(booking && !booking.is_quote_expired && moment(booking.quote_expires_at).tz(selectedTimeZone).format('M/D/YYYY h:mm A z')) || '---'}
                                        </td>
                                    </tr>
                                )
                            }
                            {
                                booking.is_quote_expired && booking.quote_expired_at && (
                                    <tr>
                                        <th className="text-danger">Expired On:</th>
                                        <td className="text-danger">
                                            {(booking && booking.is_quote_expired && moment(booking.quote_expired_at).tz(selectedTimeZone).format('M/D/YYYY h:mm A z')) || '---'}
                                        </td>
                                    </tr>
                                )
                            }
                            {
                                booking?.quote_accepted_at && (
                                    <tr>
                                        <th className='text-success'>Accepted On:</th>
                                        <td className='text-success'>{(booking && moment(booking.quote_accepted_at).tz(selectedTimeZone).format('M/D/YYYY h:mm A z')) || '---'}</td>
                                    </tr>
                                )
                            }
                            {
                                /rejected/i.test(booking?.quote_status) && (
                                    <tr>
                                        <th>Rejected On:</th>
                                        <td>{(booking && moment(booking.updated_at).tz(selectedTimeZone).format('M/D/YYYY h:mm A z')) || '---'}</td>
                                    </tr>
                                )
                            }
                            <tr>
                                <th>Created By:</th>
                                <td>{(booking && (booking.user_name || 'CUSTOMER')) || '---'}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )
        }

        <div className={`drawer-booking-ledger-section ${newBooking ? 'pt-4' : ''}`}>
            <table>
                <tbody>
                    <tr>
                        <th className="align-top">Package</th>
                        <td className="align-top">
                            <p className='p-0 m-0'>{ booking ? pkg.name : '———' }</p>
                            { booking?.parent_booking && <p className='p-0 m-0 mt-1'><span className='badge badge-secondary py-1'>grouped</span></p> }
                            { pkg?.allow_members_only && <p className='p-0 m-0 mt-1'><span className='badge badge-secondary py-1'>members only</span></p> }
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </>
}
