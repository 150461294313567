import React from 'react'
import Input from '@/components/Form/Input'
import FormRow from '@/components/Form/FormRow'
import TextArea from '@/components/Form/TextArea'
import Select from '@/components/Form/Select'

export default function ItemDiscountsForm({ handleUpdate, form, errors }) {
    return (
        <div>
            <FormRow>
                <Input
                    name='name'
                    value={form.name}
                    errors={errors}
                    validation={{ 'name': { required: true } }}
                    handleChange={handleUpdate} />
            </FormRow>

            <FormRow>
                <TextArea
                    name='description'
                    value={form.description}
                    rows={5}
                    handleChange={handleUpdate} />
            </FormRow>

            <FormRow>
                <Select
                    cols={6}
                    name='amount_type'
                    value={form.amount_type}
                    handleChange={handleUpdate}
                    options={[
                        { display: 'Percentage %', value: 'percent' },
                        { display: 'Dollar $', value: 'dollar' }
                    ]}
                    validation={{ amount_type: { required: true } }} />

                <Input
                    cols={6}
                    name='amount'
                    type='number'
                    min={0}
                    step={1}
                    value={form.amount}
                    errors={errors}
                    validation={{ amount: { required: true } }}
                    handleChange={handleUpdate} />
            </FormRow>
        </div>
    )
}
