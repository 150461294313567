import React, { useCallback, useEffect, useState } from 'react'
import Input from '@/components/Form/Input'
import FormRow from '@/components/Form/FormRow'
import RichTextEditor from '@/components/Form/RichTextEditor'
import { titleize } from 'inflected'

export default function AdditionalCustomEmailFields({
  emailName=null,
  content={},
  errors,
  onChange=() => {}
}) {
    const [tmpContent, setContent] = useState({})

    const handleChange = (name, value) => {
        const updatedContent = {...content}

        // ignore the first part with the
        // slice() which is the email name
        const parts = name.split('.').slice(1)

        // build levels if they don't exist yet
        if (!updatedContent?.[parts[0]]) { updatedContent[parts[0]] = {} }
        if (!updatedContent?.[parts[0]]?.[parts[1]]) { updatedContent[parts[0]][parts[1]] = {} }

        updatedContent[parts[0]][parts[1]] = value

        setContent(updatedContent)
    }

    useEffect(() => {
        if (Object.values(tmpContent).length > 0) {
            onChange(tmpContent)
        }
    }, [tmpContent])

    const generatePromotion = useCallback((name='promotion') => (
        <FormRow key={name} className='pt-3 px-2'>
            <Input
                cols='3'
                label='Promotion Code'
                req={false}
                placeholder=' '
                value={content?.[name]?.code || ''}
                validation={{ [`${emailName}_${name}_code`]: { required: false } }}
                errors={errors}
                handleChange={(e) => handleChange(`${emailName}.${name}.code`, e.currentTarget.value)}
            />
            <Input
                cols='3'
                label='Promotion Amount'
                append={true}
                req={false}
                type='number'
                min='0'
                placeholder=' '
                value={content?.[name]?.amount || ''}
                validation={{ [`${emailName}_${name}_amount`]: { required: false } }}
                errors={errors}
                handleChange={(e) => handleChange(`${emailName}.${name}.amount`, e.currentTarget.value)}
            >
                <div className="input-group-append">
                    <span className="input-group-text">%</span>
                </div>
            </Input>
            <Input
                cols='6'
                label='"Use Promo Code Here" button link'
                req={false}
                placeholder=' '
                value={content?.[name]?.button_link || ''}
                validation={{ [`${emailName}_${name}_button_link`]: { required: false } }}
                errors={errors}
                handleChange={(e) => handleChange(`${emailName}.${name}.button_link`, e.currentTarget.value)}
            />
        </FormRow>
    ), [content, errors])

    const generateTestimonial = useCallback((name='testimonial') => (
        <FormRow key={name} className='px-2'>
            <RichTextEditor
                cols='12 mt-3 mb-n2'
                label={titleize(name)}
                req={false}
                value={content?.[name]?.text || ''}
                validation={{ [`${emailName}_${name}_text`]: { required: false }}}
                errors={errors}
                handleChange={(f,v) => handleChange(`${emailName}.${name}.text`, v)}
            />
            <Input
                cols='7 offset-5'
                hideLabel={true}
                prepend={true}
                req={false}
                placeholder=' '
                value={content?.[name]?.citation || ''}
                validation={{ [`${emailName}_${name}_citation`]: { required: false }}}
                errors={errors}
                handleChange={(e) => handleChange(`${emailName}.${name}.citation`, e.currentTarget.value)}
            >
                <div className="input-group-prepend">
                    <span className="input-group-text">Citation</span>
                </div>
            </Input>
        </FormRow>
    ), [content, errors])

    return !!emailName && <>
        {
            /^retargeting_email_1$/i.test(emailName) && <>
                { generateTestimonial() }
            </>
        }

        {
            /^retargeting_email_2$/i.test(emailName) && <>
                { generatePromotion() }
                { generateTestimonial() }
            </>
        }

        {
            /^retargeting_email_3$/i.test(emailName) && <>
                { generatePromotion() }
                { [1,2,3].map((index) => generateTestimonial(`testimonial_${index}`)) }
            </>
        }
    </>
}
