export default null

export const LOAD_SPECIFIC_DATE        = 'load_date'
export const USE_DARK_MODE             = 'useDarkMode'
export const SELECTED_COMPANY          = 'company-id'
export const SELECTED_LOCATION         = 'location-id'
export const SELECTED_TERMINAL         = 'pbax-terminal'
export const SELECTED_TERMINAL_ADYEN   = 'adyen-terminal'
export const ADYEN_TERMINAL_SALE_ID    = 'adyen-terminal-sale-id'
export const ADYEN_TERMINAL_SERVICE_ID = 'adyen-terminal-service-id'
export const CURRENT_BOOKING_ID        = 'current_booking_id'
export const CURRENT_PARENT_BOOKING_ID = 'current_parent_booking_id'
export const IS_BOOKING_OPEN           = 'is_booking_open'
export const IS_PARENT_BOOKING_OPEN    = 'is_parent_booking_open'
export const IS_SOCKET_CONNECTED       = 'socket_connected'
export const PREVENT_LOADER            = 'prevent_loader'
export const IS_SCROLLING              = 'is_scrolling'
export const CREATE_RESERVATION        = 'create-reservation'
export const PACKAGE_GROUP_STEP0       = 'package-group-step0'
export const ADV_POS_SESSION           = 'adv_pos_session'
export const DEVICE_UUID               = 'device_uuid'
