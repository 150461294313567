import React, { useState } from 'react'
import axios from "axios";
import { selectBalanceDue, setDiscount } from '../giftCardSlice'
import { useSelector, useDispatch } from 'react-redux'

export default function PromoCode({ companyId, locationId }) {
  const dispatch = useDispatch()
  const balanceDue = useSelector(selectBalanceDue)

  const [promoCode, setPromoCode] = useState('')
  const [error, setError] = useState('')

  const applyPromoCode = () => {
    setError('')

    axios.get(`/companies/${companyId}/locations/${locationId}/gift_card_promotions/apply`, {
      params: { promo_code: promoCode }
    })
    .then(({ data }) => {
      if (data.success) {
        setPromoCode('')

        dispatch(setDiscount(data.gift_card_promotion))
      } else {
        setError(data.message)
      }
    })
  }

  return (
    <div className="input-group mb-4">
      <input
        name="promoCode"
        type="text"
        id="promo_code"
        className={`text-monospace form-control ${error ? 'is-invalid' : ''}`}
        placeholder="Promo Code"
        value={promoCode}
        disabled={balanceDue === 0}
        onKeyPress={e => {
          if (e.key === 'Enter' && e.target.id === 'promo_code') {
            applyPromoCode()
          }
        }}
        onChange={e => setPromoCode(e.target.value)}
      />

      <div className="input-group-append">
        <button
          type="button"
          className="btn btn-primary"
          disabled={balanceDue === 0 || promoCode.length === 0}
          onClick={applyPromoCode}
        >
          Apply
        </button>
      </div>

      {error && <div className="invalid-feedback d-block mx-2">{error}</div>}
    </div>
  )
}
