import React from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { selectTimeZone } from '@/features/Locations/locationSlice'

import {
  selectDate,
  selectViewedDate,
  setDate,
  setViewedDate,
  setSelectedBookingTopOffset,
  setShouldCenterCalendar
} from '@/features/Calendar/calendarSlice'

export default function ToggleDay() {

    const dispatch   = useDispatch()
    const date       = useSelector(selectDate)
    const timezone   = useSelector(selectTimeZone)
    const viewedDate = useSelector(selectViewedDate)

    const format = 'YYYY-MM-DD'

    const handleDateChange = (datetime) => {
        const newDate = datetime.format(format)
        dispatch(setDate(newDate))
        dispatch(setViewedDate(newDate))
    }

    const handlePreviousDay = () => {
        const datetime = moment.tz(date, timezone).clone().subtract('1', 'day')
        dispatch(setSelectedBookingTopOffset(null))
        handleDateChange(datetime)
    }

    const handleNextDay = () => {
        const datetime = moment.tz(date, timezone).clone().add('1', 'day')
        dispatch(setSelectedBookingTopOffset(null))
        handleDateChange(datetime)
    }

    const handleToday = () => {
        dispatch(setSelectedBookingTopOffset(null))
        dispatch(setShouldCenterCalendar(true))

        if (date !== moment().tz(timezone).format(format)) {
            handleDateChange(moment().tz(timezone))
        }
    }

    return (
        <div className="toggle-day">
            <div>
                <button title="View Previous Day" className="btn btn-link bold" onClick={handlePreviousDay}>
                    <i className="far fa-chevron-left h5 mb-n1" />
                </button>

                <button className="btn btn-link bold" onClick={handleToday}>
                    <span className="d-none d-lg-inline mt-1">TODAY</span>
                    <i className="fas fa-circle d-lg-none h5 mb-n1" />
                </button>

                <button title="View Next Day" className="btn btn-link bold" onClick={handleNextDay}>
                    <i className="far fa-chevron-right h5 mb-n1" />
                </button>
            </div>
        </div>
    )
}
