import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { addAlert } from '@/features/Notifications/notificationSlice'

import {
    configureModal,
    selectModals,
    updateTokenizedCardOnTab,
    cancelTransaction,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import CenteredText from '@/features/AdvancedPointOfSale/components/CenteredText'
import StoredCreditCard from '@/features/AdvancedPointOfSale/components/StoredCreditCard'
import Modal from '@/features/AdvancedPointOfSale/components/Modal'
import { useConfirm } from '@/lib/useConfirmHook'

export default function EditCardOnTabModal() {

    const dispatch                = useDispatch()
    const { confirm }             = useConfirm()
    const { editCardOnTab:modal } = useSelector(selectModals)

    const cardRemovalAction = modal?.storedCard
        ? updateTokenizedCardOnTab(modal.tab.id, 'remove')
        : modal?.preAuthorizedTransaction?.id
            ? cancelTransaction(modal.checkId, modal.preAuthorizedTransaction.id, 'card-on-file')
            : null

    const handleClose = () => {
        dispatch(configureModal({
            modal: 'editCardOnTab',
            config: {
                isOpen: false,
                checkId: null,
                tab: null,
                storedCard: null,
                preAuthorizedTransaction: null,
            },
        }))
    }

    const handleRemove = async () => {
        if (await confirm('Are you sure you want to remove this card?', { dangerous: true })) {
            if (cardRemovalAction !== null) {
                dispatch(cardRemovalAction).then((data) => {
                    if (data.success) {
                        handleClose()
                    } else {
                        if (!!modal.preAuthorizedTransaction?.id) {
                            dispatch(addAlert({ type: 'error', text: 'Could not remove the card on file'}))
                        }
                    }
                })
            } else {
                dispatch(addAlert({ type: 'error', text: 'Could not determine how to remove card (ECOTM-HR-1)'}))
            }
        }
    }

    const handleReplace = async () => {
        if (await confirm(
            '<p>Are you sure you want to replace this card?</p>' +
            '<p>The existing card will be removed first even if a new card is unable to be stored.</p>', { dangerous: true }
        )) {
            if (cardRemovalAction !== null) {
                await dispatch(cardRemovalAction).then((data) => {
                    if (data.success) {
                        dispatch(configureModal({
                            modal: 'addCardToTab',
                            config: { isOpen: true, tab: modal.tab },
                        }))
                        handleClose()
                    } else {
                        if (!!modal.preAuthorizedTransaction?.id) {
                            dispatch(addAlert({ type: 'error', text: 'Could not remove the card on file'}))
                        }
                    }
                })
            } else {
                dispatch(addAlert({ type: 'error', text: 'Could not determine how to remove card (ECOTM-HR-2)'}))
            }
        }
    }

    return modal.isOpen && (
        <Modal
            className='edit-card-on-tab-modal'
            title='Edit Card On Tab'
            isOpen={modal.isOpen}
            size='modal-lg'
            footerButtons={<>
                <button
                    children='Close'
                    type='button'
                    className='btn btn-link text-bold'
                    onClick={handleClose}
                />
            </>}
            onClose={handleClose}
        >
            {
                (!!modal.storedCard || !!modal.preAuthorizedTransaction) ? (
                    <div className="p-4 mt-4 d-flex align-items-center">
                        <StoredCreditCard
                            transactionId={modal?.preAuthorizedTransaction?.id}
                            card={modal?.storedCard || modal?.preAuthorizedTransaction?.card}
                            iconSize='fa-2x'
                            showPreAuthorizedAmount
                        />

                        <button
                            children='Remove'
                            type='button'
                            className='btn btn-link-danger py-0 px-3 ml-auto mr-2'
                            onClick={handleRemove}
                        />

                        <button
                            children='Replace'
                            type='button'
                            className='btn btn-primary py-0 px-3'
                            onClick={handleReplace}
                        />
                    </div>
                ) : (
                    <CenteredText
                        text='No Card On File'
                        textClassName='text-gray4 h5 mt-2 py-5'
                    />
                )
            }
        </Modal>
    )
}
