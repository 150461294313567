import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UI } from '@/lib/Constants'

import {
    selectOpenModal,
    closeBookingHistoryModal,
    openBookingHistoryModal,
} from '../BookingHistory/bookingHistorySlice'

import BookingHistoryEmails from '../BookingHistory/BookingHistoryEmails'
import { selectBooking } from './editBookingSlice'
import { selectLocation } from '@/features/Locations/locationSlice'

import BookingHistoryAddonTime from './BookingHistoryAddonTime'
import BookingHistoryChangeLog from './BookingHistoryChangeLog'
import BookingHistoryTabs from './BookingHistoryTabs'
import BookingHistoryPayments from '../BookingHistory/BookingHistoryPayments'
import GenerateInvoice from '@/features/EditBooking/GenerateInvoice'
import { numberToCurrency } from '@/lib/Number'

const tabs = [
    ['payments', 'Payments & Refunds'],
    ['addonTime', 'Addon Time'],
    ['emails', 'Emails'],
    ['changeLog', 'Changelog'],
]

export default function BookingHistoryModal({ openHistory }) {

    const dispatch  = useDispatch()
    const open      = useSelector(selectOpenModal)
    const booking   = useSelector(selectBooking)
    const location  = useSelector(selectLocation)

    const [currentTab, setCurrentTab] = useState('payments')

    const handleKeyDown = (e) => {
        if (
            e.key === 'Escape'
            && !document.body.classList.contains(UI.classes.CONFIRMATION_OPEN)
            && !document.body.classList.contains(UI.classes.HELPJUICE_OPEN)
        ) {
            dispatch(closeBookingHistoryModal())
        }
    }

    /**
     * Handle the initial mount and
     * unmount of the component
     */
    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown, false)

        if (open) {
            document.body.classList.add(UI.classes.MULTIPLE_MODALS_OPEN)
        }

        // cleanup/reset on unmount
        return () => {
            document.body.classList.remove(UI.classes.MULTIPLE_MODALS_OPEN)
            document.removeEventListener('keydown', handleKeyDown, false)
        }
    }, [open])

    useEffect(() => {
        if (openHistory) {
            dispatch(openBookingHistoryModal())
        }
    }, [openHistory])

    const renderTab = () => {
        switch (currentTab) {
            case 'changeLog':
                return <BookingHistoryChangeLog />

            case 'emails':
                return <BookingHistoryEmails />

            case 'addonTime':
                return <BookingHistoryAddonTime />

            case 'payments':
                return <BookingHistoryPayments />
        }
    }

    return open && booking ? (
        <div className="modal modal-backdrop modal--xxxl booking-history--modal">
            <div className="modal-container">
                <div className="modal-header">
                    <div className="modal-title">Reservation History</div>

                    <BookingHistoryTabs
                        tabs={tabs}
                        current={currentTab}
                        onChange={setCurrentTab}
                    />

                    <button className="modal-close mb-auto" onClick={() => dispatch(closeBookingHistoryModal())}>
                        <span>Close</span>
                        <i className="far fa-times"></i>
                    </button>
                </div>

                <div className="container border-bottom">
                    <div className="row justify-content-center center my-3 px-3 smaller">
                        <div className="col-6 col-lg-3 pl-lg-2 pb-3 pb-lg-0 text-left">
                            <strong><span className="text-gray3 mr-2">RESERVATION NUMBER</span><br />{booking.reservation_number}</strong>
                        </div>
                        <div className="col-6 col-lg-3 pl-lg-2 pb-3 pb-lg-0 text-left">
                            <strong><span className="text-gray3 mr-2">RESERVATION NAME</span><br />{booking.name}</strong>
                        </div>
                        <div className="col-12 col-lg-3 pl-lg-2 pb-3 pb-lg-0 text-left">
                            <strong>
                                <span className="text-gray3 mr-2 d-block">RESERVATION BALANCE</span>
                                <span className="mr-2">{numberToCurrency(booking.price_cents / 100)}</span>
                                <span className={booking.balance_cents / 100 === 0 ? 'text-success' : 'text-danger'}>
                                  ({
                                    booking.balance_cents !== 0
                                        ? `${numberToCurrency(booking.balance_cents / 100)} Remaining`
                                        : 'Fully Paid'
                                  })
                                </span>
                            </strong>
                        </div>
                        <div className="col-12 col-lg-3 pl-lg-0 pr-lg-2 pt-lg-1 pb-3 pb-lg-0">
                            {!!booking.check ?
                                <a
                                    children='View POS Transaction History'
                                    href={`/companies/${location.company_id}/locations/${location.id}/pos/checks/${booking.check.check_number}`}
                                    target="_blank"
                                    className="btn btn-outline text-white w-100 text-nowrap px-4"
                                />
                            :
                                <GenerateInvoice />
                            }
                        </div>
                    </div>
                </div>

                <div className="modal-body">
                    <div className="modal-body-main">
                        <div className="modal-body-main-section">
                            { renderTab() }
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                </div>
            </div>
        </div>
    ) : null
}
