import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { addAlert } from '@/features/Notifications/notificationSlice'
import { PREVENT_LOADER, ADYEN_TERMINAL_SALE_ID, ADYEN_TERMINAL_SERVICE_ID } from '@/lib/Storage'
import { SecureHash } from '@/lib/Crypto'
import { debug } from '@/lib/Debug'

const giftCardTemplate = {
    amount: 0,
    email: '',
    purchaserName: '',
    customNote: '',
    sendAtDate: '',
    sendAtTime: '',
    sendOnFutureDate: false,
    useReceiptEmail: false,
    showPurchaserName: false,
    showCustomNote: false,
}

const calculateBalanceDue = (total, discount) => {
    if (total === 0) { return 0 }

    if (Object.keys(discount).length === 0) { return total }

    if (discount.amount_type === 'dollar') {
        if (discount.amount > total) {
            return 0
        } else {
            return total - discount.amount
        }
    } else {
        return total - (total * discount.amount / 100)
    }
}

export const giftCardSlice = createSlice({
    name: 'giftcard',
    initialState: {
        amounts: [],
        balanceDue: 0,
        quantity: 0,
        cart: [],
        creditToken: null,
        paymentType: 'credit',
        checkNumber: null,
        name: null,
        zip: null,
        receiptEmail: null,
        loading: false,
        isComplete: false,
        isCompleteFailure: false,
        isUnableToContinue: false,
        discount: {},
        defaultDiscount: {},
        total: 0,
        hasPromotionWithCode: false,
        changeLog: [],
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setComplete: (state, action) => {
            state.isComplete = true
        },
        setCompleteFailure: (state, action) => {
            state.isCompleteFailure = true
        },
        setUnableToContinue: (state, action) => {
            state.isUnableToContinue = true
        },
        setAmounts: (state, action) => {
            state.amounts = action.payload
        },
        setBalanceDue: (state, action) => {
            state.balanceDue = action.payload
        },
        setQuantity: (state, action) => {
            const amount = Number(action.payload)

            state.quantity = amount

            switch(true) {
                case amount > state.cart.length :
                    const amountToGenerate = amount - state.cart.length
                    const newCards = [...Array(amountToGenerate)].map((c) => giftCardTemplate)
                    state.cart = [...state.cart, ...newCards]
                    break

                case amount < state.cart.length :
                    state.cart = state.cart.slice(0, amount)
                    break
            }

            state.total = [...state.cart].reduce((balance, card) => {
                const amount = parseFloat(card.amount)

                if (isNaN(amount)) {
                    return balance
                } else {
                    return balance + amount
                }
            }, 0)

            state.balanceDue = calculateBalanceDue(state.total, state.discount)
        },
        setCart: (state, action) => {
            state.cart = action.payload
        },
        updateAmount: (state, action) => {
            state.cart[action.payload.index] = {
                ...state.cart[action.payload.index],
                amount: action.payload.amount,
            }

            state.total = [...state.cart].reduce((balance, card) => {
                const amount = parseFloat(card.amount)

                if (isNaN(amount)) {
                    return balance
                } else {
                    return balance + amount
                }
            }, 0)

            state.balanceDue = calculateBalanceDue(state.total, state.discount)
        },
        updateCart: (state, action) => {
            state.cart[action.payload.index] = {
                ...state.cart[action.payload.index],
                [action.payload.name]: action.payload.value,
            }
        },
        setCreditToken: (state, action) => {
            state.creditToken = action.payload
        },
        setPaymentType: (state, action) => {
            state.paymentType = action.payload
        },
        setCheckNumber: (state, action) => {
            state.checkNumber = action.payload
        },
        setName: (state, action) => {
            state.name = action.payload
        },
        setReceiptEmail: (state, action) => {
            state.receiptEmail = action.payload
        },
        setZip: (state, action) => {
            state.zip = action.payload
        },
        setDiscount: (state, action) => {
            state.discount = action.payload

            state.balanceDue = calculateBalanceDue(state.total, state.discount)
        },
        setDefaultDiscount: (state, action) => {
            state.defaultDiscount = action.payload

            state.discount = state.defaultDiscount
            state.balanceDue = calculateBalanceDue(state.total, state.discount)
        },
        setHasPromotionWithCode: (state, action) => {
            state.hasPromotionWithCode = action.payload
        },
        setHistory: (state, action) => {
            state.changeLog = action.payload.change_log
        },
    }
})

export const {
    setLoading,
    setAmounts,
    setBalanceDue,
    setQuantity,
    setCart,
    setCreditToken,
    setCreditPreProcessed,
    setCheckNumber,
    setName,
    setZip,
    setReceiptEmail,
    setPaymentType,
    setFormIsValid,
    setComplete,
    setCompleteFailure,
    setUnableToContinue,
    updateAmount,
    updateCart,
    setDiscount,
    setDefaultDiscount,
    setHasPromotionWithCode,
    setHistory
} = giftCardSlice.actions

export const selectLoading              = state => state.giftCard.loading
export const selectIsComplete           = state => state.giftCard.isComplete
export const selectIsCompleteFailure    = state => state.giftCard.isCompleteFailure
export const selectIsUnableToContinue   = state => state.giftCard.isUnableToContinue
export const selectBalanceDue           = state => state.giftCard.balanceDue
export const selectQuantity             = state => state.giftCard.quantity
export const selectCart                 = state => state.giftCard.cart
export const selectAmounts              = state => state.giftCard.amounts
export const selectCreditToken          = state => state.giftCard.creditToken
export const selectCheckNumber          = state => state.giftCard.checkNumber
export const selectName                 = state => state.giftCard.name
export const selectZip                  = state => state.giftCard.zip
export const selectReceiptEmail         = state => state.giftCard.receiptEmail
export const selectPaymentType          = state => state.giftCard?.paymentType
export const selectDiscount             = state => state.giftCard.discount
export const selectDefaultDiscount      = state => state.giftCard.defaultDiscount
export const selectTotal                = state => state.giftCard.total
export const selectHasPromotionWithCode = state => state.giftCard.hasPromotionWithCode
export const selectChangeLog            = state => state.giftCard.changeLog

export default giftCardSlice.reducer

export function submitPayment(url=null) {
    return async (dispatch, getState) => {
        try {
            const creditToken  = getState().giftCard.creditToken
            const balanceDue   = getState().giftCard.balanceDue
            const name         = getState().giftCard.name || ''
            const receiptEmail = getState().giftCard.receiptEmail || ''
            const zip          = getState().giftCard.zip || ''
            const cart         = getState().giftCard.cart
            const total        = getState().giftCard.total
            const discount     = getState().giftCard.discount
            const adyenPayment = getState().adyen.adyenPayment

            if (!url || (!creditToken && !adyenPayment) || !balanceDue || !cart || cart.length === 0 || !receiptEmail || !zip) {
                dispatch(addAlert({ type: 'error', text: 'Could not complete gift card purchase!' }))
                if (debug && console) { console.log(url, zip, receiptEmail, creditToken, adyenPayment, balanceDue, cart) }
                return
            }

            // temporarily disable the usual loading animation and immediately
            // display the one specific to paying for gift cards instead
            window.sessionStorage.setItem(PREVENT_LOADER, true)
            window.sessionStorage.setItem(ADYEN_TERMINAL_SALE_ID, SecureHash(10))
            window.sessionStorage.setItem(ADYEN_TERMINAL_SERVICE_ID, SecureHash(5))
            dispatch(setLoading(true))

            axios.post(url, {
                payload: window.btoa(JSON.stringify({
                    credit_token:           creditToken,
                    receipt_email:          receiptEmail,
                    name:                   name,
                    zip:                    zip,
                    balance_due:            balanceDue,
                    cart:                   cart,
                    discount:               total - balanceDue,
                    total:                  total,
                    gift_card_promotion_id: discount.id,
                    payment:                adyenPayment,
                    sale_id:                window.sessionStorage.getItem(ADYEN_TERMINAL_SALE_ID),
                    service_id:             window.sessionStorage.getItem(ADYEN_TERMINAL_SERVICE_ID),
                }))
            })
            .then(({ data }) => {
                if (data.success) {
                    if (debug && console) { console.log(data.message) }
                    dispatch(setComplete())
                    return
                }

                dispatch(setCompleteFailure())
                dispatch(addAlert({ type: 'error', text: data.message }))
            })
            .catch((error) => {
                if (debug && console) { console.error(error?.response?.data || error) }
                dispatch(setCompleteFailure())
                dispatch(addAlert({ type: 'error', text: (error?.response?.data?.message || 'Could not complete gift card purchase!') }))
            })
            .finally(() => {
                window.sessionStorage.removeItem(PREVENT_LOADER)
                window.sessionStorage.removeItem(ADYEN_TERMINAL_SALE_ID)
                window.sessionStorage.removeItem(ADYEN_TERMINAL_SERVICE_ID)
                dispatch(setLoading(false))
            })
        } catch(e) {
            if (debug && console) { console.error(e) }
            window.sessionStorage.removeItem(PREVENT_LOADER)
            window.sessionStorage.removeItem(ADYEN_TERMINAL_SALE_ID)
            window.sessionStorage.removeItem(ADYEN_TERMINAL_SERVICE_ID)
            dispatch(setLoading(false))
            dispatch(setCompleteFailure())
            dispatch(addAlert({ type: 'error', text: 'Could not complete gift card purchase!' }))
            return false
        }
    }
}

export function fetchHistory(id) {
    return async (dispatch) => {
        dispatch(setLoading(true))

        axios.get(`/gift_cards/${id}/history`).then(({ data }) => {
            dispatch(setHistory(data))
        }).catch(e => {
            console.warn(e)
        }).finally(() => {
            dispatch(setLoading(false))
        })
    }
}
