import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { setOpen } from '../../Schedule/scheduleSlice'
import { selectPackage } from '../../Packages/packageSlice'
import { selectBooking, updateStatus, sendBookingCancellationConfirmationEmail } from "../../EditBooking/editBookingSlice"
import { setAutoGratuityAmount, setAmountToPay, selectPaymentType, selectAmountToPay, selectCashChangeDue } from "../../BookingPayment/bookingPaymentSlice"
import BasicInfo from '@/features/Schedule/components/ledger/BasicInfo'
import ParticipantCosts from '@/features/Schedule/components/ledger/ParticipantCosts'
import ParticipantAdjustments from '@/features/Schedule/components/ledger/ParticipantAdjustments'
import BookingTotals from '@/features/Schedule/components/ledger/BookingTotals'
import BookingBalances from '@/features/Schedule/components/ledger/BookingBalances'
import PaymentLedgerBalancesTop from '@/features/BookingPayment/ledger/BalancesTop'
import PaymentLedgerBalancesBottom from '@/features/BookingPayment/ledger/BalancesBottom'
import { isBookingQuote } from '@/lib/Booking'
import { useConfirm } from '@/lib/useConfirmHook'

export default function BookingLedger({
    className='',
    newBooking=false,
    basePrice=0,
    autoGratuity=0,
    taxesAndFees=0,
    total=0,
    paid=0,
    remaining=0,
    tip=0,
    depositToApply=0,
    discounts=[],
    discountTotal=0,
    paymentMode=false,
    customerTypeTotals=[],
    members=[],
    memberPrice=null,
    showLedgerBreakdown=false,
    participants=0,
    handleViewParent
}) {

    const dispatch            = useDispatch()
    const formMethods         = useForm()
    const { confirm }         = useConfirm()
    const pkg                 = useSelector(selectPackage)
    const booking             = useSelector(selectBooking)
    const paymentType         = useSelector(selectPaymentType)
    const amountToPay         = useSelector(selectAmountToPay)
    const cashChangeDue       = useSelector(selectCashChangeDue)

    const [isGroupPricing, setIsGroupPricing] = useState(null)

    const paidToDate       = (paid || (booking?.amount_paid_cents / 100).toFixed(2))
    const remainingBalance = (remaining || (booking?.balance_cents / 100).toFixed(2))

    const reservationTotal = Number.parseFloat((
        booking?.base_price_cents
         + booking?.auto_gratuity_cents
         + booking?.taxes_cents
         + booking?.rkd_fees_cents
         + booking?.merchant_fees_cents
         + booking?.third_party_fees_cents
    ) / 100).toFixed(2)

    const balanceAfterPayment = (
        Number.parseFloat(remainingBalance)
        - Number.parseFloat(total || 0)
        + Number.parseFloat(depositToApply || 0)
    ).toFixed(2)

    const cancelReservation = () => {
        formMethods.reset()
        dispatch(updateStatus(isBookingQuote(booking) ? 'reject_quote': 'cancelled', true))
        dispatch(setOpen(false))
    }

    const handleCancel = async () => {
        let areYouSureSentence = `Are you sure you wish to ${isBookingQuote(booking) ? 'reject this quote' : 'cancel this reservation'}?`

        if (!isBookingQuote(booking)) {
            areYouSureSentence += '<br /><br />Payment will not be automatically refunded.'
        }

        if (await confirm(areYouSureSentence)) {
            if (!isBookingQuote(booking) && await confirm('Would you like to send a cancellation email to the customer?')) {
                cancelReservation()
                dispatch(sendBookingCancellationConfirmationEmail(booking.id))
            } else {
                cancelReservation()
            }
        }
    }

    const handleReactivate = async () => {
        if (await confirm(
            'Please ensure that the date and time are both still available before reactivating this reservation.<br /><br /><em class="h4">This may result in overlapping reservations on the calendar.</em>',
            { header_text: 'Are you sure?', dangerous: true, size: 'md', cancel_text: 'Cancel', confirm_text: 'Reactivate!' }
        )) {
            dispatch(updateStatus('reserved', true))
        }
    }

    /**
     * This is the amount the customer is currently
     * choosing to pay via the modal (partial or full).
     * We track it in redux because the various payment
     * type tabs reference and update it in the payment modal.
     */
    useEffect(() => {
        dispatch(setAutoGratuityAmount(autoGratuity))

        dispatch(setAmountToPay( (
            Number.parseFloat(total)
            + Number.parseFloat(tip || 0)
            - Number.parseFloat(depositToApply || 0)
        ).toFixed(2)))
    }, [pkg, booking, total, autoGratuity, tip, depositToApply])

    /**
     * If we have customer types, loop through them and determine if
     * we need to display group pricing variants in any of the sub-components
     */
    useEffect(() => {
        if (!!customerTypeTotals && !!customerTypeTotals?.types?.length) {
            customerTypeTotals.types.forEach((t) => {
                setIsGroupPricing(t.isGroup)
            })
        }
    }, [customerTypeTotals])

    return (
        <div className={`drawer-booking-ledger ${className}`.trim()}>
            {/*
                !paymentMode && booking?.drawer_open && booking?.drawer_user != userName && (
                    <div className="alert alert-danger mb-2" style={{ borderBottom: '5px solid indianred', borderRadius: '5px' }}>
                        {booking?.drawer_user || 'Another employee'} is also viewing this!
                    </div>
                )
            */}

            {
                paymentMode && (
                    <PaymentLedgerBalancesTop
                        reservationTotal={reservationTotal}
                        paidToDate={paidToDate}
                        remaining={remaining}
                        remainingBalance={remainingBalance}
                        pendingAmountPaidCents={booking?.pending_amount_paid_cents || 0}
                        pendingAmountRefundedCents={booking?.pending_amount_refunded_cents || 0}
                    />
                )
            }

            {
                !paymentMode && (
                    <BasicInfo
                        newBooking={newBooking}
                        handleViewParent={handleViewParent}
                        handleCancel={handleCancel}
                        handleReactivate={handleReactivate}
                    />
                )
            }

            {
                showLedgerBreakdown && <>
                    <ParticipantCosts
                        customerTypeTotals={customerTypeTotals}
                        isGroupPricing={isGroupPricing}
                        paymentMode={paymentMode}
                        members={members}
                        memberPrice={memberPrice}
                    />

                    <ParticipantAdjustments
                        discounts={discounts}
                        discountTotal={discountTotal}
                        customerTypeTotals={customerTypeTotals}
                        isGroupPricing={isGroupPricing}
                        initiallyFolded={!newBooking}
                        paymentMode={paymentMode}
                        participants={participants}
                    />

                    <BookingTotals
                        newBooking={newBooking}
                        quoteMode={isBookingQuote(booking)}
                        paymentMode={paymentMode}
                        basePrice={basePrice}
                        taxesAndFees={taxesAndFees}
                        depositToApply={depositToApply}
                        autoGratuity={autoGratuity}
                        tip={tip}
                        remainingBalance={remainingBalance}
                        reservationTotal={reservationTotal}
                    />
                </>
            }

            {
                !paymentMode && showLedgerBreakdown && (
                    <BookingBalances
                        booking={booking}
                        newBooking={newBooking}
                        quoteMode={isBookingQuote(booking)}
                        paidToDate={paidToDate}
                        remainingBalance={remainingBalance}
                        pendingAmountPaidCents={booking?.pending_amount_paid_cents || 0}
                        pendingAmountRefundedCents={booking?.pending_amount_refunded_cents || 0}
                    />
                )
            }

            {
                paymentMode && (
                    <PaymentLedgerBalancesBottom
                        amountToPay={amountToPay}
                        cashChangeDue={cashChangeDue}
                        paymentType={paymentType}
                        balanceAfterPayment={balanceAfterPayment}
                    />
                )
            }
        </div>
    )
}
