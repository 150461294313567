import React, { useEffect } from "react";
import { Chart as ChartJS } from 'chart.js/auto' // eslint-disable-line
import { Bar, Pie } from 'react-chartjs-2'
import colorInterpolation from '../../utils/colorInterpolation'

function Chart(props) {
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////*** chart.js config start ***/////
    const labels = Object.keys(props.chartData);

    const numberOfColors = labels.length
    const colorsArray = colorInterpolation(numberOfColors)

    const data = {
        labels,
        datasets: [
            {
                backgroundColor: colorsArray,
                data: Object.values(props.chartData)
            }
        ]
    }

    const options = {
        indexAxis: !!props.indexAxis ? props.indexAxis : 'y',
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: !!(props.chartType === "pie"),
                position: 'left'
            }
        }
    };
    /////*** chart.js config end ***/////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const renderChart = () => {
        if (props.chartType === "pie") { return(<Pie options={options} data={data} height={props.height} />) }
        if (props.chartType === "bar") { return(<Bar options={options} data={data} height={props.height} />) }
    }

    return (
        <div>
            {renderChart()}
        </div>
    )
}

export default Chart;