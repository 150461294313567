import React from 'react'
import { useSelector } from 'react-redux'
import { selectOrder, selectPricing, selectSelectedProducts, selectDebug } from './productSlice'
import Discount from './Discount'
import MemberProductDiscount from "./MemberProductDiscount"
import ProductTransactionItems from './ProductTransactionItems'
import ProductTransactionTotals from './ProductTransactionTotals'
import { debug } from '@/lib/Debug'

export default function ProductLedger({locationId}) {

    const order               = useSelector(selectOrder)
    const pricing             = useSelector(selectPricing)
    const selections          = useSelector(selectSelectedProducts)
    const transactionDebug    = useSelector(selectDebug)

    return <>
        <ProductTransactionItems />
        <ProductTransactionTotals />

        {
            selections.length > 0
            && order?.amount_paid_cents === undefined
            && <>
                {
                    !pricing.discount && <>
                        <hr className="mt-4 mb-4" />
                        <MemberProductDiscount locationId={locationId}/>
                    </>
                }

                {
                    !pricing.memberDiscount && <>
                        <hr className="mt-4 mb-4" />
                        <Discount />
                    </>
                }
            </>
        }

        {
            debug &&
                <div className="border-top mt-5 pt-2 text-monospace">
                      <h6>DEBUG</h6>
                      {
                          Object.entries(transactionDebug).map(([k,v]) => (
                              <small key={k} className="d-block"><strong>{k}:</strong> {v}</small>
                          ))
                      }
                </div>
        }
    </>
}
