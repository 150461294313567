import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import TerminalTransaction from '../Terminal/TerminalTransaction'

export default function ProductTerminal({ hardwareKey, transactionCallback }) {

    const dispatch = useDispatch()

    return (
        <div>
            <TerminalTransaction
                hardwareKey={hardwareKey}
                transactionCallback={transactionCallback}
            />
        </div>
    )
}
