import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { setQuantity, selectQuantity, selectCart } from '@/features/GiftCards/giftCardSlice'
import PurchaseModalGiftCard from '@/features/GiftCards/components/PurchaseModalGiftCard'
import { errorMessageFor } from '@/components/Form/ErrorsHelper'

export default function PurchaseModalCards({ maxPurchaseable=1, locationTimeZone }) {

    const { register, formState: { errors } } = useFormContext()

    const dispatch  = useDispatch()
    const quantity  = useSelector(selectQuantity)
    const giftCards = useSelector(selectCart)

    const quantityField = register('quantity', { min: { value: 1, message: 'Please select a quantity.' } })

    const handleQuantityChange = (amount) => {
        dispatch(setQuantity(amount))
    }

    return (
        <div className="col-12 col-md-8 pr-md-4 pr-lg-5">
            <div className="form-group">
                <label htmlFor="quantity">
                    Select Number of Gift Cards
                    <span className="req">*</span>
                </label>

                <select
                    {...quantityField}
                    id="quantity"
                    className={`custom-select ${errors?.quantity ? 'is-invalid' : ''}`}
                    value={quantity}
                    onChange={(e) => {
                        quantityField.onChange(e)
                        handleQuantityChange(e.target.value)
                    }}
                >
                    <option value="0" disabled>Please Select...</option>
                    {
                        [...Array(Number(maxPurchaseable))].map((option, index) => (
                            <option key={`option-${index}`}>{index +1}</option>
                        ))
                    }
                </select>

                { errorMessageFor('quantity', errors) }
            </div>

            <div className="row mt-2 gc-list-of-cards overflow-auto" style={{ maxHeight:'65vh' }}>
                {
                    giftCards.length > 0 ? (
                        giftCards.map((giftCard, index) => (
                            <PurchaseModalGiftCard
                                key={index}
                                index={index}
                                singleCard={giftCards.length === 1}
                                locationTimeZone={locationTimeZone}
                            />
                        ))
                    ) : (
                        <h4 className="mt-4 w-100 text-center">
                            Your Cart Is Empty
                        </h4>
                    )
                }
            </div>
        </div>
    )
}
