import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

export default function PackageOptions({ packages, setCurrentPackages }) {
  const handleOnDragEnd = result => {
    if (!result.destination) return

    const items = Array.from(packages)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    setCurrentPackages(items)
  }

  const getItems = packages => {
    return packages.map((p, index) => {
      return (
        <Draggable key={p.id} draggableId={`${p.id}`} index={index}>
          {provided => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <div className={`form-group draggable cursor-pointer px-2 ${index === 0 ? 'pb-1' : 'border-top mt-1 pt-3 pb-2'}`}>
                <strong>{p.name}</strong>
                <i className="fas fa-bars text-muted ml-3 float-right" />
              </div>
            </div>
          )}
        </Draggable>
      )
    })
  }

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="draggable-options">
        {provided => (
          <div
            className="draggable-options"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {getItems(packages)}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
