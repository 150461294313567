import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import AuthorizeManagerOverride from '@/features/ManagerCodes/AuthorizeManagerOverride'
import GiftCardFormCheck from '@/features/GiftCards/components/GiftCardFormCheck'
import GiftCardFormPaymentType from '@/features/GiftCards/components/GiftCardFormPaymentType'
import GiftCardFormCreditCard from '@/features/GiftCards/components/GiftCardFormCreditCard'
import ProductTerminal from '@/features/Products/ProductTerminal'
import TerminalTransaction from '@/features/Terminal/TerminalTransaction'
import AdyenTerminalTransaction from '@/features/Terminal/AdyenTerminalTransaction'
import { selectConnected, submitTransaction } from '@/features/Terminal/terminalSlice'
import { selectAdyenPayment } from '@/features/Adyen/adyenSlice'
import { selectValid, clear } from '@/features/ManagerCodes/managerCodeSlice'
//import { submitPurchase, } from '@/features/Products/productSlice'
import { ADYEN_TERMINAL_SALE_ID, ADYEN_TERMINAL_SERVICE_ID } from '@/lib/Storage'
import { SecureHash } from '@/lib/Crypto'

import {
    setCreditToken,
    setPaymentType,
    selectBalanceDue,
    selectPaymentType,
    selectCreditToken,
} from '@/features/GiftCards/giftCardSlice'

export default function GiftCardFormPayment({
    form,
    locationId,
    locationTimeZone,
    environment,
    hardwareKey,
    handleDisableSaveButton,
    handleManualUpdate,
    paymentProcessor,
    handleSaveAndClose,
}) {

    const { trigger, formState: { isValid } } = useFormContext()

    const dispatch            = useDispatch()
    const creditToken         = useSelector(selectCreditToken)
    const paymentType         = useSelector(selectPaymentType)
    const paymentAmount       = useSelector(selectBalanceDue)
    const terminalConn        = useSelector(selectConnected)
    const managerIsAuthorized = useSelector(selectValid)
    const adyenPayment        = useSelector(selectAdyenPayment)

    const [loaded, setLoaded]               = useState(false)
    const [adyenCheckout, setAdyenCheckout] = useState(null)
    const [term, setTerm]                   = useState(null)

    /*
    const submit = () => {
        if (paymentType === 'credit') { return }
        dispatch(submitPayment(purchasePath, email))
    }

    const submitTerminal = () => {
        const amt = (Math.round(Number.parseFloat(paymentAmount) * 100)).toFixed(0)
        dispatch(submitTransaction(amt, transactionCallback))
    }

    const transactionCallback = (payment, partialAmountCharged = null) => {
        alert('refactor this')
        //dispatch(submitPurchase(purchasePath, name, email, payment.cardToken, payment, partialAmountCharged))
    }
    */

    const submitPaysafeTerminal = () => {
        const amt = (Math.round(Number.parseFloat(paymentAmount) * 100)).toFixed(0)
        dispatch(submitTransaction(amt, transactionCallback))
    }

    const submitAdyenTerminal = () => {
        window.sessionStorage.setItem(ADYEN_TERMINAL_SALE_ID, SecureHash(10))
        window.sessionStorage.setItem(ADYEN_TERMINAL_SERVICE_ID, SecureHash(5))
        handleSaveAndClose()
    }

    const transactionCallback = (payment) => {
        /**
         * This is setup is different than the others, because we are essentially using the parent drawer
         * to submit this form instead of having a specific endpoint connected to a button that we have
         * control over.
         *
         * This form is submitting to the generic gift card post endpoints, so we also need to see what
         * is being submitted there in terms of data.
         *
         * In the end we need to do whatever routine that is running unfortunately...
         *
         * It's doing a lot of callbacks on handleSave... do we just pass that down and give it access?
         *
         * Either that, or we just use the same button and save the payment somewhere in slice in that
         * callback instead? In the end, as long as the token is saved we should actually be ok...
         */
        dispatch(setCreditToken(payment.cardToken))
    }

    const handleCreditCardClick = () => {
        if (paymentProcessor === 'paysafe') { return }
        adyenCheckout.submit()
    }

    const renderFinalizeButton = () => {
        switch(paymentType) {
        case 'credit':
            return <>
                <button id='finish-button' className='btn btn-primary w-100' disabled={creditToken || adyenPayment || !isValid} onClick={handleCreditCardClick}>
                    { (creditToken || adyenPayment) ? 'Authorized' : 'Authorize Credit Payment' }
                </button>
                <p className="w-100 text-center text-muted mt-5">
                    You must authorize the payment before clicking "Save & Close"
                </p>
            </>

        case 'terminal':
            return <>
                <button
                    children='Submit Terminal Transaction'
                    className='btn btn-primary w-100'
                    disabled={creditToken || !isValid}
                    onClick={paymentProcessor === 'paySafe' ? submitPaysafeTerminal : submitAdyenTerminal}
                />
                {
                    paymentProcessor === 'paysafe' && (
                        <p className="w-100 text-center text-muted mt-5">
                            You must authorize the terminal payment before clicking "Save & Close"
                        </p>
                    )
                }
            </>

        case 'skip_payment':
            return (
                <p className="w-100 text-center text-muted mt-4">
                  { managerIsAuthorized
                      ? 'Payment will be processed automatically upon clicking "Save & Close"'
                      : 'You must authorize as a manager before continuing.'
                  }
                </p>
            )

        // cash or check types
        default:
            return (
                <p className="w-100 text-center text-muted mt-5">
                    Payment will be processed automatically upon clicking "Save & Close"
                </p>
            )
        }
    }

    useEffect(() => {
        setLoaded(true)

        return () => {
            dispatch(clear())
        }
    }, [])

    useEffect(() => {
        if (loaded) {
            trigger()

            if (/check|cash/i.test(paymentType)) {
                handleDisableSaveButton(false)
            }

            if (/terminal/i.test(paymentType)) {
                handleDisableSaveButton(!terminalConn)
            }

            if (/skip_payment/i.test(paymentType)) {
                handleDisableSaveButton(!managerIsAuthorized)
            }

            handleManualUpdate('payment_type', paymentType)
        }
    }, [paymentType, paymentAmount, terminalConn, managerIsAuthorized])

    // useEffect(() => {
    //     if (paymentType === 'terminal') {
    //         handleDisableSaveButton(!!!creditToken)
    //     }
    // }, [creditToken, paymentType])

    useEffect(() => {
        if (!term) { return }
        handleManualUpdate('terminal_id', term)
    }, [term])

    const renderTerminalForm = () => (
        paymentProcessor === 'paysafe' ? (
            <TerminalTransaction
                hardwareKey={hardwareKey}
                transactionCallback={transactionCallback}
            />
        ) : (
            <AdyenTerminalTransaction
                locationId={locationId}
                setTerminal={(t) => setTerm(t)}
                transactionCallback={transactionCallback}
                hideButton
            />
        )
    )

    return (
        <div>
            <div className="row">
                <div className="col-12">
                    <h3 className="border-bottom">Payment Type</h3>
                    <GiftCardFormPaymentType />
                </div>
            </div>

            { paymentType === 'credit' &&
                <GiftCardFormCreditCard
                    email={form.receipt_email}
                    handleDisableSaveButton={handleDisableSaveButton}
                    handleManualUpdate={handleManualUpdate}
                    locationId={locationId}
                    locationTimeZone={locationTimeZone}
                    environment={environment}
                    paymentProcessor={paymentProcessor}
                    adyenCheckout={adyenCheckout}
                    setAdyenCheckout={setAdyenCheckout}
                />
            }

            { paymentType === 'terminal' && renderTerminalForm() }

            { paymentType === 'check' &&
                <GiftCardFormCheck
                    form={form}
                    handleManualUpdate={handleManualUpdate}
                />
            }

            { paymentType === 'skip_payment' && !managerIsAuthorized &&
                <div className="row mt-2">
                    <div className="w-50 mx-auto">
                        <AuthorizeManagerOverride locationId={locationId} />
                    </div>
                </div>
            }

            <div className="form-group">
                { renderFinalizeButton() }
            </div>
        </div>
    )
}
