import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useFormContext } from 'react-hook-form'
import errorClass from '../../lib/Errors'
import InputWrapper from './InputWrapper'

export default function Select({
  id,
  name='',
  value='',
  cols='12',
  size=null,
  errors=[],
  label,
  hideLabel,
  withWrapper,
  inputClassName='',
  inputGroupClassName='',
  options,
  multiple=false,
  includeBlank=false,
  append=true,
  prepend=false,
  disabled=false,
  req=null,
  validation=null,
  note=null,
  autoComplete=null,
  tabIndex=null,
  inputStyle=null,
  children,
  handleChange=() => {},
  handleBlur=null,
}) {
    const formMethods = useFormContext()

    /**
     * Example of an expected validation
     * schema object to register
     *
     * {
     *   'name': {
     *     required: 'A name is required',
     *     minLength: { value: 4, message: 'must be more than 4 characters long' }
     *   }
     * }
     */
    const separator = ':'

    const field = formMethods && validation
        ? formMethods.register( Object.keys(validation)[0], validation[Object.keys(validation)[0]] )
        : { name: name + separator + uuidv4(), ref: null }

    const _name = field.name.split(separator)[0]

    const handleUpdate = (e) => {
        // update react hook form validation
        if ('onChange' in field) { field.onChange(e) }
        // update our form object
        handleChange(e)
    }

    const prependedChild = () => {
        if (children?.length === 1) { return children }

        // ignore all children except first
        let child = ''

        React.Children.map(children, (c, _i) => {
            if (/prepend/.test(c.props.className)) {
                child = c
            }
        })

        return child
    }

    const appendedChild = () => {
        if (children?.length === 1) { return children }

        // ignore all children except last
        let child = ''

        React.Children.map(children, (c, _i) => {
            if (/append/.test(c.props.className)) {
                child = c
            }
        })

        return child
    }

    const renderOptions = () => (
        options.map((option, index) => {
            const dataAttrs = {}

            option?.data?.forEach((data) => {
                dataAttrs[`data-${data.key}`] = data.value
            })

            return (
                <option
                    key={index}
                    value={option?.is_blank ? '' : option?.value || option?.id || option}
                    disabled={option?.disabled || false}
                    {...dataAttrs}
                >
                    {option?.display || option?.name || option}
                </option>
            )
        })
    )

    return (
        <InputWrapper
            name={_name}
            req={(validation && req === null) || Boolean(req)}
            cols={cols}
            errors={errors}
            label={label}
            hideLabel={hideLabel}
            withWrapper={withWrapper}
        >
            <div className={`${inputGroupClassName} ${children ? 'input-group' : ''}`.trim()}>
                { prepend && prependedChild() }

                <select
                    ref={field.ref}
                    id={id}
                    name={_name}
                    className={`custom-select ${inputClassName} ${errorClass(_name, errors)}`}
                    multiple={multiple}
                    value={value || ''}
                    disabled={disabled}
                    size={size}
                    autoComplete={autoComplete}
                    tabIndex={tabIndex}
                    style={inputStyle}
                    onChange={handleUpdate}
                    onBlur={handleBlur || handleUpdate}
                >
                    { includeBlank ? <>
                        {
                            includeBlank === true || includeBlank === ""
                                ? <option value="" disabled />
                                : <option value="" disabled>{includeBlank}</option>
                        }

                        { renderOptions() }
                    </> : <>
                      <optgroup label={`Select One${ multiple ? ' or Many' : '' }`}>
                          { renderOptions() }
                      </optgroup>
                    </> }
                </select>

                { note }

                { multiple && <p className="mt-2 text-secondary text-center"><small>(Multiple can be selected with CTRL/CMD-Click)</small></p> }

                { append && appendedChild() }
            </div>
        </InputWrapper>
    )
}
