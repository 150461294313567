import React from 'react'
import { useFormContext } from "react-hook-form"
import Input from '@/components/Form/Input'
import FormRow from '@/components/Form/FormRow'
import Checkbox from '@/components/Form/Checkbox'
import ColorPicker from '@/components/Form/ColorPicker'
import MultiSelectCheckboxes from '@/components/Form/MultiSelectCheckboxes'
import { pluckKeyFromObjectsInArray } from '@/lib/Object'
import { errorMessageFor } from '@/components/Form/ErrorsHelper'
import errorClass from '@/lib/Errors'

export default function ModifierForm({
  form,
  errors,
  modifierGroupOptions,
  handleUpdate,
  handleManualUpdate,
  handleFormUpdate,
  handleCheckboxUpdate,
}) {
  const formMethods = useFormContext()

  // register the amount_cents input field while also setting a
  // default value that is converted from cents into number format
  const amountCentsField = formMethods.register('amount_cents', {
    value: form.amount_cents === null ? '' : parseFloat((form.amount_cents || 0) / 100).toFixed(2),
    validate: (v) => (v !== "" && Number(v) >= 0) || 'This field is required.'
  })

  const handleSelect = modifier_groups => {
    const modifier_group_ids = pluckKeyFromObjectsInArray(modifier_groups, 'id')
    handleFormUpdate({ ...form, ...{ modifier_groups, modifier_group_ids }})
  }

  const handleAmountUpdate = e => {
    // allow us to set the value back to null to throw a validation error
    // should the user ever fully delete the value from the input
    handleManualUpdate('amount_cents', e.target.value === '' ? null : parseFloat(e.target.value) * 100)
    amountCentsField.onChange(e)
  }

  return (
    <div className="mt-4">
      <FormRow>
        <Input
          cols='9'
          name="name"
          label="Modifier Name"
          value={form.name}
          validation={{ name: { required: true } }}
          errors={errors}
          handleChange={handleUpdate}
        />

        <Checkbox
          cols='3'
          name='is_available'
          label='Available?'
          value={form.is_available}
          className='pt-4 pl-2'
          handleChange={handleCheckboxUpdate}
        />
      </FormRow>

      <FormRow>
        <div className="col-3">
          <div className="form-group">
            <label className="req">Modifier Price</label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">$</span>
              </div>
              <input
                {...amountCentsField}
                className={`form-control ${errorClass("amount_cents", errors)}`}
                type="number"
                step="0.01"
                min="0"
                onChange={handleAmountUpdate}
              />
            </div>
            { errorMessageFor('amount_cents', errors) }
          </div>
        </div>
      </FormRow>

      <h4>Button Color</h4>
      <FormRow>
        <ColorPicker
          value={form.button_color}
          onChange={color => handleManualUpdate('button_color', color)}
        />
      </FormRow>

      <h4>Include in Modifier Groups</h4>
      <FormRow>
        <MultiSelectCheckboxes
          hideLabel={true}
          options={modifierGroupOptions}
          selectedValues={form.modifier_groups}
          useExternalList={true}
          emptyRecordMsg='No modifier groups available.'
          errors={errors}
          onSelect={handleSelect}
          onRemove={handleSelect}
        />
      </FormRow>
    </div>
  )
}
