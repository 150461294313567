import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { selectTimeZone } from '@/features/Locations/locationSlice'
import { selectBookingId } from '../EditBooking/editBookingSlice'
import { fetchBookingHistory, selectChangeLog } from '../BookingHistory/bookingHistorySlice'
import {selectResources} from "../Resources/resourcesSlice";

export default function BookingHistoryChangeLog() {

    const dispatch  = useDispatch()
    const bookingId = useSelector(selectBookingId)
    const changeLog = useSelector(selectChangeLog)
    const tz        = useSelector(selectTimeZone)
    const resources = useSelector(selectResources)

    const formattedValue = (value, column) => {
        switch(true) {
            case value === 0 || value === '0' :
                return 0

            case /^is_/i.test(column) && !Boolean(value) :
                return 'false'

            case !Boolean(value) :
                return 'N/A'

            case column === 'resource_ids' :
                const value_array = value.slice(1, -1).split(',').map(Number)

                if (value_array.length === 1 && value_array[0] === 0) {
                    return 'N/A'
                }

                const resource_names = []

                Object.values(resources).filter((resource) => value_array.includes(resource.id)).map((resource) => {
                    resource_names.push(resource.name)
                })

                return JSON.stringify(resource_names)

            case /(_at|_time)/i.test(column) && moment(value).isValid() :
                // whenever the value can be parsed into a valid moment object, we convert it into the location tz and display it.
                return moment(value).tz(tz).format('MMM D, YYYY h:mm:ss:SSS A')

            default :
                return value
        }
    }

    useEffect(() => {
        dispatch(fetchBookingHistory(bookingId))
    }, [bookingId])

    return (
        <table className="table table-middle table-sm mb-0">
          <colgroup>
            <col width="120px" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
          </colgroup>
          <tbody>
            {
                changeLog.map(eventAndUser => {
                    const change = eventAndUser.event
                    const user = eventAndUser.user || {}

                    /**
                     * Loop over the individual change log entries that are found for the current booking. We then
                     * loop over the individual object changes per column as individual table rows. This means right
                     * now each COLUMN change is actually registered as a row... we would need some sort of consolidated
                     * view to separate those but I'm assuming it doesn't really matter...
                     */
                    return Object.keys(change.object_changes).filter(column => {
                        // whatever fields we don't want to include in the audit table we can just add here
                        return !['updated_at', 'customer_settings','note'].includes(column)
                    }).map((column, index) => {
                        // the array of changes [from, to] for the individual column we're on
                        const changes = change.object_changes[column]
                        const from    = typeof changes[0] === 'object' ? JSON.stringify(changes[0]) : changes[0]
                        const to      = typeof changes[1] === 'object' ? JSON.stringify(changes[1]) : changes[1]

                        return (
                            <tr key={index}>
                                <td className="text-right text-gray3 smaller">
                                    <strong>
                                        {moment(change.created_at).tz(tz).format('MMM D, YYYY')}
                                        <br />
                                        {moment(change.created_at).tz(tz).format('h:mm:ss:SSS A')}
                                    </strong>
                                </td>

                                <td className='px-4'>
                                    <strong>{user.username || 'System'}</strong> <span className="text-gray3 smaller">Changed</span> <strong>{column}</strong>
                                </td>

                                <td className="pl-0 text-right"><strong>{formattedValue(from, column)}</strong></td>
                                <td width="0" className="px-0 text-center"><i className="fas fa-arrow-right text-yellow mx-0"></i></td>
                                <td className="pr-0 text-left"><strong>{formattedValue(to, column)}</strong></td>
                            </tr>
                        )
                    })
                })
            }
          </tbody>
        </table>
    )
}
