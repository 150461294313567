import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { numberToCurrency } from '@/lib/Number'
import {
    checkGiftCard,
    selectGiftCardBalance,
    selectGiftCardError,
    selectGiftCardNumber,
    selectPaymentAmount,
    setGiftCardError,
    setGiftCardNumber
} from './productSlice'
import { selectTotal } from '@/features/Members/memberSlice'

export default function ProductGiftCard({ membershipPayment=false }) {

    const dispatch = useDispatch()

    const number        = useSelector(selectGiftCardNumber)
    const error         = useSelector(selectGiftCardError)
    const balance       = useSelector(selectGiftCardBalance)
    const paymentAmount = membershipPayment ? useSelector(selectTotal) : useSelector(selectPaymentAmount)

    const checkBalance = e => {
        e.preventDefault()

        dispatch(checkGiftCard())
    }

    useEffect(() => {
        if (balance !== null && balance < (Math.round(paymentAmount * 100))) {
            dispatch(setGiftCardError('Insufficient balance.'))
        } else if (error) {
            dispatch(setGiftCardError(null))
        }
    }, [balance, paymentAmount, dispatch])

    return (
        <>
            <div className="form-row">
                <div className="col-12">
                    <div className="form-group">

                        <label>Gift Card Number</label>
                        <div className="input-group">
                            <input
                                name='gift_card_number'
                                className={`form-control ${error ? 'is-invalid' : ''}`}
                                placeholder='Gift Card Number...'
                                value={number || ''}
                                onChange={e => dispatch(setGiftCardNumber(e.target.value))}
                            />

                            <div className="input-group-append">
                                <button
                                    type="button"
                                    className='btn btn-secondary text-white'
                                    onClick={checkBalance}
                                    disabled={!number}
                                >
                                    Check Balance
                                </button>
                            </div>

                            <div className="invalid-feedback">{error}</div>
                        </div>

                    </div>
                </div>
            </div>

            {
                typeof balance === 'number' && (
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group text-center">
                                Gift Card Balance: {numberToCurrency(balance / 100)}
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}
