import { configureStore } from '@reduxjs/toolkit'
import advancedPointOfSaleReducer from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'
import helpSliceReducer from '@/features/Help/helpSlice'
import locationSliceReducer from '@/features/Locations/locationSlice'
import managerCodeSliceReducer from '@/features/ManagerCodes/managerCodeSlice'
import notificationSliceReducer from '@/features/Notifications/notificationSlice'
import sessionSliceReducer from '@/features/Session/sessionSlice'
import terminalSliceReducer from '@/features/Terminal/terminalSlice'
import adyenSliceReducer from '@/features/Adyen/adyenSlice'
import { setPreloadedState } from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

export default function (preloadedState=null) {
    const store = configureStore({
        reducer: {
            help: helpSliceReducer,
            location: locationSliceReducer,
            managerCode: managerCodeSliceReducer,
            notifications: notificationSliceReducer,
            point_of_sale: advancedPointOfSaleReducer,
            session: sessionSliceReducer,
            terminal: terminalSliceReducer,
            adyen: adyenSliceReducer,
        }
    })

    if (
        !!preloadedState
        && typeof preloadedState == 'object'
        && Object.keys(preloadedState).length > 0
    ) {
        store.dispatch(setPreloadedState(preloadedState))
    }

    return store
}
