import React from 'react'
import { useFormContext } from 'react-hook-form'
import titleize from '../../lib/String'
import Tooltip from "./Tooltip"
import errorClass from '../../lib/Errors'

export default function Checkbox({
  name,
  value,
  inputId,
  cols=null,
  className='',
  innerClassName='',
  labelClassName='',
  label,
  hideLabel,
  tooltip='',
  disabled,
  validation=null,
  errors=[],
  tabIndex=null,
  handleChange=() => {},
  asFormGroup=true,
  children
}) {
    const formMethods = useFormContext()

    const id = inputId || `custom-switch-${name}`

    const field = formMethods && validation
        ? formMethods.register( Object.keys(validation)[0], validation[Object.keys(validation)[0]] )
        : { name: name, ref: null }

    return (
        <div className={`${asFormGroup ? 'form-group' : ''} ${cols ? `col-${cols}` : ''} ${className} ${errorClass(field.name, errors)}`.trim()}>
            <div className={`custom-control custom-switch ${innerClassName}`}>

                <input
                    type="checkbox"
                    className="custom-control-input"
                    ref={field.ref}
                    id={id}
                    name={name}
                    checked={value || false}
                    value={value || false}
                    disabled={disabled || false}
                    tabIndex={tabIndex}
                    onChange={handleChange}
                />

                { !hideLabel && (
                    <label
                        className={`custom-control-label ${labelClassName}`}
                        htmlFor={id}
                        dangerouslySetInnerHTML={{ __html: label || titleize(name) }}
                    />
                ) }

                { tooltip !== '' && (
                    <Tooltip id={name} place='left'>
                        <div dangerouslySetInnerHTML={{ __html: tooltip }} />
                    </Tooltip>
                ) }

                { children }
            </div>
        </div>
    )
}
