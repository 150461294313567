import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'

import SeeToday from './SeeToday'
import CalendarGrid from './CalendarGrid'
import BookingsContainer from './BookingsContainer'
import TimeMarker from './TimeMarker'
import DynamicStyles from '@/features/Calendar/CalendarDynamicStyles'

import {
  selectInitialLoadComplete,
  selectBookingTopOffset,
  selectShouldCenterCalendar,
  selectCalendarScrollListeners,
  selectCalendarClass,
  selectDate,
  selectLoadedDates,
  selectMarkerOffset,
  selectLoading,
  selectSizeWidth,
  setInitialLoadComplete,
  setCompanyId,
  setLocationId,
  setDate,
  setViewedDate,
  setShouldCenterCalendar,
  triggerTimeMarkerUpdate,
  selectBooking,
  setTimesForLocation,
  setLocationSpecialHours,
  setHoldTimeDurationMinutes,
} from './calendarSlice'

import { setResourceTypes, selectSelectedResourceType, setResourceType } from '../ResourceType/resourceTypeSlice'
import { setLocation, setPaymentProcessor, selectTimeZone } from '@/features/Locations/locationSlice'
import { fetchCalendarBookings } from '../CalendarBookings/calendarBookingsSlice'
import { setParentBooking, setChildBookings, setParentBookingModalOpen } from '@/features/ParentBooking/parentBookingSlice'
import { setBooking } from '../EditBooking/editBookingSlice'
import { setOpen, setStep } from '../Schedule/scheduleSlice'
import { deviceType } from '@/lib/Devices'
import { LOAD_SPECIFIC_DATE } from '@/lib/Storage'
import { debug } from '@/lib/Debug'

const rewriteUrlHistory = () => {
    try {
        window.history.pushState({}, '', window.location.href.replace(/\/calendar\/(.*){1}$/, '/calendar'))
    } catch(e) {
        if (debug && console) { console.error(e) }
    }
}

export default function Calendar({ location, paymentProcessor, companyId, resourceTypes, selectedBooking, holdTimeDurationMinutes }) {

    const dispatch                 = useDispatch()
    const calendarLoaded           = useSelector(selectInitialLoadComplete)
    const selectedTimeZone         = useSelector(selectTimeZone)
    const selectedBookingTopOffset = useSelector(selectBookingTopOffset)
    const shouldCenterCalendar     = useSelector(selectShouldCenterCalendar)
    const loading                  = useSelector(selectLoading)
    const sizeWidth                = useSelector(selectSizeWidth)
    const date                     = useSelector(selectDate)
    const resourceType             = useSelector(selectSelectedResourceType)
    const loadedDates              = useSelector(selectLoadedDates)
    const markerOffset             = useSelector(selectMarkerOffset)
    const calendarClass            = useSelector(selectCalendarClass)
    const calendarScrollListeners  = useSelector(selectCalendarScrollListeners)

    const calendarRef                           = useRef()
    const [initialBookings, setInitialBookings] = useState(false)

    const handleScroll = (e) => {
        if (!calendarScrollListeners) { return }

        const offsetWidth  = e.target.offsetWidth
        const scrollLeft   = e.target.scrollLeft
        const scrollWidth  = e.target.scrollWidth
        const OneHourPrior = (Number(sizeWidth) * 4)

        if ((offsetWidth + scrollLeft) >= (scrollWidth - OneHourPrior)) {
            const nextDay = moment(loadedDates[loadedDates.length - 1]).add('1', 'day').format('YYYY-MM-DD')

            if (nextDay && !loading) {
                if (debug && console) { console.log(`loading ${nextDay} via scroll`) }
                dispatch(setDate(nextDay))
                dispatch(setViewedDate(nextDay))
                dispatch(fetchCalendarBookings(resourceType.id, nextDay, true, false))
            }
        }
    }

    /**
     * Setup time marker movement timer to
     * update its position every 60 seconds
     */
    useEffect(() => {
        window.__calendarTimeMarkerTimer = window.setInterval(() => {
            dispatch(triggerTimeMarkerUpdate())
        }, 60 * 1000)

        return () => {
            window.clearInterval(window.__calendarTimeMarkerTimer)
        }
    }, [])

    /**
     * Initial effect that runs to populate resource types and initial data
     */
    useEffect(() => {
        if (!calendarLoaded) {
            dispatch(setLocation(location))                               // locationSlice
            dispatch(setPaymentProcessor(paymentProcessor))               // locationSlice
            dispatch(setLocationId(location.id))                          // calendarSlice
            dispatch(setCompanyId(companyId))                             // calendarSlice
            dispatch(setHoldTimeDurationMinutes(holdTimeDurationMinutes)) // calendarSlice
            dispatch(setResourceTypes(resourceTypes))                     // resourceTypeSlice

            if (debug && console) {
                console.log(`%c CURRENT TIME (BROWSER)   |  ${moment().format()}`, 'background:#E4AF09;color:#332B00;')
                console.log(`%c CURRENT TIME (LOCATION)  |  ${moment.tz(location.time_zone).format()}  |  ${location.time_zone}`, 'background:#E4AF09;color:#332B00;')
            }

            if (selectedBooking) {
                rewriteUrlHistory()
                dispatch(setResourceType(selectedBooking.booking.resource_type.id))
                dispatch(fetchCalendarBookings(selectedBooking.booking.resource_type.id, moment.tz(selectedBooking.booking.start_time, location.time_zone).format('YYYY-MM-DD'), true, false))
                dispatch(selectBooking(selectedBooking.booking))

                if (selectedBooking.booking.is_parent) {
                    dispatch(setParentBooking({ booking: selectedBooking.booking, resources: selectedBooking.resources }))
                    dispatch(setChildBookings({ childBookings: selectedBooking.child_bookings }))
                    dispatch(setParentBookingModalOpen(true))
                } else {
                    dispatch(setBooking({ booking: selectedBooking.booking, resources: selectedBooking.resources }))
                    dispatch(setStep('3'))
                    dispatch(setOpen(true))
                }
            }

            window.localStorage.removeItem(LOAD_SPECIFIC_DATE)
            dispatch(setInitialLoadComplete(true))
        }
    }, [calendarLoaded, dispatch])

    /**
     * Whenever a resource type or date changes, we have to load bookings
     *
     * @TODO if we want to change the `date` as the user scrolls, I think this will have to be removed from here
     * and we will have to put dispatchers into DateSelect and SelectResource components to manually fire these
     * instead of automatic. otherwise, as they scroll it will fire off the wrong callback to get calendar bookings
     * for that next date only, meaning the scroll starts to blow up.
     */
     useEffect(() => {
         if (!loading && resourceType) {
             setInitialBookings(true)
             /**
              * Before we even fetch the new dates we need to scroll back otherwise the scrolling
              * will freak out if the container shrinks and will snap us to midnight and load more dates, etc
              */
             calendarRef.current.scrollTo(4000, 0)
             dispatch(fetchCalendarBookings(resourceType.id, date, !initialBookings, !initialBookings, true))
         }
     }, [dispatch, resourceType, date])

    /**
     * Whenever the calendar ref or the marker offset for the time marker change, scroll to the time marker.
     *
     * @TODO depending on if/how we implement auto-update (if we do at all), we might have to change this logic
     * because this would scroll again as time updates...
     */
    useEffect(() => {
       /**
        * Scroll the calendar to the position of the time marker.
        *
        * Scrolling to the X position itself will put the time marker at the very left of the screen,
        * in order to center the current time we take the marker offset, subtract the window's width and divide
        * that by 2, ultimately getting "half" of the user's screen.
        *
        * The reason this is passed as a prop into SeeToday is because calendarRef cannot be set
        * in a slice directly. The only thing we can do is to set both and have 2 useEffect listeners...
        * this way there's 1 shared way to scroll the calendar container and should only really need
        * to be passed into 1 other component.
        */
        if (calendarRef && markerOffset) {
            if (debug) { console.log('>>> SCROLLING CALENDAR <<<') }

            calendarRef.current.scrollTo({
                top: selectedBookingTopOffset || 0,
                left: markerOffset || 0,
                behavior: shouldCenterCalendar ? 'smooth' : 'auto' // auto = instant jump
            })

            if (shouldCenterCalendar) {
                dispatch(setShouldCenterCalendar(false))
            }
        }
    }, [calendarRef, markerOffset, selectedBookingTopOffset, shouldCenterCalendar])

    /**
     * Set the schedule for the chosen location in the chosen timezone. All hour/minute
     * combinations are converted to simple floating point numbers so that doing the maths
     * on them later on in LaneTimesContainer is drastically simpler.
     */
    useEffect(() => {
        if (!Boolean(location) || !Boolean(selectedTimeZone)) { return }

        const format = 'HH.mm'

        const getOpenTime = day => {
            return (resourceType && resourceType['override_hours'] && resourceType[`${day}_override_open`]) || location[`${day}_open`]
        }

        const getCloseTime = day => {
            return (resourceType && resourceType['override_hours'] && resourceType[`${day}_override_close`]) || location[`${day}_close`]
        }

        dispatch(setTimesForLocation({
            monday: {
                open_time:  parseFloat(moment(getOpenTime('monday')).tz(selectedTimeZone).format(format)),
                close_time: parseFloat(moment(getCloseTime('monday')).tz(selectedTimeZone).format(format)),
                all_day: location.monday_closed
            },
            tuesday: {
                open_time:  parseFloat(moment(getOpenTime('tuesday')).tz(selectedTimeZone).format(format)),
                close_time: parseFloat(moment(getCloseTime('tuesday')).tz(selectedTimeZone).format(format)),
                all_day: location.tuesday_closed
            },
            wednesday: {
                open_time:  parseFloat(moment(getOpenTime('wednesday')).tz(selectedTimeZone).format(format)),
                close_time: parseFloat(moment(getCloseTime('wednesday')).tz(selectedTimeZone).format(format)),
                all_day: location.wednesday_closed
            },
            thursday: {
                open_time:  parseFloat(moment(getOpenTime('thursday')).tz(selectedTimeZone).format(format)),
                close_time: parseFloat(moment(getCloseTime('thursday')).tz(selectedTimeZone).format(format)),
                all_day: location.thursday_closed
            },
            friday: {
                open_time:  parseFloat(moment(getOpenTime('friday')).tz(selectedTimeZone).format(format)),
                close_time: parseFloat(moment(getCloseTime('friday')).tz(selectedTimeZone).format(format)),
                all_day: location.friday_closed
            },
            saturday: {
                open_time:  parseFloat(moment(getOpenTime('saturday')).tz(selectedTimeZone).format(format)),
                close_time: parseFloat(moment(getCloseTime('saturday')).tz(selectedTimeZone).format(format)),
                all_day: location.saturday_closed
            },
            sunday: {
                open_time:  parseFloat(moment(getOpenTime('sunday')).tz(selectedTimeZone).format(format)),
                close_time: parseFloat(moment(getCloseTime('sunday')).tz(selectedTimeZone).format(format)),
                all_day: location.sunday_closed
            },
        }))
        dispatch(setLocationSpecialHours(location.special_hours.reduce((obj, item) => {
            return Object.assign(obj, { [moment(item.date).tz(selectedTimeZone).format('DD-MM-YYYY')]: {
                open_time: parseFloat(moment(item.opens_at).tz(selectedTimeZone).format(format)),
                close_time: parseFloat(moment(item.closes_at).tz(selectedTimeZone).format(format)),
                all_day: item.is_closed,
            } })}
        , {})))
    }, [selectedTimeZone, resourceType])

    /**
     * @sizeWidth represents the width of the columns.
     * @sizeHeight represents the height of the rows.
     * These are the values that will be changed on zoom.
     * 'px' unit size is omitted so I can do math more easily with jQuery at the bottom of the page.
     *
     * Default view (in all the way)
     * @sizeWidth = '125'
     * @sizeHeight = '125'

     * zoom-1
     * @sizeWidth = '100'
     * @sizeHeight = '100'

     * zoom-2
     * @sizeWidth = '75'
     * @sizeHeight = '60'

     * @labelWidth represents the width of the first column.
     * This may need to change on zoom.
     * 'px' unit size is omitted so I can do math more easily with jQuery at the bottom of the page.
     *  @labelWidth = '145'
    */

    return (
        <div id="calendar" className={calendarClass} onScroll={handleScroll} ref={calendarRef}>
            <DynamicStyles />
            <SeeToday />
            <TimeMarker />

            <DndProvider
              backend={/^mobile|tablet$/i.test(deviceType()) ? TouchBackend: HTML5Backend}
              debugMode={false}
            >
                <CalendarGrid />
                <BookingsContainer />
            </DndProvider>
        </div>
    )
}
