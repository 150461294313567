import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    selectCheckoutTab,
    selectTipAmount,
    selectCurrentCheck,
    selectAmountTowardsBalance,
    selectIsAmountValid,
    selectPayFullAmount,
    setChangeDue,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import PaymentForm from '@/features/AdvancedPointOfSale/components/checkout/PaymentForm'
import { numberToCurrency } from '@/lib/Number'

export default function Cash({
    self,
    totalDue,
    isProcessing,
    onLoad,
    onSubmit
}) {

    const dispatch             = useDispatch()
    const check                = useSelector(selectCurrentCheck)
    const tab                  = useSelector(selectCheckoutTab)
    const tipAmount            = useSelector(selectTipAmount)
    const isAmountValid        = useSelector(selectIsAmountValid)
    const amountTowardsBalance = useSelector(selectAmountTowardsBalance)
    const payFullAmount        = useSelector(selectPayFullAmount)

    const [receivedAmount, setReceivedAmount] = useState(null)

    const amountToProcess = Number.parseFloat(tipAmount || 0) + Number.parseFloat(amountTowardsBalance || 0)

    const processButton = useMemo(() => (
        <button
            children={isProcessing ? 'Please Wait...' : `Process ${numberToCurrency(amountToProcess)}`}
            className='btn btn-primary process-button'
            disabled={isProcessing || !isAmountValid || (receivedAmount < amountToProcess)}
            onClick={() => {
                onSubmit({
                    totalReceivedAmount: amountToProcess,
                    tipAmount: tipAmount || 0,
                })
            }}
        />
    ), [isProcessing, isAmountValid, receivedAmount, amountToProcess, payFullAmount])

    const handleChange = (e) => {
        const value = e.target.value

        if (value === '' || /^(?:\d*\.\d{1,2}|\d+)$/i.test(value)) {
            setReceivedAmount(value)
        }
    }

    useEffect(() => {
        if (receivedAmount !== null) {
            dispatch(setChangeDue(Math.max(Number.parseFloat(receivedAmount || 0) - amountToProcess)))
        }
    }, [receivedAmount, tipAmount, amountToProcess, payFullAmount])

    useEffect(() => {
        onLoad(processButton)

        return () => {
            dispatch(setChangeDue(null))
        }
    }, [processButton])

    return (!!tab || !!check) && (
        <PaymentForm
            self={self}
            totalDue={totalDue}
        >
            <div className='row mt-3 align-items-center'>
                <div className='col-4 text-right text-bold px-0'>Amount Received</div>
                <div className='col-8'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className='input-group-text'>$</span>
                        </div>
                        <input
                            type='number'
                            min='0'
                            step='.01'
                            placeholder='0.00'
                            name='payment_received'
                            className='form-control'
                            value={receivedAmount || ''}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
        </PaymentForm>
    )
}
