import React, { useEffect, useMemo } from 'react'
import moment from 'moment-timezone'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAvailableTimesForDate, selectTimes } from '../../Availability/availabilitySlice'
import { selectParticipants, selectDuration, selectDate, selectTime, setTime, setDate } from '../scheduleSlice'
import { selectTimeZone } from '../../Locations/locationSlice'
import { selectPackage } from '../../Packages/packageSlice'

export default function BookingStartTime() {

    const dispatch         = useDispatch()
    const pkg              = useSelector(selectPackage)
    const times            = useSelector(selectTimes)
    const participants     = useSelector(selectParticipants)
    const selectedDuration = useSelector(selectDuration)
    const selectedDate     = useSelector(selectDate)
    const selectedTime     = useSelector(selectTime)
    const selectedTimeZone = useSelector(selectTimeZone)

    const current = moment.tz(selectedDate, selectedTimeZone)
    const prev    = moment.tz(selectedDate, selectedTimeZone).subtract('1', 'days')
    const next    = moment.tz(selectedDate, selectedTimeZone).add('1', 'days')

    const format = 'ddd, MMM Do'

    const handleDateChange = (newDate) => {
        dispatch(setTime(null))
        dispatch(setDate(moment(newDate).tz(selectedTimeZone).format('YYYY-MM-DD')))
    }

    const handleTimeChange = (e) => {
        dispatch(setTime(e.target.value))
    }

    const filteredTimes = useMemo(() => {
        if (!Array.isArray(times)) { return [] }

        // if this is a special event, then the bookings controller is
        // already handling this, so we can simply bypass doing it here
        if (pkg?.is_special_event) { return times }

        const now = moment.tz(selectedTimeZone)
        return times.filter((time) => moment(time[0]).tz(selectedTimeZone).isSameOrAfter(now))
    }, [selectedTimeZone, times])

    /**
     * Fetch available times for a chosen date
     */
    useEffect(() => {
        if (pkg && participants && selectedDate && selectedDuration) {
            const localizedDate = moment.tz(selectedDate, selectedTimeZone)
                                        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

            dispatch(fetchAvailableTimesForDate(selectedDuration, localizedDate, participants, `/packages/${pkg.id}/bookings/time_populate?drawer=true`, false))
        }
    }, [dispatch, pkg, selectedDuration, selectedDate, participants])

    // if the time is preloaded, select it automatically
    useEffect(() => {
        if (Array.isArray(filteredTimes) && pkg.booking_preload?.time) {
            const time = filteredTimes.find((item) => item[0] === pkg.booking_preload.time)
            if (time) { dispatch(setTime(time[0])) }
        }
    }, [dispatch, times])

    return !!selectedDate && (
        <div className="booking-start-time">
            <h3>Start Time</h3>
            <div className="booking-time-select-change-day">
                {
                    !pkg.booking_preload?.date && (
                        <div className="booking-time-day booking-time-day-prev" onClick={() => handleDateChange(prev)}>
                            <button className="btn d-none d-sm-block">
                                <i className="far fa-chevron-left mr-1" />
                                &nbsp;
                                {prev.format(format)}
                            </button>
                            <button className="btn d-sm-none">
                                <i className="far fa-chevron-left mr-1" />
                                &nbsp;
                                PREV
                            </button>
                        </div>
                    )
                }

                <div className="booking-time-day booking-time-day-current">
                    {current.format(format)}
                </div>

                {
                    !pkg.booking_preload?.date && (
                        <div className="booking-time-day booking-time-day-next" onClick={() => handleDateChange(next)}>
                            <button className="btn d-none d-sm-block">
                                {next.format(format)}
                                &nbsp;
                                <i className="far fa-chevron-right mr-1" />
                            </button>
                            <button className="btn d-sm-none">
                                NEXT
                                &nbsp;
                                <i className="far fa-chevron-right mr-1" />
                            </button>
                        </div>
                    )
                }
            </div>
            <div className="form-row">
                {
                    filteredTimes.length > 0 ? (
                        filteredTimes.map((t, i) => {
                            const time      = t[0]
                            const available = t[1]

                            return (
                                <div key={`time-${i}`} className="col-3">
                                    <label className={`radio-as-card ${available ? '' : 'disabled'}`}>

                                        <input
                                            disabled={!available}
                                            type="radio"
                                            name="time"
                                            checked={time === selectedTime}
                                            value={time}
                                            onChange={handleTimeChange}
                                        />

                                        <div className="radio-card">
                                            {moment(time).tz(selectedTimeZone).format('LT')}
                                        </div>
                                    </label>
                                </div>
                            )
                        })
                    ) : (
                        <h6 className="text-uppercase mx-auto my-5">No times available</h6>
                    )
                }
            </div>
        </div>
    )
}
