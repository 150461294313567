import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, FormProvider } from 'react-hook-form'

import {
    setGiftCards,
    submitMultipleMethodPayment,
    selectCustomerTypeCounts,
    selectCustomersToPayFor,
    selectCustomAmount,
    selectAmountType,
    setAmountType,
} from '@/features/BookingPayment/bookingPaymentSlice'

import { selectBooking, setBookingFormValue, selectCreditToken, selectCreditZip } from '@/features/Bookings/bookingSlice'
import { setLocation } from '@/features/Locations/locationSlice'
import { selectPackage } from '@/features/Packages/packageSlice'
import { selectAdyenPayment } from '@/features/Adyen/adyenSlice'

import PaymentMethods from '@/features/Bookings/components/Step6/PaymentMethods'
import PaymentInformation from './PaymentInformation'
import ParticipantInformation from './ParticipantInformation'

import {
    calculatePriceByParticipant,
    autoGratuityRate,
    calculatePriceUniform,
    taxRateAndFeesFor,
    calculatePriceByRemaining,
} from '@/lib/Pricing'

import { errorsFor } from '@/components/Form/ErrorsHelper'

export default function Step4({
    setStep,
    booking,
    location,
    customerTypes,
    paymentProcessor,
    environment
}) {

    const formMethods             = useForm()
    const dispatch                = useDispatch()
    const bookingDataForGiftCards = useSelector(selectBooking)
    const customerTypeCounts      = useSelector(selectCustomerTypeCounts)
    const customersToPayFor       = useSelector(selectCustomersToPayFor)
    const creditToken             = useSelector(selectCreditToken)
    const creditZip               = useSelector(selectCreditZip)
    const customAmount            = useSelector(selectCustomAmount)
    const amountType              = useSelector(selectAmountType)
    const pkg                     = useSelector(selectPackage)
    const adyenPayment            = useSelector(selectAdyenPayment)

    const [processing, setProcessing]                     = useState(false)
    const [errors, setErrors]                             = useState({})
    const [balanceDue, setBalanceDue]                     = useState(0)
    const [payWithoutCreditCard, setPayWithoutCreditCard] = useState(false)
    const [prices, setPrices]                             = useState(null)
    const [adyenCheckout, setAdyenCheckout]               = useState(null)

    const onError = (formErrors, _e) => {
        setErrors(errorsFor(formErrors))
    }

    const taxRateAndFees     = taxRateAndFeesFor(location, pkg, booking)
    const auto_gratuity_rate = autoGratuityRate(pkg, booking.participants)

    const pricesByParticipant = () => calculatePriceByParticipant(
        pkg,
        booking,
        customerTypeCounts,
        customerTypes,
        auto_gratuity_rate,
        taxRateAndFees.tax_rate,
        taxRateAndFees.rkd_reservation_fee,
        taxRateAndFees.merchant_reservation_fee,
        taxRateAndFees.third_party_reservation_fee,
        location,
        booking.resource_type
    )

    const pricesByUniform = () => calculatePriceUniform(
        pkg,
        booking,
        auto_gratuity_rate,
        taxRateAndFees.tax_rate,
        taxRateAndFees.rkd_reservation_fee,
        taxRateAndFees.merchant_reservation_fee,
        taxRateAndFees.third_party_reservation_fee,
        location,
        customersToPayFor,
        booking.resource_type
    )

    const PricesByRemaining = () => calculatePriceByRemaining(
        booking,
        auto_gratuity_rate,
        taxRateAndFees.tax_rate,
        taxRateAndFees.rkd_reservation_fee,
        taxRateAndFees.merchant_reservation_fee,
        taxRateAndFees.third_party_reservation_fee
    )

    const handleSubmit = () => {
        if (!payWithoutCreditCard && !creditToken && !adyenPayment) { return }

        if (!processing) {
            setProcessing(true)

            dispatch(submitMultipleMethodPayment(booking.id, creditToken, creditZip)).then(response => {
                setProcessing(false)

                if (response.success) {
                    setStep('5')
                }
            })
        }
    }

    const handleCreditCardSubmit = () => {
        if (paymentProcessor === 'paysafe') { return }

        adyenCheckout.submit()
    }

    useEffect(() => {
        if (amountType === "custom") { return }

        let pricesByAmountType

        if (pkg.pricing_type === "pricing_by_customer_type") {
            pricesByAmountType = pricesByParticipant()
        } else if (pkg.pricing_type === "uniform_pricing") {
            pricesByAmountType = pricesByUniform()
        }

        if (booking.balance_cents / 100 > pricesByAmountType.total) {
            setPrices(pricesByAmountType)
        } else {
            setPrices(PricesByRemaining())
            dispatch(setAmountType("remaining"))
        }
    }, [booking])

    const manualUpdateFormField = (name, value) => {
        dispatch(setBookingFormValue({ name: name, value: value }))
    }

    const updateFormField = e => {
        dispatch(setBookingFormValue({ name: e.target.name, value: e.target.value }))
    }

    useEffect(() => {
        dispatch(setLocation(location))
    }, [])

    useEffect(() => {
        const giftCardAmount = bookingDataForGiftCards.giftCards.reduce((sum, giftCard) => sum + giftCard.appliedAmount, 0)

        if (giftCardAmount > 0) {
            dispatch(setGiftCards(bookingDataForGiftCards.giftCards))

            const total = customAmount ? customAmount : prices.total

            setBalanceDue(total - giftCardAmount / 100)
        } else {
            setBalanceDue((prices?.total || customAmount) || 0)
        }
    }, [prices, bookingDataForGiftCards.giftCards])

    useEffect(() => {
        setPayWithoutCreditCard(balanceDue === 0)
    }, [balanceDue])

    return (
        <div className="xbm-inner">
            <div className="xbm-step-content">
                <div className="xbm-step-content-main">
                    <div className="xbm-step-question mt-3">
                        <h2 className="mb-4">Payment Information</h2>
                    </div>

                    {amountType !== "remaining" && <ParticipantInformation booking={booking} customerTypes={customerTypes} />}

                    <PaymentInformation
                        taxesAndFeesVerbiage={booking.taxes_and_fees_verbiage}
                        prices={prices}
                        balanceDue={balanceDue}
                    />

                    <FormProvider {...formMethods}>
                        <PaymentMethods
                            booking={bookingDataForGiftCards}
                            errors={errors}
                            showGiftCardFields={true}
                            customAmountToPay={prices?.total || customAmount}
                            adyenCheckout={adyenCheckout}
                            setAdyenCheckout={setAdyenCheckout}
                            paymentProcessor={paymentProcessor}
                            locationId={location?.id}
                            locationTimeZone={location?.time_zone}
                            environment={environment}
                            onNext={formMethods.handleSubmit(handleSubmit, onError)}
                            adyenOnNext={formMethods.handleSubmit(handleSubmit, onError)}
                            handleOnError={onError}
                            handleChange={updateFormField}
                            handleManualChange={manualUpdateFormField}
                        />

                        <div className="text-center">
                            {payWithoutCreditCard ? (
                                <button
                                    children='Complete Payment'
                                    className="btn btn-primary"
                                    type="button"
                                    disabled={processing}
                                    onClick={handleSubmit}
                                />
                            ) : (
                                <div className="credit-card-button-container text-center">
                                    <button
                                        children='Complete Payment'
                                        className="btn btn-primary"
                                        type="button"
                                        id="finish-button"
                                        disabled={processing}
                                        onClick={handleCreditCardSubmit}
                                    />
                                </div>
                            )}
                        </div>
                    </FormProvider>
                </div>
            </div>
        </div>
    )
}
