import React, { useRef, useMemo, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDrop } from 'react-dnd'
import axios from 'axios'
import moment from 'moment'
import { selectTimeZone } from '@/features/Locations/locationSlice'
import {
    selectSizeWidth,
    selectTimesForLocation,
    setCurrentDragOutlineClasses,
    selectLocationSpecialHours,
} from '@/features/Calendar/calendarSlice'
import {
    selectBookings,
    setActiveDrag,
    selectActiveDrag,
    moveBooking,
    selectIsGrabbed,
} from '@/features/CalendarBookings/calendarBookingsSlice'
import { selectResources } from '@/features/Resources/resourcesSlice'
import { useConfirm } from '@/lib/useConfirmHook'
import { dragTypes } from '@/lib/Drag'
import { calculatedDragOutlineClasses } from '@/lib/CalendarTimes'
import { isBookingQuote } from '@/lib/Booking'
import { debug } from '@/lib/Debug'

export default function Time({ time, lane, index }) {

    const { confirm } = useConfirm()
    const dispatch    = useDispatch()

    const selectedTimeZone = useSelector(selectTimeZone)
    const timesForLocation = useSelector(selectTimesForLocation)
    const sizeWidth        = useSelector(selectSizeWidth)
    const resources        = useSelector(selectResources)
    const bookings         = useSelector(selectBookings)
    const activeDrag       = useSelector(selectActiveDrag)
    const isGrabbed        = useSelector(selectIsGrabbed)
    const specialHours     = useSelector(selectLocationSpecialHours)

    const [callback, setCallback] = useState(null)
    const [booking, setBooking]   = useState(null)

    const isOpen  = useRef(null)
    const classes = useRef('')

    useEffect(() => {
        if (isOpen.current === null && classes.current === '') {
            const date = time.tz(selectedTimeZone).format('DD-MM-YYYY')
            const specialHourForDate = specialHours[date]

            const format           = 'dddd'
            const day_of_week      = time.tz(selectedTimeZone).format(format).toLowerCase()
            const open_time        = specialHourForDate ? specialHours[date].open_time : timesForLocation[day_of_week].open_time
            const close_time       = specialHourForDate ? specialHours[date].close_time : timesForLocation[day_of_week].close_time
            const closed_all_day   = specialHourForDate ? specialHours[date].all_day : timesForLocation[day_of_week].all_day
            const prior_close_time = timesForLocation[time.clone().tz(selectedTimeZone).subtract(1, 'day').format(format).toLowerCase()].close_time
            const cell_time        = parseFloat(time.tz(selectedTimeZone).format('HH.mm'), 10)

            switch(true) {
                // for the moment, ignore if the current day is closed all day or not and
                // check if this cell should be open due to the prior day closing past midnight?
                case (prior_close_time > 0 && prior_close_time < open_time) && (cell_time < prior_close_time) && (cell_time < open_time) :
                    isOpen.current = true
                    break

                // unless the location is closed all day - if we're closing AFTER midnight, is this cell open during a normal range?
                //
                // note: we cutoff at 2359 instead of using the close_time because in this case, the close time is a smaller number
                // due to it closing the NEXT DAY / AFTER MIDNIGHT which means our math stops being....correctly math-ey.
                case !closed_all_day && (open_time > close_time) && (cell_time >= open_time && cell_time < 23.59) :
                    isOpen.current = true
                    break

                // unless the location is closed all day - if we're closing BEFORE midnight, is this cell open?
                case !closed_all_day && (open_time < close_time) && (cell_time >= open_time && cell_time < close_time) :
                    isOpen.current = true
                    break
            }

            classes.current = `time-block lane-${lane} time-${time.tz(selectedTimeZone).unix()} w-100 h-100 ${!isOpen.current ? 'closed' : ''}`
        }
    }, [])

    const styles = useMemo(() => ({
        width:  `${sizeWidth}px`,
        zIndex: activeDrag || isGrabbed ? 2 : 1,
        opacity: activeDrag && !isOpen.current ? 0.5 : 1
    }), [sizeWidth, activeDrag, isGrabbed, isOpen.current])

    const handleHover = (item, monitor) => {
        if (!booking) {
            setBooking(item)
        }
    }

    const handleDrop = (item, monitor) => {
        // here i think we need to do some validation on whether they can even drop it here or not.
        // i think what we essentially need to do is grab:
        //     1.) the number of lanes the booking has (activeDrag.resources)
        //     2.) the total resources
        //     3.) the index of the resource they dropped it on
        //     4.) is the index of the resource they dropped it on + the amount of resources -1? return
        // const numberOfLanes     = activeDrag.resources.length
        const resourceIds       = resources.map(r => r.id)
        const dropResourceIndex = resourceIds.indexOf(lane) + 1
        //const dropIndex         = resourceIds.indexOf(lane)

        // if (resourceIds.indexOf(dropIndex + numberOfLanes) === -1) {
        //     console.log('NOT ENOUGH SPACE TO DROP THIS BOOKING!')
        //     // the dnd package will break if the drop handler doesn't return either an object or undefined...
        //     return undefined
        // }

        if (console && debug) { console.log(`Dropping booking onto resource index: ${dropResourceIndex}`) }

        // we have to find the booking addon value from the booking itself in state -- not the booking that was attached to the
        // drag state -- unfortunately that is not updated since it doesn't really re-render like that. If durations are also
        // messed up we would also have to change that there...
        // just in case i did the same with duration -- who knows
        const abook    = (bookings.filter(b => b.booking.id === item.id)[0]).booking
        const addon    = abook.addon_minutes
        const duration = abook.duration

        // here we actually have to modify the time to be the time they dropped minus the booking duration...
        // we also have to add 15 minutes to account for the end time... the 5:45 block actually spans/ends
        // until 6:00, so we have to add 15 before subtracting the duration minutes
        const adjustedStartTime = moment(time).add(15, 'minutes').subtract(duration, 'minutes').subtract((addon || 0), 'minutes')

        // determine if we're dropping the booking back onto the same spot or not
        const hasSameResources       = item.resource_row === dropResourceIndex
        const hasSameStartTime       = moment(item.start_time).unix() === adjustedStartTime.unix()
        const isDroppingOntoSameSpot = hasSameResources && hasSameStartTime

        if (isDroppingOntoSameSpot) {
            if (debug && console) { console.log('Prevented moving the booking onto the same datetime/resource') }

            // react-dnd is expecting either an object or
            // undefined to be returned, anything else will cause an error
            return undefined
        } else {
            if (debug && console) {
                console.log(`>> Same resource(s)? ${hasSameResources}`, item.resources, dropResourceIndex)
                console.log(`>> Same time? ${hasSameStartTime}`, moment(item.start_time).unix(), adjustedStartTime.unix())
            }
        }

        if (debug && console) {
            console.log(`>> Moving booking [${item.id}] to resource [${dropResourceIndex}] @ start datetime [${adjustedStartTime.toString()}]`)
        }

        // finally, send the request to move the booking
        dispatch(moveBooking(item.id, lane, adjustedStartTime))
        .then(async data => {
            if (data.success && !hasSameStartTime && !isBookingQuote(item)) {
                if (await confirm('Do you want to let the customer know about the change?')) {
                    axios.get(`/bookings/${item.id}/notify_changed`)
                }
            }
        })
    }

    // the drop hook for bookings
    const [{ isOver }, dropRef] = useDrop(() => ({
        accept: dragTypes.BOOKING,
        drop: handleDrop,
        hover: handleHover,
        collect: (monitor) => ({
            isOver: !!monitor.isOver()
        })
    }), [activeDrag, time, lane, index])

    useEffect(() => {
        if (isOver) {
            // if our component is marked as being hovered over, the first thing we have to do is make sure
            // we have our booking set -- otherwise we have to just wait and return
            if (!booking) { return }

            // SLIGHTLY THROTTLE DRAGGING THE BOOKING
            // if we are over this time block, we set a timeout to dispatch the information,
            // making sure they're not just going crazy and breaking the site - this makes it behave a lot better
            setCallback(window.setTimeout(() => dispatch(setActiveDrag(true)), 50))

            // BUT DO NOT THROTTLE SETTING THE CSS CLASSES FOR THE DRAG OUTLINE
            dispatch(setCurrentDragOutlineClasses(calculatedDragOutlineClasses(lane, time, booking?.duration + booking?.addon_minutes, selectedTimeZone)))
        } else {
            if (!!callback) {
                window.clearTimeout(callback)
                setCallback(null)
            }

            if (!!booking) {
                setBooking(null)
            }
        }

        return () => {
            window.clearTimeout(callback)
        }
    }, [isOver])

    return (
        <div ref={dropRef} data-time={index} style={styles}>
            <div className={classes.current} />
        </div>
    )
}
