import { sortedByArray } from '@/lib/Array'

export function generatePrinterOptions(printers=null) {
    if (!printers) { return [] }

    const tmpPrinters = [ ...(printers?.receipt || []), ...(printers?.multi_purpose || []) ].map((p) => ({
        id: p.id,
        name: `${p.name} ${p.fulfillment_provider_name ? `@ ${p.fulfillment_provider_name} ` : ''}(${p.is_online ? 'Online' : 'Offline'})`
    }))

    return sortedByArray(tmpPrinters, tmpPrinters.map((p) => p.id).sort(), 'id')
}

export function acknowledgementText(data=null, jobs_created=1) {
    if (!data) { return '' }

    return `
        <h5 class='mb-3'>${data.errors.length === 1 ? 'An error' : 'Multiple errors'} occurred while processing this request.</h5>
        <p>
            While the print ${jobs_created === 1 ? 'job has been enqueued, it' : 'jobs have been enqueued, they'}
            <strong class='font-italic'>will not print</strong><br />
            until the following ${data.errors.length === 1 ? 'problem has' : 'problems have'} been fixed.
        </p>
        <ul class='list-unstyled h6 text-center text-monospace mt-4 mb-n3 pt-2'>
            ${data.errors.map((error) => `<li>${error}</li>`).join('')}
        </ul>
    `.trim()
}
