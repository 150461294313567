// https://stackoverflow.com/questions/384286/how-do-you-check-if-a-javascript-object-is-a-dom-object#:~:text=You%20could%20try%20if%20you,are%20both%20native%20object%20functions.&text=When%20you%20call%20isElement(0,0%2C%20not%20false...
export function isElement(obj=null) {
    try {
        // Using W3 DOM2 (works for FF, Opera and Chrome)
        return obj instanceof HTMLElement
    }
    catch(e){
        // Browsers not supporting W3 DOM2 don't have HTMLElement and
        // an exception is thrown and we end up here. Testing some
        // properties that all elements have (works on IE7)
        return (typeof obj==="object")
               && (obj.nodeType===1) && (typeof obj.style === "object")
               && (typeof obj.ownerDocument ==="object")
    }
}

// https://youmightnotneedjquery.com/#outer_height
export function outerHeight(el) {
    if (!el || !isElement(el)) { return null }

    const style = getComputedStyle(el)

    return (
        el.getBoundingClientRect().height
        + parseFloat(style.marginTop)
        + parseFloat(style.marginBottom)
        + parseFloat(style.borderTopWidth)
        + parseFloat(style.borderBottomWidth)
    )
}
