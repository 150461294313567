import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentCheck } from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'
import { isItemModified, calculatePriceAndDiscountedPrice } from '@/features/AdvancedPointOfSale/lib/Items'
import { numberToCurrency } from '@/lib/Number'

export default function ItemCard({ item=null, tab=null, showAfterTax=true, className='' }) {

    const check         = useSelector(selectCurrentCheck)
    const tabName       = !!tab ? (tab.is_general && check.booking?.name || tab.name) : ''
    const comp          = check?.comps?.find(comp => comp.item_uuid === item?.item?.uuid)
    const discountNotes = item?.discount?.notes

    const displayPrice = useMemo(() => {
        if (/^(approved|pending)$/i.test(comp?.status)) {
            if (/^pending$/i.test(comp?.status)) {
                return (<span>COMP PENDING</span>)
            } else {
                return (<span>COMPED</span>)
            }
        } else {
            let price = calculatePriceAndDiscountedPrice(item?.item, item?.discount, showAfterTax, false, item?.refunded_cents).price

            if ((item?.item?.discount_cents || 0) > 0) {
                return (<>
                    <strong className='text-success'>{numberToCurrency(price)}</strong>
                    <small className='d-block font-italic text-success ml-1'>Discounted</small>
                </>)
            } else if (isItemModified(item, check)) {
                return (<>
                    <strong>{numberToCurrency(price)}</strong>
                    <small className='d-block font-italic text-success ml-1'>Modified</small>
                </>)
            } else {
                return (<>
                    { numberToCurrency(item?.amount_type === 'credit' ? price * -1 : price) }
                </>)
            }
        }
    }, [item, showAfterTax, check])

    return !!check && !!tab && !!item && (
        <div className={`item-card py-2 px-3 ${className}`.trim()}>
            <div className='d-flex justify-content-between'>
                <span>
                    <strong>{item?.item?.name}</strong>
                    <small className='d-block font-italic'>{tabName}</small>
                </span>
                <span className='text-right'>
                    {displayPrice}
                </span>
            </div>

            {
                (!!item.notes || !!discountNotes) && (
                    <div className='item-notes font-italic border-top mt-3 pt-1 px-1 text-gray6 border-color-gray5'>
                        { !!item.notes ? <p className='my-1'>{item.notes}</p> : '' }
                        { !!discountNotes ? <p className='my-1'>{discountNotes}</p> : '' }
                    </div>
                )
            }
        </div>
    )
}
