import React, { useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { debug } from '@/lib/Debug'

export default function ExistingCustomerWaiver({ company, customer, waiver, handleShowAdult, handleShowMinor, handleSign }) {

    const formMethods = useFormContext()

    const [waiverStatus, setWaiverStatus] = useState("")

    useEffect(() => {
      if (waiver.always_force_new_signature) {
        setWaiverStatus("needs_new_signature")
        return
      }

      const matchedSignedWaivers = customer.signed_waivers.filter(signedWaiver => signedWaiver.waiver_id === waiver.id)

      if (matchedSignedWaivers.length > 0) {
        const latestSignedWaiver = matchedSignedWaivers.reduce((a, b) => a.updated_at > b.updated_at ? a : b)

        if (latestSignedWaiver.is_valid && latestSignedWaiver.is_active) {
          setWaiverStatus("active_and_signed")
        } else if (!latestSignedWaiver.is_valid && latestSignedWaiver.is_active) {
          setWaiverStatus("needs_new_signature")
        } else {
          setWaiverStatus("expired")
        }
      } else {
        setWaiverStatus("no_waiver")
      }
    }, [customer, waiver])

    return (
        <div className="col-xl-3 col-lg-4 col-sm-6 col-12 mb-3" value={formMethods.watch("customer_id", `${customer.id}`)}>
            <div className="matching_waiver" data-mh='matching-waiver'>
              { debug && customer.id }
              <div className="name">{customer.name}</div>
              <small className="email" style={{ overflowWrap: "break-word" }}>{customer.email}</small>

              <div className='pt-3'>
                <span className='d-block w-100'>{ customer.total_hours } { customer.total_hours === 1 ? 'Hour' : 'Hours' }</span>
                <span className='d-block w-100'>{ customer.total_visits } { customer.total_visits === 1 ? 'Visit' : 'Visits' }</span>
              </div>

              { waiverStatus === "active_and_signed" ?
                <div>
                  <div className="type_expiration">
                    {customer.is_minor ? "Minor" : "Member"}
                  </div>
                  <p className="text-success mb-0">Active waiver exists</p>
                  <input
                    {...formMethods.register('customer_id')}
                    type="hidden"
                    name="customer_id"
                    value={`${customer.id}`}
                  />
                </div>
              :
                <div>
                  <div className="type_expiration">
                    {customer.is_minor ? "Minor" : "Member"}
                    <p className="text-danger mb-0">{waiverStatus === "expired" ? "Your waiver is expired" : (waiverStatus === "needs_new_signature" ?  "Need New Signature" : "No signed waiver exists")}</p>
                  </div>
                </div>
              }
            </div>

            <div className='mt-3 mb-4 w-100 submit-existing-waiver-button'>
            { waiverStatus === "active_and_signed" ?
              <button
                type='submit'
                className='btn btn-outline-primary submit-existing-waiver btn-block'
                onSubmit={handleSign}
              >
                SUBMIT YOUR WAIVER
              </button>
              :
              <>
              {company.allow_minor_waivers && customer.is_minor ? (
                <button name="button" type="button" className="btn btn-outline-primary btn-block" onClick={() => handleShowMinor({ isReturning: true, customer: customer })}>
                  {waiverStatus === "needs_new_signature" ? "Re-Submit Your Waiver" : "SIGN A NEW WAIVER"}
                </button>
              ) : (
                <button name="button" type="button" className="btn btn-outline-primary btn-block" onClick={() => handleShowAdult({ isReturning: true, customer: customer })}>
                  {waiverStatus === "needs_new_signature" ? "Re-Submit Your Waiver" : "SIGN A NEW WAIVER"}
                </button>
              )}
              </>
            }
            </div>
        </div>
    )
}
