import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectBalanceDue,
  setZip,
  setReceiptEmail,
  selectDiscount,
  selectTotal,
  selectDefaultDiscount,
  selectHasPromotionWithCode,
} from '@/features/GiftCards/giftCardSlice'
import { errorMessageFor } from '@/components/Form/ErrorsHelper'
import PurchaseModalCreditCard from '@/features/GiftCards/components/PurchaseModalCreditCard'
import AdyenCreditCard from '@/features/Adyen/AdyenCreditCard'
import { validationPatterns } from '@/lib/Errors'
import { numberToCurrency } from '@/lib/Number'
import PromoCode from './PromoCode'

export default function PurchaseModalPayment({
    companyId,
    locationId,
    locationTimeZone,
    environment,
    paymentProcessor,
    adyenCheckout,
    setAdyenCheckout
}) {

    const { register, watch, formState: { errors, isValid } } = useFormContext()
    const receiptEmail = watch('receipt_email')
    const zip          = watch('zip')

    const dispatch   = useDispatch()
    const balanceDue = useSelector(selectBalanceDue)
    const discount = useSelector(selectDiscount)
    const defaultDiscount = useSelector(selectDefaultDiscount)
    const total = useSelector(selectTotal)
    const hasPromotionWithCode = useSelector(selectHasPromotionWithCode)

    useEffect(() => {
        dispatch(setZip(zip))
        dispatch(setReceiptEmail(receiptEmail))
    }, [zip, receiptEmail])

    const discountAmount = () => {
        if (discount.amount_type === 'percent') {
            return ` (-${discount.amount}%)`
        }

        return ''
    }

    const renderCreditCardForm = () => (
        paymentProcessor === 'adyen' ? (
            <AdyenCreditCard
                locationId={locationId}
                locationTimeZone={locationTimeZone}
                environment={environment}
                captureZipCode={false}
                adyenCheckout={adyenCheckout}
                setAdyenCheckout={setAdyenCheckout}
            />
        ) : (
            <PurchaseModalCreditCard />
        )
    )

    const renderSubmitButton = () => (
        paymentProcessor === 'adyen' ? (
            <button className="btn btn-primary btn-block mt-4" type="submit">
                Submit and Pay
            </button>
        ) : (
            <button
                id="finish-button"
                className="btn btn-primary btn-block payment-button mt-4"
                type="submit">
                Submit and Pay
            </button>
        )
    )

    return (
        <div className="col-12 col-md-4 mt-4 mt-md-0">
            <div className="row">
                <div className="col-12">
                    {hasPromotionWithCode &&
                        <PromoCode companyId={companyId} locationId={locationId} />
                    }
                    <h5 className="d-flex justify-content-between w-100">
                        <span>Total:</span>
                        <span>{ numberToCurrency(total) }</span>
                    </h5>
                    {Object.keys(discount).length !== 0 && total > 0 &&
                        <div className="d-flex justify-content-between w-100 mb-2">
                            {defaultDiscount?.id === discount.id ?
                            <span>Promotion Applied{discountAmount()}</span>
                            :
                            <span>Promo Code Applied{discountAmount()}</span>
                            }
                            <span>- {numberToCurrency(total - balanceDue)}</span>
                        </div>
                    }
                    <h5 className="d-flex justify-content-between w-100">
                        <span>Balance Due:</span>
                        <span>{ numberToCurrency(balanceDue) }</span>
                    </h5>

                    <hr className="mt-3 mb-4" />
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="bill-summary">
                        <h4 className="mb-4">Payment Information</h4>

                        {renderCreditCardForm()}

                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="zip">
                                        Zipcode
                                        <span className="req">*</span>
                                    </label>
                                    <input
                                        {...register('zip', {
                                            required: 'A zip code is required.',
                                            pattern: validationPatterns.zip
                                        })}
                                        id="zip"
                                        className={`form-control match-stripe ${errors?.zip ? 'is-invalid' : ''}`}
                                        minLength='5'
                                        maxLength='10'
                                        autoComplete="postal-code"
                                    />
                                    { errorMessageFor('zip', errors) }
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="receipt-email">
                                        Email
                                        <span className="req">*</span>
                                    </label>
                                    <input
                                        {...register('receipt_email', {
                                            required: 'A receipt email address is required.',
                                            pattern: validationPatterns.email
                                        })}
                                        id="receipt-email"
                                        className={`form-control match-stripe ${errors?.receipt_email ? 'is-invalid' : ''}`}
                                        placeholder="Receipt Email"
                                        autoComplete="email"
                                    />
                                    { errorMessageFor('receipt_email', errors) }
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                {renderSubmitButton()}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
