import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCheckNumber, setCheckNumber } from './productSlice'

export default function ProductCheck() {

    const dispatch = useDispatch()

    const number = useSelector(selectCheckNumber)

    return (
        <div className="form-row">
            <div className="col-12">
                <div className="form-group">
                    <label>Check Number</label>
                    <input
                        name='check_number'
                        className='form-control'
                        placeholder='Check Number...'
                        value={number || ""}
                        onChange={e => dispatch(setCheckNumber(e.target.value))} />
                </div>
            </div>
        </div>
    )
}
