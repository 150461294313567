import React, { useMemo, useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { numberToCurrency } from '@/lib/Number'

export default function AmountFields({
    index='',
    amounts=[],
    inputPrefix='',
    handleChange=() => {}
}) {
    const { register, formState: { errors } } = useFormContext()

    const fieldPrefix = inputPrefix !== '' ? `${inputPrefix}.` : ''

    const [amount, setAmount]             = useState(0)
    const [customAmount, setCustomAmount] = useState(null)

    const amountField = register(`${fieldPrefix}${index}.amount`, {
        required: 'An amount is required.',
        validate: (v) => {
            return (/other/i.test(v) || Number(v) > 0) || 'Please choose an amount.'
        }
    })

    const customAmountField = useMemo(() => {
        if (/other/i.test(amount)) {
            return register(`${fieldPrefix}${index}.custom_amount`, {
                required: 'An amount is required.',
                validate: { positive: (v) => Number(v) > 0 || 'Please specify a valid amount.' }
            })
        } else {
            return register(`${fieldPrefix}${index}.custom_amount`)
        }
    }, [amount, customAmount])

    const amountOptions = useMemo(() => <>
        <option value="0" disabled>———</option>
        {
            amounts.map((amount) => (
                <option key={amount} value={amount} className="text-capitalize">
                    { numberToCurrency(amount, { precision: 0 }) }
                </option>
            ))
        }
        <option value="other">Other</option>
    </>, [amounts])

    useEffect(() => {
        handleChange(/other/i.test(amount) ? customAmount : amount)
    }, [amount, customAmount])

    return (
        <div className="form-group">
            <label htmlFor={`${inputPrefix}amount-${index}`}>
                Select Amount
                <span className="req">*</span>
            </label>

            <div className="form-row">
                <div className={/other/i.test(amount) ? 'col-5' : 'col-12'}>
                    <select
                        {...amountField}
                        id={`${inputPrefix}amount-${index}`}
                        className={`custom-select ${(errors?.[inputPrefix]?.[index]?.amount || errors?.[index]?.amount || errors?.amount) ? 'is-invalid' : ''}`}
                        value={amount}
                        onChange={(e) => {
                            amountField.onChange(e)
                            setAmount(e.target.value)
                        }}
                    >
                        { amountOptions }
                    </select>
                </div>

                {
                    /other/i.test(amount) &&
                        <div className="col-7">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">$</span>
                                </div>
                                <input
                                    {...customAmountField}
                                    className={`custom-select ${(errors?.[inputPrefix]?.[index]?.custom_amount || errors?.[index]?.custom_amount || errors?.custom_amount) ? 'is-invalid' : ''}`}
                                    type="number"
                                    value={customAmount || ''}
                                    min={0.0}
                                    step={0.01}
                                    autoFocus={true}
                                    onChange={(e) => {
                                        customAmountField.onChange(e)
                                        setCustomAmount(e.target.value ? Number(parseFloat(e.target.value).toFixed(2)) : null)
                                    }}
                                />
                            </div>
                        </div>
                }
            </div>

            {
                /other/i.test(amount) ? (
                    (errors?.[inputPrefix]?.[index]?.custom_amount || errors?.[index]?.custom_amount || errors?.custom_amount) &&
                        <div className="invalid-feedback d-block mx-2">
                            { errors?.[inputPrefix]?.[index]?.custom_amount.message }
                            { errors?.[index]?.custom_amount?.message }
                            { errors?.custom_amount?.message }
                        </div>
                ) : (
                    (errors?.[inputPrefix]?.[index]?.amount || errors?.[index]?.amount || errors?.amount) &&
                        <div className="invalid-feedback d-block mx-2">
                            { errors?.[inputPrefix]?.[index]?.amount.message }
                            { errors?.[index]?.amount?.message }
                            { errors?.amount?.message }
                        </div>
                )
            }
            {errors && errors.cards &&
                <div className="invalid-feedback d-block mx-2">
                    { errors.cards[index]?.amount?.message }
                    { errors.cards[index]?.custom_amount?.message }
                </div>
            }
        </div>
    )
}
