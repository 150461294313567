import React from 'react'
import { Provider } from 'react-redux'
import memberStore from '@/stores/memberStore'
import { SELECTED_LOCATION } from '@/lib/Storage'
import MembershipForm from './MembershipForm'
import NotificationList from '@/features/Notifications/NotificationList'

export default function MembershipFormContainer({
    location,
    membershipTypeOptions,
}) {
    window.localStorage.setItem(SELECTED_LOCATION, location.id)

    return (
        <Provider store={memberStore}>
            <MembershipForm
                membershipTypeOptions={membershipTypeOptions}
                location={location}
            />
            <NotificationList />
        </Provider>
    )
}
