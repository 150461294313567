import c from 'capitalize'

/**
 * Convert snake_case string to a "Title Case" string.
 *
 * @param str snake_case string to convert
 * @returns Title Case string
 */
export function titleize(str, separator='_') {
    const string = typeof str !== 'string' ? String(str) : str
    return string.split(separator).map(i => c(i)).join(' ')
}

/**
 * Convert snake_case string to a "camelCase" string.
 *
 * @param str snake_case string to convert
 * @returns Title Case string
 */
export function camelCase(str) {
    const string = typeof str !== 'string' ? String(str) : str
    return string.split('_').map((text, index) => { return (index > 0) ? c(text) : text }).join('')
}

export function humanizedLabel(string, html=false, minLength=15, maxFirstHalfPieces=2) {
    if (typeof string !== 'string' || string?.length < minLength) { return string }

    if (/[\(|\)]/.test(string)) {
        let humanized = String(string).split('(')

        return html
            ? humanized.join('<br />(')
            : humanized.join('\n()')
    } else {
        const pieces     = String(string).split(/\s/)
        const firstHalf  = pieces.filter((p,i) => i <  maxFirstHalfPieces).join(' ')
        const secondHalf = pieces.filter((p,i) => i >= maxFirstHalfPieces).join(' ')

        return html
            ? [firstHalf, secondHalf].join('<br />')
            : [firstHalf, secondHalf].join('\n')
    }
}

export default titleize
