import React, { useState, useRef, useEffect } from 'react'
import { useConfirm } from '@/lib/useConfirmHook'
import { debug } from '@/lib/Debug'
import * as storage from '@/lib/Storage'

export default function LogoutButton({
    className='',
    theme='complex',
    username='',
    iconOnly=false,
    icon=null,
    output=null,
    logout_path:logoutPath=null,
    children
}) {

    const logoutRef         = useRef()
    const { confirm }       = useConfirm()
    const [ready, setReady] = useState(false)

    const handleLogout = async (e) => {
        if (!ready) {
            e.stopPropagation()
            e.preventDefault()

            if ((await confirm('Are you sure you want to logout?', {
                header_text: 'Please Confirm...',
                dangerous: false,
                style: { zIndex: 999999 }
            }))) {
                // we do not want to clear out browser storage when running in
                // production, but this is extremely helpful when developing
                if (debug) {
                    window.sessionStorage.clear()

                    // we don't want to clear ALL localStorage though,
                    // we want to save some things for testing purposes
                    const exceptions = [storage.ADV_POS_SESSION]
                    const tmp        = []

                    // remember the exceptions temporarily
                        exceptions.forEach((key, index) => { tmp[index] = window.localStorage.getItem(key) })
                    // clear it all
                        window.localStorage.clear()
                    // restore the exceptions
                        exceptions.forEach((key, index) => { window.localStorage.setItem(key, tmp[index]) })
                }
                setReady(true)
            }
        }
    }

    useEffect(() => {
        if (ready) {
            logoutRef.current.click()
        }
    }, [ready])

    return !!logoutPath && <>
        {
            /^complex$/i.test(theme) && (
                <div className={`action log-out ${className}`.trim()} data-toggle="tooltip" data-placement="bottom" title="Log Out">
                    {
                        !iconOnly && !output && (
                            <div className="micro-label">Logged In As</div>
                        )
                    }
                    <a href={logoutPath} rel="nofollow" data-method="delete" onClick={handleLogout} ref={logoutRef}>
                        {
                            !!output || !!children
                                ? output || children
                                : <span className="action-value d-block">
                                    { !iconOnly && username }
                                    { !!icon ? icon : <i className="far fa-sign-out-alt ml-1" /> }
                                </span>
                        }
                    </a>
                </div>
            )
        }

        {
            /^simple$/i.test(theme) && (
                <a
                    href={logoutPath}
                    className={className}
                    children={children || 'Logout'}
                    rel="nofollow"
                    data-method="delete"
                    onClick={handleLogout}
                    ref={logoutRef}
                />
            )
        }
    </>
}
