import React, { useEffect, useState, useRef } from 'react'
import PackageTabs from './PackageTabs'
import PackageSetupTab from './PackageSetupTab'
import PackagePricingTab from './PackagePricingTab'
import PackageAdvancedSettingsTab from './PackageAdvancedSettingsTab'
import SpecialPricingTab from './SpecialPricingTab'
import { useConfirm } from '@/lib/useConfirmHook'

export default function PackageForm({
    form,
    errors,
    resource,
    resources,
    customerTypes,
    location,
    new: newPackage,
    setBeforeSave,
    maxBookableFutureMonths,
    specialEventIsMutable,
    origIsActive,
    membershipTypesUrl,
    handleFormUpdate,
    handleUpdate,
    handleCheckboxUpdate,
    handleManualUpdate,
}) {

    const [tab, setTab]                           = useState('setup')
    const [setupTabLoaded, setSetupTabLoaded]     = useState(false)
    const [pricingTabLoaded, setPricingTabLoaded] = useState(false)

    const isActive                = useRef(form.active)
    const origAmountPricingGroups = useRef(form?.groups?.length || 0)
    const initialConfigSetRef     = useRef(null)

    /**
     * The increment is what keeps track of the internal "duration id" that is set in order to properly
     * map durations to price grid entries. This is needed since they wanted to change durations from dropdowns
     * to number incrementers, which means we can't find anything dealing between durations & their price grid
     * entries by duration amount. We can't use indexes since those are obviously different, so we need something
     * else that is shared between duration entries and individual duration price grid entries... the internal duration._id
     */
    const [durationIncrementer, setDurationIncrementer] = useState(form.durations && form.durations.length ? (Math.max(...form.durations.map(d => d._id)) + 1) : 1)

    const { confirm } = useConfirm()

    const handleSelectTab = (tab) => {
        setTab(tab)
    }

    const handleBeforeSave = async () => {
        if (origIsActive && !isActive.current && form.resource_exclusive_to_package && !newPackage) {
            if (
                await confirm(
                    '<p>Deactivating a special event will cancel all reservations related to this package and payments will not be automatically refunded.</p>' +
                    '<p class="my-5 w-100 text-center text-danger">This action is permanent and cannot be undone.</p>' + 
                    '<p>Do you wish to continue?</p>',
                    { header_text: 'Warning!', size: 'xl', dangerous: true }
                )
            ) {
                return true
            }
        } else {
            return true
        }
    }

    /**
     * Inject our callback function into the
     * beforeSave recordDrawer form lifecycle hook
     */
    useEffect(() => {
        setBeforeSave({ fn: handleBeforeSave })
        return () => setBeforeSave(null)
    }, [])

    useEffect(() => {
        isActive.current = form.active
    }, [form.active])

    const renderTab = () => {
        switch (tab) {
            case 'setup':
                return (
                    <PackageSetupTab
                        form={form}
                        errors={errors}
                        resource={resource}
                        resources={resources}
                        location={location}
                        durationIncrementer={durationIncrementer}
                        setDurationIncrementer={setDurationIncrementer}
                        maxBookableFutureMonths={maxBookableFutureMonths}
                        specialEventIsMutable={specialEventIsMutable}
                        newPackage={newPackage}
                        loaded={setupTabLoaded}
                        initialConfigSetRef={initialConfigSetRef}
                        handleTabLoaded={() => setSetupTabLoaded(true)}
                        handleInitialConfigSet={(value) => initialConfigSetRef.current = value}
                        handleUpdate={handleUpdate}
                        handleCheckboxUpdate={handleCheckboxUpdate}
                        handleManualUpdate={handleManualUpdate}
                        handleFormUpdate={handleFormUpdate}
                    />
                )
            case 'pricing':
                return (
                    <PackagePricingTab
                        handleUpdate={handleUpdate}
                        handleCheckboxUpdate={handleCheckboxUpdate}
                        handleManualUpdate={handleManualUpdate}
                        handleFormUpdate={handleFormUpdate}
                        form={form}
                        errors={errors}
                        location={location}
                        resources={resources}
                        customerTypes={customerTypes}
                        newPackage={newPackage}
                        loaded={pricingTabLoaded}
                        origAmountPricingGroups={origAmountPricingGroups.current}
                        membershipTypesUrl={membershipTypesUrl}
                        handleTabLoaded={() => setPricingTabLoaded(true)}
                    />
                )
            case 'advanced_settings':
                return (
                    <PackageAdvancedSettingsTab
                        location={location}
                        resource={resource}
                        form={form}
                        errors={errors}
                        handleUpdate={handleUpdate}
                        handleCheckboxUpdate={handleCheckboxUpdate}
                        handleManualUpdate={handleManualUpdate}
                    />
                )
            case 'special_pricing':
                return (
                    <SpecialPricingTab
                        handleManualUpdate={handleManualUpdate}
                        form={form}
                        errors={errors}
                        customerTypes={customerTypes}
                        location={location}
                        isNew={newPackage} />
                )
            default:
                return <h1>{tab} Not Implemented Yet</h1>
        }
    }

    return (
        <div>
            <PackageTabs
                activeTab={tab}
                tabs={['setup', 'pricing', 'special_pricing', 'advanced_settings']}
                handleClick={handleSelectTab}
            />

            {renderTab()}
        </div>
    )
}
