import React from "react";
import CustomerWaiversNameDOBZip from "./CustomerWaiversNameDOBZip";
import Input from "../Form/Input";
import {useFormContext} from "react-hook-form";

export default function CustomerWaiversAdditionalMinor({
  adult_or_minor,
  errors,
  minor,
  handleDelete,
  index,
  waiver,
}) {
  const formMethods = useFormContext()

  return (
    <div className="additional_minor_template">
      <div className="additional_minor">

        <hr className="mt-3 mb-4" style={{ background: '#ffffff' }} />

        <CustomerWaiversNameDOBZip
          additional_minor={true}
          name='additional-minor'
          adult_or_minor={adult_or_minor}
          handleDelete={handleDelete}
          minor={minor}
          errors={errors}
          index={index}
          waiver={waiver}
        />

        <div className="row">
          <div className="col-lg-6">
            <div className="waiver-item">RELATIONSHIP TO PARENT OR GUARDIAN</div>
            <Input
              name={`relationship-${index}`}
              placeholder="Relationship"
              hideLabel={true}
              value={formMethods.watch(`relationship-${index}`, '')}
              errors={errors}
              validation={{ [`relationship-${index}`]: { required: true }}}
            />
          </div>
        </div>

      </div>
    </div>
  )
}
