import React from 'react'
import titleize from '@/lib/String'

export default function PackageTabs({ activeTab, tabs, handleClick }) {

    return (
        <div className="drawer-tabs">
            {
                tabs.map((tabName) => (
                    <div
                        key={tabName}
                        className={`tab ${tabName === activeTab ? 'active' : ''}`}
                        onClick={() => handleClick(tabName)}
                    >
                        {titleize(tabName)}
                    </div>
                ))
            }
        </div>
    )
}
