import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, FormProvider } from 'react-hook-form'
import { selectPartialAlert } from '../Terminal/terminalSlice'

import {
    calculatePrice,
    fetchProducts,
    selectError,
    selectOrder,
    selectPricing,
    setLocationId,
    setLocationTaxRate,
    setLocationPaymentProcessor,
    clearFields
} from './productSlice'

import { clear } from '../MemberProductDiscount/MemberProductDiscountSlice'
import { setMemberDiscount } from './productSlice'

import Products from './Products'
import ProductLedger from './ProductLedger'
import ProductPayment from './ProductPayment'
import PartialAuthAlert from '../Terminal/PartialAuthAlert'

export default function ProductTransaction({
    productsPath,
    purchasePath,
    locationId,
    locationTaxRate,
    locationTimeZone,
    locationPaymentProcessor,
    environment,
    hardwareKey,
    hidePartialAlert,
    inBooking,
    previewMode,
}) {

    const formMethods  = useForm()
    const dispatch     = useDispatch()
    const order        = useSelector(selectOrder)
    const pricing      = useSelector(selectPricing)
    const error        = useSelector(selectError)
    const partialAlert = useSelector(selectPartialAlert)

    const [initialLoad, setInitialLoad] = useState(false)

    useEffect(() => {
        if (initialLoad) { return }
        setInitialLoad(true)

        dispatch(fetchProducts(productsPath))
        dispatch(setLocationId(locationId))
        dispatch(setLocationTaxRate(locationTaxRate))
        dispatch(setLocationPaymentProcessor(locationPaymentProcessor))
        dispatch(calculatePrice())
    }, [initialLoad])

    return (
        <FormProvider {...formMethods}>
            <div className={`pos-form ${previewMode ? 'w-100' : ''}`}>
                {
                    ((!order && !error) || (!!order && error)) && (
                        <div className='row'>
                            <div className="col">
                                <Products previewMode={previewMode} />
                                { pricing?.total > 0 && <hr className="mt-0 mb-4 pb-2" /> }
                            </div>
                        </div>
                    )
                }

                {
                    !previewMode && (
                        <div className="row">
                            <div className="col-12 col-xxl-5">
                                {
                                    pricing?.total > 0 && (!order || order?.balance_cents > 0) &&
                                        <ProductPayment
                                            purchasePath={purchasePath}
                                            hardwareKey={hardwareKey}
                                            inBooking={inBooking}
                                            locationId={locationId}
                                            locationTimeZone={locationTimeZone}
                                            locationPaymentProcessor={locationPaymentProcessor}
                                            environment={environment}
                                        />
                                }

                                {
                                    order && order?.balance_cents <= 0 && (
                                        <div className="my-5 py-5 text-center w-100">
                                            <h3 className="mb-3">Order Complete!</h3>
                                            <button
                                              children='New Order'
                                              type="button"
                                              className="btn btn-primary mt-3 px-5"
                                              onClick={() => {
                                                  dispatch(clearFields())
                                                  dispatch(clear())
                                                  dispatch(setMemberDiscount(0.0))
                                              }}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    )
                }
            </div>

            {
                !previewMode && (
                    <div className="pos-ledger">
                        <ProductLedger locationId={locationId}/>
                    </div>
                )
            }

            { !hidePartialAlert && partialAlert && <PartialAuthAlert /> }
        </FormProvider>
    )
}
