import React, { useEffect, useState, useRef, useMemo } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { setOccasionTypes } from '../../Occasions/occasionsSlice'
import { setSourceTypes } from '../../Sources/sourcesSlice'
import { selectResourceType } from '../../ResourceType/resourceTypeSlice'
import { fetchPackages, resetPackages, resetPackageGroup, resetPackageGroups } from '../../Packages/packageSlice'
import { setReferralTypes } from '../../Referrals/referralsSlice'
import { selectForm, selectOpen, setFormAttribute } from '../../Schedule/scheduleSlice'
import Icon from '@/components/FontAwesomeIcon'
import ScheduleDrawerBody from './ScheduleDrawerBody'
import { selectBooking, setExpandedActions } from '../../EditBooking/editBookingSlice'
import { isBookingQuote } from '@/lib/Booking'
import { CURRENT_BOOKING_ID, CREATE_RESERVATION } from '@/lib/Storage'
import { debug } from '@/lib/Debug'

/**
 * Load/dispatch any initial data in order to get started and render the actual
 * body which will render out the individual components... this serves as the
 * 'Parent' container that just loads/dispatches.
 */
export default function ScheduleDrawer({ occasionTypes, referralTypes, sourceTypes, userName }) {

    const dispatch    = useDispatch()
    const formMethods = useForm()

    const resourceType                = useSelector(selectResourceType)
    const open                        = useSelector(selectOpen)
    const form                        = useSelector(selectForm)
    const booking                     = useSelector(selectBooking)
    const [loaded, setLoaded]         = useState(false)
    const [isExpanded, setIsExpanded] = useState(false)
    const [changed, triggerChanged]   = useState(0)
    const changeCount                 = useRef(0)
    const modalOpenRef                = useRef(open)
    const formIsDirtyRef              = useRef(formMethods.formState.isDirty)
    const originalGroupSizeRef        = useRef(null)

    const isNewQuote = booking === null && !form.require_email_address

    const triggerHasChanged = () => {
        originalGroupSizeRef.current = null
        triggerChanged(changed + 1)
        dispatch(resetPackageGroups())
        dispatch(resetPackageGroup())
        dispatch(resetPackages())
    }

    const drawerClasses = useMemo(() => {
        let classes = [ 'drawer' ]

        classes.push(open ? 'drawer-booking-open' : 'drawer-booking-closed')
        classes.push(isExpanded ? 'expanded' : '')
        classes.push(isBookingQuote(booking) || isNewQuote ? 'quote' : '')

        if (changed > 0) {
            if (changeCount.current > 1) {
                // add a temporary class to the booking drawer...
                classes.push('drawer-booking-changed')

                // ...and then remove it after a brief pause
                window.setTimeout(() => { triggerChanged(0) }, 2000)
            }
            changeCount.current = changeCount.current + 1
        } else {
            classes = classes.filter((c) => !/drawer-booking-changed/i.test(c))
        }

        classes = classes.filter((c) => c !== '').join(' ')

        if (debug && console) {
            console.log(`%c CLASSES: ${changed}  |  ${changeCount.current}  |  ${classes}`, 'background:#E4AF09;color:#332B00;')
        }

        return classes
    }, [open, isExpanded, changed, booking, isNewQuote])

    const prefillForm = () => {
        const data = JSON.parse(window.localStorage.getItem(CREATE_RESERVATION))
        if (console) { console.log('prefilling form data', data) }

        if (!data) { return }

        dispatch(setFormAttribute({ name: "name",  value: data.name }))
        dispatch(setFormAttribute({ name: "email", value: data.email }))
        dispatch(setFormAttribute({ name: "phone", value: data.phone }))

        if (data?.is_quote) {
            dispatch(setFormAttribute({ name: "note", value: data.note }))
            dispatch(setFormAttribute({ name: "source_type",   value: data?.source_type }))
            dispatch(setFormAttribute({ name: "occasion_type", value: data?.occasion_type }))
            dispatch(setFormAttribute({ name: "referral_type", value: data?.referral_type }))
            dispatch(setFormAttribute({ name: "require_email_address", value: data.require_email_address }))
        }

        window.localStorage.removeItem(CREATE_RESERVATION)
    }

    const handleSetOriginalGroupSize = (value) => {
        if (originalGroupSizeRef.current === null) {
            originalGroupSizeRef.current = value
        }
    }

    /**
     * if pre-existing customer data exists in local storage then update
     * the store with that info so that the input fields render with prefilled data
     */
    useEffect(() => {
        prefillForm()

        return () => {
            dispatch(resetPackageGroups())
            dispatch(resetPackageGroup())
            dispatch(resetPackages())
        }
    }, [])

    /**
     * Subscribe to changes to formState
     *
     * always keep this reference up to date with the current
     * value of the form dirtiness state. we need to use a ref
     * so that we can reference the state within the global
     * handleKeyDown event, which would otherwise not have it
     */
    useEffect(() => {
        formIsDirtyRef.current = formMethods.formState.isDirty
    }, [formMethods])

    /**
     * Effect to run whenever the drawer itself opens
     */
    useEffect(() => {
        if (!loaded) {
            dispatch(setOccasionTypes(occasionTypes))
            dispatch(setReferralTypes(referralTypes))
            dispatch(setSourceTypes(sourceTypes))
            setLoaded(true)
        }

        // always keep this reference up to date with the current value of
        // the modal open/closed state and then explicitly reset the form state.
        // we need to use a ref so that we can reference the state within the
        // global handleKeyDown event, which would otherwise not have it
        modalOpenRef.current = open

        if (open) {
            dispatch(setExpandedActions(false))
            formMethods.reset()

            /**
             * if pre-existing customer data exists in local storage then update the store with that info
             * so that the input fields render with prefilled data
             */
            prefillForm()

            // always fetch the latest packages for the resource type
            // if/whenever the booking or resource type ever changes
            if (resourceType && (!booking?.id || window.sessionStorage.getItem(CURRENT_BOOKING_ID) != booking?.id)) {
                dispatch(resetPackageGroups())
                dispatch(resetPackageGroup())
                dispatch(fetchPackages(
                    `/resource_types/${resourceType}/packages.json`,
                    booking?.package_version_id,
                    !!booking?.id
                ))
            }

            /**
             * Keep a copy of the ID of the booking that is currently
             * being edited in the scheduling drawer. We need to do this
             * because when checking the new/updated booking ID when responding
             * to ActionCable updates/broadcasts we do NOT have access to the
             * current redux state within the subscription.received callback.
             */
            if (booking) {
                if (booking?.id != window.sessionStorage.getItem(CURRENT_BOOKING_ID)) {
                    triggerHasChanged()
                }
                window.sessionStorage.setItem(CURRENT_BOOKING_ID, booking?.id)
            }
        } else {
            changeCount.current = 0
            window.sessionStorage.removeItem(CURRENT_BOOKING_ID)
        }
    }, [open, booking, resourceType])

    return !open ? '' : <>
        <div className={drawerClasses}>
            <FormProvider {...formMethods}>
                <ScheduleDrawerBody
                    userName={userName}
                    formIsDirtyRef={formIsDirtyRef}
                    modalOpenRef={modalOpenRef}
                    originalGroupSize={originalGroupSizeRef.current}
                    handleSetOriginalGroupSize={handleSetOriginalGroupSize}
                >
                    <Icon
                        title={`Click to see ${isExpanded ? 'less' : 'more'}`}
                        icon={`expandable-toggle fa-circle-caret-${isExpanded ? 'right' : 'left'}`}
                        packs={['fa-solid']}
                        className='expandable-toggle'
                        onClick={() => setIsExpanded(!isExpanded)}
                    />
                </ScheduleDrawerBody>
            </FormProvider>
        </div>

        {/*<div className="success-anim">*/}
        {/*    <div className="success-anim--content">*/}
        {/*    <svg className="success-anim--checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">*/}
        {/*        <circle className="success-anim--checkmark__circle" cx="26" cy="26" r="25" fill="none"/>*/}
        {/*        <path className="success-anim--checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>*/}
        {/*    </svg>*/}
        {/*    <span>Good job doing a thing!</span>*/}
        {/*    </div>*/}
        {/*</div>*/}
    </>
}
