import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import axios from 'axios'
import { selectPaymentProcessor } from '@/features/Locations/locationSlice'
import { selectBookingId, setOpenSendLinkToCustomerModal } from '@/features/EditBooking/editBookingSlice'
import { setOpen } from '@/features/BookingPayment/bookingPaymentSlice'
import { selectPayments } from '@/features/BookingHistory/bookingHistorySlice'
import { selectCreditToken, selectAmountToPay, submitPayment } from '@/features/BookingPayment/bookingPaymentSlice'
import OnFileProfile from '@/features/BookingPayment/OnFileProfile'

export default function PaymentCreditOnFile() {

    const dispatch    = useDispatch()
    const formMethods = useFormContext()
    const processor   = useSelector(selectPaymentProcessor)
    const bookingId   = useSelector(selectBookingId)
    const creditToken = useSelector(selectCreditToken)
    const amountToPay = useSelector(selectAmountToPay)
    const payments    = useSelector(selectPayments)

    const [init, setInit]         = useState(false)
    const [profiles, setProfiles] = useState([])

    const container = document.getElementById('process-payment-button-container')

    const handleSendPaymentLinkToCustomer = () => {
        dispatch(setOpenSendLinkToCustomerModal(true))
        dispatch(setOpen(false))
    }

    const handleSubmit = () => {
        dispatch(submitPayment(bookingId))
    }

    useEffect(() => {
        if (init) { return }

        setInit(true)

        axios.get(`/bookings/${bookingId}/payment_profiles`)
             .then(({ data }) => {
                 if (!Array.isArray(data)) {
                    if (console) { console.log('No cards on file found') }
                    return
                 }

                 if (/^adyen$/i.test(processor)) {
                     setProfiles(data.filter((p) => !!p?.['recurring.shopperReference']))
                 } else {
                     setProfiles(data)
                 }
             })
             .catch((e) => { if (console) { console.error(e) } })
    }, [init])

    return <>
        {
            profiles?.length > 0 ? <>
                <div className='d-flex flex-column py-3 px-4 alert alert-warning shadow-sm rounded mb-3' role='alert'>
                    <h6 className='alert-heading pb-2'>Warning!</h6>
                    <p style={{ fontSize: '95%', fontWeight: 600, lineHeight: 1.2 }}>
                        Processing payments via card-on-file will incur higher processing costs.
                        These costs can be reduced by processing the payment in-person using a terminal or by allowing the customer to make their payment directly online.
                    </p>
                    <p className='mb-2' style={{ fontSize: '95%', fontWeight: 600, lineHeight: 1.2 }}>
                        Would you like to send the customer a link to make a payment towards the reservation themselves instead?
                    </p>
                    <button
                        type='button'
                        className='btn btn-secondary text-white ml-auto'
                        children='Send Payment Link To Customer'
                        onClick={handleSendPaymentLinkToCustomer}
                    />
                </div>
                <ul className="list-group payment-profiles">
                    { profiles.map((profile, index) => <OnFileProfile key={index} profile={profile} />) }
                </ul>
            </> : (
                <p className="pt-4 text-center text-secondary">No credit cards on file.</p>
            )
        }

        {
            container && createPortal((
                <button
                    children='Process Payment'
                    className={`w-100 py-3 px-5 btn btn-${(profiles && profiles.length > 0) ? 'primary' : 'outline-secondary'} ${payments?.length == 0 ? 'bottom-right-rounded' : ''}`}
                    disabled={!(profiles && profiles.length > 0) || (amountToPay <= 0) || !creditToken}
                    onClick={formMethods.handleSubmit(handleSubmit)}
                />
            ), container)
        }
    </>
}
