import React from 'react'
import TabDetails from '@/features/AdvancedPointOfSale/components/ordering/receipt/TabDetails'
import Totals from '@/features/AdvancedPointOfSale/components/ordering/receipt/Totals'

export default function Receipt({
    id=null,
    className='',
    classNameInner='',
    check={},
    tab={},

    currentBalance=null,
    tipAmount=null,
    paymentAmount=null,
    totalCents=null,
    amountPaidCents=null,

    collapsable=false,
    collapsedByDefault=false,
    compact=false,
    checkoutMode=false,
    hideAmountPaid=false,
    hideTipsCollected=false,
    hideRemainingBalance=false,
}) {

    return !!tab && !!tab?.items ? (
        <div id={id} className={`single-tab-receipt receipt ${className}`.trim()}>
            <TabDetails
                className={classNameInner}
                check={check}
                tab={tab}
                collapsable={collapsable}
                collapsedByDefault={collapsedByDefault}
                compact={compact}
            />

            <Totals
                className={classNameInner}
                checkoutMode={checkoutMode}
                compact={compact}
                hideAmountPaid={hideAmountPaid}
                hideTipsCollected={hideTipsCollected}
                hideRemainingBalance={hideRemainingBalance}

                subtotalCents={tab.amount_cents}
                discountCents={tab.discount_cents}
                compsCents={tab.comps_total}
                taxCents={tab.tax_cents}
                autoGratuityCents={tab.auto_gratuity_cents}
                totalCents={totalCents || tab.total_cents}
                amountPaidCents={amountPaidCents || (tab.total_paid - tab.refund_total)}
                managerAdjustmentCreditCents={tab.manager_adjustment_credit_total_cents}

                bookingTaxesAndFeesVerbiage={check?.booking?.taxes_and_fees_verbiage}

                balanceCents={Number((currentBalance * 100).toFixed(2)) || tab.balance_cents}
                tipCents={checkoutMode ? Number((tipAmount * 100).toFixed(2)) : (tab.tip_cents - tab.tip_refund_total)}
                paymentAmountCents={Number((paymentAmount * 100).toFixed(2))}
            />
        </div>
    ) : null
}
