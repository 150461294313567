import React from 'react'
import { useSelector } from 'react-redux'
import { selectChangeLog } from '@/features/GiftCards/giftCardSlice'
import moment from 'moment'

export default function GiftCardChangeLog(props) {

    const changeLog = useSelector(selectChangeLog)
    const tz        = props.timeZone

    const formattedValue = (value, column) => {
        switch(true) {
            case value === 0 || value === '0' :
                return 0

            case /^is_/i.test(column) && !Boolean(value) :
                return 'false'

            case !Boolean(value) :
                return 'N/A'

            case /(_at|_time)/i.test(column) && moment(value).isValid() :
                // whenever the value can be parsed into a valid moment object, we convert it into the location tz and display it.
                return moment(value).tz(tz).format('MMM D, YYYY h:mm:ss:SSS A')

            default :
                return value
        }
    }

    return (
        <table className="table table-middle">
          <colgroup>
            <col width="120" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
          </colgroup>
          <tbody>
            {
                changeLog.map(eventAndUser => {
                    const change = eventAndUser.event
                    const user = eventAndUser.user || {}

                    /**
                     * Loop over the individual change log entries that are found for the current booking. We then
                     * loop over the individual object changes per column as individual table rows. This means right
                     * now each COLUMN change is actually registered as a row... we would need some sort of consolidated
                     * view to separate those but I'm assuming it doesn't really matter...
                     */
                    return Object.keys(change.object_changes).filter(column => {
                        // whatever fields we don't want to include in the audit table we can just add here
                        return !['updated_at'].includes(column)
                    }).map((column, index) => {
                        // the array of changes [from, to] for the individual column we're on
                        const changes = change.object_changes[column]
                        const from    = typeof changes[0] === 'object' ? JSON.stringify(changes[0]) : changes[0]
                        const to      = typeof changes[1] === 'object' ? JSON.stringify(changes[1]) : changes[1]

                        return (
                            <tr key={index}>
                                <td className="text-right text-gray3 smaller">
                                    <b>
                                        {moment(change.created_at).tz(tz).format('MMM D, YYYY')}
                                        <br />
                                        {moment(change.created_at).tz(tz).format('h:mm:ss:SSS A')}
                                    </b>
                                </td>

                                <td><b>{user.username || 'System'}</b> <span className="text-gray3 smaller">Changed</span> <b>{column}</b></td>

                                <td className="text-right"><b>{formattedValue(from, column)}</b></td>

                                <td width="0" className="px-0"><i className="fas fa-arrow-right text-yellow mx-0"></i></td>

                                <td><b>{formattedValue(to, column)}</b></td>
                            </tr>
                        )
                    })
                })
            }
          </tbody>
        </table>
    )
}
