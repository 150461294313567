import React from 'react'
import InputWrapper from './InputWrapper'
import CreatableSelect from 'react-select/creatable'

export default function MultiSelect({ name, req, cols, errors, value, options, handleChange }) {
    return (
        <InputWrapper name={name} req={req} cols={cols} errors={errors}>
            <CreatableSelect
                onChange={options => handleChange(name, options.map(o => o.label))}
                value={value}
                options={options}
                placeholder='Select options... type and press ENTER to create'
                isMulti={true} />
        </InputWrapper>
    )
}