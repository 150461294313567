import React from 'react'

export default function Alert({ type, message, refresh }) {

    /**
     * For the most part, the table view is all we need to refresh to, since
     * we're on the main index (table) page, and sliding out a drawer, meaning
     * whatever page we were on can simply be refreshed
     */
    if (refresh) {
        window.setTimeout(() => window.location.reload(), 1500)
    }

    return (
        <div className={`alert alert-${type}`}>
            {message}
        </div>
    )
}
