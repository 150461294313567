import React from 'react'
import { isBookingComplete } from '@/lib/Booking'

export function _BookingHeader({
    booking,
    childBookings,
    pkg,
    overlappingBookingsList,
    partySizeFor,
    handleOverlapToggle
}) {
    const partySize                = partySizeFor(booking)
    const specialEventWithCheckIns = booking.is_parent && booking?.resource_type?.waivers_required

    /**
     * Calculate a checked in count of participants
     */
    let checked_in_count  = 0
    let participant_count = 0

    if (specialEventWithCheckIns) {
        childBookings.forEach(cb => {
            checked_in_count  += cb.checked_in.length
            participant_count += parseInt((cb.group_max || cb.participants), 10)
        })
    }

    return <>
            {
                overlappingBookingsList().length > 0 && (
                    <button type="button" className="btn-none overlap-toggle" onClick={handleOverlapToggle}>
                        <i className="fas fa-exclamation" />
                        <i className="fas fa-layer-group" />
                    </button>
                )
            }

            {
                !(isBookingComplete(booking) && pkg?.is_a_league) && (
                    <div className="party-size">
                        { booking.is_parent ? `${participant_count} - ${pkg?.special_event_capacity || '??'}` : partySize }
                    </div>
                )
            }

            {
                !!booking.note && (
                    <div className='has-notes ml-1' title='This booking has notes.'>
                        <i className='far fa-clipboard' />
                    </div>
                )
            }

            <div className="party-name">
                { booking.is_parent ? pkg.name : (booking.name || 'New Held Reservation') }
            </div>

            {
                !(isBookingComplete(booking) && pkg?.is_a_league) && (
                    <div className={`event-type ${booking.duration < 60 ? 'd-none' : 'd-block'} ${specialEventWithCheckIns ? 'ml-auto pr-2' : ''}`}>
                        {
                            specialEventWithCheckIns
                                ? `${ checked_in_count > 0 ? `${checked_in_count} of ${participant_count}` : 0} Checked In`
                                : (booking.is_parent ? '' : pkg.name)
                        }
                    </div>
                )
            }
    </>
}

export const BookingHeader = React.memo(_BookingHeader)
