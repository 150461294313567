import React from 'react'
import { useSelector } from 'react-redux'
import { selectPackage } from '../Packages/packageSlice'
import { selectBooking, selectMembers } from '../EditBooking/editBookingSlice'
import AddonUniform from './AddonUniform'
import AddonTypeToggles from './AddonTypeToggles'

export default function AddonCustomerTypes({ duration, typeCounts, setTypeCounts, handleError=() => {} }) {

    const booking = useSelector(selectBooking)
    const pakage  = useSelector(selectPackage)
    const members = useSelector(selectMembers)

    const participants = booking.participants

    const group = pakage.groups.filter(g => {
        return participants >= Number.parseInt(g.min) && participants <= Number.parseInt(g.max)
    })[0]

    // if the group pricing type is group, return null since they dont have to input any information
    if (group.price_type === 'group_price') {
        // timeout is necessary otherwise react complains about re-rendering a rendering component
        window.setTimeout(() => { setTypeCounts(0) }, 50)
        return null
    }

    // find pricing group
    const pricing = pakage.pricing.filter(g => {
        return participants >= Number.parseInt(g.groupMin) && participants <= Number.parseInt(g.groupMax)
    })[0]

    if (pakage.pricing_type === 'uniform_pricing') {
        return (
            <AddonUniform
                typeCounts={typeCounts}
                setTypeCounts={setTypeCounts}
                max={participants - members.length}
            />
        )
    } else {
        const prices = pricing.prices.map(typePrices => (
            typePrices.filter(p => p.duration === duration)
        )).flat()

        return (
            <AddonTypeToggles
                typeCounts={typeCounts || {}}
                setTypeCounts={setTypeCounts}
                prices={prices}
                onError={handleError}
            />
        )
    }

    return (
        <p className="text-danger text-center py-1 px-2 w-100">An error occurred.</p>
    )
}
