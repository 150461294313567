import React from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { selectTimeZone } from '@/features/Locations/locationSlice'
import {
  selectPreloaded,
  selectDate,
  selectStep,
  selectTime,
  setStep,
  setReminderAcknowledged,
  resetCustomerTypes,
  setDuration,
  setDate,
  setTime,
  setGiftCards,
  setPaymentReady,
  setGiftCardNumber,
  removePromoCode
} from '@/features/Bookings/bookingSlice'

export default function LedgerDateTime({ clearCalendars }) {

    const dispatch  = useDispatch()
    const preloaded = useSelector(selectPreloaded)
    const step      = useSelector(selectStep)
    const time      = useSelector(selectTime)
    const date      = useSelector(selectDate)
    const tz        = useSelector(selectTimeZone)

    if (/[1-3]/.test(step)) {
        return null
    }

    return (
        <div className="xbm-ledger-section">
            <div className="xbm-ledger-section-action-label">
                <div className="xbm-ledger-section-action-label-label">
                    {/* Apr 9 @ 8:30pm */}
                    {moment.tz(date, tz).format('MMM D')}
                    &nbsp;@&nbsp;
                    {moment.tz(time, tz).format('h:mma z')}
                </div>
                {
                    !preloaded && (
                        <div className="xbm-ledger-section-action-label-action">
                            <button
                                className="as-link"
                                onClick={() => {
                                    clearCalendars()
                                    dispatch(setDate(null))
                                    dispatch(setTime(null))
                                    dispatch(setGiftCards({ type: 'clear' }))
                                    dispatch(setGiftCardNumber(""))
                                    dispatch(setPaymentReady(false))
                                    dispatch(removePromoCode())
                                    dispatch(resetCustomerTypes())
                                    dispatch(setReminderAcknowledged(null))
                                    dispatch(setStep('2'))
                                }}
                            >
                                Change
                            </button>
                        </div>
                    )
                }
            </div>
        </div>
    )
}
