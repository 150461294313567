import { createSlice } from "@reduxjs/toolkit"
import axios from 'axios'
import { addAlert } from '@/features/Notifications/notificationSlice'

export const addonSlice = createSlice({
    name: 'addonSlice',
    initialState: {
        customerTypeCounts: {},
        duration: null,
        open: false,
        openRemoveModal: false,
        selectedMembers: [],
    },
    reducers: {
        setCustomerTypeCounts: (state, action) => {
            state.customerTypeCounts = action.payload
        },
        setDuration: (state, action) => {
            state.duration = action.payload
        },
        setOpen: (state, action) => {
            state.open = action.payload
        },
        setOpenRemoveModal: (state, action) => {
            state.openRemoveModal = action.payload
        },
        setSelectedMembers: (state, action) => {
            state.selectedMembers = action.payload
        }
    }
})

export const {
    setCustomerTypeCounts,
    setDuration,
    setOpen,
    setOpenRemoveModal,
    setSelectedMembers,
} = addonSlice.actions

export const selectCustomerTypeCounts = state => state.addon.customerTypeCounts
export const selectDuration           = state => state.addon.duration
export const selectOpen               = state => state.addon.open
export const selectOpenRemoveModal    = state => state.addon.openRemoveModal
export const selectSelectedMembers    = state => state.addon.selectedMembers

export function submitAddon(duration, typeCounts) {
    return async (dispatch, getState) => {
        const token     = getState().session.formToken
        const bookingId = getState().editBooking.booking.id
        const selectedMembers = getState().addon.selectedMembers

        axios.post(`/bookings/${bookingId}/booking_addon_times`, {
            authenticity_token:   token,
            customer_type_counts: typeCounts,
            membership_ids: selectedMembers,
            booking_addon_time: {
                duration:   duration,
                booking_id: bookingId
            }
        }).then(({ data }) => {
            if (data.success) {
                dispatch(setOpen(false))
                dispatch(addAlert({ type: 'success', text: data.message }))
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        }).catch(e => {
            console.warn(e)
        })
    }
}

export function removeAddon(addonId) {
    return async (dispatch, getState) => {
        const token     = getState().session.formToken
        const bookingId = getState().editBooking.booking.id

        axios.delete(`/bookings/${bookingId}/booking_addon_times/${addonId}?authenticity_token=${token}`)
        .then(({ data }) => {
            if (data.success) {
                dispatch(setOpenRemoveModal(false))
                dispatch(addAlert({ type: 'success', text: data.message }))
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        }).catch(e => {
            console.warn(e)
        })
    }
}

export default addonSlice.reducer
