import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setStep, selectStep } from '../../scheduleSlice'
import { debug } from '@/lib/Debug'

export default function ScheduleDrawerTabs({ editMode=false }) {

    const dispatch = useDispatch()
    const step     = useSelector(selectStep)

    const handleClick = (e) => {
        dispatch(setStep(e.target.dataset.step))
    }

    return (
        <div className="drawer-tabs">
            {
                editMode ? <>
                    <div
                        children='Reservation Details'
                        className={`tab ${step == 3 ? 'selected' : ''}`}
                        data-step='3'
                        onClick={handleClick}
                    />
                    <div
                        children='Group Details'
                        className={`tab ${step == 4 ? 'selected' : ''}`}
                        data-step='4'
                        onClick={handleClick}
                    />
                    {
                        (debug || /(alpha|beta|test)/i.test(window.location.href)) && (
                            <div
                                children='Debugging'
                                className={`tab ${step == 'debug' ? 'selected' : ''}`}
                                data-step='debug'
                                onClick={handleClick}
                            />
                        )
                    }
                </> : (
                    <div
                        children='Setup'
                        className="tab selected"
                    />
                )
            }
        </div>
    )
}
