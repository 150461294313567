import React from 'react'
import { useSelector } from 'react-redux'
import CalendarSearchResult from './CalendarSearchResult'
import { selectResults } from './calendarSearchSlice'

export default function CalendarSearchResultsContainer({ location }) {

    const results = useSelector(selectResults)

    return (
        <div className='calendar-search-results row py-3'>
            {
                results.map((result, i) => (
                    <div className="col-12 col-md-6" key={`result_${result.id}_${i}`}>
                        <CalendarSearchResult
                            booking={result.booking}
                            childBookings={result.child_bookings}
                            resources={result.resources}
                            pakage={result.package}
                            location={location}
                        />
                    </div>
                ))
            }
        </div>
    )
}
