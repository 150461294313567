import React, { useEffect, useState } from 'react'
import moment from 'moment'
import FormRow from '../Form/FormRow'
import Input from '../Form/Input'
import Checkbox from '../Form/Checkbox'
import TextArea from '../Form/TextArea'
import ToggleResources from '../Resources/ToggleResources'
import RecurringPackageFields from './RecurringPackageFields'
import Durations from './Durations'
import ReservationsFields from './ReservationsFields'
import DateTimePicker from '../Form/DateTimePicker'
import { momentToLocalDate, localDateToMoment } from '@/lib/CalendarTimes'
import { useConfirm } from '@/lib/useConfirmHook'
import Help from '@/features/Help/Help'

const newDuration = {
    duration:    0,
    addon_price: null,
    internal:    null,
    _id:         0
}

export default function PackageSetupTab({
    form,
    errors,
    resource,
    resources,
    location,
    durationIncrementer,
    setDurationIncrementer,
    maxBookableFutureMonths,
    specialEventIsMutable: _specialEventIsMutable,
    newPackage,
    loaded,
    initialConfigSetRef,
    handleTabLoaded,
    handleInitialConfigSet,
    handleUpdate,
    handleCheckboxUpdate,
    handleManualUpdate,
    handleFormUpdate
}) {
    const { confirm } = useConfirm()

    const isNewPackageRecurring = (newPackage && !form.recurring_type)
    const isNewPackageIncrement = (newPackage && !form.time_increment_type)

    const [specialEventIsMutable, setSpecialEventIsImmutable] = useState(false)
    const [anyNonAdjacent, setAnyNonAdjacent]                 = useState(false)

    useEffect(() => {
        if (loaded) { return false }

        if (!newPackage && !_specialEventIsMutable && !form.active) {
            setSpecialEventIsImmutable(true)
        }

        handleTabLoaded()
    }, [])

    // reconfigure various form settings in a single update
    // for a special event if the toggle is turned on
    useEffect(() => {
        if (!newPackage) { return }

        if (form.resource_exclusive_to_package) {
            if (initialConfigSetRef.current) {
                handleManualUpdate('customers_share_lanes', true)
            } else {
                handleFormUpdate({ ...form, ...{
                    'customers_share_lanes': true,
                    'time_increment_type': 'specific',
                    'durations': [form.durations?.[0] || newDuration],
                    'recurring_type': 'single_event'
                }})
                handleInitialConfigSet(true)
            }
        } else {
            handleManualUpdate('customers_share_lanes', false)
        }
    }, [form.resource_exclusive_to_package])

    useEffect(() => {
        if (resources.some((resource) => resource.non_adjacent)) {
            setAnyNonAdjacent(true)
        }
    }, [resources])

    const updateSingleEventDate = (e) => {
        if (!!e.target.value) {
            handleManualUpdate('single_event_date', localDateToMoment(e.target.value, location.time_zone))
        }
    }

    const toggleSpecialEventOption = async e => {
        if (e.target.checked) {
            const confirmSpecialEventText = ("\
                <div class='text-left'>\
                    <h4>This setting is for use in the following situations:</h4>\
                    <ul class='my-4 py-2'>\
                        <li class='mb-2'>Events like leagues, tournaments, & classes (Yoga & Axes)</li>\
                        <li class='mb-2'>\
                            Special events allow for separate reservations/registration,\
                            but ALL reservations start and end at the SAME time. For example,\
                            Axes & Yoga starts at 11am and ends at 1pm for ALL customers registered to the event.\
                        </li>\
                        <li class='mb-2'>\
                            ALL reservations SHARE the assigned resources and are NOT assigned to a specific resource.\
                            For example, League members/games can play on ANY of the reserved targets that the special event is assigned to.\
                        </li>\
                        <li class='mb-2'>\
                            There is a maximum number of participants who can register for the event.<br />\
                            For example, there may be only 32 spots available for tournament registration.\
                        </li>\
                        <li class='mb-2'>\
                            Special events reserve resources specifically for its event and do not allow other reservations to be booked.\
                        </li>\
                    </ul>\
                    <p>\
                        <strong>ALL other uses cases can and should be accommodated by the standard,\
                        <span class='d-inline-block'>non-special</span> event package set up.</strong>\
                    </p>\
                    <p class='mb-0'>\
                        <strong>Please reach out to your direct contact with any questions.</strong>\
                    </p>\
                </div>\
            ")

            if (await confirm(confirmSpecialEventText, { size: 'md', header_text: 'Are you sure this is a Special Event?', rawHTML: true })) {
                handleCheckboxUpdate(e)
            }
        } else {
            handleCheckboxUpdate(e)
        }
    }

    return (
        <div className='pt-3'>
            <FormRow>
                {
                    specialEventIsMutable && (
                        <div className='col-12'>
                            <div className='alert alert-danger h6'>
                                <strong className='pr-3'>Warning!</strong>
                                This special event can no longer be edited.
                            </div>
                        </div>
                    )
                }
                <div className='col-6'>
                    <div className='d-flex'>
                        <Checkbox
                            name='active'
                            value={form.active !== false ? true : false}
                            disabled={specialEventIsMutable}
                            handleChange={handleCheckboxUpdate}
                        />
                        <Help articleId='154000158962-active-inactive-package-toggle' />
                    </div>
                </div>
                {newPackage &&
                    <div className="col-6 ml-auto text-right">
                        <Checkbox
                            name='resource_exclusive_to_package'
                            handleChange={toggleSpecialEventOption}
                            label='Special Event?'
                            value={form.resource_exclusive_to_package}
                            disabled={!newPackage && form.resource_exclusive_to_package}
                            tooltip='This toggle blocks out all selected resources at the selected times.<br />
                            Enabling this toggle can cause long save times for this package - please be patient.' />
                    </div>
                }
            </FormRow>

            <hr className="mt-n1" />

            <FormRow>
                <div className='col-6'>
                    <div className='form-group'>
                        <label className="req d-inline">Name</label>
                        <Help articleId='154000158964-package-name-modal-header-public-description' />
                        <Input
                            name='name'
                            handleChange={handleUpdate}
                            value={form.name}
                            autoFocus={true}
                            hideLabel={true}
                            withWrapper={false}
                            errors={errors}
                            validation={{
                                'name': { required: true }
                            }}
                        />
                    </div>
                </div>

                <Input
                    name='display_name'
                    label='Modal Header'
                    handleChange={handleUpdate}
                    value={form.display_name}
                    cols='6'
                    errors={errors}
                    validation={{
                        'display_name': { required: true }
                    }}
                />
            </FormRow>

            <FormRow>
                <TextArea
                    label='Public Description'
                    name='description'
                    handleChange={handleUpdate}
                    value={form.description}
                    cols='12'
                    errors={errors}
                />
            </FormRow>

            <FormRow>
                <TextArea
                    label='Internal Notes'
                    name='internal_notes'
                    handleChange={handleUpdate}
                    value={form.internal_notes}
                    cols='12'
                    errors={errors}
                />
            </FormRow>

            <ReservationsFields
                form={form}
                errors={errors}
                isSpecialEvent={form.resource_exclusive_to_package}
                handleUpdate={handleUpdate}
            />

            <h3 className='d-flex align-items-center justify-content-between'>
                <span className='d-flex'>
                    <span>Resource Selection</span>
                    <Help
                        articleId='154000158976-package-resource-selection'
                        extraClasses='float-left ml-2'
                    />
                </span>

                {
                    anyNonAdjacent && (
                        <small className="d-flex align-items-center mr-0">
                            <Checkbox
                                className='pb-0'
                                name='ignore_non_adjacent'
                                label={form.ignore_non_adjacent ? 'Does not require resources to be adjacent' : 'Do not require resources to be adjacent?'}
                                value={form.ignore_non_adjacent}
                                handleChange={handleCheckboxUpdate}
                            />
                            <Help
                                articleId='154000158966-non-adjacent-resource-override'
                                extraClasses='float-left ml-2'
                            />
                        </small>
                    )
                }
            </h3>

            <FormRow>
                <ToggleResources
                    resources={resources}
                    inputName='available_lanes'
                    inputValue={form.available_lanes || []}
                    singularName={resource.singular_name}
                    hideHeader={true}
                    newPackage={newPackage}
                    errors={errors}
                    loaded={loaded}
                    handleChange={handleManualUpdate}
                />
            </FormRow>

            {
                (form.resource_exclusive_to_package) && (
                    <FormRow>
                        <Input
                            cols='4'
                            name='special_event_capacity'
                            label='Special Event Capacity'
                            placeholder=' '
                            append={true}
                            type='number'
                            min={1}
                            step={1}
                            value={form.special_event_capacity}
                            errors={errors}
                            validation={{'special_event_capacity': { required: true }}}
                            handleChange={handleUpdate}
                        >
                            <div className="input-group-append">
                                <span className="input-group-text">
                                    { form.special_event_capacity == 1 ? 'Person' : 'People' }
                                </span>
                            </div>
                        </Input>
                    </FormRow>
                )
            }

            <h3>Dates &amp; Times</h3>

            <div className="form-row">
                <div className={`${form.resource_exclusive_to_package ? 'col-4' : 'col-3'}`}>
                    <div className='form-group'>
                        <div className="custom-control custom-radio custom-control-inline">
                            <input
                                id='single_event'
                                type='radio'
                                name='recurring_type'
                                value='single_event'
                                className='custom-control-input'
                                checked={form.recurring_type === 'single_event'}
                                onChange={handleUpdate}
                            />

                            <label className="custom-control-label" htmlFor='single_event'>
                                Single {form.resource_exclusive_to_package ? 'Special Event' : 'Event'}
                                <Help articleId='154000158967-single-event-selection' />
                            </label>
                        </div>
                    </div>
                </div>
                <div className={`${form.resource_exclusive_to_package ? 'col-4' : 'col-9'}`}>
                    <div className="custom-control custom-radio custom-control-inline">
                        <input
                            id='recurring'
                            type='radio'
                            name='recurring_type'
                            value='recurring'
                            className='custom-control-input'
                            checked={form.recurring_type === 'recurring'}
                            onChange={handleUpdate}
                        />

                        <label className="custom-control-label" htmlFor='recurring'>
                            Recurring {form.resource_exclusive_to_package ? 'Special Events' : 'Events'}
                            <Help articleId='154000158968-recurring-event-selection' />
                        </label>
                    </div>
                </div>

                {
                    form.resource_exclusive_to_package && form.recurring_type === 'recurring' && (
                        <div className="col-4 text-right pr-4">
                            <Checkbox
                                name='only_allow_first_day_reservations'
                                label='Use League Scheduling?'
                                tooltip='Only allow reservations on the first instance of the special event?'
                                value={form.only_allow_first_day_reservations}
                                disabled={/single/i.test(form.recurring_type)}
                                handleChange={handleCheckboxUpdate}
                            />
                        </div>
                    )
                }
            </div>

            <div className="form-row">
                {
                    (form.recurring_type === 'single_event' || isNewPackageRecurring) && (
                            <DateTimePicker
                                cols='5'
                                value={!!form.single_event_date ? momentToLocalDate(moment(form.single_event_date).tz(location.time_zone)) : form.single_event_date}
                                errors={errors}
                                validation={{ 'single_event_date': { required: true }}}
                                handleChange={updateSingleEventDate}
                            />
                        )
                    }

                {
                    form.recurring_type === 'recurring' && (
                        <div className="col-12">
                            <RecurringPackageFields
                                form={form}
                                errors={errors}
                                location={location}
                                maxBookableFutureMonths={maxBookableFutureMonths}
                                isNewPackageIncrement={isNewPackageIncrement}
                                isSpecialEvent={form.resource_exclusive_to_package}
                                handleUpdate={handleUpdate}
                                handleManualUpdate={handleManualUpdate}
                                handleCheckboxUpdate={handleCheckboxUpdate}
                            />
                        </div>
                    )
                }
            </div>

            <Durations
                errors={errors}
                durations={form.durations || []}
                pricing={form.pricing || []}
                selectedCustomerTypes={form.customer_types || []}
                incrementer={durationIncrementer}
                setIncrementer={setDurationIncrementer}
                pricingType={form.pricing_type}
                isSpecialEvent={form.resource_exclusive_to_package}
                handleUpdate={handleManualUpdate}
            />
        </div>
    )
}
