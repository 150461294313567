import React from 'react'
import { useSelector } from 'react-redux'
import { selectCustomerTypes } from '../CustomerTypes/customerTypeSlice'
import { selectCustomerTypeCounts, selectBooking } from '../EditBooking/editBookingSlice'
import PaymentParticipantToggle from './PaymentParticipantToggle'

export default function PaymentParticipants() {

    const customerTypes = useSelector(selectCustomerTypes)
    const selectedTypes = useSelector(selectCustomerTypeCounts)
    const booking       = useSelector(selectBooking)

    const selectedTypeObjects = customerTypes.filter((type) => {
        if (!selectedTypes) { return null }

        return Object.keys(selectedTypes)
                     .map((key) => Number.parseInt(key))
                     .includes(type.id)
    })

    return (
        <div className="modal-body-main-section pt-3 pb-2">
            <div className="form-row">
                {
                    selectedTypeObjects && selectedTypeObjects.map((type) => {
                        // how many customers of this type has the user already paid for?
                        // we will subtract the total with this amount since this amount is
                        // not eligible to be paid for by the user
                        const paidFor = booking.customer_type_counts_paid_for ? (booking.customer_type_counts_paid_for[type.id] || 0) : 0

                        return !selectedTypes ? null : (
                            <PaymentParticipantToggle
                                key={type.id}
                                type={type}
                                total={selectedTypes[type.id] - paidFor}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}
