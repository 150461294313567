import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { selectTimeZone } from '@/features/Locations/locationSlice'
import {
    selectLabelWidth,
    selectLoadedDates,
    selectLastLoadedDatetime,
    selectSizeWidth,
    selectBookingLeftOffset,
    selectMarkerOffset,
    setMarkerOffset,
    selectTimeMarkerUpdates
} from '@/features/Calendar/calendarSlice'
import { generateTimes } from '@/lib/CalendarTimes'

export default function TimeMarker() {
    const dispatch = useDispatch()

    const loadedDates        = useSelector(selectLoadedDates)
    const lastLoadedDatetime = useSelector(selectLastLoadedDatetime)
    const timezone           = useSelector(selectTimeZone)
    const sizeWidth          = useSelector(selectSizeWidth)
    const labelWidth         = useSelector(selectLabelWidth)
    const markerOffset       = useSelector(selectMarkerOffset)
    const markerUpdated      = useSelector(selectTimeMarkerUpdates)
    const centeringOnBooking = useSelector(selectBookingLeftOffset)

    const [offset, setOffset]               = useState(null)
    const [shouldDisplay, setShouldDisplay] = useState(null)

    const calculateOffset = () => {
        if (!loadedDates || !timezone) { return }

        // GUARD CONDITION
        //
        // NOTE: We only display the red time marker line on the current date+time BUT WE DO need to let the centering
        // calculations run on any day so that when we're "jumping" around days, we always center the calendar to the
        // present time. This is why we skip displaying it instead of returning like a typical guard condition would.
        const tmpShouldDisplay = loadedDates.includes( moment().tz(timezone).format('YYYY-MM-DD') )

        const { times } = generateTimes(loadedDates, lastLoadedDatetime, timezone)
        const now       = moment().tz(timezone)
        let last        = moment().tz(timezone)
        let found       = null

        for (let i = 0; i < times.length; ++i) {
            const current     = times[i]
            const __f_current = parseFloat(current.format('HH.mm'))
            const __f_now     = parseFloat(now.format('HH.mm'))
            const __f_last    = parseFloat(last.format('HH.mm'))

            // look for the current time in the generateTimes results array
            //if (i !== 0 && current.format('L') === now.format('L') && now.isSameOrBefore(current) && now.isSameOrAfter(last)) {

            if (i !== 0) {
                if (tmpShouldDisplay) {
                    // if the generated times array DOES include the current datetime,
                    // then we assume that we're viewing TODAY and that we should display
                    // the time marker on the calendar AND center the view on it.
                    if (
                        current.format('L') === now.format('L')
                        && now.isSameOrBefore(current)
                        && now.isSameOrAfter(last)
                    ) {
                        found = i
                        break
                    }
                } else {
                    // if the generated times array DOES NOT include the current datetime, then
                    // we assume we're viewing a day other than today and should center the
                    // calendar on the current time only without taking the date into account.
                    if (
                      __f_now <= __f_current
                      && __f_now >= __f_last
                    ) {
                        found = i
                        break
                    }
                }
            }

            last = moment(current)
        }

        const totalWidth       = Number.parseInt(sizeWidth)
        const oneMinuteValue   = totalWidth / 15
        const tmpOffset        = (now.minutes() - last.minutes()) * oneMinuteValue
        const calculatedOffset = ((found - 1) * totalWidth) + tmpOffset

        if (!markerOffset) {
            const target = (calculatedOffset + Number.parseInt(labelWidth)) - (window.innerWidth / 2)
            dispatch(setMarkerOffset(target))
        }

        setOffset(calculatedOffset)
        setShouldDisplay(tmpShouldDisplay)
    }

    /**
     * Calculate on load/mount
     */
    useEffect(() => {
        if (centeringOnBooking === null) {
            calculateOffset()
        }
    }, [])

    /**
     * Re-calculate whenever the marker offset
     * is updated in the calendar slice
     */
    useEffect(() => {
        if (centeringOnBooking === null) {
            calculateOffset()
        }
    }, [markerOffset, markerUpdated])

    return (
        <div id="time-marker"
             className={shouldDisplay && offset ? 'd-block' : 'd-none'}
             style={{ transform: `translate(calc(${offset}px + ${labelWidth}px))` }}
        />
    )
}
