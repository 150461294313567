import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import axios from 'axios'
import { selectBookingId, selectReservationHolderInformation } from '../EditBooking/editBookingSlice'
import { setName, setEmail } from "./bookingPaymentSlice"
import { addAlert } from '@/features/Notifications/notificationSlice'

export default function PaymentSelectWaiver({ sendReceipt, setNameCallback, setEmailCallback }) {

    const dispatch    = useDispatch()
    const bookingId   = useSelector(selectBookingId)
    const bookingInfo = useSelector(selectReservationHolderInformation)

    const [waivers, setWaivers]     = useState([])
    const [init, setInit]           = useState(false)
    const [recipient, setRecipient] = useState(null)

    const handleChange = (e) => {
        const recipient = {
            email: e.target.value,
            name: e.target.options[e.target.selectedIndex].text
        }

        setRecipient(recipient)

        /**
         * If the select waiver is embedded in a POS transaction (or elsewhere), it will
         * use the name and email callback passed as props instead of defaulting to the booking
         * payment dispatch actions.
         */
        if (setNameCallback) {
            setNameCallback(recipient.name)
        } else {
            dispatch(setName(recipient.name))
        }

        if (setEmailCallback) {
            setEmailCallback(recipient.email)
        } else {
            dispatch(setEmail(recipient.email))
        }
    }

    useEffect(() => {
        if (init || !bookingId) {
            return
        }

        setInit(true)

        axios.get(`/bookings/${bookingId}/customer_collection`)
        .then(({ data }) => {
            setWaivers(data)
        }).catch((e) => {
            dispatch(addAlert({ type: 'error', text: e.response.data.message }))
            console.warn(e)
        })
    }, [init, bookingId])

    return sendReceipt ? (
        <div className="row">
            <div className="col">
                <div className="form-group">
                    <label htmlFor="waivers">Send Receipt To</label>
                      <select
                        id="waivers"
                        className="custom-select"
                        value={recipient?.email || ''}
                        onChange={handleChange}
                      >
                        { !recipient && <option value="" disabled>Choose a recipient</option> }

                        <optgroup label="Reservation Holder">
                            <option value={bookingInfo.email}>{bookingInfo.name}</option>
                        </optgroup>

                        {
                            waivers.length > 0 && (
                                <optgroup label="Participants">
                                    { waivers.map((w) => <option key={w.id} value={w.email}>{w.name}</option>) }
                                </optgroup>
                            )
                        }
                    </select>
                </div>
            </div>
        </div>
    ) : null
}
