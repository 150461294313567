import React, { useState, useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import {
    selectHeld,
    selectPreloaded,
    selectBooking,
    selectParticipants,
    selectReferral,
    selectOccasion,
    setBookingFormValue,
    setStep,
    setReferral,
    setOccasion,
    setGiftCards,
    setGiftCardNumber,
    setPaymentReady,
    removePromoCode,
    clearBookingCalendars,
    trackAbandonedBooking,
    selectPaymentType,
    selectCustomerTypeCounts,
    selectDate,
    selectDuration,
    selectTime,
    selectPromoCode,
} from '../../bookingSlice'

import {
    selectGroups,
    selectPricingType,
    selectId as selectPackageId,
    selectPaymentTypes,
    selectAllowMemberBenefits
} from '../../../Packages/packageSlice'

import { selectCustomerTypes } from '../../../CustomerTypes/customerTypeSlice'
import { selectResourceType } from '../../../ResourceType/resourceTypeSlice'
import { fetchDepositAmount } from '../../../Pricing/pricingSlice'
import { selectReferralTypes } from "../../../Referrals/referralsSlice"
import { selectOccasionTypes } from "../../../Occasions/occasionsSlice"

import BookingModalFooter from '../BookingModalFooter'
import BookingModalLedger from '../Ledger/BookingModalLedger'
import MobileBookingModalLedger from '../Ledger/MobileBookingModalLedger'
import LedgerLocation from '../Ledger/LedgerLocation'
import LedgerPartySize from '../Ledger/LedgerPartySize'
import LedgerDuration from '../Ledger/LedgerDuration'
import LedgerDateTime from '../Ledger/LedgerDateTime'
import LedgerCustomerTypes from '../Ledger/LedgerCustomerTypes'
import FormRow from "@/components/Form/FormRow"
import Input from "@/components/Form/Input"
import Select from "@/components/Form/Select"
import TextArea from "@/components/Form/TextArea"
import { errorsFor } from '@/components/Form/ErrorsHelper'
import { errorClass, validationPatterns } from '@/lib/Errors'
import { evaluateCustomerTypeSelectionStep } from '@/lib/Steps'
import { gaSendPageView } from '@/lib/GoogleAnalytics'

export default function Step5({ modalStyles }) {

    const formMethods         = useForm()
    const dispatch            = useDispatch()
    const preloaded           = useSelector(selectPreloaded)
    const booking             = useSelector(selectBooking)
    const participants        = useSelector(selectParticipants)
    const groups              = useSelector(selectGroups)
    const customerTypes       = useSelector(selectCustomerTypes)
    const pricingType         = useSelector(selectPricingType)
    const referral            = useSelector(selectReferral)
    const referralTypes       = useSelector(selectReferralTypes)
    const occasion            = useSelector(selectOccasion)
    const occasionTypes       = useSelector(selectOccasionTypes)
    const resourceType        = useSelector(selectResourceType)
    const bookingHeld         = useSelector(selectHeld)
    const allowMemberBenefits = useSelector(selectAllowMemberBenefits)
    const paymentTypes        = useSelector(selectPaymentTypes)
    const selectedPaymentType = useSelector(selectPaymentType)
    const customerTypeCounts  = useSelector(selectCustomerTypeCounts)
    const duration            = useSelector(selectDuration)
    const date                = useSelector(selectDate)
    const time                = useSelector(selectTime)
    const packageId           = useSelector(selectPackageId)
    const promoCode           = useSelector(selectPromoCode)
    const [errors, setErrors] = useState({})

    const handleTrackAbandoned = () => {
        dispatch(trackAbandonedBooking())
    }

    const updateFormField = e => {
        dispatch(setBookingFormValue({ name: e.target.name, value: e.target.value }))
    }

    const optionsForSelectFrom = (items) => {
        return items.map((item) => {
            return { value: item, display: item }
        })
    }

    const handleBack = () => {
        let stepToReturnTo = evaluateCustomerTypeSelectionStep(
            participants,
            groups,
            customerTypes,
            pricingType,
            true, // going backwards
            allowMemberBenefits
        )

        if (stepToReturnTo === '3') {
            dispatch(clearBookingCalendars())

            if (preloaded) {
                stepToReturnTo = '1'
            }
        }

        dispatch(setGiftCards({ type: 'clear' }))
        dispatch(setGiftCardNumber(""))
        dispatch(setPaymentReady(false))
        dispatch(removePromoCode())
        dispatch(setStep(stepToReturnTo))
    }

    const handleError = (errors) => {
        setErrors(errorsFor(errors))
    }

    const handleNext = () => {
        setErrors({})
        dispatch(trackAbandonedBooking())
        dispatch(setStep('6'))
    }

    useEffect(() => {
        gaSendPageView('Reservation Information', '/step/5')
    }, [])

    useEffect(() => {
        dispatch(trackAbandonedBooking())
    }, [referral, occasion])

    useEffect(() => {
        if (paymentTypes.authorize_card_only) { return }

        if (selectedPaymentType === 'deposit_only') {
            // when deposit only is selected, we have to fetch the deposit amount from the pricing slice
            dispatch(fetchDepositAmount(packageId, participants, customerTypeCounts, duration, date, time, promoCode))
        }
    }, [selectedPaymentType, packageId, participants, customerTypeCounts, duration, date, time, promoCode])

    return (
        <FormProvider {...formMethods}>
            <div className="xbm-step-content">
                <div className="xbm-step-content-main">
                    <div className="xbm-step-question">
                        <h2 className="mb-3">Reservation Information</h2>
                    </div>

                    <div className="xbm-checkout-ledger d-block d-md-none border-bottom pb-0 mb-n2">
                        <LedgerLocation />
                        <LedgerPartySize />
                        <LedgerCustomerTypes />
                        <LedgerDuration />
                        <LedgerDateTime />
                    </div>

                    <MobileBookingModalLedger />

                    <div className="container form-container checkout-form">
                        <FormRow>
                            <div className="col-sm-6">
                            <Input
                                type="text"
                                label="Full Name"
                                placeholder="Full Name"
                                autoComplete="name"
                                autoFocus={true}
                                value={booking.fullName}
                                errors={errors}
                                validation={{ 'fullName': { required: true }}}
                                handleBlur={handleTrackAbandoned}
                                handleChange={updateFormField}
                            />
                            </div>
                            <div className="col-sm-6">
                            <Input
                                type="tel"
                                placeholder="Phone Number"
                                autoComplete="tel"
                                value={booking.phone}
                                errors={errors}
                                validation={{ 'phone': {
                                    required: true,
                                    pattern: validationPatterns.phone,
                                }}}
                                handleBlur={handleTrackAbandoned}
                                handleChange={updateFormField}
                                mask='999-999-9999'
                            />
                            </div>
                        </FormRow>

                        <FormRow>
                            <div className="col-sm-6">
                            <Input
                                type="text"
                                placeholder="Email"
                                value={booking.email}
                                errors={errors}
                                autoComplete="email"
                                validation={{ 'email': {
                                    required: true,
                                    pattern: validationPatterns.email,
                                }}}
                                handleBlur={handleTrackAbandoned}
                                handleChange={updateFormField}
                            />
                            </div>
                            <div className="col-sm-6">
                            <Input
                                type="text"
                                label="Email Confirmation"
                                placeholder="Confirm Email"
                                autoComplete="email"
                                value={booking.emailConfirm}
                                errors={errors}
                                validation={{ 'emailConfirm': {
                                    required: true,
                                    pattern: validationPatterns.email,
                                }}}
                                handleChange={updateFormField}
                            />
                            </div>
                        </FormRow>

                        { resourceType?.confirmation_checks &&
                            <div className="my-3 container form-container px-3 pb-1">
                                { resourceType.confirmation_checks.map((confirmation, index) => (
                                    <div className="form-row mb-3" key={`confirmation_checks_${index}`}>
                                        <div className="col">
                                            <div className="custom-control custom-switch">
                                                <input
                                                    type='checkbox'
                                                    name={`confirmation_checks[${index}]`}
                                                    id={`confirmation_checks_${index}`}
                                                    className={`custom-control-input ${errorClass(`confirmation_checks_${index}`, errors)}`}
                                                    {...formMethods.register(`confirmation_checks_${index}`, { required: true })}
                                                />

                                                <label htmlFor={`confirmation_checks_${index}`} className='custom-control-label'>
                                                    {confirmation.text} <span className="req">*</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }

                        <div className="form-row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <Select
                                        name='referral'
                                        label='How did you hear about us?'
                                        req={false}
                                        options={optionsForSelectFrom(referralTypes)}
                                        value={referral}
                                        includeBlank
                                        handleChange={e => dispatch(setReferral(e.target.value))}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <Select
                                        name='occasion'
                                        label="What's the occasion?"
                                        req={false}
                                        options={optionsForSelectFrom(occasionTypes)}
                                        value={occasion}
                                        includeBlank
                                        handleChange={e => dispatch(setOccasion(e.target.value))}
                                    />
                                </div>
                            </div>
                        </div>
                        <FormRow>
                            <div className="col-sm-12">
                            <TextArea
                                name="note"
                                label="Notes & Special Requests"
                                placeholder={false}
                                rows='4'
                                req={false}
                                value={booking.note}
                                validation={{ 'note': { required: false }}}
                                handleBlur={handleTrackAbandoned}
                                handleChange={updateFormField}
                            />
                            </div>
                        </FormRow>
                    </div>
                </div>

                <BookingModalLedger />
            </div>

            <BookingModalFooter
                modalStyles={modalStyles}
                disableNext={!bookingHeld}
                onBack={handleBack}
                onNext={formMethods.handleSubmit(handleNext, handleError)}
            />

            <style children={ 'xbm-header + .xbm-mobile-progress { display: none !important; }' } />
        </FormProvider>
    )
}
