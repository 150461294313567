import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector} from 'react-redux'
import { selectPackage } from '@/features/Packages/packageSlice'
import { selectResourceType } from '@/features/ResourceType/resourceTypeSlice'
import { selectTimeZone } from '@/features/Locations/locationSlice'
import {
    applyPromoCode,
    removePromoCode,
    selectPromoCode,
    selectHasPromoCodeApplied,
    setGiftCards,
    setPaymentReady,
    setGiftCardNumber,
    selectBooking,
    selectPaymentMethod,
} from '@/features/Bookings/bookingSlice'
import {addAlert} from '@/features/Notifications/notificationSlice'
import { isValidCode } from '@/lib/Package'

export default function PromoCode() {

    const dispatch            = useDispatch()
    const tz                  = useSelector(selectTimeZone)
    const pkg                 = useSelector(selectPackage)
    const resourceType        = useSelector(selectResourceType)
    const promoCode           = useSelector(selectPromoCode)
    const hasPromoCodeApplied = useSelector(selectHasPromoCodeApplied)
    const booking             = useSelector(selectBooking)
    const paymentMethod       = useSelector(selectPaymentMethod)

    const [error, setError]           = useState('')
    const [code, setCode]             = useState('')
    const [validating, setValidating] = useState(null)

    const [hasPromoCodesAvailable, setHasPromoCodesAvailable] = useState(false)

    const resetGiftCardPayments = () => {
        dispatch(setGiftCards({ type: 'clear' }))
        dispatch(setGiftCardNumber(""))
        dispatch(setPaymentReady(false))
    }

    const handleApply = () => {
        setValidating(true)

        const validationResult = isValidCode(booking.participants, code, booking.time, tz, pkg, resourceType)

        if (validationResult['isValid']) {
            dispatch(applyPromoCode(code))
            dispatch(addAlert({ type: 'success', text: 'Promotion Applied' }))
            resetGiftCardPayments()
            setValidating(false)
            setError('')
        } else {
            setError(validationResult['errorMessage'] || 'Sorry, that code is not valid.')
            setValidating(false)

            window.setTimeout(() => {
                setError('')
                setCode('')
            }, 4000)
        }
    }

    const handleRemove = () => {
        dispatch(removePromoCode())
        resetGiftCardPayments()
        setCode('')
    }

    useEffect(() => {
        if (
            (
                pkg?.allow_promotions === true
                && pkg?.promo_codes?.length > 0
                && hasPromoCodesAvailable === false
            ) || (
                resourceType?.allow_promo_codes === true
                && resourceType?.promo_codes?.length > 0
                && hasPromoCodesAvailable === false
            )
        ) {
            setHasPromoCodesAvailable(true)
        }
    }, [pkg])

    return hasPromoCodesAvailable && paymentMethod !== 'none' && (
    <div className="container">
        <div className="row">
            <div className="col-11 col-md-5 my-3 mt-md-0 mb-md-5 mx-auto">
                <div className="input-group">
                    <input
                        type="text"
                        id="promo_code"
                        className={`text-uppercase text-monospace form-control ${error ? 'is-invalid' : ''}`}
                        name="promoCode"
                        placeholder="Promo Code"
                        value={promoCode || code}
                        disabled={hasPromoCodeApplied}
                        onKeyPress={(e) => { if (e.key === 'Enter') { handleApply() }}}
                        onChange={(e) => setCode(e.currentTarget.value)} />

                    <div className="input-group-append">
                        {
                            hasPromoCodeApplied ? (
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={handleRemove}
                                >
                                    Remove
                                </button>
                            ) : (
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    disable={validating}
                                    onClick={handleApply}
                                >
                                    { validating ? 'Validating...' : 'Apply' }
                                </button>
                            )
                        }
                    </div>

                    {
                        error && (
                            <div className="invalid-feedback bg-danger text-white rounded mt-2 px-3 py-1 font-italic text-center">
                                { error }
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    </div>
    )
}
