import React from 'react'
import StoredCreditCard from '@/features/AdvancedPointOfSale/components/StoredCreditCard'

export default function ExistingTokenizedCardsOnFile({
    id=null,
    className='px-4 pb-4 w-100',
    profiles=[],
    selected=null,
    isOptional=false,
    onChange=() => {}
}) {
    return profiles?.length > 0 && (
        <div id={id} className={className}>
            <h6 className='pb-1 border-bottom'>
                Reservation cards on file
                <small className='pl-1 font-weight-light'>
                    ({isOptional ? 'Optional' : 'Required'})
                </small>
            </h6>

            {
                profiles.map((profile, index) => {
                    const isSelected = !!selected && selected['recurring.shopperReference'] === profile['recurring.shopperReference']

                    return (
                        <StoredCreditCard
                            key={index}
                            className={`d-flex align-items-center justify-content-between text-monospace p-2 my-1 rounded ${isSelected ? 'bg-primary' : ''}`}
                            card={profile?.storedPaymentMethods?.[0]}
                            shopperReference={profile['recurring.shopperReference']}
                            isSelected={isSelected}
                            selectable
                            onClick={() => onChange(isSelected ? null : profile) }
                        />
                    )
                })
            }
        </div>
    )
}
