import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import Select from './Select'

export default function DateSelectFields({
    day:_day=null,
    month:_month=null,
    year:_year=null,
    autoComplete=null,
    errors=[],
    disabled=false,
    required=true,
    onChange=() => {}
}) {

    const [day, setDay]     = useState(_day)
    const [month, setMonth] = useState(_month)
    const [year, setYear]   = useState(_year)
    const [date, setDate]   = useState(moment())

    useEffect(() => {
        if (!month || !day || !year) { return }

        const newDate = moment(`${month}-${day}-${year}`, 'M-D-YYYY')

        setDate(newDate)
    }, [month, day, year])

    useEffect(() => {
        if (!month || !day || !year) {
            return
        }

        onChange(null, date)
    }, [date])

    const months = useMemo(() => (
        moment.months().map((month, index) => ({
            display: month,
            value: index + 1
        }))
    ))

    const handleMonthChange = (e) => {
        setMonth(Number.parseInt(e.target.value))
    }

    const handleDayChange = (e) => {
        setDay(Number.parseInt(e.target.value))
    }

    const handleYearChange = (e) => {
        setYear(Number.parseInt(e.target.value))
    }

    const validateDate = () => {
        if (required && day && month && year) {
            return moment(`${month}/${day}/${year}`, 'M/D/YYYY', true).isValid() || 'Invalid Date'
        }
    }

    return (
        <div className="row">
            <Select
                cols='4 pr-0'
                validation={{ 'bd-month': {
                    required: required ? 'Month is required' : false,
                    validate: validateDate,
                }}}
                options={months || []}
                value={month || ''}
                label='DOB Month'
                errors={errors}
                autoComplete={autoComplete !== null ? 'bday-month' : null}
                includeBlank
                disabled={disabled && !!month}
                req={required}
                handleChange={handleMonthChange}
                handleBlur={() => null}
            />
            <Select
                cols='4'
                validation={{ 'bd-day': {
                    required: required ? 'Day is required' : false,
                    validate: validateDate,
                }}}
                options={Array.from({ length: 31 }, (_, i) => i + 1)}
                value={day || ''}
                label='DOB Day'
                errors={errors}
                autoComplete={autoComplete !== null ? 'bday-day' : null}
                includeBlank
                disabled={disabled && !!day}
                req={required}
                handleChange={handleDayChange}
                handleBlur={() => null}
            />
            <Select
                cols='4 pl-0'
                validation={{ 'bd-year': {
                    required: required ? 'Year is required' : false,
                    validate: validateDate,
                }}}
                options={Array.from({ length: 101 }, (_, i) => moment().year() - i - 17)}
                value={year || ''}
                label='DOB Year'
                errors={errors}
                autoComplete={autoComplete !== null ? 'bday-year' : null}
                includeBlank
                disabled={disabled && !!year}
                req={required}
                handleChange={handleYearChange}
                handleBlur={() => null}
            />
        </div>
    )
}
