import React from 'react'
import Promotions from '../Packages/Promotions'

export default function ResourceTypeSpecialPricingTab({ handleManualUpdate, form, errors, location, customerTypes, newPackage }) {
    if (!newPackage && form.pricing_type === 'pricing_by_customer_type' && !form.customer_types) {
        return ''
    }

    const selectedCustomerTypes = () => {
        if (!form.customer_types) { return [] }
        return customerTypes.filter(t => form.customer_types.includes(t.id))
    }

    return (
        <div className='pt-0'>
            <Promotions
                form={form}
                formType="resource_type"
                errors={errors}
                handleManualUpdate={handleManualUpdate}
                promoCodes={form.promo_codes || []}
                customerTypes={selectedCustomerTypes()}
                location={location}
                allowDollarDiscount={false}
            />
        </div>
    )
}
