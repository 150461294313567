import React from 'react'
import FormRow from '../Form/FormRow'
import RichTextEditor from '../Form/RichTextEditor'
import Input from '../Form/Input'

export default function LocationScoring({ form, handleManualUpdate, handleUpdate, errors }) {
    return (
        <div className="pt-0">

            <h3>Location Scoring App Settings</h3>

            <FormRow>
                <Input
                    name='ad_video'
                    handleChange={handleUpdate}
                    value={form.ad_video}
                    cols='12'
                    errors={errors} />
            </FormRow>
            <FormRow>
                <RichTextEditor
                    name='house_rules'
                    handleChange={handleManualUpdate}
                    value={form.house_rules}
                    cols='12'
                    errors={errors} />
            </FormRow>
        </div>
    )
}
