import { createSlice } from "@reduxjs/toolkit"

export const sourcesSlice = createSlice({
  name: 'sources',
  initialState: {
    source_types: []
  },
  reducers: {
    setSourceTypes: (state, action) => {
      state.source_types = action.payload
    }
  }
})

export const {
  setSourceTypes
} = sourcesSlice.actions

export const selectSourceTypes = state => state.sources.source_types

export default sourcesSlice.reducer
