import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { errorClass } from '@/lib/Errors'
import { selectPaymentTypes, selectAuthorizeOnlyExplanation, selectId as selectPackageId } from '@/features/Packages/packageSlice'
import {
    selectPaymentType,
    setPaymentType,
    selectCustomerTypeCounts,
    selectDate,
    selectDuration,
    selectParticipants,
    selectMemberships,
    selectTime,
    selectPromoCode,
    setGiftCards,
    setPaymentReady,
    setGiftCardNumber,
    selectPaymentMethod,
} from '@/features/Bookings/bookingSlice'
import { fetchDepositAmount, setDeposit } from '../../../Pricing/pricingSlice'

export default function PaymentOptions({ errors }) {

    const dispatch = useDispatch()

    const { register }                    = useFormContext()
    const paymentTypes                    = useSelector(selectPaymentTypes)
    const authorizeOnlyExplanationMessage = useSelector(selectAuthorizeOnlyExplanation)
    const selectedPaymentType             = useSelector(selectPaymentType)
    const participants                    = useSelector(selectParticipants)
    const memberships                     = useSelector(selectMemberships)
    const customerTypeCounts              = useSelector(selectCustomerTypeCounts)
    const duration                        = useSelector(selectDuration)
    const date                            = useSelector(selectDate)
    const time                            = useSelector(selectTime)
    const packageId                       = useSelector(selectPackageId)
    const promoCode                       = useSelector(selectPromoCode)
    const paymentMethod                   = useSelector(selectPaymentMethod)

    const handleUpdate = e => {
        dispatch(setPaymentType(e.target.value))
        dispatch(setGiftCards({ type: 'clear' }))
        dispatch(setGiftCardNumber(""))
        dispatch(setPaymentReady(false))
    }

    useEffect(() => {
        if (paymentTypes.authorize_card_only) { return }

        if (selectedPaymentType === 'deposit_only') {
            // when deposit only is selected, we have to fetch the deposit amount from the pricing slice
            dispatch(fetchDepositAmount(packageId, participants, customerTypeCounts, duration, date, time, promoCode || null, memberships || null,))
        } else {
            // when full amount is set, we need to set the deposit value to 0 so the totals reset
            dispatch(setDeposit(0))
        }
    }, [selectedPaymentType, packageId, participants, customerTypeCounts, duration, date, time, promoCode, memberships])

    return paymentMethod !== 'none' && (<>
        {
            paymentTypes.authorize_card_only ? (
                <div className="container">
                    <div className="row">
                        <div className="col text-center">
                            <h5>{ authorizeOnlyExplanationMessage }</h5>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="container form-container">
                    <div className="form-row">
                        <div className="col text-center">

                            { paymentTypes.full_amount &&
                                <div className="custom-control custom-radio custom-control-inline mx-3">
                                    <input
                                        {...register('payment_amount', { required: true })}
                                        type='radio'
                                        name='payment_amount'
                                        value="full_amount"
                                        id='full_amount'
                                        // defaultChecked
                                        checked={selectedPaymentType === 'full_amount'}
                                        className={`custom-control-input ${errorClass('payment_amount', errors)}`}
                                        onClick={handleUpdate}
                                    />
                                    <label htmlFor="full_amount" className='custom-control-label'>Pay In Full</label>
                                </div>
                            }

                            { paymentTypes.deposit_amount &&
                                <div className="custom-control custom-radio custom-control-inline mx-3">
                                    <input
                                        {...register('payment_amount', { required: true })}
                                        type='radio'
                                        name='payment_amount'
                                        id='deposit_only'
                                        value='deposit_only'
                                        checked={selectedPaymentType === 'deposit_only'}
                                        className={`custom-control-input ${errorClass('payment_amount', errors)}`}
                                        onClick={handleUpdate}
                                    />
                                    <label htmlFor="deposit_only" className='custom-control-label'>Deposit Only</label>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                )
            }
    </>)
}
