import React, { useEffect } from 'react'

export default function NumberKeyPad({
    buttons=[1, 2, 3, 4, 5, 6, 7, 8, 9, 0],
    isKeyDisabled=null,
    logoutButton=null,
    onChange=() => {},
    onDelete=() => {},
}) {

    const handleButtonPress = (e, button=null) => {
        const value = button !== null ? button : e.key

        switch(true) {
            case /backspace/i.test(value) : {
                e.preventDefault()
                onDelete()
                break
            }

            case /\d/.test(value) : {
                e.preventDefault()
                onChange(value)
                break
            }
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', handleButtonPress, false)

        return () => {
            document.removeEventListener('keydown', handleButtonPress, false)
        }
    }, [])

    return (
        <div className='number-keypad row no-gutters'>
            {
                buttons.map((button, index) => (
                    <div key={`button-${index}`} className='number-pad-button col-4'>
                        {
                            /logout/i.test(button)
                                ? logoutButton
                                : (
                                    <button
                                        type='button'
                                        className={`w-100 d-flex justify-content-center align-items-center rounded bg-gray1 text-white btn-${button}`}
                                        disabled={isKeyDisabled?.[button]}
                                        onClick={(e) => handleButtonPress(e, button)}
                                    >
                                        { !isNaN(button) && <span>{button}</span> }

                                        { /backspace/i.test(button) && <i className='fal fa-backspace' /> }
                                    </button>
                                )
                        }
                    </div>
                ))
            }
        </div>
    )
}
