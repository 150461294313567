import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectInputLocked } from '@/features/Calendar/calendarSlice'
import { UI } from '@/lib/Constants'
import { debug } from '@/lib/Debug'

export default function InputLock() {
    const inputIsLocked = useSelector(selectInputLocked)

    const handleClick = (e) => {
        try {
            e.preventDefault()
            e.stopPropagation()
            e.stopImmediatePropagation()
            return false
        } catch(e) {
            return false
        }
    }

    useEffect(() => {
        document.body.classList[inputIsLocked ? 'add' : 'remove'](UI.classes.INPUT_LOCKED)
    }, [inputIsLocked])

    return inputIsLocked
        ? <div id="calendar-input-lock" className={debug ? 'debug' : ''} onClick={handleClick} />
        : null
}
