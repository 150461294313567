import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import { selectLocation } from '@/features/Locations/locationSlice'
import { selectAdyenPayment } from '@/features/Adyen/adyenSlice'
import { selectCreditToken, setCreditZip, setCreditMethod, selectCreditZip } from '@/features/Members/memberSlice'
import AdyenCreditCard from '@/features/Adyen/AdyenCreditCard'

export default function Manual({ environment }) {
  const dispatch     = useDispatch()
  const formMethods  = useFormContext()
  const location     = useSelector(selectLocation)
  const creditToken  = useSelector(selectCreditToken)
  const adyenPayment = useSelector(selectAdyenPayment)
  const zip          = useSelector(selectCreditZip)

  const [adyenCheckout, setAdyenCheckout] = useState(null)

  const container = document.getElementById('process-payment-button-container')

  const handleAdyenSubmit = () => adyenCheckout.submit()

  useEffect(() => {
    dispatch(setCreditMethod('manual'))

    return () => {
      dispatch(setCreditMethod(null))
    }
  }, [])

  return <>
    <AdyenCreditCard
      locationId={location.id}
      locationTimeZone={location.time_zone}
      environment={environment}
      adyenCheckout={adyenCheckout}
      setAdyenCheckout={setAdyenCheckout}
      zipcode={zip}
      setZipCode={(e) => dispatch(setCreditZip(e.target.value))}
    />
    {
      container && createPortal((
        <button
          children='Process Payment'
          id="adyen-finish-button"
          className="btn btn-primary w-100 px-5"
          onClick={formMethods.handleSubmit(handleAdyenSubmit)}
        />
      ), container)
    }
    <button id='finish-button' className='btn btn-primary w-100' disabled={creditToken || adyenPayment} onClick={handleAdyenSubmit}>
      { (creditToken || adyenPayment) ? 'Authorized' : 'Authorize Credit Payment' }
    </button>
    {((creditToken || adyenPayment) && zip) &&
      <button
        type='submit'
        children='Submit Payment'
        className='btn btn-primary w-100 mt-3'
      />
    }
  </>
}
