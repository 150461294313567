import React from 'react'
import { useSelector } from 'react-redux'
import { selectSelectedType } from '../memberSlice'

export default function MembershipTypeLedger() {
    const type = useSelector(selectSelectedType)

    return (
        <div className="xbm-ledger-section">
            <h5>Membership Type</h5>
            {type?.name}
        </div>
    )
}