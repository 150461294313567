import React from 'react'

{
    /*
    * @NOTE:
    * styles for the buttons (transparent vs outline vs primary) are controlled
    * in /app/assets/stylesheets/styles/advanced-point-of-sale/terminal/_menu-item.scss
    * instead of being defined directly on each element via a className attribute.
    *
    * This allows us to have all buttons follow a "theme" that is dynamic depending
    * on HOW MANY are output from this component.
    *
    * Additional classes MAY still be included in a className attribute if necessary.
    */
}

// THEMES
// -- link-outline-solid
// -- outline-solid
// -- all-solid

export default function ButtonGroup({
    id=null,
    className='theme--link-outline-solid',
    children
}) {
    return (
        <div id={id} className={`button-group ${className}`.trim()}>
            { children }
        </div>
    )
}
