import React from 'react'
import { useSelector } from 'react-redux'
import { selectLocation } from '@/features/Locations/locationSlice'
import { selectBooking } from '@/features/EditBooking/editBookingSlice'
import { numberToCurrency } from '@/lib/Number'

export default function BookingTotals({
    newBooking=false,
    quoteMode=false,
    paymentMode=false,
    basePrice,
    taxesAndFees,
    depositToApply,
    autoGratuity,
    tip,
    remainingBalance,
    reservationTotal
}) {

    const location = useSelector(selectLocation)
    const booking  = useSelector(selectBooking)

    return (
        <div className="drawer-booking-ledger-section drawer-booking-ledger-section-totals">
            <table>
                <tbody>
                    <tr>
                        <th>Subtotal</th>
                        <td>{numberToCurrency(Number.parseFloat(basePrice))}</td>
                    </tr>
                    <tr>
                        <th>{booking?.taxes_and_fees_verbiage || location.reservation_taxes_and_fees_verbiage}</th>
                        <td>{numberToCurrency(taxesAndFees)}</td>
                    </tr>
                    {
                        !!depositToApply && (
                            <tr>
                                <th>Deposit to Apply</th>
                                <td>{numberToCurrency(depositToApply)}</td>
                            </tr>
                        )
                    }
                    {
                        autoGratuity > 0 && (
                            <tr>
                                <th>Gratuity</th>
                                <td>{numberToCurrency(autoGratuity)}</td>
                            </tr>
                        )
                    }
                    {
                        !!tip && (
                            <tr>
                                <th>Tip</th>
                                <td>{numberToCurrency(tip)}</td>
                            </tr>
                        )
                    }
                    {
                        !newBooking && !paymentMode && !quoteMode && (
                            <tr>
                                <th>Reservation Total</th>
                                <td>{numberToCurrency(!booking || isNaN(reservationTotal) ? remainingBalance : reservationTotal)}</td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </div>
    )
}
