import React from "react"
import Input from "../Form/Input"
import FormRow from "../Form/FormRow"
import Select from "../Form/Select"

export default function GuestDiscountFields({
    customerTypes,
    guestDiscount,
    selectedGuestDiscounts,
    index,
    handleGuestDiscountChange,
    handleCustomerTypeChange,
    handleRemove,
    disabledIfMembers,
}) {

    const formattedOptions = (customerTypesArray) => (
        customerTypesArray
            .map((customerType) => ({
                value: customerType.id,
                display: customerType.name,
                disabled: selectedGuestDiscounts.map((gd) => String(gd.customer_type_id)).includes(String(customerType.id))
            }))
    )

    return (
        <div className="drawer-add-row border-0">
            <FormRow>
                <Select
                    name="customer_type_id"
                    label="Customer Type"
                    cols="4"
                    value={guestDiscount.customer_type_id}
                    includeBlank='Please choose'
                    req={true}
                    options={formattedOptions(customerTypes)}
                    disabled={disabledIfMembers}
                    handleChange={(e) => {
                        handleCustomerTypeChange(e, index)
                    }}
                />

                <Select
                    name="discount_type"
                    cols="3"
                    value={guestDiscount.discount_type}
                    options={[
                        { display: "Percentage", value: "percent" },
                        { display: "Dollar", value: "dollar" },
                    ]}
                    includeBlank='Please choose'
                    req={true}
                    disabled={disabledIfMembers}
                    handleChange={(e) => handleGuestDiscountChange(e, index)}
                />

                <Input
                    name="discount_amount"
                    type="number"
                    cols="3"
                    min='0.0'
                    value={guestDiscount.discount_amount}
                    req={true}
                    disabled={disabledIfMembers}
                    append={selectedGuestDiscounts[index]?.discount_type == 'percent'}
                    prepend={selectedGuestDiscounts[index]?.discount_type == 'dollar'}
                    handleChange={(e) => handleGuestDiscountChange(e, index)}
                >
                    {
                        selectedGuestDiscounts[index]?.discount_type == 'percent' && (
                            <div className="input-group-append">
                                <span className="input-group-text">%</span>
                            </div>
                        )
                    }
                    {
                        selectedGuestDiscounts[index]?.discount_type == 'dollar' && (
                            <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                            </div>
                        )
                    }
                </Input>

                <div className="col-2 text-right">
                    <button
                        type='button'
                        children={<i className="fas fa-trash-alt" />}
                        className="btn btn-danger text-white px-3 py-2 min-h-auto mt-2"
                        disabled={disabledIfMembers}
                        onClick={() => handleRemove(index)}
                    />
                </div>
            </FormRow>
        </div>
    )
}
