import React, { useState, useRef, useEffect } from 'react'
import { useConfirm } from '@/lib/useConfirmHook'
import { debug } from '@/lib/Debug'

export default function LogoutButton({
    class:className='',
    username,
    logout_path:logoutPath=null,
    dropdown_selector:dropdownSelector=null,
    theme='complex',
    children
}) {
    const logoutRef         = useRef()
    const { confirm }       = useConfirm()
    const [ready, setReady] = useState(false)

    const handleLogout = async (e) => {
        if (!ready) {
            e.stopPropagation()
            e.preventDefault()

            if (!!dropdownSelector && jQuery) {
                await jQuery(dropdownSelector).dropdown('hide')
            }

            if ((await confirm('Are you sure you want to logout?'))) {
                // we do not want to clear out browser storage when running in
                // production, but this is extremely helpful when developing
                if (debug) {
                    window.localStorage.clear()
                    window.sessionStorage.clear()
                }
                setReady(true)
            }
        }
    }

    useEffect(() => {
        if (ready) {
            logoutRef.current.click()
        }
    }, [ready])

    return !!logoutPath && <>
        {
            /^complex$/i.test(theme) && (
                <div className={`action log-out ${className}`.trim()} data-toggle="tooltip" data-placement="bottom" title="Log Out">
                    <div className="micro-label">Logged In As</div>
                    <a href={logoutPath} rel="nofollow" data-method="delete" onClick={handleLogout} ref={logoutRef}>
                        {
                            children || <>
                                <span className="action-value">{ username }</span> <i className="far fa-sign-out-alt" />
                            </>
                        }
                    </a>
                </div>
            )
        }

        {
            /^simple$/i.test(theme) && (
                <a
                    href={logoutPath}
                    className={className}
                    children={children || 'Logout'}
                    rel="nofollow"
                    data-method="delete"
                    onClick={handleLogout}
                    ref={logoutRef}
                />
            )
        }
    </>
}
