import React from 'react'
import { useSelector } from 'react-redux'
import GiftCardFormPaymentType from '@/features/GiftCards/components/GiftCardFormPaymentType'
import GiftCardFormCheck from '@/features/GiftCards/components/GiftCardFormCheck'
import ProductGiftCard from '@/features/Products/ProductGiftCard'
import {
  selectPaymentType,
  selectCheckNumber,
  selectTotal,
} from '@/features/Members/memberSlice'
import { selectValid } from '@/features/ManagerCodes/managerCodeSlice'
import {
  selectGiftCardNumber,
  selectGiftCardBalance,
} from '@/features/Products/productSlice'
import CreditCardPayments from './CreditCardPayments'
import Totals from './Totals'
import SkipPayment from './SkipPayment'
import PaymentProcessing from './PaymentProcessing'

export default function MembershipPayment() {
  const paymentType = useSelector(selectPaymentType)
  const managerIsAuthorized = useSelector(selectValid)
  const checkNumber = useSelector(selectCheckNumber)
  const giftCardNumber = useSelector(selectGiftCardNumber)
  const balance = useSelector(selectGiftCardBalance)
  const paymentAmount = useSelector(selectTotal)

  const submitButton = (
    <button
      type='submit'
      className='btn btn-primary w-100'
      disabled={(
        (paymentType === 'check' && checkNumber === null)
        ||
        (paymentType === 'gift_card' && (giftCardNumber === null || balance === null || (balance < (Math.round(paymentAmount * 100)))))
      )}
    >Submit Payment</button>
  )

  return <>
    <h5 className='border-bottom mb-4'>Payment</h5>

    <GiftCardFormPaymentType membershipPayment />

    <span style={{ 'borderTop' : '1px solid #E2E2E2' }}></span>

    <div className='row mt-4 h-100'>
      <div className='col-8'>
        {paymentType === 'credit' &&
          <CreditCardPayments />
        }
        {paymentType === 'gift_card' && <>
          <ProductGiftCard membershipPayment />
          <div className='mt-3'>{submitButton}</div>
        </>}
        {paymentType === 'check' && <>
          <GiftCardFormCheck membershipPayment />
          <div className='mt-3'>{submitButton}</div>
        </>}
        {paymentType === 'cash' && <div className='mt-5'>{submitButton}</div>}
        {paymentType === 'skip_payment' && <>
          <SkipPayment />
          {managerIsAuthorized && <div className='mt-3'>{submitButton}</div>}
        </>}
      </div>
      <div className='col-4 mt-4'>
        <Totals />
      </div>
    </div>

    <PaymentProcessing />
  </>
}
