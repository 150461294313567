import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Input from '@/components/Form/Input'
import FormRow from '@/components/Form/FormRow'
import DateSelectFields from '@/components/Form/DateSelectFields'
import {
  setMemberInformationField,
  selectCreateMember,
  selectMemberInformation,
  selectFormErrors,
} from '@/features/Members/memberSlice'
import moment from 'moment'
import { validationPatterns } from '@/lib/Errors'

export default function MemberInformation() {
  const dispatch          = useDispatch()
  const createMember      = useSelector(selectCreateMember)
  const memberInformation = useSelector(selectMemberInformation)
  const errors            = useSelector(selectFormErrors)

  return <>
    <h5 className='border-bottom mb-3'>Member Information</h5>
    <FormRow>
      <Input
        cols='6'
        type='text'
        label='First Name'
        placeholder='John'
        className='form-control'
        value={memberInformation.firstName}
        validation={{ 'firstName': {
          required: createMember || !memberInformation.firstName,
        }}}
        disabled={!createMember && !!memberInformation.firstName}
        errors={errors}
        autoFocus
        handleChange={e => dispatch(setMemberInformationField({ name: 'firstName', value: e.target.value }))}
      />
      <Input
        cols='6'
        type='text'
        label='Last Name'
        placeholder='Doe'
        className='form-control'
        value={memberInformation.lastName}
        validation={{ 'lastName': {
          required: createMember || !memberInformation.lastName,
        }}}
        disabled={!createMember && !!memberInformation.lastName}
        errors={errors}
        handleChange={e => dispatch(setMemberInformationField({ name: 'lastName', value: e.target.value }))}
      />
    </FormRow>

    <FormRow>
      <Input
        cols='12'
        type='text'
        label='Email'
        className='form-control'
        value={memberInformation.email}
        disabled
      />
    </FormRow>

    <FormRow>
      <Input
        cols='6'
        type='tel'
        className='form-control'
        value={memberInformation.phone}
        mask='999-999-9999'
        placeholder='123-456-7890'
        validation={{ 'phone': {
          required: createMember || !memberInformation.phone,
          pattern: validationPatterns.phone_basic,
        }}}
        disabled={!createMember && !!memberInformation.phone}
        errors={errors}
        handleChange={e => dispatch(setMemberInformationField({ name: 'phone', value: e.target.value }))}
      />
      <Input
        cols='6'
        type='text'
        className='form-control'
        value={memberInformation.zip_code}
        validation={{ 'zip_code': {
          required: createMember || !memberInformation.zip_code,
          pattern: validationPatterns.zip_code,
        }}}
        placeholder='12345'
        disabled={!createMember && !!memberInformation.zip_code}
        errors={errors}
        handleChange={e => dispatch(setMemberInformationField({ name: 'zip_code', value: e.target.value }))}
        />
      </FormRow>
      <FormRow>
        <div className='col-12'>
          <DateSelectFields
            month={Number((memberInformation.birthdate?.split('-'))?.[0])}
            day={Number((memberInformation.birthdate?.split('-'))?.[1])}
            year={Number((memberInformation.birthdate?.split('-'))?.[2])}
            required={createMember || !memberInformation.birthdate}
            errors={errors}
            autoComplete
            disabled={!createMember}
            onChange={(_, value) => dispatch(setMemberInformationField({ name: 'birthdate', value: moment(value).format('M-D-YYYY') }))}
          />
        </div>
      </FormRow>
  </>
}
