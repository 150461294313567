import { interpolateSpectral } from 'd3-scale-chromatic';

export default function colorInterpolation(numberOfColors) {
    const firstColorPoint = (1 / numberOfColors);
    let colorsArray = [];
    for (let i = 0; i < numberOfColors; i++) {
        let addedValue = (firstColorPoint * i);
        let interpolatedPoint = firstColorPoint + addedValue;
        colorsArray.push(interpolateSpectral(interpolatedPoint));
    };

    return colorsArray;
};