import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { TrixEditor } from "react-trix";
import errorClass from '../../lib/Errors'
import InputWrapper from './InputWrapper'

export default function RichTextEditor({
  name='',
  value='',
  cols,
  errors=[],
  label,
  hideLabel,
  setEditor,
  req=null,
  validation=null,
  style,
  handleChange=() => {}
}) {
    const formMethods = useFormContext()

    const _name = validation ? Object.keys(validation)[0] : name

    /**
     * Example of an expected validation
     * schema object to register
     *
     * {
     *   'name': {
     *     required: 'A name is required',
     *     minLength: { value: 4, message: 'must be more than 4 characters long' }
     *   }
     * }
     */

    const handleUpdate = (field, value) => {
        // update react hook form validation
        if ('onChange' in field) { field.onChange(value) }
        // update our form object (assumed via handleManualUpdate)
        handleChange(field.name, value)
    }

    const handleEditorReady = editor => {
        if (setEditor && typeof setEditor === 'function') {
            setEditor(editor)
        }
    }

    return (
        <InputWrapper
            name={_name}
            req={(validation && req === null) || Boolean(req)}
            cols={cols}
            errors={errors}
            label={label}
            hideLabel={hideLabel}
        >
          <div className={'trix ' + errorClass(_name, errors)}>
              { formMethods ? (
                  <Controller
                      name={_name}
                      control={formMethods.control}
                      defaultValue={value || ''}
                      rules={validation ? validation[Object.keys(validation)[0]] : {}}
                      render={({ field }) =>
                          <TrixEditor
                              ref={field.ref}
                              name={_name}
                              value={field.value || ''}
                              className={`form-control ${errorClass(_name, errors)}`}
                              onEditorReady={handleEditorReady}
                              onChange={(value) => handleUpdate(field, value)}
                              onBlur={(value) => handleUpdate(field, value)}
                          />
                      }
                  />
              ) : (
                  <TrixEditor
                      name={_name}
                      value={value || ''}
                      className={`form-control ${errorClass(_name, errors)}`}
                      onEditorReady={handleEditorReady}
                      onChange={(e) => handleUpdate({}, e)}
                      onBlur={(e) => handleUpdate({}, e)}
                  />
              )}
          </div>
        </InputWrapper>
    )
}
