import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectStep } from '@/features/Bookings/bookingSlice'
import { debug } from '@/lib/Debug'

export default function BookingModalProgressBar({
    id=null,
    className='',
    totalSteps=6,
    usedStep0=false,
    onChange=() => {},
}) {
    const step = useSelector(selectStep)

    /*
     * @note: The largest percentage is always 95% so that it looks like there's one last
     * little step to go (i.e., prompting the customer to click the final submit button)
     */
    const progress = useMemo(() => {
        const _currentStep = Number(usedStep0 ? step : step - 1)
        const _totalSteps  = Number(totalSteps - 1)
        const percent      = ((_currentStep / _totalSteps) * 100).toFixed() - 5

        if (console && debug) { console.log(_currentStep, _totalSteps, percent) }
        onChange(percent)

        return percent
    }, [step])

    return (
        <div id={id} className={`progress-bar--container ${className}`.trim()}>
            <div className='progress-bar' style={{ width: `${progress}%` }} />
        </div>
    )
}
