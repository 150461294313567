import React, { useEffect, useMemo } from 'react'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { useFormState } from 'react-hook-form'
import giftCardStore from '@/stores/giftCardStore'
import {
    setBalanceDue,
    selectBalanceDue,
    selectPaymentType,
    selectCreditToken,
    selectDiscount,
    setDiscount
} from '@/features/GiftCards/giftCardSlice'
import { selectValid } from '@/features/ManagerCodes/managerCodeSlice'
import Input from '@/components/Form/Input'
import FormRow from '@/components/Form/FormRow'
import AmountFields from '@/components/GiftCards/AmountFields'
import GiftCardFormPayment from '@/features/GiftCards/components/GiftCardFormPayment'
import NotificationList from '@/features/Notifications/NotificationList'
import { selectAdyenPayment } from '@/features/Adyen/adyenSlice'
import { validationPatterns } from '@/lib/Errors'
import { numberToCurrency } from '@/lib/Number'
import PromoCode from '@/features/GiftCards/components/PromoCode'

export default function GiftCardForm({
    locationId,
    companyId,
    locationTimeZone,
    environment,
    recordUrl,
    amounts,
    hardwareKey,
    validPromotionWithoutCode,
    hasPromotionWithCode,
    form,
    errors,
    handleUpdate,
    handleManualUpdate,
    handleDisableSaveButton,
    paymentProcessor,
    handleSaveAndClose,
}) {
    return (
        <Provider store={giftCardStore}>
            <GiftCardFormFields
                recordUrl={recordUrl}
                amounts={amounts}
                hardwareKey={hardwareKey}
                locationId={locationId}
                companyId={companyId}
                locationTimeZone={locationTimeZone}
                environment={environment}
                form={form}
                errors={errors}
                handleUpdate={handleUpdate}
                handleManualUpdate={handleManualUpdate}
                handleDisableSaveButton={handleDisableSaveButton}
                paymentProcessor={paymentProcessor}
                handleSaveAndClose={handleSaveAndClose}
                validPromotionWithoutCode={validPromotionWithoutCode}
                hasPromotionWithCode={hasPromotionWithCode}
            />
            <NotificationList />
        </Provider>
    )
}

export function GiftCardFormFields({
    locationId,
    companyId,
    locationTimeZone,
    environment,
    amounts,
    hardwareKey,
    form,
    errors,
    handleUpdate,
    handleManualUpdate,
    handleDisableSaveButton,
    paymentProcessor,
    handleSaveAndClose,
    validPromotionWithoutCode,
    hasPromotionWithCode,
}) {

    const { isValid } = useFormState()

    const dispatch            = useDispatch()
    const balanceDue          = useSelector(selectBalanceDue)
    const paymentType         = useSelector(selectPaymentType)
    const creditToken         = useSelector(selectCreditToken)
    const managerIsAuthorized = useSelector(selectValid)
    const adyenPayment        = useSelector(selectAdyenPayment)
    const discount            = useSelector(selectDiscount)

    const discountName = useMemo(() => {
        if (discount?.amount_type === 'percent') {
            return ` (-${discount.amount}%)`
        }

        return ''
    }, [discount])

    useEffect(() => {
        handleDisableSaveButton(true)

        if (validPromotionWithoutCode) {
            dispatch(setDiscount(validPromotionWithoutCode))
        }
    }, [])

    useEffect(() => {
        switch(paymentType) {
            case 'credit' :
            case 'terminal' :
                handleDisableSaveButton(isValid && (creditToken || adyenPayment) ? false : true)
                break

            case 'skip_payment' :
                handleDisableSaveButton(isValid && managerIsAuthorized ? false : true)
                break

            default :
                handleDisableSaveButton(isValid ? false : true)
        }
    }, [isValid, creditToken, managerIsAuthorized, paymentType, adyenPayment])

    useEffect(() => {
        if (creditToken) {
            handleManualUpdate('credit_token', creditToken)
        }
    }, [creditToken])

    useEffect(() => {
        if (adyenPayment) {
            handleManualUpdate('adyen_payment', adyenPayment)
        }
    }, [adyenPayment])

    useEffect(() => {
        if (discount?.amount) {
            handleManualUpdate('discount', discount)

            if (discount?.amount) {
                if (discount.amount_type === 'percent') {
                    const discount_amount = form.amount * discount.amount / 100
                    dispatch(setBalanceDue(form.amount - discount_amount))
                } else {
                    dispatch(setBalanceDue(form.amount - discount.amount))
                }
            } else {
                dispatch(setBalanceDue(form.amount))
            }
        }
    }, [discount])

    return (
        <div>
            <div className='row'>
                <div className='col-8'>
                    <AmountFields
                        amounts={amounts}
                        handleChange={(amount) => {
                            handleManualUpdate('amount', amount)

                            if (discount?.amount) {
                                if (discount.amount_type === 'percent') {
                                    const discount_amount = amount * discount.amount / 100
                                    dispatch(setBalanceDue(amount - discount_amount))
                                } else {
                                    dispatch(setBalanceDue(amount - discount.amount))
                                }
                            } else {
                                dispatch(setBalanceDue(amount))
                            }
                        }}
                    />

                    <FormRow>
                        <Input
                            cols={6}
                            disabled={!!form.id}
                            label="Recipient Email"
                            type="email"
                            placeholder=""
                            value={form.email}
                            validation={{ 'email': { required: true, pattern: validationPatterns.email } }}
                            errors={errors}
                            handleChange={handleUpdate}
                        />

                        <Input
                            cols={6}
                            disabled={!!form.id}
                            type="email"
                            placeholder=""
                            value={form.receipt_email}
                            validation={{ 'receipt_email': { required: true, pattern: validationPatterns.email } }}
                            autoComplete="email"
                            errors={errors}
                            handleChange={handleUpdate}
                        />
                    </FormRow>
                    {hasPromotionWithCode && parseFloat(balanceDue) > 0 &&
                        <FormRow>
                            <div className='col-6'>
                                <PromoCode locationId={locationId} companyId={companyId} />
                            </div>
                        </FormRow>
                    }
                </div>

                <div className='col-4 m-auto'>
                    <div className='d-flex justify-content-between'>
                        <div className='text-bold'>Total:</div>
                        <div>{numberToCurrency(form.amount)}</div>
                    </div>
                    {form.amount > 0 && discount?.amount &&
                        <div className='d-flex justify-content-between'>
                            <div>Promotion Applied{discountName}</div>
                            <div>-{numberToCurrency(form.amount - balanceDue)}</div>
                        </div>
                    }
                    <div className='d-flex justify-content-between border-top'>
                        <div className='text-bold'>Payment Total:</div>
                        <div>{numberToCurrency(balanceDue)}</div>
                    </div>
                </div>
            </div>
            <GiftCardFormPayment
                form={form}
                locationId={locationId}
                locationTimeZone={locationTimeZone}
                environment={environment}
                paymentProcessor={paymentProcessor}
                hardwareKey={hardwareKey}
                handleDisableSaveButton={handleDisableSaveButton}
                handleManualUpdate={handleManualUpdate}
                handleSaveAndClose={handleSaveAndClose}
            />
        </div>
    )
}
