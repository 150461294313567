import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import Input from '@/components/Form/Input'
import FormRow from '@/components/Form/FormRow'
import RichTextEditor from '@/components/Form/RichTextEditor'
import AdditionalCustomEmailFields from '@/components/Locations/AdditionalCustomEmailFields'
import { errorsFor } from '@/components/Form/ErrorsHelper'
import titleize from '@/lib/String'
import { UI } from '@/lib/Constants'

export default function LocationCustomizedEmailEditModal({ showModal, email, emailSubject, form, handleManualUpdate, handleClose }) {
    const { unregister, trigger, formState } = useFormContext()

    const [errors, setErrors]                     = useState([])
    const [subject, setSubject]                   = useState('')
    const [content, setContent]                   = useState('')
    const [additionalFields, setAdditionalFields] = useState({})

    const handleKeyDown = (e) => {
        e.stopPropagation()

        if (
            e.key === 'Escape'
            && !document.body.classList.contains(UI.classes.CONFIRMATION_OPEN)
            && !document.body.classList.contains(UI.classes.HELPJUICE_OPEN)
        ) {
            handleClose()
        }
    }

    const handleSave = async () => {
        if (await trigger()) {
            handleClose()
        }
    }

    /**
     * Parse and update form fields if the main form object's
     * customized_emails content ever changes (ex: on load or when cleared)
     */
    useEffect(() => {
        const emails = JSON.parse(form.customized_emails)
        setSubject(emails[email]?.subject || '')
        setContent(emails[email]?.content || '')

        const tmp = !!emails[email]
            ? Object.fromEntries(Object.entries(emails[email]).filter(([key]) => !/subject|content/i.test(key)))
            : null

        setAdditionalFields(tmp || {})
    }, [form.customized_emails])

    /**
     * Whenever the mini-form in the modal is updated,
     * send that update back up to the main form object
     * to be saved when the location is saved.
     */
    useEffect(() => {
        if (showModal && Object.keys(formState.errors).length === 0) {
            let updatedContent    = { ...JSON.parse(form.customized_emails) }
            updatedContent[email] = { subject, content, ...additionalFields }
            updatedContent        = JSON.stringify(updatedContent)
            handleManualUpdate('customized_emails', updatedContent)
        }
    }, [subject, content, additionalFields])

    /**
     * Update our errors object whenever the form error state changes
     */
    useEffect(() => {
        setErrors(errorsFor(formState.errors))
    }, [formState, subject, content, additionalFields])

    /**
     * Respond to showing/hiding of the modal
     */
    useEffect(() => {
        if (showModal) {
            document.body.classList.add(UI.classes.MULTIPLE_MODALS_OPEN)
        } else {
            document.body.classList.remove(UI.classes.MULTIPLE_MODALS_OPEN)
            unregister('subject')
            unregister('content')
        }
    }, [showModal])

    /**
     * Handle the initial mount and
     * unmount of the component
     */
    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown, false)

        // cleanup/reset on unmount
        return () => {
            document.body.classList.remove(UI.classes.MULTIPLE_MODALS_OPEN)
            document.removeEventListener('keydown', handleKeyDown, false)
        }
    }, [])

    return !showModal ? '' : (
        <div className="modal modal-backdrop email-edit--modal modal--lg">
            <div className="modal-container">
                <div className="modal-header">
                    <div className="modal-title">
                        { /email/i.test(email) ? titleize(email) : `${titleize(email)} Email` }
                    </div>

                    <button type='button' className="modal-close" onClick={() => handleClose()}>
                        <span>Close</span>
                        <i className="far fa-times" />
                    </button>
                </div>

                <div className="modal-body">
                    <div className="modal-body-main">
                        <div className="modal-body-main-section text-left p-4">
                            <FormRow className="mb-3 px-1">
                                <Input
                                    cols='12'
                                    value={subject}
                                    placeholder=' '
                                    req={false}
                                    validation={{ 'subject': { required: false }}}
                                    errors={errors}
                                    handleChange={(e) => setSubject(e.currentTarget.value)}
                                />
                                <small className="form-text text-muted ml-2 mt-n2">
                                    Default: { typeof emailSubject == 'string' ? emailSubject : '' }
                                </small>
                            </FormRow>

                            <AdditionalCustomEmailFields
                                emailName={email}
                                content={additionalFields}
                                errors={errors}
                                onChange={(object) => setAdditionalFields(object)}
                            />

                            <FormRow className="pt-3 m-0">
                                <RichTextEditor
                                    cols='12'
                                    value={content}
                                    label="Additional Email Body Content"
                                    req={false}
                                    validation={{ 'content': { required: false }}}
                                    errors={errors}
                                    handleChange={(f,v) => setContent(v)}
                                />
                            </FormRow>
                        </div>
                    </div>
                </div>

                <div className="modal-footer">
                    <button type='submit' className='btn btn-link text-danger' onClick={() => handleClose()}>
                        Close
                    </button>
                    <button type='submit' className='btn btn-primary ml-auto' onClick={handleSave} >
                        Done
                    </button>
                </div>
            </div>
        </div>
    )
}
