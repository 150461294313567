import React, { useEffect } from 'react'

export default function ResourceTypeTabs({
  activeTab,
  isReservable=true,
  onChange,
}) {
  const tabs = isReservable ? [
    { slug: 'setup_reservable',  title: 'Setup'             },
    { slug: 'custom_open_hours', title: 'Custom Open Hours' },
    { slug: 'custom_peak_hours', title: 'Custom Peak Hours' },
    { slug: 'special_pricing',   title: 'Special Pricing'   },
  ] : [
    { slug: 'setup_non_reservable', title: 'Setup' },
  ]

  useEffect(() => {
    onChange(tabs[0].slug)
  }, [isReservable])

  return (
    <div className="drawer-tabs">
      {
        tabs.map(({ slug, title }) => (
          <div
            key={slug}
            children={title}
            className={`tab ${slug === activeTab ? 'active' : ''}`}
            onClick={() => onChange(slug)}
          />
        ))
      }
    </div>
  )
}
