import React from 'react'
import { useDispatch } from 'react-redux'
import { startTraining, completeTraining, setBooking, updateStatus } from '@/features/EditBooking/editBookingSlice'
import { isBookingHeld } from '@/lib/Booking'
import StatusIcons from '@/components/Bookings/StatusIcons'

export default function BookingStatusIcons({
  pkg,
  booking,
  resources,
  completeConfirmationOptions={},
  additionalCompleteWarningText='',
  childInParentModal=false,
}) {
    const dispatch = useDispatch()

    const handleTrainingStarted = () => {
      dispatch(setBooking({booking: booking, resources: resources}))
      dispatch(startTraining())
    }

    const handleTrainingComplete = () => {
      dispatch(setBooking({booking: booking, resources: resources}))
      dispatch(completeTraining())
    }

    const handleUpdateStatus = (status, should_activate) => {
      dispatch(setBooking({booking: booking, resources: resources}))
      dispatch(updateStatus(status, should_activate))
    }

    return isBookingHeld(booking) ? null : (
        <StatusIcons
            booking={booking}
            pkg={pkg}
            completeConfirmationOptions={completeConfirmationOptions}
            additionalCompleteWarningText={additionalCompleteWarningText}
            childInParentModal={childInParentModal}
            onToggleTrainingStarted={handleTrainingStarted}
            onToggleTrainingComplete={handleTrainingComplete}
            onToggleInProgress={handleUpdateStatus}
            onToggleWarning={handleUpdateStatus}
            onToggleComplete={handleUpdateStatus}
        />
    )
}
