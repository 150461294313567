import React, { useMemo, useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import TextareaAutosize from 'react-textarea-autosize'
import { errorsFor } from '@/components/Form/ErrorsHelper'
import { BasicBookingInfo } from '../BasicBookingInfo'
import BookingDurations from '../BookingDurations'
import BookingCalendar from '../BookingCalendar'
import BookingStartTime from '../BookingStartTime'
import { BookingActions } from '../BookingActions'
import { useDispatch, useSelector } from 'react-redux'
import {
    selectLoading,
    selectDuration,
    selectDate,
    selectTime,
    selectForm,
    setFormAttribute,
    setStep,
    holdCalendarBooking
} from '@/features/Schedule/scheduleSlice'
import { selectDates } from '../../../Availability/availabilitySlice'
import { selectId } from '../../../Packages/packageSlice'
import QuoteExpirationDate from '@/features/Schedule/components/QuoteExpirationDate'
import NewBookingLedger from '../NewBookingLedger'
import QuoteToggle from '../QuoteToggle'
import Tabs from '@/features/Schedule/components/steps/Tabs'
import { useConfirm } from '@/lib/useConfirmHook'
import { selectMemberCount } from '../../scheduleSlice'

export default function BookingSetupStep2({ handleClose, children }) {

    const dispatch            = useDispatch()
    const { confirm }         = useConfirm()
    const formMethods         = useFormContext()
    const loading             = useSelector(selectLoading)
    const duration            = useSelector(selectDuration)
    const date                = useSelector(selectDate)
    const time                = useSelector(selectTime)
    const availableDates      = useSelector(selectDates)
    const packageId           = useSelector(selectId)
    const form                = useSelector(selectForm)
    const memberCount         = useSelector(selectMemberCount)
    const [errors, setErrors] = useState({})

    const isNewQuote = !form.require_email_address

    const buttonLabel = useMemo(() => {
      const verb = form.require_email_address ? 'Booking' : 'Quote'
      return loading ? `Creating ${verb}...` : `Create ${verb}`
    }, [loading, form.require_email_address])

    /**
     * Create a record to hold the booking
     *
     * note: at this step we are just holding
     * the booking - finalize is for payment?
     */
    const handleCreate = async () => {
        if (isNewQuote && !await confirm("Is the quote's start time and date correct?<br /><br />This cannot be updated later.")) {
            return
        }
        dispatch(holdCalendarBooking(packageId))
    }

    const handleQuoteExpirationDateChange = (localizedDateTime) => {
        dispatch(setFormAttribute({ name: 'quote_expires_at', value: localizedDateTime }))
    }

    /**
     * Loop through form errors and build up our own errors
     * object that gets passed down to all of the components
     */
    const onError = (formErrors) => {
        setErrors(errorsFor(formErrors))
    }

    /**
     * Handle initial load
     * or unload of component
     */
    useEffect(() => {
        return () => {
            // i think we can only do this if there is no memberCount. if there is a member
            // count, i think we need to move this logic somewhere else or something? this logic
            // would need to go when the member validation and new form submission happens member-side.
            if (memberCount < 1) {
                dispatch(setFormAttribute({ name: 'date', value: null }))
                dispatch(setFormAttribute({ name: 'time', value: null }))
                dispatch(setFormAttribute({ name: 'duration', value: null }))
                formMethods.reset()
                setErrors({})
            }
        }
    }, [])

    /**
     * Update errors when form state changes
     */
    useEffect(() => {
        setErrors(errorsFor(formMethods.formState.errors))
    }, [formMethods.formState])

    const renderNextButton = () => {
        // if they have not filled out the required fields, don't show anything yet.
        if (!duration || !date || !time) {
            return
        }

        return submitButton()
    }

    const submitButton = () => {
        return (
            <button
                type="button"
                className="btn btn-primary"
                disabled={loading}
                onClick={formMethods.handleSubmit(handleCreate, onError)}>
                { buttonLabel }
            </button>
        )
    }

    return (
        <div className={`drawer-scaffold ${isNewQuote ? 'quote' : ''}`}>
            <div className="drawer-main">
                <div className="drawer-header-container">
                    <div className="drawer-header">
                        <QuoteToggle />
                    </div>
                    <Tabs />
                </div>
                <div className="drawer-main-content">
                    <div className="drawer-scroller">

                        <BasicBookingInfo
                            form={form}
                            errors={errors}
                            newBooking={true}
                            dispatchAction={setFormAttribute} />

                        {
                            isNewQuote && <>
                                <div className="form-group">
                                    <label>
                                        Notes (Optional - Will be shown in quote pdf/email)
                                    </label>
                                    <TextareaAutosize
                                        cacheMeasurements
                                        className="form-control"
                                        name="note"
                                        placeholder="Thanks for your interest. Here is your quote."
                                        value={form.note || ""}
                                        onChange={(e) => dispatch(setFormAttribute({ name: e.target.name, value: e.target.value }))}
                                    />
                                </div>

                                <QuoteExpirationDate
                                    form={form}
                                    errors={errors}
                                    onChange={handleQuoteExpirationDateChange}
                                />
                            </>
                        }

                        <div className="row">
                            <div className="col">
                                <BookingDurations errors={errors} />
                            </div>
                            <div className="col">
                                { duration && <BookingCalendar /> }
                            </div>
                        </div>

                        { duration && availableDates.length > 0 && <BookingStartTime /> }
                    </div>
                </div>

                <BookingActions handleClose={handleClose}>
                    <button
                        type="button"
                        className="btn btn-outline-primary mr-3"
                        onClick={() => dispatch(setStep('1'))}
                    >
                        <i className="fal fa-chevron-left mr-3" /> Back
                    </button>

                    { renderNextButton() }
                </BookingActions>

            </div>
            <div className="drawer-aside">
                <NewBookingLedger />
            </div>

            { children }
        </div>
    )
}
