import React, { useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

export default function DraggableOptions({
  packages,
  inputValue,
  handleCheckboxChange,
  groupPackages,
  setGroupPackages,
  errorClass,
  errors
}) {
  useEffect(() => {
    let sortedPackages = []
    let activePackages = [...packages]

    inputValue.forEach(value => {
      const foundPackage = activePackages.find(p => p.id === value)

      if(foundPackage) {
        sortedPackages.push(foundPackage)
        activePackages = activePackages.filter(p => p.id !== value)
      }
    })

    setGroupPackages([...sortedPackages, ...activePackages])
  }, [])

  const handleOnDragEnd = result => {
    if (!result.destination) return

    const items = Array.from(groupPackages)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    setGroupPackages(items)
    handleCheckboxChange(items)
  }

  const getItems = packages => {
      return packages.map((p, index) => {
        const isDisabled = !p.active

        return (
          <Draggable key={p.id} draggableId={`${p.id}`} index={index}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                <div className={`form-group draggable cursor-pointer px-2 ${index === 0 ? 'pb-1' : 'border-top mt-1 pt-3 pb-2'}`}>
                  <div className="custom-control custom-checkbox">
                    <input
                      id={`packages-toggle-${p.id}`}
                      name={p.name}
                      type="checkbox"
                      className={`custom-control-input ${!isDisabled && errorClass('packages', errors)}`}
                      value={p.id}
                      checked={inputValue.includes(parseInt(p.id, 10))}
                      disabled={isDisabled}
                      onChange={() => handleCheckboxChange(groupPackages)}
                    />

                    <label className="custom-control-label w-100 d-flex align-items-center justify-content-between" htmlFor={`packages-toggle-${p.id}`}>
                        <strong className="pl-1">{p.name}</strong>
                        <span>
                          <span className={`badge badge-${p.show_package_in_drawer ? 'success' : 'default'} py-1 ml-2`}>Shown In Drawer</span>
                          <span className={`badge badge-${p.allow_member_benefits ? 'success' : 'default'} py-1 ml-2`}>Allows Member Benefits</span>
                          <i className="fas fa-bars text-muted ml-3" />
                        </span>
                    </label>
                  </div>
                </div>
              </div>
            )}
          </Draggable>
        )
    })
  }

  return(
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="draggable-options">
        {(provided) => (
          <div className='draggable-options' {...provided.droppableProps} ref={provided.innerRef}>
            {getItems(groupPackages)}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
