import React, { useEffect } from 'react'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import FormRow from '../Form/FormRow'
import Input from '../Form/Input'
import Select from '../Form/Select'
import DatePicker from '../Form/DatePicker'
import Checkbox from '../Form/Checkbox'
import { momentToLocalDate, localDateToMoment } from '@/lib/CalendarTimes'
import titleize from '@/lib/String'

export default function PromotionField({
    form,
    customerTypes,
    code,
    index,
    location,
    allowDollarDiscount,
    handlePromoCodeChange,
    handleCustomerTypeChange,
    handleRemove
}) {
    const tz = location?.time_zone || 'America/New_York'

    const cols = allowDollarDiscount ? 3 : 4

    useEffect(() => {
        if (!code.require_code) {
            handlePromoCodeChange({ target: { name: 'code', value: '' } }, index)
        }
    }, [code.require_code])

    return (
        <div className="promo-code-rules">
            <div className="d-flex w-100">
                <button
                    type='button'
                    className='btn remove ml-auto py-1 px-0'
                    style={{ height: 'auto' }}
                    onClick={() => handleRemove(index)}
                >
                    REMOVE
                </button>
            </div>

            <FormRow>
                <Input
                    name='name'
                    req={true}
                    disabled={!!code.uuid}
                    handleChange={e => handlePromoCodeChange(e, index)}
                    value={code.name}
                    cols={cols} />

                <Input
                    name='code'
                    cols={cols}
                    req={code.require_code}
                    disabled={!code.require_code || !!code.uuid}
                    value={code.code}
                    handleChange={e => handlePromoCodeChange(e, index)}
                />

                {allowDollarDiscount &&
                <Select
                    name='amount_type'
                    req={true}
                    disabled={!!code.uuid}
                    handleChange={e => handlePromoCodeChange(e, index)}
                    options={[{ value: 'percent', display: 'Percent' }, { value: 'dollar', display: 'Dollar' }]}
                    value={code.amount_type}
                    cols={cols} />
                }
                <Input
                    name='amount'
                    type='number'
                    req={true}
                    disabled={!!code.uuid}
                    prepend={true}
                    handleChange={e => handlePromoCodeChange(e, index)}
                    value={code.amount}
                    cols={cols}
                >
                    {code.amount_type === 'dollar' ?
                    <div className="input-group-prepend">
                        <span className="input-group-text">$</span>
                    </div>
                    :
                    <div className="input-group-append">
                        <span className="input-group-text">%</span>
                    </div>
                    }
                </Input>
            </FormRow>

            <FormRow>
                <DatePicker
                    cols={cols}
                    name='valid_from'
                    req={true}
                    disabled={!!code.uuid}
                    value={code.valid_from ? momentToLocalDate(moment.tz(code.valid_from, tz)) : null}
                    handleChange={(_t, d) => handlePromoCodeChange({target: { name: 'valid_from', value: localDateToMoment(d, tz).toISOString() }}, index)}
                />

                <DatePicker
                    cols={cols}
                    name='valid_to'
                    req={true}
                    disabled={!!code.uuid}
                    value={code.valid_to ? momentToLocalDate(moment.tz(code.valid_to, tz)) : null}
                    handleChange={(_t, d) => handlePromoCodeChange({target: { name: 'valid_to', value: localDateToMoment(d, tz).toISOString() }}, index)}
                />

                <Select
                    name='date_range_type'
                    label='Date Range Type'
                    disabled={!!code.uuid}
                    handleChange={e => handlePromoCodeChange(e, index)}
                    options={[
                        { value: 'start_time', display: 'Reservation Date' },
                        { value: 'created_at', display: 'Created Date' },
                    ]}
                    value={code.date_range_type || 'start_time'}
                    cols={cols}
                    />
            </FormRow>

            <FormRow>
                <Input
                    name='minimum_group_size'
                    type='number'
                    disabled={!!code.uuid}
                    handleChange={e => handlePromoCodeChange(e, index)}
                    value={code.minimum_group_size}
                    cols={cols} />

                <Input
                    name='limit'
                    type='number'
                    disabled={!!code.uuid}
                    handleChange={e => handlePromoCodeChange(e, index)}
                    value={code.limit}
                    cols={cols} />

                <div className={`col-${cols} d-flex align-items-center`}>
                    <span className='ml-2 mt-2' style={{ fontSize: '1rem' }}><em>Current Usage: {code.current_usage}</em></span>
                </div>
            </FormRow>

            <FormRow>
                <div className={`col-${cols} inline-toggle`}>
                    <Checkbox
                        name='applies_to_ranged_groups'
                        label='Apply to Ranged Groups'
                        inputId={`applies-to-ranged-${index}`}
                        disabled={!!code.uuid}
                        handleChange={_e => handlePromoCodeChange({ target: { name: 'applies_to_ranged_groups', value: !code.applies_to_ranged_groups } }, index)}
                        value={code.applies_to_ranged_groups} />
                </div>

                <div className={`col-${cols} inline-toggle`}>
                    <Checkbox
                        name='require_code'
                        inputId={`code-required-${index}`}
                        label='Require Code?'
                        disabled={!!code.uuid}
                        value={code.require_code}
                        handleChange={_e => handlePromoCodeChange({ target: { name: 'require_code', value: !code.require_code } }, index)}
                    />
                </div>
            </FormRow>

            <FormRow>
                <div className={`col-${cols}`}>
                    <Checkbox
                        name='weekday_restrictions'
                        inputId={`weekday-restrictions-${index}`}
                        disabled={!!code.uuid}
                        value={code.weekday_restrictions}
                        handleChange={_e => handlePromoCodeChange({ target: { name: 'weekday_restrictions', value: !code.weekday_restrictions } }, index)}
                    />
                </div>
                </FormRow>
                {code.weekday_restrictions && <>
                    {!code.uuid &&
                        <div className='row'>
                            <div className='col-12'>
                                <p className='text-gray3' style={{ fontSize: '1rem' }}><em>Selected days of the week will allow the promotion to be used:</em></p>
                            </div>
                        </div>
                    }
                    <FormRow>
                        <div className={`col-${cols === 3 ? 9 : 8}`}>
                            <div className='row ml-2'>
                                {['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].map(day => (
                                    <div className="col-4 custom-control custom-checkbox" key={day}>
                                        <input
                                            onChange={() => {
                                                if ((code.applied_days_of_week || []).includes(day)) {
                                                    const newAppliedDaysOfWeek = [...(code.applied_days_of_week || [])].filter(dayOfWeek => dayOfWeek !== day)
                                                    handlePromoCodeChange({ target: { name: 'applied_days_of_week', value: newAppliedDaysOfWeek} }, index)
                                                } else {
                                                    handlePromoCodeChange({ target: { name: 'applied_days_of_week', value: [...(code.applied_days_of_week || []), day] } }, index)
                                                }
                                            }}
                                            type='checkbox'
                                            name='customer_type'
                                            disabled={!!code.uuid}
                                            value={day}
                                            checked={(code.applied_days_of_week || []).includes(day)}
                                            className='custom-control-input'
                                            id={`restriction-day-${day}-${index}`} />
                                        <label htmlFor={`restriction-day-${day}-${index}`} className='custom-control-label'>{titleize(day)}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                </FormRow>
            </>}

            { form?.pricing_type === 'pricing_by_customer_type' && (
                <div>
                    <h4 className="bb-1">Allowed Customer Types</h4>

                    <FormRow>
                        {
                            customerTypes.map((customerType, _customerTypeIndex) => {
                                return (
                                    <div className={`col-${cols} mb-2`} key={uuidv4()}>
                                        <div className="custom-control custom-checkbox mr-3">
                                            <input
                                              onChange={() => handleCustomerTypeChange(customerType.id, index)}
                                              type='checkbox'
                                              name='customer_type'
                                              disabled={!!code.uuid}
                                              value={customerType.id}
                                              checked={code.customer_types.includes(customerType.id)}
                                              className='custom-control-input'
                                              id={`promo-customer-type-${index}-${customerType.id}`} />

                                            <label htmlFor={`promo-customer-type-${index}-${customerType.id}`} className='custom-control-label'>{customerType.name}</label>
                                      </div>
                                    </div>
                                )

                            })
                        }
                    </FormRow>
                </div>
            ) }
        </div>
    )
}
