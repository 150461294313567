import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import FormRow from '@/components/Form/FormRow'
import Checkbox from '@/components/Form/Checkbox'
import Input from '@/components/Form/Input'
import Select from "@/components/Form/Select"
import TextArea from '@/components/Form/TextArea'
import ColorPicker from '@/components/Form/ColorPicker'
import MultiSelectCheckboxes from '@/components/Form/MultiSelectCheckboxes'
import ExpectedPricing from '@/components/ExpectedPricing'
import { pluckKeyFromObjectsInArray } from '@/lib/Object'
import { errorMessageFor } from '@/components/Form/ErrorsHelper'
import errorClass from '@/lib/Errors'

export default function ItemFormDetails({
  form,
  errors,
  itemTypesOptions=[],
  salesTaxTypesOptions=[],
  categoryOptions=[],
  defaultModifiers=null,
  newRecord=false,
  handleUpdate,
  handleManualUpdate,
  handleFormUpdate,
  handleCheckboxUpdate,
}) {

  const defaultModifiersAmountCents = defaultModifiers !== null
    ? defaultModifiers.reduce((sum, m) => sum + Number(m.modifierAmountCents), 0)
    : form.default_modifiers.reduce((sum, m) => sum + Number(m.amount_cents), 0)

  const defaultTax    = salesTaxTypesOptions.find((tax) => tax?.is_default)
  const zeroTax       = salesTaxTypesOptions.find((tax) => tax?.is_zero)
  const expectedPrice = (form.amount_cents + defaultModifiersAmountCents) / 100.0

  const formMethods                     = useFormContext()
  const [salesTaxRate, setSalesTaxRate] = useState(form?.sales_tax_rate || defaultTax?.data?.[0]?.value || zeroTax?.data?.[0]?.value || 0)

  // register the amount_cents input field while also setting a
  // default value that is converted from cents into number format
  const amountCentsField = formMethods.register('amount_cents', {
    value: form.amount_cents === null ? '' : parseFloat((form.amount_cents || 0) / 100).toFixed(2),
    validate: (v) => (v !== "" && Number(v) >= 0) || 'This field is required.'
  })

  const handleAmountUpdate = (e) => {
    // allow us to set the value back to null to throw a validation error
    // should the user ever fully delete the value from the input
    handleManualUpdate('amount_cents', e.target.value === '' ? null : parseFloat(e.target.value) * 100)
    amountCentsField.onChange(e)
  }

  const handleCategoriesUpdate = (categories) => {
    const category_ids = pluckKeyFromObjectsInArray(categories, 'id')
    handleFormUpdate({ ...form, ...{ categories, category_ids }})
  }

  const handleSalesTaxUpdate = (e) => {
    setSalesTaxRate(e.target.selectedOptions[0].dataset.percentage)
    handleManualUpdate('sales_tax_type_id', Number(e.target.value))
  }

  return (
    <div>
      <div className='row'>
        <div className='col-4'>
          <FormRow>
            <Input
              value={form.name}
              label="Item Name"
              errors={errors}
              req={true}
              validation={{ 'name': { required: true }}}
              autoFocus={newRecord}
              tabIndex={1}
              handleChange={handleUpdate}
            />
          </FormRow>

          <FormRow>
            <TextArea
              name='description'
              value={form.description}
              rows={5}
              tabIndex={7}
              handleChange={handleUpdate}
            />
          </FormRow>

          <FormRow>
            <Select
              label="Item Type"
              cols="7"
              options={itemTypesOptions}
              value={parseInt(form.item_type_id)}
              validation={{ item_type_id: { required: true } }}
              errors={errors}
              tabIndex={3}
              includeBlank
              handleChange={handleUpdate}
            />
            <Checkbox
              cols="5"
              name='is_available'
              label="86'd?"
              value={!form.is_available}
              className='pl-1 pt-1 mt-4'
              tabIndex={8}
              handleChange={handleCheckboxUpdate}
            />
          </FormRow>
        </div>

        <div className='col-4'>
          <div className='col-12'>
            <div className="form-group">
              <label className="req">{ form.price_includes_sales_tax ? 'Base Target' : 'Base' } Price</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">$</span>
                </div>
                <input
                  {...amountCentsField}
                  className={`form-control ${errorClass("amount_cents", errors)}`}
                  type="number"
                  step="0.01"
                  min="0"
                  tabIndex={2}
                  onChange={handleAmountUpdate}
                />
                { errorMessageFor('amount_cents', errors) }
              </div>
            </div>
          </div>

          <Select
            label='Sales Tax'
            cols="12 px-0"
            options={salesTaxTypesOptions}
            value={
              pluckKeyFromObjectsInArray(salesTaxTypesOptions, 'id').includes(form.sales_tax_type_id)
                ? form.sales_tax_type_id
                : null
            }
            validation={{ sales_tax_type_id: { required: true } }}
            errors={errors}
            tabIndex={4}
            handleChange={handleSalesTaxUpdate}
          />

          <div className='col-12'>
            <Checkbox
              className='mt-2'
              name='price_includes_sales_tax'
              label='Include Sales Tax?'
              value={form.price_includes_sales_tax}
              tabIndex={5}
              handleChange={handleCheckboxUpdate}
            />

            <Checkbox
              className='mt-2'
              name='allow_membership_merchandise_discount'
              label='Allow Member Discount?'
              value={form.allow_membership_merchandise_discount}
              tabIndex={6}
              handleChange={handleCheckboxUpdate}
            />
          </div>
        </div>

        <ExpectedPricing
          sectionTitle='Item Price'
          sectionSubTitle='(Incl. Default Modifiers)'
          className='col-4 pl-2'
          basePriceCents={form.amount_cents}
          price={expectedPrice}
          taxRate={salesTaxRate}
          additionalItems={defaultModifiers || form.default_modifiers}
          priceIncludesSalesTax={form.price_includes_sales_tax}
        />
      </div>

      <div className='row'>
        <div className="col-5">
        </div>


        <div className='col-4 text-center'>
        </div>
      </div>

      <h4 className='mt-4'>Button Color</h4>
      <FormRow>
        <ColorPicker
          value={form.button_color}
          onChange={color => handleManualUpdate('button_color', color)}
        />
      </FormRow>

      <h4>Categories</h4>
      <FormRow>
        <MultiSelectCheckboxes
          hideLabel={true}
          options={categoryOptions}
          selectedValues={form.categories}
          useExternalList={true}
          emptyRecordMsg='No categories available.'
          errors={errors}
          onSelect={handleCategoriesUpdate}
          onRemove={handleCategoriesUpdate}
        />
      </FormRow>
    </div>
  )
}
