import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectLocation } from '@/features/Locations/locationSlice'
import { selectBooking } from '@/features/EditBooking/editBookingSlice'
import { selectOccasionTypes } from '@/features/Occasions/occasionsSlice'
import { selectReferralTypes } from '@/features/Referrals/referralsSlice'
import { selectSourceTypes } from '@/features/Sources/sourcesSlice'
import { selectStep } from '@/features/Schedule/scheduleSlice'
import {
    setConfirmationResent,
    setConfirmationTextResent,
    selectConfirmationResent,
    selectConfirmationTextResent,
    resendBookingConfirmationEmail,
    resendBookingConfirmationText,
} from '@/features/EditBooking/editBookingSlice'
import FormRow from '@/components/Form/FormRow'
import Input from '@/components/Form/Input'
import Select from '@/components/Form/Select'
import { isBookingQuote } from '@/lib/Booking'
import { validationPatterns } from '@/lib/Errors'

export function _BasicBookingInfo({ form, errors, dispatchAction }) {

    const dispatch               = useDispatch()
    const location               = useSelector(selectLocation)
    const booking                = useSelector(selectBooking)
    const referralTypes          = useSelector(selectReferralTypes)
    const occasionTypes          = useSelector(selectOccasionTypes)
    const sourceTypes            = useSelector(selectSourceTypes)
    const currentStep            = useSelector(selectStep)
    const confirmationResent     = useSelector(selectConfirmationResent)
    const confirmationTextResent = useSelector(selectConfirmationTextResent)
    const shouldFieldsBeRequired = parseInt(currentStep, 10) > 1

    const handleChange = e => {
        dispatch(dispatchAction({ name: e.target.name, value: e.target.value }))
    }

    const handleResendConfirmation = () => {
        dispatch(resendBookingConfirmationEmail())
    }

    const handleResendTextConfirmation = () => {
        dispatch(resendBookingConfirmationText())
    }

    useEffect(() => {
        if (booking) {
            if (confirmationResent) {
                dispatch(setConfirmationResent(null))
            } else if (confirmationTextResent) {
                dispatch(setConfirmationTextResent(null))
            }
        }

        return () => {
            dispatch(setConfirmationResent(null))
            dispatch(setConfirmationTextResent(null))
        }
    }, [booking])

    return (
        <div>
            <FormRow className="align-items-baseline">
                <Input
                    cols='4'
                    name='name'
                    placeholder='John Doe'
                    value={form.name}
                    errors={errors}
                    validation={{ 'name': { required: shouldFieldsBeRequired }}}
                    req={shouldFieldsBeRequired}
                    handleChange={handleChange}
                    label='Name'
                />
                <Input
                    cols='4'
                    name='phone'
                    type='tel'
                    placeholder="555-867-5309"
                    labelAction={() => !Boolean(booking?.phone) ? '' : <>
                        { confirmationTextResent !== null ? (
                            <span className={`text-${confirmationTextResent ? 'success' : 'danger'}`}>
                                {confirmationTextResent ? 'Sent!' : 'Failed to Resend'}
                            </span>
                        ) : <>
                              { location.send_text_confirmation && !isBookingQuote(booking) && (
                                    <button
                                        children='Resend Confirmation Text'
                                        tabIndex="-1"
                                        className="link"
                                        onClick={handleResendTextConfirmation}
                                    />
                              )}
                        </>}
                    </>}
                    mask='999-999-9999'
                    value={form.phone}
                    errors={errors}
                    validation={{ 'phone': {
                        required: shouldFieldsBeRequired,
                        pattern: validationPatterns.phone_basic,
                    }}}
                    req={shouldFieldsBeRequired}
                    handleChange={handleChange}
                />

                <Input
                    cols='4'
                    name='email'
                    placeholder="sample@email.com"
                    label="Email"
                    labelAction={() => !Boolean(booking?.email) ? '' : <>
                      { confirmationResent !== null ? (
                            <span className={`text-${confirmationResent ? 'success' : 'danger'}`}>
                                {confirmationResent ? 'Sent!' : 'Failed to Resend'}
                            </span>
                        ) : <>
                          { !isBookingQuote(booking) &&
                              <button
                                  children='Resend Confirmation Email'
                                  tabIndex="-1"
                                  className="link"
                                  onClick={handleResendConfirmation}
                              />
                          }
                      </>}
                    </>}
                    value={form.email}
                    errors={errors}
                    validation={{ 'email': {
                        required: shouldFieldsBeRequired,
                        pattern: validationPatterns.email,
                    }}}
                    req={shouldFieldsBeRequired}
                    handleChange={handleChange}
                />
            </FormRow>

            <FormRow>
                <Select
                    cols='4'
                    name='source_type'
                    label='Source'
                    errors={errors}
                    value={form.source_type || ''}
                    options={ sourceTypes.map(type => { return { value: type, display: type, disabled: /^online$/i.test(type) } }) }
                    validation={{ 'source_type': { required: shouldFieldsBeRequired } }}
                    req={shouldFieldsBeRequired}
                    includeBlank='Please choose'
                    handleChange={handleChange}
                />
                <Select
                    cols='4'
                    name='occasion_type'
                    label='Occasion'
                    errors={errors}
                    value={form.occasion_type || ''}
                    options={occasionTypes.map(type => { return { value: type, display: type } })}
                    includeBlank='Please choose'
                    handleChange={handleChange}
                />
                <Select
                    cols='4'
                    name='referral_type'
                    label='Referral'
                    errors={errors}
                    value={form.referral_type || ''}
                    options={referralTypes.map(type => { return { value: type, display: type } })}
                    includeBlank='Please choose'
                    handleChange={handleChange}
                />
            </FormRow>
        </div>
    )
}

export const BasicBookingInfo = _BasicBookingInfo
