import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import { selectCheckNumber, setCheckNumber } from '@/features/GiftCards/giftCardSlice'
import {
    selectCheckNumber as selectMemberCheckNumber,
    setCheckNumber as setMemberCheckNumber,
} from '@/features/Members/memberSlice'

export default function GiftCardFormCheck({ handleManualUpdate=()=>{}, membershipPayment=false }) {

    const dispatch = useDispatch()
    const number   = membershipPayment ? useSelector(selectMemberCheckNumber) : useSelector(selectCheckNumber)

    const { register, formState: { errors } } = useFormContext()
    const checkField   = register('check_number', { required: 'A check number is required.' })

    return (
        <div className="form-row">
            <div className="col-12">
                <div className="form-group">
                    <label htmlFor="check">
                        Check Number
                        <span className="req">*</span>
                    </label>
                    <input
                        {...checkField}
                        id="check"
                        className={`form-control ${errors?.check_number ? 'is-invalid' : ''}`}
                        placeholder='Check Number...'
                        value={number || ""}
                        onChange={(e) => {
                            checkField.onChange(e)
                            handleManualUpdate('check_number', e.target.value)
                            if (membershipPayment) {
                                dispatch(setMemberCheckNumber(e.target.value))
                            } else {
                                dispatch(setCheckNumber(e.target.value))
                            }
                        }}
                    />

                    { errors?.check_number &&
                        <div className="invalid-feedback d-block mx-2">{ errors.check_number.message }</div>
                    }
                </div>
            </div>
        </div>
    )
}
