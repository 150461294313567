import { configureStore } from "@reduxjs/toolkit"
import helpSliceReducer from '../features/Help/helpSlice'
import notificationSliceReducer from '../features/Notifications/notificationSlice'
import sessionSliceReducer from "../features/Session/sessionSlice"
import utilitiesSliceReducer from "../features/Utilities/utilitiesSlice"

export default configureStore({
    reducer: {
        help: helpSliceReducer,
        notifications: notificationSliceReducer,
        session: sessionSliceReducer,
        utilities: utilitiesSliceReducer,
    }
})
