import React, { useState } from 'react'
import { Provider } from 'react-redux'
import axios from 'axios'
import bookingStore from '@/stores/bookingStore'
import PurchaseModalStyles from '@/features/GiftCards/components/PurchaseModalStyles'
import NotificationList from '@/features/Notifications/NotificationList'
import Step1 from './Step1/Step1'
import Step2 from './Step2/Step2'
import Step3 from './Step3/Step3'
import Step4 from './Step4/Step4'
import Step5 from './Step5/Step5'

// Necessary to force Axios requests to send "as XHR"
axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'

export default function PublicPaymentContainer({ modalStyles, reservationNumber, paymentProcessor, environment }) {
  const [step, setStep] = useState('1')
  const [booking, setBooking] = useState(null)
  const [bookingCustomers, setBookingCustomers] = useState([])
  const [location, setLocation] = useState(null)
  const [customerTypes, setCustomerTypes] = useState([])
  const [isPaymentRequired, setIsPaymentRequired] = useState(true)

  const fetchBooking = reservationNumber => {
    return axios
      .get(`/bookings/${reservationNumber}/payment_information`)
      .then(({ data }) => {
        if (data.success) {
          setBooking(data.booking)
          setLocation(data.location)
          setBookingCustomers(data.booking_customers)

          axios
            .get(`/packages/${data.booking.package.id}/fetch_customer_types`, {
              params: {
                version: data.booking.package_version_id,
              },
            })
            .then(({ data }) => {
              setCustomerTypes(data)
            })
        }

        return data
      })
  }

  const renderStep = step => {
    switch (step) {
      case '1': // reservation search
        return (
          <Step1
            setStep={setStep}
            fetchBooking={fetchBooking}
            setIsPaymentRequired={setIsPaymentRequired}
            autoFillReservationNumber={reservationNumber}
          />
        )
      case '2': // select participants or amount to pay
        return (
          <Step2
            setStep={setStep}
            booking={booking}
            bookingCustomers={bookingCustomers}
          />
        )
      case '3': // collect email for receipt
        return <Step3 setStep={setStep} />
      case '4': // payment
        return (
          <Step4
            setStep={setStep}
            booking={booking}
            location={location}
            customerTypes={customerTypes}
            paymentProcessor={paymentProcessor}
            environment={environment}
          />
        )
      case '5': // confirm payment success
        return <Step5 isPaymentRequired={isPaymentRequired} booking={booking} />
    }
  }

  return (
    <Provider store={bookingStore}>
      <div className="purchase-modal-container">
        <div className="container-xl p-4 vh-100">
          <div className="row mb-4 border-bottom">
            <div className="col-12">
              <div className="h4">Make a payment</div>
            </div>
          </div>
          {renderStep(step)}
        </div>
      </div>
      <PurchaseModalStyles modalStyles={modalStyles} />
      <NotificationList />
    </Provider>
  )
}
