import React, { useEffect, useState } from 'react'
import ResourceTypeTabs from './ResourceTypeTabs'
import ResourceTypeReservableSetupTab from './ResourceTypeReservableSetupTab'
import ResourceTypeNonReservableSetupTab from './ResourceTypeNonReservableSetupTab'
import ResourceTypeCustomOpenHoursTab from './ResourceTypeCustomOpenHoursTab'
import ResourceTypeCustomPeakHoursTab from './ResourceTypeCustomPeakHoursTab'
import ResourceTypeSpecialPricingTab from './ResourceTypeSpecialPricingTab'

export default function ResourceForm({
  new: newResourceType,
  form,
  errors,
  time_zone,
  location,
  customerTypes,
  handleUpdate,
  handleCheckboxUpdate,
  handleManualUpdate,
  handleFormUpdate,
}) {
  const isReservable  = form.is_reservable
  const [tab, setTab] = useState(null)

  useEffect(() => {
    if (form.is_reservable && tab === 'setup_reservable') {
      setTab('setup_non_reservable')
    }
    if (form.is_reservable && tab === 'setup_non_reservable') {
      setTab('setup_reservable')
    }
  }, [form.is_reservable])

  const renderTab = () => {
    switch (tab) {
      case 'setup_reservable' : return (
        <ResourceTypeReservableSetupTab
          form={form}
          location={location}
          errors={errors}
          handleUpdate={handleUpdate}
          handleCheckboxUpdate={handleCheckboxUpdate}
          handleManualUpdate={handleManualUpdate}
          handleFormUpdate={handleFormUpdate}
        />
      )

      case 'setup_non_reservable' : return (
        <ResourceTypeNonReservableSetupTab
          form={form}
          location={location}
          errors={errors}
          handleUpdate={handleUpdate}
          handleCheckboxUpdate={handleCheckboxUpdate}
          handleManualUpdate={handleManualUpdate}
          handleFormUpdate={handleFormUpdate}
        />
      )

      case 'setup_reservable' : return (
        <ResourceTypeReservableSetupTab
          form={form}
          location={location}
          errors={errors}
          handleUpdate={handleUpdate}
          handleCheckboxUpdate={handleCheckboxUpdate}
          handleManualUpdate={handleManualUpdate}
          handleFormUpdate={handleFormUpdate}
        />
      )

      case 'custom_open_hours' : return (
        <ResourceTypeCustomOpenHoursTab
          form={form}
          errors={errors}
          time_zone={time_zone}
          handleUpdate={handleUpdate}
          handleCheckboxUpdate={handleCheckboxUpdate}
          handleManualUpdate={handleManualUpdate}
          handleFormUpdate={handleFormUpdate}
        />
      )

      case 'custom_peak_hours' : return (
        <ResourceTypeCustomPeakHoursTab
          form={form}
          time_zone={time_zone}
          handleUpdate={handleUpdate}
          handleFormUpdate={handleFormUpdate}
        />
      )

      case 'special_pricing' : return (
        <ResourceTypeSpecialPricingTab
          form={form}
          errors={errors}
          customerTypes={customerTypes}
          location={location}
          isNew={newResourceType}
          handleManualUpdate={handleManualUpdate}
        />
      )
    }
  }

  return (
    <div>
      <ResourceTypeTabs
        activeTab={tab}
        isReservable={isReservable}
        onChange={setTab}
      />

      { renderTab() }
    </div>
  )
}
