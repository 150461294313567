import React from 'react'

export default function Step5({ isPaymentRequired, booking }) {

  return <>
    {isPaymentRequired ?
      <div className="text-center mt-5">
        <p className="lead pt-5">Thank you for your payment!</p>
      </div>
    :
      <>
        {!!booking.check ?
          <div className="text-center">
            <p className="lead pt-5">Please see your server to pay your bill.</p>
          </div>
          :
          <div className="text-center">
            <p className="lead pt-5">There's no remaining balance for this reservation.</p>
          </div>
        }
      </>
    }
  </>
}
