import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setOpen } from '../Addon/addonSlice'
import { selectBooking } from '@/features/EditBooking/editBookingSlice'
import { isBookingCancelled, isBookingComplete } from '@/lib/Booking'

export default function EditBookingAddonTime({ disabled=false, twoColumn=false }) {

    const dispatch    = useDispatch()
    const booking     = useSelector(selectBooking)
    const buttonStyle = twoColumn ? "w-100 mb-0 px-2" : "btn-block"

    return (
        <button
            children='Add Time'
            className={`btn btn-outline ${buttonStyle}`}
            disabled={disabled || isBookingCancelled(booking) || isBookingComplete(booking)}
            onClick={() => dispatch(setOpen(true))}
        />
    )
}
