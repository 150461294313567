import React from 'react'
import { useDispatch } from 'react-redux'

import {
    configureModal,
    openModal,
    closeModal,
    resetMultipleItemsMode,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import NewCheckButton from '@/features/AdvancedPointOfSale/components/buttons/NewCheckButton'

export default function UnknownResourceTypeCheckButton({
    id='advanced-pos--terminal-navigation--new-check',
    className='',
    label='New Check',
    onClick=() => {}
}) {

    const dispatch = useDispatch()

    const handleClick = () => {
        dispatch(resetMultipleItemsMode())
        dispatch(configureModal({ modal: 'moveItemsBetweenTabs', config: { fromTabId: null, toTabId: null } })) // reset

        dispatch(closeModal('createCheck'))

        dispatch(configureModal({
            modal: 'createCheck',
            config: {
                action: 'create_unknown_resource_type_check',
                resourceType: null,
            }
        }))

        dispatch(openModal('createCheck'))

        onClick()
    }

    return (
        <NewCheckButton
            id={id}
            className={`btn btn-primary rounded-xl py-1 px-3 text-uppercase ${className}`.trim()}
            label={label}
            onClick={handleClick}
        />
    )
}
