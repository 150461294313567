import React from 'react'
import Input from '@/components/Form/Input'
import FormRow from '@/components/Form/FormRow'

export default function CompReasonForm({
  form,
  errors,
  handleUpdate,
}) {

  return (
    <div className="mt-4">
      <FormRow>
        <Input
          cols="6"
          name="name"
          label="Comp Reason Name"
          value={form.name}
          validation={{ name: { required: true } }}
          errors={errors}
          handleChange={handleUpdate}
        />
      </FormRow>
    </div>
  )
}
