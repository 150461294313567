import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchMembershipTypes, selectCompanyId, selectLocationId, selectTypes, setSelectedTerm } from "../memberSlice"
import MemberStep from "./MemberStep"
import MemberTypeCard from "../MemberTypeCard"

export default function SelectMembership({ hideMobileProgressHeader }) {

    const dispatch   = useDispatch()
    const locationId = useSelector(selectLocationId)
    const companyId  = useSelector(selectCompanyId)
    const types      = useSelector(selectTypes)

    useEffect(() => {
        dispatch(setSelectedTerm(null))
    }, [])

    useEffect(() => {
        if (!locationId || !companyId) {
            return
        }

        dispatch(fetchMembershipTypes())
    }, [locationId, companyId])

    return (
        <MemberStep
            hideMobileProgressHeader={hideMobileProgressHeader}
            hideNavigation
            hideLedger
        >
            <h2 className="text-center pt-4 pb-4">Select a Membership Level</h2>
            <div className="row">{!!types && types.map((t) => <MemberTypeCard key={t.id} type={t} />)}</div>
        </MemberStep>
    )
}
