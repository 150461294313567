import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useConfirm } from '@/lib/useConfirmHook'
import { selectOpen } from '@/features/Schedule/scheduleSlice'
import { resetBooking } from '@/features/EditBooking/editBookingSlice'
import { selectLocation } from '@/features/Locations/locationSlice'
import { selectSelectedResourceType } from '@/features/ResourceType/resourceTypeSlice'

import { isAdvancedPointOfSaleEnabled } from '@/lib/AdvancedPointOfSale'

import {
  isBookingCheckedIn,
  isBookingComplete,
  isBookingCancelled,
  isBookingInProgress,
  isBookingPaid,
  isBookingTrainingStarted,
  // isBookingTrainingComplete,
  isBookingWaiverComplete,
  isBookingWarned
} from '@/lib/Booking'

export default function StatusIcons({
  booking=null,
  pkg=null,
  completeConfirmationOptions={},
  additionalCompleteWarningText='',
  childInParentModal=false,
  onToggleTrainingStarted=() => {},
  // onToggleTrainingComplete=() => {},
  onToggleInProgress=() => {},
  onToggleWarning=() => {},
  onToggleComplete=() => {},
}) {
    if (!booking) { return null }

    const dispatch                    = useDispatch()
    const { confirm }                 = useConfirm()
    const resourceType                = useSelector(selectSelectedResourceType)
    const scheduleDrawerIsOpen        = useSelector(selectOpen)
    const location                    = useSelector(selectLocation)
    const [iconStyles, setIconStyles] = useState({})
    const isUnpaid                    = !booking?.parent_booking_statuses?.all_paid
    const isAllWaiversSigned          = booking?.parent_booking_statuses?.all_waivers_signed
    const isAllParticipantsCheckedIn  = booking?.parent_booking_statuses?.all_participants_checked_in

    useEffect(() => {
      let dollarSign // payment status
      let gavelIcon // waiver status
      let usersIcon // check-in status
      let bagShoppingIcon // check status

      if (booking.is_parent) {
        if (isUnpaid) {
          dollarSign = { className: "", title: "Reservation Unpaid" }
        } else {
          dollarSign = { className: "task-complete", title: "Reservation Paid" }
        }

        if (isAllWaiversSigned) {
          gavelIcon = { className: "task-complete", title: "All Waivers Signed" }
        } else {
          gavelIcon = { className: "", title: "Not All Waivers Are Signed" }
        }

        if (isAllParticipantsCheckedIn) {
          usersIcon = { className: "task-complete", title: "All Participants Are Checked-Ind" }
        } else {
          usersIcon = { className: "", title: "Not All Participants Are Checked-In" }
        }
      } else {
        if (isBookingPaid(booking)) {
          dollarSign = { className: "task-complete", title: "Reservation Paid" }
        } else {
          dollarSign = { className: "", title: "Reservation Unpaid" }
        }

        if (isBookingWaiverComplete(booking)) {
          gavelIcon = { className: "task-complete", title: "All Waivers Signed" }
        } else {
          gavelIcon = { className: "", title: "Not All Waivers Are Signed" }
        }

        if (isBookingCheckedIn(booking)) {
          usersIcon = { className: "task-complete", title: "All Participants Are Checked-Ind" }
        } else {
          usersIcon = { className: "", title: "Not All Participants Are Checked-In" }
        }

        if (booking?.check?.status === "closed") {
          bagShoppingIcon = { className: "paid task-complete", title: "Check Closed" }
        } else {
          bagShoppingIcon = { className: "", title: "Check Open" }
        }
      }

      setIconStyles({ ...iconStyles, dollarSign, gavelIcon, usersIcon, bagShoppingIcon })
    }, [booking, isUnpaid, isAllWaiversSigned, isAllParticipantsCheckedIn])

    return (
      <div className={`booking-status-icons ${isBookingComplete(booking) ? 'booking-completed' : ''}`}>
        <div
          children={<i className="far fa-dollar-sign" />}
          className={`booking-status-icon paid ${iconStyles.dollarSign?.className}`}
          title={iconStyles.dollarSign?.title}
        />

        {!booking.is_parent && isAdvancedPointOfSaleEnabled(location?.advanced_point_of_sale_status) && booking?.check &&
          <div
            className={`booking-status-icon ${iconStyles.bagShoppingIcon?.className}`}
            title={iconStyles.bagShoppingIcon?.title}
            children={<i className="fa-solid fa-bag-shopping" />}
          />
        }

        {booking?.resource_type?.waivers_required && <>
          <div
            className={`booking-status-icon waiver ${iconStyles.gavelIcon?.className}`}
            title={iconStyles.gavelIcon?.title}
            children={<i className="fas fa-gavel" />}
          />

          <div
            className={`booking-status-icon arrived ${iconStyles.usersIcon?.className}`}
            title={iconStyles.usersIcon?.title}
            children={<i className="fas fa-users" />}
          />
        </>}

        {
          childInParentModal === false && <>
            {
              resourceType.use_status_icons && (
                <div
                  children={<i className="fas fa-graduation-cap" />}
                  className={`booking-status-icon trained ${!isBookingCancelled(booking) && !isBookingComplete(booking) && !booking.is_child === true ? 'clickable' : ''} ${isBookingTrainingStarted(booking) ? 'task-complete' : ''}`}
                  title={isBookingTrainingStarted(booking) ? 'Training Started' : 'Click To Start Training'}
                  onClick={async (e) => {
                    if (!booking.is_child === true) {
                      e.stopPropagation()
                      if (!isBookingCancelled(booking) && !isBookingComplete(booking)) {
                        if (isBookingTrainingStarted(booking)) {
                          onToggleTrainingStarted()
                        } else if (await confirm('Are you sure you want to start training?')) {
                          onToggleTrainingStarted()
                        }
                      }
                    }
                  }}
                />
              )
            }

            <div
              children={<i className="fas fa-play" />}
              className={`booking-status-icon started ${!isBookingCancelled(booking) && !isBookingComplete(booking) && !booking.is_child === true ? 'clickable' : ''} ${isBookingInProgress(booking) ? 'task-complete' : ''}`}
              title={isBookingInProgress(booking) ? 'Reservation Started' : 'Click To Start The Reservation'}
              onClick={async (e) => {
                if (!booking.is_child === true) {
                  e.stopPropagation()
                  if (!isBookingCancelled(booking) && !isBookingComplete(booking)) {
                    if (isBookingInProgress(booking)) {
                      if (await confirm('This reservation is already in progress!<br /><br />Are you sure?')) {
                        onToggleInProgress('in_progress', false)
                      }
                    } else {
                      onToggleInProgress('in_progress', true)
                    }
                  }
                }
              }}
            />

            {
              resourceType.use_status_icons && (
                <div
                  children={<i className="fas fa-exclamation" />}
                  className={`booking-status-icon warned ${!isBookingCancelled(booking) && !isBookingComplete(booking) && !booking.is_child === true && isBookingInProgress(booking) ? 'clickable' : ''} ${isBookingWarned(booking) ? 'task-complete' : ''}`}
                  title={isBookingWarned(booking) ? 'Customers Notified That Only 15 Minutes Remain' : 'Click To Warn Customers That Only 15 Minutes Remain'}
                  onClick={(e) => {
                    if (!booking.is_child === true) {
                      e.stopPropagation()
                      if (isBookingInProgress(booking) && !isBookingCancelled(booking) && !isBookingComplete(booking)) {
                        onToggleWarning('warned', !isBookingWarned(booking))
                      }
                    }
                  }}
                />
              )
            }

            <div
              children={<i className="far fa-check" />}
              className={`booking-status-icon done ${!isBookingCancelled(booking) && !booking.is_child === true && !(isBookingComplete(booking) && pkg?.is_a_league) ? 'clickable' : ''} ${isBookingComplete(booking) ? 'task-complete' : ''}`}
              title={isBookingComplete(booking) ? 'Reservation Complete' : 'Click To Complete This Reservation'}
              onClick={async (e) => {
                if (!booking.is_child === true) {
                  e.stopPropagation()
                  if (!isBookingCancelled(booking)) {
                    if (isBookingComplete(booking)) {
                      if (await confirm('This reservation has already been marked as completed and this action will mark it as incomplete.<br /></br />Are you sure?')) {
                        onToggleComplete('complete', false)
                      }
                    } else if (await confirm(`Are you sure you want to mark this as complete?${additionalCompleteWarningText}`, completeConfirmationOptions)) {
                      onToggleComplete('complete', true)
                      if (!scheduleDrawerIsOpen) {
                        dispatch(resetBooking())
                      }
                    }
                  }
                }
              }}
            />
          </>
        }
      </div>
    )
}
