import React, { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
    closeModal,
    selectModals,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import Modal from '@/features/AdvancedPointOfSale/components/Modal'
import CompCard from'@/features/AdvancedPointOfSale/components/cards/CompCard'

export default function CompDetailsModal() {

    const dispatch               = useDispatch()
    const { compDetails: modal } = useSelector(selectModals)

    const handleClose = () => {
        dispatch(closeModal('compDetails'))
    }

    const content = useMemo(() => {
        return <CompCard comp={modal.comp} item={modal.comp?.item} />
    }, [modal])

    const footerButtons = <>
        <button
            children="Close"
            className="d-block btn btn-link w-100"
            onClick={handleClose}
        />
    </>

    return !!modal.comp && (
        <Modal
            title='Comp Details'
            className='comp-details-modal'
            size={modal.size}
            isOpen={modal.isOpen}
            footerButtons={footerButtons}
            onClose={handleClose}
        >
            <div>{content}</div>
        </Modal>
    )
}
