import { configureStore } from '@reduxjs/toolkit'
import giftCardSliceReducer from '@/features/GiftCards/giftCardSlice'
import managerCodeSliceReducer from '@/features/ManagerCodes/managerCodeSlice'
import notificationSliceReducer from '@/features/Notifications/notificationSlice'
import sessionSliceReducer from '@/features/Session/sessionSlice'
import terminalSliceReducer from '@/features/Terminal/terminalSlice'
import adyenSliceReducer from '@/features/Adyen/adyenSlice'

export default configureStore({
    reducer: {
        giftCard:      giftCardSliceReducer,
        managerCode:   managerCodeSliceReducer,
        notifications: notificationSliceReducer,
        session:       sessionSliceReducer,
        terminal:      terminalSliceReducer,
        adyen:         adyenSliceReducer,
    }
})
