import React from 'react'
import { useDispatch } from 'react-redux'
import NumberIncrementer from '../../../../components/Form/NumberIncrementer'
import { setCustomerTypeCounts } from '../../bookingSlice'

export default function CustomerTypeIncrementer({ type, value, disabled }) {

    const dispatch = useDispatch()

    return (
        <div className="container">
            <div className="row align-items-center mb-3">
                <div className="col-sm-5 text-right">
                    <h5 className="mb-0 xbm-customer-type">{type.name}</h5>
                </div>
                <div className="col-sm-7">
                    <div className="xbm-incrementer-container">
                        <NumberIncrementer
                            disabled={disabled}
                            min={0}
                            preventTyping={true}
                            value={value || 0}
                            incrementAmount={1}
                            handleChange={value => dispatch(setCustomerTypeCounts({
                                customerTypeId: type.id,
                                count: value
                            }))}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
