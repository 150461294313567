import axios from 'axios'
import { createSlice } from "@reduxjs/toolkit"
import { addAlert } from '@/features/Notifications/notificationSlice'
import { IS_PARENT_BOOKING_OPEN } from '@/lib/Storage'

const initialState = {
    parentBooking: null,
    childBookings: null,
    parentBookingResources: [],
    parentBookingModalOpen: false,
    tab: 'group',
    loading: false,
    packageId: null,
    duration: null
}

export const parentBookingSlice = createSlice({
    name: 'parentBooking',
    initialState: initialState,
    reducers: {
        setParentBooking: (state, action) => {
            state.parentBooking = {
                ...action.payload?.booking,
                date: action.payload?.booking.start_time,
                time: action.payload?.booking.start_time
            }

            state.packageId = action.payload?.booking.packageId
            state.duration  = action.payload?.booking.duration

            if ('resources' in action.payload) {
                state.parentBookingResources            = action.payload?.resources
                state.parentBooking.number_of_resources = action.payload?.resources.length
            }
        },
        setChildBookings: (state, action) => {
            state.childBookings = action.payload?.childBookings?.filter((cb) => !/hold/i.test(cb.status))
        },
        setParentBookingModalOpen: (state, action) => {
            window.sessionStorage.setItem(IS_PARENT_BOOKING_OPEN, action.payload)
            state.parentBookingModalOpen = action.payload
        },
        setParentBookingAttribute: (state, action) => {
            state.parentBooking[action.payload.name] = action.payload.value
        },
        setTab: (state, action) => {
            state.tab = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        resetParentBooking: (state) => {
            state.parentBooking          = initialState.parentBooking
            state.childBookings          = initialState.childBookings
            state.parentBookingResources = initialState.parentBookingResources
            state.parentBookingModalOpen = initialState.parentBookingModalOpen
            state.tab                    = initialState.tab
            state.loading                = initialState.loading
            state.packageId              = initialState.packageId
            state.duration               = initialState.duration
        }
    }
})

export const {
    setParentBooking,
    setParentBookingModalOpen,
    setParentBookingAttribute,
    setChildBookings,
    setTab,
    setOpen,
    setLoading,
    resetParentBooking
} = parentBookingSlice.actions

export const selectParentBooking          = state => state.parentBooking.parentBooking
export const selectParentBookingResources = state => state.parentBooking.parentBookingResources
export const selectChildBookings          = state => state.parentBooking.childBookings
export const selectParentBookingModalOpen = state => state.parentBooking.parentBookingModalOpen
export const selectTab                    = state => state.parentBooking.tab
export const selectLoading                = state => state.parentBooking.loading
export const selectParentBookingPackageId = state => state.parentBooking.parentBooking.package_id
export const selectParentBookingDate      = state => state.parentBooking.parentBooking.date
export const selectParentBookingStartTime = state => state.parentBooking.parentBooking.time
export const selectParentBookingDuration  = state => state.parentBooking.parentBooking.duration

export function updateParentBooking(data) {
    return async (dispatch, getState) => {
        const token = getState().session.formToken

        return axios.patch(`/bookings/${data.id}`, {
            authenticity_token: token,
            booking: { ...data }
        }).then(({ data }) => {
          if (data.success) {
              dispatch(addAlert({ type: 'success', text: data.message }))
              return data.success
          }

          dispatch(addAlert({ type: 'error', text: data.message }))
        })
    }
}

export default parentBookingSlice.reducer
