import React, { useState, useEffect, useRef } from 'react'
import { UI } from '@/lib/Constants'
import axios from 'axios'
import { accessToken } from "@/lib/Csrf";
import Alert from '../Alert/Alert'
import PackageOptions from './PackageOptions'

export default function PackageOrderDrawer({ resource_type, packages }) {
  const [open, setOpen] = useState(false)
  const [alert, setAlert]  = useState(null)
  const [currentPackages, setCurrentPackages] = useState([])

  const drawerRef = useRef()

  const handleClose = () => {
    setOpen(false)
  }

  const handleSave = e => {
    e.preventDefault()

    const currentPackageIds = currentPackages.map(p => p.id)

    axios.patch(`/resource_types/${resource_type.id}/update_package_order`, {
      authenticity_token: accessToken,
      package_ids: currentPackageIds,
    })
    .then(({ data }) => {
      if (data.success) {
        setAlert({ type: 'success', message: data.message, refresh: true  })
      }
    })
  }

  const handleEscape = e => {
    if (
      e.key === 'Escape' &&
      !document.body.classList.contains(UI.classes.MULTIPLE_MODALS_OPEN) &&
      !document.body.classList.contains(UI.classes.CONFIRMATION_OPEN) &&
      !document.body.classList.contains(UI.classes.HELPJUICE_OPEN)
    ) {
      handleClose()
    }
  }

  useEffect(() => {
    setCurrentPackages(packages)
  }, [packages])

  // Bind events related to closing the drawer by pressing ESC
  useEffect(() => {
    if (open) {
      document.addEventListener('keydown', handleEscape, false)
    }
    return () => {
      document.removeEventListener('keydown', handleEscape, false)
    }
  }, [open])

  return (
    <>
      <button className="drawer-pop btn btn-outline-primary" onClick={() => setOpen(true)}>
        Sort Packages
      </button>
      {open &&
        <div className={`drawer ${open ? 'open' : ''} dnd-support`} ref={drawerRef}>
          <div className="drawer-scaffold">
            <form onSubmit={e => handleSave(e)} className="drawer-main">
              { alert && <Alert {...alert} /> }
              <div className="drawer-header-container">
                <div className="drawer-header">
                  <h2>Sort Packages</h2>
                  <span
                    className="text-muted pt-2 pl-4 ml-auto"
                    style={{ fontSize: "12px", fontStyle: "italic", fontWeight: "100" }}
                  >Sort order can be changed using drag and drop.
                  </span>
                </div>
              </div>
              <div className="drawer-main-content">
                <div>
                  <div className="col py-4 mt-2">
                    <PackageOptions
                      packages={currentPackages}
                      setCurrentPackages={setCurrentPackages}
                    />
                  </div>
                </div>
              </div>

              <div className="actions">
                <button
                  type="button"
                  className="close-drawer btn btn-link btn-link-danger close"
                  onClick={handleClose}
                >Close</button>

                <button
                  type="submit"
                  className="btn btn-primary"
                  value="Save"
                >Save &amp; Close</button>
              </div>
            </form>
          </div>
        </div>
      }
    </>
  )
}
