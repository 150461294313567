import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectDurations, } from "@/features/Packages/packageSlice"
import { setBookingAttribute } from '@/features/EditBooking/editBookingSlice'

export default function EditBookingDuration({
    form,
    isParent=false,
    isChild=false,
    isQuote=false,
    disabled=false
}) {

    const dispatch         = useDispatch()
    const packageDurations = useSelector(selectDurations)

    const handleChange = (e) => {
        dispatch(setBookingAttribute({ name: e.target.name, value: Number.parseInt(e.target.value) }))
    }

    return !packageDurations ? null : (
        <div className="form-group">
            <label>Duration</label>

            <select
                className="custom-select"
                name='duration'
                value={form.duration}
                disabled={disabled || !(packageDurations.length > 0) || isParent || isChild || isQuote}
                onChange={handleChange}
            >
                {
                    packageDurations.length > 0 ? (
                        packageDurations.map(duration => (
                            <option value={duration.duration} key={duration.duration}>
                                {`${duration.duration} Minutes`}
                            </option>
                        ))
                    ) : (
                        <option>Loading...</option>
                    )
                }
            </select>
        </div>
    )
}
