import React, {useEffect, useState} from 'react'
import Input from '../Form/Input'
import FormRow from '../Form/FormRow'
import Checkbox from '../Form/Checkbox'
import Tooltip from "../Form/Tooltip";

export default function ResourceForm({
  form,
  errors,
  new: newResource,
  canBeDeactivated,
  handleUpdate,
  handleFormUpdate,
  handleCheckboxUpdate,
  loaded
}) {

    useEffect(() => {
        if (!loaded && newResource) {
            handleFormUpdate({
                ...form,
                primary_hex_color: '',
                text_hex_color: ''
            })
        }
    }, [])

    const resetColors = () => {
        handleFormUpdate({
            ...form,
            primary_hex_color: null,
            text_hex_color: null
        })
    }

    return (
        <div>
            <h4>
                Resources
                <Tooltip id='resource-info' place='right'>
                    Add and configure resources belonging to this particular <br />
                    resource type.
                </Tooltip>
            </h4>

            <FormRow>
                <Input
                    cols='8'
                    name='name'
                    value={form.name}
                    errors={errors}
                    validation={{ 'name': { required: true }}}
                    handleChange={handleUpdate}
                />

                <Input
                    cols='4'
                    name='max_capacity'
                    value={form.max_capacity}
                    errors={errors}
                    validation={{ 'max_capacity': { required: true }}}
                    handleChange={handleUpdate}
                />
            </FormRow>

            <FormRow>
                <Input
                name='primary_hex_color'
                type='color'
                handleChange={handleUpdate}
                req={false}
                value={form.primary_hex_color}
                cols='4'
                errors={errors} />

                <Input
                name='text_hex_color'
                type='color'
                handleChange={handleUpdate}
                req={false}
                value={form.text_hex_color}
                cols='4'
                errors={errors} />

                <button type="button" className='btn btn-link btn-link-primary pt-4' onClick={resetColors}>Clear Colors</button>

            </FormRow>

            <FormRow className='mt-3'>
                <Checkbox
                    cols='3'
                    name='active'
                    disabled={form.active && !canBeDeactivated && !newResource}
                    value={form.active !== false}
                    handleChange={handleCheckboxUpdate}
                />

                <Checkbox
                    cols='4'
                    name='non_bookable'
                    className='text-center'
                    label='Resource is not bookable'
                    tooltip='Used to prevent future bookings on this resource, either temporarily or as preparation for full deactivation.'
                    value={form.non_bookable || false}
                    handleChange={handleCheckboxUpdate}
                />

                <Checkbox
                    cols='5'
                    name='non_adjacent'
                    className='text-right'
                    label='Non-adjacent to previous resource'
                    value={form.non_adjacent || false}
                    handleChange={handleCheckboxUpdate}
                />
            </FormRow>
        </div>
    )
}
