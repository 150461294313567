import { createSlice } from "@reduxjs/toolkit"
import axios from 'axios'

export const resourceTypeSlice = createSlice({
    name: 'resourceType',
    initialState: {
        resourceType: null,
        resourceTypeUrl: null,
        resourceTypes: []
    },
    reducers: {
        setResourceType: (state, action) => {
            state.resourceType = action.payload
        },
        setResourceTypeUrl: (state, action) => {
            state.resourceTypeUrl = action.payload
        },
        setResourceTypes: (state, action) => {
            state.resourceTypes = action.payload
        }
    }
})

export const {
    setResourceType,
    setResourceTypeUrl,
    setResourceTypes
} = resourceTypeSlice.actions

export const selectResourceType           = state => state.resourceType.resourceType
export const selectResourceTypeUrl        = state => state.resourceType.resourceTypeUrl
export const selectResourceTypes          = state => state.resourceType.resourceTypes
export const selectCustomerVerbiagePlural = state => state.resourceType.resourceType.customer_verbiage_plural

export const selectSelectedResourceType = state => {
    return state.resourceType.resourceTypes.filter(rt => rt.id === state.resourceType.resourceType)[0]
}

export function fetchResourceType(url) {
    return async (dispatch) => {
        axios.get(url).then(({ data }) => {
            dispatch(setResourceType(data))
        })
    }
}

export function fetchResourceTypes(url) {
    return async (dispatch) => {
        axios.get(url).then(({ data }) => {
            dispatch(setResourceTypes(data))
        })
    }
}

export default resourceTypeSlice.reducer
