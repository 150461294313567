import React from 'react'
import { useFormContext } from 'react-hook-form'
import errorClass from '../../lib/Errors'

export default function NumberIncrementer({
  name='',
  value,
  incrementAmount,
  min,
  max,
  disabled=false,
  disabledDecrement=false,
  preventTyping=false,
  errors=[],
  validation=null,
  handleChange=() => {},
}) {
    const formMethods = useFormContext()

    /**
     * Example of an expected validation
     * schema object to register
     *
     * {
     *   'name': {
     *     required: 'A name is required',
     *     minLength: { value: 4, message: 'must be more than 4 characters long' }
     *   }
     * }
     */
    const field = formMethods && validation
        ? formMethods.register( Object.keys(validation)[0], validation[Object.keys(validation)[0]] )
        : { name }

    /**
     * Handle what happens when a user clicks on one if the toggle buttons. This will
     * run some basic checks and then fire the handleChange callback once it's validated
     *
     * @param {*} newValue the new value of what the input will be
     * @param {*} increment are we incrementing vs decrementing? used for disabled functionality
     */
    const handleClick = (newValue, increment) => {
        if (typeof min !== 'undefined' && newValue < min) { return }
        if (typeof max !== 'undefined' && newValue > max) { return }

        // most times we still allow decrementing when the input is disabled, but
        // if this prop has been passed in, then we absolutely do not allow it
        if (disabledDecrement) { return }

        // note: this is for customer type related reasons
        //
        // if they're trying to increment and we're disabled, then prevent the action.
        // however decrementing is allowed. this is more of just a helper in order
        // to disable the input but also disable the buttons when they're clicked
        if (disabled && increment) { return }

        handleChange(newValue)
    }

    const handleUpdate = (e) => {
        value = Number.parseInt(typeof e === 'object' ? e.target.value : e)
        // update react hook form validation
        if ('onChange' in field) { field.onChange(value) }
        // update our form object
        handleChange(value)
    }

    return (
        <div className='input-group'>
            <div
                className={`input-group-prepend ${disabledDecrement ? 'disabled' : ''}`.trim()}
                onClick={() => handleClick(value + (incrementAmount * -1), false)}
            >
                <div className="input-group-text">&#8722;</div>
            </div>

            <input
                type="text"
                disabled={preventTyping || disabled}
                className={`form-control text-center ${errorClass(field.name, errors)} cursor-default`.trim()}
                value={value}
                onChange={handleUpdate}
            />

            <div
                className={`input-group-append ${disabled ? 'disabled' : ''}`.trim()}
                onClick={() => handleClick(value + incrementAmount, true)}
            >
                <div className="input-group-text">&#43;</div>
            </div>

            {
                errors[field.name] && (
                    <div className="invalid-feedback text-center">
                        { errors[field.name].join(', ') }
                    </div>
                )
            }
        </div>
    )
}
