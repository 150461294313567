import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { selectAdyenPayment } from '@/features/Adyen/adyenSlice'
import { addAlert } from '@/features/Notifications/notificationSlice'
import AdyenCreditCard from '@/features/Adyen/AdyenCreditCard'
import Checkbox from '@/components/Form/Checkbox'

export default function AddCardForm({ submitPath, environment, locationId, locationTimeZone, forceDefault=false }) {

    const dispatch     = useDispatch()
    const adyenPayment = useSelector(selectAdyenPayment)

    const [adyenCheckout, setAdyenCheckout] = useState(null)
    const [defaultCard, setDefaultCard]     = useState(forceDefault)

    const handleSubmit = () => {
        axios.post(submitPath, {
            adyen_payment: adyenPayment,
            default_card: defaultCard,
        }).then(({ data }) => {
            if (data.success) {
                window.location.reload()
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        })
    }

    return (
        <div className="new-card-container">
            <h5>Enter New Card</h5>

            <AdyenCreditCard
                environment={environment}
                locationId={locationId}
                locationTimeZone={locationTimeZone}
                captureZipCode={false}
                adyenCheckout={adyenCheckout}
                setAdyenCheckout={setAdyenCheckout}
                onSubmit={handleSubmit}
            />

            <div className="row mt-2">
                <div className="col-lg-12 mt-2">
                    <Checkbox
                        className="mt-2 mr-2"
                        name="defaultCard"
                        label='Set as default'
                        value={defaultCard}
                        disabled={forceDefault}
                        handleChange={() => setDefaultCard(!defaultCard)}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12 text-right">
                    <hr />
                    <button
                        children='Save Card'
                        className="btn btn-primary"
                        type="button"
                        onClick={() => adyenCheckout.submit()}
                    />
                </div>
            </div>
        </div>
    )
}
