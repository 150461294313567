import React, {useEffect, useState} from "react";
import axios from 'axios'
import { Provider } from 'react-redux'
import notificationStore from '@/stores/notificationStore'
import NotificationList from '@/features/Notifications/NotificationList'
import ConfirmationDialog from '@/features/Notifications/ConfirmationDialog'
import CustomerWaiversHeader from "./CustomerWaiversHeader"
import CustomerWaiversForm from "./CustomerWaiversForm"
import CustomerWaiversSearch from "./CustomerWaiversSearch"
import CustomerWaiversSuccess from "./CustomerWaiversSuccess"

// Necessary to force Axios requests to send "as XHR"
axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'

export default function CustomerWaivers({
  waiver,
  company,
  location,
  styles,
  reservation_number=null,
  resource_type_id=null,
  success_url=null,
}) {
  const [adult,       setAdult]       = useState(null)
  const [minor,       setMinor]       = useState(null)
  const [search,      setSearch]      = useState(null)
  const [waiverForm,  setWaiverForm]  = useState(false)
  const [success,     setSuccess]     = useState(false)
  const [isReturning, setIsReturning] = useState(false)
  const [customer,    setCustomer]    = useState(null)

  const handleWaiverFormReset = () => {
    setAdult(false)
    setMinor(false)
    setWaiverForm(false)
    setSearch(false)
    setSuccess(false)
  }

  const showAdult = ({ isReturning=false, customer=null }) => {
    handleWaiverFormReset()
    setAdult(true)
    setWaiverForm(true)
    setIsReturning(isReturning)
    setCustomer(customer)
  }

  const showMinor = ({ isReturning=false, customer=null }) => {
    handleWaiverFormReset()
    setMinor(true)
    setWaiverForm(true)
    setIsReturning(isReturning)
    setCustomer(customer)
  }
  const showSearch = () => {
    handleWaiverFormReset()
    setSearch(true)
  }

  const handleEnter = (e) => {
    if (e.keyCode === 13) {
      return false;
    }
  }

  const showSuccess = () => {
    setSuccess(true)
  }

  useEffect(() => {
    document.addEventListener('keydown', handleEnter, false)

    return () => {
        document.removeEventListener('keydown', handleEnter, false)
    }
  }, [])

  useEffect(() => {
    if (waiverForm) {
      document.querySelector("input[name='first_name']").focus()
    }
  }, [waiverForm, adult, minor])

  return !waiver ? null : (
    <Provider store={notificationStore}>
      <div className={`waiver-wrapper ${styles.use_dark_text_image ? 'light' : 'dark'}`}>
        <NotificationList position="top left" />
        <ConfirmationDialog />

        <CustomerWaiversHeader
          styles={styles}
          company={company} />

        <div className="container pb-3">
          <div className="row justify-content-center">
            <div className="col p-0">
              <div className="waiver-container mx-auto px-4">
                <div className="d-none" id="waiver-accepted">
                  <h3 className="waiver-completed-header">
                    {waiver.waiver_completed_header}
                  </h3>
                </div>
                <div id="waiver-process">
                  <h3 className="waiver-header py-3 py-md-0">
                    {waiver.waiver_header}
                  </h3>

                  <small className="d-block d-md-none font-italic w-100 text-center pb-2">
                      please scroll/read through the waiver to continue
                  </small>

                  <div
                      id='waiver-body'
                      className='form-control trix-content p-4 p-md-5 unread'
                      dangerouslySetInnerHTML={{__html: waiver.waiver_body}}
                  />

                  <div id='continue_waiver_note' className='d-none'>
                    By proceeding with the waiver form below, you agree that you have read the waiver details above.
                  </div>

                  {
                    !isReturning && (
                        <div id="waiver-buttons" className="row waiver-type-buttons">
                          <div className={company.allow_minor_waivers ? 'col-4' : 'col-6'}>
                            <div className='waiver_type_button mb-3 mb-4-md'>
                              <button
                                name='button'
                                id='sign-adult-waiver'
                                className={`btn ${adult ? 'btn-primary' : 'btn-outline-primary'} btn-block ${search === null ? 'disabled' : ''} adult-waiver`}
                                onClick={showAdult}>
                                Adult Waiver
                              </button>
                            </div>
                          </div>

                          {company.allow_minor_waivers && (
                            <div className={company.allow_minor_waivers ? 'col-4 px-0' : 'col-6'}>
                              <div className='waiver_type_button mb-3 mb-4-md'>
                                <button
                                  name='button'
                                  id='sign-minor-waiver'
                                  className={`btn ${minor ? 'btn-primary' : 'btn-outline-primary'} btn-block ${search === null ? 'disabled' : ''} minor-waiver`}
                                  onClick={showMinor}>
                                  Minor Waiver
                                </button>
                              </div>
                            </div>
                          )}

                          <div className={company.allow_minor_waivers ? 'col-4' : 'col-6'}>
                            <div className='waiver_type_button mb-3 mb-4-md'>
                              <button
                                name='button'
                                className={`btn ${search ? 'btn-primary' : 'btn-outline-primary'} btn-block ${search === null ? 'disabled' : ''} existing-waiver`}
                                onClick={showSearch}>
                                Returning {company.customer_verbiage_singular}
                              </button>
                            </div>
                          </div>
                        </div>
                    )
                  }

                  {/*This is the form that needs to be submitted to create new waiver(s)*/}
                  {waiverForm && (
                    <CustomerWaiversForm
                      waiver={waiver}
                      company={company}
                      location={location}
                      reservation_number={reservation_number}
                      resourceTypeId={resource_type_id}
                      adult={adult}
                      minor={minor}
                      showSuccess={showSuccess}
                      isReturning={isReturning}
                      customer={customer}
                    />
                  )}

                  {/*This is the search form*/}
                  {search && (
                    <CustomerWaiversSearch
                      waiver={waiver}
                      company={company}
                      location={location}
                      reservation_number={reservation_number}
                      resourceTypeId={resource_type_id}
                      showSuccess={showSuccess}
                      showAdult={showAdult}
                      showMinor={showMinor}
                    />
                  )}

                  {/*This is the success view*/}
                  {
                      success &&
                          <CustomerWaiversSuccess
                              success_url={success_url}
                              handleWaiverFormReset={handleWaiverFormReset}
                          />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Provider>
  )
}
