import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchHistory } from '@/features/GiftCards/giftCardSlice'
import GiftCardChangeLog from '@/features/GiftCards/GiftCardChangeLog'

export default function GiftCardHistory ({ timeZone, giftCardId }) {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchHistory(giftCardId))
    },[])

    return ( 
        <div className="row">
            <div className="col-12">
                <h3 className="border-bottom">Gift Card History</h3>
                <GiftCardChangeLog timeZone={timeZone}/>
            </div>
        </div>
    )
}
