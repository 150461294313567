import React, { useMemo, useState, useEffect } from 'react'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { selectConcurrentBookingStartTimes } from '@/features/CalendarBookings/calendarBookingsSlice'
import { selectSizeWidth } from '@/features/Calendar/calendarSlice'
import { selectTimeZone } from '@/features/Locations/locationSlice'

export function _TimeHeader({ time }) {

    const concurrentTimes = useSelector(selectConcurrentBookingStartTimes)
    const sizeWidth       = useSelector(selectSizeWidth)
    const timezone        = useSelector(selectTimeZone)

    const defaultClasses        = 'time'
    const [classes, setClasses] = useState(defaultClasses)

    const datetime = useMemo(() => (
        moment(time).tz(timezone)
    ), [time, timezone])

    const width = useMemo(() => (
        { width: `${sizeWidth}px` }
    ), [sizeWidth])

    useEffect(() => {
        if (concurrentTimes.length > 0 && concurrentTimes.includes(time.toISOString())) {
            setClasses(`${defaultClasses} max-concurrency-warning`)
        } else {
            setClasses(defaultClasses)
        }
    }, [concurrentTimes])

    return (
        <div className={classes} style={width}>
            <span className="date-label">
                { datetime.format('ddd, D MMM') }
            </span>
            <span className="time-label">
                { datetime.format('LT') }
            </span>
        </div>
    )
}

export const TimeHeader = React.memo(_TimeHeader)
