import React from 'react'
import days from '../../lib/Days'

export default function DailyStartEndTimes({ dailyStartsEnds, handleUpdate }) {

    const handleTimeSelect = (e) => {
        if (dailyStartsEnds.map(d => d.day).includes(e.target.name)) {
            // value is there, map a new value
            handleUpdate('daily_starts_ends', dailyStartsEnds.map(d => {
                if (d.day === e.target.name) {
                    return { ...d, value: e.target.value }
                } else {
                    return d
                }
            }))
        } else {
            // create new value
            handleUpdate('daily_starts_ends', dailyStartsEnds.concat({ day: e.target.name, value: e.target.value }))
        }
    }

    return days.map(day => {
        return (
            <div className="start-end-day">
                <div className="day-row">
                    <div className="row">
                        <div className="col-2">
                            <b>{day[0]}</b>
                        </div>
                        <div className="col-5">
                            <label>FROM</label>
                            <select name={`${day[1]}_from`} className='custom-select' onChange={handleTimeSelect}>
                                <option value='close'>Close</option>
                                <option value='9'>9:00 A.M.</option>
                            </select>
                        </div>
                        <div className="col-5">
                            <label>TO</label>
                            <select name={`${day[1]}_to`} className='custom-select' onChange={handleTimeSelect}>
                                <option value='close'>Close</option>
                                <option value='9'>9:00 A.M.</option>
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            <b>{day[0]} Peak</b>
                        </div>
                        <div className="col-5">
                            <label>FROM</label>
                            <select name={`${day[1]}_peak_from`} className='custom-select' onChange={handleTimeSelect}>
                                <option value='close'>Close</option>
                                <option value='9'>9:00 A.M.</option>
                            </select>
                        </div>
                        <div className="col-5">
                            <label>TO</label>
                            <select name={`${day[1]}_peak_to`} className='custom-select' onChange={handleTimeSelect}>
                                <option value='close'>Close</option>
                                <option value='9'>9:00 A.M.</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        )
    })
}
