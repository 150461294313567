import React from 'react'

export default function StatusBadge({ status }) {
  const statusBadgeColor = () => {
    let color

    switch (String(status).toLowerCase().replace(' ', '_')) {
      case "declined":
      case "failed":
      case "unauthorized":
      case "capture_failed":
      case "refund_failed":
      case "refund_reversed":
      case "connection_issue":
        color = "danger"
        break

      case "pending":
      case "issued":
      case "authorised":
      case "authorized":
      case "aborted":
      case "cancelled":
        color = "warning"
        break

      case "authorized_only":
        color = "info"
        break

      case "complete":
      case "completed":
      case "captured":
      case "refunded":
        color = "success"
        break

      case "initialized":
      case "processing":
      default:
        color = "secondary"
    }

    return color
  }

  return (
    <span className={`badge badge-pill badge-${statusBadgeColor()}`}>
      {status}
    </span>
  )
}
