import React from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { generateInvoiceLink, selectBooking } from '@/features/EditBooking/editBookingSlice'
import { setLoading } from '@/features/Schedule/scheduleSlice'
import { setInputLocked } from '@/features/Calendar/calendarSlice'
import { addAlert } from '@/features/Notifications/notificationSlice'
import { useConfirm } from '@/lib/useConfirmHook'
import { accessToken } from '@/lib/Csrf'

export default function GenerateInvoice({
    label='Generate Invoice',
    icon=true,
    sendAsEmail=false,
    className='',
}) {

    const dispatch    = useDispatch()
    const { confirm } = useConfirm()
    const booking     = useSelector(selectBooking)
    const invoiceLink = useSelector(generateInvoiceLink)

    const linkAttrs = sendAsEmail ? {} : {
        target: '_blank',
        rel: 'noopener nofollow'
    }

    const sendInvoiceViaEmail = async () => {
        if (!booking.email) {
            return dispatch(addAlert({ type: 'warning', text: 'Please add an email to the quote first.' }))
        }

        if (!await confirm(`Send this quote via email to ${booking.email}?`)) {
            return
        }

        dispatch(setLoading(true))
        dispatch(setInputLocked(true))

        axios.post(`/bookings/${booking.id}/email_invoice`, {
            authenticity_token: accessToken,
        })
        .then(({ data }) => {
            if (data.success) {
                dispatch(addAlert({ type: 'success', text: data.message }))
                return
            }
            dispatch(addAlert({ type: 'error', text: data.message }))
        })
        .finally(() => {
            dispatch(setLoading(false))
            dispatch(setInputLocked(false))
        })
        .catch((error) => {
            if (debug && console) { console.error(error?.response?.data || error) }
            dispatch(addAlert({ type: 'error', text: (error?.response?.data?.message || 'An error occurred!') }))
        })
    }

    const handleClick = (e) => {
        if (sendAsEmail) {
            e.preventDefault()
            sendInvoiceViaEmail()
        }
    }

    return (
        <a
            {...linkAttrs}
            href={invoiceLink}
            className={`btn btn-outline text-white w-100 ${className}`}
            onClick={handleClick}
        >
            { icon && <i className="fas fa-file-invoice mr-2" /> }
            { label }
        </a>
    )
}
