import React from 'react'
import { useDispatch } from 'react-redux'
import { setDetailsAndTerms, setTermsOpen } from './memberSlice'

export default function TermsButton({
    detailsAndTerms='',
    buttonClass='btn text-transform-none',
    wrapperClass='',
    noWrapper=false,
}) {
    const dispatch = useDispatch()

    const handleDetailsClick = () => {
        dispatch(setTermsOpen(true))
        dispatch(setDetailsAndTerms(detailsAndTerms))
    }

    const button = (
        <button
            type='button'
            className={buttonClass}
            onClick={handleDetailsClick}
        >
            Full Details & Terms
            <i className="ml-2 fa fa-external-link-alt" />
        </button>
    )

    return noWrapper ? button : (
        <div className={wrapperClass || 'row mt-3'}>
            <div className="col-12 text-center">
                { button }
            </div>
        </div>
    )
}
