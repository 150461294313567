import React, { useMemo } from 'react'
import TabDetails from '@/features/AdvancedPointOfSale/components/ordering/receipt/TabDetails'
import Totals from '@/features/AdvancedPointOfSale/components/ordering/receipt/Totals'
import { addBookingTabToGeneralTab, isPaid } from '@/features/AdvancedPointOfSale/lib/Checks'

export default function CheckReceipt({
    id=null,
    className='',
    check={},

    currentBalance=null,
    tipAmount=null,
    paymentAmount=null,

    collapsable=false,
    collapsedByDefault=false,
    compact=false,
    checkoutMode=false,
    hideAmountPaid=false,
    hideTipsCollected=false,
    hideRemainingBalance=false,
}) {
    const filteredTabs = useMemo(() => (
        addBookingTabToGeneralTab(check.tabs).filter((tab) => !tab.is_closed && tab.items.length > 0)
    ), [check])

    return !!check && (
        <div id={id} className={`full-check-receipt receipt ${className}`.trim()}>
            {
                filteredTabs.map((tab) => (
                    <TabDetails
                        key={`tab-${tab.id}`}
                        className='mb-5'
                        check={check}
                        tab={tab}
                        collapsable={collapsable}
                        collapsedByDefault={collapsedByDefault}
                        compact={compact}
                    />
                ))
            }

            <Totals
                checkoutMode={checkoutMode}
                hideAmountPaid={hideAmountPaid}
                hideTipsCollected={hideTipsCollected}
                hideRemainingBalance={hideRemainingBalance}

                subtotalCents={check.amount_cents}
                discountCents={check.discount_cents}
                compsCents={check.comps_total}
                taxCents={check.tax_cents}
                autoGratuityCents={check.auto_gratuity_cents}
                grandTotalCents={check.grand_total_cents}
                amountPaidCents={check.total_paid_cents > 0 ? check.total_paid_cents : null}

                bookingTaxesAndFeesVerbiage={check?.booking?.taxes_and_fees_verbiage}

                balanceCents={isPaid(check) ? null : (Number((currentBalance * 100).toFixed(2)) || check.balance_cents)}
                tipCents={checkoutMode ? Number((tipAmount * 100).toFixed(2)): check.tip_total}
                paymentAmountCents={Number((paymentAmount * 100).toFixed(2))}
            />
        </div>
    )
}
