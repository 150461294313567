import React, { useEffect, useState } from 'react'
import titleize from '../../lib/String'
import RecordDrawer from './RecordDrawer'

/**
 * =========================================================================================================================================
 *
 * This is the entry into rendering drawers on the site.
 *
 * =========================================================================================================================================
 *
 * @param {*} props Necessary props that are passed and used throughout the system:
 *                      formObjects:  a nested object with any other objects that are needed to render the form e.g. collections for selects
 *                      new:          whether or not it's a new record
 *                      linkClass:    which class to apply to the button/toggle below
 *                      object:       the form object that is being modified -- needed for edit values
 *                      recordUrl:    the URL that the form should submit to
 *                      recordType:   the record type e.g. hit_miss_message, customer_type, etc.
 *
 * =========================================================================================================================================
 */
export default function DrawerButton(props) {
    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        if (localStorage.getItem('drawerOpen')) {
            setOpen(true)
            localStorage.removeItem('drawerOpen')
        }
    }, [])

    return (
        <>
            <button id={props.buttonId} className={props.linkClass} onClick={() => setOpen(true)}>
                {props.new ? 'New ' + titleize(props.customRecordName || props.recordType) : 'Edit ' + titleize(props.customRecordName || props.recordType)}
            </button>
            {
                open && (
                    <RecordDrawer
                        open={open}
                        close={handleClose}
                        {...props} />
                )
            }
        </>
    )
}
