import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import CreditCard from '@/features/Bookings/components/Step6/CreditCard'
import AdyenCreditCard from '@/features/Adyen/AdyenCreditCard'
import { selectAdyenPayment } from '@/features/Adyen/adyenSlice'
import { validationPatterns } from '@/lib/Errors'
import {
    setName,
    setZip,
    setCreditToken,
    selectName,
    selectZip,
    selectCreditToken,
    submitPayment
} from '@/features/GiftCards/giftCardSlice'

export default function GiftCardFormCreditCard({
    email,
    locationId,
    locationTimeZone,
    environment,
    paymentProcessor,
    adyenCheckout,
    setAdyenCheckout,
    handleDisableSaveButton,
    handleManualUpdate,
}) {

    const dispatch     = useDispatch()
    const creditToken  = useSelector(selectCreditToken)
    const name         = useSelector(selectName)
    const zip          = useSelector(selectZip)
    const adyenPayment = useSelector(selectAdyenPayment)

    const { register, trigger, formState: { errors, isValid } } = useFormContext()
    const nameField = register('name', { required: 'A name is required.' })

    useEffect(() => {
        if (zip) { handleManualUpdate('zip', zip) }
    }, [zip])

    useEffect(() => {
        if ((!creditToken && !adyenPayment) || !zip) {
            handleDisableSaveButton(true)
        } else {
            if (isValid) {
                handleDisableSaveButton(false)
            }
        }
    }, [creditToken, zip, isValid, adyenPayment])

    const renderCreditCardForm = () => (
        paymentProcessor === 'adyen' ? (
            <AdyenCreditCard
                locationId={locationId}
                locationTimeZone={locationTimeZone}
                environment={environment}
                adyenCheckout={adyenCheckout}
                zipcode={zip}
                setAdyenCheckout={setAdyenCheckout}
                setZipCode={(e) => dispatch(setZip(e.target.value))}
            />
        ) : (
            <CreditCard
                creditToken={creditToken}
                setToken={(token) => dispatch(setCreditToken(token))}
                setZip={(e) => dispatch(setZip(e.target.value))}
            />
        )
    )

    return (
        <div>
            <div className="form-row">
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="name">
                            Name
                            <span className="req">*</span>
                        </label>
                        <input
                            {...nameField}
                            id="name"
                            className={`form-control ${errors?.name ? 'is-invalid' : ''}`}
                            value={name || ""}
                            onChange={(e) => {
                                nameField.onChange(e)
                                handleManualUpdate('name', e.target.value)
                                dispatch(setName(e.target.value))
                            }}
                        />
                        { errors?.name &&
                            <div className="invalid-feedback d-block mx-2">{ errors.name.message }</div>
                        }
                    </div>
                </div>
            </div>

            {renderCreditCardForm()}
        </div>
    )
}
