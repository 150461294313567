import React, { useEffect, useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
    selectCheckoutTab,
    checkGiftCardBalance,
    selectCurrentCheck,
    selectAmountTowardsBalance,
    selectCurrentBalance,
    selectShowCalculator,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import Input from '@/components/Form/Input'
import PaymentForm from '@/features/AdvancedPointOfSale/components/checkout/PaymentForm'
import { numberToCurrency } from '@/lib/Number'

export default function GiftCard({
    self,
    totalDue,
    isProcessing,
    onLoad,
    onSubmit
}) {

    const dispatch       = useDispatch()
    const check          = useSelector(selectCurrentCheck)
    const tab            = useSelector(selectCheckoutTab)
    const amountToCharge = useSelector(selectAmountTowardsBalance)
    const currentBalance = useSelector(selectCurrentBalance)
    const showCalculator = useSelector(selectShowCalculator)

    const [giftCardNumber, setGiftCardNumber]   = useState('')
    const [giftCardBalance, setGiftCardBalance] = useState('')
    const [error, setError]                     = useState(null)

    const readyToSubmit = useMemo(() => {
        if (!amountToCharge || !giftCardBalance) { return false }

        return (
            giftCardBalance / 100 >= Number.parseFloat(amountToCharge)
                && Number.parseFloat(amountToCharge) <= currentBalance
        )
    }, [giftCardBalance, amountToCharge, currentBalance])

    const processButton = useMemo(() => (
        <button
            children={isProcessing ? 'Please Wait...' : `Process ${numberToCurrency(amountToCharge)}`}
            className='btn btn-primary process-button'
            disabled={isProcessing || !readyToSubmit}
            onClick={() => {
                onSubmit({
                    totalReceivedAmount: amountToCharge,
                    giftCardNumber: giftCardNumber,
                })
            }}
        />
    ), [isProcessing, readyToSubmit, amountToCharge, giftCardNumber])

    const handleCheckBalance = () => {
        setError(null)
        setGiftCardBalance('')

        dispatch(checkGiftCardBalance(giftCardNumber)).then((data) => {
            if (data.success) {
                setGiftCardBalance(data.balance)
            } else {
                setError(data.message)
            }
        })
    }

    useEffect(() => {
        onLoad(processButton)
    }, [processButton])

    return (!!tab || !!check) && (
        <PaymentForm
            self={self}
            totalDue={totalDue}
            hideTipField
        >
            <div className='row align-items-center'>
                <div className='col-4 text-right text-bold pt-4 px-0'>Card{showCalculator ? <><br/></> : ''}Number</div>
                <div className='col-8'>
                    <Input
                        type='text'
                        className='form-control text-uppercase font-monospace'
                        mask="*****-*****-*****-*****"
                        placeholder="XXXXX-XXXXX-XXXXX-XXXXX"
                        value={giftCardNumber}
                        withWrapper={false}
                        handleChange={(e) => {
                            setGiftCardNumber(e.target.value)}
                        }
                    />
                </div>
            </div>
            <div className='row align-items-center'>
                <div className='col-12 text-right text-bold'>
                    <div className="invalid-feedback d-block my-3 pl-5 my-0 mr-2">
                        { error }
                    </div>
                    <button
                        children='Check Balance'
                        className='btn btn-outline-primary'
                        onClick={handleCheckBalance}
                        disabled={!giftCardNumber}
                    />
                </div>
            </div>

            {
                !!giftCardNumber && !!giftCardBalance && (
                    <div className='row mt-3 align-items-center'>
                        <div className='col-12 text-right text-bold'>
                            Card Balance Remaining:
                            <span className={`ml-2 ${giftCardBalance > 0 ? 'text-green' : 'text-red'}`}>
                                {numberToCurrency(giftCardBalance / 100)}
                            </span>
                            {
                                (giftCardBalance / 100) < amountToCharge && (
                                    <span className="text-red d-block">Insufficient Balance</span>
                                )
                            }
                        </div>
                    </div>
                )
            }
        </PaymentForm>
    )
}
