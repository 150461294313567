import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    setOpen,
    selectArticle,
    fetchHelpJuiceArticle,
    fetchFreshDeskArticle
} from '@/features/Help/helpSlice'

export default function HelpLink({
    className='',
    articleId=null,
    linkText=null,
    service='FRESHDESK', // HELPJUICE
}) {
    const dispatch = useDispatch()
    const article  = useSelector(selectArticle)

    // NOTE
    // If the articleId is set in the redux state, do not
    // re-load the article, just open the modal again
    const handleOpen = () => {
        dispatch(setOpen())

        if (service === 'HELPJUICE' && article?.article?.id != articleId) {
            dispatch(fetchHelpJuiceArticle(articleId))
        }

        if (service === 'FRESHDESK' && article?.article?.id != articleId) {
            dispatch(fetchFreshDeskArticle(articleId))
        }
    }
    return !!articleId && <>
        {
            linkText ? (
                <div
                    id={`${service}--${articleId}`}
                    className={`btn btn-link ${className}`.trim()}
                    children={linkText}
                    onClick={handleOpen}
                />
            ) : (
                <span
                    id={`${service}--${articleId}`}
                    className={`btn btn-none ${className}`.trim()}
                    children={<i className='far fa-question-square' />}
                    onClick={handleOpen}
                />
            )
        }
    </>
}
