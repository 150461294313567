import React, { useState, useEffect } from 'react'
import axios from 'axios'

export default function Loading({ delay=700 /* milliseconds */, debug=false }) {

    const [show, setShow] = useState(false)

    const __debugger = (options) => {
        if (debug) { console.log(options) }
    }

    const startTimer = () => {
        window._axiosLoading =
            window.setTimeout(() => {
                __debugger('showing loader')
                setShow(true)
            }, delay)
    }

    const clearTimer = () => {
        window.clearTimeout(window._axiosLoading)
        __debugger('timer cleared')
    }

    const clearRequests = () => {
        window._axiosRequests = 0
        __debugger('request counter cleared')
    }

    const incrementRequests = () => {
        window._axiosRequests++
        __debugger(['incremented', window._axiosRequests])
    }

    const decrementRequests = () => {
        window._axiosRequests--
        __debugger(['decremented', window._axiosRequests])
    }

    const hideLoaderIfLastRequestComplete = () => {
        if (window._axiosRequests === 0) {
            __debugger('hiding loader')
            setShow(false)
        }
    }

    /**
     * We hook into all Axios requests/responses via its interceptors. Each
     * time a request is made, we start a timer and then increment a counter
     * (and then decrement it later on once a response is received—even an
     * error response). When the counter reaches zero, we hide the loader.
     *
     * The loader animation itself only displays after a delayed amount of
     * time has passed so that we don't annoy the user by displaying it too soon.
     */
    useEffect(() => {
        clearRequests()

        /**
         * Hook into all Axios requests
         */
        axios.interceptors.request.use((config) => {
            clearTimer()
            incrementRequests()
            startTimer()
            __debugger(['REQUEST: start!', config.url])

            return config
        }, (error) => {
            clearTimer()
            decrementRequests()
            hideLoaderIfLastRequestComplete()
            __debugger(['REQUEST: error occurred!', error])
            return Promise.reject(error)
        })

        /**
         * Hook into all Axios responses
         */
        axios.interceptors.response.use((response) => {
            clearTimer()
            decrementRequests()
            hideLoaderIfLastRequestComplete()
            return response
        }, (error) => {
            clearTimer()
            decrementRequests()
            hideLoaderIfLastRequestComplete()
            __debugger(['RESPONSE: error occurred!', error])
            return Promise.reject(error)
        })

        /**
         * Handle unmounting this component
         * should it ever happen (it shouldn't)
         */
        return () => {
            setShow(false)
            clearRequests()
            clearTimer()
        }
    }, [])

    return <>
        { show && (
            <div id="global-loading">
                <div id="global-loading--content">
                <div class="spinner">
                    <div class="rect1"></div>
                    <div class="rect2"></div>
                    <div class="rect3"></div>
                    <div class="rect4"></div>
                    <div class="rect5"></div>
                    <div class="rect6"></div>
                </div>
                    Loading
                </div>
            </div>
        )}
    </>
}
