import React, { useEffect, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useDispatch, useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import { selectResourceType } from '@/features/ResourceType/resourceTypeSlice'
import { setFormAttribute } from '@/features/Schedule/scheduleSlice'

import {
    resetPackages,
    resetTempPackageId,
    fetchPackageGroups,
    selectPackages,
    selectPackageGroup,
    selectPackageGroups,
    selectTempPackageId
} from '@/features/Packages/packageSlice'

import BookingPackageGroupOption from './BookingPackageGroupOption'
import BookingPackageOption from './BookingPackageOption'
import { debug } from '@/lib/Debug'

export default function BookingPackages({ errors, setTriggerGroupLoad }) {

    const formMethods   = useFormContext()
    const dispatch      = useDispatch()
    const resourceType  = useSelector(selectResourceType)
    const packages      = useSelector(selectPackages)
    const packageGroup  = useSelector(selectPackageGroup)
    const packageGroups = useSelector(selectPackageGroups)
    const tempPackageId = useSelector(selectTempPackageId)

    const [viewingAllPackageGroups, setViewingAllPackageGroups] = useState(false)

    const filteredPackages = useMemo(() => {
        if (packageGroup === null || packageGroup.id === 'all') {
            return packages.filter(pkg => !pkg.hide_from_all_packages_in_drawer)
        } else {
            return packages
        }
    }, [packages, packageGroup])

    const sectionTitle = () => {
        switch(true) {
            case viewingAllPackageGroups :
                return 'All Package Groups'

            case packageGroup !== null :
                return packageGroup.name

            default :
                return 'All Packages'
        }
    }

    const handleViewAllGroups = () => {
        setViewingAllPackageGroups(true)

        dispatch(setFormAttribute({ name: 'package_id', value: null }))
        formMethods.clearErrors('package_id')
        formMethods.setValue('package_id', null)
        formMethods.trigger()

        dispatch(resetPackages())
        dispatch(fetchPackageGroups(
            `/resource_types/${resourceType}/fetch_package_groups`
        ))
    }

    useEffect(() => {
        resetTempPackageId()
    }, [])

    useEffect(() => {
        if (packages.length > 0) {
            setViewingAllPackageGroups(false)
        }
    }, [packages])

    return (
        <div className="booking-packages">
            <div className="row mt-4 mb-3">
                <div className={`${viewingAllPackageGroups ? 'col-12' : 'col-8'} border-bottom-thick px-0 ml-3 mr-n3 align-items-bottom`}>
                    <h3 className="border-bottom-0 m-0 p-0 text-truncate">
                        { sectionTitle() }
                    </h3>
                </div>

                <div className={`${viewingAllPackageGroups ? 'd-none' : 'col-4'}  border-bottom-thick px-0 ml-n3 mr-3 align-items-bottom`}>
                    {
                        !viewingAllPackageGroups && (
                            <button
                                className='btn btn-secondary btn-compact mt-n1 ml-3 text-white float-right'
                                onClick={handleViewAllGroups}
                            >
                                <i className={`mr-md-2 fas ${debug ? 'fa-police-box' : 'fa-boxes'}`} />
                                <span className="d-none d-md-inline">Package Groups</span>
                            </button>
                        )
                    }
                </div>
            </div>

            <div className="row row-cols-2 px-1">
                {
                  viewingAllPackageGroups ? (
                      packageGroups.map((group) => (
                          <BookingPackageGroupOption
                              key={uuidv4()}
                              group={group}
                          />
                      ))
                  ) : (
                    filteredPackages.map((pakage) => (
                        <BookingPackageOption
                            key={uuidv4()}
                            pkg={pakage}
                            setTriggerGroupLoad={setTriggerGroupLoad}
                            tempPackageId={tempPackageId}
                        />
                    ))
                  )
                }

                {
                    (
                        (viewingAllPackageGroups && packageGroups.length === 0)
                          ||
                        (!viewingAllPackageGroups && packages.length === 0)
                    ) && (
                        <div className="col-12">
                            <p className="h6 text-muted">Loading...</p>
                        </div>
                    )
                }
            </div>

            { errors['package_id'] &&
              <div className="invalid-feedback d-block mt-3 ml-2">
                  You must select a package.
              </div>
            }
        </div>
    )
}
