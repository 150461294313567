import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectMembershipCard, setMembershipCard } from '@/features/Members/memberSlice'
import Checkbox from '@/components/Form/Checkbox'
import { titleize } from '@/lib/String'

export default function SelectCardCheckbox({ card }) {

    const dispatch     = useDispatch()
    const selectedCard = useSelector(selectMembershipCard)
    const isSelected   = !!selectedCard && selectedCard === card.id

    const cardType = () => {
        switch(card.card_type) {
            case 'visa' : return 'Visa'
            case 'mc'   : return 'MasterCard'
            case 'dc'   : return 'Diners Club'
            case 'di'   : return 'Discover'
            case 'am'   : return 'American Express'
            default     : return titleize(card.card_type)
        }
    }

    /**
     * Set default card as checked on load
     */
    useEffect(() => {
        if (card.default) {
            handleSelected()
        }
    }, [])

    /**
     * Select a given card to use by its id
     */
    const handleSelected = () => {
        dispatch(setMembershipCard(isSelected ? null : card.id))
    }

    return (
        <div key={card.id} className="py-2">
            <div className="d-flex align-items-center justify-content-between">
                <span>
                    { cardType() } ending in <span className='text-monospace'>{card.last_digits}</span>
                    { card.default && <span className='badge badge-secondary py-1 px-2 ml-3' children='default' /> }
                </span>

                <Checkbox
                    name={`card_${card.id}`}
                    className="pb-0"
                    label=" "
                    value={isSelected}
                    handleChange={handleSelected}
                />
            </div>
        </div>
    )
}
