import React from 'react'

export default function Upsells() {
    return (
        <div className="upsells">
            <h4>Upsells &amp; Addons</h4>
            <p style={{ color: 'red' }}>Needs more information...</p>
        </div>
    )
}
