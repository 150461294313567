import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { closeManagerOverrideModal } from "../EditBooking/editBookingSlice"
import { addAlert } from '@/features/Notifications/notificationSlice'

export const discountSlice = createSlice({
    name: "discounts",
    initialState: {
        discounts:       [],
        bookingDiscount: null
    },
    reducers: {
        setDiscounts: (state, action) => {
            state.discounts = action.payload
        },
        setBookingDiscount: (state, action) => {
            state.bookingDiscount = action.payload
        }
    },
})

export const {
    setDiscounts,
    setBookingDiscount
} = discountSlice.actions

export const selectDiscounts       = (state) => state.discounts.discounts
export const selectBookingDiscount = (state) => state.discounts.bookingDiscount

export function fetchDiscounts(locationId) {
    return async (dispatch) => {
        axios
            .get(`/locations/${locationId}/discounts.json`)
            .then(({ data }) => {
                dispatch(setDiscounts(data))
            })
            .catch((e) => {
                console.warn(e)
                dispatch(addAlert({ type: 'error', text: e?.response?.data?.message || 'ERROR: Could not fetch discounts' }))
            })
    }
}

export function submitDiscount(bookingId, code, discountId, amountType, amount, flatRate, afterTax, taxExempt, promoCode) {
    return async (dispatch, getState) => {
        const token = getState().session.formToken

        axios.post(`/bookings/${bookingId}/booking_discounts`, {
            authenticity_token: token,
            booking_discount:   {
                booking_id:        bookingId,
                manager_code_used: code,
                discount_id:       discountId,
                amount_type:       amountType,
                amount:            amount,
                flat_rate:         flatRate,
                after_tax:         afterTax,
                tax_exempt:        taxExempt,
                promo_code:        promoCode,
            }
        }).then(({ data }) => {
            if (data.success) {
                // dispatch to close the modal...
                dispatch(addAlert({ type: 'success', text: 'Override created' }))
                dispatch(closeManagerOverrideModal())
            } else {
                // show errors
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        }).catch((e) => {
            console.warn(e)
            dispatch(addAlert({ type: 'error', text: e?.response?.data?.message || 'ERROR: Could not process request' }))
        })
    }
}

export function deleteDiscount(bookingId) {
    return async (dispatch, getState) => {
        const token = getState().session.formToken

        axios.delete(`/bookings/${bookingId}/booking_discounts/delete_override?authenticity_token=${token}`).then(({ data }) => {
            if (data.success) {
                dispatch(addAlert({ type: 'success', text: 'Override removed from booking' }))
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        }).catch(e => {
            console.warn(e)
            dispatch(addAlert({ type: 'error', text: e?.response?.data?.message || 'ERROR: Could not delete override' }))
        })
    }
}

export function fetchBookingDiscount(bookingId) {
    return async (dispatch, getState) => {
        axios.get(`/bookings/${bookingId}/booking_discounts`).then(({ data }) => {
            dispatch(setBookingDiscount(data))
        }).catch(e => {
            console.warn(e)
            dispatch(addAlert({ type: 'error', text: e?.response?.data?.message || 'ERROR: Could not delete override' }))
        })
    }
}

export default discountSlice.reducer
