import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import axios from 'axios'
import { selectTimeZone } from '@/features/Locations/locationSlice'
import { fetchPackage, setNewPackage, setTempPackageId } from '@/features/Packages/packageSlice'
import { selectParentBooking, selectChildBookings, updateParentBooking } from '@/features/ParentBooking/parentBookingSlice'
import { setNewBooking } from '@/features/EditBooking/editBookingSlice'
import { setOpen, setStep, setNewSchedule } from '@/features/Schedule/scheduleSlice'
import { useConfirm } from '@/lib/useConfirmHook'
import { isBookingComplete } from '@/lib/Booking'
import { debug } from '@/lib/Debug'

export default function ParentBookingReservationDetailsTab({ immutableEvent, onClose }) {

    const dispatch         = useDispatch()
    const { confirm }      = useConfirm()
    const selectedTimeZone = useSelector(selectTimeZone)
    const parentBooking    = useSelector(selectParentBooking)
    const childBookings    = useSelector(selectChildBookings)
    const participantCount = childBookings?.reduce((sum, cb) => sum + parseInt((cb.group_max || cb.participants), 10), 0)

    const [booking, setBooking] = useState(null)

    useEffect(() => {
        const date = parentBooking.start_time
        const time = parentBooking.start_time
        setBooking({ ...parentBooking, date: date, time: time })
        dispatch(fetchPackage(`/packages/${parentBooking.package_id}`))
    }, [])

    const handleChange = (type, value) => {
        let newBooking = { ...booking }
        let newValue = value

        const dateFormat = 'YYYY-MM-DD'
        const timeFormat = 'HH:mm:ss'

        if (type === 'date') {
            const newDate      = moment(value).format(dateFormat)
            const currentTime  = moment(booking.time).format(timeFormat)
            newValue           = moment(`${newDate} ${currentTime}`).toISOString()
            newBooking['time'] = newValue

        } else if (type === 'time') {
            const newTime      = moment(value).format(timeFormat)
            const currentDate  = moment(booking.date).format(dateFormat)
            newValue           = moment(`${currentDate} ${newTime}`).toISOString()
            newBooking['date'] = newValue

        } else if (type === 'duration') {
            newValue = Number.parseInt(value)
        }

        newBooking[type] = newValue

        setBooking({ ...newBooking })
    }

    const handleSave = () => {
        dispatch(updateParentBooking(booking)).then(async result => {
            if (result) {
                if (await confirm('Do you want to let the participants know about the change?')) {
                    axios.get(`/bookings/${booking.id}/notify_child_booking_emails`)
                }
                onClose()
            }
        })
    }

    const handleOpen = () => {
        dispatch(setNewBooking())
        dispatch(setNewPackage())
        dispatch(setNewSchedule())
        dispatch(setStep('1'))
        dispatch(setOpen(true))
        dispatch(setTempPackageId(parentBooking.package_id))
        onClose()
    }

    return <>
        <div className="modal-body">
            <div className="modal-body-main">
                <div className="modal-body-main-section">
                    <div className="drawer-main-content">
                        <div className="drawer-scroller">
                            <div className="form-row mb-3">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Welcome Message</label>
                                        <input
                                            name="welcome_message"
                                            type="text"
                                            className="form-control"
                                            placeholder="Welcome Message"
                                            value={booking?.welcome_message || ''}
                                            onChange={e => handleChange(e.target.name, e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-row mb-3">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Note (Internal use only)</label>
                                        <textarea
                                            name="note"
                                            className="form-control"
                                            rows='10'
                                            value={booking?.note || ''}
                                            onChange={e => handleChange(e.target.name, e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal-body-sidebar">
                <div className="modal-body-sidebar-inner">
                    <div className="drawer-booking-ledger-section border-bottom-0">
                        <table>
                            <tbody>
                                {
                                    debug && (
                                        <tr>
                                            <th>ID:</th>
                                            <td>{parentBooking.id}</td>
                                        </tr>
                                    )
                                }
                                <tr>
                                    <th>SPECIAL EVENT:</th>
                                    <td>{parentBooking?.reservation_number}</td>
                                </tr>
                                <tr>
                                    <th>PACKAGE NAME:</th>
                                    <td>{parentBooking?.package?.name}</td>
                                </tr>
                                <tr>
                                    <th>CREATED ON:</th>
                                    <td>
                                        {
                                            parentBooking && (
                                                moment.tz(parentBooking.created_at, selectedTimeZone)
                                                      .format('MM/DD/YYYY h:mm A z')
                                            )
                                        }
                                    </td>
                                </tr>
                                <tr><th colSpan='2'><hr /></th></tr>
                                <tr>
                                    <th>SCHEDULED DATE:</th>
                                    <td>{ moment.tz(parentBooking.start_time, selectedTimeZone).format('MM/DD/YYYY') }</td>
                                </tr>
                                <tr>
                                    <th>SCHEDULED TIME:</th>
                                    <td>{ moment.tz(parentBooking.start_time, selectedTimeZone).format('LT') }</td>
                                </tr>
                                <tr>
                                    <th>DURATION:</th>
                                    <td>{booking?.duration} Minutes</td>
                                </tr>
                                {
                                    !(isBookingComplete(parentBooking) && parentBooking.package.is_a_league) && (
                                        <tr>
                                            <th>CAPACITY:</th>
                                            <td>{participantCount} - {parentBooking.package.special_event_capacity || '??'} Slots</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal-footer">
            {
                !immutableEvent && !parentBooking.package.special_event_is_sold_out && (
                    <button className="group-reservation-new-event text-white" onClick={handleOpen}>
                        <i className="far fa-plus" />
                    </button>
                )
            }

            <button className="btn btn-link-danger ml-auto" onClick={onClose}>
                <span>Close</span>
            </button>

            <button className="btn btn-primary" onClick={handleSave}>
                Save &amp; Close
            </button>
        </div>
    </>
}
