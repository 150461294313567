import axios from 'axios'
import { createSlice } from '@reduxjs/toolkit'
import { addAlert } from '@/features/Notifications/notificationSlice'
import { USE_DARK_MODE } from '@/lib/Storage'
import { debug } from '@/lib/Debug'

const useDarkMode  = window.localStorage.getItem(USE_DARK_MODE) === 'true'

export const utilitiesSlice = createSlice({
    name: 'utilities',
    initialState: {
        darkMode: useDarkMode,
    },
    reducers: {
        setDarkMode: (state, action) => {
            // set it to null first to always *force* a change
            // if the incoming value is the same as the stored value
            state.darkMode = null
            state.darkMode = action.payload
        },
    }
})

export const {
    setDarkMode
} = utilitiesSlice.actions

export const selectDarkMode = state => state.utilities.darkMode

export function updateDarkModePreference(userId, payload) {
    return async (dispatch, getState) => {
        dispatch(setDarkMode(payload))

        if (!userId) { return }

        return axios.patch(`/users/${userId}/toggle-dark-mode`, {
            authenticity_token: getState().session.formToken,
            use_dark_mode: payload
        }).then(response => {
            return response.data.success
        }).catch((error) => {
            if (debug && console) { console.error(error) }
        })
    }
}

export function sendPayloadToDestination({ options={}, payload={} }) {
    return async (dispatch, getState) => (
        axios.post('/utilities/send_payload_to_destination', {
            authenticity_token: getState().session.formToken,
            payload,
            ...options
        })
        .then(({ data }) => {
            if (data.success) {
                dispatch(addAlert({ type: 'success', text: data.message }))
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
            return data
        })
        .catch(e => {
            if (console) { console.error(e) }
            dispatch(addAlert({ type: 'error', text: e?.response?.data?.message || `Could not send to ${options?.destination || 'destination'}!` }))
        })
    )
}

export default utilitiesSlice.reducer
