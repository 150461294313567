export default null

/**
 * Determine which step to navigate to when trying to navigate to the customer
 * type selection screen (step 4). We have to evaluate whether or not to actually
 * navigate there since if the user chose a group-priced group we have to skip
 * the customer type selection screen and take them directly to the next step.
 * We also have to ask if they're going backwards or not since we have to know
 * which step to ultimately go to if it's being skipped.
 *
 * @param {*} participants number of participants the user selected for this booking (step 1)
 * @param {*} groups the array of group options on the package itself
 * @param {*} customerTypes array of customer types available for this package
 * @param pricingType enum for package pricing type
 * @param {*} goingBackwards boolean whether or not we're navigating backwards instead of 'next'
 * @param {*} allowMemberBenefits are memberships allowed for the given package?
 * @returns string step to navigate to using dispatch
 */
export function evaluateCustomerTypeSelectionStep(participants, groups, customerTypes, pricingType, goingBackwards=false, allowMemberBenefits=false) {
    // this is the customer type selection step that would be the target
    let step = '4'

    if (skipCustomerTypeSelection(participants, groups, customerTypes, pricingType, allowMemberBenefits)) {
        step = goingBackwards ? '3' : '5'
    }

    return step
}

/**
 * Determine whether or not the customer type selection screen should be skipped based
 * on whether or not the user selected a participant count within group_price groups or
 * if there is only one customer type available for the current package.
 *
 * This is shared because we don't always just use this on navigation, we also use it for
 * displaying headers (see step5 header which bumps to step4 if customer type selection was skipped).
 *
 * @param {*} participants number of participants the user selected for this booking (step 1)
 * @param {*} groups the array of group options on the package itself
 * @param {*} customerTypes array of customer types available for this package
 * @param {*} pricingType enum for package pricing type
 * @param {*} allowMemberBenefits are memberships allowed for the given package?
 * @returns boolean whether or not customer type should be skipped given the current participants & groups
 */
export function skipCustomerTypeSelection(participants, groups, _customerTypes, pricingType, allowMemberBenefits=false) {
    // this is the customer type selection step that would be the target
    let skip = false

    // groups are parsed out as min-mix format e.g. 2-8, but non-groups are the individual number e.g. 2
    const rangeParts = String(participants).split('-')

    // parse out the first number of the range (13)
    const participants_min = Number(rangeParts[0])

    // parse out the last number of the range (18)
    // when range 1 doesn't exist, we use part 0 since it means we're in non-group pricing.
    const participants_max = Number(rangeParts[1] || rangeParts[0])

    groups.forEach(group => {
        const group_min = Number.parseInt(group.min)
        const group_max = Number.parseInt(group.max)

        // only run skip logic when we find the group that applies to our participant count
        if (participants_min >= group_min && participants_max <= group_max) {
            // Skip customer type selection for group pricing and for uniform pricing where memberships are not allowed.
            if (group.price_type === 'group_price') {
                skip = true
            }

            if (pricingType === 'uniform_pricing' && !allowMemberBenefits) {
                skip = true
            }
        }
    })

    return skip
}
