import React, { useState } from 'react'
import LocationTabs from './LocationTabs'
import LocationSetupTab from './LocationSetupTab'
import LocationHoursTab from './LocationHoursTab'
import LocationBrandingTab from './LocationBrandingTab'
import LocationEmails from './LocationEmails'
import LocationPaymentTab from "./LocationPaymentTab";
import LocationScoring from "./LocationScoring";
import LocationOccasionsTab from "./LocationOccasionsTab";
import LocationMembershipOccasionsTab from "./LocationMembershipOccasionsTab";
import LocationReferralsTab from "./LocationReferralsTab";

export default function LocationForm({
  form,
  errors,
  directUploadUrl,
  imagesUrl,
  fontsUrl,
  fonts,
  superUser,
  companyAdmin,
  emailSubjects,
  timezones,
  paymentProcessors,
  new: newLocation,
  membershipsEnabled=false,
  isAdvPosStatusMutable,
  handleUpdate,
  handleCheckboxUpdate,
  handleManualUpdate,
  handleFormUpdate,
}) {
    const [tab, setTab]                             = useState('setup')
    const [setupTabLoaded, setSetupTabLoaded]       = useState(false)
    const [brandingTabLoaded, setBrandingTabLoaded] = useState(false)

    const renderTab = () => {
      switch (tab) {
        case 'setup':
          return (
            <LocationSetupTab
              form={form}
              errors={errors}
              imagesUrl={imagesUrl}
              directUploadUrl={directUploadUrl}
              loaded={setupTabLoaded}
              superUser={superUser}
              companyAdmin={companyAdmin}
              membershipsEnabled={membershipsEnabled}
              isAdvPosStatusMutable={isAdvPosStatusMutable}
              handleUpdate={handleUpdate}
              handleCheckboxUpdate={handleCheckboxUpdate}
              handleManualUpdate={handleManualUpdate}
              handleTabLoaded={() => setSetupTabLoaded(true)}
            />
          )
        case 'hours':
          return (
            <LocationHoursTab
              form={form}
              errors={errors}
              timezones={timezones}
              newLocation={newLocation}
              handleUpdate={handleUpdate}
              handleManualUpdate={handleManualUpdate}
              handleCheckboxUpdate={handleCheckboxUpdate}  />
          )
        case 'payment':
          return (
            <LocationPaymentTab
              form={form}
              errors={errors}
              newLocation={newLocation}
              handleUpdate={handleUpdate}
              handleManualUpdate={handleManualUpdate}
              paymentProcessors={paymentProcessors}
            />
          )
        case 'branding':
          return (
            <LocationBrandingTab
              form={form}
              errors={errors}
              handleUpdate={handleUpdate}
              handleCheckboxUpdate={handleCheckboxUpdate}
              handleManualUpdate={handleManualUpdate}
              handleFormUpdate={handleFormUpdate}
              directUploadUrl={directUploadUrl}
              imagesUrl={imagesUrl}
              fontsUrl={fontsUrl}
              fonts={fonts}
              newLocation={newLocation}
              loaded={brandingTabLoaded}
              handleTabLoaded={() => setBrandingTabLoaded(true)}
            />
          )
        case 'scoring':
          return (
            <LocationScoring
              form={form}
              handleManualUpdate={handleManualUpdate}
              handleUpdate={handleUpdate}
              errors={errors} />
          )
      case 'emails':
          return (
              <LocationEmails
                  form={form}
                  handleUpdate={handleUpdate}
                  handleManualUpdate={handleManualUpdate}
                  handleCheckboxUpdate={handleCheckboxUpdate}
                  emailSubjects={emailSubjects}
                  errors={errors} />
          )
      case 'reso_occ.':
          return (
              <LocationOccasionsTab
                  form={form}
                  handleManualUpdate={handleManualUpdate}
                  errors={errors} />
          )
      case 'member_occ.':
          return (
              <LocationMembershipOccasionsTab
                  form={form}
                  handleManualUpdate={handleManualUpdate}
                  errors={errors} />
          )
      case 'referrals':
          return (
              <LocationReferralsTab
                  form={form}
                  handleManualUpdate={handleManualUpdate}
                  errors={errors} />
          )
      }
    }

    return (
      <div>
        <LocationTabs
          handleClick={(tab) => setTab(tab)}
          activeTab={tab}
          superUser={superUser}
          companyAdmin={companyAdmin}
          membershipsEnabled={form.memberships_enabled}
        />

        { renderTab() }
      </div>
    )
}
