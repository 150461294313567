import React, { useEffect, useState } from 'react'
import { useFormContext, useFormState } from 'react-hook-form'
import moment from 'moment-timezone'
import FormRow from '@/components/Form/FormRow'
import Input from '@/components/Form/Input'
import Checkbox from '@/components/Form/Checkbox'
import MomentTimePicker from '@/components/Form/MomentTimePicker'
import DatePicker from '@/components/Form/DatePicker'
import { errorMessageFor } from '@/components/Form/ErrorsHelper'
import { momentToLocalDate, localDateToMoment } from '@/lib/CalendarTimes'

export function _specialHoursForm({ specialHours, newLocation, tz, handleManualUpdate, handleCheckboxUpdate }) {

    // form validation
    const formMethods         = useFormContext()
    const { errors }          = useFormState()
    const date                = formMethods.watch('date', null)
    const opensAt             = formMethods.watch('opens_at', null)
    const closesAt            = formMethods.watch('closes_at', null)
    const note                = formMethods.watch('note', null)

    const [isClosed, setIsClosed] = useState(true)
    const [loaded, setLoaded]     = useState(false)

    const handleIsClosedToggle = (e) => {
        setIsClosed(e.target.checked)
    }

    const handleAdd = async () => {
        const valid = await formMethods.trigger(['date', 'opens_at', 'closes_at', 'note'])

        if (valid && date) {
            // update special_hours
            handleManualUpdate('special_hours', specialHours.concat({
                date:      date,
                is_closed: isClosed,
                opens_at:  opensAt,
                closes_at: closesAt,
                note:      note
            }))
        }
    }

    const handleReset = () => {
        if (date)     { formMethods.setValue('date') }
        if (opensAt)  { formMethods.setValue('opens_at') }
        if (closesAt) { formMethods.setValue('closes_at') }
        if (note)     { formMethods.setValue('note') }
        setIsClosed(true)
    }

    /**
     * Reset form fields after successfully
     * adding a special hour dataset
     */
    useEffect(() => {
        handleReset()
    }, [specialHours])

    //
    // note: MomentTimePicker can accept null or
    // Moment objects as values, just not strings
    //
    const value_for = (value, allowBlank=false) => {
        // supply the timepicker with null so that it renders
        // a blank/empty field since we don't have a value
        if (!value && allowBlank) { return null }

        // if this is a new location, we don't yet know the timezone so
        // default to Eastern so that we're at least on the right day.
        // we will be stripping out the timezone information before
        // saving it to the database anyway
        if (value !== null && newLocation && !allowBlank) {
            return moment(value).tz('UTC')
        }

        // the time picker supplies a default timezone of UTC
        // when it's already rendered a null value, so since
        // we have a string/chosen time, simply render it as
        // it's already in UTC as expected
        if (typeof value === 'string' && newLocation) {
            return moment(value)
        }

        // for existing locations, supply the timepicker with
        // a moment object in the location's preferred timezone
        if (typeof value === 'string' && !newLocation) {
            return moment(value).tz(tz)
        }

        // in all other case, supply the timepicker with
        // null so that it renders a blank/empty field
        if (console) { console.warn('LocationSpecialHours: this should never happen!') }

        return null
    }

    return (
        <div className="pb-3">
            <FormRow>
                <DatePicker
                    cols='3'
                    name='date'
                    value={date ? momentToLocalDate(moment.tz(date, tz)) : null}
                    minDate={momentToLocalDate(moment().tz(tz))}
                    validation={{ 'date': { required: Boolean(date) || Boolean(note) ? 'A date is required' : false }}}
                    handleChange={(name, date) => formMethods.setValue(name, localDateToMoment(date, tz).toISOString())}
                />

                <Checkbox
                    cols='3'
                    className="mt-4 pt-1 pl-3"
                    name='is_closed'
                    label='Location Closed?'
                    value={isClosed}
                    handleChange={handleIsClosedToggle}
                />

                <MomentTimePicker
                    cols="3"
                    label='Opens At'
                    name={`opens_at`}
                    value={value_for(opensAt, true)}
                    tz={tz}
                    placeholder='XX:00 PM'
                    preventTyping={true}
                    disabled={isClosed}
                    req={!isClosed}
                    validation={{ 'opens_at': { required: isClosed ? false : 'An open time is required' }}}
                    handleUpdate={(e) => formMethods.setValue('opens_at', value_for(e.target.value))}
                />

                <MomentTimePicker
                    cols="3"
                    label='Closes At'
                    name={`closes_at`}
                    value={value_for(closesAt, true)}
                    tz={tz}
                    placeholder='XX:00 PM'
                    preventTyping={true}
                    disabled={isClosed}
                    req={!isClosed}
                    validation={{ 'closes_at': { required: isClosed ? false : 'A close time is required' }}}
                    handleUpdate={(e) => formMethods.setValue('closes_at', value_for(e.target.value))}
                />
            </FormRow>

            <FormRow>
                <Input
                    cols='8'
                    type='text'
                    value={note}
                    placeholder='Note'
                    label='Note'
                    req={false}
                    validation={{ 'note': { required: false } }}
                    handleChange={(e) => formMethods.setValue('note', e.target.value)}
                />

                <div className="col-4 align-self-center text-right">
                    { (date || opensAt || closesAt || note) &&
                        <button
                            type="button"
                            className="btn btn-secondary btn-sm text-white mb-n2 mr-2"
                            onClick={handleReset}
                        >
                            Reset
                        </button>
                    }

                    <button
                        type="button"
                        className="btn btn-primary btn-sm mb-n2"
                        disabled={!date}
                        onClick={handleAdd}
                    >
                        <i className="far fa-plus mr-2" />Add Date
                    </button>
                </div>
            </FormRow>

            { errorMessageFor('date', errors) }
            { errorMessageFor('opens_at', errors) }
            { errorMessageFor('closes_at', errors) }
        </div>
    )
}

export const SpecialHoursForm = React.memo(_specialHoursForm)
