import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NumberIncrementer from '../../../../components/Form/NumberIncrementer'
import { selectUniformParticipantCount, setUniformParticipantCount } from '../../bookingSlice'
import { selectCustomerVerbiagePlural } from '../../../ResourceType/resourceTypeSlice'

export default function ParticipantIncrementer({ disabled }) {

    const dispatch = useDispatch()

    const verbiage = useSelector(selectCustomerVerbiagePlural)
    const count = useSelector(selectUniformParticipantCount)

    const handleChange = value => {
        dispatch(setUniformParticipantCount(parseInt(value)))
    }
 
    return (
        <div className="container">
            <div className="row align-items-center mb-3">
                <div className="col-sm-5 text-right">
                    <h5 className="mb-0 xbm-customer-type">{verbiage}</h5>
                </div>
                <div className="col-sm-7">
                    <div className="xbm-incrementer-container">
                        <NumberIncrementer
                            disabled={disabled}
                            min={0}
                            preventTyping={true}
                            value={count || 0}
                            incrementAmount={1}
                            handleChange={handleChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
