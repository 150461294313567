import React from 'react'
import Input from '../Form/Input'
import Checkbox from '../Form/Checkbox';
import FormRow from '../Form/FormRow'

export default function CustomerTypeForm({ handleUpdate, handleCheckboxUpdate, form, errors }) {
    return (
        <div>
            <FormRow>
                <Input
                    cols='12'
                    value={form.name}
                    errors={errors}
                    validation={{ 'name': { required: true }}}
                    handleChange={handleUpdate}
                />
            </FormRow>
            <FormRow>
                <Checkbox
                    cols='12'
                    name='non_participant'
                    label='Customer Type is a non-participant'
                    value={form.non_participant}
                    handleChange={handleCheckboxUpdate}
                />

        {/*
                <Checkbox
                    cols='6'
                    name='shows_in_scoring_app'
                    label='Shows in scoring app?'
                    value={form.shows_in_scoring_app}
                    handleChange={handleCheckboxUpdate}
                />
         */}
            </FormRow>
        </div>
    )
}
