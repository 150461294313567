import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import InputMask from "react-input-mask"
import { setAmountType } from '@/features/BookingPayment/bookingPaymentSlice'
import { setPackage } from '@/features/Packages/packageSlice'

export default function Step1({ setStep, fetchBooking, setIsPaymentRequired, autoFillReservationNumber }) {
  const dispatch = useDispatch()

  const [reservationNumber, setReservationNumber] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (autoFillReservationNumber) {
      setReservationNumber(autoFillReservationNumber)
    }
  }, [])

  const amountType = (pricingType, isGroupPricing, booking) => {
    if (booking.uses_flat_rate || booking.booking_discounts.find(discount => discount.amount_type === "dollar")) {
      return "custom"
    }

    if (isGroupPricing) {
      return "custom"
    } else if (pricingType === "pricing_by_customer_type") {
      return "participant"
  } else if (pricingType === "uniform_pricing") {
      return "participant_uniform"
    }
  }

  const handleReservationSearch = () => {
    if (reservationNumber !== "") {
      fetchBooking(reservationNumber).then(data => {
        if (data.success) {

          if (data.booking.balance_cents <= 0 || !!data.booking.check) {
            setIsPaymentRequired(false)
            setStep('5')
            return
          }

          const participants = data.booking.participants
          const group = data.package.groups.filter(group => {
            return participants >= Number.parseInt(group.min) && participants <= Number.parseInt(group.max)
          })[0]

          if (data.booking.members.length > 0) {
            dispatch(setAmountType('custom'))
          } else {
            dispatch(setAmountType(amountType(data.package.pricing_type, group.price_type === 'group_price', data.booking)))
          }
          dispatch(setPackage(data.package))

          setStep('2')
        } else {
          setErrorMessage(data.error_message)
        }
      })
    } else {
      setErrorMessage('Please enter you reservation number.')
    }
  }

  return (
    <div className="row justify-content-center">
      <div className="col-12 col-md-6">
        <div className="form-group">
          <label htmlFor="reservation_number">Enter reservation number</label>

          <InputMask
            id="reservation_number"
            mask="a**-9999-9999"
            className="form-control"
            onChange={e => setReservationNumber(e.target.value)}
            value={reservationNumber}
          />

          <div
            className="invalid-feedback d-block text-center mt-2"
            style={{ fontSize: "90%" }}
          >{errorMessage}</div>
          <button
            className="btn btn-primary btn-block mt-4"
            onClick={handleReservationSearch}>
            Search
          </button>
        </div>
      </div>
    </div>
  )
}
