import React, { useMemo } from 'react'
import moment from 'moment-timezone'

export function _specialHoursList({ specialHours, tz, handleManualUpdate }) {

    const hours = useMemo(() => {
        const now = moment().startOf('day').tz(tz)

        return specialHours.filter((d) => moment.tz(d.date, tz) >= now)
                           .sort((a, b) => (moment.tz(a.date, tz) > moment.tz(b.date, tz)) ? 1 : -1)
    }, [specialHours.length])

    const handleRemove = (targetIndex) => {
        handleManualUpdate('special_hours', hours.filter((o, i) => i !== targetIndex))
    }

    return hours.length > 0 && (
        <div className="border-top py-3">
            {
              hours.map((sh, index) => {
                  const note = Boolean(sh.note) ? `(${sh.note})` : ''

                  return (
                      <div key={index} className="row d-flex align-items-center">
                          <div className="col-3">
                              { moment.tz(sh.date, tz).format('ddd, MMM Do, YYYY') }
                          </div>

                          <div className="col-6">
                              {
                                  sh.is_closed
                                      ? <span className="mr-3 badge badge-danger">Closed</span>
                                      : <span className="mr-3 badge badge-success px-3">Open</span>
                              }
                              <span>
                                { sh.is_closed
                                    ? note
                                    : `${moment.tz(sh.opens_at, tz).format('LT')} - ${moment.tz(sh.closes_at, tz).format('LT')} ${note}`
                                }
                              </span>
                          </div>

                          <div className="col-3 text-right">
                              <button
                                type='button'
                                className='btn btn-link text-danger'
                                onClick={() => handleRemove(index)}
                              >
                                  Remove
                              </button>
                          </div>
                      </div>
                  )
              })
            }
        </div>
    )
}

export const SpecialHoursList = React.memo(_specialHoursList)
