import React from 'react'
import { useSelector } from 'react-redux'
import { selectOpen as selectScheduleDrawerOpen } from '@/features/Schedule/scheduleSlice'
import { selectOpen as selectPaymentModalOpen, selectAmountType, selectCustomersToPayFor, selectCustomerTypeCounts } from '@/features/BookingPayment/bookingPaymentSlice'
import { humanizedLabel } from '@/lib/String'
import { numberToCurrency } from '@/lib/Number'
import { selectPackage } from '@/features/Packages/packageSlice'

export default function ParticipantCosts({
    customerTypeTotals=[],
    members=[],
    memberPrice=null,
    isGroupPricing=false,
    paymentMode=false,
}) {

    const scheduleDrawerOpen                = useSelector(selectScheduleDrawerOpen)
    const paymentModalOpen                  = useSelector(selectPaymentModalOpen)
    const paymentModalAmountType            = useSelector(selectAmountType)
    const paymentModalCustomersToPayFor     = useSelector(selectCustomersToPayFor)
    const paymentModalCustomerTypesToPayFor = useSelector(selectCustomerTypeCounts)
    const pkg                               = useSelector(selectPackage)

    const shouldDisplay = (
        !!customerTypeTotals
        && (!!customerTypeTotals?.types?.length || members.length > 0)
        && (
            (!paymentMode && scheduleDrawerOpen)
            || (
                // if we're paying via the payment modal...
                paymentMode && paymentModalOpen
                // and we've chosen per participant...
                && /^(participant|participant_uniform)$/i.test(paymentModalAmountType)
                // and if we have customers chosen to pay for (regardless of pricing scheme)...
                && (paymentModalCustomersToPayFor > 0 || Object.values(paymentModalCustomerTypesToPayFor).reduce((p,c) => (p + c), 0) > 0)
            )
        )
    )

    return shouldDisplay && (
        <div className="drawer-booking-ledger-section">
            <table>
                <colgroup>
                    <col width="48%" />
                    <col width="auto" />
                    <col width="auto" />
                </colgroup>
                <tbody>
                    {
                        customerTypeTotals.types.map((t, index) => {
                            const quantity = paymentMode && paymentModalCustomersToPayFor ? paymentModalCustomersToPayFor : t.quantity
                            const total    = isGroupPricing ? t.price : (Number.parseFloat(t.price) * quantity)

                            return (
                                <tr key={`ctt-${index}`}>
                                    <th className="text-left align-top" dangerouslySetInnerHTML={{ __html: humanizedLabel(t.name, true) }} />
                                    <th className="text-right align-top">
                                        {
                                            // Only show "AMOUNT x QUANTITY" for non-group pricing
                                            !isGroupPricing && <>
                                                {numberToCurrency(t.price)}
                                                <span className="text-lowercase"> x {quantity}</span>
                                            </>
                                        }
                                    </th>
                                    <th className="text-right align-top text-dark">
                                        {numberToCurrency(total)}
                                    </th>
                                </tr>
                            )
                        })
                    }
                    {
                        pkg?.pricing_type === 'pricing_by_customer_type' && members.length > 0 &&
                        <tr>
                            <th className="text-left align-top">
                                Member
                            </th>
                            <th className="text-right align-top">
                                {numberToCurrency(memberPrice)}
                                <span className="text-lowercase"> x {members.length}</span>
                            </th>
                            <th className="text-right align-top text-dark">
                                {numberToCurrency(memberPrice * members.length)}
                            </th>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}
