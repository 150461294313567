import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setOpenRemoveModal } from '../Addon/addonSlice'
import { selectBooking } from '@/features/EditBooking/editBookingSlice'
import { isBookingCancelled } from '@/lib/Booking'

export default function RemoveBookingAddonTime({ disabled=false }) {

    const dispatch = useDispatch()
    const booking  = useSelector(selectBooking)

    return (
        <button
            children='Remove Time'
            className="btn btn-outline w-100 mb-0 px-2"
            disabled={disabled || isBookingCancelled(booking)}
            onClick={() => dispatch(setOpenRemoveModal(true))}
        />
    )
}
