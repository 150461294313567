import React from 'react'
import { createPortal } from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'

import { selectBookingId, selectBooking } from '../EditBooking/editBookingSlice'
import TerminalTransaction from '../Terminal/TerminalTransaction'
import AdyenTerminalTransaction from '../Terminal/AdyenTerminalTransaction'
import { submitTransaction, selectConnected, selectProcessing as selectPaysafeProcessing } from '../Terminal/terminalSlice'
import { selectTerminals, selectSelectedTerminal, selectProcessing as selectAdyenProcessing } from '../Adyen/adyenSlice'

import {
    submitPayment,
    selectAmountToPay,
    selectAmountType,
    selectCustomAmount,
    selectCustomerTypeCounts,
    selectCustomersToPayFor
} from './bookingPaymentSlice'

import { selectPackage } from '../Packages/packageSlice'
import { selectLocation, selectPaymentProcessor } from '../Locations/locationSlice'
import { selectCustomerTypes } from '../CustomerTypes/customerTypeSlice'
import { selectSelectedResourceType } from '../ResourceType/resourceTypeSlice'
import { taxRateAndFeesFor, parsePricingByType } from '@/lib/Pricing'
import { debug } from '@/lib/Debug'

export default function PaymentCreditTerminal({ hardwareKey }) {

    const formMethods = useFormContext()
    const dispatch    = useDispatch()
    const bookingId   = useSelector(selectBookingId)
    const container   = document.getElementById('process-payment-button-container')

    /* PRICING STUFF */
    const booking                   = useSelector(selectBooking)
    const customerTypeCounts        = useSelector(selectCustomerTypeCounts)
    const pkg                       = useSelector(selectPackage)
    const location                  = useSelector(selectLocation)
    const paymentProcessor          = useSelector(selectPaymentProcessor)
    const customerTypes             = useSelector(selectCustomerTypes)
    const customAmount              = useSelector(selectCustomAmount)
    const customersToPayFor         = useSelector(selectCustomersToPayFor)
    const resourceType              = useSelector(selectSelectedResourceType)
    const connected                 = useSelector(selectConnected)
    const processingPaysafeTerminal = useSelector(selectPaysafeProcessing)
    const processingAdyenTerminal   = useSelector(selectAdyenProcessing)
    const adyenTerminals            = useSelector(selectTerminals)
    const adyenTerminal             = useSelector(selectSelectedTerminal)
    const amountType                = useSelector(selectAmountType)
    const amountToPay               = useSelector(selectAmountToPay)

    const transactionCallback = (payment=null, partialAmountCharged=null) => {
        dispatch(submitPayment(
            bookingId,
            payment?.cardToken,
            payment,
            partialAmountCharged,
            paymentProcessor === 'adyen' ? adyenTerminal : null
        ))
    }

    const handleAdyenSubmit = () => {
        transactionCallback()
    }

    const handlePaysafeSubmit = () => {
        const taxRateAndFees = taxRateAndFeesFor(location, pkg, booking)

        const prices  = parsePricingByType(
            amountType,
            pkg,
            booking,
            customerTypeCounts,
            customerTypes,
            taxRateAndFees.tax_rate,
            taxRateAndFees.rkd_reservation_fee,
            taxRateAndFees.merchant_reservation_fee,
            taxRateAndFees.third_party_reservation_fee,
            location,
            resourceType,
            customersToPayFor,
            customAmount
        )

        const total = Math.round(Number.parseFloat(prices.total) * 100)

        if (debug && console) { console.log('SUBMITTING PAYMENT VIA TERMINAL') }
        dispatch(submitTransaction(total, transactionCallback))
    }

    return <>
        {
            paymentProcessor === 'adyen' && <>
                <AdyenTerminalTransaction
                    displayAuthorizedCards={false}
                    hideButton
                />

                { container && createPortal(
                    <button
                        type='button'
                        children={`${processingAdyenTerminal ? 'Processing' : 'Process'} Payment`}
                        className='w-100 py-3 px-5 btn btn-primary'
                        disabled={(amountToPay <= 0) || processingAdyenTerminal || (adyenTerminals?.length <= 0) || !adyenTerminal}
                        onClick={formMethods.handleSubmit(handleAdyenSubmit)}
                    />
                , container) }
            </>
        }

        {
            paymentProcessor === 'paysafe' && <>
                <TerminalTransaction
                    hardwareKey={hardwareKey}
                    transactionCallback={transactionCallback}
                />

                { container && createPortal(
                    <button
                        type='button'
                        children={`${processingPaysafeTerminal ? 'Processing' : 'Process'} Payment`}
                        className={`w-100 py-3 px-5 btn btn-${connected ? 'primary' : 'outline-secondary'}`}
                        disabled={(amountToPay <= 0) || !connected || processingPaysafeTerminal}
                        onClick={formMethods.handleSubmit(handlePaysafeSubmit)}
                    />
                , container) }
            </>
        }
    </>
}
