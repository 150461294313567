import React, { useState } from 'react'
import FormRow from "../Form/FormRow"
import Input from "../Form/Input"
import Checkbox from "../Form/Checkbox"
import errorClass from "../../lib/Errors"
import DraggableOptions from './DraggableOptions'

export default function PackageGroupSetUpTab({
  handleUpdate,
  handleManualUpdate,
  form,
  errors,
  packages,
}) {
  const [groupPackages, setGroupPackages] = useState([])

  const inputValue = form.packages || []

  const handleCheckboxChange = packages => {
    handleManualUpdate('packages', packages.filter(p => document.querySelector(`#packages-toggle-${p.id}`).checked).map(p => p.id))
  }

  return (
    <div>
      <FormRow>
        <Input
          cols='8'
          name='name'
          handleChange={handleUpdate}
          value={form.name}
          autoFocus={true}
          errors={errors}
          validation={{
            'name': { required: true }
          }}
        />

        <Checkbox
          cols='4'
          className="pt-1 pl-4 mt-4"
          label='Shown in Drawer?'
          tooltip='When enabled, this package group will be available in the calendar schedule drawer.'
          name='show_in_drawer'
          value={form.show_in_drawer}
          handleChange={(e) => handleManualUpdate(e.target.name, e.target.checked)}
        />
      </FormRow>

      <FormRow>
        <div className="col py-4">
          <h3 className="mt-0 mb-4 d-flex align-items-end justify-content-between">
            <span>Available Packages</span>

            <small className="text-muted font-italic font-weight-light">
                Sort order can be changed using drag and drop.
            </small>
          </h3>

          <DraggableOptions
            packages={packages}
            inputValue={inputValue}
            handleCheckboxChange={handleCheckboxChange}
            handleManualUpdate={handleManualUpdate}
            groupPackages={groupPackages}
            setGroupPackages={setGroupPackages}
            errorClass={errorClass}
            errors={errors}
          />
        </div>
      </FormRow>

      {
        errors['packages'] && (
          <div className="invalid-feedback d-block">
            { errors['packages'].join(', ') }
          </div>
        )
      }
    </div>
  )
}
