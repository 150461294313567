import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAvailableDaysForDuration, selectScrollingDate } from '../../../Availability/availabilitySlice'
import { selectDuration, selectParticipants, setDate, setDuration } from '../../bookingSlice'

export default function Duration({ duration, preselected=false }) {

    const dispatch = useDispatch()

    const bookingDuration = useSelector(selectDuration)
    const scrollingDate = useSelector(selectScrollingDate)
    const participants = useSelector(selectParticipants)

    const handleClick = e => {
        const d = Number.parseInt(duration.duration)

        // set the date to null since it may no longer be available for the new duration on that day.
        dispatch(setDate(null))

        // set the duration itself
        dispatch(setDuration(d))

        // request the calendar to populate based on that duration, but keep whatever
        // scrolling month we're on in case they're looking at future months
        dispatch(fetchAvailableDaysForDuration(d, scrollingDate, participants))
    }

    // click itself upon mounting
    // if it should be pre-selected
    useEffect(() => {
        if (preselected) {
          handleClick()
        }
    }, [])

    return (
        <label className="radio-as-card">

            <input
                onChange={handleClick}
                checked={bookingDuration === duration.duration}
                type="radio"
                name="duration"
                value={duration.duration} />

            <div className="radio-card">
                {/* @TODO right now we are showing everything in minutes for ease... we can switch to dynamic between minutes & hours, etc... */}
                {duration.duration} Minutes
            </div>

        </label>
    )
}
