import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, FormProvider } from 'react-hook-form'

import {
    selectLoading,
    selectOpen,
    selectAmountType,
    selectTab,
    setOpen,
    clearForm
} from '@/features/BookingPayment/bookingPaymentSlice'

import { selectBookingId } from '@/features/EditBooking/editBookingSlice'
import { selectPayments, fetchBookingPayments } from '@/features/BookingHistory/bookingHistorySlice'
import { clearAdyenState, selectProcessing as selectAdyenProcessing } from '@/features/Adyen/adyenSlice'
import { selectProcessing as selectPaysafeProcessing } from '@/features/Terminal/terminalSlice'
import { selectLocation } from '@/features/Locations/locationSlice'

import BookingPaymentTabs from './BookingPaymentTabs'
import PaymentAmountType from './PaymentAmountType'
import PaymentParticipants from './PaymentParticipants'
import PaymentCustomAmount from './PaymentCustomAmount'
import PaymentTip from './PaymentTip'
//import PaymentInlineTotals from './PaymentInlineTotals'
import PaymentCash from './PaymentCash'
import PaymentCheck from './PaymentCheck'
import PaymentCreditCard from './PaymentCreditCard'
import PaymentApplyDeposit from './PaymentApplyDeposit'
import PaymentGiftCard from './PaymentGiftCard'
import PaymentReceiptInfo from './PaymentReceiptInfo'
import PaymentProcessing from './PaymentProcessing'
import BookingPaymentLedger from './BookingPaymentLedger'
import PaymentUniformParticipants from './PaymentUniformParticipants'
import BookingHistoryPayments from '@/features/BookingHistory/BookingHistoryPayments'
import { UI } from '@/lib/Constants'

export default function PaymentModal({ hardwareKey, environment }) {

    const formMethods = useForm({ mode: 'all' })

    const dispatch                  = useDispatch()
    const processingAdyenTerminal   = useSelector(selectAdyenProcessing)
    const processingPaysafeTerminal = useSelector(selectPaysafeProcessing)
    const processingTerminal        = processingAdyenTerminal || processingPaysafeTerminal
    const processingOther           = useSelector(selectLoading)
    const open                      = useSelector(selectOpen)
    const tab                       = useSelector(selectTab)
    const amountType                = useSelector(selectAmountType)
    const bookingId                 = useSelector(selectBookingId)
    const payments                  = useSelector(selectPayments)
    const location                  = useSelector(selectLocation)

    const [modalLoaded, setModalLoaded] = useState(false)

    const handleKeyDown = (e) => {
        if (
            e.key === 'Escape'
            && !document.body.classList.contains(UI.classes.CONFIRMATION_OPEN)
            && !document.body.classList.contains(UI.classes.HELPJUICE_OPEN)
            && !document.body.classList.contains(UI.classes.INPUT_LOCKED)
        ) {
            handleClose()
        }
    }

    const handleClose = () => {
        dispatch(setOpen(false))
    }

    // Handle the initial mount and unmount of the component
    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown, false)
        document.body.classList.add(UI.classes.MULTIPLE_MODALS_OPEN)
        setModalLoaded(true)

        return () => {
            document.body.classList.remove(UI.classes.MULTIPLE_MODALS_OPEN)
            document.removeEventListener('keydown', handleKeyDown, false)
            delete window._BPH_TIMER_PAUSE
        }
    }, [])

    // Handle open/close of the modal
    useEffect(() => {
        if (modalLoaded && !open) {
            formMethods.reset()
            dispatch(clearForm())
            dispatch(clearAdyenState())
        }
    }, [open])

    // Clear form errors whenever we change between the main
    // payment method tabs or payment amount type sub-tabs
    useEffect(() => {
        formMethods.clearErrors()
    }, [tab, amountType])

    useEffect(() => {
        // Pause/un-pause the auto-refresh of the terminal log while a payment is or is not in flight
        window._BPH_TIMER_PAUSE = (processingOther || processingTerminal)

        // Update the terminal log once payment is complete
        if (!processingOther && !processingTerminal) {
            dispatch(fetchBookingPayments(bookingId))
        }
    }, [processingTerminal, processingOther])

    return open && <>
        <FormProvider {...formMethods}>
            <div className="modal modal-backdrop modal--xxl">
                <div id='payment-modal' className="modal-container">

                    <div className="modal-header">
                        <div className="modal-title">Payment Type</div>

                        <BookingPaymentTabs />

                        <button className="modal-close" onClick={handleClose}>
                            <span>Close</span>
                            <i className="far fa-times" />
                        </button>
                    </div>

                    <div className={`modal-body ${payments?.length > 0 ? 'border-bottom mb-3' : ''}`}>
                        <div className="modal-body-main">
                            <PaymentAmountType />

                            {/*
                                THIS IS THE AREA THAT CHANGES BASED ON THE 3 RADIOS ABOVE.
                                These sections are the same regardless of payment method.
                            */}

                            {/* { amountType === 'custom' && <PaymentInlineTotals /> } */}

                            {/* Remaining Balance has no additional fields. */}

                            { amountType === 'participant' && <PaymentParticipants /> }
                            { amountType === 'participant_uniform' && <PaymentUniformParticipants /> }

                            { /participant/i.test(amountType) && <PaymentApplyDeposit /> }

                            { amountType === 'custom' && <PaymentCustomAmount /> }

                            {/* BEGIN EXISTS REGARDLESS OF PAYMENT METHOD OR AMOUNT */}
                            { !location.advanced_point_of_sale_enabled_at && <PaymentTip /> }
                            <PaymentReceiptInfo />
                            {/* END EXISTS REGARDLESS OF PAYMENT METHOD OR AMOUNT */}

                            {/* PaymentCreditCard is modal now */}
                            { tab === 'credit'   && modalLoaded && <PaymentCreditCard hardwareKey={hardwareKey} environment={environment} /> }
                            { tab === 'cash'     && <PaymentCash /> }
                            { tab === 'check'    && <PaymentCheck /> }
                            { tab === 'giftCard' && <PaymentGiftCard /> }
                        </div>
                        <div className="modal-body-sidebar">
                            <div className="payment-modal-ledger h-100">
                                <BookingPaymentLedger />
                            </div>
                        </div>
                    </div>

                    <div className={payments?.length > 0 ? 'modal-footer overflow-auto' : 'd-none'} style={{ maxHeight: '200px' }}>
                        <BookingHistoryPayments
                            id='transaction-log'
                            className='table table-sm m-0 sticky-table-headers'
                            autoRefreshDelay='2000'
                            // preventAutoRefresh
                            preventRefunds
                            preventEmailingReceipts
                        />
                    </div>
                </div>
            </div>
        </FormProvider>

        <PaymentProcessing />
    </>
}
