import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useFormContext } from 'react-hook-form'
import InputWrapper from './InputWrapper'
import errorClass from '../../lib/Errors'
import titleize from '../../lib/String'
import InputMask from "react-input-mask"

export default function Input({
  id,
  name='',
  value='',
  cols,
  min,
  minLength,
  max,
  maxLength,
  step='1',
  type='text',
  className='',
  containerClassName='',
  errors=[],
  children,
  label,
  labelTooltip,
  toolTipOptions={},
  labelAction,
  hideLabel,
  hint,
  mask,
  maskPlaceholder,
  placeholder,
  append=true,
  prepend=false,
  withWrapper=true,
  autoFocus=false,
  autoComplete,
  role=null,
  req=null,
  validation=null,
  handleFocus=() => {},
  handleBlur=() => {},
  handleChange=() => {},
  handleKeyDown=() => {},
  handleKeyPress=null,
  disabled=false,
  readOnly=false,
  tabIndex=null,
  style={},
}) {
    const formMethods = useFormContext()

    /**
     * Example of an expected validation
     * schema object to register
     *
     * {
     *   'name': {
     *     required: 'A name is required',
     *     minLength: { value: 4, message: 'must be more than 4 characters long' }
     *   }
     * }
     */
    const separator = ':'

    const field = formMethods && validation
        ? formMethods.register( Object.keys(validation)[0], validation[Object.keys(validation)[0]] )
        : { name: name + separator + uuidv4(), ref: null }

    const _name = field.name.split(separator)[0]

    const _handleFocus = (e) => {
        // update react hook form validation
        if ('onFocus' in field) { field.onFocus(e) }
        // update our form object
        handleFocus(e)
    }

    const _handleBlur = (e) => {
        // update react hook form validation
        if ('onBlur' in field) { field.onBlur(e) }
        // update our form object
        handleBlur(e)
    }

    const _handleChange = (e) => {
        // update react hook form validation
        if ('onChange' in field) { field.onChange(e) }
        // update our form object
        handleChange(e)
    }

    const _handleKeyPress = (e) => {
        // allow a function to be passed in as a prop
        // that overrides the default behavior below
        if (typeof handleKeyPress === 'function') {
            return handleKeyPress(e)
        }

        if (e.key === 'Enter') {
            e.preventDefault()
        }
    }

    const prependedChild = () => {
        if (children?.length === 1) { return children }

        // ignore all children except first
        let child = ''

        React.Children.map(children, (c, _i) => {
            if (/prepend/.test(c?.props?.className)) {
                child = c
            }
        })

        return child
    }

    const appendedChild = () => {
        if (children?.length === 1) { return children }

        // ignore all children except last
        let child = ''

        React.Children.map(children, (c, _i) => {
            if (/append/.test(c?.props?.className)) {
                child = c
            }
        })

        return child
    }

    return (
        <InputWrapper
            name={_name}
            req={(validation && req === null) || Boolean(req)}
            cols={cols}
            errors={errors}
            label={label}
            tooltip={labelTooltip}
            toolTipOptions={toolTipOptions}
            action={labelAction}
            hideLabel={hideLabel}
            withWrapper={withWrapper}
        >
          {
            mask ? (
              <div className={children ? 'input-group' : ''}>
                  {prepend && prependedChild()}

                  <InputMask
                    mask={mask}
                    maskPlaceholder={maskPlaceholder}
                    value={value === 0 ? 0 : (value || '')}
                    onFocus={_handleFocus}
                    onBlur={_handleBlur}
                    onChange={_handleChange}
                    disabled={disabled}
                    readOnly={readOnly}
                  >
                    <input
                      ref={field.ref}
                      id={id}
                      name={field.name}
                      type={type}
                      min={min}
                      minLength={minLength}
                      max={max}
                      maxLength={maxLength}
                      step={step}
                      className={`form-control ${className} ${errorClass(field.name, errors)}`}
                      placeholder={placeholder || titleize(_name)}
                      autoFocus={autoFocus}
                      autoComplete={autoComplete}
                      role={role}
                      style={style}
                      readOnly={readOnly}
                      tabIndex={tabIndex}
                      onKeyDown={handleKeyDown}
                      onKeyPress={_handleKeyPress}
                    />
                  </InputMask>

                  {append && appendedChild()}
              </div>
            ) : (
              <div className={`${containerClassName} ${children ? 'input-group' : ''}`}>
                  {prepend && prependedChild()}

                  <input
                      ref={field.ref}
                      id={id}
                      name={field.name}
                      type={type}
                      min={min}
                      minLength={minLength}
                      max={max}
                      maxLength={maxLength}
                      step={step}
                      value={value === 0 ? 0 : (value || '')}
                      className={`form-control ${className} ${errorClass(field.name, errors)}`}
                      placeholder={placeholder === false ? "" : (placeholder|| titleize(_name))}
                      autoFocus={autoFocus}
                      autoComplete={autoComplete}
                      role={role}
                      onFocus={_handleFocus}
                      onBlur={_handleBlur}
                      onChange={_handleChange}
                      onKeyDown={handleKeyDown}
                      onKeyPress={_handleKeyPress}
                      disabled={disabled}
                      readOnly={readOnly}
                      tabIndex={tabIndex}
                      style={style}
                  />

                  {hint && (
                    <small className="form-text text-muted">{hint}</small>
                  )}

                  {append && appendedChild()}
              </div>
            )
          }
        </InputWrapper>
    )
}
