import { createSlice } from "@reduxjs/toolkit"
import axios from 'axios'

const pricingInitialState = {
    loading:          false,
    basePrice:        0,
    autoGratuity:     0,
    rkd_fees:         0,
    merchant_fees:    0,
    third_party_fees: 0,
    total:            0,
    taxes:            0,
    taxesAndFees:     0,
    deposit:          0,
    discounts:        [],
}

export const pricingSlice = createSlice({
    name: 'pricing',
    initialState: pricingInitialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setPrices: (state, action) => {
            state.basePrice        = action.payload.base_price
            state.autoGratuity     = action.payload.auto_gratuity
            state.rkd_fees         = action.payload.rkd_fees
            state.merchant_fees    = action.payload.merchant_fees
            state.third_party_fees = action.payload.third_party_fees
            state.total            = action.payload.total
            state.taxes            = action.payload.taxes
            state.taxesAndFees     = action.payload.taxes_and_fees

            state.discounts = action.payload?.discounts
                ? action.payload.discounts
                : []
        },
        setDeposit: (state, action) => {
            state.deposit = action.payload
        },
        clearPrices: () => {
            return pricingInitialState
        }
    }
})

export const {
    setLoading,
    setPrices,
    setDeposit,
    clearPrices
} = pricingSlice.actions

export const selectLoading        = state => state.pricing.loading
export const selectBasePrice      = state => state.pricing.basePrice
export const selectAutoGratuity   = state => state.pricing.autoGratuity
export const selectRkdFees        = state => state.pricing.rkd_fees
export const selectMerchantFees   = state => state.pricing.merchant_fees
export const selectThirdPartyFees = state => state.pricing.third_party_fees
export const selectTotal          = state => state?.pricing?.total
export const selectTaxes          = state => state.pricing.taxes
export const selectTaxesAndFees   = state => state.pricing.taxesAndFees
export const selectDeposit        = state => state?.pricing?.deposit
export const selectDiscounts      = state => state.pricing.discounts

const parsedParticipants = (participants) => {
    // if participants is a single number (ex: 1) or a range (ex: 13-18), parse
    // out either the single number (1) or the first number of the range (13)
    return Number(String(participants).split('-')[0])
}

export function fetchPrices(packageId, participants, customerTypeCounts, duration, date, time, memberships = null, promoCode = null) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        axios.post(`/packages/${packageId}/calculate_price`, {
            authenticity_token:   getState().session.formToken,
            participants:         parsedParticipants(participants),
            memberships:          JSON.stringify(memberships || []),
            customer_type_counts: customerTypeCounts,
            duration:             duration,
            date:                 date,
            time:                 time,
            promo_code:           promoCode
        }).then(({ data }) => {
            dispatch(setPrices(data))
        }).catch((e) => {
            console.warn(e)
        }).then(() => {
            dispatch(setLoading(false))
        })
    }
}

export function fetchDepositAmount(packageId, participants, customerTypeCounts, duration, date, time, promoCode = null, memberships = null) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        axios.post(`/packages/${packageId}/calculate_deposit`, {
            authenticity_token:   getState().session.formToken,
            participants:         parsedParticipants(participants),
            memberships:          JSON.stringify(memberships || []),
            customer_type_counts: customerTypeCounts,
            duration:             duration,
            date:                 date,
            time:                 time,
            promo_code:           promoCode
        }).then(({ data }) => {
            // right now the only deposit information that we show on the modal is the total deposit amount,
            // we don't break down the taxes for the deposit as well -- just the total amount
            dispatch(setDeposit(data.total))
        }).catch((e) => {
            console.warn(e)
        }).then(() => {
            dispatch(setLoading(false))
        })
    }
}

export default pricingSlice.reducer
