import { createSlice } from "@reduxjs/toolkit"

export const referralsSlice = createSlice({
    name: 'referrals',
    initialState: {
        referral_types: []
    },
    reducers: {
        setReferralTypes: (state, action) => {
            state.referral_types = action.payload
        }
    }
})

export const {
    setReferralTypes
} = referralsSlice.actions

export const selectReferralTypes = state => state.referrals.referral_types

export default referralsSlice.reducer
