import React from 'react'
import { useSelector } from 'react-redux'
import { selectBookings } from '../CalendarBookings/calendarBookingsSlice'
import { Booking } from './Booking'

export default function BookingsContainer() {
    const bookings = useSelector(selectBookings)

    return (
        <div className="bookings">
            {
                bookings.map((b, index) => (
                    !b.booking.parent_booking && (
                        <Booking
                            key={`booking_${b.booking.id}_${index}`}
                            booking={b.booking}
                            resources={b.resources}
                            childBookings={b.child_bookings}
                            pkg={b.package}
                        />
                    )
                ))
            }
        </div>
    )
}
