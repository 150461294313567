import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    configureModal,
    moveCheckItemsBetweenTabs,
    selectCurrentCheck,
    selectModals,
    selectMultipleItemsMode,
    resetMultipleItemsMode
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import CenteredText from '@/features/AdvancedPointOfSale/components/CenteredText'
import TabStatus from '@/features/AdvancedPointOfSale/components/tabs/TabStatus'
import Modal from '@/features/AdvancedPointOfSale/components/Modal'
import ModalListButton from '@/features/AdvancedPointOfSale/components/buttons/ModalListButton'
import { isOpen, inAlteration } from '@/features/AdvancedPointOfSale/lib/Checks'

export default function MoveItemsBetweenTabsModal() {

    const dispatch                         = useDispatch()
    const check                            = useSelector(selectCurrentCheck)
    const { moveItemsBetweenTabs:modal }   = useSelector(selectModals)
    const { uuids:multipleItemsModeUUIDs } = useSelector(selectMultipleItemsMode)

    const tabOptions = (
        (check?.tabs || [])
            .filter((tab) => tab.id !== modal.fromTabId)
            .filter((tab) => !tab.is_booking)
            .filter((tab) => !tab.payment_locked_by_id)
            .filter((tab) => (isOpen(check) && !tab.is_closed) || (inAlteration(check) && tab.is_closed))
    )

    const handleClose = () => {
        dispatch(configureModal({
            modal: 'moveItemsBetweenTabs',
            config: {
                isOpen: false,
                fromTabId: null,
                toTabId: null,
            }
        }))
        dispatch(resetMultipleItemsMode())
    }

    const handleMove = () => {
        dispatch(moveCheckItemsBetweenTabs()).then(() => handleClose())
    }

    const footerButtons = <>
        <button
            type='button'
            children='Close'
            className='btn btn-outline-secondary text-white text-bold mr-auto'
            onClick={handleClose}
        />

        <button
            type='button'
            children='Move'
            className='btn btn-primary ml-auto'
            disabled={!modal.toTabId}
            onClick={handleMove}
        />
    </>

    return modal.isOpen && (
        <Modal
            className='move-items-between-tabs-modal'
            isOpen={modal.isOpen}
            title={`Move Item${multipleItemsModeUUIDs.length === 1 ? '' : 's'} To...`}
            size={modal.size}
            footerButtons={footerButtons}
            onClose={handleClose}
        >
            <div className='py-3'>
                {
                    tabOptions.map((tab) => (
                        <ModalListButton
                            key={tab.id}
                            children={
                                <div className='d-flex align-content-center'>
                                    { tab.is_tab_of_primary_check_owner && <i className='fas fa-star text-yellow mr-2' /> }
                                    <span>{tab.is_general && check.booking?.name || tab.name}</span>
                                    <TabStatus className={modal.toTabId === tab.id ? 'text-white' : 'text-green'} tab={tab} />
                                </div>
                            }
                            containerClassName='mx-4 my-1'
                            selected={modal.toTabId === tab.id}
                            onClick={() => dispatch(configureModal({ modal: 'moveItemsBetweenTabs', config: { toTabId: tab.id }}))}
                        />
                    ))
                }

                {
                    tabOptions.length === 0 && (
                        <CenteredText className='my-5' text='No Tabs Found' textClassName='text-gray2' />
                    )
                }
            </div>
        </Modal>
    )
}
