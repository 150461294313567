import React from 'react'

export default function DurationPricing({ duration, handleUpdate }) {

    const handlePriceChange = e => {
        // this is set/passed in Pricing.jsx, see that event handler
        handleUpdate(e, duration)
    }

    return (
        <div className="price-group-duration">
            <div className="connected-fields">
                <div className="form-group">
                    <label className="text-dark">{duration.duration} Minutes</label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="far fa-dollar-sign" />
                          </span>
                        </div>
                        <input
                            name='price'
                            type='number'
                            min={0}
                            step={0.01}
                            className="form-control"
                            defaultValue={duration.price || ''}
                            onChange={handlePriceChange}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label>Peak</label>
                    <input
                        name='pricePeak'
                        type='number'
                        min={0}
                        step={0.01}
                        className="form-control"
                        defaultValue={duration.pricePeak || ''}
                        onChange={handlePriceChange}
                    />
                </div>
                <div className="form-group">
                    <label>Add-On</label>
                    <input
                        name='addon_price'
                        type='number'
                        min={0}
                        step={0.01}
                        className="form-control"
                        defaultValue={duration.addon_price || ''}
                        onChange={handlePriceChange}
                    />
                </div>
            </div>
        </div>
    )
}
