import { createSlice } from "@reduxjs/toolkit"
import axios from 'axios'
import moment from 'moment'
import { addAlert } from '@/features/Notifications/notificationSlice'
import { parsedParticipants } from '@/lib/Booking'
import { debug } from '@/lib/Debug'

export const availabilitySlice = createSlice({
    name: 'availability',
    initialState: {
        /**
         * URL to fetch dates from... for all days of a given month
         */
        availableDatesUrl: null,
        /**
         * URL to fetch times for a specific day from
         */
        availableTimesUrl: null,
        /**
         * Are we currently fetching records?
         */
        loading: false,
        /**
         * Array of available dates for a given month
         */
        dates: [],
        /**
         * Array of all times for a given day in format [time, available?]
         */
        times: [],
        /**
         * The scrolling month. This is used so they can scroll without changing the
         * selected date unless they manually click on a date in one of the other months
         */
        scrollingDate: moment().toISOString()
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setDates: (state, action) => {
            state.dates = action.payload
        },
        setTimes: (state, action) => {
            state.times = action.payload
        },
        setScrollingDate: (state, action) => {
            state.scrollingDate = action.payload
        },
        setAvailableDatesUrl: (state, action) => {
            state.availableDatesUrl = action.payload
        },
        setAvailableTimesUrl: (state, action) => {
            state.availableTimesUrl = action.payload
        },
        resetDatesAndTimes: (state, action) => {
            state.dates = []
            state.times = []
        }
    }
})

export const {
    setLoading,
    setDates,
    setScrollingDate,
    setAvailableDatesUrl,
    setAvailableTimesUrl,
    setTimes,
    resetDatesAndTimes,
} = availabilitySlice.actions

export const selectLoading = state => state.availability.loading
export const selectDates = state => state.availability.dates
export const selectTimes = state => state.availability.times
export const selectScrollingDate = state => state.availability.scrollingDate

const buildParamsFor = (duration, participants, date) => {
    return `selected_duration=${duration}&selected_date=${date}&participants=${parsedParticipants(participants)}`
}

// since we are having to work with different params being passed in, we can't assume we are the
// first param or not. if you start args with & the request will blow up.
function paramConnector(url) {
    if (url.match(/\?/)) {
        return '&'
    } else {
        return '?'
    }
}

export function fetchAvailableDaysForDuration(duration, date, participants, url) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        const formattedDate = moment(date).format('YYYY-MM-DD')

        // allow the user to override the url that needs to be set in case
        // it's dynamic i.e. we don't know the package up front
        const targetUrl = url || getState().availability.availableDatesUrl

        const params = buildParamsFor(duration, participants, formattedDate)

        axios.get(`${targetUrl}${paramConnector(targetUrl)}${params}`).then(({ data }) => {
            if (Array.isArray(data) && data.length === 0) {
                if (debug && console) {
                    console.log('Could not find any available dates.')
                }
                dispatch(addAlert({type: 'warning', text: 'Could not find any available dates.'}))
                dispatch(setDates([]))
            } else if (typeof data === 'string') {
                dispatch(addAlert({type: 'warning', text: data}))
            } else {
                dispatch(setDates(data))
            }
        }).catch((error) => {
            if (console) { console.error(error) }
        }).finally(() => {
            dispatch(setLoading(false))
            dispatch(setScrollingDate(formattedDate))
        })
    }
}

export function fetchAvailableTimesForDate(duration, date, participants, url, displayAlert) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        dispatch(setTimes([]))

        const formattedDate = moment(date).format('YYYY-MM-DD')

        // allow the user to override the url that needs to be set in case
        // it's dynamic i.e. we don't know the package up front
        const targetUrl = url || getState().availability.availableTimesUrl

        const params = buildParamsFor(duration, participants, formattedDate)

        axios.get(`${targetUrl}${paramConnector(targetUrl)}${params}`).then(({ data }) => {
            if (Array.isArray(data) && data.length === 0) {
                if (debug && console) { console.log('Could not find any available times.') }
                if (displayAlert) { dispatch(addAlert({ type: 'warning', text: 'Could not find any available times.' })) }
                dispatch(setTimes([]))
            } else {
                dispatch(setTimes(data))
            }
        }).catch((error) => {
            if (console) { console.error(error) }
        }).finally(() => {
            dispatch(setLoading(false))
        })
    }
}

export default availabilitySlice.reducer
