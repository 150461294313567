import React, { useRef, useState } from "react"

export default function LocationReferralsTab({ form, errors, handleManualUpdate }) {
    const inputRef                    = useRef()
    const [isDisabled, setIsDisabled] = useState(true)

    const handleAdd = (e) => {
        if (inputRef.current.value !== '') {
            setIsDisabled(false)
        }

        if (
          /click/i.test(e.type)
          || (/keypress/i.test(e.type) && /enter/i.test(e.key))
        ) {
            e.preventDefault()
            let updated = [...form.referral_types]
            updated.push(inputRef.current.value)
            handleManualUpdate('referral_types', updated)
            inputRef.current.value = ''
            setIsDisabled(true)
        }
    }

    const handleRemove = (targetIndex) => {
        handleManualUpdate('referral_types', form.referral_types.filter((o, i) => i !== targetIndex))
    }

    return !form.referral_types ? '' : <div>
        <div className="pb-3">
            <div className="input-group">
                <input
                    ref={inputRef}
                    type='text'
                    name='referral'
                    autoFocus={true}
                    className='form-control'
                    defaultValue=''
                    onKeyPress={handleAdd}
                />
                <div className="input-group-append">
                    <button
                        type='button'
                        className={`btn ${isDisabled ? 'btn-secondary' : 'btn-primary'}`}
                        disabled={isDisabled}
                        onClick={handleAdd}
                    >
                        Add
                    </button>
                </div>
            </div>
        </div>

        <hr className="mt-4 mb-3" />

        {
            form.referral_types
                .sort((a,b) => a.localeCompare(b))
                .map((referral, index) =>

                <div key={index} className="row py-1">
                    <div className="col-8 col-md-10">
                        { referral }
                    </div>
                    <div className="col-4 col-md-2 text-right">
                        <button
                            type='button'
                            className='btn btn-link text-danger'
                            onClick={() => handleRemove(index)}
                        >
                            Remove
                        </button>
                    </div>
                </div>
            )
        }
    </div>
}
