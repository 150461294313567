import React, { useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { errorsFor } from '../Form/ErrorsHelper'
import FormRow from '../Form/FormRow'
import Input from '../Form/Input'
import RichTextEditor from '../Form/RichTextEditor'

export default function PartySizeMessageFormRow({ handleUpdate, messages }) {
    // form validation
    const formMethods         = useForm()
    const [errors, setErrors] = useState({})

    // are we using the small text input or the full wysiwyg
    const [useFullEditor, setUseFullEditor] = useState(null)

    // the wysiwyg trix object itself so we can programatically control it if needed
    const [editor, setEditor] = useState(null)

    /**
     * Loop through form errors and build up our own errors
     * object that gets passed down to all of the components
     */
    const onError = (formErrors, e) => {
      setErrors(errorsFor(formErrors))
    }

    const handleAdd = (form) => {
        handleUpdate('party_size_selection_messages', messages.concat({
            from: Number.parseInt(form.from),
            to: Number.parseInt(form.to),
            message: form.message
        }))

        // we have to programatically change the values of the editor in order to reset the value... it seems the value prop
        // is only for the initial value of the editor... the editor is being passed in and set via the wysiwyg so we can
        // control it through a callback, setting it to our local state above.
        try {
          if (editor?.element) {
            editor.element.innerText = ''
            editor.element.innerHTML = ''
          }
        } catch (e) {
            console.warn('Not able to reset wysiwyg content...', e)
        }

        // reset registered form values and validation state
        formMethods.setValue('from',    '', { shouldValidate: true })
        formMethods.setValue('to',      '', { shouldValidate: true })
        formMethods.setValue('message', '', { shouldValidate: true })
        setErrors({})
    }

    return (
        <FormProvider {...formMethods}>
            <FormRow>

                <Input
                    cols='2'
                    type='number'
                    min="1"
                    value={formMethods.watch('from') || ''}
                    errors={errors}
                    validation={{ 'from': { required: true }}}
                />

                <Input
                    cols='2'
                    type='number'
                    min="1"
                    value={formMethods.watch('to') || ''}
                    errors={errors}
                    validation={{ 'to': { required: true }}}
                />

                {
                    useFullEditor ? (
                        <RichTextEditor
                            cols='12'
                            label={<span>Message&nbsp;<span className='primary-action' onClick={() => setUseFullEditor(false)}>Use Basic Editor</span></span>}
                            value={formMethods.watch('message') || ''}
                            setEditor={setEditor}
                            errors={errors}
                            validation={{ 'message': { required: true }}}
                        />
                    ) : (
                        <Input
                            cols='6'
                            label={<span>Message&nbsp;<span className='primary-action' onClick={() => setUseFullEditor(true)}>Use Full Editor</span></span>}
                            value={formMethods.watch('message') || ''}
                            errors={errors}
                            validation={{ 'message': { required: true }}}
                        />
                    )
                }


                <div className="col-2">
                    <button
                        type="button"
                        className="btn btn-primary pimary-action text-white"
                        style={useFullEditor ? { fontSize: '11px', margin: '-10px 0 30px' } : { fontSize: '11px', marginTop: '22px', padding: '9px 15px', width: '100%' }}
                        onClick={formMethods.handleSubmit(handleAdd, onError)}
                    >
                        Save Message
                    </button>
                </div>

            </FormRow>
        </FormProvider>
    )
}
