import React, { useEffect, useMemo } from 'react'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'

import {
    selectAmountType,
    selectCustomAmount,
    selectCustomerTypeCounts,
    selectTipAmount,
    selectTipType,
    selectDepositToApply,
    selectCustomersToPayFor,
    switchToCustom,
    setCustomAmount,
} from './bookingPaymentSlice'

import { selectBooking, selectParticipants } from '../EditBooking/editBookingSlice'
import { parsePricingByType, calculatedTip } from '../../lib/Pricing'
import { selectPackage } from '../Packages/packageSlice'
import { selectLocation } from '../Locations/locationSlice'
import { selectCustomerTypes } from '../CustomerTypes/customerTypeSlice'
import { selectSelectedResourceType } from '../ResourceType/resourceTypeSlice'
import BookingLedger from '../Schedule/components/BookingLedger'
import BookingPaymentButton from '@/features/BookingPayment/BookingPaymentButton'
import { evaluateParticipantPricingByGroupType, taxRateAndFeesFor } from '@/lib/Pricing'
import { useConfirm } from '@/lib/useConfirmHook'
import { debug } from '@/lib/Debug'

export default function BookingPaymentLedger() {

    const dispatch           = useDispatch()
    const amountType         = useSelector(selectAmountType)
    const participants       = useSelector(selectParticipants)
    const booking            = useSelector(selectBooking)
    const customerTypeCounts = useSelector(selectCustomerTypeCounts)
    const pkg                = useSelector(selectPackage)
    const location           = useSelector(selectLocation)
    const customerTypes      = useSelector(selectCustomerTypes)
    const customAmount       = useSelector(selectCustomAmount)
    const tip                = useSelector(selectTipAmount)
    const tipType            = useSelector(selectTipType)
    const depositToApply     = useSelector(selectDepositToApply)
    const customersToPayFor  = useSelector(selectCustomersToPayFor)
    const resourceType       = useSelector(selectSelectedResourceType)

    const { confirm } = useConfirm()

    const taxRateAndFees = useMemo(() => (
        taxRateAndFeesFor(location, pkg, booking)
    ), [location, pkg, booking])

    const prices = useMemo(() => (
        parsePricingByType(
            amountType,
            pkg,
            booking,
            customerTypeCounts,
            customerTypes,
            taxRateAndFees.tax_rate,
            taxRateAndFees.rkd_reservation_fee,
            taxRateAndFees.merchant_reservation_fee,
            taxRateAndFees.third_party_reservation_fee,
            location,
            resourceType,
            customersToPayFor,
            customAmount
        )
    ), [amountType, pkg, booking, customerTypeCounts, customerTypes, taxRateAndFees, location, resourceType, customersToPayFor, customAmount])

    const parsedParticipantCounts = useMemo(() => (
        evaluateParticipantPricingByGroupType(
            pkg,
            {
              ...booking,
              start_time: moment.tz(booking.start_time, location.time_zone),
              weekday: moment.tz(booking.start_time, location.time_zone).format('dddd')
            },
            customerTypeCounts,
            customerTypes,
            taxRateAndFees.tax_rate,
            taxRateAndFees.rkd_reservation_fee,
            taxRateAndFees.merchant_reservation_fee,
            taxRateAndFees.third_party_reservation_fee,
            location,
            participants,
            resourceType
        )
    ), [pkg, booking, customerTypeCounts, customerTypes, taxRateAndFees, location, participants, resourceType])

    const paymentCents = Math.round(Number.parseFloat(prices.total) * 100)
    const bookingCents = booking?.balance_cents

    const changeToCustom = async () => {
        if (await confirm(
            '<p>It looks like you are trying to make a payment<br />for more than the full booking balance.</p>\
             <p>Please use a custom amount that is no greater<br />than the full amount instead.</p>',
            {
                size: 'md',
                header_text:  'Payment Amount Notice',
                confirm_only: true,
                confirm_text: 'OK'
            }
        )) {
            // here we set the payment type as custom and put the booking balance cents as the payment amount...
            dispatch(setCustomAmount(null))
            dispatch(switchToCustom(bookingCents / 100))
        }
    }

    useEffect(() => {
        if (paymentCents > bookingCents) {
            changeToCustom()
            if (debug && console) { console.log('BOOKING/PAYMENT DIFF', paymentCents, bookingCents, prices) }
        }
    }, [prices.total])

    return <div className='d-flex flex-column justify-content-between align-items-center h-100'>
        <BookingLedger
            {...prices}
            className='col'
            tip={calculatedTip(prices.basePrice, tip, tipType)}
            depositToApply={depositToApply ? Number.parseFloat(depositToApply).toFixed(2) : null}
            customerTypeTotals={parsedParticipantCounts}
            discounts={booking?.booking_discounts}
            discountTotal={(Number.parseFloat(booking?.discount_cents) / 100).toFixed(2)}
            showLedgerBreakdown={true}
            paymentMode={true}
        />

        <BookingPaymentButton className='d-flex align-items-end mb-3 col' />
    </div>
}
