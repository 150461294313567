import React from 'react'
import FormRow from '../../../components/Form/FormRow'
import Input from '../../../components/Form/Input'
import { useFormContext } from 'react-hook-form'
import { validateUniqueness } from '@/lib/Errors'

export default function MemberInput({ pkg, member, index, errors={}, onChange, onRemove, disabled=false }) {

    const formMethods = useFormContext()
    const key = `member_id_${index}`

    const formKeys = Object.keys(formMethods.getValues()).filter(d => /^member_id_/.test(d) && d !== key)

    const combinedErrors = {
        ...errors,
        [key]: (errors[key] || []).concat((member?.errors && member.errors.length > 0) ? member.errors : [])
    }

    return (
        <FormRow>
            <Input
                name={key}
                type="text"
                prepend={true}
                append={!pkg.allow_members_only}
                hideLabel={true}
                placeholder={false}
                value={member?.memberId || ''}
                validation={{ [key]: {
                    required: true,
                    validate: () => validateUniqueness(formMethods.getValues(formKeys), formMethods.getValues(key)) || 'Please enter a unique ID',
                }}}
                disabled={disabled}
                handleChange={onChange}
                errors={combinedErrors}
            >
                <div className="input-group-prepend">
                    <span className="input-group-text">MEMBER ID</span>
                </div>
                <div className="input-group-append">
                    <button
                        children='Remove'
                        type="button"
                        className="btn btn-danger text-white"
                        disabled={disabled}
                        onClick={onRemove}
                    />
                </div>
            </Input>
        </FormRow>
    )
}
