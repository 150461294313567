import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { parameterize } from 'inflected'

import {
    fetchMenuContent,
    fetchMenuItemContent,
    selectBreadcrumbs,
    selectLoadedItemId,
    selectLoadedMenuPath,
    selectInItemEditMode,
    selectMenuContent,
    selectMenuItem,

    addBreadcrumb,
    resetMenuBreadcrumbs,
    resetMenuContent,
    resetMenuLoadedPath,
    resetMenuItem,
    setCurrentComponent,
    setItemEditMode,
    setLoadedMenuPath,
    setTemporary,
    truncateBreadcrumbs,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import Breadcrumbs from '@/features/AdvancedPointOfSale/components/Breadcrumbs'
import ItemHeader from '@/features/AdvancedPointOfSale/components/menu-item/ItemHeader'
import Modifiers from '@/features/AdvancedPointOfSale/components/menu-item/Modifiers'
import FooterActions from '@/features/AdvancedPointOfSale/components/menu-item/FooterActions'
import { addAlert } from '@/features/Notifications/notificationSlice'
import scrollHinting from '@/lib/ScrollHinting'

export default function MenuItem({
    self='MenuItem',
    onChange=() => {}
}) {
    const dispatch       = useDispatch()
    const menu           = useSelector(selectMenuContent)
    const isLoadingMenu  = useRef(false)
    const inItemEditMode = useSelector(selectInItemEditMode)
    const breadcrumbs    = useSelector(selectBreadcrumbs)
    const itemId         = useSelector(selectLoadedItemId)
    const menuPath       = useSelector(selectLoadedMenuPath)
    const item           = useSelector(selectMenuItem)

    const [isLoading, setIsLoading] = useState(false)

    const handleLoadContentFor = (object) => {
        // requesting to load a specific menu, category, or item
        if (object?.id || object?.menu_uuid)
        {
            switch(String(object.type).toLowerCase()) {
                case 'menu' :
                    isLoadingMenu.current = true

                    dispatch(fetchMenuContent(object)).then(() => {
                        dispatch(resetMenuLoadedPath())
                        dispatch(addBreadcrumb({
                            id: object.id,
                            name: object.name,
                            type: object.type,
                            button_color: object.button_color,
                        }))
                        dispatch(truncateBreadcrumbs(object))
                        dispatch(setCurrentComponent('Menus'))
                    })
                    break

                case 'category' :
                    isLoadingMenu.current = true

                    dispatch(fetchMenuContent(object?.menu_id ? { id: object.menu_id } : menu)).then(() => {
                        if (!!object?.path) {
                            dispatch(setLoadedMenuPath({ action: 'replace', payload: object.path }))
                            dispatch(truncateBreadcrumbs(object))
                            dispatch(setCurrentComponent('Menus'))
                        }
                    })
                    break

                default :
                    dispatch(addAlert({ type: 'error', text: `Could not load content for "${object.type}" type!` }))
            }
        } else {
            // requesting to load "all menus"
            isLoadingMenu.current = true

            dispatch(resetMenuBreadcrumbs())
            dispatch(resetMenuLoadedPath())
            dispatch(setCurrentComponent('Menus'))
        }
    }

    // load data/dependencies if necessary
    useEffect(() => {
        if (isLoadingMenu.current === false && !isLoading) {
            setIsLoading(true)

            dispatch(fetchMenuItemContent(itemId)).finally(() => {
                scrollHinting.search()
                setIsLoading(false)
            })
        }

        return () => {
            dispatch(resetMenuItem())
            dispatch(setItemEditMode(false))
            dispatch(setTemporary({}))

            if (isLoadingMenu.current === false) {
                dispatch(resetMenuBreadcrumbs())
                dispatch(resetMenuLoadedPath())
                dispatch(resetMenuContent())
            }
        }
    }, [itemId, menuPath])

    // handle updating breadcrumbs
    useEffect(() => {
        if (!breadcrumbs) { return }

        if (inItemEditMode) {
            onChange(
                <Breadcrumbs
                    id='advanced-pos--terminal-navigation--breadcrumbs'
                    data={[
                        { id: item.id, name: item.name, type: item.type, button_color: item.button_color },
                        { id: null, name: 'Edit', type: null },
                    ]}
                />
            )
        } else {
            onChange(
                <Breadcrumbs
                    id='advanced-pos--terminal-navigation--breadcrumbs'
                    data={breadcrumbs}
                    onClick={handleLoadContentFor}
                />
            )
        }
    }, [breadcrumbs, inItemEditMode])

    return !item ? null : (
        <div id={`advanced-pos-terminal--${parameterize(self)}`} className='py-2 px-4 my-xl-3 mx-xl-3'>
            <div id={`advanced-pos-terminal--${parameterize(self)}--header`} className='pb-3'>
                <ItemHeader />
            </div>

            <div id={`advanced-pos-terminal--${parameterize(self)}--modifiers`} className='scrollhint'>
                <div className='scrollhint--inner'>
                    <Modifiers />
                </div>
            </div>

            <div id={`advanced-pos-terminal--${parameterize(self)}--footer`}>
                <FooterActions />
            </div>
        </div>
    )
}
