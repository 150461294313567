import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import Select from '@/components/Form/Select'
import NameInput from '@/features/AdvancedPointOfSale/components/forms/NameInput'
import { PREVENT_LOADER } from '@/lib/Storage'

export default function NewNamedCheck({
    resourceTypeText=null,
    optionsToGenerate=100,
}) {

    const { watch, setValue, trigger, clearErrors, reset } = useFormContext()

    const selection = watch('selection')
    const isCustom  = selection === 'custom_name'

    // Generate "Name <num>" style options
    const resourceTypes = [...Array(optionsToGenerate).keys()].map((n) => `${resourceTypeText} ${n + 1}`.trim())

    const handleSelectChange = (e) => {
        const value = e.target.value

        if (value === 'custom_name') {
            setValue('check_name', '')
        } else {
            setValue('check_name', value)
            trigger('check_name')
        }
    }

    const handleEnter = () => {
        document.getElementById('create-named-check-submit-button').click()
    }

    useEffect(() => {
        window.sessionStorage.setItem(PREVENT_LOADER, true)

        return () => {
            window.sessionStorage.removeItem(PREVENT_LOADER)
            reset()
        }
    }, [])

    return (
        <div className='p-2 row'>
            {
                !!resourceTypeText && (
                    <div className={isCustom ? 'col-5 pr-0' : 'col-12'}>
                        <Select
                            includeBlank='Please choose...'
                            options={[
                                ...[{ value: 'custom_name', display: 'Custom Name' }],
                                ...resourceTypes.map((rt) => ({ value: rt, display: rt }))
                            ]}
                            value={selection}
                            validation={{ 'selection': { required: false } }}
                            withWrapper={false}
                            req={false}
                            hideLabel
                            handleChange={handleSelectChange}
                        />
                    </div>
                )
            }

            <div className={isCustom ? 'col-7' : 'col-12'}>
                <NameInput
                    className='px-1'
                    inputContainerClassName='w-100'
                    field='check_name'
                    type={(!!!resourceTypeText || isCustom) ? 'text' : 'hidden'}
                    placeholder=' '
                    autoComplete='chrome-off'
                    autoFocus
                    role='presentation'
                    withWrapper={false}
                    onFocus={() => clearErrors('check_name')}
                    onEnter={handleEnter}
                />
            </div>
        </div>
    )
}
