import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { setNewPackage, setTempPackageId } from '@/features/Packages/packageSlice'
import { setBooking, setNewBooking } from '@/features/EditBooking/editBookingSlice'
import { setOpen, setStep, setNewSchedule } from '@/features/Schedule/scheduleSlice'
import { removeCustomerFromBooking } from '@/features/Customers/customerSlice'
import { addAlert } from '@/features/Notifications/notificationSlice'
import {
    selectParentBookingResources,
    selectParentBooking,
    selectChildBookings,
    setChildBookings,
    resetParentBooking
} from '@/features/ParentBooking/parentBookingSlice'
import BasicInfo from '@/features/ParentBooking/GroupDetails/BasicInfo'
import ChildBookings from '@/features/ParentBooking/GroupDetails/ChildBookings'
import LeagueParticipantHistory from '@/features/ParentBooking/GroupDetails/LeagueParticipantHistory'
import ParticipantsList from '@/features/ParentBooking/GroupDetails/ParticipantsList'
import { isBookingComplete } from '@/lib/Booking'
import { useConfirm } from '@/lib/useConfirmHook'
import { accessToken } from '@/lib/Csrf'

export default function ParentBookingGroupDetailsTab({ immutableEvent, expandedParticipants, onToggleExpandedParticipants, onClose }) {

    const dispatch                = useDispatch()
    const { confirm }             = useConfirm()
    const parentBookingResources  = useSelector(selectParentBookingResources)
    const parentBooking           = useSelector(selectParentBooking)
    const childBookings           = useSelector(selectChildBookings)

    const [checkedInCount,   setCheckedInCount]   = useState(0)
    const [participantCount, setParticipantCount] = useState(0)

    const waiversRequired = parentBooking?.resource_type?.waivers_required

    const handleChildBookingClick = (cb) => {
        dispatch(setBooking({ booking: cb, resources: parentBookingResources }))
        dispatch(resetParentBooking())
        dispatch(setOpen(true))
        dispatch(setStep('3'))
    }

    const handleRemoveParticipant = async (customerId, bookingId) => {
        if (!(await confirm('Are you sure you want to remove<br />this customer from the booking?'))) {
            return
        }

        dispatch(removeCustomerFromBooking(customerId, bookingId, parentBooking.id))
    }

    const handleCustomerCheckin = (e, cb, c) => {
        axios.post(`/bookings/${cb.id}/handle_customer_checkin/${c.id}?check_in=${e.target.checked}`, {
            authenticity_token: accessToken
        }).then(({ data }) => {
            if (data.success) {
                return dispatch(setChildBookings({ childBookings: data.childBookings }))
            }

            dispatch(addAlert({ type: 'error', text: data.message }))
        }).catch(e => {
            console.warn(e)
            dispatch(addAlert({ type: 'error', text: 'Could not check the customer in!' }))
        })
    }

    const handleNewBooking = () => {
        dispatch(setNewBooking())
        dispatch(setNewPackage())
        dispatch(setNewSchedule())
        dispatch(setStep('1'))
        dispatch(setOpen(true))
        dispatch(setTempPackageId(parentBooking.package_id))
        onClose()
    }

    useEffect(() => {
        if (Array.isArray(childBookings)) {
            let checked_in_count  = 0
            let participant_count = 0

            childBookings.forEach(cb => {
                checked_in_count  += cb.checked_in.length
                participant_count += parseInt((cb.group_max || cb.participants), 10)
            })

            setCheckedInCount(checked_in_count)
            setParticipantCount(participant_count)
        }
    }, [childBookings])

    return (
        <>
            <div className="modal-body">
                <div className="modal-body-main">
                  <BasicInfo />

                  <div className="modal-body-main-section">
                      {
                          isBookingComplete(parentBooking) && parentBooking.package.is_a_league ? (
                              <LeagueParticipantHistory />
                          ) : <>
                              <ChildBookings
                                  immutableEvent={immutableEvent}
                                  waiversRequired={waiversRequired}
                                  expandedParticipants={expandedParticipants}
                                  onChildBookingClick={handleChildBookingClick}
                                  onCustomerCheckIn={handleCustomerCheckin}
                                  onRemoveParticipant={handleRemoveParticipant}
                                  onToggleExpandedParticipants={onToggleExpandedParticipants}
                              />
                          </>
                      }
                  </div>
              </div>

              {
                  waiversRequired && !!participantCount && !isBookingComplete(parentBooking) && (
                      <div className="modal-body-sidebar">
                          <div className="modal-body-sidebar-inner pt-2">
                              <ParticipantsList
                                  checkedInCount={checkedInCount}
                                  participantCount={participantCount}
                                  immutableEvent={immutableEvent}
                                  onCustomerCheckIn={handleCustomerCheckin}
                                  onRemoveParticipant={handleRemoveParticipant}
                              />
                          </div>
                      </div>
                  )
              }
            </div>

            <div className="modal-footer">
                {
                    !immutableEvent && !parentBooking.package.special_event_is_sold_out && (
                        <button className="group-reservation-new-event text-white" onClick={handleNewBooking}>
                            <i className="far fa-plus" />
                        </button>
                    )
                }

                <button className="btn btn-link-danger ml-auto" onClick={onClose}>
                    <span>Close</span>
                </button>
            </div>
        </>
    )
}
