import React from 'react'

export default function DrawerActions({
    close,
    save,
    saveAndClose,
    disabledSaveButton=false,
    showSave=false,
    showSaveAndNew=false,
    showSaveAndClose=true
}) {
    return (
        <div className="actions">
            <button
                type="button"
                className="close-drawer btn btn-link btn-link-danger mr-auto close"
                onClick={close}
            >
                Close
            </button>


            { showSave && (
                <button
                    type="button"
                    className="btn btn-outline mr-2"
                    disabled={disabledSaveButton}
                    onClick={save}
                >
                    Save
                </button>
            )}

            { showSaveAndNew && (
                <button
                    type="button"
                    className="btn btn-outline mr-2"
                    disabled={disabledSaveButton}
                    onClick={() => {
                        localStorage.setItem('drawerOpen', true)
                        saveAndClose()
                    }}
                >
                    Save &amp; New
                </button>
            )}

            { showSaveAndClose && (
                <button
                    type="button"
                    className="btn btn-primary"
                    value="Save"
                    disabled={disabledSaveButton}
                    onClick={saveAndClose}
                >
                    Save &amp; Close
                </button>
            )}
        </div>
    )
}
