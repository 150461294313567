import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { selectTime, setTime } from '../../bookingSlice'
import { selectTimeZone } from '../../../Locations/locationSlice'

export default function Time({ time }) {

    // TODO dispatch to setTime
    const dispatch = useDispatch()

    const selectedTime = useSelector(selectTime)
    const selectedTimeZone = useSelector(selectTimeZone)

    // payload is in the form of [<iso stamp>, <available true/false>]
    const value = time[0]
    const available = time[1]

    return (
        <label className={`radio-as-card col-3 ${available ? '' : 'disabled'}`}>
            <input
                disabled={!available}
                type="radio"
                name="duration"
                value={value}
                checked={value === selectedTime}
                onChange={() => dispatch(setTime(value))} />

            <div className="radio-card">
                {moment(value).tz(selectedTimeZone).format('LT')}
            </div>

        </label>
    )
}
