import { pluralize, humanize } from 'inflected'

export function customerVerbiage(amount, resourceType={}, allowMembersOnly=false) {
  const useDynamic = !Boolean(resourceType?.customer_verbiage_plural)
                     || (resourceType?.customer_verbiage_singular?.toLowerCase() == resourceType?.customer_verbiage_plural?.toLowerCase())

  const singular = allowMembersOnly ? 'Member'  : resourceType?.customer_verbiage_singular
  const plural   = allowMembersOnly ? 'Members' : resourceType?.customer_verbiage_plural

  return String(amount) === '1' ? humanize(singular) : (useDynamic ? humanize(pluralize(singular)) : humanize(plural))
}

export function resourceVerbiage(amount, resourceType={}) {
  const useDynamic = !Boolean(resourceType?.plural_name)
                     || (resourceType?.singular_name?.toLowerCase() == resourceType?.plural_name?.toLowerCase())

  const singular = resourceType?.singular_name || 'Lane'
  const plural   = resourceType?.plural_name   || 'Lanes'

  return String(amount) === '1' ? humanize(singular) : (useDynamic ? humanize(pluralize(singular)) : humanize(plural))
}

export function formatParticipants(participants=null) {
  if (!participants || !participants?.includes("-")) { return participants }

  const range = participants.split("-")

  return (range[0] === range[1]) ? range[0] : participants
}
