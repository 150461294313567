import React, { useState } from 'react'
import WaiverSetupTab from './WaiverSetupTab'
import OptionalFields from './OptionalFields'
import WaiverTabs from './WaiverTabs'

export default function WaiverForm({
  form,
  errors,
  statuses = [],
  locations = [],
  handleUpdate,
  handleManualUpdate,
  handleCheckboxUpdate,
}) {
  const [tab, setTab] = useState('setup')

  const renderTab = () => {
    switch (tab) {
      case 'setup':
        return (
          <WaiverSetupTab
            form={form}
            errors={errors}
            statuses={statuses}
            locations={locations}
            handleUpdate={handleUpdate}
            handleManualUpdate={handleManualUpdate}
            handleCheckboxUpdate={handleCheckboxUpdate}
          />
        )
      case 'optional_fields':
        return (
          <OptionalFields
            form={form}
            handleCheckboxUpdate={handleCheckboxUpdate}
          />
        )
    }
  }

  return (
    <div>
      <WaiverTabs
        handleClick={tab => setTab(tab)}
        activeTab={tab}
        form={form}
      />

      {renderTab()}
    </div>
  )
}
