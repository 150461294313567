import React, { useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'
import Input from '../Form/Input'
import NumberIncrementer from '../Form/NumberIncrementer'
import Pricing from '../../lib/Pricing'

export default function DurationsFields({
  durations,
  pricing,
  selectedCustomerTypes,
  pricingType,
  errors=[],
  isSpecialEvent=false,
  handleUpdate
}) {
    const { register } = useFormContext()

    const name = 'durations'

    const field = register(name, {
        validate: {
            atLeastOneTime: () => {
                return durations.length >= 1 || 'Must have at least one duration.'
            },
            greaterThanZeroMinutes: () => {
                let empty_durations = durations.filter((d) => d.duration === 0)
                return empty_durations.length === 0 || `${isSpecialEvent ? 'The' : 'Every'} duration must be longer than 0 minutes.`
            }
        }
    })

    const [removeFromPrices, setRemoveFromPrices] = useState(false)
    const [editPrices, setEditPrices] = useState(false)

    const [duration, setDuration] = useState()
    const [newDuration, setNewDuration] = useState(null)

    useEffect(() => {
        if (removeFromPrices) {
            removeDurationFromPriceGrid()
        }

        if (editPrices) {
            editDurationInPriceGrid()
        }
    }, [durations])

    const removeDurationFromPriceGrid = () => {
        setRemoveFromPrices(false)

        handleUpdate('pricing', pricing.map(group => {
            if (group.individualPricing && pricingType === Pricing.BY_CUSTOMER_TYPE) {
                // filter group -> prices -> customer type -> duration objects
                return {
                    ...group,
                    prices: group.prices.map(customerTypeDurationPrices => {
                        // for each customer type, the customer type array's objects should only be 'other' durations
                        return customerTypeDurationPrices.filter(p => p._durationId !== duration._id)
                    }).filter(p => p.length)
                }
            } else {
                // filter group -> prices -> duration objects
                return {
                    ...group,
                    prices: group.prices.filter(p => p._durationId !== duration._id)
                }
            }
        }))

        setDuration(null)
    }

    const editDurationInPriceGrid = () => {
        setEditPrices(false)

        handleUpdate('pricing', pricing.map(group => {
            if (group.individualPricing && pricingType === Pricing.BY_CUSTOMER_TYPE) {
                // group -> prices -> customer type -> durations
                if (group.prices.length) {
                    return {
                        ...group,
                        // TODO check to see if prices is empty first -- if it is we have to loop over each selected customer type
                        // and make an entry for the newDuration field
                        prices: group.prices.map(customerTypeDurationPrices => {
                            /**
                             * What is happening here is we have to first check whether or not the new duration
                             * exists inside of the customer type durations. This happens because we have to determine
                             * whether we are changing an existing value (changing the dropdown), or adding one that doesn't
                             * yet exist, therefore that duration would not be found, and no entry would be made into the
                             * pricing grid.
                             */
                            if (customerTypeDurationPrices.map(p => p._durationId).includes(duration._id)) {
                                return customerTypeDurationPrices.map(p => {
                                    if (p._durationId === duration._id) {
                                        return {
                                            ...p,
                                            duration: newDuration
                                        }
                                    } else {
                                        return p
                                    }
                                })
                            } else {
                                const customerType = customerTypeDurationPrices[0].customerType

                                return customerTypeDurationPrices.concat({
                                    customerType: customerType,
                                    duration:     newDuration,
                                    price:        null,
                                    pricePeak:    null,
                                    _durationId:  duration._id
                                })
                            }
                        })
                    }
                } else {
                    return {
                        ...group,
                        prices: selectedCustomerTypes.map(customerType => {
                            return [{
                                customerType: customerType,
                                duration:     newDuration,
                                price:        null,
                                pricePeak:    null,
                                _durationId:  duration._id
                            }]
                        })
                    }
                }
            } else {
                // group -> prices -> durations
                if (group.prices.length) {
                    if (group.prices.map(p => p._durationId).includes(duration._id)) {
                        return {
                            ...group,
                            prices: group.prices.map(p => {
                                if (p._durationId === duration._id) {
                                    return {
                                        ...p,
                                        duration: newDuration
                                    }
                                } else {
                                    return p
                                }
                            })
                        }
                    } else {
                        return {
                            ...group,
                            prices: group.prices.concat({
                                duration:    newDuration,
                                price:       null,
                                prcePeak:    null,
                                _durationId: duration._id
                            })
                        }
                    }
                } else {
                    return {
                        ...group,
                        prices: group.prices.concat({
                            duration:    newDuration,
                            price:       null,
                            pricePeak:   null,
                            _durationId: duration._id
                        })
                    }
                }
            }
        }))

        setDuration(null)
        setNewDuration(null)
    }

    const handleOptionChange = (e, index, skipPrice) => {
        if (!skipPrice) {
            setDuration(durations[index])
            setNewDuration(e.target.value)
            setEditPrices(true)
        }

        handleUpdate('durations', durations.map((d, i) => {
            if (index === i) {
                return { ...d, [e.target.name]: e.target.value }
            } else {
                return d
            }
        }))
    }

    const handleCheckboxOptionChange = (e, index) => {
        handleUpdate('durations', durations.map((d, i) => {
            if (index === i) {
                return { ...d, [e.target.name]: !d[e.target.name] }
            } else {
                return d
            }
        }))
    }

    const handleRemove = index => {
        setDuration(durations[index])
        setRemoveFromPrices(true)
        handleUpdate('durations', durations.filter((d, i) => i !== index))
    }

    const changeDuration = (duration, amount, index, skipPrice) => {
        if (duration.duration === 0 && amount < 0) {
            return
        }

        handleOptionChange(
            {
                target: {
                    name: 'duration',
                    value: amount
                }
            },
            index,
            skipPrice
        )
    }

    return (
        <>
            { durations.map((d, index) => (
                <div className="drawer-add-row py-3" key={uuidv4()}>
                    <div className="form-row">

                        <div className="col-4">
                            <div className="increment increment-inline">
                                <NumberIncrementer
                                    value={d.duration}
                                    incrementAmount={15}
                                    preventTyping={true}
                                    handleChange={amount => changeDuration(d, amount, index, false)}
                                />
                                <label className="pt-1 pl-3">Minutes</label>
                            </div>
                        </div>

                        <div className="col offset-2 align-self-center">
                            <div className="custom-control custom-checkbox">
                                {
                                    !isSpecialEvent && <>
                                        <input
                                            id={`durations-internal-${index}`}
                                            className='custom-control-input'
                                            name='internal'
                                            type='checkbox'
                                            value={!!d.internal}
                                            checked={!!d.internal}
                                            onChange={e => handleCheckboxOptionChange(e, index)}
                                        />

                                        <label htmlFor={`durations-internal-${index}`} className='custom-control-label'>
                                            For Internal Use Only
                                        </label>
                                    </>
                                }
                            </div>
                        </div>

                        {
                            !isSpecialEvent && (
                                <div className="col-2 offset-1 text-right">
                                    <button
                                        type='button'
                                        className='btn btn-danger text-white'
                                        onClick={() => handleRemove(index)}
                                    >
                                        Remove
                                    </button>
                                </div>
                            )
                        }
                    </div>
                </div>
            ))}

            {
                durations.length == 0 && (
                    <small className='d-block text-muted pt-3'>No Durations</small>
                )
            }

            {
                errors[name] && (
                    <div className="invalid-feedback d-block pt-2">
                        { errors[name].join(', ') }
                    </div>
                )
            }
        </>
    )
}
