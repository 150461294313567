import React from "react";
import Input from "../Form/Input";
import CustomerWaiversBirthday from "./CustomerWaiversBirthday";
import CustomerWaiversGender from "./CustomerWaiversGender";
import {validationPatterns} from "../../lib/Errors";
import {useFormContext} from "react-hook-form";

export default function CustomerWaiversNameDOBZip({
  additional_minor = false,
  adult_or_minor,
  handleDelete,
  errors,
  index,
  isReturning,
  customer,
  waiver,
}) {
  const formMethods = useFormContext()

  return (
    <div>
      {additional_minor === true ? (
        <div id='name_dob_address'>
          <div className='waiver-item waiver_name_label'>
            <div className='d-inline'>
              {adult_or_minor}'S NAME
            </div>

            <div className='d-inline delete_additional_minor_wrapper mt-n2'>
              <button type='button' className='btn btn-outline-primary btn-sm delete_additional_minor' onClick={handleDelete}>
                <i className="fas fa-trash-alt"/>
              </button>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6'>
              <Input
                name={`first_name-${index}`}
                placeholder='First Name'
                hideLabel={true}
                value={formMethods.watch(`first_name-${index}`, '')}
                handleChange={(e) => {
                    formMethods.setValue(`first_name-${index}`, String(e.target.value).trim())
                }}
                errors={errors}
                validation={{ [`first_name-${index}`]: {
                  required: true,
                  pattern: validationPatterns.trimmedText
                }}}
              />
            </div>
            <div className='col-md-6'>
              <Input
                name={`last_name-${index}`}
                placeholder='Last Name'
                hideLabel={true}
                value={formMethods.watch(`last_name-${index}`, '')}
                handleChange={(e) => {
                    formMethods.setValue(`last_name-${index}`, String(e.target.value).trim())
                }}
                errors={errors}
                validation={{ [`last_name-${index}`]: {
                  required: true,
                  pattern: validationPatterns.trimmedText
                }}}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6'>
              <div className='waiver-item'>
                {adult_or_minor}'S BIRTHDAY
              </div>

              <CustomerWaiversBirthday
                additional_minor={additional_minor}
                field='dob'
                errors={errors}
                index={index}
                isReturning={isReturning}
                customer={customer}
              />
            </div>

            { waiver.zip_code &&
            <div className='col-md-6'>
              <div className='waiver-item'>
                {adult_or_minor}'S HOME ZIP CODE
              </div>

              <Input
                name={`zip_code-${index}`}
                placeholder='ZIP/Postal'
                mask="99999"
                maskPlaceholder={null}
                autoComplete="postal-code"
                hideLabel={true}
                value={formMethods.watch(`zip_code-${index}`, '')}
                errors={errors}
                validation={{ [`zip_code-${index}`]: {
                    required: waiver.zip_code,
                    pattern: validationPatterns.zip,
                  }}} />
            </div>
            }

            { waiver.gender &&
            <div className='col-lg-6 gender_selection'>
              <div id="minor_gender">
                <CustomerWaiversGender
                  additional_minor={additional_minor}
                  name="minor_gender"
                  adult_or_minor={adult_or_minor}
                  errors={errors}
                  index={index}
                  waiver={waiver}
                />
              </div>
            </div>
            }

          </div>
        </div>
      ) : (
        <div id='name_dob_address'>
          <div className='waiver-item waiver_name_label'>
            <div className='d-inline'>
              {adult_or_minor}'S NAME
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6'>
              <Input
                name='first_name'
                placeholder='First Name'
                hideLabel={true}
                value={formMethods.watch("first_name", '')}
                handleChange={(e) => {
                    formMethods.setValue('first_name', String(e.target.value).trim())
                }}
                errors={errors}
                validation={{ 'first_name': {
                  required: true,
                  pattern: validationPatterns.trimmedText
                }}}
              />
            </div>
            <div className='col-md-6'>
              <Input
                name='last_name'
                placeholder='Last Name'
                hideLabel={true}
                value={formMethods.watch("last_name", '')}
                handleChange={(e) => {
                    formMethods.setValue('last_name', String(e.target.value).trim())
                }}
                errors={errors}
                validation={{ 'last_name': {
                  required: true,
                  pattern: validationPatterns.trimmedText
                }}}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6 flex-column justify-content-end'>
              <div className='waiver-item'>
                {adult_or_minor}'S BIRTHDAY
              </div>

              <CustomerWaiversBirthday
                additional_minor={additional_minor}
                field='dob'
                errors={errors}
                isReturning={isReturning}
                customer={customer}
              />
            </div>

            { waiver.zip_code &&
            <div className='col-md-6'>
              <div className='waiver-item'>
                {adult_or_minor}'S HOME ZIP CODE
              </div>

              <Input
                name='zip_code'
                placeholder='ZIP/Postal'
                mask="99999"
                maskPlaceholder={null}
                autoComplete="postal-code"
                hideLabel={true}
                value={formMethods.watch("zip_code", '')}
                errors={errors}
                validation={{ 'zip_code': {
                    required: waiver.zip_code,
                    pattern: validationPatterns.zip,
                  }}} />
            </div>
            }

            { waiver.gender &&
            <div className='col-lg-6 gender_selection'>
              {adult_or_minor === 'MINOR' ? (
                <div id="minor_gender">
                  <CustomerWaiversGender
                    name="minor_gender"
                    adult_or_minor={adult_or_minor}
                    errors={errors}
                    waiver={waiver}
                  />
                </div>
              ) : (
                <div id="member_gender">
                  <CustomerWaiversGender
                    name="adult_gender"
                    adult_or_minor={adult_or_minor}
                    errors={errors}
                    waiver={waiver}
                  />
                </div>
              )}
            </div>
            }

          </div>
        </div>
      )}
    </div>
  )
}
