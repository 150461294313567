import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectProcessing as selectTerminalProcessing } from '@/features/Adyen/adyenSlice'
import { selectLoading as selectProcessingPayment, selectTotal, selectCreditMethod, selectPaymentType } from '@/features/Members/memberSlice'
import CancelButton from '@/features/Utilities/CancelButton'
import { numberToCurrency } from '@/lib/Number'

export default function PaymentProcessing() {

    const paymentType        = useSelector(selectPaymentType)
    const creditMethod       = useSelector(selectCreditMethod)
    const processingTerminal = useSelector(selectTerminalProcessing)
    const processingOther    = useSelector(selectProcessingPayment)
    const amountToPay        = useSelector(selectTotal)

    const isProcessing = useMemo(() => (
        processingTerminal || processingOther
    ), [processingTerminal, processingOther])

    return isProcessing && (
        <div id="global-loading">
            <div id="global-loading--content">
                <div className="spinner">
                    <div className="rect1" />
                    <div className="rect2" />
                    <div className="rect3" />
                    <div className="rect4" />
                    <div className="rect5" />
                    <div className="rect6" />
                </div>

                <div className='text-center'>
                    Payment Processing<br />
                   { Number(amountToPay) === 0 ? 'Complete' : numberToCurrency(amountToPay) }
                </div>

                {
                    paymentType === 'credit' && creditMethod === 'terminal' && processingTerminal && (
                        <CancelButton
                            appendedButtonText='Transaction'
                            paymentProcessor='adyen'
                            shouldCancelTerminalTransaction
                        />
                    )
                }
            </div>
        </div>
    )
}
