import React, { useState, useEffect } from 'react'
import FormRow from '@/components/Form/FormRow'
import MultiSelectCheckboxes from '@/components/Form/MultiSelectCheckboxes'
import ExternalSelectedList from '@/components/AdvancedPointOfSale/ExternalSelectedList'
import { pluckKeyFromObjectsInArray } from '@/lib/Object'
import { sortedByArray } from '@/lib/Array'
import { debug } from '@/lib/Debug'

export default function ItemModifiers({
  form,
  errors,
  new: newItem,
  modifierGroupsWithModifiersOptions=[],
  handleFormUpdate,
  handleManualUpdate,
  defaultModifiersHasChanged=() => {},
}) {

  const [defaultModifiers, setDefaultModifiers]             = useState([])
  const [selectedModifierGroups, setSelectedModifierGroups] = useState([])

  const handleModifierGroupsUpdate = (item_modifier_groups_attributes, selectedModifierGroup=null, isRemove=false) => {
    const modifier_groups_sort_order = pluckKeyFromObjectsInArray(item_modifier_groups_attributes, 'modifier_group_id')
    let copy

    if (isRemove) {
      copy = [...item_modifier_groups_attributes, { id: selectedModifierGroup.id, _destroy: '1' /* true */ }]
    } else {
      copy = [...item_modifier_groups_attributes]
      copy = copy.map(c => {
        if (c.modifier_group_id === selectedModifierGroup.modifier_group_id) {
          return {
            ...c,
            modifier_ids: c.collection.map(i => i.id),
            collection: c.collection.map(i => ({ ...i, is_visible: true }))
          }
        } else {
          return c
        }
      })
    }

    const previouslyRemovedItems = form.item_modifier_groups_attributes.filter(e => e['_destroy'])

    if (previouslyRemovedItems.length > 0) {
      copy.push(...previouslyRemovedItems)
    }

    handleFormUpdate({ ...form, ...{
      item_modifier_groups_attributes: copy,
      modifier_groups_sort_order
    }})
  }

  const handleSubItemsDefaultChange = ({ parent, itemId }) => {
    const selectedItemModifierGroup = form.item_modifier_groups_attributes.find(item_modifier_group => {
      return item_modifier_group.modifier_group_id === parent.modifier_group_id
    })

    const isItemDefault = selectedItemModifierGroup.default_modifier_ids?.includes(itemId) || false

    updateDefaultModifier(parent.modifier_group_id, !isItemDefault, itemId)
  }

  const updateDefaultModifier = (modifierGroupId, isDefault, modifierId) => {
    const copy        = [...form.item_modifier_groups_attributes]
    const parentIndex = copy.findIndex(obj => obj.modifier_group_id === modifierGroupId)
    const childIndex  = copy[parentIndex].collection.findIndex(obj => obj.id === modifierId)

    copy[parentIndex].collection[childIndex] = {
      ...copy[parentIndex].collection[childIndex],
      is_default: isDefault,
    }

    copy[parentIndex].default_modifier_ids = isDefault
      ? [...(copy[parentIndex].default_modifier_ids || []), modifierId]
      : copy[parentIndex].default_modifier_ids.filter(id => id !== modifierId)

    handleManualUpdate('item_modifier_groups_attributes', copy)
  }

  const handleSubItemsVisibilityChange = ({ parent, itemId, isVisible }) => {
    const copy        = [...form.item_modifier_groups_attributes]
    const parentIndex = copy.findIndex(obj => obj.modifier_group_id === parent.modifier_group_id)
    const childIndex  = copy[parentIndex].collection.findIndex(obj => obj.id === itemId)

    copy[parentIndex].collection[childIndex].is_visible = isVisible

    if (!isVisible) {
      copy[parentIndex].collection[childIndex].is_default = false

      const modifierIdIndex = copy[parentIndex].modifier_ids.indexOf(itemId)
      copy[parentIndex].modifier_ids.splice(modifierIdIndex, 1)

      const defaultIdIndex = copy[parentIndex]?.default_modifier_ids?.indexOf(itemId)
      copy[parentIndex]?.default_modifier_ids?.splice(defaultIdIndex, 1)
    } else {
      copy[parentIndex].modifier_ids = [...(copy[parentIndex].modifier_ids || []), itemId]
    }

    handleManualUpdate('item_modifier_groups_attributes', copy)
  }

  const handleLimitUpdate = (limit, modifierGroup) => {
    const copy        = [...form.item_modifier_groups_attributes]
    const parentIndex = copy.findIndex(obj => obj.modifier_group_id === modifierGroup.modifier_group_id)

    copy[parentIndex].max_quantity = parseInt(limit)

    handleManualUpdate('item_modifier_groups_attributes', copy)
  }

  const handleSubItemsSortUpdate = (parentId, collection) => {
    const collection_sort_order = pluckKeyFromObjectsInArray(collection, 'id')

    const copy        = [...form.item_modifier_groups_attributes]
    const parentIndex = copy.findIndex(obj => obj.modifier_group_id === parentId)

    copy[parentIndex].collection            = collection
    copy[parentIndex].collection_sort_order = collection_sort_order // used by UI/JS, ignored by Rails
    copy[parentIndex].modifiers_sort_order  = collection_sort_order // stored by Rails, ignored by UI/JS

    handleManualUpdate('item_modifier_groups_attributes', copy)
  }

  /*
   * Update modifier groups list whenever one is deleted and
   * mark the old item as "to be deleted" with _destroy = 1/true
   */
  useEffect(() => {
    setSelectedModifierGroups(
      form.item_modifier_groups_attributes.filter(modifierGroup => !modifierGroup._destroy)
    )
  }, [form.item_modifier_groups_attributes])

  /*
   * Mirror items that are flagged as defaults
   * into the "selected default modifiers" list
   * whenever data changes (load or via user input)
   */
  useEffect(() => {
    const collection = []

    form.item_modifier_groups_attributes?.map(itemModifierGroup => {
      itemModifierGroup.collection?.map(item => {
        if (itemModifierGroup.default_modifier_ids?.includes(item.id) && item.is_visible) {
          collection.push({
            modifierGroupId: itemModifierGroup.modifier_group_id,
            modifierGroupName: itemModifierGroup.name,
            modifierId: item.id,
            modifierName: item.name,
            modifierAmountCents: item.amount_cents,
          })
        }
      })
    })

    setDefaultModifiers(collection)
    defaultModifiersHasChanged(collection)
  }, [form.item_modifier_groups_attributes])

  return (
    <div>
      <FormRow>
        <MultiSelectCheckboxes
          label="Modifier Groups"
          options={modifierGroupsWithModifiersOptions}
          selectedValues={sortedByArray(selectedModifierGroups, form.modifier_groups_sort_order, 'modifier_group_id')}
          useExternalList={true}
          externalListItemsParentIdKey='modifier_group_id'
          externalListIsSortable={true}
          externalListSubMenuIsSortable={true}
          externalListCanLimitSubMenuItems={true}
          externalListSubMenuIsDefaultIconClass={debug ? 'fa-space-station-moon-alt' : 'fa-clipboard-check'}
          externalListCanLimitFieldName="max_quantity"
          emptyRecordMsg='No modifier groups available.'
          errors={errors}
          onSelect={handleModifierGroupsUpdate}
          onRemove={handleModifierGroupsUpdate}
          onSort={handleModifierGroupsUpdate}
          onLimitUpdate={handleLimitUpdate}
          onSubItemsSort={handleSubItemsSortUpdate}
          onSubItemsIconChange={handleSubItemsDefaultChange}
          onSubItemsVisibilityChange={handleSubItemsVisibilityChange}
        />
      </FormRow>

      <ExternalSelectedList
        sectionTitle='Selected Default Modifiers'
        collection={sortedByArray(defaultModifiers, form.modifier_groups_sort_order, 'modifierGroupId')}
        schema={{
            parentId: 'modifierGroupId',
            parentName: 'modifierGroupName',
            childId: 'modifierId',
            childName: 'modifierName',
            childAmountCents: 'modifierAmountCents',
        }}
        onRemove={updateDefaultModifier}
      />
    </div>
  )
}
