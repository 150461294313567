import React from 'react'
import FormRow from "../Form/FormRow"
import Input from "../Form/Input"

export default function TerminalForm({
  form,
  errors,
  handleUpdate
}) {
  return (
    <div>
      <FormRow>
        <Input
          value={form.name}
          cols={12}
          errors={errors}
          validation={{ 'name': { required: true }}}
          handleChange={handleUpdate}
        />
      </FormRow>
    </div>
  )
}
