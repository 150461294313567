import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'

import {
    selectLoading,
    selectIsValidAmount,
    selectTab,
    selectCashAmount,
    selectCheckAmount,
    selectAmountToPay,
    submitPayment,
} from '@/features/BookingPayment/bookingPaymentSlice'

import { selectBookingId } from '@/features/EditBooking/editBookingSlice'
import { selectPayments } from '@/features/BookingHistory/bookingHistorySlice'
import { selectProcessing as selectAdyenProcessing } from '@/features/Adyen/adyenSlice'
import { selectProcessing as selectPaysafeProcessing } from '@/features/Terminal/terminalSlice'

export default function BookingPaymentButton({ className=null }) {

    const formMethods = useFormContext()
    const dispatch    = useDispatch()

    const tab                       = useSelector(selectTab)
    const bookingId                 = useSelector(selectBookingId)
    const payments                  = useSelector(selectPayments)
    const cashAmount                = useSelector(selectCashAmount)
    const checkAmount               = useSelector(selectCheckAmount)
    const amountToPay               = useSelector(selectAmountToPay)
    const isValidAmount             = useSelector(selectIsValidAmount)
    const processingAdyenTerminal   = useSelector(selectAdyenProcessing)
    const processingPaysafeTerminal = useSelector(selectPaysafeProcessing)
    const processingTerminal        = processingAdyenTerminal || processingPaysafeTerminal
    const processingOther           = useSelector(selectLoading)

    const [isValidPayment, setIsValidPayment] = useState(false)

    const handleSubmit = () => {
        dispatch(submitPayment(bookingId))
    }

    useEffect(() => {
        if (tab === 'cash') {
            setIsValidPayment(parseFloat(cashAmount) >= parseFloat(amountToPay) > 0)
        }

        if (tab === 'check') {
            setIsValidPayment(parseFloat(checkAmount) === parseFloat(amountToPay))
        }
    }, [tab, cashAmount, checkAmount, amountToPay])

    return (
        <div className={className}>
            <div className="form-group pb-0 w-100 overflow-hidden">
                {
                    // ---------------------------------------------------------------
                    // COMPONENTS THAT INJECT A PAYMENT BUTTON INTO THIS REACT PORTAL
                    // ---------------------------------------------------------------
                    // - @/features/BookingPayment/PaymentCreditTerminal
                    // - @/features/BookingPayment/PaymentCreditOnFile
                    // - @/features/BookingPayment/PaymentCreditCard
                    // ---------------------------------------------------------------
                }
                <div id="process-payment-button-container" className={payments?.length == 0 ? 'bottom-right-rounded' : ''} />

                {
                    /^(cash|check)$/i.test(tab) && (
                        <button
                            children='Process Payment'
                            className={`btn btn-primary py-3 px-5 w-100 ${payments?.length == 0 ? 'bottom-right-rounded' : ''}`}
                            disabled={processingTerminal || processingOther || !isValidPayment}
                            onClick={formMethods.handleSubmit(handleSubmit)}
                        />
                    )
                }

                {
                    /^(giftCard)$/i.test(tab) && (
                        <button
                            children='Process Payment'
                            className={`btn btn-primary py-3 px-5 w-100 ${payments?.length == 0 ? 'bottom-right-rounded' : ''}`}
                            disabled={processingTerminal || processingOther || !isValidAmount}
                            onClick={formMethods.handleSubmit(handleSubmit)}
                        />
                    )
                }
            </div>
        </div>
    )
}
