import { createSlice } from "@reduxjs/toolkit"

export const occasionsSlice = createSlice({
    name: 'occasions',
    initialState: {
        occasion_types: []
    },
    reducers: {
        setOccasionTypes: (state, action) => {
            state.occasion_types = action.payload
        }
    }
})

export const {
    setOccasionTypes
} = occasionsSlice.actions

export const selectOccasionTypes = state => state.occasions.occasion_types

export default occasionsSlice.reducer
