import React, { forwardRef } from 'react'

export default forwardRef(function Search({
    col='12',
    className='',
    placeholder='',
    searchInput='',
    autoFocus=false,
    disabled=false,
    onFocus=() => {},
    onBlur=() => {},
    onSearch=() => {},
}, ref) {

    return (
        <div className={`terminal-search input-group col-${col} ${className}`.trim()}>
            <input
                ref={ref}
                className='form-control search-field py-2'
                type="search"
                placeholder={placeholder}
                value={searchInput}
                autoFocus={autoFocus}
                disabled={disabled}
                onChange={onSearch}
                onFocus={onFocus}
                onBlur={onBlur}
            />
            <span className="input-group-append">
                <div className="search-icon input-group-text bg-transparent">
                    <i className="fa fa-search" />
                </div>
            </span>
        </div>
    )
})
