import React, { useMemo } from "react"
import { useFormContext } from "react-hook-form"
import Select from "../Form/Select"
import moment from 'moment'

export default function CustomerWaiversBirthday({
  field,
  errors,
  index,
  isReturning,
  customer,
  signedWaiver=null,
}) {

  const formMethods = useFormContext()

  const defaultDob = () => {
    let dob

    if (field === 'guardian_dob') {
      dob = signedWaiver?.guardian_dob
    } else {
      dob = customer?.dob
    }

    return dob?.split('-').map(ele => parseInt(ele))
  }

  const months = useMemo(() => (
      moment.months().map((month, index) => ({
          display: month,
          value: index + 1
      }))
  ), [])

  const fieldIndex = Number.isInteger(index) ? `-${index}` : ''

  return (
    <div className="row">
      <Select
        name={`${field}_month${fieldIndex}`}
        options={months || []}
        value={isReturning && defaultDob() ? defaultDob()[1] : formMethods.watch(`${field}_month${fieldIndex}`, '')}
        includeBlank='Month'
        validation={{ [`${field}_month${fieldIndex}`]: { required: true }}}
        disabled={isReturning && defaultDob()}
        req={true}
        cols='4'
        hideLabel={true}
        errors={errors}
      />
      <Select
        name={`${field}_day${fieldIndex}`}
        options={Array.from({ length: 31 }, (_, i) => i + 1)}
        value={isReturning && defaultDob() ? defaultDob()[2] : formMethods.watch(`${field}_day${fieldIndex}`, '')}
        includeBlank='Day'
        validation={{
          [`${field}_day${fieldIndex}`]: {
            required: true,
            validate: () => {
              if (formMethods.getValues(`${field}_month${fieldIndex}`) && formMethods.getValues(`${field}_year${fieldIndex}`)) {
                return moment(`${formMethods.getValues(`${field}_month${fieldIndex}`)}/${formMethods.getValues(`${field}_day${fieldIndex}`)}/${formMethods.getValues(`${field}_year${fieldIndex}`)}`, 'M/D/YYYY', true).isValid() || 'Invalid Date'
              }
            }
          }
        }}
        disabled={isReturning && defaultDob()}
        req={true}
        cols='4'
        hideLabel={true}
        errors={errors}
      />
      <Select
        name={`${field}_year${fieldIndex}`}
        options={Array.from({ length: 101 }, (_, i) => moment().year() - i)}
        value={isReturning && defaultDob() ? defaultDob()[0] : formMethods.watch(`${field}_year${fieldIndex}`, '')}
        includeBlank='Year'
        validation={{ [`${field}_year${fieldIndex}`]: { required: true }}}
        disabled={isReturning && defaultDob()}
        req={true}
        cols='4'
        hideLabel={true}
        errors={errors}
      />

      {isReturning && field === 'dob' && defaultDob() &&
        <>
          <input
            {...formMethods.register('dob_month')}
            type="hidden"
            name="dob_month"
            value={defaultDob()[1]}
          />
          <input
          {...formMethods.register('dob_day')}
          type="hidden"
          name="dob_day"
          value={defaultDob()[2]}
          />
          <input
          {...formMethods.register('dob_year')}
          type="hidden"
          name="dob_year"
          value={defaultDob()[0]}
          />
        </>
      }

      {isReturning && field === 'guardian_dob' && defaultDob() &&
        <>
          <input
            {...formMethods.register('guardian_dob_month')}
            type="hidden"
            name="guardian_dob_month"
            value={defaultDob()[1]}
          />
          <input
          {...formMethods.register('guardian_dob_day')}
          type="hidden"
          name="guardian_dob_day"
          value={defaultDob()[2]}
          />
          <input
          {...formMethods.register('guardian_dob_year')}
          type="hidden"
          name="guardian_dob_year"
          value={defaultDob()[0]}
          />
        </>
      }
    </div>
  )
}
