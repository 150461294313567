import React, { useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { errorsFor } from '@/components/Form/ErrorsHelper'
import { BookingStatus } from '../BookingStatus'
import { BookingActions } from '../BookingActions'
import { BookingLedgerActions } from '../../../EditBooking/ledger/BookingActions'
import { QuoteLedgerActions } from '../../../EditBooking/ledger/QuoteActions'
import BookingGroupSize from '../BookingGroupSize'
import { BookingCustomerTypes } from '../BookingCustomerTypes'
import BookingResourceQty from '../../../EditBooking/BookingResourceQty'
import BookingWaiversTable from '../BookingWaiversTable'
import EditBookingLedger from '../../../EditBooking/EditBookingLedger'
import Tabs from '@/features/Schedule/components/steps/Tabs'
import { useDispatch, useSelector } from 'react-redux'
import {
    fetchCustomerTypeCounts,
    selectBooking,
    selectBookingId,
    selectCustomerTypeCounts,
    selectParticipants,
    selectGroupMin,
    selectGroupMax,
    setBookingAttribute,
    setCustomerTypeCounts,
    updateBooking,
    setMemberCount,
    setMembers,
    selectTempMembers,
    setTempMembers,
} from '../../../EditBooking/editBookingSlice'
import { fetchCustomerTypes } from '@/features/CustomerTypes/customerTypeSlice'
import {selectResources} from "../../../Resources/resourcesSlice";
import { isBookingQuote, isQuoteExpiredOrRejected, isBookingCancelled, isBookingComplete } from '@/lib/Booking'
import { fetchPackage, selectPackage, selectOriginalPackage, selectGroups } from "../../../Packages/packageSlice";
import EditBookingMembers from '../EditBookingMembers'

export default function BookingSetupStep4({
    userName,
    originalGroupSize,
    handleClose,
    handleViewParent,
    handleSetOriginalGroupSize,
    children
}) {

    const dispatch           = useDispatch()
    const formMethods        = useFormContext()
    const booking            = useSelector(selectBooking)
    const bookingId          = useSelector(selectBookingId)
    const participants       = useSelector(selectParticipants)
    const groupMin           = useSelector(selectGroupMin)
    const groupMax           = useSelector(selectGroupMax)
    const customerTypeCounts = useSelector(selectCustomerTypeCounts)
    const pkg                = useSelector(selectPackage)
    const originalPkg        = useSelector(selectOriginalPackage)
    const resources          = useSelector(selectResources)
    const groups             = useSelector(selectGroups)
    const tempMembers        = useSelector(selectTempMembers)

    const [errors, setErrors]                                 = useState({})
    const [triggerGroupLoad, setTriggerGroupLoad]             = useState(null)
    const [forceHideCustomerTypes, setForceHideCustomerTypes] = useState(null)
    const [isChild, setIsChild]                               = useState(false)

    /*
    const handleConversion = () => {
        dispatch(updateBooking(false, true))
    }
    */

    const handleSave = (skipClose) => {
        dispatch(updateBooking(skipClose, false, () => {
            if (!skipClose && bookingId) {
                handleClose()
            }
        }))


        // dispatch(updateBooking(skipClose))
        // .then(async data => {
        //     if (data.has_start_date_or_time_changed) {
        //         if (!isBookingQuote(data.booking) && await confirm('Do you want to let the customer know about the change?')) {
        //             axios.get(`/bookings/${data.booking.id}/notify_changed`)
        //         }

        //         dispatch(calendarSelectBooking(data.booking))
        //     }
        // })
        // .then(() => {
        //     if (!skipClose && bookingId) {
        //         handleClose()
        //     }
        // })
    }

    const handleGroupSizeChange = (e) => {
        const value = !!e?.target?.value ? e.target.value : e

        if (/-/.test(value)) {
            const [min, max] = value.split('-')
            dispatch(setBookingAttribute({ name: 'participants', value: Number(min) }))
            dispatch(setBookingAttribute({ name: 'group_min',    value: Number(min) }))
            dispatch(setBookingAttribute({ name: 'group_max',    value: Number(max) }))
        } else {
            dispatch(setBookingAttribute({ name: 'participants', value: Number(value) }))
            dispatch(setBookingAttribute({ name: 'group_min',    value: null }))
            dispatch(setBookingAttribute({ name: 'group_max',    value: null }))
        }
    }

    const handleHideCustomerTypes = (state) => {
        setForceHideCustomerTypes(state)
    }

    const handleCustomerTypesChange = (value) => {
        dispatch(setCustomerTypeCounts(value))
    }

    /**
     * Loop through form errors and build up our own errors
     * object that gets passed down to all of the components
     */
    const onError = (formErrors) => {
        setErrors(errorsFor(formErrors))
    }

    /**
     * Handle initial load
     * or unload of component
     */
    useEffect(() => {
        return () => {
            formMethods.reset()
            setErrors({})
        }
    }, [])

    useEffect(() => {
        if (booking) {
            if (booking.parent_booking) {
                // fetch the original package if this is a child of a special event because
                // we will need to grab the correct current_participants count from here
                dispatch(fetchPackage(`/packages/${booking.package_id}`, null, true))

                setIsChild(true)
            }

            dispatch(fetchPackage(`/packages/${booking.package_id}`, booking.package_version_id))
            dispatch(fetchCustomerTypeCounts())
            dispatch(fetchCustomerTypes(`/packages/${booking.package_id}/customer_types`, booking.package_version_id))
        }
    }, [booking])

    const onMemberChange = (value) => {
        dispatch(setMemberCount(value))

        if (tempMembers.length > parseInt(value)) {
            dispatch(setMembers([...tempMembers.slice(0, (tempMembers.length - parseInt(value)) * -1)]))
        } else {
            let newMembers = [...tempMembers]

            Array.from(Array(parseInt(value) - tempMembers.length)).forEach(() => newMembers.push({ memberId: null, errors: [] }))

            dispatch(setMembers(newMembers))
            dispatch(setTempMembers(newMembers))
        }
    }

    return (
        <div className="drawer-scaffold">
            <div className="drawer-main">
                <div className="drawer-header-container">
                    <div className="drawer-header align-items-center pb-1">
                        <h2>
                            { booking.is_quote_expired ? 'EXPIRED' : /rejected/i.test(booking.quote_status) ? 'REJECTED' : 'Edit' }
                            <span className='d-none d-md-inline'> { isBookingQuote(booking) ? 'Quote' : 'Booking' }</span>
                        </h2>
                        {
                            !isBookingQuote(booking) && (
                                <BookingStatus isChild={isChild} />
                            )
                        }
                    </div>
                    <Tabs editMode />
                </div>
                <div className="drawer-main-content">
                    <div className="drawer-scroller">

                        {
                          groups?.length > 0 &&
                              <BookingGroupSize
                                  errors={errors}
                                  pkg={booking.parent_booking ? originalPkg : pkg }
                                  participants={participants}
                                  groupMin={groupMin}
                                  groupMax={groupMax}
                                  originalGroupSize={originalGroupSize}
                                  isBookingQuote={isBookingQuote(booking)}
                                  setOriginalGroupSize={handleSetOriginalGroupSize}
                                  setTriggerGroupLoad={setTriggerGroupLoad}
                                  triggerGroupLoad={triggerGroupLoad}
                                  onHideCustomerTypes={handleHideCustomerTypes}
                                  onChange={handleGroupSizeChange}
                                  onMemberChange={onMemberChange}
                              />
                        }

                        { /**
                          * only display customer types component if participants are available and a group pricing
                          * participants range (ex: 13-18 participants) is NOT selected and we currently are not
                          * forcing it to be hidden (which can happen when a group price range is chosen in BookingGroupSize)
                          * and the booking's package pricing type is NOT "uniform_pricing"
                          */
                          Number(participants) > 0 && /(-){1}/.test(participants) === false
                          && !forceHideCustomerTypes
                          && pkg.pricing_type !== 'uniform_pricing'
                          && (
                              <BookingCustomerTypes
                                  mode='editBooking'
                                  errors={errors}
                                  participants={participants}
                                  customerTypeCounts={customerTypeCounts}
                                  isBookingQuote={isBookingQuote(booking)}
                                  disabled={isBookingCancelled(booking) || isBookingComplete(booking) || booking?.check?.has_adv_pos_booking_payment_been_received}
                                  onChange={handleCustomerTypesChange}
                                  onMemberChange={onMemberChange}
                              />
                          )
                        }

                        <EditBookingMembers errors={errors} />

                        {!booking.parent &&
                            <BookingResourceQty
                                resources={resources}
                                isChild={isChild}
                            />
                        }

                        {
                            !isBookingQuote(booking) && (
                                <BookingWaiversTable />
                            )
                        }
                    </div>
                </div>

                <BookingActions handleClose={handleClose}>
                    <button
                        type="button"
                        className="btn btn-outline text-white"
                        disabled={isBookingCancelled(booking)}
                        onClick={formMethods.handleSubmit(() => handleSave(true), onError)}
                    >
                        Save
                    </button>

                    <button
                        type="button"
                        className="btn btn-primary ml-2"
                        disabled={isBookingCancelled(booking)}
                        onClick={formMethods.handleSubmit(() => handleSave(false), onError)}
                    >
                        Save &amp; Close
                    </button>
                </BookingActions>
            </div>

            <div className="drawer-aside">
                <EditBookingLedger userName={userName} handleViewParent={handleViewParent} />

                {
                    isBookingQuote(booking)
                        ? !isQuoteExpiredOrRejected(booking) ? <QuoteLedgerActions onError={(formErrors) => onError(formErrors)} /> : null
                        : <BookingLedgerActions />
                }
            </div>

            { children }
        </div>
    )
}
