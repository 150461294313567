import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { selectStepErrors } from './memberSlice'

export default function MemberErrors() {
    const errors = useSelector(selectStepErrors)

    useEffect(() => {
        if (!isEmpty) {
            document.querySelectorAll('.xbm-step-content-main .alert-danger')?.[0]?.scrollIntoView({ behavior: 'smooth' })
        }
    }, [errors])

    return !isEmpty(errors) && (
        <div className="alert alert-danger smaller mt-3 p-2 rounded">
            <strong>Please fix the following issues:</strong>

            <ul className='smaller mb-0'>
                {
                    Object.keys(errors).map((key, index) => (
                        <li key={index}>
                            {errors[key]}
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}
