import React from 'react'
import Promotions from './Promotions'

export default function SpecialPricingTab({ handleManualUpdate, form, errors, customerTypes, location, isNew }) {
    if (!isNew && form.pricing_type === 'pricing_by_customer_type' && !form.customer_types) {
        return ''
    }

    const selectedCustomerTypes = () => {
        if (!form.customer_types) { return [] }
        return customerTypes.filter(t => form.customer_types.includes(t.id))
    }

    return (
        <Promotions
            form={form}
            errors={errors}
            handleManualUpdate={handleManualUpdate}
            promoCodes={form.promo_codes || []}
            location={location}
            customerTypes={selectedCustomerTypes()}
        />
    )
}
