import React from 'react'
import c from 'capitalize'
import Input from '../Form/Input'
import Select from '../Form/Select'
import FormRow from '../Form/FormRow'

const formattedOptions = (categories) => {
    return Object.keys(categories).map((category) => {
        return { value: category, display: c(category) }
    })
}

export default function HitMissMessageForm({ handleUpdate, categories, form, errors }) {
    return (
        <div>
            <FormRow>
                <Select
                    name='category'
                    handleChange={handleUpdate}
                    req={true}
                    options={formattedOptions(categories)}
                    value={form.category}
                    cols='12'
                    errors={errors} />
            </FormRow>
            <FormRow>
                <Input
                    name='message'
                    handleChange={handleUpdate}
                    req={true}
                    value={form.message}
                    cols='12'
                    errors={errors} />
            </FormRow>
        </div>
    )
}