import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { selectLoadedDates, selectLastLoadedDatetime } from '@/features/Calendar/calendarSlice'
import { selectTimeZone } from '@/features/Locations/locationSlice'
import { generateTimes } from '@/lib/CalendarTimes'
import Time from '@/features/Calendar/Time'

export function _LaneTimesContainer({ lane }) {

    const loadedDates        = useSelector(selectLoadedDates)
    const lastLoadedDatetime = useSelector(selectLastLoadedDatetime)
    const selectedTimeZone   = useSelector(selectTimeZone)

    const generatedTimes = useMemo(() => (
        generateTimes(loadedDates, lastLoadedDatetime, selectedTimeZone).times
    ), [loadedDates, lastLoadedDatetime, selectedTimeZone])

    return generatedTimes.map((time, index) => (
        <Time
            key={`${index}_${time.unix()}`}
            time={time}
            lane={lane}
            index={index}
        />
    ))
}

export const LaneTimesContainer = React.memo(_LaneTimesContainer)
