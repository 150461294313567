import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import { selectCart, updateCart } from '@/features/GiftCards/giftCardSlice'
import moment from 'moment-timezone'

export default function ScheduleDate({ index, locationTimeZone }) {
    const dispatch = useDispatch()
    const { register, getValues, resetField, formState: { errors } } = useFormContext()
    const giftCards = useSelector(selectCart)
    const [sendOnFutureDate, sendAtDate, sendAtTime] = useMemo(() => [
        giftCards[index].sendOnFutureDate,
        giftCards[index].sendAtDate,
        giftCards[index].sendAtTime
    ], [giftCards[index]])

    const dateField = register(`cards.${index}.sendAtDate`, {
        required: sendOnFutureDate ? "Please select valid date." : false,
        validate: () => {
            const parsedDateTime = moment(getValues(`cards.${index}.sendAtDate`) + " " + sendAtTime).tz(locationTimeZone).format('YYYY-MM-DD h:mm a')
            const localTimeLimit = moment().add(24, 'hours').tz(locationTimeZone).format('YYYY-MM-DD h:mm a')
            return !sendOnFutureDate || (moment(parsedDateTime).isAfter(localTimeLimit)) || "Please set the time after 24 hours or more."
        }
    })

    const handleChange = () => {
        if (sendOnFutureDate) {
            dispatch(updateCart({ index, name: "sendOnFutureDate", value: false }))
            dispatch(updateCart({ index, name: "sendAtDate", value: "" }))
            dispatch(updateCart({ index, name: "sendAtTime", value: "00:00" }))
            resetField(`cards.${index}.sendAtDate`)
        } else {
            dispatch(updateCart({ index, name: "sendOnFutureDate", value: true }))
        }
    }

    useEffect(() => {
        dispatch(updateCart({ index, name: "sendAtTime", value: "00:00" }))
    }, [])

    return <>
        <div className="custom-control custom-checkbox my-3">
            <input
                id={`send-on-future-date-${index}`}
                type="checkbox"
                className="custom-control-input"
                checked={sendOnFutureDate}
                onChange={handleChange}
            />
            <label
                htmlFor={`send-on-future-date-${index}`}
                className="custom-control-label"
            >Send on a future date?
            </label>
        </div>

        {sendOnFutureDate &&
            <div className="custom-control">
                <div className="form-row">
                    <input
                        {...dateField}
                        type="date"
                        className={`form-control custom-select mb-3 ${errors?.cards?.[index]?.sendAtDate ? 'is-invalid' : ''}`}
                        style={{ width: "200px" }}
                        value={sendAtDate}
                        min={moment().tz(locationTimeZone).format('YYYY-MM-DD')}
                        onChange={e => {
                            dateField.onChange(e)
                            dispatch(updateCart({ index, name: "sendAtDate", value: e.target.value }))
                        }}
                    />
                </div>
                <div className="form-row">
                    <select
                        type="number"
                        min="1"
                        max="12"
                        className="form-control custom-select"
                        style={{ width: "200px" }}
                        value={sendAtTime}
                        onChange={e => {
                            dispatch(updateCart({ index, name: "sendAtTime", value: e.target.value }))
                        }}
                    >
                        <option value="00:00">12 AM</option>
                        {Array.from(Array(11).keys()).map(i => <option key={i} value={`${i + 1}:00`}>{i + 1} AM</option>)}
                        <option value="12:00">12 PM</option>
                        {Array.from(Array(11).keys()).map(i => <option key={i}  value={`${i + 13}:00`}>{i + 1} PM</option>)}
                    </select>
                </div>
                {
                    errors?.cards?.[index]?.sendAtDate &&
                    <div className="invalid-feedback d-block">{errors.cards[index].sendAtDate.message}</div>
                }
            </div>
        }
    </>
}
