import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectPayFullAmount, setPayFullAmount } from '../../advancedPointOfSaleSlice'

export default function PayFullAmount({ enabled=true }) {

    const dispatch      = useDispatch()
    const payFullAmount = useSelector(selectPayFullAmount)

    const handleChange = () => {
        dispatch(setPayFullAmount(!payFullAmount))
    }

    return enabled && (
        <div className='row mt-2 mb-n1'>
            <div className='col-12 text-right text-bold px-1'>
                <div className="form-check form-check-inline">
                    <label className="checkbox form-check-label d-flex align-items-center">
                        <input
                            className="form-check-input mr-1"
                            type="checkbox"
                            checked={payFullAmount}
                            onChange={handleChange}
                        />
                        <span>Pay Full Amount</span>
                    </label>
                </div>
            </div>
        </div>
    )
}
