import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectDepositToApply, setDepositToApply } from './bookingPaymentSlice'
import { selectBooking } from '../EditBooking/editBookingSlice'
import { addAlert } from '../Notifications/notificationSlice'
import { numberToCurrency } from '@/lib/Number'

export default function PaymentApplyDeposit() {

    const dispatch = useDispatch()
    const amount   = useSelector(selectDepositToApply)
    const booking  = useSelector(selectBooking)

    const handleClear = () => {
        dispatch(setDepositToApply(null))
    }

    const handleApplyFullAmount = () => {
        dispatch(setDepositToApply(booking.deposit_not_applied / 100))
    }

    const handleChange = (e) => {
        const newAmount = Number.parseFloat(e.target.value)

        if (newAmount > Number.parseFloat(Number.parseFloat(booking.deposit_not_applied / 100).toFixed(2))) {
            dispatch(addAlert({ type: 'error', text: 'You can not apply a deposit amount greater than what is available.' }))
            return
        }

        dispatch(setDepositToApply(newAmount))
    }

    return Number.parseFloat(booking?.deposit_not_applied || 0) <= 0 ? null : (
        <div className="modal-body-main-section py-3">
            <div className="d-flex align-items-stretch">
                <div className="form-group p-0 pr-3 d-flex align-items-center">
                    <label className="m-0">Apply&nbsp;Deposit&nbsp;Funds</label>
                </div>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <div className="input-group-text">
                            <i className="far fa-dollar-sign"></i>
                        </div>
                    </div>

                    <input
                        value={amount || ''}
                        onChange={handleChange}
                        type="number"
                        min={0}
                        step={.01}
                        className="form-control"
                        placeholder="0.00"
                    />

                    {
                        !!amount && amount > 0 && (
                            <div className='input-group-append'>
                                <button
                                    children={<i className="far fa-times" />}
                                    type='button'
                                    className='btn btn-secondary text-white'
                                    onClick={handleClear}
                                />
                            </div>
                        )
                    }
                </div>

                <div className="form-group p-0 px-3 d-flex align-items-center">
                    <label className="m-0">Of</label>
                </div>

                <div className="d-flex align-items-center">
                    <button
                        type='button'
                        className={`btn btn-text ${(amount * 100) === booking?.deposit_not_applied ? 'text-success' : 'text-danger'}`}
                        title='Apply the full amount'
                        style={{ fontSize: '18px', fontWeight: 900 }}
                        onClick={handleApplyFullAmount}
                    >
                        {numberToCurrency(booking?.deposit_not_applied / 100 || 0)}
                    </button>
                </div>
            </div>
        </div>
    )
}
