import React from 'react'
import Input from '../Form/Input'

/**
 * Render an individual lane field for an individual column (name, capacity). These work the same for all inputs
 * so this component is shared between them.
 *
 * @param type the input type (number/text, etc.)
 * @param cols the number of columns the input should span
 * @param handleChange the event handler for when an input is changed
 * @param collection the collection (object) to get this field's value from and expand into
 * @param prefix the prefix name (name/capacity) for the object key
 * @param column the db column we're setting in the form when it gets updated (e.g. resource_names)
 * @param number the lane number we're editing
 */
export default function LaneField({ type, cols, handleChange, collection, prefix, column, number }) {

    // when a lane field is changed, we need to set a new collection object value by slicing the updated
    // field into the overall object... this way only our individual field is updated.
    const handleLaneFieldChange = (e) => {
        handleChange(column, {
            ...collection,
            [e.target.name]: e.target.value
        })
    }

    return (
        <Input
            type={type}
            cols={cols}
            handleChange={handleLaneFieldChange}
            name={`${prefix}_${number}`}
            value={collection[`${prefix}_${number}`] || ''} />
    )
}