import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import TextareaAutosize from 'react-textarea-autosize'
import { selectForm, setFormAttribute, setStep } from '@/features/Schedule/scheduleSlice'
import { selectPackage, selectPackages, selectPackageGroups } from '@/features/Packages/packageSlice'
import { BasicBookingInfo } from '../BasicBookingInfo'
import BookingPackages from '../BookingPackages'
import BookingGroupSize from '../BookingGroupSize'
import { BookingCustomerTypes } from '../BookingCustomerTypes'
import { BookingActions } from '../BookingActions'
import NewBookingLedger from '../NewBookingLedger'
import Tabs from '@/features/Schedule/components/steps/Tabs'
import QuoteToggle from '../QuoteToggle'
import { errorsFor } from '@/components/Form/ErrorsHelper'
import { debug } from '@/lib/Debug'
import { selectMemberCount, selectMembers, checkMemberIds } from '../../scheduleSlice'

export default function BookingSetupStep1({ handleClose, children }) {

    const dispatch      = useDispatch()
    const formMethods   = useFormContext()
    const form          = useSelector(selectForm)
    const chosenPackage = useSelector(selectPackage)
    const packageGroups = useSelector(selectPackageGroups)
    const packages      = useSelector(selectPackages)
    const memberCount   = useSelector(selectMemberCount)
    const members       = useSelector(selectMembers)

    const [initialLoad, setInitialLoad]           = useState(true)
    const [errors, setErrors]                     = useState({})
    const [triggerGroupLoad, setTriggerGroupLoad] = useState(null)

    const isNewQuote = !form.require_email_address

    const handleNext = () => {
        dispatch(checkMemberIds()).then(({ isValid }) => {
            if (isValid) {
                dispatch(setStep('2'))
            }
        })
    }

    /**
     * Loop through form errors and build up our own errors
     * object that gets passed down to all of the components
     */
    const onError = (formErrors) => {
        setErrors(errorsFor(formErrors))
    }

    /**
     * Handle initial load
     * or unload of component
     */
    useEffect(() => {
        setInitialLoad(false)
        return () => {
            formMethods.reset()
            setErrors({})
        }
    }, [])

    /**
     * Reset the form and counts if the user
     * ever changes which package is selected
     */
    useEffect(() => {
        if (!initialLoad) {
            dispatch(setFormAttribute({ name: 'participants', value: null }))
            dispatch(setFormAttribute({ name: 'customer_type_counts', value: {} }))
            dispatch(setFormAttribute({ name: 'memberCount', value: 0 }))
            dispatch(setFormAttribute({ name: 'members', value: [] }))
            if (debug && console) { console.log('package changed! resetting') }
        }

        if (chosenPackage.is_special_event) {
            // value of false = quoting
            // value of true = normal booking
            dispatch(setFormAttribute({ name: 'require_email_address', value: true }))
        }
    }, [chosenPackage])

    /**
     * Update errors when form state changes
     */
    useEffect(() => {
        setErrors(errorsFor(formMethods.formState.errors))
    }, [formMethods.formState])

    const onMemberChange = (value) => {
        dispatch(setFormAttribute({ name: 'memberCount', value: value }))

        if (parseInt(value) >= parseInt(memberCount)) {
            // add member(s)
            let newMembers = [...members]

            Array.from(Array(parseInt(value) - parseInt(memberCount))).forEach(() => newMembers.push({ memberId: null, errors: [] }))

            dispatch(setFormAttribute({
                name: 'members',
                value: newMembers,
            }))
        } else {
            // remove member(s) from the end
            dispatch(setFormAttribute({ name: 'members', value: [...members.slice(0, (parseInt(memberCount) - parseInt(value)) * -1)] }))
        }
    }

    const onMemberRemove = index => {
        const newMembers = [...members]

        newMembers.splice(index, 1)

        dispatch(setFormAttribute({ name: 'memberCount', value: memberCount - 1 }))
        dispatch(setFormAttribute({ name: 'members', value: newMembers }))
    }

    return (
        <div className={`drawer-scaffold ${isNewQuote ? 'quote' : ''}`}>
            <div className="drawer-main">
                <div className="drawer-header-container">
                    <div className="drawer-header">
                        <QuoteToggle />
                    </div>
                    <Tabs />
                </div>

                <div className="drawer-main-content">
                    <div className="drawer-scroller">

                        <BasicBookingInfo
                            form={form}
                            errors={errors}
                            newBooking={true}
                            dispatchAction={setFormAttribute} />

                        {
                            isNewQuote && <>
                                <div className="form-group">
                                    <label>
                                        Notes (Optional - Will be shown in quote pdf/email)
                                    </label>
                                    <TextareaAutosize
                                        cacheMeasurements
                                        className="form-control"
                                        name="note"
                                        placeholder="Thanks for your interest. Here is your quote."
                                        value={form.note || ""}
                                        onChange={(e) => dispatch(setFormAttribute({ name: e.target.name, value: e.target.value }))}
                                    />
                                </div>
                            </>
                        }

                        <BookingPackages
                            errors={errors}
                            setTriggerGroupLoad={setTriggerGroupLoad}
                        />

                        { packages && packages.length > 0 && ('id' in chosenPackage) && <>
                            <BookingGroupSize
                                errors={errors}
                                pkg={chosenPackage}
                                participants={form.participants}
                                groupMin={form.group_min}
                                groupMax={form.group_max}
                                setTriggerGroupLoad={setTriggerGroupLoad}
                                triggerGroupLoad={triggerGroupLoad}
                                isBookingQuote={isNewQuote}
                                onMemberChange={onMemberChange}
                                onMemberRemove={onMemberRemove}
                                onChange={(value) => dispatch(setFormAttribute({ name: 'participants', value: value }))}
                            />

                            {
                                // only display customer types component if participants are available and
                                // group pricing participants range (ex: 13-18 participants) is NOT selected
                                // and fetched customer types are populated
                                Number(form.participants) > 0 && /(-){1}/.test(form.participants) === false && (
                                    <BookingCustomerTypes
                                        mode='newBooking'
                                        errors={errors}
                                        participants={form.participants}
                                        customerTypeCounts={form.customer_type_counts}
                                        isBookingQuote={isNewQuote}
                                        onChange={(value) => dispatch(setFormAttribute({ name: 'customer_type_counts', value: value }))}
                                        onMemberChange={onMemberChange}
                                        onMemberRemove={onMemberRemove}
                                    />
                                )
                            }
                        </>}

                    </div>
                </div>

                <BookingActions handleClose={handleClose}>
                    <button
                        type="button"
                        className="btn btn-primary"
                        disabled={packageGroups.length > 0}
                        onClick={formMethods.handleSubmit(handleNext, onError)}
                    >
                        Next: Start Date/Time &amp; Duration <i className="fal fa-chevron-right ml-3"></i>
                    </button>
                </BookingActions>
            </div>

            <div className="drawer-aside">
                <NewBookingLedger />
            </div>

            { children }
        </div>
    )
}
