import React from 'react'

const warningIcon = (
    <span className='ml-2 fa-stack fa-xs fa-bounce' style={{ '--fa-animation-iteration-count': 2 }}>
        <i className='fa-solid fa-circle fa-stack-2x text-white' />
        <i className='fa-solid fa-circle-exclamation text-red fa-stack-2x' />
    </span>
)

// This is the "block style" type of tab navigation in use on the
// order/chit sidebar and within the checks, end of shift, 86ing pages, etc.

export default function TabNav({
    id=null,
    className='',
    containerClassName='',
    tabs=[],
    currentTab=null,
    prepend:prependedChildren=null,
    append:appendedChildren=null,
    handleChange=() => {},
}) {
    return tabs.length === 0 ? null : (
        <nav id={id} className={`tab--navigation ${containerClassName}`.trim()}>
            { prependedChildren }

            <div id={id} className={`tab--navigation--inner nav nav-tabs nav-fill ${className}`.trim()}>
                {
                    tabs.map((tab, index) => (
                        <button
                            key={`tab-${index}`}
                            type='button'
                            className={`nav-item ${currentTab === (tab?.title || tab) ? 'active' : ''}`.trim()}
                            onClick={(e) => handleChange(tab, e)}
                        >
                            { tab?.prepend }
                            { tab?.title || tab }
                            { tab?.append === 'warning' && warningIcon }
                        </button>
                    ))
                }
            </div>

            { appendedChildren }
        </nav>
    )
}
