import React from 'react'
import Input from '@/components/Form/Input'
import FormRow from '@/components/Form/FormRow'
import Select from '@/components/Form/Select'

export default function FulfillmentProviderForm({
  form,
  itemTypesOptions=[],
  errors,
  handleUpdate,
  handleManualUpdate,
}) {
  return (
    <div className="mt-4">
      <FormRow>
        <Input
          cols="6"
          label="Printer Location Name"
          value={form.name}
          validation={{ name: { required: true } }}
          errors={errors}
          handleChange={handleUpdate}
        />

        <Select
          cols="6"
          multiple={true}
          label="Item Types"
          options={itemTypesOptions}
          value={form.item_type_ids}
          validation={{ item_type_ids: { required: true } }}
          errors={errors}
          handleChange={(e) => {
            const values = Array.from(e.target.selectedOptions, (option) => option.value)
            handleManualUpdate('item_type_ids', values)
          }}
        />
      </FormRow>
    </div>
  )
}
