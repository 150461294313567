import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { selectCurrentUserId } from '@/features/Session/sessionSlice'

import {
    fetchCheck,
    fetchChecksInAlteration,
    selectChecksInAlteration,
    selectChecksInAlterationLastUpdatedAt,
    setCurrentCheck,
    setCurrentComponent,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import Breadcrumbs from '@/features/AdvancedPointOfSale/components/Breadcrumbs'
import ChecksGrid from '@/features/AdvancedPointOfSale/components/ChecksGrid'
import { parameterize, titleize } from 'inflected'
import scrollHinting from '@/lib/ScrollHinting'
import { PREVENT_LOADER } from '@/lib/Storage'
import { useConfirm } from '@/lib/useConfirmHook'
import { debug } from '@/lib/Debug'

export default function ChecksInAlteration({
    self='ChecksInAlteration',
    onChange=() => {},
}) {
    const dispatch              = useDispatch()
    const { confirm }           = useConfirm()
    const currentUserId         = useSelector(selectCurrentUserId)
    const checks                = useSelector(selectChecksInAlteration)
    const lastUpdatedAt         = useSelector(selectChecksInAlterationLastUpdatedAt)
    const [loading, setLoading] = useState(null)

    const dispatchFetchChecks = ({ skippable }) => {
        setLoading(true)

        // IF lastUpdatedAt is set and filteredChecks are present...
        // AND IF the last time we fetched them was within X seconds ago..
        // THEN do not fetch them again
        if (
            skippable === true
            && !!lastUpdatedAt
            && checks.length > 0
            && moment(Date.now()).diff(moment(lastUpdatedAt), 'seconds') < 15
        ) {
            if (debug && console) { console.log('SKIPPING CHECKS FETCH') }
            setLoading(false)

        // OTHERWISE fetch the checks
        } else {
            dispatch(fetchChecksInAlteration({ unlessPopulated: false })).then(() => {
                setLoading(false)
                scrollHinting.search()
            })
        }
    }

    const handleCheckClick = async (check) => {
        if (check.last_altered_by === currentUserId) {
            dispatch(fetchCheck(check.id)).then((check) => {
                dispatch(setCurrentCheck(check))
                dispatch(setCurrentComponent('ManageCheck'))
            })
        } else {
            await confirm('This check is currently being altered by a different manager.', {
                header_text: 'Unable to Continue!',
                confirm_only: true,
                confirm_text: 'OK',
            })
        }
    }

    // load data/dependencies if necessary
    useEffect(() => {
        window.sessionStorage.setItem(PREVENT_LOADER, true)

        onChange(
            <Breadcrumbs
                id='advanced-pos--terminal-navigation--breadcrumbs'
                data={[ { id: null, name: titleize(self), type: null } ]}
            />
        )

        return () => {
            window.sessionStorage.removeItem(PREVENT_LOADER)
        }
    }, [])

    // always fetch checks on either initial load
    // or when we've switched to a new user
    useEffect(() => {
        if (!loading) {
            dispatchFetchChecks({ skippable: false })
        }
    }, [dispatch])

    return (
        <div id={`advanced-pos-terminal--${parameterize(self)}`} className="scrollhint">
            <div className='scrollhint--inner p-1'>
                <ChecksGrid
                    content={checks}
                    loading={loading}
                    onCheckClick={handleCheckClick}
                />
            </div>
        </div>
    )
}
