import React  from 'react'
import { useSelector } from 'react-redux'
import { selectInDeviceSetupMode, selectDeviceSettings } from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'
import Battery from '@/components/AdvancedPointOfSale/Battery'

export default function DeviceFingerPrint({
    showBattery=false,
    showBatteryPercent=false,
    showLocation=false
}) {
    const inDeviceSetupMode = useSelector(selectInDeviceSetupMode)
    const deviceSettings    = useSelector(selectDeviceSettings)

    return !inDeviceSetupMode && (
        <small className='device-fingerprint row m-0'>
            <span className='d-inline-block col-6 text-left pr-0'>
                { deviceSettings.name }
            </span>

            <span className='d-inline-block col-6 text-right pl-0'>
                <span className='d-flex align-items-center w-100'>
                    <Battery
                        show={showBattery}
                        showPercent={showBatteryPercent}
        debug={true}
                        className={showLocation ? 'mr-auto' : 'ml-auto'}
                    />

                    {
                        (showLocation || !window.navigator.getBattery) &&  (
                            <span className='ml-auto'>
                                { deviceSettings.location.name }
                            </span>
                        )
                    }
                </span>
            </span>
        </small>
    )
}
