import React from 'react'
import Input from '@/components/Form/Input'
import FormRow from '@/components/Form/FormRow'
import MultiSelectCheckboxes from '@/components/Form/MultiSelectCheckboxes'
import { pluckKeyFromObjectsInArray } from '@/lib/Object'
import { sortedByArray } from '@/lib/Array'

export default function ModifierGroupForm({
    form,
    errors,
    modifierOptions,
    handleUpdate,
    handleFormUpdate,
}) {

    const handleModifiersUpdate = (modifiers) => {
        const modifier_ids         = pluckKeyFromObjectsInArray(modifiers, 'id')
        const modifiers_sort_order = modifier_ids

        handleFormUpdate({ ...form, ...{
            modifiers,
            modifier_ids,
            modifiers_sort_order
        }})
    }

    return (
        <div className="mt-4">
            <FormRow>
                <Input
                    name="name"
                    label="Modifier Group Name"
                    value={form.name}
                    handleChange={handleUpdate}
                    validation={{ name: { required: true } }}
                    errors={errors}
                />
            </FormRow>

            <h3>Modifiers in Group</h3>

            <FormRow>
                <MultiSelectCheckboxes
                    hideLabel={true}
                    options={modifierOptions}
                    selectedValues={sortedByArray(form.modifiers, form.modifiers_sort_order)}
                    useExternalList={true}
                    externalListIsSortable={true}
                    emptyRecordMsg='No modifiers available.'
                    validation={{ modifiers: { required: true, value: form.modifiers }}}
                    errors={errors}
                    onSelect={handleModifiersUpdate}
                    onRemove={handleModifiersUpdate}
                    onSort={handleModifiersUpdate}
                />
            </FormRow>
        </div>
    )
}
