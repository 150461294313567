import moment from 'moment'
import { roundUp, numberToCurrency } from '@/lib/Number'
import { debug } from '@/lib/Debug'

/**
 * Helper class around pricing attributes e.g. pricing type, etc.
 */
export default class Pricing {
    /**
     * Static string for pricing_type field - by customer type
     */
    static BY_CUSTOMER_TYPE = 'pricing_by_customer_type'
}

export function calculateTaxesAndFeesFromPayment(auto_gratuity_rate, tax, rkd_fee, merchant_fee, third_party_fee, amount) {
    // VARIABLES
    // --------------------------------------------
    // <Transaction Amount>   = $150 flat payment
    // <Auto Gratuity Rate %> = 20% auto gratuity
    // <Sales Taxes %>        = 7.5% Sales Tax
    // <RKd Fee %>            = 4% reservation fee
    // <Merchant Fee %>       = 2% reservation fee
    // <Third Party Fee %>    = 1% reservation fee
    // --------------------------------------------

    // Add together the Taxes and Fees percentages
    //  7.5% + 4% + 2% + 1% = 14.5%
    // <Taxes & Fees Total %> = <Sales Tax %> + <RKd Fee %> + <Merchant Fee %> + <Third Party Fee %>
    const taxes_fees_percent_total = tax + rkd_fee + merchant_fee + third_party_fee

    // Calculate the Subtotal
    // $150 / (1 + 0.2 + (14.5 / 100)) = $111.52416356877323
    // <Transaction Amount> / (1 + <Auto Gratuity Rate> + (<Taxes & Fees Total %> / 100)) = <Subtotal>
    const subtotal = amount / (1 + auto_gratuity_rate + (taxes_fees_percent_total / 100))

    // Calculate AutoGratuity separately based on the SubTotal
    // $150 * 0.2 = $30
    // <Subtotal> * <Auto Gratuity Rate> = <Auto Gratuity Amount>
    const auto_gratuity = subtotal * auto_gratuity_rate

    // Calculate the Taxes & Fees
    // $150 * (14.5 / 100) = $21.75
    // <Transaction Amount> * (<Taxes & Fees Percent Total %> / 100) = <Taxes & Fees>
    const taxes_and_fees_charge_total = subtotal * (taxes_fees_percent_total / 100)

    // Separate Taxes from Taxes & Fees
    // $21.75 * (7.5% / (7.5% + 4% + 2% + 1%)) = $11.25
    // <Taxes & Fees Charge Total %> * (<Sales Tax %> / <Taxes Fees Percent Total %>)
    let taxes_charge_total = taxes_and_fees_charge_total * (tax / taxes_fees_percent_total)

    // Calculate additional fees individually by separating them out from the main Taxes & Fees
    //
    // RKD Fee Example:
    //   $11.25 * (4% / 14.5%) = $3.103448275862069
    //   <Taxes & Fees Charge Total %> * (<RKD Fee %> / (<Taxes Fees Percent Total %>)
    let rkd_fee_charge_total         = taxes_and_fees_charge_total * (rkd_fee / taxes_fees_percent_total)
    let merchant_fee_charge_total    = taxes_and_fees_charge_total * (merchant_fee / taxes_fees_percent_total)
    let third_party_fee_charge_total = taxes_and_fees_charge_total * (third_party_fee / taxes_fees_percent_total)

    // handle any NaN or divisions by zero issues
    taxes_charge_total           = isNaN(taxes_charge_total)           ? 0 : taxes_charge_total
    rkd_fee_charge_total         = isNaN(rkd_fee_charge_total)         ? 0 : rkd_fee_charge_total
    merchant_fee_charge_total    = isNaN(merchant_fee_charge_total)    ? 0 : merchant_fee_charge_total
    third_party_fee_charge_total = isNaN(third_party_fee_charge_total) ? 0 : third_party_fee_charge_total

    return {
        auto_gratuity:    Number.parseFloat(auto_gratuity.toFixed(2)),
        taxes:            Number.parseFloat(taxes_charge_total.toFixed(2)),
        rkd_fees:         Number.parseFloat(rkd_fee_charge_total.toFixed(2)),
        merchant_fees:    Number.parseFloat(merchant_fee_charge_total.toFixed(2)),
        third_party_fees: Number.parseFloat(third_party_fee_charge_total.toFixed(2)),
        subtotal:         Number.parseFloat(subtotal.toFixed(2))
    }
}

/*
 * NOTE: I think this is no longer in use but leaving it in just to be safe...
 */
export function parsedParticipants(_participants) {
    const participants = typeof _participants !== 'string' ? _participants.toString() : _participants

    if (/(-{1})/.test(participants)) {
        const [min, max] = participants.split('-')
        return {
            min: Number.parseInt(min),
            max: Number.parseInt(max)
        }
    } else {
        return {
            min: Number.parseInt(participants),
            max: Number.parseInt(participants)
        }
    }
}

export function autoGratuityRate(pakage, participants) {
    if (!pakage || !participants || ('groups' in pakage === false)) { return 0.0 }

    // find the group definition for the booking that we're using
    const group = pakage.groups.filter((group) => {
        if (typeof participants === 'object' && 'min' in participants && 'max' in participants) {
            return participants.min >= Number.parseInt(group.min) && participants.max <= Number.parseInt(group.max)
        } else {
            return Number.parseInt(participants) >= Number.parseInt(group.min) && Number.parseInt(participants) <= Number.parseInt(group.max)
        }
    })

    if (!Boolean(group) || group?.length !== 1) {
        if (debug && console) { console.log('Could not find the group definition!', pakage, participants) }
        return 0.0
    }

    return group[0]?.auto_gratuity ? (group[0]?.auto_gratuity / 100) : 0.0
}

export function parsePricingByType(amountType, pkg, booking, customerTypeCounts, customerTypes, taxRate, rkd_fee, merchant_fee, third_party_fee, location, resourceType, customersToPayFor, customAmount) {
    taxRate = booking.is_tax_exempt ? 0.0 : taxRate

    const amountPackage = runTypeCalculations(amountType, pkg, booking, customerTypeCounts, customerTypes, taxRate, rkd_fee, merchant_fee, third_party_fee, location, resourceType, customersToPayFor, customAmount)

    const basePrice    = Number.parseFloat(amountPackage.basePrice)
    let   taxesAndFees = Number.parseFloat(amountPackage.taxesAndFees)
    const total        = Number.parseFloat(amountPackage.total)

    if ((taxesAndFees + basePrice) > total) {
        const taxesBaseCents = (taxesAndFees + basePrice) * 100
        const totalCents     = total * 100
        const diff           = taxesBaseCents - totalCents
        let taxesCents       = taxesAndFees * 100
        taxesCents          -= diff
        taxesAndFees         = taxesCents / 100

        amountPackage.taxesAndFees = taxesAndFees.toFixed(2)
    }

    if (debug && console) { console.log(amountPackage) }

    return amountPackage
}

function runTypeCalculations(amountType, pkg, booking, customerTypeCounts, customerTypes, taxRate, rkd_fee, merchant_fee, third_party_fee, location, resourceType, customersToPayFor, customAmount) {
    const auto_gratuity_rate = autoGratuityRate(pkg, Number.parseInt(booking.participants))

    switch (amountType) {
    case 'remaining':
        return calculatePriceByRemaining(booking, auto_gratuity_rate, taxRate, rkd_fee, merchant_fee, third_party_fee)

    case 'participant':
        return calculatePriceByParticipant(pkg, booking, customerTypeCounts, customerTypes, auto_gratuity_rate, taxRate, rkd_fee, merchant_fee, third_party_fee, location, resourceType)
        /**
         * @TODO we need to implement a case for participant_uniform here and parse it according to that data structure...
         * we should be able to share a lot with the below method, just updating the data structure...
         */

    case 'participant_uniform':
        return calculatePriceUniform(pkg, booking, auto_gratuity_rate, taxRate, rkd_fee, merchant_fee, third_party_fee, location, customersToPayFor, resourceType)

    case 'custom':
        return calculatePriceByCustom(customAmount, auto_gratuity_rate, taxRate, rkd_fee, merchant_fee, third_party_fee)

    default:
        console.warn(`Unknown type: ${amountType}`)
        return {
            basePrice:    5,
            autoGratuity: 0,
            taxesAndFees: 2,
            total:        7
        }
    }
}

export function calculatePriceByRemaining(booking, auto_gratuity_rate, taxRate, rkd_fee, merchant_fee, third_party_fee) {
    if (!moment(booking?.start_time).isValid()) { return }

    if (debug && console) {
        console.log('-------------------------------------------')
        console.log('Calculating Price by Remaining')
    }

    const balance = Number.parseFloat((booking.balance_cents / 100).toFixed(2))

    const breakdown = calculateTaxesAndFeesFromPayment(auto_gratuity_rate, taxRate, rkd_fee, merchant_fee, third_party_fee, balance)

    return {
        basePrice:    breakdown.subtotal.toFixed(2),
        autoGratuity: breakdown.auto_gratuity.toFixed(2),
        taxesAndFees: (breakdown.taxes + breakdown.rkd_fees + breakdown.merchant_fees + breakdown.third_party_fees).toFixed(2),
        total:        balance.toFixed(2)
    }
}

function calculatePriceByCustom(customAmount, auto_gratuity_rate, taxRate, rkd_fee, merchant_fee, third_party_fee) {
    if (debug && console) {
        console.log('-------------------------------------------')
        console.log('Calculating Price by Custom')
    }

    const amount = Number.parseFloat(customAmount)

    const breakdown = calculateTaxesAndFeesFromPayment(auto_gratuity_rate, taxRate, rkd_fee, merchant_fee, third_party_fee, amount)

    return {
        basePrice:    (breakdown.subtotal || 0).toFixed(2),
        autoGratuity: (breakdown.auto_gratuity || 0).toFixed(2),
        taxesAndFees: ((breakdown.taxes + breakdown.rkd_fees + breakdown.merchant_fees + breakdown.third_party_fees) || 0).toFixed(2),
        total:        (amount || 0).toFixed(2)
    }
}

export function evaluateParticipantPricingByGroupType(pakage, booking, customerTypeCounts, customerTypes, taxRate, rkd_fee, merchant_fee, third_party_fee, location, participants, resourceType) {
    try {
        if (!moment(booking?.start_time).isValid() || !Boolean(pakage?.pricing)) { return null }

        const group = pakage.pricing.filter(g => Number.parseInt(participants) >= Number.parseInt(g.groupMin) && Number.parseInt(participants) <= Number.parseInt(g.groupMax))[0]

        const auto_gratuity_rate = autoGratuityRate(pakage, Number.parseInt(booking.participants))

        if (group?.isGroup && !group?.individualPricing) {
            const groupPrice = calculatePriceGroup(pakage, booking, auto_gratuity_rate, taxRate, rkd_fee, merchant_fee, third_party_fee, location, participants, resourceType).customerTypeTotals
            return groupPrice
        }

        if (pakage.pricing_type !== 'pricing_by_customer_type') {
            if (pakage.pricing_type === 'uniform_pricing') {
                return calculatePriceUniform(pakage, booking, auto_gratuity_rate, taxRate, rkd_fee, merchant_fee, third_party_fee, location, participants, resourceType).customerTypeTotals
            }
            return
        }

        if (!group?.isGroup) {
            return
        }

        return calculatePriceByParticipant(pakage, booking, customerTypeCounts, customerTypes, auto_gratuity_rate, taxRate, rkd_fee, merchant_fee, third_party_fee, location, resourceType).customerTypeTotals
    } catch (e) {
        if (debug && console) { console.error(e) }
        return null
    }
}

// get the discount for a booking (if there is one present)
export function getDiscountForBooking(booking) {
    try {
        return booking?.booking_discounts?.[0]?.discount_record
    } catch(e) {
        console.log(e)
        return null
    }
}

/**
 * Calculate prices by customer type
 *
 * @param {*} pakage package to pull from
 * @param {*} booking booking that's being calculated for
 * @param {*} customerTypeCounts customer type counts for whatever we're using
 * @param {*} customerTypes customer type objects
 * @param {*} taxRate location tax rate
 * @param rkd_fee
 * @param merchant_fee
 * @param third_party_fee
 * @param {*} location location for peak time data
 * @param resourceType
 * @returns object with base price, taxes and fees, total, and customer type totals
 */
export function calculatePriceByParticipant(pakage, booking, customerTypeCounts, customerTypes, auto_gratuity_rate, taxRate, rkd_fee, merchant_fee, third_party_fee, location, resourceType) {
    if (!moment(booking?.start_time).isValid() || !Boolean(booking?.participants)) {
        return { customerTypeTotals: {} }
    }

    if (debug && console) {
        console.log('-------------------------------------------')
        console.log('Calculating Price By Participant')
    }

    // find the general group for the booking that we're using which contains pricing array
    const priceGroup = pakage.pricing.filter((pricingGroup) => {
        return Number.parseInt(booking.participants) >= Number.parseInt(pricingGroup.groupMin) && Number.parseInt(booking.participants) <= Number.parseInt(pricingGroup.groupMax)
    })[0]

    const discountRecord = getDiscountForBooking(booking)

    // object that will be used and added to while looping
    let totals = {
        discount: 0.0,
        subtotal: 0.0,
        types:    [],
    }

    priceGroup.prices.forEach((customerTypePrices) => {
        customerTypePrices.filter(t => t.duration === booking.duration).forEach((durationPrice) => {
            if (Object.keys(customerTypeCounts).map(t => Number.parseInt(t)).includes(durationPrice.customerType))
            {
                const typeObject = customerTypes.filter(t => t.id === durationPrice.customerType)[0]

                let price    = Number.parseFloat(priceOrPeakPrice(booking, durationPrice, location, resourceType))
                let discount = 0.0
                const qty    = customerTypeCounts[durationPrice.customerType]
                const total  = price * qty

                if (!!discountRecord?.['amount'] && !!discountRecord?.['amount_type']) {
                    if (!!discountRecord?.customer_types) {
                        if (discountRecord.customer_types?.includes(typeObject?.id) && /percent/i.test(discountRecord['amount_type'])) {
                            discount = (price * (Number.parseFloat(discountRecord['amount']) / 100)) * qty
                        }
                    } else {
                        if (/percent/i.test(discountRecord['amount_type'])) {
                            discount = (price * (Number.parseFloat(discountRecord['amount']) / 100)) * qty
                        }
                        if (/dollar/i.test(discountRecord['amount_type'])) {
                            discount = Number.parseFloat(discountRecord['amount']) * qty
                        }
                    }
                }

                totals.types = totals.types.concat({
                    ...typeObject,
                    isGroup:      false,
                    price:        price,
                    quantity:     qty,
                    discount:     discount,
                    total:        total
                }).filter(t => t.quantity > 0)

                totals.discount += discount
                totals.subtotal += total
            }
        })
    })

    const autoGratuity = roundUp((totals.subtotal - totals.discount) * auto_gratuity_rate)
    const costTax      = (totals.subtotal - totals.discount) * (taxRate / 100)
    const costFees     = (totals.subtotal - totals.discount) * ((rkd_fee + merchant_fee + third_party_fee) / 100)

    return {
        basePrice:          (totals.subtotal - totals.discount),
        autoGratuity:       autoGratuity,
        taxesAndFees:       (costTax + costFees),
        total:              ((totals.subtotal - totals.discount) + autoGratuity + costTax + costFees),
        customerTypeTotals: totals
    }
}

export function calculateMemberPrice(pakage, booking, customerTypeCounts, location, resourceType) {
    if (!pakage.allow_member_benefits) { return null }
    if (pakage.pricing_type !== 'pricing_by_customer_type') { return null }
    if (!moment(booking?.start_time).isValid() || !Boolean(pakage?.pricing) || !Boolean(booking?.participants) || !customerTypeCounts) { return null }

    const priceGroup = pakage.pricing.filter((pricingGroup) => {
        return Number.parseInt(booking.participants) >= Number.parseInt(pricingGroup.groupMin) && Number.parseInt(booking.participants) <= Number.parseInt(pricingGroup.groupMax)
    })[0]

    let memberPrice

    priceGroup?.prices?.forEach((customerTypePrices) => {
        customerTypePrices.filter(t => t.duration === booking.duration).forEach((durationPrice) => {
            if (parseInt(durationPrice.customerType) === pakage.membership_customer_type_id) {
                memberPrice = Number.parseFloat(priceOrPeakPrice(booking, durationPrice, location, resourceType))
            }
        })
    })

    return memberPrice
}

export function calculatePriceUniform(pakage, booking, auto_gratuity_rate, taxRate, rkd_fee, merchant_fee, third_party_fee, location, qty, resourceType) {
    if (!moment(booking?.start_time).isValid() || !Boolean(booking?.participants)) {
        return { customerTypeTotals: {} }
    }

    if (debug && console) {
        console.log('-------------------------------------------')
        console.log('Calculating Price Uniform')
    }

    // find the general group for the booking that we're using which contains pricing array
    const priceGroup = pakage.pricing.filter((pricingGroup) => {
        return Number.parseInt(booking.participants) >= Number.parseInt(pricingGroup.groupMin) && Number.parseInt(booking.participants) <= Number.parseInt(pricingGroup.groupMax)
    })[0]

    const discountRecord = getDiscountForBooking(booking)
    const priceBlock     = priceGroup.prices?.filter(p => p.duration === booking.duration)?.[0]

    const price  = Number.parseFloat(priceOrPeakPrice(booking, priceBlock, location, resourceType))
    let discount = 0

    // discount formula
    // - if discount is a percentage
    //   - multiply the price x amount as a percentage
    //
    // - if discount is a dollar ammount and quantity is set
    //   - convert the discount dollars to cents
    //   - then divide total number of participants by 1 to find the per person dollar amount
    //   - then convert that amount back to whole dollars
    if (discountRecord) {
        discount = /percent/i.test(discountRecord['amount_type'])
            ? price * (Number.parseFloat(discountRecord['amount'] / 100))
            : (((Number.parseFloat(discountRecord['amount']) * 100) / (Number.parseInt(booking.participants) / 1)) / 100)
    }

    const subtotal     = (price - discount) * qty
    const costTax      = subtotal * (taxRate / 100)
    const costFees     = subtotal * ((rkd_fee + merchant_fee + third_party_fee) / 100)
    const autoGratuity = subtotal * auto_gratuity_rate

    const typeTotals = {
        types: [
            {
                isGroup:  false,
                name:     resourceType.customer_verbiage_singular,
                price:    price.toFixed(2),
                quantity: qty,
                discount: discount.toFixed(2),
                total:    subtotal.toFixed(2)
            }
        ],
        total: subtotal.toFixed(2)
    }

    return {
        basePrice:          subtotal,
        autoGratuity:       autoGratuity,
        taxesAndFees:       (costTax + costFees),
        total:              (subtotal + autoGratuity + costTax + costFees),
        customerTypeTotals: typeTotals
    }
}

/**
 * Return group pricing information
 */
export function calculatePriceGroup(pakage, booking, auto_gratuity_rate, taxRate, rkd_fee, merchant_fee, third_party_fee, location, qty, resourceType) {
    if (!moment(booking?.start_time).isValid() || !Boolean(booking?.participants)) { return }

    if (debug && console) {
        console.log('-------------------------------------------')
        console.log('Calculating Price Group')
    }

    // find the general group for the booking that we're using which contains pricing array
    const priceGroup = pakage.pricing.filter((pricingGroup) => {
        return Number.parseInt(booking.participants) >= Number.parseInt(pricingGroup.groupMin) && Number.parseInt(booking.participants) <= Number.parseInt(pricingGroup.groupMax)
    })[0]

    const priceBlock = priceGroup.prices.filter(p => p.duration === booking.duration)[0]

    // don't multiply price by quantity since it's grouped pricing -- the price applies for all
    const subtotal     = Number.parseFloat(priceOrPeakPrice(booking, priceBlock, location, resourceType))
    const autoGratuity = subtotal * auto_gratuity_rate
    const costTax      = subtotal * (taxRate / 100)
    const costFees     = subtotal * ((rkd_fee + merchant_fee + third_party_fee) / 100)

    const typeTotals = {
        types: [{
            isGroup:  true,
            name:     'Group',
            price:    subtotal.toFixed(2),
            quantity: qty,
            total:    subtotal.toFixed(2)
        }],
        total: subtotal.toFixed(2)
    }

    return {
        basePrice:          subtotal.toFixed(2),
        autoGratuity:       autoGratuity.toFixed(2),
        taxesAndFees:       (costTax + costFees).toFixed(2),
        total:              (subtotal + autoGratuity + costTax + costFees).toFixed(2),
        customerTypeTotals: typeTotals
    }
}

/**
 * Determine the tax rate and fees for a given location, package, and/or booking
 *
 * Values returned are as whole percentages (1.5 instead of 0.015)
 *
 * @param { object } a full location object: if reservations are taxable, the rate will be a positive number.
 * @param { object } a full package object: if a legacy booking/package, the rate will be NULL. Once a tax rate is updated on a location, a rate from 0-Infinity will be set on the package.
 * @return { object } a taxes and fees object
 */
export function taxRateAndFeesFor(location=null, pakage=null, booking={}) {
    if (!location || !pakage) { return }

    const BASE = {
        tax_rate: 0.0,
        third_party_reservation_fee: 0.0,
        merchant_reservation_fee: 0.0,
        rkd_reservation_fee: 0.0
    }

    const isPresent = (value) => {
        if (typeof value === 'number') {
            return value >= 0
        }
        return Boolean(value)
    }

    // first check the booking
    // ..then the package
    // ....finally the location
    // ......if all else fails, return 0.0
    try {
        const taxRate = (
            isPresent(booking?.legacy_tax_rate_and_fee_data)
                ? booking?.legacy_tax_rate_and_fee_data?.reservation_tax_rate
                : isPresent(pakage?.reservation_tax_rate)
                      ? pakage?.reservation_tax_rate
                      : location.reservation_tax_rate
        )

        const thirdPartyReservationFee = (
            isPresent(booking?.legacy_tax_rate_and_fee_data)
                ? booking?.legacy_tax_rate_and_fee_data?.reservation_third_party_fee
                : isPresent(pakage?.reservation_third_party_fee)
                    ? pakage?.reservation_third_party_fee
                    : location.third_party_reservation_fee
        )

        const merchantReservationFee = (
            isPresent(booking?.legacy_tax_rate_and_fee_data)
                ? booking?.legacy_tax_rate_and_fee_data?.reservation_merchant_fee
                : isPresent(pakage?.reservation_merchant_fee)
                    ? pakage?.reservation_merchant_fee
                    : location.merchant_reservation_fee
        )

        const rkdReservationFee = (
            isPresent(booking?.legacy_tax_rate_and_fee_data)
                ? booking?.legacy_tax_rate_and_fee_data?.reservation_rkd_fee
                : isPresent(pakage?.reservation_rkd_fee)
                    ? pakage?.reservation_rkd_fee
                    : location.rkd_reservation_fee
        )

        const taxRateAndFees = {
            tax_rate:                    taxRate                  || 0,
            third_party_reservation_fee: thirdPartyReservationFee || 0,
            merchant_reservation_fee:    merchantReservationFee   || 0,
            rkd_reservation_fee:         rkdReservationFee        || 0
        }

        if (debug && console) {
            console.log('TAX_RATE_AND_FEES_FOR', taxRateAndFees, booking.legacy_tax_rate_and_fee_data)
        }

        return taxRateAndFees
    } catch(e) {
        console.error(e)
        return BASE
    }
}

/**
 * Figure out whether to use the normal price or peak price based on the booking start date and the location settings
 *
 * @param {*} durationPrice individual duration price object that contains price and pricePeak attrs
 * @returns price as was input into the individual pricing input - either price or peak price
 */
function priceOrPeakPrice(booking, durationPrice, location, resourceType) {
    if ( !Boolean(booking) || !moment(booking?.start_time).isValid() || !Boolean(booking?.weekday) ) { return }

    if (debug && console) {
        console.log('-------------------------------------------')
        console.log('Finding price or peak price')
    }

    const startTime = booking.start_time instanceof moment ? booking.start_time : moment.tz(booking.start_time, location.time_zone)
    const weekday   = booking.weekday.toLowerCase()
    const peakStart = resourceType[`${weekday}_peak_start`] || location[`${weekday}_peak_start`]
    const peakEnd   = resourceType[`${weekday}_peak_end`]   || location[`${weekday}_peak_end`]

    // whenever we don't even have a peak start, end, or peak price to begin with, we just return the normal price.
    // this is to prevent the user from partially filling out their peak forms and breaking the pricing
    // NOTE: if they leave the actual price empty i'm not sure there's anything we can do here...
    if (!peakStart || !peakEnd || !durationPrice?.pricePeak) {
        if (!Boolean(durationPrice.price) || isNaN(durationPrice.price)) {
            console.error('> (GUARD) Could not find the price!')
            return 0
        }
        if (debug && console) {
            console.log('> (GUARD) Using normal pricing')
            console.log(
                weekday,
                resourceType[`${weekday}_peak_start`],
                location[`${weekday}_peak_start`],
                resourceType[`${weekday}_peak_end`],
                location[`${weekday}_peak_end`],
                durationPrice
            )
        }
        return durationPrice.price
    }

    const parsedPeakStart = applyTimeToBookingDate(startTime, peakStart, location.time_zone)
    const parsedPeakEnd   = applyTimeToBookingDate(startTime, peakEnd, location.time_zone)?.subtract(1, 'second')

    // If parsedPeakStart > parsedPeakEnd
    // that means the location is closing at/after midnight
    // so we need to add a day to the end time to push it ahead by a day to accurately match what peakEnd should really be
    if (parsedPeakStart > parsedPeakEnd) {
        parsedPeakEnd.add(1, 'day')
    }

    // it's weird, but trust me, it's necessary
    if (startTime < parsedPeakStart && startTime < parsedPeakEnd) {
        parsedPeakStart.subtract(1, 'day')
        parsedPeakEnd.subtract(1, 'day')
    }

    /*
     * This is useful/helpful debugging that is enough of a PITA to write
     * out each time that it warrants simply leaving it in the codebase
     *
    */
    if (debug && console) {
        console.log(`> startTime: ${startTime.toString()}`)
        console.log(`> peak start is from ${Boolean(resourceType[`${weekday}_peak_start`]) ? 'resource type' : 'location'}`)
        console.log(`> peak end is from ${Boolean(resourceType[`${weekday}_peak_end`]) ? 'resource type' : 'location'}`)
        console.log([ parsedPeakStart.toString(), startTime >= parsedPeakStart ])
        console.log([ parsedPeakEnd.toString(), startTime <= parsedPeakEnd ])
    }

    if (startTime >= parsedPeakStart && startTime <= parsedPeakEnd)
    {
        if (!Boolean(durationPrice.pricePeak) || isNaN(durationPrice.pricePeak)) {
            console.error('= Could not find the peak price!')
            return 0
        }
        if (debug && console) {
            console.log('= Using peak pricing')
            console.log('-------------------------------------------')
        }
        return durationPrice.pricePeak
    }
    else
    {
        if (!Boolean(durationPrice.price) || isNaN(durationPrice.price)) {
            console.error('= Could not find the price!')
            return 0
        }
        if (debug && console) {
            console.log('= Using normal pricing')
            console.log('-------------------------------------------')
        }
        return durationPrice.price
    }
}

/**
 * Apply a time onto a date
 *
 * @param {*} targetDate date to keep
 * @param {*} targetTime time to keep
 * @returns new moment object with a mix between the passed date and time
 */
export function applyTimeToBookingDate(targetDate, targetTime, timezone) {
    if (!targetDate || !targetTime) { return moment().tz(timezone) }

    const date_format = 'YYYY-MM-DD'
    const time_format = 'HH:mm:ss'

    const date = moment.tz(targetDate, timezone).format(date_format)
    const time = moment.tz(targetTime, timezone).format(time_format)

    // construct a localized date using the same format as above
    return moment.tz(`${date}T${time}`, timezone)
}

/**
 * Calculate the tip amount based on the type selected
 *
 * @param { float } booking subtotal (price only, no fees or taxes)
 * @param { float } amount the tip amount
 * @param { string } the type of tip
 * @returns a percentage of the subtotal or a simple dollar amount
 */
export function calculatedTip(subtotal, amount, type) {
    if (!subtotal || !amount || !type) { return 0 }

    if (/^percent$/i.test(type)) {
        return (Number.parseFloat(subtotal) * (Number.parseFloat(amount) / 100)).toFixed(2)
    }

    if (/^dollar/i.test(type)) {
        return Number.parseFloat(amount).toFixed(2)
    }

    return 0
}

/**
 * Return friendly versions of discount name, type, amount
 * given a raw discount object
 */
export function humanizedDiscount(discount) {
    // name of the discount
    // ex: "Halfies" or "Custom Discount"
    let name = discount?.discount_record?.name || discount?.name || 'Custom Discount'

    // type of the discount
    // ex: "20% Off" or "$4.00 Off"
    let type = ""

    if (discount?.type !== "membership") {
        type = /^percent$/i.test(discount?.amount_type || discount?.discount_record?.amount_type)
        ? `${Number.parseFloat(discount?.amount || discount?.discount_record?.amount || 0)}% OFF`
        : `${numberToCurrency(discount?.amount || discount?.discount_record?.amount || 0)} OFF`
    }

    // full calculated amount of the discount
    // ex: "-$20.50"
    let amount = numberToCurrency(Number.parseFloat((discount?.discount_cents || 0) / 100) * -1)

    // if the name already has a percentage in it, such as "20% Off This Weekend!",
    // then we don't also need to display a calculated "20% Off" as well.
    // ex: "Father's Day Weekend -20%"
    if (/\%/.test(name)) {
        type = ''
    }

    // if the discount is for each customer type, add "each" to the text
    // ex: "20% Off Each"
    if (discount?.customer_types?.length > 0) {
        type = `${type} Each`
    }

    // finally, surround the type details in parentheses
    // final ex: "Halfsies (50% Off)"
    if (type !== '') {
        type = `(${type})`
    }

    // if (debug && console) { console.log(discount, { name, type, amount }) }

    return { name, type, amount }
}
