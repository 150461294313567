import React, {useEffect, useState} from 'react'
import Checkbox from '../Form/Checkbox'
import FormRow from '../Form/FormRow'
import Input from '../Form/Input'
import Image from "../Form/Image"
import axios from "axios"
import Select from "../Form/Select"

export default function PackageGroupModalStyleTab({
  form,
  handleUpdate,
  handleCheckboxUpdate,
  handleManualUpdate,
  handleFormUpdate,
  directUploadUrl,
  imagesUrl,
  fonts,
  errors,
  loaded,
  newPackageGroup,
  handleTabLoaded
}) {
  const [previewImages, setPreviewImages] = useState({
    currentModalBackgroundImage: null
  })

  const [imagesFetched, setImagesFetched] = useState(false)
  const [imagesLoading, setImagesLoading] = useState(false)

  const formattedOptions = (fonts) => {
    return Object.keys(fonts).map((font) => {
      return { value: font, display: font }
    })
  }

  useEffect(() => {
    if (!loaded && newPackageGroup) {
      handleFormUpdate({
        ...form,
        primary_hex_color: '#000000',
        secondary_hex_color: '#000000',
        text_hex_color: '#000000'
      })
    }
  }, [])

  useEffect(() => {
    if (!loaded) { handleTabLoaded() }
  }, [])

  useEffect(() => {
    if (imagesFetched || !imagesUrl) {
      return
    }

    setImagesLoading(true)

    axios.get(imagesUrl).then(({ data }) => {
      setPreviewImages({
        currentModalBackgroundImage: data.modal_background_image
      })
    }).catch((error) => {
      console.warn('Unable to load images...')
    }).finally(() => {
      setImagesLoading(false)
      setImagesFetched(true)
    })
  }, [imagesFetched])

  return (
    <div>
      <h3 className="mt-0">Package Group Modal Branding</h3>

      <FormRow>
        <Input
          name='primary_hex_color'
          type='color'
          handleChange={handleUpdate}
          req={false}
          value={form.primary_hex_color || '#000000'}
          cols='4'
          errors={errors} />

        <Input
          name='secondary_hex_color'
          type='color'
          handleChange={handleUpdate}
          req={false}
          value={form.secondary_hex_color || '#000000'}
          cols='4'
          errors={errors} />

        <Input
          name='text_hex_color'
          type='color'
          handleChange={handleUpdate}
          req={false}
          value={form.text_hex_color || '#000000'}
          cols='4'
          errors={errors} />
      </FormRow>

      <Checkbox
        handleChange={handleCheckboxUpdate}
        name='use_dark_text_image'
        label="Dark logo on light background?"
        value={form.use_dark_text_image === null ? true : form.use_dark_text_image} />

      <Image
        loading={imagesLoading}
        name='modal_background_image'
        handleChange={handleManualUpdate}
        previewUrl={previewImages.currentModalBackgroundImage}
        directUploadUrl={directUploadUrl}
        tooltip='Minimum 800 x 600 pixels, recommended 1200 x 700 pixels' />

      <FormRow>
        <Select
          name='modal_font'
          label='Modal Font'
          handleChange={handleUpdate}
          options={formattedOptions(fonts)}
          value={form.modal_font}
          cols='6'
          errors={errors}
        />
      </FormRow>
    </div>
  )
}
