import React from 'react'
import FormMappings from '../../lib/FormMappings'

/**
 * Dynamically render the drawer record form based on the `type` prop that is passed in.
 *
 * For the most part this code shouldn't have to be touched since it's more of a helper
 * to render individual forms that are nested within the drawers. In other words, no actual
 * form functionality should live here at all.
 */
export default function RecordDrawerForm(props) {
    // dynamically find our component based on the type that is passed in... the result here is used as a component
    const TypeForm = FormMappings[props.type]

    if (!TypeForm) {
        console.warn('Unsupported form: ', props.type)
        return null
    }

    return (
        <TypeForm {...props} />
    )
}
