import moment from 'moment'
import { debug } from '@/lib/Debug'
export default null

export function durationsPrices(durations, customerTypeId) {
    return durations.map(duration => {
        return {
            duration:     duration.duration,
            customerType: customerTypeId,
            price:        null,
            pricePeak:    null,
            _durationId:  duration._id
        }
    })
}

export function isValidCode(participants, code=null, bookingStartTime=null, tz=null, pkg=null, resourceType=null) {
    if (!code || !bookingStartTime || !tz || !pkg || !resourceType) { return { isValid: false } }

    if (console && debug) { console.log('isValidCode?', bookingStartTime, tz, pkg, resourceType) }

    try {
        const findMatchingCodes = (promotions) => (
            promotions.filter((promo) => RegExp(`^${promo.code}$`, 'i').test(code))
        )

        const validateCodes = (promotions) => {
            let result = {}

            promotions.forEach((promotion) => {
                // stop once we've found a valid promotion
                if (result?.['isValid'] === true) { return }

                if (Boolean(promotion.weekday_restrictions) && !!promotion.applied_days_of_week && !promotion.applied_days_of_week.includes(moment.tz(bookingStartTime, tz).format('dddd').toLowerCase())) {
                    result['isValid']      = false
                    result['errorMessage'] = "I'm sorry the reservation date does not meet promo code requirements."

                } else if (promotion.minimum_group_size && parseInt(promotion.minimum_group_size) > parseInt(participants)) {
                    result['isValid']      = false
                    result['errorMessage'] = 'Group size does not meet minimum for this promo code.'

                } else if (promotion.limit && parseInt(promotion.limit) <= promotion.current_usage) {
                    result['isValid'] = false

                } else if (!!promotion.valid_from && !!promotion.valid_to) {
                    const from = moment.tz(promotion.valid_from, tz).format('YYYY-MM-DD')
                    const to   = moment.tz(promotion.valid_to, tz).format('YYYY-MM-DD')

                    let date

                    if (promotion.date_range_type === 'created_at') {
                        date = moment().tz(tz).format('YYYY-MM-DD')
                    } else {
                        date = moment.tz(bookingStartTime, tz).format('YYYY-MM-DD')
                    }

                    if (console && debug) {
                        console.log( 'code within date range', [
                            code + ' is valid? ' + RegExp(`^${promotion.code}$`, 'i').test(code),
                            from.toString(),
                            date.toString(),
                            to.toString(),
                            'after from? ' + moment(date).isSameOrAfter(from),
                            'before to? ' + moment(date).isSameOrBefore(to)
                        ])
                    }

                    if (moment(date).isSameOrAfter(from) && moment(date).isSameOrBefore(to)) {
                        result['isValid']   = true
                        result['promotion'] = promotion
                    } else {
                        result['isValid'] = false
                        result['errorMessage'] = "I'm sorry the date range for this reservation does not meet promo code requirements."
                    }
                } else {
                    result['isValid']   = true
                    result['promotion'] = promotion
                }
            })

            return result
        }

        let result = {}

        // look on the package first
        if (pkg.allow_promo_codes) {
            const pkgCodes = findMatchingCodes(pkg.promo_codes)
            result         = validateCodes(pkgCodes)
        }

        // then look on the resource type if not found
        if (Object.keys(result).length === 0 && resourceType.allow_promo_codes) {
            const rtCodes = findMatchingCodes(resourceType.promo_codes)
            result        = validateCodes(rtCodes)
        }

        // return either a promotion object or an empty object
        return result
    } catch (e) {
        if (console) { console.error('Cannot parse code: ', e) }
        return { isValid: false }
    }
}
