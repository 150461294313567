import React from 'react'
import { numberToCurrency } from '@/lib/Number'

export default function StoredCreditCard({
    card=null,
    className='d-flex align-items-center justify-content-start text-monospace',
    iconSize='', // https://docs.fontawesome.com/v5/web/style/size
    transactionId=null,
    shopperReference=null,
    selectable=false,
    isSelected=false,
    showPreAuthorizedAmount=false,
    onClick=() => {},
}) {
    const isSelectable = selectable && (!!shopperReference || transactionId)

    return !!card && (
        <span
            id={transactionId ? `payment-${transactionId}` : null}
            className={`stored-credit-card mr-auto ${className} ${isSelectable ? 'cursor-pointer' : ''}`.trim()}
            onClick={onClick}
        >
            <i className={`${!!card?.icon ? card.icon : 'fas fa-credit-card'} ${iconSize}`} />

            {
                card?.brand && !card?.name && (
                     <span className='pl-3 text-uppercase' children={card?.brand} />
                )
            }

            {
                card?.name && (
                    <span className='pl-3' children={card?.name} />
                )
            }

            <span className='pl-3'>
                {`(${card?.lastFour || card?.last_digits} ${card?.expiryMonth || card?.month}/${card?.expiryYear || card?.year})`}
            </span>

            {
                showPreAuthorizedAmount && card?.maximum_authorized_amount_cents && (
                    <span className='pl-4'>
                        {numberToCurrency(card.maximum_authorized_amount_cents / 100)}
                        <small className='pl-2 text-gray5'>AUTH</small>
                    </span>
                )
            }

            {
                isSelectable && (
                    <span>
                        <input
                            readOnly
                            type="radio"
                            name="on_file_card"
                            value={shopperReference || transactionId}
                            checked={isSelected}
                            onClick={onClick}
                        />
                    </span>
                )
            }
        </span>
    )
}
