import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCreditToken, selectCreditToken, setCardZip } from '@/features/BookingPayment/bookingPaymentSlice'
import { formatPaymentProfile, isAdyenProfile, isPaysafeProfile } from '@/lib/PaymentProfile'

export default function OnFileProfile({ profile }) {

    if (!isAdyenProfile(profile) && !isPaysafeProfile(profile)) { return null }

    const dispatch    = useDispatch()
    const creditToken = useSelector(selectCreditToken)

    const handleChange = (e) => {
        const newToken = e.target.value

        if (creditToken === newToken) {
            dispatch(setCreditToken(null))
            dispatch(setCardZip(null))
        } else {
            dispatch(setCreditToken(newToken))
            dispatch(setCardZip(profile.zip))
        }
    }

    return formatPaymentProfile(profile).cards.map((card, index) => {
        const isSelected = creditToken === card.paymentToken

        const cardExpiration = !!card?.cardExpiry.month && !!card?.cardExpiry.year
                                   ? <span className='pl-5' children={`EXPIRES (${card.cardExpiry.month}/${card.cardExpiry.year})`} />
                                   : ''

        const classes = [
            'list-group-item cursor-pointer p-0',
            index === 0 ? '' : 'mt-3',
            isSelected ? 'border-primary text-primary' : '',
        ]

        return (
            <li key={index} className={classes.join(' ').trim()}>
                <label className='d-flex align-items-center font-weight-bold cursor-pointer py-3 px-4'>
                    <span className='card-type text-uppercase'>
                        <i className="fas fa-credit-card mr-2" />
                        <span dangerouslySetInnerHTML={{ __html: card.cardType?.padEnd(4, '_').replace(/_/g, ' &nbsp;') }} />
                    </span>

                    <span className='pl-3'>
                        {card.lastDigits}
                        {cardExpiration}
                    </span>

                    <input
                        readOnly
                        type="radio"
                        name="on_file_card"
                        value={card.paymentToken}
                        checked={isSelected}
                        onClick={handleChange}
                    />
                </label>
            </li>
        )
    })
}
