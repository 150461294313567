import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import moment from 'moment'
import { concatBookings } from '@/features/CalendarBookings/calendarBookingsSlice'
import { assignBookingsToTimeColumns } from '@/lib/CalendarTimes'

export const calendarSearch = createSlice({
    name: 'calendarSearch',
    initialState: {
        open: false,
        search: null,
        loading: true,
        results: [],
        searchedQuery: null,
    },
    reducers: {
        setOpen: (state, action) => {
            state.open = action.payload
        },
        setSearch: (state, action) => {
            state.search = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setResults: (state, action) => {
            state.results = action.payload
        },
        setClosed: (state) => {
            state.open = false
            state.results = []
        },
        setSearchedQuery: (state, action) => {
            state.searchedQuery = action.payload
        },
    }
})

export const {
    setOpen,
    setClosed,
    setSearch,
    setLoading,
    setResults,
    setSearchedQuery,
} = calendarSearch.actions

export const selectOpen          = state => state.calendarSearch.open
export const selectSearch        = state => state.calendarSearch.search
export const selectLoading       = state => state.calendarSearch.loading
export const selectResults       = state => state.calendarSearch.results
export const selectSearchedQuery = state => state.calendarSearch.searchedQuery

export function performSearch() {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        const locationId = getState().calendar.locationId
        const companyId  = getState().calendar.companyId
        const search     = getState().calendarSearch.search

        if (search === null) { return }

        // we set the searched query to the present state of the search so the user can see
        // the searched value in the drawer without it typing as they change their search...
        // the drawer search param will only update when they hit enter again.
        dispatch(setSearchedQuery(search))

        axios.get(`/calendars/search?q=${search}&compact=1`)
        .then(({ data }) => {
            /**
             * Attempt to inject any returned results that are for the current day into
             * calendarBookings.bookings if it doesn't already exist in the array, such
             * as if the original query only returned a limited result/slice of bookings
             * (e.g.: the last 3hrs initial load)
             */
            if (Array.isArray(data) && data.length > 0) {
                const timezone           = getState().location?.location?.time_zone
                const loadedDates        = getState().calendar.loadedDates
                const viewedDate         = getState().calendar.viewedDate
                const lastLoadedDatetime = getState().calendar.lastLoadedDatetime
                const calendarBookingIds = getState().calendarBookings?.bookings?.map((cb) => cb.booking.id)
                let newBookings          = []

                data.forEach((record) => {
                    if (
                        (moment.tz(record.booking.start_time, timezone).format('YYYY-MM-DD') == viewedDate)
                        && !calendarBookingIds.includes(record.booking.id)
                    ) {
                        newBookings = newBookings.concat(
                            assignBookingsToTimeColumns(
                                [record],
                                loadedDates,
                                lastLoadedDatetime,
                                timezone
                            )
                        )
                    }
                })

                if (newBookings.length > 0) {
                    dispatch(concatBookings(newBookings))
                }
            }

            // reset the search input, set the search
            // results data, and open the results drawer
            dispatch(setSearch(null))
            dispatch(setResults(data))
            dispatch(setOpen(true))
        }).catch(e => {
            if (console) { console.warn(e) }
        }).then(() => {
            dispatch(setLoading(false))
        })
    }
}

export default calendarSearch.reducer
