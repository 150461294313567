import React, { useState, useEffect, useMemo } from 'react'
import FormRow from '@/components/Form/FormRow'
import MultiSelectCheckboxes from '@/components/Form/MultiSelectCheckboxes'
import ExternalSelectedList from '@/components/AdvancedPointOfSale/ExternalSelectedList'
import { pluckKeyFromObjectsInArray } from '@/lib/Object'
import { sortedByArray } from '@/lib/Array'

export default function CategoryItems({
    form,
    errors=[],
    items=[],
    handleFormUpdate,
}) {

    const [selectedItems, setSelectedItems] = useState([])

    const defaultPinnedItems = useMemo(() => (
        selectedItems.filter(item => item.is_default)
    ), [selectedItems])

    const handleItemsUpdate = (updatedItems, categoryItem=null, isRemove=false) => {
        // categoryItem is null on sort
        const items_sort_order = pluckKeyFromObjectsInArray(updatedItems, 'item_id')

        let copy

        if (categoryItem) {
            if (isRemove) {
                if (categoryItem.id) {
                    copy = [
                        ...form.category_items_attributes.filter(item => item.item_id !==  categoryItem.item_id),
                        { id: categoryItem.id, _destroy: '1' /* true */ }
                    ]
                } else {
                    // we need to get category_item_id here because categoryItem.id is undefined when item is removed from multiselect
                    const removedCategoryItem = form.category_items_attributes.find(item => item.item_id ===  categoryItem.item_id)

                    copy = [
                        ...form.category_items_attributes.filter(item => item.item_id !==  categoryItem.item_id),
                        { id: removedCategoryItem.id, _destroy: '1' /* true */ }
                    ]
                }
            } else {
                copy = [...form.category_items_attributes, categoryItem]
            }
        } else {
            copy = [...form.category_items_attributes]
        }

        handleFormUpdate({ ...form, ...{
            category_items_attributes: copy,
            items_sort_order: items_sort_order
        }})
    }

    const handleIconChange = (itemId, isDefault) => {
        const copy = [...form.category_items_attributes]
        const index = copy.findIndex(obj => obj.item_id === itemId)

        copy[index] = { ...copy[index], is_default: isDefault }

        handleFormUpdate({ ...form, category_items_attributes: copy })
    }

    /*
    * Update item list whenever one is deleted and
    * mark the old item as "to be deleted" with _destroy = 1/true
    */
    useEffect(() => {
        setSelectedItems(
            form.category_items_attributes.filter(categoryItem => !categoryItem._destroy)
        )
    }, [form.category_items_attributes])


    return (
        <div>
            <div className='alert alert-secondary rounded font-italic font-size-initial'>
                Content defined in this category is treated as a default. It may or may not be overridden by a menu at a later date.
                This includes items, sub-categories, sort ordering, and whether an item is "pinned" to the top of the category.
            </div>

            <ExternalSelectedList
                sectionTitle='Default Pinned Items'
                collection={sortedByArray(defaultPinnedItems, form.items_sort_order, 'item_id')}
                schema={{
                    parentId: 'item_id',
                    parentName: 'name',
                }}
                onRemove={handleIconChange}
            />

            <h3>Items</h3>
            <FormRow>
                <MultiSelectCheckboxes
                    hideLabel={true}
                    options={items}
                    selectedValues={sortedByArray(selectedItems, form.items_sort_order, 'item_id')}
                    useExternalList={true}
                    externalListIsSortable={true}
                    externalListIconOnMainList='fa-bookmark'
                    emptyRecordMsg='No items available.'
                    errors={errors}
                    validation={{ items: { required: false }}}
                    onSelect={handleItemsUpdate}
                    onRemove={handleItemsUpdate}
                    onSort={handleItemsUpdate}
                    onIconChange={handleIconChange}
                />
            </FormRow>
        </div>
    )
}
