import React from 'react'
import { useSelector } from 'react-redux'
import { selectMembers } from '../EditBooking/editBookingSlice'
import NumberIncrementer from '../../components/Form/NumberIncrementer'

export default function AddonUniform({ typeCounts, setTypeCounts, max }) {
    const members = useSelector(selectMembers)

    return (
        <div className="row">
            <div className="col-12">
                <div className="form-group">
                    <label>Number of {members.length > 0 ? "Non-Member" : ""} Participants</label>

                    <NumberIncrementer
                        name='customer_type_counts'
                        value={typeCounts || 0}
                        incrementAmount={1}
                        min={0}
                        max={max}
                        handleChange={e => setTypeCounts(e)} />
                </div>
            </div>
        </div>
    )
}
