import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setPaymentMethod, selectPaymentReady } from "@/features/Bookings/bookingSlice";
import { addAlert } from "@/features/Notifications/notificationSlice";
import { debug } from "@/lib/Debug";

export default function CreditCard({
    eventToListenTo='click',
    ignoreNextOnError,
    creditToken,
    setToken,
    setZip,
    onError = () => {},
    onNext=() => {},
}) {
    const dispatch = useDispatch();
    const paymentReady = useSelector(selectPaymentReady);

    const [init, setInit] = useState(false);

    useEffect(() => {
        if (init) {
            return;
        }

        setInit(true);
        setToken(null);

        // request payment credentials
        axios.get("/credentials/payments").then(({ data }) => {
            if (debug && console) {
                console.log(data);
            }
            initializePaysafe(data.username, data.password, data.environment);
        });
    }, [init]);

    const initializePaysafe = (username, password, environment) => {
        const apiKey = window.btoa(`${username}:${password}`);

        const options = {
            environment: environment,
            fields: {
                cardNumber: {
                    selector: "#cardNumber",
                    placeholder: "Card Number",
                    separator: " ",
                },
                expiryDate: {
                    selector: "#expiryDate",
                    placeholder: "Expiry date",
                },
                cvv: {
                    selector: "#cvv",
                    placeholder: "CVV",
                    optional: false,
                },
            },
        };

        window.paysafe.fields.setup(apiKey, options, (instance, error) => {
            if (error) {
                if (console) {
                    console.error("Paysafe setup error: ", error);
                }
                return;
            }

            // listen to payment button click -- when that happens it will just get a token,
            // this will not submit a form or anything -- that is passed into this component
            // and is fired off when a token is passed in as a prop to ensure it's set since
            // everything is async
            document.getElementById("finish-button").addEventListener(eventToListenTo, () => {
                instance.tokenize((_instance, error, result) => {
                    if (error) {
                        if (console) { console.error('Paysafe error: ', error) }

                        dispatch(addAlert({ type: 'error', text: error.displayMessage }))

                        if ((!!error?.fieldErrors && error.fieldErrors.length > 0) === false) {
                            dispatch(addAlert({ type: 'error', text: 'An error occurred during payment.' }))
                        }

                        if (onError) {
                            onError(error.displayMessage);
                        }

                        if (!ignoreNextOnError) {
                            onNext(); // this will trigger normal form validation
                        }

                        return
                    } else {
                        setToken(result.token);
                    }
                });
            });
        });
    };

    // if payment is ready because gift cards are going to be used
    // to pay off the complete balance, then change the payment method
    // and bypass the credit card fields/validation
    useEffect(() => {
        if (paymentReady === null) {
            return;
        }
        if (debug && console) {
            console.log(`PAYMENT READY? ${paymentReady}`);
        }
        dispatch(setPaymentMethod(paymentReady ? "gift_card" : "credit_card"));
    }, [paymentReady]);

    // if the credit card was tokenized, fire up the component chain
    // and trigger the onNext() functionality, usually this is formMethods.submit()
    useEffect(() => {
        if (creditToken) {
            onNext();
        }
    }, [creditToken]);

    return (
        <>
            <div className="row form-row">
                <div className="col-12">
                    <div className="form-group">
                        <div id="cardNumber" className="form-control"></div>
                    </div>
                </div>
            </div>
            <div className="row form-row">
                <div className="col-md-4">
                    <div className="form-group">
                        <div id="expiryDate" className="form-control"></div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <div id="cvv" className="form-control"></div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <input
                            className="form-control"
                            placeholder="ZipCode"
                            maxLength="5"
                            onChange={(e) => setZip(e)}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
