import React, { useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { selectResources } from '@/features/Resources/resourcesSlice'
import { selectBooking, selectSizeHeight, setSelectedBookingTopOffset } from '@/features/Calendar/calendarSlice'
import { setStep, setOpen } from '@/features/Schedule/scheduleSlice'
import { setBooking, flagBookingDrawerOpen } from '@/features/EditBooking/editBookingSlice'
import { selectTimeZone } from '@/features/Locations/locationSlice'
import { isBookingCancelled, isBookingComplete, isBookingQuote, textStatusForBooking, textStatusForQuote } from '@/lib/Booking'
import { parseResourceRow } from '@/lib/Resources'
import { UI } from '@/lib/Constants'

export default function RecentlyViewed({ location }) {

    const dispatch         = useDispatch()
    const selectedTimeZone = useSelector(selectTimeZone)
    const sizeHeight       = useSelector(selectSizeHeight)
    const allResources     = useSelector(selectResources)

    const [drawerOpen, setDrawerOpen] = useState(false)
    const [bookings, setBookings]     = useState(null)

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            setDrawerOpen(false)
        }
    }

    const handleOpen = () => {
        axios.get(`/bookings/recently_viewed?location_id=${location.id}`).then(({ data }) => {
            setBookings(data)
            setDrawerOpen(true)
        })
    }

    const handleClick = (booking) => {
        if (booking.location_id !== location.id) {
            window.location.href = `/companies/${booking.company_id}/locations/${booking.location_id}/calendar/${booking.reservation_number}`
        } else {
            /**
             * The row number of our booking's resource
             *
             * @TODO we could technically do the same thing we are doing with time columns and do this once
             * when the bookings are fetched from the server, however to do that we have to pass the data to
             * the thunk in order to keep passing around which could get a little messy. In theory there should
             * only be up to a dozen or so resources for the largest locations, so parsing over these should
             * be almost instant, whereas time slots have several hundred once they start scrolling.
             */
            const resourceRow = parseResourceRow(booking.resources, allResources)
            setDrawerOpen(false)
            dispatch(selectBooking(booking))
            dispatch(setSelectedBookingTopOffset((resourceRow * sizeHeight) - sizeHeight))
            booking.is_parent ? handleParentBookingClick(booking) : handleBookingClick(booking)
        }
    }

    const handleBookingClick = (booking) => {
        axios.get(`/bookings/${booking.id}/information`)
        .then(({ data }) => {
            dispatch(setBooking({ booking: data.booking, resources: data.resources }))
            dispatch(flagBookingDrawerOpen(booking.id, true))
            dispatch(setStep('3'))
            dispatch(setOpen(true))
        })
    }

    const handleParentBookingClick = (booking) => {
        axios.get(`/bookings/${booking.id}/information`)
        .then(({ data }) => {
            dispatch(setParentBooking({ booking: data.booking, resources: data.resources }))
            dispatch(setChildBookings({ childBookings: data.child_bookings }))
            dispatch(flagBookingDrawerOpen(booking.id, true))
            dispatch(setParentBookingModalOpen(true))
        })
    }

    const renderStatus = (booking, pkg) => {
        let status = isBookingQuote(booking) ? textStatusForQuote(booking) : textStatusForBooking(booking)
        let statusClass = 'right '

        if (isBookingCancelled(booking) || /rejected/i.test(status)) {
            statusClass += 'cancelled'
        }

        return (
            <p className={statusClass}>
                <label>{isBookingQuote(booking) ? 'Quote' : pkg.is_special_event ? 'Special Event' : 'Reservation'} Status:</label>
                {status}
            </p>
        )
    }

    const renderPartySize = (booking, child_bookings=[], pkg) => {
        const participantCount = child_bookings ? child_bookings.reduce((sum, cb) => sum + parseInt((cb.group_max || cb.participants), 10), 0) : 0

        return pkg.is_special_event
            ? `${participantCount} - ${pkg.special_event_capacity || '??'}`
            : booking.participants
    }

    /**
     * Handle the initial mount and
     * unmount of the component
     */
    useEffect(() => {
        document.body.classList.add(UI.classes.MULTIPLE_MODALS_OPEN)
        document.addEventListener('keydown', handleKeyDown, false)

        // cleanup/reset on unmount
        return () => {
            document.body.classList.remove(UI.classes.MULTIPLE_MODALS_OPEN)
            document.removeEventListener('keydown', handleKeyDown, false)
        }
    }, [])

    return <>
        <div id='recently-viewed'>
            <button className="btn btn-link" title="Recently Viewed Reservations" onClick={handleOpen}>
                <i className="far fa-history h5 mb-n1" />
            </button>
        </div>

        { drawerOpen &&
        <div className="drawer drawer-booking-open">
            <div className="drawer-scaffold">
                <div className="drawer-main">

                    <div className="drawer-header-container">
                        <div className="drawer-header">
                            <h2>Recently Viewed</h2>
                        </div>
                    </div>

                    <div className="drawer-main-content">
                        <div className="drawer-scroller">
                            <div className='calendar-search-results row py-3'>
                                {
                                    bookings.map((data, i) => {
                                        const booking        = data.booking
                                        const pkg            = data.package
                                        const child_bookings = data.child_bookings
                                        return (
                                            <div className="col-12 col-md-6" key={`result_${booking.id}_${i}`}>
                                                <div className="result-card" key={booking.id} onClick={() => handleClick(booking)}>
                                                    <div className="result-card-header">
                                                        {
                                                            !(isBookingComplete(booking) && pkg?.is_a_league) && (
                                                                <div className="party-size">{renderPartySize(booking, child_bookings, pkg)}</div>
                                                            )
                                                        }
                                                        <div className="party-name">{booking.name}</div>
                                                        <div className="event-type">{pkg.name}</div>
                                                    </div>
                                                    <div className="result-card-body flex-column">
                                                        <div className="font-weight-bold">
                                                            Location:&nbsp;&nbsp;&nbsp;{booking.location_name}
                                                        </div>
                                                        <div className="font-weight-bold mb-2">
                                                            Resource Type:&nbsp;&nbsp;&nbsp;{booking.resource_type.title}
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                        <p>
                                                            <label>reservation date:</label>
                                                            {moment(booking.start_time).tz(selectedTimeZone).format('LLL')}
                                                        </p>
                                                        <p className="center">
                                                            <label>number:</label>
                                                            {booking.reservation_number}
                                                        </p>
                                                        {renderStatus(booking, pkg)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    <div className="actions">
                        <button className='close-drawer btn btn-link btn-link-danger close mr-auto' onClick={() => setDrawerOpen(false)}>
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
        }
    </>
}
