import React from 'react'
import { useSelector } from 'react-redux'
import DateSelect from './DateSelect'
import { selectLabelWidth } from './calendarSlice'

export default function SeeToday() {

    const labelWidth = useSelector(selectLabelWidth)

    return (
        <div className="time today" style={{ width: `${labelWidth}px` }} >
            <DateSelect />
        </div>
    )
}
