import { useDispatch } from 'react-redux'
import {
    showManagerOverride,
    hideManagerOverride,
    setManagerOverrideOptions,
} from '@/features/ManagerCodes/managerCodeSlice'
import { UI } from '@/lib/Constants'

let resolveCallback

export function useManagerOverride() {
    const dispatch = useDispatch()

    const onAuthorization = () => {
        document.body.classList.remove(UI.classes.MULTIPLE_MODALS_OPEN)
        dispatch(hideManagerOverride())

        if (typeof resolveCallback === 'function') {
            resolveCallback(true)
        }
    }

    const onCancellation = () => {
        document.body.classList.remove(UI.classes.MULTIPLE_MODALS_OPEN)
        dispatch(hideManagerOverride())

        if (typeof resolveCallback === 'function') {
            resolveCallback(false)
        }
    }

    const authorize = (options={}) => {
        if (Object.keys(options).length > 0) {
            dispatch(setManagerOverrideOptions(options))
        }

        document.body.classList.add(UI.classes.MULTIPLE_MODALS_OPEN)
        dispatch(showManagerOverride())

        return new Promise((resolve) => {
            resolveCallback = resolve
        })
    }

    return { authorize, onAuthorization, onCancellation }
}
