export default null

export function isAdyenProfile(profile) {
    return profile?.storedPaymentMethods?.length > 0
}

export function isPaysafeProfile(profile) {
    return profile?.cards?.length > 0
}

// -------------------------------------------------------------------------------------------------------------------
// PAYSAFE PAYMENT PROFILE
// -------------------------------------------------------------------------------------------------------------------
// {"paymentMethods"=>
//   [{"name"=>"Credit Card", "type"=>"scheme", "brands"=>["amex", "cup", "diners", "discover", "mc", "visa"]},
//    {"name"=>"Pay later with Klarna.", "type"=>"klarna"},
//    {"name"=>"Pay over time with Klarna.", "type"=>"klarna_account"}],
//  "storedPaymentMethods"=>
//   [{"id"=>"RC3QZH9P8WHGZG65",
//     "name"=>"VISA",
//     "type"=>"scheme",
//     "brand"=>"visa",
//     "lastFour"=>"0008",
//     "expiryYear"=>"30",
//     "holderName"=>"Checkout Shopper PlaceHolder",
//     "expiryMonth"=>"03",
//     "networkTxReference"=>"239411166214167",
//     "supportedShopperInteractions"=>["Ecommerce", "ContAuth"],
//     "supportedRecurringProcessingModels"=>["CardOnFile", "Subscription", "UnscheduledCardOnFile"]}],
//  "recurringProcessingModel"=>"CardOnFile",
//  "recurring.shopperReference"=>"1689607186-cf6d2a010f8b",
//  "recurring.recurringDetailReference"=>"RC3QZH9P8WHGZG65"}
// -------------------------------------------------------------------------------------------------------------------
export function formatPaymentProfile(profile) {
    if (isPaysafeProfile(profile)) {
        // do nothing for paysafe
        return profile
    }

    return formatAdyenProfile(profile)
}

/**
 * Format Adyen payment profile to match data structure of Paysafe
 * to keep the JS consistent without overly branched code.
 * For data integrity purposes we keep Adyen payment_profile
 * records exactly how they give it to us instead of modifying
 * it to match and possibly losing some data. Using this library
 * should keep everything consistent in the UI.
 */
function formatAdyenProfile(profile) {
    // - cards []
    //   - cardType
    //   - cardExpiry.month
    //   - cardExpiry.year
    //   - lastDigits
    //   - paymentToken
    return {
        cards: profile.storedPaymentMethods.map((p) => ({
            brand: p.brand,
            cardType: p.brand,
            cardExpiry: {
                month: p.expiryMonth,
                year: p.expiryYear,
            },
            lastDigits: p.lastFour,
            paymentToken: formatAdyenToken(profile, p),
        }))
    }
}

/**
 * Format Adyen saved card as token. Unlike Paysafe, when you
 * want to use Adyen stored ards, you have to provide both
 * the token and the shopper reference ID. Instead of creating
 * new attributes for all this, we just use the current creditToken
 * attribute with any required attributes separated with | symbols
 * to be split out and parsed however we need. The idea is for the
 * workflow to be the same and Adyen can parse them however we want.
 */
export function formatAdyenToken(profile, card) {
    // <shopper_reference>|<card_token>
    return `${profile['recurring.shopperReference']}|${card.id}`
}
