import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectLocation } from '@/features/Locations/locationSlice'
import { setLoading } from '@/features/Schedule/scheduleSlice'
import { addAlert } from '@/features/Notifications/notificationSlice'

import {
    selectBooking,
    selectOpenAddCardToBookingModal,
    setOpenAddCardBookingModal,
    addCardToBooking,
} from '@/features/EditBooking/editBookingSlice'

import {
    selectTerminalResponse,
    tokenizeCardViaTerminal,
    preAuthorizeCardViaTerminal,
    cancelTerminalTransaction,
    clearAdyenState,
} from '@/features/Adyen/adyenSlice'

import AdyenTerminalTransaction from '@/features/Terminal/AdyenTerminalTransaction'
import PreAuthorizationAmount from '@/features/AdvancedPointOfSale/components/PreAuthorizationAmount'
import { PREVENT_LOADER } from '@/lib/Storage'

export default function AddCardToBookingModal({
    method='PRE_AUTHORIZE', // or TOKENIZE
}) {

    const dispatch         = useDispatch()
    const booking          = useSelector(selectBooking)
    const location         = useSelector(selectLocation)
    const isOpen           = useSelector(selectOpenAddCardToBookingModal)
    const terminalResponse = useSelector(selectTerminalResponse)

    const [amountToPreAuthorize, setAmountToPreAuthorize] = useState(null)

    const handleClose = () => {
        dispatch(cancelTerminalTransaction())
        dispatch(setOpenAddCardBookingModal(false))
        dispatch(clearAdyenState())
    }

    const handleTokenizeCard = () => {
        dispatch(setLoading(true))
        dispatch(tokenizeCardViaTerminal(location.id))
    }

    const handlePreAuthorizeCard = () => {
        dispatch(setLoading(true))

        dispatch(preAuthorizeCardViaTerminal(
            amountToPreAuthorize,
            location.id,
            booking.id,
            'booking'
        ))
        .then((data) => {
            if (data.success) {
                if (data.success) {
                    dispatch(addAlert({ type: 'success', text: data.message }))
                } else {
                    dispatch(addAlert({ type: 'error', text: data.message }))
                }
            }
        })
        .catch((e) => {
            if (console) { console.error(e.response.data.error) }
            dispatch(addAlert({ type: 'error', text: e.response.data.message }))
        })
        .finally(() => {
            dispatch(setLoading(false))
            handleClose()
        })
    }

    // NOTE: TOKENIZE-ONLY
    //
    // automatically save and close once the
    // response comes back from the terminal
    useEffect(() => {
        if (isOpen && !!terminalResponse) {
            dispatch(addCardToBooking(booking.id, terminalResponse))
            .then(({ data }) => {
                if (data.success) {
                    dispatch(addAlert({ type: 'success', text: data.message }))
                } else {
                    dispatch(addAlert({ type: 'error', text: data.message }))
                }
            })
            .catch((e) => {
                if (console) { console.error(e.response.data.error) }
                dispatch(addAlert({ type: 'error', text: e.response.data.message }))
            })
            .finally(() => {
                dispatch(setLoading(false))
                handleClose()
            })
        }
    }, [isOpen, terminalResponse])

    useEffect(() => {
        if (isOpen) {
            window.sessionStorage.setItem(PREVENT_LOADER, true)
            dispatch(clearAdyenState())
        } else {
            window.sessionStorage.removeItem(PREVENT_LOADER)
        }
    }, [isOpen])

    return isOpen && (
        <div className='modal modal-backdrop modal--sm add-card-to-booking-modal'>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-container modal-content">
                    <div className="modal-header">
                        <div className='modal-title'>
                            Capture / Pre-Auth
                        </div>
                    </div>

                    <div className="modal-body">
                        <div className="modal-body-main">
                            <div className="modal-body-main-section text-center">
                                {
                                    (method === 'PRE_AUTHORIZE') && (
                                        <PreAuthorizationAmount
                                            amount={amountToPreAuthorize}
                                            minimumAmount={location.minimum_pre_authorized_amount}
                                            formula='FULL'
                                            location={location}
                                            booking={booking}
                                            theme='calendar'
                                            onChange={(amount) => setAmountToPreAuthorize(amount)}
                                        />
                                    )
                                }

                                <div className="pt-4 px-2">
                                    {
                                        <AdyenTerminalTransaction
                                            locationId={location.id}
                                            buttonText='Capture'
                                            buttonTextProcessing='Capturing...'
                                            buttonTextProcessed='Captured!'
                                            menuClassName='border border-color-gray4'
                                            transactionCallback={() => {
                                                if (method === 'TOKENIZE') {
                                                    handleTokenizeCard()
                                                }
                                                if (method === 'PRE_AUTHORIZE') {
                                                    handlePreAuthorizeCard()
                                                }
                                            }}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button
                            type='button'
                            className='btn btn-outline-primary px-5'
                            children='Cancel'
                            onClick={handleClose}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
