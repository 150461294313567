import React from 'react'
import { useDispatch } from 'react-redux'
import {
    configureModal,
    openModal,
    closeModal,
    createCheck
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

export default function NewCheckButton({
    id='new-check-button',
    className=null,
    label='',
    resourceType=null,
    disabled=false,
    onClick=null
}) {
    const dispatch = useDispatch()

    const handleClick = () => {
        // allow overriding this event handler
        if (!!onClick && typeof onClick === 'function') { return onClick() }

        // default event handler
        dispatch(closeModal('createCheck'))

        if (resourceType?.id === -1 ) {
            dispatch(createCheck(/* no resource type */))
        } else {
            dispatch(configureModal({
                modal: 'createCheck',
                config: {
                    action: '',
                    resourceType: resourceType,
                    title: '',
                    mode: resourceType.id === -2 ? 'All' : null
                }
            }))

            dispatch(openModal('createCheck'))
        }
    }

    return (
        <button
            children={label}
            type='button'
            id={id}
            className={className}
            disabled={disabled}
            onClick={onClick || handleClick}
        />
    )
}

