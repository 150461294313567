import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { Bar, Pie } from 'react-chartjs-2'
import { addAlert } from '@/features/Notifications/notificationSlice'
import { numberToCurrency } from '@/lib/Number'

import {
    Chart as ChartJS,
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    SubTitle,
    Tooltip,
    Legend,
} from 'chart.js'

ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    SubTitle,
    Tooltip,
    Legend,
)

export default function DashboardCharts({
    id=null,
    className='',
    url=null,
    titleFontSize='20px',
    subTitleFontSize= '16px',
}) {
    const Error     = <div className= "chart-error">Unable to Load</div>
    const emptyData = { title: '', subtitle: '', data: { labels: [], datasets: [] } }

    const dispatch            = useDispatch()
    const [loaded, setLoaded] = useState(false)

    const [data, setData] = useState({
        weeklySalesVsComps: emptyData,
        weeklyCheckAveragesVsTips: emptyData,
        dailyReservationSalesVsFbSales: emptyData,
        weeklyReservationSalesVsFbSales: emptyData,
    })

    const defaultOptions = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                top: 15,
                bottom: 15,
                left: 25,
                right: 25,
            },
        },
    }

    const barOptions = (title) => ({
        ...defaultOptions,
        plugins: {
            title: {
                display: true,
                text: title,
                font: { size: titleFontSize },
                padding: { bottom: 15 },
            },
            subtitle: { display: false },
            tooltip: {
                caretPadding: 10,
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                callbacks: {
                    label: (context) => ` ${numberToCurrency(context.parsed.y)}`
                },
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                min: 0,
                title: {
                    display: true,
                    text: 'US Dollars',
                    font: { weight: 'bold' },
                    padding: 15,
                },
                ticks: {
                    padding: 7,
                    callback: (value) => `${numberToCurrency(value)}`,
                }
            },
        }
    })

    const pieOptions = (title, subtitle) => ({
        ...defaultOptions,
        plugins: {
            title: {
                display: true,
                text: title,
                font: { size: titleFontSize },
                padding: { bottom: 15 },
            },
            subtitle: {
                display: true,
                text: subtitle,
                position: 'bottom',
                font: { size: subTitleFontSize, weight: 'bold' },
                padding: { top: 15 },
            },
            tooltip: {
                caretPadding: 10,
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                callbacks: {
                    label: (context) => ` ${numberToCurrency(context.parsed)}`
                },
            },
        },
    })

    useEffect(() => {
        if (loaded) { return }

        axios.get(`${url}.json`).then(({ data }) => {
            if (data.success) {
                setData(data.payload)
                setLoaded(true)
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        })
    }, [])

    return (
        <div id={id} className={`container-xl mx-0 px-0 ${className}`.trim()}>
            <div className="row">
                <div className="col-12 col-md-6 mb-4">
                    <div className="chart">
                        <Pie
                            fallbackContent={Error}
                            data={data.dailyReservationSalesVsFbSales.data}
                            options={pieOptions(
                                data.dailyReservationSalesVsFbSales.title,
                                data.dailyReservationSalesVsFbSales.subtitle,
                            )}
                        />
                    </div>
                </div>

                <div className="col-12 col-md-6 mb-4">
                    <div className="chart">
                        <Pie
                            fallbackContent={Error}
                            data={data.weeklyReservationSalesVsFbSales.data}
                            options={pieOptions(
                                data.weeklyReservationSalesVsFbSales.title,
                                data.weeklyReservationSalesVsFbSales.subtitle
                            )}
                        />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-lg-6 mb-4">
                    <div className="chart">
                        <Bar
                            fallbackContent={Error}
                            data={data.weeklySalesVsComps.data}
                            options={barOptions(data.weeklySalesVsComps.title)}
                        />
                    </div>
                </div>

                <div className="col-12 col-lg-6 mb-4">
                    <div className="chart">
                        <Bar
                            fallbackContent={Error}
                            data={data.weeklyCheckAveragesVsTips.data}
                            options={barOptions(data.weeklyCheckAveragesVsTips.title)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
