import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useFormContext } from 'react-hook-form'
import errorClass from '../../lib/Errors'
import titleize from '../../lib/String'
import InputWrapper from './InputWrapper'

export default function TextArea({
  name='',
  value='',
  cols,
  rows,
  errors=[],
  label,
  hideLabel,
  placeholder,
  autoFocus=false,
  withWrapper=true,
  req=null,
  validation=null,
  tabIndex=null,
  handleBlur=() => {},
  handleChange=() => {},
}) {
    const formMethods = useFormContext()

    /**
     * Example of an expected validation
     * schema object to register
     *
     * {
     *   'name': {
     *     required: 'A name is required',
     *     minLength: { value: 4, message: 'must be more than 4 characters long' }
     *   }
     * }
     */
    const separator = ':'

    const field = formMethods && validation
        ? formMethods.register( Object.keys(validation)[0], validation[Object.keys(validation)[0]] )
        : { name: name + separator + uuidv4(), ref: null }

    const _name = field.name.split(separator)[0]

    const _handleBlur = (e) => {
        // update react hook form validation
        if ('onBlur' in field) { field.onBlur(e) }
        // update our form object
        handleBlur(e)
    }

    const _handleChange = (e) => {
        // update react hook form validation
        if ('onChange' in field) { field.onChange(e) }
        // update our form object
        handleChange(e)
    }

    return (
        <InputWrapper
            name={_name}
            req={(validation && req === null) || Boolean(req)}
            cols={cols}
            errors={errors}
            label={label}
            hideLabel={hideLabel}
            withWrapper={withWrapper}
        >
            <textarea
                ref={field.ref}
                name={field.name}
                className={`form-control w-100 ${errorClass(field.name, errors)}`}
                placeholder={placeholder === false ? '' : (placeholder || titleize(_name))}
                defaultValue={value}
                rows={rows}
                autoFocus={autoFocus}
                tabIndex={tabIndex}
                onBlur={_handleBlur}
                onChange={_handleChange}
            />
        </InputWrapper>
    )
}
