import React from 'react'
import { LanesContainer } from './LanesContainer'
import { GridHeaderTimes } from './GridHeaderTimes'

export default function CalendarGrid() {
    return (
        <div className="grid pb-5 pb-md-0">
            <GridHeaderTimes />
            <LanesContainer />
        </div>
    )
}
