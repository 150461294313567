import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import FormRow from '@/components/Form/FormRow'
import Input from '@/components/Form/Input'
import { errorsFor } from '@/components/Form/ErrorsHelper'

export default function NameInput({
    id=null,
    className='pt-3 px-3',
    inputClassName='',
    inputContainerClassName='',
    field='name',
    placeholder='Name',
    type='text',
    role=null,
    autoFocus=true,
    autoComplete=null,
    hideLabel=true,
    withWrapper=true,
    onEnter=() => {},
    onBlur=() => {},
    onFocus=() => {}
}) {

    const { watch, setValue, formState: { errors } } = useFormContext()

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            onEnter()
        }
    }

    useEffect(() => {
        return () => {
            setValue(field, null)
        }
    }, [])

    return (
        <FormRow id={id} className={className}>
            <Input
                cols='12'
                className={inputClassName}
                containerClassName={inputContainerClassName}
                hideLabel={hideLabel}
                autoFocus={autoFocus}
                autoComplete={autoComplete}
                withWrapper={withWrapper}
                type={type}
                role={role}
                value={watch(field)}
                placeholder={placeholder}
                validation={{ [field]: { required: true, shouldUnregister: true }}}
                errors={errorsFor(errors)}
                handleFocus={onFocus}
                handleBlur={onBlur}
                handleKeyPress={handleKeyPress}
            />
        </FormRow>
    )
}
