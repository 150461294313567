import React from 'react'
import { useSelector } from 'react-redux'
import { selectErrors, selectStep, selectPaymentMethod } from '../bookingSlice'
import ProgressBar from '@/features/Bookings/components/BookingModalProgressBar'
import rkdLogoLight from '../../../../assets/images/powered-by-rkd.png';
import rkdLogoDark from '../../../../assets/images/powered-by-rkd-dark.png';

export default function BookingModalFooter({
    modalStyles,
    disableNext=false,
    nextText,
    step0=false,
    step0Url=null,
    useAdyen=false,
    onBack,
    onNext,
}) {

    const paymentMethod = useSelector(selectPaymentMethod)
    const errors        = useSelector(selectErrors)
    let step            = useSelector(selectStep)

    if (step0 === true) { step = '0' }

    const handlePaymentClick = () => {
        // we only want to call onNext if we are using Adyen,
        // because Paysafe has its own onClick handlers, whereas Adyen is manual.
        if (useAdyen) { onNext() }
    }

    return (
        <div className="xbm-footer">
            {
                !step0 && (
                    <ProgressBar usedStep0={!!step0Url}  />
                )
            }

            <div className="xbm-footer-errors">
                { errors?.length > 0 &&
                    <div className="invalid-feedback w-auto d-inline-block bg-danger text-white px-5 py-1 rounded text-center font-italic mt-n1 mb-3">
                        { errors.join(' ') }
                    </div>
                }
            </div>
            <div className="xbm-footer-actions">
                {
                    !/unbookable|0|1/i.test(step) && (
                        <button className="btn btn-default" type="button" onClick={onBack}>
                            <i className="far fa-chevron-left mr-1" /> Back
                        </button>
                    )
                }

                {
                    /unbookable|1/i.test(step) && !!step0Url && (
                        <button className="btn btn-default" type="button" onClick={onBack}>
                            <i className="far fa-chevron-left mr-1" /> Back
                        </button>
                    )
                }

                {
                    step !== '0' && (step !== '6' || paymentMethod !== 'credit_card') && (
                        <button className="btn btn-primary" type="button" onClick={onNext} disabled={disableNext}>
                            {nextText || 'Next'}&nbsp;<i className="far fa-chevron-right ml-1" />
                        </button>
                    )
                }

                {/* STEP 6 CREDIT CARD BUTTON */}
                {
                    step === '6' && paymentMethod === 'credit_card' && <>
                        <div className='credit-card-button-container' style={{ display: 'inline' }}>
                            <button
                                className="btn btn-primary"
                                type="button"
                                id="finish-button"
                                disabled={disableNext}
                                onClick={handlePaymentClick}>

                                {nextText || 'Next'}&nbsp;<i className="far fa-chevron-right ml-1" />

                            </button>
                        </div>
                    </>
                }
            </div>

            <div className='recaptcha-text mt-2 font-italic'>
                This site is protected by reCAPTCHA and the Google<br className='d-block d-md-none' />
                &nbsp;<a href='https://policies.google.com/privacy' target='_blank' rel='nofollow noreferrer noopener' className='border-bottom text-white'>Privacy Policy</a> and
                &nbsp;<a href='https://policies.google.com/terms' target='_blank' rel='nofollow noreferrer noopener' className='border-bottom text-white'>Terms of Service</a> apply.
            </div>

            <div className="xbm-footer-logo">
                <img src={modalStyles.use_dark_text_image ? rkdLogoLight : rkdLogoDark} alt="Powered By RKd Solutions" />
            </div>
        </div>
    )
}
