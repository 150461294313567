import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import PromotionField from '@/components/Packages/PromotionField'

export default function PromotionFields({
    form,
    promoCodes,
    handleManualUpdate,
    customerTypes,
    location,
    allowDollarDiscount,
    errors,
}) {

    const { register } = useFormContext()

    const name = 'promo_codes'

    const field = register(name, {
        validate: () => {
          const temp = promoCodes.map((item) => item.code).sort()
          const duplicates = []

          temp.map((code, index) => {
            if (temp[index+1] && code.toUpperCase() == temp[index+1].toUpperCase()) {
              duplicates.push(code)
            }
          })

          return duplicates.length === 0 || 'Sorry, duplicate promo codes are not allowed'
        },

        validate: () => {
            const empties = []
            promoCodes.map((item) => {
                if (
                  !item.name || !item.code || !item.amount_type || !item.amount ||
                  !item.valid_from || !item.valid_to
                ) {
                  empties.push(item)
                }
            })
            return empties.length === 0 || 'All promo code fields must be filled out'
        },

        validate: () => {
            const emptyCustomerType = []
            promoCodes.map((item) => {
                if (
                  form.pricing_type === 'pricing_by_customer_type' && item.customer_types.length === 0
                ) {
                    emptyCustomerType.push(item)
                }
            })
            return emptyCustomerType.length === 0 || 'You must select at least one customer type'
        }
    })

    const handleAdd = () => {
        handleManualUpdate(name, [{
            name:                     null,
            code:                     null,
            amount_type:              'percent',
            amount:                   null,
            customer_types:           [],
            valid_from:               null,
            valid_to:                 null,
            applies_to_ranged_groups: true,
            date_range_type:          'start_time',
        }].concat(promoCodes))
    }

    const handleRemove = index => {
        handleManualUpdate(name, promoCodes.filter((_p, i) => i !== index))
    }

    const handlePromoCodeChange = (e, index) => {
        handleManualUpdate(name, promoCodes.map((code, i) => {
            if (i === index) {
                const name = e.target.name.split(':')[0]
                return {
                    ...code,
                    [name]: e.target.value
                }
            } else {
                return code
            }
        }))
    }

    const handleCustomerTypeChange = (customerTypeId, index) => {
        handleManualUpdate(name, promoCodes.map((code, i) => {
            if (i === index) {
                return {
                    ...code,
                    // either remove (filter) the id out of the customer types or add (concat) it
                    customer_types: code.customer_types.includes(customerTypeId) ?
                        code.customer_types.filter(t => t !== customerTypeId)    :
                        code.customer_types.concat(customerTypeId)
                }
            } else {
                return code
            }
        }))
    }

    return (
        <div>
            <div className="drawer-side-action btn btn-link" onClick={handleAdd}>
                + Add Promotion
            </div>

            {
                promoCodes.map((code, index) => (
                    <PromotionField
                        key={`pc-${index}-${code?.valid_from || ''}`}
                        form={form}
                        customerTypes={customerTypes}
                        code={code}
                        index={index}
                        location={location}
                        allowDollarDiscount={allowDollarDiscount}
                        handlePromoCodeChange={handlePromoCodeChange}
                        handleCustomerTypeChange={handleCustomerTypeChange}
                        handleRemove={handleRemove}
                    />
                ))
            }

            {
                errors[name] && (
                    <div className="invalid-feedback d-block">
                        { errors[name].join(', ') }
                    </div>
                )
            }
        </div>
    )
}
