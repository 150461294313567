import React, { useEffect, useState } from 'react'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { useFormState } from 'react-hook-form'
import { useFormContext } from 'react-hook-form'
import AuthorizeManagerOverride from '@/features/ManagerCodes/AuthorizeManagerOverride'
import giftCardStore from '@/stores/giftCardStore'
import Input from '@/components/Form/Input'
import FormRow from '@/components/Form/FormRow'
import NotificationList from '@/features/Notifications/NotificationList'
import { validationPatterns } from '@/lib/Errors'
import { debug } from '@/lib/Debug'
import { selectValid, clear } from '@/features/ManagerCodes/managerCodeSlice'
import GiftCardHistory from './GiftCardHistory'

export default function GiftCardEditForm({
    recordUrl,
    form,
    errors,
    handleUpdate,
    handleDisableSaveButton,
    timeZone,
}) {
    return (
        <Provider store={giftCardStore}>
            <GiftCardEditFormFields
                recordUrl={recordUrl}
                form={form}
                timeZone={timeZone}
                errors={errors}
                handleUpdate={handleUpdate}
                handleDisableSaveButton={handleDisableSaveButton}
            />
            <NotificationList />
        </Provider>
    )
}

export function GiftCardEditFormFields({
    recordUrl,
    form,
    errors,
    handleUpdate,
    handleDisableSaveButton,
    timeZone,
}) {

    const { trigger, formState: { isValid } } = useFormContext()

    const dispatch            = useDispatch()
    const managerIsAuthorized = useSelector(selectValid)

    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        handleDisableSaveButton(true)
        setLoaded(true)

        return () => {
            dispatch(clear())
        }
    }, [])

    useEffect(() => {
        if (loaded) {
            handleDisableSaveButton(!managerIsAuthorized)
        }
    }, [managerIsAuthorized])


    const renderFinalizeButton = () => {
        return (
            <p className="w-100 text-center text-muted mt-4">
              { managerIsAuthorized
                  ? 'Change will be applied upon clicking "Save & Close"'
                  : 'You must authorize as a manager before continuing.'
              }
            </p>
        )     
    }

    return (
        <div>
            <FormRow>
                <Input
                    cols={6}
                    disabled={!form.id}
                    label="Recipient Email"
                    type="email"
                    placeholder=""
                    value={form.email}
                    validation={{ 'email': { required: true, pattern: validationPatterns.email }}}
                    errors={errors}
                    handleChange={handleUpdate}
                />

                <Input
                    cols={6}
                    disabled={!form.id}
                    type="email"
                    placeholder=""
                    value={form.receipt_email}
                    validation={{ 'receipt_email': { required: true, pattern: validationPatterns.email }}}
                    autoComplete="email"
                    errors={errors}
                    handleChange={handleUpdate}
                />
            </FormRow>
            <div className="row mt-2">
                <div className="w-50 mx-auto">
                    <AuthorizeManagerOverride locationId={form.location_id} />
                </div>
            </div>
             <GiftCardHistory 
                giftCardId={form.id}
                timeZone={timeZone}
            />
            <div className="form-group">
                { renderFinalizeButton() }
            </div>
        </div>
    )
}
