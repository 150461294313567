import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NumberIncrementer from '../../../../components/Form/NumberIncrementer'
import { selectMemberCount, setMemberCount } from '../../bookingSlice'

export default function MemberIncrementer({ disabled }) {
    const dispatch = useDispatch()

    const count = useSelector(selectMemberCount)

    const handleChange = (value) => {
        dispatch(setMemberCount(value))
    }

    return (
        <div className="container">
            <div className="row align-items-center mb-3">
                <div className="col-sm-5 text-right">
                    <h5 className="mb-0 xbm-customer-type text-primary">Members</h5>
                </div>
                <div className="col-sm-7">
                    <div className="xbm-incrementer-container member-incrementer">
                        <NumberIncrementer
                            disabled={disabled}
                            min={0}
                            preventTyping={true}
                            value={count}
                            incrementAmount={1}
                            handleChange={handleChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
