import React from 'react'
import Input from '../Form/Input'

export default function ConfirmationChecksFields({ confirmationChecks=[], handleUpdate }) {

    const handleChange = (index, text) => {
        handleUpdate('confirmation_checks', confirmationChecks.map((e, i) => {
            if (index === i) {
                return { ...e, ['text']: text }
            } else {
                return e
            }
        }))
    }

    const handleRemove = index => {
        handleUpdate('confirmation_checks', confirmationChecks.filter((e, i) => i !== index))
    }

    return confirmationChecks.map((e, index) => {
        return (
            <div className="drawer-add-row border-0" key={index}>
                <div className="form-row">

                    <div className="col-11">
                        <div className="form-group">
                            <Input
                                key={index}
                                cols="12 pl-0"
                                name={`confirmation_checks[${index}]`}
                                value={e.text}
                                hideLabel={true}
                                placeholder='Please confirm that you will...'
                                handleChange={(e) => handleChange(index, e.currentTarget.value)}
                                className='form-control' />
                        </div>
                    </div>

                    <div className="col-1">
                        <div className="remove" onClick={() => handleRemove(index)}>Remove</div>
                    </div>

                </div>
            </div>
        )
    })
}
