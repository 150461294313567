import React, { useState, useEffect } from 'react'
import FormRow from '@/components/Form/FormRow'
import SelectCustomerTypes from '@/components/Packages/SelectCustomerTypes'
import Help from '@/features/Help/Help'
import MembershipPricing from '@/components/Packages/members/MembershipPricing'
import { durationsPrices } from '@/lib/Package'

export default function AllowedCustomerTypes({
    selectedCustomerTypes,
    customerTypes,
    pricingType,
    pricing,
    durations,
    promoCodes,
    form,
    location,
    errors,
    isNewPackage,
    origAmountPricingGroups,
    membershipTypesUrl,
    handleUpdate,
    handleManualUpdate,
    handleFormUpdate,
}) {
    const isPricingByCustomerType       = isNewPackage ? false : pricingType === 'pricing_by_customer_type'
    const isUniformPriceType            = isNewPackage ? true : pricingType === 'uniform_pricing'
    const pricingSchemeImmutable        = origAmountPricingGroups > 0 && form.groups.length > 0
    const isMemberCustomerTypeDisplayed = !!form.allow_member_benefits && !isUniformPriceType && form.customer_types.length > 0

    const [showCustomerTypes, setShowCustomerTypes] = useState(isPricingByCustomerType)

    /**
     * Convert the pricing grid for all the current grids to switch between price by
     * customer type and uniform pricing.
     *
     * This works by looping over all the existing group entries in the pricing grid,
     * and replacing the group.prices array with argument `groupPrices`, which is ultimately
     * either a new price entry per duration, or a new price entry per customer type per duration.
     *
     * @param {*} e native event
     * @param {*} groupPrices the group prices that should replace the current ones for each group
     */
    const convertPricingGroup = (e, groupPrices) => {
        handleFormUpdate({
            ...form,
            [e.target.name]: e.target.value,
            pricing: pricing.map(group => {
                // if it's not individual pricing, we don't want to do anything
                if (group.individualPricing) {
                    return {
                        ...group,
                        prices: groupPrices
                    }
                } else {
                    return group
                }
            })
        })
    }

    /**
     * Update the group prices arrays by creating an entry per customer type per
     * duration, just like we do elsewhere. We don't port the prices per duration
     * since this is completely changing the pricing structure.
     */
    const priceByCustomerType = e => {
        convertPricingGroup(e, selectedCustomerTypes.map(customerType => durationsPrices(durations, customerType)))
    }

    /**
     * Update the group prices array by removing the customer type - duration prices
     * and just entering individual durations, just like for grouped pricing.
     */
    const uniformPricing = e => {
        convertPricingGroup(e, durationsPrices(durations, null))
    }

    /**
     * Toggle the display of customer types depending
     * on which price type is currently in chose
     */
    useEffect(() => {
        setShowCustomerTypes(isPricingByCustomerType)
    }, [pricingType])

    return <>
        <div className="allowed-customer-types">
            <h3 className="d-flex justify-content-between align-items-center">
                <span className='d-flex'>
                    <span>Allowed Customer Types</span>
                    <Help articleId='154000158978-allowed-customer-types' />
                </span>
            </h3>
            <FormRow className="px-1">
                <div className="form-group col-4 mb-2">
                    <div className="custom-control custom-radio">
                        <input
                            id='pricing_by_customer_type'
                            type='radio'
                            name='pricing_type'
                            value='pricing_by_customer_type'
                            disabled={pricingSchemeImmutable}
                            onChange={priceByCustomerType}
                            className="custom-control-input"
                            checked={isPricingByCustomerType} />

                        <label className="custom-control-label" htmlFor='pricing_by_customer_type'>Customer Type Pricing</label>
                    </div>
                </div>
                <div className="form-group col-5">
                    <div className="custom-control custom-radio">
                        <input
                            id='uniform_pricing'
                            type='radio'
                            name='pricing_type'
                            value='uniform_pricing'
                            disabled={pricingSchemeImmutable}
                            onChange={uniformPricing}
                            className="custom-control-input"
                            checked={isUniformPriceType} />

                        <label className="custom-control-label" htmlFor='uniform_pricing'>Uniform Pricing</label>
                    </div>
                </div>
            </FormRow>

            { showCustomerTypes &&
                <SelectCustomerTypes
                    selectedCustomerTypes={selectedCustomerTypes}
                    membershipCustomerType={form.membership_customer_type_id}
                    customerTypes={customerTypes}
                    pricing={pricing}
                    durations={durations}
                    promoCodes={promoCodes}
                    pricingType={form.pricing_type}
                    allowMemberBenefits={form.allow_member_benefits}
                    errors={errors}
                    handleUpdate={handleManualUpdate}
                />
            }
        </div>

        <MembershipPricing
            form={form}
            errors={errors}
            customerTypes={customerTypes}
            selectedCustomerTypes={selectedCustomerTypes}
            isMemberCustomerTypeDisplayed={isMemberCustomerTypeDisplayed}
            isUniformPriceType={isUniformPriceType}
            membershipTypesUrl={membershipTypesUrl}
            enabled={location.memberships_enabled}
            handleUpdate={handleUpdate}
            handleManualUpdate={handleManualUpdate}
        />
    </>
}
