import React from 'react'
import DatePicker from 'react-datepicker'
import { useDispatch, useSelector } from 'react-redux'
import { selectTimeZone } from '@/features/Locations/locationSlice'
import { selectDate, selectDuration, selectParticipants, setDate } from '../../bookingSlice'
import moment from 'moment'
import { fetchAvailableDaysForDuration, selectDates } from '../../../Availability/availabilitySlice'
import { localDateToMoment, momentToLocalDate } from '@/lib/CalendarTimes'
import { preventTyping } from '@/lib/Form'

export default function SelectDate() {

    const dispatch         = useDispatch()
    const selectedTimeZone = useSelector(selectTimeZone)
    const date             = useSelector(selectDate)
    const duration         = useSelector(selectDuration)
    const availableDates   = useSelector(selectDates)
    const participants     = useSelector(selectParticipants)

    const handleMonthChange = (month) => {
        dispatch(fetchAvailableDaysForDuration(duration, month, participants))
    }

    const handleDateChange = (date) => {
        const localizedDate = localDateToMoment(date, selectedTimeZone)
                                .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                                .format('YYYY-MM-DD')

        dispatch(setDate(localizedDate))
    }

    return (
        <div className="xbm-step-question">

            <h2 className="mb-3">Date</h2>

            <div className="text-center">
                <div className="axe-datepicker">
                    <DatePicker
                        inline
                        disabledKeyboardNavigation
                        minDate={momentToLocalDate(moment().tz(selectedTimeZone))}
                        forceShowMonthNavigation={true}
                        includeDates={availableDates.map(d => moment(d).toDate())}
                        selected={date ? momentToLocalDate(moment.tz(date, selectedTimeZone)) : null}
                        onMonthChange={handleMonthChange}
                        onChange={handleDateChange}
                        onKeyDown={preventTyping}
                        openToDate={availableDates.length >= 1 ? momentToLocalDate(moment.tz(availableDates[0], selectedTimeZone)) : null}
                    />
                </div>
            </div>

        </div>
    )
}
