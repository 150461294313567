import React, { useEffect, useState } from 'react'
import moment from 'moment'
import TimePicker from 'rc-time-picker'
import { useSelector } from 'react-redux'
import { selectTimeZone } from '@/features/Locations/locationSlice'
import { selectBooking } from '@/features/EditBooking/editBookingSlice'
import EditBookingDate from '@/features/EditBooking/EditBookingDate'
import EditBookingDuration from '@/features/EditBooking/EditBookingDuration'
import { EditBookingScheduledTime } from '@/features/EditBooking/EditBookingScheduledTime'
import { isBookingInProgress, isBookingComplete, isBookingCancelled, isBookingQuote } from '@/lib/Booking'

export function _BookingDateTime({
    form,
    handleDateChange,
    startTime,
    handleStartTimeChange,
    handleStartedAtChange,
    isParent=false,
    isChild=false
}) {
    const booking          = useSelector(selectBooking)
    const selectedTimeZone = useSelector(selectTimeZone)

    const [editActualStartTime, setEditActualStartTime] = useState(false)

    useEffect(() => {
        if (form?.status && /[hold|open|reserved]?/i.test(form.status)) {
            setEditActualStartTime(false)
        }
    }, [form])

    return (
        <div className="booking-calendar">
            <div className="form-row">

                <div className="col-3">
                    { form?.date &&
                        <EditBookingDate
                          date={form.date}
                          isParent={isParent}
                          isChild={isChild}
                          isQuote={isBookingQuote(booking)}
                          disabled={isBookingCancelled(booking) || isBookingComplete(booking) || booking?.check?.has_adv_pos_booking_payment_been_received}
                          handleDateChange={handleDateChange}
                        />
                    }
                </div>

                <div className="col-3">
                    {
                        form && (
                            <EditBookingDuration
                              form={form}
                              isParent={isParent}
                              isChild={isChild}
                              isQuote={isBookingQuote(booking)}
                              disabled={isBookingCancelled(booking) || isBookingComplete(booking) || booking?.check?.has_adv_pos_booking_payment_been_received}
                            />
                        )
                    }
                </div>

                <div className="col-3">
                    { startTime &&
                        <EditBookingScheduledTime
                          startTime={startTime}
                          isParent={isParent}
                          isChild={isChild}
                          isQuote={isBookingQuote(booking)}
                          disabled={isBookingCancelled(booking) || isBookingComplete(booking) || booking?.check?.has_adv_pos_booking_payment_been_received}
                          handleStartTimeChange={handleStartTimeChange}
                        />
                    }
                </div>

                <div className="col-3">
                    { form?.end_time &&
                        <div className="form-group">
                            <label>Est. End Time</label>
                            <input
                              type="text"
                              disabled
                              className="form-control"
                              value={moment(form.end_time).tz(selectedTimeZone).format('h:mm A')}
                            />
                        </div>
                    }
                </div>
            </div>

            {
               (isBookingInProgress(booking) || form.addon_minutes > 0) && (
                    <div className="form-row">
                        <div className="col-3">
                            <div className="form-group">
                                <label>Actual Start Time</label>
                                {
                                    editActualStartTime ? (
                                        <TimePicker
                                            name='started_at'
                                            defaultValue={form?.started_at ? moment(form.started_at).tz(selectedTimeZone) : moment().tz(selectedTimeZone)}
                                            placement='bottomLeft'
                                            format={'h:mm A'}
                                            showSecond={false}
                                            use12Hours
                                            onChange={handleStartedAtChange}
                                        />
                                    ) : (
                                        <input
                                          type="text"
                                          className="form-control disabled"
                                          style={{ cursor: /^(in_progress|warned)$/i.test(form.status) ? 'pointer' : 'default' }}
                                          readOnly={true}
                                          value={form.started_at !== null ? moment(form.started_at).tz(selectedTimeZone).format('h:mm A') : 'Not Started'}
                                          onClick={() => { setEditActualStartTime(/^(in_progress|warned)$/i.test(form.status)) }}
                                        />
                                    )
                                }
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group">
                                <label>Actual End Time</label>
                                <input
                                  type="text"
                                  disabled
                                  className="form-control"
                                  value={form.ended_at !== null ? moment(form.ended_at).tz(selectedTimeZone).format('h:mm A') : (form.started_at !== null ? 'Ongoing' : 'Not Started')}
                                />
                            </div>
                        </div>
                        {
                            !isParent && !isChild &&
                                <div className="col-6 col-lg-3">
                                    <div className="form-group">
                                        <label>Add-On Time</label>
                                        <div className="input-group">
                                            <input type="text" disabled className="form-control" value={form.addon_minutes || 0} />
                                            <div className="input-group-append">
                                                <span className="input-group-text">Minutes</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                )
            }
        </div>
    )
}

export const BookingDateTime = React.memo(_BookingDateTime)
