import { createSlice } from "@reduxjs/toolkit"
import axios from 'axios'
import { addAlert } from '@/features/Notifications/notificationSlice'
import { configureModal, openModal } from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'
import { SELECTED_COMPANY, SELECTED_LOCATION, ADV_POS_SESSION, DEVICE_UUID } from '@/lib/Storage'
import { accessToken } from '@/lib/Csrf'
import { v4 as uuidv4 } from 'uuid'

export const sessionSlice = createSlice({
    name: 'session',
    initialState: {
        formToken: accessToken,
        hasError: false,
        previousUserId: null,
        currentUser: {
            id: null,
            name: '',
            username: '',
            email: '',
            role: '',
            is_session_locked: null,
            is_restricted_to_pos: null,
        },
        logoutPath: null,
        returnUrl: null,
    },
    reducers: {
        setFormToken: (state, action) => {
            state.formToken = action.payload
        },
        setHasError: (state, action) => {
            state.error = action.payload
        },
        setLogoutPath: (state, action) => {
            state.logoutPath = action.payload
        },
        setReturnUrl: (state, action) => {
            if (state.returnUrl !== null) { return }
            state.returnUrl = action.payload
        },
        setCurrentUser: (state, action) => {
            state.currentUser = { ...state.currentUser, ...action.payload }
        },
        setIsUserRestrictedToPos: (state, action) => {
            state.currentUser.is_restricted_to_pos = action.payload
        },
        setPreviousUserId: (state, action) => {
            state.previousUserId = action.payload
        },
        setSessionLocked: (state, action) => {
            state.currentUser.is_session_locked = action.payload
        },
    }
})

export const {
    setFormToken,
    setHasError,
    setCurrentUser,
    setLogoutPath,
    setReturnUrl,
    setSessionLocked,
    setIsUserRestrictedToPos,
    setPreviousUserId,
} = sessionSlice.actions

export const selectFormToken       = state => state.session.formToken
export const selectHasError        = state => state.session.hasError
export const selectLogoutPath      = state => state.session.logoutPath
export const selectReturnUrl       = state => state.session.returnUrl
export const selectCurrentUser     = state => state.session.currentUser
export const selectCurrentUserId   = state => state.session.currentUser.id
export const selectPreviousUserId  = state => state.session.previousUserId
export const selectIsSessionLocked = state => state.session.currentUser.is_session_locked

export function fetchUser(id=null) {
    return async (dispatch) => {
        if (id === null || id === undefined) { return }

        return axios.get(`/users/${id}`).then(({ data }) => {
            if (data.success && !!data?.user) {
                dispatch(setCurrentUser(data.user))
                return
            }
            dispatch(addAlert({ type: 'error', text: data.message }))
        })
    }
}

export function switchUser(_companyId=null, _locationId=null, pin=null, checkId=null) {
    return async (dispatch, getState) => {
        if (pin === null || pin === undefined) { return }

        const companyId  = _companyId  || getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = _locationId || getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const userId     = getState().session.currentUser.id

        let device_uuid = window.sessionStorage.getItem(DEVICE_UUID, device_uuid)

        if (!device_uuid) {
            device_uuid = uuidv4()
            window.sessionStorage.setItem(DEVICE_UUID, device_uuid)
        }

        return axios.post(`/companies/${companyId}/locations/${locationId}/pos/sessions/switch`, {
            authenticity_token: getState().session.formToken,
            id: userId,
            check_id: checkId,
            device_uuid,
            pin
        })
        .then(({ data }) => {
            if (data.success && !!data?.user) {
                window.sessionStorage.setItem(ADV_POS_SESSION, JSON.stringify({ user: { id: data.user.id }}))
                dispatch(setPreviousUserId(userId))
                dispatch(setCurrentUser(data.user))
                return userId !== data.user.id
            }

            // If unauthorized, shake the form and display a pop modal message
            const form           = document.getElementById('advanced-pos--lock-screen-form')
            const animationClass = 'fa-shake'
            const animationDelay = !!form ? 125 : 0

            window.setTimeout(() => {
                dispatch(configureModal({
                    modal: 'popModal',
                    config: {
                        text: data.message,
                        icon: 'fa-lock-keyhole bg-danger',
                        delay: 1600
                    }
                }))

                dispatch(openModal('popModal'))
            }, animationDelay)

            if (!!form) {
                form.classList.add(animationClass)
                window.setTimeout(() => { form.classList.remove(animationClass) }, animationDelay)
            }
        })
    }
}

export function updateIsSessionLocked(bool=null, afterRequestEnabled=true, callback=() => {}) {
    return async (dispatch, getState) => {
        if (bool === null || bool === undefined) { return }

        const currentUser = getState().session.currentUser
        const companyId   = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId  = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)

        if (!currentUser?.id || currentUser?.is_session_locked) { return }

        return axios.patch(`/companies/${companyId}/locations/${locationId}/pos/sessions/update`, {
            authenticity_token: getState().session.formToken,
            is_session_locked: bool,
            user_id: currentUser.id
        })
        .then(({ data }) => {
            if (data.success === true) {
                callback()
                if (afterRequestEnabled) { dispatch(setSessionLocked(false)) }
                return
            }
            dispatch(addAlert({ type: 'error', text: data.message }))
        })
    }
}

export default sessionSlice.reducer
