import React from 'react'

export default function PartySizeMessagesList({ handleUpdate, messages }) {

    const remove = index => {
        handleUpdate('party_size_selection_messages', messages.filter((message, i) => i !== index))
    }

    return (
        <div className="party-size-messages-list">
            {
                messages.map((message, index) => {
                    return (
                        <div key={index} className="row py-2">
                            <div className="col-1">
                                {message.from} to {message.to}
                            </div>
                            <div className="col-9">
                                <div dangerouslySetInnerHTML={{ __html: message.message }}></div>
                            </div>
                            <div className="col-2 text-right">
                                <button type="button" className="btn btn-text remove py-0" onClick={() => remove(index)}>
                                    REMOVE
                                </button>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}
