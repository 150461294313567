import React from 'react'
import LaneFieldsRow from './LaneFieldsRow'

/**
 * The overall container for lane fields. This is responsible for creating lane fields for each number, based
 * on the number of resources/lanes they entered.
 *
 * @param number the number of lanes they have
 * @param names the resource_names object
 * @param capacities the resource_capacities object
 * @param handleChange event handler for whenever an input is entered
 * @param singularName name of lane/rink/etc. for display purposes
 */
export default function LaneFieldsContainer({ number, names, handleChange, singularName }) {

    const resources = []

    // loop through the amount of lanes they have, and render a lane field row for each one
    for (let i = 0; i < number; ++i) {
        resources.push(
            <LaneFieldsRow
                key={i}
                handleChange={handleChange}
                names={names}
                number={i + 1} />
        )
    }

    return (
        <div className="resource-names">
            <h3>Custom {singularName} Names</h3>
            {resources}
        </div>
    )
}
