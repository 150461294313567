import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedType, setStep, selectMembership } from './memberSlice'
import MemberSteps from '../../lib/Member'
import TermsButton from './TermsButton'

export default function MemberTypeCard({ type }) {
    const dispatch = useDispatch()

    const membership = useSelector(selectMembership)

    const handleSelect = () => {
        dispatch(setSelectedType(type))

        // current members (manage membership) need to go directly to membership
        // term, but new members go to select recipient type>
        if (membership) {
            dispatch(setStep(MemberSteps.MEMBERSHIP_TERM))
        } else {
            dispatch(setStep(MemberSteps.SELECT_RECIPIENT))
        }
    }

    return (
        <div className="col-lg-4 col-md-6 pb-4">
            <div className="card membership-type-card text-center">
                <div className="card-body">
                    <h4 className="text-center">{type.name}</h4>
                    <p className="pt-2 text-left" style={{ whiteSpace: "pre-wrap" }}>{type.public_description}</p>
                </div>

                <div className="card-footer">
                    <div className="row">
                        <div className="col-12 text-center">
                            <button className="btn btn-primary" onClick={handleSelect}>
                                SELECT
                            </button>
                        </div>
                    </div>

                    <TermsButton detailsAndTerms={type.terms} />
                </div>
            </div>
        </div>
    )
}
