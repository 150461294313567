import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectProcessing as selectTerminalProcessing } from '@/features/Adyen/adyenSlice'
import {
    selectProcessingPayment,
    selectAmountTowardsBalance,
    selectCreditMethod,
    selectPaymentMethod,
    selectTipAmount
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'
import CancelButton from '@/features/Utilities/CancelButton'
import { numberToCurrency } from '@/lib/Number'

export default function PaymentProcessing() {

    const paymentMethod      = useSelector(selectPaymentMethod)
    const creditMethod       = useSelector(selectCreditMethod)
    const processingTerminal = useSelector(selectTerminalProcessing)
    const processingOther    = useSelector(selectProcessingPayment)
    const amountToPay        = useSelector(selectAmountTowardsBalance)
    const tipAmount          = useSelector(selectTipAmount)

    const isProcessing = useMemo(() => (
        processingTerminal || processingOther
    ), [processingTerminal, processingOther])

    return !isProcessing ? null : (
        <div id="global-loading">
            <div id="global-loading--content">
                <div className="spinner">
                    <div className="rect1" />
                    <div className="rect2" />
                    <div className="rect3" />
                    <div className="rect4" />
                    <div className="rect5" />
                    <div className="rect6" />
                </div>

                <div className='text-center'>
                    {
                        !(Number(amountToPay) === 0 && Number(tipAmount) === 0) && <>
                            { numberToCurrency(Number(amountToPay) + Number(tipAmount)) }
                        </>
                    }
                </div>

                {
                    paymentMethod === 'credit' && creditMethod === 'terminal' && processingTerminal && (
                        <CancelButton
                            appendedButtonText='Transaction'
                            paymentProcessor='adyen'
                            shouldCancelTerminalTransaction
                        />
                    )
                }
            </div>
        </div>
    )
}
