import React, { useState } from 'react'

export default function Calculator({
  className='',
  theme='dark',
  maxInputLength=15,
}) {
  const [currentInput, setCurrentInput]         = useState('')
  const [selectedOperator, setSelectedOperator] = useState('')
  const [calc, setCalc]                         = useState('')
  const [result, setResult]                     = useState('')
  const [showResult, setShowResult]             = useState(false)

  const ops = ['/', '*', '-', '+']

  const updateCalc = value => {
    if (showResult) { setShowResult(false) }

    if (ops.includes(value) && currentInput === '' ) { return }

    if (!!currentInput && currentInput.length >= maxInputLength) { return }

    if (ops.includes(value)) {
      setSelectedOperator(value)

      if (ops.includes(calc[calc.length - 1])) {
        let calcCopy = calc.split('')
        calcCopy.pop()
        calcCopy.push(value)
        setCalc(calcCopy.join(''))
      } else {
        if (showResult) {
          setCalc(result + value)
          setResult('')
        } else {
          setCalc(calc + value)
        }
      }
    } else {
      if (showResult || ops.includes(calc[calc.length - 1])) {
        setCurrentInput(value)
        setResult('')
      } else {
        setCurrentInput(currentInput.toString() + value.toString())
      }

      setCalc(calc + value)
    }
  }

  const handleResult = () => {
    if (showResult || ops.includes(calc[calc.length - 1])) { return }

    const calcResult = eval(calc)

    setResult(calcResult)
    setShowResult(true)
    setSelectedOperator('')
    setCalc('')
    setCurrentInput(calcResult)
  }

  const handleClear = () => {
    setShowResult(false)
    setCalc('')
    setResult('')
    setCurrentInput('')
    setSelectedOperator('')
  }

  const genButton = (content, value=null, col=3, bgColor='bg-gray1') => {
    const contentValue = value ? value : content

    return (
      <div className={`number-pad-button col-${col}`}>
        <button
          type='button'
          className={`h2 mb-0 pressable w-100 d-flex justify-content-center align-items-center rounded text-white ${bgColor} ${selectedOperator === contentValue && 'border border-white'}`.trim()}
          onClick={() => {
            if (content === '=') {
              handleResult()
            } else {
              updateCalc(contentValue)
            }
          }}
        >
          { content }
        </button>
      </div>
    )
  }

  return (
  <div className={`calculator passcode-keypad ${className} theme--${theme}`.trim()}>
    <div className='number-keypad row mx-n1'>
      <div className='col-12 result'>
        <div className='d-flex justify-content-between align-items-center rounded mb-2 px-3'>
          { (result || currentInput) && (
            <span className='font-weight-bold cursor-pointer' onClick={handleClear}>CLEAR</span>
          )}
          <span className='h3 mb-0'>
            { (showResult ? Number(result).toFixed(2) : currentInput).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }
          </span>
        </div>
      </div>
      { genButton(7) }{ genButton(8) }{ genButton(9) }{ genButton(<>&#247;</>, ops[0], 3, 'bg-orange') }
      { genButton(4) }{ genButton(5) }{ genButton(6) }{ genButton(<>&#215;</>, ops[1], 3, 'bg-orange') }
      { genButton(1) }{ genButton(2) }{ genButton(3) }{ genButton('—', ops[2], 3, 'bg-orange') }
      { genButton(0, null, 6) }{ genButton('.') }{ genButton('+', ops[3], 3, 'bg-orange') }
      { genButton('=', null, 12, 'bg-red') }
    </div>
  </div>
  )
}
