import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { searchCustomers, selectQuery, setQuery } from './customerSlice'
import { debug } from '@/lib/Debug'

export default function CustomerSearch() {

    const dispatch = useDispatch()
    const query    = useSelector(selectQuery)

    const handleSearch = () => {
        dispatch(searchCustomers())
    }

    const handleKeyDown = (e) => {
        if (e.which === 13) {
            handleSearch()
        }
    }

    const handleSetQuery = (e) => {
        dispatch(setQuery(e.target.value))
    }

    return (
        <div className="row pb-2">
            <div className="col-12">
                <div className="form-group">

                    <label>Query (Name/Email/Phone)</label>

                    <div className="input-group">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Enter search query..."
                            value={query || ''}
                            autoFocus={true}
                            onKeyDown={handleKeyDown}
                            onChange={handleSetQuery}
                        />
                        <div className="input-group-append">
                            <button className="btn btn-primary" onClick={handleSearch}>
                                Search
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
