import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCode,
  clear,
  selectError,
  setCode,
  validateManagerCode,
} from '@/features/ManagerCodes/managerCodeSlice'
import { selectLocation } from '@/features/Locations/locationSlice'
import SecurePassword from '@/components/Form/SecurePassword'

export default function SkipPayment() {
  const dispatch = useDispatch()
  const code     = useSelector(selectCode)
  const error    = useSelector(selectError)
  const location = useSelector(selectLocation)

  const validateCode = () => {
      dispatch(validateManagerCode(location.id, code))
  }

  const handleChange = (value) => {
      dispatch(setCode(value))
  }

  const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
          validateCode()
      }
  }

  useEffect(() => {
      if (!!error) {
          dispatch(setCode(''))
          window.setTimeout(() => dispatch(clear()), 3000)
      }
  }, [error])

  return <div className='input-group'>
    <SecurePassword
      placeholder='Manager Code'
      value={code || ''}
      error={error}
      autoFocus={true}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
    />

    <div className="input-group-append">
      <button
        type='button'
        children={!!error ? 'Invalid' : 'Verify'}
        className={`btn ${!!error ? 'btn-danger text-white' : 'btn-primary'}`}
        onClick={(e) => {
          e.preventDefault()
          validateCode()
        }}
      />
    </div>
  </div>
}
