import React from 'react'
import { numberToCurrency } from '@/lib/Number'

export default function RefundItems({
  payment,
  tabs,
  selectedTabItems,
  setSelectedTabItems,
  isNonSplitBookingRefunded,
  refundedUuids,
}) {
  const toggleItem = (tabId, item, isBooking=false) => {
    const index = selectedTabItems.map(i => i.tab_id).indexOf(tabId)
    const key = isBooking ? 'booking_items' : 'items'

    if (index > -1) {
      const existingItem = (selectedTabItems[index][key] || []).find(i => i.uuid === item.uuid)
      let updatedTabItems = [...selectedTabItems]

      if (existingItem) {
        updatedTabItems[index] = { ...updatedTabItems[index], [key]: updatedTabItems[index][key].filter(i => i.uuid !== item.uuid) }
      } else {
        updatedTabItems[index] = { ...updatedTabItems[index], [key]: [...(updatedTabItems[index][key] || []), item] }
      }

      setSelectedTabItems(updatedTabItems)
    } else {
      setSelectedTabItems([...selectedTabItems, { tab_id: tabId, [key]: [item] }])
    }
  }

  const toggleBookingTotal = (tabId, total, taxesAndFees) => {
    const index = selectedTabItems.map(i => i.tab_id).indexOf(tabId)

    if (index > -1) {
      let updatedTabItems = [...selectedTabItems]

      if (updatedTabItems[index].total_received_booking_cents) {
        delete updatedTabItems[index].total_received_booking_cents
      } else {
        updatedTabItems[index] = { ...updatedTabItems[index], total_received_booking_cents: total, total_received_booking_cents: total, booking_taxes_and_fees: taxesAndFees }
      }

      setSelectedTabItems(updatedTabItems)
    } else {
      setSelectedTabItems([...selectedTabItems, { tab_id: tabId, total_received_booking_cents: total, booking_taxes_and_fees: taxesAndFees }])
    }
  }

  return <>
    <h6>What would you like to refund?</h6>
    {payment.adv_pos_tab_transaction_splits.sort((a, b) => a.tab_id - b.tab_id).map(split => (
      <div key={split.tab_id} className='my-3 p-2'>
        <div>{tabs.find(t => t.id === split.tab_id).name} (#{split.tab_id})</div>
        <div className='d-flex mt-2'>
          {!isNonSplitBookingRefunded && !!split.total_received_booking_cents && split.total_received_booking_cents > 0 && (!split.booking_items || split.booking_items.length === 0) &&
            <div className='d-flex flex-column'>
              <div className='custom-control custom-checkbox pl-2'>
                <input
                  type='checkbox'
                  name='total_received_booking_cents'
                  id='total_received_booking_cents'
                  value='total_received_booking_cents'
                  checked={selectedTabItems.find(i => i.tab_id === split.tab_id)?.total_received_booking_cents}
                  className='mr-2'
                  onChange={() => toggleBookingTotal(split.tab_id, split.total_received_booking_cents, split.booking_taxes_and_fees)}
                />
                <label htmlFor='total_received_booking_cents'>
                  Reservation Amount ({numberToCurrency(split.total_received_booking_cents / 100.0)})
                </label>
              </div>
            </div>
          }
          {(split.booking_items || []).filter(item => !refundedUuids.includes(item.uuid)).length > 0 &&
            <div className='d-flex flex-column ml-4'>
              {(split.booking_items || []).filter(item => !refundedUuids.includes(item.uuid)).map(item =>
                <div key={item.uuid}>
                  <input
                    type='checkbox'
                    name={`item-${item.uuid}`}
                    id={`item-${item.uuid}`}
                    value={`item-${item.uuid}`}
                    checked={selectedTabItems.find(i => i.tab_id === split.tab_id)?.booking_items?.find(i => i.uuid === item.uuid)}
                    className='mr-2'
                    onChange={() => toggleItem(split.tab_id, item, true)}
                  />
                  <label htmlFor={`item-${item.uuid}`}>
                    {item.name} ({numberToCurrency(item.total_received_cents / 100.0)})
                  </label>
                </div>
              )}
            </div>
          }
          {(split.items || []).filter(item => !refundedUuids.includes(item.uuid)).length > 0 &&
            <div className='d-flex flex-column ml-4'>
              {(split.items || []).filter(item => !refundedUuids.includes(item.uuid)).map(item =>
                <div key={item.uuid}>
                  <input
                    type='checkbox'
                    name={`item-${item.uuid}`}
                    id={`item-${item.uuid}`}
                    value={`item-${item.uuid}`}
                    checked={selectedTabItems.find(i => i.tab_id === split.tab_id)?.items?.find(i => i.uuid === item.uuid)}
                    className='mr-2'
                    onChange={() => toggleItem(split.tab_id, item)}
                  />
                  <label htmlFor={`item-${item.uuid}`}>
                    {item.name} ({numberToCurrency(item.total_received_cents / 100.0)})
                  </label>
                </div>
              )}
            </div>
          }
        </div>
      </div>
    ))}
  </>
}
