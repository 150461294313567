import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import FormRow from '@/components/Form/FormRow'
import errorClass from '@/lib/Errors'
import titleize from '@/lib/String'
import { everyDay } from '@/components/Packages/mixins/packageTimes'
import days from '@/lib/Days'

export default function PackageDaySelect({
    name=null,
    values=[],
    errors=[],
    daysClosed=[],
    compact=false,
    dense=false,
    overridingPublicStoreHours=false,
    handleUpdate=() => {}
}) {
    if (name === null) { return null }

    const { register, unregister, setValue } = useFormContext()

    const field = register(name, { validate: (v) => v.length > 0 || 'At least one day must be checked' })

    useEffect(() => {
        return () => {
            // manually unregister the input when this component unmounts because
            // for some dumb reason "shouldUnregister: true" is not behaving as expected
            unregister(name)
        }
    }, [])

    const labelFor = (day) => {
        if (compact) { return day.substr(0,3) }
        if (dense)   { return day.substr(0,1) }
        return day
    }

    const handleDayChange = (field, e) => {
        const target = Number.parseInt(e.target.value)

        const newValues = values.includes(target)
            ? [...values].filter(d => d !== target) // remove
            : [...values].concat(target)            // add

        // update react hook form validation
        field.onChange(e)
        // update our form object
        handleUpdate(name, newValues)
    }

    const renderSelectAll = (compactOrDense) => (
        <div
            key={`${field.name}-all`}
            className={`${(compactOrDense) ? 'col pr-3' : 'col-12'} mb-2 pl-0`}
        >
            <div className='custom-control custom-checkbox'>
                <input
                    id={`${name}-all`}
                    name={`${name}-all`}
                    className='custom-control-input'
                    type='checkbox'
                    value='all'
                    checked={values.length === 7}
                    onChange={() => {
                        const newValues = values.length > 0 ? [] : everyDay
                        // update react hook form validation
                        setValue(name, newValues)
                        // update our form object
                        handleUpdate(name, newValues)
                    }}
                />

                <label htmlFor={`${field.name}-all`} className='custom-control-label'>
                    { compactOrDense ? 'ALL' : 'Select All' }
                </label>
            </div>
        </div>
    )

    // each day is represented as an array
    // of a friendly name and an index int
    // example: [ 'monday', 1 ]
    return (
        <FormRow>
            { !(compact || dense) && renderSelectAll(compact || dense) }

            { days.map((day) => {
                let isDisabled

                switch(true) {
                    case overridingPublicStoreHours :
                        isDisabled = false
                        break
                    default :
                        isDisabled = Boolean(daysClosed[day[1]])
                        break
                }

                const isChecked = (
                    (overridingPublicStoreHours && values.includes(day[1]))
                        ||
                    (( !overridingPublicStoreHours && !Boolean(daysClosed[day[1]]) ) && values.includes(day[1]))
                )

                return (
                  <div
                      key={`${field.name}-${day[1]}`}
                      className={`${(compact || dense) ? 'col pr-3' : 'col-3'} mb-2 pl-0`}
                  >
                      <div className={`${day[0] === 'hidden' ? 'invisible ' : ''}custom-control custom-checkbox`}>
                          <input
                            id={`${field.name}-${day[1]}`}
                            ref={isDisabled ? null : field.ref}
                            name={field.name}
                            type='checkbox'
                            className={`custom-control-input ${!isDisabled && errorClass(field.name, errors)}`}
                            checked={isChecked}
                            disabled={isDisabled}
                            value={day[1]}
                            onChange={(e) => handleDayChange(field, e)}
                          />

                          <label
                              htmlFor={`${field.name}-${day[1]}`}
                              className={`custom-control-label ${!isDisabled && overridingPublicStoreHours && Boolean(daysClosed[day[1]]) ? 'text-danger' : ''}`}
                          >
                              { titleize(labelFor(day[0])) }
                          </label>
                      </div>
                  </div>
                )
            })}

            {
                (compact || dense)
                    && (overridingPublicStoreHours || daysClosed.length === 0)
                    && renderSelectAll(compact || dense)
            }

            {
                errors[name] && (
                  <div className="invalid-feedback d-block">
                      { errors[name].join(', ') }
                  </div>
                )
            }
        </FormRow>
    )
}
