import React from 'react'
import moment from 'moment'
import Input from '../Form/Input'
import FormRow from '../Form/FormRow'
import TextArea from '../Form/TextArea'
import Select from '../Form/Select'
import DatePicker from '../Form/DatePicker'
import Checkbox from '../Form/Checkbox'

export default function GiftCardPromotionForm({ handleUpdate, handleManualUpdate, handleCheckboxUpdate, form, errors }) {
  return (
    <div className="mt-4">
      <FormRow>
        <Input
          name="name"
          value={form.name}
          handleChange={handleUpdate}
          validation={{ name: { required: true } }}
          errors={errors}
        />
      </FormRow>

      <FormRow>
        <Select
          name="amount_type"
          cols={6}
          value={form.amount_type}
          options={[
            { display: 'Percentage %', value: 'percent' },
            { display: 'Dollar $', value: 'dollar' },
          ]}
          handleChange={handleUpdate}
          validation={{ amount_type: { required: true } }}
          errors={errors}
        />

        <Input
          name="amount"
          type="number"
          cols={6}
          value={form.amount}
          min={0}
          step={1}
          handleChange={handleUpdate}
          validation={{ amount: { required: true } }}
          errors={errors}
        />
      </FormRow>

      <FormRow>
        <Input
          name="promo_code"
          label="Promotion Code"
          placeholder="Promotion Code"
          cols={6}
          disabled={!form.require_code}
          value={form.promo_code}
          handleChange={handleUpdate}
          req={false}
          validation={{ promo_code: {
            required: false,
            pattern: {
              value: /^[0-9a-zA-Z]+$/,
              message: "Cannot contain whitespace or special characters.",
            }
          }}}
          errors={errors}
        />
        <div className='col-6 d-flex align-items-center mt-3'>
          <Checkbox
            name='require_code'
            label='Require Code?'
            value={Boolean(form.require_code)}
            handleChange={handleCheckboxUpdate}
          />
        </div>
      </FormRow>

      <FormRow>
        <DatePicker
          cols={3}
          name="valid_from"
          value={form.valid_from}
          handleChange={(_, date) => handleManualUpdate('valid_from', moment(date).format('YYYY-MM-DD')) }
          />

        <DatePicker
          cols={3}
          name="valid_to"
          value={form.valid_to}
          handleChange={(_, date) => handleManualUpdate('valid_to', moment(date).format('YYYY-MM-DD')) }
          req={false}
          validation={{ 'valid_to': {
            validate: () => {
              if (moment(form.valid_to).isBefore(form.valid_from)) {
                return 'Valid to should not be earlier than valid from'
              }

              return true
            }
          }}}
          errors={errors}
        />
      </FormRow>

      <FormRow>
        <TextArea
          name="description"
          rows={5}
          value={form.description}
          handleChange={handleUpdate}
        />
      </FormRow>
    </div>
  )
}
