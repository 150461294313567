import React, { useRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { selectHoldTimeDurationMinutes } from '@/features/Calendar/calendarSlice'

export default function CalendarCountdown({ refreshFrequencyInSeconds=1, booking, columns }) {

    const minutes               = useSelector(selectHoldTimeDurationMinutes)
    const [value, setValue]     = useState(0)
    const [started, setStarted] = useState(false)
    const timer                 = useRef(null)

    const calculateCountdown = () => {
        const expiration = moment(booking.created_at).add(parseInt(minutes, 10), 'minutes')
        const now        = moment()
        const diff       = moment(expiration).diff(now)
        const duration   = moment.duration(diff)

        let mins = Number.parseInt(duration.minutes())
        let secs = Number.parseInt(duration.seconds())

        if (secs < 10 && secs >= 0) {
            secs = `0${secs}`
        }

        if (!isNaN(mins) && !isNaN(mins) && mins >= 0 && secs >= 0) {
            setValue(`${mins}:${secs}`)
        }

        if (mins <= 0 && secs <= 0) {
            stopTimer()
            setValue(null)
            return
        }
    }

    const startTimer = () => {
        if (!timer.current) {
            timer.current = window.setInterval(() => {
                calculateCountdown()
                setStarted(true)
            }, refreshFrequencyInSeconds * 1000)
        }
    }

    const stopTimer = () => {
        window.clearInterval(timer.current)
        timer.current = null
    }

    // clear the timer upon component unmount
    useEffect(() => {
        startTimer()
        return () => stopTimer()
    }, [])

    return started && (
        <div className="booking-countdown">
            <small className="mr-2 bold">
                { columns >= 2 ? 'Hold Time Remaining' : 'Hold' }: &nbsp;{value || 'EXPIRED'}
            </small>
        </div>
    )
}
