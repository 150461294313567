import React, { useState } from 'react'
import MembershipTypeLedger from './Ledger/MembershipTypeLedger'
import MemberInformationLedger from './Ledger/MemberInformationLedger'
import MemberTermLedger from './Ledger/MemberTermLedger'

export default function MemberMobileProgressHeader() {

    const [open, setOpen] = useState(false)

    return (
        <div className="xbm-mobile-progress xbm-checkout-ledger d-block d-md-none px-4">
            <div
              className={`xbm-mobile-progress-trigger ${open ? 'open' : ''}`}
              onClick={() => setOpen(!open)}
            />

            <div className={`xbm-ledger-${open ? 'open' : 'collapse'}`}>
                <div className="xbm-ledger-section-type">
                    <MembershipTypeLedger />
                    <MemberInformationLedger />
                    <MemberTermLedger />
                </div>
            </div>
        </div>
    )
}
