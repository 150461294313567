import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setNewBooking } from '@/features/EditBooking/editBookingSlice'
import { setOpen, setStep, setNewSchedule } from '@/features/Schedule/scheduleSlice'
import { selectResourceType } from '@/features/ResourceType/resourceTypeSlice'
import { setNewPackage } from '@/features/Packages/packageSlice'
import { CREATE_RESERVATION } from '@/lib/Storage'

export function _AddBookingToggle() {

    const dispatch     = useDispatch()
    const resourceType = useSelector(selectResourceType)

    useEffect(() => {
        /**
         * if pre-existing customer info exists (set by the create reservation shortcut)
         * then automatically open the drawer when the page loads
         */
        const prefillData = JSON.parse(window.localStorage.getItem(CREATE_RESERVATION))
        if (prefillData) { handleOpen() }
    }, [])

    /**
     * Handle what happens when "add booking" button is clicked...
     * dispatch to set the new booking, step, and open the drawer
     */
    const handleOpen = () => {
        dispatch(setNewBooking())
        dispatch(setNewPackage())
        dispatch(setNewSchedule())
        dispatch(setStep('1'))
        dispatch(setOpen(true))
    }

    return !resourceType ? null : (
        <button
            id="new-quote-or-reservation"
            className="new-event"
            onClick={handleOpen}
        >
            <i className="far fa-plus" />
        </button>
    )
}

export const AddBookingToggle = React.memo(_AddBookingToggle)
