import React from 'react'

export function errorsFor(formErrors) {
    let errors = {}

    Object.keys(formErrors).map((field) => {
        const error_message = formErrors[field].message !== ''
            ? formErrors[field].message
            : 'This field is required.'

        errors[field] = [ error_message ]
    })

    return errors
}

export function errorMessageFor(field, errors) {
    if (!field || !errors || !errors[field]) { return '' }

    const text = errors[field]?.message && errors[field]?.message !== ''
                    ? errors[field]?.message
                    : !!errors[field] && Array.isArray(errors[field])
                        ? errors[field].join(', ')
                        : 'This field is required'

    return <div className="invalid-feedback d-block mx-2">{ text }</div>
}
