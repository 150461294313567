import { createSlice } from "@reduxjs/toolkit"
import axios from 'axios'
import { addAlert } from '@/features/Notifications/notificationSlice'

import { setProcessing as setTerminalProcessing, cancelTerminalTransaction } from '@/features/Adyen/adyenSlice'
import { PREVENT_LOADER, ADYEN_TERMINAL_SALE_ID, ADYEN_TERMINAL_SERVICE_ID } from '@/lib/Storage'
import MemberSteps, { RecipientTypes } from "@/lib/Member"
import { SecureHash } from '@/lib/Crypto'

export const memberSlice = createSlice({
    name: 'member',
    initialState: {
        disabled: false,
        loading: false,
        step: MemberSteps.SELECT_MEMBER_TYPE,
        termsOpen: false,
        detailsAndTerms: "",
        types: [],
        selectedType: null,
        locationId: null,
        companyId: null,
        recipientType: null,
        membership: null,
        taxRate: 0.0,
        membershipsTaxable: false,
        rkdReservationFee: 0.0,
        merchantReservationFee: 0.0,
        cards: [],
        purchaseFor: null,
        returnUrl: null,
        memberInformation: {
            id: null,
            firstName: '',
            lastName: '',
            birthdate: '',
            phone: '',
            zip_code: '',
            email: '',
            confirmEmail: '',
            newsletter: true,
            occasion: ''
        },
        buyerInformation: {
            firstName: '',
            lastName: '',
            birthdate: '',
            phone: '',
            email: '',
            confirmEmail: '',
            newsletter: true,
            occasion: ''
        },
        errors: {},
        autoRenew: true,
        terms: [],
        selectedTerm: null,
        allowCreditCardPayments: true,
        creditToken: null,
        creditZip: null,
        consented: false,
        membershipCard: null,
        giftCardNumber: "",
        giftCards: [],
        paymentReady: null,
        total: 0.0,
        createMember: false,
        membershipTypeId: "",
        membershipType: null,
        membershipTermIndex: 0,
        paymentType: 'credit',
        creditMethod: 'manual',
        checkNumber: null,
        terminalId: null,
        formErrors: {},
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setStep: (state, action) => {
            state.step = action.payload
        },
        setDisabled: (state, action) => {
            state.disabled = action.payload
        },
        setTermsOpen: (state, action) => {
            state.termsOpen = action.payload
        },
        setDetailsAndTerms: (state, action) => {
            state.detailsAndTerms = action.payload
        },
        setTypes: (state, action) => {
            state.types = action.payload
        },
        setSelectedType: (state, action) => {
            state.selectedType = action.payload
            // add ids to terms
            state.terms = action.payload.membership_terms.map((term, index) => (
                {
                    ...term,
                    id: index + 1
                }
            ))
        },
        setMembershipTypeId: (state, action) => {
            state.membershipTypeId = action.payload
        },
        setMembershipType: (state, action) => {
            state.membershipType = action.payload
        },
        setMembershipTermIndex: (state, action) => {
            state.membershipTermIndex = action.payload
        },
        setLocationAndCompany: (state, action) => {
            state.locationId = parseInt(action.payload.locationId)
            state.companyId  = parseInt(action.payload.companyId)
        },
        setRecipientType: (state, action) => {
            state.recipientType = action.payload
        },
        setMemberInformationField: (state, action) => {
            state.memberInformation[action.payload.name] = action.payload.value
        },
        setBuyerInformationField: (state, action) => {
            state.buyerInformation[action.payload.name] = action.payload.value
        },
        setErrors: (state, action) => {
            // errors are scoped by step in order to allow the user to navigate back
            // and then be able to click next again to come back to the disabled step.
            // if we didn't do this, if the form was ever disabled because they haven't
            // filled out information yet, and they click back, they would never be able
            // to get back to the step they were on. therefore, we scope errors per step.
            let errors = state.errors[state.step]
            if (!errors) {
                errors = {}
            }
            errors[action.payload.type] = action.payload.value
            state.errors[state.step] = errors
        },
        deleteError: (state, action) => {
            // delete an error in a specified step
            // payload = [STEP, ERROR_KEY]
            if (Array.isArray(action.payload) && !!state.errors[action.payload[0]] && !!state.errors[action.payload[0]][action.payload[1]]) {
                delete state.errors[action.payload[0]][action.payload[1]]
            }

            // delete an error in the current step
            // payload = ERROR_KEY
            if (typeof action.payload === 'string' && !!state.errors[state.step] && !!state.errors[state.step][action.payload]) {
                delete state.errors[state.step][action.payload]
            }
        },
        setAutoRenew: (state, action) => {
            state.autoRenew = action.payload
        },
        setTerms: (state, action) => {
            state.terms = action.payload
        },
        setSelectedTerm: (state, action) => {
            state.selectedTerm = action.payload
        },
        setCreditToken: (state, action) => {
            state.creditToken = action.payload
        },
        setCreditZip: (state, action) => {
            state.creditZip = action.payload
        },
        setConsented: (state, action) => {
            state.consented = action.payload
        },
        setMembership: (state, action) => {
            state.membership = action.payload
        },
        setCards: (state, action) => {
            state.cards = action.payload
        },
        setMembershipCard: (state, action) => {
            state.membershipCard = action.payload

            // if we're setting a membership card, then
            // we can assume that payment is also ready
            state.paymentReady = !!action.payload
        },
        setTaxRate: (state, action) => {
            state.taxRate = action.payload
        },
        setMembershipsTaxable: (state, action) => {
            state.membershipsTaxable = action.payload
        },
        setRkdReservationFee: (state, action) => {
            state.rkdReservationFee = action.payload
        },
        setMerchantReservationFee: (state, action) => {
            state.merchantReservationFee = action.payload
        },
        setGiftCardNumber: (state, action) => {
            state.giftCardNumber = action.payload
        },
        setGiftCards: (state, action) => {
            if (action.payload.type === 'add') {
                state.giftCards = [...state.giftCards, action.payload.newGiftCard]
            } else if (action.payload.type === 'clear') {
                state.giftCards = []
            }
        },
        setPaymentReady: (state, action) => {
            state.paymentReady = action.payload
        },
        setTotal: (state, action) => {
            state.total = action.payload
        },
        setPurchaseFor: (state, action) => {
            state.purchaseFor = action.payload
        },
        setReturnUrl: (state, action) => {
            state.returnUrl = action.payload
        },
        setAllowCreditCardPayments: (state, action) => {
            state.allowCreditCardPayments = action.payload
        },
        setCreateMember: (state, action) => {
            state.createMember = action.payload
        },
        setPaymentType: (state, action) => {
            state.paymentType = action.payload
        },
        setCreditMethod: (state, action) => {
            state.creditMethod = action.payload
        },
        setCheckNumber: (state, action) => {
            state.checkNumber = action.payload
        },
        setTerminalId: (state, action) => {
            state.terminalId = action.payload
        },
        setFormErrors: (state, action) => {
            state.formErrors = action.payload
        },
    }
})

export const {
    setLoading,
    setStep,
    setDisabled,
    setTypes,
    setSelectedType,
    setLocationAndCompany,
    setRecipientType,
    setMemberInformationField,
    setBuyerInformationField,
    setErrors,
    deleteError,
    setAutoRenew,
    setTerms,
    setSelectedTerm,
    setCreditToken,
    setCreditZip,
    setConsented,
    setMembership,
    setCards,
    setMembershipCard,
    setTermsOpen,
    setDetailsAndTerms,
    setTaxRate,
    setMembershipsTaxable,
    setRkdReservationFee,
    setMerchantReservationFee,
    setGiftCardNumber,
    setGiftCards,
    setPaymentReady,
    setTotal,
    setPurchaseFor,
    setReturnUrl,
    setAllowCreditCardPayments,
    setCreateMember,
    setMembershipTypeId,
    setMembershipType,
    setMembershipTermIndex,
    setPaymentType,
    setCreditMethod,
    setCheckNumber,
    setTerminalId,
    setFormErrors,
} = memberSlice.actions

export const selectLoading                 = state => state.member.loading
export const selectStep                    = state => state.member.step
export const selectDisabled                = state => state.member.disabled
export const selectTypes                   = state => state.member.types
export const selectSelectedType            = state => state.member.selectedType
export const selectLocationId              = state => state?.member?.locationId
export const selectCompanyId               = state => state.member.companyId
export const selectRecipientType           = state => state.member.recipientType
export const selectMemberInformation       = state => state.member.memberInformation
export const selectBuyerInformation        = state => state.member.buyerInformation
export const selectErrors                  = state => state.member.errors
export const selectStepErrors              = state => state.member.errors[state.member.step] || {}
export const selectAutoRenew               = state => state.member.autoRenew
export const selectTerms                   = state => state.member.terms
export const selectSelectedTerm            = state => state.member.selectedTerm
export const selectCreditToken             = state => state.member.creditToken
export const selectCreditZip               = state => state.member.creditZip
export const selectConsented               = state => state.member.consented
export const selectMembership              = state => state.member.membership
export const selectCards                   = state => state.member.cards
export const selectMembershipCard          = state => state.member.membershipCard
export const selectTermsOpen               = state => state.member.termsOpen
export const selectDetailsAndTerms         = state => state.member.detailsAndTerms
export const selectTaxRate                 = state => state.member.taxRate
export const selectMembershipsTaxable      = state => state.member.membershipsTaxable
export const selectRkdReservationFee       = state => state.member.rkdReservationFee
export const selectMerchantReservationFee  = state => state.member.merchantReservationFee
export const selectGiftCardNumber          = state => state?.member?.giftCardNumber
export const selectGiftCards               = state => state?.member?.giftCards
export const selectPaymentReady            = state => state?.member?.paymentReady
export const selectTotal                   = state => state?.member?.total
export const selectPurchaseFor             = state => state?.member?.purchaseFor
export const selectReturnUrl               = state => state?.member?.returnUrl
export const selectAllowCreditCardPayments = state => state?.member?.allowCreditCardPayments
export const selectCreateMember            = state => state?.member?.createMember
export const selectMembershipTypeId        = state => state?.member?.membershipTypeId
export const selectMembershipType          = state => state?.member?.membershipType
export const selectMembershipTermIndex     = state => state?.member?.membershipTermIndex
export const selectPaymentType             = state => state?.member?.paymentType
export const selectCreditMethod            = state => state?.member?.creditMethod
export const selectCheckNumber             = state => state?.member?.checkNumber
export const selectTerminal                = state => state?.member?.terminalId
export const selectFormErrors              = state => state?.member?.formErrors

export function fetchMembershipTypes() {
    return async (dispatch, getState) => {
        const locationId      = getState().member.locationId
        const companyId       = getState().member.companyId
        const queryParameters = new URLSearchParams(window.location.search)
        const membershipType  = queryParameters.get("membership_type_id")

        const url = !!membershipType
            ? `/companies/${companyId}/locations/${locationId}/membership_types.json?membership_type_id=${membershipType}`
            : `/companies/${companyId}/locations/${locationId}/membership_types.json`

        // Handles modal previews instances when only a single membership type should be loaded
        axios.get(url).then(({ data }) => {
            if (data.length === 0) {
                dispatch(setDisabled(true))
                return
            }

            if (!!membershipType) {
                dispatch(setSelectedType(data[0]))
                dispatch(setStep(MemberSteps.SELECT_RECIPIENT))
            } else {
                dispatch(setTypes(data))
            }
        })
        .catch((error) => {
            dispatch(addAlert({ type: 'error', text: (error?.response?.data?.message || 'Unable to fetch membership types!') }))
        })
    }
}

export function validateEmailAddress(email) {
    return async (_dispatch, getState) => {
        const locationId = getState().member.locationId
        const companyId  = getState().member.companyId
        const url        = `/companies/${companyId}/locations/${locationId}/members/validate_email?email=${encodeURIComponent(email)}`

        return axios.get(url)
        .then(({ data }) => data)
        .catch((error) => { return error?.response?.data })
    }
}

export function submitMember(admin=false) {
    return async (dispatch, getState) => {
        const member       = getState().member
        const locationId   = member.locationId
        const companyId    = member.companyId
        const gifted       = member.recipientType === RecipientTypes.GIFT
        const adyenPayment = getState().adyen.adyenPayment

        window.axiosTransactionSource = axios.CancelToken.source()

        window.sessionStorage.setItem(PREVENT_LOADER, true)
        window.sessionStorage.setItem(ADYEN_TERMINAL_SALE_ID, SecureHash(10))
        window.sessionStorage.setItem(ADYEN_TERMINAL_SERVICE_ID, SecureHash(5))

        if (admin && !!member.terminalId) {
            dispatch(setTerminalProcessing(true))
        } else {
            dispatch(setLoading(true))
        }

        let membershipInfo = {
            membership_type_id: member.selectedType?.id || member.membershipType?.id,
            auto_renew: member.autoRenew,
            gifted: gifted,
        }

        if (!admin) {
            membershipInfo = {
                ...membershipInfo,
                gifter_first_name: member.buyerInformation.firstName,
                gifter_last_name: member.buyerInformation.lastName,
                gift_email: member.buyerInformation.email,
                gifter_phone: member.buyerInformation.phone,
                gifter_newsletter: member.buyerInformation.newsletter,
                occasion: member.memberInformation.occasion
            }
        }

        axios.request({
            url: `/companies/${companyId}/locations/${locationId}/members`,
            cancelToken: window.axiosTransactionSource.token,
            method: 'POST',
            data: {
                member: {
                    first_name: member.memberInformation.firstName,
                    last_name: member.memberInformation.lastName,
                    birthdate: member.memberInformation.birthdate,
                    phone: member.memberInformation.phone,
                    email: member.memberInformation.email,
                    newsletter: member.memberInformation.newsletter,
                    zip_code: member.memberInformation.zip_code,
                    company_id: companyId,
                },
                membership: membershipInfo,
                term: member.selectedTerm,
                credit_token: member.creditToken,
                credit_zip: member.creditZip,
                gift_cards: JSON.stringify(member.giftCards),
                only_gift_card: member.paymentReady,
                payment_profile_card_id: member.membershipCard,
                adyen_payment: adyenPayment,
                purchase_for: member.purchaseFor,
                member_id: member.memberInformation.id,
                transaction_type: member.paymentType,
                credit_method: member.creditMethod,
                check_number: member.checkNumber,
                terminal_id: member.terminalId,
                sale_id: window.sessionStorage.getItem(ADYEN_TERMINAL_SALE_ID),
                service_id: window.sessionStorage.getItem(ADYEN_TERMINAL_SERVICE_ID),
                via_admin: admin,
                gift_card: member.paymentType === 'gift_card' ? getState().products.giftCardNumber : null,
            },
            headers: {
                timeout: admin && !!member.terminalId ? 90 : 20 // 0 indicates no timeout
            }
        }).then(({ data }) => {
            if (admin) {
                if (data.error) {
                    dispatch(addAlert({ type: 'error', text: data.message }))
                    return
                }

                window.location = `/companies/${companyId}/locations/${locationId}/members/${data.member.id}`
            } else {
                if (data.error) {
                    dispatch(setErrors({ type: 'submit', value: data.message }))
                    dispatch(addAlert({ type: 'error', text: 'An error occurred!' }))
                    return
                }

                dispatch(setMembership(data.membership))

                // set step to confirmation
                dispatch(setStep(MemberSteps.CONFIRMATION))
            }

        }).catch((e) => {
            if (axios.isCancel(e)) {
                console.log(e.message.toUpperCase())
            } else {
                if (console) { console.warn(e) }

                if (e.response.status === 504) {
                    dispatch(addAlert({ type: 'error', text: 'TRANSACTION TIMED OUT' }))

                    if (admin && !!member.terminalId) {
                        dispatch(cancelTerminalTransaction())
                    }
                } else {
                    dispatch(addAlert({ type: 'error', text: e?.response?.data?.message || 'TRANSACTION FAILED!' }))
                }
            }
        }).finally(() => {
            window.sessionStorage.removeItem(PREVENT_LOADER)
            window.sessionStorage.removeItem(ADYEN_TERMINAL_SALE_ID)
            window.sessionStorage.removeItem(ADYEN_TERMINAL_SERVICE_ID)
            delete window.axiosTransactionSource

            if (admin && !!member.terminalId) {
                dispatch(setTerminalProcessing(false))
            } else {
                dispatch(setLoading(false))
            }
        })
    }
}

export function updateMembership() {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        const member = getState().member
        const membership = member.membership
        const locationId = member.locationId
        const companyId = member.companyId

        const adyenPayment = getState().adyen.adyenPayment

        axios.patch(`/member/memberships/${membership.id}`, {
            membership: {
                membership_type_id: member.selectedType.id,
                auto_renew: member.autoRenew,
            },
            term: member.selectedTerm,
            payment_profile_card_id: member.membershipCard,
            adyen_payment: adyenPayment,
        })
        .then(({ data }) => {
            if (data.error) {
                dispatch(setErrors({ type: 'submit', value: data.message }))
                return
            }

            // set step to confirmation
            dispatch(setStep(MemberSteps.CONFIRMATION))
        })
        .catch((error) => {
            dispatch(addAlert({ type: 'error', text: (error?.response?.data?.message || 'Unable to update membership!') }))
        })
        .finally(() => {
            dispatch(setLoading(false))
        })
    }
}

export default memberSlice.reducer
