import React from 'react'
import { useSelector } from 'react-redux'
import { selectSelectedProducts } from './productSlice'
import { numberToCurrency } from '@/lib/Number'

export default function ProductTransactionItems() {

    const items = useSelector(selectSelectedProducts)

    return items.length === 0 ? null : <>
        <table className='table table-striped table-sm'>
            <tbody>
                {
                  items.map((item) => (
                      <tr key={item.id}>
                          <td className="wrap"><strong>{item.name}</strong></td>
                          <td className="text-right">{numberToCurrency(item.price)} x {item.quantity}</td>
                          <td className="text-right">{numberToCurrency(item.quantity * Number.parseFloat(item.price))}</td>
                      </tr>
                  ))
                }
            </tbody>
        </table>

        <hr />
    </>
}
