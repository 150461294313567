import React, { useMemo } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { selectPackage } from '../Packages/packageSlice'
import { selectCustomerTypes } from '../CustomerTypes/customerTypeSlice'
import { selectSelectedResourceType } from "../ResourceType/resourceTypeSlice"
import {
    selectCustomerTypeCounts,
    selectParticipants,
    selectBooking,
    selectMembers,
} from './editBookingSlice'
import { selectLocation } from '../Locations/locationSlice'
import BookingLedger from '../Schedule/components/BookingLedger'
import { evaluateParticipantPricingByGroupType, taxRateAndFeesFor, calculateMemberPrice } from '@/lib/Pricing'

export default function EditBookingLedger({ userName, handleViewParent }) {
    const pakage                 = useSelector(selectPackage)
    const participants           = useSelector(selectParticipants)
    const formCustomerTypeCounts = useSelector(selectCustomerTypeCounts)
    const customerTypes          = useSelector(selectCustomerTypes)
    const booking                = useSelector(selectBooking)
    const resourceType           = useSelector(selectSelectedResourceType)
    const members                = useSelector(selectMembers)
    const location               = useSelector(selectLocation)

    /**
     * @TODO I'm thinking we don't even need to do this... i think we should just use base_price_cents, tax/fee, price_cents to do this...
     */
    // useEffect(async () => {
    //     const fetch = async () => {
    //         await dispatch(fetchPrices(packageId, participants, formCustomerTypeCounts, duration, date, time))
    //     }
    //     if (packageId && participants && duration && date && time && formCustomerTypeCounts && customerTypesLoaded) {
    //         fetch()
    //         // dispatch(fetchPrices(packageId, participants, formCustomerTypeCounts, duration, date, time))
    //     }
    // }, [packageId, participants, formCustomerTypeCounts, duration, date, time, customerTypesLoaded])

    const taxRateAndFees = useMemo(() => (
        taxRateAndFeesFor(location, pakage, booking)
    ), [location, pakage, booking])

    const parsedParticipantCounts = useMemo(() => (
        evaluateParticipantPricingByGroupType(
            pakage,
            {
              ...booking,
              start_time: moment.tz(booking.start_time, location.time_zone),
              weekday: moment.tz(booking.start_time, location.time_zone).format('dddd')
            },
            formCustomerTypeCounts,
            customerTypes,
            taxRateAndFees.tax_rate,
            taxRateAndFees.rkd_reservation_fee,
            taxRateAndFees.merchant_reservation_fee,
            taxRateAndFees.third_party_reservation_fee,
            location,
            participants,
            resourceType
        )
    ), [pakage, booking, formCustomerTypeCounts, customerTypes, taxRateAndFees, location, participants, resourceType])

    const memberPrice = useMemo(() => (
        calculateMemberPrice(
            pakage,
            {
                ...booking,
                start_time: moment.tz(booking.time, location.time_zone),
                weekday: moment.tz(booking.date, location.time_zone).format('dddd')
            },
            formCustomerTypeCounts,
            location,
            resourceType
        )
    ), [pakage, booking, location, resourceType])

    return (
        <BookingLedger
            basePrice={(booking.base_price_cents / 100).toFixed(2)}
            autoGratuity={(booking.auto_gratuity_cents / 100).toFixed(2)}
            taxesAndFees={((booking.taxes_cents + booking.rkd_fees_cents + booking.merchant_fees_cents + booking.third_party_fees_cents) / 100).toFixed(2)}
            total={(booking.price_cents / 100).toFixed(2)}
            customerTypeTotals={parsedParticipantCounts}
            members={members}
            memberPrice={memberPrice}
            discounts={booking.booking_discounts}
            discountTotal={(Number.parseFloat(booking.discount_cents) / 100).toFixed(2)}
            showLedgerBreakdown={true}
            userName={userName}
            handleViewParent={handleViewParent}
        />
    )
}