import React from 'react'
import { useDispatch } from 'react-redux'
import NumberIncrementer from '../../components/Form/NumberIncrementer'
import { selectProduct } from './productSlice'
import { numberToCurrency } from '@/lib/Number'

export default function ProductToggle({ product, quantity, previewMode}) {

    const dispatch = useDispatch()

    const handleChange = amount => {
        if (amount < 0) {
            return
        }

        // we have to parse the target amount because the type is different if they click the increment
        // buttons or if they manually type (which fires off as an input onChange event)
        const targetAmount = Number.parseInt(typeof amount === 'object' ? amount.target.value : amount) || 0

        dispatch(selectProduct({
            product: product,
            amount:  targetAmount
        }))
    }

    return (
        <div className="my-3">
            <label className="pl-1">{product.name}</label>

            <div className="d-flex align-items-center p-1 pl-3 border rounded">
                <strong>
                    {numberToCurrency(product.price)}
                </strong>

                <div className="ml-auto" style={{ width: '60%' }}>
                    <NumberIncrementer
                      value={quantity}
                      incrementAmount={1}
                      disabled={previewMode}
                      handleChange={handleChange}
                    />
                </div>
            </div>
        </div>
    )
}
