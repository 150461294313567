import React from 'react'
import { useSelector } from 'react-redux'
import { selectLocation } from '../../../Locations/locationSlice'

export default function LedgerLocation() {

    const location = useSelector(selectLocation)

    if (!location.id) {
        return null
    }

    return (
        <div className="xbm-ledger-section">
            <b>{location.name}</b><br/>
            {location.address_line_1}<br/>
            {location.address_line_2 && <>{location.address_line_2}<br/></>}
            {location.city},&nbsp;{location.state}&nbsp;{location.zip}
        </div>
    )
}