export function isAdvancedPointOfSaleEnabled(status='', options={}) {
    options = {
        allowTransition: false,
        ...options
    }

    if (options.allowTransition === true) {
        return /^(enabled|in_transition)$/i.test(status)
    } else {
        return /^(enabled)$/i.test(status)
    }
}

export function isAdvancedPointOfSaleInTransition(status='') {
    return /^(in_transition)$/i.test(status)
}

export function isAdvancedPointOfSaleDisabled(status='') {
    return /^(disabled)$/i.test(status)
}
