import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import titleize from '../../lib/String'
import { selectPaymentType, setPaymentType } from './productSlice'

export default function ProductPaymentType() {

    const dispatch = useDispatch()

    const paymentType = useSelector(selectPaymentType)

    const paymentLabel = paymentType => {
        if (paymentType === 'credit') {
            return "Credit/Debit"
        } else {
            return titleize(paymentType)
        }
    }

    return (
        <ul className="radio-tabs">
            {
                ['credit', 'terminal', 'cash', 'check', 'gift_card'].map(t => (
                    <li key={t}>
                        <div className="radio-tab">
                            <input
                                id={`type-${t}`}
                                className='radio-tab-input'
                                type='radio'
                                name='payment_type'
                                value={t}
                                onChange={() => dispatch(setPaymentType(t))}
                                checked={paymentType === t} />
                            <label className='radio-tab-label' htmlFor={`type-${t}`}>{paymentLabel(t)}</label>
                        </div>
                    </li>
                ))
            }
        </ul>
    )
}
