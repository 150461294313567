import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectBooking, setOpenAddCardBookingModal } from '@/features/EditBooking/editBookingSlice'
import { isBookingCancelled } from '@/lib/Booking'

export default function CapturePaymentCard({
    className='btn-primary',
    method='PRE_AUTHORIZE', // or TOKENIZE
    disabled=false
}) {

    const dispatch = useDispatch()
    const booking  = useSelector(selectBooking)

    const open = () => {
        dispatch(setOpenAddCardBookingModal(true))
    }

    if (method === 'TOKENIZE' && !!booking?.source_type && !/^(direct|walk-in)$/i.test(booking?.source_type)) { return null }

    return (
        <button
            children={booking.has_card_on_file ? 'Captured!' : 'Capture / Pre-Auth'}
            className={`btn ${className} btn-block`}
            disabled={disabled || booking.has_card_on_file || isBookingCancelled(booking) || !!booking.check}
            onClick={open}
        />
    )
}
