import React, { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
    setCashChangeDue,
    selectAmountType,
    selectCustomAmount,
    selectCustomerTypeCounts,
    selectTipAmount,
    selectTipType,
    selectDepositToApply,
    selectCustomersToPayFor,
    selectCashAmount
} from './bookingPaymentSlice'

import { selectBooking } from '../EditBooking/editBookingSlice'
import { selectPackage } from '../Packages/packageSlice'
import { selectLocation } from '../Locations/locationSlice'
import { selectCustomerTypes } from '../CustomerTypes/customerTypeSlice'
import { selectSelectedResourceType } from '../ResourceType/resourceTypeSlice'
import { taxRateAndFeesFor, parsePricingByType, calculatedTip } from '@/lib/Pricing'

export default function PaymentCashChange() {

    const dispatch           = useDispatch()
    const cashAmount         = useSelector(selectCashAmount)
    const amountType         = useSelector(selectAmountType)
    const booking            = useSelector(selectBooking)
    const customerTypeCounts = useSelector(selectCustomerTypeCounts)
    const pkg                = useSelector(selectPackage)
    const location           = useSelector(selectLocation)
    const customerTypes      = useSelector(selectCustomerTypes)
    const customAmount       = useSelector(selectCustomAmount)
    const tip                = useSelector(selectTipAmount)
    const tipType            = useSelector(selectTipType)
    const depositToApply     = useSelector(selectDepositToApply)
    const customersToPayFor  = useSelector(selectCustomersToPayFor)
    const resourceType       = useSelector(selectSelectedResourceType)

    const taxRateAndFees = useMemo(() => (
        taxRateAndFeesFor(location, pkg, booking)
    ), [location, pkg, booking])

    const prices = useMemo(() => (
        parsePricingByType(
            amountType,
            pkg,
            booking,
            customerTypeCounts,
            customerTypes,
            taxRateAndFees.tax_rate,
            taxRateAndFees.rkd_reservation_fee,
            taxRateAndFees.merchant_reservation_fee,
            taxRateAndFees.third_party_reservation_fee,
            location,
            resourceType,
            customersToPayFor,
            customAmount
        )
    ), [amountType, pkg, booking, customerTypeCounts, customerTypes, taxRateAndFees, location, resourceType, customersToPayFor, customAmount])

    useEffect(() => {
        if (Boolean(cashAmount)) {
            const changeDue = (
              Number.parseFloat(prices.total)
              + Number.parseFloat(calculatedTip(prices.basePrice, tip, tipType))
              - Number.parseFloat(depositToApply || 0)
            ).toFixed(2)

            dispatch(setCashChangeDue(
                Number.parseFloat(cashAmount || 0) - Number.parseFloat(changeDue || 0)
            ))
        }
    }, [prices, cashAmount, depositToApply, tip, tipType])

    return null
}
