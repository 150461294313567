import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'

import {
    generateWaiverLink,
    openManagerOverrideModal,
    selectBooking,
    selectExpandedActions,
    setExpandedActions,
    setOpenServerSelectModal,
    setOpenSendLinkToCustomerModal,
} from '@/features/EditBooking/editBookingSlice'

import { selectSelectedResourceType } from '@/features/ResourceType/resourceTypeSlice'
import { openBookingHistoryModal } from '@/features/BookingHistory/bookingHistorySlice'
import { setModalOpen } from '@/features/Products/productSlice'
import { selectLocation } from '@/features/Locations/locationSlice'
import EditBookingAddonTime from '@/features/EditBooking/EditBookingAddonTime'
import RemoveBookingAddonTime from '@/features/EditBooking/RemoveBookingAddonTime'
import ReservationPayment from '@/features/EditBooking/EditBookingPayment'
import CapturePaymentCard from '@/features/EditBooking/CapturePaymentCard'
import GenerateInvoice from '@/features/EditBooking/GenerateInvoice'
import Icon from '@/components/FontAwesomeIcon'
import QRCodeLink from '@/components/QRCodeLink'
import { isAdvancedPointOfSaleEnabled } from '@/lib/AdvancedPointOfSale'
import { isBookingCancelled, isBookingComplete } from '@/lib/Booking'
import { accessToken } from "@/lib/Csrf"

export function _BookingLedgerActions({ isExpandable=true, children }) {

    const dispatch     = useDispatch()
    const location     = useSelector(selectLocation)
    const resourceType = useSelector(selectSelectedResourceType)
    const booking      = useSelector(selectBooking)
    const isExpanded   = useSelector(selectExpandedActions)
    const waiverLink   = useSelector(generateWaiverLink)

    const [followUpEmail, setFollowUpEmail]   = useState(true)
    const [isWaiverCopied, setIsWaiverCopied] = useState(false)

    const handleExpansionToggle = () => {
        dispatch(setExpandedActions(!isExpanded))
    }

    /**
     * construct the customer waiver link and copy to clipboard
     * display the "copied" message then after 3 seconds re-render the button
     */
    const handleCopyWaiverLinkToClipboard = () => {
        navigator?.clipboard?.writeText(waiverLink)
        setIsWaiverCopied(true)
        setTimeout(() => { setIsWaiverCopied(false) }, 3000)
    }

    const handleFollowUpEmail = (followUpEmail) => {
        if (!booking?.id) { throw('Booking unknown!') }

        axios.patch(`/bookings/${booking.id}/toggle_follow_up_email`, {
            authenticity_token: accessToken,
            follow_up_email: followUpEmail,
        })
    }

    const handleSendLinkToCustomer = () => {
        dispatch(setOpenSendLinkToCustomerModal(true))
    }

    useEffect(() => {
        setFollowUpEmail(booking?.send_follow_up_email)
    }, [booking])

    return (
        <div className={`drawer-booking-ledger-actions pt-4 expandable ${isExpandable && isExpanded ? 'expanded' : ''}`}>
            {
                isExpandable && (
                    <Icon
                        title={`Click to see ${isExpanded ? 'less' : 'more'}`}
                        icon={`fa-circle-caret-${isExpanded ? 'down' : 'up'}`}
                        packs={['fa-solid']}
                        className='expandable-toggle'
                        onClick={handleExpansionToggle}
                    />
                )
            }

            <div className='expandable-content booking-drawer-expandable-content'>
                <ReservationPayment />

                {
                    isAdvancedPointOfSaleEnabled(location.advanced_point_of_sale_status) && (
                        <CapturePaymentCard className='btn-outline' />
                    )
                }

                {
                    isAdvancedPointOfSaleEnabled(location.advanced_point_of_sale_status) && resourceType.display_in_pos ? (
                        <button
                            children={`${(isBookingCancelled(booking) || !!booking.check) ? 'Sent' : 'Send'} to POS`}
                            className="btn btn-primary btn-block send-to-pos"
                            disabled={isBookingCancelled(booking) || !!booking.check}
                            onClick={() => dispatch(setOpenServerSelectModal(true))}
                        />
                    ) : !isAdvancedPointOfSaleEnabled(location.advanced_point_of_sale_status) && (
                        <button
                            children='POS Transaction'
                            className="btn btn-primary btn-block"
                            disabled={isBookingCancelled(booking)}
                            onClick={() => dispatch(setModalOpen(true))}
                        />
                    )
                }

                <button
                    children='Reservation History'
                    className={`btn ${!!booking.check ? 'btn-primary' : 'btn-outline'} btn-block`}
                    onClick={() => dispatch(openBookingHistoryModal())}
                />

                {
                    isExpandable && isExpanded && <>
                        <hr />

                        <button
                            children='Manager Override'
                            className="btn btn-outline btn-block"
                            disabled={isBookingCancelled(booking) || isBookingComplete(booking) || booking?.check?.has_adv_pos_booking_payment_been_received}
                            onClick={() => dispatch(openManagerOverrideModal())}
                        />

                        {
                            !booking.is_parent && !booking.is_child && <>
                                {
                                    booking.booking_addon_times.length > 0 ? (
                                        <div className="row no-gutters pb-1 mb-2">
                                            <div className="col-6 pr-1">
                                                <EditBookingAddonTime twoColumn />
                                            </div>
                                            <div className="col-6 pl-1">
                                                <RemoveBookingAddonTime />
                                            </div>
                                        </div>
                                    ) : (
                                      <EditBookingAddonTime />
                                    )
                                }
                            </>
                        }

                        {
                            booking.resource_type.waivers_required && <>
                                {
                                    navigator.clipboard ? (
                                        <button
                                            children={isWaiverCopied ? 'Copied!' : 'Copy Waiver Link'}
                                            type="button"
                                            className="btn btn-outline btn-block"
                                            disabled={isWaiverCopied}
                                            onClick={handleCopyWaiverLinkToClipboard}
                                        />
                                    ) : (
                                        <a
                                            children='Sign Waivers'
                                            href={waiverLink}
                                            className='btn btn-outline btn-block'
                                            title='Right click to copy the link'
                                            target='_blank'
                                            rel='noopener nofollow'
                                        />
                                    )
                                }

                                <QRCodeLink
                                    url={waiverLink}
                                    content='<button type="button" class="btn btn-outline btn-block">Waiver QR Code</button>'
                                />
                            </>
                        }

                        <hr />

                        <button
                            children='Send Link To Customer'
                            type="button"
                            className="btn btn-outline btn-block"
                            onClick={handleSendLinkToCustomer}
                        />

                        {
                            !booking.follow_up_email_disabled && <>
                                <hr />
                                <div className='form-group'>
                                    <div className="custom-control custom-switch">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id='toggle-follow-up-email'
                                            name="follow-up-email"
                                            defaultChecked={followUpEmail}
                                            disabled={isBookingCancelled(booking) || isBookingComplete(booking)}
                                            onChange={() => handleFollowUpEmail(!followUpEmail)}
                                        />
                                        <label className="custom-control-label text-bold" htmlFor='toggle-follow-up-email'>
                                            Send follow up email?
                                        </label>
                                    </div>
                                </div>
                            </>
                        }

                        { children }
                    </>
                }
            </div>
        </div>
    )
}

export const BookingLedgerActions = React.memo(_BookingLedgerActions)
