import React from 'react'
import FormRow from '../../../../components/Form/FormRow'
import Input from '../../../../components/Form/Input'
import { useDispatch } from 'react-redux'
import { setMember } from '../../bookingSlice'

export default function MemberInput({ member, index }) {
    const dispatch = useDispatch()

    const handleChange = (e) => {
        dispatch(setMember({
            index: index,
            memberId: e.target.value
        }))
    }

    const key = `member_id_${index}`

    return (
        <FormRow>
            <Input
                name={key}
                type="text"
                label="MEMBER ID"
                placeholder="Member ID"
                value={member?.memberId || ''}
                validation={{ [key]: {
                    required: true
                }}}
                handleChange={handleChange}
                errors={member?.errors ? { [key]: member?.errors } : {}}
            />
        </FormRow>
    )
}
