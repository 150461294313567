import { createSlice } from "@reduxjs/toolkit"
import axios from 'axios'
import { addAlert } from '@/features/Notifications/notificationSlice'

export const customerTypeSlice = createSlice({
    name: 'customerType',
    initialState: {
        customerTypes: []
    },
    reducers: {
        setCustomerTypes: (state, action) => {
            state.customerTypes = action.payload
        }
    }
})

export const {
    setCustomerTypes
} = customerTypeSlice.actions

export const selectCustomerTypes = state => state.customerType.customerTypes

export function fetchCustomerTypes(url, version=null) {
    return async (dispatch) => {
        axios.get(version ? `${url}?version=${version}` : url)
        .then(({ data }) => {
            if (Array.isArray(data)) {
                dispatch(setCustomerTypes(data))
            } else {
                dispatch(addAlert({ type: 'error', text: 'Could not load customer types' }))
            }
        })
    }
}

export default customerTypeSlice.reducer
