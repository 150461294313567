import moment from 'moment'
import days from '@/lib/Days'

export const name     = 'specific_time_block'
export const everyDay = days.map((tuple) => tuple[1])

export function handleAdd(times, daysClosed, fn) {
    fn(name, times.concat({
        time: null,
        days: everyDay.filter((d, i) => daysClosed[i] === false)
    }))
}

export function handleTimeChange(form, times, start_time, end_time, timezone, index, fn) {
    const friendlyST   = moment.tz(start_time, 'YYYY-MM-DDTHH:mmZZ', timezone).format('LT')
    const friendlyET   = moment.tz(end_time, 'YYYY-MM-DDTHH:mmZZ', timezone).format('LT')
    const selectedDays = form[name][index]?.days || []

    fn(name, times.map((time, i) => {
        if (i === index) {
            let update = {
                ...time,
                days: selectedDays || everyDay,
            }

            if (start_time) {
                update['time'] = friendlyST
            }

            if (end_time) {
                update['end_time'] = friendlyET
            }

            return update
        } else {
            return time
        }
    }))
}

export function handleDayChange(times, checkbox_name, values, fn) {
    const index = Number(checkbox_name.substr(-1))

    fn(name, times.map((time, i) => {
        if (i === index) {
            return {
                ...time,
                days: values.sort()
            }
        } else {
            return time
        }
    }))
}

export function handleRemove(times, index, fn) {
    fn(name, times.filter((t, i) => i !== index))
}
