import React from 'react'
import { useDispatch } from 'react-redux'
import { setClosed, setSearch } from './calendarSearchSlice'

export default function CalendarSearchFooter() {

    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(setSearch(null))
        dispatch(setClosed())
    }

    return (
        <div className="actions">
            <button className='close-drawer btn btn-link btn-link-danger close mr-auto' onClick={handleClose}>
                Close
            </button>
        </div>
    )
}
