import React from 'react'
import { useSelector } from 'react-redux'
import DatePicker from 'react-datepicker'
import moment from 'moment-timezone'
import { selectTimeZone } from '@/features/Locations/locationSlice'
import { momentToLocalDate } from '@/lib/CalendarTimes'
import { preventTyping } from '@/lib/Form'

export function _EditBookingScheduledTime({
    startTime,
    handleStartTimeChange,
    isParent=false,
    isChild=false,
    isQuote=false,
    disabled=false
}) {

    const selectedTimeZone = useSelector(selectTimeZone)

    return (
        <div className="form-group">
            <label>Scheduled Time</label>

            <DatePicker
                selected={momentToLocalDate(moment(startTime).tz(selectedTimeZone))}
                value={momentToLocalDate(moment(startTime).tz(selectedTimeZone))}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
                onChange={handleStartTimeChange}
                onKeyDown={preventTyping}
                disabled={disabled || isParent || isChild || isQuote}
            />
        </div>
    )
}

export const EditBookingScheduledTime = React.memo(_EditBookingScheduledTime)
