import React, {useState} from "react";
import Input from "../Form/Input";
import { validationPatterns, validateEquality } from "../../lib/Errors";
import CustomerWaiversBirthday from "./CustomerWaiversBirthday";
import CustomerWaiversAdditionalMinor from "./CustomerWaiversAdditionalMinor";
import {useFormContext} from "react-hook-form";

export default function CustomerWaiversGuardian({
  adult_or_minor,
  customer,
  signedWaiver,
  isReturning,
  waiver,
  errors
}) {
  const formMethods = useFormContext()

  const [additionalMinors, setAdditionalMinors] = useState([])

  const addMinor = () => {
    setAdditionalMinors([...additionalMinors, []])
  }

  const removeMinor = (index) => {
    const updateAdditionalMinors = [...additionalMinors]
    updateAdditionalMinors.splice(index, 1)
    setAdditionalMinors([...updateAdditionalMinors])
  }

  return (
    <div id='minors_waiver'>
      <div className="row">
        <div className="col-lg-6">
          <div className="waiver-item">RELATIONSHIP TO PARENT OR GUARDIAN</div>
          <Input
            name='relationship'
            placeholder='Relationship'
            hideLabel={true}
            value={formMethods.watch("relationship", '')}
            handleChange={(e) => {
                formMethods.setValue("relationship", String(e.target.value).trim())
            }}
            errors={errors}
            validation={{ 'relationship': { required: true }}}
          />
        </div>
      </div>

      {additionalMinors.length > 0 && (
        <div className="mt-3" id="additional_minors">
          <h3>Additional Minors</h3>

          {
            additionalMinors.map((minor, index) => (
              <CustomerWaiversAdditionalMinor
                key={`mw-${index}`}
                adult_or_minor={adult_or_minor}
                errors={errors}
                minor={minor}
                handleDelete={() => removeMinor(index)}
                index={index}
                waiver={waiver}
              />
            ))
          }
        </div>
      )}

      {!isReturning &&
        <div className="add_minor text-center">
          <button name='button' type='button' className='btn btn-outline-primary add_minor' onClick={() => addMinor()}>ADD ANOTHER MINOR</button>
        </div>
      }

      <hr className="my-5"/>
      <h3 className="waiver-item">PARENT OR GUARDIAN'S NAME</h3>
      <div className="row">
        <div className="col-lg-6">
          <Input
            name='guardian_first_name'
            placeholder='First Name'
            hideLabel={true}
            value={formMethods.watch("guardian_first_name", '')}
            handleChange={(e) => {
              formMethods.setValue('guardian_first_name', String(e.target.value).trim())
            }}
            errors={errors}
            validation={{ 'guardian_first_name': { required: true }}} />
        </div>
        <div className="col-lg-6">
          <Input
            name='guardian_last_name'
            placeholder='Last Name'
            hideLabel={true}
            value={formMethods.watch("guardian_last_name", '')}
            handleChange={(e) => {
              formMethods.setValue('guardian_last_name', String(e.target.value).trim())
            }}
            errors={errors}
            validation={{ 'guardian_last_name': { required: true }}} />
        </div>
      </div>
      <div className="row">

        { waiver.phone &&
        <div className="col-lg-6">
          <h3 className="waiver-item">PARENT OR GUARDIAN'S PHONE NUMBER</h3>
          <Input
            name="guardian_phone"
            type="tel"
            mask="999-999-9999"
            maskPlaceholder="___-___-____"
            hideLabel={true}
            value={formMethods.watch("guardian_phone", '')}
            errors={errors}
            placeholder="Phone Number"
            validation={{ "guardian_phone" : {
              required: waiver.phone,
              pattern: validationPatterns.phone
            }}}
          />
        </div>
        }

        <div className="col-lg-6">
          <h3 className="waiver-item">PARENT OR GUARDIAN'S BIRTHDAY</h3>
          <CustomerWaiversBirthday
            additional_minor={false}
            field='guardian_dob'
            errors={errors}
            isReturning={isReturning}
            customer={customer}
            signedWaiver={signedWaiver}
          />
        </div>
      </div>
      <h3 className="waiver-item">PARENT OR GUARDIAN'S EMAIL ADDRESS</h3>
      <div className="row mb-5">
        <div className="col-lg-6">
          <Input
            name='guardian_email'
            type='email'
            hideLabel={true}
            value={formMethods.watch("guardian_email", '')}
            handleChange={(e) => {
              formMethods.setValue('guardian_email', String(e.target.value).trim())
            }}
            errors={errors}
            placeholder="Email Address"
            validation={{ 'guardian_email': {
              required: true,
              pattern: validationPatterns.email
            }}}
          />
        </div>
        <div className="col-lg-6">
          <Input
            name='guardian_email2'
            type='email'
            hideLabel={true}
            value={formMethods.watch("guardian_email2", '')}
            handleChange={(e) => {
              formMethods.setValue('guardian_email2', String(e.target.value).trim())
            }}
            errors={errors}
            placeholder="Confirm Email Address"
            validation={{ 'guardian_email2': {
              required: true,
              pattern: validationPatterns.email,
              validate: () => validateEquality(formMethods.getValues("guardian_email"), formMethods.getValues("guardian_email2")) || 'Email addresses do not match!'
            }}}
          />
        </div>
      </div>
    </div>
  )
}
