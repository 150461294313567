import React, { useState, useEffect } from 'react'
import FormRow from '@/components/Form/FormRow'
import Input from '@/components/Form/Input'
import TextArea from '@/components/Form/TextArea'
import Checkbox from '@/components/Form/Checkbox'
import RichTextEditor from '@/components/Form/RichTextEditor'
import Select from '@/components/Form/Select'
import titleize from '@/lib/String'

const formattedOptions = values => {
  return Object.keys(values).map(value => {
    return { value: value, display: titleize(value) }
  })
}

export default function WaiverSetupTab({
  form,
  errors,
  statuses = [],
  locations = [],
  handleUpdate,
  handleManualUpdate,
  handleCheckboxUpdate,
}) {

  const [resourceTypes, setResourceTypes] = useState([])

  useEffect(() => {
    // populate resource type options when location is selected
    if (form.location_id === "" || form.location_id === null) {
        setResourceTypes([])
        handleManualUpdate("resource_type_id", "")
    } else {
        const location = locations.find(location => location.id === parseInt(form.location_id))

        setResourceTypes(location.resource_types)
    }
  }, [form.location_id])

  return (
    <div className='pt-0'>
      <FormRow>
        <Input
          cols="6"
          value={form.name}
          errors={errors}
          validation={{ name: { required: true } }}
          handleChange={handleUpdate}
        />

        <Select
          cols="6"
          options={formattedOptions(statuses)}
          validation={{ status: { required: 'You must select a status.' } }}
          value={form.status}
          errors={errors}
          handleChange={handleUpdate}
        />
      </FormRow>

      <FormRow>
        <Select
          cols="6"
          options={locations.map(location => ({
            value: location.id,
            display: location.name,
          }))}
          validation={{ location_id: { required: false } }}
          value={form.location_id}
          label="Location"
          req={false}
          includeBlank="All Locations"
          errors={errors}
          handleChange={handleUpdate}
        />

        {form.location_id && resourceTypes && (
          <Select
            cols="6"
            options={resourceTypes.map(rt => ({
              value: rt[0],
              display: rt[1],
            }))}
            validation={{ resource_type_id: { required: false } }}
            value={form.resource_type_id}
            label="Resource Type"
            req={false}
            includeBlank="All Resource Type"
            errors={errors}
            handleChange={handleUpdate}
          />
        )}
      </FormRow>

      <FormRow>
        <Checkbox
          cols={4}
          className="mt-4 pt-1 pl-3"
          name='always_force_new_signature'
          value={form.always_force_new_signature}
          handleChange={handleCheckboxUpdate}
        />
      </FormRow>

      <h4>Miscellaneous</h4>

      <FormRow>
        <Input
          cols="12"
          value={form.reservation_search_prompt}
          errors={errors}
          validation={{ reservation_search_prompt: { required: true } }}
          handleChange={handleUpdate}
        />
      </FormRow>

      <FormRow>
        <TextArea
          cols="12"
          row="4"
          value={form.reservation_search_help_text}
          errors={errors}
          validation={{ reservation_search_help_text: { required: true } }}
          handleChange={handleUpdate}
        />
      </FormRow>

      <FormRow>
        <TextArea
          cols="12"
          row="4"
          value={form.opt_in_message}
          label="Email Marketing Opt In Message"
          errors={errors}
          validation={{ opt_in_message: { required: true } }}
          handleChange={handleUpdate}
        />
      </FormRow>

      <FormRow>
        <TextArea
          cols="12"
          row="2"
          value={form.next_scheduled_events_empty_state}
          errors={errors}
          validation={{ next_scheduled_events_empty_state: { required: true } }}
          handleChange={handleUpdate}
        />
      </FormRow>

      <h4>Disclaimer Text</h4>

      <FormRow>
        <TextArea
          cols="12"
          row="4"
          value={form.disclaimer_message}
          errors={errors}
          validation={{ disclaimer_message: { required: true } }}
          handleChange={handleUpdate}
        />
      </FormRow>

      <FormRow>
        <TextArea
          cols="12"
          row="4"
          value={form.disclaimer_message_part_two}
          errors={errors}
          validation={{ disclaimer_message_part_two: { required: true } }}
          handleChange={handleUpdate}
        />
      </FormRow>

      <h4>Waiver Text</h4>

      <FormRow>
        <Input
          cols="12"
          value={form.waiver_header}
          errors={errors}
          validation={{ waiver_header: { required: true } }}
          handleChange={handleUpdate}
        />
      </FormRow>

      <FormRow>
        <Input
          cols="12"
          value={form.waiver_completed_header}
          errors={errors}
          validation={{ waiver_completed_header: { required: true } }}
          handleChange={handleUpdate}
        />
      </FormRow>

      <FormRow>
        <RichTextEditor
          cols="12"
          name="waiver_body"
          value={form.waiver_body}
          errors={errors}
          validation={{ waiver_body: { required: true } }}
          handleChange={handleManualUpdate}
        />
      </FormRow>
    </div>
  )
}
