import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectAmountType, setAmountType } from './bookingPaymentSlice'
import { selectBooking } from '../EditBooking/editBookingSlice'
import { selectPackage } from '../Packages/packageSlice'

export default function PaymentAmountType() {

    const dispatch = useDispatch()

    const amountType = useSelector(selectAmountType)
    const booking    = useSelector(selectBooking)
    const pkg        = useSelector(selectPackage)

    const participants = booking.participants
    const group        = pkg.groups.filter(p => participants >= Number.parseInt(p.min) && participants <= Number.parseInt(p.max))[0]

    const handleChange = (e) => {
        dispatch(setAmountType(e.target.value))
    }

    const hasIncompatibleDiscount = useMemo(() => (
        booking?.booking_discounts?.[0]?.discount_record?.['amount_type'] === 'dollar'
        && pkg.pricing_type === 'pricing_by_customer_type'
    ), [booking, pkg])

    // We could also do this by checking the Booking.member_ids but with the current data,
    // this is faster
    const hasMembers = useMemo(() => (
        booking?.booking_discounts?.[0]?.['promo_code'] === 'Membership discount'
    ), [booking, pkg])

    return (!amountType || !booking || !pkg || !participants || !group) ? '' : (
        <div className="modal-body-main-section py-2">
            <div className="form-row nowrap">
                <ul className="radio-tabs">

                    <li className='w-100'>
                        <div className="radio-tab">
                            <input
                                type="radio"
                                id="remainingBalance"
                                name="amountType"
                                value="remaining"
                                checked={amountType === 'remaining'}
                                className="radio-tab-input"
                                onChange={handleChange}
                            />

                            <label className="radio-tab-label w-100 text-center" htmlFor="remainingBalance">
                                Remaining Balance
                            </label>
                        </div>
                    </li>

                    <li className='w-100'>
                        <div className="radio-tab">
                            <input
                                type="radio"
                                id="setAmount"
                                name="amountType"
                                value="custom"
                                checked={amountType === 'custom'}
                                className="radio-tab-input"
                                onChange={handleChange}
                            />

                            <label className="radio-tab-label w-100 text-center" htmlFor="setAmount">
                                Custom Amount
                            </label>
                        </div>
                    </li>

                    {
                        !hasIncompatibleDiscount && group.price_type !== 'group_price' && pkg.pricing_type === 'pricing_by_customer_type' && !hasMembers && (
                            <li className='w-100'>
                                <div className="radio-tab">
                                    <input
                                        type="radio"
                                        id="byParticipant"
                                        name="amountType"
                                        value="participant"
                                        checked={amountType === 'participant'}
                                        className="radio-tab-input"
                                        onChange={handleChange}
                                    />

                                    <label className="radio-tab-label w-100 text-center" htmlFor="byParticipant">
                                        Per Person
                                    </label>
                                </div>
                            </li>
                        )
                    }

                    {
                        group.price_type !== 'group_price' && pkg.pricing_type === 'uniform_pricing' && (
                            <li className='w-100'>
                                <div className="radio-tab">
                                    <input
                                        type="radio"
                                        id="byParticipantUniform"
                                        name="amountType"
                                        value="participant_uniform"
                                        checked={amountType === 'participant_uniform'}
                                        className="radio-tab-input"
                                        onChange={handleChange}
                                    />

                                    <label className="radio-tab-label w-100 text-center" htmlFor="byParticipantUniform">
                                        Per Person
                                    </label>
                                </div>
                            </li>
                        )
                    }
                </ul>
            </div>
        </div>
    )
}
