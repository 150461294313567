import React, { useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import VerifyEmail from './VerifyEmail'
import MemberInformation from './MemberInformation'
import MembershipInformation from './MembershipInformation'
import MembershipPayment from './MembershipPayment'
import { setLocation } from '@/features/Locations/locationSlice'
import {
  selectSelectedTerm,
  setLocationAndCompany,
  setFormErrors,
  submitMember,
} from '@/features/Members/memberSlice'
import { errorsFor } from '@/components/Form/ErrorsHelper'

export default function MembershipForm({
  membershipTypeOptions,
  location,
}) {
  const dispatch = useDispatch()
  const formMethods = useForm()
  const [step, setStep] = useState('verify_email')
  const term = useSelector(selectSelectedTerm)

  const onError = (formErrors) => {
    dispatch(setFormErrors(errorsFor(formErrors)))
  }

  useEffect(() => {
    dispatch(setLocation(location))
    dispatch(setLocationAndCompany({ locationId: location.id, companyId: location.company_id  }))
  }, [])

  const handleSubmit = () => dispatch(submitMember(true))

  return <>
    {step === 'verify_email' &&
      <VerifyEmail setStep={setStep} />
    }
    {step === 'member_information' &&
      <FormProvider {...formMethods} >
        <form onSubmit={formMethods.handleSubmit(handleSubmit, onError)}>
          <div className='row'>
            <div className='col-12 col-xl-6'>
              <div className='card'>
                <MemberInformation />
                <MembershipInformation membershipTypeOptions={membershipTypeOptions} />
              </div>
            </div>
            {term &&
              <div className='col-12 col-xl-6'>
                <div className='card'>
                  <MembershipPayment />
                </div>
              </div>
            }
          </div>
        </form>
      </FormProvider>
    }
  </>
}
