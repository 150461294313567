import React from "react";
import FormRow from "../Form/FormRow";
import Input from "../Form/Input";

export default function MemberForm({
    handleUpdate,
    form,
    errors,
}) {

    return (
        <div>
            <h4>Change Member Password</h4>
            <FormRow>
                <Input
                    cols="6"
                    value={form.password}
                    errors={errors}
                    validation={{ password: { required: false } }}
                    handleChange={handleUpdate}
                />

                <Input
                    cols="6"
                    value={form.password_confirmation}
                    errors={errors}
                    validation={{ password_confirmation: { required: false } }}
                    handleChange={handleUpdate}
                />
            </FormRow>
        </div>
    );
}
