import React from 'react'
import { useSelector } from 'react-redux'
import { selectDragStartColumnClass, selectCurrentDragOutlineClasses } from '@/features/Calendar/calendarSlice'

export default function DynamicStyles() {

    const dragStartColumnClass      = useSelector(selectDragStartColumnClass)
    const currentDragOutlineClasses = useSelector(selectCurrentDragOutlineClasses)

    const colStyles  = 'background: hotpink !important; opacity: 0.3;'
    const dragStyles = 'background: hotpink !important; opacity: 0.7;'

    return (
      <style type="text/css">
        { !!dragStartColumnClass &&
            `${dragStartColumnClass} { ${colStyles} }`
        }

        { !!currentDragOutlineClasses &&
            `${currentDragOutlineClasses} { ${dragStyles} }`
        }
      </style>
    )
}
