import React from 'react'
import Menus from '@/features/AdvancedPointOfSale/Menus'
import MenuItem from '@/features/AdvancedPointOfSale/MenuItem'
import Sidebar from '@/features/AdvancedPointOfSale/components/sidebars/Order'

export default function MenusContainer(props) {
    return (
        <div id='advanced-pos-terminal--menus-container'>
            {
                /^Menus$/i.test(props.content) && (
                    <Menus {...props} onChange={props.onChange} />
                )
            }

            {
                /^MenuItem$/i.test(props.content) && (
                    <MenuItem {...props} onChange={props.onChange} />
                )
            }

            <Sidebar />
        </div>
    )
}
