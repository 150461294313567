import React, { useEffect, useRef } from 'react'
import { Provider } from 'react-redux'
import axios from 'axios'
import bookingStore from '../../stores/bookingStore'
import BookingModalBody from '../../features/Bookings/components/BookingModalBody'
import ConfirmationDialog from '@/features/Notifications/ConfirmationDialog'
import NotificationList from '@/features/Notifications/NotificationList'

// Necessary to force Axios requests to send "as XHR"
axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'

/**
 * All this is really responsible for is returning returning the booking modal body
 * content in the context of a Redux store provider. Without having this wrapped
 * it was blowing up saying dispatch has to be inside of Provider... meaning
 * anything that uses the store has to be rendered from the Provider first.
 */
export default function BookingModal({
  packageUrl,
  locationUrl,
  resourceTypeUrl,
  availableDatesUrl,
  availableTimesUrl,
  holdBookingUrl,
  trackAbandonedUrl,
  finalizeBookingUrl,
  customerTypesUrl,
  referralTypes,
  occasionTypes,
  modalStyles,
  step0 = false,
  packageGroup,
  packages,
  preloadedData,
  environment,
  holdTimeDurationMinutes,
}) {
    const modalContainerRef = useRef()
    const modalInnerBody    = useRef()
    const paddingPx         = 20;

    const setModalHeight = () => {
        if (!modalContainerRef.current || !modalInnerBody.current) { return }

        // we need to have a tiny delay between the resize event
        // triggering and the styles being set so that the browser
        // has time to calculate window.innerHeight before we use it
        window.setTimeout(() => {
            // set the .booking-modal-container's height
            modalContainerRef.current.setAttribute('style', `height: ${window.innerHeight}px; padding:${paddingPx}px;`)

            // set the #booking-modal-body.xbm-inner's height MINUS the padding
            modalInnerBody.current.setAttribute('style', `height: ${window.innerHeight - (paddingPx * 2)}px`)
        }, 100)
    }

    useEffect(() => {
        modalInnerBody.current = document.getElementById('booking-modal-body')
        setModalHeight()
        window.addEventListener('resize', setModalHeight, false)

        return () => {
            window.removeEventListener('resize', setModalHeight, false)
        }
    }, [])

    return (
        <Provider store={bookingStore}>
            <div ref={modalContainerRef} className="booking-modal-container">
                <BookingModalBody
                    packageUrl={packageUrl}
                    locationUrl={locationUrl}
                    resourceTypeUrl={resourceTypeUrl}
                    availableDatesUrl={availableDatesUrl}
                    availableTimesUrl={availableTimesUrl}
                    holdBookingUrl={holdBookingUrl}
                    trackAbandonedUrl={trackAbandonedUrl}
                    finalizeBookingUrl={finalizeBookingUrl}
                    customerTypesUrl={customerTypesUrl}
                    referralTypes={referralTypes}
                    occasionTypes={occasionTypes}
                    modalStyles={modalStyles}
                    step0={step0}
                    packageGroup={packageGroup}
                    packages={packages}
                    preloadedData={preloadedData}
                    environment={environment}
                    holdTimeDurationMinutes={holdTimeDurationMinutes}
                />
            </div>

            <ConfirmationDialog />
            <NotificationList />
        </Provider>
    )
}
