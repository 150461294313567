/*
 * Based on
 * https://stackoverflow.com/questions/60738424/javascript-generate-random-hexadecimal
*/
export function SecureHash(length=20) {
    return [
        ...window.crypto.getRandomValues(new Uint32Array(64)),
        ...[Date.now()],
    ]
    .map((m) => `0${m.toString(16).slice(-2)}`)
    .join('')
    .slice(0, length)
}
