import React, { useEffect } from 'react'
import moment from 'moment'
import { useFormContext } from 'react-hook-form'
import FormRow from '@/components/Form/FormRow'
import PackageDaySelect from '@/components/Packages/PackageDaySelect'
import MomentTimePicker from '@/components/Form/MomentTimePicker'
import { preventTyping } from '@/lib/Form'

import {
    name,
    everyDay,
    handleAdd,
    handleRemove,
    handleTimeChange,
    handleDayChange,
} from '@/components/Packages/mixins/packageTimes'

export default function SpecificStartTimes({
    form,
    errors,
    times=[],
    daysClosed,
    timezone,
    handleManualUpdate
}) {
    const { register, unregister } = useFormContext()

    const field = register(name, {
        validate: {
            atLeastOneTime: () => {
                return times.length >= 1 || 'Must have at least one specific start time.'
            },
            validTimes: () => {
                let emptyTimes = times.filter((t) => !t.time)
                return emptyTimes.length === 0 || 'All start times must be filled out.'
            },
        }
    })

    useEffect(() => {
        return () => {
            // manually unregister the input when this component unmounts because
            // for some dumb reason "shouldUnregister: true" is not behaving as expected
            unregister(name)
        }
    }, [])

    return (
        <div className="specific-start-times specific-time-increments">
            <h4>Specific Start Times</h4>

            <button
                type='button'
                className="drawer-side-action btn btn-link"
                onClick={() => handleAdd(times, daysClosed, handleManualUpdate)}
            >
                + Add Time
            </button>

            {
                times.map((object, index) => (
                    <div className="drawer-add-row py-2" key={`times_${index}`}>
                        <FormRow>
                            <div className="col">
                                <div className="form-group pt-2 pb-0 mb-n2">
                                    <MomentTimePicker
                                        hideLabel={true}
                                        placeholder='XX:00 AM'
                                        value={object?.time ? moment(object.time, 'LT') : ''}
                                        tz={timezone}
                                        preventTyping={preventTyping}
                                        handleUpdate={(e) => handleTimeChange(
                                            form,
                                            times,
                                            e.target.value,
                                            e.target.value,
                                            timezone,
                                            index,
                                            handleManualUpdate
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="col-2 text-right">
                                <button
                                    type='button'
                                    className='btn btn-danger text-white'
                                    onClick={() => handleRemove(times, index, handleManualUpdate)}
                                >
                                    Remove
                                </button>
                            </div>
                        </FormRow>

                        <div className="col-12 col-md-10 col-lg-9 pl-4 py-2">
                            <PackageDaySelect
                                name={`specific_start_time_weekday_${index}`}
                                values={object?.days || everyDay.filter((d,i) => daysClosed[i] === false)}
                                errors={errors}
                                compact={true}
                                daysClosed={daysClosed}
                                overridingPublicStoreHours={form.override_public_store_hours}
                                handleUpdate={(name, value) => handleDayChange(times, name, value, handleManualUpdate)}
                            />
                        </div>
                    </div>
                ))
            }

            {
                errors[field.name] && (
                    <div className="invalid-feedback d-block pt-2 pl-3" style={{ fontSize: '0.8rem' }}>
                        { errors[field.name].join(', ') }
                    </div>
                )
            }
        </div>
    )
}
