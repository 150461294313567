import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCreditToken, setCreditToken, setZip, selectZipCode, submitPurchase } from './productSlice'
import CreditCard from '../Bookings/components/Step6/CreditCard'
import AdyenCreditCard from '../Adyen/AdyenCreditCard'

export default function ProductCreditCard({
    purchasePath,
    name,
    email,
    managerCode,
    locationId,
    locationTimeZone,
    locationPaymentProcessor,
    environment,
    adyenCheckout,
    setAdyenCheckout
}) {

    const dispatch    = useDispatch()
    const creditToken = useSelector(selectCreditToken)
    const zipcode     = useSelector(selectZipCode)

    const handleSubmit = () => {
        dispatch(submitPurchase(purchasePath, name, email, null, null, null, managerCode))
    }

    return (
        <div>
            <div className="form-row">
                <div className="col-12">
                    {
                        locationPaymentProcessor === 'paysafe' && (
                            <CreditCard
                                creditToken={creditToken}
                                setToken={(token) => dispatch(setCreditToken(token))}
                                setZip={(e) => dispatch(setZip(e.target.value))}
                                onNext={handleSubmit}
                            />
                        )
                    }

                    {
                        locationPaymentProcessor === 'adyen' && (
                            <AdyenCreditCard
                                locationId={locationId}
                                locationTimeZone={locationTimeZone}
                                environment={environment}
                                adyenCheckout={adyenCheckout}
                                zipcode={zipcode}
                                setAdyenCheckout={setAdyenCheckout}
                                setZipCode={(e) => dispatch(setZip(e.target.value))}
                                onNext={handleSubmit}
                                onSubmit={handleSubmit}
                            />
                        )
                    }
                </div>
            </div>
        </div>
    )
}
