import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useFormContext, Controller } from 'react-hook-form'
import InputWrapper from './InputWrapper'
import RDate from 'react-datepicker'
import moment from 'moment'
import errorClass from '../../lib/Errors'
import { preventTyping } from '@/lib/Form'
import { toPlainDate } from '../../lib/CalendarTimes'

/**
 * Wrapper around a DatePicker component.
 *
 * When we go to set the 'selected' date, we have to parse it with moment in order to get the date
 * to render properly. For whatever reason the date 2021-04-06 from Rails will render
 * April 5, 2021. When we parse it with moment.format('l') it wil convert that date to 04/06/2021
 * which JS new Date() will parse correctly and not set us back a day. If there is an easier
 * way of doing this we can remove/change how that parsing works.
 *
 * https://github.com/Hacker0x01/react-datepicker
 *
 * @param name input name
 * @param value input value (date to select by default)
 * @param handleChange onChange handler
 * @param req is the input required (mainly for label styles)
 * @param cols how many columns should this input take up?
 * @param errors the array of errors (for bootstrap input error styles)
 */
export default function DatePicker({
  name='',
  placeholder='',
  value='',
  selected,
  minDate=null,
  tooltip='',
  req=null,
  cols,
  label,
  hideLabel,
  errors=[],
  validation=null,
  handleChange=() => {},
  showMonthDropdown=false,
  showYearDropdown=false,
  yearDropdownItemNumber=100,
  dropdownMode='select',
  disabled=false,
  clearable=false,
  ...props
}) {
    const formMethods = useFormContext()

    const separator = ':'

    const _name = validation
        ? Object.keys(validation)[0]
        : name + separator + uuidv4()

    /**
     * Example of an expected validation
     * schema object to register
     *
     * {
     *   'name': {
     *     required: 'A name is required',
     *   }
     * }
     */

    const handleUpdate = (field, date) => {
        let dateVal = date;

        // update react hook form validation
        if ('onChange' in field) { field.onChange(dateVal) }
        // update our form object
        handleChange(field.name, dateVal)
    }

    return (
        <InputWrapper
            name={_name.split(separator)[0]}
            req={(validation && req === null) || Boolean(req)}
            tooltip={tooltip}
            cols={cols}
            label={label}
            hideLabel={hideLabel}
            errors={errors}
        >
            <div className={errorClass(_name, errors)}>
                { formMethods ? (
                    <Controller
                        name={_name}
                        control={formMethods.control}
                        defaultValue={value}
                        rules={validation ? validation[Object.keys(validation)[0]] : {}}
                        render={({ field }) => (
                            <RDate
                                ref={field.ref}
                                name={_name}
                                className={`form-control ${errorClass(_name, errors)}`}
                                autoComplete="off"
                                showMonthDropdown={showMonthDropdown}
                                showYearDropdown={showYearDropdown}
                                yearDropdownItemNumber={yearDropdownItemNumber}
                                dropdownMode={dropdownMode}
                                minDate={minDate}
                                selected={field.value ? Date.parse(moment(value).format('l')) : ''}
                                onChange={(date) => handleUpdate(field, date)}
                                onKeyDown={preventTyping}
                                disabled={disabled}
                                isClearable={Boolean(field.value) && clearable}
                                placeholderText={placeholder}
                                {...props}
                            />
                        )}
                    />
                ) : (
                    <RDate
                        name={_name}
                        className={`form-control ${errorClass(_name, errors)}`}
                        autoComplete="off"
                        showMonthDropdown={showMonthDropdown}
                        showYearDropdown={showYearDropdown}
                        yearDropdownItemNumber={yearDropdownItemNumber}
                        dropdownMode={dropdownMode}
                        minDate={minDate}
                        selected={value ? Date.parse(moment(value).format('l')) : ''}
                        onChange={(date) => handleUpdate({ name: _name }, date)}
                        onKeyDown={preventTyping}
                        disabled={disabled}
                        isClearable={clearable}
                        placeholderText={placeholder}
                        {...props}
                    />
                )}
            </div>
        </InputWrapper>
    )
}
