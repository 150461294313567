import React from 'react'
import Input from '../Form/Input'

export default function ConfirmationChecksFields({
    confirmationChecks=[],
    disabledIfMembers,
    handleUpdate
}) {

    const handleChange = (index, text) => {
        handleUpdate('confirmation_checks', confirmationChecks.map((e, i) => {
            if (index === i) {
                return { ...e, ['text']: text }
            } else {
                return e
            }
        }))
    }

    const handleRemove = index => {
        handleUpdate('confirmation_checks', confirmationChecks.filter((_e, i) => i !== index))
    }

    return confirmationChecks.map((e, index) => {
        return (
            <div className="drawer-add-row border-0" key={index}>
                <div className="input-group mb-3">
                    <Input
                        key={index}
                        cols="pl-0"
                        name={`confirmation_checks[${index}]`}
                        value={e.text}
                        placeholder='Please confirm that you will...'
                        className='form-control'
                        withWrapper={false}
                        hideLabel
                        append
                        handleChange={(e) => handleChange(index, e.currentTarget.value)}
                    >
                        <div className="input-group-append">
                            <button
                                children={<i className="fas fa-trash-alt" />}
                                className="btn btn-danger text-white px-3 py-1 min-h-auto"
                                disabled={disabledIfMembers}
                                onClick={() => handleRemove(index)}
                            />
                        </div>
                    </Input>
                </div>
            </div>
        )
    })
}
