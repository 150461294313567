import React from 'react'
import FormRow from '../Form/FormRow'
import Checkbox from '../Form/Checkbox'

export default function OptionalFields({ form, handleCheckboxUpdate }) {
  return (
    <>
      <h6>Any field toggled “on” will be displayed and required when a customer is signing their waiver.</h6>
      <FormRow className="mt-4">
        <Checkbox
          cols="6"
          name="zip_code"
          handleChange={handleCheckboxUpdate}
          value={form.zip_code}
        />

        <Checkbox
          cols="6"
          name="gender"
          handleChange={handleCheckboxUpdate}
          value={form.gender}
        />

        <Checkbox
          cols="6"
          name="phone"
          handleChange={handleCheckboxUpdate}
          value={form.phone}
        />
      </FormRow>
    </>
  )
}
