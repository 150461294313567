import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import titleize from '../../lib/String'
import FormRow from './FormRow'
import { DirectUpload } from '@rails/activestorage'
import Progress from './Progress'
import Tooltip from "./Tooltip";
import Label from "./Label";
import { addAlert } from '@/features/Notifications/notificationSlice'

export default function File({ name, label, handleChange, directUploadUrl, children, tooltip='' }) {

    const dispatch = useDispatch()

    const [filename, setFilename] = useState(null)

    const [progress, setProgress] = useState({
        loaded: null,
        total:  null,
        active: false
    })

    const handleImageChange = (e) => {
        const targetFile = e.target.files[0]

        const upload = new DirectUpload(targetFile, directUploadUrl, {
            directUploadWillStoreFileWithXHR(request) {
                request.upload.addEventListener('progress', event => {
                    setProgress({
                        ...progress,
                        active: event.loaded < event.total,
                        loaded: event.loaded,
                        total:  event.total
                    })

                    // only set the filename after the whole file has been uploaded... this is a placeholder
                    // to basically set the 'completed & ready' state...
                    if (event.loaded >= event.total) {
                        setFilename(targetFile.name)
                    }
                })
            }
        })

        upload.create((error, blob) => {
            if (error) {
                dispatch(addAlert({ type: 'error', text: 'Unable to upload the file.' }))
            } else {
                /**
                 * @TODO i think here we can actually send up the blob that comes back to get a URL for it if we want...
                 * that should allow us to get image previews on upload...
                 */
                handleChange(name, blob.signed_id)
            }
        })
    }

    return (
        <FormRow>
            <div className="col-6">
                <div className="form-group">
                    <Label name={label || name}>
                        { tooltip !== '' &&
                            <Tooltip place='right'>{tooltip}</Tooltip>
                        }
                    </Label>

                    <div className="custom-file">

                        <input
                            onChange={handleImageChange}
                            name={name}
                            type="file"
                            className="custom-file-input"
                            id={`file-${name}`} />

                        <label className="custom-file-label" htmlFor={`file-${name}`}>Choose file</label>

                    </div>

                    {
                        progress.active && (
                            <Progress {...progress} />
                        )
                    }

                    {
                        !progress.active && filename && (
                            <div className="file-name">
                                <small><i>{filename}</i></small>
                            </div>
                        )
                    }

                </div>
            </div>

            <div className="col-6">
                {children}
            </div>
        </FormRow>
    )
}
