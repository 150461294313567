import { createSlice } from '@reduxjs/toolkit'
import { setCreditToken, submitPurchase } from '../Products/productSlice'
import { SELECTED_TERMINAL, SELECTED_LOCATION } from '../../lib/Storage'
import { addAlert } from '@/features/Notifications/notificationSlice'

export const terminalSlice = createSlice({
    name: 'terminal',
    initialState: {
        devices:           [],
        selected:          null,
        connected:         false,
        processing:        false,
        connectedDevice:   null,
        partialAlert:      false,
        partialAttempted:  0,
        partialAuthorized: 0
    },
    reducers: {
        setDevices: (state, action) => {
            state.devices = action.payload
        },
        setSelected: (state, action) => {
          state.selected = action.payload

          const location_id = window.localStorage.getItem(SELECTED_LOCATION)

          if (action.payload !== null && location_id !== null) {
              // when a terminal is selected we also want to set the terminal id/device id combination
              // stored in local storage this should also auto-select the slice when present
              window.localStorage.setItem(SELECTED_TERMINAL, `${action.payload}|${location_id}`)
          } else {
              window.localStorage.removeItem(SELECTED_TERMINAL)
          }
        },
        setConnected: (state, action) => {
            state.connected = action.payload

            // also set which device we're connected to now
            state.connectedDevice = action.payload ? state.selected : null
        },
        setProcessing: (state, action) => {
            state.processing = action.payload
        },
        setPartialAlert: (state, action) => {
            state.partialAlert = action.payload
        },
        setPartialAmounts: (state, action) => {
            state.partialAttempted  = action.payload.attempted
            state.partialAuthorized = action.payload.authorized
            state.partialAlert      = action.payload.alert
        },
        clearAmounts: (state, action) => {
            state.partialAttempted  = 0
            state.partialAuthorized = 0
            state.partialAlert      = false
        }
    }
})

export const {
    setDevices,
    setSelected,
    setConnected,
    setProcessing,
    setPartialAlert,
    setPartialAmounts,
    clearAmounts
} = terminalSlice.actions

export const selectDevices           = state => state.terminal.devices
export const selectSelected          = state => state.terminal.selected
export const selectConnected         = state => state.terminal.connected
export const selectProcessing        = state => state.terminal.processing
export const selectConnectedDevice   = state => state.terminal.connectedDevice
export const selectPartialAlert      = state => state.terminal.partialAlert
export const selectPartialAttempted  = state => state.terminal.partialAttempted
export const selectPartialAuthorized = state => state.terminal.partialAuthorized

export function fetchDevices(hardwareKey, transactionCallback) {
    return async (dispatch, getState) => {
        const instance = new Handpoint()

        window.hpInstance = instance

        instance.init(hardwareKey, false, (p) => {
            // this recovered transaction actually fired once with matt's device, i'm not sure what we're supposed
            // to be doing here when transactions are "recovered" or what that even means... i'm assuming it's not
            // something we will need to support though?
            console.log('Recovered transaction -> ', p)

            /**
             * @TODO here i think we have to check the payment status or attributes
             * to figure out exactly what needs to happen -- the only thing we've
             * seen here is when it tries to actually process the transaction...
             * if other statuses/pre transaction are caught here, we would have to do
             * something entirely different, maybe passing in several callbacks/checks...
             */
            if (p.finStatus === 'AUTHORISED') {
                transactionCallback(p)
            } else {
                dispatch(addAlert({ type: 'error', text: payment.statusMessage }))
            }

            return Promise.resolve()
        }).then((devices) => {
            /**
             * @todo right now we can't serialize data in the store here, which could be a problem... how are we supposed to store
             * that data in the store?
             * 2 options would be to:
             *   1) JSON.parse(JSON.serialize(devices))
             *   2) window.handpointdevices = devices
             *   3) disable serialization checks in the store
             *      - trying to do this didn't seem to work -- broke other dispatch calls...
             */
            dispatch(setDevices(JSON.parse(JSON.stringify(devices))))
        }).catch((error) => {
            dispatch(addAlert({ type: 'error', text: 'Could not initialize the terminal.' }))
            console.log('Initialization error: ', error)
        })
    }
}

// NOTE: previously connected terminals automatically disconnect/drop
// their connection whenever a new connection is established
export function connectToDevice(device) {
    return (dispatch, getState) => {
        // const did = device.split('|')[0]
        hpInstance.connect(device).then((connect) => {
            dispatch(addAlert({ type: 'success', text: `<strong>CONNECTION SUCCESSFUL</strong><br />ID: ${device}` }))
            dispatch(setConnected(true))
        }).catch((error) => {
            dispatch(setConnected(false))
            dispatch(addAlert({ type: 'error', text: `<strong>CONNECTION FAILED</strong><br />ID: ${device}` }))
        })
    }
}

export function cancelTransaction() {
    return (dispatch, getState) => {
        hpInstance.stopCurrentTransaction().then((success) => {
            console.log('CANCEL SUCCESS BLOCK: ', success)
            dispatch(addAlert({ type: 'success', text: 'Transaction Cancelled' }))
        }).catch((error) => {
            console.log('CANCEL ERROR BLOCK: ', error)
            dispatch(addAlert({ type: 'error', text: error?.errorMessage }))
        }).finally(() => {
            dispatch(setProcessing(false))
            return Promise.resolve()
        })
    }
}

export function submitTransaction(amountCents, paymentCallback) {
    return (dispatch, getState) => {
        dispatch(setProcessing(true))

        // hpInstance.saleAndTokenization(amountCents, 'USD', null, (status) => {
        hpInstance.tokenizeCard({}).then(payment => {
            console.log('Tokenized card response: ', payment)

            /**
             * --------------------------------------------------------------------------------
             *                           Possible finStatus values:
             *
             * UNDEFINED, AUTHORISED, DECLINED, PROCESSED, FAILED, CANCELLED, PARTIAL_APPROVAL
             * --------------------------------------------------------------------------------
             */
            if (payment.finStatus === 'AUTHORISED') {
                paymentCallback(payment)
            }

            // else if (payment.finStatus === 'PARTIAL_APPROVAL') {
            //     // @TODO: if we are only tokenizing now, we should never run into the bottom scenario...

            //     const amountCharged = payment.totalAmount

            //     // if we have the amount charged, i dont think we care about anything else -- the payment response
            //     // has the full information we can always look at in the record if we need to when looking at a payment...
            //     // the amount charged & partial flag would be enough to quickly check in both instead of opening up
            //     // the payment response and parsing it every time...

            //     paymentCallback(payment, (amountCharged / 100))

            //     // now i think all we have to do is dispatch the terminal partial alert information
            //     dispatch(setPartialAmounts({
            //         attempted:  Number.parseInt(amountCents),
            //         authorized: Number.parseInt(amountCharged),
            //         alert:      true
            //     }))
            // }
            else {
                // NOTE Paysafe mentioned adding a check for cancelled and type is TOKENIZE_CARD e.g.
                if (payment.finStatus === 'CANCELLED' && payment.type === 'TOKENIZE_CARD') {
                    dispatch(addAlert({ type: 'error', text: 'Payment has been cancelled.' }))
                    return Promise.resolve()
                }
                dispatch(addAlert({ type: 'error', text: payment.statusMessage }))
            }

            // if (payment.finStatus !== 'AUTHORISED') {
            //     dispatch(addAlert({ type: 'error', text: payment.statusMessage }))
            //     return
            // }

        }).catch((error) => {
            dispatch(addAlert({ type: 'error', text: 'Could not complete the transaction.' }))
            console.log('Payment failed: ', error)
        }).finally(() => {
            dispatch(setProcessing(false))
        })

        // hpInstance.tokenizeCard({}).then((payment) => {

        //     console.log('Payment response: ', payment)

        //     if (payment.finStatus !== 'AUTHORISED') {
        //         dispatch(addAlert({ type: 'error', text: payment.statusMessage }))
        //     }

        //     hpInstance.disconnect(getState().terminal.selected)

        //     const token = payment.cardToken

        //     tokenCallback(token)

        // }).catch((error) => {
        //     dispatch(addAlert({ type: 'error', text: 'Could not complete the transaction.' }))
        //     console.log('Payment failed: ', error)
        // }).finally(() => {
        //     dispatch(setProcessing(false))
        // })
    }
}

export default terminalSlice.reducer
