import React, { useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useFormContext } from 'react-hook-form'
import Tooltip from '@/components/Form/Tooltip'
import titleize from '@/lib/String'
import errorClass from '@/lib/Errors'

export default function ToggleResources({
  resources,
  inputName,
  inputValue,
  singularName,
  hideHeader,
  newPackage,
  errors=[],
  loaded,
  twoColumn=false,
  handleChange
}) {
    const { register } = useFormContext()

    const field = register(inputName, {
        validate: () => inputValue.length >= 1 || 'At least one resource must be checked.'
    })

    useEffect(() => {
        // pre-select all bookable resources for a brand new package
        if (!loaded && newPackage) { selectAll() }

        // pre-select all resources on the package that are still bookable
        if (!loaded && !newPackage) {
            handleChange(inputName,
                resources.filter((r) => inputValue.includes(parseInt(r.id, 10)))
                         .filter((r) => r.active && !r.non_bookable)
                         .map((r) => r.id)
            )
        }
    }, [])

    /**
     * Handle what happens when an individual checkbox is toggled.
     */
    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            // add value
            if (inputValue.indexOf(parseInt(e.target.value, 10)) === -1) {
                handleChange(inputName, inputValue.concat(parseInt(e.target.value, 10)))
            }
        } else {
            // remove value
            handleChange(inputName, inputValue.filter(v => v !== parseInt(e.target.value, 10)))
        }
    }

    /**
     * Handle what happens when the user clicks the Select All checkbox. This also deselects.
     */
    const handleSelectAll = (e) => {
        if (e.target.checked) {
            selectAll()
        } else {
            // remove all
            handleChange(inputName, [])
        }
    }

    const selectAll = () => {
        handleChange(inputName, resources.filter((r) => r.active && !r.non_bookable).map((r) => r.id))
    }

    // the actual resources that will be rendered on the page
    const resource_checkboxes = []

    // A counter of the active resources
    let activeCounter = 0

    // loop over the number of resources, building out the input
    resources.forEach((resource, index) => {
        const isDisabled = resource.non_bookable || resource.max_capacity <= 0

        // Increment the counter of activeResources for the select all checkbox
        if (!isDisabled) { activeCounter = activeCounter + 1 }

        resource_checkboxes.push(
            <div className={`${twoColumn ? 'col-6' : 'col-4'}`} key={uuidv4()}>
                <div className="form-group">
                    <div className="custom-control custom-checkbox">

                        <input
                            id={`${inputName}-toggle-${resource.id}`}
                            className={`custom-control-input ${!isDisabled && errorClass(inputName, errors)}`}
                            name={resource.name}
                            type="checkbox"
                            value={resource.id}
                            checked={resource.active && !resource.non_bookable ? inputValue.includes(parseInt(resource.id, 10)) : false}
                            disabled={isDisabled}
                            onChange={handleCheckboxChange}
                        />

                        <label className="custom-control-label" htmlFor={`${inputName}-toggle-${resource.id}`}>
                            <strong className="pr-2">{singularName} {index + 1}:</strong>
                            { resource.non_adjacent ? <i className="fas fa-arrow-to-left mr-1" title='This resource is configured as "non-adjacent".' /> : null }
                            { resource.name }
                        </label>

                        { isDisabled && resource.active && resource.non_bookable &&
                            <Tooltip id={`tooltip-${resource.name}`} place='left'>
                                This resource is disabled because it's in an active non-bookable state in preparation for future deactivation.
                            </Tooltip>
                        }
                    </div>
                </div>
            </div>
        )
    } )

    return (
        <div className={`resource-toggles ${twoColumn ? 'col-8' : 'col-12'}`}>
            {
                !hideHeader && <h3>{titleize(inputName)}</h3>
            }

            <div className="form-row">
                <div className="col-12">
                    <div className="form-group">
                        <div className="custom-control custom-checkbox">
                            <input
                                id={`${inputName}-select-all`}
                                className="custom-control-input"
                                type="checkbox"
                                checked={inputValue.length === activeCounter}
                                onChange={handleSelectAll}
                            />

                            <label className="custom-control-label text-bold" htmlFor={`${inputName}-select-all`}>
                                Select All
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="form-row">
                {resource_checkboxes}
            </div>

            {
                errors[inputName] && (
                    <div className="invalid-feedback d-block">
                        { errors[inputName].join(', ') }
                    </div>
                )
            }
        </div>
    )
}
