import React from 'react'
import { useSelector } from 'react-redux'
import { selectStep, selectBooking } from '@/features/Bookings/bookingSlice'

export default function LedgerReservationInformation() {

    const step    = useSelector(selectStep)
    const booking = useSelector(selectBooking)

    if (step !== '6') { return null }

    return (
        <div className="xbm-ledger-section">
            <b>{booking.fullName}</b><br/>
            {booking.email}<br/>
            {booking.phone}<br/>
        </div>
    )
}
