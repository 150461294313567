import React, { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useConfirm } from '@/lib/useConfirmHook'

import {
    closeModal,
    configureModal,
    openModal,
    printReceipt,
    selectModals,
    selectCurrentCheck,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import Modal from '@/features/AdvancedPointOfSale/components/Modal'
import Receipt from '@/features/AdvancedPointOfSale/components/ordering/Receipt'
import { acknowledgementText } from '@/features/AdvancedPointOfSale/lib/Printers'
import { inAlteration, isOpen } from '@/features/AdvancedPointOfSale/lib/Checks'
import { numberToCurrency } from '@/lib/Number'

export default function ReceiptModal() {

    const { confirm }        = useConfirm()
    const dispatch           = useDispatch()
    const { receipt: modal } = useSelector(selectModals)
    const check              = useSelector(selectCurrentCheck)

    const shouldAppendContent = () => {
        switch(true) {
            case inAlteration(check) :
            case modal.footer === 'simple' :
                return false

            case modal.footer === 'complex' :
                return true

            default :
                return false
        }
    }

    const tabs = useMemo(() => {
        if (!modal.isOpen) { return [] }

        return (
            !!modal.tabs ? modal.tabs : [modal.tab]
        ).filter((tab) => !!tab && (inAlteration(check) || !isOpen(check) ? true : !tab.is_closed) && tab.items.length > 0)
    }, [modal])

    const handleClose = () => {
        dispatch(closeModal('receipt'))
    }

    const handlePrint = async () => {
        if (modal.forFullCheck) {
            dispatch(closeModal('receipt'))
            dispatch(configureModal({
              modal: 'printOption',
              config: {
                title: 'Print Full Check',
                data: {
                  includeTipLine: true,
                  includeSignatureLine: true,
                }
              }
            }))
            dispatch(openModal('printOption'))
        } else {
            if (!await confirm('<h5 class="mb-0">Print this tab as its own receipt?</h5>', { rawHTML: true, size: 'sm' })) { return }

            dispatch(printReceipt({
                tab: modal.tab,
            }))
            .then((data) => {
                if (data.success && data.with_errors) {
                    const delay = 1800

                    dispatch(configureModal({
                        modal: 'popModal',
                        config: { text: data.message, icon: 'fa-print bg-warning', delay }
                    }))
                    dispatch(openModal('popModal'))

                    window.setTimeout(async () => {
                        if (
                            !await confirm(acknowledgementText(data), {
                                header_text: 'Warning!',
                                confirm_only: true,
                                confirm_text: 'OK',
                                dangerous: true,
                                rawHTML: true,
                                size: 'md'
                            })
                        )  {
                            return
                        }
                    }, delay + 50)
                }
            })
        }
    }

    const handleEmailReceipt = () => {
        dispatch(configureModal({
            modal: 'emailReceipt',
            config: {
                tab: modal.tab,
                afterSuccess: handleClose(),
            }
        }))

        dispatch(openModal('emailReceipt'))
    }

    const content = () => {
        // RECEIPT TABS
        const receiptTabs = tabs.map((tab, index) => (
            <Receipt
                key={`receipt--tab-${tab.id}`}
                check={check}
                tab={tab}
                className={index === 0 ? 'pt-1 px-2' : 'pt-3 px-2'}
                classNameInner='px-2'
            />
        ))

        // CHECK TOTAL OF ALL TABS IF MULTIPLE
        const receiptTotal = tabs.length > 1 && (
            <div key='receipt-total' className='mt-4 px-3'>
                <div className='w-100 border-top border-top-gray4 border-thick mb-1' />
                <div className='w-100 border-top border-top-gray4 border-thick' />

                <table className='mt-4 table-sm w-100 border-none'>
                    <colgroup>
                        <col width='100%' />
                        <col width='auto' />
                    </colgroup>
                    <tfoot>
                        <tr>
                            <td className='text-left border-top-none border-top-gray4 py-1 lead'>
                                <span className='font-weight-bolder pr-3'>TOTAL DUE</span>
                            </td>
                            <td className='text-right border-top-none border-top-gray4 py-1 lead'>
                                <span className='font-weight-bolder'>
                                    { numberToCurrency(check.grand_total_cents / 100) }
                                </span>
                            </td>
                        </tr>
                        {
                            check.grand_total_cents !== check.balance_cents && (
                                <tr>
                                    <td className='text-left border-top border-top-gray4 py-1'>
                                        <span className='font-weight-bold pr-3'>REMAINING BALANCE</span>
                                    </td>
                                    <td className='text-right border-top border-top-gray4 py-1 lead'>
                                        <span className='font-weight-bold'>
                                            { numberToCurrency(check.balance_cents / 100) }
                                        </span>
                                    </td>
                                </tr>
                            )
                        }
                    </tfoot>
                </table>
            </div>
        )

        const children = []

        if (receiptTabs.length > 0) { children.push(receiptTabs) }
        if (!!receiptTotal) { children.push(receiptTotal) }

        return children.length > 0
            ? <div className="receipt-modal m-3">{children}</div>
            : null
    }

    return modal.isOpen && (
        <Modal
            title='View Receipt'
            className='receipt-modal'
            size={modal.size}
            isOpen={modal.isOpen}
            children={content()}
            append={shouldAppendContent && (
                <div className='d-flex align-items-center justify-content-between p-4'>
                    <button
                        children="Email Receipt"
                        className="btn btn-outline-primary mr-2 w-100"
                        onClick={handleEmailReceipt}
                    />
                    <button
                        children="Print"
                        className="btn btn-outline-primary ml-2 w-100"
                        onClick={handlePrint}
                    />
                </div>
            )}
            footerButtons={
                <button
                    children="Cancel"
                    className="btn btn-link text-blue px-5"
                    onClick={handleClose}
                />
            }
            onClose={handleClose}
        />
    )
}
