import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setCustomAmount, selectCustomAmount } from '@/features/BookingPayment/bookingPaymentSlice'
import { numberToCurrency } from '@/lib/Number'

export default function CustomerAmountSelect({ setStep, booking }) {
  const dispatch = useDispatch()

  const customAmount = useSelector(selectCustomAmount)

  const [errorMessage, setErrorMessage] = useState('')
  const [inputCustomAmount, setInputCustomAmount] = useState(0)
  const [inputCustomAmountType, setInputCustomAmountType] = useState("dollar")

  const handleChange = e => {
    if (inputCustomAmountType === "dollar" && parseFloat(e.target.value) > booking.balance_cents / 100) {
      return
    } else if (inputCustomAmountType === "percent" && parseFloat(e.target.value) > 100) {
      return
    } else {
      // Number.parseFloat(e.target.value).toFixed(2) doesn't work here.
      // It kept appending the inputted number in the end of the string.
      setInputCustomAmount(e.target.value ? Number(parseFloat(e.target.value).toFixed(2)).toString() : 0)
    }
  }

  const handleAmountType = e => {
    setInputCustomAmount(0)
    setInputCustomAmountType(e.target.value)
  }

  useEffect(() => {
    let paymentAmount

    if (inputCustomAmountType === "dollar") {
      paymentAmount = inputCustomAmount
    } else {
      paymentAmount = (inputCustomAmount * booking.balance_cents / 100 / 100).toFixed(2)
    }

    dispatch(setCustomAmount(paymentAmount))
  }, [inputCustomAmount, inputCustomAmountType])

  return (
    <div className="col-12 col-md-8">
      <div className="form-group">
        <h3 className="mb-3">Enter the amount you want to pay</h3>

        <div className="row mx-0 justify-content-center">
          <div className="col-12">
            Remaining Balance: {numberToCurrency(booking.balance_cents / 100)}
          </div>
        </div>
        <div className="row mx-0 justify-content-center">
          <div className="col-12">
            Payment Amount: {numberToCurrency(customAmount)}
          </div>
        </div>
        <div className="row mx-0 justify-content-center">
          <div className="col-12">
            Balance after payment: {numberToCurrency(booking.balance_cents / 100 - customAmount)}
          </div>
        </div>

        <div className="d-flex align-items-stretch mt-3">
          <div className="radio-as-button pr-2 d-flex align-stretch">
            <input
              id="customAmountDollar"
              name="customAmountType"
              type="radio"
              value="dollar"
              checked={inputCustomAmountType === 'dollar'}
              className="radio-as-button-input"
              onChange={handleAmountType}
            />

            <label
              htmlFor="customAmountDollar"
              className={`radio-as-button-label d-flex mb-0 px-3 align-items-center ${
                inputCustomAmountType === 'dollar' && 'btn-primary'
              }`}
            ><i className={`far fa-dollar-sign ${inputCustomAmountType === 'dollar' ? 'text-white' : 'text-primary'}`}/>
            </label>
          </div>

          <div className="radio-as-button pr-2 d-flex align-stretch">
            <input
              id="customAmountPercent"
              name="customAmountType"
              type="radio"
              value="percent"
              checked={inputCustomAmountType === 'percent'}
              className="radio-as-button-input"
              onChange={handleAmountType}
            />

            <label
              htmlFor="customAmountPercent"
              className={`radio-as-button-label d-flex mb-0 px-3 align-items-center ${inputCustomAmountType === 'percent' && 'btn-primary'}`}
              ><i className={`far fa-percent ${inputCustomAmountType === 'percent' ? 'text-white' : 'text-primary'}`}/>
            </label>
          </div>

          <input
            className="flex-1 form-control"
            type="number"
            min={0}
            step="0.1"
            placeholder="Enter amount"
            value={inputCustomAmount}
            onChange={handleChange}
          />
        </div>

        <div className="invalid-feedback d-block">{errorMessage}</div>

        <button
          className="btn btn-secondary btn-block mt-4"
          onClick={() => {
            setInputCustomAmountType("dollar")
            setInputCustomAmount(booking.balance_cents / 100)
          }}
        >
          Pay remainder
        </button>

        <button
          className="btn btn-primary btn-block mt-4"
          onClick={() => {
            if (customAmount > 0) {
              setStep('3')
            } else {
              setErrorMessage('Please enter amount.')
            }
          }}
        >
          Continue
        </button>
      </div>
    </div>
  )
}
