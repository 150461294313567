import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setPaymentType, selectPaymentType } from '@/features/GiftCards/giftCardSlice'
import {
    setPaymentType as setMembershipPaymentType,
    selectPaymentType as selectMembershipPaymentType
} from '@/features/Members/memberSlice'
import titleize from '@/lib/String'

export default function GiftCardFormPaymentType({ membershipPayment=false }) {

    const dispatch            = useDispatch()
    const selectedPaymentType = membershipPayment ? useSelector(selectMembershipPaymentType) : useSelector(selectPaymentType)

    const paymentTypes = ['credit', 'terminal', 'check', 'cash', 'skip_payment']

    if (membershipPayment) { paymentTypes.splice(1,1,'gift_card') }

    return (
        <ul className="radio-tabs" style={{ marginBottom: '1rem' }}>
            {
                paymentTypes.map(t => (
                    <li key={t}>
                        <div className="radio-tab">
                            <input
                                id={`type-${t}`}
                                className='radio-tab-input'
                                type='radio'
                                name='payment_type'
                                value={t}
                                checked={selectedPaymentType === t}
                                onChange={() => {
                                    if (membershipPayment) {
                                        dispatch(setMembershipPaymentType(t))
                                    } else {
                                        dispatch(setPaymentType(t))
                                    }
                                }}
                            />

                            <label className='radio-tab-label' htmlFor={`type-${t}`}>
                                { /skip_payment/i.test(t) ? 'Skip Payment' : titleize(t) }
                            </label>
                        </div>
                    </li>
                ))
            }
        </ul>
    )
}
