import React from 'react'
import Input from '@/components/Form/Input'
import FormRow from '@/components/Form/FormRow'
import Checkbox from '@/components/Form/Checkbox'
import Select from '@/components/Form/Select'

export default function PrinterForm({
  form,
  errors,
  printerLocationOptions=[],
  printerTypeOptions=[],
  handleUpdate,
  handleCheckboxUpdate,
}) {
  return (
    <div className="mt-4">
      <FormRow>
        <Input
          cols="6"
          name="name"
          label="Printer Name"
          value={form.name}
          validation={{ name: { required: true } }}
          errors={errors}
          handleChange={handleUpdate}
        />
        <Input
          cols="3"
          name="mac_address"
          label='Printer MAC Address'
          mask="**:**:**:**:**:**"
          placeholder="XX:XX:XX:XX:XX:XX"
          value={form.mac_address}
          validation={{ mac_address: { required: true } }}
          errors={errors}
          handleChange={handleUpdate}
        />

        <Checkbox
          cols="3"
          name="is_enabled"
          label="Is Enabled?"
          value={form.is_enabled}
          className="pt-4 pl-2 mt-1"
          handleChange={handleCheckboxUpdate}
        />
      </FormRow>

      <FormRow>
        <Select
          cols="6"
          label="Printer Location"
          req={/^(chit|multi_purpose)$/i.test(form.printer_type)}
          includeBlank={true}
          options={printerLocationOptions}
          value={form.fulfillment_provider_id}
          validation={{ fulfillment_provider_id: {
            required: /^(chit|multi_purpose)$/i.test(form.printer_type)
          }}}
          errors={errors}
          handleChange={handleUpdate}
        />

        <Select
          cols="6"
          name="printer_type"
          options={printerTypeOptions}
          value={form.printer_type}
          validation={{ printer_type: { required: true } }}
          errors={errors}
          handleChange={handleUpdate}
        />
      </FormRow>

      {
        /^(chit|receipt|multi_purpose)$/i.test(form.printer_type) && (
          <FormRow>
            {
              /^(chit|multi_purpose)$/i.test(form.printer_type) && (
                <Checkbox
                  cols="6"
                  name="is_master"
                  label="Is This A Master Chit Printer?"
                  value={/^(chit|multi_purpose)$/i.test(form.printer_type) && form.is_master}
                  className="pt-4 pl-2 mt-1"
                  handleChange={handleCheckboxUpdate}
                />
              )
            }

            {
              /^(receipt|multi_purpose)$/i.test(form.printer_type) && (
                <Checkbox
                  cols="6"
                  name="has_cash_drawer"
                  label="Has An Attached Cash Drawer?"
                  value={/^(receipt|multi_purpose)$/i.test(form.printer_type) && form.has_cash_drawer}
                  className="pt-4 pl-2 mt-1"
                  handleChange={handleCheckboxUpdate}
                />
              )
            }
          </FormRow>
          )
        }
    </div>
  )
}
