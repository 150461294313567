import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Icon from '@/components/FontAwesomeIcon'
import { outerHeight } from '@/lib/Elements'
import { numberToCurrency } from '@/lib/Number'
import { parameterize } from 'inflected'
import { debug } from '@/lib/Debug'

export default function MultiSelectExternalListSubItems({
    parentId=null,
    iconType='is_default',
    items=[],
    isSortable=false,
    isCollapsed=true,
    isDefaultIconClass=null,
    isLimitReached=false,
    onSort=() => {},
    onIconChange=() => {},
    onVisibilityChange=() => {},
}) {

    const [computedMinHeight, setComputedMinHeight] = useState(null)

    /*
     * Items are either pre-sorted by the sort order array before being passed
     * in to this component as a property or are sorted ALPHA ASC by default
     */
    const sortedItems = isSortable ? items
                                   : items.sort((a,b) => a.name > b.name ? 1 : -1)

    const handleOnDragEnd = (result) => {
        if (!result.destination) { return }

        const updatedItems = [...items]
        const [reorderedItem] = updatedItems.splice(result.source.index, 1)
        updatedItems.splice(result.destination.index, 0, reorderedItem)

        onSort(parentId, updatedItems)
    }

    const handleIconChange = ({ parentId, itemId, isVisible, isSelected }) => {
        if (!isVisible) { return }
        if (isLimitReached && !isSelected) { return }

        onIconChange({ parentId, itemId, isVisible })
    }

    useEffect(() => {
        setComputedMinHeight(
            (outerHeight(document.querySelectorAll('.draggable-sub-items .draggable')?.[0]) || 55) * (items?.length || 0)
        )
    }, [isCollapsed, items])

    return !isCollapsed && items.length > 0 && (
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable
                droppableId="draggable-sub-items"
                isCombineEnabled={false}
            >
                {
                    (provided) => <>
                        <div className='py-3'>
                            <div
                                ref={provided.innerRef}
                                className='droppable-sub-items sub-items list-unstyled pl-5'
                                {...provided.droppableProps}
                                style={{
                                    minHeight: computedMinHeight,
                                    ...provided.droppableProps.style
                                }}
                            >
                                {
                                    sortedItems.map((item, index) => (
                                        <Draggable
                                            index={index}
                                            key={parameterize(`${item.id}-${item.name}`)}
                                            draggableId={parameterize(`${item.id}-${item.name}`)}
                                            isDragDisabled={!isSortable}
                                        >
                                            {
                                                (provided, snapshot) => <>
                                                    <div
                                                        key={parameterize(`${item.id}-${item.name}`)}
                                                        className={`${snapshot.isDragging ? 'dragging' : 'draggable'} mb-2`}
                                                        {...(!!provided.innerRef ? { ref: provided.innerRef } : {})}
                                                        {...provided.draggableProps}
                                                    >
                                                        <div className={`d-flex sub-item ${item.is_visible ? '' : 'partially-visible' } align-items-center rounded border-bottom py-1`}>
                                                            <div className='col name h6 mb-0 d-flex align-items-center'>
                                                                {
                                                                    isSortable && (
                                                                        <Icon
                                                                            className='cursor-drag draggable d-fas text-muted h5 mb-0 mr-4 pr-2'
                                                                            icon='fa-bars'
                                                                            {...provided.dragHandleProps}
                                                                        />
                                                                    )
                                                                }

                                                                <Icon
                                                                    icon={isDefaultIconClass || 'fa-star'}
                                                                    className={[
                                                                        'h5 mb-0 mr-3',
                                                                        item[iconType] ? 'd-fas' : 'd-fal',
                                                                        item.is_visible && item[iconType]  && isLimitReached ? 'text-primary cursor-pointer' : null,
                                                                        item.is_visible && item[iconType]  && !isLimitReached ? 'text-primary cursor-pointer' : null,
                                                                        item.is_visible && !item[iconType] && !isLimitReached ? 'cursor-pointer'   : null,
                                                                        item.is_visible && !item[iconType] && isLimitReached ? 'text-muted cursor-default'   : null,
                                                                        !item.is_visible ? 'text-muted cursor-default' : null,
                                                                    ].filter(i => !!i).join(' ')}
                                                                    onClick={() => handleIconChange({
                                                                        parentId,
                                                                        itemId: item.id,
                                                                        isVisible: item.is_visible,
                                                                        isSelected: item[iconType]
                                                                    })}
                                                                />

                                                                <span className={`d-block w-100 ${item.is_visible ? '' : 'text-strikethrough'}`}>
                                                                    { debug ? `(ID: ${item.id}) ` : '' }{ item.name }
                                                                </span>

                                                                {
                                                                    !!item.amount_cents && (
                                                                        <span className={`d-block w-100 ${item.is_visible ? '' : 'text-strikethrough'}`}>
                                                                            +{numberToCurrency(Number.parseFloat(item.amount_cents) / 100.0)}
                                                                        </span>
                                                                    )
                                                                }
                                                            </div>

                                                            <div className='col-auto text-right sub-item--actions pr-0'>
                                                                <button
                                                                    type='button'
                                                                    className={`btn btn-link ${item.is_visible ? 'text-danger' : ''} mr-1`}
                                                                    onClick={() => onVisibilityChange({
                                                                        parentId,
                                                                        itemId: item.id,
                                                                        isVisible: !item.is_visible,
                                                                    })}
                                                                >
                                                                    { item.is_visible ? 'Hide' : 'Show' }
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    { provided.placeholder }
                                                </>
                                            }
                                        </Draggable>
                                    ))
                                }
                            </div>
                        </div>

                        { provided.placeholder }
                    </>
                }
            </Droppable>
        </DragDropContext>
    )
}
