import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectMenuItem, selectItemConfig } from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'
import { calculateSubtotalAmountCents } from '@/features/AdvancedPointOfSale/lib/Items'
import { titleize } from 'inflected'
import { numberToCurrency } from '@/lib/Number'

export default function MenuItemHeader() {

    const item       = useSelector(selectMenuItem)
    const itemConfig = useSelector(selectItemConfig)

    const priceCents = useMemo(() => calculateSubtotalAmountCents(item, itemConfig), [item, itemConfig])

    return <>
        <h1 className='h2 border-bottom pb-2 mb-3 d-flex align-items-end justify-content-start'>
            <span className='w-75 text-left pr-3'>
                { titleize(item.name) }
            </span>
            <span className='w-25 text-right'>
                { numberToCurrency(priceCents / 100) }
            </span>
        </h1>

        <p className='lead'>
            { item.description || 'No description available.' }
        </p>

        {
            item.allow_membership_merchandise_discount && (
                <span className='badge badge-info mt-3'>Member Discounts Allowed</span>
            )
        }
    </>
}
