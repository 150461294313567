import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"

import {
    selectLoading,
    selectValid,
    selectError,
    validateMembershipId,
    selectMessage,
    clear,
} from "../MemberProductDiscount/MemberProductDiscountSlice"
import { setMemberDiscount } from "../Products/productSlice"

export default function MemberProductDiscount({ locationId }) {

    const dispatch = useDispatch()
    const loading  = useSelector(selectLoading)
    const valid    = useSelector(selectValid)
    const error    = useSelector(selectError)
    const message  = useSelector(selectMessage)

    const [membershipId, setMembershipId] = useState("")

    const handleVerify = () => {
        dispatch(validateMembershipId(locationId, membershipId))
    }

    const handleClear = () => {
        setMembershipId('')
        dispatch(clear())
        dispatch(setMemberDiscount(0.0))
    }

    return (
        <div className="row">
            <div className="col-12 pb-2">
                <strong>Add a Membership Discount?</strong>
            </div>

            <div className="col-12">
                <div className="input-group">
                    <input
                        className={`form-control ${error ? "is-invalid" : ""}`}
                        placeholder="Member ID..."
                        value={membershipId}
                        type={"text"}
                        disabled={valid}
                        onChange={(e) => setMembershipId(e.target.value)}
                    />

                    <div className="input-group-append">
                        <button
                            children={
                                loading
                                    ? 'Verifying...'
                                    : valid
                                        ? 'Clear'
                                        : 'Verify Member'
                            }
                            className="btn btn-primary w-100"
                            onClick={valid ? handleClear : handleVerify}
                        />
                    </div>
                </div>

                <div className="invalid-message">{message}</div>
            </div>
        </div>
    )
}
