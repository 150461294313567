import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { cancelTerminalTransaction as cancelAdyenTransaction } from '@/features/Adyen/adyenSlice'
import { cancelTransaction as cancelPaysafeTransaction } from '@/features/Terminal/terminalSlice'

export default function CancelButton({
    id=null,
    className='btn-danger text-white mt-4',
    appendedButtonText='Transaction',
    paymentProcessor=null,
    axiosRequestCancellationDelay=500,
    axiosRequestCancellationMessage='OPERATION CANCELED BY THE USER',
    shouldCancelAxiosRequest=false,
    shouldCancelTerminalTransaction=false,
}) {

    const dispatch                = useDispatch()
    const [attempts, setAttempts] = useState(0)
    const isCancelling            = attempts > 0

    const handleCancel = () => {
        if (isCancelling) { return }

        setAttempts(attempts + 1)

        if (shouldCancelAxiosRequest && !!window.axiosTransactionSource) {
            window.setTimeout(() => {
                window.axiosTransactionSource.cancel(axiosRequestCancellationMessage)
            }, axiosRequestCancellationDelay)
        }

        if (shouldCancelTerminalTransaction) {
            switch (paymentProcessor) {
                case 'adyen' :
                    dispatch(cancelAdyenTransaction())
                    break

                case 'paysafe' :
                    dispatch(cancelPaysafeTransaction())
                    break
            }
        }
    }

    return (
        <button
            id={id}
            type='button'
            className={`btn ${className}`}
            children={`${isCancelling ? 'Cancelling' : 'Cancel'} ${appendedButtonText}`}
            disabled={isCancelling}
            onClick={handleCancel}
        />
    )
}
