import { createSlice } from "@reduxjs/toolkit"
import axios from 'axios'
import { UI } from '@/lib/Constants'

export const helpSlice = createSlice({
    name: 'help',
    initialState: {
        article: null,
        open: false
    },
    reducers: {
        setArticle: (state, action) => {
            state.article = action.payload
        },
        setOpen: (state) => {
            state.open = true
            document.body.classList.add(UI.classes.HELP_OPEN)
        },
        setClose: (state) => {
            state.open    = false
            state.article = null
            document.body.classList.remove(UI.classes.HELP_OPEN)
        }
    }
})

export const {
    setArticle,
    setOpen,
    setClose
} = helpSlice.actions

export const selectArticle = state => state.help?.article
export const selectOpen    = state => state.help.open

export function fetchHelpJuiceArticle(articleId) {
    return async (dispatch) => {
        return axios.get(`/help/v1/${articleId}.json`).then(({ data }) => {
            dispatch(setArticle(data))
        }).catch((e) => {
            if (console) { console.error(e) }
        })
    }
}

export function fetchFreshDeskArticle(articleId) {
    return async (dispatch) => {
        return axios.get(`/help/v2/${articleId}.json`).then(({ data }) => {
            dispatch(setArticle(data))
        }).catch((e) => {
            if (console) { console.error(e) }
        })
    }
}

export default helpSlice.reducer
