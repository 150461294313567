import React from 'react'
import ReactTooltip from 'react-tooltip'
import { v4 as uuidv4 } from 'uuid'

// https://github.com/wwayne/react-tooltip
export default function Tooltip({ children, id=null, type, place, toolTipOptions }) {
    const uuid = id !== null ? id : uuidv4()

    return (
        <div className="custom-tooltip">
            <span className="icon" data-tip data-for={uuid}>
                <i className={`far fa-question-circle ${toolTipOptions?.className}`} />
            </span>

            <ReactTooltip
                id={uuid}
                type={type || 'dark'}
                border={true}
                place={toolTipOptions?.place ? toolTipOptions.place : (place || 'top')}
                effect='solid'
                multiline={true}
                html={toolTipOptions?.html ? toolTipOptions.html : false}
                delayHide={1000}
                clickable={toolTipOptions?.clickable ? toolTipOptions.clickable : false}
            >
                {children}
            </ReactTooltip>
        </div>
    )
}
