import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    clearAmounts,
    selectPartialAlert,
    selectPartialAttempted,
    selectPartialAuthorized
} from './terminalSlice'

export default function PartialAuthAlert() {

    const dispatch          = useDispatch()
    const open              = useSelector(selectPartialAlert)
    const partialAttempted  = useSelector(selectPartialAttempted)
    const partialAuthorized = useSelector(selectPartialAuthorized)

    const handleClose = () => {
        dispatch(clearAmounts())
    }

    return !open ? null : (
        <div className="modal modal-backdrop modal--md">
            <div className="modal-container" style={{ border: '2rem solid indianred' }}>
                <div className="modal-header">
                    <div className="row">
                        <div className="col-12 mb-3">
                            <div className="modal-title">PARTIAL AUTHORIZATION ENCOUNTERED</div>
                        </div>
                    </div>
                    <button className="modal-close mb-auto" onClick={handleClose}>
                        <span>Close</span>
                        <i className="far fa-times"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="modal-body-main">
                        <div className="modal-body-main-section text-center">
                            <h1 style={{ textDecoration: 'underline' }}>
                                The customer's payment was only partially authorized.
                            </h1>
                            <div className="row mt-3">
                                <div className="col-4">
                                    <h3>Attempted: ${Number.parseFloat(partialAttempted / 100).toFixed(2)}</h3>
                                </div>
                                <div className="col-4">
                                    <h3>Authorized: ${Number.parseFloat(partialAuthorized / 100).toFixed(2)}</h3>
                                </div>
                                <div className="col-4">
                                    <h3>Remaining: ${Number.parseFloat((partialAttempted - partialAuthorized) / 100).toFixed(2)}</h3>
                                </div>
                            </div>
                            <button className="btn btn-primary mt-3" onClick={handleClose}>CLOSE</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
