import React from 'react'

export const LMFAO = (
    <div
        id='advanced-pos--terminal-container'
        className='d-flex align-items-center justify-content-center'
    >
        <h2 className='m-0 text-center'>
            <i className='h1 fas fa-grin-squint-tears text-yellow' />
            <br /><br />
            Oh, so you think you're <u>clever</u>, huh?
            <br /><br />
            The last time I laughed <em>this</em> hard, I fell off my <span className='text-green'>dinosaur</span>.
        </h2>
    </div>
)

export const ERROR = (
    <div
        id='advanced-pos--terminal-container'
        className='d-flex align-items-center justify-content-center'
    >
        <div className='text-center'>
            <h2 className='p-0 mb-1'>
                Uh oh, something went wrong.
            </h2>
            <h5 className='p-2 m-0 font-weight-lighter'>
                Please try refreshing the app.
            </h5>

            <button
                children={<i className='fa-solid fa-rotate-right h1 m-0 mt-3' />}
                type='button'
                className='btn btn-transparent text-green'
                onClick={(e) => {
                    e.target.classList.add('fa-spin');
                    window.setTimeout(() => { window.location.reload() }, 800)
                }}
            />
        </div>
    </div>
)
