$(function(){
    var $window     = $(window);
    var $sideNav    = $('#side-nav');
    var $menuToggle = $('#menu-toggle');

    window.__lastKnownWidth = $window.width();

    $window.on('resize', function() {
        if ($window.width() < window.__lastKnownWidth) {
            $menuToggle.removeClass(['forced-open', 'forced-closed']);
            $sideNav.removeClass(['forced-open', 'forced-closed']);
        }
        window.__lastKnownWidth = $window.width();
    });

    $menuToggle.on('click', function() {
        if ($window.width() >= 1441) {
            $menuToggle.find('.bars').toggleClass('forced-closed');
            $sideNav.toggleClass('forced-closed');
        } else {
            $menuToggle.find('.bars').toggleClass('forced-open');
            $sideNav.toggleClass('forced-open');
        }
    });

    // on initial load, auto-expand the parent of the current-page child
    $('#side-nav .current-page').parents('.expandable').addClass('expandable--expanded');

    // on initial load, toggle icon for those loaded in an expanded state
    $('#side-nav .expandable--expanded .expandable--toggle svg').removeClass('fa-chevron-down').addClass('fa-chevron-up');

    // expanding/collapsing menus
    $('#side-nav').on('click', '.expandable--toggle, .expandable > span', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();

        const $menu = $(e.currentTarget).closest('li.expandable');

        const collapse = function($menu, speed) {
            const $submenu = $menu.find('> ul')
            $submenu[speed === 0 ? 'hide' : 'slideUp'](speed);
            $submenu.parent().toggleClass('expandable--expanded');
            $menu.find('.expandable--toggle svg').removeClass('fa-chevron-up').addClass('fa-chevron-down');
        }

        const expand = function($menu, speed) {
            const $submenu = $menu.find('> ul')
            $submenu[speed === 0 ? 'show' : 'slideDown'](speed);
            $submenu.parent().toggleClass('expandable--expanded');
            $menu.find('> span .expandable--toggle svg').removeClass('fa-chevron-down').addClass('fa-chevron-up');
        }

        if ($menu.hasClass('expandable--expanded')) {
            collapse($menu, 200);
        } else {
            collapse($menu.siblings('.expandable--expanded'), 0);
            expand($menu, 200);
        }
    });

    // $('.table-responsive').on('show.bs.dropdown', function () {
    //     $('.table-responsive').css( "overflow", "inherit" );
    // });

    // $('.table-responsive').on('hide.bs.dropdown', function () {
    //      $('.table-responsive').css( "overflow", "auto" );
    // })
});
