import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useForm, FormProvider } from "react-hook-form"
import Input from '@/components/Form/Input'
import { validationPatterns } from '@/lib/Errors'
import { errorsFor } from '@/components/Form/ErrorsHelper'
import { setEmail as setReceiptEmail } from '@/features/BookingPayment/bookingPaymentSlice'

export default function Step3({ setStep }) {
  const dispatch = useDispatch()

  const onError = (formErrors, e) => {
    setErrors(errorsFor(formErrors))
  }

  const formMethods = useForm()

  const [errors, setErrors] = useState({})
  const [email, setEmail] = useState("")

  const handleSubmit = () => {
    dispatch(setReceiptEmail(email))
    setStep('4')
  }

  return (
    <div className="row justify-content-center">
      <div className="col-12 col-md-6">
        <div className="form-group">
          <FormProvider {...formMethods} >
            <form className="pb-5 mb-5" onSubmit={formMethods.handleSubmit(handleSubmit, onError)}>
              <div className='row'>
                <Input
                  label="Enter your email"
                  type="email"
                  className="form-control"
                  placeholder="Email Address"
                  value={email}
                  validation={{ 'email': {
                    required: true,
                    pattern: validationPatterns.email,
                  }}}
                  errors={errors}
                  handleChange={e => setEmail(e.target.value)}
                />

                <div className='col-12'>
                  <button
                    className="btn btn-primary btn-block mt-4"
                    type="submit"
                  >
                    Continue
                  </button>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  )
}
