import React from 'react'
import { useSelector } from 'react-redux'
import {
  selectOrder,
  selectPaymentType,
  selectPaymentReceived,
  selectChangeDue,
} from './productSlice'
import { numberToCurrency } from '@/lib/Number'

export default function ProductOrderTotals() {

    const order           = useSelector(selectOrder)
    const paymentType     = useSelector(selectPaymentType)
    const paymentReceived = useSelector(selectPaymentReceived)
    const changeDue       = useSelector(selectChangeDue)

    return !order ? null : <>
        <hr className="mt-1 mb-2" />
        {paymentType === 'cash' ?
            <div className="pos-totals-item">
                <span className="pos-totals-item-label">Amount Received</span>
                <span className="pos-totals-item-value green">{numberToCurrency(paymentReceived)}</span>
            </div>
            :
            <div className="pos-totals-item">
                <span className="pos-totals-item-label">Amount Paid</span>
                <span className="pos-totals-item-value green">{numberToCurrency(order.amount_paid_cents / 100)}</span>
            </div>
        }

        <div className="pos-totals-item">
            <span className="pos-totals-item-label">Balance Remaining:</span>
            <span className={`pos-totals-item-value ${order.balance_cents === 0 ? 'green' : 'red'}`}>{numberToCurrency(order.balance_cents / 100)}</span>
        </div>

        {paymentType === 'cash' &&
            <div className="pos-totals-item">
                <span className="pos-totals-item-label">Change Due:</span>
                <span className="pos-totals-item-value">{numberToCurrency(Number.parseFloat(String(changeDue).replace('$','')))}</span>
            </div>
        }
    </>
}
