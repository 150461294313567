import React, { useEffect, useMemo, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {selectDurations, selectPricing, selectPricingType} from '../../../Packages/packageSlice'
import Duration from './Duration'
import { setStep, setErrors, selectParticipants, setCustomerTypesWithoutPricesForSelectedDuration } from "../../bookingSlice";
import { selectCustomerTypes } from '@/features/CustomerTypes/customerTypeSlice'
import { debug } from '@/lib/Debug'

export default function SelectDuration() {

    const dispatch             = useDispatch()
    const durations            = useSelector(selectDurations)
    const pricing              = useSelector(selectPricing)
    const pricingType          = useSelector(selectPricingType)
    const participants         = useSelector(selectParticipants)
    const customerTypes        = useSelector(selectCustomerTypes)
    const invalidCustomerTypes = useRef({})
    const parsedParticipants   = Number(String(participants).split('-')[0])

    const parsedPrices = useMemo(() => (
        pricing.filter((p) => parsedParticipants >= p.groupMin && parsedParticipants <= p.groupMax)
               .filter((p) => !p.internal)
               .map((pricing) => pricing.prices)?.[0]
    ), [pricing, participants])

    const filteredDurations = useMemo(() => {
        if (debug && console) {
            console.log(
                'FILTERED_DURATIONS / PRICING', {
                    customerTypes: customerTypes,
                    durations: durations,
                    parsedParticipants: parsedParticipants,
                    parsedPrices: parsedPrices,
                    rawPrices: pricing,
                    type: pricingType,
                }
            )
        }

        return (
            durations
                // filter out durations that are only internal
                .filter((duration) => !duration.internal)

                // filter out durations that have no prices (both nil AND $0) for uniform pricing groups
                .filter((duration) => {
                    if (!/uniform/i.test(pricingType)) { return true }
                    const priceGroup = parsedPrices.filter((price) => price.duration === duration.duration)?.[0] || null
                    return priceGroup !== null && Number(priceGroup.price) > 0
                })

                // filter out durations that have NIL ONLY prices at all for customer type pricing groups
                .filter((duration) => {
                    if (!/customer_type/i.test(pricingType)) { return true }

                    const nonParticipantCustomerTypeIds = customerTypes.filter((ct) => ct.non_participant).map((ct) => ct.id)

                    const allPriceGroups = parsedPrices.flat().filter((priceGroup) => (
                        priceGroup.duration === duration.duration
                        && !nonParticipantCustomerTypeIds.includes(priceGroup.customerType)
                    ))

                    const invalidPriceGroups = parsedPrices.flat().filter((priceGroup) => {
                        if (
                            priceGroup.duration === duration.duration
                            && !nonParticipantCustomerTypeIds.includes(priceGroup.customerType)
                            // this intentionally allows $0 to be valid for participating customer types
                            && !!priceGroup.price === false
                        ) {
                            if (
                                !Object.keys(invalidCustomerTypes.current).includes(priceGroup.duration)
                                    ||
                                !invalidCustomerTypes.current?.[priceGroup.duration]?.includes(priceGroup.customerType)
                            ) {
                                invalidCustomerTypes.current[priceGroup.duration] = [
                                    ...(invalidCustomerTypes.current?.[priceGroup.duration] || []),
                                    priceGroup.customerType
                                ]
                            }
                            return true
                        }
                        return false
                    })

                    // allow the duration if at least one customer type has a price but
                    // filter it out if no prices are present for ALL of the customer types
                    return invalidPriceGroups.length < allPriceGroups.length
                })
        )
    }, [durations, participants, pricing, pricingType])

    useEffect(() => {
        // remember invalid customer types for use on the customer type selection step (4)
        if (invalidCustomerTypes.current !== null) {
            dispatch(setCustomerTypesWithoutPricesForSelectedDuration(invalidCustomerTypes.current))
        }

        // prevent forward step movement if necessary
        if (durations.length > 0 && filteredDurations.length === 0) {
            dispatch(setErrors({ type: 'add', errors: ['No durations are available for that group size.'] }))
            dispatch(setStep('1'))
        }
    }, [filteredDurations])

    return (
        <div className="xbm-step-question">
            <h2 className="mb-4">Duration</h2>

            {
                filteredDurations.map((duration, index) => (
                    <Duration key={index} duration={duration} preselected={(index === 0)} />
                ))
            }
        </div>
    )
}
