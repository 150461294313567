import React from 'react'
import DurationsFields from './DurationsFields'
import Help from '@/features/Help/Help'

export default function Durations({
  durations,
  pricing,
  selectedCustomerTypes,
  incrementer,
  setIncrementer,
  pricingType,
  isSpecialEvent=false,
  errors,
  handleUpdate,
}) {

    /**
     * The increment is what keeps track of the internal "duration id" that is set in order to properly
     * map durations to price grid entries. This is needed since they wanted to change durations from dropdowns
     * to number incrementers, which means we can't find anything dealing between durations & their price grid
     * entries by duration amount. We can't use indexes since those are obviously different, so we need something
     * else that is shared between duration entries and individual duration price grid entries... the internal duration._id
     */
    // const [incrementer, setIncrementer] = useState(durations.length ? (Math.max(...durations.map(d => d._id)) + 1) : 1)

    const handleAdd = () => {
        /**
         *
         * ------------------------------------------------------------------------------------
         *
         * @NOTE if the duration starts at anything besides 0, we are not automatically
         *       firing off to the pricing grid to add this new duration entry. This is
         *       because this input used to be a dropdown but was changed to a hard-coded
         *       toggle. If they want it to start at 15, we need to move the routine inside
         *       of DurationsFields#editDurationInPriceGrid to here and have it passed down
         *       to DurationsFields since both components would need to use that same
         *       functionality. Essentially the same useEffect pattern there would have to
         *       be implemented here as well, essentially modifying the price grid every time
         *       they add a new duration. The quick way around that is to just deafult it to 0
         *       so when they update the value it will update the price grid with that
         *       new duration option as well.
         *
         * ------------------------------------------------------------------------------------
         *
         */
        handleUpdate('durations', durations.concat({
            duration:    0,
            addon_price: null,
            internal:    null,
            _id:         incrementer
        }))

        // bump incrementer since new item has been added
        setIncrementer(incrementer + 1)
    }

    return (
        <div className="durations">
            <h3 className="d-flex justify-content-between align-items-center">
                <span className='d-flex'>
                    <span>
                        { isSpecialEvent ? 'Event Duration' : 'Durations' }
                    </span>
                    <Help articleId='154000158975-package-durations' />
                </span>
            </h3>

            {
                !isSpecialEvent && (
                    <div className="drawer-side-action btn btn-link" onClick={handleAdd}>
                        + Add Duration
                    </div>
                )
            }

            <DurationsFields
                durations={durations}
                pricing={pricing}
                pricingType={pricingType}
                selectedCustomerTypes={selectedCustomerTypes}
                isSpecialEvent={isSpecialEvent}
                errors={errors}
                handleUpdate={handleUpdate}
            />
        </div>
    )
}
