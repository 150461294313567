import React from 'react'
import { numberToCurrency } from '@/lib/Number'

export default function BookingBalances({
    booking=null,
    newBooking=false,
    quoteMode=false,
    paidToDate,
    pendingAmountPaidCents,
    pendingAmountRefundedCents,
    remainingBalance
}) {
    return (
        <div className="drawer-booking-ledger-remaining">
            {
                !newBooking && !quoteMode && (
                    <div className="paid-to-date">
                        <div className="label">
                            Paid to Date
                        </div>
                        <div className="amount">
                            {numberToCurrency(paidToDate)}
                        </div>
                    </div>
                )
            }

            {
                !newBooking && !quoteMode && pendingAmountPaidCents > 0 && (
                    <div className="pending-payments border border-success fa-fade">
                        <div className="label">
                            Pending<br />Payment(s)
                        </div>
                        <div className="amount">
                            {numberToCurrency(pendingAmountPaidCents / 100)}
                        </div>
                    </div>
                )
            }

            {
                !newBooking && !quoteMode && pendingAmountRefundedCents > 0 && (
                    <div className="pending-refunds border border-danger fa-fade">
                        <div className="label">
                            Pending<br />Refund(s)
                        </div>
                        <div className="amount">
                            {numberToCurrency(pendingAmountRefundedCents / 100)}
                        </div>
                    </div>
                )
            }

            <div className="amount-remaining">
                <div className="label" dangerouslySetInnerHTML={{ __html: (newBooking || quoteMode) ? 'Total' : 'Remaining<br/>Balance' }} />

                <div className={`amount ${remainingBalance > 0 ? 'text-danger' : 'text-success'}`}>
                    {numberToCurrency(remainingBalance)}
                </div>
            </div>

            {
                !!booking?.check && (
                    <small className="font-italic text-secondary text-center px-2 mt-3">
                        Remaining balance shown reservation only. Manage full payments via POS.
                    </small>
                )
            }
        </div>
    )
}
