import React from 'react'
import { useSelector } from 'react-redux'
// import { useDispatch, useSelector } from 'react-redux'
// import axios from 'axios'
import { selectParentBookingResources, selectChildBookings } from '@/features/ParentBooking/parentBookingSlice'
import BookingStatusIcons from '@/features/Calendar/BookingStatusIcons'
import BookingParticipants from '@/features/ParentBooking/GroupDetails/ParticipantsTable'
import Checkbox from '@/components/Form/Checkbox'
// import { addAlert } from '@/features/Notifications/notificationSlice'
// import { useConfirm } from '@/lib/useConfirmHook'
// import { accessToken } from '@/lib/Csrf'
import { debug } from '@/lib/Debug'

export default function ParentBookingGroupDetailsChildBookings({
    immutableEvent=false,
    waiversRequired=false,
    expandedParticipants=false,
    onToggleExpandedParticipants,
    onChildBookingClick,
    onCustomerCheckIn,
    onRemoveParticipant
}) {

    // const dispatch            = useDispatch()
    // const { confirm }         = useConfirm()
    const parentBookingResources = useSelector(selectParentBookingResources)
    const childBookings          = useSelector(selectChildBookings)

    // const handleChildBookingUngroup = async (cb) => {
    //     if (await confirm('<p>Are you sure you want to ungroup this booking?</p><p class="mt-5 text-danger">This cannot be undone.</p>', { header_text: 'Warning!', dangerous: true, size: 'md' })) {
    //         axios.post(`/bookings/${cb.id}/ungroup_child_booking`, {
    //             authenticity_token: accessToken,
    //             cb
    //         }).then(({ data }) => {
    //             if (data.success) {
    //                 onChildBookingClick(cb)
    //                 return data.success
    //             }

    //             dispatch(addAlert({ type: 'error', text: data.message }))
    //         }).catch(e => {
    //             console.warn(e)
    //             dispatch(addAlert({ type: 'error', text: 'Could not ungroup the booking!' }))
    //         })
    //     }
    // }

    return <>
        {
            childBookings?.length > 0 && waiversRequired && <>
                <Checkbox
                    name='expand'
                    label='Expand Participants'
                    value={expandedParticipants}
                    handleChange={onToggleExpandedParticipants}
                />

                <hr className='mt-1 mb-4' />
            </>
        }

        <div className="groups">
            {
                childBookings?.map((cb) => (
                    <div className={`event mb-3 ${expandedParticipants ? 'expand' : ''}`} key={`child_booking-${cb.id}`}>
                        <div className="event-inner">
                            <div className="event-inner-border-container">
                                <div className="event-header px-2">
                                    <div className="party-info" title="Click to view this booking" onClick={() => onChildBookingClick(cb)}>
                                        <div className="party-size py-2">{cb.group_max || cb.participants}</div>
                                        <div className="party-name">{cb.name} {debug && `(ID: ${cb.id})`}</div>
                                    </div>

                                    <BookingStatusIcons
                                        booking={cb}
                                        resources={parentBookingResources}
                                        childInParentModal={true}
                                    />

                                    <div>
                                        { /*
                                            !immutableEvent && (
                                                <div className="ungroup float-left">
                                                    <button
                                                        type='button'
                                                        className="btn btn-ungroup h-auto py-2"
                                                        onClick={() => handleChildBookingUngroup(cb)}
                                                    >
                                                        UNGROUP
                                                    </button>
                                                </div>
                                            )
                                        */ }
                                        <div className="ungroup float-left">
                                            <button
                                                type='button'
                                                className="btn btn-ungroup h-auto py-2"
                                                onClick={() => onChildBookingClick(cb)}
                                            >
                                                VIEW
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <BookingParticipants
                                    booking={cb}
                                    customers={cb.customers}
                                    immutableEvent={immutableEvent}
                                    onCustomerCheckIn={onCustomerCheckIn}
                                    onRemoveParticipant={onRemoveParticipant}
                                />
                            </div>
                        </div>
                    </div>
                ))
            }

            {
                childBookings?.length === 0 && (
                    <h6 className='w-100 text-center mt-5'>
                        This special event does not have any reservations yet.
                    </h6>
                )
            }
        </div>
    </>
}
