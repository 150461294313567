import React, { useState }  from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectError, validateManagerCode } from '../ManagerCodes/managerCodeSlice'
import { selectLocationId } from './productSlice'

export default function DiscountManagerCode() {
    const dispatch   = useDispatch()
    const locationId = useSelector(selectLocationId)
    const error      = useSelector(selectError)

    const [code, setCode] = useState('')

    const verify = () => {
        dispatch(validateManagerCode(locationId, code))
            .catch((e) => { console.warn(e) })
    }

    return (
        <div className="row">
            <div className="col">
                <div className="input-group">
                    <input
                        className={`form-control ${error ? 'is-invalid' : ''}`}
                        placeholder='Manager Code...'
                        value={code}
                        type={Boolean(code) ? 'password' : 'text'}
                        onChange={e => setCode(e.target.value)}
                        onKeyDown={(e) => { if (e.keyCode === 13) { verify() }}}
                    />

                    <div className="input-group-append">
                        <button className='btn btn-primary w-100' onClick={verify}>
                            Verify Code
                        </button>
                    </div>
                </div>

                <div className="invalid-feedback">{error}</div>
            </div>
        </div>
    )
}
