import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { selectDates, fetchAvailableDaysForDuration } from '../../Availability/availabilitySlice'
import { selectDate, selectDuration, selectParticipants, setFormAttribute } from '../scheduleSlice'
import { selectPackage } from '../../Packages/packageSlice'
import { selectTimeZone } from '@/features/Locations/locationSlice'
import { localDateToMoment, momentToLocalDate } from '@/lib/CalendarTimes'

export default function BookingCalendar() {

    const dispatch         = useDispatch()
    const pkg              = useSelector(selectPackage)
    const selectedTimeZone = useSelector(selectTimeZone)
    const availableDates   = useSelector(selectDates)
    const date             = useSelector(selectDate)
    const duration         = useSelector(selectDuration)
    const participants     = useSelector(selectParticipants)

    const [loaded, setLoaded] = useState(false)

    const handleMonthChange = (month) => {
        dispatch(fetchAvailableDaysForDuration(duration, month, participants, `/packages/${pkg.id}/bookings/calendar_populate?drawer=true`))
        dispatch(setFormAttribute({ name: 'date', value: null }))
        dispatch(setFormAttribute({ name: 'time', value: null }))
    }

    const handleDateChange = (date) => {
        const localizedDate = localDateToMoment(date, selectedTimeZone)
                                .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                                .format('YYYY-MM-DD')

        dispatch(setFormAttribute({ name: 'date', value: localizedDate }))
        dispatch(setFormAttribute({ name: 'time', value: null }))
    }

    /**
     * if the date is preloaded, select it automatically
     * otherwise, set the current date
     */
    useEffect(() => {
        if (!loaded && pkg && duration) {
            dispatch(setFormAttribute({ name: 'date', value: (pkg.booking_preload?.date || moment().format('YYYY-MM-DD')) }))
            setLoaded(true)
        }
    }, [dispatch, pkg, duration, availableDates])

    /**
     * Fetch available dates
     */
    useEffect(() => {
        if (pkg && duration && date && participants) {
            dispatch(fetchAvailableDaysForDuration(duration, date, participants, `/packages/${pkg.id}/bookings/calendar_populate?drawer=true`))
        }
    }, [dispatch, pkg, duration, participants, date])

    return availableDates && (
        <div className="booking-calendar">
            <h3>Date</h3>

            <div className="axe-datepicker">
                <DatePicker
                    inline
                    disabledKeyboardNavigation
                    minDate={momentToLocalDate(moment().tz(selectedTimeZone))}
                    forceShowMonthNavigation={true}
                    includeDates={availableDates.map(d => moment(d).toDate())}
                    selected={date ? momentToLocalDate(moment.tz(date, selectedTimeZone)) : null}
                    onMonthChange={handleMonthChange}
                    onChange={handleDateChange}
                    openToDate={availableDates.length >= 1 ? momentToLocalDate(moment.tz(availableDates[0], selectedTimeZone)) : null}
                />
            </div>
        </div>
    )
}
