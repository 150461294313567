import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import ProductOrderTotals from "./ProductOrderTotals"
import {
    selectSelectedProducts,
    selectPricing,
    calculatePrice,
    selectDiscount,
    selectDiscountNotes,
    selectMemberDiscount,
    selectMemberDiscountInfo,
} from "./productSlice"
import { numberToCurrency } from "@/lib/Number"

export default function ProductTransactionTotals() {
    const dispatch           = useDispatch()
    const items              = useSelector(selectSelectedProducts)
    const pricing            = useSelector(selectPricing)
    const discount           = useSelector(selectDiscount)
    const memberDiscount     = useSelector(selectMemberDiscount)
    const memberDiscountInfo = useSelector(selectMemberDiscountInfo)
    const discountNotes      = useSelector(selectDiscountNotes)

    useEffect(() => {
        dispatch(calculatePrice())
    }, [items, discount, memberDiscount])

    return (
        <div className="pos-totals">
            {!!memberDiscount && (
                <div className="pos-totals-item">
                    <span className="pos-totals-item-label">Member Discount:</span>
                    <span className="pos-totals-item-value">-{memberDiscountInfo}</span>
                </div>
            )}

            {!!discount && (
                <div className="pos-totals-item">
                    <span className="pos-totals-item-label">Discount:</span>
                    <span className="pos-totals-item-value">-{numberToCurrency(discount)}</span>
                </div>
            )}

            {!!discountNotes && (
                <div className="pos-totals-item">
                    <small className="text-muted">
                        <em>{discountNotes}</em>
                    </small>
                </div>
            )}

            <div className="pos-totals-item">
                <span className="pos-totals-item-label">Subtotal:</span>
                <span className="pos-totals-item-value">{numberToCurrency(pricing.subtotal)}</span>
            </div>

            <div className="pos-totals-item">
                <span className="pos-totals-item-label">Sales Tax:</span>
                <span className="pos-totals-item-value">{numberToCurrency(pricing.taxes)}</span>
            </div>

            <div className="pos-totals-item border-top pt-2">
                <span className="pos-totals-item-label">Total:</span>
                <span className="pos-totals-item-value">{numberToCurrency(pricing.total)}</span>
            </div>

            <ProductOrderTotals />
        </div>
    )
}
