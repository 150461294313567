import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { findPathDeep } from 'deepdash/standalone'
import { addAlert } from '@/features/Notifications/notificationSlice'
import Input from '@/components/Form/Input'
import FormRow from '@/components/Form/FormRow'
import Checkbox from '@/components/Form/Checkbox'
import ColorPicker from '@/components/Form/ColorPicker'
import MultiSelectCheckboxes from '@/components/Form/MultiSelectCheckboxes'
import MenuBuilderList from '@/components/AdvancedPointOfSale/Menus/MenuBuilderList'
import { pluckKeyFromObjectsInArray, setPropertyByPath } from '@/lib/Object'
import { debug } from '@/lib/Debug'

export default function MenuForm({
  form,
  errors,
  new: newMenu,
  rootCategoriesUrl,
  categoryUrl,
  handleUpdate,
  handleManualUpdate,
  handleCheckboxUpdate,
}) {

  const dispatch                            = useDispatch()
  const [rootCategories, setRootCategories] = useState([])

  const selectedCategories = !Array.isArray(form?.content) ? [] : (
    form.content.map((category) => (!!category ? { id: category.id, name: category.name } : null))
                .filter((i) => i !== null)
  )

  const updateMenuContent = (content) => {
      handleManualUpdate('content', content)
  }

  /*
   * Fetch content (items + sub-categories) for the
   * new category and add it to the menuContent JSON
   */
  const handleCheckboxesAdd = (content) => {
    const hasExistingContent = Object.values(form.content).length > 0
    const existingIds        = hasExistingContent ? pluckKeyFromObjectsInArray(form.content, 'id') : []
    const categoryId         = content.map((c) => c.id).filter((id) => !existingIds.includes(id))[0]

    axios.get(`${categoryUrl}${categoryId}`).then(({ data }) => {
      if (data.success) {
        updateMenuContent(hasExistingContent ? [...form.content, data.payload] : [data.payload])
      } else {
        dispatch(addAlert({ type: 'error', text: data.message }))
      }
    })
  }

  /*
   * Simply remove the category from the menuContent JSON
   */
  const handleCheckboxesRemove = (_content, item=null) => {
    if (!!item) {
      updateMenuContent([...form.content].filter((category) => category.id !== item.id))
    }
  }

  const handleMenuContentAdd = (item=null) => {
    const newContent = [...form.content]
    const key        = 'menu_uuid'
    const pattern    = new RegExp(`(\.${key})$`)

    // NOTE we need to tear off the end of the path because we're
    // always assuming that we're manipulating objects from arrays,
    // not key/value pairs from within the target object
    const path = findPathDeep(newContent, (value) => (value === item[key]))?.replace(pattern, '')

    if (!!path) {
      eval(`newContent${path}.is_present = true`)
      updateMenuContent(newContent)
    }
  }

  const handleMenuContentRemove = (_content, item=null) => {
    const newContent = [...form.content]
    const key        = 'menu_uuid'
    const pattern    = new RegExp(`(\.${key})$`)

    // NOTE we need to tear off the end of the path because we're
    // always assuming that we're manipulating objects from arrays,
    // not key/value pairs from within the target object
    const path = findPathDeep(newContent, (value) => (value === item[key]))?.replace(pattern, '')

    if (!!path) {
      eval(`newContent${path}.is_present = false`)
      updateMenuContent(newContent)
    }
  }

  const handleMenuContentSort = (content, options) => {
    if (options?.isNested && !!options?.collection) {
      const newContent = [...form.content]
      const key        = 'menu_uuid'
      const pattern    = new RegExp(`(\.${key})$`)

      // NOTE we need to tear off the end of the path because we're
      // always assuming that we're manipulating objects from arrays,
      // not key/value pairs from within the target object
      //
      // NOTE we replace it with a collection type so we know whether
      // we're sorting items or categories within the list
      const path = findPathDeep(newContent, (value) => (value === options.parentMenuUUID))?.replace(pattern, `.${options.collection}`)

      if (!!path) {
        setPropertyByPath(newContent, path, content)
        updateMenuContent(newContent)
      }
    } else {
      updateMenuContent(content)
    }
  }

  const handleMenuContentDefaultChange = ({ id, toState, parentMenuUUID }) => {
    if (!!id && toState !== undefined && toState !== null && !!parentMenuUUID) {
      const newContent = [...form.content]
      const pattern    = /(\.items\[[0-9]+\]\.menu_uuid)$/

      // NOTE we need to tear off the end of the path because we're
      // always assuming that we're manipulating objects from arrays,
      // not key/value pairs from within the target object
      const path = findPathDeep(newContent, (value) => (value === parentMenuUUID))?.replace(pattern, '.default_item_ids')

      let newDefaultItemIds = [...eval(`newContent${path}`)]

      if (toState) {
        newDefaultItemIds.push(id)
      } else {
        newDefaultItemIds = newDefaultItemIds.filter((_id) => (_id != id))
      }

      if (!!path) {
        setPropertyByPath(newContent, path, newDefaultItemIds)
        updateMenuContent(newContent)
      }
    }
  }

  /*
   * Fetch menu categories on each load
   */
  useEffect(() => {
    axios.get(rootCategoriesUrl).then(({ data }) => {
      if (data.success) {
        setRootCategories(data.payload)
        return
      }

      dispatch(addAlert({ type: 'error', text: data.message }))
    })
  }, [])

  return (
    <div className="mt-4">
      <FormRow>
        <Input
          cols='8'
          name="name"
          className='mt-3'
          hideLabel={true}
          value={form.name}
          handleChange={handleUpdate}
        />

        <Checkbox
          cols='4'
          name="is_default"
          label="Default Menu"
          className="mt-3 pt-2 pl-4"
          value={form.is_default}
          handleChange={handleCheckboxUpdate}
        />
      </FormRow>

      <h4>Button Color</h4>
      <FormRow>
        <ColorPicker
          value={form.button_color}
          onChange={color => handleManualUpdate('button_color', color)}
        />
      </FormRow>

      <h4>Content</h4>

      <FormRow>
        <MultiSelectCheckboxes
          hideLabel={true}
          options={rootCategories}
          selectedValues={selectedCategories}
          useExternalList={true}
          emptyRecordMsg='No categories available.'
          errors={errors}
          onSelect={handleCheckboxesAdd}
          onRemove={handleCheckboxesRemove}
        >
            <MenuBuilderList
              className='menu-builder pt-3'
              items={form.content}
              itemsParentIdKey='id'
              isSubMenuCollapsedOnLoad={!newMenu}
              iconType='is_priority'
              onAdd={handleMenuContentAdd}
              onRemove={handleMenuContentRemove}
              onDefaultChange={handleMenuContentDefaultChange}
              onSort={handleMenuContentSort}
            />
        </MultiSelectCheckboxes>
      </FormRow>

      {
        debug && <>
          <h4 className='mt-5 text-monospace'>RAW CONTENT</h4>
          <FormRow>
            <MenuBuilderList
              className='menu-builder'
              items={form.raw_content}
              itemsParentIdKey='id'
              isSubMenuCollapsedOnLoad={!newMenu}
              iconType='is_priority'
              debug={true}
            />
          </FormRow>
        </>
      }
    </div>
  )
}
