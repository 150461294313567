import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NumberIncrementer from '../../components/Form/NumberIncrementer'
import { selectCustomersToPayFor, setCustomersToPayFor } from './bookingPaymentSlice'
import { selectBooking } from '../EditBooking/editBookingSlice'

export default function PaymentUniformParticipants() {

    const dispatch = useDispatch()

    const customersToPayFor = useSelector(selectCustomersToPayFor)
    const booking = useSelector(selectBooking)

    const handleChange = (value) => {
        const target = Number.parseInt(typeof value === 'object' ? value.target.value : value)
        dispatch(setCustomersToPayFor(target))
    }

    const remaining = () => {
        return booking.participants - booking.customers_paid_for
    }

    return (
        <div className="modal-body-main-section pb-2">
            <div className="row">
                <div className="col-12">
                    <div className="form-group">
                        <label className="d-flex justify-content-between">
                            Participants

                            {/* <div className={`badge badge-${customersToPayFor < remaining() ? 'danger' : 'success'} bold`}> */}
                            {/*     {customersToPayFor} of {remaining()} */}
                            {/* </div> */}
                        </label>

                        <NumberIncrementer
                            name='amount'
                            value={customersToPayFor}
                            disabled={customersToPayFor >= remaining()}
                            incrementAmount={1}
                            min={0}
                            handleChange={handleChange} />
                    </div>
                </div>
            </div>
        </div>
    )
}
