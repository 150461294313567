import React from 'react'
import FormRow from '../Form/FormRow'
import Checkbox from '../Form/Checkbox'
import TextArea from '../Form/TextArea'
import RichTextEditor from '../Form/RichTextEditor'
import PartySizeMessages from './PartySizeMessages'
import Help from '@/features/Help/Help'

export default function PackageAdvancedSettingsTab({
    resource,
    form,
    errors,
    handleUpdate,
    handleCheckboxUpdate,
    handleManualUpdate,
}) {

    return (
        <div className="booking-settings pt-0">
            <h3 className="d-flex justify-content-between align-items-center">
                <span className='d-flex'>
                    <span>Package Visibility</span>
                </span>
            </h3>

            <FormRow className='px-1'>
                <Checkbox
                    cols='6'
                    name='show_package_in_drawer'
                    label='Show Package in Admin Drawer'
                    value={form.show_package_in_drawer}
                    handleChange={handleCheckboxUpdate}
                >
                    <Help articleId='154000158982-admin-bookings' />
                </Checkbox>
                <Checkbox
                    cols='6'
                    name='hide_from_all_packages_in_drawer'
                    label='Hide from All Packages in Admin Drawer'
                    value={form.hide_from_all_packages_in_drawer}
                    handleChange={handleCheckboxUpdate}
                >
                </Checkbox>
            </FormRow>

            <PartySizeMessages
                handleUpdate={handleManualUpdate}
                messages={form.party_size_selection_messages || []}
            />

            <h3 className="d-flex justify-content-between align-items-center">
                <span className='d-flex'>
                    <span>Package Size Message</span>
                    <Help articleId='154000158984-package-size-message' />
                </span>
            </h3>
            <FormRow>
                <TextArea
                    name='party_size_message'
                    handleChange={handleUpdate}
                    value={form.party_size_message}
                    cols='12'
                    hideLabel={true} />
            </FormRow>

            {
                resource.show_reminder_modal && <>
                    <h3 className="d-flex justify-content-between align-items-center">
                        <span className='d-flex'>
                            <span>Reminder Modal Text</span>
                            <Help articleId='154000158985-override-reminder-modal-text' />
                        </span>
                    </h3>


                    <Checkbox
                        cols='6'
                        name='override_resource_type_reminder_text'
                        label='Override resource type reminder text?'
                        handleChange={handleCheckboxUpdate}
                        value={form.override_resource_type_reminder_text} />

                    <FormRow className='mt-2'>
                        <TextArea
                        cols='12'
                        rows='7'
                        value={form.reminder_modal_text}
                        req={false}
                        errors={errors}
                        validation={{ 'reminder_modal_text': { required: false } }}
                        handleChange={handleUpdate}
                        />
                    </FormRow>
                </>
            }

            <h3 className="d-flex justify-content-between align-items-center">
                <span className='d-flex'>
                    <span>Reservation Confirmation Email Content</span>
                    <Help articleId='154000158986-reservation-confirmation-email-content' />
                </span>
            </h3>

            <FormRow>
                <RichTextEditor
                    name='reservation_confirmation_email_content'
                    handleChange={handleManualUpdate}
                    value={form.reservation_confirmation_email_content}
                    hideLabel={true}
                    cols='12'
                    errors={errors}
                />
            </FormRow>

            <h3 className="d-flex justify-content-between align-items-center">
                <span className='d-flex'>
                    <span>Custom No Times Available Message in Booking Modal</span>
                    <Help articleId='154000158987-custom-no-times-available-content' />
                </span>
            </h3>

            <FormRow>
                <Checkbox
                    cols='12'
                    name='override_no_times_available_message'
                    label='Override No Times Available Message?'
                    handleChange={handleCheckboxUpdate}
                    value={form.override_no_times_available_message} />
            </FormRow>

            <FormRow className='mt-2'>
                <RichTextEditor
                    name='custom_no_times_available_message'
                    handleChange={handleManualUpdate}
                    value={form.custom_no_times_available_message}
                    hideLabel={true}
                    cols='12'
                    errors={errors}
                />
            </FormRow>

        </div>
    )
}
