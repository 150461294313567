import React, { memo, forwardRef } from 'react'
import { statusOf } from '@/lib/Booking'

export function _BookingOverlappingMenu({
    overlappingBookingsList,
    displayOverlapUI,
    handleSurfaceBooking
}, ref) {
    return displayOverlapUI && overlappingBookingsList().length > 0 && (
        <div className="overlap-list" ref={ref}>
            <div className="overlap-list-inner">
                {
                    overlappingBookingsList().map((record) => (
                        <button
                            key={`overlapping-booking-menu-${record.booking.id}`}
                            type="button"
                            className={`btn-none event ${statusOf(record.booking)}`}
                            onClick={(e) => handleSurfaceBooking(e, record.booking)}
                        >
                            <div className="event-inner">
                                <div className="event-header">
                                    <div className="party-name">
                                        { record.booking.name }
                                    </div>
                                </div>
                            </div>
                        </button>
                    ))
                }
            </div>
        </div>
    )
}

export const BookingOverlappingMenu = memo(forwardRef(_BookingOverlappingMenu))
