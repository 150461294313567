import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import { isEmpty } from 'lodash'

import {
    setStep,
    selectStep,
    selectStepErrors,
    selectMemberInformation,
    selectPaymentReady,
    selectPurchaseFor,
    validateEmailAddress,
} from './memberSlice'

import { addAlert } from '@/features/Notifications/notificationSlice'
import MemberSteps from '@/lib/Member'

export default function MemberFooter({
    nextStep,
    backStep,
    hideBackStep=false,
    disabled,
    footerButtons,
    adyenCheckout,
    handleSubmit,
    onError
}) {
    const dispatch    = useDispatch();
    const formMethods = useFormContext()

    const memberInformation = useSelector(selectMemberInformation)
    const errors            = useSelector(selectStepErrors)
    const step              = useSelector(selectStep)
    const paymentReady      = useSelector(selectPaymentReady)
    const purchaseFor       = useSelector(selectPurchaseFor)

    const [emailError, setEmailError]       = useState(false)
    const [checkingEmail, setCheckingEmail] = useState(false)

    const handleBack = () => {
        if (!backStep) { return }
        dispatch(setStep(backStep))
    };

    const handleNext = async () => {
        if (!nextStep) { return }

        if ([MemberSteps.MEMBER_INFORMATION, MemberSteps.GIFTED_MEMBER_INFORMATION].includes(step)) {
            setCheckingEmail(true)

            await new Promise((resolve) => window.setTimeout(resolve, 500))

            await dispatch(validateEmailAddress(memberInformation.email)).then((response) => {
                if (response.success || /^myself$/i.test(purchaseFor)) {
                    setEmailError(false)
                    dispatch(setStep(nextStep))
                } else {
                    setEmailError(true)
                    dispatch(addAlert({ type: "error", text: response.message, positiion: "bottom right" }))
                }
            })

            setCheckingEmail(false)
        } else {
            dispatch(setStep(nextStep));
        }
    };

    // submit adyen checkout which will encrypt information and fire callback passed to AdyenCreditCard
    const handleAdyenSubmit = () => {
        handleNext()
        adyenCheckout.submit()
    }

    // membership-TODO: Talk to Tyler about this.The problem is that if your card information fails, there's no way to re-verify your information without clicking submit.
    // I'm basically saying here that if we're on the payment-information step, we have to ignore the errors block and only check if the consent button has been checked.
    const nextDisabled = () => {
        if (step === "payment-information") {
            return disabled;
        } else {
            return !isEmpty(errors) || disabled;
        }
    };

    const renderNextButton = () => {
        if ((step === MemberSteps.MEMBER_INFORMATION || step === MemberSteps.GIFTED_MEMBER_INFORMATION) && checkingEmail) {
            return (
                <button className="btn btn-primary" type="button" onClick={handleNext} disabled={nextDisabled()}>
                    Verifying email...&nbsp;
                    <i className="far fa-chevron-right ml-1" />
                </button>
            );
        } else if ((step === MemberSteps.MEMBER_INFORMATION || step === MemberSteps.GIFTED_MEMBER_INFORMATION) && !checkingEmail) {
            return (
                <button className="btn btn-primary" type="button" onClick={formMethods.handleSubmit(handleNext, onError)} disabled={nextDisabled()}>
                    Next&nbsp;
                    <i className="far fa-chevron-right ml-1" />
                </button>
            );
        } else if (step !== MemberSteps.PAYMENT_INFORMATION) {
            return (
                <button className="btn btn-primary" type="button" onClick={handleNext} disabled={nextDisabled()}>
                    Next&nbsp;
                    <i className="far fa-chevron-right ml-1" />
                </button>
            );
        } else if (step === MemberSteps.PAYMENT_INFORMATION) {
            if (paymentReady) {
                return (
                    <span>
                        <button
                            className="btn btn-primary"
                            type="button"
                            onClick={handleSubmit}
                            disabled={nextDisabled()}
                        >
                            FINISH & PAY&nbsp;
                            <i className="far fa-chevron-right ml-1" />
                        </button>
                    </span>
                );
            } else {
                return (
                    <button
                        id="finish-button"
                        className="btn btn-primary"
                        type="button"
                        onClick={handleAdyenSubmit}
                        disabled={nextDisabled()}
                    >
                        FINISH & PAY&nbsp;
                        <i className="far fa-chevron-right ml-1" />
                    </button>
                );
            }
        }
    };

    return (
        <div className="xbm-footer">
            <div className="xbm-footer-actions">
                {
                    /* When we have footer buttons passed as a prop, render those... otherwise render the normal next/back buttons */
                    footerButtons ? (
                        footerButtons
                    ) : <>
                        {
                            !hideBackStep && (
                                <button className="btn btn-default" type="button" onClick={handleBack}>
                                    <i className="far fa-chevron-left mr-1" /> Back
                                </button>
                            )
                        }

                        {renderNextButton()}
                    </>
                }
            </div>
        </div>
    );
}
