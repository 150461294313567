import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { configureModal, openModal } from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'
import Icon from '@/components/FontAwesomeIcon'

export default function Battery({
    className='',
    show=true,
    showPercent=true,
    debug=false,
}) {
    const dispatch                                                  = useDispatch()
    const [isInitialized, setIsInitialized]                         = useState(false)
    const [isSupported, setSupportBatteryManager]                   = useState(false)
    const [isBatteryLow, setIsBatteryLow]                           = useState(false)
    const [batteryIsCharging, setBatteryIsCharging]                 = useState(false)
    const [batteryChargingSeconds, setBatteryChargingSeconds]       = useState(0)
    const [batteryDischargingSeconds, setBatteryDischargingSeconds] = useState(0)
    const [batteryLevelPercent, setBatteryLevelPercent]             = useState(0)
    const [batteryClass, setBatteryClass]                           = useState('')

    const updateBatteryStatus = (battery) => {
        setBatteryIsCharging(battery.charging)
        setBatteryChargingSeconds(battery.chargingTime)
        setBatteryDischargingSeconds(battery.dischargingTime)
        setBatteryLevelPercent(Number.parseFloat((battery.level * 100).toFixed(2)))

        if (battery.charging && battery.level < 1) {
            setBatteryClass('fa-battery-bolt text-success')
        } else {
            switch(true) {
                case battery.level >= 0.95 :
                    setBatteryClass('fa-battery-full')
                    break

                case battery.level >= 0.75 :
                    setBatteryClass('fa-battery-three-quarters')
                    break

                case battery.level >= 0.50 :
                    setBatteryClass('fa-battery-half')
                    break

                case battery.level >= 0.25 :
                    setBatteryClass('fa-battery-quarter text-warning')
                    setIsBatteryLow(false)
                    break

                case battery.level <= 0.20 :
                    setIsBatteryLow(true)

                case battery.level >= 0.20 :
                    setBatteryClass('fa-battery-low text-danger')
                    break

                case battery.level >= 0.15 :
                    setBatteryClass('fa-battery-empty text-danger')
                    break

                default :
                    setBatteryClass('fa-battery-exclamation text-danger')
                    break
            }
        }
    }

    useEffect(() => {
        if (isInitialized) { return }

        const initBattery = async () => {
            if (window.navigator.getBattery) {
                setSupportBatteryManager(true)

                await window.navigator.getBattery().then((battery) => {
                    battery.onchargingchange    = () => updateBatteryStatus(battery)
                    battery.ondischargingchange = () => updateBatteryStatus(battery)
                    battery.onlevelchange       = () => updateBatteryStatus(battery)

                    updateBatteryStatus(battery)
                })

                setIsInitialized(true)
            }
        }

        initBattery()
    }, [])

    useEffect(() => {
        if (isSupported && isBatteryLow) {
            dispatch(configureModal({
                modal: 'popModal',
                config: { text: 'Battery Low', icon: 'fa-triangle-exclamation bg-danger' }
            }))
            dispatch(openModal('popModal'))
        }
    }, [isSupported, isBatteryLow])

    useEffect(() => {
        if (isSupported && isInitialized && debug && console) {
            console.log('BATTERY: ', {
                isSupported,
                batteryIsCharging,
                batteryChargingSeconds,
                batteryDischargingSeconds,
                batteryLevelPercent,
            })
        }
    }, [
        isSupported,
        isInitialized,
        batteryIsCharging,
        batteryChargingSeconds,
        batteryDischargingSeconds,
        batteryLevelPercent
    ])

    return show && isSupported && (
        <span className={className}>
            <Icon packs={['fa-solid']} icon={`fa-xl ${batteryClass}`} />
            { showPercent && <span className='ml-1' children={`${batteryLevelPercent}%`} /> }
        </span>
    )
}
