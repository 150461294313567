import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UI } from '@/lib/Constants'
import CalendarSearchFooter from './CalendarSearchFooter'
import CalendarSearchResultsContainer from './CalendarSearchResultsContainer'
import { selectOpen, setClosed, selectResults, selectSearchedQuery, setSearch } from './calendarSearchSlice'

export default function CalendarSearchDrawer({ location }) {

    const dispatch      = useDispatch()
    const drawerOpen    = useSelector(selectOpen)
    const searchedQuery = useSelector(selectSearchedQuery)
    const results       = useSelector(selectResults)

    const handleClose = (e) => {
        if (
            e.key === 'Escape'
            && !document.body.classList.contains(UI.classes.CONFIRMATION_OPEN)
            && !document.body.classList.contains(UI.classes.HELPJUICE_OPEN)
        ) {
            dispatch(setSearch(null))
            dispatch(setClosed())
        }
    }

    useEffect(() => {
        document.addEventListener('keyup', handleClose)

        return () => {
            document.body.classList.remove(UI.classes.MULTIPLE_MODALS_OPEN)
            document.removeEventListener('keyup', handleClose)
        }
    }, [drawerOpen])

    return !drawerOpen ? null : (
        <div className="drawer drawer-booking-open">
            <div className="drawer-scaffold">
                <div className="drawer-main">

                    <div className="drawer-header-container">
                        <div className="drawer-header">
                            <h2>Search Results:&nbsp; "{searchedQuery}"</h2>
                        </div>
                    </div>

                    <div className="drawer-main-content">
                        <div className="drawer-scroller">
                            {
                                results.length
                                    ? <CalendarSearchResultsContainer location={location}/>
                                    : <h1 className="mt-4">No Results Found</h1>
                            }
                        </div>
                    </div>

                    <CalendarSearchFooter />

                </div>
            </div>
        </div>
    )
}
