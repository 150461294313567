//
// original src:
// https://martijnhols.nl/gists/how-to-detect-the-on-screen-keyboard-in-ios-safari
//

import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setHasVirtualKeyboardApi, setVirtualKeyboardIsOpen } from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'
import { debug } from '@/lib/Debug'

export default function addVirtualKeyboardSupport() {

    const dispatch            = useDispatch()
    const [loaded, setLoaded] = useState(false)
    const [isOpen, setOpen]   = useState(null)

    const isKeyboardInput = (el) => (
      (el.tagName === 'INPUT' && !['button', 'submit', 'checkbox', 'file', 'image'].includes(el.type))
        || el.tagName === 'TEXTAREA'
        || el.isContentEditable
        || el.hasAttribute('contenteditable')
    )

    const handleFocusIn = (e) => {
        if (!e.target) { return }
        if (isKeyboardInput(e.target)) { setOpen(true) }
    }

    const handleFocusOut = (e) => {
        if (!e.target) { return }
        if (isKeyboardInput(e.target)) { setOpen(false) }
    }

    // NOTE
    // We set null first to "reset" the state in order
    // to absolutely ensure it changes to false
    const handleBlur = () => {
        window.setTimeout(() => {
            setOpen(null)
            setOpen(false)
        }, 75)
    }

    useEffect(() => {
        if (!loaded) { return }

        if (isOpen) {
            window.addEventListener('blur', handleBlur)

            if (console && debug) { console.log('VIRTUAL KEYBOARD: OPEN') }
        } else {
            window.removeEventListener('blur', handleBlur)

            if ("virtualKeyboard" in navigator === false) {
                window.scrollTo(0,0)
            }

            if (console && debug) { console.log('SCROLL RESET') }
            if (console && debug) { console.log('VIRTUAL KEYBOARD: CLOSED') }
        }

        dispatch(setVirtualKeyboardIsOpen(isOpen))
    }, [isOpen])

    useEffect(() => {
        document.addEventListener('focusin', handleFocusIn)
        document.addEventListener('focusout', handleFocusOut)

        if ('virtualKeyboard' in navigator) {
            document.body.classList.add('has-virtual-keyboard')
            dispatch(setHasVirtualKeyboardApi(true))
        }

        setLoaded(true)

        return () => {
            document.removeEventListener('focusin', handleFocusIn)
            document.removeEventListener('focusout', handleFocusOut)
        }
    }, [])

    return isOpen
}
