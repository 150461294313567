import React from 'react'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { useFormContext } from 'react-hook-form'
import FormRow from '../Form/FormRow'
import Input from '../Form/Input'
import Select from '../Form/Select'
import DatePicker from '../Form/DatePicker'
import Checkbox from '../Form/Checkbox'

export default function PromoCodeFields({ form, promoCodes, handleManualUpdate, customerTypes, errors }) {
    const { register } = useFormContext()

    const name = 'promo_codes'

    const field = register(name, {
        validate: () => {
          const temp = promoCodes.map((item) => item.code).sort()
          const duplicates = []

          temp.map((code, index) => {
            if (temp[index+1] && code.toUpperCase() == temp[index+1].toUpperCase()) {
              duplicates.push(code)
            }
          })

          return duplicates.length === 0 || 'Sorry, duplicate promo codes are not allowed'
        },

        validate: () => {
            const empties = []
            promoCodes.map((item) => {
                if (
                  !item.name || !item.code || !item.amount_type || !item.amount ||
                  !item.valid_from || !item.valid_to
                ) {
                  empties.push(item)
                }
            })
            return empties.length === 0 || 'All promo code fields must be filled out'
        },

        validate: () => {
            const emptyCustomerType = []
            promoCodes.map((item) => {
                if (
                  form.pricing_type === 'pricing_by_customer_type' && item.customer_types.length === 0
                ) {
                    emptyCustomerType.push(item)
                }
            })
            return emptyCustomerType.length === 0 || 'You must select at least one customer type'
        }
    })

    const handleAdd = () => {
        handleManualUpdate(name, promoCodes.concat({
            name:                     null,
            code:                     null,
            amount_type:              null,
            amount:                   null,
            customer_types:           [],
            valid_from:               null,
            valid_to:                 null,
            applies_to_ranged_groups: true
        }))
    }

    const handleRemove = index => {
        handleManualUpdate(name, promoCodes.filter((p, i) => i !== index))
    }

    const handlePromoCodeChange = (e, index) => {
        handleManualUpdate(name, promoCodes.map((code, i) => {
            if (i === index) {
                const name = e.target.name.split(':')[0]
                return {
                    ...code,
                    [name]: e.target.value
                }
            } else {
                return code
            }
        }))
    }

    const handleCustomerTypeChange = (customerTypeId, index) => {
        handleManualUpdate(name, promoCodes.map((code, i) => {
            if (i === index) {
                return {
                    ...code,
                    // either remove (filter) the id out of the customer types or add (concat) it
                    customer_types: code.customer_types.includes(customerTypeId) ?
                        code.customer_types.filter(t => t !== customerTypeId)    :
                        code.customer_types.concat(customerTypeId)
                }
            } else {
                return code
            }
        }))
    }

    return (
        <div>
            <div className="drawer-side-action btn btn-link" onClick={handleAdd}>
                + Add Promo Code
            </div>

            {
                promoCodes.map((code, index) => {
                    return (
                        <div className="promo-code-rules" key={`promo_code_${index}`}>
                            <div className="remove" onClick={() => handleRemove(index)}>Remove</div>
                            <FormRow>
                                <DatePicker
                                    cols='3'
                                    name='valid_from'
                                    value={code.valid_from}
                                    handleChange={(t, d) => handlePromoCodeChange({target: { name: 'valid_from', value: moment(d).format('YYYY-MM-DD') }}, index)} />

                                <DatePicker
                                    cols='3'
                                    name='valid_to'
                                    value={code.valid_to}
                                    handleChange={(t, d) => handlePromoCodeChange({target: { name: 'valid_to', value: moment(d).format('YYYY-MM-DD') }}, index)} />

                                <Input
                                    name='name'
                                    handleChange={e => handlePromoCodeChange(e, index)}
                                    value={code.name}
                                    cols='3' />

                                <Input
                                    name='code'
                                    handleChange={e => handlePromoCodeChange(e, index)}
                                    value={code.code}
                                    cols='3' />
                            </FormRow>

                            <FormRow>
                                <Select
                                    name='amount_type'
                                    handleChange={e => handlePromoCodeChange(e, index)}
                                    options={[{ value: 'percent', display: 'Percent' }, { value: 'dollar', display: 'Dollar' }]}
                                    value={code.amount_type}
                                    cols='3' />

                                <Input
                                    name='amount'
                                    type='number'
                                    handleChange={e => handlePromoCodeChange(e, index)}
                                    value={code.amount}
                                    cols='3' />

                                <div className="col-6 inline-toggle">
                                    <Checkbox
                                        name='applies_to_ranged_groups'
                                        inputId={`applies-to-ranged-${index}`}
                                        handleChange={e => handlePromoCodeChange({ target: { name: 'applies_to_ranged_groups', value: !code.applies_to_ranged_groups } }, index)}
                                        value={code.applies_to_ranged_groups} />
                                </div>
                            </FormRow>

                            { form.pricing_type === 'pricing_by_customer_type' && (
                                <div>
                                    <h4 className="bb-1">Allowed Customer Types</h4>

                                    <FormRow>
                                        {
                                            customerTypes.map((customerType, customerTypeIndex) => {
                                                return (
                                                    <div className="col-3 mb-2" key={uuidv4()}>
                                                        <div className="custom-control custom-checkbox mr-3">
                                                            <input
                                                              onChange={() => handleCustomerTypeChange(customerType.id, index)}
                                                              type='checkbox'
                                                              name='customer_type'
                                                              value={customerType.id}
                                                              checked={code.customer_types.includes(customerType.id)}
                                                              className='custom-control-input'
                                                              id={`promo-customer-type-${index}-${customerType.id}`} />

                                                            <label htmlFor={`promo-customer-type-${index}-${customerType.id}`} className='custom-control-label'>{customerType.name}</label>
                                                      </div>
                                                    </div>
                                                )

                                            })
                                        }
                                    </FormRow>
                                </div>
                            ) }



                        </div>
                    )
                })
            }
            {
                errors[name] && (
                    <div className="invalid-feedback d-block">
                        { errors[name].join(', ') }
                    </div>
                )
            }
        </div>
    )
}
