import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from "moment"
import StaffName from '@/features/AdvancedPointOfSale/components/StaffName'
import { approveComp, declineComp } from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'
import { selectTimeZone } from '@/features/Locations/locationSlice'
import { useConfirm } from '@/lib/useConfirmHook'
import { calculateSubtotalAmountCents } from '@/features/AdvancedPointOfSale/lib/Items'
import { numberToCurrency } from '@/lib/Number'

export default function CompCard({ comp=null, item=null, handleClose=() => {} }) {

    const dispatch    = useDispatch()
    const { confirm } = useConfirm()
    const tz          = useSelector(selectTimeZone)

    const priceCents = calculateSubtotalAmountCents(item?.item, item?.config)

    const handleClick = async (e) => {
        if (await confirm(`Are you sure you want to ${e.target.name} this comp?`)) {

            if (e.target.name === 'approve') {
                dispatch(approveComp(comp)).then(data => {
                    if (data.success) { handleClose() }
                })
            } else {
                dispatch(declineComp(comp)).then(data => {
                    if (data.success) { handleClose() }
                })
            }
        }
    }

    return !!comp && (
        <div id={comp.id} className='comp-card p-0'>
            <div className="comp-card-container">
                <div className="d-flex justify-content-between p-2">
                    <div>
                        <h5>{comp.booking_name || comp.tab_name}</h5>
                        <span className='h4'>{item?.item.name}</span>
                    </div>

                    {comp.status === 'pending' &&
                        <div className="d-flex justify-content-between">
                            <button
                                className='comp-card-button btn btn-outline-primary mr-2'
                                name='decline'
                                onClick={handleClick}
                            >Decline</button>
                            <button
                                className='comp-card-button btn btn-primary'
                                name='approve'
                                onClick={handleClick}
                            >Approve</button>
                        </div>
                    }

                </div>
                <div className="p-2">
                    {comp.resource_type_name && (<>{`RESOURCE: ${comp.resource_type_name}`}<br/></>)}
                    REASON: {comp.comp_reason.name}
                    {comp.notes && (<><br/><span className="font-weight-bold">"{comp.notes}"</span></>)}
                    {comp.status === 'approved' &&
                        <div>Approved by {comp.manager?.name} at {moment.tz(comp.approved_at, tz).format('MMM D, YYYY h:mma z')}</div>
                    }
                    {comp.status === 'declined' &&
                        <div>Declined by {comp.manager?.name } at {moment.tz(comp.declined_at, tz).format('MMM D, YYYY h:mma z')}</div>
                    }
                </div>

                <div className="check-card--footer">
                    <StaffName className="staff" text={comp.server?.name} />
                    <span>{ numberToCurrency(priceCents / 100) }</span>
                </div>
            </div>
        </div>
    )
}
