import React from 'react'
import { Provider } from 'react-redux'
import memberStore from '@/stores/memberStore'
import AddCardForm from '@/features/Adyen/AddCardForm'
import { useForm, FormProvider } from 'react-hook-form'

export default function AddCardFormContainer(props) {
    const formMethods = useForm()

    return (
        <Provider store={memberStore}>
            <FormProvider {...formMethods}>
                <AddCardForm {...props} />
            </FormProvider>
        </Provider>
    )
}
