import React from 'react'
import { useSelector } from 'react-redux'
import { selectLoading } from '@/features/Bookings/bookingSlice'

export default function BookingFinalizationLoading() {
    const loading = useSelector(selectLoading)

    return !loading ? null : (
        <div id="global-loading">
            <div id="global-loading--content">
                <div className="spinner">
                    <div className="rect1" />
                    <div className="rect2" />
                    <div className="rect3" />
                    <div className="rect4" />
                    <div className="rect5" />
                    <div className="rect6" />
                </div>
                Finalizing Your Booking...
            </div>
        </div>
    )
}
