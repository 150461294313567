import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { resetDatesAndTimes, fetchAvailableDaysForDuration } from '../../Availability/availabilitySlice'
import { setFormAttribute } from '../scheduleSlice'

export default function DurationOption({ duration }) {

    const dispatch     = useDispatch()
    const { register } = useFormContext()
    const field        = register('duration', { required: true })

    const handleChange = (e) => {
        // update form validation
        field.onChange(e)

        // update redux
        dispatch(setFormAttribute({ name: 'duration', value: duration.duration }))
        dispatch(resetDatesAndTimes())
    }

    return (
        <label id={`booking-duration-${duration.duration}`} className="radio-as-card">

            <input
                ref={field.ref}
                name={field.name}
                type="radio"
                value={duration.duration}
                onChange={handleChange}
            />

            <div className="radio-card">
                { `${duration.duration} Minutes` }
            </div>

        </label>
    )
}
