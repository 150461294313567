import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectAmounts, updateAmount, selectCart } from '@/features/GiftCards/giftCardSlice'
import AmountFields from '@/components/GiftCards/AmountFields'
import RecipientEmail from './RecipientEmail'
import PurchaserName from './PurchaserName'
import CustomNote from './CustomNote'
import ScheduleDate from './ScheduleDate'

export default function PurchaseModalGiftCard({ index=0, singleCard=false, locationTimeZone }) {
    const dispatch = useDispatch()
    const amounts = useSelector(selectAmounts)

    const [amount, setAmount] = useState(0)
    const giftCards = useSelector(selectCart)
    const [sendOnFutureDate, useReceiptEmail] = useMemo(() => [
        giftCards[index].sendOnFutureDate,
        giftCards[index].useReceiptEmail
    ], [giftCards[index]])

    useEffect(() => {
        dispatch(updateAmount({ index, amount }))
    }, [amount])

    return (
        <div className={singleCard ? 'col-12' : 'col-12 col-lg-6'}>
            <div className="card card-normal py-3 px-4 mb-4">

                <h5 className="mb-3">Gift Card #{index + 1}</h5>

                <AmountFields
                    amounts={amounts}
                    inputPrefix='cards.'
                    index={index}
                    handleChange={(amount) => setAmount(amount)}
                />

                <div className="form-group pb-0">
                    <label htmlFor={`email-${index}`}>
                        Recipient's Email:
                        <span className="req">*</span>
                    </label>

                    <RecipientEmail index={index} />
                    <PurchaserName index={index} />
                    <CustomNote index={index} />
                    <ScheduleDate index={index} locationTimeZone={locationTimeZone} />

                    {!sendOnFutureDate && (useReceiptEmail ?
                        <small className="pt-2 form-text text-muted">
                            A gift card confirmation email will be immediately sent to the email address used to make this purchase.
                        </small>
                        :
                        <small className="pt-2 form-text text-muted">
                            A gift card confirmation email will be immediately sent to the above email address.
                            If this is a gift, be sure to use their email address and not your own.
                        </small>
                    )}
                </div>
            </div>
        </div>
    )
}
