import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectTotal,
  setTotal,
  selectSelectedTerm,
} from '@/features/Members/memberSlice'
import { selectLocation } from '@/features/Locations/locationSlice'
import { numberToCurrency } from '@/lib/Number'
import { calculatePrices } from '@/lib/Member'

export default function Totals() {
  const dispatch = useDispatch()
  const total = useSelector(selectTotal)
  const location = useSelector(selectLocation)
  const term = useSelector(selectSelectedTerm)

  const [subtotal, setSubtotal] = useState(0)
  const [taxesAndFees, setTaxesAndFees] = useState(0)

  useEffect(() => {
    if (!!term) {
      const convertedTaxRate     = location.tax_rate/100.0
      const rkdFee               = (parseFloat(location.rkd_reservation_fee || 0) || 0.0)/100.0
      const merchantFee          = (parseFloat(location.merchant_reservation_fee || 0) || 0.0)/100.0
      const additionalOneTimeFee = (parseFloat(term.additional_fees) || 0.0)

      const totals = calculatePrices(convertedTaxRate, rkdFee, merchantFee, additionalOneTimeFee, location.memberships_taxable, term)
      setSubtotal(totals.subtotal)
      setTaxesAndFees(totals.taxesPlusReservationFees)
      dispatch(setTotal(totals.total))
    }
  }, [term])

  return (
    <div className='mx-auto w-100'>
      <div className='d-flex justify-content-between'>
        <div className='text-bold'>Subtotal:</div>
        <div>{numberToCurrency(subtotal || 0)}</div>
      </div>
      <div className='d-flex justify-content-between'>
        <div className='text-bold'>Taxes & Fees:</div>
        <div>{numberToCurrency(taxesAndFees || 0)}</div>
      </div>
      <div className='d-flex justify-content-between border-top'>
          <div className='text-bold'>Total:</div>
          <div>{numberToCurrency(total)}</div>
      </div>
    </div>
  )
}
