import React from 'react'
import MembershipTypeLedger from './MembershipTypeLedger'
import MemberInformationLedger from './MemberInformationLedger'
import MemberTermLedger from './MemberTermLedger'

export default function MemberLedger() {
    return (
        <div className="xbm-running-ledger">
            <MembershipTypeLedger />
            <MemberInformationLedger />
            <MemberTermLedger />
        </div>
    )
}
