import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { selectCart, updateCart } from '@/features/GiftCards/giftCardSlice'

export default function CustomNote({ index }) {
    const dispatch = useDispatch()
    const { register, resetField, formState: { errors } } = useFormContext()
    const giftCards = useSelector(selectCart)
    const [showCustomNote, customNote] = useMemo(() => [
        giftCards[index].showCustomNote,
        giftCards[index].customNote
    ], [giftCards[index]])

    const handleChange = () => {
        if (showCustomNote) {
            resetField(`cards.${index}.customNote`)
            dispatch(updateCart({ index, name: "customNote", value: "" }))
            dispatch(updateCart({ index, name: "showCustomNote", value: false }))
        } else {
            dispatch(updateCart({ index, name: "showCustomNote", value: true }))
        }
    }

    const noteField = register(`cards.${index}.customNote`, {
        required: showCustomNote ? 'Please Enter Custom Note.' : false,
    })

    return <>
        <div className="custom-control custom-checkbox my-3">
            <input
                id={`set-custom-note-${index}`}
                type="checkbox"
                className="custom-control-input"
                checked={showCustomNote}
                onChange={handleChange}
            />
            <label
                htmlFor={`set-custom-note-${index}`}
                className="custom-control-label"
            >Add a custom Note?
            </label>
        </div>

        {showCustomNote && <>
            <textarea
                {...noteField}
                id={`custom-note-${index}`}
                className={`form-control ${errors?.cards?.[index]?.customNote ? 'is-invalid' : ''}`}
                placeholder="Max 240 Characters"
                rows="3"
                maxLength="240"
                value={customNote}
                onChange={(e) => {
                    noteField.onChange(e)
                    dispatch(updateCart({ index, name: "customNote", value: e.target.value }))
                }}
            />
            {
                errors?.cards?.[index]?.customNote &&
                    <div className="invalid-feedback d-block mx-2">{ errors.cards[index].customNote.message }</div>
            }
        </>}
    </>
}
