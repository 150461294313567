import React, { useState, useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import {
    finalizeBooking,
    selectHeld,
    selectBooking,
    setBookingFormValue,
    setStep,
    selectPaymentMethod,
    selectPaymentType,
    setPaymentMethod,
} from '../../bookingSlice'

import { selectPaymentTypes } from '@/features/Packages/packageSlice'
import { selectTotal, selectDeposit, selectLoading as selectPricingLoading } from '@/features/Pricing/pricingSlice'

import BookingFinalizationLoading from './BookingFinalizationLoading'
import BookingModalFooter from '../BookingModalFooter'
import BookingModalLedger from '../Ledger/BookingModalLedger'
import MobileBookingModalLedger from '../Ledger/MobileBookingModalLedger'
import LedgerLocation from '../Ledger/LedgerLocation'
import LedgerPartySize from '../Ledger/LedgerPartySize'
import LedgerDuration from '../Ledger/LedgerDuration'
import LedgerDateTime from '../Ledger/LedgerDateTime'
import LedgerCustomerTypes from '../Ledger/LedgerCustomerTypes'
import PaymentOptions from './PaymentOptions'
import PaymentMethods from './PaymentMethods'
import PromoCode from './PromoCode'
import Input from "@/components/Form/Input"
import LedgerReservationInformation from '../Ledger/LedgerReservationInformation'
import { errorsFor } from '@/components/Form/ErrorsHelper'
import { numberToCurrency } from '@/lib/Number'
import { gaSendPageView } from '@/lib/GoogleAnalytics'
import { debug } from '@/lib/Debug'

export default function Step6({ modalStyles, environment }) {

    const formMethods         = useForm()
    const dispatch            = useDispatch()
    const pricingLoading      = useSelector(selectPricingLoading)
    const booking             = useSelector(selectBooking)
    const paymentTypes        = useSelector(selectPaymentTypes)
    const paymentType         = useSelector(selectPaymentType)
    const paymentMethod       = useSelector(selectPaymentMethod)
    const bookingHeld         = useSelector(selectHeld)
    const calculatedTotal     = useSelector(selectTotal)
    const deposit             = useSelector(selectDeposit)
    const total               = paymentTypes.authorize_card_only ? 0 : calculatedTotal

    const [errors, setErrors]               = useState({})
    const [adyenCheckout, setAdyenCheckout] = useState(null)

    const updateFormField = (e) => {
        dispatch(setBookingFormValue({ name: e.target.name, value: e.target.value }))
    }

    const manualUpdateFormField = (name, value) => {
        dispatch(setBookingFormValue({ name: name, value: value }))
    }

    /**
     * Loop through form errors and build up our own errors
     * object that gets passed down to all of the components
     */
    const onError = (formErrors) => {
        setErrors(errorsFor(formErrors))
    }

    const handleSubmit = () => {
        if (console && debug) { console.log('Handle submit....') }

        // here, if we have an adyenCheckout object, it should mean adyen has been configured and ready for checkout.
        // if that exists, we have to subit THAT first before we can finalize the booking below.
        if (adyenCheckout && /^(credit_card|multiple)$/i.test(paymentMethod)) {
            // adyen - finalize all that first before finalizing booking...
            if (console && debug) { console.log('Attempting to submit Adyen payment...') }
            // the callbacks/event listeners for the result of this is found in AdyenCreditCard.jsx in the "config" object
            // we pass to the AdyenCheckout Promise.
            adyenCheckout.submit()
        } else {
            if (console && debug) { console.log('Attempting to submit Paysafe or GC payment...') }
            dispatch(finalizeBooking())
        }
    }

    useEffect(() => {
        gaSendPageView('Reservation Payment', '/step/6')
    }, [])

    useEffect(() => {
        if (calculatedTotal === 0) {
            dispatch(setPaymentMethod('none'))
        }
    }, [calculatedTotal])

    return (
        <FormProvider {...formMethods}>
            <BookingFinalizationLoading />

            <div className="xbm-step-content">
                <div className="xbm-step-content-main">
                    <div className="xbm-checkout-ledger d-block d-md-none">
                        <LedgerLocation />
                        <LedgerPartySize />
                        <LedgerCustomerTypes />
                        <LedgerDuration />
                        <LedgerDateTime />
                        <LedgerReservationInformation />
                    </div>

                    <div className="xbm-step-question mt-3">
                        <h2 className="mb-4">Payment Information</h2>
                    </div>

                    <PaymentOptions
                        booking={booking}
                        errors={errors}
                        handleChange={updateFormField}
                    />

                    <h4 className="payment-total text-center my-4 d-block">
                        Total <span>{numberToCurrency(paymentType === 'deposit_only' && !pricingLoading ? deposit : total)}</span>
                    </h4>

                    <div className="d-none">
                        <Input
                            type="hidden"
                            autoComplete="off"
                            withWrapper={false}
                            hideLabel={true}
                            value={String(booking.paymentReady)}
                            errors={errors}
                            validation={{ 'paymentReady': { required: true } }}
                            handleChange={updateFormField}
                        />
                    </div>

                    { booking.members.length === 0 && <PromoCode /> }

                    <MobileBookingModalLedger />

                    {
                        calculatedTotal > 0 && (
                            <PaymentMethods
                                booking={booking}
                                errors={errors}
                                environment={environment}
                                showOptionToSaveCard={paymentType === 'deposit_only' && !paymentTypes.authorize_card_only}
                                handleOnError={onError}
                                handleChange={updateFormField}
                                handleManualChange={manualUpdateFormField}
                                onNext={formMethods.handleSubmit(handleSubmit, onError)}
                                setAdyenCheckout={setAdyenCheckout}
                                adyenCheckout={adyenCheckout}
                            />
                        )
                    }
                </div>

                <BookingModalLedger />
            </div>

            <BookingModalFooter
                modalStyles={modalStyles}
                nextText={paymentMethod === 'none' ? "Finish" : "Finish & Pay"}
                disableNext={!bookingHeld}
                useAdyen={!!adyenCheckout}
                onBack={() => dispatch(setStep('5'))}
                onNext={formMethods.handleSubmit(handleSubmit, onError)}
            />

            <style children={ 'xbm-header + .xbm-mobile-progress { display: none !important; }' } />
        </FormProvider>
    )
}
