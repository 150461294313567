import React from 'react'
import { useDispatch } from 'react-redux'
import { configureModal, openModal, resetMultipleItemsMode } from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

export default function NewTabButton({ id=null, className=null, disabled=false }) {

    const dispatch = useDispatch()

    const handleClick = () => {
        dispatch(resetMultipleItemsMode())
        dispatch(configureModal({ modal: 'moveItemsBetweenTabs', config: { fromTabId: null, toTabId: null } })) // reset
        dispatch(openModal('createNewTab'))
    }

    return (
        <button
            id={id}
            type='button'
            className={className}
            children='Create New Tab'
            disabled={disabled}
            onClick={handleClick}
        />
    )
}
