import React, { useEffect } from "react"
import { useDispatch } from 'react-redux'
import { addAlert } from '@/features/Notifications/notificationSlice'

export default function CustomerWaiversSuccess({ success_url=null, handleWaiverFormReset }) {
    const dispatch = useDispatch()

    useEffect(() => {
        if (Boolean(success_url)) {
            window.location.href = success_url
        } else {
            // note: this is a failsafe
            // as long as a success url has been passed in
            // from the rails side, this should never execute
            dispatch(addAlert({ type: 'success', text: 'Thank You!<br /><br />Your Waiver Has Been Completed!' }))
            handleWaiverFormReset()
        }
    }, [])

    return null
}
