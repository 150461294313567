import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Checkbox from '@/components/Form/Checkbox'
import FormRow from '@/components/Form/FormRow'
import Select from '@/components/Form/Select'
import MembershipCustomerType from '@/components/Packages/members/MembershipCustomerType'
//import Help from '@/features/Help/Help'
import { useConfirm } from '@/lib/useConfirmHook'
import { debug } from '@/lib/Debug'

export default function MembershipPricing({
    form,
    errors,
    membershipTypesUrl,
    customerTypes,
    selectedCustomerTypes,
    isMemberCustomerTypeDisplayed,
    isUniformPriceType,
    enabled=false,
    handleUpdate,
    handleManualUpdate,
}) {
    const { confirm } = useConfirm()

    const [membershipTypes, setMembershipTypes]                             = useState([])
    const [shouldRestrictMembershipTypes, setShouldRestrictMembershipTypes] = useState(form.membership_type_id_restrictions.length > 0)

    const handleChangeMemberBenefits = async (e) => {
        if (!form.allow_member_benefits) {
            e.stopPropagation()

            if (await confirm(
                'Enabling member benefits on this package will allow active members to use their membership benefits when making a reservation for this package.' +
                (form.pricing_type === 'pricing_by_customer_type' ? '<br /><br />Since this package uses Customer Type pricing, you will also need to select the default customer type that membership discount benefits to be applied to.' : '')
            )) {
                handleManualUpdate('allow_member_benefits', !form.allow_member_benefits)
            }
        } else {
            handleManualUpdate('allow_member_benefits', !form.allow_member_benefits)
        }
    }

    const handleChangeShowPackageOnMemberDashboard = async (e) => {
        e.stopPropagation()

        switch(true) {
            case form.show_on_member_dashboard :
                if (await confirm('Prevent this package from displaying on the membership dashboard?')) {
                    handleManualUpdate('show_on_member_dashboard', !form.show_on_member_dashboard)
                }
                break

            case !form.show_on_member_dashboard && form.allow_member_benefits :
                if (await confirm('Display this package within the <span class="nowrap">"Packages accepting membership benefits"</span><br />section of the membership dashboard?')) {
                    handleManualUpdate('show_on_member_dashboard', !form.show_on_member_dashboard)
                }
                break

            case !form.show_on_member_dashboard && !form.allow_member_benefits :
                if (await confirm('Display this package within the <span class="nowrap">"Packages not accepting membership benefits"</span><br />section of the membership dashboard?')) {
                    handleManualUpdate('show_on_member_dashboard', !form.show_on_member_dashboard)
                }
                break
        }
    }

    const handleMemberRestrictionsUpdate = (e) => {
      const values = Array.from(e.target.selectedOptions, option => parseInt(option.value), 10)
      handleManualUpdate('membership_type_id_restrictions', values)
    }

    // fetch a list of membership types either
    // on load or after the toggle is checked
    useEffect(() => {
        if ((shouldRestrictMembershipTypes || form.membership_type_id_restrictions.length > 0) && membershipTypes.length === 0) {
            axios.get(membershipTypesUrl)
                 .then(({ data }) => {
                     setMembershipTypes(data.map((mt) => ({ value: mt.id, display: mt.name })))
                 })
                 .catch(({ error }) => {
                    if (console && debug) { console.log(error) }
                 })
        }
    }, [form, shouldRestrictMembershipTypes])

    return enabled && (
        <div className={`package-membership-pricing ${isUniformPriceType ? 'mt-n4' : ''}`}>
            <h3 className="d-flex justify-content-between align-items-center">
                <span className='d-flex'>
                    <span>Memberships</span>
                    { /* <Help articleId='package-memberships' /> */ }
                </span>

                <Checkbox
                    className='font-weight-normal text-right mr-4'
                    asFormGroup={false}
                    label="Show On Member Dashboard"
                    name='show_on_member_dashboard'
                    value={form.show_on_member_dashboard}
                    tooltip={(() => {
                        switch(true) {
                            case !form.show_on_member_dashboard :
                                return 'This package will not display on the member dashboard'

                            case form.show_on_member_dashboard && form.allow_member_benefits :
                                return 'This package will show in the "Packages accepting membership benefits" section of the membership dashboard'

                            case form.show_on_member_dashboard && !form.allow_member_benefits :
                                return 'This package will show in the "Packages not accepting membership benefits" section of the membership dashboard'
                        }
                    })()}
                    handleChange={handleChangeShowPackageOnMemberDashboard}
                />
            </h3>
            <FormRow className="px-1">
                <Checkbox
                    cols='4'
                    label="Allow Member Benefits"
                    name='allow_member_benefits'
                    value={form.allow_member_benefits}
                    tooltip="The selected customer type or uniform pricing will determine which per person price will be used to offer the membership discount when booking."
                    handleChange={handleChangeMemberBenefits}
                />

                {
                    form.allow_member_benefits && <>
                        <Checkbox
                            cols='4'
                            label="Allow Members Only"
                            name='allow_members_only'
                            value={form.allow_members_only}
                            handleChange={() => handleManualUpdate('allow_members_only', !form.allow_members_only)}
                        />

                        <Checkbox
                            cols='4'
                            label="Restrict Membership Types"
                            value={shouldRestrictMembershipTypes}
                            handleChange={() => {
                                if (shouldRestrictMembershipTypes) {
                                    setShouldRestrictMembershipTypes(false)
                                    handleManualUpdate('membership_type_id_restrictions', [])
                                } else {
                                    setShouldRestrictMembershipTypes(true)
                                }
                            }}
                        />
                    </>
                }
            </FormRow>

            <FormRow className="px-1">
                {
                    isMemberCustomerTypeDisplayed && (
                            <MembershipCustomerType
                                cols='4'
                                selectedTypes={selectedCustomerTypes}
                                allTypes={customerTypes}
                                form={form}
                                errors={errors}
                                handleUpdate={handleUpdate}
                            />
                    )
                }

                {
                    shouldRestrictMembershipTypes && membershipTypes.length > 0 && (
                        <div className={`col-8 ${isMemberCustomerTypeDisplayed ? '' : 'offset-4'} mb-n4`}>
                            <Select
                                label='Allowed Membership Types'
                                name='membership_type_id_restrictions'
                                value={form.membership_type_id_restrictions}
                                options={membershipTypes}
                                cols='12'
                                size={membershipTypes.length > 4 ? 8 : 4}
                                multiple
                                handleChange={handleMemberRestrictionsUpdate}
                            />
                        </div>
                    )
                }
            </FormRow>
        </div>
    )
}
