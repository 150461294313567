import React from 'react'
import Select from '@/components/Form/Select'

export default function MembershipCustomerType({
    form,
    errors,
    cols='12',
    selectedTypes,
    allTypes,
    handleUpdate,
}) {
    // only show selected customer types?
    const typesForSelect = allTypes.filter(t => selectedTypes.includes(t.id))
                                   .map(t => ({ display: t.name, value: t.id }))

    return (
        <Select
            label='Default Customer Type For Members'
            cols={cols}
            options={typesForSelect}
            value={form.membership_customer_type_id}
            errors={errors}
            validation={{ 'membership_customer_type_id': {
                validate: (v) => Number.parseFloat(v) >= 0 || 'A customer type must be selected'
            }}}
            handleChange={e => handleUpdate({
                target: {
                    name: 'membership_customer_type_id',
                    value: parseInt(e.target.value)
                }
            })}
        />
    )
}
