import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux'
import { selectLoading, selectTimes } from '../../../Availability/availabilitySlice'
import Time from './Time'
import moment from "moment-timezone";
import { selectTimeZone } from "../../../Locations/locationSlice";
import { selectPackage } from '../../../Packages/packageSlice'

export default function TimesContainer() {

    const loading          = useSelector(selectLoading)
    const times            = useSelector(selectTimes)
    const selectedTimeZone = useSelector(selectTimeZone)
    const pkg              = useSelector(selectPackage)

    const [filteredTimes, setFilteredTimes] = useState([])

    const filterTimes = () => {
        if (!selectedTimeZone || !times ||!Array.isArray(times)) { return [] }
        const now = moment().tz(selectedTimeZone)
        return times.filter((time) => moment(time[0]).tz(selectedTimeZone).isSameOrAfter(now))
    }

    // filter times whenever they're updated
    useEffect(() => {
        setFilteredTimes(filterTimes())
    }, [times, selectedTimeZone])

    return (
        <div className="container form-container">
            <div className="form-row xbm-time-select">
                { filteredTimes.map((time, index) => <Time key={index} time={time} />) }

                { !loading && (times.length === 0 || (times.length > 0 && filteredTimes.length === 0)) &&
                    <>
                    {pkg.override_no_times_available_message ?
                        <div dangerouslySetInnerHTML={{ __html: pkg.custom_no_times_available_message }} className="w-100 text-center"/>
                        :  <p className="w-100 text-center mt-5 pt-5 h4">No times available</p>
                    }
                    </>
                }
            </div>
        </div>
    )
}
