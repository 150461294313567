import React from 'react'
import { useSelector } from 'react-redux'
import { selectParentBooking, selectChildBookings } from '@/features/ParentBooking/parentBookingSlice'
import CustomerBadges from '@/features/Customers/CustomerBadges'

export default function ParentBookingGroupDetailsParticipantsList({
    checkedInCount=0,
    participantCount=0,
    immutableEvent=false,
    onCustomerCheckIn,
    onRemoveParticipant
}) {

    const parentBooking = useSelector(selectParentBooking)
    const childBookings = useSelector(selectChildBookings)

    return <>
        <h5 className='d-inline-block mb-0 w-100 border-bottom pb-2'>Participants</h5>

        <div className="d-flex justify-content-between align-items-center mb-3 border-bottom py-2">
            <span className={`badge w-100 badge-${checkedInCount == participantCount ? 'success' : (checkedInCount === 0 ? 'danger' : 'warning')}`}>
                {checkedInCount} of {participantCount} Checked In
            </span>
            {
                participantCount > 0 && (
                    <a
                        className="btn btn-link min-h-auto py-1 px-2 ml-3"
                        href={`/bookings/${parentBooking.id}/participants`}
                        title="Click to download a list of all participants as a CSV file"
                    >
                        <i className="fas fa-download" />
                    </a>
                )
            }
        </div>

        {
            childBookings.map((childBooking) => (
                <span key={`child_booking-${childBooking.id}`}>
                    {
                        childBooking?.customers?.map((customer) => (
                            <div className='row' key={`child_booking_customer-${customer.id}`}>
                                <div className='col-10'>
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            type="checkbox"
                                            id={`check-${customer.id}`}
                                            className="custom-control-input cursor-pointer"
                                            title={`Click to check this participant ${childBooking.checked_in.includes(customer.id) ? 'out' : 'in'}`}
                                            checked={childBooking.checked_in.includes(customer.id) || false}
                                            disabled={immutableEvent}
                                            onChange={(e) => onCustomerCheckIn(e, childBooking, customer)}
                                        />
                                        <label className="custom-control-label cursor-pointer" htmlFor={`check-${customer.id}`}>
                                            {customer.name}
                                        </label>
                                    </div>
                                </div>

                                <div className='col-2 text-right'>
                                    {
                                        !immutableEvent && (
                                            <button
                                                type='button'
                                                className="btn btn-link-danger py-1 px-2 min-h-auto"
                                                title='Click to remove this participant'
                                                onClick={() => onRemoveParticipant(customer.id, childBooking.id)}
                                            >
                                                <i className='fas fa-trash-alt' />
                                            </button>
                                        )
                                    }
                                </div>

                                <div className="col-11 offset-1 pt-1 pb-3">
                                    <CustomerBadges
                                       customer={customer}
                                       waiversRequired={childBooking.waivers_required}
                                       bookingActiveWaiver={childBooking.waiver_id}
                                       compact={true}
                                    />
                                </div>
                            </div>
                        ))
                    }
                </span>
            ))
        }
    </>
}
