import React, { useRef, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useForm, FormProvider } from 'react-hook-form'
import { addAlert } from '@/features/Notifications/notificationSlice'
import { errorsFor } from '../Form/ErrorsHelper'
import { durationsPrices } from '../../lib/Package'
import Input from '../Form/Input'
import FormRow from '../Form/FormRow'
import Select from '../Form/Select'
import Pricing from '../../lib/Pricing'

export default function GroupForm({
  id='group-form',
  group=null,
  groups,
  pricing,
  durations,
  selectedCustomerTypes,
  pricingType,
  isSpecialEvent,
  handleChange,
  handleManualUpdate,
}) {

    const dispatch                              = useDispatch()
    const [errors, setErrors]                   = useState({})
    const [groupIndex, setGroupIndex]           = useState(null)
    const [editMode,  setEditMode]              = useState(false)
    const [updatePriceGrid, setUpdatePriceGrid] = useState(false)

    const existingMinMax = useRef(null)

    // group form attributes and validation
    const formMethods         = useForm()
    const min                 = formMethods.watch('min', group?.min)
    const max                 = formMethods.watch('max', group?.max)
    const priceType           = formMethods.watch('price_type', group?.price_type)
    const autoGratuity        = formMethods.watch('auto_gratuity', group?.auto_gratuity)
    const depositAmount       = formMethods.watch('deposit_amount', group?.deposit_amount)
    const depositType         = formMethods.watch('deposit_type', group?.deposit_type)
    const internal            = formMethods.watch('internal', group?.internal || false)
    const participantsPerLane = formMethods.watch('participants_per_lane', group?.participants_per_lane)

    const depositTypeOptions = [
        { display: 'Percentage of Total',    value: 'percent' },
        { display: 'Amount Per Participant', value: 'dollar'  },
        { display: 'Flat Fee',               value: 'flat'    }
    ]

    /**
     * Loop through form errors and build up our own errors
     * object that gets passed down to all of the components
     */
    const onError = (formErrors) => {
      setErrors(errorsFor(formErrors))
    }

    /**
     * Handle what happens when a user saves a group. This will add the group record to 'form.groups'
     * as well as setting that we need to now send the price, which will happen whenever the group
     * param is updated (see use effect above). Whenever we create a group there is nothing else that
     * should be updating the group param at that second in time.
     */
    const handleAdd = () => {
        // when a group is added, we set that we need to send the price as well. the reason we don't send the price
        // automatically is because it will fire off instantly and overwrite the state of the above groups call.
        // things are happening too quickly async so they need to be controlled. this is only an issue because we're
        // modifying the same state variable 'form' in our RecordDrawer component.
        setUpdatePriceGrid(true)

        handleManualUpdate('groups', groups.concat({
            min:                   min,
            max:                   max,
            price_type:            priceType,
            deposit_amount:        depositAmount,
            deposit_type:          depositType,
            auto_gratuity:         autoGratuity,
            internal:              internal,
            participants_per_lane: participantsPerLane
        }))
    }

    /**
     * Handle what happens when a user saves a group. This will add the group record to 'form.groups'
     * as well as setting that we need to now send the price, which will happen whenever the group
     * param is updated (see use effect above). Whenever we create a group there is nothing else that
     * should be updating the group param at that second in time.
     */
    const handleUpdate = () => {
        try {
            if (typeof groupIndex !== 'number') {
                return console.error('Could not update the price group.', groupIndex)
            }

            let updatedGroups = [ ...groups ]

            // we store the existing min/max combination so that we can update
            // the price group if the admin has edited the group min/max values
            if (existingMinMax.current === null) {
                existingMinMax.current = [groups[groupIndex].min, groups[groupIndex].max]
            }

            updatedGroups[groupIndex] = {
                min:                   min,
                max:                   max,
                price_type:            priceType,
                deposit_amount:        depositAmount,
                deposit_type:          depositType,
                auto_gratuity:         autoGratuity,
                internal:              internal,
                participants_per_lane: participantsPerLane
            }

            // when a group is added, we set that we need to send the price as well. the reason we don't send the price
            // automatically is because it will fire off instantly and overwrite the state of the above groups call.
            // things are happening too quickly async so they need to be controlled. this is only an issue because we're
            // modifying the same state variable 'form' in our RecordDrawer component.
            setUpdatePriceGrid(true)

            handleManualUpdate('groups', updatedGroups)
            handleChange()
        } catch(e) {
            console.error(e)
            dispatch(addAlert({ type: 'error', text: 'Could not update the group price!' }))
        }
    }

    // reset registered form values and validation state
    const resetForm = () => {
        formMethods.setValue('min',                   null,         { shouldValidate: true })
        formMethods.setValue('max',                   null,         { shouldValidate: true })
        formMethods.setValue('price_type',            'per_person', { shouldValidate: true })
        formMethods.setValue('deposit_type',          'percent',    { shouldValidate: true })
        formMethods.setValue('deposit_amount',        '',           { shouldValidate: true })
        formMethods.setValue('internal',              false,        { shouldValidate: true })
        formMethods.setValue('participants_per_lane', null,         { shouldValidate: true })
        formMethods.setValue('auto_gratuity',         null,         { shouldValidate: true })
        setErrors({})
    }

    /**
     * Modify the price grid, inserting a price grid entry for this group that was just created, including
     * a pricing block for each customer type for each duration that was created elsewhere in the package form.
     */
    const modifyPriceGrid = () => {
        try {
            const isIndividualPricing = /^per_person$/i.test(priceType)

            if (editMode) {
                const updatedPricing = [...pricing]
                let pricingIndex     = null

                // find the matching pricing group's index in the array of pricing groups
                if (pricingIndex === null && Array.isArray(existingMinMax.current)) {
                    updatedPricing.forEach((p, index) => {
                          if (p.groupMin == existingMinMax.current[0] && p.groupMax == existingMinMax.current[1]) {
                                pricingIndex = index
                          }
                    })
                }

                // reset this temp variable
                existingMinMax.current = null

                updatedPricing[pricingIndex] = {
                    isGroup: true,
                    groupMin: min,
                    groupMax: max,
                    participantsPerLane: participantsPerLane,
                    individualPricing: isIndividualPricing,
                    autoGratuity: autoGratuity,
                    prices: updatedPricing[pricingIndex].prices,
                }

                handleManualUpdate('pricing', updatedPricing)
            } else {
                // new prices array that will be set
                let newPrices = []

                // if durations are empty, it will create empty arrays and mess up the pricing grid. simply leaving the
                // prices as an empty array when there's no durations solves this. we might be able to do a check in
                // the durationsPrices method itself to check for empty durations, but this check here solves it too
                if (durations.length) {
                    // for groups (non individual) we can just set the duration prices directly since they're not
                    // nested under customer types. for individual, we have to do the same durations, but nested
                    // under each customer type
                    if (isIndividualPricing && pricingType === Pricing.BY_CUSTOMER_TYPE) {
                        newPrices = selectedCustomerTypes.map(t => durationsPrices(durations, t))
                    } else {
                        newPrices = durationsPrices(durations, null)
                    }
                }

                handleManualUpdate('pricing', pricing.concat({
                    isGroup: true,
                    groupMin: min,
                    groupMax: max,
                    participantsPerLane: participantsPerLane,
                    individualPricing: isIndividualPricing,
                    autoGratuity: autoGratuity,
                    prices: newPrices
                }))
            }

            resetForm()
        } catch(e) {
            dispatch(addAlert({ type: 'error', text: 'Could not update the group price!' }))
            console.error(e)
        }
    }

    // whenever our groups have been updated, we check to see if we need to send the price, based on if the user
    // just created a new group... whenever groups have been updated and we're execting a price push, we fire
    // off the modify price grid routine. this ensures that the groups have changed in state and something else
    // can be pushed up to the form state.
    useEffect(() => {
        if (updatePriceGrid) {
            modifyPriceGrid()
        }
    }, [groups])

    /**
     * Set the usage mode upon init
     */
    useEffect(() => {
        if (Boolean(group)) {
            setEditMode(true)
            setGroupIndex(group?.groupIndex)
        }
    }, [group])

    return (
        <FormProvider {...formMethods}>
            <div id={id} className="groups-form">

                <FormRow>
                    <div className="col-5">
                        <FormRow>
                            <Input
                                cols='6'
                                type='number'
                                min='1'
                                value={min}
                                placeholder='Min. Participants'
                                errors={errors}
                                validation={{ 'min': { required: true }}}
                                label="Min. Participants"
                            />

                            <Input
                                cols='6'
                                type='number'
                                min='1'
                                value={max}
                                placeholder='Max. Participants'
                                errors={errors}
                                validation={{ 'max': { required: true }}}
                                label="Max. Participants"
                            />
                        </FormRow>
                    </div>

                    {
                        !isSpecialEvent && (
                            <Input
                                label='Resource Occupancy Override'
                                cols='4'
                                type='number'
                                min='1'
                                max={max}
                                step='1'
                                value={participantsPerLane}
                                name="override_participants_per_lane"
                                placeholder='(Optional)'
                                errors={errors}
                                req={false}
                                validation={{ 'participants_per_lane': { required: false }}}
                            />
                        )
                    }

                    <div className="col-3 text-right inline-toggle">
                        <div className="custom-control custom-checkbox">
                            <input
                                id={`${id}-internal`}
                                className='custom-control-input'
                                type='checkbox'
                                value={true}
                                checked={!!internal}
                                {...formMethods.register('internal', { required: false })}
                            />

                            <label htmlFor={`${id}-internal`} className='custom-control-label'>
                                For Internal Use Only
                            </label>
                        </div>
                    </div>
                </FormRow>

                <FormRow>
                    <div className="col-5">
                        <FormRow>
                            <Select
                                cols='6'
                                value={priceType}
                                options={[
                                  { display: 'Per Person Price', value: 'per_person' },
                                  { display: 'Group Price', value: 'group_price' }
                                ]}
                                errors={errors}
                                validation={{ 'price_type': { required: true } }}
                            />

                            <Select
                                cols='6'
                                value={depositType}
                                options={depositTypeOptions}
                                errors={errors}
                                validation={{ 'deposit_type': { required: true } }}
                            />
                        </FormRow>
                    </div>

                    <Input
                        cols='4'
                        type='number'
                        min='1'
                        value={depositAmount}
                        placeholder={depositTypeOptions.filter(o => o.value == depositType)?.[0]?.display || 'Deposit'}
                        errors={errors}
                        validation={{ 'deposit_amount': { required: true }}}
                        disabled={!depositType}
                        prepend={depositType !== null && depositType !== 'percent'}
                        append={depositType !== null && depositType === 'percent'}
                    >
                        <div className="input-group-prepend">
                            <span className="input-group-text">$</span>
                        </div>
                        <div className="input-group-append">
                            <span className="input-group-text">%</span>
                        </div>
                    </Input>

                    <Input
                        cols='3'
                        type='number'
                        min='1'
                        name='auto_gratuity'
                        value={autoGratuity}
                        placeholder='(Optional)'
                        label="Auto-Gratuity"
                        append={true}
                        req={false}
                        errors={errors}
                        validation={{ 'auto_gratuity': { required: false }}}
                    >
                        <div className="input-group-append">
                            <span className="input-group-text">%</span>
                        </div>
                    </Input>

                </FormRow>

                <FormRow className="button-row">
                    <div className="col ml-auto align-self-center text-right">
                        {
                            editMode === false ? (
                                <div className="btn btn-primary btn-sm mb-n2" onClick={formMethods.handleSubmit(handleAdd, onError)}>
                                    <i className="far fa-plus mr-2" /> Add Group
                                </div>
                            ) : (
                                <div className="btn btn-primary btn-sm mb-n2" onClick={formMethods.handleSubmit(handleUpdate, onError)}>
                                    <i className="fas fa-save mr-2" /> Update Group
                                </div>
                            )
                        }
                    </div>
                </FormRow>
            </div>
        </FormProvider>
    )
}
