import React from 'react'
import { useDispatch } from 'react-redux'
import { setManualBufferOpen } from './calendarSlice'

export default function ManualBuffer() {

    const dispatch = useDispatch()

    const handleClick = () => {
        dispatch(setManualBufferOpen(true))
    }

    return (
        <div id='add-buffers'>
            <button className="btn btn-link bold" onClick={handleClick}>
                <i className="far fa-plus-square h5 mb-n1 d-lg-none" />

                <i className="far fa-plus-square d-none d-lg-inline h5 mt-2" />
                <span className="d-none d-lg-inline ml-2">BUFFERS</span>
            </button>
        </div>
    )
}
