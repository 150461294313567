const scrollHinting = {
    isScrolling: false,

    init: (options={}) => {
        options = {
            ...{
                debug: false,
                cleanup: false,
                scrollTopMinimum: 25,
                className: {
                    target: '.scrollhint--inner',
                    top: 'scrollhint--at-top',
                    bottom: 'scrollhint--at-bottom',
                }
            },
            ...options,
        }

        /*
         * setup global listener event that sub-components can
         * trigger when they mount to search/research for targets
         *
         * EXAMPLE USAGE:
         *     useEffect(() => {
         *         scrollHinting.search()
         *     }, [])
         */
        if (!!window.__scrollhintInitEvent === false) {
            document.addEventListener('scrollhint-init', () => scrollHinting.init(options), false)
            window.__scrollhintInitEvent = new Event('scrollhint-init')
        }

        const targets = document.querySelectorAll(options.className.target)

        const resizeObserver = new ResizeObserver((elements) => {
            elements.forEach((element) => scrollHinting.events.handleOverflowChange(element.target, options))
        })

        targets.forEach((target) => {
            if (options.cleanup === false)
            {
                // detect and handle scrolling events
                target.addEventListener('scroll', (e) => scrollHinting.events.handleScrollChange(e, options), false)

                // detect changes if the viewport resizes and causes our elements to resize
                resizeObserver.observe(target)

                // try and detect if we should initially show the bottom scroll indicator
                // NOTE we need a short delay to wait for any components to mount
                window.setTimeout(() => scrollHinting.events.handleOverflowChange(target, options), 2500)
            }
            else
            {
                resizeObserver.unobserve(target)

                target.removeEventListener('scroll', (e) => scrollHinting.events.handleScrollChange(e, options), false)
            }
        })

        scrollHinting.logger(targets, options)
    },
    search: () => {
        document.dispatchEvent(window.__scrollhintInitEvent)
    },
    cleanup: (options={}) => {
        document.removeEventListener('scrollhint-init', () => scrollHinting.init(options), false)
        delete window.__scrollhintInitEvent
        scrollHinting.init({ ...options, cleanup: true })
    },
    logger: (targets=[], options={}) => {
        if (console && options?.debug && !options?.cleanup) {
            console.log(`SCROLL HINTING: ${targets.length} Target${targets.length === 1 ? '' : 's'} Found`)
        }
    },
    events: {
        handleScrollChange: (e, options) => {
            const target = e?.target || e

            if (!scrollHinting.isScrolling) {
                window.requestAnimationFrame(() => {
                    if (target.scrollTop <= options.scrollTopMinimum) {
                        target.parentElement.classList.add(options.className.top)
                        target.parentElement.classList.remove(options.className.bottom)
                    }

                    if (target.scrollTop > options.scrollTopMinimum) {
                        target.parentElement.classList.remove(options.className.top)
                        target.parentElement.classList.add(options.className.bottom)
                    }

                    scrollHinting.isScrolling = false
                })

                scrollHinting.isScrolling = true
            }
        },
        handleOverflowChange: (e, options) => {
            const target = e?.target || e

            // can the element can be scrolled?
            if (getComputedStyle(target).overflowY === 'auto' && target.scrollTop === 0)
            {
                // is it fully scrolled?
                if (!(Math.round(target.scrollTop + target.clientHeight) === target.scrollHeight)) {
                    target.parentElement.classList.add(options.className.top)
                } else {
                    target.parentElement.classList.remove(options.className.top)
                }
            }
        },
    },
}

export default scrollHinting
