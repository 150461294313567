import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setValid } from '../ManagerCodes/managerCodeSlice'
import { selectOrder, setDiscountFields, selectDiscount } from './productSlice'

export default function DiscountAmountForm({ onCancel=() => {} }) {

    const dispatch = useDispatch()
    const discount = useSelector(selectDiscount)
    const order    = useSelector(selectOrder)

    const [amount, setAmount] = useState('')
    const [notes, setNotes]   = useState('')

    const apply = () => {
        dispatch(setDiscountFields({
            discount: amount || 0,
            notes: notes
        }))
    }

    const cancel = () => {
        setNotes('')
        setAmount('')
        dispatch(setValid(false))
        dispatch(setDiscountFields({
            discount: 0,
            notes: notes
        }))
        onCancel()
    }

    return (
        <div className="col">
            <div className="row">
                <div className="col-12">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">$</span>
                        </div>

                        <input
                            className='form-control'
                            placeholder='Amount...'
                            type='number'
                            step='.01'
                            min='0'
                            value={amount}
                            autoFocus={true}
                            onChange={e => setAmount(Number.parseFloat(e.target.value) || '')} />
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-12">
                    <textarea
                        className='form-control'
                        placeholder='Notes...'
                        value={notes}
                        rows='3'
                        onChange={e => setNotes(e.target.value)}
                    />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-7">
                    <button className='btn btn-outline text-white w-100' disabled={!Boolean(amount)} onClick={apply}>
                      { !!discount ? 'Update' : 'Apply' } Discount
                    </button>
                </div>
                <div className="col-5">
                    <button className='btn btn-danger text-white w-100' onClick={cancel}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    )
}
