import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { UI } from '@/lib/Constants'
import CustomerSearch from './CustomerSearch'
import { CustomerPagination } from './CustomerPagination'
import { selectSearchOpen, selectSearched, setSearchOpen, setQuery } from './customerSlice'
import { selectMembershipsEnabled } from '../Locations/locationSlice'
import CustomerSearchResults from './CustomerSearchResults'

export default function CustomerSearchModal() {

    const dispatch           = useDispatch()
    const membershipsEnabled = useSelector(selectMembershipsEnabled)
    const open               = useSelector(selectSearchOpen)
    const searched           = useSelector(selectSearched)

    const [selectedCustomers, setSelectedCustomers] = useState([])

    const handleKeyDown = (e) => {
        if (
            e.key === 'Escape'
            && !document.body.classList.contains(UI.classes.CONFIRMATION_OPEN)
            && !document.body.classList.contains(UI.classes.HELPJUICE_OPEN)
        ) {
            handleClose()
        }
    }

    const handleCustomerSelect = (customerId) => {
        if (selectedCustomers.includes(customerId)) {
            setSelectedCustomers(selectedCustomers.filter((id) => id != customerId))
        } else {
            setSelectedCustomers([customerId, ...selectedCustomers])
        }
    }

    const handleClose = () => {
        setSelectedCustomers([])
        dispatch(setSearchOpen(false))
    }

    /**
     * Handle the initial mount and
     * unmount of the component
     */
    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown, false)

        if (open) {
            document.body.classList.add(UI.classes.MULTIPLE_MODALS_OPEN)
        }

        // cleanup/reset on unmount
        return () => {
            document.body.classList.remove(UI.classes.MULTIPLE_MODALS_OPEN)
            document.removeEventListener('keydown', handleKeyDown, false)
            dispatch(setQuery(null))
        }
    }, [open])

    return !open ? null : (
        <div className={`modal modal-backdrop modal--${searched ? membershipsEnabled ? 'xxxxl' : 'xxxl' : 'sm'} customer-search-modal`}>
            <div className="modal-container">
                <div className="modal-header">
                    <div className="row">
                        <div className="col-12">
                            <div className="modal-title">Search Customers</div>
                        </div>
                    </div>
                    <button className="modal-close mb-auto" onClick={handleClose}>
                        <span>Close</span>
                        <i className="far fa-times" />
                    </button>
                </div>

                <div className={`pt-4 px-4 ${searched ? 'mb-n2' : 'pb-2'}`}>
                    <CustomerSearch />
                </div>

                {
                    searched && <>
                        <div className="modal-body mh-100">
                            <div className="modal-body-main mh-100">
                                <div className="modal-body-main-section pb-0">
                                    <CustomerSearchResults
                                        selectedCustomers={selectedCustomers}
                                        onSelect={handleCustomerSelect}
                                        onClearSelected={() => setSelectedCustomers([])}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer d-block py-3">
                            <CustomerPagination selectedCustomersCount={selectedCustomers.length} />
                        </div>
                    </>
                }
            </div>
        </div>
    )
}
