import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setBooking } from '@/features/EditBooking/editBookingSlice'
import { setOpen, setStep } from '@/features/Schedule/scheduleSlice'
import { selectParentBooking, selectChildBookings, resetParentBooking } from '@/features/ParentBooking/parentBookingSlice'
import CustomerBadges from '@/features/Customers/CustomerBadges'

export default function ParentBookingGroupDetailsParticipantsTable({
    booking=null,
    customers=[],
    immutableEvent=false,
    onCustomerCheckIn,
    onRemoveParticipant
}) {

    const dispatch      = useDispatch()
    const parentBooking = useSelector(selectParentBooking)
    const childBookings = useSelector(selectChildBookings)

    const handleAddParticipants = (e) => {
        const cb = childBookings.filter((cb) => cb.id == e.target.value)

        if (cb.length === 1) {
            dispatch(setBooking({ booking: cb[0], resources: parentBookingResources }))
            dispatch(setStep('4'))
            dispatch(resetParentBooking())
            dispatch(setOpen(true))
        }
    }

    return !booking ? null : <>
        {
            customers.length > 0 && (
                <div className="event-body">
                    <div className="group-participants">
                        <table className="table">
                            <tbody>
                                {
                                    customers.map((customer) => (
                                        <tr key={`child_booking_customer-${customer.id}`}>
                                            <td>
                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        id={`check-${customer.id}`}
                                                        className="custom-control-input cursor-pointer"
                                                        checked={booking.checked_in.includes(customer.id) || false}
                                                        disabled={immutableEvent}
                                                        onChange={(e) => onCustomerCheckIn(e, booking, customer)}
                                                    />
                                                    <label className="custom-control-label cursor-pointer" htmlFor={`check-${customer.id}`}>
                                                        {customer.name}
                                                    </label>
                                                </div>
                                            </td>

                                            <td className={immutableEvent ? 'text-right' : 'text-left'}>
                                                <CustomerBadges
                                                   customer={customer}
                                                   waiversRequired={booking.waivers_required}
                                                   bookingActiveWaiver={booking.waiver_id}
                                                />
                                            </td>

                                            {
                                                !immutableEvent && (
                                                    <td>
                                                        <button
                                                            type='button'
                                                            className="btn btn-danger text-white min-h-auto py-2 px-3"
                                                            onClick={() => onRemoveParticipant(customer.id, booking.id)}
                                                        >
                                                            REMOVE
                                                        </button>
                                                    </td>
                                                )
                                            }
                                        </tr>
                                    ))
                                }

                                {
                                    !immutableEvent && Object.keys(customers).length === 0 && (
                                        <tr>
                                            <td className="d-flex align-items-center">
                                                0 Participants
                                                <button
                                                    type='button'
                                                    className="btn btn-primary text-white ml-auto h-auto py-1 px-3"
                                                    value={booking.id}
                                                    onClick={handleAddParticipants}
                                                >
                                                    ADD
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            )
        }
    </>
}
