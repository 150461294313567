import React from 'react'

export default function ModalListButton({
    id=null,
    className='',
    containerClassName='',
    type='button',
    disabled=false,
    selected=false,
    onClick=() => {},
    children
}) {
    return (
        <div id={id} className={`menu-button ${containerClassName}`.trim()}>
            <button
                className={`btn d-flex justify-content-between ${selected ? 'selected' : ''} ${className}`.trim()}
                type={type}
                disabled={disabled}
                onClick={onClick}
            >
                <span>{children}</span>
                {selected && <i className='fa-solid fa-check' />}
            </button>
        </div>
    )
}
