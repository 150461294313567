import React, { useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import FormRow from '../Form/FormRow'
import Input from '../Form/Input'
import Checkbox from '../Form/Checkbox'
import AllowedCustomerTypes from './AllowedCustomerTypes'
import Groups from './Groups'
import Pricing from './Pricing'
import Help from '@/features/Help/Help'

export default function PackagePricingTab({
    form,
    errors,
    resources,
    customerTypes,
    newPackage,
    loaded,
    location,
    origAmountPricingGroups,
    membershipTypesUrl,
    handleTabLoaded,
    handleUpdate,
    handleManualUpdate,
    handleFormUpdate,
}) {

    const [showCreditCardRequirementMessage, setShowCreditCardRequirementMessage] = useState(form.authorize_card_only)
    const [selectedOnlineBookingTypes, setSelectedOnlineBookingTypes]             = useState([])

    const { register } = useFormContext()

    const onlineBookingField = register('onlineBooking', {
      validate: (v) => {
        const selected = selectedOnlineBookingTypes.filter((type) => type === true)
        return selected.length > 0 || 'At least one must be selected'
      }
    })

    /**
     * When one of the show full/deposit options are selected, the authorize card only toggle cannot be on.
     */
    const handleShowPaymentToggle = e => {
        handleFormUpdate({
            ...form,
            [e.target.name]:     !form[e.target.name],
            authorize_card_only: false
        })
    }

    /**
     * When the authorize payment toggle is used, the show full/deposit options cannot be on.
     */
    const handleAuthorizeToggle = e => {
        handleFormUpdate({
            ...form,
            [e.target.name]:     !form[e.target.name],
            show_full_payment:   false,
            show_deposit_option: false
        })
    }

    /**
      * Toggle the display of the credit card message field
      * whenever the "authorize card only" checkbox changes
      */
    useEffect(() => {
        setShowCreditCardRequirementMessage(form.authorize_card_only)
    }, [form.authorize_card_only])

    /**
     * Track which forms of online booking have been selected
     * and set a hidden (and otherwise unused) form field so
     * that we can use it to validate the form and require at
     * least one to have been selected by the user
     */
    useEffect(() => {
        setSelectedOnlineBookingTypes([
            form.show_full_payment,
            form.show_deposit_option,
            form.authorize_card_only
        ])
    }, [
        form.show_full_payment,
        form.show_deposit_option,
        form.authorize_card_only
    ])


    /**
     * Initially set full payment checkbox to
     * true if we're creating a new package
     */
    useEffect(() => {
        if (!loaded && newPackage) {
            handleFormUpdate({
                ...form,
                show_full_payment: true,
                authorize_card_only: false
            })
        }
    }, [])

    useEffect(() => {
      if (!loaded) { handleTabLoaded() }
    }, [])

    return <>
        <h3 className="d-flex justify-content-between align-items-center">
            <span className='d-flex'>
                <span>Online Payment Options</span>
                <Help articleId='154000158977-public-booking-modal-payment-options' />
            </span>
        </h3>

        {
            errors[onlineBookingField.name] && (
                <div className="invalid-feedback d-block mb-2">
                    { errors[onlineBookingField.name].join(', ') }
                </div>
            )
        }

        <div className="row">
            <div className="col-4">
                <Checkbox
                    name='show_full_payment'
                    handleChange={handleShowPaymentToggle}
                    value={form.show_full_payment}
                    label="Allow Full Payment" />
            </div>
            <div className="col-4">
                <Checkbox
                    name='show_deposit_option'
                    handleChange={handleShowPaymentToggle}
                    value={form.show_deposit_option} 
                    label="Allow Deposits" />
            </div>
            <div className="col-4">
                <Checkbox
                    name='authorize_card_only'
                    handleChange={handleAuthorizeToggle}
                    value={form.authorize_card_only} />
            </div>
        </div>

        <input type="hidden" value={selectedOnlineBookingTypes} {...onlineBookingField} />

        { showCreditCardRequirementMessage &&
            <FormRow>
                <div className="col">
                    <Input
                        cols='12'
                        name='credit_card_requirement_message'
                        label='Credit Card Requirement Explanation Message'
                        value={form.credit_card_requirement_message || ''}
                        validation={{ 'credit_card_requirement_message': { required: true }}}
                        handleChange={handleUpdate}
                    />
                </div>
            </FormRow>
        }

        <AllowedCustomerTypes
            selectedCustomerTypes={form.customer_types || []}
            promoCodes={form.promo_codes || []}
            customerTypes={customerTypes}
            pricingType={form.pricing_type}
            pricing={form.pricing || []}
            durations={form.durations || []}
            form={form}
            location={location}
            errors={errors}
            isNewPackage={newPackage && !form.pricing_type}
            origAmountPricingGroups={origAmountPricingGroups}
            membershipTypesUrl={membershipTypesUrl}
            handleUpdate={handleUpdate}
            handleManualUpdate={handleManualUpdate}
            handleFormUpdate={handleFormUpdate}
        />

        <Groups
            resources={resources || []}
            durations={form.durations || []}
            groups={form.groups || []}
            pricing={form.pricing || []}
            pricingType={form.pricing_type}
            selectedCustomerTypes={form.customer_types || []}
            isSpecialEvent={form.resource_exclusive_to_package}
            errors={errors}
            handleManualUpdate={handleManualUpdate}
        />

        <Pricing
            form={form}
            durations={form.durations || []}
            pricing={form.pricing || []}
            pricingType={form.pricing_type}
            customerTypes={customerTypes}
            isSpecialEvent={form.resource_exclusive_to_package}
            handleManualUpdate={handleManualUpdate}
        />
    </>
}
