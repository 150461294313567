import React from 'react'

export default function CenteredText({
    text=null,
    className='',
    textClassName='text-gray1',
    children
}) {

    return (
        <div className={`d-flex h-100 align-items-center ${className}`.trim()}>
            <h3 className={`m-0 p-0 w-100 text-center ${textClassName}`}>
                { text || children }
            </h3>
        </div>
    )
}
