import React from 'react'
import axios from 'axios'
import { Provider } from 'react-redux'
import giftCardStore from '@/stores/giftCardStore'
import PurchaseModal from '@/features/GiftCards/components/PurchaseModal'
import PurchaseModalStyles from '@/features/GiftCards/components/PurchaseModalStyles'
import NotificationList from '@/features/Notifications/NotificationList'

// Necessary to force Axios requests to send "as XHR"
axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'

export default function GiftCardPurchaseModal({ 
    url,
    amounts,
    locationPhoneNumber,
    modalStyles,
    company_id,
    location_id,
    location_time_zone,
    environment,
    valid_promotion_without_code,
    has_promotion_with_code,
    paymentProcessor,
}) {
    return (
        <Provider store={giftCardStore}>
            <PurchaseModal
                url={url}
                amounts={amounts}
                locationPhoneNumber={locationPhoneNumber}
                companyId={company_id}
                locationId={location_id}
                locationTimeZone={location_time_zone}
                environment={environment}
                valid_promotion_without_code={valid_promotion_without_code}
                has_promotion_with_code={has_promotion_with_code}
                paymentProcessor={paymentProcessor}
            />
            <PurchaseModalStyles modalStyles={modalStyles} />
            <NotificationList />
        </Provider>
    )
}
