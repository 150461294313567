import React from 'react'
import { SpecialHoursForm } from './SpecialHoursForm'
import { SpecialHoursList } from './SpecialHoursList'
import Help from '@/features/Help/Help'

export default function LocationHoursSpecialHours({ specialHours=[], newLocation, tz, handleManualUpdate, handleCheckboxUpdate }) {
  return (
    <div className="special-hours">
      <h3>Special Hours<Help articleId='154000155472-hours-tab-special-hours' /></h3>

      <SpecialHoursForm
        newLocation={newLocation}
        specialHours={specialHours}
        tz={tz}
        handleManualUpdate={handleManualUpdate}
        handleCheckboxUpdate={handleCheckboxUpdate}
      />

      <SpecialHoursList
        specialHours={specialHours}
        tz={tz}
        handleManualUpdate={handleManualUpdate}
      />
    </div>
  )
}
