import React from 'react'
import { Provider } from 'react-redux'
import HelpModal from '@/features/Help/HelpModal'
import calendarStore from '@/stores/calendarStore'
import memberStore from '@/stores/memberStore'

const stores = {
    calendarStore: calendarStore,
    memberStore: memberStore,
}

export default function NotificationContainer({ store='calendarStore' }) {
    return (
        <Provider store={stores[store]}>
            <HelpModal />
        </Provider>
    )
}
