import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addBookingTabToGeneralTab } from '@/features/AdvancedPointOfSale/lib/Checks'

import {
    setCurrentComponent,
    selectCurrentCheck,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import ButtonGroup from '@/features/AdvancedPointOfSale/components/buttons/ButtonGroup'
import CenteredText from '@/features/AdvancedPointOfSale/components/CenteredText'
import Receipt from '@/features/AdvancedPointOfSale/components/ordering/Receipt'
import { groupItems } from '@/features/AdvancedPointOfSale/lib/Items'
import scrollHinting from '@/lib/ScrollHinting'
import { numberToCurrency } from '@/lib/Number'
import { debug } from '@/lib/Debug'

export default function ViewCheck() {

    const dispatch   = useDispatch()
    const check      = useSelector(selectCurrentCheck)
    const sortedTabs = useMemo(() => addBookingTabToGeneralTab(check.tabs), [check])
    const hasTabs    = check?.tabs?.length > 0
    const isEmpty    = sortedTabs?.reduce((total, tab) => total + tab.items.length, 0) === 0

    useEffect(() => {
        scrollHinting.search()
    }, [])

    return <>
        <div className={`d-flex align-items-center justify-content-${debug && !!check ? 'between' : 'center'} text-white py-2 w-100`}>
            <small className={`d-block font-weight-bold px-2 ${debug && !!check ? 'text-left' : 'text-center'}`}>
                CHECK TOTAL{debug && !!check ? ': ' : ' — '}{numberToCurrency((check?.grand_total_cents || 0) / 100)}
            </small>
            { debug && !!check && <small className='d-block text-right px-2'>CHECK ID: {check.id}</small>}
        </div>

        <div className={`tab--content scrollhint ${hasTabs ? '' : 'd-flex align-items-center justify-content-center'}`.trim()}>
            <div className='scrollhint--inner'>
                {
                    hasTabs ? <>
                        {
                            sortedTabs.map((tab) => {
                                const filteredItems = groupItems(tab.items.filter(({ item }) => !/^credit$/i.test(item.amount_type)), check)

                                return (
                                    <Receipt
                                        key={tab.id}
                                        className='check-tab pb-2 mb-4'
                                        check={check}
                                        tab={tab}
                                        collapsable={filteredItems.length > 0}
                                        collapsedByDefault={filteredItems.length > 3}
                                        hideAmountPaid
                                        hideTipsCollected
                                        hideRemainingBalance
                                        compact
                                    />
                                )
                            }) 
                        }
                    </> : (
                        <CenteredText text='Empty' textClassName='text-gray2' />
                    )
                }
            </div>
        </div>

        <ButtonGroup className='tab--footer theme--outline-solid'>
            {
                hasTabs && <>
                    <button
                        type='button'
                        children='Manage Check'
                        onClick={() => dispatch(setCurrentComponent('ManageCheck'))}
                    />

                    {
                        !isEmpty && (
                            <button
                                type='button'
                                children='Print / Pay'
                                onClick={() => dispatch(setCurrentComponent('PrintPay'))}
                            />
                        )
                    }
                </>
            }
        </ButtonGroup>
    </>
}
