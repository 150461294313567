import React, { useMemo } from 'react'
import moment from 'moment-timezone'
import CalendarCountdown from './CalendarCountdown'
import BookingStatusIcons from '@/features/Calendar/BookingStatusIcons'
import { isBookingHeld, isBookingQuote, isBookingInProgress } from '@/lib/Booking'
import { debug } from '@/lib/Debug'

const tinyFontSize = { fontSize: '10px' }

export function _BookingBody({ booking, pkg, resources, timezone, columns, currentTime }) {

    const scheduledStartTime = moment.tz(booking.start_time, timezone)
    const actualStartTime    = isBookingInProgress(booking) ? moment.tz(booking.started_at, timezone) : null

    const calculatedLength = useMemo(() => (
        parseInt(booking.duration, 10) + parseInt((booking?.addon_minutes || 0), 10)
    ), [booking.duration, booking?.addon_minutes])

    const calculatedEndTimeFor = (start_time) => (
        moment(start_time).tz(timezone).add(calculatedLength, 'minutes')
    )

    const isAtLeastThirtyMinutesLong = calculatedLength >= 30
    const isAtLeastSixtyMinutesLong  = calculatedLength >= 60

    return (
        <div className="event-body row">
            <div className="col pr-0">
                <div className="time">
                    { scheduledStartTime.format('LT') }
                    { isAtLeastSixtyMinutesLong? ' - ' : '-' }
                    { calculatedEndTimeFor(scheduledStartTime).format('LT') }

                    { isBookingInProgress(booking) && isAtLeastThirtyMinutesLong &&
                        <span className="pl-1" style={tinyFontSize}>(SCHEDULED)</span>
                    }
                </div>

                {
                    isBookingHeld(booking) && (
                        <CalendarCountdown booking={booking} columns={columns} />
                    )
                }

                {
                    isBookingInProgress(booking) && Boolean(actualStartTime) &&
                        <div className="time bold">
                            { actualStartTime.format('LT') }
                            { isAtLeastSixtyMinutesLong ? ' - ' : '-' }
                            { calculatedEndTimeFor(actualStartTime).format('LT') }

                            { isBookingInProgress(booking) && isAtLeastThirtyMinutesLong &&
                                <span className="pl-1" style={tinyFontSize}>(ACTUAL)</span>
                            }
                        </div>
                }

                {
                    debug &&
                        <pre className='debug text-white pt-1' style={tinyFontSize}>
                            ID: {booking.id}<br />
                            PACKAGE VERSION: {booking.package_version_id || 'DEFAULTING TO LATEST'}<br />
                            CURRENT TIME: { currentTime.format('HH:MM:SS z') }
                        </pre>
                }
            </div>

            {
                isAtLeastSixtyMinutesLong && !isBookingQuote(booking) &&
                    <div className="col-6 pl-0 d-flex flex-column align-items-right">
                        <BookingStatusIcons
                            booking={booking}
                            pkg={pkg}
                            resources={resources}
                            completeConfirmationOptions={
                                pkg.is_a_league
                                    ? { dangerous: true, size: 'lg' }
                                    : {}
                            }
                            additionalCompleteWarningText={
                                pkg.is_a_league
                                    ? "<p class='my-5'>Because this special event is a league, reservations will be transferred from this instance to the next once completed.</p>" +
                                      "<p class='text-danger'><u>This is cannot be undone!</u></p>"
                                    : ''
                            }
                        />

                        {booking.server && <span className='server-name text-right mt-2'>Server: {booking.server.name}</span>}
                    </div>
            }
        </div>
    )
}

export const BookingBody = React.memo(_BookingBody)
