import React from 'react'
import moment from 'moment-timezone'
import FormRow from '../Form/FormRow'
import Checkbox from '../Form/Checkbox'
import MomentTimePicker from "../Form/MomentTimePicker"

export default function ResourceTypeHoursTab({
  form,
  errors,
  handleUpdate,
  handleCheckboxUpdate,
  time_zone
}) {

  //
  // note: MomentTimePicker can accept null or
  // Moment objects as values, just not strings
  //
  const value_for = (value, allowBlank=false) => {
    // supply the timepicker with null so that it renders
    // a blank/empty field since we don't have a value
    if (!value && allowBlank) { return null }

    // for existing resource types, supply the timepicker with
    // a moment object in the location's preferred timezone
    if (value !== null && typeof value === 'string') {
        return moment(value).tz(time_zone)
    }

    // in all other case, supply the timepicker with
    // null so that it renders a blank/empty field
    if (console) { console.warn('ResourceTypePeakTimes: this should never happen!') }
    return null
  }

  return (
    <div>
      <h3>
          Resource Type Hours Override
      </h3>

      <FormRow>
        <div className="col-6 mt-3">
          <Checkbox
            label='Override Location Hours?'
            name='override_hours'
            handleChange={handleCheckboxUpdate}
            value={form.override_hours}
            tooltip="Setting an open & close time for a day will override the<br />
              location's default hours. Leaving it empty will fall back<br />
              to the location's date and time."
          />
        </div>
      </FormRow>

      <div className="form-group">
        {
          ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].map((day, index) => {
            const spacingClasses = index > 0 ? 'border-top mt-3 pt-4' : ''
            // loop over the days and create start/end peak hour inputs
            return (
              <FormRow key={`day_${index}`} className={`p-2 ${spacingClasses}`}>
                <div className="col-3">
                  <h2 className="mt-4 text-capitalize">{day}</h2>
                </div>

                <MomentTimePicker
                  label='Opens At'
                  cols='4'
                  name={`${day}_open`}
                  value={value_for(form[`${day}_open`], true)}
                  tz={time_zone}
                  placeholder='XX:00 AM'
                  errors={errors}
                  preventTyping={true}
                  handleUpdate={handleUpdate}
                  disabled={!form.override_hours}
                />

                <MomentTimePicker
                  label='Peak Time Starts At'
                  cols='4'
                  name={`${day}_peak_start`}
                  value={value_for(form[`${day}_peak_start`], true)}
                  tz={time_zone}
                  placeholder='XX:00 AM'
                  errors={errors}
                  preventTyping={true}
                  handleUpdate={handleUpdate}
                />

                <div className="col-3">&nbsp;</div>

                <MomentTimePicker
                  cols='4'
                  label='Closes At'
                  name={`${day}_close`}
                  value={value_for(form[`${day}_close`], true)}
                  tz={time_zone}
                  placeholder='XX:00 PM'
                  errors={errors}
                  preventTyping={true}
                  handleUpdate={handleUpdate}
                  disabled={!form.override_hours}
                />

                <MomentTimePicker
                  cols='4'
                  label='Peak Time Ends At'
                  name={`${day}_peak_end`}
                  value={value_for(form[`${day}_peak_end`], true)}
                  tz={time_zone}
                  placeholder='XX:00 PM'
                  errors={errors}
                  preventTyping={true}
                  handleUpdate={handleUpdate}
                />
              </FormRow>
            )
          })
        }
      </div>
    </div>
  )
}
