import React from "react";
import { Line } from 'react-chartjs-2'
import colorInterpolation from '../../utils/colorInterpolation'

function LineChart(props) {
    const chartData = props.customerTypesData
    const numberOfColors = Object.entries(chartData.data).length
    const colorsArray = colorInterpolation(numberOfColors)

    const chartDataWithColors = () => {
        let coloredChartData = chartData.data
        coloredChartData.forEach((entry, index) => {
            coloredChartData[index]["backgroundColor"] = colorsArray[index]
            coloredChartData[index]["borderColor"] = colorsArray[index]
        })
        return coloredChartData
    }

    const data = {
        labels: chartData.xLabelArray,
        datasets: chartDataWithColors()
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'left'
            }
        },
        scales: {
            y: {
              min: 0,
              ticks: {
                stepSize: 1,
              }
            }
        }
    }

    return (
        <div>
            <Line options={options} data={data} height={400}/>
        </div>
    )
}

export default LineChart
