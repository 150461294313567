import React, { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectAllowMemberBenefits, selectAllowMembersOnly } from '@/features/Packages/packageSlice'
import { selectCustomers, addCustomerToBooking, addCustomersToBooking } from './customerSlice'
import { selectBooking } from '../EditBooking/editBookingSlice'
import CustomerBadges from '@/features/Customers/CustomerBadges'

export default function CustomerSearchResults({
    selectedCustomers=[],
    onSelect=() => {},
    onClearSelected=() => {},
}) {

    const dispatch            = useDispatch()
    const booking             = useSelector(selectBooking)
    const customers           = useSelector(selectCustomers)
    const allowMemberBenefits = useSelector(selectAllowMemberBenefits)
    const allowMembersOnly    = useSelector(selectAllowMembersOnly)

    // if the booking's package is members only, then we're
    // safely assuming that the members have already been added
    // as throwers/scoring participants and if we're adding any
    // more people, that they are spectators/non-scoring participants
    const showInScoringApp = useMemo(() => (
        allowMemberBenefits
        && allowMembersOnly
        && booking?.members?.length >= booking?.customer_settings?.filter((c) => c.show_in_scoring_app)?.length
            ? false
            : true
    ), [booking, allowMembersOnly, allowMemberBenefits])

    const handleSelectCustomer = (customerId) => {
        onSelect(customerId, document.getElementById(`customer-search-result-${customerId}`).checked)
    }

    const handleAddCustomer = (e) => {
        if (!!e.target.dataset.id) {
            dispatch(addCustomerToBooking(e.target.dataset.id, showInScoringApp))
        }
    }

    const handleAddCustomers = () => {
        if (selectedCustomers.length > 0) {
            dispatch(addCustomersToBooking(selectedCustomers, showInScoringApp))
        }
    }

    return customers.length > 0 && (
        <table className="table table-hover my-0">
            <colgroup>
                <col width='50px' />
                <col width='auto' />
                <col width='auto' />
                { allowMemberBenefits && <col width='auto' /> }
                <col width='100px' />
                <col width='100px' />
                { booking.waivers_required && <col width='auto' /> }
                <col width='75px' />
            </colgroup>
            <thead>
                <tr>
                    <th className="pl-2 pr-0">
                        <div className="custom-control custom-checkbox">
                            <input
                                id='customer-search-result-clear-selected'
                                className='custom-control-input mt-2'
                                type='checkbox'
                                checked={selectedCustomers.length > 0}
                                disabled={selectedCustomers.length === 0}
                                onChange={onClearSelected}
                            />

                            {/* This empty label has to be here to make the custom checkbox work.
                              Maybe we can use it for aria later. */}
                            <label htmlFor='customer-search-result-clear-selected' className='custom-control-label' />
                        </div>
                    </th>
                    <th className='pl-1'>Name</th>
                    <th>Email</th>
                    { allowMemberBenefits && <th>Member ID</th> }
                    <th>Total Visits</th>
                    <th>Total Hours</th>
                    <th className='text-right pr-0' colSpan='2'>
                        <button
                            children='Add Selected'
                            type='button'
                            className={`btn ${selectedCustomers.length > 0 ? 'btn-success' : 'btn-outline-secondary disabled cursor-default'} py-2 min-h-auto`}
                            disabled={selectedCustomers === 0}
                            onClick={handleAddCustomers}
                        />
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    customers.map((customer) => {
                        const isSelected = selectedCustomers.includes(customer.id)

                        return (
                            <tr
                                key={customer.id}
                                className={`cursor-pointer ${isSelected ? 'bg-light font-weight-bold' : ''}`}
                            >
                                <td className="pl-2 pr-0">
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            id={`customer-search-result-${customer.id}`}
                                            className='custom-control-input mt-2'
                                            type='checkbox'
                                            checked={selectedCustomers.includes(customer.id)}
                                            onChange={() => handleSelectCustomer(customer.id)}
                                        />

                                        {/* This empty label has to be here to make the custom checkbox work.
                                          Maybe we can use it for aria later. */}
                                        <label htmlFor={`customer-search-result-${customer.id}`} className='custom-control-label' />
                                    </div>
                                </td>
                                <td className='pl-1' onClick={() => handleSelectCustomer(customer.id)}>
                                    {customer.name}
                                </td>
                                <td onClick={() => handleSelectCustomer(customer.id)}>
                                    {customer.email}
                                </td>
                                {
                                    allowMemberBenefits && (
                                        <td onClick={() => handleSelectCustomer(customer.id)}>
                                            <span className="badge badge-info py-1" children={customer?.member?.active_membership?.membership_id || ''} />
                                        </td>
                                    )
                                }
                                <td onClick={() => handleSelectCustomer(customer.id)}>
                                    {customer.total_visits}
                                </td>
                                <td onClick={() => handleSelectCustomer(customer.id)}>
                                    {customer.total_hours}
                                </td>
                                <td onClick={() => handleSelectCustomer(customer.id)} className='text-wrap'>
                                      <CustomerBadges
                                          customer={customer}
                                          waiversRequired={booking.waivers_required}
                                          bookingActiveWaiver={booking.active_waiver}
                                          includeMemberId={false}
                                      />
                                </td>
                                <td className="pr-1">
                                    <button
                                        children='Add'
                                        className="btn btn-primary w-100 py-2 min-h-auto"
                                        data-id={customer.id}
                                        onClick={handleAddCustomer}
                                    />
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    )
}
