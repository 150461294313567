import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectProcessing as selectAdyenProcessing } from '@/features/Adyen/adyenSlice'
import { selectProcessing as selectPaysafeProcessing } from '@/features/Terminal/terminalSlice'

import {
    selectProcessingPayment,
    selectPaymentAmount,
    selectPaymentType,
    selectLocationPaymentProcessor
} from '@/features/Products/productSlice'

import CancelButton from '@/features/Utilities/CancelButton'
import { numberToCurrency } from '@/lib/Number'

export default function PaymentProcessing() {

    const paymentProcessor          = useSelector(selectLocationPaymentProcessor)
    const paymentType               = useSelector(selectPaymentType)
    const processingPaysafeTerminal = useSelector(selectPaysafeProcessing)
    const processingAdyenTerminal   = useSelector(selectAdyenProcessing)
    const processingOther           = useSelector(selectProcessingPayment)
    const amountToPay               = useSelector(selectPaymentAmount)

    const isProcessing = useMemo(() => (
        processingAdyenTerminal
        || processingPaysafeTerminal
        || processingOther
    ), [processingAdyenTerminal, processingPaysafeTerminal, processingOther])

    return isProcessing && (
        <div id="global-loading">
            <div id="global-loading--content">
                <div className="spinner">
                    <div className="rect1" />
                    <div className="rect2" />
                    <div className="rect3" />
                    <div className="rect4" />
                    <div className="rect5" />
                    <div className="rect6" />
                </div>

                <div className='text-center'>
                    Payment Processing<br />
                   { Number(amountToPay) === 0 ? 'Complete' : numberToCurrency(amountToPay) }
                </div>

                {
                    paymentType === 'terminal'
                    && (processingPaysafeTerminal || processingAdyenTerminal)
                    && (
                        <CancelButton
                            appendedButtonText='Transaction'
                            paymentProcessor={paymentProcessor}
                            shouldCancelTerminalTransaction
                        />
                    )
                }
            </div>
        </div>
    )
}
