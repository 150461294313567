import React from 'react'
import { useSelector } from 'react-redux'
import { selectResourceType } from '@/features/ResourceType/resourceTypeSlice'
import { selectCustomerTypeCounts, selectParticipants, selectStep } from '@/features/Bookings/bookingSlice'
import { selectPricingType, selectAllowMemberBenefits, selectAllowMembersOnly } from '@/features/Packages/packageSlice'
import { selectMemberCount } from '../../bookingSlice'
import LedgerCustomerTypePrice from './LedgerCustomerTypePrice'
import { customerVerbiage, formatParticipants } from '@/features/Bookings/utils'
import { humanizedLabel } from '@/lib/String'
import { debug } from '@/lib/Debug'

export default function LedgerCustomerTypes({ customerTypeTotals=null, memberPrice=null }) {

    const step                = useSelector(selectStep)
    const resourceType        = useSelector(selectResourceType)
    const pricingType         = useSelector(selectPricingType)
    const participants        = useSelector(selectParticipants)
    const customerTypeCounts  = useSelector(selectCustomerTypeCounts)
    const memberCount         = useSelector(selectMemberCount)
    const allowMemberBenefits = useSelector(selectAllowMemberBenefits)
    const allowMembersOnly    = useSelector(selectAllowMembersOnly)

    let participantsMinusMembers = participants

    try {
        if (!participants?.includes('-') && pricingType !== 'pricing_by_customer_type') {
            participantsMinusMembers = String(parseInt(participantsMinusMembers) - parseInt(memberCount))
        }
    } catch (e) {
        console.log('Could not set participants minus members: ', e)
    }

    if (debug && console) {
        console.log('~~~ Customer type totals: ', customerTypeTotals)
    }

    return /5|6/.test(step) && Boolean(customerTypeTotals) && (
        <div className="xbm-ledger-section pr-0">
            {
                participantsMinusMembers && <>
                    {
                        !allowMembersOnly && (
                            <div className="xbm-ledger-section-action-label">
                                <div className="xbm-ledger-section-action-label-label">
                                    <span dangerouslySetInnerHTML={{
                                        __html: humanizedLabel(`${formatParticipants(participantsMinusMembers)} ${customerVerbiage(participantsMinusMembers, resourceType)}`, true, 22)
                                    }} />
                                </div>
                            </div>
                        )
                    }

                    {
                        allowMemberBenefits && memberCount > 0 && (
                            <div className="xbm-ledger-section-action-label">
                                <div className="xbm-ledger-section-action-label-label">
                                    <span dangerouslySetInnerHTML={{
                                        __html: humanizedLabel(`${formatParticipants(String(memberCount))} ${customerVerbiage(String(memberCount), { customer_verbiage_singular: 'Member', customer_verbiage_plural: 'Members' })}`, true, 22)
                                    }} />
                                </div>
                            </div>
                        )
                    }
                </>
            }

            {
                pricingType === 'pricing_by_customer_type' && (
                    <div className="xbm-ledger-section-guests my-1">
                        {
                            !allowMembersOnly && Object.keys(customerTypeCounts).map((id) => (
                                <LedgerCustomerTypePrice
                                    key={`customer_${id}`}
                                    count={customerTypeCounts[id]}
                                    type={customerTypeTotals?.types?.filter(t => t.id === Number.parseInt(id))[0]}
                                />
                            ))
                        }

                        { 
                            // When we have members we grab their prices from
                            // the membership customer type connection on the package
                            memberCount > 0 && (
                                <LedgerCustomerTypePrice
                                    count={memberCount}
                                    type={{
                                        name: 'Member',
                                        price: memberPrice
                                    }}
                                />
                            )
                        }
                    </div>
                )
            }
        </div>
    )
}
