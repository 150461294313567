import React from 'react'
import { useSelector } from 'react-redux'
import { selectBooking } from '@/features/EditBooking/editBookingSlice'
import AddonTimes from '@/features/Schedule/components/ledger/AddonTimes'
import Discounts from '@/features/Schedule/components/ledger/Discounts'

export default function ParticipantAdjustments({
    discounts=[],
    customerTypeTotals=[],
    isGroupPricing=false,
    initiallyFolded=true,
    paymentMode=false,
    participants
}) {

    const booking = useSelector(selectBooking)

    const shouldDisplayAddonTimes = (
        Array.isArray(booking?.booking_addon_times) && booking?.booking_addon_times?.length > 0
    )

    const shouldDisplayDiscounts = (
        discounts.length > 0 || booking?.uses_flat_rate
    )

    return <>
        {
            shouldDisplayDiscounts && (
                <div className="drawer-booking-ledger-section drawer-booking-ledger-section-adjustments">
                    <table>
                        <colgroup>
                            <col width={isGroupPricing ? 'auto' : '48%' } />
                            { !isGroupPricing && <col width="auto" /> }
                            <col width="20%" />
                        </colgroup>
                        <tbody>
                            <Discounts
                                header='Adjustments'
                                discounts={discounts}
                                customerTypeTotals={customerTypeTotals}
                                isGroupPricing={isGroupPricing}
                                initiallyFolded={initiallyFolded}
                                paymentMode={paymentMode}
                                participants={participants}
                            />

                            {
                                !paymentMode && <>
                                    {
                                        booking?.uses_flat_rate || booking?.is_tax_exempt && (
                                            <tr><td colSpan={isGroupPricing ? 2 : 3}><hr className="my-1 py-0" /></td></tr>
                                        )
                                    }

                                    {
                                        booking?.uses_flat_rate && <tr>
                                            <td className="py-0 text-left text-muted" colSpan={isGroupPricing ? 2 : 3}>
                                                — <em>Manager Flat Rate Applied {booking?.flat_rate_applied_after_tax && 'After Tax'}</em>
                                            </td>
                                        </tr>
                                    }

                                    {
                                        booking?.is_tax_exempt && <tr>
                                            <td className="py-0 text-left text-muted" colSpan={isGroupPricing ? 2 : 3}>
                                                — <em>Booking Is Tax Exempt</em>
                                            </td>
                                        </tr>
                                    }
                                </>
                            }
                        </tbody>
                    </table>
                </div>
            )
        }

        {
            shouldDisplayAddonTimes && (
                <div className="drawer-booking-ledger-section drawer-booking-ledger-section-adjustments">
                    <table>
                        <colgroup>
                            <col width={isGroupPricing ? 'auto' : '48%' } />
                            { !isGroupPricing && <col width="auto" /> }
                            <col width="auto" />
                        </colgroup>
                        <tbody>
                            <AddonTimes
                                header='Additional Time'
                                header_tooltip='Additional time never includes any discounts or promotions.'
                                addonTimes={booking?.booking_addon_times}
                                isGroupPricing={isGroupPricing}
                                initiallyFolded={paymentMode}
                            />
                        </tbody>
                    </table>
                </div>
            )
        }
    </>
}
