import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectLocation } from '@/features/Locations/locationSlice'
import { setCreditToken, selectCreditToken, setCreditMethod, setTerminalId } from '@/features/Members/memberSlice'
import AdyenTerminalTransaction from '@/features/Terminal/AdyenTerminalTransaction'

export default function Terminal() {
    const dispatch     = useDispatch()
    const location     = useSelector(selectLocation)
    const creditToken  = useSelector(selectCreditToken)

    useEffect(() => {
        dispatch(setCreditMethod('terminal'))

        return () => {
            dispatch(setCreditMethod(null))
        }
    }, [])

    return <>
        <AdyenTerminalTransaction
            locationId={location.id}
            setTerminal={id => dispatch(setTerminalId(id))}
            transactionCallback={payment => dispatch(setCreditToken(payment.cardToken))}
            hideButton
        />
        <button
            type='submit'
            children='Submit Terminal Transaction'
            className='btn btn-primary w-100'
            disabled={creditToken}
        />
    </>
}
