import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentCheck, selectCheckoutTab } from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'
import CheckReceipt from '@/features/AdvancedPointOfSale/components/ordering/CheckReceipt'
import Receipt from '@/features/AdvancedPointOfSale/components/ordering/Receipt'
import scrollHinting from '@/lib/ScrollHinting'

export default function CheckoutSidebar({
    currentBalance=0,
    tipAmount=0,
    amountToProcess=0,
}) {
    const check = useSelector(selectCurrentCheck)
    const tab   = useSelector(selectCheckoutTab)

    useEffect(() => {
        scrollHinting.search()
    }, [])

    return (
        <aside id='advanced-pos-terminal--checkout--sidebar'>
            <h4 className='sticky-top title py-2 px-4 mb-0'>Receipt</h4>

            <div className='scrollhint'>
                <div className='scrollhint--inner p-4'>
                    {
                        !!tab ? (
                            <Receipt
                                check={check}
                                tab={tab}
                                currentBalance={currentBalance}
                                tipAmount={tipAmount}
                                paymentAmount={Number(amountToProcess)}
                                checkoutMode
                            />
                        ) : (
                            <CheckReceipt
                                check={check}
                                currentBalance={currentBalance}
                                tipAmount={tipAmount}
                                paymentAmount={Number(amountToProcess)}
                                checkoutMode
                            />
                        )
                    }
                </div>
            </div>
        </aside>
    )
}
