import React, { useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import errorClass from '../../lib/Errors'
import FormRow from '../Form/FormRow'
import { durationsPrices } from '../../lib/Package'
import Pricing from '../../lib/Pricing'

export default function SelectCustomerTypes({
    allowMemberBenefits,
    selectedCustomerTypes,
    membershipCustomerType,
    customerTypes,
    pricing,
    durations,
    promoCodes,
    pricingType,
    errors,
    handleUpdate,
}) {
    const { register } = useFormContext()

    const customerTypesField = register('customer_types', {
        validate: (v) => v.length > 0 || 'At least one customer type must be checked',
        shouldUnregister: true
    })

    const [removePrice, setRemovePrice] = useState(false)
    const [addPrice, setAddPrice] = useState(false)

    const [removePromoCodeCustomerType, setRemovePromoCodeCustomerType] = useState(false)

    const [toggledId, setToggledId] = useState(null)

    useEffect(() => {
        if (removePrice) {
            removePriceOption()
        }

        if (addPrice) {
            addPriceOption()
        }

        if (removePromoCodeCustomerType) {
            removePromoCodeOptions()
        }
    }, [selectedCustomerTypes, pricing])

    /**
     * Loop through the price option and remove price grid options for the individual toggled customer type
     */
    const removePriceOption = () => {
        setRemovePrice(false)
        // fire off that we need to update the selected customer types in promo codes as well
        setRemovePromoCodeCustomerType(true)

        handleUpdate('pricing', pricing.map(group => {
            // if the pricing record is not for individual pricing, we can skip, otherwise dig in and remove entries for this customer type
            if (group.individualPricing && pricingType === Pricing.BY_CUSTOMER_TYPE) {
                return {
                    ...group,
                    prices: group.prices.filter(price => {
                        // here is an array of the duration blocks per customer type... since these are all the same
                        // customer type we should be able to just get the first one and see
                        return price[0].customerType !== toggledId
                    })
                }
            } else {
                return group
            }
        }))
    }

    /**
     * Remove promo code options for the toggled customer type. We have to do this because the customer types
     * in promo codes are only the user-selected customer types. So if one of those is removed and was selected
     * in promo codes, the data would still live for that customer type. The only way to keep the integrity
     * of that promo_codse array is to manually remove it whenever a customer type is deselected.
     */
    const removePromoCodeOptions = () => {
        setRemovePromoCodeCustomerType(false)

        handleUpdate('promo_codes', promoCodes.map(c => {
            return {
                ...c,
                customer_types: c.customer_types.filter(id => id !== toggledId)
            }
        }))

        // this should  always happen on the last routine to run that needs this field...
        // we might not even need this field since it should be getting overwritten anyways
        setToggledId(null)
    }

    const addPriceOption = () => {
        setAddPrice(false)

        handleUpdate('pricing', pricing.map(group => {
            // skip if this group is not marked for individual pricing
            if (group.individualPricing && pricingType === Pricing.BY_CUSTOMER_TYPE && durations.length) {
                return {
                    ...group,
                    prices: group.prices.concat([ durationsPrices(durations, toggledId) ])
                }
            } else {
                return group
            }
        }))

        setToggledId(null)
    }

    const handleTypeChange = (e) => {
        const target = Number.parseInt(e.target.value)

        setToggledId(target)

        if (selectedCustomerTypes.includes(target)) {
            // remove
            setRemovePrice(true)
            handleUpdate(customerTypesField.name, selectedCustomerTypes.filter(id => id !== target))
        } else {
            // add
            setAddPrice(true)
            handleUpdate(customerTypesField.name, selectedCustomerTypes.concat(target))
        }

        // explicitly update the form validation because
        // we've overridden the registered onChange handler
        customerTypesField.onChange(e)
    }

    return (
        <FormRow className='px-1'>
            {
                customerTypes.map(type => {
                    // calculate decent looking columns on a 12-col
                    // grid with the min size always defaulting to 3
                    let columns = Math.floor(12 / customerTypes.length)
                    columns = columns < 3 ? 3 : columns

                    const isMemberType = allowMemberBenefits && membershipCustomerType === type.id

                    return (
                        <div className={`col-${columns} mb-2`} key={type.id}>
                            <div className="custom-control custom-checkbox">
                                <input
                                    id={`allowed-customer-type-${type.id}`}
                                    className={`custom-control-input ${errorClass(customerTypesField.name, errors)}`}
                                    ref={customerTypesField.ref}
                                    name={customerTypesField.name}
                                    type='checkbox'
                                    value={type.id}
                                    checked={selectedCustomerTypes.includes(type.id)}
                                    onChange={handleTypeChange}
                                />

                                <label
                                    children={isMemberType ? `${type.name} (Member)` : type.name}
                                    htmlFor={`allowed-customer-type-${type.id}`}
                                    className={`custom-control-label ${isMemberType ? 'font-weight-bold' : ''}`}
                                />
                            </div>
                        </div>
                    )
                })
            }

            {
                errors[customerTypesField.name] && (
                    <div className="invalid-feedback d-block">
                        { errors[customerTypesField.name].join(', ') }
                    </div>
                )
            }
        </FormRow>
    )
}
