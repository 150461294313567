import React from 'react'

export default function BookingHistoryTabs({
    tabs=[],
    current='',
    onChange=() => {}
}) {

    const handleClick = (value) => {
        if (value !== current) {
            onChange(value)
        }
    }

    return (
        <ul className="modal-tabs" role="nav">
            {
                tabs.map((item, index) => {
                    const value   = item[0]
                    const display = item[1]

                    return (
                        <li key={index}>
                            <button
                                children={display}
                                className={`modal-tab ${value === current ? 'selected' : ''}`}
                                onClick={() => handleClick(value)}
                            />
                        </li>
                    )
                })
            }
        </ul>
    )
}
