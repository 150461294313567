import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import MenuBuilderListItem from '@/components/AdvancedPointOfSale/Menus/MenuBuilderListItem'
import { parameterize } from 'inflected'

export default function MenuBuilderList({
    items=[],
    itemsParentIdKey=null,
    defaultItemIds=[],
    iconType="is_default",
    isSubMenuCollapsedOnLoad=false,
    subMenuIsDefaultIconClass=null,
    overrideInternalRemoveFunction=false,
    className='',
    debug=false,
    onAdd=() => {},
    onRemove=() => {},
    onDefaultChange=() => {},
    onSort=() => {},
}) {

    const handleOnSort = (result, options) => {
        if (!!result?.destination && !!result?.source) {
            const itemsCopy = [...items]
            const [reorderedItem] = itemsCopy.splice(result.source.index, 1)
            itemsCopy.splice(result.destination.index, 0, reorderedItem)
            onSort(itemsCopy, options)
        } else {
            onSort(result, options)
        }
    }

    const handleOnRemove = (item, index) => {
        if (overrideInternalRemoveFunction) { return onRemove(item, index) }

        const itemsCopy = [...items]
        itemsCopy.splice(index, 1)

        onRemove(itemsCopy, item)
    }

    return items.length > 0 && (
        <DragDropContext onDragEnd={handleOnSort}>
            <Droppable
                droppableId="droppable-items"
                isCombineEnabled={false}
            >
                {
                    (provided) => (
                        <div
                            ref={provided.innerRef}
                            className={`draggable-items multi-select--external-list w-100 ${className}`.trim()}
                            {...provided.droppableProps}
                        >
                            {
                                items.map((item, index) => (item?.menu_uuid && item?.id && item?.name) ? (
                                    <Draggable
                                        index={index}
                                        key={parameterize(`${item.menu_uuid}-${item.id}-${item.name}`)}
                                        draggableId={parameterize(`${item.menu_uuid}-${item.id}-${item.name}`)}
                                        isDragDisabled={debug}
                                    >
                                        {
                                            (provided, snapshot) => <>
                                                <MenuBuilderListItem
                                                    parentIdKey={itemsParentIdKey}
                                                    iconType={iconType}
                                                    item={item}
                                                    index={index}
                                                    dnd={[provided, snapshot]}
                                                    isDefault={defaultItemIds.includes(item.id)}
                                                    isSubMenuCollapsedOnLoad={isSubMenuCollapsedOnLoad}
                                                    subMenuIsDefaultIconClass={subMenuIsDefaultIconClass}
                                                    debug={debug}
                                                    onAdd={onAdd}
                                                    onRemove={handleOnRemove}
                                                    onDefaultChange={onDefaultChange}
                                                    onSort={handleOnSort}
                                                />
                                                { provided.placeholder }
                                            </>
                                        }
                                    </Draggable>
                                ) : null)
                            }

                            { provided.placeholder }
                        </div>
                    )
                }
            </Droppable>
        </DragDropContext>
    )
}
