import React from "react"
import { useDispatch, useSelector } from "react-redux";
import { useFormContext } from 'react-hook-form'
import { selectCashAmount, setCashAmount, selectAmountToPay } from "./bookingPaymentSlice";
import PaymentCashChange from './PaymentCashChange'
import { errorMessageFor } from '@/components/Form/ErrorsHelper'

export default function PaymentCash() {

    const { register, setValue, formState: { errors } } = useFormContext()

    const dispatch    = useDispatch()
    const cashAmount  = useSelector(selectCashAmount)
    const amountToPay = useSelector(selectAmountToPay)

    const cashAmountField = register("amount_received", {
        validate: v => parseFloat(v) >= parseFloat(amountToPay) || 'The amount received cannot be less than the payment amount'
    })

    const handleClear = () => {
        setValue('amount_received', null)
        dispatch(setCashAmount(null))
    }

    return <>
        <div className="modal-body-main-section">
            <div className="pay-by-cash">
                <div className="form-row align-items-center">
                    <div className="col-12">
                        <div className="form-group pb-0">
                            <label>Amount Received</label>

                            <div className="input-group mb-2 text-right">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">$</div>
                                </div>

                                <input
                                    {...cashAmountField}
                                    type="number"
                                    min="0"
                                    value={cashAmount || ''}
                                    step=".01"
                                    className={`form-control ${errors?.amount_received ? 'is-invalid' : ''}`}
                                    placeholder="0.00"
                                    onChange={ e => {
                                        cashAmountField.onChange(e)
                                        dispatch(setCashAmount(e.target.value))
                                    }}
                                />

                                {
                                    !!cashAmount && cashAmount > 0 && (
                                        <div className='input-group-append'>
                                            <button
                                                children={<i className="far fa-times" />}
                                                type='button'
                                                className='btn btn-secondary text-white'
                                                onClick={handleClear}
                                            />
                                        </div>
                                    )
                                }

                                { errorMessageFor('amount_received', errors) }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <PaymentCashChange />
    </>
}
