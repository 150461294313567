import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCustomAmount, setCustomAmount } from './bookingPaymentSlice'
import PaymentCustomAmountSplit from './PaymentCustomAmountSplit'

export default function PaymentCustomAmount() {

    const dispatch     = useDispatch()
    const customAmount = useSelector(selectCustomAmount)

    const handleClear = () => {
        dispatch(setCustomAmount(null))
    }

    return (
        <div className="modal-body-main-section py-3">

            <PaymentCustomAmountSplit />

            <div className="form-row pt-2 mt-2 bt">
                <div className="col-5 d-flex align-items-center">
                    <div className="form-group w-100 p-0 text-right">
                        <label className="m-0">Enter Amount</label>
                    </div>
                </div>
                <div className="col-7 pr-0">
                    <div className="input-group mb-0">
                        <div className="input-group-prepend">
                            <div className="input-group-text">$</div>
                        </div>

                        <input
                            value={customAmount || ''}
                            type="number"
                            min="0"
                            step=".01"
                            className="form-control"
                            placeholder="0.00"
                            onChange={e => dispatch(setCustomAmount(e.target.value))}
                        />

                        {
                            !!customAmount && customAmount > 0 && (
                                <div className='input-group-append'>
                                    <button
                                        children={<i className="far fa-times" />}
                                        type='button'
                                        className='btn btn-secondary text-white'
                                        onClick={handleClear}
                                    />
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
