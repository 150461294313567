import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { LOAD_SPECIFIC_DATE } from '@/lib/Storage'
import { debug } from '@/lib/Debug'

const specificDate = window.localStorage.getItem(LOAD_SPECIFIC_DATE)

const dateFormat = 'YYYY-MM-DD'

const initialDate = () => (
    Boolean(specificDate)
        ? moment(specificDate)
        : moment()
)

// if specificDate is present, we're loading ONLY that date
// and not the [date-1.day, date] pair of the initial load
const initialLoadedDates = () => {
    let dates = [initialDate().format(dateFormat)]

    if (!Boolean(specificDate)) {
          dates.unshift( initialDate().subtract('1', 'day').format(dateFormat) )
    }

    return dates
}

export const calendarSlice = createSlice({
    name: 'calendar',
    initialState: {
        initialLoadComplete: false,
        loading: false,
        zoom: 0,
        sizeWidth: '125',
        sizeHeight: '125',
        labelWidth: '145',
        date: initialDate().format(dateFormat),
        viewedDate: initialDate().format(dateFormat),
        loadedDates: initialLoadedDates(),
        lastLoadedDatetime: initialDate().endOf('day').toISOString(),
        selectedBookingTopOffset: null,
        selectedBookingLeftOffset: null,
        markerOffset: null,
        locationId: null,
        companyId: null,
        calendarClass: null,
        calendarScrollListeners: false,
        manualBufferOpen: false,
        timesForLocation: null,
        locationSpecialHours: null,
        shouldCenterCalendar: null,
        timeMarkerUpdates: 0,
        inputLocked: false,
        dragStartColumnClass: null,
        currentDragOutlineClasses: null,
        holdTimeDurationMinutes: null,
    },
    reducers: {
        setInitialLoadComplete: (state, action) => {
            state.initialLoadComplete = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setInputLocked: (state, action) => {
            state.inputLocked = action.payload
        },
        setHoldTimeDurationMinutes: (state, action) => {
            state.holdTimeDurationMinutes = action.payload
        },
        setZoom: (state, action) => {
            state.zoom = action.payload
        },
        setShouldCenterCalendar: (state, action) => {
            state.shouldCenterCalendar = action.payload
        },
        setSizeWidth: (state, action) => {
            state.sizeWidth = action.payload
        },
        setSizeHeight: (state, action) => {
            state.sizeHeight = action.payload
        },
        setLabelWidth: (state, action) => {
            state.labelWidth = action.payload
        },
        setDate: (state, action) => {
            state.date = action.payload
        },
        setLoadedDates: (state, action) => {
            if (action.payload.resetMarker) {
                state.markerOffset = null
            }
            state.loadedDates = action.payload.dates
            state.calendarScrollListeners = true
        },
        setLastLoadedDatetime: (state, action) => {
            state.lastLoadedDatetime = action.payload
        },
        setViewedDate: (state, action) => {
            state.viewedDate = action.payload
        },
        setSelectedBookingTopOffset: (state, action) => {
            state.selectedBookingTopOffset = action.payload
        },
        setSelectedBookingLeftOffset: (state, action) => {
            state.selectedBookingLeftOffset = action.payload
        },
        setMarkerOffset: (state, action) => {
            state.markerOffset = action.payload
        },
        triggerTimeMarkerUpdate: (state, action) => {
            state.timeMarkerUpdates = state.timeMarkerUpdates + 1
        },
        setLocationId: (state, action) => {
            state.locationId = action.payload
        },
        setCompanyId: (state, action) => {
            state.companyId = action.payload
        },
        setCalendarClass: (state, action) => {
            state.calendarClass = action.payload
        },
        setCalendarScrollListeners: (state, action) => {
            state.calendarScrollListeners = action.payload
        },
        setManualBufferOpen: (state, action) => {
            state.manualBufferOpen = action.payload
        },
        setTimesForLocation: (state, action) => {
            state.timesForLocation = action.payload
        },
        setLocationSpecialHours: (state, action) => {
            state.locationSpecialHours = action.payload
        },
        setDragStartColumn: (state, action) => {
            if (state.dragStartColumnClass !== null) { return }
            state.dragStartColumnClass = `.time-${action.payload}`
        },
        setCurrentDragOutlineClasses: (state, action) => {
            state.currentDragOutlineClasses = action.payload.join(', ')
        },
        clearDragClasses: (state, action) => {
            state.dragStartColumnClass      = null
            state.currentDragOutlineClasses = null
        }
    }
})

export const {
    setInitialLoadComplete,
    setLoading,
    setShouldCenterCalendar,
    setInputLocked,
    setHoldTimeDurationMinutes,
    setZoom,
    setSizeWidth,
    setSizeHeight,
    setLabelWidth,
    setDate,
    setViewedDate,
    setLoadedDates,
    setLastLoadedDatetime,
    setSelectedBookingTopOffset,
    setSelectedBookingLeftOffset,
    setMarkerOffset,
    setLocationId,
    setTimesForLocation,
    setLocationSpecialHours,
    setCompanyId,
    setCalendarClass,
    setCalendarScrollListeners,
    setManualBufferOpen,
    triggerTimeMarkerUpdate,
    concatBookings,
    setDragStartColumn,
    setCurrentDragOutlineClasses,
    clearDragClasses
} = calendarSlice.actions

export const selectInitialLoadComplete       = state => state.calendar.initialLoadComplete
export const selectLoading                   = state => state.calendar.loading
export const selectShouldCenterCalendar      = state => state.calendar.shouldCenterCalendar
export const selectInputLocked               = state => state.calendar.inputLocked
export const selectHoldTimeDurationMinutes   = state => state.calendar.holdTimeDurationMinutes
export const selectZoom                      = state => state.calendar.zoom
export const selectSizeWidth                 = state => state.calendar.sizeWidth
export const selectSizeHeight                = state => state.calendar.sizeHeight
export const selectLabelWidth                = state => state.calendar.labelWidth
export const selectDate                      = state => state.calendar?.date
export const selectViewedDate                = state => state.calendar.viewedDate
export const selectLoadedDates               = state => state.calendar.loadedDates
export const selectLastLoadedDatetime        = state => state.calendar.lastLoadedDatetime
export const selectTimesForLocation          = state => state.calendar.timesForLocation
export const selectLocationSpecialHours      = state => state.calendar.locationSpecialHours
export const selectBookingTopOffset          = state => state.calendar.selectedBookingTopOffset
export const selectBookingLeftOffset         = state => state.calendar.selectedBookingLeftOffset
export const selectMarkerOffset              = state => state.calendar.markerOffset
export const selectTimeMarkerUpdates         = state => state.calendar.timeMarkerUpdates
export const selectCalendarClass             = state => state.calendar.calendarClass
export const selectCalendarScrollListeners   = state => state.calendar.calendarScrollListeners
export const selectManualBufferOpen          = state => state.calendar.manualBufferOpen
export const selectDragStartColumnClass      = state => state.calendar.dragStartColumnClass
export const selectCurrentDragOutlineClasses = state => state.calendar.currentDragOutlineClasses

export function scrollToBooking(booking) {
    return async (dispatch, getState) => {
        if (!booking) { return }

        const loadedDates = getState().calendar.loadedDates
        const timezone    = getState().location?.location?.time_zone

        if (!loadedDates || !timezone) { return }

        // the first thing we do is find out how many 15 minute blocks are in between
        // midnight (start of the first day on the calendar) and the start time of the
        // target booking. we then set the marker offset to that amount of pixels.
        const calendarStart = moment.tz(loadedDates[0], timezone).startOf('day')
        const bookingStart  = moment.tz(booking.start_time, timezone)

        // difference in minutes between the start time and midnight of that day
        const diff = bookingStart.diff(calendarStart, 'minutes')

        // take the minute difference and divide by 15, since we only deal with 15 minute blocks
        const diffIn15Minutes = diff / 15

        // the amount of pixels wide each block is - different for different zoom sizes.
        const blockWidth = Number.parseInt(getState().calendar.sizeWidth)

        // multiply the difference of 15 minute blocks by how wide each 15 minute block
        // is on the calendar itself in pixels... that will be the amount of pixels
        // we need to scroll on the calendar
        const offset = diffIn15Minutes * blockWidth

        // this is a semi-hacky way to force the redux state to update
        //
        // it is necessary in the case where the offset is already the same as
        // the offset of the scroll-to target booking - this can happen if you...
        //
        // -- view a search result
        //    >> then close the drawer
        //       >> then scroll the calendar a bit
        //          >> and then finally click the same booking again
        //
        // by setting them a pixel off and then setting it to the true offset
        // 1 millisecond later, it forces a redux state update since the values
        // are now truly different
        dispatch(setMarkerOffset(offset-1))
        window.setTimeout(() => { dispatch(setMarkerOffset(offset)) }, 1)
    }
}

export function selectBooking(booking) {
    return async (dispatch, getState) => {
        const loadedDates = getState().calendar.loadedDates
        const timezone    = getState().location?.location?.time_zone

        if (!loadedDates || !timezone) { return }

        // the first thing we do is find out how many 15 minute blocks are in between
        // midnight (start of the day) and the start time of the selected booking.
        // we then set the marker offset to that amount of pixels.
        const dayStart     = moment.tz(booking.start_time, timezone).startOf('day')
        const bookingStart = moment.tz(booking.start_time, timezone)

        // difference in minutes between the start time and midnight of that day
        const diff = bookingStart.diff(dayStart, 'minutes')

        // take the minute difference and divide by 15, since we only deal with 15 minute blocks
        const diffIn15Minutes = diff / 15

        // the amount of pixels wide each block is - different for different zoom sizes.
        const blockWidth = Number.parseInt(getState().calendar.sizeWidth)

        // multiply the difference of 15 minute blocks by how wide each 15 minute block
        // is on the calendar itself in pixels... that will be the amount of pixels
        // we need to scroll on the calendar
        const offset = diffIn15Minutes * blockWidth

        // IF the selected date is not the same as the currently viewed date on the calendar
        if (loadedDates.includes(bookingStart.format('YYYY-MM-DD')) === false) {
            if (debug && console) { console.log(`>>> LOADING ${bookingStart.format('YYYY-MM-DD')} AND SCROLLING TO ${bookingStart.toString()} <<<`) }

            // remember this offset for later so that we can scroll to the selected search result
            // booking in the then() callback of the fetchCalendarBookings() action in the
            // calendarBookingSlice once the selected search result booking's day is finished loading
            dispatch(setSelectedBookingLeftOffset(offset))

            // change the date to the date of the selected search result booking
            // and trigger fetchCalendarBookings() followed by re-centering
            //
            // note: since this will automatically try to center on the current time once the
            // date is loaded in, we have to remember the offset we calculated to re-center
            // the calendar on the selected search result booking
            dispatch(setDate(bookingStart.format('YYYY-MM-DD')))
        } else {
            if (debug && console) { console.log(`>>> SCROLLING TO ${bookingStart.toString()} <<<`) }

            // since we're already on the same date, the recentering won't happen on its own,
            // so we have to explicitly scroll the calendar to the selected booking
            dispatch(scrollToBooking(booking))
        }
    }
}

export default calendarSlice.reducer
