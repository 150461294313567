import React from 'react'
import { numberToCurrency } from '@/lib/Number'

export default function BalancesBottom({ amountToPay, cashChangeDue, paymentType, balanceAfterPayment }) {
    return (
        <div className="drawer-booking-ledger-remaining">
            <div className="payment-amount">
                <div className="label">
                    Payment Amount
                </div>
                <div className="amount">
                    {numberToCurrency(amountToPay)}
                </div>
            </div>

            {
                Boolean(cashChangeDue) && paymentType === 'cash' && (
                    <div className="cash-change-due">
                        <div className="label">
                            Change Due
                        </div>
                        <div className={`amount ${cashChangeDue > 0 ? 'text-success' : 'text-danger'}`}>
                            {numberToCurrency(cashChangeDue)}
                        </div>
                    </div>
                )
            }

            <div className="balance-after-payment">
                <div className="label">
                    Balance<br />After Payment
                </div>
                <div className={`amount ${balanceAfterPayment > 0 ? 'text-danger' : 'text-success'}`}>
                    {numberToCurrency(balanceAfterPayment)}
                </div>
            </div>
        </div>
    )
}
