import { configureStore } from "@reduxjs/toolkit"
import utilitiesSliceReducer from "../features/Utilities/utilitiesSlice"
import calendarSliceReducer from "../features/Calendar/calendarSlice"
import locationSliceReducer from "../features/Locations/locationSlice"
import resourceTypeSliceReducer from "../features/ResourceType/resourceTypeSlice"
import scheduleSliceReducer from "../features/Schedule/scheduleSlice"
import sessionSliceReducer from "../features/Session/sessionSlice"
import referralTypeSliceReducer from "../features/Referrals/referralsSlice"
import occasionTypeSliceReducer from "../features/Occasions/occasionsSlice"
import sourceTypeSliceReducer from "../features/Sources/sourcesSlice"
import packageSliceReducer from "../features/Packages/packageSlice"
import customerTypeSliceReducer from "../features/CustomerTypes/customerTypeSlice"
import availabilitySliceReducer from "../features/Availability/availabilitySlice"
import resourcesSliceReducer from "../features/Resources/resourcesSlice"
import calendarBookingsSliceReducer from "../features/CalendarBookings/calendarBookingsSlice"
import editBookingSliceReducer from "../features/EditBooking/editBookingSlice"
import bookingHistorySliceReducer from "../features/BookingHistory/bookingHistorySlice"
import addonSliceReducer from "../features/Addon/addonSlice"
import pricingSliceReducer from "../features/Pricing/pricingSlice"
import bookingPaymentSliceReducer from "../features/BookingPayment/bookingPaymentSlice"
import calendarSearchSliceReducer from "../features/CalendarSearch/calendarSearchSlice"
import parentBookingSliceReducer from "../features/ParentBooking/parentBookingSlice"
import productSliceReducer from "../features/Products/productSlice"
import managerCodeSliceReducer from "../features/ManagerCodes/managerCodeSlice"
import memberProductCodeDiscount from "../features/MemberProductDiscount/MemberProductDiscountSlice";
import discountSliceReducer from "../features/Discounts/discountSlice"
import terminalSliceReducer from "../features/Terminal/terminalSlice"
import customerSliceReducer from '../features/Customers/customerSlice'
import notificationSliceReducer from '../features/Notifications/notificationSlice'
import helpSliceReducer from '../features/Help/helpSlice'
import advancedPointOfSaleReducer from "../features/AdvancedPointOfSale/advancedPointOfSaleSlice"
import adyenSliceReducer from '../features/Adyen/adyenSlice'

export default configureStore({
    reducer: {
        utilities: utilitiesSliceReducer,
        calendar: calendarSliceReducer,
        location: locationSliceReducer,
        resourceType: resourceTypeSliceReducer,
        schedule: scheduleSliceReducer,
        session: sessionSliceReducer,
        referrals: referralTypeSliceReducer,
        occasions: occasionTypeSliceReducer,
        sources: sourceTypeSliceReducer,
        package: packageSliceReducer,
        customerType: customerTypeSliceReducer,
        availability: availabilitySliceReducer,
        resources: resourcesSliceReducer,
        calendarBookings: calendarBookingsSliceReducer,
        editBooking: editBookingSliceReducer,
        bookingHistory: bookingHistorySliceReducer,
        addon: addonSliceReducer,
        pricing: pricingSliceReducer,
        bookingPayment: bookingPaymentSliceReducer,
        calendarSearch: calendarSearchSliceReducer,
        parentBooking: parentBookingSliceReducer,
        products: productSliceReducer,
        managerCode: managerCodeSliceReducer,
        memberProductDiscount: memberProductCodeDiscount,
        discounts: discountSliceReducer,
        terminal: terminalSliceReducer,
        customers: customerSliceReducer,
        notifications: notificationSliceReducer,
        help: helpSliceReducer,
        advancedPointOfSale: advancedPointOfSaleReducer,
        adyen: adyenSliceReducer,
    }
})
