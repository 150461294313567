import React, {useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NumberIncrementer from '../../components/Form/NumberIncrementer'
import { selectBooking, selectNumberOfResources, setBookingAttribute } from './editBookingSlice'
import { isBookingCancelled, isBookingComplete } from '@/lib/Booking'

export default function BookingResourceQty({ isChild=false }) {

    const dispatch                = useDispatch()
    const booking                 = useSelector(selectBooking)
    const numberOfResources       = useSelector(selectNumberOfResources)
    const [override, setOverride] = useState(false)

    const handleOverrideChange = () => {
      setOverride(!override)
    }

    return (
        <div className="mb-4">
            <h3>Resource Quantity</h3>

            <div className="row">
                <div className="col-6">
                    <NumberIncrementer
                      value={numberOfResources}
                      handleChange={value => dispatch(setBookingAttribute({ name: 'number_of_resources', value: Number.parseInt(value) }))}
                      incrementAmount={1}
                      min={1}
                      disabled={isBookingCancelled(booking) || isBookingComplete(booking) || !override || isChild}
                      disabledDecrement={!override}
                    />
                </div>

                <div className="col pt-2">
                  <div className="custom-control custom-switch">
                    <input
                      value={override}
                      checked={override}
                      onChange={handleOverrideChange}
                      disabled={isBookingCancelled(booking) || isBookingComplete(booking) || isChild}
                      type="checkbox"
                      className="custom-control-input"
                      id="resource-qty-override" />

                    <label className="custom-control-label" htmlFor="resource-qty-override">
                      Count Override
                    </label>
                  </div>
                </div>
            </div>
        </div>
    )
}
