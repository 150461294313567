import React, { useEffect } from 'react'
import FormRow from '@/components/Form/FormRow'
import Input from '@/components/Form/Input'
import Select from '@/components/Form/Select'
import PaymentProcessingFees from '@/components/Locations/PaymentProcessingFees'
import titleize from '@/lib/String'

let originalPaymentProcessorId

export default function LocationPaymentForm({
    form,
    errors,
    paymentProcessors=[],
    handleUpdate,
    handleManualUpdate
}) {
    const SHOPPER_STATEMENT_LIMIT = 22

    const paymentProcessorOptions = paymentProcessors.map((p) => ({ value: p.id, display: titleize(p.name) }))
                                                     .sort((a,b) => (a.display > b.display)? 1 : (b.display > a.display) ? -1 : 0)

    const handleUpdateHardwareKey = (e) => {
        const sanitizedValue = e.target.value.replace(/\s/g, '')
        handleManualUpdate('terminal_hardware_key', sanitizedValue)
    }

    const handleShopperStatement = (e) => {
        if (Number(e?.target?.value?.length || 0) <= SHOPPER_STATEMENT_LIMIT) {
            handleUpdate(e)
        }
    }

    useEffect(() => {
        if (!originalPaymentProcessorId) {
            originalPaymentProcessorId = form.payment_processor_id
        }

        return () => {
            originalPaymentProcessorId = null
        }
    }, [])

    return <>
        <h4>Reservation Fees</h4>

        <FormRow>
          <Input
            label='RKd Reservation Fee'
            value={form.rkd_reservation_fee}
            type='number'
            min={0}
            cols='3'
            errors={errors}
            prepend={true}
            validation={{ 'rkd_reservation_fee': {
                validate: (v) => Number.parseFloat(v) >= 0 || 'Amount cannot be less than 0.0'
            }}}
            handleChange={handleUpdate}
          >
            <div className="input-group-append">
              <div className="input-group-text">%</div>
            </div>
          </Input>

          <Input
              label='Merchant Reservation Fee'
              value={form.merchant_reservation_fee}
              type='number'
              min={0}
              cols='3'
              req={false}
              errors={errors}
              prepend={true}
              validation={{ 'merchant_reservation_fee': {
                  validate: (v) => Number.parseFloat(v) >= 0 || 'Amount cannot be less than 0.0'
              }}}
              handleChange={handleUpdate}
          >
              <div className="input-group-append">
                  <div className="input-group-text">%</div>
              </div>
          </Input>

          <Input
            label='Third Party Reservation Fee'
            value={form.third_party_reservation_fee}
            type='number'
            min={0}
            cols='3'
            errors={errors}
            req={false}
            prepend={true}
            validation={{ 'third_party_reservation_fee': {
                validate: (v) => Number.parseFloat(v) >= 0 || 'Amount cannot be less than 0.0'
            }}}
            handleChange={handleUpdate}
          >
            <div className="input-group-append">
              <div className="input-group-text">%</div>
            </div>
          </Input>
        </FormRow>

        <PaymentProcessingFees
            form={form}
            errors={errors}
            handleManualUpdate={handleManualUpdate}
        />

        <h4>Payment Processing Configuration</h4>
        {
            // display the processor selection menu if...
            // -- we have more than one to choose from
            // -- either
            //    -- the original was Paysafe and we've chosen Adyen
            //    -- or Adyen is the stored processor but we want to allow switching back
            paymentProcessorOptions.length > 1
            && (
                originalPaymentProcessorId == 1
                || (!!form.paysafe_account_number && !!form.adyen_onboarding_status)
            ) && <>
                <FormRow>
                    <Select
                        label="Payment Processor"
                        name="payment_processor_id"
                        cols={12}
                        req={true}
                        options={paymentProcessorOptions}
                        value={form.payment_processor_id}
                        errors={errors}
                        handleChange={handleUpdate}
                    />
                </FormRow>
                <hr className='mt-0' />
            </>
        }

        {
            // PAYSAFE FIELDS
            form.payment_processor_id == 1 && <>
                <FormRow>
                    <Input
                        name="paysafe_account_number"
                        handleChange={handleUpdate}
                        value={form.paysafe_account_number}
                    />
                </FormRow>

                <FormRow>
                    <Input
                        name="terminal_hardware_key"
                        value={form.terminal_hardware_key}
                        handleChange={handleUpdateHardwareKey}
                    />
                </FormRow>

                <FormRow>
                    <Input
                        name="third_party_paysafe_account_number"
                        handleChange={handleUpdate}
                        value={form.third_party_paysafe_account_number}
                    />
                </FormRow>
            </>
        }

        {
            // ADYEN FIELDS
            form.payment_processor_id == 2 && <>
                <FormRow>
                    <Input
                        cols='6'
                        label={`${form.payment_processor_id == 1 ? 'Adyen ' : ''}Legal Entity ID`}
                        placeholder='LExxxxxxxxxxxxxxxxxxxxxxx'
                        value={form.adyen_legal_entity_id}
                        req={false}
                        errors={errors}
                        validation={{ 'adyen_legal_entity_id': { required: false }}}
                        disabled
                        handleChange={handleUpdate}
                    />
                    <Input
                        cols='6'
                        label={`${form.payment_processor_id == 1 ? 'Adyen ' : ''}Account Holder ID`}
                        placeholder='AHxxxxxxxxxxxxxxxxxxxxxxx'
                        value={form.adyen_account_holder_id}
                        req={false}
                        errors={errors}
                        validation={{ 'adyen_account_holder_id': { required: false }}}
                        disabled
                        handleChange={handleUpdate}
                    />
                </FormRow>

                <FormRow>
                    <Input
                        cols='6'
                        label={`${form.payment_processor_id == 1 ? 'Adyen ' : ''}Business Line ID`}
                        placeholder='SExxxxxxxxxxxxxxxxxxxxxxx'
                        value={form.adyen_business_line_id}
                        req={false}
                        validation={{ 'adyen_business_line_id': { required: false }}}
                        disabled
                        handleChange={handleUpdate}
                    />
                    <Input
                        cols='6'
                        label={`${form.payment_processor_id == 1 ? 'Adyen ' : ''}Balance Account ID`}
                        placeholder='BAxxxxxxxxxxxxxxxxxxxxxxx'
                        value={form.adyen_balance_account_id}
                        req={false}
                        errors={errors}
                        validation={{ 'adyen_balance_account_id': { required: false }}}
                        disabled
                        handleChange={handleUpdate}
                    />
                </FormRow>

                <FormRow>
                    <Input
                        cols='6'
                        label={`${form.payment_processor_id == 1 ? 'Adyen ' : ''}Store ID`}
                        placeholder='STxxxxxxxxxxxxxxxxxxxxxxx'
                        value={form.adyen_store_id}
                        req={false}
                        errors={errors}
                        validation={{ 'adyen_store_id': { required: false }}}
                        disabled
                        handleChange={handleUpdate}
                    />
                    <Input
                        cols='6'
                        label={`${form.payment_processor_id == 1 ? 'Adyen ' : ''}Store Reference`}
                        placeholder='Unknown!'
                        value={form.adyen_store_reference}
                        req={false}
                        errors={errors}
                        validation={{ 'adyen_store_reference': { required: false }}}
                        disabled
                        handleChange={handleUpdate}
                    />
                </FormRow>
            </>
        }

        {
           /*
           * We need the Adyen shopper statement input to live outside of the condition so that
           * it can be set when editing an existing location, before the Adyen store is created,
           * without forcing the selection of Adyen as the payment processor (because in that
           * situation it would be too early to do so).
           */
        }
        <hr />

        <FormRow>
            <Input
                cols='6'
                label={`${form.payment_processor_id == 1 ? 'Adyen ' : ''}Shopper Statement`}
                labelTooltip={`The store name to be shown on the shopper's bank or credit card statement and on the shopper receipt. Maximum length is ${SHOPPER_STATEMENT_LIMIT} characters and it cannot be all numbers. If left blank, "RkD Solutions" will be used by default.`}
                placeholder='RKd Solutions'
                value={form.adyen_shopper_statement}
                append={true}
                req={false}
                errors={errors}
                validation={{ 'adyen_shopper_statement': {
                    required: false,
                    validate: (v) => {
                        const rawLength       = parseInt(String(v).length)
                        const lengthAsNumbers = parseInt(String(parseInt(v)).length)

                        if (rawLength > SHOPPER_STATEMENT_LIMIT) { return `Cannot be more than ${SHOPPER_STATEMENT_LIMIT} characters long.` }
                        if (lengthAsNumbers === rawLength) { return 'Cannot be all numbers.' }
                    }
                }}}
                disabled={!!form.adyen_store_reference && !!form.adyen_store_id}
                handleChange={handleShopperStatement}
            >
                <div className="input-group-append">
                    <span className="input-group-text">
                        {form?.adyen_shopper_statement?.length || 0}/{SHOPPER_STATEMENT_LIMIT} Characters
                    </span>
                </div>
            </Input>
        </FormRow>
    </>
}
