import React from 'react'
import { pluralize } from 'inflected'
import { humanizedLabel } from '@/lib/String'
import { numberToCurrency } from '@/lib/Number'

export default function LedgerCustomerTypePrice({ count, type, displayDots=false}) {

    return count === 0 ? null : (
        <div className="xbm-ledger-section-guests-type my-1">
            <div className="xbm-ledger-section-guests-type-label d-inline-block w-75">
                <span dangerouslySetInnerHTML={{ __html: humanizedLabel(`${count} ${count === 1 ? type?.name : pluralize(type?.name)}`, true, 22) }} />
            </div>

            { displayDots && <div className="dots" /> }

            <div className="xbm-ledger-section-guests-type-cost d-inline-block w-25 float-right text-right">
              {numberToCurrency(type?.price || 0)}/ea
            </div>
        </div>
    )
}
