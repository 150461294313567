import React, { useEffect, useRef } from 'react'

export default function Breadcrumbs({ id=null, data=[], className=null, onClick=() => {} }) {

    const breadcrumbRefs = useRef([])

    const setRef = (ref) => {
        breadcrumbRefs.current.push(ref)
    }

    const autoScroll = () => {
        if (breadcrumbRefs.current && breadcrumbRefs.current[breadcrumbRefs.current.length - 1]) {
            breadcrumbRefs.current[breadcrumbRefs.current.length - 1]
                          .scrollIntoView({ behavior: 'auto', block: 'center', inline: 'end' })
        }
    }

    useEffect(() => {
        screen?.orientation?.addEventListener('change', autoScroll)
        window.addEventListener('orientationchange', autoScroll)

        return () => {
            screen?.orientation?.removeEventListener('change', autoScroll)
            window.removeEventListener('orientationchange', autoScroll)
        }
    }, [])

    useEffect(() => {
        autoScroll()
    }, [data])

    return data?.length > 0 && (
        <div id={id} className={className}>
            <div id={!!id ? `${id}-inner`: null}>
                {
                    data.map((breadcrumb, index) => (
                        <button
                            ref={setRef}
                            key={index}
                            type='button'
                            disabled={data.length === 1 || index === (data.length - 1)}
                            onClick={() => onClick(breadcrumb)}
                        >
                            {
                                !!breadcrumb.button_color && (
                                    <span style={{ backgroundColor: breadcrumb.button_color }} />
                                )
                            }
                            { breadcrumb.name }
                        </button>
                    ))
                }
            </div>
        </div>
    )
}
