import React, { useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { validationPatterns, validateEquality } from '@/lib/Errors'
import { selectCart, updateCart } from '@/features/GiftCards/giftCardSlice'

export default function RecipientEmail({ index }) {
    const dispatch = useDispatch()
    const { register, getValues, resetField, formState: { errors } } = useFormContext()
    const giftCards = useSelector(selectCart)
    const [useReceiptEmail, email] = useMemo(() => [
        giftCards[index].useReceiptEmail,
        giftCards[index].email
    ], [giftCards[index]])
    const [confirmEmail, setConfirmEmail] = useState('')

    const emailField = register(`cards.${index}.email`, {
        required: useReceiptEmail ? false : 'A recipient email address is required.',
        pattern: validationPatterns.email
    })

    const confirmEmailField = register(`cards.${index}.confirmEmail`, {
        required: useReceiptEmail ? false : 'A confirm email address is required.',
        pattern: validationPatterns.email,
        validate: () => validateEquality(getValues(`cards.${index}.email`), getValues(`cards.${index}.confirmEmail`)) || 'Email addresses do not match!'
    })

    const handleChange = () => {
        if (useReceiptEmail) {
            dispatch(updateCart({ index, name: "useReceiptEmail", value: false }))
        } else {
            setConfirmEmail("")
            dispatch(updateCart({ index, name: "useReceiptEmail", value: true }))
            dispatch(updateCart({ index, name: "email", value: "" }))
            resetField(`cards.${index}.email`, { defaultValue: "" })
            resetField(`cards.${index}.confirmEmail`, { defaultValue: "" })
        }
    }

    useEffect(() => {
        resetField(`cards.${index}.email`, { defaultValue: "" })
        resetField(`cards.${index}.confirmEmail`, { defaultValue: "" })
    }, [])

    return <>
        <div className="custom-control custom-checkbox my-3">
            <input
                id={`use-receipt-email-${index}`}
                type="checkbox"
                className="custom-control-input"
                checked={useReceiptEmail}
                onChange={handleChange}
            />
            <label
                htmlFor={`use-receipt-email-${index}`}
                className="custom-control-label"
            >Use purchaser email
            </label>
        </div>

        {!useReceiptEmail && <>
            <input
                {...emailField}
                id={`email-${index}`}
                className={`form-control ${errors?.cards?.[index]?.email ? 'is-invalid' : ''}`}
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={(e) => {
                    emailField.onChange(e)
                    dispatch(updateCart({ index, name: "email", value: e.target.value }))
                }}
            />
            <input
                {...confirmEmailField}
                id={`confirm-email-${index}`}
                className={`form-control ${errors?.cards?.[index]?.confirmEmail ? 'is-invalid' : ''} my-3`}
                type="email"
                placeholder="Confirm Email Address"
                value={confirmEmail}
                onChange={(e) => {
                    confirmEmailField.onChange(e)
                    setConfirmEmail(e.target.value)
                }}
            />
        </>}

        {
            errors?.cards?.[index]?.email &&
                <div className="invalid-feedback d-block mx-2">{ errors.cards[index].email.message }</div>
        }

        {
            errors?.cards?.[index]?.confirmEmail &&
                <div className="invalid-feedback d-block mx-2">{ errors.cards[index].confirmEmail.message }</div>
        }
    </>
}
