import React from 'react'
import axios from 'axios'
import { Provider } from 'react-redux'
import calendarStore from '@/stores/calendarStore'
import Toolbar from '@/features/Calendar/Toolbar'
import Calendar from '@/features/Calendar/Calendar'
import Loading from '@/components/Loading'
import { AddBookingToggle } from '@/features/Calendar/AddBookingToggle'
import CalendarSubscription from '@/features/Calendar/CalendarSubscription'
import CalendarSearchDrawer from '@/features/CalendarSearch/CalendarSearchDrawer'
import ScheduleDrawer from '@/features/Schedule/components/ScheduleDrawer'
import ParentBookingModal from '@/features/ParentBooking/ParentBookingModal'
import BookingHistoryModal from '@/features/EditBooking/BookingHistoryModal'
import ManagerOverrideModal from '@/features/EditBooking/ManagerOverrideModal'
import ServerSelectModal from '@/features/EditBooking/ServerSelectModal'
import PaymentModal from '@/features/BookingPayment/PaymentModal'
import ProductTransactionModal from '@/features/Products/ProductTransactionModal'
import AddonModal from '@/features/Addon/AddonModal'
import RemoveAddonModal from '@/features/Addon/RemoveAddonModal'
import CustomerSearchModal from '@/features/Customers/CustomerSearchModal'
import { ManualBufferModal } from '@/features/Calendar/ManualBufferModal'
import AddCardToBookingModal from '@/features/EditBooking/AddCardToBookingModal'
import SendLinkToCustomerModal from '@/features/EditBooking/SendLinkToCustomerModal'
import PartialAuthAlert from '@/features/Terminal/PartialAuthAlert'
import CalendarInputLock from '@/features/Calendar/CalendarInputLock'
import ConfirmationDialog from '@/features/Notifications/ConfirmationDialog'
import NotificationList from '@/features/Notifications/NotificationList'
import { SELECTED_LOCATION } from '@/lib/Storage'
import ResendReceiptModal from '../../features/BookingHistory/ResendReceiptModal'

// Necessary to force Axios requests to send "as XHR"
axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'

export default function CalendarContainer({
    location,
    paymentProcessor,
    companyId,
    selectedBooking,
    resourceTypes,
    occasionTypes,
    referralTypes,
    sourceTypes,
    productsPath,
    purchasePath,
    hardwareKey,
    userName,
    openHistory,
    environment,
    holdTimeDurationMinutes
})
{
    window.localStorage.setItem(SELECTED_LOCATION, location.id)

    return (
        <Provider store={calendarStore}>
            <Loading />
            <CalendarInputLock />
            <CalendarSubscription />
            <NotificationList />
            <PartialAuthAlert />

            <div id="calendar-container" className="w-100 h-100">
                <Toolbar location={location} />

                <Calendar
                    location={location}
                    paymentProcessor={paymentProcessor}
                    companyId={companyId}
                    resourceTypes={resourceTypes}
                    selectedBooking={selectedBooking}
                    holdTimeDurationMinutes={holdTimeDurationMinutes}
                />

                <AddBookingToggle />
            </div>

            <ScheduleDrawer
                occasionTypes={occasionTypes}
                referralTypes={referralTypes}
                sourceTypes={sourceTypes}
                userName={userName}
            />

            <CalendarSearchDrawer location={location} />

            <ParentBookingModal />
            <BookingHistoryModal openHistory={openHistory} />
            <ServerSelectModal />
            <ResendReceiptModal />
            <ManagerOverrideModal />
            <CustomerSearchModal />
            <AddonModal />
            <RemoveAddonModal />
            <ManualBufferModal />
            <AddCardToBookingModal />
            <SendLinkToCustomerModal />

            <PaymentModal
                hardwareKey={hardwareKey}
                environment={environment}
            />

            <ProductTransactionModal
                locationId={location.id}
                locationTaxRate={location.tax_rate}
                locationTimeZone={location.time_zone}
                locationPaymentProcessor={location.payment_processor_name}
                environment={environment}
                productsPath={productsPath}
                purchasePath={purchasePath}
                hardwareKey={hardwareKey}
                hidePartialAlert={true}
                inBooking={true}
            />

            <ConfirmationDialog />
        </Provider>
    )
}
