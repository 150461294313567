import React from 'react'

export default function SecurePassword({
    id=null,
    className='form-control text-monospace text-uppercase',
    error=null,
    value='',
    mask='•',
    placeholder=null,
    autoFocus=false,
    autoComplete='off',
    onFocus=() => {},
    onBlur=() => {},
    onChange=() => {},
    onKeyDown=() => {},
}) {

    // NOTE: This assumes all edits happen at the end of the password.
    const handleChange = (e) => {
        const newValue = e.target.value

        // Account for multi-character unicode masks
        const newLength = newValue.length / mask.length
        const oldLength = value.length * mask.length

        // We've added characters at the end
        if (value.length < newLength) {
            onChange(value + newValue.slice(oldLength))
        }

        // We've removed characters from the end
        if (value.length > newLength) {
            onChange(value.slice(0, newLength))
        }
    }

    return (
        <input
            id={id}
            className={`${className} ${!!error ? 'is-invalid' : ''}`.trim()}
            value={mask.repeat(value?.length || 0)}
            placeholder={placeholder}
            autoFocus={autoFocus}
            autoComplete={autoComplete}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            onChange={handleChange}
        />
    )
}
