import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectSelectedTerm, setSelectedTerm } from "./memberSlice"
import { termLength, termLengthLabel } from '@/lib/Member'
import { numberToCurrency } from '@/lib/Number'

export default function TermRadio({ term }) {

    const dispatch = useDispatch()
    const selected = useSelector(selectSelectedTerm)

    const handleClick = () => {
        dispatch(setSelectedTerm(selected?.id == term.id ? null : term))
    }

    return !!term && (
        <div className="radio-as-card mt-4 col-12 col-md-6" onClick={handleClick}>
            <input
                name="recipient_type"
                type="radio"
                value={term.id}
                checked={term.id === selected?.id}
                onChange={handleClick}
            />

            <div className="radio-card shadow">
                <div>{ termLength(term) } { termLengthLabel(term) } - {numberToCurrency(term.price) }</div>

                {
                    !!term.additional_fees && (
                        <small className="d-block smaller font-italic">
                            + {numberToCurrency(term.additional_fees)} One-time fee
                        </small>
                    )
                }

                <div className="smaller pt-4">{term.short_description}</div>
            </div>
        </div>
    )
}
