export const checkConfirmationQuestion = `
    <p class="mb-0">All tabs are paid.</p>
    <p class="mb-0">Would you like to close this check?</p>
`

export const tabConfirmationQuestion = `
    <p class="mb-0">This tab is fully paid.</p>
    <p class="mb-0">Would you like to close this tab?</p>
`

export const checkBookingParticipantChangeWhileSplitWarning = `
    <p>The price of this reservation has already been split amongst multiple check tabs.</p>
    <p>Saving a change to the number of participants, or the amount per type, <u>will cause the pricing split to be reset and the server will need to redo their work.</u><p>
    <hr class='my-4' />
    <p>If this must be done, please be sure to notify the server of these changes!<p>
`

// typically used for testing the status of a check or a tab
export function isOpen(object=null, key='status') {
    return /^(open)$/i.test(object?.[key])
}

// typically used for testing the payment status of a check or a tab
export function isPaid(object=null, key='payment_status') {
    return /^(paid|fully_paid|fullypaid)$/i.test(object?.[key])
        && ((object?.amount_cents || 0) > 0 && (object?.balance_cents || 0) == 0)
}

// typically used for testing the payment status of a check or a tab
export function isPartiallyPaid(object=null, key='payment_status') {
    return /^(partially_paid)$/i.test(object?.[key])
}

// typically used for testing the payment status of a check or a tab
export function isOverPaid(object=null, key='payment_status') {
    return /^(overpaid|over_paid)$/i.test(object?.[key]) && (object?.balance_cents || 0) < 0
}

// typically used for testing the payment status of a check or a tab
export function isUnpaid(object=null, key='payment_status') {
    return /^(unpaid)$/i.test(object?.[key])
}

// typically used for testing the status of a check or a tab
export function inAlteration(check=null) {
    return /^(alteration)$/i.test(check?.status)
}

export function determineReservationPartySize(check=null) {
    if (!check || !check?.booking) { return null }

    return !!check.booking?.group_min && !!check.booking?.group_max
        ? `${check.booking.group_min}-${check.booking.group_max}`
        : check.booking.participants
}

export function sortedAndFilteredTabs(tabs=null) {
    if (!tabs || !Array.isArray(tabs)) { return tabs }

    const generalTab  = tabs.find((tab) => tab.is_general)
    const updatedTabs = [...tabs].filter((tab) => !tab.is_general && !tab.is_booking)
                                 .sort((a,b) => (a.name > b.name) ? 1 : -1)

    updatedTabs.unshift(generalTab)

    return updatedTabs
}

export function addBookingTabToGeneralTab(tabs=null) {
    if (!tabs || !Array.isArray(tabs)) { return tabs }

    let tabsCopy       = [...tabs]
    const bookingTab   = tabsCopy.find(tab => tab.is_booking)
    const generalIndex = tabsCopy.findIndex(tab => tab.is_general)

    if (!!bookingTab && !!generalIndex) {

        // source the payment profile from the general tab if it
        // exists, otherwise, use the one on the booking instead
        const paymentProfile = !!tabsCopy[generalIndex].payment_profile
            ? tabsCopy[generalIndex].payment_profile
            : bookingTab.payment_profile

        const bookingTabItemsCopy = JSON.parse(JSON.stringify(bookingTab.items))
        bookingTabItemsCopy[0]['item']['type'] = 'general_booking'

        tabsCopy[generalIndex] = {
            ...tabsCopy[generalIndex],
            payment_profile: paymentProfile,
            items: [
                ...tabsCopy[generalIndex].items,
                ...bookingTabItemsCopy,
            ],
            amount_cents: tabsCopy[generalIndex].amount_cents + bookingTab.amount_cents,
            discount_cents: tabsCopy[generalIndex].discount_cents + bookingTab.discount_cents,
            balance_cents: tabsCopy[generalIndex].balance_cents + bookingTab.balance_cents,
            tax_cents: tabsCopy[generalIndex].tax_cents + bookingTab.tax_cents,
            tip_cents: tabsCopy[generalIndex].tip_cents + bookingTab.tip_cents,
            auto_gratuity_cents: tabsCopy[generalIndex].auto_gratuity_cents + bookingTab.auto_gratuity_cents,
            total_cents: tabsCopy[generalIndex].total_cents + bookingTab.total_cents,
            total_paid: tabsCopy[generalIndex].total_paid + bookingTab.total_paid,
            fully_paid: tabsCopy[generalIndex].fully_paid && bookingTab.fully_paid,
            membership: bookingTab.membership
        }
    }

    return sortedAndFilteredTabs(tabsCopy)
}

export function checkNameForBreadcrumbs(check, reservationResources) {
    if (check?.name && !!reservationResources) {
        return [reservationResources, check.name].join(' - ')
    } else {
        return `Check #${check.check_number}`
    }
}
