import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import TextareaAutosize from 'react-textarea-autosize'
import { errorsFor } from '@/components/Form/ErrorsHelper'
import { BookingStatus } from '../BookingStatus'
import { BasicBookingInfo } from '../BasicBookingInfo'
import { BookingActions } from '../BookingActions'
import { BookingLedgerActions } from '../../../EditBooking/ledger/BookingActions'
import { QuoteLedgerActions } from '../../../EditBooking/ledger/QuoteActions'
import { BookingDateTime } from '../BookingDateTime'
import { BookingPackage } from '../../../EditBooking/BookingPackage'
import EditBookingLedger from '../../../EditBooking/EditBookingLedger'
import QuoteExpirationDate from '@/features/Schedule/components/QuoteExpirationDate'
import Tabs from '@/features/Schedule/components/steps/Tabs'
import { selectTimeZone } from '@/features/Locations/locationSlice'
import { setStep } from '../../scheduleSlice'
import {
    fetchCustomerTypeCounts,
    selectBooking,
    selectBookingId,
    selectDate,
    selectStartTime,
    setBookingAttribute,
    updateBooking
} from '../../../EditBooking/editBookingSlice'
import { fetchCustomerTypes } from '@/features/CustomerTypes/customerTypeSlice'
import { fetchPackage } from "../../../Packages/packageSlice"
import { isBookingQuote, isQuoteExpiredOrRejected, isBookingCancelled } from '@/lib/Booking'
import { localDateToMoment } from '@/lib/CalendarTimes'
import { selectSelectedResourceType } from "../../../ResourceType/resourceTypeSlice"

export default function BookingSetupStep3({ userName, handleClose, handleViewParent, children }) {

    const dispatch            = useDispatch()
    const formMethods         = useFormContext()
    const booking             = useSelector(selectBooking)
    const bookingId           = useSelector(selectBookingId)
    const date                = useSelector(selectDate)
    const startTime           = useSelector(selectStartTime)
    const selectedTimeZone    = useSelector(selectTimeZone)
    const resourceType        = useSelector(selectSelectedResourceType)

    const [errors, setErrors]   = useState({})
    const [isChild, setIsChild] = useState(false)

    const handleChange = e => {
        dispatch(setBookingAttribute({ name: e.target.name, value: e.target.value }))
    }

    const handleDateChange = (datetime) => {
        dispatch(setBookingAttribute({ name: 'date', value: localDateToMoment(datetime, selectedTimeZone).toISOString() }))
    }

    const handleQuoteExpirationDateChange = (localizedDateTime) => {
        dispatch(setBookingAttribute({ name: 'quote_expires_at', value: localizedDateTime }))
    }

    const handleStartTimeChange = (datetime) => {
        dispatch(setBookingAttribute({ name: 'time', value: localDateToMoment(datetime, selectedTimeZone).toISOString() }))
    }

    const handleStartedAtChange = (datetime) => {
        dispatch(setBookingAttribute({ name: 'started_at', value: localDateToMoment(datetime, selectedTimeZone).toISOString() }))
    }

    /**
     * Loop through form errors and build up our own errors
     * object that gets passed down to all of the components
     */
    const onError = (formErrors) => {
        setErrors(errorsFor(formErrors))
    }

    const handleSave = skipClose => {
        dispatch(updateBooking(skipClose, false, () => {
            if (!skipClose && bookingId) {
                handleClose()
            }
        }))

        // dispatch(updateBooking(skipClose)).then(async data => {
        //     if (data.has_start_date_or_time_changed) {
        //         if (!isBookingQuote(data.booking) && await confirm('Do you want to let the customer know about the change?')) {
        //             axios.get(`/bookings/${data.booking.id}/notify_changed`)
        //         }

        //         dispatch(calendarSelectBooking(data.booking))
        //     }
        // })
        // .then(() => {
        //     if (!skipClose && bookingId) {
        //         handleClose()
        //     }
        // })
    }

    /**
     * Handle initial load
     * or unload of component
     */
    useEffect(() => {
        return () => {
            formMethods.reset()
            setErrors({})
        }
    }, [])

    useEffect(() => {
        if (booking) {
            dispatch(fetchPackage(`/packages/${booking.package_id}`, booking.package_version_id))
            dispatch(fetchCustomerTypeCounts())
            dispatch(fetchCustomerTypes(`/packages/${booking.package_id}/customer_types`, booking.package_version_id))
            if (booking.parent_booking) {
                setIsChild(true)
            }
        }
    }, [booking])

    return (
        <div className="drawer-scaffold">
            <div className="drawer-main">
                <div className="drawer-header-container">
                    <div className="drawer-header align-items-center pb-1">
                        <h2>
                            { booking.is_quote_expired ? 'EXPIRED' : /rejected/i.test(booking.quote_status) ? 'REJECTED' : 'Edit' }
                            <span className='d-none d-md-inline'> { isBookingQuote(booking) ? 'Quote' : 'Booking' }</span>
                        </h2>
                        {
                            !isBookingQuote(booking) && (
                                <BookingStatus isChild={isChild} />
                            )
                        }
                    </div>
                    <Tabs editMode />
                </div>
                <div className="drawer-main-content">
                    <div className="drawer-scroller">

                        <BasicBookingInfo
                            form={booking}
                            errors={errors}
                            newBooking={false}
                            dispatchAction={setBookingAttribute}
                        />

                        <div className="form-row">
                            {
                                !isBookingQuote(booking) && (
                                    <div className="col-4">
                                        <div className="form-group">
                                            <label>Company (optional)</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Company Name"
                                                name="company_name"
                                                value={booking.company_name || ""}
                                                onChange={handleChange} />
                                        </div>
                                    </div>
                                )
                            }

                            {
                                resourceType?.uses_scoring_app && !isBookingQuote(booking) && (
                                    <div className="col-8">
                                        <div className="form-group">
                                            <label>Scoring App Welcome Message</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Welcome Message"
                                                name="welcome_message"
                                                value={booking.welcome_message || ""}
                                                onChange={handleChange} />
                                        </div>
                                    </div>
                                )
                            }

                            <div className="col-12">
                                <div className="form-group">
                                    <label>Notes (Optional - {isBookingQuote(booking) ? "Will be shown in quote pdf/email" : "Not visible to customer"})</label>
                                    <TextareaAutosize
                                        cacheMeasurements
                                        className="form-control"
                                        name="note"
                                        placeholder={isBookingQuote(booking) ? "Thanks for your interest. Here is your quote." : null}
                                        value={booking.note || ""}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            {
                                isBookingQuote(booking) && <>
                                    <div className="col-12 col-lg-8">
                                        <div className="form-group">
                                            <label>Quote Cancellation Reason (Optional - Not visible to customer)</label>
                                            <TextareaAutosize
                                                cacheMeasurements
                                                className="form-control"
                                                name="quote_cancellation_reason"
                                                value={booking.quote_cancellation_reason || ""}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12 col-lg-4">
                                        <div className="form-group">
                                            <QuoteExpirationDate
                                                form={booking}
                                                errors={errors}
                                                onChange={handleQuoteExpirationDateChange}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                        </div>

                        {/*Need to edit this to pass in proper variables*/}
                        <BookingDateTime
                            form={booking}
                            errors={errors}
                            dispatchAction={setBookingAttribute}
                            date={date}
                            startTime={startTime}
                            handleDateChange={handleDateChange}
                            handleStartTimeChange={handleStartTimeChange}
                            handleStartedAtChange={handleStartedAtChange}
                            isChild={isChild}
                        />

                        <BookingPackage booking={booking} />
                    </div>
                </div>

                <BookingActions handleClose={handleClose}>
                    <button
                        type="button"
                        className="btn btn-outline text-white"
                        disabled={isBookingCancelled(booking)}
                        onClick={formMethods.handleSubmit(() => handleSave(true), onError)}
                    >
                        Save
                    </button>

                    <button
                        type="button"
                        className="btn btn-primary ml-2"
                        disabled={isBookingCancelled(booking)}
                        onClick={formMethods.handleSubmit(() => handleSave(false), onError)}
                    >
                        Save &amp; Close
                    </button>
                </BookingActions>
            </div>

            <div className="drawer-aside">
                <EditBookingLedger userName={userName} handleViewParent={handleViewParent} />

                {
                    isBookingQuote(booking)
                        ? !isQuoteExpiredOrRejected(booking) ? <QuoteLedgerActions onError={(formErrors) => onError(formErrors)} /> : null
                        : <BookingLedgerActions />
                }
            </div>

            { children }
        </div>
    )
}
