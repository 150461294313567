import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Alert from '@/features/Notifications/Alert'
import { selectAlerts, removeAlert } from '@/features/Notifications/notificationSlice'

export default function NotificationList({ position='bottom left' }) {
    const dispatch = useDispatch()
    const alerts   = useSelector(selectAlerts)

    const handleRemove = (id) => {
        dispatch(removeAlert(id))
    }

    return (
        <div id='notifications--container'
             className={position}
             aria-live="polite"
             aria-atomic="true"
        >
            <div id="notifications--container-inner"
                 className={`d-flex flex-column${/top/i.test(position) ? '-reverse' : ''}`}
            >

                { alerts.map((alert) => (
                    <Alert key={alert.id} data={alert} onHide={handleRemove} />
                 ))}

            </div>
        </div>
    )
}
