import React from 'react'
import Tooltip from '@/components/Form/Tooltip'
import Icon from '@/components/FontAwesomeIcon'
import { titleize } from '@/lib/String'

export default function HeaderRow({
    text=null,
    tooltip=null,
    isFoldable=false,
    isFolded=false,
    isGroupPricing=false,
    onChange=() => {}
}) {
    return (
        <tr>
            <th colSpan={isGroupPricing ? 1 : 2}>
                { text && titleize(text) }
                {
                    tooltip && (
                        <Tooltip place='right'>
                            <span dangerouslySetInnerHTML={{ __html: tooltip }} />
                        </Tooltip>
                    )
                }
            </th>
            <th className="text-right">
                {
                    isFoldable && (
                        <Icon
                            className={`fas fa-caret-${isFolded ? 'up' : 'down'} cursor-pointer`}
                            title={isFolded ? 'Show Breakdown' : 'Hide Breakdown'}
                            style={{ fontSize: '1.2rem' }}
                            onClick={onChange}
                        />
                    )
                }
            </th>
        </tr>
    )
}
