import React from "react"
import Input from "../Form/Input"
import FormRow from "../Form/FormRow"
import Select from "../Form/Select"

export default function MembershipTermFields({
    index=0,
    isLast=false,
    errors=[],
    disabledIfMembers=false,
    membershipTerm=null,
    onChange=() => {},
    onRemove=() => {},
}) {

    return !!membershipTerm && (
        <div className={`drawer-add-row ${isLast ? '' : 'border-bottom pb-3 mb-4'}`}>
            <FormRow>
                <Select
                    cols="3"
                    label="Term Length"
                    disabled={disabledIfMembers}
                    value={membershipTerm.term_length}
                    options={[
                        { display: "1 Month", value: 1 },
                        { display: "3 Months", value: 3 },
                        { display: "6 Months", value: 6 },
                        { display: "1 Year", value: 12 },
                    ]}
                    validation={{ [`term_length__${index}`]: { required: disabledIfMembers ? false : true }}}
                    errors={errors}
                    includeBlank
                    handleChange={(e) => onChange(e, index)}
                />

                <Input
                    label="Recurring Price"
                    placeholder=" "
                    type="number"
                    cols="3"
                    disabled={disabledIfMembers}
                    value={membershipTerm.price}
                    validation={{ [`price__${index}`]: { required: disabledIfMembers ? false : true }}}
                    errors={errors}
                    prepend
                    handleChange={(e) => onChange(e, index)}
                >
                    <div className="input-group-prepend">
                        <span className="input-group-text">$</span>
                    </div>
                </Input>

                <div className="col-1">
                    <button
                        children={<i className="fas fa-trash-alt" />}
                        type="button"
                        className="btn btn-danger text-white"
                        disabled={disabledIfMembers}
                        style={{ marginTop: '6px' }}
                        onClick={() => onRemove(index)}
                    />
                </div>
            </FormRow>

            <FormRow>
                <Input
                    cols="10"
                    label='Membership Term Description'
                    placeholder='"Save 20% as a member!"'
                    disabled={disabledIfMembers}
                    value={membershipTerm.short_description}
                    validation={{ [`short_description__${index}`]: { required: disabledIfMembers ? false : true }}}
                    errors={errors}
                    handleChange={(e) => onChange(e, index)}
                />
            </FormRow>

            <FormRow>
                <Input
                    name={`additional_fees__${index}`}
                    label="One Time Fee"
                    placeholder=" "
                    type="number"
                    cols="3"
                    disabled={disabledIfMembers}
                    value={membershipTerm.additional_fees}
                    req={false}
                    prepend
                    handleChange={(e) => onChange(e, index)}
                >
                    <div className="input-group-prepend">
                        <span className="input-group-text">$</span>
                    </div>
                </Input>

                <Input
                    name={`fees_label__${index}`}
                    label="Fee Description"
                    placeholder='"Initial account setup fee"'
                    cols="7"
                    disabled={disabledIfMembers}
                    value={membershipTerm.fees_label}
                    req={false}
                    handleChange={(e) => onChange(e, index)}
                />
            </FormRow>
        </div>
    )
}
