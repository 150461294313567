import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    selectCheckoutTab,
    selectCurrentCheck,
    selectPayFullAmount,
    selectAmountTowardsBalance,
    setAmountTowardsBalance,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

export default function AmountTowardsBalance() {
    const dispatch             = useDispatch()
    const check                = useSelector(selectCurrentCheck)
    const tab                  = useSelector(selectCheckoutTab)
    const amountTowardsBalance = useSelector(selectAmountTowardsBalance)
    const payFullAmount        = useSelector(selectPayFullAmount)

    const currentCheckBalance = useMemo(() => check.balance_cents / 100, [check])
    const currentTabBalance   = useMemo(() => !!tab ? (tab.balance_cents / 100) : null, [tab])

    const handleChange = (e) => {
        const amount = e.target.value

        if (amount === '' || /^(?:\d*\.\d{1,2}|\d+)$/i.test(amount)) {
            dispatch(setAmountTowardsBalance(amount ? amount : 0))
        }
    }

    useEffect(() => {
        if (payFullAmount) {
            if (!!tab) {
                dispatch(setAmountTowardsBalance(currentTabBalance))
            } else {
                dispatch(setAmountTowardsBalance(currentCheckBalance))
            }
        }
    }, [tab, payFullAmount, currentCheckBalance, currentTabBalance])

    return (
        <div className='row mt-3 align-items-center'>
            <div className={`col-4 text-right text-bold px-0 ${payFullAmount ? 'text-secondary' : ''}`}>
                Amount<br />
                Towards Balance
            </div>

            <div className='col-8'>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className={`input-group-text ${payFullAmount ? 'disabled' : 'text-white'}`}>$</span>
                    </div>
                    <input
                        type='number'
                        min='0'
                        max={tab ? currentTabBalance : currentCheckBalance}
                        step='.01'
                        placeholder='0.00'
                        name='payment_received'
                        className='form-control'
                        disabled={payFullAmount}
                        value={amountTowardsBalance || ''}
                        onChange={handleChange}
                    />
                </div>
            </div>
        </div>
    )
}
