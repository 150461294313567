import React from 'react'

export default function BasicCard({
    id=null,
    className='',
    data:card=null,
    layout='1-col',
    clickable=true,
    headerContent=null,
    footerContent=null,
    onClick=() => {},
    children
}) {

    return !!card && (
        <div id={id} className={`basic-card ${className}`.trim()}>
            <button
                type='button'
                className='btn basic-card'
                disabled={!clickable}
                onClick={(e) => onClick(card, e)}
            >
                <div className='basic-card--content'>
                    <div className='col--main'>
                        {
                            !!headerContent && (
                                <div className='basic-card--header mb-2'>
                                    { headerContent }
                                </div>
                            )
                        }

                        <div className='basic-card--body'>
                            <span className='text'>
                                { card?.name || card?.title || '' }
                            </span>
                        </div>
                    </div>

                    {
                        /^1-col-with-actions$/i.test(layout) && (
                            <div className='col--actions mt-2'>
                                { children }
                            </div>
                        )
                    }
                </div>

                {
                    !!footerContent && (
                        <div className='basic-card--footer'>
                            { footerContent }
                        </div>
                    )
                }
            </button>
        </div>
    )
}
