import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { numberToCurrency } from '@/lib/Number'
import {
  selectChangeDue,
  selectPaymentAmount,
  selectPaymentReceived,
  setPaymentReceived,
  setChangeDue,
} from './productSlice'

export default function ProductCashPaymentAmountReceived() {

  const dispatch        = useDispatch()
  const paymentAmount   = useSelector(selectPaymentAmount)
  const paymentReceived = useSelector(selectPaymentReceived)
  const changeDue       = useSelector(selectChangeDue)

  const handleChange = e => {
    const receivedAmount = Number.parseFloat(e.target.value || 0)

    dispatch(setPaymentReceived(receivedAmount))
    dispatch(setChangeDue(receivedAmount - (paymentAmount || 0)))
  }

  return <>
      <div className="col-12">
        <div className="form-group">
          <label>Amount Received</label>
          <div className="input-group">
              <div className="input-group-prepend">
                  <span className="input-group-text">$</span>
              </div>
              <input
                  type='number'
                  min='0'
                  step='.01'
                  name='payment_received'
                  className='form-control'
                  placeholder='Payment received'
                  value={paymentReceived || ""}
                  onChange={handleChange}
              />
          </div>
        </div>
      </div>

      {
        changeDue !== null && (
          <div className="col-12">
              <strong className='pr-2'>Change Due:</strong>
              <span className={changeDue < 0 ? 'text-danger' : 'text-success'}>
                {numberToCurrency(changeDue)}
              </span>
          </div>
        )
      }
  </>
}
