import React from 'react'

export default null

export function customerWaiverBadge(bookingActiveWaiver, customer, compact=false) {
    const signedWaivers = customer?.signed_waivers.filter(signedWaiver => signedWaiver.waiver_id === bookingActiveWaiver)

    if (signedWaivers.length > 0) {
        if (signedWaivers.find(signedWaiver => signedWaiver.is_active)) {
            return <span className={`badge badge-success ${compact ? 'py-1' : ''} mr-2`}>Valid Waiver</span>
        } else {
            return <span className={`badge badge-danger ${compact ? 'py-1' : ''} mr-2`}>Expired Waiver</span>
        }
    } else {
        return <span className={`badge badge-secondary ${compact ? 'py-1' : ''} mr-2`}>No Waiver</span>
    }

    // {
    //     parentBooking.waivers_required && (
    //         <div>
    //             {
    //                 customer.signed_waivers.find((signed_waiver) => signed_waiver.id === parentBooking.waiver_id && signed_waiver.is_valid) ? (
    //                     <span className="badge badge-success">Valid Waiver</span>
    //                 ) : (
    //                     <span className="badge badge-danger">Invalid Waiver</span>
    //                 )
    //             }
    //         </div>
    //     )
    // }

    //
    // {
    //     parentBooking.waivers_required && customer.signed_waivers.find((signed_waiver) => (
    //         signed_waiver.id === parentBooking.waiver_id && signed_waiver.is_valid
    //     )) ? <span className="badge badge-success">Valid Waiver</span>
    //        : <span className="badge badge-danger">Invalid Waiver</span>
    // }
}

export function simpleCustomerWaiverBadge(hasSignedWaiver=false, signedWaiverIsValid=false, compact=false) {
    if (hasSignedWaiver) {
        if (signedWaiverIsValid) {
            return <span className={`badge badge-success ${compact ? 'py-1' : ''} mr-2`}>Valid Waiver</span>
        } else {
            return <span className={`badge badge-danger ${compact ? 'py-1' : ''} mr-2`}>Expired Waiver</span>
        }
    } else {
        return <span className={`badge badge-secondary ${compact ? 'py-1' : ''} mr-2`}>No Waiver</span>
    }
}
