import React, { Children, useEffect, useState, useMemo, useRef } from 'react'
import { useFormContext } from 'react-hook-form'

export default function Wizard({
    steps=[],
    validate=false,
    className='',
    buttonGroupClassName='w-100 mt-5 pt-3 border-top border-color-gray3 d-flex',
    prevButtonText='Previous',
    prevButtonClassName='btn btn-outline-primary text-white',
    nextButtonText='Next',
    nextButtonClassName='btn btn-primary',
    completeButtonText='Submit',
    completeButtonClassName='btn btn-primary',
    isNextDisabled=false,
    onNext=() => {},
    onPrevious=() => {},
    onComplete=() => {},
    onChange=() => {},
    style=null,
    children,
}) {

    let trigger
    let isValid

    if (validate) {
        const { trigger:_trigger, formState: { isValid:_isValid } } = useFormContext()
        trigger = _trigger
        isValid = _isValid
    }

    const [currentStep, setCurrentStep]       = useState(0)
    const [firstStep, setFirstStep]           = useState(true)
    const [finalStep, setFinalStep]           = useState(false)
    const contentRef                          = useRef()
    const nextButtonRef                       = useRef()

    const stepContent = useMemo(() => (
        Children.toArray(children)[currentStep]
    ), [children, currentStep])

    const handlePrevious = () => {
        setCurrentStep(currentStep - 1)
        onPrevious()
    }

    const handleNext = () => {
        if (validate && !isValid) {
            trigger()
        } else if (validate && isValid) {
            setCurrentStep(currentStep + 1)
            onNext()
        } else {
            setCurrentStep(currentStep + 1)
            onNext()
        }
    }

    const handleKeyDown = (e) => {
        e.stopPropagation()

        if (e.key === 'Enter') {
            nextButtonRef.current.click()
        }
    }

    useEffect(() => {
        setFirstStep(currentStep === 0)
        setFinalStep(steps[currentStep] === steps[steps.length - 1])
    }, [currentStep, steps])

    useEffect(() => {
        onChange({
            currentStep: currentStep,
            isFirstStep: firstStep,
            isFinalStep: finalStep,
        })
    }, [currentStep, finalStep])

    useEffect(() => {
        if (!!contentRef.current) {
            contentRef.current.querySelectorAll('input, select, textarea')?.[0]?.focus()
        }
    }, [stepContent])

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown, false)

        return () => {
            document.removeEventListener('keydown', handleKeyDown, false)
        }
    }, [])

    return (
        <div className={`form-wizard ${className}`.trim()} style={style}>
            <div ref={contentRef}>
                { stepContent }
            </div>

            <div className={`form-wizard--buttons ${buttonGroupClassName}`.trim()}>
                {
                    !firstStep && (
                        <button
                            type='button'
                            children={prevButtonText}
                            className={`mr-auto ${prevButtonClassName}`}
                            onClick={handlePrevious}
                        />
                    )
                }

                {
                    finalStep ? (
                        <button
                            ref={nextButtonRef}
                            type='button'
                            children={completeButtonText}
                            className={`ml-auto ${completeButtonClassName}`}
                            disabled={isNextDisabled}
                            onClick={onComplete}
                        />
                    ) : (
                        <button
                            ref={nextButtonRef}
                            type='button'
                            children={nextButtonText}
                            className={`ml-auto ${nextButtonClassName}`}
                            disabled={isNextDisabled}
                            onClick={handleNext}
                        />
                    )
                }
            </div>
        </div>
    )
}
