import React, { useEffect, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isBookingCancelled, isBookingComplete } from '@/lib/Booking'
import { setBookingAttribute } from '@/features/EditBooking/editBookingSlice'
import { selectPackage, selectPackages, setPackage } from '@/features/Packages/packageSlice'
import { selectStep } from '@/features/Schedule/scheduleSlice'

export function BookingPackage({ booking }) {

    const dispatch      = useDispatch()
    const pkg           = useSelector(selectPackage)
    const packages      = useSelector(selectPackages)
    const drawerStep    = useSelector(selectStep)
    const origPackageId = useRef(null)

    // hide packages that shouldn't be visible in the schedule drawer
    // UNLESS the reservation was booked using it via the public modal
    const filteredPackages = useMemo(() => (
        packages.filter((p) => (
            !p.show_package_in_drawer
            && p.id !== pkg.id
            && Number(drawerStep) === 3 ? false : true
        ))
    ), [packages])

    const handleChange = (e) => {
        const value   = e.target.value.split('|')
        const id      = Number.parseInt(value[0])
        const version = value[1] === '' ? null : Number.parseInt(value[1])

        const selectedPackage = packages.filter((p) => (p.id === id && p.version === version))[0]

        dispatch(setBookingAttribute({ name: 'package_id', value: id }))
        dispatch(setBookingAttribute({ name: 'package_version_id', value: version }))
        dispatch(setPackage(selectedPackage))
    }

    useEffect(() => {
        if (pkg?.id && origPackageId.current === null) {
            origPackageId.current = pkg?.id
        }
    }, [pkg])

    return (
        <div className="inline-section">

            <label>Selected Package</label>

            <select
                name="package_id"
                className="custom-select w-100"
                value={`${pkg.id}|${pkg.version || ''}`}
                onChange={handleChange}
                disabled={
                    isBookingCancelled(booking)
                    || isBookingComplete(booking)
                    || booking.is_child
                    || booking?.check?.has_adv_pos_booking_payment_been_received
                    || booking?.check?.has_booking_been_split
                }
            >
                {
                    filteredPackages.map((p,i) => (
                      <option
                        key={`package_${p.id}_${i}`}
                        value={`${p.id}|${p.version || ''}`}
                      >
                          { p.name }
                          {
                              // if multiple versions of the same package exist in
                              // the menu, then display the version next to the name
                              p.id === origPackageId.current
                              && packages.filter((p) => p.id === origPackageId.current).length > 1
                              && (
                                  !p.version ? ' — (current)' : ` — (v.${p.version})`
                              )
                          }
                        </option>
                    ))
                }
            </select>

        </div>
    )
}
