import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentCheck, configureModal, openModal } from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'
import CenteredText from '@/features/AdvancedPointOfSale/components/CenteredText'
import CheckCard from '@/features/AdvancedPointOfSale/components/cards/CheckCard'
import StaffName from '@/features/AdvancedPointOfSale/components/StaffName'
import CheckAndChit from '@/features/AdvancedPointOfSale/components/ordering/CheckAndChit'

export default function OrderSidebar({ className=null }) {

    const dispatch = useDispatch()
    const check    = useSelector(selectCurrentCheck)

    const handleChangeStaff = () => {
        dispatch(configureModal({
            modal: 'changeStaff',
            config: { check: check }
        }))

        dispatch(openModal('changeStaff'))
    }

    return (
        <aside id="advanced-pos--terminal-sidebar--order" className={className}>
            {
                !!check ? <>
                    <CheckCard
                        key={check.id}
                        id='advanced-pos--terminal-sidebar--order--check-card'
                        data={check}
                        showStaff={false}
                        showResourceTypeName={true}
                        clickable={false}
                    />

                    <div id='advanced-pos--terminal-sidebar--order--staff'>
                        <span className='info'>
                            <StaffName text={check.server.name} />
                        </span>

                        <button
                            children='Change'
                            type='button'
                            className='btn btn-link text-blue3 text-bold pl-2 pr-0'
                            onClick={handleChangeStaff}
                        />
                    </div>

                    <CheckAndChit
                        id='advanced-pos--terminal-sidebar--order--check-and-chit'
                    />
                </> : (
                    <CenteredText text='Empty' textClassName='text-gray2' />
                )
            }
        </aside>
    )
}
