import React, { useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import HeaderRow from '@/features/Schedule/components/ledger/HeaderRow'
import { selectOpen as selectScheduleDrawerOpen } from '@/features/Schedule/scheduleSlice'
import { selectOpen as selectPaymentModalOpen, selectAmountType, selectCustomersToPayFor, selectCustomerTypeCounts } from '@/features/BookingPayment/bookingPaymentSlice'
import { selectPricingType } from '@/features/Packages/packageSlice'
import { ledgerDiscountDependenciesFor } from '@/lib/Booking'
import { numberToCurrency } from '@/lib/Number'
import { humanizedLabel } from '@/lib/String'
import { humanizedDiscount } from '@/lib/Pricing'
import { debug } from '@/lib/Debug'

export default function Discounts({
    header=null,
    discounts=[],
    customerTypeTotals=[],
    isGroupPricing=false,
    initiallyFolded=true,
    paymentMode=false,
    participants
}) {

    const packagePricingType                = useSelector(selectPricingType)
    const scheduleDrawerOpen                = useSelector(selectScheduleDrawerOpen)
    const paymentModalOpen                  = useSelector(selectPaymentModalOpen)
    const paymentModalAmountType            = useSelector(selectAmountType)
    const paymentModalCustomersToPayFor     = useSelector(selectCustomersToPayFor)
    const paymentModalCustomerTypesToPayFor = useSelector(selectCustomerTypeCounts)

    const [isFoldable, setIsFoldable] = useState(null)
    const [isFolded, setIsFolded]     = useState(initiallyFolded)
    const filteredDiscounts           = useMemo(() => discounts.filter((d) => !Boolean(d.flat_rate)), [discounts])
    const className                   = isFoldable && !isFolded ? 'd-table-row' : 'd-none'
    let calculatedTotal               = 0.0

    const shouldDisplay = (
        !!customerTypeTotals
        && (!!customerTypeTotals?.types?.length || discounts.length > 0)
        && (
            (!paymentMode && scheduleDrawerOpen)
            || (
                // if we're paying via the payment modal...
                paymentMode && paymentModalOpen
                // and we've chosen per participant...
                && /^(participant|participant_uniform)$/i.test(paymentModalAmountType)
                // and if we have customers chosen to pay for (regardless of pricing scheme)...
                && (paymentModalCustomersToPayFor > 0 || Object.values(paymentModalCustomerTypesToPayFor).reduce((p,c) => (p + c), 0) > 0)
            )
        )
    )

    useEffect(() => {
        if (debug && console) {
            console.log('> DISCOUNTS')
            console.log({ discounts, filteredDiscounts, isGroupPricing, customerTypeTotals })
        }
    }, [discounts, filteredDiscounts])

    useEffect(() => {
        setIsFoldable(
            customerTypeTotals?.types?.length > 0
                && customerTypeTotals?.types?.[0].isGroup !== true
                && filteredDiscounts.length > 0
                && !isGroupPricing
        )
    }, [filteredDiscounts, customerTypeTotals])

    return shouldDisplay && <>
        <HeaderRow
            text={header}
            isFoldable={isFoldable}
            isFolded={isFolded}
            isGroupPricing={isGroupPricing}
            onChange={() => setIsFolded(!isFolded)}
        />

        {
            // -----------------------------------------------------
            // FOLDABLE CONTENT
            // -----------------------------------------------------
            // display discounts for each customer type
            filteredDiscounts?.length === 1
                && customerTypeTotals?.types?.length > 0
                && (filteredDiscounts?.[0].discount_record?.amount || filteredDiscounts?.[0].amount)
                && customerTypeTotals?.types?.map((customerType, index) =>
            {
                let [dollar, percentage, shouldDiscount] = ledgerDiscountDependenciesFor(
                    customerType.id,
                    customerTypeTotals?.types?.map(t => t.id),
                    filteredDiscounts[0],
                    isGroupPricing
                )

                let quantity = paymentMode && paymentModalCustomersToPayFor ? paymentModalCustomersToPayFor : customerType.quantity
                let total    = isGroupPricing ? Number.parseFloat(customerType.price) : (Number.parseFloat(customerType.price) * quantity)

                // remember a running total to display below the foldable content
                if (shouldDiscount) {
                    if (paymentMode && dollar !== null && /uniform_pricing/i.test(packagePricingType)) {
                        const discount  = (((dollar * -100) / (participants / 1)) / 100)
                        calculatedTotal = calculatedTotal + (discount * quantity)

                        // prevent it from calculating/displaying $-off
                        // per participant in the foldable area
                        shouldDiscount = false
                        if (isFoldable) { setIsFoldable(false) }

                    } else if (!paymentMode && dollar !== null) {
                        // in the case of per participant pricing with a simple cash discount
                        // we need to only calculate this once since we DO NOT discount per
                        // customer but instead off of the entire booking total
                        if ((index + 1) === customerTypeTotals.types.length) {
                            calculatedTotal = calculatedTotal + dollar
                        }

                        // prevent it from calculating/displaying $-off
                        // per participant in the foldable area
                        shouldDiscount = false
                        if (isFoldable) { setIsFoldable(false) }

                    } else {
                        calculatedTotal = calculatedTotal + (total * percentage)
                    }
                }

                return <React.Fragment key={`ctt-${index}-container`}>
                    {
                        shouldDiscount && (
                            <tr className={className}>
                                <th
                                    colSpan={isGroupPricing ? 2 : 1}
                                    className="text-left align-top"
                                    dangerouslySetInnerHTML={{ __html: humanizedLabel(customerType.name, true) }}
                                />
                                {
                                    // Only show "AMOUNT x QUANTITY" for non-group pricing
                                    !isGroupPricing && <th className="text-right align-top">
                                        { !!customerType?.price && !!percentage &&
                                            numberToCurrency(Number.parseFloat(customerType.price) * percentage)
                                        }
                                        &nbsp;
                                        { !!quantity &&
                                            <span className="text-lowercase">x {quantity}</span>
                                        }
                                    </th>
                                }
                                <th className="text-right align-top text-dark">
                                    { !!total && !!percentage &&
                                        numberToCurrency(total * percentage)
                                    }
                                </th>
                            </tr>
                        )
                    }

                    {
                        // -----------------------------------------------------
                        // SEPARATOR AFTER THE LAST ITEM
                        // -----------------------------------------------------
                        isFoldable && (index + 1) === customerTypeTotals?.types?.length && (
                            <tr className={className}>
                                <td colSpan={isGroupPricing ? 2 : 3}><hr className="my-1 py-0" /></td>
                            </tr>
                        )
                    }
                </React.Fragment>
            })
        }

        {
            // -----------------------------------------------------
            // SUMMARY
            // -----------------------------------------------------
            filteredDiscounts.map((d, index) => {
                const discount = humanizedDiscount(d)
                const discountRecord = d.discount_record

                let guestDiscount = 0
                let memberDiscount = 0

                if (discount.name === "Membership discount") {
                    if (discountRecord) { // edit booking
                        guestDiscount = discountRecord.reduce((acc, record) => acc + parseFloat(record.guest_discount || 0), 0)
                        memberDiscount = discountRecord.reduce((acc, record) => acc + parseFloat(record.discount_amount || 0), 0)
                    } else { // new booking
                        guestDiscount = parseInt(d.guest_discount_cents) / 100
                        memberDiscount = (parseInt(d.discount_cents) / 100) - guestDiscount
                    }
                }

                return (
                    <React.Fragment key={`d-${index}`}>
                        {discount.name === "Membership discount" ?
                            <tr>
                                <th
                                    colSpan="2"
                                    className="align-top text-uppercase w-75"
                                >{discount.name}</th>
                                <th className="align-top text-right text-dark">
                                    { numberToCurrency(memberDiscount * -1) }
                                </th>
                            </tr>
                        :
                            <tr>
                                <th
                                    colSpan={isGroupPricing ? 1 : 2}
                                    className="align-top text-uppercase w-75"
                                    dangerouslySetInnerHTML={{ __html: humanizedLabel(`${discount.name} ${discount.type}`, true, 24) }}
                                />
                                <th className="align-top text-right text-dark">
                                    { numberToCurrency(calculatedTotal) }
                                </th>
                            </tr>
                        }
                        {guestDiscount > 0 &&
                            <tr>
                                <th
                                    colSpan="2"
                                    className="align-top text-uppercase w-75"
                                >Guest Discount</th>
                                <th className="align-top text-right text-dark">
                                    { numberToCurrency(guestDiscount * -1) }
                                </th>
                            </tr>
                        }
                    </React.Fragment>
                )
            })
        }
    </>
}
