import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCustomerTypeCounts, selectCustomerTypeCounts } from '@/features/BookingPayment/bookingPaymentSlice'

export default function CustomerIncrementer({ customerType, maxCustomerTypeCounts }) {
  const dispatch = useDispatch()

  const customerTypeCounts = useSelector(selectCustomerTypeCounts)

  const handleIncrementer = (customerTypeId, value) => {
    if (value < 0 || value > maxCustomerTypeCounts[customerTypeId]) {
      return
    }

    const newCustomerTypeCounts = { ...customerTypeCounts, [customerTypeId]: value }

    dispatch(setCustomerTypeCounts(newCustomerTypeCounts))
  }

  return (
    <div key={customerType.id}>
      <div className="container">
        <div className="row align-items-center mb-3">
          <div className="col-sm-5 px-0">
            <h5 className="mb-0 xbm-customer-type">{customerType.name}</h5>
          </div>
          <div className="col-sm-7 px-0">
            <div className="xbm-incrementer-container">
              <div className="input-group">
                <div
                  className="input-group-prepend"
                  onClick={() =>
                    handleIncrementer(
                      customerType.customer_type_id,
                      customerTypeCounts[customerType.customer_type_id] - 1
                    )
                  }
                >
                  <div className="input-group-text">
                    <i className="far fa-minus"></i>
                  </div>
                </div>

                <input
                  type="number"
                  min={0}
                  className={'form-control text-center'}
                  value={Number(customerTypeCounts[customerType.customer_type_id]).toString() || 0}
                  onChange={e =>
                    handleIncrementer(
                      customerType.customer_type_id,
                      parseInt(e.target.value)
                    )
                  }
                />

                <div
                  className="input-group-append"
                  onClick={() =>
                    handleIncrementer(
                      customerType.customer_type_id,
                      customerTypeCounts[customerType.customer_type_id] + 1
                    )
                  }
                >
                  <div className="input-group-text">
                    <i className="far fa-plus"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
