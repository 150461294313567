import React, { useRef, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { validationPatterns } from '@/lib/Errors'
import FormRow from '@/components/Form/FormRow'
import Input from '@/components/Form/Input'
import { selectLocation } from '@/features/Locations/locationSlice'
import { selectTotal, selectDeposit } from '@/features/Pricing/pricingSlice'
import { addAlert } from '@/features/Notifications/notificationSlice'
import {
    selectGiftCards,
    selectPaymentType,
    setGiftCards,
    setBookingFormValue,
    setPaymentReady,
    setPaymentMethod,
    validateGiftCard,
    fetchGiftCardBalance,
} from '@/features/Bookings/bookingSlice'
import {
    setGiftCards as setMemberGiftCards,
    selectGiftCards as selectMemberGiftCards,
    selectGiftCardNumber,
    selectLocationId,
    setPaymentReady as setMemberPaymentReady,
    selectTotal as selectMemberTotal,
} from '@/features/Members/memberSlice'

export default function GiftCardFields({
    booking,
    errors,
    handleChange,
    handleManualChange,
    handleOnError,
    customAmountToPay,
}) {

    const buttonCheckRef = useRef()

    const {
        trigger,
        setError,
        clearErrors,
        formState,
    } = useFormContext()

    const dispatch = useDispatch()

    // booking payment
    const location     = useSelector(selectLocation)
    const giftCards    = useSelector(selectGiftCards)
    const packageTotal = useSelector(selectTotal)
    const paymentType  = useSelector(selectPaymentType)
    const deposit      = useSelector(selectDeposit)

    // membership payment
    const memberGiftCards = useSelector(selectMemberGiftCards)
    const giftCardNumber  = useSelector(selectGiftCardNumber)
    const locationId      = useSelector(selectLocationId)
    const memberTotal     = useSelector(selectMemberTotal)

    const [loaded, setLoaded]     = useState(false)
    const [checking, setChecking] = useState(false)

    /**
     * Handle initial mount/unmount
     * of the component
     */
    useEffect(() => {
        if (!loaded) {
            setLoaded(true)
        }

        return () => {
            dispatch(setBookingFormValue({ name: 'giftCardNumber', value: '' }))
            resetForm()
        }
    }, [])

    useEffect(() => {
        resetForm()
    }, [paymentType])

    /**
     * Return the amount that will be paid towards this package. This changes based on if the user
     * is paying full price or deposit only.
     */
    const amountToPay = booking ? customAmountToPay || (paymentType === 'full_amount' ? packageTotal : deposit) : memberTotal

    const resetForm = () => {
        clearErrors('giftCardNumber')
        clearErrors('paymentReady')
        handleOnError(formState.errors)
    }

    const handleBalanceCheckError = () => {
        setChecking(false)
        handleOnError(formState.errors)
        return false
    }

    /**
     * Step 1 & 2:
     * Handle checking the validity of the card
     * and whether or not it has an available balance
     */
    const handleBalanceCheck = async () => {
        // reset validity/balance before every check
        resetForm()
        setChecking(true)

        // -------------------------------------------------------------
        // Step 1: Validate the number against a regex pattern
        // -------------------------------------------------------------
        const validNumber = await trigger('giftCardNumber', { shouldFocus: true })

        if (!validNumber) { return handleBalanceCheckError() }

        // -------------------------------------------------------------
        // Step 2: Validate the card number against the API
        // -------------------------------------------------------------

        if ((giftCards || memberGiftCards).some(giftCard => giftCard.cardNumber.toLowerCase() === (booking?.giftCardNumber || giftCardNumber).toLowerCase())) {
            setError('giftCardNumber', { type: 'manual', message: 'Already applied.' })
            return handleBalanceCheckError()
        }

        dispatch(validateGiftCard(booking?.giftCardNumber || giftCardNumber, location?.id || locationId))
        .then(response => {
            if (response) {
                /**
                 * Step 3: Fetch the card's balance and apply
                 */
                applyGiftCard(booking?.giftCardNumber || giftCardNumber)
            } else {
                setError('giftCardNumber', { type: 'manual', message: 'Invalid or unknown card.' })
            }
            handleBalanceCheckError()
        })
    }

    const applyGiftCard = giftCardNumber => {
        dispatch(fetchGiftCardBalance(giftCardNumber))
        .then(data => {
            if (isNaN(data.balance)) {
                dispatch(addAlert({ type: 'error', text: 'An error occurred while checking gift card balance.' }))
                return handleBalanceCheckError()
            }

            // short-circuit when card is empty
            if (data.balance <= 0) {
                setError('giftCardNumber', { type: 'manual', message: 'The card has an empty balance' })
                return handleBalanceCheckError()
            }

            const amount = amountToPay * 100
            let amountAfterGiftCard

            if ((giftCards || memberGiftCards).length === 0) {
                amountAfterGiftCard = amount
            } else {
                const appliedTotal = (giftCards || memberGiftCards).reduce((sum, giftCard) => {
                    return sum + giftCard.appliedAmount
                }, 0)
                amountAfterGiftCard = amount - appliedTotal
            }

            if (amount > 0 && amountAfterGiftCard === 0) {
                return
            } else {
                const action = booking ? setGiftCards : setMemberGiftCards

                if (data.balance < amountAfterGiftCard) {
                    const newGiftCard = { ...data, appliedAmount: data.balance }
                    dispatch(action({ type: 'add', newGiftCard: newGiftCard }))
                    dispatch(setPaymentMethod('multiple'))

                } else if (data.balance >= amountAfterGiftCard) {
                    const newGiftCard = { ...data, appliedAmount: amountAfterGiftCard }
                    dispatch(action({ type: 'add', newGiftCard: newGiftCard }))
                    clearErrors('paymentReady')
                    dispatch(setPaymentMethod('gift_card'))
                    dispatch(booking ? setPaymentReady(true) : setMemberPaymentReady(true))
                }

                handleManualChange("giftCardNumber", "")
                clearErrors('giftCardNumber')
                handleOnError(formState.errors)
                return
            }
        })
    }

    return <>
        <FormRow>
            <Input
                cols="12 col-sm-8"
                className='text-uppercase'
                type="text"
                label="Gift Card Number"
                placeholder="11111-22222-33333-44444"
                mask="*****-*****-*****-*****"
                value={booking?.giftCardNumber || giftCardNumber}
                errors={errors}
                append={true}
                req={false}
                autoComplete="off"
                validation={{ 'giftCardNumber': {
                    pattern: validationPatterns.giftCard.number,
                }}}
                handleChange={handleChange}
                handleKeyDown={(e) => { if (e.key === 'Enter') { buttonCheckRef.current.click() }}}
            >
                <div className="input-group-append">
                    <button
                        ref={buttonCheckRef}
                        type="button"
                        className="btn btn-primary"
                        onClick={handleBalanceCheck}
                    >
                        { checking ? 'Checking...' : 'Apply' }
                    </button>
                </div>
            </Input>
        </FormRow>
    </>
}
