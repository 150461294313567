import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useConfirm } from '@/lib/useConfirmHook'
import { numberToCurrency } from '@/lib/Number'
import { createRefund, fetchBookingPayments } from './bookingHistorySlice'
import { selectBooking } from "../EditBooking/editBookingSlice"
import { addAlert } from '@/features/Notifications/notificationSlice'
import { debug } from '@/lib/Debug'

export default function RefundForm({ payment, collapseElement, index, onClose }) {

    const dispatch    = useDispatch()
    const { confirm } = useConfirm()
    const booking     = useSelector(selectBooking)

    const [amount, setAmount] = useState(null)
    const [notes, setNotes]   = useState(null)
    const [email, setEmail]   = useState(null)

    const handleRefundToCreditCard = ()  => handleSubmit('credit_card')
    const handleRefundToGiftCard   = ()  => handleSubmit('gift_card')
    const handleRefundToCash       = ()  => handleSubmit('cash')
    const handleSetRefundAmount    = (e) => setAmount(e.target.value)
    const handleSetEmailAddress    = (e) => setEmail(e.target.value)
    const handleSetNotes           = (e) => setNotes(e.target.value)

    const handleSubmit = async (refundMethod) => {
        if (!amount) {
            return dispatch(addAlert({ type: 'warning', text: 'You must enter a refund amount.' }))
        } else if (parseFloat(amount) > parseFloat(payment.amount_refundable_cents / 100)) {
            return dispatch(addAlert({ type: 'warning', text: 'Refund amount cannot be more than the available amount.' }))
        }

        if (!(await confirm('Are you sure you want to submit this refund?'))) {
            return
        }

        const amountCents = Number.parseInt(Math.round(Number.parseFloat(amount) * 100))

        dispatch(createRefund(payment.id, amountCents, email, notes, refundMethod))
        .then(({ data }) => {
            if (debug && console) { console.log('Refund response: ', data) }

            if (!data.success) {
                dispatch(addAlert({ type: 'error', text: data.message }))
                return
            }

            dispatch(addAlert({
                type: 'success',
                text: `Refund ${payment.payment_processor_id == 2 ? 'Requested' : 'Processed'}!`
            }))

            dispatch(fetchBookingPayments(booking.id)).then(() =>  {
                setAmount(null)
                setNotes(null)
                setEmail(null)
                collapseElement.current[index].classList.remove("show")
                onClose()
            })
        })
    }

    useEffect(() => {
        if (payment?.amount_refundable_cents > 0) {
            setAmount(Number.parseFloat(payment.amount_refundable_cents / 100).toFixed(2))
        }

        setEmail(payment?.email)
    }, [payment])

    return (
        <div className="border-top pt-4 pb-3 px-5">
            <div className="row">
              <div className="col-3 align-middle text-gray3">
                    <strong className="d-block w-100 mt-3">AVAILABLE TO REFUND</strong>
                    <strong className="d-block w-100 text-success">{numberToCurrency(payment.amount_refundable_cents / 100)}</strong>
                </div>
                <div className="col-3">
                    <div className="form-group">
                        <label className="req">Refund Amount</label>
                        <input
                            className="form-control"
                            type="number"
                            placeholder="Refund Amount"
                            step="1"
                            min="0"
                            name="amount"
                            value={amount || ''}
                            onChange={handleSetRefundAmount}
                        />
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <label>Refund Email</label>
                        <input
                            className="form-control"
                            name="email"
                            type="text"
                            placeholder="Email Address"
                            value={email || ''}
                            onChange={handleSetEmailAddress}
                        />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="form-group">
                        <label>Notes (Not Visible to Customer)</label>
                        <textarea
                            className="form-control"
                            name="notes"
                            value={notes || ''}
                            onChange={handleSetNotes}
                        />
                    </div>
                </div>
            </div>

            <div className="row justify-content-center">
                {
                    /^(terminal|credit)$/i.test(payment.transaction_type) &&
                        <div className="col-4">
                            <div className="form-group">
                                <button className="btn btn-primary w-100" onClick={handleRefundToCreditCard}>
                                    Refund To Credit Card
                                </button>
                            </div>
                        </div>
                }

                {
                    (/cash|check/i.test(payment.transaction_type)) &&
                        <div className="col-4">
                            <div className="form-group">
                                <button className="btn btn-primary w-100" onClick={handleRefundToCash}>
                                    Refund To Cash
                                </button>
                            </div>
                        </div>
                }

                <div className="col-4">
                    <div className="form-group">
                        <button className="btn btn-outline w-100 text-white" onClick={handleRefundToGiftCard}>
                            Refund to Gift Card
                        </button>
                    </div>
                </div>

                <div className="col-4">
                    <div className="form-group">
                        <button
                            children='Cancel'
                            className="btn btn-secondary w-100 text-white"
                            data-toggle="collapse"
                            data-target={`#collapseRefund-${payment.id}`}
                            onClick={(e) => { onClose(); return e; }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
