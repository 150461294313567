import { configureStore } from "@reduxjs/toolkit"
import adyenSliceReducer from '../features/Adyen/adyenSlice'
import productSliceReducer from "../features/Products/productSlice"
import managerCodeSliceReducer from "../features/ManagerCodes/managerCodeSlice"
import memberProductCodeDiscount from "../features/MemberProductDiscount/MemberProductDiscountSlice"
import sessionSliceReducer from "../features/Session/sessionSlice"
import terminalSliceReducer from "../features/Terminal/terminalSlice"
import notificationSliceReducer from "../features/Notifications/notificationSlice"

export default configureStore({
    reducer: {
       adyen:                 adyenSliceReducer,
       products:              productSliceReducer,
       managerCode:           managerCodeSliceReducer,
       memberProductDiscount: memberProductCodeDiscount,
       session:               sessionSliceReducer,
       terminal:              terminalSliceReducer,
       notifications:         notificationSliceReducer,
    }
})
