import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  selectParticipants,
  selectPreloaded,
  selectStep,
  setReminderAcknowledged,
  setStep,
  setParticipants,
  resetCustomerTypes,
  setDuration,
  setDate,
  setTime,
  setGiftCards,
  setPaymentReady,
  setGiftCardNumber,
  removePromoCode
} from '@/features/Bookings/bookingSlice'

import { selectAllowMembersOnly } from '@/features/Packages/packageSlice'
import { selectResourceType } from '@/features/ResourceType/resourceTypeSlice'
import { customerVerbiage, formatParticipants } from '@/features/Bookings/utils'

export default function LedgerPartySize({ clearCalendars }) {

    const dispatch         = useDispatch()
    const preloaded        = useSelector(selectPreloaded)
    const step             = useSelector(selectStep)
    const participants     = useSelector(selectParticipants)
    const resourceType     = useSelector(selectResourceType)
    const allowMembersOnly = useSelector(selectAllowMembersOnly)

    if (step === '1') {
        return null
    }

    return (
        <div className="xbm-ledger-section">
            <div className="xbm-ledger-section-action-label">
                <div className="xbm-ledger-section-action-label-label text-capitalize">
                    { `${formatParticipants(participants)} ${customerVerbiage(participants, resourceType, allowMembersOnly)}` }
                </div>
                {
                        <div className="xbm-ledger-section-action-label-action">
                            <button
                                children='Change'
                                className="as-link"
                                onClick={() => {
                                    clearCalendars()

                                    if (!preloaded) {
                                      dispatch(setDuration(null))
                                      dispatch(setDate(null))
                                      dispatch(setTime(null))
                                    }

                                    dispatch(setParticipants(null))
                                    dispatch(resetCustomerTypes())
                                    dispatch(setReminderAcknowledged(null))
                                    dispatch(setGiftCards({ type: 'clear' }))
                                    dispatch(setGiftCardNumber(""))
                                    dispatch(setPaymentReady(false))
                                    dispatch(removePromoCode())
                                    dispatch(setStep('1'))
                                }}
                            />
                        </div>
                }
            </div>
        </div>
    )
}
