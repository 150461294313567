import React, { useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { setMemberInformationField, setCreateMember, setCards } from '@/features/Members/memberSlice'
import { selectLocation } from '@/features/Locations/locationSlice'
import Input from '@/components/Form/Input'
import { errorsFor } from '@/components/Form/ErrorsHelper'
import { validationPatterns } from '@/lib/Errors'

export default function VerifyEmail({ setStep }) {
  const formMethods = useForm()
  const dispatch = useDispatch()
  const location = useSelector(selectLocation)

  const onError = (formErrors) => setErrors(errorsFor(formErrors))

  const [errors, setErrors] = useState({})
  const [email, setEmail] = useState('')

  const handleSubmit = () => {
    axios.get(`/memberships/verify_email`, {
      params: {
        email: email,
        company_id: location.company_id,
      }
    })
    .then(({ data }) => {
      setErrors({})

      if (!data.member) {
        setStep('member_information')
        dispatch(setCreateMember(true))
        dispatch(setMemberInformationField({ name: 'email', value: email }))
      } else if (data.member.active_membership) {
        setErrors({ email: ['An active membership already exists for this member.'] })
      } else {
        dispatch(setMemberInformationField({ name: 'id', value: data.member.id }))
        dispatch(setMemberInformationField({ name: 'firstName', value: data.member.first_name }))
        dispatch(setMemberInformationField({ name: 'lastName', value: data.member.last_name }))
        dispatch(setMemberInformationField({ name: 'birthdate', value: data.member.birthdate }))
        dispatch(setMemberInformationField({ name: 'phone', value: data.member.phone }))
        dispatch(setMemberInformationField({ name: 'email', value: data.member.email }))
        dispatch(setMemberInformationField({ name: 'zip', value: data.member.zip_code }))
        dispatch(setCards(data.cards_on_file))
        setStep('member_information')
      }
    })
  }

  return (
    <FormProvider {...formMethods} >
      <div className='row w-100 mx-auto'>
        <div className='card col-12 col-md-5'>
            <h5 className='border-bottom pb-2 mb-2'>Member Information</h5>

            <div className='form-group'>
              <form onSubmit={formMethods.handleSubmit(handleSubmit, onError)} className='mt-2'>
                <Input
                  type='email'
                  className='form-control'
                  placeholder='Email Address'
                  value={email}
                  withWrapper={false}
                  validation={{ 'email': {
                    required: true,
                    pattern: validationPatterns.email,
                  }}}
                  append
                  hideLabel
                  autoFocus
                  errors={errors}
                  handleChange={e => setEmail(e.target.value)}
                >
                  <div className='input-group-append'>
                    <button
                      children='Submit'
                      type='submit'
                      className='btn btn-primary'
                    />
                  </div>
                </Input>
              </form>
          </div>
        </div>
      </div>
    </FormProvider>
  )
}
