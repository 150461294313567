import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { selectReloadTrigger, selectReloadDuration } from '@/features/Notifications/notificationSlice'

export default function ForceReloadAlert() {

    const trigger  = useSelector(selectReloadTrigger)
    const duration = useSelector(selectReloadDuration)

    const [countdown, setCountdown] = useState(null)
    const [minimized, setMinimized] = useState(false)
    const expiresAt                 = useRef(null)
    const timer                     = useRef(null)
    const almostExpired             = useRef(false)

    const handleReload = () => {
        window.location.reload()
    }

    const calculateCountdown = () => {
        const now      = moment()
        const diff     = moment(expiresAt.current).diff(now)
        const duration = moment.duration(diff)

        let mins = Number.parseInt(duration.minutes())
        let secs = Number.parseInt(duration.seconds())

        if (secs < 10 && secs >= 0) {
            secs = `0${secs}`
        }

        if (diff <= 60_000) {
            almostExpired.current = true
        }

        if (!isNaN(mins) && !isNaN(mins) && mins >= 0 && secs >= 0) {
            setCountdown(`${mins}:${secs}`)
        }

        if (mins <= 0 && secs <= 0) {
            handleReload()
            return
        }
    }

    const startTimer = () => {
        if (!timer.current) {
            expiresAt.current = moment().add(parseInt(duration, 10), 'ms').add(100, 'ms')
            calculateCountdown()
            timer.current = window.setInterval(() => calculateCountdown(), 1000)
        }
    }

    const stopTimer = () => {
        window.clearInterval(timer.current)
        timer.current     = null
        expiresAt.current = null
    }

    useEffect(() => {
        return () => stopTimer()
    }, [])

    useEffect(() => {
        trigger === true ? startTimer() : stopTimer()
    }, [trigger])

    return !!countdown && (
        <div
            id='force-reload--bar'
            className={`d-flex flex-column align-items-center text-dark ${minimized ? 'minimized' : ''}`}
        >
            <div
                className='zoom-control'
                onClick={() => setMinimized(!minimized)}
            >
                {
                    minimized ? (
                        <i className='fa-solid fa-arrow-up-right-and-arrow-down-left-from-center' />
                    ) : (
                        <i className='fa-solid fa-down-left-and-up-right-to-center' />
                    )
                }
            </div>

            <strong className='time-remaining w-100'>
                Time Remaining Until Automatic Reload
            </strong>

            <h2 className={`countdown ${almostExpired.current ? 'text-danger fa-fade' : 'text-dark'} py-4 m-0 w-100`}>
                { countdown }
            </h2>

            <button
                children='Reload Now'
                type='button'
                className='btn btn-success text-nowrap mt-3 mx-auto'
                onClick={handleReload}
            />
        </div>
    )
}
