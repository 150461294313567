import React from 'react'
import File from './File'

export default function Image({ name, handleChange, previewUrl, directUploadUrl, loading, label, tooltip='' }) {
    return (
        <File name={name} handleChange={handleChange} directUploadUrl={directUploadUrl} tooltip={tooltip} label={label}>
            {
                loading && (
                    <div className="spinner-border text-warning d-flex mx-auto mt-3" role="status"></div>
                )
            }

            {
                previewUrl && (
                    <img
                        className='img-fluid my-2 mx-auto d-flex border'
                        style={{ maxHeight: '60px' }}
                        src={previewUrl}
                        alt={name} />
                )
            }
        </File>
    )
}
