import React from 'react'
import { useSelector } from 'react-redux'
import {
  selectCustomerTypeCounts,
  selectCustomersToPayFor,
} from '@/features/BookingPayment/bookingPaymentSlice'

export default function ParticipantInformation({ booking, customerTypes }) {
  const customersToPayFor = useSelector(selectCustomersToPayFor)
  const customerTypeCounts = useSelector(selectCustomerTypeCounts)

  const customerCountVerbiage = (booking, count) => {
    if (count === 1) {
      return `${count} ${booking.resource_type.customer_verbiage_singular}`
    } else {
      return `${count} ${booking.resource_type.customer_verbiage_plural}`
    }
  }

  const customerTypeCountVerbiage = customerTypeCounts => {
    let customerTypeCountVerbiages = []

    Object.keys(customerTypeCounts).forEach(customerTypeId => {
      if (parseInt(customerTypeCounts[customerTypeId]) <= 0) { return }

      const customerType = customerTypes.find(customerType => customerType.id === parseInt(customerTypeId)).name

      customerTypeCountVerbiages = [
        ...customerTypeCountVerbiages,
        `${customerType} x ${customerTypeCounts[customerTypeId]}`
      ]
    })

    return customerTypeCountVerbiages.join(', ')
  }

  return (
    <>
    {customersToPayFor > 0 &&
    <div className='row justify-content-center'>
      <div className='col-6 text-center'>{customerCountVerbiage(booking, customersToPayFor)}</div>
    </div>
    }

    {customerTypeCounts &&
    <div className='row justify-content-center'>
      <div className='col-6 text-center'>{customerTypeCountVerbiage(customerTypeCounts)}</div>
    </div>
    }
    </>
  )
}
