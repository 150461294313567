import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    closeModal,
    configureModal,
    setCurrentComponent,
    selectModals,
    openModal,
    fetchCheck
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import { addBookingTabToGeneralTab, isOpen, isPaid } from '@/features/AdvancedPointOfSale/lib/Checks'
import Modal from '@/features/AdvancedPointOfSale/components/Modal'

export default function ManageCheckModal({ className='manage-check-modal' }) {

    const dispatch              = useDispatch()
    const { manageCheck:modal } = useSelector(selectModals)

    const handleClose = () => {
        dispatch(closeModal('manageCheck'))
    }

    const handleKeyDown = (e) => {
        e.stopPropagation()

        if (e.key === 'Escape') { handleClose() }
    }

    const handleTransfer = () => {
        dispatch(configureModal({
            modal: 'changeStaff',
            config: { check: modal.check }
        }))

        dispatch(openModal('changeStaff'))

        handleClose()
    }

    const handleManage = () => {
        dispatch(fetchCheck(modal.check.id)).then((check) => {
            if (!check) { return }
            handleClose()
            dispatch(setCurrentComponent('ManageCheck'))
        })
    }

    const handleReceipt = () => {
        dispatch(fetchCheck(modal.check.id)).then((check) => {
            if (!check) { return }

            dispatch(configureModal({
                modal: 'receipt',
                config: {
                    isCheckPaid: isPaid(check),
                    tabs: addBookingTabToGeneralTab(check.tabs),
                    footer: 'complex'
                }
            }))

            dispatch(openModal('receipt'))

            handleClose()
        })
    }

    const handlePrintPay = () => {
        dispatch(fetchCheck(modal.check.id)).then((check) => {
            if (!check) { return }
            handleClose()
            dispatch(setCurrentComponent('PrintPay'))
        })
    }

    useEffect(() => {
        if (modal.isOpen) {
            document.addEventListener('keydown', handleKeyDown, false)
        } else {
            document.removeEventListener('keydown', handleKeyDown, false)
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown, false)
        }
    }, [modal.isOpen])

    return modal.isOpen && !!modal.check && (
        <Modal
            isOpen={modal.isOpen}
            title={
                modal.check.name
                || modal.check.title
                || modal.check.booking?.name
                || 'Retail Check'
            }
            size={modal.size}
            className={className}
            footerButtons={
                <button
                    children='Cancel'
                    type='button'
                    className='btn btn-link text-blue'
                    onClick={handleClose}
                />
            }
            onClose={handleClose}
        >
            <div className='p-4 my-2'>
                <div className='d-flex align-items-center justify-content-between mb-3'>
                    <button
                        type='button'
                        className="btn btn-outline-primary py-4 w-100 mr-2"
                        children='Transfer'
                        disabled={!isOpen(modal.check)}
                        onClick={handleTransfer}
                    />
                    <button
                        type='button'
                        className={`btn btn-${(modal.check.grand_total_cents > 0 || !isOpen(modal.check)) ? 'primary' : 'outline-primary'} py-4 w-100 ml-2`}
                        children='Receipt'
                        onClick={handleReceipt}
                    />
                </div>

                <div className='d-flex align-items-center justify-content-between'>
                    <button
                        type='button'
                        className={`btn btn-${(modal.check.grand_total_cents > 0 || !isOpen(modal.check)) ? 'outline-primary' : 'primary'} py-4 w-100 mr-2`}
                        children='Manage'
                        disabled={!isOpen(modal.check)}
                        onClick={handleManage}
                    />
                    <button
                        type='button'
                        className={`btn btn-${modal.check.grand_total_cents > 0 ? 'primary' : 'outline-primary'} py-4 w-100 ml-2`}
                        children='Print / Pay'
                        disabled={!isOpen(modal.check) || modal.check.grand_total_cents <= 0}
                        onClick={handlePrintPay}
                    />
                </div>
            </div>
        </Modal>
    )
}
