import React from 'react'
import Label from './Label'
import Tooltip from './Tooltip'

export default function InputWrapper({
  name,
  req,
  children,
  cols,
  errors,
  label,
  hideLabel,
  withWrapper=true,
  containerClassName='',
  tooltip='',
  toolTipOptions,
  action=''
}) {
    let html = <>
        { !hideLabel && <>
            { action !== '' ? (
              <div className="label-has-action">
                  <Label req={req} name={label || name}>
                    { tooltip !== '' &&
                        <Tooltip place='right' toolTipOptions={toolTipOptions}>{tooltip}</Tooltip>
                    }
                  </Label>
                  { action() }
              </div>
            ) : (
                <Label req={req} name={label || name}>
                  { tooltip !== '' &&
                      <Tooltip place='right' toolTipOptions={toolTipOptions}>{tooltip}</Tooltip>
                  }
                </Label>
            )}
        </>}

        {children}

        {
            (errors[name] && errors[name].length > 0) && (
                <div className="invalid-feedback d-block mx-2">
                    { errors[name].join(', ') }
                </div>
            )
        }
    </>

    if (withWrapper) {
        const columns = cols != 0
            ? (cols ? `col-${cols}` : 'col-12')
            : null

        html = (
            <div className={`${containerClassName} ${columns}`}>
                <div className="form-group">
                    { html }
                </div>
            </div>
        )
      }

    return html
}
