import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import MultiSelectExternalListItem from '@/components/Form/MultiSelectExternalListItem'
import { useConfirm } from '@/lib/useConfirmHook'
import { parameterize } from 'inflected'

export default function MultiSelectExternalList({
    items=[],
    itemsParentIdKey=null,
    iconType="is_default",
    isSortable=false,
    iconOnMainList=null,
    isSubMenuSortable=false,
    subMenuIsDefaultIconClass=null,
    canLimitSubMenuItems=false,
    canLimitFieldName=null,
    overrideInternalRemoveFunction=false,
    className='draggable-items multi-select--external-list pt-3 w-100',
    onRemove=() => {},
    onSort=() => {},
    onLimitUpdate=() => {},
    onIconChange=() => {},
    onSubItemsSort=() => {},
    onSubItemsIconChange=() => {},
    onSubItemsVisibilityChange=() => {},
}) {

    const { confirm } = useConfirm()

    /*
     * Items are either pre-sorted by the sort order array before being passed
     * in to this component as a property or are sorted ALPHA ASC by default
     */
    const sortedItems = isSortable ? items
                                   : items.sort((a,b) => a.name > b.name ? 1 : -1)


    const handleOnDragEnd = (result) => {
        if (!result.destination) { return }

        const updatedItems = [...items]
        const [reorderedItem] = updatedItems.splice(result.source.index, 1)
        updatedItems.splice(result.destination.index, 0, reorderedItem)

        onSort(updatedItems)
    }

    const handleRemove = async (item, index) => {
        if (overrideInternalRemoveFunction) { return onRemove(item, index) }
        if (!await confirm('Are you sure?', { size: 'sm' })) { return }

        let itemsCopy = [...items]
        itemsCopy.splice(index, 1)

        onRemove(itemsCopy, item)
    }

    return sortedItems.length > 0 && (
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable
                droppableId="droppable-items"
                isCombineEnabled={false}
            >
                {
                    (provided) => (
                        <div
                            ref={provided.innerRef}
                            className={className}
                            {...provided.droppableProps}
                        >
                            {
                                sortedItems.map((item, index) => (
                                    <Draggable
                                        index={index}
                                        key={parameterize(`${item.id}-${item.name}`)}
                                        draggableId={parameterize(`${item.id}-${item.name}`)}
                                        isDragDisabled={!isSortable}
                                    >
                                        {
                                            (provided, snapshot) => <>
                                                <MultiSelectExternalListItem
                                                    parentIdKey={itemsParentIdKey}
                                                    iconType={iconType}
                                                    item={item}
                                                    index={index}
                                                    dnd={[provided, snapshot]}
                                                    isSortable={isSortable}
                                                    iconOnMainList={iconOnMainList}
                                                    isSubMenuSortable={isSubMenuSortable}
                                                    isSubMenuCollapsedOnLoad={!!item.id}
                                                    subMenuIsDefaultIconClass={subMenuIsDefaultIconClass}
                                                    canLimitSubMenuItems={canLimitSubMenuItems}
                                                    canLimitFieldName={canLimitFieldName}
                                                    onRemove={handleRemove}
                                                    onLimitUpdate={onLimitUpdate}
                                                    onIconChange={onIconChange}
                                                    onSubItemsSort={onSubItemsSort}
                                                    onSubItemsIconChange={onSubItemsIconChange}
                                                    onSubItemsVisibilityChange={onSubItemsVisibilityChange}
                                                />
                                                { provided.placeholder }
                                            </>
                                        }
                                    </Draggable>
                                ))
                            }

                            { provided.placeholder }
                        </div>
                    )
                }
            </Droppable>
        </DragDropContext>
    )
}
