import React from 'react'
import { useDispatch } from 'react-redux'
import { useErrorBoundary } from 'react-error-boundary'
import { setSessionLocked, updateIsSessionLocked } from '@/features/Session/sessionSlice'

export default function LockSessionButton({ text='Lock Session', output=null, className='btn-link' }) {

    const dispatch         = useDispatch()
    const { showBoundary } = useErrorBoundary()

    const handleLockSession = () => {
        dispatch(updateIsSessionLocked(true, false))
            .then(() => { dispatch(setSessionLocked(true)) })
            .catch((e) => { showBoundary(e) })
    }

    return (
        <button
            type='button'
            className={`btn ${className}`.trim()}
            onClick={handleLockSession}
        >
            { output || text }
        </button>
    )
}
