import React, { useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { pluralize } from 'inflected'
import { selectStep } from '@/features/Bookings/bookingSlice'
import { selectDiscounts } from '@/features/Pricing/pricingSlice'
import { ledgerDiscountDependenciesFor } from '@/lib/Booking'
import { numberToCurrency } from '@/lib/Number'
import { humanizedDiscount } from '@/lib/Pricing'
import { humanizedLabel } from '@/lib/String'
import { debug } from '@/lib/Debug'

export default function LedgerDiscounts({ customerTypeTotals=null, initiallyFolded=false }) {

    const step      = useSelector(selectStep)
    const discounts = useSelector(selectDiscounts)

    if (!/5|6/.test(step)) {
        return null
    }

    const [isFoldable, setIsFoldable]         = useState(null)
    const [isFolded, setIsFolded]             = useState(initiallyFolded)
    const [isGroupPricing, setIsGroupPricing] = useState(null)

    const filteredDiscounts = useMemo(() => discounts.filter((d) => !Boolean(d.flat_rate)), [discounts])

    useEffect(() => {
        if (debug && console) {
            console.log('> DISCOUNTS')
            console.log({ discounts, filteredDiscounts, isGroupPricing, customerTypeTotals })
        }
    }, [discounts, filteredDiscounts])

    /**
     * If we have customer types, loop through them and determine if
     * we need to display group pricing variants in any of the sub-components
     */
    useEffect(() => {
        if (!!customerTypeTotals && !!customerTypeTotals?.types?.length) {
            customerTypeTotals.types.forEach((t) => {
                setIsGroupPricing(t.isGroup)
            })
        }
    }, [customerTypeTotals])

    useEffect(() => {
        setIsFoldable(
            customerTypeTotals?.types?.length > 0
                && customerTypeTotals?.types?.[0].isGroup !== true
                && filteredDiscounts.length > 0
        )
    }, [filteredDiscounts, customerTypeTotals])

    return Boolean(customerTypeTotals) && Boolean(discounts) && discounts.length > 0 && (
        <div className="xbm-ledger-section">
            <div className="xbm-ledger-section-action-label">
                <div className="xbm-ledger-section-action-label-label">
                    Adjustments
                </div>

                <div className='xbm-ledger-section-action-label-action'>
                    {
                        isFoldable && (
                            <i
                                className={`fas fa-caret-${isFolded ? 'up' : 'down'} cursor-pointer float-right`}
                                title={isFolded ? 'Show Breakdown' : 'Hide Breakdown'}
                                style={{ fontSize: '1.2rem' }}
                                onClick={() => setIsFolded(!isFolded)}
                            />
                        )
                    }
                </div>
            </div>

            {
                isFoldable && !isFolded && <>
                    {
                        // display discounts for each customer type
                        filteredDiscounts?.length === 1
                            && customerTypeTotals?.types?.length > 0
                            && (filteredDiscounts?.[0].discount_record?.amount || filteredDiscounts?.[0].amount)
                            && customerTypeTotals?.types?.map((customerType, index) =>
                        {
                            const [, percentage, shouldDiscount] = ledgerDiscountDependenciesFor(
                                customerType.id,
                                customerTypeTotals?.types?.map(t => t.id),
                                filteredDiscounts[0],
                                isGroupPricing
                            )

                            return shouldDiscount && (
                                <div key={`ctt-${index}`} className="xbm-ledger-section-action-label">
                                    <div className="xbm-ledger-section-guests-label-label">
                                        <span dangerouslySetInnerHTML={{ __html:
                                            humanizedLabel(`${customerType?.quantity} ${customerType?.quantity === 1 ? customerType?.name : pluralize(customerType?.name)}`, true, 22)
                                        }} />
                                    </div>
                                    <div className="xbm-ledger-section-action-label-action">
                                        { !!customerType?.total && !!customerType?.quantity && percentage &&
                                            numberToCurrency((customerType.total / customerType.quantity) * percentage) }/ea
                                    </div>
                                </div>
                            )
                        })
                    }

                    {
                        customerTypeTotals?.types?.length > 0 && filteredDiscounts.length > 0 && (
                            <hr className="my-1 py-0" />
                        )
                    }
                </>
            }

            {
                filteredDiscounts.map((d, index) => {
                    const discount = humanizedDiscount(d)
                    const guestDiscount = parseInt(d.guest_discount_cents) / 100
                    const memberDiscount = (parseInt(d.discount_cents) / 100) - guestDiscount

                    return (
                        <React.Fragment key={`d-${index}`}>
                            {discount.name === "Membership discount" ?
                                <div className="xbm-ledger-section-action-label">
                                    <div className="xbm-ledger-section-guests-label-label">
                                        <span>{discount.name}</span>
                                    </div>
                                    <div className="xbm-ledger-section-action-label-action">
                                        { numberToCurrency(memberDiscount * -1) }
                                    </div>
                                </div>
                                :
                                <div className="xbm-ledger-section-action-label">
                                    <div className="xbm-ledger-section-guests-label-label">
                                        <span dangerouslySetInnerHTML={{ __html: humanizedLabel(`${discount.name} ${discount.type}`, true) }} />
                                    </div>
                                    <div className="xbm-ledger-section-action-label-action">
                                        { discount.amount }
                                    </div>
                                </div>
                            }
                            {guestDiscount > 0 &&
                                <div className="xbm-ledger-section-action-label">
                                    <div className="xbm-ledger-section-guests-label-label">
                                        <span>Guest Discount</span>
                                    </div>
                                    <div className="xbm-ledger-section-action-label-action">
                                    { numberToCurrency(guestDiscount * -1) }
                                    </div>
                                </div>
                            }
                        </React.Fragment>
                    )
                })
            }
        </div>
    )
}
