import React, { useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
    selectCheckoutTab,
    selectTipAmount,
    selectCurrentCheck,
    selectAmountTowardsBalance,
    selectIsAmountValid,
    selectShowCalculator
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import PaymentForm from '@/features/AdvancedPointOfSale/components/checkout/PaymentForm'
import { numberToCurrency } from '@/lib/Number'

export default function Check({
    self,
    totalDue,
    isProcessing,
    onLoad,
    onSubmit
}) {

    const check                = useSelector(selectCurrentCheck)
    const tab                  = useSelector(selectCheckoutTab)
    const tipAmount            = useSelector(selectTipAmount)
    const amountTowardsBalance = useSelector(selectAmountTowardsBalance)
    const isAmountValid        = useSelector(selectIsAmountValid)
    const showCalculator       = useSelector(selectShowCalculator)

    const [checkNumber, setCheckNumber] = useState('')

    const amountToProcess = useMemo(() => (
        parseFloat(tipAmount || 0) + parseFloat(amountTowardsBalance || 0)
    ), [tipAmount, amountTowardsBalance])

    const processButton = useMemo(() => (
        <button
            children={isProcessing ? 'Please Wait...' : `Process ${numberToCurrency(amountToProcess)}`}
            className='btn btn-primary process-button'
            disabled={isProcessing || !checkNumber || !isAmountValid}
            onClick={() => {
                onSubmit({
                    totalReceivedAmount: amountToProcess,
                    checkNumber: checkNumber,
                    tipAmount: tipAmount || 0
                })
            }}
        />
    ), [isProcessing, isAmountValid, checkNumber, tipAmount, amountToProcess])

    useEffect(() => {
        onLoad(processButton)
    }, [processButton])

    return (!!tab || !!check) && (
        <PaymentForm
            self={self}
            totalDue={totalDue}
            checkNumberField={
                <div className='row mt-3 align-items-center'>
                    <div className='col-4 text-right text-bold px-0'>Check{showCalculator ? <><br/></> : ''}Number</div>
                    <div className='col-8'>
                        <input
                            type='text'
                            className='form-control'
                            placeholder='CHECK-123'
                            value={checkNumber}
                            onChange={(e) => {
                                setCheckNumber(e.target.value)}
                            }
                        />
                    </div>
                </div>
            }
        />
    )
}
