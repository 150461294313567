import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import {
  setCustomerTypeCounts,
  selectCustomerTypeCounts,
  selectAmountType,
  setAmountType,
} from '@/features/BookingPayment/bookingPaymentSlice'
import { selectPackage } from '@/features/Packages/packageSlice'
import CustomerTypeSelect from './CustomerTypeSelect'
import ParticipantNumberSelect from './ParticipantNumberSelect'
import CustomAmountSelect from './CustomAmountSelect'

export default function Step2({ setStep, booking, bookingCustomers }) {
  const dispatch = useDispatch()

  const customerTypeCounts = useSelector(selectCustomerTypeCounts)
  const amountType = useSelector(selectAmountType)
  const pkg = useSelector(selectPackage)

  const [maxCustomerTypeCounts, setMaxCustomerTypeCounts] = useState([])
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (pkg.pricing_type === 'pricing_by_customer_type') {
      axios
        .get(`/bookings/${booking.id}/fetch_customer_type_counts`)
        .then(({ data }) => {
          const counts = {}

          data.forEach(bookingCustomer => {
            let count = bookingCustomer.count
            const paidCount =
              booking.customer_type_counts_paid_for[
                bookingCustomer.customer_type_id
              ]

            if (paidCount) {
              count -= paidCount
            }

            counts[bookingCustomer.customer_type_id] = count
          })

          if (Object.values(counts).find(count => count > 0)) {
            setMaxCustomerTypeCounts(counts)
          } else {
            dispatch(setAmountType('custom'))
          }

        })

      const customerTypeCount = {}

      bookingCustomers.forEach(customerType => {
        customerTypeCount[customerType.customer_type_id] = 0
      })

      dispatch(setCustomerTypeCounts(customerTypeCount))
    }

    setLoaded(true)
  }, [])

  return (
    <>
    {loaded &&
    <div className="row justify-content-center">
      {amountType === 'custom' ?
        <CustomAmountSelect booking={booking} setStep={setStep} />
        : <>
          {pkg.pricing_type === 'pricing_by_customer_type' && (
            <CustomerTypeSelect
              bookingCustomers={bookingCustomers}
              maxCustomerTypeCounts={maxCustomerTypeCounts}
              customerTypeCounts={customerTypeCounts}
              setStep={setStep}
            />
          )}

          {pkg.pricing_type === 'uniform_pricing' && (
            <ParticipantNumberSelect
              booking={booking}
              setStep={setStep}
            />
          )}
        </>
      }
    </div>
    }
    </>
  )
}
