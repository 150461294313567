import { createSlice } from "@reduxjs/toolkit"
import axios from 'axios'
import { addAlert } from '@/features/Notifications/notificationSlice'

const initialPackage = {
    groups: [],
    party_size_selection_messages: [],
    durations: []
}

export const packageSlice = createSlice({
    name: 'package',
    initialState: {
        package: initialPackage,
        originalPackage: null,
        packages: [],
        packageGroup: null,
        packageGroups: [],
        tempPackageId: null
    },
    reducers: {
        setNewPackage: (state, action) => {
            state.package = initialPackage
        },
        setOriginalPackage: (state, action) => {
            state.originalPackage = action.payload
        },
        setPackage: (state, action) => {
            state.package = action.payload
        },
        setPackages: (state, action) => {
            state.packages = action.payload
        },
        setPackageGroup: (state, action) => {
            state.packageGroup = action.payload
        },
        setPackageGroups: (state, action) => {
            state.packageGroups = [
                {
                    id: 'all',
                    name: 'All Packages',
                },
                ...action.payload
            ]
        },
        setTempPackageId: (state, action) => {
            state.tempPackageId = action.payload
        },
        resetPackage: (state, action) => {
            state.package = initialPackage
        },
        resetPackages: (state, action) => {
            state.packages = []
        },
        resetPackageGroup: (state, action) => {
            state.packageGroup = null
        },
        resetPackageGroups: (state, action) => {
            state.packageGroups = []
        },
        resetTempPackageId: (state, action) => {
            state.tempPackageId = null
        }
    }
})

export const {
    setNewPackage,
    setOriginalPackage,
    setPackage,
    setPackages,
    setPackageGroup,
    setPackageGroups,
    setTempPackageId,
    resetPackage,
    resetPackages,
    resetPackageGroup,
    resetPackageGroups,
    resetTempPackageId
} = packageSlice.actions

export const selectPackage                  = state => state.package.package
export const selectMembershipCustomerTypeId = state => state.package.package.membership_customer_type_id
export const selectOriginalPackage          = state => state.package.originalPackage
export const selectName                     = state => !!state.package.package.display_name ? state.package.package.display_name : state.package.package.name
export const selectGroups                   = state => state.package.package.groups
export const selectAllowMemberBenefits      = state => state.package.package.allow_member_benefits
export const selectAllowMembersOnly         = state => state.package.package.allow_members_only
export const selectPartySizeMessages        = state => state.package.package.party_size_selection_messages
export const selectDurations                = state => state.package.package.durations
export const selectId                       = state => state.package.package.id
export const selectPricingType              = state => state.package.package.pricing_type
export const selectPricing                  = state => state.package.package.pricing
export const selectPromoCodes               = state => state.package.package.promo_codes
export const selectPackages                 = state => state.package.packages
export const selectPackageGroup             = state => state.package.packageGroup
export const selectPackageGroups            = state => state.package.packageGroups
export const selectTempPackageId            = state => state.package.tempPackageId

export const selectAuthorizeOnlyExplanation = state => state.package.package.credit_card_requirement_message

export const selectPaymentTypes = state => { return {
    full_amount: state.package.package.show_full_payment,
    deposit_amount: state.package.package.show_deposit_option,
    authorize_card_only: state.package.package.authorize_card_only
}}


export function fetchPackage(url, version=null, storeAsOriginal=false) {
    return async (dispatch) => {
        axios.get(version ? `${url}?version=${version}` : url)
        .then(({ data:pkg }) => {
            if (storeAsOriginal) {
                dispatch(setOriginalPackage(pkg))
            } else {
                dispatch(setPackage(pkg))
            }
        })
        .catch((error) => {
            dispatch(addAlert({ type: 'error', text: (error?.response?.data?.message || 'An error occurred!') }))
        })
    }
}

export function fetchPackages(_url, version=null, editMode=false) {

    let params = []

    if (version)  { params.push({ version:   version  }) }
    if (editMode) { params.push({ edit_mode: editMode }) }

    // generate url encoded params list based on this function's options
    params = params.map(pair => `${Object.keys(pair)[0]}=${Object.values(pair)[0]}` )

    return async (dispatch) => {
        axios.get(!!params ? `${_url}?${params.join('&')}` : _url)
        .then(({ data:packages }) => {
            dispatch(setPackages(packages))
        })
        .catch((error) => {
            dispatch(addAlert({ type: 'error', text: (error?.response?.data?.message || 'An error occurred!') }))
        })
    }
}

export function fetchPackageGroups(url) {
    return async (dispatch) => {
        axios.get(url)
        .then(({ data:package_groups }) => {
            dispatch(resetPackageGroup())
            dispatch(setPackageGroups(package_groups))
        })
        .catch((error) => {
            dispatch(addAlert({ type: 'error', text: (error?.response?.data?.message || 'An error occurred!') }))
        })
    }
}

export function fetchPackagesForGroup(url, version=null) {
    return async (dispatch) => {
        axios.get(version ? `${url}&version=${version}` : url)
        .then(({ data:packages }) => {
            dispatch(resetPackage())
            dispatch(resetPackageGroups())
            dispatch(setPackages(packages))
        })
        .catch((error) => {
            dispatch(addAlert({ type: 'error', text: (error?.response?.data?.message || 'An error occurred!') }))
        })
    }
}

export default packageSlice.reducer
