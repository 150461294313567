import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useFormContext } from 'react-hook-form'

import {
  selectName,
  selectEmail,
  selectSendReceipt,
  setName,
  setEmail,
  setSendReceipt
} from '@/features/BookingPayment/bookingPaymentSlice'

import PaymentSelectWaiver from './PaymentSelectWaiver'
import { errorMessageFor } from '@/components/Form/ErrorsHelper'
import { errorClass, validationPatterns } from '@/lib/Errors'

export default function PaymentReceiptInfo() {

    const { register, unregister, setValue, formState: { errors } } = useFormContext()

    const dispatch    = useDispatch()
    const name        = useSelector(selectName)
    const email       = useSelector(selectEmail)
    const sendReceipt = useSelector(selectSendReceipt)

    const nameField = register('receipt_name', sendReceipt ? {
      required: 'A name is required'
    } : {})

    const emailField = register('receipt_email', sendReceipt ? {
        required: 'An email is required',
        pattern: validationPatterns.email
    } : {})

    useEffect(() => {
        if (!sendReceipt) {
            unregister('receipt_name')
            unregister('receipt_email')
        }
    }, [sendReceipt])

    return (
        <div className="modal-body-main-section">
            <div className="form-row">
                <div className="col-4 d-flex align-items-center">
                    <div className="custom-control custom-checkbox">
                        <input
                            id="noReceipt"
                            name="amountType"
                            type="checkbox"
                            className="custom-control-input"
                            checked={sendReceipt}
                            onChange={() => dispatch(setSendReceipt(!sendReceipt))}
                        />

                        <label className="custom-control-label" htmlFor="noReceipt">
                            Send receipt
                        </label>
                    </div>
                </div>
                <div className="col">
                    <PaymentSelectWaiver
                        sendReceipt={sendReceipt}
                        setNameCallback={(name) => {
                            setValue('receipt_name', name, { shouldValidate: true })
                            dispatch(setName(name))
                        }}
                        setEmailCallback={(email) => {
                            setValue('receipt_email', email, { shouldValidate: true })
                            dispatch(setEmail(email))
                        }}
                    />
                </div>
            </div>
            {
                sendReceipt && (
                    <div className="form-row mt-1">
                        <div className="col-6">
                            <div className="form-group pb-0">
                                <label htmlFor="receipt_name">Name</label>
                                <input
                                    {...nameField}
                                    className={`form-control ${errorClass('receipt_name', errors)}`}
                                    type="text"
                                    placeholder="Name"
                                    value={name || ''}
                                    onBlur={(e) => {
                                        nameField.onBlur(e)
                                        dispatch(setName(e.target.value))
                                    }}
                                    onChange={(e) => {
                                        nameField.onChange(e)
                                        dispatch(setName(e.target.value))
                                    }}
                                />
                                { errorMessageFor('receipt_name', errors) }
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group pb-0">
                                <label htmlFor="receipt_email">Email Address</label>
                                <input
                                    {...emailField}
                                    className={`form-control ${errorClass('receipt_email', errors)}`}
                                    type="email"
                                    placeholder="Email Address"
                                    value={email || ''}
                                    onBlur={(e) => {
                                        emailField.onBlur(e)
                                        dispatch(setEmail(e.target.value))
                                    }}
                                    onChange={(e) => {
                                        emailField.onChange(e)
                                        dispatch(setEmail(e.target.value))
                                    }}
                                />
                                { errorMessageFor('receipt_email', errors) }
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}
