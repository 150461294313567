import React from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { selectPaymentAmount, selectPricing, setPaymentAmount, selectOrder } from './productSlice'

export default function ProductPaymentAmount() {

    const dispatch = useDispatch()
    const order    = useSelector(selectOrder)
    const amount   = useSelector(selectPaymentAmount)
    const pricing  = useSelector(selectPricing)

    return (
        <div className="form-group">
            <label>Amount to Pay</label>
            <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text">$</span>
                </div>
                <input
                    type='number'
                    min='0'
                    step='.01'
                    name='payment_amount'
                    className='form-control'
                    placeholder='Amount to Pay...'
                    value={amount || 0}
                    onChange={e => dispatch(setPaymentAmount(Number.parseFloat(e.target.value)))}
                />
            </div>
        </div>
    )
}
