import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectPackage } from '@/features/Packages/packageSlice'
import { startTraining, completeTraining, selectBooking, updateStatus } from '@/features/EditBooking/editBookingSlice'
import StatusIcons from '@/components/Bookings/StatusIcons'

export function _BookingStatus({ isChild=false }) {
    const dispatch = useDispatch()
    const pkg      = useSelector(selectPackage)
    const booking  = useSelector(selectBooking)

    const handleTrainingStarted = () => {
        dispatch(startTraining())
    }

    const handleTrainingComplete = () => {
        dispatch(completeTraining())
    }

    const handleUpdateStatus = (status, should_activate) => {
        dispatch(updateStatus(status, should_activate))
    }

    return (
        <StatusIcons
            booking={booking}
            pkg={pkg}
            isChild={isChild}
            onToggleTrainingStarted={handleTrainingStarted}
            onToggleTrainingComplete={handleTrainingComplete}
            onToggleInProgress={handleUpdateStatus}
            onToggleWarning={handleUpdateStatus}
            onToggleComplete={handleUpdateStatus}
        />
    )
}

export const BookingStatus = React.memo(_BookingStatus)
