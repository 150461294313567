export const errorClass = (name, errors) => {
    if (errors[name] && errors[name].length > 0) {
        return 'is-invalid'
    }

    return ''
}

export const validationPatterns = {
    date: {
        // value: /^[1-12]{2}\/[1-31]{2}\/\d{4}$/,
        value: /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d\d$/,
        message: 'Must be formatted as MM/DD/YYYY.'
    },

    url: {
        value: /^(http|https):\/\/[^ "]+$/,
        message: 'Must be a properly formatted url.'
    },

    // Valid location prexif
    locationPrefix: {
        value: /^[A-Z0-9]{3}$/,
        message: 'Must be 3 uppercase letters or numbers.'
    },

    // valid email address
    email: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Must be a valid email address.'
    },

    phone_basic: {
        // 10 digits and only numbers
        value: /^\d{3}-\d{3}-\d{4}$/,
        message: 'Must be 10 digits long and numbers only.'
    },

    phone: {
        // 10 digits and only numbers
        // value: /^\d{3}-\d{3}-\d{4}$/,

        // 10 digits and only numbers, area code must be 200+
        value: /^[2-9]{1}\d{2}-\d{3}-\d{4}$/,
        message: 'Must be a valid phone number.'
    },

    zip: {
        value: /^([0-9]{5})+[-{1}]?([0-9]{4})?$/,
        message: '5 or 9 digit (hyphenated) zip codes only.'
    },

    taxRate: {
        // https://stackoverflow.com/a/8609898
        value: /^\s*(?=.*[0-9])\d*(?:\.\d{1,3})?\s*$/,
        message: 'Number with optional 3 decimal places. Must be a positive number.'
    },

    creditCard: {
        // https://www.regular-expressions.info/creditcard.html
        number: {
            value: new RegExp(
                '^(' +
                    // Visa
                    '?:4[0-9]{12}(?:[0-9]{3})?'+

                    // MasterCard
                    '|(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}'+

                    // American Express
                    '|3[47][0-9]{13}'+

                    // Diners Club
                    '|3(?:0[0-5]|[68][0-9])[0-9]{11}'+

                    // Discover
                    '|6(?:011|5[0-9]{2})[0-9]{12}'+

                    // JCB
                    '|(?:2131|1800|35\d{3})\d{11}'+
                ')$'
            ),
            message: 'Invalid card number. Be sure to type numbers only. No dashes.'
        },

        // MM/YYYY - Year 2000 and forward
        expirationDate: {
            value: /^((0[1-9]|10|11|12){1}(\/){1}(2[0-9]{3}){1}){1}$/,
            message: 'Invalid expiration date.'
        },

        cvv: {
            value: /^([1-9]{3}){1}$/,
            message: 'Invalid CVV.'
        },

        // a possible card number is anything longer than 12 digits
        // ignoring non-digits and special characters (ex: å ø ü ß)
        possibleCardNumber: {
            value: /^[\d^a-zA-Z\S\u00C0-\u02A0]{12,}$/i,
            message: 'Possible credit card number'
        },
    },

    giftCard: {
        number: {
            value: /^([a-z\d]{5}-[a-z\d]{5}-[a-z\d]{5}-[a-z\d]{5})?$/,
            message: 'Invalid gift card number.'
        },
    },

    // https://stackoverflow.com/questions/354044/what-is-the-best-u-s-currency-regex
    currency: {
        value: /^([+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?)?$/,
        message: 'Invalid amount'
    },

    trimmedText: {
        value: /^[A-Za-z0-9-]+(?: +[A-Za-z0-9]+)*$/i,
        message: 'Contains leading or trailing spaces'
    },
}

export function validateEquality(a='', b='') {
    return String(a).trim().toLowerCase() === String(b).trim().toLowerCase()
}

export function validateUniqueness(array, data) {
    return !array.includes(data)
}

export default errorClass
