import { isEmpty } from 'lodash'
import { deleteError, selectMemberInformation, setErrors, setMemberInformationField } from './memberSlice'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import validator from 'validator'
import Input from '../../components/Form/Input'

/**
 * Shared Input component used for Member modal form.
 *
 * This will render an email input and run validations for:
 *   - Making sure email and confirm match
 *   - Making sure email is properly formatted
 *   - Making sure email is unique in the system
 *
 * @param {Boolean} ignoreConfirm Whether or not to ignore email confirmation field on input change.
 * @param {Boolean} ignoreFormat Whether or not to ignore email format on input change.
 * @param {Boolean} ignoreUniqueness Whether or not to ignore email uniqueness on input change.
 * @param {String} name Input name for field
 * @param {String} label Input label for field
 * @param {String} placeholder Input placeholder for field
 * @param {Object} record Info object to use from slice... either member or buyer.
 * @param {Function} onChange callback to use when dispatching new input value to slice.
 */
export default function MemberEmail({
   ignoreConfirm,
   ignoreFormat,
   ignoreUniqueness,
   name='email',
   label='EMAIL',
   placeholder='Email Address',
   disabled=false,
   record,
   onChange
})
{
    const dispatch = useDispatch()

    const info = record || useSelector(selectMemberInformation)

    /**
     * Set member information value in store and run validations.
     *
     * @param {String} name Field name
     * @param {String} value Field value
     */
    const handleChange = (name, value) => {
        if (onChange) {
            onChange(name, value)
        } else {
            dispatch(setMemberInformationField({ name, value }))
        }

        fieldValidation(name, value)
    }

    /**
     * Run field validations whenever a given input is changed
     *
     * @param {String} name Name of field's input
     * @param {String} value Value of field's input
     */
    const fieldValidation = (name, value) => {
        if (!ignoreFormat) {
            checkEmailFormat(value)
        }

        if (!ignoreUniqueness) {
            // checkEmailUniqueness(value)
        }

        if (!ignoreConfirm) {
            const targetEmail = name === 'email' ? value : info.email
            const targetConfirm = name === 'email' ? info.confirmEmail : value
            checkConfirmEmail(targetEmail, targetConfirm)
        }
    }

    /**
     * Validate that email is of proper format.
     *
     * @param {String} email User email to validate
     */
    const checkEmailFormat = (email) => {
        if (validator.isEmail(email)) {
            dispatch(deleteError('emailFormat'))
        } else {
            dispatch(setErrors({ type: 'emailFormat', value: 'Email must be properly formatted.' }))
        }
    }

    /**
     * Check that the email and confirm email fields match.
     *
     * @param {String} email User's email address
     * @param {String} confirmEmail Confirmation field for user's email
     */
    const checkConfirmEmail = (email, confirmEmail) => {
        if (!isEmpty(email) || !isEmpty(confirmEmail)) {
            if (email.toLowerCase() !== confirmEmail.toLowerCase()) {
                dispatch(setErrors({ type: 'confirmEmail', value: 'Email and Confirm Email must match.' }))
            } else {
                dispatch(deleteError('confirmEmail'))
            }
        } else {
            dispatch(deleteError('confirmEmail'))
        }
    }

    return (
        <Input
            label={label}
            name={name}
            value={info[name]}
            placeholder={placeholder}
            req={true}
            type='text'
            className='form-control'
            cols='12 col-md-6'
            disabled={disabled}
            handleChange={e => handleChange(name, e.target.value)}
        />
    )
}
