import React, { useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {selectPackage, selectPricing, selectPricingType} from '../../Packages/packageSlice'
import DurationOption from './DurationOption'
import { selectParticipants } from '@/features/Schedule/scheduleSlice'

export default function BookingDurations({ errors=[] }) {

    const dispatch     = useDispatch()
    const pkg          = useSelector(selectPackage)
    const pricing      = useSelector(selectPricing)
    const pricingType  = useSelector(selectPricingType)
    const durations    = pkg.durations
    const participants = useSelector(selectParticipants)

    const parsedParticipants = Number(String(participants).split('-')[0])

    const parsedPrices = pricing?.filter((p) => parsedParticipants >= p.groupMin && parsedParticipants <= p.groupMax)
                                ?.map((pricing) => pricing.prices)[0]

    const filteredDurations = useMemo(() => (
        Object.values(durations)
            // first, filter out durations that are only internal or marked with addon prices
            .filter((duration) => !duration.addon_price)
            .filter((duration) => {
                // Check the appropriate parsedPrice, and filter out the duration if a price isn't present
                const price = pricingType === 'uniform_pricing' ? (parsedPrices?.filter((price) => price.duration === duration.duration)) : 'price_by_customer_type'
                if (price === 'price_by_customer_type') { return true }
                if (price?.length === 0) { return false }
                return Number(price?.[0]?.price) >= 0
            })
            // sort ascending
            .sort((a,b) => a.duration - b.duration)
    ), [durations])

    // if the duration is preloaded, select it automatically
    useEffect(() => {
        if (filteredDurations && (pkg.booking_preload?.duration || filteredDurations?.length === 1)) {
            const targetDuration = pkg.booking_preload?.duration || filteredDurations[0].duration
            document.getElementById(`booking-duration-${targetDuration}`)?.click()
        }
    }, [dispatch, pkg, filteredDurations])

    return (
        <div className="booking-duration">
            <h3>Duration</h3>

            <div id="booking-durations-list">
                { filteredDurations.map((d) => <DurationOption key={d.duration} duration={d} />) }
            </div>

            { errors['duration'] &&
                <div className="invalid-feedback d-block w-100 text-center mt-3">
                    You must choose a duration.
                </div>
            }
        </div>
    )
}
