import React from 'react'

export default function Alert({
  text=null,
  icon='fa-light fa-circle-exclamation h4 m-0 p-0',
  className='',
  children
}) {
    return (
        <div className={`alert bg-red rounded d-flex align-items-center ${className}`.trim()}>
            <i className={icon} />
            <span className='ml-3'>{ text || children }</span>
        </div>
    )
}
