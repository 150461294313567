import React from "react";
import { useDispatch } from 'react-redux'
import {FormProvider, useForm} from "react-hook-form";
import axios from "axios";
import ExistingCustomerWaiver from '@/components/Waivers/ExistingCustomerWaiver'
import { addAlert } from '@/features/Notifications/notificationSlice'
import {accessToken} from "@/lib/Csrf";
import { debug } from "@/lib/Debug"

export default function CustomerWaiversRenewWaiver({
  waiver,
  company,
  location,
  reservation_number,
  resourceTypeId,
  searchResults,
  showSuccess,
  showAdult,
  showMinor,
}) {
  const dispatch    = useDispatch()
  const formMethods = useForm()

  /**
   * Loop through form errors and build up our own errors
   * object that gets passed down to all of the components
   */
  const onError = (formErrors, e) => {
    setErrors(errorsFor(formErrors))
  }

  const handleRenew = (data) => {
    axios.post(`/companies/${company.url_hash}/locations/${location.url_hash}/waivers/${reservation_number}/renew_waiver`, {
      authenticity_token: accessToken,
      resource_type_id: resourceTypeId,
      data
    }).then(({ data }) => {
      if (data.success) {
        showSuccess()
        return
      }
      dispatch(addAlert({ type: 'error', text: data.message }))
    }).catch((e) => {
      if (debug && console) { console.error(e) }
      dispatch(addAlert({ type: 'error', text: 'An error occurred.' }))
    })
  }

  return (
    <FormProvider {...formMethods} >
      <form id="renew-waiver-form" onSubmit={formMethods.handleSubmit(handleRenew, onError)}>
        <div id='waiver_search_results_container' className="pt-3 mt-n4">
          <div id='waiver_result_header'>

            {
                Array.isArray(searchResults) && searchResults.length > 0 && <>
                    <h3 className="waiver-item">MATCHING WAIVERS</h3>
                    <hr />
                </>
            }

            <div id='waiver_search_results' className='row justify-content-start justify-content-md-center py-3'>
              {
                  Array.isArray(searchResults) && searchResults.map((customer, index) => (
                      <ExistingCustomerWaiver
                          key={index}
                          company={company}
                          customer={customer}
                          waiver={waiver}
                          handleShowAdult={showAdult}
                          handleShowMinor={showMinor}
                          handleSign={formMethods.handleSubmit(handleRenew, onError)}
                      />
                  ))
              }

              {
                  Array.isArray(searchResults) && searchResults.length === 0 && (
                      <div className="text-center">
                          No matching waivers found. Please check the search value & try again, or complete a new waiver.
                      </div>
                  )
              }
            </div>

          </div>
        </div>
      </form>
    </FormProvider>
  )
}
