import React, { useEffect, useMemo } from 'react'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { selectCustomerTypes } from '../../CustomerTypes/customerTypeSlice'
import { selectLocation } from '../../Locations/locationSlice'
import { selectId, selectPackage } from '../../Packages/packageSlice'
import { fetchPrices, selectBasePrice, selectAutoGratuity, selectTaxesAndFees, selectDiscounts, selectTotal } from '../../Pricing/pricingSlice'
import { selectForm, selectMembers } from '../scheduleSlice'
import BookingLedger from './BookingLedger'
import { selectSelectedResourceType } from '../../ResourceType/resourceTypeSlice'
import { evaluateParticipantPricingByGroupType, taxRateAndFeesFor, calculateMemberPrice } from '@/lib/Pricing'

export default function NewBookingLedger() {

    const dispatch = useDispatch()

    const form          = useSelector(selectForm)
    const packageId     = useSelector(selectId)
    const pakage        = useSelector(selectPackage)
    const location      = useSelector(selectLocation)
    const customerTypes = useSelector(selectCustomerTypes)
    const basePrice     = useSelector(selectBasePrice)
    const autoGratuity  = useSelector(selectAutoGratuity)
    const taxesAndFees  = useSelector(selectTaxesAndFees)
    const discounts     = useSelector(selectDiscounts)
    const total         = useSelector(selectTotal)
    const resourceType  = useSelector(selectSelectedResourceType)
    const members       = useSelector(selectMembers)

    useEffect(() => {
        if (packageId && form.participants && form.duration && form.date && form.time && form.customer_type_counts) {
            // ----------------------------------------------------------------------------------------------------------------------------
            // TODO: Passing `members` here presents a larger UX problem.
            // ----------------------------------------------------------------------------------------------------------------------------
            // Right now, the ledger is updated before the user submits the form, but when we verify the member information and submit,
            // we never have a chance to show the user the updated price with their provided member information... instead we
            // are submitting the form immediately upon validation... not sure how else this would work though.
            // ----------------------------------------------------------------------------------------------------------------------------
            dispatch(fetchPrices(packageId, form.participants, form.customer_type_counts, form.duration, form.date, form.time, members))
        }
    }, [dispatch, packageId, form.participants, form.duration, form.date, form.time, form.customer_type_counts])

    const taxRateAndFees = useMemo(() => (
        taxRateAndFeesFor(location, pakage)
    ), [location, pakage])

    const parsedParticipantCounts = useMemo(() => (
        evaluateParticipantPricingByGroupType(
            pakage,
            {
                ...form,
                start_time: moment.tz(form.time, location.time_zone),
                weekday: moment.tz(form.date, location.time_zone).format('dddd')
            },
            form.customer_type_counts,
            customerTypes,
            taxRateAndFees.tax_rate,
            taxRateAndFees.rkd_reservation_fee,
            taxRateAndFees.merchant_reservation_fee,
            taxRateAndFees.third_party_reservation_fee,
            location,
            Number.parseInt(form.participants),
            resourceType
        )
    ), [pakage, form, customerTypes, taxRateAndFees, location, resourceType])

    const memberPrice = useMemo(() => (
        calculateMemberPrice(
            pakage,
            {
                ...form,
                start_time: moment.tz(form.time, location.time_zone),
                weekday: moment.tz(form.date, location.time_zone).format('dddd')
            },
            form.customer_type_counts,
            location,
            resourceType
        )
    ), [pakage, form, location, resourceType])

    return (
        <BookingLedger
            newBooking={true}
            basePrice={Number(basePrice).toFixed(2)}
            autoGratuity={Number(autoGratuity).toFixed(2)}
            taxesAndFees={Number(taxesAndFees).toFixed(2)}
            total={Number(total).toFixed(2)}
            customerTypeTotals={parsedParticipantCounts}
            members={members}
            memberPrice={memberPrice}
            paid={0.0.toFixed(2)}
            remaining={Number(total).toFixed(2)}
            discounts={discounts}
            showLedgerBreakdown={Boolean(form?.duration) && Boolean(form?.date) && Boolean(form?.time)}
            participants={Number.parseInt(form?.participants)}
        />
    )
}
