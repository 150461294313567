import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from "moment"
import { setOpenRemoveModal, selectOpenRemoveModal, removeAddon } from './addonSlice'
import { selectBooking } from '../EditBooking/editBookingSlice'
import { selectTimeZone } from '@/features/Locations/locationSlice'
import { numberToCurrency } from '@/lib/Number'
import { UI } from '@/lib/Constants'
import { useConfirm } from '@/lib/useConfirmHook'
import { debug } from '@/lib/Debug'

export default function RemoveAddonModal() {
  const dispatch    = useDispatch()
  const { confirm } = useConfirm()

  const open    = useSelector(selectOpenRemoveModal)
  const booking = useSelector(selectBooking)
  const tz      = useSelector(selectTimeZone)

  const handleKeyDown = e => {
    if (
      e.key === 'Escape'
      && !document.body.classList.contains(UI.classes.CONFIRMATION_OPEN)
      && !document.body.classList.contains(UI.classes.HELPJUICE_OPEN)
    ) {
      close()
    }
  }

  const close = () => {
    dispatch(setOpenRemoveModal(false))
  }

  const handleDelete = async addonId => {
    if (await confirm('Are you sure you want to remove this additional time?')) {
      dispatch(removeAddon(addonId))
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false)

    if (open) {
      document.body.classList.add(UI.classes.MULTIPLE_MODALS_OPEN)
    }

    return () => {
      document.body.classList.remove(UI.classes.MULTIPLE_MODALS_OPEN)
      document.removeEventListener('keydown', handleKeyDown, false)
    }
  }, [open])

  return !open ? null : (
    <div className="modal modal-backdrop modal--xl">
      <div
        className="modal-container"
        style={{ maxWidth: '1000px', minWidth: 'unset' }}
      >
        <div className="modal-header">
          <div className="modal-title">Remove Additional Time From This Reservation</div>
          <button className="modal-close" onClick={close}>
            <span>Close</span>
            <i className="far fa-times" />
          </button>
        </div>
        <div className="modal-body">
          <div className="modal-body-main">
            <div className="modal-body-main-section">
              <table className="table">
                <colgroup>
                  { debug && <col width='auto' /> }
                  <col width="20%" />
                  <col width="30%" />
                  <col width="auto" />
                  <col width="auto" />
                </colgroup>
                <thead>
                  <tr>
                    { debug && <th>ID</th> }
                    <th className='pl-0'>Added At</th>
                    <th>Duration</th>
                    <th>Details</th>
                    <th className='pr-0'></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    booking.booking_addon_times.map((addonTime, index) => (
                      <tr key={index}>
                        { debug && <td><strong>{addonTime.id}</strong></td> }
                        <td className='pl-0'><strong>{moment.tz(addonTime.created_at, tz).format('MMM D, YYYY h:mma z')}</strong></td>
                        <td><strong>{addonTime.duration} Minutes</strong></td>
                        <td>
                            {
                                addonTime.customer_types.map((ct, index) => (
                                    <span key={`ct-${index}`} className="d-block w-100 mb-1">
                                        { /* NAME x 1 - $20.00 */ }
                                        { `${ct.name.toUpperCase()}${/^group$/i.test(ct.name) ? '' : ' x ' + ct.quantity} - ${numberToCurrency(ct.total_price || 0)}` }
                                    </span>
                                ))
                            }
                        </td>

                        <td className="text-right pr-0">
                          <button
                            children='Remove'
                            type="button"
                            className='btn btn-danger text-white h-auto py-1 pl-3'
                            disabled={booking?.check_addon_times_with_payments_ids?.includes(addonTime.id)}
                            title={booking?.check_addon_times_with_payments_ids?.includes(addonTime.id) ? 'This item cannot be removed because payments have been made towards it' : null}
                            onClick={() => handleDelete(addonTime.id)}
                          />
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
