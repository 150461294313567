import React from 'react'
import { useSelector } from 'react-redux'
import { selectBooking } from '@/features/EditBooking/editBookingSlice'
import NewQuoteFromExistingQuote from '@/features/Schedule/components/NewQuoteFromExistingQuote'
import { isQuoteExpiredOrRejected } from '@/lib/Booking'

export function _BookingActions({ handleClose, children }) {

    const booking = useSelector(selectBooking)

    return (
        <div className="actions">
            <button
                type="button"
                className="close-drawer btn btn-link btn-link-danger close mr-auto"
                onClick={handleClose}
            >
                Close
            </button>

            {
                isQuoteExpiredOrRejected(booking)
                    ? <NewQuoteFromExistingQuote quote={booking} onClose={handleClose} />
                    : children
            }
        </div>
    )
}

export const BookingActions = React.memo(_BookingActions)
