import React, { useMemo } from 'react'

export default function StaffName({
    text=null,
    id=null,
    className=null,
    format='fi.lname',
    prependedLabel=true
}) {

    const [firstInitial, firstName, lastName] = useMemo(() => {
        if (!text) { return [null, null, null] }

        const parts        = String(text).split(' ')
        const firstName    = parts?.[0]
        const firstInitial = parts?.[0]?.charAt(0)
        const lastName     = parts?.[parts.length - 1]

        return [firstInitial, firstName, lastName]
    }, [text])

    return (
        <span id={id} className={className}>
            {
                prependedLabel && (
                    <strong className='pr-3'>
                        STAFF
                    </strong>
                )
            }

            <span className='font-weight-light'>
                { /^fi.lname$/i.test(format) && firstInitial && lastName ? `${firstInitial}. ${lastName}` : '' }
                { /^fname lname$/i.test(format) && firstName && lastName ? `${firstName} ${lastName}` : '' }
                { /^full|fullname$/i.test(format) ? text : '' }
            </span>
        </span>
    )
}
