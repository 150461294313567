import CustomerForm from '../components/Customer/CustomerForm'
import CustomerTypeForm from '../components/CustomerTypes/CustomerTypeForm'
import GameRuleForm from '../components/GameRule/GameRuleForm'
import HitMissMessageForm from '../components/HitMissMessages/HitMissMessageForm'
import ResourceForm from '../components/Resources/ResourceForm'
import ResourceTypeForm from '../components/Resources/ResourceTypeForm'
import UserForm from '../components/User/UserForm'
import CompanyForm from '../components/Company/CompanyForm'
import PackageForm from '../components/Packages/PackageForm'
import LocationForm from '../components/Locations/LocationForm'
import TerminalForm from '../components/Terminals/TerminalForm'
import WaiverForm from '../components/Waivers/WaiverForm'
import ProductForm from '../components/Products/ProductsForm'
import DiscountsForm from '../components/Discounts/DiscountsForm'
import PackageGroupForm from "../components/PackageGroups/PackageGroupForm"
import GiftCardForm from "../components/GiftCards/GiftCardForm"
import GiftCardPromotionForm from '../components/GiftCardPromotions/GiftCardPromotionForm'
import GiftCardEditForm from '../components/GiftCardEdits/GiftCardEditForm'
import MembershipTypeForm from '../components/MembershipTypes/MembershipTypeForm'
import MenuForm from '../components/AdvancedPointOfSale/Menus/MenuForm'
import ItemForm from '../components/AdvancedPointOfSale/Items/ItemForm'
import CategoryForm from '../components/AdvancedPointOfSale/Categories/CategoryForm'
import SalesTaxTypeForm from '../components/AdvancedPointOfSale/SalesTaxTypes/SalesTaxTypeForm'
import ModifierGroupForm from '../components/AdvancedPointOfSale/ModifierGroups/ModifierGroupForm'
import ModifierForm from '../components/AdvancedPointOfSale/Modifiers/ModifierForm'
import FulfillmentProviderForm from '../components/AdvancedPointOfSale/FulfillmentProviders/FulfillmentProviderForm'
import PrinterForm from '../components/AdvancedPointOfSale/Printers/PrinterForm'
import CompReasonForm from '../components/AdvancedPointOfSale/CompReasons/CompReasonForm'
import MemberForm from '../components/Members/MemberForm'
import ItemTypeForm from '../components/AdvancedPointOfSale/ItemTypes/ItemTypeForm'
import ItemDiscountsForm from '../components/AdvancedPointOfSale/ItemDiscounts/ItemDiscountsForm'

/**
 * The FormMappings file is an object that gets exported. This object maps record types to individual
 * resource forms. For example, if we get passed a 'customer_type' record type, we map that to the
 * CustomerTypeForm. When creating new drawer form types, this is the only file in the whole system
 * that should have to change. The goal is to render the DrawerButton component inside of ERB files,
 * passing in the necessary props which will work its way down to rendering the individual form, with
 * the whole drawer system surrounding it.
 */
export default {
    'customer':                  CustomerForm,
    'customer_type':             CustomerTypeForm,
    'game_rule':                 GameRuleForm,
    'hit_miss_message':          HitMissMessageForm,
    'location_hit_miss_message': HitMissMessageForm,
    'resource':                  ResourceForm,
    'resource_type':             ResourceTypeForm,
    'user':                      UserForm,
    'company':                   CompanyForm,
    'package':                   PackageForm,
    'location':                  LocationForm,
    'terminal':                  TerminalForm,
    'waiver':                    WaiverForm,
    'discount':                  DiscountsForm,
    'product':                   ProductForm,
    'package_group':             PackageGroupForm,
    'gift_card':                 GiftCardForm,
    'gift_card_promotion':       GiftCardPromotionForm,
    'gift_card_email':           GiftCardEditForm,
    'membership_type':           MembershipTypeForm,
    'menu':                      MenuForm,
    'item':                      ItemForm,
    'category':                  CategoryForm,
    'sales_tax_type':            SalesTaxTypeForm,
    'modifier_group':            ModifierGroupForm,
    'modifier':                  ModifierForm,
    'fulfillment_provider':      FulfillmentProviderForm,
    'printer':                   PrinterForm,
    'comp_reason':               CompReasonForm,
    'member':                    MemberForm,
    'item_type':                 ItemTypeForm,
    'item_discount':             ItemDiscountsForm
}
