import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    selectMenuItem,
    setItemConfig,
    selectItemConfig,
    selectTemporary,
    selectInItemEditMode,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import MenuButton from '@/features/AdvancedPointOfSale/components/buttons/MenuButton'
import Alert from '@/features/AdvancedPointOfSale/components/Alert'
import { sortedByArray } from '@/lib/Array'
import { titleize } from 'inflected'

export default function MenuItemModifiers() {

    const dispatch       = useDispatch()
    const item           = useSelector(selectMenuItem)
    const itemConfig     = useSelector(selectItemConfig)
    const snapshot       = useSelector(selectTemporary)
    const inItemEditMode = useSelector(selectInItemEditMode)

    const [currentConfig, modifierGroups, defaultModifiers] = useMemo(() => {
        switch (true) {
            case inItemEditMode === 'check-item' :
                return [
                    (snapshot?.itemConfig || []),
                    sortedByArray([...(snapshot?.modifierGroups || [])], (snapshot?.modifierGroupsSortOrder || [])),
                    (snapshot?.defaultModifiers || []),
                ]

            case inItemEditMode === 'chit-item' :
                return [
                    itemConfig,
                    sortedByArray([...item.modifier_groups], item.modifier_groups_sort_order),
                    item.default_modifiers,
                ]

            // configuring / adding a new item
            default :
                return [
                    [],
                    sortedByArray([...item.modifier_groups], item.modifier_groups_sort_order),
                    item.default_modifiers,
                ]
        }
    }, [snapshot])

    const isSelected = (modifier) => {
        if (!itemConfig?.modifiers) { return false }
        return itemConfig.modifiers
                         .filter((m) => (
                             !/^remove|unavailable$/i.test(String(m.chit_action))
                         ))
                         .map((m) => m.id)
                         .includes(modifier.id)
    }

    const limitHasBeenReachedFor = (modifierGroup) => {
        if (!itemConfig?.modifiers || modifierGroup.max_quantity === null) { return false }

        return (itemConfig.modifiers
                          .filter((m) => m.is_available && !/^remove$/i.test(String(m.chit_action)))
                          .map((m) => m.modifier_group_ids)
                          .flat()
                          .filter((mgId) => mgId === modifierGroup.id)
                          .length
        ) >= modifierGroup.max_quantity
    }

    const handleToggle = (modifier, modifierGroup) => {
        let updatedModifiers = JSON.parse(JSON.stringify(itemConfig.modifiers))
        let _modifier        = {...modifier}

        // toggle off / remove
        if (isSelected(_modifier)) {
            if (_modifier.is_default) {
                const targetIndex = updatedModifiers.findIndex((m) => m.id === _modifier.id)
                updatedModifiers[targetIndex].chit_action = 'remove'
            } else {
                updatedModifiers = updatedModifiers.filter((m) => m.id !== _modifier.id)
            }

        // toggle on / add
        } else {
            // allow modifiers to be selected if...
            // - there is no max quantity limit
            // - or if the max quantity limit has nto yet been reached
            if (
                modifierGroup.max_quantity === null
                || (modifierGroup.max_quantity !== null && !limitHasBeenReachedFor(modifierGroup))
            ) {
                if (_modifier.is_default) {
                    const targetIndex = updatedModifiers.findIndex((m) => m.id === _modifier.id)
                    if (!updatedModifiers[targetIndex].is_available) { updatedModifiers[targetIndex].is_available = true }
                    updatedModifiers[targetIndex].chit_action = null
                } else {
                    _modifier.chit_action = 'add'
                    updatedModifiers = [...updatedModifiers, ...[_modifier]]
                }
            }
        }

        dispatch(setItemConfig({
            ...itemConfig,
            ...{ modifiers: updatedModifiers }
        }))
    }

    return (!item || !itemConfig) ? null : <>
        {
            modifierGroups.length === 0 && <>
                <h2 className='d-flex align-items-end h5 border-bottom pt-5 px-2 pb-1 mb-1'>
                    Modifiers
                </h2>
                <p className='p-2'>
                    No modifiers are available for this item.
                </p>
            </>
        }

        {
            defaultModifiers.find(modifier => !modifier.is_available) && (
                <Alert text="A Default Modifier is 86'd" className='my-3' />
            )
        }

        {
            modifierGroups.filter((modifierGroup) => modifierGroup.modifiers.length > 0)
                .map((modifierGroup) => (
                    <div key={`modifer-group-${modifierGroup.id}`} className='modifier-group'>
                        <h2 className='d-flex align-items-end h5 border-bottom pt-3 px-2 pb-1 mb-1'>
                            <span className={`${!!modifierGroup.max_quantity ? 'w-75' : 'w-100'} text-left`}>
                                { titleize(modifierGroup.name) }
                            </span>
                            {
                                !!modifierGroup.max_quantity && (
                                    <small className='w-25 text-right pl-3'>
                                        LIMIT { modifierGroup.max_quantity }
                                    </small>
                                )
                            }
                        </h2>

                        <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3 align-items-stretch no-gutters mx-n1'>
                            {
                                sortedByArray([...modifierGroup.modifiers], modifierGroup.modifiers_sort_order)
                                    .filter((modifier) => modifier.is_visible)
                                    .map((modifier) => {
                                        // modifier should be selectable on edit mode if it's already included in the tab
                                        const selectable = inItemEditMode === 'check-item' && !!(currentConfig || []).find(config => {
                                            return config.id === modifier.id && config.chit_action !== 'unavailable'
                                        })

                                        return <MenuButton
                                            key={`modifer-${modifier.id}`}
                                            data={modifier}
                                            isSelected={isSelected(modifier)}
                                            isUnavailable={!selectable && !modifier.is_available}
                                            onClick={() => handleToggle(modifier, modifierGroup)}
                                        />
                                    })
                            }
                        </div>
                    </div>
                ))
        }
    </>
}
