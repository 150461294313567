import React from 'react'

const tabs = [
    ['group',       'Group Details'],
    ['reservation', 'Reservation Details']
]

export default function ParentBookingModalTabs({ activeTab, handleClick }) {

    return (
        <ul className="modal-tabs" role="nav">
            {
                tabs.map((item) => (
                    <li key={item}>
                        <button
                            type='button'
                            className={`modal-tab ${item[0] === activeTab ? 'selected' : ''}`}
                            onClick={() => handleClick(item[0])}
                        >
                            {item[1]}
                        </button>
                    </li>
                ))
            }
        </ul>
    )
}
