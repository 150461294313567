import React from 'react'
import RDate from 'react-datepicker'
import moment from 'moment'
import { preventTyping } from '@/lib/Form'

export default function DateExclusionsFields({ exclusions=[], errors=[], handleUpdate }) {
    const handleExclusionChange = (index, date, key) => {
        handleUpdate('date_exclusions', exclusions.map((e, i) => {
            if (index === i) {
                return { ...e, [key]: moment(date).format('YYYY-MM-DD') }
            } else {
                return e
            }
        }))
    }

    const handleRemove = (index) => {
        handleUpdate('date_exclusions', exclusions.filter((e, i) => i !== index))
    }

    return (
        <>
            { exclusions.map((e, index) => (
                <div className="drawer-add-row" key={index}>
                    <div className="form-row">

                        <div className="col">
                            <div className="form-group pb-0">
                                <RDate
                                    placeholderText="Start"
                                    selected={e.from ? new Date(moment(e.from).format('l')) : ''}
                                    onChange={(d) => handleExclusionChange(index, d, 'from')}
                                    onKeyDown={preventTyping}
                                    className='form-control'
                                />
                            </div>
                        </div>

                        <div className="col-1 text-center">
                            <b>to</b>
                        </div>

                        <div className="col">
                            <div className="form-group pb-0">
                                <RDate
                                    placeholderText="End"
                                    selected={e.to ? new Date(moment(e.to).format('l')) : ''}
                                    onChange={(d) => handleExclusionChange(index, d, 'to')}
                                    onKeyDown={preventTyping}
                                    className='form-control'
                                />
                            </div>
                        </div>

                        <div className="col-2 text-right">
                            <button
                                type='button'
                                className='btn btn-danger text-white'
                                onClick={() => handleRemove(index)}
                            >
                                Remove
                            </button>
                        </div>
                    </div>
                </div>
            ))}

            {
                exclusions.length === 0 && (
                    <small className="d-block text-muted pt-1">No Date Exclusions</small>
                )
            }
        </>
    )
}
