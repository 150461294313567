import React, { useEffect, useRef } from 'react'
import { outerHeight } from '@/lib/Elements'

export default function StickyElement({
    id=null,
    className='',
    scrollableElementId='advanced-pos-terminal--main',
    stickyClasses='bg-gray0',
    offset=0,
    debug=false,
    children,
    onChange=() => {}
}) {
    const containerRef = useRef()
    const stickyRef    = useRef()
    const normalRef     = useRef()

    const handleScroll = () => {
        if (console && debug) {
            console.log(containerRef.current.scrollTop, outerHeight(normalRef.current))
        }

        if (containerRef.current.scrollTop > (outerHeight(normalRef.current) + Number(offset))) {
            stickyRef.current.classList.add('visible')
            stickyRef.current.classList.remove('invisible')
            onChange('add')
        } else {
            stickyRef.current.classList.add('invisible')
            stickyRef.current.classList.remove('visible')
            onChange('remove')
        }
    }

    useEffect(() => {
        containerRef.current = document.getElementById(scrollableElementId)
        containerRef.current.addEventListener('scroll', handleScroll, false)

        return () => {
            containerRef.current.removeEventListener('scroll', handleScroll, false)
        }
    }, [])

    return <>
        <div
            id={id}
            ref={stickyRef}
            className={`${className} sticky-element position-fixed z-index-2 w-100 invisible ${stickyClasses}`.trim()}
            children={children}
        />

        <div
            id={id}
            ref={normalRef}
            className={className}
            children={children}
        />
    </>
}
