import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useFormContext } from 'react-hook-form'
import MultiSelect from 'multiselect-react-dropdown'
import InputWrapper from './InputWrapper'
import MultiSelectExternalList from './MultiSelectExternalList'
import errorClass from '@/lib/Errors'

// Documentation:
// https://github.com/srigar/multiselect-react-dropdown

export default function MultiSelectCheckboxes({
    id,
    name='',
    displayValue='name',
    selectedValues=[],
    cols='12',
    errors=[],
    label,
    hideLabel,
    withWrapper,
    options=[],
    useExternalList=false,
    externalListItemsParentIdKey=null,
    externalListIsSortable=false,
    externalListIconOnMainList=null,
    externalListSubMenuIsSortable=false,
    externalListSubMenuIsDefaultIconClass=null,
    externalListCanLimitSubMenuItems=false,
    externalListCanLimitFieldName=null,
    iconType='is_default',
    emptyRecordMsg='None available.',
    placeholder='Select...',
    hidePlaceholder=false,
    disabled=false,
    singleSelect=false,
    selectionLimit='-1', // -1 is infinite/all
    avoidHighlightFirstOption=true,
    showCheckbox=true,
    isObject=true,
    loading=false,
    loadingMessage='Please wait...',
    req=null,
    validation=null,
    onSelect=() => {},
    onRemove=() => {},
    onSearch=() => {},
    onSort=() => {},
    onLimitUpdate=() => {},
    onIconChange=() => {},
    onSubItemsSort=() => {},
    onSubItemsIconChange=() => {},
    onSubItemsVisibilityChange=() => {},
    children
}) {
    const formMethods = useFormContext()

    /**
     * Example of an expected validation
     * schema object to register
     *
     * {
     *   'name': {
     *     required: 'A name is required',
     *     minLength: { value: 4, message: 'must be more than 4 characters long' }
     *   }
     * }
     */
    const separator = ':'

    const field = formMethods && validation
        ? formMethods.register( Object.keys(validation)[0], validation[Object.keys(validation)[0]] )
        : { name: name + separator + uuidv4(), ref: null }

    const _name = field.name.split(separator)[0]

    const handleSelect = (values, item) => {
        // update react hook form validation
        if ('onChange' in field) { formMethods.setValue(_name, values) }
        // update our form object
        onSelect(values, item)
    }

    const handleRemove = (values, removedItem) => {
        // update react hook form validation
        if ('onChange' in field) { formMethods.setValue(_name, values) }
        // update our form object
        onRemove(values, removedItem, true)
    }

    return (
        <InputWrapper
            name={_name}
            req={(validation && req === null) || Boolean(req)}
            cols={cols}
            errors={errors}
            label={label}
            hideLabel={hideLabel}
            withWrapper={withWrapper}
        >
            <MultiSelect
                ref={field.ref}
                id={id}
                name={_name}
                displayValue={displayValue}
                className={`custom-select ${useExternalList ? 'with-external-list' : ''} ${errorClass(_name, errors)}`}
                options={options}
                selectedValues={selectedValues}
                disable={disabled}
                singleSelect={singleSelect}
                selectionLimit={selectionLimit}
                avoidHighlightFirstOption={avoidHighlightFirstOption}
                showCheckbox={showCheckbox}
                placeholder={placeholder}
                hidePlaceholder={(!!selectedValues && !useExternalList) || hidePlaceholder}
                emptyRecordMsg={emptyRecordMsg}
                loading={loading}
                loadingMessage={loadingMessage}
                isObject={isObject}
                onSelect={handleSelect}
                onRemove={handleRemove}
                onSearch={onSearch}
            />

            {
                useExternalList && !children && (
                    <MultiSelectExternalList
                        items={selectedValues}
                        itemsParentIdKey={externalListItemsParentIdKey}
                        isSortable={externalListIsSortable}
                        iconOnMainList={externalListIconOnMainList}
                        isSubMenuSortable={externalListSubMenuIsSortable}
                        subMenuIsDefaultIconClass={externalListSubMenuIsDefaultIconClass}
                        canLimitSubMenuItems={externalListCanLimitSubMenuItems}
                        canLimitFieldName={externalListCanLimitFieldName}
                        iconType={iconType}
                        onRemove={handleRemove}
                        onSort={onSort}
                        onLimitUpdate={onLimitUpdate}
                        onIconChange={onIconChange}
                        onSubItemsSort={onSubItemsSort}
                        onSubItemsIconChange={onSubItemsIconChange}
                        onSubItemsVisibilityChange={onSubItemsVisibilityChange}
                    />
                )
            }

            { useExternalList && !!children && <>{ children }</> }
        </InputWrapper>
    )
}
