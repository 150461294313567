import React from 'react'
import { useSelector } from 'react-redux'
import { selectProducts, selectSelectedProducts } from './productSlice'
import ProductToggle from './ProductToggle'

export default function Products({ previewMode }) {

    const products         = useSelector(selectProducts)
    const selectedProducts = useSelector(selectSelectedProducts)

    const productClass = !previewMode
        ? `col-12 col-lg-6 col-xl-4 ${selectedProducts.length > 0 ? 'mb-4' : ''}`
        : 'col-4'

    return (
        <div className="row">
            {
                products.map((product) => {
                    const selected = selectedProducts.filter(s => s.id === product.id)[0] || { quantity: 0 }

                    return (
                        <div key={product.id} className={productClass}>
                            <ProductToggle
                                product={product}
                                quantity={selected.quantity}
                                previewMode={previewMode}
                            />
                        </div>
                    )
                })
            }
        </div>
    )
}
