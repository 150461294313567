import { createSlice } from "@reduxjs/toolkit";
import axios from 'axios'

export const resourcesSlice = createSlice({
    name: 'resources',
    initialState: {
        resources: []
    },
    reducers: {
        setResources: (state, action) => {
            state.resources = action.payload
        }
    }
})

export const {
    setResources
} = resourcesSlice.actions

export const selectResources = state => state.resources.resources

export function fetchResources(resourceTypeId) {
    return async (dispatch) => {
        axios.get(`/resource_types/${resourceTypeId}/resources.json`).then(({ data }) => {
            dispatch(setResources(data))
        })
    }
}

export default resourcesSlice.reducer