import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { setMemberDiscount, setDiscountNotes } from "../Products/productSlice"
import { debug } from '@/lib/Debug'

export const memberProductDiscountSlice = createSlice({
    name: "memberProductDiscount",
    initialState: {
        loading: null,
        valid: null,
        error: null,
        answer: null,
        membershipId: null,
        discount: null,
        message: null,
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setValid: (state, action) => {
            state.valid = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        },
        setMessage: (state, action) => {
            state.message = action.payload
        },
        setMembershipId: (state, action) => {
            state.membershipId = action.payload
        },
        setDiscount: (state, action) => {
            state.discount = action.payload
        },
        clear: (state, _action) => {
            state.valid        = false
            state.error        = null
            state.membershipId = null
            state.discount     = null
            state.message      = null
        },
    },
})

export const {
    setLoading,
    setValid,
    setError,
    setMembershipId,
    setMessage,
    setDiscount,
    clear
} = memberProductDiscountSlice.actions

export const selectLoading  = (state) => state.memberProductDiscount.loading
export const selectMemberId = (state) => state.memberProductDiscount.membershipId
export const selectValid    = (state) => state.memberProductDiscount.valid
export const selectError    = (state) => state.memberProductDiscount.error
export const selectMessage  = (state) => state.memberProductDiscount.message
export const selectDiscount = (state) => state.memberProductDiscount.discount

export function validateMembershipId(locationId, membershipId) {
    return async (dispatch, getState) => {
        if (!locationId) {
            dispatch(setValid(false))
            dispatch(setError(true))
            dispatch(setMessage("Missing parameter"))
            return
        }

        if (!membershipId) {
            dispatch(setValid(false))
            dispatch(setError(true))
            dispatch(setMessage("You must enter a Membership ID"))
            return
        }

        dispatch(setLoading(true))

        axios.post(`/locations/${locationId}/validate_membership_id`, {
            authenticity_token: getState().session.formToken,
            membership_id: membershipId,
        })
        .then(({ data }) => {
            if (data.success) {
                dispatch(setValid(true))
                dispatch(setMessage(null))
                dispatch(setError(null))
                dispatch(setDiscount(data.discount))  // this slice
                dispatch(setMemberDiscount(Number.parseFloat(data.discount)))
                dispatch(setDiscountNotes(`Member Discount: ${membershipId}`))
            } else {
                dispatch(setValid(false))
                dispatch(setMessage(data.message))
                dispatch(setError(data.error))
                dispatch(setDiscount(0.0))  // this slice
                dispatch(setMemberDiscount(0.0))
                dispatch(setDiscountNotes(null))
            }
        })
        .catch((e) => {
            if (debug && console) { console.warn(e) }
        })
        .finally(() => {
            dispatch(setLoading(false))
        })
    }
}

export default memberProductDiscountSlice.reducer
