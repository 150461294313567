import React, { useEffect, useState } from 'react'

export default function Alert({ show=true, delay=4000, data:alert=null, onHide=() => {}, children }) {
    const [visible, setVisible] = useState(show)

    const timerId = `alert_${alert.id.replace(/-/g, '_')}_timer`

    const startAutoDismiss = () => {
        window[timerId] = window.setTimeout(() => setVisible(false), delay + (alert.order * 800))
    }

    const cancelAutoDismiss = () => {
        window.clearTimeout(window[timerId])
    }

    useEffect(() => {
        startAutoDismiss()
    }, [])

    useEffect(() => {
        if (!visible) { onHide(alert.id) }
    }, [visible])

    return (
        <div className={`toast d-flex-inline ${visible ? 'show' : 'hide'} ${alert.type}`}
             role="alert"
             aria-live="assertive"
             aria-atomic="true"
             onMouseEnter={cancelAutoDismiss}
             onMouseLeave={startAutoDismiss}
        >
            <div className="toast-body font-weight-bold"
                 dangerouslySetInnerHTML={{ __html: (alert.text || children) }}
            />
        </div>
    )
}
