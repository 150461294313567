import React from 'react'
import titleize from '../../lib/String'

export default function Label({ className='', name, req, children=null }) {
    return (
        <label className={`${className} ${req ? 'req' : ''}`.trim()}>
            {typeof name === 'string' ? titleize(name) : name}
            {children}
        </label>
    )
}
