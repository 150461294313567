import React, { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from "moment"
import { selectBookingId } from '../EditBooking/editBookingSlice'
import { selectTimeZone } from '@/features/Locations/locationSlice'
import { fetchBookingHistory, selectChangeLog } from '../BookingHistory/bookingHistorySlice'

export default function BookingHistoryAddonTime() {

    const dispatch  = useDispatch()
    const bookingId = useSelector(selectBookingId)
    const tz        = useSelector(selectTimeZone)
    const changeLog = useSelector(selectChangeLog)

    const addonTime = useMemo(() => (
        changeLog.map((eventAndUser) => {
            /**
             * Loop over the individual change log entries that are found for the current booking. We then
             * loop over the individual object changes per column as individual table rows. This means right
             * now each COLUMN change is actually registered as a row... we would need some sort of consolidated
             * view to separate those but I'm assuming it doesn't really matter...
             */
            return {
                change: eventAndUser.event,
                user: eventAndUser.user || {},
                minutes: Object.keys(eventAndUser.event.object_changes)
                               .filter((column) => ['addon_minutes'].includes(column))
            }
        })
    ), [changeLog])

    const addonTimeCount = useMemo(() => (
        addonTime.map((object) => object.minutes.length)
                 .reduce((sum, count) => sum + count, 0)
    ), [addonTime])

    useEffect(() => {
        dispatch(fetchBookingHistory(bookingId))
    }, [bookingId])

    return (
        <table className="table table-middle table-sm mb-0">
            <colgroup>
                <col width="120px" />
                <col width="auto" />
                <col width="auto" />
            </colgroup>
            <tbody>
                {
                    addonTime.map((object) => {
                        const change = object.change
                        const user   = object.user

                        return object.minutes.map((column, index) => {

                            // the array of changes [from, to] for the individual column we're on
                            const changes = object.change.object_changes[column]
                            const from    = changes[0]
                            const to      = changes[1]

                            return (
                                <tr key={index}>
                                    <td className="text-right text-gray3 smaller pr-4">
                                        <strong>
                                            {moment(change.created_at).tz(tz).format('MMM D, YYYY')}
                                            <br />
                                            {moment(change.created_at).tz(tz).format('h:mm:ss:SSS A')}
                                        </strong>
                                    </td>

                                    <td>
                                        <strong>{user.username || 'System'}</strong> <span className="text-gray3 smaller">Changed</span> <strong>{column}</strong>
                                    </td>

                                    <td className="text-right"><strong>{from || 'N/A'}</strong></td>

                                    <td width="0" className="px-0"><i className="fas fa-arrow-right text-yellow mx-0"></i></td>

                                    <td><strong>{to || 'N/A'}</strong></td>
                                </tr>
                            )
                        })
                    })
                }

                {
                    addonTimeCount === 0 && (
                        <tr>
                            <td
                                className='text-muted text-center font-weight-bolder'
                                children='None Found'
                                colSpan='5'
                            />
                        </tr>
                    )
                }
            </tbody>
        </table>
    )
}
