import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import InputWrapper from './InputWrapper'
import DatePicker from 'react-datepicker'
import errorClass from '../../lib/Errors'
import { preventTyping } from '@/lib/Form'

export default function DateTimePicker({
  name,
  value='',
  cols,
  label,
  hideLabel,
  tooltip,
  req=null,
  errors=[],
  validation=null,
  popperPlacement='bottom',
  handleChange=() => {},
  disabled=false
}) {
    const formMethods = useFormContext()

    const _name = validation ? Object.keys(validation)[0] : name

    /**
     * Example of an expected validation
     * schema object to register
     *
     * {
     *   'name': {
     *     required: 'A name is required',
     *   }
     * }
     */

    const handleUpdate = (field, date) => {
        // update react hook form validation
        if ('onChange' in field) { field.onChange(date) }
        if ('onBlur' in field) { field.onBlur(date) }

        // update our form object
        handleChange({ target: { name: field.name, value: date } })
    }

    return (
        <InputWrapper
            name={_name}
            label={label}
            hideLabel={hideLabel}
            req={(validation && req === null) || Boolean(req)}
            tooltip={tooltip}
            cols={cols}
            errors={errors}
        >
            <div className={errorClass(_name, errors)}>
                { formMethods ? (
                    <Controller
                        name={_name}
                        control={formMethods.control}
                        defaultValue={value}
                        rules={validation ? validation[Object.keys(validation)[0]] : {}}
                        render={({ field }) =>
                            <DatePicker
                                ref={field.ref}
                                className={`form-control ${errorClass(_name, errors)}`}
                                timeIntervals={15}
                                showTimeSelect
                                popperPlacement={popperPlacement}
                                dateFormat="MMMM d, yyyy h:mm aa"
                                selected={field.value ? new Date(field.value) : ''}
                                onChange={(date) => handleUpdate(field, date)}
                                onBlur={(e) => handleUpdate(field, e.target.value)}
                                onKeyDown={preventTyping}
                                disabled={disabled}
                            />
                        }
                    />
                ) : (
                    <DatePicker
                        name={_name}
                        className={`form-control ${errorClass(_name, errors)}`}
                        timeIntervals={15}
                        showTimeSelect
                        popperPlacement={popperPlacement}
                        dateFormat="MMMM d, yyyy h:mm aa"
                        selected={value ? new Date(value) : ''}
                        onChange={(date) => handleUpdate({}, date)}
                        onBlur={(e) => handleUpdate({}, e.target.value)}
                        onKeyDown={preventTyping}
                        disabled={disabled}
                    />
                )}
            </div>
        </InputWrapper>
    )
}
