import React, { useEffect, useState } from 'react'
import BookingModalLedger from '@/features/Bookings/components/Ledger/BookingModalLedger'
import BookingModalFooter from '@/features/Bookings/components/BookingModalFooter'
import { PACKAGE_GROUP_STEP0 } from '@/lib/Storage'

export default function UnbookableError({ h2=null, text=null, modalStyles }) {

    const [step0Url, setStep0Url] = useState(null)

    /**
     * Initial component mount
     */
    useEffect(() => {
        /**
         * When step 1 mounts we need to check and grab the step 0 url to generate the back btn
         */
        const packageGroupStep0Url = window.sessionStorage.getItem(PACKAGE_GROUP_STEP0);

        if (packageGroupStep0Url) {
            setStep0Url(packageGroupStep0Url);
        }
    }, [])

    return <>
        <div className="xbm-step-content">
            <div className="xbm-step-content-main">
                <div className="xbm-step-question text-center pt-5">
                    <h2 className='mt-5 pt-5 mb-3'>
                        { h2 || "We're sorry..." }
                    </h2>
                    <p>{text || "This package is unable to be booked."}</p>
                </div>
            </div>

            <BookingModalLedger simple={true} />
        </div>

        <BookingModalFooter
            modalStyles={modalStyles}
            disableNext={true}
            step0Url={step0Url}
            onBack={() => window.location.href=step0Url}
        />
    </>
}
