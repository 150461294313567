import React from 'react'
import Input from '../Form/Input'
import FormRow from '../Form/FormRow'
import Checkbox from '../Form/Checkbox'
import { isAdvancedPointOfSaleEnabled } from '@/lib/AdvancedPointOfSale'

export default function ResourceTypeNonReservableSetupTab({
  form,
  location,
  errors,
  handleUpdate,
  handleCheckboxUpdate,
}) {
  return (
    <div className='pt-0'>
      <FormRow>
        <Input
          cols='6'
          name='title'
          label='Resource Type Name'
          value={form.title}
          errors={errors}
          validation={{ 'title': { required: true }}}
          handleChange={handleUpdate}
        />

        <Input
          cols='3'
          name='singular_name'
          value={form.singular_name}
          errors={errors}
          validation={{ 'singular_name': { required: true }}}
          handleChange={handleUpdate}
        />

        <Input
          cols='3'
          name='plural_name'
          value={form.plural_name}
          errors={errors}
          validation={{ 'plural_name': { required: true }}}
          handleChange={handleUpdate}
        />
      </FormRow>

      <hr className="mt-2 pb-2" />

      <FormRow className='mt-2'>
        <div className='col-6 d-flex align-items-start justify-content-between'>
          <Checkbox
            cols='6'
            name='is_reservable'
            label='Is Reservable?'
            value={form.is_reservable}
            disabled={!!form.created_at}
            handleChange={handleCheckboxUpdate}
          />

            <Input
              cols='6 mt-n2'
              name='is_reservable_quantity'
              value={form.is_reservable_quantity}
              type='number'
              min='0'
              errors={errors}
              validation={{ 'is_reservable_quantity': { required: form.is_reservable }}}
              hideLabel={true}
              prepend
              handleChange={handleUpdate}
            >
              <div className="input-group-prepend">
                <span className="input-group-text">Quantity</span>
              </div>
            </Input>
        </div>

        {
          isAdvancedPointOfSaleEnabled(location.advanced_point_of_sale_status, { allowTransition: true }) && (
            <Checkbox
              cols='6'
              name='display_in_pos'
              label='Display in Point of Sale'
              value={form.display_in_pos}
              handleChange={handleCheckboxUpdate}
            />
          )
        }
      </FormRow>
    </div>
  )
}
