import React, { useEffect, useState } from 'react'
import HeaderRow from '@/features/Schedule/components/ledger/HeaderRow'
import { humanizedLabel } from '@/lib/String'
import { numberToCurrency } from '@/lib/Number'

export default function AddonTimes({
    header=null,
    header_tooltip=null,
    addonTimes=[],
    isGroupPricing=false,
    initiallyFolded=false
}) {

    const [isFoldable, setIsFoldable] = useState(false)
    const [isFolded, setIsFolded]     = useState(initiallyFolded)

    const [totalTime, totalPrice] = (() => {
        let time  = 0
        let price = 0

        addonTimes.forEach((bat) => {
            bat.customer_types.forEach((t) => {
                time  += bat.duration
                price += isGroupPricing ? t.total_price : (t.price_per * t.quantity)
            })
        })

        return [time, price]
    })()

    useEffect(() => {
        setIsFoldable(addonTimes?.length > 0)
    }, [addonTimes])

    return addonTimes.length > 0 && <>
        <HeaderRow
            text={header}
            tooltip={header_tooltip}
            isFoldable={isFoldable}
            isFolded={isFolded}
            isGroupPricing={isGroupPricing}
            onChange={() => setIsFolded(!isFolded)}
        />

        {
            !isFolded && addonTimes.map((bat) => (
                bat.customer_types.map((t, index) => (
                    <tr key={`bat-${index}`}>
                        {
                          !isGroupPricing && (
                              <th
                                  className="text-left align-top"
                                  dangerouslySetInnerHTML={{ __html: humanizedLabel(t.name, true) }}
                              />
                          )
                        }
                        <th className={isGroupPricing ? 'text-left' : 'text-right align-top'}>
                            {bat.duration} MIN
                            { !isGroupPricing &&
                                <span className="text-lowercase"> x {t.quantity}</span>
                            }
                        </th>
                        <th className="text-right align-top text-dark">
                            { numberToCurrency(t.total_price || 0) }
                        </th>
                    </tr>
                ))
            ))
        }

        {
            isFolded && totalTime > 0 && (
                <tr>
                    <th colSpan='2'>{ totalTime } Minutes</th>
                    <th className='text-right text-dark'>{ numberToCurrency(totalPrice) || 0 }</th>
                </tr>
            )
        }
    </>
}

