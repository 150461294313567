import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    setOpen,
    selectArticle,
    fetchHelpJuiceArticle,
    fetchFreshDeskArticle
} from '@/features/Help/helpSlice'

export default function Help({
    articleId=null,
    service='FRESHDESK', // HELPJUICE
    extraClasses=''
}) {

    const dispatch = useDispatch()
    const article  = useSelector(selectArticle)

    // NOTE
    // If the articleId is set in the redux state, do not
    // re-load the article, just open the modal again
    const handleOpen = () => {
        dispatch(setOpen())

        if (service === 'HELPJUICE' && article?.article?.id != articleId) {
            dispatch(fetchHelpJuiceArticle(articleId))
        }

        if (service === 'FRESHDESK' && article?.article?.id != articleId) {
            dispatch(fetchFreshDeskArticle(articleId))
        }
    }

    return !!articleId && (
        <span id={`${service}--${articleId}`} className={`help ${extraClasses}`.trim()}>
            <button
                children={<i className='far fa-question-square' />}
                type='button'
                className='help-toggle btn-none'
                title='Click for help on this topic'
                onClick={handleOpen}
            />
        </span>
    )
}
