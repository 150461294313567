import React, { useState } from 'react'
import PackageGroupSetUpTab from './PackageGroupSetUpTab'
import PackageModalStyleTab from './PackageGroupModalStyleTab'
import PackageGroupTabs from './PackageGroupTabs'

export default function PackageGroupForm({
  form,
  errors,
  handleUpdate,
  handleCheckboxUpdate,
  handleManualUpdate,
  handleFormUpdate,
  directUploadUrl,
  imagesUrl,
  packages,
  location,
  fonts,
  new: newPackageGroup,
}) {

  const [tab, setTab] = useState('setup')
  const [brandingTabLoaded, setBrandingTabLoaded] = useState(false)

  const renderTab = () => {
    switch (tab) {
      case 'setup':
        return (
          <PackageGroupSetUpTab
            handleUpdate={handleUpdate}
            handleManualUpdate={handleManualUpdate}
            form={form}
            errors={errors}
            packages={packages}/>
        )
      case 'branding':
        return (
          <PackageModalStyleTab
            form={form}
            errors={errors}
            handleUpdate={handleUpdate}
            handleCheckboxUpdate={handleCheckboxUpdate}
            handleManualUpdate={handleManualUpdate}
            handleFormUpdate={handleFormUpdate}
            directUploadUrl={directUploadUrl}
            imagesUrl={imagesUrl}
            fonts={fonts}
            newPackageGroup={newPackageGroup}
            loaded={brandingTabLoaded}
            handleTabLoaded={() => setBrandingTabLoaded(true)}
          />
        )
    }
  }

  return (
    <div>
        <PackageGroupTabs
          handleClick={tab => setTab(tab)}
          activeTab={tab}
        />

      { renderTab() }
    </div>
  )
}
