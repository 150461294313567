import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    clearBookingCalendars,
    trackAbandonedBooking,
    selectCustomerTypeCounts,
    selectCustomerTypesWithoutPricesForSelectedDuration,
    selectParticipants,
    selectPreloaded,
    selectHeld,
    setErrors,
    setStep,
    setMemberCount,
    selectMemberCount,
    selectUniformParticipantCount
} from '../../bookingSlice'

import { selectLocation } from '../../../Locations/locationSlice'
import { selectPackage, selectPricingType } from '../../../Packages/packageSlice'
import { selectCustomerTypes } from '../../../CustomerTypes/customerTypeSlice'
import BookingModalFooter from '../BookingModalFooter'
import BookingModalLedger from '../Ledger/BookingModalLedger'
import CustomerTypeIncrementer from './CustomerTypeIncrementer'
import ParticipantIncrementer from './ParticipantIncrementer'
import MemberIncrementer from '../Members/MemberIncrementer'
import { gaSendPageView } from '@/lib/GoogleAnalytics'

export default function Step4({ modalStyles }) {

    const dispatch                = useDispatch()
    const pkg                     = useSelector(selectPackage)
    const preloaded               = useSelector(selectPreloaded)
    const bookingHeld             = useSelector(selectHeld)
    const customerTypes           = useSelector(selectCustomerTypes)
    const customerTypeCounts      = useSelector(selectCustomerTypeCounts)
    const maxParticipantsCount    = useSelector(selectParticipants)
    const memberCount             = useSelector(selectMemberCount)
    const pricingType             = useSelector(selectPricingType)
    const uniformParticipantCount = useSelector(selectUniformParticipantCount)
    const location                = useSelector(selectLocation)

    const customerTypesWithoutPricesForSelectedDuration = useSelector(selectCustomerTypesWithoutPricesForSelectedDuration)

    // Skip over non-participants when determining participant counts
    const participantIds = customerTypes.filter(c => !c.non_participant).map(c => c.id)

    const count = Object.keys(customerTypeCounts).map(k => Number.parseInt(k)).filter(k => participantIds.includes(k)).map(k => customerTypeCounts[k])

    // Protect against empty array reducing
    let selectedParticipantsCount = count.length ? count.reduce((a, b) => (a + b)) : 0

    // also add member count to selected participants count so the numbers are correct...
    selectedParticipantsCount += memberCount
    selectedParticipantsCount += uniformParticipantCount

    const filteredCustomerTypes = useMemo(() => (
        customerTypes.filter((type) => !customerTypesWithoutPricesForSelectedDuration.includes(type.id))
    ), [customerTypes])

    const handleNext = () => {
        let errors = []

        if (selectedParticipantsCount === 0) {
          dispatch(setErrors({ type: 'clear' }))
          errors.push('Please tell us more about your guests.')
        }

        if (selectedParticipantsCount > 0 && selectedParticipantsCount < maxParticipantsCount) {
            dispatch(setErrors({ type: 'clear' }))
            errors.push('There are still some guests you need to tell us about.')
        }

        if (errors.length === 0) {
            dispatch(trackAbandonedBooking())
            dispatch(setErrors({ type: 'clear' }))
            // TODO if the user selected any members, we have to take them to a different step
            // where they will fill out all of their member information, THEN they go to step 5...
            if (memberCount > 0) {
                dispatch(setStep('members'))
            } else {
                dispatch(setStep('5'))
            }
        } else {
            dispatch(setErrors({ type: 'add', errors }))
            return
        }
    }

    // MEMBERS ONLY SKIP - STEP 1
    // If the package is members only, we set the
    // number of members to the participant count...
    useEffect(() => {
        gaSendPageView('Define Your Guests', '/step/4')

        if (pkg.allow_members_only && memberCount === 0) {
            dispatch(setMemberCount(Number.parseInt(maxParticipantsCount, 10)))
        }
    }, [])

    // MEMBERS ONLY SKIP - STEP 2
    // ....then we can "skip" this step entirely on the next render
    useEffect(() => {
        if (pkg.allow_members_only && selectedParticipantsCount > 0) {
            handleNext()
        }
    }, [memberCount])

    return <>
        <div className="xbm-step-content">
            <div className="xbm-step-content-main">

                {/*<div className="xbm-step-counter">Step 4</div>*/}

                <div className="xbm-step-question text-center mb-5">
                    <h2>Tell us more about<br className="d-block d-md-none" /> who's coming.</h2>
                    <p>{selectedParticipantsCount} out of { maxParticipantsCount } guests</p>
                </div>

                {
                    pricingType === 'pricing_by_customer_type' && filteredCustomerTypes.map((type) => (
                        <CustomerTypeIncrementer
                            key={type.id}
                            type={type}
                            value={customerTypeCounts[type.id]}
                            disabled={type.non_participant === false ? selectedParticipantsCount >= maxParticipantsCount : false}
                        />
                    ))
                }

                {
                    pricingType === 'uniform_pricing' && !pkg.allow_members_only && (
                        <ParticipantIncrementer disabled={selectedParticipantsCount >= maxParticipantsCount} />
                    )
                }

                {
                    location.memberships_enabled && pkg.allow_member_benefits && (
                        <MemberIncrementer disabled={selectedParticipantsCount >= maxParticipantsCount} />
                    )
                }
            </div>

            <BookingModalLedger />
        </div>

        <BookingModalFooter
            modalStyles={modalStyles}
            disableNext={!bookingHeld}
            onBack={() => {
              dispatch(clearBookingCalendars())
              dispatch(setStep(preloaded ? '1' : '3'))
            }}
            onNext={handleNext}
        />
    </>
}
