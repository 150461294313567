import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    setCurrentComponent,
    selectComponent,
    selectIsSidebarOpen,
    selectInDeviceSetupMode,
    toggleSidebar,
    closeSidebar,
    toggleMenuSearch,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import MenuToggleButton from '@/components/MenuToggleButton'
import NewCheckButton from '@/features/AdvancedPointOfSale/components/buttons/UnknownResourceTypeCheckButton'
import UserIcon from '@/features/AdvancedPointOfSale/components/UserIcon'

export default function Navigation({ dynamicContent=null }) {

    const dispatch          = useDispatch()
    const loadedComponent   = useSelector(selectComponent)
    const isSidebarOpen     = useSelector(selectIsSidebarOpen)
    const inDeviceSetupMode = useSelector(selectInDeviceSetupMode)
    const buttonClasses     = 'btn btn-transprent btn-nav d-none d-sm-block'

    const buttonClassesFor = (name=null, extraClasses='') => (
        [
            buttonClasses,
            (name !== null && RegExp(name, 'i').test(loadedComponent) ? 'active' : ''),
            extraClasses
        ].join(' ').trim()
    )

    const handleLoadComponent = (name) => {
        dispatch(setCurrentComponent(name))
        if (isSidebarOpen) { dispatch(closeSidebar()) }
    }

    const handleOpenSearch = () => {
        dispatch(toggleMenuSearch('open'))
    }

    const handleToggleSidebar = (e) => {
        e.stopPropagation()
        dispatch(toggleSidebar())
    }

    return (
        <nav id="advanced-pos--terminal-navigation" className='row p-0 m-0'>
            <div
                id='advanced-pos--terminal-navigation--dynamic-content'
                className='col-9 col-sm-5 col-lg-6 col-xl-7 d-flex flex-row justify-content-start align-items-center px-0'
                children={dynamicContent}
            />

            <div
                id='advanced-pos--terminal-navigation--buttons'
                className='col-3 col-sm-7 col-lg-6 col-xl-5 d-flex flex-row justify-content-end align-items-center pl-0 pr-0'
            >
                {
                    !inDeviceSetupMode && <>
                        <button
                            children={<i className='fas fa-magnifying-glass' />}
                            type='button'
                            className={`${buttonClasses} px-3`}
                            onClick={handleOpenSearch}
                        />
                        <button
                            children='Menus'
                            type='button'
                            className={buttonClassesFor('Menu')}
                            disabled={/^(menu|menuitem)$/i.test(loadedComponent)}
                            onClick={() => handleLoadComponent('DefaultMenu')}
                        />

                        <button
                            children='Open Checks'
                            type='button'
                            className={buttonClassesFor('OpenChecks')}
                            disabled={/^(openchecks)$/i.test(loadedComponent)}
                            onClick={() => handleLoadComponent('OpenChecks', 'mr-1')}
                        />

                        <NewCheckButton className='mx-2' />
                    </>
                }

                <UserIcon size='sm' className='mr-1 d-none d-sm-flex' />

                <MenuToggleButton
                    className='btn-transparent text-white'
                    isOpen={isSidebarOpen}
                    onClick={handleToggleSidebar}
                />
            </div>
        </nav>
    )
}
