import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectTimeZone } from '@/features/Locations/locationSlice'
import { selectLoadedDates, selectLastLoadedDatetime, selectLabelWidth } from '@/features/Calendar/calendarSlice'
import { TimeHeader } from '@/features/Calendar/TimeHeader'
import { generateTimes } from '@/lib/CalendarTimes'

export function _GridHeaderTimes() {

    const timezone           = useSelector(selectTimeZone)
    const labelWidth         = useSelector(selectLabelWidth)
    const loadedDates        = useSelector(selectLoadedDates)
    const lastLoadedDatetime = useSelector(selectLastLoadedDatetime)

    const generatedTimes = useMemo(() => (
        generateTimes(loadedDates, lastLoadedDatetime, timezone).times
    ), [loadedDates, lastLoadedDatetime, timezone])

    const width = useMemo(() => (
        { width: `${labelWidth}px` }
    ), [labelWidth])

    /**
     * @TODO The styling for the whole hour time column labels is done with nth-child and is not a perminent solution.
     *       The whole hour time labels should be classed 'oclock'
     */
    return !(timezone && loadedDates && labelWidth) ? null : (
        <div className="times">
            <div className="time" style={width} />
            { generatedTimes.map((time, index) => <TimeHeader key={`${index}-${time}`} time={time} />) }
        </div>
    )
}

export const GridHeaderTimes = React.memo(_GridHeaderTimes)
