import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  selectStep,
  selectSelectedTerm,
  selectAutoRenew,
  selectTaxRate,
  selectMembershipsTaxable,
  selectRkdReservationFee,
  selectMerchantReservationFee,
  setTotal,
  selectTotal,
  selectGiftCards,
  setAllowCreditCardPayments,
} from '../memberSlice'

import MemberSteps, { termLength, termLengthLabel, calculatePrices } from '@/lib/Member'
import { numberToCurrency } from '@/lib/Number'

export default function MemberTermLedger() {

    const dispatch               = useDispatch()
    const step                   = useSelector(selectStep)
    const term                   = useSelector(selectSelectedTerm)
    const autoRenew              = useSelector(selectAutoRenew)
    const taxRate                = useSelector(selectTaxRate)
    const membershipsTaxable     = useSelector(selectMembershipsTaxable)
    const rkdReservationFee      = useSelector(selectRkdReservationFee)
    const merchantReservationFee = useSelector(selectMerchantReservationFee)
    const total                  = useSelector(selectTotal)
    const giftCards              = useSelector(selectGiftCards)

    const [subtotal, setSubtotal]         = useState(0.0)
    const [oneTimeFee, setOneTimeFee]     = useState(0.0)
    const [taxesAndFees, setTaxesAndFees] = useState(0.0)

    useEffect(() => {
        if (!!term) {
            const convertedTaxRate     = taxRate/100.0
            const rkdFee               = (parseFloat(rkdReservationFee) || 0.0)/100.0
            const merchantFee          = (parseFloat(merchantReservationFee) || 0.0)/100.0
            const additionalOneTimeFee = (parseFloat(term.additional_fees) || 0.0)

            const totals = calculatePrices(convertedTaxRate, rkdFee, merchantFee, additionalOneTimeFee, membershipsTaxable, term)

            setOneTimeFee(additionalOneTimeFee)
            setSubtotal(totals.subtotal)
            setTaxesAndFees(totals.taxesPlusReservationFees)
            dispatch(setTotal(totals.total))
        }
    }, [term])

    const white = [
        MemberSteps.PAYMENT_INFORMATION,
        MemberSteps.MEMBER_INFORMATION,
        MemberSteps.GIFTED_MEMBER_INFORMATION,
        MemberSteps.GIFTED_BUYER_INFORMATION,
    ]

    if (!white.includes(step)) {
        return null
    }

    const paymentDue = useMemo(() => {
        if (giftCards.length === 0) {
            return total
        } else {
            const appliedTotal = giftCards.reduce((sum, giftCard) => (
                sum + giftCard.appliedAmount
            ), 0)
            return total - appliedTotal / 100.0
        }
    }, [total, giftCards])

    useEffect(() => {
        dispatch(setAllowCreditCardPayments(paymentDue === 0 ? false : true))
    }, [paymentDue])

    return (
        <div className="xbm-ledger-section term-ledger pr-0">
            <div className="item">
                <div className="key smaller w-75">Term Length</div>
                <div className="value smaller w-25">{ termLength(term) } { termLengthLabel(term) }</div>
            </div>

            <div className="item">
                <div className="key smaller w-75">Auto-Renews?</div>
                <div className="value smaller w-25">{autoRenew ? 'Yes' : 'No'}</div>
            </div>

            <div className="item">
                <div className="key smaller w-75">Subtotal</div>
                <div className="value smaller w-25">{numberToCurrency(subtotal)}</div>
            </div>

            {
                !!oneTimeFee && (
                    <div className="item d-flex align-items-start">
                        <div className="key smaller w-75">
                            One-Time Fee:<br />
                            <small className='smaller font-italic'>{term.fees_label}</small>
                        </div>
                        <div className="value smaller w-25">{numberToCurrency(oneTimeFee)}</div>
                    </div>
                )
            }

            <div className="item border-top mt-3 pt-3">
                <div className="key smaller w-75">Taxes & Fees</div>
                <div className="value smaller w-25">{numberToCurrency(taxesAndFees)}</div>
            </div>

            <div className="item">
                <div className="key smaller w-75">Total</div>
                <div className="value smaller w-25">{numberToCurrency(total)}</div>
            </div>

            {
                giftCards.length > 0 && <>
                    {giftCards.map((giftCard, index) => {
                        return (
                            <div key={`gift-card-applied-${index}`} className="item">
                                <div className="key smaller w-75">
                                    Gift Card Ending in <span className='text-uppercase text-monospace'>{ giftCard.cardNumber.slice(-4) }</span>
                                </div>
                                <div className="value smaller w-25">
                                    - {numberToCurrency(giftCard.appliedAmount / 100)}
                                </div>
                            </div>
                        )
                    })}
                    <div className="item">
                        <div className="key smaller w-75">Payment Due</div>
                        <div className="value smaller w-25">
                            <strong>{numberToCurrency(paymentDue)}</strong>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}
