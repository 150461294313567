import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectLocation } from '@/features/Locations/locationSlice'

import {
    configureModal,
    selectCurrentCheck,
    selectModals,
    updateTokenizedCardOnTab,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import {
    selectTerminalResponse,
    tokenizeCardViaTerminal,
    preAuthorizeCardViaTerminal,
    cancelTerminalTransaction,
    clearAdyenState
} from '@/features/Adyen/adyenSlice'

import Modal from '@/features/AdvancedPointOfSale/components/Modal'
import AdyenTerminalTransaction from '@/features/Terminal/AdyenTerminalTransaction'
import ExistingTokenizedCardsOnFile from '@/features/AdvancedPointOfSale/components/ExistingTokenizedCardsOnFile'
import PreAuthorizationAmount from '@/features/AdvancedPointOfSale/components/PreAuthorizationAmount'
import { PREVENT_LOADER } from '@/lib/Storage'

export default function AddCardToTabModal({
    method='PRE_AUTHORIZE', // or TOKENIZE
}) {

    const dispatch               = useDispatch()
    const { addCardToTab:modal } = useSelector(selectModals)
    const location               = useSelector(selectLocation)
    const check                  = useSelector(selectCurrentCheck)
    const terminalResponse       = useSelector(selectTerminalResponse)
    const existingProfiles       = check?.booking?.payment_profiles || []

    const minimumPreAuthorizedAmount = Math.max(
        ((modal?.tab?.balance_cents || 0) / 100),
        location.minimum_pre_authorized_amount
    )

    const [amountToPreAuthorize, setAmountToPreAuthorize] = useState(null)
    const [selectedProfile, setSelectedProfile]           = useState(null)

    const capturingDisabled = useMemo(() => (
        method === 'PRE_AUTHORIZE' && (
            amountToPreAuthorize === null
            || isNaN(amountToPreAuthorize)
            || amountToPreAuthorize === Infinity
            || amountToPreAuthorize < minimumPreAuthorizedAmount
            || amountToPreAuthorize <= 0
        )
    ), [amountToPreAuthorize])

    const handleClose = () => {
        setAmountToPreAuthorize(null)
        setSelectedProfile(null)

        dispatch(cancelTerminalTransaction())

        dispatch(configureModal({
            modal: 'addCardToTab',
            config: { tab: null, isOpen: false },
        }))

        dispatch(clearAdyenState())
    }

    const handleSaveAndClose = () => {
        if (!!selectedProfile) {
            dispatch(updateTokenizedCardOnTab(modal.tab.id, 'add-existing', selectedProfile)).then(() => {
                handleClose()
            })
        }
    }

    const handleTokenizeCard = () => {
        setSelectedProfile(null)
        dispatch(tokenizeCardViaTerminal(location.id))
    }

    const handlePreAuthorizeCard = () => {
        dispatch(preAuthorizeCardViaTerminal(
            amountToPreAuthorize,
            location.id,
            modal.tab.id,
            'tab',
        ))
        .then((data) => {
            if (data.success) {
                dispatch(configureModal({
                    modal: 'popModal',
                    config: { isOpen: true, text: data.message, icon: 'fa-floppy-disk bg-green' }
                }))

                // the transaction was already attached
                // to the tab in the webhook response
                handleClose()
            }
        })
    }

    // automatically save and close once the
    // response comes back from the terminal
    useEffect(() => {
        if (modal.isOpen && !!terminalResponse) {
            dispatch(updateTokenizedCardOnTab(modal.tab.id, 'add')).then(() => {
                handleClose()
            })
        }
    }, [modal.isOpen, terminalResponse])

    useEffect(() => {
        if (modal.isOpen) {
            window.sessionStorage.setItem(PREVENT_LOADER, true)
            dispatch(clearAdyenState())
        } else {
            window.sessionStorage.removeItem(PREVENT_LOADER)
        }
    }, [modal.isOpen])

    return modal.isOpen && (
        <Modal
            className='add-card-to-tab-modal'
            title={method === 'TOKENIZE' ? 'Add Card To Tab' : 'Capture / Pre-Auth'}
            isOpen={modal.isOpen}
            footerButtons={<>
                <button
                    type='button'
                    className='btn btn-link text-bold'
                    children='Close'
                    onClick={handleClose}
                />

                {
                    !!selectedProfile && (
                        <button
                            type='button'
                            className='btn btn-primary ml-auto py-2 text-bold'
                            children='Save'
                            onClick={handleSaveAndClose}
                        />
                    )
                }
            </>}
            onClose={handleClose}
        >
            {
                (method === 'TOKENIZE' && existingProfiles.length > 0) && (
                    <div className="alert alert-warning rounded mt-4 mx-4 mb-2 text-center font-weight-bold font-italic" role="alert">
                        <p className='mb-0'>Either capture a new credit card or choose an existing stored card.</p>
                    </div>
                )
            }

            {
                (method === 'PRE_AUTHORIZE') && (
                    <PreAuthorizationAmount
                        amount={amountToPreAuthorize}
                        minimumAmount={minimumPreAuthorizedAmount}
                        formula={check.resource_type_is_bookable && modal.tab.is_general ? 'FULL' : 'BASIC'}
                        location={location}
                        booking={check?.booking}
                        onChange={(amount) => setAmountToPreAuthorize(amount)}
                    />
                )
            }

            <div className="p-4 w-100 form-group">
                <label className={method === 'TOKENIZE' && existingProfiles.length > 0 ? '' : 'req'}>
                    Select a terminal
                </label>

                {
                    // NOTE we intentionally wrap this component in the
                    // isOpen condition to force it to re-render whenever
                    // the modal opens/closes so that it's internal state
                    // does not become stale
                    modal.isOpen && (
                        <AdyenTerminalTransaction
                            locationId={location.id}
                            buttonText='Capture'
                            buttonTextProcessing='Capturing...'
                            buttonTextProcessed='Captured!'
                            menuClassName='border border-color-gray4'
                            disabled={capturingDisabled}
                            transactionCallback={() => {
                                if (method === 'TOKENIZE') {
                                    handleTokenizeCard()
                                }
                                if (method === 'PRE_AUTHORIZE') {
                                    handlePreAuthorizeCard()
                                }
                            }}
                        />
                    )
                }
            </div>

            {
                (method === 'TOKENIZE') && (
                    <ExistingTokenizedCardsOnFile
                        profiles={check?.booking?.payment_profiles}
                        selected={selectedProfile}
                        isOptional={check?.booking?.payment_profiles?.length > 0}
                        onChange={(data) => {
                            dispatch(cancelTerminalTransaction())
                            setSelectedProfile(data)
                        }}
                    />
                )
            }
        </Modal>
    )
}
