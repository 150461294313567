import { configureStore } from "@reduxjs/toolkit"
import scheduleSliceReducer from '../features/Schedule/scheduleSlice'
import sessionSliceReducer from '../features/Session/sessionSlice'
import referralTypeSliceReducer from '../features/Referrals/referralsSlice'
import occasionTypeSliceReducer from '../features/Occasions/occasionsSlice'
import sourceTypeSliceReducer from '../features/Sources/sourcesSlice'
import packageSliceReducer from '../features/Packages/packageSlice'
import customerTypeSliceReducer from '../features/CustomerTypes/customerTypeSlice'
import availabilitySliceReducer from '../features/Availability/availabilitySlice'
import resourceTypeSliceReducer from '../features/ResourceType/resourceTypeSlice'
import notificationSliceReducer from '../features/Notifications/notificationSlice'

export default configureStore({
    reducer: {
        schedule: scheduleSliceReducer,
        session: sessionSliceReducer,
        referrals: referralTypeSliceReducer,
        occasions: occasionTypeSliceReducer,
        sources: sourceTypeSliceReducer,
        package: packageSliceReducer,
        customerType: customerTypeSliceReducer,
        availability: availabilitySliceReducer,
        resourceType: resourceTypeSliceReducer,
        notifications: notificationSliceReducer
    }
})
