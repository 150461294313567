import titleize from '@/lib/String'
import { debug } from '@/lib/Debug'

/**
 * Steps of the membership modal
 */
export const MemberSteps = {
    /**
     * Main screen - select membership type
     */
    SELECT_MEMBER_TYPE: 'select',
    /**
     * Select recipient type (myself or gift)
     */
    SELECT_RECIPIENT: 'recipient',
    /**
     * Enter member information (for recipient type myself)
     */
    MEMBER_INFORMATION: 'information',
    /**
     * Enter the gifted member's information
     */
    GIFTED_MEMBER_INFORMATION: 'gifted-member-information',
    /**
     * Enter the purchaser of the membershi's information (buyer)
     */
    GIFTED_BUYER_INFORMATION: 'gifted-buyer-information',
    /**
     * Step where the user selects the term/duration for their membership.
     */
    MEMBERSHIP_TERM: 'membership-term',
    /**
     * Step where user enters their CC information.
     */
    PAYMENT_INFORMATION: 'payment-information',
    /**
     * Confirmation step after the user successfully paid
     */
    CONFIRMATION: 'confirmation'
}

/**
 * Membership recipient types (buying for yourself or as a gift)
 */
export const RecipientTypes = {
    /**
     * Membership is for the user (myself)
     */
    MYSELF: 'myself',
    /**
     * Membership is for someone else (gift)
     */
    GIFT: 'gift'
}

/**
 * Calculate customer type counts with the injected member customer id count.
 *
 * E.g. if members are attached to veterans, and no veterans were selected, this will inject how many members
 * into the customer type count as veterans so prices can be accurately calculated.
 */
export function calculateCustomerTypeCountsWithMembers(memberCount, customerTypeCounts, memberCustomerId) {
    // return normal customer type counts when no member count
    if (memberCount < 1) {
        return customerTypeCounts
    }

    let customerTypeCountsWithMember = { ...customerTypeCounts } // copy, don't modify since objects are by ref

    let target = customerTypeCountsWithMember[memberCustomerId] || 0
    target += memberCount
    customerTypeCountsWithMember[memberCustomerId] = target

    if (console) { console.log('~~~ Customer type counts with member: ', customerTypeCountsWithMember) }

    return customerTypeCountsWithMember
}

export function termLength(term) {
    return (
        Number.parseInt(
            debug && /\D/.test(term.term_length)
                ? term.term_length.split(/^(\d+)/).filter(v => v !== '')[0]
                : term.term_length
        , 10)
    )
}

export function termLengthLabel(term) {
    const isPlural = Number.parseInt(term.term_length, 10) === 1 ? false : true

    const labelBase = debug && /\D/.test(term.term_length)
        ? term.term_length.split(/^(\d+)/).filter(v => v !== '')[1]
        : 'Month'

    return titleize([
        labelBase,
        isPlural ? 's' : '',
    ].join(''))
}

export function calculatePrices(convertedTaxRate, rkdFee, merchantFee, additionalOneTimeFee, membershipsTaxable, term) {
    const calculatedSubtotal = parseFloat(term.price)
    let taxesPlusReservationFees

    if (membershipsTaxable){
        taxesPlusReservationFees = (calculatedSubtotal*convertedTaxRate) + (additionalOneTimeFee*convertedTaxRate) + (rkdFee*calculatedSubtotal) + (merchantFee*calculatedSubtotal)
    } else {
        taxesPlusReservationFees = (rkdFee*calculatedSubtotal) + (merchantFee*calculatedSubtotal)
    }

    return {
        subtotal: calculatedSubtotal,
        taxesPlusReservationFees: taxesPlusReservationFees,
        total: calculatedSubtotal + additionalOneTimeFee + taxesPlusReservationFees
    }
}

export default MemberSteps
