import React from 'react'
import Checkbox from '@/components/Form/Checkbox'
import Input from '@/components/Form/Input'
import FormRow from '@/components/Form/FormRow'
import { validationPatterns } from '@/lib/Errors'

export default function SalesTaxTypeForm({ form, errors, handleUpdate, handleCheckboxUpdate }) {
    return (
        <div>
            <FormRow>
                <Input
                    cols='6'
                    label='Tax Name'
                    value={form.name}
                    errors={errors}
                    validation={{ 'name': { required: true }}}
                    handleChange={handleUpdate}
                />

                <Input
                    cols='3'
                    label='Tax Rate'
                    value={form.tax_rate}
                    type='number'
                    min={0}
                    max={100}
                    step='1'
                    append={true}
                    errors={errors}
                    validation={{ 'tax_rate': {
                      required: true,
                      pattern: validationPatterns.taxRate
                    }}}
                    handleChange={handleUpdate}
                >
                    <div className="input-group-append">
                        <div className="input-group-text">%</div>
                    </div>
                </Input>


                <div className='col-3 text-center'>
                    <Checkbox
                        className='mt-1 pt-4'
                        name='is_default'
                        label='Is Default?'
                        value={form.is_default}
                        handleChange={handleCheckboxUpdate}
                    />
                </div>
            </FormRow>
        </div>
    )
}
