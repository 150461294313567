import React from "react"
import Help from '@/features/Help/Help'
import MembershipTermFields from "./MembershipTermFields"

export default function MembershipTerms({
    errors,
    membershipTerms = [],
    disabledIfMembers,
    handleManualUpdate,
}) {

    const name = "membership_terms"

    const handleAdd = () => {
        handleManualUpdate(
            name,
            [...membershipTerms].concat({
                term_length: null,
                price: null,
                price_includes_taxes_and_fees: false,
                additional_fees: null,
                fees_label: null,
                short_description: null,
            })
        )
    }

    const handleRemove = (index) => {
        handleManualUpdate(
            name,
            membershipTerms.filter((_p, i) => i !== index)
        )
    }

    const handleChange = (e, index) => {
        handleManualUpdate(
            name,
            membershipTerms.map((term, i) => {
                if (i === index) {
                    const name = e.target.name.split(/__/)[0]
                    return {
                        ...term,
                        [name]: e.target.value,
                    }
                } else {
                    return term
                }
            })
        )
    }

    return (
        <div className="confirmation-checks mb-2">
            <h3>Term Lengths and Prices<Help articleId='154000159022-term-lengths-prices' /></h3>

            <button
                children='+ Add Membership Term'
                type='button'
                className="drawer-side-action btn btn-link px-0 mt-n1"
                disabled={disabledIfMembers}
                onClick={handleAdd}
            />

            {
                membershipTerms.length > 0 ? (
                    membershipTerms.map((membershipTerm, index) => (
                        <MembershipTermFields
                            key={`membership-term-${index}`}
                            index={index}
                            isLast={(index + 1) === membershipTerms.length}
                            errors={errors}
                            disabledIfMembers={disabledIfMembers}
                            membershipTerm={membershipTerm}
                            onChange={handleChange}
                            onRemove={handleRemove}
                        />
                    ))
                ) : (
                    <div className="row text-center">
                        <div className={`col-12 text-${Object.keys(errors).length == 0 ? 'muted' : 'danger'}`}>
                            None right now — at least one is required
                        </div>
                    </div>
                )
            }

            {
                errors && errors[name] && (
                    <div className="invalid-feedback d-block">{errors[name].join(", ")}</div>
                )
            }
        </div>
    )
}
