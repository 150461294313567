import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useErrorBoundary } from 'react-error-boundary'

import {
    selectIsSessionLocked,
    setSessionLocked,
    updateIsSessionLocked,
    switchUser
} from '@/features/Session/sessionSlice'

import {
    afterUserSwitchActions,
    configureModal,
    closeSidebar,
    selectIsSidebarOpen,
    selectModals,
    setCurrentComponent,
    selectComponent,
    toggleMenuSearch,
    resetChecks,
    resetComps,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import UserIcon from '@/features/AdvancedPointOfSale/components/UserIcon'
import DeviceFingerPrint from '@/features/AdvancedPointOfSale/components/DeviceFingerPrint'
import PassCodeKeyPad from '@/features/AdvancedPointOfSale/components/PassCodeKeyPad'

export default function LockScreen({
    companyId=null,
    locationId=null,
    timeoutSeconds=null,
    debug=false
}) {

    if (!(!!timeoutSeconds) || isNaN(timeoutSeconds)) { return null }

    const dispatch               = useDispatch()
    const { showBoundary }       = useErrorBoundary()
    const { search:searchModal } = useSelector(selectModals)
    const isSessionLocked        = useSelector(selectIsSessionLocked)
    const isSidebarOpen          = useSelector(selectIsSidebarOpen)
    const currentComponent       = useSelector(selectComponent)

    const eventListeners   = (action) => {
        const events       = ['keydown', 'click', 'mousemove', 'touchmove', 'touchstart']
        const eventOptions = { capture: true, passive: false, }

        events.forEach((event) => {
            document[`${action}EventListener`](event, resetTimer, eventOptions)
        })

        if (debug && console) { console.log(`>> SESSION LOCK: EVENTS ${action}ED`.toUpperCase().replace(/VEE/ig, 'VE'))}
    }

    const startTimer = () => {
        window.__timer = window.setTimeout(() => {
            dispatch(updateIsSessionLocked(true, false))
                .then(() => { dispatch(setSessionLocked(true)) })
                .catch((e) => { showBoundary(e) })
        }, timeoutSeconds * 1000)

        if (debug && console) { console.log('>> SESSION LOCK: TIMER STARTED')}
    }

    const clearTimer = () => {
        if (!!window.__timer) {
            window.clearTimeout(window.__timer)
            delete window.__timer
            if (debug && console) { console.log('>> SESSION LOCK: TIMER CLEARED')}
        }
    }

    const resetTimer = () => {
        if (!!window.__timer) {
            if (debug && console) { console.log('>> SESSION LOCK: TIMER RESETTING')}
            clearTimer()
            startTimer()
        }
    }

    const attemptUnlock = (pin) => {
        dispatch(switchUser(companyId, locationId, pin))
            .then((result) => {
                // if result was true then the user switched
                // instead of simply unlocking the current user
                if (result === true) {
                    dispatch(afterUserSwitchActions())
                }

                // if result was false, then the same user
                // returned and unlocked the device
                if (result === false) {
                    if (/^(menuitem)$/i.test(currentComponent)) {
                        dispatch(setCurrentComponent('DefaultMenu'))
                    }

                    if (/^(checkout)$/i.test(currentComponent)) {
                        dispatch(setCurrentComponent('PrintPay'))
                    }

                    if (/^(EndOfShift|CloseOfDay)$/i.test(currentComponent)) {
                        dispatch(configureModal({
                            modal: 'transactions',
                            config: { check: null, isOpen: false }
                        }))
                    }
                }
            })
            .catch((e) => { showBoundary(e) })
    }

    useEffect(() => {
        if (isSessionLocked === null) { return }

        if (isSessionLocked) {
            if (isSidebarOpen) {
                dispatch(closeSidebar())
            }

            if (searchModal.isOpen) {
                dispatch(toggleMenuSearch('close'))
            }

            dispatch(resetChecks())
            dispatch(resetComps())
        }

        if (isSessionLocked === true && !!window.__timer) {
            clearTimer()
            eventListeners('remove')
        }

        if (isSessionLocked === false) {
            eventListeners('add')
            startTimer()
        }
    }, [isSessionLocked])

    return (
        <div
            id='advanced-pos--lock-screen'
            className={`${isSessionLocked ? 'd-flex' : 'd-none'} flex-column justify-content-center align-items-center`}
        >
            {
                isSessionLocked && <>
                    <DeviceFingerPrint
                        showBattery={true}
                        showBatteryPercent={false}
                        showLocation={true}
                    />

                    <div className='row'>
                        <div className='col-10 offset-1 text-center'>
                            <UserIcon size='xxl' className='d-flex shadow mx-auto mb-4' />

                            <PassCodeKeyPad
                                id='advanced-pos--lock-screen-form'
                                captureEvents={isSessionLocked}
                                onChange={attemptUnlock}
                            />
                        </div>
                    </div>
                </>
            }
        </div>
    )
}
