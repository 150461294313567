export function sortedByArray(items=[], sortOrder=null, key='id') {
    if (!Array.isArray(items)) { return items }

    return items.sort((a,b) => (sortOrder || items).indexOf(a[key]) - (sortOrder || items).indexOf(b[key]))
}

export function sortByKey(items=[], key='id', direction='asc') {
    let result = items.sort((a, b) => {
        const x = a[key]
        const y = b[key]
        return ((x < y) ? -1 : ((x > y) ? 1 : 0))
    })

    return /^(desc)$/i.test(direction) ? result.reverse() : result
}

export function sortedAsc(object={}) {
    return Object.fromEntries(
        Object.entries(object).sort((a,b) => a[0].localeCompare(b[0]))
    )
}

export function sortedDesc(object={}) {
    return Object.fromEntries(
        Object.entries(object).sort((a,b) => b[0].localeCompare(a[0]))
    )
}

export function unique(items=[], key=null) {
    return items.reduce((acc, obj) => {
        if (key === null) {
            if (!acc.some(o => o === obj)) {
                acc.push(obj)
            }
        } else {
            if (!acc.some(o => o[key] === obj[key])) {
                acc.push(obj)
            }
        }
        return acc
    }, [])
}
