import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectTimeZone } from '@/features/Locations/locationSlice'
import { selectDate, setDate, setTime } from '@/features/Bookings/bookingSlice'
import moment from 'moment'

export default function DayNavigation() {

    const dispatch = useDispatch()

    /**
     * Grab the timezone of the location we're booking on
     */
    const tz = useSelector(selectTimeZone)

    /**
     * We always use the booking date because if the user navigates between dates using this component, they are actually
     * changing their booking date as well. In other words, their initial date is selected from the previous step's calendar
     * component. When they get to step 3 we load the times for that date, but they are also able to navigate to different
     * dates using this component, which is actually changing the booking date as well.
     */
    const date = useSelector(selectDate)

    // the moment format to format all our dates under -> Wed, May 26th
    const format = 'ddd, MMM Do'

    const handleClick = (newDate) => {
        const formatted = moment(newDate).format('YYYY-MM-DD')

        /**
         * Since our time sets a somewhat arbitrary date but sets a specific time, if the user
         * navigates away from the current date we should also reset the time since that time
         * may no longer be applicable for that new day they navigated to in the first place.
         */
        dispatch(setTime(null))

        /**
         * Whenever this date changes and we're still on Step 3, the useEffect
         * in step3 itself will automatically fetch new times for the new date since
         * it is a dependency on the useEffect. To see why we're not manually calling
         * anything here see app/javascript/features/Bookings/components/Step3/Step3.jsx
         */
        dispatch(setDate(formatted))
    }

    const current = moment.tz(date, tz)
    const prev    = moment.tz(date, tz).subtract('1', 'days')
    const next    = moment.tz(date, tz).add('1', 'days')

    return (
        <div className="xbm-time-select-change-day">
            <div className="xbm-time-day xbm-time-day-prev">
                <button className="btn d-none d-sm-block" onClick={() => handleClick(prev)}>
                    <i className="far fa-chevron-left mr-1"></i>
                    &nbsp;
                    {prev.format(format)}
                </button>

                <button className="btn d-sm-none" onClick={() => handleClick(prev)}>
                    <i className="far fa-chevron-left mr-1"></i>
                    &nbsp;
                    PREV
                </button>
            </div>

            <div className="xbm-time-day xbm-time-day-current">
                {current.format(format)}
            </div>

            <div className="xbm-time-day xbm-time-day-next">
                <button className="btn d-none d-sm-block" onClick={() => handleClick(next)}>
                    {next.format(format)}
                    &nbsp;
                    <i className="far fa-chevron-right mr-1"></i>
                </button>

                <button className="btn d-sm-none" onClick={() => handleClick(next)}>
                    NEXT
                    &nbsp;
                    <i className="far fa-chevron-right mr-1"></i>
                </button>
            </div>

        </div>
    )
}
