import React, { useEffect } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import { useConfirm } from '@/lib/useConfirmHook'
import { scrollToBooking } from '@/features/Calendar/calendarSlice'
import { setParentBooking, setChildBookings, setParentBookingModalOpen } from '@/features/ParentBooking/parentBookingSlice'
import { selectBooking, resetBooking, flagBookingDrawerOpen, setExpandedActions } from '@/features/EditBooking/editBookingSlice'
import { setOpen, setStep, selectStep } from '@/features/Schedule/scheduleSlice'
import { clearPrices } from '@/features/Pricing/pricingSlice'
import { resetTempPackageId } from '@/features/Packages/packageSlice'
import BookingSetupStep1 from './steps/BookingSetupStep1'
import BookingSetupStep2 from './steps/BookingSetupStep2'
import BookingSetupStep3 from './steps/BookingSetupStep3'
import BookingSetupStep4 from './steps/BookingSetupStep4'
import BookingSetupStepDebug from './steps/BookingSetupStepDebug'

/**
 * Evaluate which step we're on and which component(s) to render based on that,
 * essentially serving as our router.
 */
export default function ScheduleDrawerBody(props) {

    const dispatch    = useDispatch()
    const { confirm } = useConfirm()
    const { reset }   = useFormContext()
    const booking     = useSelector(selectBooking)
    const step        = useSelector(selectStep)

    /**
     * Handle closing the drawer when ESC key
     * is pressed as long as...
     *   - this modal itself is not already open
     *   - and another modal on top of it is not already open
    const handleKeyDown = (e) => {
        if (
            open
            && props.modalOpenRef.current
            && e.key === 'Escape'
            && !document.body.classList.contains(UI.classes.MULTIPLE_MODALS_OPEN)
        ) {
            handleClose()
        }
    }
     */

    const handleClose = async (thenViewParent=true) => {
        if (props.formIsDirtyRef.current && !(await confirm('Are you sure you want to close?<br /><br />Any changes will not be saved.'))) { return }

        reset()
        dispatch(setOpen(false))
        dispatch(resetBooking())
        dispatch(setStep('1'))
        dispatch(clearPrices())
        dispatch(resetTempPackageId())

        // flag drawer as no longer open on the booking
        if (booking?.id) {
            dispatch(flagBookingDrawerOpen(booking?.id, false))
        }

        if (booking?.parent?.id && thenViewParent) {
            handleViewParent()
        }
    }

    const handleViewParent = async () => {
        if (!booking?.parent?.id) { return }

        axios.get(`/bookings/${booking.parent.id}/information`)
        .then(({ data }) => {
            dispatch(setParentBooking({ booking: data.booking, resources: data.resources }))
            dispatch(setChildBookings({ childBookings: data.child_bookings }))
            dispatch(setParentBookingModalOpen(true))
            dispatch(flagBookingDrawerOpen(data.booking.id, true))
            dispatch(scrollToBooking(data.booking))
        })
        .then(() => {
            handleClose(false)
        })
    }

    /**
     * Handle the initial mount and
     * unmount of the component
     */
    useEffect(() => {
        //document.addEventListener('keydown', handleKeyDown, false)

        // cleanup/reset on unmount
        return () => {
            dispatch(setExpandedActions(false))
            //document.removeEventListener('keydown', handleKeyDown, false)
        }
    }, [])

    switch (step) {
        case '1':
            return <BookingSetupStep1 handleClose={handleClose} {...props} />
        case '2':
            return <BookingSetupStep2 handleClose={handleClose} {...props} />
        case '3':
            return <BookingSetupStep3 handleClose={handleClose} handleViewParent={handleViewParent} {...props} />
        case '4':
            return <BookingSetupStep4 handleClose={handleClose} handleViewParent={handleViewParent} {...props} />
        case 'debug':
            return <BookingSetupStepDebug handleClose={handleClose} handleViewParent={handleViewParent} {...props} />
    }
}
