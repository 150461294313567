import React, { useMemo } from 'react'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { useFormContext } from 'react-hook-form'
import FormRow from '@/components/Form/FormRow'
import PackageDaySelect from '@/components/Packages/PackageDaySelect'
import MomentTimePicker from '@/components/Form/MomentTimePicker'
import { preventTyping } from '@/lib/Form'

import {
    name,
    everyDay,
    handleAdd,
    handleRemove,
    handleTimeChange,
    handleDayChange,
} from '@/components/Packages/mixins/packageTimes'

export default function CustomTimeRanges({
    form,
    errors,
    times=[],
    daysClosed,
    timezone,
    handleManualUpdate,
}) {
    const { register } = useFormContext()

    const uuid = useMemo(() => uuidv4(), [times.length])

    const field = register(name, {
        validate: {
            atLeastOneTime: () => {
                return times.length >= 1 || 'Must have at least one custom open/close range.'
            },
            validTimes: () => {
                let emptyTimes = times.filter((t) => !t.time || !t.end_time)
                return emptyTimes.length === 0 || 'All times must be filled out.'
            },
            validDays: () => {
                let emptyDays = times.filter((t) => t?.days.length === 0)
                return emptyDays.length === 0 || 'At least one day must be set.'
            }
        }
    })

    return (
        <div className="custom-time-ranges specific-time-increments mb-2">
            <h4>Custom Open Hours</h4>

            <button
                type='button'
                className="drawer-side-action btn btn-link"
                onClick={() => handleAdd(times, daysClosed, handleManualUpdate)}
            >
                + Add Time
            </button>

            {
                times.map((object, index) => (
                    <div className="drawer-add-row py-3" key={`${uuid}-${index}`}>
                        <FormRow className="drawer-add-row border-bottom-0">
                            <div className="col">
                                <div className="form-group pb-0 mb-n3">
                                    <MomentTimePicker
                                        hideLabel={true}
                                        placeholder='XX:00 AM'
                                        value={object?.time ? moment(object.time, 'LT') : ''}
                                        tz={timezone}
                                        preventTyping={preventTyping}
                                        handleUpdate={(e) => handleTimeChange(
                                            form,
                                            times,
                                            e.target.value,
                                            null,
                                            timezone,
                                            index,
                                            handleManualUpdate
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="col-1 text-center">
                                <b>to</b>
                            </div>

                            <div className="col">
                                <div className="form-group pb-0 mb-n3">
                                    <MomentTimePicker
                                        hideLabel={true}
                                        placeholder='XX:00 PM'
                                        value={object?.end_time ? moment(object.end_time, 'LT') : ''}
                                        tz={timezone}
                                        preventTyping={preventTyping}
                                        handleUpdate={(e) => handleTimeChange(
                                            form,
                                            times,
                                            null,
                                            e.target.value,
                                            timezone,
                                            index,
                                            handleManualUpdate
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="col-2 text-right">
                                <button
                                    type='button'
                                    className='btn btn-danger text-white'
                                    onClick={() => handleRemove(times, index, handleManualUpdate)}
                                >
                                    Remove
                                </button>
                            </div>
                        </FormRow>

                        <div className="col-12 col-md-10 col-lg-9 pl-4 py-2">
                            <PackageDaySelect
                                name={`specific_start_time_weekday_${index}`}
                                values={object?.days || everyDay.filter((d,i) => daysClosed[i] === false)}
                                errors={errors}
                                compact={true}
                                daysClosed={daysClosed}
                                overridingPublicStoreHours={form.override_public_store_hours}
                                handleUpdate={(name, value) => handleDayChange(times, name, value, handleManualUpdate)}
                            />
                        </div>
                    </div>
                ))
            }

            {
                errors[field.name] && (
                    <div className="invalid-feedback d-block border-top pt-2 pl-3" style={{ fontSize: '0.8rem' }}>
                        { errors[field.name].join(', ') }
                    </div>
                )
            }
        </div>
    )
}
