import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { performSearch, selectSearch, setSearch } from './calendarSearchSlice'
import { selectOpen as selectScheduleDrawerOpen } from '../Schedule/scheduleSlice'
import { selectOpen as selectSearchOpen } from '../CalendarSearch/calendarSearchSlice'

export default function ToolbarSearch() {

    const dispatch = useDispatch()

    const search = useSelector(selectSearch)
    const scheduleOpen = useSelector(selectScheduleDrawerOpen)
    const searchOpen = useSelector(selectSearchOpen)

    const handleChange = e => {
        dispatch(setSearch(e.target.value))
    }

    const handleKeyDown = e => {
        if (e.key !== 'Enter') {
            return
        }

        dispatch(performSearch())
    }

    return (
        <input
            disabled={scheduleOpen || searchOpen}
            value={search || ''}
            type="text"
            placeholder="Search..."
            className="form-control"
            tabIndex="1"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
        />
    )
}
