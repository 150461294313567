import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectPartySizeMessages } from '../../../Packages/packageSlice'
import { selectParticipants } from '../../bookingSlice'
import { debug } from '@/lib/Debug'

export default function PartySizeMessage() {
    const messages     = useSelector(selectPartySizeMessages)
    const participants = useSelector(selectParticipants)

    const [message, setMessage]       = useState(null)
    const [isRichText, setIsRichText] = useState(false)

    /**
     * Ref to track rich content message div (below)
     */
    const richTag = React.createRef()

    /**
     * Effect for tracking changes to our rich text message div. When content
     * is loaded it will scan the div for 'a' tags, and set their targets as blank since
     * this is being loaded inside of an iframe, we can't link directly to external sites
     * from within the same window.
     *
     * NOTE: if this needs to be expanded upon, I would recommend getting this into its own component.
     */
    useEffect(() => {
        // skip when empty -- no rich text to modify
        if (!richTag.current) {
            return
        }

        // query for all 'a' tags within our rich-party-message div (see below for id)
        const tags = document.querySelectorAll('#rich-party-message a')

        // loop over each tag, and set the target to blank
        for (let i = 0; i < tags.length; ++i) {
            tags[i].target = '_blank'
        }
    }, [richTag])

    /**
     * Find the group size message that corresponds with the currently selected
     * participant count. This will grab the first message that fits within
     * the `from` and `to` range of the group message
     */
    const findMessage = () => {
        try {
          return messages.filter((m) => (
            Number.parseInt(participants, 10) >= m.from && Number.parseInt(participants, 10) <= m.to
          ))[0]?.message || ''
        } catch (e) {
            if (debug && console) { console.log(e) }
            return ''
        }
    }

    useEffect(() => {
        if (messages && participants) {
            setMessage(findMessage())
        }
    }, [messages, participants])

    useEffect(() => {
        if (message) { setIsRichText(/[\<\>]/ig.test(message)) }
    }, [message, participants])

    return message && (
        <div className={`xbm-range-custom-messaging ${isRichText ? 'text-left' : 'text-center'}`}>
            { isRichText ? (
                <div id="rich-party-message" className="pt-2" dangerouslySetInnerHTML={{ __html: message }} ref={richTag} />
              ) : (
                <div className="pt-2">{ message }</div>
              )
            }
        </div>
    )
}
