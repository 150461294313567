import React from 'react'
import { isPaid, isOverPaid } from '@/features/AdvancedPointOfSale/lib/Checks'

export default function TabStatus({
    id=null,
    className='',
    iconSize='',
    tab=null,
    compact=true,
}) {
    const _iconSize = !!iconSize ? iconSize : (compact ? 'fa-md' : 'fa-lg')

    return !!tab && (
        <span id={id} className={`ml-2 d-flex-inline justify-items-start align-items-center ${className}`.trim()}>
            {
                isPaid(tab) && !isOverPaid(tab) && <span>
                    <span className={`text-green mt-2 ${_iconSize} ${compact ? 'mx-1' : 'ml-4'}`}><i className='fa-solid fa-sack-dollar' /></span>
                    { compact ? null : <span className='mt-1 ml-2'><small>PAID</small></span> }
                </span>
            }

            {
                isOverPaid(tab) && <span>
                    <span className={`fa-fade text-warning mt-2 ${_iconSize} ${compact ? 'mx-1' : 'ml-4'}`}><i className='fa-solid fa-triangle-exclamation' /></span>
                    { compact ? null : <span className='mt-1 ml-2'><small>OVERPAID</small></span> }
                </span>
            }

            {
                tab.has_unallocated_booking_credits && <>
                    <span className={`warning-stack fa-layers ${_iconSize} ${compact ? 'mx-1 mb-n1' : 'ml-4'} fa-fade `}>
                        <i className="fas fa-triangle-exclamation text-danger" />
                        <i className="fa-inverse fas fa-exclamation text-white" data-fa-transform="shrink-7 down-1" />
                    </span>
                    { compact ? null : <span className='mt-1 ml-2'><small>RESERVATION OVERPAYMENT</small></span> }
                </>
            }

            {
                tab.is_closed && <span>
                    <span className={`text-green mt-2 ${_iconSize} ${compact ? 'mx-1' : 'ml-4'}`}><i className='fa-solid fa-flag-checkered' /></span>
                    { compact ? null : <span className='mt-1 ml-2'><small>CLOSED</small></span> }
                </span>
            }
        </span>
    )
}
