import React, { useRef, useState } from "react";
import {FormProvider, useForm} from "react-hook-form";
import Input from "../Form/Input";
import axios from "axios";
import CustomerWaiversRenewWaiver from "./CustomerWaiversRenewWaiver";
import { errorsFor } from '@/components/Form/ErrorsHelper'

export default function CustomerWaiversSearch({
  waiver,
  company,
  location,
  reservation_number,
  resourceTypeId,
  showSuccess,
  showAdult,
  showMinor,
}) {
  const formMethods                       = useForm()
  const submitButtonRef                   = useRef()
  const [searchResults, setSearchResults] = useState(null)
  const [showResults, setShowResults]     = useState(false)
  const [errors, setErrors]               = useState({})

  /**
   * Loop through form errors and build up our own errors
   * object that gets passed down to all of the components
   */
  const onError = (formErrors) => {
    setErrors(errorsFor(formErrors))
  }

  const resetSearchResults = () => {
    setSearchResults(null)
  }

  const handleEnter = (e) => {
      if (e.keyCode === 13) {
          submitButtonRef.current.click()
      }
  }

  const handleSearch = ({search}) => {
    resetSearchResults()

    axios.get(`/companies/${company.url_hash}/locations/${location.url_hash}/waivers/${reservation_number}/search_waivers`, {
      params: {
        q: search,
        resource_type_id: resourceTypeId,
      }
    })
    .then(({ data }) => {
      setSearchResults(data)
    }).catch(e => {
      console.warn(e)
    }).finally(() => {
      setShowResults(true)
      document.getElementById('waiver_search_results_container').scrollIntoView()
    })
  }

  /*
  const handleClick = (adult_or_minor) => {
    if (adult_or_minor === 'minor') {
      document.getElementById('sign-minor-waiver').click()
    } else {
      document.getElementById('sign-adult-waiver').click()
    }
  }
  */

  return (
    <div id='waiver_search'>
      <h3 className='waiver-header'>
        SEARCH WAIVERS
      </h3>

      <FormProvider {...formMethods} >
        <form id="waiver-form" onSubmit={formMethods.handleSubmit(handleSearch, onError)}>

          <div className="waiver_email_search row">
            <Input
              id="waiver-search-input"
              cols="col-12 col-lg-6 mx-auto"
              name='search'
              hideLabel={true}
              value={formMethods.watch("search", '')}
              errors={errors}
              append={true}
              placeholder="Full Email Address or Full Name"
              validation={{ 'search': {
                required: "Please enter a full email address or a full name to search"
              }}}
              handleKeyDown={handleEnter}
            >
                <div className="input-group-append">
                  <button
                    ref={submitButtonRef}
                    name="button"
                    type="submit"
                    className="btn btn-outline-primary btn-block"
                  >
                      Search
                  </button>
                </div>
            </Input>
          </div>

        </form>
      </FormProvider>

      {showResults && (
        <CustomerWaiversRenewWaiver
          waiver={waiver}
          company={company}
          location={location}
          reservation_number={reservation_number}
          resourceTypeId={resourceTypeId}
          searchResults={searchResults}
          showSuccess={showSuccess}
          showAdult={showAdult}
          showMinor={showMinor}
        />
      )}
    </div>
  )
}
