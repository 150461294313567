import React from 'react'
import { useSelector } from 'react-redux'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { selectTimeZone } from '@/features/Locations/locationSlice'
import { momentToLocalDate } from '@/lib/CalendarTimes'
import { preventTyping } from '@/lib/Form'

export default function EditBookingDate({
    date,
    handleDateChange,
    isParent=false,
    isChild=false,
    isQuote=false,
    disabled=false
}) {

    const selectedTimeZone = useSelector(selectTimeZone)

    return (
        <div className="form-group">
            <label>Reservation Date</label>

            <DatePicker
                selected={momentToLocalDate(moment(date).tz(selectedTimeZone))}
                minDate={momentToLocalDate(moment().tz(selectedTimeZone))}
                validation={{ 'date': { required: true } }}
                onChange={handleDateChange}
                onKeyDown={preventTyping}
                disabled={disabled || isParent || isChild || isQuote}
            />
        </div>
    )
}
