import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UI } from '@/lib/Constants'
import { useConfirm } from '@/lib/useConfirmHook'
import { setOpen, selectOpen, submitAddon, setSelectedMembers, selectSelectedMembers } from './addonSlice'
import { selectBooking, selectMembers } from '../EditBooking/editBookingSlice'
import AddonDuration from './AddonDuration'
import AddonCustomerTypes from './AddonCustomerTypes'

export default function AddonModal() {

    const dispatch    = useDispatch()
    const open        = useSelector(selectOpen)
    const members     = useSelector(selectMembers)
    const booking     = useSelector(selectBooking)
    const selectedMembers = useSelector(selectSelectedMembers)
    const { confirm } = useConfirm()

    const participants = booking?.participants
    const memberNames = booking?.customers.reduce((acc, customer) => (
        { ...acc, [customer.member?.active_membership?.membership_id]: customer.name}
    ), {})

    const [duration, setDuration]                 = useState(null)
    const [typeCounts, setTypeCounts]             = useState(null)
    const [isDisabledButton, setIsDisabledButton] = useState(false)

    const toggleSelect = (id, checked) => {
        if (checked) {
            dispatch(setSelectedMembers([...selectedMembers, id]))
        } else {
            dispatch(setSelectedMembers(selectedMembers.filter(memberId => memberId !== id)))
        }
    }

    const handleKeyDown = (e) => {
        if (
            e.key === 'Escape'
            && !document.body.classList.contains(UI.classes.CONFIRMATION_OPEN)
            && !document.body.classList.contains(UI.classes.HELPJUICE_OPEN)
        ) {
            handleClose()
        }
    }

    const handleClose = () => {
        dispatch(setOpen(false))
    }

    const handleSubmit = async () => {
        if (!(await confirm('Are you sure you want to add additional time?'))) {
            return
        }

        // if package has group pricing, then the AddonCustomerTypes component
        // has changed typeCounts from null > 0 but we actually don't want to
        // send in 0 in this case, so send in null instead.
        dispatch(submitAddon(duration, typeCounts === null ? 0 : typeCounts))
        dispatch(setSelectedMembers([]))
    }

    /**
     * Handle the initial mount and
     * unmount of the component
     */
    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown, false)

        if (open) {
            document.body.classList.add(UI.classes.MULTIPLE_MODALS_OPEN)
        } else {
            setDuration(null)
            setTypeCounts(null)
            setIsDisabledButton(false)
        }

        // cleanup/reset on unmount
        return () => {
            document.body.classList.remove(UI.classes.MULTIPLE_MODALS_OPEN)
            document.removeEventListener('keydown', handleKeyDown, false)
        }
    }, [open])

    return !open ? null : (
        <div className="modal modal-backdrop modal--xs">
            <div className="modal-container booking-addon-time-modal">
                <div className="modal-header">
                    <div className="modal-title">Add Time To This Reservation</div>
                    <button className="modal-close" onClick={handleClose}>
                        <span>Close</span>
                        <i className="far fa-times" />
                    </button>
                </div>
                <div className="modal-body">
                    <div className="modal-body-main">
                        <div className="modal-body-main-section">
                            <div className="row">
                                <div className='col-12'>
                                    <AddonDuration duration={duration} setDuration={setDuration} />
                                </div>
                            </div>
                            {duration && (participants - members.length) > 0 &&
                                <div className="row">
                                    <div className="col-12">
                                        <AddonCustomerTypes
                                            duration={duration}
                                            typeCounts={typeCounts}
                                            setTypeCounts={setTypeCounts}
                                            handleError={() => setIsDisabledButton(true)}
                                        />
                                    </div>
                                </div>
                            }
                            {duration && members.length > 0 && <>
                                <h5 className='my-3'>Members</h5>
                                {members.map(member => (
                                    <div className="custom-control custom-checkbox mb-2" key={member.memberId}>
                                        <input
                                            id={`member-${member.memberId}`}
                                            className='custom-control-input mt-2'
                                            type='checkbox'
                                            checked={selectedMembers.includes(member.memberId)}
                                            onChange={e => toggleSelect(member.memberId, e.target.checked)}
                                        />
                                        <label htmlFor={`member-${member.memberId}`} className='custom-control-label'>
                                            {memberNames[member.memberId]} ({member.memberId})
                                        </label>
                                    </div>
                                ))}
                            </>}
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group mt-4 mb-0">
                                        <button
                                            children='Submit'
                                            className='btn btn-primary w-100'
                                            disabled={
                                                !duration
                                                || (
                                                    (!booking.group_max && !booking.group_min)
                                                    && (
                                                        (
                                                            typeCounts === null
                                                            || typeCounts === 0
                                                            || (typeof(typeCounts) === 'object' && Object.keys(typeCounts).filter(type => typeCounts[type] !== 0).length === 0)
                                                        )
                                                        && selectedMembers.length === 0
                                                    )
                                                   )
                                                || isDisabledButton
                                            }
                                            onClick={handleSubmit}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
