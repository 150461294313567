import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'

import { selectBookingId, selectBooking } from '../EditBooking/editBookingSlice'
import { selectPaymentProcessor, selectLocation } from '../Locations/locationSlice'

import {
    selectLoading,
    selectCreditToken,
    setCreditToken,
    setCardZip,
    submitPayment,
    selectAmountToPay,
    selectCreditMethod,
    setCreditMethod,
    selectCardZip
} from './bookingPaymentSlice'

import AdyenCreditCard from '../Adyen/AdyenCreditCard'
import CreditCard from '@/features/Bookings/components/Step6/CreditCard'
import PaymentCreditOnFile from './PaymentCreditOnFile'
import PaymentCreditTerminal from './PaymentCreditTerminal'

export default function PaymentCreditCard({ hardwareKey, environment }) {

    const dispatch         = useDispatch()
    const formMethods      = useFormContext()
    const loading          = useSelector(selectLoading)
    const creditToken      = useSelector(selectCreditToken)
    const bookingId        = useSelector(selectBookingId)
    const booking          = useSelector(selectBooking)
    const creditMethod     = useSelector(selectCreditMethod)
    const amountToPay      = useSelector(selectAmountToPay)
    const paymentProcessor = useSelector(selectPaymentProcessor)
    const location         = useSelector(selectLocation)
    const zipcode          = useSelector(selectCardZip)

    const [adyenCheckout, setAdyenCheckout] = useState(null)

    const container = document.getElementById('process-payment-button-container')

    const handleUpdateCreditMethod = (e) => {
        dispatch(setCreditMethod(e.target.value))
    }

    const handleSubmit = () => {
        dispatch(submitPayment(bookingId))
    }

    const handleAdyenSubmit = () => {
        adyenCheckout.submit()
    }

    const renderCreditMethodForm = () => {
        switch (creditMethod) {
            case 'terminal':
                return <PaymentCreditTerminal hardwareKey={hardwareKey} />

            case 'on_file':
                return <PaymentCreditOnFile />

            case 'manual':
                return paymentProcessor === 'adyen'
                    ? renderManualAdyenForm()
                    : renderManualPaysafeForm()

            default:
                return null
        }
    }

    const renderManualAdyenForm = () => <>
        <AdyenCreditCard
            locationId={location?.id}
            locationTimeZone={location?.time_zone}
            environment={environment}
            booking={booking}
            zipcode={zipcode}
            adyenCheckout={adyenCheckout}
            setAdyenCheckout={setAdyenCheckout}
            setZipCode={(e) => dispatch(setCardZip(e.target.value))}
            onSubmit={handleSubmit}
        />

        {
            container && createPortal((
                <button
                    children='Process Payment'
                    id="adyen-finish-button"
                    className="btn btn-primary w-100 py-3 px-5"
                    disabled={loading || (amountToPay <= 0)}
                    onClick={formMethods.handleSubmit(handleAdyenSubmit)}
                />
            ), container)
        }
    </>

    const renderManualPaysafeForm = () => <>
        <CreditCard
            creditToken={creditToken}
            setToken={(token) => dispatch(setCreditToken(token))}
            setZip={(e) => dispatch(setCardZip(e.target.value))}
            onNext={formMethods.handleSubmit(handleSubmit)}
        />

        {
            container && createPortal((
                <button
                    children='Process Payment'
                    id="finish-button"
                    type="button"
                    className="btn btn-primary w-100 py-3 px-5"
                    disabled={loading || (amountToPay <= 0)}
                    // onClick handler is registered in initializePaysafe() which is
                    // located in '@/features/Bookings/components/Step6/CreditCard'
                />
            ), container)
        }
    </>

    // select the first tab by default
    useEffect(() => {
        if (!creditMethod) {
            dispatch(setCreditMethod('terminal'))
        }
    }, [])

    // nulling this out allows Adyen JS to re-instantiate
    // whenever the user switches payment methods/tabs
    useEffect(() => {
        if (creditMethod !== 'manual' && !!adyenCheckout) {
            setAdyenCheckout(null)
        }
    }, [creditMethod])

    return (
        <div className="modal-body-main-section">
            <ul className="radio-tabs mb-4">
                <li className='w-100'>
                    <div className="radio-tab">
                        <input
                            id="tab-terminal"
                            type="radio"
                            name="terminal"
                            value="terminal"
                            className="radio-tab-input"
                            checked={/terminal/i.test(creditMethod)}
                            onChange={handleUpdateCreditMethod}
                        />
                        <label className="radio-tab-label w-100 text-center" htmlFor="tab-terminal">
                            Terminal
                        </label>
                    </div>
                </li>

                <li className='w-100'>
                    <div className="radio-tab">
                        <input
                            id="tab-on_file"
                            type="radio"
                            name="on_file"
                            value="on_file"
                            className="radio-tab-input"
                            checked={/on_file/i.test(creditMethod)}
                            onChange={handleUpdateCreditMethod}
                        />
                        <label className="radio-tab-label w-100 text-center" htmlFor="tab-on_file">
                            Card On File
                        </label>
                    </div>
                </li>

                <li className='w-100'>
                    <div className="radio-tab">
                        <input
                            id="tab-manual"
                            type="radio"
                            name="manual"
                            value="manual"
                            className="radio-tab-input"
                            checked={/manual/i.test(creditMethod)}
                            onChange={handleUpdateCreditMethod}
                        />
                        <label className="radio-tab-label w-100 text-center" htmlFor="tab-manual">
                            Manual Entry
                        </label>
                    </div>
                </li>
            </ul>

            { renderCreditMethodForm() }
        </div>
    )
}
