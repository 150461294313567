import React, { useState, useEffect, useRef } from 'react'
import Circle from '@uiw/react-color-circle'
import titleize from '@/lib/String'
import colors from 'config/color_picker_colors.yml'

export default function ColorPicker({
    className='col-12 mb-4 ml-1',
    value=null,
    onChange=() => {},
}) {
    const colorPicker               = useRef()
    const [color, setColor]         = useState(null)
    const [colorName, setColorName] = useState(null)

    const transparent = colors.find((c) => /transparent/i.test(c.title))

    const findNameForColor = (color) => (
        colors.find((c) => RegExp(c.color, 'i').test(color))?.title || null
    )

    const handleChange = (color) => {
        setColorName(findNameForColor(value))
        setColor(color)
        onChange(color === transparent.color ? null : color)
    }

    useEffect(() => {
        if (!!value) {
            setColorName(findNameForColor(value))
            setColor(value)
        } else {
            setColorName(transparent.title)
            setColor(transparent.color)
        }
    }, [value])

    useEffect(() => {
        colorPicker.current.querySelectorAll(`:scope > div`).forEach((el) => {
            const origTitle = el.getAttribute('title')
            el.classList.add(origTitle)
            el.setAttribute('title', titleize(origTitle, '-'))
        })
    }, [])

    return (
        <div className={`${className} color-picker`}>
            <Circle
                ref={colorPicker}
                className={colorName || ''}
                colors={colors}
                color={color}
                onChange={(color) => handleChange(color.hexa)}
            />
        </div>
    )
}
