import React from 'react'
import { Provider } from 'react-redux'
import ConfirmationDialog from '@/features/Notifications/ConfirmationDialog'
import NotificationList from '@/features/Notifications/NotificationList'
import ProductTransaction from '../../features/Products/ProductTransaction'
import productStore from '../../stores/productStore'
import { SELECTED_LOCATION } from '@/lib/Storage'

export default function ProductTransactionContainer({
    productsPath,
    purchasePath,
    locationId,
    locationTaxRate,
    locationTimeZone,
    locationPaymentProcessor,
    environment,
    hardwareKey,
    previewMode=false,
}) {
    window.localStorage.setItem(SELECTED_LOCATION, locationId)

    return (
        <Provider store={productStore}>
            {/* shared in the edit booking POS modal  */}
            <ProductTransaction
                productsPath={productsPath}
                purchasePath={purchasePath}
                locationId={locationId}
                locationTaxRate={locationTaxRate}
                locationTimeZone={locationTimeZone}
                locationPaymentProcessor={locationPaymentProcessor}
                environment={environment}
                hardwareKey={hardwareKey}
                previewMode={previewMode}
            />

            <ConfirmationDialog />
            <NotificationList />
        </Provider>
    )
}
