import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectTab, setPaymentType, setTab } from './bookingPaymentSlice'

const tabs = [
    ['credit',   'Credit/Debit', 'credit_card'],
    ['cash',     'Cash',         'cash'],
    ['check',    'Check',        'check'],
    ['giftCard', 'Gift Card',    'gift_card']
]

export default function BookingPaymentTabs() {

    const dispatch = useDispatch()
    const tab      = useSelector(selectTab)

    const update = item => {
        dispatch(setTab(item[0]))
        dispatch(setPaymentType(item[2]))
    }

    return (
        <ul className="modal-tabs" role="nav">
                {
                    tabs.map(item => (
                        <li key={item}>
                            <button
                                key={item}
                                type='button'
                                className={`modal-tab ${item[0] === tab ? 'selected' : ''}`}
                                onClick={() => update(item)}
                            >
                                {item[1]}
                            </button>
                        </li>
                    ))
                }
        </ul>
    )
}
