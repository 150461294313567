import React, { forwardRef } from 'react'
import { v4 as uuidv4 } from 'uuid'

export default forwardRef(function FontAwesomeIcon(props, ref) {

    const packs = props?.packs || [
        'fa-solid',
        'fa-light',
        'fa-regular',
        'fa-duotone',
        'fa-thin'
    ]

    return (
        <span
            ref={ref}
            key={uuidv4()}
            children={packs.map((ip) => (
                <i key={uuidv4()} className={`${ip} ${props?.icon}`.trim()} />
            ))}
            onClick={props?.onClick}
            {...props}
        />
    )
})
