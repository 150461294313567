import React from 'react'

export default function TabIcons({
    className='d-flex-inline justify-items-start align-items-center',
    check=null,
    tab=null,
    showName=true,
    showPrimary=true,
    showCreditCard=true,
    showMembership=false,
}) {
    return (
        <span className={className}>
            {
                showPrimary && tab.is_tab_of_primary_check_owner && (
                    <i className='fas fa-star text-yellow mr-2' />
                )
            }

            {
                showCreditCard && <>
                    {
                        !!tab.payment_profile && (
                            <i className="fa-regular fa-credit-card mr-2 text-white" />
                        )
                    }

                    {
                        !!tab.pre_authorized_transaction && (
                            <i className={`${tab.pre_authorized_transaction?.card?.icon ? tab.pre_authorized_transaction.card.icon : 'fa-regular fa-credit-card'} mr-2 text-white`} />
                        )
                    }
                </>
            }

            {
                /^(compact)$/i.test(showMembership) && tab.membership && (
                    <span className='badge badge-info mb-n1 mr-2' style={{ fontSize: '75%', padding: '4px 6px', position: 'relative', top: '-1px' }}>
                        <i className="fa-solid fa-id-badge"></i>
                    </span>
                )
            }

            {
                showName && (
                    <span className='tab-name mr-3'>
                        { tab.is_general && check?.booking?.name || tab.name }
                    </span>
                )
            }

            {
                /^(full)$/i.test(showMembership) && tab.membership && (
                    <span className='badge badge-info mr-3' style={{ fontSize: '50%', position: 'relative', top: '-2px' }}>
                        <i className="fa-solid fa-id-badge"></i>
                        <span className='ml-2'>{tab.membership.membership_id}</span>
                    </span>
                )
            }
        </span>
    )
}
