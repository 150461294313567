import React, { useEffect, useState, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { titleize } from 'inflected'
import moment from 'moment-timezone'

export default function SinceTimer({
    id=null,
    className='',
    label=null,
    time=null,
    timezone='UTC',
    format='YYYY-MM-DDTHH:mm:ss.SSS[Z]',
    refreshEveryNSeconds=60,
    debug=false,
}) {
    const uuid              = useRef(uuidv4())
    const [value, setValue] = useState(null)

    const calculate = () => {
        if (!time) { return }

        const updatedValue = moment.tz(time, format, timezone).toNow(true)

        if (updatedValue !== value) {
            setValue(updatedValue)

            if (debug && console) { console.log('Timer UPDATED', uuid.current) }
        }
    }

    const startTimer = () => {
        if (!window[uuid.current]) {
            calculate()

            window[uuid.current] = window.setInterval(() => {
                calculate()
            }, refreshEveryNSeconds * 1000)

            if (debug && console) { console.log('Timer STARTED', uuid.current) }
        }
    }

    const stopTimer = () => {
        window.clearInterval(window[uuid.current])
        delete window[uuid.current]
        setValue(null)

        if (debug && console) { console.log('Timer STOPPED', uuid.current) }
    }

    useEffect(() => {
        startTimer()

        return () => {
            stopTimer()
        }
    }, [])

    return value && (
        <span id={id} className={`since-timer ${className}`.trim()}>
            {
                !!label && (
                    <span className='pr-3 label'>{ label }</span>
                )
            }
            <span className='time'>
                { titleize(value) }
            </span>
        </span>
    )
}
