import React, { useMemo, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { selectTimeZone } from '@/features/Locations/locationSlice'
import { selectResources } from '@/features/Resources/resourcesSlice'
import { selectBooking, selectSizeHeight, setSelectedBookingTopOffset } from '@/features/Calendar/calendarSlice'
import { setClosed } from '@/features/CalendarSearch/calendarSearchSlice'
import { setBooking, flagBookingDrawerOpen } from '@/features/EditBooking/editBookingSlice'
import { setParentBooking, setChildBookings, setParentBookingModalOpen } from '@/features/ParentBooking/parentBookingSlice'
import { setStep, setOpen } from '@/features/Schedule/scheduleSlice'
import { isBookingCancelled, isBookingComplete, isBookingQuote, textStatusForBooking, textStatusForQuote } from '@/lib/Booking'
import { parseResourceRow } from '@/lib/Resources'
import { UI } from '@/lib/Constants'

export default function CalendarSearchResult({ booking, childBookings, resources, pakage, location }) {

    const dispatch         = useDispatch()
    const selectedTimeZone = useSelector(selectTimeZone)
    const sizeHeight       = useSelector(selectSizeHeight)
    const allResources     = useSelector(selectResources)

    /**
     * The row number of our booking's resource
     *
     * @TODO we could technically do the same thing we are doing with time columns and do this once
     * when the bookings are fetched from the server, however to do that we have to pass the data to
     * the thunk in order to keep passing around which could get a little messy. In theory there should
     * only be up to a dozen or so resources for the largest locations, so parsing over these should
     * be almost instant, whereas time slots have several hundred once they start scrolling.
     */
    const resourceRow = useMemo(() => parseResourceRow(resources, allResources), [resources, allResources])

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            dispatch(setClosed())
        }
    }

    // load the date of the selected search result
    // booking and scroll the calendar to view it
    const handleClick = () => {
        if (booking.location_id !== location.id) {
            window.location.href = `/companies/${booking.company_id}/locations/${booking.location_id}/calendar/${booking.reservation_number}`
        } else {
            dispatch(setClosed())
            dispatch(selectBooking(booking))
            dispatch(setSelectedBookingTopOffset((resourceRow * sizeHeight) - sizeHeight))
            booking.is_parent ? handleParentBookingClick() : handleBookingClick()
        }
    }

    const handleBookingClick = () => {
        axios.get(`/bookings/${booking.id}/information`)
        .then(({ data }) => {
            dispatch(setBooking({ booking: data.booking, resources: data.resources }))
            dispatch(flagBookingDrawerOpen(booking.id, true))
            dispatch(setStep('3'))
            dispatch(setOpen(true))
        })
    }

    const handleParentBookingClick = () => {
        axios.get(`/bookings/${booking.id}/information`)
        .then(({ data }) => {
            dispatch(setParentBooking({ booking: data.booking, resources: data.resources }))
            dispatch(setChildBookings({ childBookings: data.child_bookings }))
            dispatch(flagBookingDrawerOpen(booking.id, true))
            dispatch(setParentBookingModalOpen(true))
        })
    }

    const renderStatus = () => {
        let status = isBookingQuote(booking) ? textStatusForQuote(booking) : textStatusForBooking(booking)
        let statusClass = 'right '

        if (isBookingCancelled(booking) || /rejected/i.test(status)) {
            statusClass += 'cancelled'
        }

        return (
            <p className={statusClass}>
                <label>{isBookingQuote(booking) ? 'Quote' : pakage.is_special_event ? 'Special Event' : 'Reservation'} Status:</label>
                {status}
            </p>
        )
    }

    const renderPartySize = () => {
        const participantCountOfChildren = childBookings?.reduce((sum, cb) => sum + parseInt((cb.group_max || cb.participants), 10), 0)

        return pakage.is_special_event && booking.is_parent
            ? `${participantCountOfChildren} - ${pakage.special_event_capacity || '??'}`
            : booking.participants
    }

    /**
     * Handle the initial mount and
     * unmount of the component
     */
    useEffect(() => {
        document.body.classList.add(UI.classes.MULTIPLE_MODALS_OPEN)
        document.addEventListener('keydown', handleKeyDown, false)

        // cleanup/reset on unmount
        return () => {
            document.body.classList.remove(UI.classes.MULTIPLE_MODALS_OPEN)
            document.removeEventListener('keydown', handleKeyDown, false)
        }
    }, [])

    return (
        <div className="result-card" onClick={handleClick}>
            <div className="result-card-header">
                {
                    !(isBookingComplete(booking) && pakage?.is_a_league) && (
                        <div className="party-size">{renderPartySize()}</div>
                    )
                }
                <div className="party-name">{booking.name}</div>
                <div className="event-type">{pakage.name}</div>
            </div>
            <div className="result-card-body flex-column">
                <div className="font-weight-bold">
                    Location:&nbsp;&nbsp;&nbsp;{booking.location_name}
                </div>
                <div className="font-weight mb-2">
                    Resource Type:&nbsp;&nbsp;&nbsp;{booking?.resource_type?.title}
                </div>
                <div className="d-flex justify-content-between">
                <p>
                    <label>Date:</label>
                    {moment.tz(booking.start_time, selectedTimeZone).format('M/D/YYYY h:mma z')}
                </p>
                <p className="center">
                    <label>{pakage.is_special_event ? 'Special Event' : 'Reservation'}:</label>
                    {booking.reservation_number}
                </p>
                {renderStatus()}
                </div>
            </div>
        </div>
    )
}
