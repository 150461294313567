import React from 'react'
import Input from '../Form/Input'
import FormRow from '../Form/FormRow'
import Help from '@/features/Help/Help'

export default function ReservationsFields({ form, errors, isSpecialEvent, handleUpdate }) {

    return (
        <div className="reservations-fields">
            <h3 className="d-flex justify-content-between align-items-center">
                <span className='d-flex'>
                    <span>Reservation Lead Times</span>
                    <Help articleId='154000158965-reservation-lead-times' />
                </span>
            </h3>

            {
              /*
               * The customers_share_lanes form fields are hidden because the "fill the bus model"
               * does not exist yet but the booking code still needs them to be set in particular ways.
               *
               * However, for UI/UX reasons, it's confusing at the moment, so it's better to hide it.
               * If the "fill the bus model" is ever built, then we can re-visit this UI/UX later
               */
            }
            <FormRow className='d-none'>
                <div className="col-4">
                    <div className="form-group">
                        <div className="custom-control custom-radio custom-control-inline">
                            <input
                                id='customers_share_lanes_false'
                                className='custom-control-input'
                                name='customers_share_lanes'
                                type='radio'
                                value='false'
                                disabled={isSpecialEvent}
                                checked={!form.customers_share_lanes}
                                onChange={handleUpdate}
                            />

                            <label className="custom-control-label" htmlFor='customers_share_lanes_false'>
                                Private Reservation
                            </label>
                        </div>
                    </div>
                </div>
                <div className="col-8">
                    <div className="form-group">
                        <div className="custom-control custom-radio custom-control-inline">
                            <input
                                id='customers_share_lanes_true'
                                className='custom-control-input'
                                name='customers_share_lanes'
                                type='radio'
                                value='true'
                                disabled={!isSpecialEvent}
                                checked={form.customers_share_lanes}
                                onChange={handleUpdate}
                            />

                            <label className="custom-control-label" htmlFor='customers_share_lanes_true'>
                                Grouped Reservation (Customers Share Lanes)
                            </label>
                        </div>
                    </div>
                </div>
            </FormRow>

            <FormRow>
                <Input
                    name='reservation_timing_min_limit'
                    label='Minimum hours into the future to accept reservations'
                    placeholder='Optional'
                    append={true}
                    type='number'
                    cols='6'
                    min='0'
                    step='1'
                    value={form.reservation_timing_min_limit}
                    errors={errors}
                    labelTooltip={<span>When set, customers will only be able to book this amount
                    of hours into the future. For example, if set to 2 "hours" and the current
                    time is 12pm, then the earliest available time will be 2pm.</span>}
                    handleChange={handleUpdate}
                >
                    <div className="input-group-append">
                        <span className="input-group-text">
                            {form.reservation_timing_min_limit == 1 ? 'Hour' : 'Hours'}
                        </span>
                    </div>
                </Input>

                <Input
                    name='reservation_timing_max_limit'
                    label='Maximum days into the future to accept reservations'
                    placeholder='Optional'
                    append={true}
                    type='number'
                    cols='6'
                    min='0'
                    step='1'
                    labelTooltip={<span>The maximum number of days into the future to accept reservations.
                    For example, if today is the 1st and this is set to "15 days", a customer would
                    not be able to book on or after the 16th</span>}
                    value={form.reservation_timing_max_limit}
                    errors={errors}
                    handleChange={handleUpdate}
                >
                    <div className="input-group-append">
                        <span className="input-group-text">
                            {form.reservation_timing_max_limit == 1 ? 'Day' : 'Days'}
                        </span>
                    </div>
                </Input>
            </FormRow>
        </div>
    )
}
