import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { selectTimeZone } from '@/features/Locations/locationSlice'
import { momentToLocalDate, localDateToMoment } from '@/lib/CalendarTimes'
import { debug } from '@/lib/Debug'

import {
  selectDate,
  setDate,
  setViewedDate,
  setSelectedBookingTopOffset,
} from '@/features/Calendar/calendarSlice'

export default function DateSelect() {

    const dispatch = useDispatch()
    const date     = useSelector(selectDate)
    const timezone = useSelector(selectTimeZone)
    const format   = 'YYYY-MM-DD'

    const handleDateChange = (value) => {
        const newDate = localDateToMoment(value, timezone).format(format)
        if (debug && console) { console.log(`selected date: ${newDate}`) }
        dispatch(setDate(newDate))
        dispatch(setViewedDate(newDate))
        dispatch(setSelectedBookingTopOffset(null))
    }

    return (
        <div className="date-select">
            <label>
                <div className="date-select-trigger cursor-pointer">
                    <span><i className="far fa-calendar-alt mr-1" /> Jump To Day</span>
                </div>
                <DatePicker
                    portalId="root-portal"
                    type="date"
                    className="form-control select-from-calendar"
                    popperClassName="select-calendar-date"
                    selected={momentToLocalDate(moment.tz(date, timezone))}
                    todayButton={"Today"}
                    showPopperArrow={false}
                    shouldCloseOnSelect={true}
                    onChange={handleDateChange}
                />
            </label>
        </div>
    )
}
