import React from 'react'
import { useSelector } from 'react-redux'
import { selectResources } from '../Resources/resourcesSlice'
import { Lane } from './Lane'

export function _LanesContainer() {
    const resources = useSelector(selectResources)

    return resources.map(resource => <Lane key={resource.id} resource={resource} />)
}

export const LanesContainer = React.memo(_LanesContainer)
