import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    clear,
    selectCode,
    selectError,
    setCode,
    validateManagerCode,
} from '@/features/ManagerCodes/managerCodeSlice'

import SecurePassword from '@/components/Form/SecurePassword'

export default function AuthorizeManagerOverride({ locationId }) {

    const dispatch = useDispatch()
    const code     = useSelector(selectCode)
    const error    = useSelector(selectError)

    const validateCode = () => {
        dispatch(validateManagerCode(locationId, code))
    }

    const handleChange = (value) => {
        dispatch(setCode(value))
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            validateCode()
        }
    }

    useEffect(() => {
        if (!!error) {
            dispatch(setCode(''))
            window.setTimeout(() => dispatch(clear()), 3000)
        }
    }, [error])

    return (
        <form
            id='authorize-manager-override'
            className='input-group'
            onSubmit={(e) => {
                e.preventDefault()
                validateCode()
            }}
        >
            <SecurePassword
                placeholder='Manager Code'
                value={code || ''}
                error={error}
                autoFocus={true}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
            />

            <div className="input-group-append">
                <button
                    type='submit'
                    children={!!error ? 'Invalid' : 'Verify'}
                    className={`btn ${!!error ? 'btn-danger text-white' : 'btn-primary'}`}
                />
            </div>
        </form>
    )
}
