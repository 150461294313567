import React from "react";
import FormRow from "../Form/FormRow";
import {useFormContext} from "react-hook-form";

export default function CustomerWaiversGender({
  additional_minor,
  name,
  adult_or_minor,
  errors,
  index,
  waiver,
}) {
  const formMethods = useFormContext()
  const fieldName   = additional_minor ? `${name}-${index}` : name
  const genderField = formMethods.register(`${fieldName}`, { required: waiver.gender ? 'This field is required' : false })

  return (
    <div className="waiver-item">
      {adult_or_minor}'S GENDER

      {additional_minor ? (
        <FormRow className="mt-3">
          <div className="col-auto">
            <div className="form-group">
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  id={`male_check_box-${index}`}
                  type='radio'
                  name={fieldName}
                  value="male"
                  className={`custom-control-input ${errors[fieldName] ? 'is-invalid' : ''}`}
                  {...genderField}
                />

                <label className="custom-control-label" htmlFor={`male_check_box-${index}`}>Male</label>
              </div>
            </div>
          </div>

          <div className="col-auto">
            <div className="form-group">
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  id={`female_check_box-${index}`}
                  type='radio'
                  name={fieldName}
                  value="female"
                  className={`custom-control-input ${errors[fieldName] ? 'is-invalid' : ''}`}
                  {...genderField}
                />

                <label className="custom-control-label" htmlFor={`female_check_box-${index}`}>Female</label>
              </div>
            </div>
          </div>

          <div className="col-auto">
            <div className="form-group">
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  id={`other_check_box-${index}`}
                  type='radio'
                  name={fieldName}
                  value="other"
                  className={`custom-control-input ${errors[fieldName] ? 'is-invalid' : ''}`}
                  {...genderField}
                />

                <label className="custom-control-label" htmlFor={`other_check_box-${index}`}>Other</label>
              </div>
            </div>
          </div>

          <div className="col-auto">
            <div className="form-group">
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  id={`prefer_not_to_say_check_box-${index}`}
                  type='radio'
                  name={fieldName}
                  value="prefer_not_to_say"
                  className={`custom-control-input ${errors[fieldName] ? 'is-invalid' : ''}`}
                  {...genderField}
                />

                <label className="custom-control-label" htmlFor={`prefer_not_to_say_check_box-${index}`}>Prefer Not To Say</label>
              </div>
            </div>
          </div>

          {
              errors[fieldName] && (
                  <div className="invalid-feedback d-block mx-2">
                      { errors[fieldName].join(', ') }
                  </div>
              )
          }
        </FormRow>
      ) : (
        <FormRow className="mt-3">
          <div className="col-auto">
            <div className="form-group">
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  id='male_check_box'
                  type='radio'
                  name={fieldName}
                  value="male"
                  className={`custom-control-input ${errors[fieldName] ? 'is-invalid' : ''}`}
                  {...genderField}
                />

                <label className="custom-control-label" htmlFor='male_check_box'>Male</label>
              </div>
            </div>
          </div>

          <div className="col-auto">
            <div className="form-group">
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  id='female_check_box'
                  type='radio'
                  name={fieldName}
                  value="female"
                  className={`custom-control-input ${errors[fieldName] ? 'is-invalid' : ''}`}
                  {...genderField}
                />

                <label className="custom-control-label" htmlFor='female_check_box'>Female</label>
              </div>
            </div>
          </div>

          <div className="col-auto">
            <div className="form-group">
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  id='other_check_box'
                  type='radio'
                  name={fieldName}
                  value="other"
                  className={`custom-control-input ${errors[fieldName] ? 'is-invalid' : ''}`}
                  {...genderField}
                />

                <label className="custom-control-label" htmlFor='other_check_box'>Other</label>
              </div>
            </div>
          </div>

          <div className="col-auto">
            <div className="form-group">
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  id='prefer_not_to_say_check_box'
                  type='radio'
                  name={fieldName}
                  value="prefer_not_to_say"
                  className={`custom-control-input ${errors[fieldName] ? 'is-invalid' : ''}`}
                  {...genderField}
                />

                <label className="custom-control-label" htmlFor='prefer_not_to_say_check_box'>Prefer Not To Say</label>
              </div>
            </div>
          </div>

          {
              errors[fieldName] && (
                  <div className="invalid-feedback d-block mx-2">
                      { errors[fieldName].join(', ') }
                  </div>
              )
          }
        </FormRow>
      )}
    </div>
  )
}
