import React from 'react'
import Input from '../Form/Input'
import FormRow from '../Form/FormRow'
import Checkbox from '@/components/Form/Checkbox'
import ExpectedPricing from '@/components/ExpectedPricing'

export default function ProductsForm({
    form,
    locationTaxRate=0,
    errors,
    handleUpdate,
    handleCheckboxUpdate
}) {

    return (
        <div className="row">
            <div className="col-6">
                <FormRow className="mt-4">
                    <Input
                        value={form.name}
                        errors={errors}
                        validation={{ 'name': { required: true } }}
                        handleChange={handleUpdate}
                    />
                </FormRow>

                <FormRow>
                    <Input
                        cols='8'
                        type='number'
                        min={0}
                        prepend={true}
                        value={form.price}
                        errors={errors}
                        validation={{ 'price': { required: true } }}
                        handleChange={handleUpdate}
                    >
                        <div className="input-group-prepend">
                            <span className="input-group-text">$</span>
                        </div>
                    </Input>

                    <Input
                        cols='4'
                        label='Sales Tax'
                        labelTooltip={form.use_product_tax_rate ? '' : "This is defaulting to the location's tax rate"}
                        type='number'
                        min={0}
                        append={true}
                        value={form.use_product_tax_rate ? form.tax_rate : locationTaxRate}
                        errors={errors}
                        req={form.use_product_tax_rate}
                        validation={{ 'tax_rate': {
                          validate: (v) => form.use_product_tax_rate ? Boolean(v) : true
                        }}}
                        disabled={!form.use_product_tax_rate}
                        handleChange={handleUpdate}
                    >
                        <div className="input-group-append">
                            <span className="input-group-text">%</span>
                        </div>
                    </Input>
                </FormRow>

                <FormRow className="mt-3">
                    <Checkbox
                        label='Include Sales Tax In Price?'
                        name='price_includes_sales_tax'
                        value={form.price_includes_sales_tax}
                        handleChange={handleCheckboxUpdate}
                    />
                </FormRow>

                <FormRow>
                    <Checkbox
                        label='Custom Sales Tax %?'
                        name='use_product_tax_rate'
                        value={form.use_product_tax_rate}
                        handleChange={handleCheckboxUpdate}
                    />
                </FormRow>
            </div>

            <ExpectedPricing
                className='col-6 pl-5 pt-5'
                price={form.price}
                taxRate={form.use_product_tax_rate ? form.tax_rate : locationTaxRate}
                priceIncludesSalesTax={form.price_includes_sales_tax}
            />
        </div>
    )
}
