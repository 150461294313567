import React, { useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { selectCustomerTypes } from '../../CustomerTypes/customerTypeSlice'
import { setMember, selectMembers } from '../scheduleSlice'
import { selectBooking, selectMembers as selectExistingMembers } from '@/features/EditBooking/editBookingSlice'
import { selectPackage } from '../../Packages/packageSlice'
import { selectLocation } from '../../Locations/locationSlice'
import MemberInput from './MemberInput'
import NumberIncrementer from '../../../components/Form/NumberIncrementer'
import { checkBookingParticipantChangeWhileSplitWarning } from '@/features/AdvancedPointOfSale/lib/Checks'
import { useConfirm } from '@/lib/useConfirmHook'

export function _BookingCustomerTypes({
    mode,
    errors,
    customerTypeCounts,
    isBookingQuote=false,
    participants,
    disabled=false,
    onChange,
    onMemberChange,
    onMemberRemove,
}) {
    const dispatch        = useDispatch()
    const { confirm }     = useConfirm()
    const { register }    = useFormContext()
    const location        = useSelector(selectLocation)
    const pkg             = useSelector(selectPackage)
    const booking         = useSelector(selectBooking)
    const customerTypes   = useSelector(selectCustomerTypes)
    const members         = useSelector(selectMembers)
    const existingMembers = useSelector(selectExistingMembers)

    const [typesSelected, setTypesSelected]         = useState(0)
    const [numberOfRemaining, setNumberOfRemaining] = useState(0)

    const memberCount = (mode === 'newBooking' ? members.length : existingMembers?.filter((m) => !!m?.memberId)?.length) || 0

    const confirmation = () => confirm(checkBookingParticipantChangeWhileSplitWarning, {
        header_text: 'Warning!',
        dangerous: true,
        size: 'md',
        confirm_text: 'OK',
        cancel_text: 'CANCEL'
    })

    const handleChange = async (id, value, _non_participant) => {
        const execute = (id, value, _non_participant) => {
            const updatedTypeCounts = {
                ...customerTypeCounts,
                [id]: value
            }

            setTypesSelected(skipNonParticipants(updatedTypeCounts))

            // fire the callback with the new customer type counts value... this can
            // be dispatched to whatever  slice we're currently dealing with in
            // the parent component.
            onChange(updatedTypeCounts)
        }

        if (booking?.check?.has_booking_been_split) {
            if (await confirmation()) {
                execute(id, value, _non_participant)
            }
        } else {
            execute(id, value, _non_participant)
        }
    }

    const handleMemberQuantityChange = async (e) => {
        if (booking?.check?.has_booking_been_split) {
            if (await confirmation()) {
                onMemberChange(e)
            }
        } else {
            onMemberChange(e)
        }
    }

    const handleMemberInputChange = async (index, value) => {
        if (booking?.check?.has_booking_been_split) {
            if (await confirmation()) {
                dispatch(setMember({ index, value }))
            }
        } else {
            dispatch(setMember({ index, value }))
        }
    }

    const handleMemberInputRemove = async (index) => {
        if (booking?.check?.has_booking_been_split) {
            if (await confirmation()) {
                onMemberRemove(index)
            }
        } else {
            onMemberRemove(index)
        }
    }


    const skipNonParticipants = (typeCounts) => {
        if (!Array.isArray(customerTypes)) { return 0 }
        const participantIds = customerTypes.filter(c => !c.non_participant).map(c => c.id)
        const count = Object.keys(typeCounts).map(k => Number.parseInt(k)).filter(k => participantIds.includes(k)).map(k => typeCounts[k])

        // Protect against empty array reducing
        return count.length ? count.reduce((a, b) => (a + b)) : 0
    }

    const calcNumRemaining = () => {
        setTypesSelected(skipNonParticipants(customerTypeCounts))

        return Number(participants)
            ? Number(participants) - Number(typesSelected) - Number(memberCount)
            : 0
    }

    /**
     * Update the calculated remaining count
     */
    useEffect(() => {
        setNumberOfRemaining(calcNumRemaining())
    }, [participants, typesSelected, customerTypes, members, existingMembers])

    /**
     * Set initial values
     */
    useEffect(() => {
        if (Object.values(customerTypeCounts).length > 0) {
            setTypesSelected(Object.values(customerTypeCounts).reduce((a, b) => (a + b)))
        }
        setNumberOfRemaining(calcNumRemaining())
        document.getElementById('booking-customer-types')?.scrollIntoView()
    }, [])

    const renderMemberIncrementer = () => {
        if (!location.memberships_enabled || !pkg?.allow_member_benefits || isBookingQuote) { return }

        return (
            <div className="col-12 col-md-4">
                <div className="form-group">
                    <label className='text-primary'>Members</label>
                    <div className="incrementer-container member-incrementer">
                        <div className="xbm-incrementer-container">
                            <NumberIncrementer
                                disabled={disabled || numberOfRemaining <= 0}
                                disabledDecrement={disabled}
                                min={0}
                                preventTyping={true}
                                value={memberCount}
                                incrementAmount={1}
                                handleChange={handleMemberQuantityChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (!Array.isArray(customerTypes) || customerTypes.length === 0) ? null : (
        <div id='booking-customer-types' className="booking-customer-types">
            <div className="section-header-actions">
                <h3>Customer Types</h3>
                <div className="section-counter">
                    {
                        numberOfRemaining === 0 ? (
                            <span className='badge badge-pill badge-success py-1'>Fully Allocated</span>
                        ) : <>
                            <label className='mb-0'>
                                { numberOfRemaining > 0 ? 'Necessary Remaining' : 'Too Many Allocated' }
                            </label>
                            <span className={`badge badge-pill py-1 badge-${numberOfRemaining > 0 ? 'warning' : 'danger'}`}>
                                { numberOfRemaining }
                            </span>
                        </>
                    }
                </div>
            </div>

            <input type="hidden" {...register('number_of_remaining', { validate: (_v) => numberOfRemaining === 0 })} />

            {
                !pkg?.allow_members_only && (
                    <div className="form-row">
                        {   /* only show customer type selections if NOT uniform pricing */
                            pkg?.pricing_type !== 'uniform_pricing' && customerTypes.map(type => (
                                <div key={`customer_type_${type.name}`} className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label>{type.name}</label>
                                        <div className="incrementer-container">
                                            <NumberIncrementer
                                                value={customerTypeCounts?.[type.id] || 0}
                                                incrementAmount={1}
                                                min={0}
                                                preventTyping={true}
                                                disabled={disabled || (type.non_participant === false ? numberOfRemaining <= 0 : false)}
                                                disabledDecrement={disabled}
                                                handleChange={(value) => handleChange(type.id, value, type.non_participant)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))
                        }

                        { renderMemberIncrementer() }

                        { errors['number_of_remaining'] &&
                            <div className="invalid-feedback d-block ml-2 mb-3">
                                Please specify the proper number of customers.
                            </div>
                        }
                    </div>
                )
            }

            { mode === 'newBooking' && memberCount > 0 &&
                [...Array(memberCount).keys()].map(index => (
                    <MemberInput
                        key={index}
                        index={index}
                        pkg={pkg}
                        member={members[index]}
                        errors={errors}
                        onChange={(e) => handleMemberInputChange(index, e.target.value)}
                        onRemove={() => handleMemberInputRemove(index)}
                    />
                ))
            }
        </div>
    )
}

export const BookingCustomerTypes = React.memo(_BookingCustomerTypes)
