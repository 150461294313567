import React from 'react'

export default function MenuToggleButton({
    className='btn-transparent',
    isOpen=false,
    onClick=() => {},
    children
}) {

    return (
        <button
            id='menu-toggle'
            type='button'
            className={`btn ${className}`}
            onClick={onClick}
        >

            <div className={`bars ${isOpen ? 'open' : 'closed forced-closed'}`}>
                <span />
                <span />
                <span />
            </div>

            {children}
        </button>
    )
}
