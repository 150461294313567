import React, { useState, useEffect } from 'react'
import { Provider } from 'react-redux'
import { useForm, FormProvider } from 'react-hook-form'
import axios from 'axios'
import notificationStore from '@/stores/notificationStore'
import Help from '@/features/Help/Help'
import Icon from '@/components/FontAwesomeIcon'
import Drawer from '@/components/Drawer/Drawer'
import RecordDrawerForm from '@/components/Drawer/RecordDrawerForm'
import { errorsFor } from '@/components/Form/ErrorsHelper'
import titleize from '@/lib/String'
import { accessToken } from '@/lib/Csrf'
import { ADYEN_TERMINAL_SALE_ID, ADYEN_TERMINAL_SERVICE_ID } from '@/lib/Storage'

// Necessary to force Axios requests to send "as XHR"
axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'

/**
 * The RecordDrawer is responsible for setting the initial form, alert, error state, handling saving the form, and rendering
 * the parent drawer HTML and the nested form itself.
 */
export default function RecordDrawer(props) {
    // refreshing of the page happens in the Alert
    // component that is loaded in Drawer/Drawer.jsx
    let shouldRefresh = null

    /**
     * ---------------------------------
     * beforeSave form lifecycle hook
     * ---------------------------------
     * Due to how useState works, it works using the expectation that
     * an object with a property of "fn" exists containing a callback function
     *
     * EXAMPLE: { fn: callbackFunctionReference }
     */
    const [beforeSave, setBeforeSave] = useState(null)

    const [isFullScreen, setFullScreen] = useState(false)

    // the state of the form that is being modified -- default to the record
    const [form, setForm] = useState({ ...props.record })

    // initialize form validation
    const formMethods = useForm({ mode: 'all' })

    // drawer alert (success & danger, etc.)
    const [alert, setAlert]  = useState(null)

    const [disabledSaveButton, setDisableSaveButton] = useState(null)

    // form errors
    const [errors, setErrors] = useState({})

    // ref for the drawer, used for scrolling
    const [drawerRef] = useState(React.createRef())

    // is the form submitting/loading?
    const [loading, setLoading] = useState(false)

    // has the record been saved successfully?
    const [saved, setSaved] = useState(false)

    const handleDisableSaveButton = (bool) => {
        setDisableSaveButton(bool)
    }

    /**
     * Handle what happens when a form element/input is updated
     */
    const handleUpdate = (e) => {
        const name = e.target.name.split(':')[0]
        setForm({ ...form, [name]: e.target.value })
    }

    /**
     * Handle what happens when a checkbox is toggled
     */
    const handleCheckboxUpdate = (e) => {
        const name = e.target.name.split(':')[0]
        setForm({ ...form, [name]: !form[name] })
    }

    /**
     * Handle a manual update of the form state, essentially manually binding a value to a form key
     *
     * @param {*} name the name of the attribute (key) being changed in the form
     * @param {*} value the new value of that key in the form
     */
    const handleManualUpdate = (name, value) => {
        setForm({ ...form, [name]: value })
    }

    /**
     * A callback to update the entire form object for updating multiple entries at a given time...
     * this is so we don't have to hook up multiple useEffects in the site in order to update
     * multiple form attributes at a given time.
     *
     * @TODO in the package form there are places where we're using useEffect hooks instead of using
     * this handleFormUpdate. Look at how easy it would be to switch those over to just using this...
     *
     * @param {*} newForm the new form object to set the state to
     */
    const handleFormUpdate = (newForm) => {
        setForm(newForm)
    }

    const handleSave = (e) => {
        shouldRefresh = false
        formMethods.handleSubmit(handleBeforeSave, onError)(e)
    }

    const handleSaveAndClose = (e) => {
        shouldRefresh = true
        formMethods.handleSubmit(handleBeforeSave, onError)(e)
    }

    /**
     * Handle what happens before the form is saved
     *
     * Due to how useState works, it works using the expectation that
     * an object with a property of "fn" exists containing a callback function
     *
     * EXAMPLE: { fn: callbackFunctionReference }
     */
    const handleBeforeSave = async (data, e) => {
      if (
        beforeSave !== null
        && ('fn' in beforeSave)
        && typeof beforeSave.fn === 'function'
      ) {
            if (await beforeSave.fn(form) === true) {
                performSave(data, e)
                setBeforeSave(null)
            }
        } else {
            performSave(data, e)
        }
    }

    /**
     * Handle what happens when the form is saved
     */
    const performSave = (_data, _e) => {
        // don't try to submit again if we're already loading/submitting or if the record has been saved
        // and we're waiting to refresh the page.
        if (loading || saved) {
          return
        }

        // determine which axios method to use based on if it's a new object or not (post/patch)
        const method = props.new ? axios.post : axios.patch

        // set the loading state
        setLoading(true)

        // show a message that the form is submitting... this form is overwritten when a response comes back
        setAlert({ type: 'warning', message: 'Submitting Form...' })

        // try to scroll to the top of the drawer once the form is being submitted so the user can see
        // what is happening i.e. submitting, alerts, etc.
        try {
            drawerRef.current.scrollTo(0, 0)
        } catch (e) {
            if (console) { console.warn('Could not scroll...') }
        }

        let sessionData = {}

        try {
            const sessionDataPairs = [
                `sale_id:${ADYEN_TERMINAL_SALE_ID}`,
                `service_id:${ADYEN_TERMINAL_SERVICE_ID}`,
            ]

            sessionDataPairs.map((kv) => {
                const key   = kv.split(':')[0]
                const value = kv.split(':')[1]

                if (!!window.sessionStorage.getItem(value)) {
                    sessionData[key] = window.sessionStorage.getItem(value)
                }
            })
        } catch(e) {
            if (console) { console.error(e) }
        }

        method(props.recordUrl, {
            [props.recordType]: {
                ...form,
                ...sessionData,
            },
            authenticity_token: accessToken
        }).then(({ data }) => {
            if (data.success) {
                if (shouldRefresh === true) { setSaved(true) }
                setAlert({ type: 'success', message: data.message, refresh: shouldRefresh })
                setErrors({})
            } else {
                setAlert({ type: 'danger', message: data.message })
                setErrors(data.errors)
            }
        }).catch(e => {
            setAlert({ type: 'danger', message: e.message })
        }).finally(() => {
            // no matter what happens we want to unset the loading state
            setLoading(false)

            // automatically clear the alert if the page didn't refresh
            window.setTimeout(() => setAlert(null), 2000)

            window.sessionStorage.removeItem(ADYEN_TERMINAL_SALE_ID)
            window.sessionStorage.removeItem(ADYEN_TERMINAL_SERVICE_ID)
        })
    }

    /**
     * Loop through form errors and build up our own errors
     * object that gets passed down to all of the components
     */
    const onError = (formErrors, e) => {
      setErrors(errorsFor(formErrors))
    }

    /**
     * Clear our form errors object whenever the
     * React Hook Form state becomes valid
     */
    useEffect(() => {
        if (formMethods.formState.isValid && Object.values(errors || {}).length > 0) {
            setErrors({})
        }
    }, [formMethods.formState])

    return (
        <Provider store={notificationStore}>
          <FormProvider {...formMethods}>
              <Drawer
                  record={props.record}
                  form={form}
                  recordUrl={props.recordUrl}
                  open={props.open}
                  close={props.close}
                  confirmOnClose={!!props.confirmOnClose}
                  confirmOnSaveMessage={props.confirmOnSaveMessage}
                  disabledSaveButton={disabledSaveButton}
                  save={handleSave}
                  saveAndClose={handleSaveAndClose}
                  isFullScreen={isFullScreen}
                  alert={alert}
                  drawerRef={drawerRef}
                  showSave={props.showSave}
                  showSaveAndClose={props.showSaveAndClose}
                  showSaveAndNew={props.showSaveAndNew}
                  shouldFetchRecordOnLoad={!props.new && !props?.record && !!props?.recordUrl && Object.values(form).length === 0}
                  handleFormUpdate={handleFormUpdate}
              >
                  <div className="drawer-header-container">
                      <div className="drawer-header">
                          <h2 className="d-flex align-content-center justify-content-between w-100">
                              <span>{props.new ? 'New' : 'Edit'} {props.headerText ? props.headerText : titleize(props.recordType)}</span>

                              <span>
                                  {
                                      props.helpArticleId && (
                                          <Help articleId={props.helpArticleId} />
                                      )
                                  }

                                  {
                                      props?.allowFullScreen && (
                                          <button
                                              type='button'
                                              className='btn btn-link p-2 mt-n1'
                                              onClick={() => setFullScreen(!isFullScreen)}
                                          >
                                              <Icon
                                                  icon={`${isFullScreen ? 'fa-down-left-and-up-right-to-center' : 'fa-up-right-and-down-left-from-center'} fa-lg`}
                                                  packs={['fa-solid']}
                                              />
                                          </button>
                                      )
                                  }
                              </span>
                          </h2>
                      </div>
                  </div>
                  <div className="drawer-main-content">
                      {/* <div className="drawer-scroller"> */}
                          <RecordDrawerForm
                              handleUpdate={handleUpdate}
                              handleCheckboxUpdate={handleCheckboxUpdate}
                              handleManualUpdate={handleManualUpdate}
                              handleFormUpdate={handleFormUpdate}
                              handleDisableSaveButton={handleDisableSaveButton}
                              handleSaveAndClose={handleSaveAndClose}
                              setBeforeSave={setBeforeSave}
                              form={form}
                              errors={errors}
                              type={props.recordType}
                              { ...{new: props.new, ...props.formObjects} } />
                      {/* </div> */}
                  </div>

              </Drawer>
          </FormProvider>
      </Provider>
    )
}
