// ORIGINAL SOURCE/IDEA:
// https://github.com/TechStark/react-dnd-scrolling/issues/5#issuecomment-896850558
// https://codesandbox.io/s/react-dnd-scrolling-demo-forked-98ubm?file=/src/useDragScrolling.js

import { useRef } from 'react'
import { throttle as _throttle } from 'lodash'
import { IS_SCROLLING } from '@/lib/Storage'

export default function useDragScrolling(options) {
    const {
        useElementInsteadOfViewPort=false,
        elementId=null,
        speed=10,
        throttleDrag=true,
        throttle=300,
        edgeHeights=50,
        debug=false
    } = options

    const isScrolling = useRef(false)

    const _edgeHeights = Array.isArray(edgeHeights)
        ? { top: edgeHeights[0], right: edgeHeights[1], bottom: edgeHeights[2], left: edgeHeights[3] }
        : { top: edgeHeights, right: edgeHeights, bottom: edgeHeights, left: edgeHeights }

    const element = useElementInsteadOfViewPort
        ? document.getElementById(elementId)
        : document.documentElement

    const scrollUp = () => {
        element.scrollTop -= speed

        const position = useElementInsteadOfViewPort ? element.scrollTop : window.scrollY

        if (isScrolling.current && position > 0) {
            window.requestAnimationFrame(scrollUp)
        }
    }

    const scrollDown = () => {
        element.scrollTop += speed

        const { offsetHeight, scrollTop, scrollHeight } = element
        const isScrollEnd = offsetHeight + scrollTop >= scrollHeight

        if (isScrolling.current && !isScrollEnd) {
            window.requestAnimationFrame(scrollDown)
        }
    }

    const scrollLeft = () => {
        element.scrollLeft -= speed

        const position = useElementInsteadOfViewPort ? element.scrollLeft : window.scrollX

        if (isScrolling.current && position > 0) {
            window.requestAnimationFrame(scrollLeft)
        }
    }

    const scrollRight = () => {
        element.scrollLeft += speed

        const { offsetWidth, scrollLeft, scrollWidth } = element
        const isScrollEnd = offsetWidth + scrollLeft >= scrollWidth

        if (isScrolling.current && !isScrollEnd) {
            window.requestAnimationFrame(scrollRight)
        }
    }

    const onDragOver = (event) => {
         const isMouseAtTopEdge = useElementInsteadOfViewPort
             ? event.clientY < _edgeHeights.top + (element.getBoundingClientRect().top + window.scrollY)
             : event.clientY < _edgeHeights.top

        const isMouseAtBottomEdge = window.innerHeight - event.clientY < _edgeHeights.bottom
        const isMouseAtLeftEdge   = event.clientX < _edgeHeights.left
        const isMouseAtRightEdge  = window.innerWidth - event.clientX < _edgeHeights.right

        if (debug && console) {
            console.log(
                event.clientX,
                event.clientY,
                isMouseAtTopEdge,
                isMouseAtBottomEdge,
                isMouseAtLeftEdge,
                isMouseAtRightEdge
            )
        }

        const isMouseAtAnyEdge = isMouseAtTopEdge || isMouseAtBottomEdge || isMouseAtLeftEdge || isMouseAtRightEdge

        if (!isScrolling.current && isMouseAtAnyEdge) {
            isScrolling.current = true
            window.sessionStorage.setItem(IS_SCROLLING, isScrolling.current)

            if (isMouseAtTopEdge) {
                if (debug && console) { console.log('SCROLLING UP') }
                window.requestAnimationFrame(scrollUp)
            }

            if (isMouseAtBottomEdge) {
                if (debug && console) { console.log('SCROLLING DOWN') }
                window.requestAnimationFrame(scrollDown)
            }

            if (isMouseAtLeftEdge) {
                if (debug && console) { console.log('SCROLLING LEFT') }
                window.requestAnimationFrame(scrollLeft)
            }

            if (isMouseAtRightEdge) {
                if (debug && console) { console.log('SCROLLING RIGHT') }
                window.requestAnimationFrame(scrollRight)
            }
        } else if (!isMouseAtAnyEdge) {
            isScrolling.current = false
            window.sessionStorage.setItem(IS_SCROLLING, isScrolling.current)
        }
    }

    const handleOnDragOver = throttleDrag
        ? _throttle(onDragOver, throttle)
        : onDragOver

    const addEventListenerForWindow = () => {
        if (useElementInsteadOfViewPort && !element) {
            if (debug && console) { console.error('useDragScrolling: element not found!') }
            return
        }

        window.addEventListener('dragover', handleOnDragOver, false)
    }

    const removeEventListenerForWindow = () => {
        if (useElementInsteadOfViewPort && !element) {
            if (debug && console) { console.error('useDragScrolling: element not found!') }
            return
        }

        window.removeEventListener('dragover', handleOnDragOver, false)
        isScrolling.current = false
    }

    return {
        addEventListenerForWindow,
        removeEventListenerForWindow
    }
}
