import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCompReasons } from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

export default function CompModal({
    onClose=() => {},
    onSave=() => {},
}) {

    const compReasons     = useSelector(selectCompReasons)
    const [comp, setComp] = useState(null)

    const handleChange = (e) => {
        const existingComp = !!comp ? comp : {}
        setComp({ ...existingComp, [e.target.name]: e.target.value })
    }

    const handleSave = () => {
        onSave(comp)
    }

    return (<>
        <div className='reason-list mb-4'>
            {
                compReasons.map((compReason) => (
                    <div key={compReason.id} className='menu-button px-0'>
                        <button
                            name='comp_reason_id'
                            className={`btn d-flex justify-content-between ${compReason.id === parseInt(comp?.comp_reason_id) ? 'selected' : ''}`}
                            value={compReason.id}
                            onClick={handleChange}
                        >
                            <span>{compReason.name}</span>
                            {compReason.id === parseInt(comp?.comp_reason_id) && <i className="fa-solid fa-check" />}
                        </button>
                    </div>
                ))
            }
        </div>

        <textarea
            name='notes'
            placeholder='Notes'
            className='form-control'
            value={comp?.notes || ''}
            onChange={handleChange}
        />

        <div className="modal-footer mx-n4 mt-4 px-4 pb-0 border-top border-thick border-top-gray0">
            <button
                type='button'
                className='btn btn-outline-secondary text-white text-bold mr-auto'
                children='Close'
                onClick={onClose}
            />
            <button
                children="Submit"
                className="btn btn-primary ml-auto"
                disabled={comp === null}
                onClick={handleSave}
            />
        </div>
    </>)
}
