import React from 'react'
import { useSelector } from 'react-redux'
import { selectBooking } from '@/features/EditBooking/editBookingSlice'
import { numberToCurrency } from '@/lib/Number'

export default function BalancesTop({
    reservationTotal,
    paidToDate,
    remaining,
    remainingBalance,
    pendingAmountRefundedCents
}) {

    const booking = useSelector(selectBooking)

    return (
        <div className="drawer-booking-ledger-remaining mt-0 mb-2">
            <div className="reservation-total">
                <div className="label">
                    Reservation Total
                </div>
                <div className="amount">
                    {numberToCurrency(reservationTotal)}
                </div>
            </div>

            <div className="paid-to-date">
                <div className="label">
                    Paid to Date
                </div>
                <div className="amount">
                    {numberToCurrency(paidToDate)}
                </div>
            </div>

            {
                pendingAmountRefundedCents > 0 && (
                    <div className="pending-refunds border border-danger">
                        <div className="label">
                            Pending<br />Refund(s)
                        </div>
                        <div className="amount">
                            {numberToCurrency(pendingAmountRefundedCents / 100)}
                        </div>
                    </div>
                )
            }

            <div className="amount-remaining">
                <div className="label">
                    Remaining<br/>Balance
                </div>
                <div className={`amount ${(remaining || (booking?.balance_cents / 100).toFixed(2)) > 0 ? 'text-danger' : 'text-success'}`}>
                    {numberToCurrency(remainingBalance)}
                </div>
            </div>
        </div>
    )
}
