import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import {
    selectBooking,
    selectExpandedActions,
    setExpandedActions,
    openManagerOverrideModal,
    updateBooking,
} from '@/features/EditBooking/editBookingSlice'
import GenerateInvoice from '@/features/EditBooking/GenerateInvoice'
import { isBookingCancelled } from '@/lib/Booking'

export function _QuoteLedgerActions({ isExpandable=true, onError, children }) {

    const dispatch    = useDispatch()
    const formMethods = useFormContext()
    const booking     = useSelector(selectBooking)
    const isExpanded  = useSelector(selectExpandedActions)

    const handleExpansionToggle = () => {
        dispatch(setExpandedActions(!isExpanded))
    }

    const handleConversion = () => {
        dispatch(updateBooking(false, true))
    }

    return (
        <div className={`drawer-booking-ledger-actions pt-4 expandable ${isExpandable && isExpanded ? 'expanded' : ''}`}>
            {
                isExpandable && (
                    <i
                        title={`Click to see ${isExpanded ? 'less' : 'more'}`}
                        className={`expandable-toggle fas fa-caret-circle-${isExpanded ? 'down' : 'up'}`}
                        onClick={handleExpansionToggle}
                    />
                )
            }

            <div className='expandable-content'>
                <button
                    type="button"
                    className="btn btn-success text-white w-100"
                    disabled={isBookingCancelled(booking)}
                    onClick={formMethods.handleSubmit(handleConversion, onError)}
                >
                    Accept Quote
                </button>

                <div className="row no-gutters">
                    <div className="col-6 pr-1">
                        <GenerateInvoice icon={false} label='Email' sendAsEmail={true} />
                    </div>
                    <div className="col-6 pl-1">
                        <GenerateInvoice icon={false} label='Download' sendAsEmail={false} />
                    </div>
                </div>

                <hr className='mt-0' />

                <button
                  className="btn btn-outline btn-block"
                  disabled={isBookingCancelled(booking)}
                  onClick={() => dispatch(openManagerOverrideModal())}
                >
                    Manager Override
                </button>

                {
                    isExpandable && isExpanded && <>
                        { children }
                    </>
                }
            </div>
        </div>
    )
}

export const QuoteLedgerActions = React.memo(_QuoteLedgerActions)
