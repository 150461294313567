import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux'
import { addAlert } from '@/features/Notifications/notificationSlice'
import { useForm, FormProvider } from "react-hook-form";
import CustomerWaiversNameDOBZip from "./CustomerWaiversNameDOBZip";
import CustomerWaiversGuardian from "./CustomerWaiversGuardian";
import { validateEquality, validationPatterns } from "../../lib/Errors";
import { errorsFor } from '@/components/Form/ErrorsHelper'
import Input from "../Form/Input";
import axios from "axios";
import {accessToken} from "@/lib/Csrf";
import { debug } from '@/lib/Debug'

const customerName = customer => {
  let names = customer.name.split(' ')
  const firstName = names.shift()
  const lastName = names.join(' ')

  return { firstName: firstName, lastName: lastName }
}

export default function CustomerWaiversForm({
  waiver,
  company,
  location,
  reservation_number,
  resourceTypeId,
  adult,
  minor,
  showSuccess,
  isReturning,
  customer,
}) {
  const dispatch = useDispatch()

  const signedWaiver = customer?.signed_waivers.find(signedWaiver => {
    return signedWaiver.waiver_id === waiver.id
  })

  const formMethods = useForm({
      mode: 'onChange',
      defaultValues: {
        first_name: customer ? customerName(customer).firstName : '',
        last_name: customer ? customerName(customer).lastName : '',
        phone: customer ? customer.phone : '',
        zip_code: customer ? customer.zip_code : '',
        adult_gender: customer ? customer.gender : null,
        minor_gender: customer ? customer.gender : null,
        relationship: signedWaiver ? signedWaiver.guardian_relationship : '',
        guardian_first_name: signedWaiver ? signedWaiver.guardian_first_name : '',
        guardian_last_name: signedWaiver ? signedWaiver.guardian_last_name : '',
        guardian_phone: signedWaiver ? signedWaiver.guardian_phone : '',
        guardian_email: signedWaiver ? signedWaiver.guardian_email : '',
        guardian_email2: signedWaiver ? signedWaiver.guardian_email : '',
      }
  })

  const [errors, setErrors] = useState({})

  const name = company.customer_verbiage_singular.toString().toLowerCase().replace(/ /g,"_") || "member"

  const adult_or_minor = minor ? 'MINOR' : name.toUpperCase()

  const has_consented_label = <>
    <p dangerouslySetInnerHTML={{__html: `${waiver.disclaimer_message}`}}/>
    <p className='text-white' dangerouslySetInnerHTML={{__html: `${waiver.disclaimer_message_part_two}`}}/>
  </>

  /**
   * Loop through form errors and build up our own errors
   * object that gets passed down to all of the components
   */
  const onError = (formErrors, e) => {
    setErrors(errorsFor(formErrors))
  }

  const handleSave = (data) => {
    axios.post(`/companies/${company.url_hash}/locations/${location.url_hash}/waivers/${reservation_number}/submit_waiver`, {
      authenticity_token: accessToken,
      is_minor: minor,
      is_returning: isReturning,
      customer_id: customer?.id,
      data,
    }).then(({ data }) => {
      if (data.success) {
        showSuccess()
        return
      }

      dispatch(addAlert({ type: 'error', text: data.message }))
    }).catch(e => {
      if (debug && console) { console.warn(e) }
      dispatch(addAlert({ type: 'error', text: 'An error occurred!' }))
    })
  }

  /**
   * Update our errors object whenever the form errors object changes
   */
  useEffect(() => {
      if (Object.values(formMethods.formState.errors).length !== Object.values(errors).length) {
          setErrors(errorsFor(formMethods.formState.errors))
      }
  }, [formMethods.formState])

  useEffect(() => {
    formMethods.reset()

    const waiverButtons = document.getElementById('waiver-buttons')
    const waiverForm    = document.getElementById('waiver-form')

    if (waiverButtons) {
        waiverButtons.scrollIntoView()
    } else if (waiverForm) {
        waiverForm.scrollIntoView()
    }
  }, [adult, minor])

  return (
    <FormProvider {...formMethods} >
      <form id="waiver-form" className="pt-4 pb-5 mb-5" onSubmit={formMethods.handleSubmit(handleSave, onError)}>
        <input
          {...formMethods.register('waiver_id')}
          type="hidden"
          name="waiver_id"
          value={`${waiver.id}`}
        />
        <input
          {...formMethods.register('resource_type_id')}
          type="hidden"
          name="resource_type_id"
          value={resourceTypeId || ''}
        />
        <CustomerWaiversNameDOBZip
          additional_minor={false}
          name={name}
          adult_or_minor={adult_or_minor}
          errors={errors}
          isReturning={isReturning}
          customer={customer}
          waiver={waiver}
        />

        {minor ? (
          <CustomerWaiversGuardian
            adult_or_minor={adult_or_minor}
            isReturning={isReturning}
            customer={customer}
            signedWaiver={signedWaiver}
            waiver={waiver}
            errors={errors} />
        ) : (
          <div id="member_email" className="mb-5">
            {waiver.phone &&
            <div className="row">
              <div className="col-lg-6">
                <div className="waiver-item">
                  {adult_or_minor}'S PHONE NUMBER
                </div>
                <Input
                  name="phone"
                  type="tel"
                  mask="999-999-9999"
                  maskPlaceholder="___-___-____"
                  hideLabel={true}
                  value={formMethods.watch("phone", '')}
                  errors={errors}
                  placeholder="Phone Number"
                  validation={{ phone: { pattern: validationPatterns.phone }}}
                />
              </div>
            </div>
            }
            <div className="row">
              <div className="col">
                <div className="waiver-item">
                  {adult_or_minor}'S EMAIL ADDRESS
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <Input
                  name='email'
                  type='email'
                  hideLabel={true}
                  value={isReturning && customer?.email ? customer.email : formMethods.watch("email", '')}
                  errors={errors}
                  placeholder="Email Address"
                  disabled={isReturning && customer?.email}
                  handleChange={(e) => {
                    formMethods.setValue('email', String(e.target.value).trim())
                  }}
                  validation={{ 'email': {
                    required: true,
                    pattern: validationPatterns.email
                  }}}
                />
              </div>
              <div className="col-lg-6">
                <Input
                  name='email2'
                  type='email'
                  hideLabel={true}
                  value={isReturning && customer?.email ? customer.email : formMethods.watch("email2", '')}
                  errors={errors}
                  placeholder="Confirm Email"
                  disabled={isReturning && customer?.email}
                  handleChange={(e) => {
                    formMethods.setValue('email2', String(e.target.value).trim())
                  }}
                  validation={{ 'email2': {
                    required: true,
                    pattern: validationPatterns.email,
                    validate: () => validateEquality(formMethods.getValues("email"), formMethods.getValues("email2")) || 'Email addresses do not match!'
                  }}}
                />
              </div>
              { isReturning && customer?.email && <>
                <input
                  {...formMethods.register('email')}
                  type="hidden"
                  name="email"
                  value={customer.email}
                />
                <input
                  {...formMethods.register('email2')}
                  type="hidden"
                  name="email2"
                  value={customer.email}
                />
                </>
              }
            </div>
          </div>
        )}

        <div className="row">
          <div className="col">
            <div className="receive_email_checkbox">
              <div className="form-group">
                <div className="custom-control custom-checkbox custom-control-inline">
                  <input
                    {...formMethods.register("email_opt_in")}
                    type='checkbox'
                    name='email_opt_in'
                    id='email_opt_in'
                    className='custom-control-input'
                    defaultChecked={true}
                  />
                  <label className="custom-control-label" htmlFor="email_opt_in">{waiver.opt_in_message}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="has_consented">
              <div className="form-group">
                <div className="custom-control custom-checkbox custom-control-inline">

                  <input
                    type='checkbox'
                    name='has_consented'
                    id='has_consented'
                    className={`custom-control-input ${errors['has_consented'] ? 'is-invalid' : ''}`}
                    value="true"
                    {...formMethods.register("has_consented", { required: 'This field is required' })}
                  />
                  <label className="custom-control-label" htmlFor="has_consented">{has_consented_label}</label>
                </div>
              </div>
            </div>

            {
                errors['has_consented'] && (
                    <div className="invalid-feedback d-block mt-n3 ml-4 pl-2">
                        { errors['has_consented'].join(', ') }
                    </div>
                )
            }
          </div>
        </div>

        <hr/>
        <div className="waiver-item">
          <div className="row">
            <div className="col-11">
              E-SIGNATURE
            </div>
          </div>
        </div>
        {minor ? (
          <div className="row">
            <div className="col-lg-6">
              <Input
                name="esign_first_name"
                hideLabel={true}
                placeholder="Parent/Guardian First Name"
                value={formMethods.watch("esign_first_name", '')}
                handleChange={(e) => {
                    formMethods.setValue('esign_first_name', String(e.target.value).trim())
                }}
                errors={errors}
                hint='First Name must match exactly as the First Name field above.'
                validation={{ 'esign_first_name': {
                    required: true,
                    validate: () => validateEquality(formMethods.getValues("esign_first_name"), formMethods.getValues("guardian_first_name")) || 'Does not match!'
                  }}}
              />
            </div>
            <div className="col-lg-6">
              <Input
                name="esign_last_name"
                hideLabel={true}
                placeholder="Parent/Guardian Last Name"
                value={formMethods.watch("esign_last_name", '')}
                handleChange={(e) => {
                    formMethods.setValue('esign_last_name', String(e.target.value).trim())
                }}
                errors={errors}
                hint='Last Name must match exactly as the Last Name field above.'
                validation={{ 'esign_last_name': {
                    required: true,
                    validate: () => validateEquality(formMethods.getValues("esign_last_name"), formMethods.getValues("guardian_last_name")) || 'Does not match!'
                  }}}
              />
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-lg-6">
              <Input
                name="esign_first_name"
                hideLabel={true}
                placeholder="First Name"
                value={formMethods.watch("esign_first_name", '')}
                handleChange={(e) => {
                    formMethods.setValue('esign_first_name', String(e.target.value).trim())
                }}
                errors={errors}
                hint='First Name must match exactly as the First Name field above.'
                validation={{ 'esign_first_name': {
                    required: true,
                    validate: () => validateEquality(formMethods.getValues("esign_first_name"), formMethods.getValues("first_name")) || 'Does not match!'
                  }}}
              />
            </div>
            <div className="col-lg-6">
              <Input
                name="esign_last_name"
                hideLabel={true}
                placeholder="Last Name"
                value={formMethods.watch("esign_last_name", '')}
                handleChange={(e) => {
                    formMethods.setValue('esign_last_name', String(e.target.value).trim())
                }}
                errors={errors}
                hint='Last Name must match exactly as the Last Name field above.'
                validation={{ 'esign_last_name': {
                    required: true,
                    validate: () => validateEquality(formMethods.getValues("esign_last_name"), formMethods.getValues("last_name")) || 'Does not match!'
                  }}}
              />
            </div>
          </div>
        )}
        <div className="submit_waiver_button">
          <button type="submit" className="btn btn-outline-primary submit-waiver btn-block">AGREE TO THIS DOCUMENT</button>
        </div>
        <div className="waiver-submit-spinner spinner d-none">
        </div>
      </form>
    </FormProvider>
  )
}
