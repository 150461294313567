import React, { useState } from 'react'
import CustomerIncrementer from './CustomerIncrementer'

export default function CustomerTypeSelect({
  bookingCustomers,
  maxCustomerTypeCounts,
  customerTypeCounts,
  setStep,
}) {
  const [errorMessage, setErrorMessage] = useState('')

  return (
    <div className="col-12 col-md-8">
      <div className="form-group">
        <h3 className="mb-3">Who are you paying for?</h3>

        {bookingCustomers
          .filter(
            customerType =>
              maxCustomerTypeCounts[customerType.customer_type_id] > 0
          )
          .map(customerType => {
            return (
              <CustomerIncrementer
                key={customerType.id}
                customerType={customerType}
                maxCustomerTypeCounts={maxCustomerTypeCounts}
              />
            )
          })}

        <div className="invalid-feedback d-block">{errorMessage}</div>

        <button
          className="btn btn-primary btn-block mt-4"
          onClick={() => {
            if (Object.values(customerTypeCounts).reduce((a, b) => a + b, 0) > 0) {
              setStep('3')
            } else {
              setErrorMessage('Please select customer you are paying for.')
            }
          }}
        >
          Continue
        </button>
      </div>
    </div>
  )
}
