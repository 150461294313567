import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import moment from 'moment'

import {
    selectMemberInformation,
    setMemberInformationField,
    selectSelectedType,
    selectPurchaseFor
} from '../memberSlice'

import { errorsFor } from '@/components/Form/ErrorsHelper'
import Checkbox from '../../../components/Form/Checkbox'
import Input from '../../../components/Form/Input'
import DateSelectFields from '../../../components/Form/DateSelectFields'
import MemberEmail from '../MemberEmail'
import MemberStep from './MemberStep'
import MemberSteps from '@/lib/Member'
import { validationPatterns } from '@/lib/Errors'

export default function MemberInformation({ hideMobileProgressHeader }) {

    const dispatch       = useDispatch()
    const membershipType = useSelector(selectSelectedType)
    const info           = useSelector(selectMemberInformation)
    const purchaseFor    = useSelector(selectPurchaseFor)

    const [allChecked, setAllChecked]       = useState(false)
    const [confirmations, setConfirmations] = useState({})
    const [errors, setErrors]               = useState({})

    const onError = (formErrors) => {
        setErrors(errorsFor(formErrors))
    }

    useEffect(() => {
        let confirmationOptions = {}
        if (!!membershipType.confirmation_checks) {
            membershipType.confirmation_checks.forEach(function (_conf, i) {
                confirmationOptions[`confirmation_checks_${i}`] = false
            });

            setConfirmations(confirmationOptions)
        }
    }, [membershipType])

    const handleConfirmationCheck = (e) => {
        setConfirmations({
            ...confirmations,
            [e.target.id]: e.target.checked
        })
    }

    useEffect(() => {
        const result = Object.values(confirmations).every(v => v);
        setAllChecked(result);
    }, [confirmations])

    const handleChange = (name, value) => {
        dispatch(setMemberInformationField({ name, value }))
    }

    const disabled = () => {
        return isEmpty(info.firstName)    ||
               isEmpty(info.lastName)     ||
               isEmpty(info.phone)        ||
               isEmpty(info.email)        ||
               isEmpty(info.confirmEmail) ||
               isEmpty(info.zip_code)     ||
               isEmpty(info.birthdate)    ||
               !allChecked
    }

    return (
        <MemberStep
            disabled={disabled()}
            backStep={MemberSteps.MEMBERSHIP_TERM}
            nextStep={MemberSteps.PAYMENT_INFORMATION}
            hideMobileProgressHeader={hideMobileProgressHeader}
            onError={onError}
        >
            <h2 className="text-center pt-md-4 pb-4">Member Information</h2>

            <div className="row">
                <Input
                    req={true}
                    type='text'
                    name='firstName'
                    label='FIRST NAME'
                    placeholder='First Name'
                    value={info.firstName}
                    className='form-control'
                    cols='12 col-md-6'
                    handleChange={e => handleChange('firstName', e.target.value)}
                />

                <Input
                    req={true}
                    type='text'
                    name='lastName'
                    label='LAST NAME'
                    placeholder='Last Name'
                    value={info.lastName}
                    className='form-control'
                    cols='12 col-md-6'
                    handleChange={e => handleChange('lastName', e.target.value)}
                />
            </div>

            <div className="row">
                <div className="col-12 col-md-6">
                    <DateSelectFields
                        month={Number((info.birthdate?.split('-'))?.[0])}
                        day={Number((info.birthdate?.split('-'))?.[1])}
                        year={Number((info.birthdate?.split('-'))?.[2])}
                        errors={errors}
                        autoComplete
                        onChange={(_, value) => handleChange('birthdate', moment(value).format('M-D-YYYY'))}
                    />
                </div>

                <Input
                    req={true}
                    name='phone'
                    label='PHONE'
                    mask='999-999-9999'
                    value={info.phone}
                    cols='12 col-md-6'
                    handleChange={e => handleChange('phone', e.target.value)}
                />
            </div>

            <div className="row">
                <Input
                    req={true}
                    name='zip_code'
                    label='ZIPCODE'
                    value={info.zip_code}
                    cols='12 col-md-6'
                    validation={{ 'zip_code': {
                        required: true,
                        pattern: validationPatterns.zip,
                    }}}
                    errors={errors}
                    handleChange={e => handleChange('zip_code', e.target.value)}
                />
            </div>

            <div className="row">
                <MemberEmail disabled={/^myself$/i.test(purchaseFor)} />

                <MemberEmail
                    ignoreFormat={true}
                    ignoreUniqueness={true}
                    name='confirmEmail'
                    label='CONFIRM EMAIL'
                    placeholder='Confirm Email Address'
                />
            </div>

            { membershipType?.confirmation_checks &&
                <div className="mt-4">
                    { membershipType.confirmation_checks.map((confirmation, index) => (
                        <div className="form-row mb-3" key={`confirmation_checks_${index}`}>
                            <div className="col">
                                <div className="custom-control custom-checkbox">
                                    <input
                                        type='checkbox'
                                        name={`confirmation_checks[${index}]`}
                                        id={`confirmation_checks_${index}`}
                                        className={`custom-control-input`}
                                        onChange={handleConfirmationCheck}
                                    />

                                    <label htmlFor={`confirmation_checks_${index}`} className='custom-control-label'>
                                        {confirmation.text} <span className="req">*</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            }

            <div className="row mt-4 pt-3">
                <Checkbox
                    cols='12'
                    value={info.newsletter}
                    label='Sign up for our newsletter for offers and events? Your data is safe with us. Pinky swear.'
                    handleChange={(_e) => handleChange('newsletter', !info.newsletter)}
                />
            </div>
        </MemberStep>
    )
}
