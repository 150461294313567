import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectTipAmount, setTipAmount } from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

export default function TipAmount({ enabled=true }) {

    const dispatch  = useDispatch()
    const tipAmount = useSelector(selectTipAmount)

    const handleChange = (e) => {
        const amount = e.target.value

        if (amount === '' || /^(?:\d*\.\d{1,2}|\d+)$/i.test(amount)) {
            dispatch(setTipAmount(amount ? amount : 0))
        }
    }

    return enabled && (
        <div className='row mt-3 align-items-center'>
            <div className='col-4 text-right text-bold px-0'>Add Tip</div>
            <div className='col-8'>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text text-white">$</span>
                    </div>
                    <input
                        type='number'
                        min='0'
                        step='.01'
                        placeholder='0.00'
                        name='tip'
                        className='form-control'
                        value={tipAmount || ''}
                        onChange={handleChange}
                    />
                </div>
            </div>
        </div>
    )
}
