import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCreditMethod,
  setCreditMethod,
  setPaymentType,
  selectCards,
  selectMembershipCard,
} from '@/features/Members/memberSlice'
import CardsOnFile from '@/features/Members/CardsOnFile'
import Terminal from './Terminal'
import Manual from './Manual'

export default function CreditCardPayments() {
  const dispatch     = useDispatch()
  const creditMethod = useSelector(selectCreditMethod)
  const cards        = useSelector(selectCards)
  const selectedCard = useSelector(selectMembershipCard)

  const [adyenCheckout, setAdyenCheckout] = useState(null)

  const handleUpdateCreditMethod = (e) => {
    dispatch(setCreditMethod(e.target.value))
  }

  // nulling this out allows Adyen JS to re-instantiate
  // whenever the user switches payment methods/tabs
  useEffect(() => {
    if (creditMethod !== 'manual' && !!adyenCheckout) {
      setAdyenCheckout(null)
    }
  }, [creditMethod])

  useEffect(() => {
    dispatch(setPaymentType('credit'))
    dispatch(setCreditMethod('terminal'))
  }, [])

  return <>
    <ul className="radio-tabs mb-4">
      <li className='w-100'>
        <div className="radio-tab">
          <input
            id="tab-terminal"
            type="radio"
            name="terminal"
            value="terminal"
            className="radio-tab-input"
            checked={/terminal/i.test(creditMethod)}
            onChange={handleUpdateCreditMethod}
          />
          <label className="radio-tab-label w-100 text-center" htmlFor="tab-terminal">
            Terminal
          </label>
        </div>
      </li>
      {(cards || []).length > 0 &&
        <li className='w-100'>
          <div className="radio-tab">
            <input
              id="tab-on_file"
              type="radio"
              name="on_file"
              value="on_file"
              className="radio-tab-input"
              checked={/on_file/i.test(creditMethod)}
              onChange={handleUpdateCreditMethod}
            />
            <label className="radio-tab-label w-100 text-center" htmlFor="tab-on_file">
              Card On File
            </label>
          </div>
        </li>
      }
      <li className='w-100'>
        <div className="radio-tab">
          <input
            id="tab-manual"
            type="radio"
            name="manual"
            value="manual"
            className="radio-tab-input"
            checked={/manual/i.test(creditMethod)}
            onChange={handleUpdateCreditMethod}
          />
          <label className="radio-tab-label w-100 text-center" htmlFor="tab-manual">
            Manual
          </label>
        </div>
      </li>
    </ul>
    {creditMethod === 'terminal' && <Terminal />}
    {creditMethod === 'on_file' && <>
      <CardsOnFile withoutTitle={true}/>
      <button
        type='submit'
        children='Submit Payment'
        className='btn btn-primary w-100'
        disabled={creditMethod === 'on_file' && !selectedCard}
      />
    </>}
    {creditMethod === 'manual' && <Manual />}
  </>
}
