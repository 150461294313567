import React, { useState, useEffect } from 'react'
import Input from '../Form/Input'
import FormRow from '../Form/FormRow'
import Checkbox from '../Form/Checkbox'
import TextArea from '../Form/TextArea'
import ConfirmationChecks from './ConfirmationChecks'
import Help from '@/features/Help/Help'
import { isAdvancedPointOfSaleEnabled } from '@/lib/AdvancedPointOfSale'

export default function ResourceTypeReservableSetupTab({
  form,
  location,
  errors,
  handleUpdate,
  handleCheckboxUpdate,
  handleManualUpdate,
  handleFormUpdate
}) {
  const [initialRender, setInitialRender] = useState(true)

  useEffect(() => {
    setInitialRender(false)
  }, [])

  useEffect(() => {
    if (
      !initialRender
      && form.uses_scoring_app
      && !form.waivers_required
      && !form.use_status_icons
    ) {
      handleFormUpdate({ ...form, ...{
          use_status_icons: true,
          waivers_required: true,
        }})
    }
  }, [form.uses_scoring_app])

  useEffect(() => {
    if (
      !initialRender
      && form.uses_scoring_app
      && (
        !form.waivers_required || !form.use_status_icons
      )
    ) {
      handleFormUpdate({ ...form, ...{ uses_scoring_app: false }})
    }
  }, [form.waivers_required, form.use_status_icons])

  return (
    <div className='pt-0'>
      <FormRow>
        <Input
          cols='6'
          name='title'
          label='Resource Type Name'
          value={form.title}
          errors={errors}
          validation={{ 'title': { required: true }}}
          handleChange={handleUpdate}
        />

        <Input
          cols='3'
          name='singular_name'
          value={form.singular_name}
          errors={errors}
          validation={{ 'singular_name': { required: true }}}
          handleChange={handleUpdate}
        />

        <Input
          cols='3'
          name='plural_name'
          value={form.plural_name}
          errors={errors}
          validation={{ 'plural_name': { required: true }}}
          handleChange={handleUpdate}
        />
      </FormRow>

      <FormRow>
        <div className='form-group col-4 mt-n1'>
          <label className="d-inline">Max Concurrent Reservations</label>
          <Help articleId='154000158942-max-concurrent-reservations' />
          <Input
            type='number'
            name='max_concurrent_reservations'
            hideLabel={true}
            withWrapper={false}
            value={form.max_concurrent_reservations}
            handleChange={handleUpdate}
          />
        </div>

        <Input
          cols='4'
          value={form.customer_verbiage_singular}
          errors={errors}
          validation={{ 'customer_verbiage_singular': { required: true }}}
          handleChange={handleUpdate}
        />

        <Input
          cols='4'
          value={form.customer_verbiage_plural}
          errors={errors}
          validation={{ 'customer_verbiage_plural': { required: true }}}
          handleChange={handleUpdate}
        />
      </FormRow>

      <FormRow>
        <div className='form-group col-4'>
          <label className="d-inline">Consecutive Reservations Buffer</label>
          <Help articleId='154000158944-consecutive-reservations-buffer' />
          <Input
            type='number'
            name='concurrent_reservations_buffer'
            placeholder='Consecutive Reservations Buffer'
            hideLabel={true}
            withWrapper={false}
            value={form.concurrent_reservations_buffer}
            handleChange={handleUpdate} />
        </div>

        <div className="col-8">
          <div className="row mt-2 pt-4 px-0">
            <Checkbox
              cols='6 pr-0'
              name='add_buffer_before_reservations'
              handleChange={handleCheckboxUpdate}
              value={form.add_buffer_before_reservations} />

            <Checkbox
              cols='6 pr-0'
              label='Add Buffer After Reservations'
              name='add_buffer_after_reservations'
              handleChange={handleCheckboxUpdate}
              value={form.add_buffer_after_reservations} />
          </div>
        </div>
      </FormRow>

      <hr className="mt-2 pb-2" />

      <FormRow className='mt-2'>
        <Checkbox
          cols='6'
          name='is_reservable'
          label='Is Reservable?'
          value={form.is_reservable}
          disabled={!!form.created_at}
          handleChange={handleCheckboxUpdate}
        />

        <div className='d-flex col-6'>
          <Checkbox
            name='waivers_required'
            value={form.waivers_required}
            handleChange={handleCheckboxUpdate}
          />
          <Help articleId='154000158948-waivers-required-toggle' />
        </div>

        <div className='d-flex col-6'>
          <Checkbox
            name='uses_scoring_app'
            label="Uses the Scoring Application?"
            value={form.uses_scoring_app}
            handleChange={handleCheckboxUpdate}
          />
          <Help articleId='154000158946-uses-the-scoring-application-toggle' />
        </div>

        {
          isAdvancedPointOfSaleEnabled(location.advanced_point_of_sale_status, { allowTransition: true }) && (
            <Checkbox
              cols='6'
              name='display_in_pos'
              label='Uses the POS Application?'
              value={form.display_in_pos}
              handleChange={handleCheckboxUpdate}
            />
          )
        }

        <div className='d-flex col-6'>
          <Checkbox
            label='Training & Time Warning Status Icons'
            name='use_status_icons'
            value={form.use_status_icons}
            handleChange={handleCheckboxUpdate}
          />
          <Help articleId='154000158947-uses-status-icons-toggle' />
        </div>

        <div className='d-flex col-6'>
          <Checkbox
            name='show_reminder_modal'
            value={form.show_reminder_modal}
            handleChange={handleCheckboxUpdate}
          />
          <Help articleId='154000158949-show-reminders-modal-toggle' />
        </div>
      </FormRow>

      { form.show_reminder_modal &&
          <>
              <hr className="mt-2 pb-2" />

              <FormRow className='mt-2'>
                <TextArea
                  cols='12'
                  rows='7'
                  value={form.reminder_modal_text}
                  errors={errors}
                  validation={{ 'reminder_modal_text': { required: true } }}
                  handleChange={handleUpdate}
                />
              </FormRow>
          </>
      }

      <ConfirmationChecks
        errors={errors}
        confirmationChecks={form.confirmation_checks}
        handleUpdate={handleManualUpdate} />
    </div>
  )
}
