/**
 * Find which index the first (lowest number) resource is on a booking and return where that is in relation to all available resources.
 *
 * @param {*} bookingResources array of the resources assigned to an individual booking
 * @param {*} allResources array of all possible resources for the user's selected resource type
 * @returns integer of which row to put this booking on
 */
export function parseResourceRow(bookingResources, allResources) {
    /**
     * Array of all of the available resource ids that belong to the currently selected resource type.
     */
    const allResourceIds = allResources.map(r => r.id)

    /**
     * Grab the first booking resource if there are multiple. This is the resource we need to use as the 'top' of the card
     *
     * When we get back resources they are sorted according to their `order` attribute, meaning this array
     * should be ordered and ready to use.
     */
    const firstBookingResource = Math.min(...bookingResources)

    /**
     * The ids here can be arbitrary, we don't want to use the ID here, we want to use the index of that ID.
     *
     * e.g. if the resources are [444, 500] and the first booking resource is 500, the top of the booking card
     * needs to be set to "2", not "500", since it's the 2nd row/2nd resource that gets rendered.
     *
     * We + 1 because we don't want an array index, rather whether it's the 1st,2nd,etc. row
     */
    return allResourceIds.indexOf(firstBookingResource) + 1
}

export default null
