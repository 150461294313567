import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { addAlert } from '@/features/Notifications/notificationSlice'
import Alert from '../Alert/Alert'
import DrawerActions from './DrawerActions'
import { useConfirm } from '@/lib/useConfirmHook'
import { UI } from '@/lib/Constants'

export default function Drawer({
  record,
  form,
  recordUrl,
  open,
  close,
  confirmOnClose,
  confirmOnSaveMessage,
  save,
  saveAndClose,
  isFullScreen,
  alert,
  drawerRef,
  children,
  disabledSaveButton=false,
  showSave=false,
  showSaveAndClose=true,
  showSaveAndNew=false,
  shouldFetchRecordOnLoad=false,
  handleFormUpdate=() => {},
}) {

    const { confirm } = useConfirm()
    const dispatch    = useDispatch()

    /*
     * This prevents warnings from any react-dnd components inside
     * of the drawers about having multiple scrollable parent elements
     */
    const toggleClasses = (action) => {
        const body             = document.getElementById('body')
        const responsiveTables = document.querySelectorAll('.table-responsive')
        const drawerClasses    = ['drawer-open', 'overflow-hidden']

        if (action === 'add') {
            body.classList.add(...drawerClasses)
            responsiveTables.forEach((table) => table.classList.add(...drawerClasses))
        }

        if (action === 'remove') {
            body.classList.remove(...drawerClasses)
            responsiveTables.forEach((table) => table.classList.remove(...drawerClasses))
        }
    }

    const handleClose = async() => {
        if (confirmOnClose && JSON.stringify(form) !== JSON.stringify(record)) {
            if (await confirm('Are you sure? Your changes will not be saved.')) {
                close()
            }
        } else {
            close()
        }
    }

    const handleSave = async() => {
        if (confirmOnSaveMessage) {
            if (await confirm(confirmOnSaveMessage)) {
                save()
            }
        } else {
            save()
        }
    }

    const handleSaveAndClose = async() => {
        if (confirmOnSaveMessage) {
            if (await confirm(confirmOnSaveMessage)) {
                saveAndClose()
            }
        } else {
            saveAndClose()
        }
    }

    const handleEscape = (e) => {
        if (
          e.key === 'Escape'
          && !document.body.classList.contains(UI.classes.MULTIPLE_MODALS_OPEN)
          && !document.body.classList.contains(UI.classes.CONFIRMATION_OPEN)
          && !document.body.classList.contains(UI.classes.HELPJUICE_OPEN)
        ) {
            handleClose()
        }
    }

    useEffect(() => {
        if (open) {
            document.addEventListener('keydown', handleEscape, false)
        }

        // Auto-load the record contents into form state if
        // an object was not provided but a recordUrl was
        if (shouldFetchRecordOnLoad) {
            axios.get(recordUrl).then(({ data }) => {
                if (data.success) {
                    handleFormUpdate(data.payload)
                    return
                }

                dispatch(addAlert({ type: 'error', text: data.message }))
                setTimeout(() => close(), 250)
            })
        }

        toggleClasses(open ? 'add' : 'remove')

        return () => {
            document.removeEventListener('keydown', handleEscape, false)
            toggleClasses('remove')
        }
    }, [open])

    return (
        <div className={`drawer dnd-support ${open ? 'open' : ''} ${isFullScreen ? 'fullscreen' : ''}`.trim()} ref={drawerRef}>
            <div className="drawer-scaffold">
                <div className="drawer-main">
                    { alert && <Alert {...alert} /> }

                    { children }

                    <DrawerActions
                        close={handleClose}
                        save={handleSave}
                        saveAndClose={handleSaveAndClose}
                        disabledSaveButton={disabledSaveButton}
                        showSave={showSave}
                        showSaveAndClose={showSaveAndClose}
                        showSaveAndNew={showSaveAndNew}
                    />
                </div>
            </div>
        </div>
    )
}
