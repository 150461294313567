import React, { useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectConnected, submitTransaction } from '../Terminal/terminalSlice'
import { selectCode } from '../ManagerCodes/managerCodeSlice'

import {
  selectPricing,
  selectOrder,
  selectError,
  selectPaymentType,
  selectPaymentAmount,
  selectPaymentReceived,
  selectChangeDue,
  selectSelectedProducts,
  selectProcessingPayment,
  selectCheckNumber,
  selectZipCode,
  submitPurchase,
} from './productSlice'

import PaymentProcessing from '../../features/Products/PaymentProcessing'
import AdyenTerminalTransaction from '../../features/Terminal/AdyenTerminalTransaction'
import ProductCheck from './ProductCheck'
import ProductGiftCard from './ProductGiftCard'
import ProductPaymentType from './ProductPaymentType'
import ProductCreditCard from './ProductCreditCard'
import ProductTerminal from './ProductTerminal'
import ProductPaymentAmount from './ProductPaymentAmount'
import PaymentSelectWaiver from '../BookingPayment/PaymentSelectWaiver'
import ProductCashPaymentAmountReceived from './ProductCashPaymentAmountReceived'

export default function ProductPayment({ purchasePath, hardwareKey, inBooking, locationId, locationTimeZone, locationPaymentProcessor, environment }) {

    const dispatch            = useDispatch()
    const order               = useSelector(selectOrder)
    const error               = useSelector(selectError)
    const pricing             = useSelector(selectPricing)
    const paymentType         = useSelector(selectPaymentType)
    const paymentAmount       = useSelector(selectPaymentAmount)
    const paymentReceived     = useSelector(selectPaymentReceived)
    const changeDue           = useSelector(selectChangeDue)
    const selectedProducts    = useSelector(selectSelectedProducts)
    const isProcessingPayment = useSelector(selectProcessingPayment)
    const isTerminalConnected = useSelector(selectConnected)
    const managerCode         = useSelector(selectCode)
    const checkNumber         = useSelector(selectCheckNumber)
    const zipcode             = useSelector(selectZipCode)

    const [name, setName]                   = useState('')
    const [email, setEmail]                 = useState('')
    const [adyenCheckout, setAdyenCheckout] = useState(null)

    const submit = () => {
        if (paymentType === 'credit') { return }
        dispatch(submitPurchase(purchasePath, name, email, null, null, null, managerCode))
    }

    const submitTerminal = () => {
        /**
         * @TODO what we have to do here is figure out how to send a shared routine for sending the request amount -- I think we
         * need to get the information concerning the payment amount and possibly other information we need to send for the request?
         * I think ultimately it's just the credentials maybe? I'm really not sure what data we need to pass there to be honest...
         * I think step number absolute 1 is -- get amount we need to pay for and start from there?
         *
         * The terminal slice has some of those features and callbacks or whatever that we can deal with, with this callback being
         * what needs to happen when it's done and returns...
         *
         * In this case I think we would be fine with starting with the payment amount?
         */
        const amt = (Math.round(Number.parseFloat(paymentAmount) * 100)).toFixed(0)

        dispatch(submitTransaction(amt, transactionCallback))

        // dispatch(submitTransaction(amt, (payment) => {
        //     dispatch(submitPurchase(purchasePath, name, email, null, payment))
        //     // set the token in the purchase slice
        //     // @TODO: i think this will have to be abstracted once we put this into bookings, etc...
        //     // dispatch(setCreditToken(token))

        //     // submit the purchase -- can we get into a race condition here?
        //     // @TODO: i think this will have to be abstracted once we put this into bookings as well...
        //     // dispatch(submitPurchase(purchasePath, name, email, token))
        // }))
    }

    const transactionCallback = (payment, partialAmountCharged = null) => {
        dispatch(submitPurchase(purchasePath, name, email, payment?.cardToken, payment, partialAmountCharged, managerCode))
    }

    const isDisabled = useMemo(() => {
        let answer = false

        switch(true) {
            case !!error :
                answer = false
                break

          case isProcessingPayment :
                answer = true
                break

          case /^credit$/i.test(paymentType) && (name === '' || email === '' || zipcode === '') :
                answer = true
                break

          case /^cash$/i.test(paymentType) && (Number.parseFloat(paymentReceived || 0) <= 0 || changeDue < 0) :
                answer = true
                break

          case /^check$/i.test(paymentType) && checkNumber === '':
                answer = true
                break

          case Boolean(order) && (Number.parseFloat(order?.balance_cents || 0) / 100) === 0 :
                answer = true
                break

          case String(paymentAmount).startsWith('0') && !/\./.test(paymentAmount) :
                answer = true
                break

            case Number.parseFloat(paymentAmount || 0) <= 0 :
                answer = true
                break

            case order?.balance_cents && Number.parseFloat(paymentAmount || 0) > (Number.parseFloat(order?.balance_cents || 0) / 100) :
                answer = true
                break

            case Number.parseFloat(paymentAmount || 0) > Number.parseFloat(pricing?.total || 0) :
                answer = true
                break
        }

        return answer
    }, [
        isProcessingPayment,
        name,
        email,
        zipcode,
        checkNumber,
        paymentType,
        paymentAmount,
        paymentReceived,
        changeDue,
        pricing,
        order,
        error
    ])

    const handleCreditCardClick = () => {
        if (locationPaymentProcessor === 'paysafe') { return }
        adyenCheckout.submit()
    }

    const renderFinalizeButton = () => {
        switch(paymentType) {
            case 'credit': return (
                <button id='finish-button' className='btn btn-primary' disabled={isDisabled} onClick={handleCreditCardClick}>
                    Process Credit Payment
                </button>
            )

            case 'terminal': return (
                <div>
                    <button onClick={submitTerminal} className='btn btn-primary' disabled={isDisabled}>
                        Submit Terminal Transaction
                    </button>
                </div>
            )

            default: return (
                <div>
                    <button onClick={submit} className='btn btn-primary' disabled={isDisabled}>
                        Process Payment
                    </button>
                </div>
            )
        }
    }

    const renderTerminal = () => {
        if (locationPaymentProcessor === 'paysafe') { return (
            <ProductTerminal
                hardwareKey={hardwareKey}
                transactionCallback={transactionCallback}
            />
        )}

        if (locationPaymentProcessor === 'adyen') { return (
            <AdyenTerminalTransaction
                locationId={locationId}
                transactionCallback={transactionCallback}
            />
        )}
    }

    // nulling this out allows Adyen JS to re-instantiate
    // whenever the user switches payment methods/tabs
    useEffect(() => {
        if (paymentType !== 'credit' && !!adyenCheckout) {
            setAdyenCheckout(null)
        }
    }, [paymentType])

    return (
        <div>
            <p><b>Payment Information</b></p>

            <div>
                <div style={{ margin: '0 auto 1rem auto', backgroundColor: '#F6F6FA', width: '65%' }}>
                    <ProductPaymentType />
                </div>
            </div>

            {
                inBooking && (
                    <PaymentSelectWaiver
                        setNameCallback={name => setName(name)}
                        setEmailCallback={email => setEmail(email)} />
                )
            }

            { paymentType === 'terminal' && renderTerminal() }

            {
                (paymentType != 'terminal' || (paymentType === 'terminal' && isTerminalConnected)) && <>
                    <div className="form-row">
                        <div className="col-xl-6">
                            <div className="form-group">
                                <label>Name</label>
                                <input
                                    className='form-control'
                                    placeholder='John Doe'
                                    value={name}
                                    onChange={e => setName(e.target.value)} />
                            </div>
                        </div>

                        <div className="col-xl-6">
                            <div className="form-group">
                                <label>Email</label>
                                <input
                                    className='form-control'
                                    placeholder='customer@example.com'
                                    type='email'
                                    value={email}
                                    onChange={e => setEmail(e.target.value)} />
                            </div>
                        </div>
                    </div>

                    { paymentType === 'check' && <ProductCheck /> }
                    { paymentType === 'gift_card' && <ProductGiftCard /> }

                    { paymentType === 'credit' && (
                        <ProductCreditCard
                            purchasePath={purchasePath}
                            name={name}
                            email={email}
                            managerCode={managerCode}
                            locationId={locationId}
                            locationTimeZone={locationTimeZone}
                            locationPaymentProcessor={locationPaymentProcessor}
                            environment={environment}
                            adyenCheckout={adyenCheckout}
                            setAdyenCheckout={setAdyenCheckout}
                        />
                    )}

                    <div className="form-row">
                        <div className="col-12">
                            <ProductPaymentAmount />
                        </div>
                        { paymentType === 'cash' &&
                            <ProductCashPaymentAmountReceived/>
                        }
                    </div>

                    <div className="form-group border-top mt-3 pt-3 text-center">
                        {renderFinalizeButton()}
                    </div>
                </>
            }

            <PaymentProcessing />
        </div>
    )
}
