import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { selectBuyerInformation, setBuyerInformationField, selectSelectedType } from '../memberSlice'
import Checkbox from '../../../components/Form/Checkbox'
import Input from '../../../components/Form/Input'
import MemberEmail from '../MemberEmail'
import MemberStep from './MemberStep'
import MemberSteps from '../../../lib/Member'

export default function GiftedBuyerInformation() {

    const dispatch       = useDispatch()
    const membershipType = useSelector(selectSelectedType)
    const info           = useSelector(selectBuyerInformation)

    const [allChecked, setAllChecked]       = useState(false)
    const [confirmations, setConfirmations] = useState({})

    const handleChange = (name, value) => {
        dispatch(setBuyerInformationField({ name, value }))
    }

    const disabled = () => {
        return isEmpty(info.firstName) ||
               isEmpty(info.lastName)  ||
               isEmpty(info.email)     ||
               !allChecked
    }

    useEffect(() => {
        let confirmationOptions = {}
        if (!!membershipType.confirmation_checks) {
            membershipType.confirmation_checks.forEach(function (_conf, i) {
                confirmationOptions[`confirmation_checks_${i}`] = false
            });

            setConfirmations(confirmationOptions)
        }
    }, [membershipType])

    const handleConfirmationCheck = (e) => {
        setConfirmations({
            ...confirmations,
            [e.target.id]: e.target.checked
        })
    }

    useEffect(() => {
        const result = Object.values(confirmations).every(v => v);
        setAllChecked(result);
    }, [confirmations])

    return (
        <MemberStep
            backStep={MemberSteps.GIFTED_MEMBER_INFORMATION}
            nextStep={MemberSteps.PAYMENT_INFORMATION}
            disabled={disabled()}
        >

            <h2 className="text-center pt-md-4 pb-4">Your Information</h2>

            <h5 className='text-center font-weight-lighter pb-4'>
                Enter your information below. We'll use this to send you the
                membership certificate that you can give to the recipient.
            </h5>

            <div className="row">
                <Input
                    req={true}
                    type='text'
                    label='FIRST NAME'
                    name='firstName'
                    placeholder='First Name'
                    value={info.firstName}
                    className='form-control'
                    cols='12 col-md-6'
                    handleChange={e => handleChange('firstName', e.target.value)}
                />

                <Input
                    req={true}
                    type='text'
                    label='LAST NAME'
                    name='lastName'
                    placeholder='Last Name'
                    value={info.lastName}
                    className='form-control'
                    cols='12 col-md-6'
                    handleChange={e => handleChange('lastName', e.target.value)}
                />
            </div>

            <div className="row">
                <MemberEmail
                    ignoreConfirm={true}
                    ignoreUniqueness={true}
                    record={info}
                    onChange={(name, value) => handleChange(name, value)}
                />

                <Input
                    name='phone'
                    label='PHONE'
                    mask='999-999-9999'
                    value={info.phone}
                    cols='12 col-md-6'
                    handleChange={e => handleChange('phone', e.target.value)}
                />
            </div>

            { membershipType?.confirmation_checks &&
                <div className="mt-4 container form-container">
                    { membershipType.confirmation_checks.map((confirmation, index) => (
                        <div className="form-row mb-3" key={`confirmation_checks_${index}`}>
                            <div className="col">
                                <div className="custom-control custom-checkbox">
                                    <input
                                        type='checkbox'
                                        name={`confirmation_checks[${index}]`}
                                        id={`confirmation_checks_${index}`}
                                        className={`custom-control-input`}
                                        onChange={handleConfirmationCheck}
                                    />

                                    <label htmlFor={`confirmation_checks_${index}`} className='custom-control-label'>
                                        {confirmation.text} <span className="req">*</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            }

            <div className="row mt-4 pt-3">
                <Checkbox
                    cols='12'
                    value={info.newsletter}
                    label='Sign up for our newsletter for offers and events? Your data is safe with us. Pinky swear.'
                    handleChange={(_e) => handleChange('newsletter', !info.newsletter)}
                />
            </div>
        </MemberStep>
    )
}
