import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setOpen } from '../BookingPayment/bookingPaymentSlice'
import { selectBooking } from '@/features/EditBooking/editBookingSlice'
import { isBookingCancelled } from '@/lib/Booking'

export default function EditBookingPayment({ className='btn-primary' }) {

    const dispatch = useDispatch()
    const booking  = useSelector(selectBooking)

    const open = () => {
        dispatch(setOpen(true))
    }

    return (
        <button
            children='Reservation Payment'
            className={`btn btn-block ${className}`}
            disabled={isBookingCancelled(booking) || !!booking.check}
            onClick={open}
        />
    )
}
