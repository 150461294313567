import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectLocation, selectTimeZone } from '@/features/Locations/locationSlice'
import { selectSelectedResourceType } from '@/features/ResourceType/resourceTypeSlice'
import { selectPackage, selectPricingType } from '@/features/Packages/packageSlice'
import { selectCode, selectValid, clear } from '@/features/ManagerCodes/managerCodeSlice'
import { addAlert } from '@/features/Notifications/notificationSlice'
import AuthorizeManagerOverride from '@/features/ManagerCodes/AuthorizeManagerOverride'
import Tooltip from '@/components/Form/Tooltip'
import { useConfirm } from '@/lib/useConfirmHook'
import { isValidCode } from '@/lib/Package'
import { numberToCurrency } from '@/lib/Number'
import { UI } from '@/lib/Constants'

import {
  selectOpenModal,
  closeManagerOverrideModal,
  selectBooking,
  selectBookingId,
  selectPaymentsCount,
  selectBookingHasAddons,
  selectBookingTaxExemptStatus,
  setBookingTaxExemptStatus
} from "./editBookingSlice"

import {
    fetchDiscounts,
    selectDiscounts,
    submitDiscount,
    deleteDiscount,
    fetchBookingDiscount,
    selectBookingDiscount,
    setBookingDiscount
} from "../Discounts/discountSlice"

export default function ManagerOverrideModal() {
    const dispatch = useDispatch()

    const open               = useSelector(selectOpenModal)
    const location           = useSelector(selectLocation)
    const tz                 = useSelector(selectTimeZone)
    const code               = useSelector(selectCode)
    const valid              = useSelector(selectValid)
    const resourceType       = useSelector(selectSelectedResourceType)
    const pkg                = useSelector(selectPackage)
    const pricingType        = useSelector(selectPricingType)
    const discounts          = useSelector(selectDiscounts)
    const paymentsCount      = useSelector(selectPaymentsCount)
    const booking            = useSelector(selectBooking)
    const bookingId          = useSelector(selectBookingId)
    const bookingDiscount    = useSelector(selectBookingDiscount)
    const bookingTaxExempt   = useSelector(selectBookingTaxExemptStatus)
    const bookingHasAddons   = useSelector(selectBookingHasAddons)
    const bookingHasPayments = booking?.amount_paid_cents > 0 && paymentsCount > 0

    const [priceGroup, setPriceGroup]             = useState(null)
    const [selectedDiscount, setSelectedDiscount] = useState('')
    const [amountType, setAmountType]             = useState('')
    const [amount, setAmount]                     = useState('')
    const [flatRate, setFlatRate]                 = useState('')
    const [afterTax, setAfterTax]                 = useState(false)
    const [promoCode, setPromoCode]               = useState('')
    const [summary, setSummary]                   = useState(null)

    const { confirm } = useConfirm()

    useEffect(() => {
        if (!bookingDiscount) {
            return
        }

        setSelectedDiscount(bookingDiscount.discount_id || '')

        setAmount(bookingDiscount.amount      ? Number.parseFloat(bookingDiscount.amount).toFixed(2)    : '')
        setFlatRate(bookingDiscount.flat_rate ? Number.parseFloat(bookingDiscount.flat_rate).toFixed(2) : '')

        setPromoCode(bookingDiscount.promo_code || '')
        setAfterTax(bookingDiscount.after_tax)
        setAmountType(bookingDiscount.amount_type || '')
    }, [bookingDiscount])

    useEffect(() => {
        if (amount != '' && amountType == '') {
            setAmountType(/^pricing_by_customer_type$/i.test(pricingType))
        }
    }, [amount, bookingDiscount, pricingType])

    /**
     * Handle the initial mount and
     * unmount of the component
     */
    useEffect(() => {
        if (open) {
            document.addEventListener('keydown', handleKeyDown, false)
            document.body.classList.add(UI.classes.MULTIPLE_MODALS_OPEN)

            if (location?.id && discounts.length === 0) { dispatch(fetchDiscounts(location.id)) }
            if (bookingId) { dispatch(fetchBookingDiscount(bookingId)) }
        }

        // cleanup/reset on unmount
        return () => {
            document.body.classList.remove(UI.classes.MULTIPLE_MODALS_OPEN)
            document.removeEventListener('keydown', handleKeyDown, false)
            setSelectedDiscount('')
            setAmount('')
            setAmountType('')
            setFlatRate('')
            setPromoCode('')
            dispatch(setBookingDiscount(null))
            dispatch(clear())
        }
    }, [open, location, bookingId])

    /**
      * Construct summary text so that the user knows
      * what the form will generate in plain text
      */
    useEffect(() => {
        let discountPrepended = 'apply a'
        let discount          = null
        let discountAppended  = 'discount'
        let taxes             = `The reservation ${bookingTaxExempt ? 'will not be taxed' : "will be taxed according to this location's sales tax settings"}`
        let subject           = /^pricing_by_customer_type$/i.test(pricingType)
                                    ? (amountType == 'dollar' || priceGroup?.individualPricing === false)
                                        ? ' to the reservation subtotal'
                                        : ' per approved customer type'
                                    : ' to the reservation subtotal'

        switch(true) {
            case selectedDiscount != '' : {
                const d = discounts.filter((d) => d.id == selectedDiscount)
                if (d.length === 1) {
                    discount = d[0].amount_type == 'percent' ? `${d[0].amount}%` : `${numberToCurrency(d[0].amount)}`
                }
                break
            }

            case amount != '' && amountType != '' :
                discount = amountType == 'percent' ? `${amount}%` : numberToCurrency(amount)
                break

            case flatRate != '' :
                discount          = numberToCurrency(flatRate)
                discountPrepended = "change the reservation's price to be"
                discountAppended  = 'total'
                subject           = ''
                if (afterTax) { discountAppended += ' (after taxes and fees are calculated)' }
                break

            case promoCode != '' : {
                const promotion = isValidCode(booking?.participants, promoCode, booking?.start_time, tz, pkg, resourceType)

                if (promotion['isValid']) {
                    discount = promotion['promotion'].amount_type == 'percent' ? `${promotion['promotion'].amount}%` : `${numberToCurrency(promotion['promotion'].amount)}`
                } else {
                    discount = null
                }
                break
            }

            case bookingDiscount && !!bookingDiscount?.discount_record :
                discount = bookingDiscount.discount_record.amount_type == 'percent'
                    ? `${bookingDiscount.discount_record.amount}%`
                    : numberToCurrency(bookingDiscount.discount_record.amount)
                break
        }

        setSummary(!!discount ? `This will ${`${discountPrepended} <strong>${discount}</strong> ${discountAppended}` || 'AN UNKNOWN DISCOUNT'}${subject}.<br />${taxes}.` : null)
    }, [pkg, priceGroup, resourceType, discounts, amount, amountType, flatRate, afterTax, promoCode, selectedDiscount, bookingDiscount, bookingTaxExempt])

    /**
     * Clear things when they're no longer in use
     */
    useEffect(() => {
        if (amount == '' && flatRate == '' && promoCode == '' && selectedDiscount == '') {
            setSummary(null)
            setAmountType('')
            setAfterTax(false)
        }
    }, [amount, flatRate, promoCode, selectedDiscount])

    /**
      * find the general group for the booking that
      * we're using which contains pricing array
      */
    useEffect(() => {
        if (pkg && booking) {
            setPriceGroup(
                pkg?.pricing?.filter((pricingGroup) => {
                    return booking.participants >= Number.parseInt(pricingGroup.groupMin) && booking.participants <= Number.parseInt(pricingGroup.groupMax)
                })?.[0]
            )
        }
    }, [pkg, booking])

    const handleKeyDown = (e) => {
        if (
            e.key === 'Escape'
            && !document.body.classList.contains(UI.classes.CONFIRMATION_OPEN)
            && !document.body.classList.contains(UI.classes.HELPJUICE_OPEN)
        ) {
            dispatch(closeManagerOverrideModal())
        }
    }

    const setDiscountOption = (e) => {
        setSelectedDiscount(!!e.target.value ? Number.parseInt(e.target.value) : "")
        setAmount("")
        setAmountType("")
        setFlatRate("")
        setPromoCode("")
    }

    const setCustomAmount = (e) => {
        setAmount(!!e.target.value ? Number.parseFloat(e.target.value) : "")
        setSelectedDiscount("")
        setFlatRate("")
        setPromoCode("")
    }

    const setCustomFlatRateAmount = (e) => {
        setFlatRate(!!e.target.value ? Number.parseFloat(e.target.value) : "")
        setSelectedDiscount("")
        setAmount("")
        setAmountType("")
        setPromoCode("")
    }

    const setCustomPromoCode = (e) => {
        setPromoCode(e.target.value)
        setFlatRate("")
        setSelectedDiscount("")
        setAmount("")
        setAmountType("")
    }

    const handleSubmit = () => {
        if (!selectedDiscount && (amount == '' || parseFloat(amount) == 0) && (flatRate == '' || parseFloat(flatRate) == 0) && !promoCode) {
            dispatch(addAlert({ type: 'warning', text: 'A price adjustment is not possible when a discount, promo code, or amount is not present.' }))

        } else if (!isNaN(parseFloat(amount)) && parseFloat(amount) == 0) {
            dispatch(addAlert({ type: 'warning', text: 'The amount must be greater than 0.' }))

        } else if (selectedDiscount == '' && promoCode == '' && flatRate == '' && amountType == '') {
            dispatch(addAlert({ type: 'warning', text: 'You must select an amount type.' }))

        } else if (!isNaN(parseFloat(flatRate)) && parseFloat(flatRate) == 0) {
            dispatch(addAlert({ type: 'warning', text: 'The flat rate must be greater than 0.' }))

        } else {
            dispatch(submitDiscount(bookingId, code, selectedDiscount, amountType, amount, flatRate, afterTax, bookingTaxExempt, promoCode))
        }
    }

    /**
     * When we go to remove the booking override from a booking we reset its state
     * to its default values.
     */
    const handleRemove = async () => {
        if (!await confirm(`Are you sure you want to<br />clear ${/^(membership discount)$/i.test(bookingDiscount.name) ? 'membership discounts?' : 'this override?'}`)) {
            return
        }

        dispatch(deleteDiscount(bookingId))

        setSelectedDiscount("")
        setAmountType("")
        setAmount("")
        setFlatRate("")
        setAfterTax(false)
        setPromoCode("")
        setSummary(null)

        dispatch(setBookingDiscount(null))
    }

    return !open ? null : (
        <div className={`modal modal-backdrop modal--${valid ? /^(membership discount)$/i.test(bookingDiscount?.name) ? 'md' : 'sm' : 'xxs'}`}>
            <div className="modal-container manager-override">
                <div className="modal-header">
                    <div className="modal-title">Manager Override</div>

                    <button className="modal-close" onClick={() => dispatch(closeManagerOverrideModal())}>
                        <span>Close</span>
                        <i className="far fa-times"></i>
                    </button>
                </div>

                { !valid &&
                    <div className="modal-body">
                        <div className="modal-body-main">
                            <div className="modal-body-main-section">
                                <AuthorizeManagerOverride locationId={location.id} />
                            </div>
                        </div>
                    </div>
                }

                { valid && <>
                    <div className="modal-body">
                        <div className="modal-body-main">
                            <div className="modal-body-main-section">
                                {
                                    !bookingDiscount && promoCode == '' && amount == '' && flatRate == '' && (
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label>Manager Discounts</label>
                                                    <select className="custom-select" value={selectedDiscount} onChange={setDiscountOption} autoFocus={true}>
                                                        <option value="">
                                                            ————————
                                                        </option>
                                                        {discounts.map((d) => (
                                                            <option key={d.id} value={d.id}>
                                                                {d.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                                {
                                    !bookingDiscount && promoCode == '' && selectedDiscount == '' && <>
                                        <div className="row">
                                            {
                                                (selectedDiscount == '' && flatRate == '') && (
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label>Discount by amount</label>
                                                            <div className="d-flex">
                                                                <label className="radio-as-card radio-card-inline mr-2 mb-0">
                                                                    <input
                                                                        type="radio"
                                                                        name="amount_type"
                                                                        value="percent"
                                                                        checked={amountType === "percent"}
                                                                        onChange={() => setAmountType("percent")}
                                                                    />
                                                                    <div className="radio-card">%</div>
                                                                </label>

                                                                <label className="radio-as-card radio-card-inline mr-2 mb-0">
                                                                    <input
                                                                        type="radio"
                                                                        name="amount_type"
                                                                        value="dollar"
                                                                        checked={amountType === "dollar"}
                                                                        onChange={() => setAmountType("dollar")}
                                                                    />
                                                                    <div className="radio-card">$</div>
                                                                </label>

                                                                <div className="ml-1 input-group">
                                                                    <input
                                                                        type="number"
                                                                        step={1}
                                                                        className="form-control"
                                                                        placeholder="Discount Amount"
                                                                        value={amount}
                                                                        onChange={setCustomAmount}
                                                                    />

                                                                    {
                                                                        amount != '' && !bookingDiscount && (
                                                                            <div className="input-group-append">
                                                                                <button type='button' className="btn btn-secondary text-white" onClick={() => setAmount('')}>
                                                                                    CLEAR
                                                                                </button>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>

                                        {
                                            (selectedDiscount == '' && amount == '') && (
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label>Set A Flat Rate</label>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text cursor-default">$</span>
                                                                </div>

                                                                <input
                                                                    className="form-control"
                                                                    type="number"
                                                                    step={1}
                                                                    placeholder="Flat Rate Amount"
                                                                    value={flatRate}
                                                                    onChange={setCustomFlatRateAmount}
                                                                />

                                                                {
                                                                    flatRate != '' && !bookingDiscount && (
                                                                        <div className="input-group-append">
                                                                            <button className="btn btn-secondary text-white" onClick={() => setFlatRate('')}>
                                                                                CLEAR
                                                                            </button>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 text-right">
                                                        <div className="custom-control custom-checkbox">
                                                            <input
                                                                type="checkbox"
                                                                id="afterTax"
                                                                name="afterTax"
                                                                className="custom-control-input"
                                                                checked={afterTax}
                                                                disabled={!flatRate}
                                                                onChange={() => setAfterTax(!afterTax)}
                                                            />
                                                            <label className="custom-control-label" htmlFor="afterTax">
                                                                Apply flat rate after taxes &amp; fees
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </>
                                }

                                {
                                    bookingDiscount && (
                                        <div className="row">
                                            <div className="col-12">
                                                {
                                                    /^(membership discount)$/i.test(bookingDiscount?.name) ? (
                                                        <div className="alert alert-warning rounded" role="alert">
                                                            <h5 className="mb-4">This Reservation Has One or More Members</h5>
                                                            <p>Member Benefits and Manager Overrides may not be combined.</p>
                                                            <p>In order to apply a Manager Override you must first remove the Member ID(s) from this reservation. After the Member ID(s) are removed, the Members Allowance Hours will be returned, Guests of Members Discounts will be removed, and you then be able to apply a Manager Override.</p>
                                                        </div>
                                                    ) : <>
                                                        <div className="alert alert-secondary rounded text-center" role="alert">
                                                            <h5 className="mb-4">This Reservation Has An Override Applied</h5>
                                                            {
                                                                !!flatRate && (
                                                                    <h5 className='mb-0'>{ numberToCurrency(flatRate) } Flat Rate</h5>
                                                                )
                                                            }
                                                            {
                                                                !!amount && (
                                                                    <h5 className='mb-0'>{ amountType == 'percent' ? `${amount}%` : numberToCurrency(amount) } Off</h5>
                                                                )
                                                            }
                                                            {
                                                                !!bookingDiscount.discount_record && amount == '' && flatRate == '' && (
                                                                    <h5 className='mb-0'>{ bookingDiscount.discount_record.amount_type == 'percent' ? `${bookingDiscount.discount_record.amount}%` : numberToCurrency(bookingDiscount.discount_record.amount) } Off</h5>
                                                                )
                                                            }
                                                        </div>
                                                        <div className="text-center">
                                                            <button
                                                                type='button'
                                                                children='Clear Override'
                                                                className='btn btn-outline-danger mt-4 mb-2'
                                                                onClick={handleRemove}
                                                            />
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    {
                        !bookingDiscount && selectedDiscount == '' && amount == '' && flatRate == '' && (
                            <div className={`modal-footer ${promoCode != '' ? 'pt-0 border-top-0' : ''}`}>
                                <div className="row w-100 text-left">
                                    <div className="col px-0">
                                        <div className="form-group pb-2">
                                            <label>Promo Code</label>

                                            <div className="input-group">
                                                <input
                                                    className="form-control text-monospace text-uppercase"
                                                    label="Promo Code"
                                                    placeholder="Code"
                                                    value={promoCode}
                                                    onChange={setCustomPromoCode}
                                                />
                                                {
                                                    promoCode != '' && !bookingDiscount && (
                                                        <div className="input-group-append">
                                                            <button className="btn btn-secondary text-white" onClick={() => setPromoCode('')}>
                                                                CLEAR
                                                            </button>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    {
                        !bookingDiscount && (
                            <div className="modal-footer">
                                <div className="row w-100 text-left">
                                    <div className="col px-0">
                                        <div className="custom-control custom-checkbox">
                                            <input
                                                type="checkbox"
                                                id="noTax"
                                                name="amountType"
                                                className="custom-control-input"
                                                checked={bookingTaxExempt}
                                                disabled={bookingHasPayments || bookingHasAddons}
                                                onChange={() => dispatch(setBookingTaxExemptStatus(!bookingTaxExempt))}
                                            />
                                            <label className="custom-control-label" htmlFor="noTax">
                                                Designate Reservation As Tax Exempt

                                              {
                                                  (bookingHasPayments || bookingHasAddons) &&
                                                      <Tooltip id='tax_exemption' place='right'>
                                                        Tax exemption status cannot be changed for this booking because&nbsp;
                                                        { bookingHasPayments ? 'payments have already been made' : '' }
                                                        { bookingHasPayments && bookingHasAddons ? ' and ' : '' }
                                                        { bookingHasAddons ? 'it has additional time added' : '' }.
                                                      </Tooltip>
                                              }
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )
                    }
                </>}

                {
                    // THE MODAL FOOTER ALSO NEED TO APPEAR ON SUCCESSFUL MANAGER CODE ENTRY
                    !bookingDiscount && valid && (
                        <div className="modal-footer">
                            <div className="row w-100">
                                <button className="btn btn-primary ml-auto mr-auto" onClick={handleSubmit}>
                                    <i className="fas fa-money-check-edit-alt mr-2" />
                                    Authorize Price Adjustment
                                </button>
                            </div>

                            {
                                summary !== null && (
                                    <div className="row w-100 text-center pt-1">
                                        <div className="col px-0">
                                            <small className="text-muted font-italic" dangerouslySetInnerHTML={{ __html: summary }} />
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    )
                }
            </div>
        </div>
    )
}
