import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectGroups } from '@/features/Packages/packageSlice'

import {
    selectPricingType,
    selectBooking,
    selectMemberCount as selectEditMemberCount,
} from '@/features/EditBooking/editBookingSlice'

import {
    selectStep,
    setMember,
    selectMembers,
    selectMemberCount,
} from '@/features/Schedule/scheduleSlice'

import Select from '@/components/Form/Select'
import MemberInput from './MemberInput'
import { isBookingCancelled, isBookingComplete, participantMaxCount } from '@/lib/Booking'
import { checkBookingParticipantChangeWhileSplitWarning } from '@/features/AdvancedPointOfSale/lib/Checks'
import { useConfirm } from '@/lib/useConfirmHook'

export default function BookingGroupSize({
    pkg,
    participants,
    groupMin=null,
    groupMax=null,
    errors,
    originalGroupSize=null,
    setOriginalGroupSize=() => {},
    setTriggerGroupLoad,
    triggerGroupLoad,
    isBookingQuote=false,
    onChange,
    onMemberChange,
    onMemberRemove,
    onHideCustomerTypes=() => {}
}) {
    if (!pkg) { return null }

    const dispatch           = useDispatch()
    const { confirm }        = useConfirm()
    const booking            = useSelector(selectBooking)
    const step               = useSelector(selectStep)
    const bookingPricingType = useSelector(selectPricingType)
    const groups             = useSelector(selectGroups)
    const members            = useSelector(selectMembers)
    const memberCount        = useSelector(selectMemberCount)
    const editMemberCount    = useSelector(selectEditMemberCount)

    const [sortedGroupOptions, setSortedGroupOptions] = useState([])
    const [dense, setDense]                           = useState(false)
    const [error, setError]                           = useState(null)

    // reformat the booking's raw participant value to be compatible
    // with either single integers or group ranges on every render cycle
    const value = groupMin !== null && groupMax !== null
        ? `${groupMin}-${groupMax}`
        : participants

    const confirmation = () => confirm(checkBookingParticipantChangeWhileSplitWarning, {
        header_text: 'Warning!',
        dangerous: true,
        size: 'md',
        confirm_text: 'OK',
        cancel_text: 'CANCEL'
    })

    const handleChange = async (e) => {
        if (window.__temp === undefined) { window.__temp = e.target.value }

        const execute = (value) => {
            triggerInterfaceChanges()
            onChange(value)

            if (pkg.allow_members_only) {
                onMemberChange(parseInt(value))
            }

            window.setTimeout(() => {
                document.getElementById('booking-group-size')?.scrollIntoView({ inline: 'end' })
            }, 300)

            delete window.__temp
        }

        if (booking?.check?.has_booking_been_split) {
            if (await confirmation()) {
                execute(window.__temp)
            }
        } else {
            execute(window.__temp)
        }
    }

    const handleMemberQuantityChange = async (value) => {
        const execute = (value) => {
            onMemberChange(value)
            window.setTimeout(() => {
                document.getElementById('booking-group-size')?.scrollIntoView({ inline: 'end' })
            }, 300)
        }

        if (booking?.check?.has_booking_been_split) {
            if (await confirmation()) {
                execute(value)
            }
        } else {
            execute(value)
        }
    }

    const handleMemberInputChange = async (index, value) => {
        if (booking?.check?.has_booking_been_split) {
            if (await confirmation()) {
                dispatch(setMember({ index, value }))
            }
        } else {
            dispatch(setMember({ index, value }))
        }
    }

    const handleMemberInputRemove = async (index) => {
        if (booking?.check?.has_booking_been_split) {
            if (await confirmation()) {
                onMemberRemove(index)
            }
        } else {
            onMemberRemove(index)
        }
    }

    /**
     * We have to use this hacky way of determining the "value"
     * of the selected option because we need to change the UI
     * if a range is selected vs a singular integer. HOWEVER,
     * because the actual value of the option could be an integer
     * OR a string (ex: "13-18"), we have to rely on testing against
     * the TEXT of the option as that is always consistent.
     */
    const triggerInterfaceChanges = () => {
        // short-circuit any UI changes when a booking is using
        // uniform pricing and always render the dense mode of
        // the UI because uniform_pricing does not need to
        // display the customer type UI/fields
        if (bookingPricingType === 'uniform_pricing') {
            setDense(true)
            return
        }

        const menu  = document.getElementById('participants-menu')
        const value = menu.selectedOptions?.[0]?.text

        // check if group range pricing is chosen by testing
        // if the text (i.e., the "value") contains a dash
        if (/(-){1}/.test(value)) {
            onHideCustomerTypes(true)
            setDense(true)
        } else {
            onHideCustomerTypes(false)
            setDense(false)
        }
    }

    /**
    * Build out the option elements for the select menu
    */
    const setGroupOptions = () => {
        if (!groups || groups.length === 0) { return }

        let options = []

        groups.forEach(group => {
            const min = Number.parseInt(group.min, 10)
            const max = participantMaxCount(pkg, group)

            switch(true) {
            // examples:
            //   different min/max  <option value="13-18">13 - 18</option>
            //   same min/max       <option value="5-5">5</option>
            case group.price_type === 'group_price' : {
                if (max >= min) {
                    options.push({
                    value: `${min}-${max}`,
                    display: (min == max) ? max : `${min} - ${max}`
                    })
                }
                break
            }

            // example:
            // <option value="5">5</option>
            default : {
                for (let i = min; i <= max; ++i) {
                options.push({ value: i, display: i })
                }
            }
            }
        })

        // sorted ASC
        if (options.length === 0) {
            setError(/1/.test(step) ? 'This package is sold out!' : 'Group size modifications are no longer possible.')
        } else {
            setSortedGroupOptions(Object.values(options).sort((a,b) => parseInt(a.value, 10) - parseInt(b.value, 10)))
            setError(null)
        }
        setTriggerGroupLoad(false)
    }

    /**
     * Build out the option elements for the select menu
     */
    useEffect(() => {
        setGroupOptions()
        setOriginalGroupSize(value)
        document.getElementById('booking-group-size')?.scrollIntoView()
    }, [])

    useEffect(() => {
      setGroupOptions()
    }, [triggerGroupLoad === true])

    useEffect(() => {
        triggerInterfaceChanges()
    }, [sortedGroupOptions])

    return <>
        <div id='booking-group-size' className={`inline-section ${dense ? 'border-bottom-0 mb-n4' : /1/.test(step) ? 'border-0 my-2' : ''} ${!!originalGroupSize ? 'd-block' : 'd-flex'}`}>
            {
                error ? (
                    <div className="alert alert-danger w-100 text-center rounded">{ error }</div>
                ) : <>
                    {
                        !!originalGroupSize && (
                            <div className='w-100 mt-1 mb-3'>
                                <label>Current Group Size</label>
                                <strong className='pl-3 ml-1'>{originalGroupSize}</strong>
                            </div>
                        )
                    }

                    <div className='d-flex'>
                        <div className='d-flex align-items-center w-100 mr-5'>
                            <label>
                                { !!originalGroupSize ? 'Change Group Size?' : 'Group Size' }
                            </label>

                            <Select
                                id='participants-menu'
                                inputGroupClassName='d-inline'
                                value={value || ''}
                                options={sortedGroupOptions}
                                errors={errors}
                                disabled={isBookingCancelled(booking) || isBookingComplete(booking) || booking?.check?.has_adv_pos_booking_payment_been_received}
                                validation={{ 'participants': { required: true } }}
                                withWrapper={false}
                                hideLabel
                                includeBlank='Please choose...'
                                handleChange={handleChange}
                            />
                        </div>

                        {
                            pkg.allow_member_benefits
                            && !pkg.allow_members_only
                            && groupMin === null
                            && groupMax === null
                            && participants > 0
                            && pkg.pricing_type === 'uniform_pricing'
                            && !isBookingQuote
                            && (
                                <div className='w-100 ml-2 mt-1'>
                                    <div className='form-group'>
                                        <label style={{ fontSize: "0.6875rem" }} className='mt-n1 mb-1'>How many are members?</label>
                                        <select
                                            className='custom-select'
                                            value={!!originalGroupSize ? parseInt(editMemberCount) : memberCount}
                                            disabled={isBookingCancelled(booking) || isBookingComplete(booking) || booking?.check?.has_adv_pos_booking_payment_been_received}
                                            onChange={(e) => handleMemberQuantityChange(e.target.value)}
                                        >
                                            {[...Array(parseInt(participants) + 1).keys()].map(option => (
                                                <option key={option} value={option}>{option}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </>
            }
        </div>

        { parseInt(memberCount) > 0 && step === '1' && pkg.pricing_type === 'uniform_pricing' &&
            [...Array(parseInt(memberCount)).keys()].map(index => (
                <MemberInput
                    key={index}
                    index={index}
                    pkg={pkg}
                    member={members[index]}
                    errors={errors}
                    disabled={booking?.check?.has_adv_pos_booking_payment_been_received}
                    onChange={(e) => handleMemberInputChange(index, e.target.value)}
                    onRemove={() => handleMemberInputRemove(index)}
                />
            ))
        }
    </>
}
