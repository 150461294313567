import React, { useEffect, useState } from 'react'

// https://docs.fontawesome.com/web/style/animate

export default function Incrementer({
    min=0,
    max=null,
    value=0,
    disableDecrement=false,
    disableIncrement=false,
    preventTyping=false,
    className='',
    animateFeedback=true,
    feedbackDurationMilliseconds=150,
    decreaseClassName='',
    increaseClassName='',
    onIncrease=() => {},
    onDecrease=() => {},
    onChange=() => {},
}) {
    const [loaded, setLoaded]   = useState(false)
    const [trigger, setTrigger] = useState(false)

    useEffect(() => {
        setLoaded(true)
    }, [])

    useEffect(() => {
        if (animateFeedback && loaded) {
            setTrigger(true)
        }
    }, [value])

    useEffect(() => {
        if (animateFeedback && loaded && trigger === true) {
            window.setTimeout(() => setTrigger(false), feedbackDurationMilliseconds)
        }
    }, [trigger])

    return <span className={`increment-field d-flex align-items-center ${className}`.trim()}>
        <button
            type='button'
            className={`btn pressable p-0 input-group-prepend ${decreaseClassName}`.trim()}
            disabled={disableDecrement}
            onClick={() => onDecrease(value)}
        >
            <div className='input-group-text d-flex justify-content-center align-items-center'>
                <i className='fa-regular fa-minus' />
            </div>
        </button>

        <input
            type='number'
            className={`text-center mx-3 ${animateFeedback && trigger === true ? 'fa-beat' : ''}`}
            style={{
                '--fa-animation-delay': 0,
                '--fa-animation-duration': `${feedbackDurationMilliseconds}ms`,
                '--fa-animation-iteration-count': 1,
                '--fa-beat-scale': 1.2,
            }}
            min={min}
            max={max}
            value={value}
            readOnly={preventTyping}
            onChange={(e) => onChange(parseInt(e.target.value))}
        />

        <button
            type='button'
            className={`btn pressable p-0 input-group-append ${increaseClassName}`.trim()}
            disabled={disableIncrement}
            onClick={() => onIncrease(value)}
        >
            <div className='input-group-text d-flex justify-content-center align-items-center'>
                <i className='fa-regular fa-plus' />
            </div>
        </button>
    </span>
}
