import React, { useEffect, useCallback } from 'react'
import moment from 'moment'
import { useFormContext } from 'react-hook-form'
import DatePicker from '../Form/DatePicker'
import FormRow from '../Form/FormRow'
import Checkbox from "../Form/Checkbox"
import PackageDaySelect from './PackageDaySelect'
import NumberIncrementer from "../Form/NumberIncrementer"
import CustomTimeRanges from "./CustomTimeRanges"
import SpecificStartTimes from "./SpecificStartTimes"
import DateExclusions from "./DateExclusions"
import Help from '@/features/Help/Help'

export default function RecurringPackageFields({
    form,
    errors,
    location,
    maxBookableFutureMonths,
    isSpecialEvent=false,
    handleUpdate,
    handleManualUpdate,
    handleCheckboxUpdate,
}) {

    const { trigger } = useFormContext()

    const daysClosed = location ? [
        location.sunday_closed,
        location.monday_closed,
        location.tuesday_closed,
        location.wednesday_closed,
        location.thursday_closed,
        location.friday_closed,
        location.saturday_closed
    ] : []

    const maxRepetition = useCallback(() => {
        let max

        if (/day/i.test(form.repeats_every_type)) {
            max = Math.ceil(moment.duration(maxBookableFutureMonths, 'months').asDays())
        }

        if (/week/i.test(form.repeats_every_type || 'week')) {
            max = Math.ceil(moment.duration(maxBookableFutureMonths, 'months').asWeeks())
        }

        if (/month/i.test(form.repeats_every_type)) {
            max = maxBookableFutureMonths
        }

        return max
    }, [form.repeats_every_type])

    useEffect(() => {
        trigger()
    }, [form.time_increment_type])

    return (
        <div className='recurring-fields'>
            <FormRow>
                {
                    /*
                    * @todo It turns out, these are not wired up at all! Hide them for now.
                    *
                    isSpecialEvent && <>
                        <Input
                            cols='3'
                            type='number'
                            label='Repeats Every'
                            step={1}
                            min={1}
                            max={maxRepetition()}
                            value={form.repeats_every_number || 1}
                            errors={errors}
                            validation={{ 'repeats_every_number': {
                              validate: (v) => isSpecialEvent
                                ? (parseInt(v) <= maxRepetition()) || `Must be less than or equal to ${maxRepetition()}`
                                : true
                            }}}
                            handleChange={handleUpdate}
                        />
                        <Select
                            cols='3'
                            label='Frequency'
                            value={form.repeats_every_type || 'week'}
                            options={[
                                { value: 'day',   display: (form.repeats_every_number || 1) == 1 ? 'Day'   : 'Days'   },
                                { value: 'week',  display: (form.repeats_every_number || 1) == 1 ? 'Week'  : 'Weeks'  },
                                { value: 'month', display: (form.repeats_every_number || 1) == 1 ? 'Month' : 'Months' },
                            ]}
                            errors={errors}
                            validation={{ 'repeats_every_type': { required: isSpecialEvent }}}
                            handleChange={handleUpdate}
                        />
                    </>
                    */
                }
                <DatePicker
                    label={isSpecialEvent ? 'Starts Repeating On' : 'Bookable Starting On'}
                    cols={6 /*isSpecialEvent ? 3 : 6*/}
                    value={form.repeats_beginning_at}
                    errors={errors}
                    validation={{ 'repeats_beginning_at': { required: true }}}
                    handleChange={(_, date) => handleManualUpdate('repeats_beginning_at', moment(date).format('YYYY-MM-DD') )}
                />
                <DatePicker
                    label={isSpecialEvent ? 'Ends Repeating On' : 'Bookable Ending On'}
                    name='repeats_ending_at'
                    value={form.repeats_ending_at}
                    placeholder={isSpecialEvent ? '' : 'Optional'}
                    tooltip={isSpecialEvent ? '' : 'Leaving this field blank indicates an indefinite run time.'}
                    cols={6 /*isSpecialEvent ? 3 : 6*/}
                    clearable={true}
                    errors={errors}
                    validation={isSpecialEvent ? { 'repeats_ending_at': { required: true }} : null }
                    handleChange={(_, date) => handleManualUpdate('repeats_ending_at', moment(date).format('YYYY-MM-DD') )}
                />
            </FormRow>

            {
                form.override_public_store_hours && (
                    <PackageDaySelect
                        name='repeats_days'
                        values={form.repeats_days || []}
                        errors={errors}
                        daysClosed={daysClosed}
                        overridingPublicStoreHours={form.override_public_store_hours}
                        handleUpdate={handleManualUpdate}
                    />
                )
            }

            <FormRow>
                <div className="col-6 mt-1">
                    <div className='d-flex mt-2'>
                        <Checkbox
                            label='Override Location Days of the Week?'
                            name='override_public_store_hours'
                            handleChange={handleCheckboxUpdate}
                            value={form.override_public_store_hours}
                        />
                        <Help articleId='154000158969-override-location-days-of-the-week' />
                    </div>
                </div>
            </FormRow>

            <FormRow>
                <div className="col-12">
                    <h4>Start Times</h4>

                    <FormRow>
                        <div className="col-4">
                            <div className='form-group'>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input
                                        id='time_increment_type_open'
                                        type='radio'
                                        name='time_increment_type'
                                        value='incremental'
                                        className='custom-control-input'
                                        checked={form.time_increment_type === 'incremental'}
                                        disabled={isSpecialEvent}
                                        onChange={handleUpdate}
                                    />

                                    <label className="custom-control-label" htmlFor='time_increment_type_open'>Location Open Hours</label>
                                    <Help articleId='154000158970-start-times-location-open-hours' />
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input
                                        id='time_increment_type_custom_range'
                                        type='radio'
                                        name='time_increment_type'
                                        value='custom_range'
                                        className='custom-control-input'
                                        checked={form.time_increment_type === 'custom_range'}
                                        disabled={isSpecialEvent}
                                        onChange={handleUpdate}
                                    />

                                    <label className="custom-control-label" htmlFor='time_increment_type_custom_range'>Custom Open Hours</label>
                                    <Help articleId='154000158971-start-times-custom-open-hours' />
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input
                                        id='time_increment_type_specific'
                                        type='radio'
                                        name='time_increment_type'
                                        value='specific'
                                        className='custom-control-input'
                                        checked={form.time_increment_type === 'specific'}
                                        onChange={handleUpdate}
                                    />

                                    <label className="custom-control-label" htmlFor='time_increment_type_specific'>Specific Start Times</label>
                                    <Help articleId='154000158972-start-times-specific-start-times' />
                                </div>
                            </div>
                        </div>
                    </FormRow>
                </div>
            </FormRow>

            {
                /^(custom_range|specific)$/i.test(form.time_increment_type) && !isSpecialEvent && (
                    <FormRow>
                        <div className="col-12 border-top pt-3 text-center">
                            <Checkbox
                                label='Override ALL Hours on the Location and/or Resource?'
                                name='force_override_location_and_resource_hours'
                                value={form.force_override_location_and_resource_hours}
                                handleChange={handleCheckboxUpdate}
                            />
                        </div>
                    </FormRow>
                )
            }

            {
                /^custom_range/i.test(form.time_increment_type) && (
                    <CustomTimeRanges
                        form={form}
                        errors={errors}
                        times={form.specific_time_block || []}
                        timezone={location.time_zone}
                        daysClosed={daysClosed}
                        handleManualUpdate={handleManualUpdate}
                    />
                )
            }

            {
                /^specific/i.test(form.time_increment_type) && (
                    <SpecificStartTimes
                        form={form}
                        errors={errors}
                        times={form.specific_time_block || []}
                        timezone={location.time_zone}
                        daysClosed={daysClosed}
                        handleManualUpdate={handleManualUpdate}
                    />
                )
            }

            {
                /^incremental|custom_range/i.test(form.time_increment_type) && (
                    <FormRow>
                        <div className="col-12">
                            <h4 className="d-flex justify-content-between align-items-center">
                                <span className='d-flex'>
                                    <span>Increment Start Times By<sup className="ml-1">(minutes)</sup></span>
                                    <Help articleId='154000158973-start-time-increments' />
                                </span>
                            </h4>

                            <div className="w-25">
                                <NumberIncrementer
                                    min={15}
                                    max={60}
                                    incrementAmount={15}
                                    value={form.time_increment_amount || 15}
                                    preventTyping={true}
                                    handleChange={amount => handleManualUpdate('time_increment_amount', amount)}
                                />
                            </div>
                        </div>
                    </FormRow>
                )
            }

            <DateExclusions
                errors={errors}
                exclusions={form.date_exclusions || []}
                handleUpdate={handleManualUpdate}
            />
        </div>
    )
}
