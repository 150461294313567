import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import LedgerCustomerTypes from './LedgerCustomerTypes'
import LedgerPrices from './LedgerPrices'
import LedgerDiscounts from './LedgerDiscounts'
import { selectLocation } from '@/features/Locations/locationSlice'
import { selectResourceType } from '@/features/ResourceType/resourceTypeSlice'
import { selectPackage } from '@/features/Packages/packageSlice'
import { selectBooking, selectParticipants, selectCustomerTypeCounts } from '@/features/Bookings/bookingSlice'
import { selectCustomerTypes } from '@/features/CustomerTypes/customerTypeSlice'
import { evaluateParticipantPricingByGroupType, calculateMemberPrice } from '@/lib/Pricing'

export default function MobileBookingModalLedger() {

    const location           = useSelector(selectLocation)
    const resourceType       = useSelector(selectResourceType)
    const booking            = useSelector(selectBooking)
    const pakage             = useSelector(selectPackage)
    const participants       = useSelector(selectParticipants)
    const customerTypeCounts = useSelector(selectCustomerTypeCounts)
    const customerTypes      = useSelector(selectCustomerTypes)

    const parsedParticipantCounts = useMemo(() => (
        evaluateParticipantPricingByGroupType(
            pakage,
            {
              ...booking,
              start_time: moment.tz(booking.time, location.time_zone),
              weekday: moment.tz(booking.date, location.time_zone).format('dddd').toLowerCase()
            },
            customerTypeCounts,
            customerTypes,
            location.tax_rate,
            location.rkd_reservation_fee,
            location.merchant_fee,
            location.third_party_fee,
            location,
            participants,
            resourceType
        )
    ), [pakage, customerTypeCounts, customerTypes, location, participants, resourceType])

    const memberPrice = useMemo(() => (
        calculateMemberPrice(
            pakage,
            {
                ...booking,
                start_time: moment.tz(booking.time, location.time_zone),
                weekday: moment.tz(booking.date, location.time_zone).format('dddd')
            },
            customerTypeCounts,
            location,
            resourceType
        )
    ), [pakage, booking, customerTypeCounts, location, resourceType])

    return (
        <div className="xbm-checkout-ledger d-block d-md-none">
            <LedgerCustomerTypes customerTypeTotals={parsedParticipantCounts} memberPrice={memberPrice} />
            <LedgerDiscounts customerTypeTotals={parsedParticipantCounts} initiallyFolded={true} />
            <LedgerPrices />
        </div>
    )
}
