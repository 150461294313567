import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectPackage } from "../../../Packages/packageSlice"
import {
  selectReminderAcknowledged,
  selectParticipants,
  setErrors,
  setStep,
  selectDuration,
  selectDate,
  selectTime,
  setShowReminder,
  trackAbandonedBooking,
} from '../../bookingSlice'
import BookingModalFooter from '../BookingModalFooter'
import BookingModalLedger from '../Ledger/BookingModalLedger'
import PartySizeMessage from './PartySizeMessage'
//import PartySizeSlider from './PartySizeSlider'
//import PartySizeInputMobile from './PartySizeInputMobile'
import PartySizeInput from './PartySizeInput'
import { PACKAGE_GROUP_STEP0 } from '../../../../lib/Storage'
import { gaSendPageView } from '@/lib/GoogleAnalytics'

export default function Step1({ modalStyles, onNext=() =>  {} }) {

    const dispatch             = useDispatch()
    const pkg                  = useSelector(selectPackage)
    const participants         = useSelector(selectParticipants)
    const duration             = useSelector(selectDuration)
    const date                 = useSelector(selectDate)
    const time                 = useSelector(selectTime)
    const reminderAcknowledged = useSelector(selectReminderAcknowledged)

    const [initialLoad, setInitialLoad] = useState(true)
    const [partySize, setPartySize]     = useState(null)
    const [step0Url, setStep0Url]       = useState(null)

    const handlePartySizeChange = (size) => {
        setPartySize(size)
        dispatch(setErrors({ type: 'clear' }))
    }

    const handleNext = () => {
        dispatch(setErrors({ type: 'clear' }))

        /**
         * If a reminder acknowledgement is required, it will be TRUE or FALSE.
         * Otherwise, we can consider NULL to also be "truthy"
         */
        if (duration && date && time && reminderAcknowledged === false) {
            dispatch(setShowReminder(true))
        }

        if (reminderAcknowledged === true || reminderAcknowledged === null) {
            finalizeStep()
        }
    }

    const finalizeStep = () => {
        let errors = []

        if (partySize === 0) {
          errors.push('Please tell how many guests are in your party.')
        }

        if (errors.length !== 0) {
            dispatch(setErrors({ type: 'add', errors }))
            return
        }

        dispatch(trackAbandonedBooking())
        dispatch(setStep('2'))
        onNext()
    }

    /**
     * Initial component mount
     */
    useEffect(() => {
        /**
         * When step 1 mounts we need to check and grab the step 0 url to generate the back btn
         */
        const packageGroupStep0Url = window.sessionStorage.getItem(PACKAGE_GROUP_STEP0);
        if (packageGroupStep0Url) {
            setStep0Url(packageGroupStep0Url);
        }

        gaSendPageView('Choose Party Size', '/step/1')

        setInitialLoad(false)
    }, [])

    useEffect(() => {
        if (initialLoad) { return }

        /**
         * If a reminder acknowledgement is required, it will be TRUE or FALSE.
         * Otherwise, we can consider NULL to also be "truthy"
         */
        if (reminderAcknowledged === null || reminderAcknowledged === true) {
            finalizeStep()
        }
    }, [dispatch, reminderAcknowledged])

    return !pkg?.id ? null : <>
        <div className="xbm-step-content">
            <div className="xbm-step-content-main">
                {/*<div className="xbm-step-counter">Step 1</div>*/}
                <div className="xbm-step-question">
                    <h2>{ pkg?.party_size_message || 'How many in your party?' }</h2>
                    <PartySizeInput handlePartySizeChange={handlePartySizeChange} />
                    <PartySizeMessage />
                </div>
            </div>

            <BookingModalLedger />
        </div>

        <BookingModalFooter
            modalStyles={modalStyles}
            disableNext={!participants}
            onNext={handleNext}
            step0Url={step0Url}
            onBack={() => window.location.href=step0Url}
        />
    </>
}
