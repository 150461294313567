import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
    addCompToTabItems,
    closeModal,
    configureModal,
    discountItems,
    resetMultipleItemsMode,
    selectCurrentCheck,
    selectModals,
    selectMultipleItemsMode,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import DiscountModal from '@/features/AdvancedPointOfSale/components/modals/DiscountModal'
import CompModal from '@/features/AdvancedPointOfSale/components/modals/CompModal'
import Modal from '@/features/AdvancedPointOfSale/components/Modal'
import ItemCard from '@/features/AdvancedPointOfSale/components/cards/ItemCard'

import { calculatePriceAndDiscountedPrice } from '@/features/AdvancedPointOfSale/lib/Items'
import { numberToCurrency } from '@/lib/Number'

export default function MultipleItemsActionModal() {

    const dispatch                      = useDispatch()
    const check                         = useSelector(selectCurrentCheck)
    const { items, uuids }              = useSelector(selectMultipleItemsMode)
    const { manageMultipleItems:modal } = useSelector(selectModals)
    const tabName                       = !!modal.tab ? (modal.tab.is_general && check.booking?.name || modal.tab.name) : ''

    const combinedDisplayPrice = () => (
        items.map((item) => {
                 const comp = check?.comps?.find((comp) => comp.item_uuid === item?.item?.uuid)

                 let price = !/^(pending)$/i.test(comp?.status)
                    ? calculatePriceAndDiscountedPrice(
                            item?.item,
                            item?.discount,
                            modal.showPriceAfterTax,
                            false,
                            item?.refunded_cents
                        ).price
                    : 0

                 return item?.amount_type === 'credit' ? (price * -1) : price
             })
             .reduce((sum, price) => sum + price, 0)
    )

    const handleClose = () => {
        dispatch(configureModal({
            modal: 'manageMultipleItems',
            config: {
                isOpen: false,
                title: '',
                content: '',
                showPriceAfterTax: false,
            },
        }))
    }

    const content = () => {
        switch(modal.content) {
            case 'CompModal' : return (
                <CompModal
                    onClose={handleClose}
                    onSave={(comp) => {
                        dispatch(addCompToTabItems(comp, modal.tab.id, uuids)).then(() => {
                            dispatch(closeModal('manageCheckItemModal'))
                            dispatch(resetMultipleItemsMode())
                            handleClose()
                        })
                    }}
                />
            )

            case 'DiscountModal' : return (
                <DiscountModal
                    items={items}
                    onClose={handleClose}
                    onSave={(discount) => {
                        dispatch(discountItems(discount, modal.tab.id, uuids)).then(() => {
                            dispatch(closeModal('manageCheckItemModal'))
                            dispatch(resetMultipleItemsMode())
                            handleClose()
                        })
                    }}
                />
            )
        }
    }

    return modal.isOpen && (
        <Modal
            className='manage-multiple-items-modal'
            title={modal.title}
            size={modal.size}
            isOpen={modal.isOpen}
            onClose={handleClose}
        >
            {
                items.length > 1 ? (
                    <div className='item-card py-2 px-3 rounded mt-4 mx-4'>
                        <div className='d-flex justify-content-between'>
                            <span>
                                <strong>Multiple Items ({items.length})</strong>
                                <small className='d-block font-italic'>{tabName}</small>
                            </span>

                            <span className='text-right'>
                                { numberToCurrency(combinedDisplayPrice()) }
                            </span>
                        </div>
                    </div>
                ) : (
                    <ItemCard
                        className='rounded mt-4 mx-4'
                        item={items[0]}
                        tab={modal.tab}
                    />
                )
            }

            <div className="d-flex flex-column justify-content-center w-100 p-4">
                { content() }
            </div>
        </Modal>
    )
}
