import React from 'react'
import FormRow from '@/components/Form/FormRow'
import Input from '@/components/Form/Input'
import Select from '@/components/Form/Select'
import Checkbox from '@/components/Form/Checkbox'
import { validationPatterns } from '@/lib/Errors'
import { stateOptions } from '@/lib/States'

export default function LocationAddresses({
    form,
    errors,
    handleUpdate,
    handleCheckboxUpdate
}) {

    return <div className='border-top pt-3 pb-1'>
        {
            !form.legal_address_is_same && (
                <h6 className='mb-3'>Location Address</h6>
            )
        }

        <FormRow className={form.legal_address_is_same ? '' : 'pl-3'}>
            <Input
                cols='6'
                value={form.address_line_1}
                validation={{ 'address_line_1': { required: true }}}
                errors={errors}
                handleChange={handleUpdate}
            />

            <Input
                cols='6'
                value={form.address_line_2}
                req={false}
                validation={{ 'address_line_2': { required: false }}}
                errors={errors}
                handleChange={handleUpdate}
            />
        </FormRow>

        <FormRow className={form.legal_address_is_same ? '' : 'pl-3'}>
            <Input
                cols='4'
                value={form.city}
                validation={{ 'city': { required: true }}}
                errors={errors}
                handleChange={handleUpdate}
            />

            <Select
                cols='2'
                value={form.state}
                options={stateOptions}
                validation={{ 'state': { required: true }}}
                errors={errors}
                handleChange={handleUpdate}
            />

            <Input
                cols='2'
                mask='99999'
                value={form.zip}
                label='Zip Code'
                validation={{ 'zip': {
                    required: true,
                    pattern: validationPatterns.zip,
                }}}
                errors={errors}
                handleChange={handleUpdate}
            />

            <Checkbox
                cols='4'
                className="mt-4 pt-1 pl-3"
                name='legal_address_is_same'
                label='Use As Legal Address?'
                value={form.legal_address_is_same}
                handleChange={handleCheckboxUpdate}
            />
        </FormRow>

        {
            !form.legal_address_is_same && <>
                <h6 className='mt-2 mb-3'>Legal Address</h6>

                <FormRow className={form.legal_address_is_same ? '' : 'pl-3'}>
                    <Input
                        cols='6'
                        label='Address Line 1'
                        placeholder='Address Line 1'
                        value={form.legal_address_line_1}
                        validation={{ 'legal_address_line_1': { required: !form.legal_address_is_same }}}
                        errors={errors}
                        handleChange={handleUpdate}
                    />

                    <Input
                        cols='6'
                        label='Address Line 2'
                        placeholder='Address Line 2'
                        value={form.legal_address_line_2}
                        req={false}
                        validation={{ 'legal_address_line_2': { required: false }}}
                        errors={errors}
                        handleChange={handleUpdate}
                    />
                </FormRow>

                <FormRow className={form.legal_address_is_same ? '' : 'pl-3'}>
                    <Input
                        cols='4'
                        label='City'
                        placeholder='City'
                        value={form.legal_address_city}
                        validation={{ 'legal_address_city': { required: !form.legal_address_is_same }}}
                        errors={errors}
                        handleChange={handleUpdate}
                    />

                    <Select
                        cols='2'
                        label='State'
                        placehodler='State'
                        value={form.legal_address_state_or_province}
                        options={stateOptions}
                        validation={{ 'legal_address_state_or_province': { required: !form.legal_address_is_same }}}
                        errors={errors}
                        handleChange={handleUpdate}
                    />

                    <Input
                        cols='2'
                        mask='99999'
                        label='Zip Code'
                        placeholder='Zip Code'
                        value={form.legal_address_postal_code}
                        validation={{ 'legal_address_postal_code': {
                            required: !form.legal_address_is_same,
                            pattern: validationPatterns.zip,
                        }}}
                        errors={errors}
                        handleChange={handleUpdate}
                    />
                </FormRow>
            </>
        }
    </div>
}
