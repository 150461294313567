import React from 'react'
import ColorPicker from '@/components/Form/ColorPicker'
import FormRow from '@/components/Form/FormRow'
import Input from '@/components/Form/Input'

export default function CategoryDetails({
    form,
    errors,
    handleUpdate,
    handleManualUpdate
}) {
    return (
        <div>
            <FormRow>
                <Input
                    value={form.name}
                    label="Category Name"
                    errors={errors}
                    req={true}
                    validation={{ 'name': { required: true }}}
                    handleChange={handleUpdate}
                />
            </FormRow>

            <h3>Button Color</h3>
            <FormRow>
                <ColorPicker
                    value={form.button_color}
                    onChange={(color) => handleManualUpdate('button_color', color)}
                />
            </FormRow>
        </div>
    )
}
