import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import {
    setExpired,
    selectExpired,
    selectLoading,
    selectBooking,
    selectHeld,
    selectHoldTimeDurationMinutes,
} from '@/features/Bookings/bookingSlice'

import { useConfirm } from '@/lib/useConfirmHook'

export default function BookingCountdown() {

    const { confirm }     = useConfirm()
    const dispatch        = useDispatch()
    const booking         = useSelector(selectBooking)
    const minutes         = useSelector(selectHoldTimeDurationMinutes)
    const isHeld          = useSelector(selectHeld)
    const isExpired       = useSelector(selectExpired)
    const isFinalizing    = useSelector(selectLoading)
    const [time, setTime] = useState(null)

    const calculateCountdown = () => {
        if (isExpired || !booking.updatedAt) { return }

        const expiration = moment(booking.updatedAt).add(parseInt(minutes, 10), 'minutes').add(2, 'seconds')
        const now        = moment()
        const diff       = moment(expiration).diff(now)
        const duration   = moment.duration(diff)

        let mins = Number.parseInt(duration.minutes())
        let secs = Number.parseInt(duration.seconds())

        if (secs < 10 && secs >= 0) {
            secs = `0${secs}`
        }

        if (!isNaN(mins) && !isNaN(mins) && mins >= 0 && secs >= 0) {
            setTime(`${mins}:${secs}`)
        }

        if (mins <= 0 && secs <= 0) {
            window.clearInterval(window.timerInterval)
            dispatch(setExpired(true))
            return
        }
    }

    const startTimer = () => {
        if (!window.timerInterval) {
            window.timerInterval = window.setInterval(() => {
                calculateCountdown()
            }, 1000)
        }
    }

    const stopTimer = () => {
        window.clearInterval(window.timerInterval)
        delete window.timerInterval
        setTime(null)
    }

    const handleCountdownExpiration = async () => {
        if (await confirm('Your session has expired.', { header_text: "We're Sorry...", confirm_only: true, confirm_text: 'OK' })) {
            stopTimer()
            window.location.reload()
        }
    }

    useEffect(() => {
        if (booking && booking.id && booking.updatedAt && isHeld) {
            startTimer()
        } else {
            stopTimer()
        }
    }, [booking, isHeld])

    useEffect(() => {
        if (isFinalizing) {
            stopTimer()
        }
    }, [isFinalizing])

    useEffect(() => {
        if (isExpired) {
            handleCountdownExpiration()
        }
    }, [isExpired])

    // clear the timer upon component unmount
    useEffect(() => {
        return () => {
            stopTimer()
        }
    }, [])

    return time && (
        <div className="booking-countdown pr-md-5 mr-md-4">
            <small className="mr-2">Hold Time Remaining:</small> {time}
        </div>
    )
}
