import React from 'react'
import Input from '../Form/Input'
import RichTextEditor from "../Form/RichTextEditor";
import FormRow from "../Form/FormRow";

export default function GameRuleForm({ handleUpdate, handleManualUpdate, form, errors }) {
    return (
        <div>
            <FormRow>
                <Input
                    name='name'
                    handleChange={handleUpdate}
                    req={true}
                    value={form.name}
                    cols='12'
                    errors={errors} />
            </FormRow>
            <FormRow>
                <RichTextEditor
                    name='description'
                    handleChange={handleManualUpdate}
                    req={true}
                    value={form.description}
                    cols='12'
                    errors={errors} />
            </FormRow>
        </div>
    )
}