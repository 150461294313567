import moment from 'moment'
import { debug } from '@/lib/Debug'

/**
  * Determine if the target date is the date of the DST switchover
  * by comparing the UTC offsets at the beginning and end of the same day
  *
  * @param {datetime} the Moment datetime of the target date
    *
  * @returns Boolean
  */
export function isDateOfDSTSwitch(datetime=null) {
    if (!datetime || !moment.isMoment(datetime)) { return false }
    const beginningOfDay = datetime.clone().startOf('day')
    const endOfDay       = datetime.clone().endOf('day')
    return beginningOfDay.utcOffset() !== endOfDay.utcOffset()
}

/**
 * Main shared routine for generating an array of available times (moment objects) for a given
 * calendar day that can be looped over in order to build out the calendar grid.
 *
 * @param {int} increment the time to increment for every block
 * @todo right now this is hard coded to 15 minutes, do we ever need anything else i.e. dynamic?
 *
 * @returns Array each individual time slot available
 */
export function generateTimes(loadedDates, lastLoadedDatetime, timezone) {
    const currentDate = moment().tz(timezone)
    const increment   = 15
    const times       = []
    let dates         = [...loadedDates]

    const lastDateTime = lastLoadedDatetime
        ? moment.tz(lastLoadedDatetime, timezone)
        : moment.tz(loadedDates[loadedDates.length-1], timezone).endOf('day')

    if (!dates.includes(lastDateTime.format('YYYY-MM-DD'))) {
        dates.push(lastDateTime.format('YYYY-MM-DD'))
    }

    dates.forEach((date) => {
        const targetDate = moment.tz(date, timezone)

        times.push(targetDate.clone())

        // ORIGINAL
        // The -1 is here because loaded dates will generate midnight today till midnight tomorrow without it.
        // Instead, we want it to stop 1 increment before (i.e. 11:45 for a 15 min increment)
        // const timeSlots = ((24 * 60) / increment) - 1;

        // 2022 DST HACKY HACK
        // const timeSlots = (((moment.tz('2022-11-06', timezone).isSame(targetDate) ? 25 : 24) * 60) / increment) - 1;

        // HANDLE DST
        // Determine the number of hours in the target day to render on the calendar
        //   - This either renders and hour less, an additional hour, or the standard amount of
        //   - hours in a day depending on the state of DST right now and of the target day
        const timeSlots = (((
            isDateOfDSTSwitch(targetDate)
                ? (targetDate.utcOffset() > currentDate.utcOffset() ? 25 : 23)
                : 24
        ) * 60) / increment) - 1;

        [...Array(timeSlots).keys()].forEach(() => {
            if ( targetDate.clone().add(increment, 'minutes').isBefore(lastDateTime) ) {
                targetDate.add(increment, 'minutes')
                times.push(targetDate.clone())
            }
        })
    });

    return { dates, times }
}

/**
 * For an array of booking objects, map them to include their _timeColumn attribute which is where
 * along the time grid we have to place each individual booking
 *
 * @param {array} bookings array of booking objects from the server
 * @returns modified array of bookings with _timeColumn attribute to be used
 */
export function assignBookingsToTimeColumns(bookings, loadedDates, lastLoadedDatetime, timezone) {
    // populate `times` here once so we don't have to do that on every iteration of the loop
    const { times } = generateTimes(loadedDates, lastLoadedDatetime, timezone)

    const sortedDescBookings = [...bookings].sort((a,b) => b.booking.id - a.booking.id)

    // return the same exact bookings structure, just with an injected attribute
    return bookings.map(booking => {
        return {
            ...booking,
            booking: {
                ...booking.booking,
                _timeColumn: findTimeColumn(booking.booking.id, booking.booking.start_time, times, timezone),

                /**
                 * Whenever we are parsing through bookings, we also check to see if it overlaps any other bookings.
                 */
                overlapping: sortedDescBookings.filter((ob) => {
                    // right away, make sure we're not checking the same booking -- would always return true
                    if (ob.booking.id === booking.booking.id) { return false }

                    // ignore buffers
                    if (/buffer/i.test(ob.booking?.type)) { return false }

                    const isBookingOverlapped = (
                        moment(booking.booking.start_time).isBetween(
                            moment(ob.booking.start_time),
                            moment(ob.booking.start_time).add(ob.booking.duration, 'minutes').add(ob.booking.addon_minutes, 'minutes')
                        )
                        || (booking.booking.start_time == ob.booking.start_time)
                    )

                    const hasSharedResource = booking.resources.some((r) => ob.resources.includes(r))

                    return hasSharedResource && isBookingOverlapped
                }),

                /**
                 * Whenever we are parsing through bookings, we also check to see if it is overlapped by any other bookings.
                 */
                overlappedBy: sortedDescBookings.filter((ob) => {
                    // right away, make sure we're not checking the same booking -- would always return true
                    if (ob.booking.id === booking.booking.id) { return false }

                    // ignore buffers
                    if (/buffer/i.test(ob.booking?.type)) { return false }

                    const isOverlappingBookingWithinBookingTimeRange = (
                        moment(ob.booking.start_time).isBetween(
                           moment(booking.booking.start_time),
                           moment(booking.booking.start_time).add(booking.booking.duration, 'minutes').add(booking.booking.addon_minutes, 'minutes')
                        )
                        || (booking.booking.start_time == ob.booking.start_time)
                    )

                    const hasSharedResource = booking.resources.some((r) => ob.resources.includes(r))

                    return hasSharedResource && isOverlappingBookingWithinBookingTimeRange
                })
            }
        }
    })
}

/**
 * Find and return the index our startTime is in out of all available times. We have to do this
 * because we have to know the individual number (1st,2nd,30th, etc.) of the column in which
 * represents the booking's start time.
 *
 * @param {moment} datetime moment object representing the booking's start time
 * @param {moment[]} times array of moment objects that represent all available time blocks
 * @param {string} the timezone of the current location
 * @returns {integer or undefined} of the time block 'startTime' is found in out of 'times'
 */
function findTimeColumn(id, datetime, times, timezone) {
    let timecolumn

    const booking   = document.getElementById(`booking-${id}`)
    const startTime = moment.tz(datetime, timezone)

    /**
     * Loop over each available time block in `times` and check to see if is the
     * same time as our startTime, if so we return that value. Each item in the
     * times array is already a moment object.
     */
    for (let index = 0; index < times.length; ++index) {
        /**
         * Check if the current time slot is the same as our start time, if so
         * we need to just return the index of that time slot + 1 in order to
         * give which actual time grid block is this, 1st, 2nd, etc.
         */
        if (times[index].tz(timezone).isSame(startTime)) {
            timecolumn = index + 1
        }
    }

    if (!timecolumn && debug && console) {
        if (booking) { booking.classList.add('d-none') }
        console.log('-------------------------------------------------------------------------------')
        console.error(`Could not determine a time column for ${startTime.toString()} - we may simply not be rendering this day/time`)
        console.log(times.map((t) => t.toString()))
        console.log('-------------------------------------------------------------------------------')
    } else {
        if (booking) { booking.classList.remove('d-none') }
    }

    return timecolumn
}

/**
 * This function exists because react-datepicker can only accept normal browser date objects
 * and when those are instantiated they strip out all timezone information from moment and
 * return a date in the browser's/user's local timezone.
 *
 * By sending in a moment object that is in the correct/intended timezone, outputting it as
 * a formatted string (in the intended timezone BUT without timezone information) and then
 * simply parsing the string, we can do the "conversion" ourselves.
 *
 * So if the original datetime is 01/01/1970 @ 1500 UTC and we need it in CT (-0600), to match
 * the location's time BUT the local browser time is in ET (-0500) then we send it in as
 * datetime = moment(<UTC DATE>).tz('America/Chicago') and then format the string output as
 * '01/01/1970T0900' which can then be parsed "as" an ET datetime even though it's actually
 * displaying a CT calculated datetime.
 */
export function momentToLocalDate(datetime, format='YYYY-MM-DDTHH:mm:ss') {
    if (!moment.isMoment(datetime)) {
        if (console && debug) { console.error(`Could not convert momentToLocalDate for ${datetime}. Value passed in is not a moment object.`) }
        return datetime
    }

    try {
        return new Date(Date.parse(datetime.format(format)))
    } catch (error) {
        if (console && debug) { console.error('Could not convert expected Moment to Datetime object, unknown type passed in.', datetime, typeof datetime, error) }
        return datetime
    }
}

export function localDateToMoment(datetime, timezone=null) {
    if (moment.isMoment(datetime)) {
        if (console && debug) { console.info(`Passed in variable is already a Moment object, so we're simply passing it back like a HOT POTATO.`, datetime) }
        return datetime
    }

    if (!datetime instanceof Date) {
        if (console && debug) { console.error(`Could not convert localDateToMoment for ${datetime}. Value passed in is not a standard JS date object.`, typeof datetime) }
        return datetime
    }

    try {
        // pad values with a leading 0 and convert months
        // from starting at 0 for January > 1 for January
        const year    = datetime.getFullYear()
        const date    = (datetime.getDate() < 10 ? '0' : '') + datetime.getDate()
        const month   = ((datetime.getMonth() + 1) < 10 ? '0' : '') + (datetime.getMonth() + 1)
        const hours   = (datetime.getHours() < 10 ? '0' : '') + datetime.getHours()
        const minutes = (datetime.getMinutes() < 10 ? '0' : '') + datetime.getMinutes()
        const seconds = (datetime.getSeconds() < 10 ? '0' : '') + datetime.getSeconds()

        if (timezone) {
            return moment.tz(`${year}-${month}-${date} ${hours}:${minutes}:${seconds}`, timezone)
        } else {
            return moment(`${year}-${month}-${date} ${hours}:${minutes}:${seconds}`)
        }
    } catch (error) {
        if (console && debug) { console.error('Could not convert expected Datetime to Moment object, unknown type passed in.', datetime, typeof datetime, error) }
        return datetime
    }
}

export function toPlainDate(datetime) {
    if (!datetime instanceof Date) {
        if (console && debug) { console.error(`Could not convert datetime. Value passed in is not a standard JS date object.`, typeof datetime) }
        return datetime
    }

    try {
        return datetime.toLocaleDateString("en-US", {year: "numeric", month: "2-digit", day: "2-digit"});
    } catch (error) {
        if (console && debug) { console.error('Could not convert expected datetime to DateTime object, unknown type passed in.', datetime, typeof datetime, error) }
        return datetime
    }
}

export function adjustedForDST(datetime, timezone='UTC') {
    if (!moment.isMoment(datetime)) {
        if (console && debug) { console.info(`Datetime needs to be a Moment object.`, datetime) }
        return datetime
    }

    const now = moment.tz(timezone)

    switch(true) {
        case !now.isDST() && datetime.isDST() :
            return datetime.clone().subtract(1, 'hour')

        case now.isDST() && !datetime.isDST() :
            return datetime.clone().add(1, 'hour')

        default :
            return datetime
    }
}

export function roundedTo(number, target, direction='floor') {
    return Math[direction](number / target) * target
}

export function roundedToNearestMinute(datetime, minutes=15, direction='floor') {
    try {
        if (!moment.isMoment(datetime)) {
            datetime = moment(datetime)
        }

        return datetime.minutes(
            roundedTo(datetime.minutes(), minutes, direction)
        ).seconds(0)
    } catch(e) {
        console.error('could not rounded the date to the nearest time increment')
        return datetime
    }
}

export function calculatedDragOutlineClasses(lane, time, duration, timezone, interval=15, offset=15) {
    let startTime = moment(time).tz(timezone)
                                .subtract(duration, 'minutes')
                                .add(offset, 'minutes')

    // endtime is the time of the monitored time cell because the drag handle is on
    // the RIGHT side of the booking, so we need to build the times out BACKWARDS
    let endTime = moment(time).tz(timezone)
                              .add(offset, 'minutes')

    let classes = []

    while (startTime.unix() < endTime.unix()) {
      classes.push( `.lane-${lane}.time-${startTime.unix()}` )
      startTime.add(interval, 'minutes')
    }

    return classes
}

export default null
