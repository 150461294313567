import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import {
  setOpenResendReceiptModal,
  selectOpenResendReceiptModal,
  selectResendReceiptPayment,
  selectResendReceiptRefund,
  setResendReceiptRefund,
  setResendReceiptPayment,
} from './bookingHistorySlice'
import { addAlert } from '@/features/Notifications/notificationSlice'
import { accessToken } from '@/lib/Csrf'
import { UI } from '@/lib/Constants'

export default function ResendReceiptModal() {

  const dispatch = useDispatch()
  const open     = useSelector(selectOpenResendReceiptModal)
  const payment  = useSelector(selectResendReceiptPayment)
  const refund   = useSelector(selectResendReceiptRefund)

  const [isEmailKnown, setIsEmailKnown] = useState(false)
  const [newEmail, setNewEmail]   = useState('')
  const [selectedEmailType, setSelectedEmailType] = useState('')

  const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
          handleClose()
      }
  }

  const handleClose = () => {
      dispatch(setOpenResendReceiptModal(false))
      dispatch(setResendReceiptPayment(null))
      dispatch(setResendReceiptRefund(null))
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const resendPaymentReceipt = () => {
    let email

    if (selectedEmailType === "new") {
      if(!validateEmail(newEmail)) {
        return dispatch(addAlert({ type: 'error', text: 'Please enter valid email' }))
      }

      email = newEmail
    } else {
      email = payment.email
    }

    axios.post(`/payments/${payment.id}/send_receipt.json`, {
      authenticity_token: accessToken,
      email: email
    })
    .then(({ data }) => {
        if (data.success) {
            dispatch(addAlert({ type: 'success', text: 'Payment Receipt Sent!' }))
            handleClose()
        } else {
            dispatch(addAlert({ type: 'error', text: data.message }))
        }
    })
  }

  const resendRefundReceipt = () => {
    let email

    if (selectedEmailType === "new") {
      if(!validateEmail(newEmail)) {
        return dispatch(addAlert({ type: 'error', text: 'Please enter valid email' }))
      }

      email = newEmail
    } else {
      email = refund.email
    }

    axios.post(`/refunds/${refund.id}/send_receipt.json`, {
      authenticity_token: accessToken,
      email: email
    })
    .then(({ data }) => {
      if (data.success) {
          dispatch(addAlert({ type: 'success', text: 'Refund Receipt Sent!' }))
          handleClose()
      } else {
          dispatch(addAlert({ type: 'error', text: data.message }))
          if (debug && console) { console.warn(data.message) }
      }
    })
  }

  useEffect(() => {
      if ((payment && payment.email) || (refund && refund.email)) {
        setIsEmailKnown(true)
        setSelectedEmailType('original')
      } else {
        setSelectedEmailType('new')
      }
  }, [payment, refund])

  /**
   * Handle the initial mount and
   * unmount of the component
   */
  useEffect(() => {
      document.addEventListener('keydown', handleKeyDown, false)

      if (open) {
          document.body.classList.add(UI.classes.CONFIRMATION_OPEN)
      }

      // cleanup/reset on unmount
      return () => {
          document.body.classList.remove(UI.classes.CONFIRMATION_OPEN)
          document.removeEventListener('keydown', handleKeyDown, false)
          setIsEmailKnown(false)
          setNewEmail('')
          setSelectedEmailType('')
      }
  }, [open])

  return (
    <>
    {open &&
    <div className="modal modal-backdrop modal--md resend-receipt--modal">
      <div className="modal-container">

        <div className="modal-header">
            <div className="modal-title">
                { isEmailKnown ? 'Please verify the email address' : 'Please enter an email address' }...
            </div>
        </div>

        <div className="modal-body d-block m-5">
          {
              isEmailKnown && <>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="originalEmail"
                        name="original"
                        checked={selectedEmailType === "original"}
                        onChange={e => setSelectedEmailType(e.target.name)}
                      />
                      <label className="form-check-label" htmlFor="originalEmail">
                        Original Email Address <strong className="ml-3">({payment ? payment.email : refund.email})</strong>
                      </label>
                    </div>

                    <div className="form-check my-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="customEmail"
                        name="new"
                        checked={selectedEmailType === "new"}
                        onChange={e => setSelectedEmailType(e.target.name)}
                      />
                      <label className="form-check-label" htmlFor="customEmail">New Email Address</label>
                    </div>
              </>
          }

          {
              (selectedEmailType === 'new' || !isEmailKnown) &&
                  <div>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email Address"
                      value={newEmail}
                      onChange={e => setNewEmail(e.target.value)}
                    />
                  </div>
          }
        </div>

        <div className="modal-footer justify-content-between">
          <button
            type="button"
            className="btn btn-outline"
            onClick={handleClose}
          >
            Cancel
          </button>

          <button
            type="button"
            className="btn btn-primary"
            disabled={selectedEmailType === 'new' && newEmail === ''}
            onClick={() => {
              if(payment){
                resendPaymentReceipt()
              } else {
                resendRefundReceipt()
              }
            }}
          >Send
          </button>
        </div>
      </div>
    </div>
    }
    </>
  )
}
