import React, { useState } from "react"

export default function LocationMembershipOccasionsTab({ form, handleManualUpdate }) {
    const [currentInput, setCurrentInput] = useState('')

    const handleAdd = (e) => {
        if (/click/i.test(e.type) || /enter/i.test(e.key)) {
            e.preventDefault()
            let updated = [...form.membership_occasion_types]
            updated.push(currentInput)
            handleManualUpdate('membership_occasion_types', updated)
            setCurrentInput('')
        }
    }

    const handleRemove = (targetIndex) => {
        handleManualUpdate('membership_occasion_types', form.membership_occasion_types.filter((_, i) => i !== targetIndex))
    }

    return !form.membership_occasion_types ? '' : <div>
        <div className="pb-3">
            <div className="input-group">
                <input
                    type='text'
                    name='membership_occasion'
                    autoFocus={true}
                    className='form-control'
                    value={currentInput}
                    onChange={e => setCurrentInput(e.target.value)}
                    onKeyDown={handleAdd}
                />
                <div className="input-group-append">
                    <button
                        type='button'
                        className={`btn ${currentInput === '' ? 'btn-secondary' : 'btn-primary'}`}
                        disabled={currentInput === ''}
                        onClick={handleAdd}
                    >
                        Add
                    </button>
                </div>
            </div>
        </div>

        <hr className="mt-4 mb-3" />

        {
            form.membership_occasion_types
                .sort((a,b) => a.localeCompare(b))
                .map((occasion, index) =>
                    <div key={index} className="row py-1">
                        <div className="col-8 col-md-10">
                            { occasion }
                        </div>
                        <div className="col-4 col-md-2 text-right">
                            <button
                                type='button'
                                className='btn btn-link text-danger'
                                onClick={() => handleRemove(index)}
                            >
                                Remove
                            </button>
                        </div>
                    </div>
                )
        }
    </div>
}
