import React from 'react'
import { numberToCurrency } from '@/lib/Number'
import { parameterize } from 'inflected'

export default function ExternalSelectedList({
    sectionTitle='Replace Me!',
    collection=[],
    schema={
        parentId: 'parentId',
        parentName: 'parentName',
        childId: 'childId',
        childName: 'childName'
    },
    onRemove=() => {}
}) {

    return collection.length > 0 && <>
        <h4>{sectionTitle}</h4>

        <div className="multi-select--external-list w-100">
            {
                collection.map((item) => {
                    const price = Number.parseFloat(item[schema.childAmountCents]) / 100.0

                    return (
                        <div key={parameterize(`${item[schema.parentId]}--${item[schema.childId]}`)}>
                            <div className="row d-flex item align-items-center border-bottom mx-0 py-2 no-sub-items">
                                <div className='col name h6 pl-2 mb-0 d-flex align-items-center'>
                                    <span className='d-block w-100'>
                                        { item[schema.parentName]}
                                        { item[schema.childName] ? <>{` - ${item[schema.childName]}`}</> : ''}
                                    </span>
                                    {
                                        !isNaN(price) && (
                                            <span className='d-block w-100'>
                                                { price == 0 ? '\u00A0\u00A0\u00A0' : (price > 0 ? '+' : '-') }
                                                { numberToCurrency(price) }
                                            </span>
                                        )
                                    }
                                </div>

                                <div className='col-auto text-right item--actions pr-0 d-flex align-items-center'>
                                    <button
                                        type='button'
                                        className='btn btn-link text-danger'
                                        onClick={() => onRemove(item[schema.parentId], false, item[schema.childId])}
                                    >
                                        Remove
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    </>
}
