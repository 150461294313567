import React from 'react'
import FormRow from '@/components/Form/FormRow'
import MultiSelectCheckboxes from '@/components/Form/MultiSelectCheckboxes'
import { pluckKeyFromObjectsInArray } from '@/lib/Object'
import { sortedByArray } from '@/lib/Array'

export default function CategoryContent({
    form,
    errors=[],
    categories=[],
    handleFormUpdate,
}) {

    const handleCategoriesUpdate = (sub_categories) => {
        const sub_category_ids = pluckKeyFromObjectsInArray(sub_categories, 'id')
        const sub_categories_sort_order = sub_category_ids

        handleFormUpdate({ ...form, ...{
            sub_categories,
            sub_category_ids,
            sub_categories_sort_order
        }})
    }

    return (
        <div>
            <h3 className='mt-0'>Sub-Categories</h3>
            <FormRow>
                <MultiSelectCheckboxes
                    hideLabel={true}
                    options={categories}
                    selectedValues={sortedByArray(form.sub_categories, form.sub_categories_sort_order)}
                    useExternalList={true}
                    externalListIsSortable={true}
                    errors={errors}
                    validation={{ sub_categories: { required: false }}}
                    emptyRecordMsg='No categories available.'
                    onSelect={handleCategoriesUpdate}
                    onRemove={handleCategoriesUpdate}
                    onSort={handleCategoriesUpdate}
                />
            </FormRow>
        </div>
    )
}
