import React, { useState, useEffect } from 'react'
import GroupForm from '@/components/Packages/GroupForm'
import { useConfirm } from '@/lib/useConfirmHook'
import titleize from '@/lib/String'
import { numberToCurrency } from '@/lib/Number'

const depositTypeText = (group) => {
    switch(group.deposit_type) {
        case 'percent' : return '% of Total'
        case 'dollar'  : return '$ per Person'
        case 'flat'    : return 'Flat rate'
    }
}

const depositAmountText = (group) => {
    switch(group.deposit_type) {
        case 'percent' :
          return `${group.deposit_amount}%`

        case 'dollar' :
        case 'flat' :
          return numberToCurrency(group.deposit_amount)
    }
}

export default function GroupList({
    resources,
    groups,
    pricing,
    durations,
    selectedCustomerTypes,
    pricingType,
    isSpecialEvent,
    handleManualUpdate
}) {

    const { confirm }                             = useConfirm()
    const [sendPrice, setSendPrice]               = useState(false)
    const [editing, setEditing]                   = useState(false)
    const [groupIndexToEdit, setGroupIndexToEdit] = useState(null)
    const [groupToEdit, setGroupToEdit]           = useState(null)
    const [groupToRemove, setGroupToRemove]       = useState(null)

    const handleEdit = (value) => {
        const index = Number(value)
        setEditing(true)
        setGroupIndexToEdit(index)
        setGroupToEdit(groups[index])

        // auto-focus on the first editable field in the
        // form (the participants per resource override)
        window.setTimeout(() => {
            try {
                document.getElementById(`group-edit-form-${index}`).querySelectorAll('input')[2].focus()
            } catch(e) {
                if (console) { console.error(e) }
                setEditing(false)
                setGroupIndexToEdit(null)
                setGroupToEdit(null)
                alert('Could not edit the price block!')
            }
        }, [200])
    }

    const handleEditCancel = () => {
        setEditing(false)
        setGroupToEdit(null)
    }

    const handleUpdated = () => {
        setEditing(false)
        setGroupToEdit(null)
    }

    const handleRemove = async (value) => {
        if (await confirm('Remove this group and all of its associated prices?<br /><br/>Please note, this action is not final until<br />you click "Save & Close".')) {
            const index = Number(value)
            const target = groups[index]

            setGroupToRemove(target)
            setSendPrice(true)

            // we stringify the object and encode it in order to directly compare entire objects
            handleManualUpdate( 'groups', groups.filter((g) => window.btoa(JSON.stringify(g)) !== window.btoa(JSON.stringify(target))) )
        }
    }

    const removeGroupFromPricing = () => {
        const updatedPrices = pricing.filter((price) => {
            // we stringify the object and encode it in order to directly compare entire objects
            const a = window.btoa(JSON.stringify({ min: price.groupMin,    max: price.groupMax,    autoGratuity: price.autoGratuity,          participantsPerLane: price.participantsPerLane           }))
            const b = window.btoa(JSON.stringify({ min: groupToRemove.min, max: groupToRemove.max, autoGratuity: groupToRemove.auto_gratuity, participantsPerLane: groupToRemove.participants_per_lane }))
            return a !== b
        })

        handleManualUpdate('pricing', updatedPrices)
        setSendPrice(false)
    }

    // check out GroupForm to see how/why this pattern is implemented
    useEffect(() => {
        if (sendPrice) {
            removeGroupFromPricing()
        }
    }, [groups])

    return groups?.length === 0 ? null : (
        <table className="drawer-add-row w-100 table-sm mb-2">
            <thead>
                <tr>
                    <th>Total<br />Participants</th>
                    { !isSpecialEvent && <th>Occupancy<br />Override</th> }
                    <th>Price<br />Type</th>
                    <th>Deposit<br />Type</th>
                    <th>Deposit<br />Amount</th>
                    <th>Auto<br />Gratuity</th>
                    <th colSpan="2"></th>
                </tr>
            </thead>
            <tbody>
                {
                    groups.sort((a, b) => (Number(a.min + a.max) > Number(b.min + b.max)) ? 1 : -1)
                          .map((group, index) => {
                            // either the amount defined on the price group OR the smallest
                            // max_capacity value found among all of the active bookable resources
                            const participants_per_lane = group?.participants_per_lane || resources?.map((r) => r.max_capacity)?.sort()?.shift()

                            return <React.Fragment key={`group-${index}`}>
                                <tr className={editing && groupToRemove != index ? 'text-muted' : ''}>
                                    <td><strong>{group.min} to {group.max}</strong></td>
                                    {
                                        !isSpecialEvent && (
                                            <td>
                                                {
                                                    participants_per_lane ? <>
                                                        <span className={group?.participants_per_lane ? 'bold' : 'text-muted'}>
                                                            {
                                                                !group?.participants_per_lane && (
                                                                    <span
                                                                        className="badge badge-primary px-2 mr-4"
                                                                        title="This is the standard resource occupancy amount"
                                                                    >
                                                                        <i className='fas fa-info' />
                                                                    </span>
                                                                )
                                                            }
                                                        </span>

                                                        {
                                                            ((Number(participants_per_lane) * resources?.length) < Number(group.max)) && (
                                                                <span
                                                                    className="badge badge-danger px-2 mr-4 float-right"
                                                                    title="This price block is not bookable! There are not enough resources available
                                                                           to support the maximum participants per resource. Either reduce the maximum
                                                                           participants allowed or increase the resource occupancy override."
                                                                >
                                                                    <i className="fas fa-exclamation-triangle" />
                                                                </span>
                                                            )
                                                        }
                                                    </> : '—'
                                                }
                                            </td>
                                        )
                                    }
                                    <td>{titleize(group.price_type || '')}</td>
                                    <td>{depositTypeText(group)}</td>
                                    <td>{depositAmountText(group)}</td>
                                    <td>{group.auto_gratuity ? `${group.auto_gratuity}%` : '—'}</td>
                                    <td>
                                        { group.internal &&
                                            <span className="badge badge-pill badge-danger">Internal</span>
                                        }
                                    </td>
                                    <td className="text-right">
                                      {
                                          editing && groupIndexToEdit == index ? (
                                                <button
                                                  className="btn btn-outline-primary h-auto py-1 px-3 ml-2 w-100"
                                                  onClick={handleEditCancel}
                                                >
                                                    Cancel
                                                </button>
                                            ) : <>
                                                <button
                                                  className="btn btn-primary h-auto py-1 px-3 text-white mr-2"
                                                  title="Edit Price Block"
                                                  disabled={editing}
                                                  onClick={() => handleEdit(index)}
                                                >
                                                    <i className="far fa-edit" />
                                                </button>

                                                <button
                                                  className="btn btn-danger h-auto py-1 px-3 text-white"
                                                  value={index}
                                                  title="Remove Price Block"
                                                  disabled={editing}
                                                  onClick={() => handleRemove(index)}
                                                >
                                                    <i className="far fa-trash-alt" />
                                                </button>
                                            </>
                                        }
                                    </td>
                                </tr>
                                {
                                    editing && groupIndexToEdit == index && (
                                      <tr>
                                          <td colSpan='8' className="bg-dark text-white px-4 pt-4 pb-4">
                                              <GroupForm
                                                  id={`group-edit-form-${index}`}
                                                  key={`group-edit-form-${index}`}
                                                  group={{groupIndex: index, ...groupToEdit}}
                                                  groups={groups}
                                                  pricing={pricing}
                                                  durations={durations}
                                                  selectedCustomerTypes={selectedCustomerTypes}
                                                  pricingType={pricingType}
                                                  isSpecialEvent={isSpecialEvent}
                                                  handleChange={handleUpdated}
                                                  handleManualUpdate={handleManualUpdate}
                                              />
                                          </td>
                                      </tr>
                                    )
                                }
                            </React.Fragment>
                        }
                    )
                }
            </tbody>
        </table>
    )
}
