import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentCheck } from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'
import Calculator from '@/features/AdvancedPointOfSale/components/Calculator'
import TabIcons from '@/features/AdvancedPointOfSale/components/tabs/TabIcons'
import TabStatus from '@/features/AdvancedPointOfSale/components/tabs/TabStatus'
import scrollHinting from '@/lib/ScrollHinting'
import { useConfirm } from '@/lib/useConfirmHook'
import { numberToCurrency } from '@/lib/Number'
import { debug } from '@/lib/Debug'

const amountTypeOptions = {
    'dollar':  { type: 'amount_type', component: <span><i className='fa-solid fa-dollar' /></span>  },
    'percent': { type: 'amount_type', component: <span><i className='fa-solid fa-percent' /></span> },
}

let initialSplitConfig

/*
 * Split Config Example
 *
 *  {
*       // $20.12 - specified whole dollar amount
 *      206284720151 => {               // tab uuid
 *          amount_type  => 'dollar',
 *          input_amount => 20.12,      // amount collected from the input
 *          amount_cents => 2012,       // $20.12 amount calculated that is used during splitting
 *          remaining    => false,
 *          locked       => false
 *      },
 *      // 7% - specified whole percent amount
 *      678639070433 => {
 *          amount_type  => 'percent',
 *          input_amount => 7,
 *          amount_cents => 348,        // $3.48 amount calculated that is used during splitting
 *          remaining    => false,
 *          locked       => true
 *      },
 *      // $112.49 - calculated whole dollar remainder amount
 *      795420872654 => {
 *          amount_type  => 'dollar',
 *          input_amount => null,
 *          amount_cents => 11249,      // $112.49 amount calculated that is used during splitting
 *          remaining    => true,
 *          locked       => false
 *      },
 *  }
*/

export default function GroupPricing({
    id=null,
    tabs=[],
    prices=null,
    existingSplitConfig=null,
    shouldTriggerReset=null,
    changesMade=null,
    onChangesMade=() => {},
    onChange=() => {},
    onValidate=() => {},
}) {
    const { confirm } = useConfirm()
    const check       = useSelector(selectCurrentCheck)
    const booking     = check?.booking
    const price       = prices?.[0]?.item

    const [loaded, setLoaded]                       = useState(false)
    const [frozenItemsPerTab, setFrozenItemsPerTab] = useState(null)
    const [frozenUUIDs, setFrozenUUIDs]             = useState(null)
    const [evenSplitMode, setEvenSplitMode]         = useState(false)
    const [splitConfig, setSplitConfig]             = useState(null)

    const tabIdInRemainderMode = useMemo(() => (
        Object.entries(splitConfig || {})
              .filter(([_tabId, values]) => values.remaining === true)?.[0]?.[0]
    ), [splitConfig])

    const totalCentsRemainingToAllocate = useMemo(() => {
        if (!!splitConfig === false) { return price?.total_cents || 0 }

        const totalCalculatedCents = Object.values(splitConfig)
                                           .map(({ amount_cents }) => Number.parseFloat(amount_cents))
                                           .reduce((sum, val) => sum + val, 0)

        return (price.total_cents - totalCalculatedCents).toFixed(2)
    }, [splitConfig])

    const calculateAmount = (tabId, inputAmount, newSplitConfig) => {
        newSplitConfig[tabId].input_amount = newSplitConfig[tabId].amount_type === 'percent' && inputAmount > 100
                                                 ? 100
                                                 : inputAmount

        newSplitConfig[tabId].amount_cents = newSplitConfig[tabId].amount_type === 'percent'
                                                 ? (booking.price_cents * (newSplitConfig[tabId].input_amount / 100)).toFixed(0)
                                                 : newSplitConfig[tabId].input_amount * 100

        return newSplitConfig
    }

    const calculateRemainderAmount = (splitConfig) => {
        const filteredSplitConfig = Object.fromEntries(Object.entries(splitConfig || {}).filter(([_tabId, values]) => !values.remaining))

        const tabTotalCents = Object.values(filteredSplitConfig)
                                    .map(({ amount_cents }) => Number.parseFloat(amount_cents))
                                    .reduce((sum, val) => sum + val, 0)

        return (price.total_cents - tabTotalCents).toFixed(2)
    }

    // if any tabs have remainder mode enabled, recalculate the new remainder
    const potentiallyRecalculateRemainderAmount = (newSplitConfig) => {
        if (!!tabIdInRemainderMode && newSplitConfig[tabIdInRemainderMode].locked === false) {
            const remainingAmountCents = calculateRemainderAmount(newSplitConfig)
            newSplitConfig[tabIdInRemainderMode].input_amount = (remainingAmountCents / 100).toFixed(2)
            newSplitConfig[tabIdInRemainderMode].amount_cents = remainingAmountCents
        }
        return newSplitConfig
    }

    const discoverItemsToFreeze = () => {
        let _frozenItemsPerTab = {}

        tabs.map((tab) => {
            const paidBookingItems = tab.items.filter((item) => item.payment_made && /^(booking_participant__group)+.*$/.test(item.item.type))

            // add tabID: {} to main object
            _frozenItemsPerTab[tab.uuid] = []

            // add UUIDs to tabId.customerTypeId array
            paidBookingItems.map(({ item }) => {
                _frozenItemsPerTab[tab.uuid].push(item.uuid)
            })
        })

        if (debug && console) {
            console.log('setFrozenItemsPerTab', _frozenItemsPerTab)
            console.log('setFrozenUUIDs', Object.values(_frozenItemsPerTab).flatMap((customerTypeId) => Object.values(customerTypeId)).flat())
        }

        setFrozenItemsPerTab(_frozenItemsPerTab)
        setFrozenUUIDs(Object.values(_frozenItemsPerTab).flatMap((customerTypeId) => Object.values(customerTypeId)).flat())
    }

    const handleResetAll = () => {
        const newSplitConfig = JSON.parse(JSON.stringify(splitConfig))

        Object.keys(newSplitConfig).map((tabId) => {
            newSplitConfig[tabId] = initialSplitConfig[tabId]
        })

        setEvenSplitMode(false)
        setSplitConfig(newSplitConfig)
        onChangesMade(true)
    }

    const handleEvenSplit = async () => {
        const splitRobinsArrowInTwain = () => {
            const newEvenSplitMode     = !evenSplitMode
            const newSplitConfig       = JSON.parse(JSON.stringify(splitConfig))
            const evenSplitAmountCents = newEvenSplitMode == true ? Number.parseFloat((price.total_cents / tabs.length).toFixed(0)) : 0

            Object.keys(newSplitConfig).map((tabId) => {
                newSplitConfig[tabId].amount_type  = 'dollar'
                newSplitConfig[tabId].input_amount = (evenSplitAmountCents / 100).toFixed(2)
                newSplitConfig[tabId].amount_cents = evenSplitAmountCents
                newSplitConfig[tabId].remaining    = false
                newSplitConfig[tabId].locked       = newEvenSplitMode
            })

            const newTotalCalculatedCents = Object.values(newSplitConfig)
                                                  .map(({ amount_cents }) => Number.parseFloat(amount_cents))
                                                  .reduce((sum, val) => sum + val, 0)

            //
            // NOTE
            // If we end up with split values that, due to division remainders,
            // means that we end up with the sum of all tabs being more than the
            // booking price, then reduce the general tab's portion by that amount
            //
            if (newTotalCalculatedCents > booking.price_cents) {
                const generalTabId    = Object.keys(newSplitConfig)[0]
                const differenceCents = (newTotalCalculatedCents - booking.price_cents).toFixed(2)

                newSplitConfig[generalTabId].input_amount = (newSplitConfig[generalTabId].input_amount - (differenceCents / 100)).toFixed(2)
                newSplitConfig[generalTabId].amount_cents = (newSplitConfig[generalTabId].amount_cents - differenceCents).toFixed(2)
            }

            setEvenSplitMode(newEvenSplitMode)
            setSplitConfig(newSplitConfig)
            onChangesMade(true)

            if (debug && console) {
                console.log(
                    `%c${window.atob("SEUgU1BMSVQgUk9CSU4nUyBBUlJPVyBJTiBUV0FJTiEgaHR0cHM6Ly93d3cueW91dHViZS5jb20vd2F0Y2g/dj1aWFJiNm5QY3gxMCZ0PTVz")}`,
                    'color: green; background: yellow; font-size: 14px'
                )
            }
        }

        if (!changesMade || evenSplitMode) {
            splitRobinsArrowInTwain()
        } else if (await confirm('All changes will be lost!', { header_text: 'Are you sure?'})) {
            splitRobinsArrowInTwain()
        }
    }

    const handleSelectAmountType = (tabId, amountType) => {
        let newSplitConfig = JSON.parse(JSON.stringify(splitConfig))

        newSplitConfig[tabId].amount_type = amountType
        newSplitConfig = calculateAmount(tabId, newSplitConfig[tabId].input_amount, newSplitConfig)
        newSplitConfig = potentiallyRecalculateRemainderAmount(newSplitConfig)

        setSplitConfig(newSplitConfig)
        onChangesMade(true)
    }

    const handleInputAmountChange = (tabId, inputAmount) => {
        let newSplitConfig = JSON.parse(JSON.stringify(splitConfig))
        newSplitConfig     = calculateAmount(tabId, inputAmount, newSplitConfig)
        newSplitConfig     = potentiallyRecalculateRemainderAmount(newSplitConfig)

        setSplitConfig(newSplitConfig)
        onChangesMade(true)
    }

    const handleLockToggle = (tabId) => {
        let newSplitConfig           = JSON.parse(JSON.stringify(splitConfig))
        const newLockedMode          = !splitConfig[tabId].locked
        newSplitConfig[tabId].locked = newLockedMode
        newSplitConfig               = potentiallyRecalculateRemainderAmount(newSplitConfig)

        if (evenSplitMode) {
            setEvenSplitMode(false)
        }

        setSplitConfig(newSplitConfig)
        onChangesMade(true)

        if (debug && console) { console.log(`${tabId} ${newLockedMode ? '' : 'UN'}LOCKED`) }
    }

    const handleRemainderToggle = (tabId) => {
        // only allow one tab at a time to use remaining mode
        if (!!tabIdInRemainderMode && tabId !== tabIdInRemainderMode) { return false }

        const newRemaining   = !splitConfig[tabId].remaining
        const newSplitConfig = JSON.parse(JSON.stringify(splitConfig))

        newSplitConfig[tabId].input_amount = 0
        newSplitConfig[tabId].amount_cents = 0

        const remainingAmountCents = newRemaining === true ? calculateRemainderAmount(newSplitConfig) : 0

        newSplitConfig[tabId].remaining    = newRemaining
        newSplitConfig[tabId].amount_type  = 'dollar'
        newSplitConfig[tabId].input_amount = (remainingAmountCents / 100).toFixed(2)
        newSplitConfig[tabId].amount_cents = remainingAmountCents

        setSplitConfig(newSplitConfig)
        onChangesMade(true)
    }

    // LOAD OR CREATE SPLIT CONFIG
    useEffect(() => {
        // use the config sourced from the check
        if (existingSplitConfig !== null && splitConfig === null) {
            // set initial using JSON so we assign by value instead of by reference
            initialSplitConfig = JSON.parse(JSON.stringify(existingSplitConfig))

            setSplitConfig(existingSplitConfig)
            setLoaded(true)

            discoverItemsToFreeze()

            if (debug && console) { console.log('LOADED EXISTING SPLIT CONFIG', existingSplitConfig) }
        }

        // create a brand new split config
        if (existingSplitConfig === null && splitConfig === null) {
            let newSplitConfig = {}

            tabs.map(({ uuid:tabId }) => {
                newSplitConfig[tabId] = {
                    amount_type: 'dollar',
                    input_amount: '',
                    amount_cents: 0,
                    remaining: false,
                    locked: false,
                }
            })

            // set initial using JSON so we assign by value instead of by reference
            initialSplitConfig = JSON.parse(JSON.stringify(newSplitConfig))

            setSplitConfig(newSplitConfig)
            setLoaded(true)

            if (debug && console) { console.log('CREATED NEW SPLIT CONFIG', newSplitConfig) }
        }
    }, [tabs])

    // respond to a config reset trigger event
    useEffect(() => {
        if (loaded && !!splitConfig) {
            handleResetAll()
            onChangesMade(false)
        }
    }, [shouldTriggerReset])

    // announce to the parent that the split config has changed
    useEffect(() => {
        if (loaded && !!splitConfig) {
            if (debug && console) {
                console.log(`REMAINING TO ALLOCATE: ${numberToCurrency(totalCentsRemainingToAllocate / 100)}`)
            }
            onValidate(Number.parseFloat(totalCentsRemainingToAllocate) === 0)
            onChange(splitConfig, frozenUUIDs, totalCentsRemainingToAllocate)
        }
    }, [splitConfig])

    // init scrollhinting
    useEffect(() => scrollHinting.search(), [loaded])

    return loaded && !!price && !!splitConfig && (
        <div id={id} className='position-relative group-pricing d-flex h-100 mx-4'>
            <div className='calculator-container pt-3 pr-4'>
                <div className='button-container'>
                    {
                        frozenUUIDs?.length > 0 ? (
                            <p
                                children='Even splitting has been disabled because payments have been taken towards one or more tabs'
                                className='rounded bg-gray2 mb-3 px-4 py-3 font-italic text-gray9 text-center'
                            />
                        ) : (
                            <button
                                children={`${evenSplitMode ? 'Evenly' : 'Even'} Split`}
                                className={`btn ${evenSplitMode ? 'btn-primary' : 'btn-outline-secondary'} text-white border-thick`}
                                onClick={handleEvenSplit}
                            />
                        )
                    }
                </div>

                <Calculator className='pt-3 mt-3' theme='light' />
            </div>

            <div className='tab-list scrollhint pt-4 pl-4 pr-1 flex-grow-1 flex-shrink-1'>
                <div className='scrollhint--inner px-3'>
                    <table className='table table-sm'>
                        <colgroup>
                            <col width='auto' />
                            <col width='200px' />
                            <col width='50px' />
                            <col width='50px' />
                            <col width='80px' />
                        </colgroup>
                        <tbody>
                            {
                                tabs.map((tab) => {
                                    const {
                                        amount_type:amountType,
                                        input_amount:inputAmount,
                                        amount_cents:totalCents,
                                        remaining:isRemainderToggled,
                                        locked:isLocked
                                    } = splitConfig[tab.uuid]

                                    const isFrozen = frozenItemsPerTab?.[tab.uuid]?.length > 0

                                    if (totalCents < 0) {
                                        window.setTimeout(() => {
                                            onValidate(false)
                                            document.getElementById(`tab-${tab.uuid}-amount-display`)
                                                    .scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
                                        }, 100)
                                    }

                                    return (
                                        <tr key={`${tab.uuid}-${amountType}`} style={isFrozen ? { opacity: 0.4 } : null}>
                                            <td className='tab-name pr-2'>
                                                <div className='d-inline-flex'>
                                                    <div className='h5 mb-0 d-flex align-items-center'>
                                                        <TabIcons
                                                            tab={tab}
                                                            showMembership='compact'
                                                        />

                                                        {
                                                            isFrozen && (
                                                                <span className='text-green ml-2'><i className='fa-solid fa-sack-dollar' /></span>
                                                            )
                                                        }

                                                        { debug && <small className='ml-2' children={tab.uuid} /> }
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='amount-input px-2'>
                                                <div className={`input-group ${amountType === 'dollar' ? 'flex-row' : 'flex-row-reverse'}`}>
                                                    <div className={`input-group-${amountType === 'dollar' ? 'prepend' : 'append'}`}>
                                                        <button
                                                            children={amountTypeOptions[amountType].component}
                                                            className="btn btn-outline-secondary dropdown-toggle"
                                                            type="button"
                                                            disabled={isRemainderToggled || isLocked || isFrozen}
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                        />
                                                        <div className="dropdown-menu shadow-lg">
                                                            {
                                                                Object.keys(amountTypeOptions)
                                                                      .filter((key) => key !== amountType)
                                                                      .map((key, index) =>
                                                                (
                                                                    <React.Fragment key={`tab-${tab.uuid}-option-${index}`}>
                                                                        {
                                                                            amountTypeOptions[key].type === 'divider'
                                                                                ? amountTypeOptions[key].component
                                                                                : <span
                                                                                      children={amountTypeOptions[key].component}
                                                                                      className="dropdown-item cursor-pointer"
                                                                                      onClick={() => handleSelectAmountType(tab.uuid, key)}
                                                                                  />
                                                                        }
                                                                    </React.Fragment>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>

                                                    <input
                                                        className='form-control'
                                                        type='number'
                                                        min='0'
                                                        max={amountType == 'percent' ? 100 : (totalCentsRemainingToAllocate / 100)}
                                                        step={amountType == 'percent' ? '1' : '.01'}
                                                        value={inputAmount}
                                                        placeholder={amountType == 'percent' ? '0' : '0.00'}
                                                        disabled={isRemainderToggled || isLocked || isFrozen}
                                                        onChange={(e) => handleInputAmountChange(tab.uuid, e.target.value)}
                                                    />
                                                </div>
                                            </td>
                                            <td className='remainder-button px-2'>
                                                <button
                                                    children={<i className="fa-solid fa-scale-balanced" />}
                                                    className={`btn ${isRemainderToggled ? 'btn-success text-white' : 'btn-outline-secondary'}`}
                                                    disabled={isFrozen}
                                                    onClick={() => handleRemainderToggle(tab.uuid)}
                                                />
                                            </td>
                                            <td className='lock-button px-2'>
                                                <button
                                                    children={<i className="fa-solid fa-lock" />}
                                                    className={`btn ${isLocked ? 'btn-danger text-white' : 'btn-outline-secondary'}`}
                                                    disabled={isFrozen}
                                                    onClick={() => handleLockToggle(tab.uuid)}
                                                />
                                            </td>
                                            <td
                                                id={`tab-${tab.uuid}-amount-display`}
                                                className={`${totalCents < 0 ? 'text-warning fa-fade' : ''} amount-display pl-3`.trim()}
                                            >
                                                {numberToCurrency(totalCents / 100)}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
