import React from "react";

export default function CustomerWaiversHeader({
  company,
  styles
}) {
  const dark_text_img = styles.use_dark_text_image
  const logo_light = styles.logo_light_src ? 'true' : 'false'
  const logo_dark = styles.logo_dark_src ? 'true' : 'false'

  var header
  if (dark_text_img && logo_dark) {
    header = <img className="customer-waiver-header-img" alt={company.name} style={{width: "400px"}} src={styles.logo_dark_src}/>
  } else if (!dark_text_img && logo_light) {
    header = <img className="customer-waiver-header-img" alt={company.name} style={{width: "400px"}} src={styles.logo_light_src}/>
  } else {
    header = <h2>{company.name}</h2>
  }

  return (
    <div id="waiver-branding" className="center mt-5">
      {header}
    </div>
  )
}
