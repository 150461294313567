import React, { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import {
    selectStep,
    selectDisabled,
    setDisabled,
    setLocationAndCompany,
    setMembership,
    setCards,
    setTaxRate,
    selectTermsOpen,
    setMembershipsTaxable,
    setRkdReservationFee,
    setMerchantReservationFee,
    setMemberInformationField,
    setPurchaseFor,
    setReturnUrl,
} from "./memberSlice"

import MemberModalHeader from "./MemberModalHeader"
import SelectMembership from "./Steps/SelectMembership"
import MemberSteps from "../../lib/Member"
import SelectRecipient from "./Steps/SelectRecipent"
import MemberInformation from "./Steps/MemberInformation"
import GiftedMemberInformation from "./Steps/GiftedMemberInformation"
import MembershipTerm from "./Steps/MembershipTerm"
import GiftedBuyerInformation from "./Steps/GiftedBuyerInformation"
import MemberPayment from "./Steps/MemberPayment"
import Confirmation from "./Steps/Confirmation"
import TermsContent from "./TermsContent"
import { camelCase } from '@/lib/String'

export default function MemberModalBody({
    environment,
    companyId,
    locationId,
    locationTimeZone,
    membership=null,
    cards=[],
    occasionOptions=[],
    taxRate=0.0,
    disableGift=false,
    membershipsTaxable,
    rkdReservationFee,
    merchantReservationFee,
    existingMemberInformation,
    purchaseFor,
    returnUrl,
    disabled:initiallyDisabled=false,
}) {
    const dispatch  = useDispatch()
    const step      = useSelector(selectStep)
    const termsOpen = useSelector(selectTermsOpen)
    const disabled  = useSelector(selectDisabled)

    useEffect(() => {
        dispatch(setDisabled(initiallyDisabled))

        dispatch(setLocationAndCompany({
            locationId: locationId,
            companyId: companyId,
        }))

        dispatch(setMembership(membership))
        dispatch(setCards(cards))
        dispatch(setTaxRate(taxRate))
        dispatch(setMembershipsTaxable(membershipsTaxable))
        dispatch(setRkdReservationFee(rkdReservationFee))
        dispatch(setMerchantReservationFee(merchantReservationFee))
        dispatch(setPurchaseFor(purchaseFor))
        dispatch(setReturnUrl(returnUrl))

        // prefill membership info in redux if any is passed in
        if (!!existingMemberInformation && typeof existingMemberInformation === 'object') {
            Object.entries(existingMemberInformation).forEach(([name,value]) => {
                dispatch(setMemberInformationField({
                    name: name === 'zip_code' ? name : camelCase(name),
                    value: value
                }))
            })
        }

    }, [locationId, companyId])

    const renderStep = useCallback(() => {
        if (disabled) { return <h2 className='text-center py-5 w-100'>Membership Signup Is Disabled.</h2> }

        if (termsOpen) { return <TermsContent /> }

        switch (step) {
            case MemberSteps.SELECT_MEMBER_TYPE :
                return <SelectMembership hideMobileProgressHeader />

            case MemberSteps.SELECT_RECIPIENT :
                return <SelectRecipient disableGift={disableGift} hideMobileProgressHeader />

            case MemberSteps.MEMBERSHIP_TERM :
                return <MembershipTerm hideMobileProgressHeader />

            case MemberSteps.MEMBER_INFORMATION :
                return <MemberInformation />

            case MemberSteps.GIFTED_MEMBER_INFORMATION :
                return <GiftedMemberInformation occasionOptions={occasionOptions} />

            case MemberSteps.GIFTED_BUYER_INFORMATION :
                return <GiftedBuyerInformation />

            case MemberSteps.PAYMENT_INFORMATION : return (
                <MemberPayment
                    environment={environment}
                    locationId={locationId}
                    locationTimeZone={locationTimeZone}
                />
            )

            case MemberSteps.CONFIRMATION :
                return <Confirmation />

            default :
                return <h2>Step: [{step}] not implemented yet.</h2>
        }
    }, [step, termsOpen, disableGift, disabled])

    return (
        <div className="container-fluid px-0">
            <div id="member-modal-body" className="xbm-inner">
                {step !== MemberSteps.SELECT_MEMBER_TYPE && <MemberModalHeader />}
                { renderStep() }
            </div>
        </div>
    )
}
