import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import Select from '@/components/Form/Select'
import FormRow from '@/components/Form/FormRow'
import Checkbox from '@/components/Form/Checkbox'
import {
  selectMembershipTypeId,
  setMembershipTypeId,
  selectFormErrors,
  setMembershipTermIndex,
  selectMembershipTermIndex,
  selectAutoRenew,
  setAutoRenew,
  setMembershipType,
  selectMembershipType,
  setSelectedTerm,
} from '@/features/Members/memberSlice'

export default function MembershipInformation({ membershipTypeOptions }) {
  const dispatch            = useDispatch()
  const membershipTypeId    = useSelector(selectMembershipTypeId)
  const membershipTermIndex = useSelector(selectMembershipTermIndex)
  const errors              = useSelector(selectFormErrors)
  const autoRenew           = useSelector(selectAutoRenew)
  const membershipType      = useSelector(selectMembershipType)

  const [termsOptions, setTermsOptions] = useState([])

  useEffect(() => {
    dispatch(setMembershipTermIndex(0))

    if (membershipTypeId !== '') {
      axios.get(`/memberships/get_membership_type_terms`, {
        params: {
          membership_type_id: membershipTypeId
        }
      })
      .then(({ data }) => {
        setTermsOptions(data.membership_terms_options)
        dispatch(setMembershipType(data.membership_type))
        dispatch(setSelectedTerm(data.membership_type.membership_terms[0]))
      })
    }
  }, [membershipTypeId])

  return <>
    <h5 className='border-bottom my-3'>Membership Information</h5>

    <FormRow>
      <Select
        cols='6'
        className='form-control'
        label='Membership Type'
        value={membershipTypeId}
        validation={{ 'membership_type_id': {
          required: true,
        }}}
        includeBlank='Select one'
        options={membershipTypeOptions}
        errors={errors}
        handleChange={e => dispatch(setMembershipTypeId(e.target.value))}
      />
    </FormRow>

    {membershipType && termsOptions.length > 0 &&
      <FormRow className='align-items-center'>
        <Select
          cols='6'
          className='form-control'
          label='Membership Term'
          value={membershipTermIndex}
          validation={{ 'membership_term_index': {
            required: true,
          }}}
          options={termsOptions}
          errors={errors}
          handleChange={e => {
            dispatch(setMembershipTermIndex(parseInt(e.target.value)))
            dispatch(setSelectedTerm(membershipType.membership_terms[parseInt(e.target.value)]))
          }}
        />
        {membershipType?.renewal_option === 'allow_renewal' &&
          <Checkbox
            name='auto_renew'
            cols='6'
            className='mt-4 pl-3'
            label='Auto-Renew'
            value={autoRenew}
            handleChange={(_e) => dispatch(setAutoRenew(!autoRenew))}
          />
        }
      </FormRow>
    }
  </>
}
