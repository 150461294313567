import { createSlice } from "@reduxjs/toolkit"
import axios from 'axios'
import { setBooking } from "../EditBooking/editBookingSlice";
import { addAlert } from '@/features/Notifications/notificationSlice'
import { PREVENT_LOADER } from '@/lib/Storage'
import { debug } from '@/lib/Debug'
import { isEmpty } from "lodash";
// import { calculateCustomerTypeCountsWithMembers } from '../../lib/Member'

export const bookingSlice = createSlice({
    name: 'booking',
    initialState: {
        loading: false,
        preloaded: false,
        showReminder: false,
        reminderAcknowledged: null,
        step: '1',
        customerTypeCounter: 0,
        customerTypeLocked: false,
        trackAbandonedUrl: null,
        finalizeBookingUrl: null,
        holdBookingUrl: null,
        holdTimeDurationMinutes: null,
        held: false,
        expired: false,
        errors: [],
        hasPromoCodeApplied: false,
        giftCardValid: null,
        paymentMethod: 'credit_card',
        willSaveCreditCard: false,
        customerTypesWithoutPricesForSelectedDuration: [],
        trackedReservationNumber: null,
        booking: {
            id:                 null,
            participants:       null,
            duration:           null,
            date:               null,
            time:               null,
            customerTypeCounts: {},
            memberCount:        0,
            uniformParticipantCount: 0,
            members:            [],
            fullName:           "",
            phone:              "",
            email:              "",
            emailConfirm:       "",
            referral:           "",
            occasion:           "",
            note:               "",
            /**
             * @TODO a package is loaded and we get the available payment options, we should actually be
             * dispatching here to update this field to what the default should be if full_amount is not available.
             */
            paymentType:        "full_amount",
            paymentReady:       false,
            giftCardNumber:     "",
            giftCards:          [],
            creditNumber:       "",
            creditExpiration:   "",
            creditCvv:          "",
            creditZip:          "",
            creditToken:        null,
            promoCode:          "",
            reservationNumber:  "",
            updatedAt:          null,
            numberOfResources:  null,
        }
    },
    reducers: {
        setErrors: (state, action) => {
          if (action.payload.type === 'add') {
              state.errors = [ ...state.errors, ...action.payload.errors ]
          }

          if (action.payload.type === 'clear') {
              state.errors = []
          }
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        preloadBookingData: (state, action) => {
            if (Boolean(action.payload) && typeof action.payload === 'object' && Object.keys(action.payload).length > 0) {
                state.preloaded = true
            }
            state.booking = { ...state.booking, ...action.payload }
        },
        setShowReminder: (state, action) => {
            state.showReminder = action.payload
        },
        setReminderAcknowledged: (state, action) => {
            state.reminderAcknowledged = action.payload
        },
        setStep: (state, action) => {
            state.step = action.payload
        },
        setParticipants: (state, action) => {
            state.booking.participants = action.payload
        },
        setUpdatedAt: (state, action) => {
            state.booking.updatedAt = action.payload
        },
        setDuration: (state, action) => {
            state.booking.duration = action.payload
        },
        setDate: (state, action) => {
            state.booking.date = action.payload
        },
        setTime: (state, action) => {
            state.booking.time = action.payload
        },
        setCustomerTypesWithoutPricesForSelectedDuration: (state, action) => {
            state.customerTypesWithoutPricesForSelectedDuration = action.payload
        },
        setCustomerTypeCounts: (state, action) => {
            const typeCount = state.booking.customerTypeCounts[action.payload.customerTypeId]
            const add = !typeCount || action.payload.count > typeCount

            if (add) {
                ++state.customerTypeCounter
            } else {
                --state.customerTypeCounter
            }

            state.booking.customerTypeCounts[action.payload.customerTypeId] = action.payload.count
        },
        setMemberCount: (state, action) => {
            state.booking.memberCount = action.payload
            if (action.payload === 0) {
                state.booking.members = []
            }
        },
        setUniformParticipantCount: (state, action) => {
            state.booking.uniformParticipantCount = action.payload
        },
        setMembers: (state, action) => {
            state.booking.members = action.payload
        },
        setMember: (state, action) => {
            let members = state.booking.members

            if (isEmpty(members)) {
                members = [...Array(state.booking.memberCount)]
            }

            members[action.payload.index] = {
                memberId: action.payload.memberId,
                errors: []
            }

            state.booking.members = members
        },
        resetCustomerTypes: (state, _action) => {
            state.customerTypeCounter = 0
            state.booking.customerTypeCounts = {}
            state.booking.memberCount = 0
            state.booking.uniformParticipantCount = 0
            state.booking.members = []
        },
        setBookingFormValue: (state, action) => {
            state.booking[action.payload.name] = action.payload.value
        },
        setHoldTimeDurationMinutes: (state, action) => {
            state.holdTimeDurationMinutes = action.payload
        },
        setHoldBookingUrl: (state, action) => {
            state.holdBookingUrl = action.payload
        },
        setTrackAbandonedUrl: (state, action) => {
            state.trackAbandonedUrl = action.payload
        },
        setFinalizeBookingUrl: (state, action) => {
            state.finalizeBookingUrl = action.payload
        },
        setHeld: (state, action) => {
            state.held = action.payload
        },
        setExpired: (state, action) => {
            state.expired = action.payload
        },
        setReferral: (state, action) => {
            state.booking.referral = action.payload
        },
        setOccasion: (state, action) => {
            state.booking.occasion = action.payload
        },
        setBookingId: (state, action) => {
            state.booking.id = action.payload
        },
        setReservationNumber: (state, action) => {
            state.booking.reservationNumber = action.payload
        },
        setNumberOfResources: (state, action) => {
            state.booking.numberOfResources = action.payload
        },
        setTaxesAndFeesVerbiage: (state, action) => {
            state.booking.taxes_and_fees_verbiage = action.payload
        },
        applyPromoCode: (state, action) => {
            state.booking.promoCode   = action.payload
            state.hasPromoCodeApplied = true
        },
        removePromoCode: (state) => {
            state.booking.promoCode   = ''
            state.hasPromoCodeApplied = false
        },
        clearBookingPaymentTypes: (state, _action) => {
            const fields = [
                'giftCardNumber',
                'creditNumber',
                'creditExpiration',
                'creditCvv',
                'creditZip'
            ]

            fields.map((field) => {
                if (state.booking[field] !== '') {
                    state.booking[field] = ''
                }
            })
        },
        setPaymentReady: (state, action) => {
            state.booking.paymentReady = action.payload
        },
        setPaymentType: (state, action) => {
            state.booking.paymentType = action.payload
        },
        setCreditToken: (state, action) => {
            state.booking.creditToken = action.payload
        },
        setPaymentMethod: (state, action) => {
            state.paymentMethod = action.payload
        },
        setWillSaveCreditCard: (state, action) => {
            state.willSaveCreditCard = action.payload
        },
        setGiftCards: (state, action) => {
            if (action.payload.type === 'add') {
                state.booking.giftCards = [...state.booking.giftCards, action.payload.newGiftCard]
            } else if (action.payload.type === 'clear') {
                state.booking.giftCards = []
            }
        },
        setGiftCardNumber: (state, action) => {
            state.booking.giftCardNumber = action.payload
        },
        setTrackedReservationNumber: (state, action) => {
            state.trackedReservationNumber = action.payload
        },
    }
})

export const {
    preloadBookingData,
    setLoading,
    setShowReminder,
    setReminderAcknowledged,
    setErrors,
    setStep,
    setParticipants,
    setMemberships,
    setDuration,
    setDate,
    setTime,
    setCustomerTypesWithoutPricesForSelectedDuration,
    setCustomerTypeCounts,
    setMemberCount,
    setMembers,
    setMember,
    setBookingFormValue,
    setHoldTimeDurationMinutes,
    setHoldBookingUrl,
    setTrackAbandonedUrl,
    setFinalizeBookingUrl,
    setHeld,
    setExpired,
    setReferral,
    setOccasion,
    setBookingId,
    setReservationNumber,
    setNumberOfResources,
    setTaxesAndFeesVerbiage,
    applyPromoCode,
    removePromoCode,
    resetCustomerTypes,
    clearBookingPaymentTypes,
    setPaymentReady,
    setPaymentType,
    setCreditToken,
    setAdyenPayment,
    setPaymentMethod,
    setWillSaveCreditCard,
    setUpdatedAt,
    setGiftCards,
    setGiftCardNumber,
    setTrackedReservationNumber,
    setUniformParticipantCount,
} = bookingSlice.actions

export const selectLoading                 = state => state.booking.loading
export const selectPreloaded               = state => state.booking.preloaded
export const selectErrors                  = state => state.booking.errors
export const selectStep                    = state => state.booking.step
export const selectReminderAcknowledged    = state => state.booking.reminderAcknowledged
export const selectShowReminder            = state => state.booking.showReminder
export const selectParticipants            = state => state.booking.booking.participants
export const selectMemberships             = state => state.booking.booking.members
export const selectDuration                = state => state.booking.booking.duration
export const selectDate                    = state => state.booking.booking.date
export const selectTime                    = state => state.booking.booking.time
export const selectCustomerTypeCounts      = state => state.booking.booking.customerTypeCounts
export const selectMemberCount             = state => state.booking.booking.memberCount
export const selectUniformParticipantCount = state => state.booking.booking.uniformParticipantCount
export const selectMembers                 = state => state.booking.booking.members
export const selectCustomerTypeLocked      = state => state.booking.customerTypeLocked
export const selectHoldTimeDurationMinutes = state => state.booking.holdTimeDurationMinutes
export const selectHeld                    = state => state.booking.held
export const selectExpired                 = state => state.booking.expired
export const selectReferral                = state => state.booking.booking.referral
export const selectOccasion                = state => state.booking.booking.occasion
export const selectBooking                 = state => state?.booking?.booking
export const selectHasPromoCodeApplied     = state => state.booking.hasPromoCodeApplied
export const selectPaymentType             = state => state?.booking?.booking?.paymentType
export const selectPromoCode               = state => state.booking.booking.promoCode
export const selectCreditToken             = state => state.booking.booking.creditToken
export const selectCreditZip               = state => state.booking.booking.creditZip
export const selectPaymentMethod           = state => state.booking.paymentMethod
export const selectWillSaveCreditCard      = state => state.booking.willSaveCreditCard
export const selectReservationNumber       = state => state.booking.reservationNumber
export const selectNumberOfResources       = state => state.booking.booking.numberOfResources
export const selectGiftCards               = state => state?.booking?.booking?.giftCards
export const selectPaymentReady            = state => state.booking?.booking?.paymentReady || null

export const selectCustomerTypesWithoutPricesForSelectedDuration = state => {
    return state.booking.customerTypesWithoutPricesForSelectedDuration?.[state.booking.booking.duration] || []
}

export function validateGiftCard(card_number, location_id) {
    return async (_, getState) => {
        return axios.post(`/gift-cards/${card_number}/validate`, {
            authenticity_token: getState().session.formToken,
            location_id,
            card_number
        }).then(response => {
            return response.data.success
        }).catch((error) => {
            if (debug && console) { console.error(error) }
        })
    }
}

export function fetchGiftCardBalance(card_number) {
    return async (_, getState) => {
        if (getState().booking?.giftCardValid === false) { return false }

        return axios.post(`/gift-cards/${card_number}/balance`, {
            authenticity_token: getState().session.formToken,
            card_number
        }).then(({ data }) => {
            return { cardNumber: card_number, balance: data.balance }
        }).catch((error) => {
            if (debug && console) { console.error(error) }
        })
    }
}

export function clearBookingCalendars() {
    return async (dispatch, getState) => {
        const bookingId = getState().booking.booking.id

        if (/null/i.test(bookingId)) { return }

        axios.post(`/bookings/${bookingId}/clear_calendars`, {
            authenticity_token: getState().session.formToken,
        }).then(async () => {
            await dispatch(setBookingId(null))
            await dispatch(setReservationNumber(''))
            await dispatch(setNumberOfResources(null))
            await dispatch(setTaxesAndFeesVerbiage(null))
            await dispatch(setUpdatedAt(null))
            dispatch(setHeld(false))
        }).catch((error) => {
            console.log(error)
        })
    }
}

export function holdBooking() {
    return async (dispatch, getState) => {
        const booking = getState().booking.booking
        const url     = getState().booking.holdBookingUrl

        if (debug && console) { console.log('>>> (edit) Holding the booking...', booking, url) }

        if (!booking || !url) {
            if (debug && console) { console.log('!!! Dependencies not met while trying to hold the booking', booking, url) }
            dispatch(addAlert({ type: 'error', text: 'Could not hold the booking.' }))
            return
        }

        axios.post(url, {
            authenticity_token:   getState().session.formToken,
            participants:         booking.participants,
            customer_type_counts: booking.customerTypeCounts,
            duration:             booking.duration,
            date:                 booking.date,
            time:                 booking.time,
            calculate_price:      true
        })
        .then(async ({ data }) => {
            if (data.success) {
                if (debug && console) { console.log(data.message, data) }
                await dispatch(setBookingId(data.booking.id))
                await dispatch(setReservationNumber(data.booking.reservation_number))
                await dispatch(setNumberOfResources(data.booking.number_of_resources))
                await dispatch(setTaxesAndFeesVerbiage(data.booking.taxes_and_fees_verbiage))
                await dispatch(setUpdatedAt(data.booking.updated_at))
                dispatch(setHeld(true))
                return
            }
            dispatch(addAlert({ type: 'error', text: data.message }))
        })
        .catch((error) => {
            if (debug && console) { console.error(error?.response?.data || error) }
            dispatch(addAlert({ type: 'error', text: (error?.response?.data?.message || 'Could not hold the booking!') }))
        })
    }
}

export function updateBooking() {
    return async (dispatch, getState) => {

        const booking = getState().booking.booking

        axios.post(getState().booking.holdBookingUrl, {
            authenticity_token: getState().session.formToken,
            participants:       booking.participants,
            duration:           booking.duration,
            date:               booking.date,
            time:               booking.time,
            booking_id:         booking.id
        }).then(async ({ data }) => {
            if (debug && console) { console.log('Booking held: ', data) }
            await dispatch(setBookingId(data.booking.id))
            await dispatch(setReservationNumber(data.booking.reservation_number))
            await dispatch(setNumberOfResources(data.booking.number_of_resources))
            await dispatch(setTaxesAndFeesVerbiage(data.booking.taxes_and_fees_verbiage))
            await dispatch(setUpdatedAt(data.booking.updated_at))
            dispatch(setHeld(true))
        }).catch((error) => {
            if (debug && console) { console.warn(error) }
        }).finally(() => {
            if (debug && console) { console.log('Reset any booking loading states, etc...') }
        })
    }
}

export function trackAbandonedBooking() {
    return async (dispatch, getState) => {

        const location   = getState().location.location
        const booking    = getState().booking.booking
        const prevResNum = getState().booking.trackedReservationNumber
        const url        = getState().booking.trackAbandonedUrl
        const pkg        = getState().package.package

        if (!location?.id || !pkg || !booking || !booking?.reservationNumber || !url) { return }

        /*
         * Destroy the abandonment tracking record for the previous booking
         * hold if one is present since the customer has changed their mind
         * and a new booking hold has been generated
         */
        if (!!prevResNum && prevResNum !== booking.reservationNumber) {
            if (debug && console) { console.log(`>>> Deleting booking abandonment tracking record for ${prevResNum}...`) }

            axios.delete(getState().booking.trackAbandonedUrl.replace(/tracking/, `tracking/${prevResNum}`), {
                authenticity_token: getState().session.formToken,
            }).then(({ data }) => {
                if (debug && console) { console.log('>>> ...Done', data) }
            }).catch((error) => {
                if (debug && console) { console.error(error) }
            })
        }

        /*
         * Track the abandonment state of the booking hold
         * if we have a way of contacting the customer
         */
        if (booking?.email) {
            if (debug && console) { console.log('>>> Tracking booking abandonment...') }

            axios.post(getState().booking.trackAbandonedUrl, {
                authenticity_token: getState().session.formToken,
                reservation_number: booking.reservationNumber,
                location_id: location.id,
                package: pkg,
                data: Object.fromEntries(Object.entries(booking).filter(([key]) => !/credit/i.test(key)))
            }).then(({ data }) => {
                if (debug && console) { console.log('>>> ...Done', data) }
                dispatch(setTrackedReservationNumber(booking.reservationNumber))
            }).catch((error) => {
                if (debug && console) { console.error(error) }
            })
        }
    }
}

export function finalizeBooking() {
    return async (dispatch, getState) => {
        const booking            = getState().booking.booking
        const url                = getState().booking.finalizeBookingUrl
        const paymentMethod      = getState().booking.paymentMethod
        const willSaveCreditCard = getState().booking.willSaveCreditCard
        const adyenPayment       = getState().adyen.adyenPayment

        if (/^(credit_card|multiple)$/i.test(paymentMethod)) {
            // we check for credit token (paysafe) being empty AND no adyen payment cc object.
            if (booking.creditToken === null && !adyenPayment) {
                dispatch(addAlert({ type: 'error', text: 'Please enter valid credit card.' }))
                return false
            }

            if (!!booking?.creditToken && (booking?.creditZip || '').length < 5) {
                dispatch(addAlert({ type: 'error', text: 'Please enter a valid zip code.' }))
                return false
            }
        }

        if (debug && console) { console.log('>>> Finalizing booking...', booking, url) }

        if (!booking || !url) {
            if (debug && console) { console.log('!!! Dependencies not met while trying to finalize booking', booking, url) }
            dispatch(addAlert({ type: 'error', text: 'Could not finalize booking.' }))
            return false
        }

        // temporarily disable the usual loading animation and immediately
        // display the one specific to finalizing a booking instead
        window.sessionStorage.setItem(PREVENT_LOADER, true)
        dispatch(setLoading(true))

        const customerTypeCounts = getState()?.booking?.booking?.customerTypeCounts || null

        axios.post(url, {
            authenticity_token:          getState().session.formToken,
            'g-recaptcha-response-data': window.document.getElementById('g-recaptcha-response-data-checkout').value,
            booking_id:                  booking.id,
            participants:                booking.participants,
            duration:                    booking.duration,
            date:                        booking.date,
            time:                        booking.time,
            customer_type_counts:        customerTypeCounts,
            full_name:                   booking.fullName,
            phone:                       booking.phone,
            email:                       booking.email,
            email_confirm:               booking.emailConfirm,
            referral_type:               booking.referral,
            occasion_type:               booking.occasion,
            note:                        booking.note,
            payment_type:                booking.paymentType,
            credit_zip:                  booking.creditZip,
            credit_token:                booking.creditToken,
            adyen_payment:               adyenPayment,
            save_credit_card:            willSaveCreditCard,
            gift_cards:                  booking.giftCards,
            promo_code:                  booking.promoCode,
            members:                     booking.members,
            memberships:                 JSON.stringify(booking.members)
        })
        .then(({ data }) => {
            if (data.success) {
                if (debug && console) { console.log(data.message) }
                window.sessionStorage.removeItem(PREVENT_LOADER)
                dispatch(setBooking({ booking: data.booking, resources: data.resources }))
                dispatch(setReservationNumber(data.booking.reservation_number))
                dispatch(setStep('confirmation'))
                return
            }

            if (/(expired)/i.test(data.message)) {
                dispatch(setExpired(true))
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        })
        .catch((error) => {
            if (debug && console) { console.error(error?.response?.data || error) }

            if (/(expired)/i.test(error?.response?.data?.message)) {
               dispatch(setExpired(true))
            } else {
                dispatch(addAlert({ type: 'error', text: (error?.response?.data?.message || 'Could not finalize booking!') }))
            }
        })
        .finally(() => {
            dispatch(setLoading(false))
        })
    }
}

/**
 * Send request to check member ids for location from the database.
 */
export function checkMemberIds() {
    return async (dispatch, getState) => {
        const locationId = getState().location.location.id
        const booking    = getState().booking.booking
        const members    = booking.members
        const duration   = booking.duration
        const date       = booking.date
        const time       = booking.time

        axios.post(`/locations/${locationId}/members/check_membership_ids`, {
            members:  members,
            duration: duration,
            date:     date,
            time:     time,
        })
        .then(({ data }) => {
            // overwrite member data in store with response which will have
            // all of the same members but with added errors if found
            dispatch(setMembers(data.members))
            // when there are no errors we want to take the user to step 5...
            const errors = !isEmpty(data.members.filter(m => !isEmpty(m.errors)))
            if (!errors) {
                dispatch(setStep('5'))
            }
        })
        .catch((error) => {
            dispatch(addAlert({ type: 'error', text: (error?.response?.data?.message || 'Could not check member ids!') }))
        })
    }
}

export default bookingSlice.reducer
