import React from 'react'
import { customerWaiverBadge, simpleCustomerWaiverBadge } from '@/lib/Customer'

export default function CustomerBadges({
    customer=null,
    waiversRequired=false,
    bookingActiveWaiver=null,
    simpleWaiverBadge=false,
    includeMemberId=true,
    compact=false,
}) {
    return !!customer && <>
        <span className='mb-1 mr-n1'>
            {
                simpleWaiverBadge
                    ? simpleCustomerWaiverBadge(customer.has_signed_waiver, customer.signed_waiver_is_valid, compact)
                    : waiversRequired && !!bookingActiveWaiver && customerWaiverBadge(bookingActiveWaiver, customer, compact)
            }
        </span>

        <span className={`badge badge-${customer.under_21 ? 'warning' : 'default'} ${compact ? 'py-1' : ''} mr-1 mb-1`}>
            {
                customer.under_21
                    ? customer.minor ? 'Minor' : 'Under 21'
                    : '21+'
            }
        </span>

        <span className={`badge badge-${customer.total_visits === 0 ? 'warning' : 'default'} ${compact ? 'py-1' : ''} mr-1 mb-1`}>
            { customer.total_visits === 0 ? 'First Timer' : 'Return Customer' }
        </span>

        {
            includeMemberId && customer.member?.active_membership?.membership_id && (
                <span className={`badge badge-info ${compact ? 'py-1' : ''} mr-1 mb-1`}>
                    Member | {customer.member?.active_membership?.membership_id}
                </span>
            )
        }
    </>
}
