import React from 'react'
import { useDispatch } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import { setInputLocked } from '@/features/Calendar/calendarSlice'
import { setStep } from '@/features/Schedule/scheduleSlice'
import { resetBooking } from '@/features/EditBooking/editBookingSlice'
import { clearPrices } from '@/features/Pricing/pricingSlice'
import { CREATE_RESERVATION } from '@/lib/Storage'

export default function NewQuoteFromExistingQuote({ quote={} }) {

    const dispatch  = useDispatch()
    const { reset } = useFormContext()

    const newButton = document.getElementById('new-quote-or-reservation')

    const handleCreate = async () => {
        const newQuote = {
            // this triggers the quoting mode
            is_quote: true,
            require_email_address: false,

            name: quote.name,
            email: quote.email,
            phone: quote.phone,
            note: quote.note,
            source_type: quote.source_type,
            occasion_type: quote.occasion_type,
            referral_type: quote.referral_type,
        }

        window.localStorage.setItem(CREATE_RESERVATION, JSON.stringify(newQuote))

        reset()
        dispatch(setInputLocked(true))
        dispatch(resetBooking())
        dispatch(clearPrices())
        dispatch(setStep('1'))
        dispatch(setInputLocked(false))
    }

    return !!newButton && (
        <button
            type="button"
            className="btn btn-primary"
            onClick={handleCreate}
        >
            Create A New Quote Based On This Quote
        </button>
    )
}
