import React, { useState } from 'react'
import Icon from '@/components/FontAwesomeIcon'
import MenuBuilderList from '@/components/AdvancedPointOfSale/Menus/MenuBuilderList'
import { useConfirm } from '@/lib/useConfirmHook'
import { parameterize } from 'inflected'

export default function MenuBuilderListItem({
    parentIdKey='id',
    iconType='is_default',
    item=null,
    index=null,
    dnd=[],
    isDefault=false,
    isSubMenuCollapsedOnLoad=false,
    subMenuIsDefaultIconClass='fa-bookmark',
    debug=false,
    onAdd=() => {},
    onRemove=() => {},
    onDefaultChange=() => {},
    onSort=() => {},
}) {

    const [provided, snapshot]                        = dnd
    const { confirm }                                 = useConfirm()
    const [isSubMenuCollapsed, setIsSubMenuCollapsed] = useState(isSubMenuCollapsedOnLoad)

    const isPresent = item?.is_present === undefined ? false : item.is_present

    const handleAdd = () => {
        if (/category/i.test(item.type)) {
            setIsSubMenuCollapsed(false)
        }
        onAdd(item)
    }

    const handleRemove = async () => {
        onRemove(item, index)

        if (/category/i.test(item.type)) {
            const warningText = "This will prevent this item and any of its \
                                 descendents from displaying on this menu."

            if (!await confirm(warningText, { header_text: 'Are you sure?', size: 'sm' })) { return }

            setIsSubMenuCollapsed(true)
        }

        onRemove(item, index)
    }

    const hasNestedContent = () => (
        hasNestedCategories() || hasNestedItems()
    )

    const hasNestedCategories = () => (
        Array.isArray(item?.categories) && item.categories.length > 0
    )

    const hasNestedItems = () => (
        Array.isArray(item?.items) && item.items.length > 0
    )

    const itemClasses = [
        'row d-flex item align-items-center border-bottom mx-0',
        (hasNestedContent() ? 'pt-2 pb-1 has-sub-items' : 'py-1 no-sub-items'),
    ].join(' ').trim()

    return !!item && (
        <div
            key={parameterize(`${item.menu_uuid}-${item.id}-${item.name}`)}
            className={`${snapshot.isDragging ? 'dragging' : 'draggable'} ${isSubMenuCollapsed ? 'collapsed' : 'expanded'}`.trim()}
            {...(!!provided.innerRef ? { ref: provided.innerRef } : {})}
            {...provided.draggableProps}
        >
            <div className={itemClasses}>
                <div className={`col name h6 pl-2 mb-0 d-flex align-items-center ${isPresent ? '' : 'disabled'}`.trim()}>
                    {
                        !debug && (
                            <Icon
                                className='cursor-drag draggable d-fas text-muted h5 mb-0 mr-4 pr-2'
                                icon='fa-bars'
                                packs={['fa-solid']}
                                {...provided.dragHandleProps}
                            />
                        )
                    }

                    {
                        /item/i.test(item?.type) && (
                            <button
                                type='button'
                                className='btn btn-link px-1 mr-2'
                                onClick={() => onDefaultChange({ id: item.id, toState: !isDefault, parentMenuUUID: item.menu_uuid })}
                            >
                                <span className={isDefault ? 'd-block' : 'd-none'}><i className='fa-solid fa-bookmark text-primary fa-lg' /></span>
                                <span className={isDefault ? 'd-none' : 'd-block'}><i className='fa-light fa-bookmark text-dark fa-lg' /></span>
                            </button>
                        )
                    }

                    {
                        hasNestedContent() && (
                            <button
                                title={`Click to ${isSubMenuCollapsed ? 'Expand' : 'Collapse'}`}
                                type='button'
                                className='btn btn-link mr-2 px-1'
                                onClick={() => setIsSubMenuCollapsed(!isSubMenuCollapsed)}
                            >
                                <span className={isSubMenuCollapsed ? '' : 'd-none'}><i className='fa-solid fa-caret-right fa-xl' /></span>
                                <span className={isSubMenuCollapsed ? 'd-none' : ''}><i className='fa-solid fa-caret-down fa-xl' /></span>
                            </button>
                        )
                    }

                    <div className={`${/category/i.test(item?.type) ? 'font-weight-bolder' : ''} w-100`.trim()}>
                        { debug ? `(ID: ${item[parentIdKey]}) ` : '' }{ item.name }
                    </div>
                </div>

                <div className='col-auto text-right item--actions pr-0 d-flex align-items-center'>
                    {
                        !debug && <>
                            <button
                                children={<i className='fa-solid fa-eye-slash' />}
                                title='Click to remove this item'
                                type='button'
                                className={`btn btn-link btn-link-danger ${isPresent ? '' : 'd-none'}`.trim()}
                                onClick={handleRemove}
                            />

                            <button
                                children={<i className='fa-solid fa-plus-large' />}
                                title='Click to make this item available'
                                type='button'
                                className={`btn btn-link ${isPresent ? 'd-none' : ''}`.trim()}
                                onClick={handleAdd}
                            />
                        </>
                    }
                </div>
            </div>

            {
                hasNestedCategories() && (
                    <MenuBuilderList
                      className={`pl-5 ${isSubMenuCollapsed ? 'd-none' : ''}`.trim()}
                      items={item.categories}
                      itemsParentIdKey='id'
                      defaultItemIds={item?.default_item_ids}
                      isSubMenuCollapsedOnLoad={isSubMenuCollapsedOnLoad}
                      subMenuIsDefaultIconClass={subMenuIsDefaultIconClass}
                      iconType={iconType}
                      overrideInternalRemoveFunction={true}
                      debug={debug}
                      onAdd={onAdd}
                      onRemove={onRemove}
                      onDefaultChange={onDefaultChange}
                      onSort={(result, options) => onSort(result, (!!options.parentMenuUUID ? options : {...options, parentMenuUUID: item.menu_uuid, collection: 'categories', isNested: true})) }
                    />
                )
            }

            {
                hasNestedItems() && (
                    <MenuBuilderList
                      className={`pl-5 ${isSubMenuCollapsed ? 'd-none' : ''} ${hasNestedCategories() ? 'border-top border-thick' : ''}`.trim()}
                      items={item.items}
                      itemsParentIdKey='id'
                      defaultItemIds={item?.default_item_ids}
                      isSubMenuCollapsedOnLoad={isSubMenuCollapsedOnLoad}
                      subMenuIsDefaultIconClass={subMenuIsDefaultIconClass}
                      iconType={iconType}
                      overrideInternalRemoveFunction={true}
                      debug={debug}
                      onAdd={onAdd}
                      onRemove={onRemove}
                      onDefaultChange={onDefaultChange}
                      onSort={(result, options) => onSort(result, {...options, parentMenuUUID: item.menu_uuid, collection: 'items', isNested: true})}
                    />
                )
            }
        </div>
    )
}
