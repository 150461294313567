import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { selectIsFormValid, setCreditToken, setUnableToContinue } from '@/features/GiftCards/giftCardSlice'
import { addAlert } from '@/features/Notifications/notificationSlice'
import { debug } from '@/lib/Debug'

export default function PurchaseModalCreditCard() {

    const dispatch = useDispatch()

    const [init, setInit] = useState(false)

    useEffect(() => {
        if (init) { return }

        setInit(true)
        dispatch(setCreditToken(null))

        // request payment credentials
        axios.get('/credentials/payments').then(({ data }) => {
            if (debug && console) { console.log(data) }
            initializePaysafe(data.username, data.password, data.environment)
        })
    }, [init])

    const initializePaysafe = (username, password, environment) => {
        const apiKey = window.btoa(`${username}:${password}`)

        const options = {
            environment: environment,
            fields: {
                cardNumber: {
                    selector: '#cardNumber',
                    placeholder: 'XXXX XXXX XXXX XXXX',
                    separator: ' '
                },
                expiryDate: {
                    selector: '#expiryDate',
                    placeholder: 'MM/YY'
                },
                cvv: {
                    selector: '#cvv',
                    placeholder: 'XXX',
                    optional: false
                }
            }
        }

        window.paysafe.fields.setup(apiKey, options, (instance, error) => {
            if (error) {
                if (console) { console.error('Paysafe setup error: ', error) }
                dispatch(setUnableToContinue())
                return
            }

            // listen to payment button click -- when that happens it will just get a token,
            // this will not submit a form or anything -- that is passed into this component
            // and is fired off when a token is passed in as a prop to ensure it's set since
            // everything is async
            document.getElementById('finish-button').addEventListener('payment-ready', (e) => {
                instance.tokenize((instance, error, result) => {
                    if (error) {
                        if (console) { console.error('Paysafe error: ', error) }
                        dispatch(addAlert({ type: 'error', text: error.displayMessage }))
                    } else {
                        dispatch(setCreditToken(result.token))
                    }
                })
            })
        })
    }

    return <>
        <div className="row">
            <div className="col-12">
                <div className="form-group">
                    <label htmlFor="card-number">
                        Credit Card Number
                        <span className="req">*</span>
                    </label>
                    <div id="cardNumber" className="form-control" />
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-6">
                <div className="form-group">
                    <label htmlFor="expiration-date">
                        Expiration
                        <span className="req">*</span>
                    </label>
                    <div id="expiryDate" className="form-control" />
                </div>
            </div>

            <div className="col-6">
                <div className="form-group">
                    <label htmlFor="cvv">
                        CVV
                        <span className="req">*</span>
                    </label>
                    <div id="cvv" className="form-control" />
                </div>
            </div>
        </div>
    </>
}
