import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import NumberIncrementer from "../../components/Form/NumberIncrementer"
import { selectBooking } from '../EditBooking/editBookingSlice'
import { selectCustomAmount, setCustomAmount } from "./bookingPaymentSlice"
import { numberToCurrency } from '@/lib/Number'

export default function PaymentCustomAmountSplit() {

    const dispatch     = useDispatch()
    const booking      = useSelector(selectBooking)
    const customAmount = useSelector(selectCustomAmount)

    const [splitRemaining, setSplitRemaining] = useState("")
    const [remainingTotal, setRemainingTotal] = useState("")
    const [splitPrice, setSplitPrice]         = useState("")
    const [priceTotal, setPriceTotal]         = useState("")

    const handleRemainingChange = (value) => {
        const target = Number.parseInt(typeof value === 'object' ? value.target.value : value)

        setSplitPrice("")
        setPriceTotal("")

        if (!!target) {
            setSplitRemaining(target)

            splitCustomAmount(booking.balance_cents, target, (amount) => {
                setRemainingTotal(amount)
            })
        } else {
            setSplitRemaining("")
            setRemainingTotal("")
        }
    }

    const handlePriceChange = (value) => {
        const target = Number.parseInt(typeof value === 'object' ? value.target.value : value)

        setSplitRemaining("")
        setRemainingTotal("")

        if (!!target) {
            setSplitPrice(target)

            splitCustomAmount(booking.price_cents, target, (amount) => {
                setPriceTotal(amount)
            })
        } else {
            setSplitPrice("")
            setPriceTotal("")
        }
    }

    const applyRemaining = () => {
        splitCustomAmount(booking.balance_cents, splitRemaining, (amount) => {
            dispatch(setCustomAmount(amount))
        })
    }

    const applyPrice = () => {
        splitCustomAmount(booking.price_cents, splitPrice, (amount) => {
            dispatch(setCustomAmount(amount))
        })
    }

    const splitCustomAmount = (amountCents, people, callback) => {
        if (!people) {
            callback("")
            return
        }

        const newAmount = Number.parseFloat(((amountCents / people) / 100).toFixed(2))
        callback(newAmount)
    }

    // clear the split UI if the custom amount is cleared
    useEffect(() => {
        if (customAmount == null) {
            setSplitPrice("")
            setPriceTotal("")
            setSplitRemaining("")
            setRemainingTotal("")
        }
    }, [customAmount])

    return (
        <div>
            <div className="form-row">
                <div className="col-5 d-flex align-items-center">
                    <div className="form-group w-100 p-0 text-right">
                        <label className="m-0">Split Reservation Total By</label>
                    </div>
                </div>
                <div className="col-7 pr-0 d-flex align-items-center">
                    <NumberIncrementer
                        name='splitPrice'
                        value={splitPrice}
                        incrementAmount={1}
                        min={0}
                        preventTyping
                        handleChange={handlePriceChange}
                    />

                    <div className="text-success mr-1 ml-3">
                        <strong>{numberToCurrency(priceTotal || 0)}</strong>
                    </div>

                    <button
                        children='APPLY'
                        type='button'
                        className="btn btn-primary py-0 px-3 ml-3"
                        disabled={!splitPrice}
                        onClick={applyPrice}
                    />
                </div>
            </div>

            <div className="form-row pt-2 mt-2">
                <div className="col-5 d-flex align-items-center">
                    <div className="form-group w-100 p-0 text-right">
                        <label className="m-0">Split Remaining Balance By</label>
                    </div>
                </div>
                <div className="col-7 pr-0 d-flex align-items-center">
                    <NumberIncrementer
                        name='splitRemaining'
                        value={splitRemaining}
                        incrementAmount={1}
                        min={0}
                        preventTyping
                        handleChange={handleRemainingChange}
                    />

                    <div className="text-success mr-1 ml-3">
                        <strong>{numberToCurrency(remainingTotal || 0)}</strong>
                    </div>

                    <button
                        children='APPLY'
                        type='button'
                        className="btn btn-primary py-0 px-3 ml-3"
                        disabled={!splitRemaining}
                        onClick={applyRemaining}
                    />
                </div>
            </div>
        </div>
    )
}
