import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectValid, setCode } from '../ManagerCodes/managerCodeSlice'
import DiscountAmountForm from './DiscountAmountForm'
import DiscountManagerCode from './DiscountManagerCode'

export default function Discount() {

    const dispatch = useDispatch()
    const valid    = useSelector(selectValid)

    return (
        <div className="row">
            <div className="col-12 pb-2">
                <strong>Add a Discount?</strong>
            </div>

            <div className="col-12">
                {
                    valid
                        ? <DiscountAmountForm onCancel={() => { dispatch(setCode('')) }} />
                        : <DiscountManagerCode />
                }
            </div>
        </div>
    )
}
