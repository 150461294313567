import React, { useState, useEffect } from "react"
import axios from "axios"
import { accessToken } from "@/lib/Csrf"

export default function QRCodeLink({ url, content }) {
  const [open, setOpen] = useState(false)
  const [png, setPng]   = useState(null)

  const handleClick = () => {
    axios.post("/qr_codes/generate", {
      authenticity_token: accessToken,
      url: url,
    })
    .then(({data}) => {
      setPng(data.png)
      setOpen(true)
    })
  }

  const handleKeyDown = e => {
    if (e.key === 'Escape') {
      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false)

    return () => {
      document.removeEventListener('keydown', handleKeyDown, false)
    }
  }, [])

  return <>
      <div onClick={handleClick} className="d-inline">
        <span dangerouslySetInnerHTML={{ __html: content }}></span>
      </div>

      <div className={`modal modal-backdrop confirmation--modal ${open ? "d-block" : "d-none"}`}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-4">
            <div className="d-flex justify-content-center text-secondary">
              <small className='text-muted font-italic'>
                Right click on the QR code below to save the image to your device
              </small>
            </div>

            <div className="modal-body py-3" style={{ maxHeight: "none" }}>
              <img src={png} className="mx-auto" />
            </div>

            <div className="d-flex justify-content-center">
              <button
                children='Close'
                type='button'
                className="btn btn-outline-primary mb-0"
                onClick={() => setOpen(false)}
              />
            </div>
          </div>
        </div>
      </div>
  </>
}
