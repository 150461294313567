import React, { useState } from 'react'
import Input from '@/components/Form/Input'
import Icon from '@/components/FontAwesomeIcon'
import MultiSelectExternalListSubItems from '@/components/Form/MultiSelectExternalListSubItems'
import { parameterize } from 'inflected'
import { sortedByArray } from '@/lib/Array'
import { debug } from '@/lib/Debug'

export default function MultiSelectExternalListItem({
    parentIdKey='id',
    iconType='is_default',
    item=null,
    index=null,
    dnd=[],
    isSortable=false,
    iconOnMainList=null,
    isSubMenuSortable=false,
    isSubMenuCollapsedOnLoad=false,
    subMenuIsDefaultIconClass=null,
    canLimitSubMenuItems=false,
    canLimitFieldName='limit',
    onRemove=() => {},
    onLimitUpdate=() => {},
    onIconChange=() => {},
    onSubItemsSort=() => {},
    onSubItemsIconChange=() => {},
    onSubItemsVisibilityChange=() => {},
}) {

    const [provided, snapshot] = dnd
    const [isSubMenuCollapsed, setIsSubMenuCollapsed] = useState(isSubMenuCollapsedOnLoad)

    const hasNestedCollection = (item) => {
        return Array.isArray(item?.collection) && item.collection.length > 0
    }

    const itemClasses = [
        'row d-flex item align-items-center border-bottom mx-0',
        (hasNestedCollection(item) ? 'py-3 has-sub-items' : 'py-2 no-sub-items'),
    ].join(' ')

    return !!item && (
        <div
            key={parameterize(`${item.id}-${item.name}`)}
            className={`${snapshot.isDragging ? 'dragging' : 'draggable'} ${isSubMenuCollapsed ? 'collapsed' : 'expanded'}`}
            {...(!!provided.innerRef ? { ref: provided.innerRef } : {})}
            {...provided.draggableProps}
        >
            <div className={itemClasses}>
                <div className='col name h6 pl-2 mb-0 d-flex align-items-center'>
                    {
                        isSortable && (
                            <Icon
                                className='cursor-drag draggable d-fas text-muted h5 mb-0 mr-4 pr-2'
                                icon='fa-bars'
                                packs={['fa-solid']}
                                {...provided.dragHandleProps}
                            />
                        )
                    }

                    {iconOnMainList &&
                        <Icon
                            icon={iconOnMainList}
                            className={`${item.is_default ? 'd-fas text-primary' : 'd-fal'} cursor-pointer fa-lg mr-3`}
                            onClick={() => onIconChange(item.item_id, !item.is_default)}
                        />
                    }

                    <span className='d-block w-100'>
                        {
                            hasNestedCollection(item)
                            ? (
                                <>
                                  <strong>{ debug ? `(ID: ${item[parentIdKey]}) ` : '' }{ item.name }</strong>
                                  <br />
                                  <small className='text-muted'>
                                      { item.collection.length } items
                                  </small>
                                </>
                            ) : (
                              <span>
                                { debug ? `(ID: ${item.id}) ` : '' }{ item.name }
                              </span>
                            )
                        }
                    </span>
                </div>

                <div className='col-auto text-right item--actions pr-0 d-flex align-items-center'>
                    {
                        hasNestedCollection(item) && <>
                            {
                                canLimitSubMenuItems && (
                                    <Input
                                        name={canLimitFieldName}
                                        placeholder=" "
                                        hideLabel={true}
                                        type={item?.max_quantity ? 'number' : 'text'}
                                        min='1'
                                        step='1'
                                        value={item?.max_quantity}
                                        withWrapper={false}
                                        prepend={true}
                                        className='text-center h-auto'
                                        containerClassName='mr-5'
                                        style={{ width: '60px', padding: '4px 8px', fontSize: '14px' }}
                                        handleChange={(e) => onLimitUpdate(e.target.value, item)}
                                    >
                                        <div className="input-group-prepend">
                                          <div className="input-group-text px-2"><span className='mx-auto'>Limit</span></div>
                                        </div>
                                    </Input>
                                )
                            }

                            <button
                                type='button'
                                className='btn btn-link mr-1'
                                onClick={() => setIsSubMenuCollapsed(!isSubMenuCollapsed)}
                            >
                                { isSubMenuCollapsed ? 'Expand' : 'Collapse' }
                            </button>
                        </>
                    }

                    <button
                        type='button'
                        className='btn btn-link text-danger'
                        onClick={() => onRemove(item, index)}
                    >
                        Remove
                    </button>
                </div>
            </div>

            {
                hasNestedCollection(item) && (
                    <MultiSelectExternalListSubItems
                        parentId={item[parentIdKey]}
                        iconType={iconType}
                        items={sortedByArray(item.collection, item?.collection_sort_order || [])}
                        isSortable={isSubMenuSortable}
                        isCollapsed={isSubMenuCollapsed}
                        isDefaultIconClass={subMenuIsDefaultIconClass}
                        isLimitReached={
                            Number(item?.max_quantity || 0) > 0
                            && (item?.collection || []).filter((i) => i.is_default).length >= Number(item.max_quantity)
                        }
                        onSort={onSubItemsSort}
                        onIconChange={props => onSubItemsIconChange({ ...props, parent: item })}
                        onVisibilityChange={props => onSubItemsVisibilityChange({ ...props, parent: item })}
                    />
                )
            }
        </div>
    )
}
