import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectMembers, setMember, removeMember, selectBooking } from '../../EditBooking/editBookingSlice'
import { selectPackage } from '@/features/Packages/packageSlice'
import MemberInput from './MemberInput'
import { checkBookingParticipantChangeWhileSplitWarning } from '@/features/AdvancedPointOfSale/lib/Checks'
import { isBookingCancelled, isBookingComplete } from '@/lib/Booking'
import { useConfirm } from '@/lib/useConfirmHook'

export default function EditBookingMembers({ errors }) {

    const dispatch    = useDispatch()
    const { confirm } = useConfirm()
    const booking     = useSelector(selectBooking)
    const pkg         = useSelector(selectPackage)
    const members     = useSelector(selectMembers)

    const confirmation = () => confirm(checkBookingParticipantChangeWhileSplitWarning, {
        header_text: 'Warning!',
        dangerous: true,
        size: 'md',
        confirm_text: 'OK',
        cancel_text: 'CANCEL'
    })

    const handleMemberInputChange = async (index, value) => {
        if (booking?.check?.has_booking_been_split) {
            if (await confirmation()) {
                dispatch(setMember({ index, value }))
            }
        } else {
            dispatch(setMember({ index, value }))
        }
    }

    const handleMemberInputRemove = async (index) => {
        if (booking?.check?.has_booking_been_split) {
            if (await confirmation()) {
                dispatch(removeMember({ index }))
            }
        } else {
            dispatch(removeMember({ index }))
        }
    }

    return members.map((member, index) => (
        <MemberInput
            key={index}
            index={index}
            pkg={pkg}
            member={member}
            disabled={isBookingCancelled(booking) || isBookingComplete(booking) || booking?.check?.has_adv_pos_booking_payment_been_received}
            errors={errors}
            onChange={(e) => handleMemberInputChange(index, e.target.value)}
            onRemove={() => handleMemberInputRemove(index)}
        />
    ))
}
